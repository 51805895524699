import { Injectable } from "@angular/core";
import { ApiResourceService } from "@onsip/common/services/api/resources/api-resource.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";
import {
  ApiPromiseState,
  ApiPromiseStateService
} from "@onsip/common/services/api/api-promise-state.service";
import {
  extractData,
  OnsipApiResponse
} from "@onsip/common/services/api/apiResponse/response-body-new";
import {
  genericApiAction,
  getApiActionName
} from "@onsip/common/services/api/onsip-api-action-new";
import { ApiAction } from "@onsip/common/services/api/api-actions";
import { sessionId } from "@onsip/common/services/api/apiParams/session-id";
import {
  UserOauth2AccessTokenAddParams,
  UserOauth2AccessTokenResponse,
  oAuth2SAccessTokenApiConverter as clean,
  ApiUserOauth2AccessToken,
  UserOauth2AccessTokenProxyParams
} from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token";

import { onsipApiArrayToArray } from "@onsip/common/services/api/apiResponse/xml-json";
import { arrayToRecord } from "../../util/arrayToRecord";

const debug = false;

@Injectable({ providedIn: "root" })
export class UserOauth2AccessTokenService extends ApiResourceService<UserOauth2AccessTokenResponse> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserOAuth2AccessToken", "userOAuth2AccessTokenId");
    debug &&
      console.warn(
        this.state.subscribe(state => console.warn("UserOAuth2AccessTokenService", state))
      );
  }

  UserOAuth2AccessTokenBrowse(Limit: {
    Limit: number;
  }): ApiPromiseState<UserOauth2AccessTokenResponse> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserOAuth2AccessTokenBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        ...Limit
      }
    });
    return this.promiseState.toPromise(ApiAction.UserOAuth2AccessTokenBrowse);
  }

  UserOAuth2AccessTokenDelete(UserOAuth2AccessTokenId: string): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserOAuth2AccessTokenDelete,
        SessionId: this.store.state.pipe(sessionId()),
        UserOAuth2AccessTokenId
      }
    });

    return this.promiseState.toPromise(ApiAction.UserOAuth2AccessTokenDelete);
  }

  UserOAuth2AccessTokenVerify(
    UserOAuth2AccessTokenId: string
  ): ApiPromiseState<UserOauth2AccessTokenResponse> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserOAuth2AccessTokenVerify,
        SessionId: this.store.state.pipe(sessionId()),
        UserOAuth2AccessTokenId
      }
    });
    return this.promiseState.toPromise(ApiAction.UserOAuth2AccessTokenVerify);
  }

  UserOAuth2AccessTokenAdd(
    parameters: UserOauth2AccessTokenAddParams
  ): ApiPromiseState<UserOauth2AccessTokenResponse> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserOAuth2AccessTokenAdd,
        SessionId: this.store.state.pipe(sessionId()),
        ...parameters
      }
    });
    return this.promiseState.toPromise(ApiAction.UserOAuth2AccessTokenAdd);
  }

  UserOAuth2AccessTokenProxy(parameters: UserOauth2AccessTokenProxyParams): Promise<any> {
    return genericApiAction({
      Action: ApiAction.UserOAuth2AccessTokenProxy,
      SessionId: this.sessionId,
      ...parameters
    });
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.UserOAuth2AccessTokenBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiUserOauth2AccessToken>>(
              response,
              action,
              "UserOAuth2AccessToken",
              "UserOAuth2AccessTokens"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.UserOAuth2AccessTokenAdd:
      case ApiAction.UserOAuth2AccessTokenVerify:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              clean(
                extractData<ApiUserOauth2AccessToken>(response, action, "UserOAuth2AccessToken")
              )
            ],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.UserOAuth2AccessTokenDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedUserOAuth2AccessTokenId = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "UserOAuth2AccessTokenId")?.Value;
        deletedUserOAuth2AccessTokenId &&
          this.store.mergeStateUpdate(
            this.resourceName,
            { [deletedUserOAuth2AccessTokenId]: undefined },
            action
          );
        break;
    }
  }
}
