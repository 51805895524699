@if (hasSubAgent) {
<div onsip-grid-placement-shelf class="unassign-grid">
  <div class="first-column mobile-columns">
    <div class="mat-body-1">Sub-agent name</div>
    <div class="mat-body-2">
      {{ account.subAgentName }}
    </div>
  </div>
  <div class="second-column mobile-columns">
    <div class="mat-body-1">Sub-agent ID</div>
    <div class="mat-body-2">
      {{ account.subAgentAccountId }}
    </div>
  </div>
  <div class="third-column mobile-columns">
    <div class="mat-body-1">Date Assigned</div>
    <div class="mat-body-2">
      {{ formatDate(account.dateAssigned) }}
    </div>
  </div>
  <div class="fourth-column mobile-columns">
    <button
      mat-stroked-button
      type="button"
      color="primary"
      class="unassign-button"
      (click)="onSubmit()"
    >
      Unassign
    </button>
  </div>
</div>
} @if (!hasSubAgent) {
<div onsip-grid-placement-shelf class="assign-grid">
  <div class="first-column mobile-columns">
    <div class="mat-body-1">
      Assign account <span>{{ account.accountId }}</span> to a Sub-agent
    </div>
    <mat-form-field onsip-form-field-flush-outline appearance="outline">
      <mat-select [formControl]="assignSubAgent">
        @for (account of allAccounts; track account) {
        <mat-option [value]="account">
          {{ account.contactName + " (" + account.accountId + ")" }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div class="second-column mobile-columns">
    <button
      mat-stroked-button
      type="button"
      color="primary"
      class="assign-button"
      (click)="onSubmit()"
    >
      Assign
    </button>
  </div>
</div>
}
