import { LogService } from "../../../../../common/services/logging";
import { SnackbarService } from "../snackbar/snackbar.service";

import { take } from "rxjs/operators";
import { Subscription } from "rxjs";

import { Component, Input, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { PasswordChangeModalComponent } from "./modal/password-change-modal.component";

@Component({
  selector: "onsip-password-change",
  templateUrl: "./password-change.component.html"
})
export class PasswordChangeComponent implements OnDestroy {
  @Input() sipAddress = "";

  private unsubscriber = new Subscription();

  constructor(
    private log: LogService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openPasswordChangeDialog(): void {
    try {
      const dialogRef = this.dialog.open(PasswordChangeModalComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: { sipAddress: this.sipAddress }
      });
      this.unsubscriber.add(
        dialogRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(changeSuccess => {
            if (changeSuccess === "reset-complete") {
              this.snackbarService.openSnackBar(
                "Your password was successfully changed.",
                "success"
              );
            }
          })
      );
    } catch (err: any) {
      this.log.warn(err);
    }
  }
}
