import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CallState } from "@onsip/common/libraries/sip/call-state";
import { CallControllerService } from "@onsip/common/services/call-controller.service";
import { IncomingCallComponent } from "@onsip/web/features/call/incoming-call.component";

import { take } from "rxjs/operators";

/* This service is used to relay to the bottom sheet that it should open
 * When the bottom sheet disappears, we will likely just rely on the router,
 * but this could still be used
 */

@Injectable({ providedIn: "root" })
export class CallVisibilityService {
  private dialogRef: MatDialogRef<IncomingCallComponent> | undefined = undefined;
  private dialogCall: string | undefined;

  private callQueue: Array<CallState> = [];

  constructor(private dialog: MatDialog, private callControllerService: CallControllerService) {}

  processNewCall(call: CallState) {
    if (this.dialogRef) {
      this.callQueue.push(call);
    } else {
      this.openDialog(call);
    }
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = undefined;
      this.dialogCall = undefined;
    }
  }

  isCurrentRingingCall(uuid: string): boolean {
    return this.dialogCall === uuid;
  }

  private openDialog(call: CallState): void {
    // Dialog for incoming call open bottom sheet on closed if answered.
    this.closeDialog();
    this.dialogRef = this.dialog.open(IncomingCallComponent, {
      data: { call },
      hasBackdrop: false,
      panelClass: [
        "mat-typography",
        "onsip-dialog-remove-padding",
        "onsip-dialog-width",
        "onsip-dialog-margin"
      ],
      disableClose: true,
      maxWidth: "65vw"
    });

    this.dialogCall = call.uuid;

    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef = undefined;
        this.dialogCall = undefined;

        if (this.callQueue.length > 0) {
          let innerCall: CallState | undefined = this.callQueue.shift();

          while (innerCall) {
            const updatedState = this.callControllerService.getCallStateByUuid(innerCall.uuid);

            if (updatedState && updatedState.active && !updatedState.connected) {
              this.openDialog(innerCall);
              break;
            }

            innerCall = this.callQueue.shift();
          }
        }
      });
  }
}
