import { SessionEventFactory, SessionEvent } from "./session-event";

/**
 * Emitted once when Session activated.
 */
export class NewSessionEvent {
  static make(aor: string, uuid: string): SessionEvent {
    return SessionEventFactory.make(NewSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] new";
  }
}
