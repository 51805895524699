<div class="sidebar">
  <div class="sidebar-input-dialpad">
    <onsip-sip-address-input
      #targetComp
      [attr.data-cy]="E2eLocators.CALL_START_NEW_INPUT"
      [placeholder]="'ONSIP_I18N.PHONE_NUMBER_OR_CONTACT' | translate"
      (value)="target = $event"
      (enter)="triggerCall()"
    >
    </onsip-sip-address-input>
    <div cdkOverlayOrigin #sidebarDialpad="cdkOverlayOrigin">
      <mat-icon color="primary">dialpad</mat-icon>
      <onsip-popup-container [overlayOrigin]="sidebarDialpad" [sidebar]="true">
        <onsip-dialpad [target]="'newCall'" [showHistory]="false"></onsip-dialpad>
      </onsip-popup-container>
    </div>
  </div>
  <onsip-multiple-user-agent [card]="true"> </onsip-multiple-user-agent>
  <div class="button-container">
    <button
      id="onsip-new-call-start-audio-call"
      autofocus
      onsipNewCall
      #onsipNewCallRef="onsipNewCall"
      [remoteAor]="target"
      [attr.data-cy]="E2eLocators.CALL_START_NEW_VOICE_CALL_BTN"
      (outputStartedCall)="emitStartedCall()"
      mat-flat-button
      color="primary"
    >
      <mat-icon>phone</mat-icon> &nbsp; {{ "ONSIP_I18N.VOICE_CALL" | translate }}
    </button>
    <button
      id="onsip-new-call-start-video-call"
      aria-label="start video call"
      onsipNewCall
      [remoteAor]="target"
      [video]="true"
      (outputStartedCall)="emitStartedCall()"
      mat-flat-button
      color="primary"
    >
      <mat-icon>videocam</mat-icon>
    </button>
  </div>
</div>
