import { Injectable } from "@angular/core";

import { NetworkService } from "../../../../../common/services/network.service";
import { LogService } from "../../../../../common/modules/core";

const debug = false;

@Injectable({ providedIn: "root" })
export class WebNetworkService extends NetworkService {
  constructor(log: LogService) {
    super(log);
  }

  protected platformInit(): void {
    debug && this.log.debug("WebNetworkService.platformInit");

    // monitor network connectivity state changes
    window.addEventListener("online", () => {
      debug && this.log.debug("NetworkService: *** Online ***");
      this.stateStore.online = true;
      this.stateStore.connectivity = "unknown";
      this.publishState();
    });
    window.addEventListener("offline", () => {
      debug && this.log.debug("NetworkService: *** Offline ***");
      this.stateStore.online = false;
      this.stateStore.connectivity = "none";
      this.publishState();
    });

    // are we online now
    if (window.navigator.onLine) {
      this.stateStore.online = true;
      this.stateStore.connectivity = "unknown";

      debug && this.log.debug("NetworkService online. Connectivity unknown.");
    }

    // This is mobile specific, so it is safe to pin the suspended state to "false" for web/desktop.
    this.stateStore.suspended = false;

    this.publishState();
  }
}
