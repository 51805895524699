/** converts currency to correct string format since we dont have printf in javascript */
export function currencyFormatter(credit: string | number): string {
  if (typeof credit === "string") {
    credit = parseFloat(credit);
  }
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  return formatter.format(credit);
}
