import { Component, OnInit, OnDestroy, ChangeDetectorRef } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
  AbstractControl
} from "@angular/forms";
import { Router } from "@angular/router";

import { Subscription, combineLatest } from "rxjs";

import { Contact } from "../../../common/interfaces/contact";
import { UserService } from "../../../common/services/api/resources/user/user.service";
import { E164PhoneNumber } from "../../../common/libraries/e164-phone-number";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";

import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { customNameValidator } from "./validators/custom-contact-name.validator";
import { customAddressValidator } from "./validators/custom-contact-address.validator";
import { TranslateService } from "@ngx-translate/core";
import { views } from "../../app/phone/views";
import { CurrentContactService } from "./current-contact.service";
import { ContactService } from "../../../common/services/contact/contact.service";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { customContactPatternValidator } from "./validators/custom-contact-name-pattern.validator";

interface EditListItem {
  isContact: boolean;
  name: string;
  avatarUrl?: string;
  uuid: string;
  isInContactList: boolean;
}

@Component({
  selector: "onsip-contact-list-edit",
  templateUrl: "./contact-list-edit.component.html",
  styleUrls: ["./contact-list-edit.scss"]
})
export class ContactListEditComponent implements OnInit, OnDestroy {
  customContactForm!: FormGroup;
  displayFilteredDirectory: Array<EditListItem> = [];
  openCustomContactForm = false;
  E2eLocators = E2eLocators;

  private filteredDirectory: Array<EditListItem> = [];
  private unsubscriber = new Subscription();

  constructor(
    private router: Router,
    private currentContactService: CurrentContactService,
    private userService: UserService,
    private contactService: ContactService,
    private snackbarService: SnackbarService,
    private analyticsService: AnalyticsService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.customContactForm = new FormGroup({
      name: new FormControl("", [
        Validators.required,
        customNameValidator(this.contactService),
        customContactPatternValidator(),
        Validators.maxLength(60)
      ]),
      sipAddress: new FormControl("", [Validators.required, customAddressValidator()])
    });
    this.unsubscriber.add(
      combineLatest([
        this.contactService.state,
        this.contactService.getContactList$(),
        this.userService.selfUser
      ]).subscribe(([directory, contactList, selfUser]) => {
        const newEditList: Array<EditListItem> = [];

        directory.contacts.forEach(dirMember => {
          if (!dirMember.custom && dirMember.userId !== selfUser.userId) {
            const isInContactList = !!contactList.find(
              listMember => listMember.uuid === dirMember.uuid
            );
            newEditList.push({
              isContact: true,
              avatarUrl: dirMember.avatarUrl,
              name: dirMember.name,
              uuid: dirMember.uuid,
              isInContactList
            });
          }
        });

        this.filteredDirectory = newEditList;
        this.filteredDirectory.sort(this.compareByName);
        this.displayFilteredDirectory = this.filteredDirectory;
      })
    );
    // running change detector to update isSlackChatEnabled variable
    // and have access to slackChannelList
    // https://stackoverflow.com/questions/39366981/angular-2-viewchild-in-ngif
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  applyFilter(eventFilter: EventTarget | null): void {
    const filterValue = (eventFilter as any).value.toLowerCase();
    this.displayFilteredDirectory = this.filteredDirectory.filter(contact =>
      contact.name.toLowerCase().includes(filterValue)
    );
  }

  toggleCustomContactForm(): void {
    this.openCustomContactForm = !this.openCustomContactForm;
    this.displayFilteredDirectory = this.filteredDirectory.filter(contact =>
      contact.name.toLowerCase().includes("")
    );
  }

  handleContactListClick(item: EditListItem): void {
    if (item.isInContactList) {
      this.navigateToItem(item);
      return;
    }
    if (item.isContact) {
      const contact = this.contactService.find(item.uuid);
      if (contact) {
        this.addContact(contact);
      }
    }
  }

  createCustomContact(customContactForm: FormGroup, formDirective: FormGroupDirective): void {
    const controls = customContactForm.controls;
    if (!controls.name.errors && !controls.sipAddress.errors) {
      let contact: Contact | undefined;
      const name = controls.name.value;
      let uri = controls.sipAddress.value;
      if (uri.indexOf("@") === -1) {
        uri = new E164PhoneNumber(uri);
        contact = ContactService.createCustomPhoneContact(name, uri);
      } else {
        if (!uri.startsWith("sip:")) {
          uri = "sip:" + uri;
        }
        uri = OnSIPURI.parseString(uri);
        contact = ContactService.createCustomSIPContact(name, uri);
      }

      this.contactService
        .addCustomContact(contact.name, contact)
        .then(() => {
          this.analyticsService.sendContactEvent("Custom Create", contact, undefined);
          this.snackbarService.openSnackBar(
            this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
            "success"
          );
          this.resetCustomContactForm(formDirective);
          this.toggleCustomContactForm();
        })
        .catch(err => {
          this.snackbarService.openSnackBar(err.message, "error");
          customContactForm.controls.sipAddress.setErrors(["SIP Address is invalid"]);
        });
    }
  }

  getErrorMessage(formControl: FormGroup, controlName: string) {
    let control: AbstractControl;
    if (controlName === "name") {
      control = formControl.controls.name;
      if (control.hasError("required")) {
        return this.translate.instant("ONSIP_I18N.NAME_IS_REQUIRED");
      }

      if (control.hasError("invalidName")) {
        return `${control.value} ${this.translate.instant("ONSIP_I18N.IS_ALREADY_A_CONTACT")}`;
      }

      if (control.hasError("namePattern")) {
        return this.translate.instant("ONSIP_I18N.CONTACT_NAME_IS_INVALID");
      }

      if (control.hasError("maxlength")) {
        return this.translate.instant("VALIDATION_ERRORS.GENERIC_MAXLENGTH");
      }

      return "";
    } else if (controlName === "sipAddress") {
      control = formControl.controls.sipAddress;
      return control.hasError("required")
        ? this.translate.instant("ONSIP_I18N.A_SIP_ADDRESS_OR_PHONE_NUMBER_IS_REQUIRED")
        : control.hasError("invalidAddress")
        ? this.translate.instant("ONSIP_I18N.ENTER_A_VALID_SIP_ADDRESS_OR_PHONE_NUMBER")
        : "";
    }
  }

  private navigateToItem(item: EditListItem): void {
    const contact = this.contactService.find(item.uuid);
    if (contact) {
      this.currentContactService.state.next(contact);
      this.router.navigate([views.CONTACT]);
    }
  }

  private addContact(contact: Contact): void {
    this.contactService
      .addContact([contact])
      .then(() => {
        this.snackbarService.openSnackBar(
          this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
          "success"
        );
        this.analyticsService.sendContactEvent("Directory Create", undefined, {
          count: [contact].length
        });
      })
      .catch((errors: Error | Array<Error>) => {
        const errorArray = Array.isArray(errors) ? errors : [errors];
        errorArray.forEach(error => {
          this.snackbarService.openSnackBar(error.message, "error");
        });
      });
  }

  private resetCustomContactForm(formDirective: FormGroupDirective): void {
    this.customContactForm.reset();
    formDirective.resetForm();
  }

  private compareByName(a: EditListItem, b: EditListItem): number {
    const aName: string = a.name.toLowerCase(),
      bName: string = b.name.toLowerCase();

    if (aName > bName) {
      return 1;
    } else if (aName < bName) {
      return -1;
    } else {
      return 0;
    }
  }
}
