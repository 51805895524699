/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from "@angular/core";

import { Console } from "./console";

@Injectable()
export class ConsoleService implements Console {
  log(message?: any, ...optionalParams: Array<any>): void {
    return;
  }
  debug(message?: any, ...optionalParams: Array<any>): void {
    return;
  }
  info(message?: any, ...optionalParams: Array<any>): void {
    return;
  }
  warn(message?: any, ...optionalParams: Array<any>): void {
    return;
  }
  error(message?: any, ...optionalParams: Array<any>): void {
    return;
  }
}
