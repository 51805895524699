import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SipAddressFieldComponent } from "./sip-address-field.component";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
  declarations: [SipAddressFieldComponent],
  exports: [SipAddressFieldComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule
  ]
})
export class SipAddressFieldModule {}
