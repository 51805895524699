/**
 * Defines a Subscription event.
 */
export interface SubscriptionEvent {
  id: string; // event id
  aor: string; // subscription aor
  uuid: string; // subscription uuid
  body?: string; // optional body of the notify
}

export class SubscriptionEventFactory {
  static make(id: string, aor: string, uuid: string, body?: string): SubscriptionEvent {
    const event: SubscriptionEvent = {
      id,
      aor,
      uuid
    };
    if (body) {
      event.body = body;
    }
    return event;
  }
}

/**
 * User-Defined Type Guard for SubscriptionEvent
 * @param event Event to check
 */
export function isSubscriptionEvent(event: any): event is SubscriptionEvent {
  if (event && event.id) {
    const id = event.id;
    if (typeof id === "string") {
      return id.startsWith("[Subscription]");
    }
  }
  return false;
}
