<button
  class="dialpad-button"
  mat-button
  disableRipple="true"
  (mousedown)="down()"
  (mouseup)="up()"
  (mouseleave)="up()"
>
  <div class="label-container">
    <span class="number">{{ dtmfKey }}</span>
    <span class="letters">{{ dtmfLetters }}</span>
  </div>
</button>
