import { Injectable, NgZone } from "@angular/core";

import { LogService } from "./logging/log.service";
import { StateEmitter } from "../libraries/emitter";

import { FailoverAddress } from "./api/apiResponse/address";

import { Subject, BehaviorSubject, combineLatest } from "rxjs";
import { distinctUntilChanged, filter, take, skip } from "rxjs/operators";

import { CallController } from "../libraries/sip/call-controller";
import { MonitoredCallController } from "../libraries/sip/monitored-call-controller";
import { ApiSessionService } from "./api/api-session.service";
import { UserAddressService, UserAddress } from "./api/resources/userAddress/user-address.service";
import { takeWhile } from "rxjs/operators";
import { Role } from "./api/role";

const debug = false;

export type CallControllerConstructor = new () => CallController | MonitoredCallController;

/**
 * The application state.
 */
export interface IdentityState {
  /** default user address. undefined if not all user agents are loaded */
  defaultIdentity: UserAddress | undefined;
  /** outbound user address (not used on mobile). undefined if not all user agents are loaded */
  outboundIdentity: UserAddress | undefined;
  /** If logged in, the SIP addresses and associated credentials of the logged in user. Empty array otherwise. */
  addresses: Array<UserAddress>;
  /** The domain the user belongs to */
  domain: string | undefined;
}

@Injectable({ providedIn: "root" })
export class IdentityService extends StateEmitter<IdentityState> {
  protected unsubscribe: Subject<void> = new Subject<void>();
  private didAddressInit = false;

  // Used in mobile, will update for web if needed. In form  sip:username@domain
  protected sipAliases: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>([]);

  private static initialState(): IdentityState {
    return {
      defaultIdentity: undefined,
      outboundIdentity: undefined,
      addresses: [],
      domain: undefined
    };
  }

  constructor(
    protected log: LogService,
    private ngZone: NgZone,
    protected apiSession: ApiSessionService,
    protected userAddress: UserAddressService
  ) {
    super(IdentityService.initialState());
    debug && this.state.subscribe(state => console.warn("IdentityState", state));
    this.platformInit();

    this.apiSession.state.subscribe(apiSessionState => {
      if (!this.didAddressInit && apiSessionState.loggedIn) {
        this.clearState();
        this.apiUserAddressBrowse();
      } else if (!apiSessionState.loggedIn && !apiSessionState.loginInProgress) {
        // clear the state on logout
        this.didAddressInit = false;
        this.stateStore = IdentityService.initialState();
        this.publishState();
        this.clearState();
      }
    });

    this.apiSession.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        ),
        skip(1)
      )
      .subscribe(state => {
        if (state.loggedInRole === Role.SuperUser) {
          this.refreshWithNewUser();
        }
      });
  }

  /** Dispose of service. */
  dispose(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.publishStateComplete();

    debug && this.log.debug("IdentityService: Disposed");
  }

  /** For debugging. */
  dumpStore(): void {
    debug && this.log.debug(JSON.stringify(this.stateStore));
  }

  // Rather than doing an api call to update the dnd value, update the identities when
  // a successful api call was done and publish state to reflect correctly in sentry
  updateDndState(updatedUserAddress: UserAddress): void {
    if (
      this.stateStore.defaultIdentity &&
      this.stateStore.defaultIdentity.aor === updatedUserAddress.aor
    ) {
      this.stateStore.defaultIdentity.doNotDisturb = updatedUserAddress.doNotDisturb;
    }
    if (
      this.stateStore.outboundIdentity &&
      this.stateStore.outboundIdentity.aor === updatedUserAddress.aor
    ) {
      this.stateStore.outboundIdentity.doNotDisturb = updatedUserAddress.doNotDisturb;
    }
    this.stateStore.addresses.forEach((ua: UserAddress, index) => {
      if (ua.aor === updatedUserAddress.aor) {
        this.stateStore.addresses[index].doNotDisturb = updatedUserAddress.doNotDisturb;
      }
    });
    this.publishState();
  }

  getDefaultIdentity(): UserAddress | undefined {
    debug && this.log.debug("IdentityService: getDefaultIdentity");
    return this.stateStore.defaultIdentity;
  }

  getOutboundIdentity(): UserAddress | undefined {
    debug && this.log.debug("IdentityService: getOutboundIdentity");
    return this.stateStore.outboundIdentity;
  }

  setDefaultIdentity(aor?: string, isAnonymous: boolean = false): void {
    debug && this.log.debug("IdentityService: setDefaultIdentity");
    if (isAnonymous) {
      const falseAddress: UserAddress = {
        name: "Anon UA",
        username: "",
        domain: "",
        aor: aor || "",
        timeout: 130,
        authUsername: "",
        authPassword: "",
        addressId: "",
        doNotDisturb: false,
        e911LocationId: "",
        defaultAddress: {} as FailoverAddress,
        previousDefaultAddress: {} as FailoverAddress,
        userId: "",
        userAddressId: ""
      };

      this.stateStore.defaultIdentity = falseAddress;
      this.stateStore.outboundIdentity = falseAddress;
      this.publishState();
      return;
    }

    const userAddress = aor ? this.aorToUserAddress(aor) : undefined;

    if (this.stateStore.outboundIdentity === this.stateStore.defaultIdentity) {
      this.stateStore.outboundIdentity = userAddress;
    }
    this.stateStore.defaultIdentity = userAddress;
    this.publishState();
  }

  setOutboundIdentity(aor: string): void {
    debug && this.log.debug("IdentityService: setOutboundIdentity");
    const userAddress = this.aorToUserAddress(aor);

    if (!userAddress) {
      return;
    }

    this.stateStore.outboundIdentity = userAddress || this.stateStore.defaultIdentity;
    this.publishState();
  }

  restoreOutboundIdentity(): void {
    debug && this.log.debug("IdentityService: restoreOutboundIdentity");
    this.stateStore.outboundIdentity = this.stateStore.defaultIdentity;
    this.publishState();
  }

  apiUserAddressBrowse(): void {
    debug && this.log.debug("IdentityService.apiUserAddressBrowse");
    this.didAddressInit = true;
    this.stateStore.addresses = [];

    combineLatest([this.userAddress.selfUser, this.userAddress.state])
      .pipe(
        // TODO: this is a one-time-use subscription, refactor so that api resets don't break this
        // listen until we've populated the addreses property on state (<-- old comment)
        // eslint-disable-next-line rxjs/no-ignored-takewhile-value
        takeWhile(() => !this.stateValue.addresses.length)
      )
      .subscribe(([selfAddresses, state]) => {
        debug &&
          console.warn("IdentityService.apiUserAddressBrowse: UserAddressState update", state);

        if (!selfAddresses.length) {
          if (state.errors.length) {
            console.error(
              "UserAddressService not initialized or User has no user addresses, trunking/older account, returning"
            );
          }
          this.stateStore.addresses = [];
          this.publishState();
        } else {
          this.didAddressInit = true;
          this.stateStore.domain = selfAddresses[0].domain;
          this.stateStore.addresses = selfAddresses;
          this.publishState();
        }
      });
  }

  getAllAliases(): BehaviorSubject<Array<string>> {
    return this.sipAliases;
  }

  clearState(): void {
    this.stateStore.defaultIdentity = undefined;
    this.stateStore.addresses = [];
    this.stateStore.domain = undefined;
    this.restoreOutboundIdentity();
  }

  /**
   * Platform specific initialization.
   * This method is overridden by other platform specific implementations of IdentityService (i.e. mobile).
   */
  protected platformInit(): void {}

  protected publishRun(fn: () => any): any {
    return this.ngZone.run(() => {
      return fn();
    });
  }

  /** update identity service with new user if accessing via super admin privileges */
  private refreshWithNewUser(): void {
    debug && console.warn("IdentityService: refreshing identity service to new user");
    // clear current state first
    this.stateStore.defaultIdentity = undefined;
    this.stateStore.addresses = [];
    this.stateStore.domain = undefined;
    // User Address Browse to find the default user
    this.userAddress.selfUser
      .pipe(
        filter(userAddress => !!userAddress.length),
        take(1)
      )
      .subscribe(selfAddresses => {
        this.didAddressInit = true;
        this.stateStore.domain = selfAddresses[0].domain;
        this.stateStore.addresses = selfAddresses;
        const userAddress = selfAddresses[0];
        this.stateStore.outboundIdentity = userAddress;
        this.stateStore.defaultIdentity = userAddress;
        this.publishState();
      });
  }

  // Find the Address which matched the given userAgent
  private aorToUserAddress(aor: string): UserAddress | undefined {
    const userAddress = this.stateValue.addresses.find(
      (address: UserAddress) => address.aor === aor
    );
    if (!userAddress) {
      debug &&
        console.log(
          aor,
          "not in ApiSession.addresses  Address contains: " +
            JSON.stringify(this.stateValue.addresses)
        );
    }
    return userAddress;
  }
}
