import { CallEvent } from "../call-event";

/** MonitoredCall event kind. */
export const MonitoredCallEventKind = "[Call] [Monitored]"; // Must be string literal (being used as type).

// MonitoredCall event id types. Must be string literals (being used as types).
export enum EventId {
  EchoMonitoredCallEventId = "[Call] [Monitored] echo",
  JitterMonitoredCallEventId = "[Call] [Monitored] jitter",
  StartMonitoredCallEventId = "[Call] [Monitored] start",
  StopMonitoredCallEventId = "[Call] [Monitored] stop"
}

/** MonitoredCall event interface. */
export class MonitoredCallEvent extends CallEvent {
  /** The event identifier. Unique per event type. */
  kindMonitoredCallEvent: typeof MonitoredCallEventKind = MonitoredCallEventKind;
  /** Name of strategy which percipitated the event. */
  strategy?: string;

  /**
   * Factory for MonitoredCallEvent.
   * @param guard User defined type guard to validate created event.
   * @param id Event type id.
   * @param uuid UUID of the call assoicated with the event.
   * @param strategy Name of stategy responsible for the event.
   */
  constructor(guard: (event: any) => event is MonitoredCallEvent, uuid: string, strategy?: string) {
    super(guard, uuid);
    this.strategy = strategy;
  }
}

/**
 * User-Defined Type Guard for MonitoredCallEvent.
 * @param event Event to check.
 */
export function isMonitoredCallEvent(event: any): event is MonitoredCallEvent {
  return event instanceof MonitoredCallEvent;
}
