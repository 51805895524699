import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Config } from "@onsip/common/config";
import { views } from "@onsip/web/app/phone/views";
import { Observable, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { InitPhoneService } from "../phone/init-phone.service";

@Component({
  selector: "onsip-super-user-view",
  templateUrl: "./super-user-view.component.html",
  styleUrls: ["./super-user-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperUserViewComponent implements OnInit, OnDestroy {
  views = views;
  debug = /localhost/.test(location.host);
  isDesktop = Config.IS_DESKTOP;
  useSmallIcon!: Observable<boolean>;

  private unsubscriber = new Subscription();

  constructor(
    public initPhoneService: InitPhoneService,
    private elRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {}

  // copy from phone component
  // NB keydown happens just before keypress
  @HostListener("window:keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  @HostListener("window:keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  ngOnInit(): void {
    this.initTitle();
    this.initBreakpoints();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private initTitle(): void {
    document.title = "OnSIP - Super Admin View";
    this.unsubscriber.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter(route => route.outlet === "primary"),
          mergeMap(route => route.data)
        )
        .subscribe(event => {
          const title: string | undefined = event.title;
          if (title && title.length) {
            // some pages set their own titles
            document.title = "OnSIP - " + title;
          }
        })
    );
  }

  private initBreakpoints(): void {
    this.useSmallIcon = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map(breakpoints => breakpoints.matches));
  }

  private dontBackspace(e: any): void {
    // prevent backspace from leaving page
    if (8 !== e.which) {
      return;
    }

    if (!this.textFieldFocused()) {
      e.preventDefault();
    }
  }

  private textFieldFocused(): boolean {
    const focused: any = this.elRef.nativeElement.ownerDocument.activeElement;
    return /text|textarea|password|number/i.test(focused.type);
  }

  private windowsHotkeys(event: any): void {
    if (event.ctrlKey === true && Config.IS_DESKTOP) {
      // r key pressed
      if (event.keyCode === 82) {
        window.electron.sendMessage("relaunch-function");
      } else if (event.keyCode === 81) {
        window.electron.sendMessage("app-quit");
      }
    }
  }
}
