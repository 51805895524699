<mat-list onsip-wrap-lines-list>
  @if(listItems.length) { @for (items of listItems; track items; let i = $index) {
  <mat-list-item>
    <div class="detail-section">
      @if (itemNameSingular) {
      <span matLine class="mat-body-1">{{ itemNameSingular }} {{ i + 1 }}</span>
      } @for (item of items; track item) {
      <span
        matLine
        class="mat-body-2"
        [ngClass]="item.accentColorClass ? item.accentColorClass : ''"
        [ngStyle]="{ 'font-weight': item.accentColorClass ? 'bold' : '' }"
      >
        {{ item.title }}: {{ item.value }}
      </span>
      }
    </div>
  </mat-list-item>
  } } @else {
  <mat-list-item>
    <p matLine>
      <span class="mat-body-2">{{
        "ONSIP_I18N.NO_VALUES" | translate : { values: itemNamePlural }
      }}</span>
    </p>
  </mat-list-item>
  }
</mat-list>
