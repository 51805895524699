import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SuperAdminToolbarComponent } from "./super-admin-toolbar.component";

@NgModule({
  imports: [CommonModule, MatToolbarModule, MatButtonModule, MatDividerModule],
  declarations: [SuperAdminToolbarComponent],
  exports: [SuperAdminToolbarComponent]
})
export class SuperAdminToolbarModule {}
