import { Injectable } from "@angular/core";

import { UserAvailabilityService } from "./sayso/user-availability.service";
import { DoNotDisturbService } from "./do-not-disturb.service";

import { Subscription, BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";
import { UserAddressService } from "./api/resources/userAddress/user-address.service";

@Injectable({ providedIn: "root" })
export class GlobalPresenceService {
  globalPresenceStream: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private apiInProgress = false;
  private unsubscriber = new Subscription();
  private userAvailabilityState = false;
  private initialLogin = true;

  constructor(
    private dndService: DoNotDisturbService,
    private userAvailabilityService: UserAvailabilityService,
    private userAddressService: UserAddressService
  ) {
    this.subscribeGlobalAvailability();
    this.userAddressService.state.pipe(filter(state => !state.loading)).subscribe(state => {
      // Api call failed we should undo the "fake" update
      this.apiInProgress = false;
      // TODO real error handling
      if (state.errors.length) {
        this.globalPresenceStream.next(!this.globalPresenceStream.getValue());
      }
    });
  }

  getUserAvailability(): boolean {
    return this.userAvailabilityState;
  }

  enableGlobalPresence(enable: boolean): void {
    this.globalPresenceStream.next(enable); // Api update is slow so we fake it and update immediately
    this.apiInProgress = true;
    this.dndService.setDoNotDisturb(!enable);
  }

  private subscribeGlobalAvailability(): void {
    this.unsubscriber.add(
      this.userAvailabilityService.state
        .pipe(
          filter(state => state.availability !== "undefined") // We don't care for the undefined initial state for any use cases of GA
        )
        .subscribe(state => {
          this.userAvailabilityState = state.availability === "available";
          if (this.initialLogin) {
            this.initialLogin = false;
            this.enableGlobalPresence(this.userAvailabilityState);
            this.apiInProgress = false;
          } else {
            // We need to update the internal dnd state if another device sets themselves as away
            if (!this.apiInProgress) {
              this.globalPresenceStream.next(this.userAvailabilityState);
              this.dndService.updateUserDndState(!this.userAvailabilityState);
            }
          }
        })
    );
  }
}
