<mat-card>
  <mat-card-content>
    @if (loading | async) {
    <span class="entry-overlay"><mat-spinner></mat-spinner></span>
    } @if ((oauthSignin | async) === false) {
    <form class="entry-form">
      <mat-form-field onsip-form-field-padding-fix>
        <mat-label>{{ "ONSIP_I18N.SIP_ADDRESS" | translate }}</mat-label>
        <input
          id="onsip-login-username"
          [attr.data-cy]="E2eLocators.LOGIN_USER_NAME_INPUT"
          #loginUsername
          matInput
          type="text"
          autocomplete
          placeholder="username@example.onsip.com"
          autofocus
          [formControl]="usernameControl"
          [errorStateMatcher]="usernameErrorStateMatcher"
          (keydown.Enter)="submit($event)"
          (keydown)="resetErrors()"
          (click)="resetErrors()"
        />
        <mat-hint
          class="entry-link"
          tabindex="-1"
          routerLink="{{ prephoneViews.FORGOT_SIP }}"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ "ONSIP_I18N.FORGOT_SIP_ADDRESS" | translate }}
        </mat-hint>
      </mat-form-field>
      <mat-form-field onsip-form-field-padding-fix>
        <mat-label>{{ "ONSIP_I18N.PASSWORD" | translate }}</mat-label>
        <input
          id="onsip-login-password"
          [attr.data-cy]="E2eLocators.LOGIN_PASSWORD_INPUT"
          #loginPassword
          matInput
          type="password"
          autocomplete
          [formControl]="passwordControl"
          [errorStateMatcher]="passwordErrorStateMatcher"
          (keydown.Enter)="submit($event)"
          (keydown)="resetErrors()"
          (click)="resetErrors()"
        />
        <mat-hint
          class="entry-link"
          tabindex="-1"
          routerLink="{{ prephoneViews.FORGOT_PASS }}"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ "ONSIP_I18N.FORGOT_PASSWORD" | translate }}
        </mat-hint>
      </mat-form-field>
      <button
        class="entry-submit-button"
        mat-flat-button
        color="primary"
        [attr.data-cy]="E2eLocators.LOGIN_SUBMIT_BTN"
        [disabled]="loading | async"
        (click)="submit($event)"
        (keydown.Enter)="submit($event)"
        type="submit"
      >
        {{ "ONSIP_I18N.LOG_IN" | translate }}
      </button>
      <mat-divider></mat-divider>
      @if (!hideOauth) {
      <button mat-button class="entry-oauth-button" (click)="oauthLogin('Google')">
        <img
          [src]="'resources/img/btn_google_signin_light_normal_web.png'"
          [srcset]="
            'resources/img/btn_google_signin_light_normal_web.png 1x, resources/img/btn_google_signin_light_normal_web@2x.png 2x'
          "
          alt="Sign In With Google"
        />
      </button>
      } @if (!hideOauth) {
      <button mat-button class="entry-oauth-button" (click)="oauthLogin('Slack')">
        <img
          src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
          srcset="
            https://platform.slack-edge.com/img/sign_in_with_slack.png    1x,
            https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x
          "
          alt="Sign In With Slack"
        />
      </button>
      }
    </form>
    } @if (oauthSignin | async) {
    <form class="entry-form">
      <mat-card-content translate [translateParams]="{ value: cleanServiceName }">
        ONSIP_I18N.PLEASE_SELECT_A_USER_TO_LINK_TO_YOUR_VALUE_LOGIN
      </mat-card-content>
      <mat-list class="entry-list">
        <mat-radio-group color="primary">
          @for (user of userList; track user) {
          <mat-list-item>
            <mat-radio-button
              (click)="selectedUser = user"
              [value]="user.Addresses.Address[0].Address"
            >
            </mat-radio-button>
            <p matLine>{{ user.ContactName }}</p>
            <p matLine>{{ user.Addresses.Address[0].Address }}</p>
          </mat-list-item>
          }
        </mat-radio-group>
      </mat-list>
      <button
        mat-raised-button
        color="primary"
        class="entry-button"
        [disabled]="!selectedUser || (loading | async)"
        (click)="completeOAuthLogin()"
        type="submit"
      >
        {{ "ONSIP_I18N.LOG_IN" | translate | uppercase }}
      </button>
    </form>
    }
  </mat-card-content>
</mat-card>
