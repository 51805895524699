import { MultipleUserAgentComponent } from "./multiple-user-agent.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  declarations: [MultipleUserAgentComponent],
  exports: [MultipleUserAgentComponent]
})
export class UserAgentModule {}
