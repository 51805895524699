import { LogService } from "../../../../../common/services/logging";

import { ChatListeners } from "./chat.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";

import { Injectable } from "@angular/core";
import { Message } from "sip.js";
import { filter, take } from "rxjs/operators";
import { IdentityService } from "../../../../../common/modules/core";
import { UserSummaryContact } from "../../../../../common/services/api/resources/userSummaryContact/user-summary-contact";

@Injectable({ providedIn: "root" })
export class OnsipChatService {
  private generalListeners: any = {};
  private primaryAOR = "";
  private enabled = false;

  constructor(
    private log: LogService,
    private callControllerService: CallControllerService,
    private identity: IdentityService
  ) {}

  onMessageReceived(message: Message, messageBody: any) {
    const aor = message.request.from.uri.aor;
    if (this.enabled) {
      this.generalListeners.onMessage({
        isDirect: true, // most likely going away
        chatId: aor,
        authorId: aor,
        text: messageBody.message
      });
    } else {
      this.generalListeners.onDisabled({
        isDirect: true, // most likely going away
        chatId: aor,
        authorId: aor,
        disabledProvider: "onsip"
      });
    }
  }

  enable(allContacts: Array<UserSummaryContact>): Promise<void> {
    this.enabled = true;

    return new Promise(resolve => {
      this.identity.state
        .pipe(
          filter(state => state.addresses.length > 0),
          take(1)
        )
        .subscribe(state => {
          this.primaryAOR = state.addresses[0].aor;

          const chatContacts: Array<any> = [],
            chatBodies: Array<any> = [],
            selfId: string = this.primaryAOR;

          chatContacts.push({
            id: this.primaryAOR,
            displayName: state.addresses[0].name
          });

          allContacts.forEach(contact => {
            if (!contact.addresses || !contact.addresses[0]) {
              return;
            }
            chatContacts.push({
              id: contact.addresses[0].address,
              displayName: contact.contactName,
              email: contact.contactEmail
            });

            chatBodies.push({
              chatId: contact.addresses[0].address,
              userId: contact.addresses[0].address
            });
          });

          this.generalListeners.onConnected({
            chatContacts,
            chatBodies,
            chatData: {},
            selfId
          });

          resolve();
        });
    });
  }

  disable(): void {
    this.enabled = false;
  }

  setupListeners(listeners: ChatListeners): void {
    this.generalListeners = listeners;
  }

  sendMessage(chatLine: any, chatData: any): Promise<any> {
    const body: any = {
      type: "app-message",
      message: chatLine.text,
      available: !chatData.sendDisabled,
      primaryAOR: this.primaryAOR
    };
    try {
      const bodyStr: string = JSON.stringify(body);

      this.callControllerService.sendMessage(
        chatLine.id,
        bodyStr,
        "application/onsip.chat-message+json"
      );
    } catch (e: any) {
      this.log.error("onsipChatService", "- sendMessage:", e, "body=", body);
    }
    return Promise.resolve();
  }
}
