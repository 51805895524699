export interface ApiQueueAgentSummary {
  AgentAddress: string;
  AgentName: string;
  AvgCallLength: string;
  CallsAnswered: string;
  LoginDuration: string;
  TimeIdle: string;
  TimeOnCalls: string;
  TimeOnHold: string;
}

export interface QueueAgentSummary {
  agentAddress: string;
  agentName: string;
  avgCallLength: string;
  callsAnswered: string;
  loginDuration: string;
  timeIdle: string;
  timeOnCalls: string;
  timeOnHold: string;
}

export function apiQueueSummaryToQueueSummary(apiAgent: ApiQueueAgentSummary): QueueAgentSummary {
  return {
    agentAddress: apiAgent.AgentAddress,
    agentName: apiAgent.AgentName,
    avgCallLength: apiAgent.AvgCallLength,
    callsAnswered: apiAgent.CallsAnswered,
    loginDuration: apiAgent.LoginDuration,
    timeIdle: apiAgent.TimeIdle,
    timeOnCalls: apiAgent.TimeOnCalls,
    timeOnHold: apiAgent.TimeOnHold
  };
}
