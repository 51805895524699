export declare interface ApiTeamPageInfo {
  AccentColor: string | {};
  Blurb: string | {};
  Greeting: string | {};
}

export declare interface TeamPageInfo {
  accentColor: string;
  blurb: string;
  greeting: string;
}

export function apiTeamPageInfoToTeamPageInfo(
  apiTeamPageInfo: ApiTeamPageInfo | undefined
): TeamPageInfo {
  if (!apiTeamPageInfo) {
    return {
      accentColor: "",
      blurb: "",
      greeting: ""
    };
  } else {
    return {
      accentColor:
        typeof apiTeamPageInfo.AccentColor === "string" ? apiTeamPageInfo.AccentColor : "",
      blurb: typeof apiTeamPageInfo.Blurb === "string" ? apiTeamPageInfo.Blurb : "",
      greeting: typeof apiTeamPageInfo.Greeting === "string" ? apiTeamPageInfo.Greeting : ""
    };
  }
}
