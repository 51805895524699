// operating system build targets
export interface OperatingSystems {
  ANDROID: string; // implies platform is mobile
  IOS: string; // implies platform is mobile
  LINUX: string; // implies platform is desktop
  MAC: string; // implies platform is desktop
  WIN: string; // implies platform is desktop
}

// platform build targets
export interface Platforms {
  WEB: string; // web browser build
  MOBILE: string; // nativescript build
  DESKTOP: string; // electron build
}

// Admin API targets
export interface ApiUrls {
  PRODUCTION: string;
  BETA: string;
}

export interface WebSocketServers {
  PRODUCTION: string;
}

export class Config {
  // debug levels
  static DEBUG: Record<string, boolean> = {
    ALL: false, // if true, logs at all levels
    LEVEL_1: false, // .error logging enabled if true
    LEVEL_2: false, // .warn  logging enabled if true
    LEVEL_3: false, // .info  logging enabled if true
    LEVEL_4: false // .debug logging enabled if true
  };

  // supported operating systems
  static OPERATING_SYSTEMS: OperatingSystems = {
    ANDROID: "android",
    IOS: "ios",
    LINUX: "linux",
    MAC: "mac",
    WIN: "win"
  };

  // supported platforms
  static PLATFORMS: Platforms = {
    WEB: "web",
    MOBILE: "mobile",
    DESKTOP: "desktop"
  };

  static isAppStore = false;

  // Admin API targets
  static API_URLS: ApiUrls = {
    PRODUCTION: "https://api.onsip.com/api",
    BETA: "https://beta.jnctn.com/api"
  };

  static WS_SERVERS: WebSocketServers = {
    PRODUCTION: "wss://edge.sip.onsip.com"
  };

  // current operating system build target (defaults to not set)
  static OPERATING_SYSTEM_TARGET = "";

  // NOTE for mobile: This will be messy because if we want to use the test firebase environment we won't get push notifications
  // because the backend uses the production API Key. We'd need it to push to both to make this work right. So if we are
  // using dev build then we won't get push notifications
  static FIREBASE_ENV = "PRODUCTION";

  // convenient operating system check
  static get IS_ANDROID(): boolean {
    return Config.OPERATING_SYSTEM_TARGET === Config.OPERATING_SYSTEMS.ANDROID;
  }

  static get IS_IOS(): boolean {
    return Config.OPERATING_SYSTEM_TARGET === Config.OPERATING_SYSTEMS.IOS;
  }

  static get IS_LINUX(): boolean {
    return Config.OPERATING_SYSTEM_TARGET === Config.OPERATING_SYSTEMS.LINUX;
  }

  static get IS_MAC(): boolean {
    return Config.OPERATING_SYSTEM_TARGET === Config.OPERATING_SYSTEMS.MAC;
  }

  static get IS_WIN(): boolean {
    return Config.OPERATING_SYSTEM_TARGET === Config.OPERATING_SYSTEMS.WIN;
  }

  // current platform build target (defaults to web)
  static PLATFORM_TARGET: string = Config.PLATFORMS.WEB;

  // convenient platform check
  static get IS_DESKTOP(): boolean {
    return Config.PLATFORM_TARGET === Config.PLATFORMS.DESKTOP;
  }

  static get IS_MOBILE(): boolean {
    return Config.PLATFORM_TARGET === Config.PLATFORMS.MOBILE;
  }

  static get IS_WEB(): boolean {
    return Config.PLATFORM_TARGET === Config.PLATFORMS.WEB;
  }

  static get PLATFORM_STRING(): string {
    if (Config.IS_WEB) {
      return "web";
    }

    if (Config.IS_DESKTOP && Config.IS_WIN) {
      return "windows";
    }

    if (Config.IS_DESKTOP && Config.IS_MAC) {
      return "mac";
    }

    if (Config.IS_DESKTOP && Config.IS_LINUX) {
      return "linux";
    }

    return "impossible";
  }

  static ADMIN_API_URL: string = Config.API_URLS.PRODUCTION;
  static WS_SERVER: string = Config.WS_SERVERS.PRODUCTION;
  static VERSION = "";

  static get VERSION_NUMBER(): string {
    return Config.VERSION;
  }

  // debug mode helper functions
  static get IS_DEBUG_MODE(): boolean {
    for (const key of Object.keys(Config.DEBUG)) {
      if (Config.DEBUG[key]) {
        // if any level is on, debug mode is on
        return true;
      }
    }
    return false;
  }

  static RESET_DEBUG_MODE() {
    for (const key of Object.keys(Config.DEBUG)) {
      Config.DEBUG[key] = false;
    }
  }

  static get FIREBASE_ENVIRONMENT() {
    return Config.FIREBASE_ENV;
  }
}
