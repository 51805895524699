import { CallTargetType } from "../../../../interfaces/store/call-context";

export declare interface ApiWebCall {
  AccountId: string;
  CallData: string; // JSON string, need to parse
  Created: string; // dateTime ISO string
  HostCallId: string;
  HostCreated: string; // dateTime ISO string
  HostId: string;
  HostModified: string; // dateTime ISO string
  OrganizationId: string;
  Ouid: string;
  Target: string; // Configuration["target"]
  TargetType: string; // CallTargetType
  WebCallId: string;
}

export declare interface WebCall {
  accountId: string;
  callData: string;
  created: string;
  hostCallId: string;
  hostCreated: string;
  hostId: string;
  hostModified: string;
  organizationId: string;
  ouid: string;
  target: string;
  targetType: string;
  webCallId: string;
}

export function apiWebCallToWebCall(webCall: ApiWebCall): WebCall {
  return {
    accountId: webCall.AccountId,
    callData: webCall.CallData,
    created: webCall.Created,
    hostCallId: webCall.HostCallId,
    hostCreated: webCall.HostCreated,
    hostId: webCall.HostId,
    hostModified: webCall.HostModified,
    organizationId: webCall.OrganizationId,
    ouid: webCall.Ouid,
    target: webCall.Target,
    targetType: webCall.TargetType,
    webCallId: webCall.WebCallId
  };
}

export interface SaysoCallHistory {
  agent: string;
  audio: boolean;
  callerDisplayName: string;
  date: string;
  displayDate: string;
  duration?: number; // ms, ie 6000
  feedback: number; // 0-5
  hubspotutk: string | undefined;
  saysoCallFromLink: string | undefined;
  status: "answered" | "missed";
  time: string;
  targetType: CallTargetType;
  topicName: string;
  video: boolean;
  uuid: string;
  ouid: string;
}
