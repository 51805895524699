import { Injectable } from "@angular/core";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { ApiResourceService } from "../api-resource.service";
import { getApiActionName } from "../../onsip-api-action-new";
import { OnsipApiResponse } from "../../apiResponse/response-body-new";
import { CallSetupParams } from "./call-setup";

const debug = false;

@Injectable({ providedIn: "root" })
export class CallSetupService extends ApiResourceService<void> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "CallSetup", "" as never);
    debug && this.state.subscribe(state => console.warn("CallSetupService", state));
  }

  CallSetup(parameters: CallSetupParams): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.CallSetup,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...parameters
      }
    });
    return this.promiseState.toPromise(ApiAction.CallSetup);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.CallSetup:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
