<!-- start container section -->
@if (card) {
<mat-card>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</mat-card>
} @else {
<div>
  <ng-container *ngTemplateOutlet="content"></ng-container>
</div>
}
<!-- end container section -->
<ng-template #content>
  <mat-form-field [ngClass]="{ 'onsip-form-field-white': whiteText }">
    <mat-label>{{ placeholder }}</mat-label>
    <input
      #target
      matInput
      type="text"
      autocomplete="off"
      id="onsip-sip-address-input"
      [formControl]="formControl"
      [matAutocomplete]="auto"
      [target]="'newCall'"
      onsipDtmfEnabled
      [dtmfEnabled]="true"
    />
    <mat-autocomplete #auto="matAutocomplete">
      @for (contact of autocomplete | async; track contact) {
      <mat-option
        [value]="contact.aors[0] || (contact.e164PhoneNumbers && contact.e164PhoneNumbers[0]) || ''"
      >
        {{ contact.name }}
      </mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
</ng-template>
