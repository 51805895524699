import { LogService } from "../../../../common/services/logging";
import { CallAudioService } from "../call-audio.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { AnalyticsService } from "../../shared/components/analytics/analytics.service";
import { WebAudioService } from "../../shared/services/webAudio/web-audio.service";
import { UserAgentService } from "../../shared/components/userAgent/user-agent.service";
import { CallGroupService } from "../../shared/controller/call-group.service";
import { CallControllerService } from "../../../../common/services/call-controller.service";
import { CallTimerService } from "../../../../common/services/call-timer.service";
import { FirestoreCallService } from "../../../../common/services/sayso/firestore-call.service";
import { HubspotInfoService } from "../../shared/components/hubspot/services/hubspot-info.service";
import { VolumeService } from "../../shared/components/volume/volume.service";
import { ScreenShareService } from "../screen-share.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { views } from "../../../app/phone/views";

import { Component, Input, OnInit, OnDestroy, OnChanges } from "@angular/core";
import { CallState } from "../../../../common/libraries/sip/call-state";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { filter } from "rxjs/operators";

import { BaseCallComponent } from "../base-call.component";
import { AcceptedCallService } from "../accepted-call.service";
import { isConnectedCallEvent, isEndCallEvent } from "../../../../common/libraries/sip/call-event";
import { DialpadService } from "../../shared/components/dialpad/dialpad.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { Role } from "@onsip/common/services/api/role";

@Component({
  selector: "onsip-call-list-item",
  templateUrl: "./call-list-item.component.html",
  styleUrls: ["./call-list-item.component.scss"]
})
// eslint-disable-next-line rxjs-angular/prefer-composition
export class CallListItemComponent
  extends BaseCallComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() activeVisibleCallUuid: string | undefined;

  views: any = views;

  constructor(
    log: LogService,
    router: Router,
    callAudioService: CallAudioService,
    userAgentService: UserAgentService,
    callControllerService: CallControllerService,
    callTimerService: CallTimerService,
    screenShareService: ScreenShareService,
    callGroupService: CallGroupService,
    snackbarService: SnackbarService,
    analyticsService: AnalyticsService,
    webAudioService: WebAudioService,
    volumeService: VolumeService,
    acceptedCallService: AcceptedCallService,
    hubspotInfoService: HubspotInfoService,
    firestoreCallService: FirestoreCallService,
    translate: TranslateService,
    dialog: MatDialog,
    snackbar: MatSnackBar,
    dialpadService: DialpadService,
    private apiSession: ApiSessionService
  ) {
    super(
      log,
      router,
      callAudioService,
      userAgentService,
      callControllerService,
      callTimerService,
      acceptedCallService,
      screenShareService,
      callGroupService,
      snackbarService,
      analyticsService,
      webAudioService,
      volumeService,
      hubspotInfoService,
      firestoreCallService,
      dialog,
      snackbar,
      translate,
      dialpadService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    // Subscribe to Connected Call Event to finish accepted call service from sidebar
    this.unsubscriber.add(
      this.callControllerService
        .getCallEventObservable()
        .pipe(
          filter(
            event =>
              (isConnectedCallEvent(event) || isEndCallEvent(event)) &&
              !!this.currentCall &&
              event.uuid === this.currentCall.uuid
          )
        )
        .subscribe(() => {
          this.acceptedCallService.finishAcceptedCallState();
        })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(): void {
    this.genericOnChanges();
  }

  cancelTransfer(): void {
    super.cancelTransfer(false);
  }

  menuClosed(): void {
    // in case transfer is open, close when menu is closed
    if (!this.transferCall && this.isTransferring) this.cancelTransfer();
  }

  accept(isVideo: boolean = false): void {
    if (
      !this.currentCall ||
      this.acceptedCallService.hasConnectingAcceptedCall(this.currentCall.uuid)
    ) {
      return;
    }
    this.acceptedCallService.setAcceptedCall(this.currentCall.uuid);
    this.callAudioService.approveAudio();

    this.callControllerService.acceptCall(this.currentCall.uuid, { audio: true, video: isVideo });
  }

  triggerNavigation(call?: CallState): void {
    if (!call || call.ended) {
      return;
    }
    if (
      this.apiSession.stateValue.parentUserId &&
      this.apiSession.stateValue.loggedInRole !== Role.SuperUser
    ) {
      return;
    }
    this.router.navigate([views.CALL], {
      state: {
        skipLocationChange: true,
        replaceUrl: true,
        callUuid: call.uuid
      }
    });
  }
}
