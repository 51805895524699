import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { WarningModel } from "../queue-warnings.model";
import { QueueWarningsViewComponent } from "./view/queue-warnings-view.component";
import { QueueWarningsEditComponent } from "./edit/queue-warnings-edit.component";

@Component({
  selector: "onsip-queue-warnings-card",
  templateUrl: "./queue-warnings-card.component.html",
  standalone: true,
  imports: [QueueWarningsViewComponent, QueueWarningsEditComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueueWarningsCardComponent {
  @Input() warning!: WarningModel;
}
