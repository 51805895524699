import { ApiFullAddress, FullAddress, apiFullAddressToAddress } from "../../apiResponse/address";

export declare interface ApiGroupMemberAddress {
  Address: ApiFullAddress;
  Priority: string;
}

export declare interface GroupMemberAddress {
  address: FullAddress;
  priority: number;
}

export function apiGroupMemberAddressToGroupMemberAddress(
  apiGroupMemberAddress: ApiGroupMemberAddress
): GroupMemberAddress {
  return {
    address: apiFullAddressToAddress(apiGroupMemberAddress.Address),
    priority: parseInt(apiGroupMemberAddress.Priority)
  };
}
