import { Injectable, NgZone } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { User as FirestoreUser } from "../../libraries/firebase/store/user";
import { UserState, UserAvailability } from "../../libraries/firebase/store/user-state";

import { AuthService, AuthState } from "./auth.service";

export { UserState, UserAvailability };

@Injectable({ providedIn: "root" })
export class FirestoreAuthUserService extends FirestoreUser {
  private authState: AuthState | undefined;
  private _unsubscribe: Subject<void> = new Subject<void>();

  constructor(private auth: AuthService, private zone: NgZone) {
    super();
    this.subscribeAuth();
  }

  dispose(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
    super.dispose();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(() => {
      return fn();
    });
  }

  private signedIn(): void {
    if (!this.authState) throw new Error("auth state undefined");
    if (!this.authState.oid) throw new Error("auth state oid undefined");
    if (!this.authState.uid) throw new Error("auth state uid undefined");
    this.start(this.authState.oid, this.authState.uid);
  }

  private signedOut(): void {
    this.stop();
  }

  private subscribeAuth(): void {
    this.auth.state.pipe(takeUntil(this._unsubscribe)).subscribe(state => {
      const didChange = AuthService.didAuthStatusChange(this.authState, state);
      this.authState = state;
      switch (didChange) {
        case "signedIn":
          return this.signedIn();
        case "signedOut":
          return this.signedOut();
        default:
          return;
      }
    });
  }
}
