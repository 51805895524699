/**
 * Defines a Volume event.
 */
export interface VolumeEvent {
  id: string; // event id
  volume: number; // new volume
  uuid: string; // id of specific item (optional)
}

export class VolumeEventFactory {
  static make(id: string, volume: number, uuid: string): VolumeEvent {
    const event: VolumeEvent = {
      id,
      volume,
      uuid
    };
    return event;
  }
}
