export class OrganizationConfigurationValidationError extends Error {
  invalidProps: Array<string>;
  constructor(invalidProps: Array<string>) {
    super(
      `Organization Configuration failed validation.\nInvalid properties:\n${invalidProps.join(
        "\n"
      )}`
    );
    this.invalidProps = invalidProps;
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}
