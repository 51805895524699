import {
  ApiFullAddress,
  ApiFailoverAddress,
  FullAddress,
  FailoverAddress,
  apiFullAddressToAddress,
  apiFailoverAddressToAddress
} from "../../apiResponse/address";
import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";
import {
  ApiGroupMemberAddress,
  GroupMemberAddress,
  apiGroupMemberAddressToGroupMemberAddress
} from "./group-member";
import { ApiOrganization } from "../organization/organization";

export declare interface ApiGroupAddress {
  Address: ApiFullAddress;
  DefaultAddress: ApiFailoverAddress | ""; // DefaultAddress can be empty and is returned as an empty string
  GroupMemberAddresses: OnsipAPIArray<"GroupMemberAddress", ApiGroupMemberAddress>;
  Ordering: "parallel" | "sequential";
  Timeout: string;
}

export declare interface GroupAddress {
  /** HACK: ApiResourceService needs an index key, so we take the nested username from address */
  username: string;
  /** address object of the call group: contains its addressId, displayname, sip address, etc */
  address: FullAddress;
  /** address object of the sip address if call group fails to connect after a set time */
  defaultAddress?: FailoverAddress;
  /** list of all members' addresses in the call group */
  groupMemberAddresses: Array<GroupMemberAddress>;
  /** calling strategy of the call group. Parallel is Simultaneous Ring; sequential is Hunt group. */
  ordering: ApiGroupAddress["Ordering"];
  /** ring time until call goes to failover address */
  timeout: number;
}

export function apiGroupAddressToGroupAddress(apiGroupAddress: ApiGroupAddress): GroupAddress {
  const address = apiFullAddressToAddress(apiGroupAddress.Address);
  const groupAddress = {
    address,
    groupMemberAddresses: onsipApiArrayToArray(
      apiGroupAddress.GroupMemberAddresses,
      "GroupMemberAddress"
    ).map(apiGroupMemberAddressToGroupMemberAddress),
    ordering: apiGroupAddress.Ordering,
    timeout: parseInt(apiGroupAddress.Timeout)
  };
  return apiGroupAddress.DefaultAddress === ""
    ? { username: address.username, defaultAddress: undefined, ...groupAddress }
    : {
        username: address.username,
        ...groupAddress,
        defaultAddress: apiFailoverAddressToAddress(apiGroupAddress.DefaultAddress)
      };
}

/** Adding and editing entire groups will also result in an organization object */
export declare interface ApiGroupAddressWithOrganization {
  GroupAddress: ApiGroupAddress;
  Organization: ApiOrganization;
}
