import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { PortalModule } from "@angular/cdk/portal";

import { SpacerModule } from "../../spacer/spacer.module";

import { HeaderMainComponent } from "./header-main/header-main.component";
import { HeaderPageTitleComponent } from "./page-title/header-page-title.component";
import { HeaderActionBarComponent } from "./action-bar/header-action-bar.component";
import { HeaderBreadcrumbContainerComponent } from "./breadcrumbs/header-breadcrumb-container.component";
import { HeaderBreadcrumbDirective } from "./breadcrumbs/header-breadcrumb.directive";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    PortalModule,
    SpacerModule
  ],
  declarations: [
    HeaderMainComponent,
    HeaderPageTitleComponent,
    HeaderActionBarComponent,
    HeaderBreadcrumbDirective,
    HeaderBreadcrumbContainerComponent
  ],
  exports: [
    HeaderMainComponent,
    HeaderPageTitleComponent,
    HeaderActionBarComponent,
    HeaderBreadcrumbDirective,
    HeaderBreadcrumbContainerComponent
  ]
})
export class HeaderModule {}
