@if (debug) {
<div id="debug"></div>
} @if (hasParentUserId) {
<onsip-super-admin-toolbar></onsip-super-admin-toolbar>
}
<mat-toolbar>
  <div class="onsip-left-nav">
    @if (isSmall | async) {
    <button
      id="onsip--phone--sidenav-toggle"
      aria-label="toggle sidenav"
      mat-icon-button
      (click)="sidenav.toggle()"
    >
      <mat-icon>menu</mat-icon>
    </button>
    }
    <img
      id="onsip-logo"
      [src]="'resources/img/onsip-blue.svg'"
      [routerLink]="views.HOME"
      alt="OnSIP"
    />
  </div>
  <span class="spacer"></span>
  <nav
    mat-tab-nav-bar
    aria-label="onsip-navigation-bar"
    id="onsip-phone-menu-nav-bar"
    onsip-no-chervon
    color="primary"
    headerPosition="below"
    animationDuration="0ms"
    [tabPanel]="tabPanel"
  >
    @if (notSpoofingAgents) {
    <a
      id="onsip--phone--nav-home"
      [attr.data-cy]="E2eLocators.NAV_HOME"
      mat-tab-link
      aria-label="My Dashboard"
      [active]="currentView === views.HOME"
      [routerLink]="views.HOME"
    >
      <span
        onsip-badge-medium-font
        [matBadge]="msgCount"
        [matBadgeHidden]="!msgCount"
        matBadgeSize="small"
        matBadgeColor="warn"
        matBadgeOverlap="false"
      >
        {{ "ONSIP_I18N.MY_DASHBOARD" | translate | sentenceCase }}
      </span>
    </a>
    } @if (hasAdvancedQueues && showQueueDashboard && notSpoofingAgents) {
    <a
      id="onsip--phone-nav-queue-dashboard"
      mat-tab-link
      aria-label="Queue Dashboard"
      [active]="currentView === views.QUEUE_DASHBOARD"
      [routerLink]="views.QUEUE_DASHBOARD"
    >
      <span>{{ "ONSIP_I18N.QUEUE_DASHBOARD" | translate | sentenceCase }}</span>
    </a>
    }
    <!-- queue upsell -->
    @if (!hasAdvancedQueues && showAccountAdminTabs && !isAppStore && notSpoofingAgents) {
    <a
      id="onsip--phone-nav-queue-dashboard"
      mat-tab-link
      aria-label="Queue Dashboard"
      [active]="currentView === views.CALL_MANAGER"
      [routerLink]="views.CALL_MANAGER"
    >
      <span>{{ "ONSIP_I18N.QUEUE_DASHBOARD" | translate | sentenceCase }}</span>
    </a>
    } @if (showOrgAdminTabs && notSpoofingAgents) {
    <a
      id="onsip--phone-nav-sayso"
      mat-tab-link
      aria-label="sayso"
      [active]="isOnSaysoNavTab"
      #menuTrigger="matMenuTrigger"
      [matMenuTriggerFor]="saysoMenu"
    >
      <span>{{ "ONSIP_I18N.SAYSO" | translate }}</span>
    </a>
    } @if (showAccountAdminTabs && !isDesktop && notSpoofingAgents) {
    <a
      id="onsip--phone-nav-administrators"
      mat-tab-link
      aria-label="administrators"
      [attr.data-cy]="E2eLocators.NAV_ADMINS"
      [active]="isOnAdministratorsNavTab"
      [routerLink]="views.ADMIN_PBX_OVERVIEW"
    >
      <span>{{ "ONSIP_I18N.ADMINISTRATORS" | translate }}</span>
    </a>
    }
  </nav>
  <onsip-status-menu></onsip-status-menu>
  <button
    mat-icon-button
    id="onsip-phone-menu"
    #overflowMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
  >
    <mat-icon
      color="primary"
      onsip-badge-medium-font
      [matBadge]="msgCount"
      [matBadgeHidden]="!msgCount"
      matBadgeSize="small"
      matBadgeColor="warn"
    >
      more_vert
    </mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <ng-template matMenuContent>
      <button id="onsip-phone-menu-home" mat-menu-item [routerLink]="views.HOME">
        @if (notSpoofingAgents) {
        <span
          onsip-badge-medium-font
          [matBadge]="msgCount"
          [matBadgeHidden]="!msgCount"
          matBadgeSize="small"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "ONSIP_I18N.MY_DASHBOARD" | translate | sentenceCase }}
        </span>
        }
      </button>
      @if (hasAdvancedQueues && showQueueDashboard && notSpoofingAgents) {
      <button
        id="onsip-phone-menu-queue-dashboard"
        mat-menu-item
        [routerLink]="views.QUEUE_DASHBOARD"
      >
        <span>{{ "ONSIP_I18N.QUEUE_DASHBOARD" | translate | sentenceCase }}</span>
      </button>
      } @if (showOrgAdminTabs && notSpoofingAgents) {
      <button
        id="onsip-phone-menu-sayso"
        mat-menu-item
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="saysoMenu"
      >
        <span>{{ "ONSIP_I18N.SAYSO" | translate }}</span>
      </button>
      } @if (showAccountAdminTabs && !isDesktop && notSpoofingAgents) {
      <button
        id="onsip--phone-menu-administrators"
        mat-menu-item
        [routerLink]="views.ADMIN_PBX_OVERVIEW"
      >
        <span>{{ "ONSIP_I18N.ADMINISTRATORS" | translate }}</span>
      </button>
      }
    </ng-template>
  </mat-menu>
  <mat-menu #saysoMenu="matMenu">
    <ng-template matMenuContent>
      <button
        id="onsip--phone--sayso-dashboard"
        mat-menu-item
        [routerLink]="views.MANAGER_DASHBOARD"
      >
        <span>{{ "ONSIP_I18N.SAYSO_DASHBOARD_1" | translate }}</span>
      </button>
      <button
        id="onsip--phone--sayso-call-history"
        mat-menu-item
        [routerLink]="views.ORG_CALL_HISTORY"
      >
        <span>{{ "ONSIP_I18N.SAYSO_CALL_HISTORY" | translate }}</span>
      </button>
      @if (showSaysoAnalytics) {
      <button id="onsip--phone--sayso-analytics" mat-menu-item [routerLink]="views.SAYSO_DASHBOARD">
        <span>{{ "ONSIP_I18N.SAYSO_ANALYTICS" | translate }}</span>
      </button>
      }
      <button id="onsip--phone--sayso-settings" mat-menu-item [routerLink]="views.SAYSO_SETTINGS">
        <span>{{ "ONSIP_I18N.SAYSO_SETTINGS_1" | translate }}</span>
      </button>
      <button id="onsip--phone--sayso-team-page" mat-menu-item [routerLink]="views.TEAM_PAGE">
        <span>{{ "ONSIP_I18N.SAYSO_TEAM_PAGE" | translate }}</span>
      </button>
    </ng-template>
  </mat-menu>
</mat-toolbar>
<onsip-warning-bar></onsip-warning-bar>
<mat-sidenav-container cdkDropListGroup>
  <mat-sidenav
    #sidenav
    [ngClass]="{ 'admin-side-nav': isOnAdministratorsNavTab }"
    onsip-sidenav-overflow
    [(opened)]="sidenavOpened"
    (opened)="sidenavOpened = true"
    (closed)="sidenavOpened = false"
    [disableClose]="(isSmall | async) === false"
    [mode]="(isSmall | async) ? 'over' : 'side'"
  >
    @if (!isOnAdministratorsNavTab) {
    <div class="sidenav">
      <onsip-call-list></onsip-call-list>
      @if (!startingCall) {
      <span class="start-call-container">
        <button
          class="start-call"
          color="primary"
          id="onsip--phone--sidenav-start-new-call"
          mat-flat-button
          #tooltip="matTooltip"
          [matTooltip]="'ONSIP_I18N.CLICK_BELOW_TO_START_A_CALL' | translate"
          matTooltipDisabled="true"
          (mouseenter)="$event.stopImmediatePropagation()"
          matTooltipPosition="above"
          matTooltipClass="onsip-tooltip-background"
          (click)="handleStartCallComponent('newCall')"
          [attr.data-cy]="E2eLocators.CALL_START_NEW_BTN"
          [innerHtml]="'ONSIP_I18N.START_NEW_CALL' | translate"
        ></button>
        <a
          id="onsip--phone--start-call-menu-new-conference"
          class="onsip-start-conference-link"
          (click)="handleStartCallComponent('conferenceCall')"
        >
          {{ "ONSIP_I18N.OR_START_A_VIDEO_CONFERENCE" | translate }}
        </a>
      </span>
      } @if (startingCall) {
      <div class="start-call-opened-container">
        @if (currentCall === 'newCall') {
        <div class="onsip-new-call-sidebar">
          <span class="start-call-title-bar">
            <h3 [innerHtml]="'ONSIP_I18N.NEW_CALL' | translate"></h3>
            <button
              id="onsip--phone--sidenav-new-call-cancel"
              mat-button
              class="primary-500"
              (click)="handleStartCallComponent('none')"
              [innerHtml]="'ONSIP_I18N.CANCEL' | translate"
            ></button>
          </span>
          <onsip-new-call (outputStartedCall)="newCallClosed.next()"></onsip-new-call>
        </div>
        } @if (currentCall === 'conferenceCall') {
        <div class="onsip-conference-call-sidebar">
          <span class="start-call-title-bar">
            <h3 [innerHtml]="'ONSIP_I18N.VIDEO_CONFERENCE' | translate"></h3>
            <button
              id="onsip--phone--sidenav-new-conference-cancel"
              mat-button
              class="primary-500"
              (click)="handleStartCallComponent('none')"
              [innerHtml]="'ONSIP_I18N.CANCEL' | translate"
            ></button>
          </span>
          <onsip-new-conference
            [sidebar]="true"
            (outputStartedCall)="newCallClosed.next()"
          ></onsip-new-conference>
        </div>
        }
      </div>
      }
      <div class="add-contact-button-container">
        <button
          mat-button
          class="add-contact-option primary-500"
          [attr.data-cy]="E2eLocators.CONTACTS_ADD_BTN"
          cdkOverlayOrigin
          #addContact="cdkOverlayOrigin"
        >
          {{ "ONSIP_I18N.ADD" | translate }}
        </button>
        <onsip-popup-container [sidebar]="true" [overlayOrigin]="addContact">
          <onsip-contact-list-edit></onsip-contact-list-edit>
        </onsip-popup-container>
      </div>
      <onsip-contact-list></onsip-contact-list>
    </div>
    } @if (isOnAdministratorsNavTab) {
    <div>
      <onsip-call-list></onsip-call-list>
      <onsip-org-side-nav></onsip-org-side-nav>
    </div>
    }
  </mat-sidenav>
  <mat-sidenav-content #mainContent>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
    <onsip-free-trial-container></onsip-free-trial-container>
    <onsip-footer [showAdminTabs]="showAccountAdminTabs"></onsip-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
