import { Injectable } from "@angular/core";
import { withLatestFrom, lastValueFrom, Observable, OperatorFunction } from "rxjs";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";
import { ApiResourceService } from "../api-resource.service";
import {
  ApiAdvVoicemailbox,
  apiAdvVoicemailboxToAdvVoicemailbox as clean,
  AdvVoicemailbox
} from "./adv-voicemailbox";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiSessionService } from "../../api-session.service";
import {
  ApiBrowseAction,
  ApiAddAction,
  ApiEditAction,
  ApiReadAction,
  ApiDeleteAction,
  ApiAction,
  ApiActionType
} from "../../api-actions";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { ParameterValue } from "../../util/api-action-description";
import { apiVoicemailToVoicemail } from "./voicemail";
import { arrayToRecord } from "../../util/arrayToRecord";
import { sessionId } from "../../apiParams/session-id";
import { organizationId } from "../../apiParams/organization-id";
import { userId } from "../../apiParams/user-id";
import { primaryUserAddress } from "../../apiParams/primary-user-address";

import { Config } from "../../../../config";
// @ts-ignore: this dep is untyped, so ts yells at us because it can't find any declarations
import { fetch as polyfillFetch } from "whatwg-fetch";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { AdvVoicemailbox };
declare const polyfillFetch: any; // this dep is untyped- tell typescript it's an any

const debug = false;

@Injectable({ providedIn: "root" })
export class VoicemailService extends ApiResourceService<AdvVoicemailbox> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "AdvVoicemailbox", "advVoicemailboxId");
    debug && this.state.subscribe(state => console.warn("VoicemailService.state", state));
    debug &&
      this.selfUserVoicemailbox.subscribe(vmb => {
        console.warn("VoicemailService.selfUserVoicemailbox", vmb);
      });
    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(state => {
        this.dispose();
        if (state.loggedIn && !!state.sessionId) {
          this.advVoicemailboxBrowseWithUserId();
        }
      });
  }

  /** public getter to get a filtered observable with just the self user's voicemailbox if it exists */
  get selfUserVoicemailbox(): Observable<AdvVoicemailbox | undefined> {
    return this.state.pipe(
      filter(({ loading }) => !loading),
      withLatestFrom(this.store.state.pipe(primaryUserAddress())),
      map(([{ state }, userAddress]) => {
        const voicemailboxes = Object.values(state);
        return voicemailboxes.find(
          voicemailbox => voicemailbox.addressId === userAddress.defaultAddress?.addressId
        );
      })
    );
  }

  advVoicemailboxBrowseWithUserId(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxBrowse");
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AdvVoicemailboxBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Limit: 1000,
        Orderby: "AdvVoicemailboxId",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AdvVoicemailboxBrowse);
  }

  advVoicemailboxBrowseWithOrgId(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxBrowse");
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AdvVoicemailboxBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 1000,
        Orderby: "AdvVoicemailboxId",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AdvVoicemailboxBrowse);
  }

  advVoicemailboxEdit(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxEdit");
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AdvVoicemailboxEdit,
        SessionId: this.store.state.pipe(sessionId()),
        AdvVoicemailboxId: extraParameters?.AdvVoicemailboxId
          ? extraParameters?.AdvVoicemailboxId
          : this.selfUserVoicemailbox.pipe(selfAdvVoicemailboxId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AdvVoicemailboxEdit);
  }

  advVoicemailboxAdd(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxAdd");
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.AdvVoicemailboxAdd,
        OrganizationId: this.store.state.pipe(organizationId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAddAction.AdvVoicemailboxAdd);
  }

  advVoicemailboxRead(
    AdvVoicemailboxId?: string,
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxRead");
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.AdvVoicemailboxRead,
        SessionId: this.store.state.pipe(sessionId()),
        AdvVoicemailboxId: AdvVoicemailboxId
          ? AdvVoicemailboxId
          : this.selfUserVoicemailbox.pipe(selfAdvVoicemailboxId()),
        Limit: 10,
        Orderby: "AdvVoicemailboxId",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiReadAction.AdvVoicemailboxRead);
  }

  /**
   * @param name - UnavailableGreeting, NameGreeting, or TempGreeting
   * @param advVoicemailboxId - required when uploading greeting for other vmbox - admin only
   * @param file - uploaded greeting file, deletes the current if this is empty
   */
  async advVoicemailboxGreetingUpload(
    name: "UnavailableGreeting" | "NameGreeting" | "TempGreeting",
    advVoicemailboxId: string,
    file?: File | Blob
  ): ApiPromiseState<AdvVoicemailbox> {
    let action: ApiActionType;
    switch (name) {
      case "UnavailableGreeting":
        action = ApiAction.AdvVoicemailboxUnavailableGreetingUpload;
        break;
      case "NameGreeting":
        action = ApiAction.AdvVoicemailboxNameGreetingUpload;
        break;
      case "TempGreeting":
        action = ApiAction.AdvVoicemailboxTempGreetingUpload;
        break;
    }
    const fetchResult = await lastValueFrom(this.store.state.pipe(sessionId(), take(1))).then(
      sessionId => {
        const actionUrl = Config.ADMIN_API_URL + "?Action=" + action;
        const formData = new FormData();
        let blob: Blob | "";
        if (!file) {
          blob = "";
        } else if (file instanceof Blob) {
          blob = file;
        } else {
          blob = new Blob([file], { type: "audio/wav" });
        }
        formData.append(name, blob || "");
        formData.append("SessionId", sessionId || "");
        formData.append("AdvVoicemailboxId", advVoicemailboxId);
        formData.append("Type", "audio/wav");
        formData.append("Output", "json");

        return (fetch || polyfillFetch)(actionUrl, { method: "POST", body: formData });
      }
    );
    const res = await fetchResult.json();
    // we want to return any error that is coming out of the greeting upload api so check for the errors object
    const errors = res.Response.Context.Request.Errors;
    if (errors) {
      const data = {
        code: errors.Error.Code,
        message: errors.Error.Message,
        parameter: errors.Error.Parameter,
        action: action,
        Response: res.Response as OnsipApiResponse
      };
      return {
        status: "error",
        action,
        data
      };
    } else {
      // if there are no errors, we can update the service state with a voicemail read
      return this.advVoicemailboxRead(advVoicemailboxId);
    }
  }

  /** deletes the voicemail box including all of its voicemails */
  advVoicemailboxDelete(AdvVoicemailboxId: string): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxDelete");
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.AdvVoicemailboxDelete,
        SessionId: this.store.state.pipe(sessionId()),
        AdvVoicemailboxId
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.AdvVoicemailboxDelete);
  }

  // ---- The following edit individual voicemails within a Voicemailbox Inbox ---- //

  advVoicemailboxInboxDelete(
    metadataId: string,
    extraParameters: Record<string, ParameterValue> = {},
    advVoicemailboxId?: string
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxInboxDelete");
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.AdvVoicemailboxInboxDelete,
        SessionId: this.store.state.pipe(sessionId()),
        MetadataId: metadataId,
        AdvVoicemailboxId: advVoicemailboxId
          ? advVoicemailboxId
          : this.selfUserVoicemailbox.pipe(selfAdvVoicemailboxId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.AdvVoicemailboxInboxDelete);
  }

  advVoicemailboxInboxDeleteAll(
    extraParameters: Record<string, ParameterValue> = {},
    advVoicemailboxId?: string
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxInboxDeleteAll");
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.AdvVoicemailboxInboxDeleteAll,
        AdvVoicemailboxId: advVoicemailboxId
          ? advVoicemailboxId
          : this.selfUserVoicemailbox.pipe(selfAdvVoicemailboxId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.AdvVoicemailboxInboxDeleteAll);
  }

  advVoicemailboxInboxEdit(
    metadataId: string,
    extraParameters: Record<string, ParameterValue> = {},
    advVoicemailboxId?: string
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxInboxEdit");
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AdvVoicemailboxInboxEdit,
        MetadataId: metadataId,
        AdvVoicemailboxId: advVoicemailboxId
          ? advVoicemailboxId
          : this.selfUserVoicemailbox.pipe(selfAdvVoicemailboxId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AdvVoicemailboxInboxEdit);
  }

  // Note: unused in app
  advVoicemailboxInboxRead(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<AdvVoicemailbox> {
    debug && console.warn("VoicemailService.advVoicemailboxInboxRead");
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.AdvVoicemailboxInboxRead,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiReadAction.AdvVoicemailboxInboxRead);
  }

  getVoicemailUrl(AdvVoicemailboxId: string, MetadataId: string): string {
    let SessionId;
    this.store.state.pipe(sessionId(), take(1)).subscribe(sessionId => {
      SessionId = sessionId;
    });
    const params = {
      AdvVoicemailboxId,
      MetadataId,
      SessionId,
      Action: "AdvVoicemailboxInboxFileRead"
    };
    let urlString: string = Config.ADMIN_API_URL + "?";

    Object.keys(params).forEach((key, idx) => {
      urlString += (idx > 0 ? "&" : "") + key + "=" + (params as any)[key];
    });

    return urlString;
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    debug && console.warn("VoicemailService.reducer");

    let params = response.Context.Request.Parameters?.Parameter || [];
    if (!Array.isArray(params)) params = [params];
    const mailboxId = params.find(param => param.Name === "AdvVoicemailboxId")?.Value;
    const messageId = params.find(param => param.Name === "MetadataId")?.Value;

    switch (action) {
      case ApiBrowseAction.AdvVoicemailboxBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiAdvVoicemailbox>>(
              response,
              action,
              "AdvVoicemailbox",
              "AdvVoicemailboxes"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiEditAction.AdvVoicemailboxEdit:
      case ApiReadAction.AdvVoicemailboxRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiAdvVoicemailbox>(response, action, "AdvVoicemailbox"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAddAction.AdvVoicemailboxAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiAdvVoicemailbox>(response, action, "AdvVoicemailbox"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.AdvVoicemailboxInboxDelete:
        // find the correct mailbox and delete the specific vm
        if (mailboxId && messageId) {
          this.store.mergeStateUpdate(
            this.resourceName,
            {
              [mailboxId]: {
                voicemail: {
                  inbox: {
                    voicemail: {
                      [messageId]: {
                        folder: "DELETED"
                      }
                    }
                  }
                }
              }
            },
            action
          );
        }
        break;
      case ApiDeleteAction.AdvVoicemailboxInboxDeleteAll:
        if (mailboxId) {
          const updatedVMs =
            this.store.stateValue.AdvVoicemailbox.state[mailboxId as string].voicemail.inbox
              .voicemail;
          Object.keys(updatedVMs).forEach(vmKey => {
            updatedVMs[vmKey].folder = "DELETED";
          });
          this.store.mergeStateUpdate(
            this.resourceName,
            {
              [mailboxId]: {
                voicemail: {
                  inbox: {
                    voicemail: updatedVMs
                  }
                }
              }
            },
            action
          );
        }
        break;
      case ApiEditAction.AdvVoicemailboxInboxEdit:
        // find the correct mailbox and then vm and replace vm
        if (!!mailboxId && !!messageId) {
          this.store.mergeStateUpdate(
            this.resourceName,
            {
              [mailboxId]: {
                voicemail: {
                  inbox: {
                    voicemail: {
                      [messageId]: apiVoicemailToVoicemail(
                        (response.Result as any).AdvVoicemailboxInboxEdit.Voicemail
                      )
                    }
                  }
                }
              }
            },
            action
          );
        }
        break;
      case ApiReadAction.AdvVoicemailboxInboxRead:
        // TODO: unused in app
        break;
      case ApiEditAction.UserAddressEditDefaultAddress:
        this.advVoicemailboxBrowseWithUserId(); // TODO dont make API calls in the reducer
        break;
      case ApiDeleteAction.AdvVoicemailboxDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deleted = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
          param => param.Name === "AdvVoicemailboxId"
        )?.Value;
        deleted && this.store.mergeStateUpdate(this.resourceName, { [deleted]: undefined }, action);
    }
  }
}

function selfAdvVoicemailboxId(): OperatorFunction<AdvVoicemailbox | undefined, string> {
  return selfUserVoicemailbox =>
    selfUserVoicemailbox.pipe(
      map(vmb => vmb?.advVoicemailboxId),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}
