export function isObject(item: any): boolean {
  return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep<T extends Record<string, any>>(target: T, source: Partial<T>): T {
  const output: T = Object.assign({}, target);
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          Object.assign(output, { [key]: source[key] });
        } else {
          //@ts-ignore
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(output, { [key]: source[key] });
      }
    });
  }
  return output;
}
