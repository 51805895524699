import { Injectable } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { filter, debounceTime, pluck, distinctUntilChanged, take } from "rxjs/operators";

import { EventStateEmitter } from "../../../common/libraries/emitter";

import { URI, Registerer, UserAgentOptions, Web } from "sip.js";
import { OutgoingRequestMessage } from "sip.js/lib/core";
import { NewSessionEvent } from "../../../common/libraries/sip/session-event";
import { Call, makeCall } from "../../../common/libraries/sip/call";
import { CallState } from "../../../common/libraries/sip/call-state";
import { CallContact } from "../../../common/libraries/sip/call-contact";
import { SessionSIP } from "../../../common/libraries/sip/session-sip";
import {
  CallEvent,
  isConnectedCallEvent,
  isEndCallEvent
} from "../../../common/libraries/sip/call-event";
import { UserAgent, UserAgentDelegate } from "../../../common/libraries/sip/user-agent";
import { CallConfiguration } from "../../../common/libraries/sip/call-configuration";
import { SessionDescriptionHandler } from "../shared/controller/session-description-handler";
import {
  RegisteredUserAgentEvent,
  ConnectedUserAgentEvent
} from "../../../common/libraries/sip/user-agent-event";

import { SdhFactoryHelperService } from "../shared/controller/sdh-factory-helper.service";
import { IdentityService } from "../../../common/services/identity.service";
import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { TranslateService } from "@ngx-translate/core";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";
import { Config } from "../../../common/config";

interface AttendeeInfo {
  aor: string;
  authUsername: string;
  authPassword: string;
  displayName: string;
}

interface ConferenceInfo {
  fromDisplay: string | undefined;
  conferenceName: string;
  conferenceUri: OnSIPURI | string; // string in case of anonymous call
  mediaStream: MediaStream | undefined;
}

interface VideoConferenceDataEvent {
  anonymous: boolean | undefined;
  localDisplayName: string;
  conferenceName: string;
  conferenceUri: URI | string | undefined;
  userAgent: UserAgent | undefined;
  mediaStream: MediaStream | undefined;
}

/** status code for different UI states of conference
 *  anonymmous - anonymous page
 *  hold - on hold
 *  empty-host - user is hosting conference and call is empty
 *  setup - general case - this could be fleshed out into other cases as necessary
 */
export type VideoConferenceStatus = "anonymous" | "empty-host" | "hold" | "setup";
export interface VideoConferenceState {
  calls: Array<CallState>;
  connected: boolean;
  connectedAt: number | undefined;
  hold: boolean;
  isAnonymous: boolean | undefined;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  newVideoConferenceState: "open" | "switch" | "close";
  remoteDisplayName: string | undefined;
  remoteUri: string | undefined;
  status: VideoConferenceStatus;
}

@Injectable({ providedIn: "root" })
export class VideoConferenceService extends EventStateEmitter<
  VideoConferenceDataEvent,
  VideoConferenceState
> {
  private anonymous: boolean | undefined;
  private attendeeInfo: AttendeeInfo | undefined;
  private callsMax = 0;
  private confAor = "";
  private conferenceInfo: ConferenceInfo | undefined;
  private conferenceUA: UserAgent | undefined;
  private conferenceStartSubscription: Subscription | undefined;
  private maxConferenceCalls = 6;
  private regInterval: any;
  private videoConferenceCalls: Array<Call> = [];

  static initialEvent(): VideoConferenceDataEvent {
    return {
      anonymous: undefined,
      localDisplayName: "",
      conferenceName: "",
      conferenceUri: undefined,
      userAgent: undefined,
      mediaStream: undefined
    };
  }

  static initialState(): VideoConferenceState {
    return {
      calls: [],
      connected: false,
      connectedAt: undefined,
      hold: false,
      isAnonymous: undefined,
      isAudioMuted: false,
      isVideoMuted: false,
      newVideoConferenceState: "close",
      remoteDisplayName: undefined,
      remoteUri: undefined,
      status: "setup"
    };
  }

  constructor(
    private identityService: IdentityService,
    private analyticsService: AnalyticsService,
    private callControllerService: CallControllerService,
    private sdhFactoryHelperService: SdhFactoryHelperService,
    private translate: TranslateService
  ) {
    super(VideoConferenceService.initialState());
    const debounceDuration = 500;
    this.callControllerService.state
      .pipe(
        debounceTime(debounceDuration),
        filter(() => !!this.callControllerService.getUnheldCall())
      )
      .subscribe(() => {
        if (this.stateValue.hold) {
          return;
        } else if (this.stateValue.connected && !this.stateValue.hold) {
          this.hold();
        }
      });
  }

  /** Return an i18n translation for a given VideoConference Status */
  getStatusTranslation(status: VideoConferenceStatus): string {
    switch (status) {
      case "anonymous":
        return this.translate.instant("ONSIP_I18N.WAITING_FOR_THE_ORGANIZER_TO_JOIN");
      case "empty-host":
        return this.translate.instant("ONSIP_I18N.NO_ONES_HERE_YET");
      case "hold":
        return this.translate.instant("ONSIP_I18N.ON_HOLD");
      case "setup":
        return this.translate.instant("ONSIP_I18N.SETTING_UP");
      default:
        return "";
    }
  }

  startVideoConference(): void {
    this.stateStore = {
      calls: [],
      connected: true,
      connectedAt: Date.now(),
      hold: false,
      isAnonymous: this.anonymous,
      isAudioMuted: false,
      isVideoMuted: false,
      newVideoConferenceState: "open",
      remoteDisplayName: this.conferenceInfo && this.conferenceInfo.conferenceName,
      remoteUri: this.confAor,
      status: "setup"
    };
    this.setStatus();
  }

  endVideoConference(): void {
    if (!this.stateStore.connected) {
      return;
    }

    if (!this.anonymous && this.conferenceInfo && this.attendeeInfo) {
      this.analyticsService.sendCallEvent("Conference - Leave", undefined, {
        video: true,
        remoteAddress: (this.conferenceInfo.conferenceUri as OnSIPURI).aor,
        localAddress: this.attendeeInfo.aor,
        conference: true,
        conferenceSize: this.callsMax + 1 || 1,
        sizeOnExit: this.stateValue.calls.length + 1
      });
    }

    if (this.conferenceInfo) {
      this.conferenceInfo.mediaStream &&
        this.conferenceInfo.mediaStream.getTracks().forEach(track => {
          track.stop();
        });

      delete this.conferenceInfo;
    }

    this.stateStore = VideoConferenceService.initialState();
    this.callsMax = 0;
    // slice is due to the array changing length as end is called, without it we'd skip every other
    this.videoConferenceCalls.slice().forEach(call => call.end());
    if (this.conferenceUA) {
      this.conferenceUA.stop();
    }
    this.publishState();

    this.closeNewVideoConferenceData();
  }

  hasVideoConference(): boolean {
    return this.stateStore.connected;
  }

  getCallObservableFilteredByEnd(uuid: string): Observable<CallEvent> | undefined {
    const callFound = this.videoConferenceCalls.find(call => call.uuid === uuid);

    return callFound ? callFound.event.pipe(filter(isEndCallEvent), take(1)) : undefined;
  }

  getCallObservableOnAudioAvailChange(uuid: string): Observable<boolean> | undefined {
    const callFound = this.videoConferenceCalls.find(call => call.uuid === uuid);

    return callFound
      ? callFound.session.state.pipe(pluck("audioAvailable"), distinctUntilChanged())
      : undefined;
  }

  getConferenceLink(): string {
    const confName = this.conferenceInfo ? this.conferenceInfo.conferenceName : "Video Conference";
    return (
      "https://app.onsip.com/app/conference/?n=" +
      encodeURIComponent(confName) +
      "&a=" +
      encodeURIComponent(this.confAor)
    );
  }

  getVideoConferenceName(): string {
    if (this.stateStore.remoteDisplayName) {
      return this.stateStore.remoteDisplayName;
    } else {
      throw new Error("Cannot find current video conference name");
    }
  }

  getConferenceMediaStream(): MediaStream | undefined {
    return this.conferenceInfo ? this.conferenceInfo.mediaStream : undefined;
  }

  getPeerConnection(uuid: string): RTCPeerConnection | undefined {
    const vidConfCall: Call | undefined = this.videoConferenceCalls.find(
      call => call.uuid === uuid
    );

    if (vidConfCall && vidConfCall.ended) {
      return;
    } else if (vidConfCall && vidConfCall.connected && vidConfCall.session instanceof SessionSIP) {
      return (vidConfCall.session.SIP.sessionDescriptionHandler as SessionDescriptionHandler)
        .peerConnection;
    }
  }

  getNewVideoConferenceState(): VideoConferenceState["newVideoConferenceState"] {
    return this.stateStore.newVideoConferenceState;
  }

  addNewVideoConferenceData(data: VideoConferenceDataEvent): void {
    this.identityService.restoreOutboundIdentity();

    if (!data.userAgent || !this.callControllerService.isUserAgentSIPInstance(data.userAgent)) {
      throw new Error("conference page: attendee UA passed is not a UserAgentSIP");
    }

    this.anonymous = data.anonymous;

    this.attendeeInfo = {
      aor: data.userAgent.aor,
      authUsername: data.userAgent.SIP.configuration.authorizationUsername,
      authPassword: data.userAgent.SIP.configuration.authorizationPassword,
      displayName: data.userAgent.displayName
    };

    this.conferenceInfo = {
      fromDisplay: data.localDisplayName,
      conferenceName: data.conferenceName,
      conferenceUri:
        typeof data.conferenceUri === "string"
          ? data.conferenceUri
          : (data.conferenceUri as OnSIPURI),
      mediaStream: data.mediaStream
    };

    if (typeof this.conferenceInfo.conferenceUri === "string") {
      this.confAor = this.conferenceInfo.conferenceUri.replace(/\s/g, "");
    } else {
      this.confAor = (this.conferenceInfo.conferenceUri as OnSIPURI).aor.replace(/\s/g, "");
    }

    if (!data.anonymous) {
      this.analyticsService.sendCallEvent("Conference - Join", undefined, {
        video: true,
        remoteAddress: (this.conferenceInfo.conferenceUri as OnSIPURI).aor,
        localAddress: data.userAgent.aor,
        conference: true
      });
    }

    this.publishEvent(data);
  }

  switchingNewVideoConferenceState(): void {
    this.stateStore.newVideoConferenceState = "switch";
    this.publishState();
  }

  makeAndAddUserAgent(addToCallController: boolean): void {
    if (!this.attendeeInfo || !this.conferenceInfo) {
      throw new Error("attendee or conference info not set, can not make UA without them");
    }

    const options: Partial<UserAgentOptions> = {
        authorizationUsername: this.attendeeInfo.authUsername,
        authorizationPassword: this.attendeeInfo.authPassword,
        displayName: "X-Conf " + this.conferenceInfo.fromDisplay || this.attendeeInfo.displayName,
        userAgentString: "OnSIP_App/" + Config.VERSION_NUMBER + "/" + Config.PLATFORM_STRING
      },
      registerOptions = { expires: 60 },
      aor = this.attendeeInfo.aor,
      sdhFactory = this.sdhFactoryHelperService.createFactory(),
      delegate: UserAgentDelegate = {
        handleIncomingSession: incomingSession => {
          const callConfig: CallConfiguration = {
            audio: true,
            video: true
          };

          const call: Call = new Call(incomingSession, undefined, callConfig);

          call.session.event
            .pipe(
              filter(e => e.id === NewSessionEvent.id),
              take(1)
            )
            .subscribe(() => {
              if (
                call.session instanceof SessionSIP &&
                !call.session.SIP.request.hasHeader("X-Conf")
              ) {
                return;
              }

              Promise.all([call.setAudio(true), call.setVideo(true)]).then(() => {
                const session = call.session;
                if (!(session instanceof SessionSIP)) {
                  throw new Error("Session not instance of SessionSIP");
                }
                const sessionDescriptionHandlerOptions: Web.SessionDescriptionHandlerOptions = {
                  dataChannel: true,
                  onDataChannel: (dataChannel: RTCDataChannel) => {
                    console.log("Data channel created on incoming session");
                    this.setUpDataChannel(call, dataChannel);
                  }
                };
                session.SIP.sessionDescriptionHandlerOptions = sessionDescriptionHandlerOptions;
                call.accept();
              });
            });

          call.state
            .pipe(
              pluck("videoAvailable"),
              distinctUntilChanged(),
              filter(videoAvailable => !!videoAvailable),
              take(1)
            )
            .subscribe(() => {
              this.addCallToConference(call);
            });

          call.event.pipe(filter(isEndCallEvent), take(1)).subscribe(() => {
            this.videoConferenceCalls.forEach(callInQuestion => {
              if (call.uuid === callInQuestion.uuid) {
                this.removeVidConfCall(call);
                this.ensureCallOver(call);
              }
            });
          });
        },
        handleIncomingMessage: message => {
          if (message.request.from.displayName === aor) {
            return;
          }

          const contact: string | undefined = message.request.getHeader("contact");
          let gruu = "";

          if (contact) {
            // FIXME: Need real message parsing.
            if (contact.indexOf("<") !== -1) {
              const contactPieces = contact.split("<");
              gruu = contactPieces[1].slice(0, contactPieces[1].indexOf(">"));
            } else {
              gruu = contact;
            }
          }

          if (!this.conferenceUA || !this.conferenceInfo) {
            throw new Error("video conference: can not handle message without ua or info");
          }

          const cleanDisplay =
            this.anonymous === true
              ? "anonymous"
              : encodeURIComponent(this.conferenceUA.displayName.slice(7));
          const cleanName = this.conferenceInfo.conferenceName.replace(/\s/g, "_");
          const callContact = new CallContact(
            gruu + ";oCN=" + cleanName + ";oCP=" + cleanDisplay,
            this.conferenceInfo.conferenceName
          );

          const callConfig: CallConfiguration = {
            audio: true,
            video: true,
            sessionConfiguration: {
              sip: {
                inviteOptions: {
                  extraHeaders: ["X-Conf: true"],
                  params: {
                    // this is a hack to get SDRS to have the info we want about the user so call history looks right
                    toUri: "sip:" + message.request.from.uri.aor,
                    toDisplayName: message.request.from.displayName.slice(7) // remove X-Conf
                  },
                  sessionDescriptionHandlerOptions: {
                    dataChannel: true,
                    onDataChannel: (dataChannel: RTCDataChannel) => {
                      console.log("Data channel created on outgoing session");
                      this.setUpDataChannel(call, dataChannel);
                    }
                  }
                }
              }
            }
          };

          const call = makeCall(this.conferenceUA, Call, callContact, callConfig);

          call.event.pipe(filter(isConnectedCallEvent)).subscribe(() => {
            this.addCallToConference(call);
          });

          call.event.pipe(filter(isEndCallEvent)).subscribe(() => {
            this.removeVidConfCall(call);
          });

          this.conferenceUA.invite(call.session);
        }
      };

    this.conferenceUA = this.callControllerService.makeAndAddUserAgent(
      options,
      aor,
      registerOptions,
      delegate,
      sdhFactory,
      addToCallController
    );

    this.conferenceStart();
  }

  hold(): void {
    if (this.stateValue.hold) {
      this.callControllerService.holdAllOtherCalls();
    }
    this.stateStore.hold = !this.stateValue.hold;
    const holdPromises: Array<Promise<void>> = [],
      isHeld = this.stateStore.hold;

    this.videoConferenceCalls.forEach(call => {
      call.setAudio(!isHeld && !this.stateValue.isAudioMuted);
      call.setVideo(!isHeld && !this.stateValue.isVideoMuted);
      holdPromises.push(call.setHold(isHeld));
    });
    Promise.all(holdPromises).then(() => {
      this.setStatus();
    });
  }

  muteAudio(): void {
    this.stateStore.isAudioMuted = !this.stateValue.isAudioMuted;
    this.videoConferenceCalls.forEach(call => {
      call.setAudio(!this.stateStore.isAudioMuted);
    });
    this.publishState();
  }

  muteVideo(): void {
    this.stateStore.isVideoMuted = !this.stateValue.isVideoMuted;
    this.videoConferenceCalls.forEach(call => {
      call.setVideo(!this.stateStore.isVideoMuted);
    });
    if (this.conferenceInfo && this.conferenceInfo.mediaStream) {
      this.conferenceInfo.mediaStream
        .getTracks()
        .forEach(track => (track.enabled = !this.stateStore.isVideoMuted));
    }
    this.publishState();
  }

  private ensureCallOver(endedCall: Call): void {
    const callFound = this.videoConferenceCalls.find(call => call.uuid === endedCall.uuid);

    if (!endedCall.ended && callFound) {
      // STATUS_TERMINATED
      callFound.end();
      setTimeout(this.ensureCallOver.bind(this, endedCall), 100);
    }
  }

  private conferenceStart(): void {
    if (this.conferenceUA) {
      this.conferenceStartSubscription = this.conferenceUA.event.subscribe(e => {
        if (e.id === RegisteredUserAgentEvent.id) {
          this.onRegistered();
        } else if (this.conferenceUA && e.id === ConnectedUserAgentEvent.id) {
          const registerer: Registerer = (this.conferenceUA as any).registerer;
          const hackRequest = (registerer as any).request;
          if (!(hackRequest instanceof OutgoingRequestMessage)) {
            throw new Error(
              "HACK SHAME! The private internals of sip.js you were hacking have changed."
            );
          }
          const confName = this.conferenceInfo
            ? this.conferenceInfo.conferenceName
            : "Video Conference";
          hackRequest.setHeader("to", '"' + confName + '" <sip:$$' + this.confAor + ":5060>");
          if (hackRequest.ruri.host === "anonymous.invalid") {
            hackRequest.ruri.host = this.confAor.slice(this.confAor.indexOf("@") + 1);
          }
          this.regInterval = setInterval(
            () => this.conferenceUA && this.conferenceUA.register(),
            15 * 1000
          );
          this.conferenceUA.register();
        }
      });
      this.conferenceUA.start();
    }
  }

  private onRegistered(): void {
    const numRegistrations = this.conferenceUA && this.conferenceUA.numRegistrations;

    if (numRegistrations) {
      if (numRegistrations < this.maxConferenceCalls && this.conferenceStartSubscription) {
        this.conferenceStartSubscription.unsubscribe();
        this.conferenceUAMessage();
      } else {
        this.fullConference();
      }
    }
  }

  private conferenceUAMessage(): void {
    clearInterval(this.regInterval);
    if (this.conferenceUA) {
      const uri: URI | undefined = this.conferenceUA.targetToURI("$$" + this.confAor);

      if (uri) {
        this.conferenceUA.message(uri, "invite me!", "text/plain", [
          'Contact: "' +
            this.conferenceUA.aor +
            '" <sip:' +
            this.conferenceUA.aor +
            ";transport=ws>"
        ]);
      }
    }
  }

  private fullConference(): void {
    setTimeout(() => this.conferenceUA && this.conferenceUA.unregister(), 0);
  }

  private addCallToConference(call: Call): void {
    this.videoConferenceCalls = this.videoConferenceCalls.concat(call);
    this.stateStore.calls = this.stateValue.calls.concat(call.stateValue);

    this.videoConferenceCalls.forEach(callInQuestion => {
      callInQuestion.setAudio(!this.stateValue.hold && !this.stateValue.isAudioMuted);
      callInQuestion.setVideo(!this.stateValue.hold && !this.stateValue.isVideoMuted);
      if (this.stateValue.hold) {
        // BAD HACK: this occurs when onConnected is output (when you are the existing conference caller)
        // so it occurs at the same time as the sending of your ACK with sdp. The far side could (in certain timings)
        // attempt to process hold SDP before the ACK SDP is done processing, leading to an error and broken call
        setTimeout(() => callInQuestion.setHold(this.stateValue.hold), 200);
      }
    });

    this.callsMax =
      this.callsMax > this.videoConferenceCalls.length
        ? this.callsMax
        : this.videoConferenceCalls.length;
    this.setStatus();
  }

  private setUpDataChannel(call: Call, dataChannel: RTCDataChannel) {
    console.log(`[${call.uuid}] Video conference data channel set up`);

    const KEEPALIVE_INTERVAL = 3 * 1000;
    const KEEPALIVE_TIMEOUT = 20 * 1000;

    let pingInterval: any;
    let pingTimeout: any;

    dataChannel.onclose = () => {
      console.log(`[${call.uuid}] Video conference data channel close.`);
      clearInterval(pingInterval);
      clearTimeout(pingTimeout);
      call.end();
    };

    //@ts-ignore RTCErrorEvent interface has removed in typescript 4.4. Essentially it is the same as Event & {error: RTCError}, but RTCError has also been removed
    dataChannel.onerror = (event: RTCErrorEvent) => {
      console.error(`[${call.uuid}] Video conference data channel error.`);
      console.error(event.error);
      clearInterval(pingInterval);
      clearTimeout(pingTimeout);
      call.end();
    };

    dataChannel.onmessage = () => {
      console.log(`[${call.uuid}] Video conference data channel message.`);
      clearTimeout(pingTimeout);
      // dont' start ping timer until message is received; for backwards compatibility with older app versions
      pingTimeout = setTimeout(() => call.end(), KEEPALIVE_TIMEOUT);
    };

    dataChannel.onopen = () => {
      console.log(`[${call.uuid}] Video conference data channel open.`);
      pingInterval = setInterval(() => {
        console.log(`[${call.uuid}] Video conference data channel send.`);
        dataChannel.send("ping");
      }, KEEPALIVE_INTERVAL);
    };
  }

  private removeVidConfCall(call: Call): void {
    const idx = this.videoConferenceCalls.findIndex(c => c.uuid === call.uuid);
    if (idx !== -1) {
      this.videoConferenceCalls.splice(idx, 1);
      this.videoConferenceCalls = this.videoConferenceCalls.concat([]);
      this.stateStore.calls.splice(idx, 1);
      this.stateStore.calls = this.stateStore.calls.concat([]);
      this.setStatus();
    }
  }

  private closeNewVideoConferenceData(): void {
    this.publishEvent(VideoConferenceService.initialEvent());
  }

  private setStatus(): void {
    const numRegistrations = this.conferenceUA && this.conferenceUA.numRegistrations,
      maxConferenceCalls = this.maxConferenceCalls;
    if (this.conferenceUA && this.conferenceUA.registered && numRegistrations) {
      if (numRegistrations < maxConferenceCalls) {
        if (this.stateStore.hold) {
          this.stateStore.status = "hold";
        } else if (
          (this.callsMax === 0 && numRegistrations === 1) ||
          (this.stateStore.calls.length === 0 && this.callsMax > 0)
        ) {
          this.stateStore.status = "empty-host";
        } else {
          this.stateStore.status = "setup";
        }
      }
    } else {
      if (this.anonymous) {
        this.stateStore.status = "anonymous";
      }
      // wait for this.videoConferenceService.conferenceUA.registered to be true before setting statusString
      setTimeout(this.setStatus.bind(this), 100);
    }
    console.log("VideoConferenceService.setStatus", this.stateStore);
    this.publishState();
  }
}
