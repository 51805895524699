import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

import { BehaviorSubject, Subscription, combineLatest } from "rxjs";
import { pluck, map, filter, startWith } from "rxjs/operators";

import { UserCustomizationService } from "../../../../common/services/api/resources/userCustomization/user-customization.service";

import { AvatarUploadModalComponent } from "../../shared/components/imageUploader/uploadModal/avatar-upload-modal.component";
import { IdentityService } from "../../../../common/modules/core";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { UserService } from "../../../../common/services/api/resources/user/user.service";
import { ProfileGroup, ProfileItem } from "./profile.interface";
import { checkSocialMediaLinks } from "./validator";
import { socialItems } from "./const";
import { UserInfo } from "@onsip/common/services/api/apiResponse/user-info";

@Component({
  selector: "onsip-profile",
  templateUrl: "profile.component.html",
  styleUrls: ["./profile.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {
  blurbEditing = new BehaviorSubject<boolean>(false);
  titleEditing = new BehaviorSubject<boolean>(false);
  mediaEditing = new BehaviorSubject<boolean>(false);

  items: Array<ProfileItem> = socialItems as Array<ProfileItem>;

  titleControl = new FormControl("");
  blurbControl = new FormControl("");

  socialMediaFormGroup = new FormGroup({
    linkedin: new FormControl("", [checkSocialMediaLinks("linkedin")]),
    facebook: new FormControl("", [checkSocialMediaLinks("facebook")]),
    twitter: new FormControl("", [checkSocialMediaLinks("twitter")])
  }) as ProfileGroup;

  hideOtherMediaForms = new BehaviorSubject<boolean>(false);
  readonly maxBlurbLength = 500;

  email$ = this.userService.selfUser.pipe(pluck("contact", "email"));
  name$ = this.userService.selfUser.pipe(pluck("contact", "name"));
  publishEmail$ = this.userService.selfUser.pipe(pluck("exposePii"), startWith(false));

  private unsubscriber = new Subscription();

  link$ = combineLatest({
    teamPageDomain: this.noAuthOrgSummary.state.pipe(
      filter(state => !state.loading),
      map(state => Object.values(state.state)[0]?.teamPageDomain)
    ),
    idState: this.identity.state.pipe(filter(state => state.addresses.length > 0))
  }).pipe(
    map(({ teamPageDomain, idState }) => `${teamPageDomain}/${idState.addresses[0].username}`)
  );

  myAvatar$ = this.userCustomizationService.selfUser.pipe(
    map(userCustomization => userCustomization.userAvatarUrl)
  );

  constructor(
    private userCustomizationService: UserCustomizationService,
    private identity: IdentityService,
    private noAuthOrgSummary: NoAuthOrganizationSummaryService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openUploadAvatarModal(): void {
    this.dialog.open(AvatarUploadModalComponent, {
      panelClass: ["onsip-dialog-universal-style"]
    });
  }

  toggleMediaEdit() {
    if (this.mediaEditing.value) {
      this.socialMediaFormGroup.updateValueAndValidity();
      if (this.socialMediaFormGroup.valid) this.submit();
      this.mediaEditing.next(false);
    } else {
      this.mediaEditing.next(true);
    }
  }

  toggleTitleInfoEdit(): void {
    if (this.titleEditing.value) {
      this.submit();
      this.titleEditing.next(false);
    } else {
      this.titleEditing.next(true);
    }
  }

  toggleBlurbInfoEdit(): void {
    if (this.blurbEditing.value) {
      this.submit();
      this.blurbEditing.next(false);
    } else {
      this.blurbEditing.next(true);
    }
  }

  onCheckboxChange(event: MatSlideToggleChange): void {
    this.userService.userEditExposePii({ Enabled: event.checked }).then(state => {
      if (state.status === "error") {
        const err = state.data.message;
        console.error("Profile Component Api Service Error: ", err);
      }
    });
  }

  private fillForm() {
    this.unsubscriber.add(
      this.userCustomizationService.selfUser.subscribe(userCustomization => {
        const info = userCustomization.userInfo?.personalPageInfo!;
        this.socialMediaFormGroup.patchValue(info);
        this.titleControl.patchValue(info.title);
        this.blurbControl.patchValue(info.blurb);
      })
    );
  }

  private submit(): void {
    const params = {} as Record<keyof UserInfo["personalPageInfo"], string>;
    const socialMediaFormGroupValue = this.socialMediaFormGroup.value;

    Object.keys(socialMediaFormGroupValue).forEach(key => {
      if (this.socialMediaFormGroup.controls[key].valid) {
        params[key as keyof UserInfo["personalPageInfo"]] =
          this.socialMediaFormGroup.controls[key].value;
      }
    });
    params.title = this.titleControl.value as string;
    params.blurb = this.blurbControl.value as string;
    this.userCustomizationService.userCustomizationPersonalPageInfoEdit(params);
  }
}
