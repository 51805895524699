import { DocumentData } from "../cloud/firebase/firestore/document";
import { TimestampedState, makeTimestampedState } from "./state";
import { SessionState } from "../../sip/session";
import { getOrElse } from "./maybe";

import { Configuration } from "../../../interfaces/configuration";
import {
  CallContext,
  CallSourceType,
  CallTargetType,
  CallTargetDisplayType
} from "../../../interfaces/store/call-context";

export { SessionState };

export type Sessions = Record<string, SessionState>;

export interface CallData {
  /** Whether or not screenshare is allowed for this call */
  allowScreenshare: boolean;
  /** The AORs involed in the call. */
  aors: Array<string>;
  /** Call context. */
  context: CallContext;
  /** The delay a call has after being connected but before the parties are able to speak */
  delay: number;
  /** The OUID of the Call. */
  ouid: string;
  /** Session state. */
  sessions: Sessions;
  /** Source. */
  source: string;
  /** Type of the call source. */
  sourceType: CallSourceType;
  /** The result of the 5 star survey at the end of the call */
  survey: number;
  /** The target. */
  target: Configuration["target"];
  /** Target type. */
  targetType: CallTargetType;
  /** Target display. */
  targetDisplay: CallTargetDisplayType;
}

function makeCallData(data: DocumentData): CallData {
  return {
    allowScreenshare: getOrElse<CallData["allowScreenshare"]>(data.allowScreenshare, false),
    aors: getOrElse<CallData["aors"]>(data.aors, []),
    context: getOrElse<CallData["context"]>(data.context, {}),
    delay: getOrElse<CallData["delay"]>(data.delay, 0),
    ouid: getOrElse<CallData["ouid"]>(data.ouid, ""),
    sessions: getOrElse<CallData["sessions"]>(data.sessions, {}),
    source: getOrElse<CallData["source"]>(data.source, ""),
    sourceType: getOrElse<CallData["sourceType"]>(data.sourceType, ""),
    survey: getOrElse<CallData["survey"]>(data.survey, 0),
    target: getOrElse<CallData["target"]>(data.target, ""),
    targetType: getOrElse<CallData["targetType"]>(data.targetType, ""),
    targetDisplay: getOrElse<CallData["targetDisplay"]>(data.targetDisplay, "")
  };
}

export interface CallState extends TimestampedState, CallData {}

export function makeCallState(id: string, data: DocumentData): CallState {
  return {
    ...makeTimestampedState(id, data),
    ...makeCallData(data)
  };
}
