import { VolumeSliderComponent } from "./slider/volume-slider.component";
import { VolumePopupComponent } from "./popup/volume-popup.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  declarations: [VolumeSliderComponent, VolumePopupComponent],
  exports: [VolumeSliderComponent, VolumePopupComponent]
})
export class VolumeModule {}
