import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
  UrlSegment
} from "@angular/router";
import { views } from "./views";
import { Injectable } from "@angular/core";

import { OverlayContainer } from "@angular/cdk/overlay";

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  private handlers: { [key: string]: DetachedRouteHandle } = {};

  constructor(private overlay: OverlayContainer) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const routeStr = generateRouteArray(route).join("/");
    return !(
      routeStr.includes("account") ||
      routeStr.includes("administrators") ||
      routeStr.includes("sayso") ||
      routeStr.includes("prototype") ||
      routeStr.includes("contact-support") ||
      routeStr.includes("agent-view") ||
      routeStr.includes("app-settings") ||
      routeStr === "" ||
      `/${route.url}` === views.CALL
    );
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle && this.shouldDetach(route)) {
      const routeStr = generateRouteArray(route).join("/");
      this.handlers[routeStr] = handle;
    }

    // HACK HACK HACK
    // angular material tooltips don't disappear on some navigates, so the only fix for now is a handful of different ugly hacks
    // see here for other options, and potential solution someday: https://github.com/angular/components/issues/11478
    while (document.getElementsByTagName("mat-tooltip-component").length > 0) {
      document.getElementsByTagName("mat-tooltip-component")[0].remove();
    }

    // essentially the same ugly hack as above but for mat-selects only in the cdk overlay. These will linger if navigating programmatically
    // and the cdk overlay backdrop will prevent any interaction with the app
    const overlayContainer = this.overlay.getContainerElement();
    const matSelects = document.getElementsByClassName("mat-select-panel-wrap");
    while (overlayContainer.firstChild && matSelects.length > 0) {
      overlayContainer.removeChild(overlayContainer.firstChild);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routeStr = generateRouteArray(route).join("/");
    return routeStr !== "" && !!this.handlers[routeStr];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    const routeStr = generateRouteArray(route).join("/");
    return this.handlers[routeStr];
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // should refresh page if navigate to the users page (overview page in the future). Used for switching organizations
    const routeStr = generateRouteArray(future).join("/");
    if (
      future.routeConfig === curr.routeConfig &&
      routeStr.includes("administrators") &&
      routeStr.includes("pbx-overview")
    ) {
      return false;
    }
    // refresh agent account table if subagent spoofs into another subagent
    if (
      future.routeConfig === curr.routeConfig &&
      routeStr.includes("agent-view") &&
      routeStr.includes("accounts")
    ) {
      return false;
    }
    return future.routeConfig === curr.routeConfig;
  }
}

function generateRouteArray(_route: ActivatedRouteSnapshot): Array<UrlSegment> {
  if (!_route || !_route.url) return [];
  if (!_route.parent) return _route.url;
  return _route.url.concat(generateRouteArray(_route.parent));
}
