import { Call } from "./call";
import { CallEvent } from "./call-event";
import { CallState } from "./call-state";
import { EventStateEmitterArray } from "../emitter/event-state-emitter-array";

/** Calls - An observable Call array. */
export class Calls<CE extends CallEvent, CS extends CallState> extends EventStateEmitterArray<
  Call<CE, CS>,
  CE | CallEvent,
  CS
> {
  /**
   * Constructor
   * @param completeOnEmpty Completes when a call is removed and zero calls remain. Defaults to false.
   */
  constructor(completeOnEmpty: boolean = false) {
    super(completeOnEmpty);
  }
  /**
   * Dispose (will complete).
   */
  dispose(): void {
    this.publishComplete();
  }
  /**
   * Adds a Call.
   * @param call The Call to add.
   */
  add(call: Call<CE, CS>): this {
    return super.add(call);
  }
  /**
   * Gets a Call.
   * @param uuid The uuid of the Call.
   */
  get(uuid: string): Call<CE, CS> | undefined {
    return this.array.find(call => call.uuid === uuid);
  }
  /**
   * Removes a Call.
   * @param call The Call to remove.
   */
  remove(call: Call<CE, CS>): boolean {
    return super.remove(call);
  }
}
