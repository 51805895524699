import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  NotifyEmailOption,
  VoicemailSettingInfo,
  notifyEmailOptions
} from "./manage-voicemails.interface";
import { BehaviorSubject, Subscription, distinctUntilChanged, timer } from "rxjs";
import {
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { AdvVoicemailbox } from "@onsip/common/services/api/resources/voicemail/adv-voicemailbox";
import { VoicemailService } from "@onsip/common/services/api/resources/voicemail/voicemail.service";
import { IdentityService } from "@onsip/common/services/identity.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { Form } from "../../shared/interfaces/form.interface";
import { RecordingTypesEnum } from "../../shared/components/togglableRecorder/config/recording-types.enum";
import { TogglableRecorderComponent } from "../../shared/components/togglableRecorder/togglable-recorder.component";
import { DomSanitizer } from "@angular/platform-browser";
import { VoicemailEditService } from "../../administrators/adminUsers/usersSettings/voicemail/edit/voicemail-edit.service";
import { CommonModule } from "@angular/common";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { LoadingContainerComponent } from "../../shared/components/loadingContainer/loading-container.component";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TogglableRecorderModule } from "../../shared/components/togglableRecorder/togglable-recorder.module";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "onsip-manage-voicemails",
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    TogglableRecorderModule,
    TranslateModule,
    LoadingContainerComponent
  ],
  templateUrl: "./manage-voicemails.component.html",
  styleUrl: "./manage-voicemails.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManageVoicemailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() voicemailbox!: AdvVoicemailbox;
  @Output() toggleActionEvent = new EventEmitter();
  @ViewChild(TogglableRecorderComponent) recorderRef!: TogglableRecorderComponent;

  loadingStateComplete$ = new BehaviorSubject(true);

  formGroup!: FormGroup<Form<VoicemailSettingInfo>>;
  defaultUserAgentAor = "";
  mailboxNumber: number | undefined;
  transferAddressUsername!: string | undefined;

  greetingType = RecordingTypesEnum.RECORD;

  hasMessages = false;

  notifyEmailOptions = notifyEmailOptions.map(item => ({
    ...item,
    label: this.translate.instant(item.label)
  }));

  originalGreetingFilePath: File | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private voicemailService: VoicemailService,
    private identityService: IdentityService,
    private translate: TranslateService,
    private formBuilder: NonNullableFormBuilder,
    private snackbar: SnackbarService,
    private domSanitize: DomSanitizer,
    private voicemailEditService: VoicemailEditService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.fillEmailControl();
    this.fillForm();
    this.unsubscriber.add(
      this.identityService.state.subscribe(state => {
        this.defaultUserAgentAor = state.defaultIdentity ? state.defaultIdentity.aor : "";
      })
    );
    this.filterPasswordField();
  }

  ngAfterViewInit(): void {
    this.fillGreetingControl(this.voicemailbox);
  }

  private initForm() {
    this.formGroup = this.formBuilder.group<Form<VoicemailSettingInfo>>({
      notifyEmail: this.formBuilder.control(NotifyEmailOption.NO),
      email: this.formBuilder.control("", { validators: Validators.email }),
      password: this.formBuilder.control("", {
        validators: [Validators.required, Validators.pattern("^([0-9]{0,6}|10000000)$")]
      }),
      mwi: this.formBuilder.control(false),
      greetingFile: this.formBuilder.control(undefined),
      announceDuration: this.formBuilder.control(false),
      announceDateAndTime: this.formBuilder.control(false),
      announceCallerId: this.formBuilder.control(false)
    });
  }

  saveBox(): void {
    const formValue = this.formGroup.getRawValue();
    const promises = [];
    promises.push(
      this.voicemailEditService.addGreetingRecord(
        this.isNewRecording(),
        formValue.greetingFile as File,
        this.voicemailbox.advVoicemailboxId
      )
    );

    if (this.hasUpdatedVoicemailParams(formValue)) {
      const parameters = {
        // params not modified in the settings modal
        AdvVoicemailboxId: this.voicemailbox.advVoicemailboxId,
        Name: this.voicemailbox.name,
        Mailbox: this.voicemailbox.mailbox,
        Pager: this.voicemailbox.pager,
        Timezone: this.voicemailbox.timezone,
        TransferAddressUsername: this.transferAddressUsername || "",
        // params that are modified in the settings modal
        "EmailOptions[attach]":
          formValue.notifyEmail === NotifyEmailOption.EMAIL
            ? NotifyEmailOption.NO
            : formValue.notifyEmail,
        Email: formValue.email,
        Password: formValue.password,
        Mwi: formValue.mwi ? this.defaultUserAgentAor : "",
        SayDuration: formValue.announceDuration,
        SayDateTime: formValue.announceDateAndTime,
        SayCallerId: formValue.announceCallerId
      };

      promises.push(this.voicemailService.advVoicemailboxEdit(parameters));
      this.loadingStateComplete$.next(false);
    }

    Promise.all(promises)
      .then(res => {
        if (res.every(item => item?.status !== "error")) {
          this.handleSuccess();
        } else {
          const error = res.find(item => item?.status === "error" && item.data.message)?.data
            .message as string;
          this.handleError(error);
        }
      })
      .catch(() => {
        this.handleError();
      });
  }

  toggleUnavailableMessage(checked: boolean): void {
    this.hasMessages = checked;
  }

  handleCancel() {
    this.toggleActionEvent.emit();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private isNewRecording(): boolean {
    return this.formGroup.value.greetingFile?.name !== this.originalGreetingFilePath;
  }

  private fillForm() {
    const {
      mwi,
      sayDuration,
      sayDateTime,
      sayCallerId,
      email,
      password,
      mailbox,
      emailOptions,
      voicemail
    } = this.voicemailbox;
    this.hasMessages = !!voicemail?.greetings?.UnavailableGreeting;
    this.transferAddressUsername =
      this.voicemailbox.transferAddress && this.voicemailbox.transferAddress.username
        ? this.voicemailbox.transferAddress.username
        : undefined;

    this.formGroup
      .get("notifyEmail")
      ?.setValue(
        email.length > 0
          ? emailOptions.attach === NotifyEmailOption.NO
            ? NotifyEmailOption.EMAIL
            : (emailOptions.attach as NotifyEmailOption)
          : NotifyEmailOption.NO
      );

    this.formGroup.get("mwi")?.setValue(!!mwi);
    this.formGroup.get("announceDuration")?.setValue(sayDuration);
    this.formGroup.get("announceDateAndTime")?.setValue(sayDateTime);
    this.formGroup.get("announceCallerId")?.setValue(sayCallerId);
    this.formGroup.get("email")?.setValue(email);
    this.formGroup.get("password")?.setValue(password);
    this.mailboxNumber = parseInt(mailbox);
  }

  private fillGreetingControl(voicemailbox: AdvVoicemailbox) {
    const vmGreetings = voicemailbox.voicemail?.greetings;
    const id = voicemailbox?.advVoicemailboxId;
    if (vmGreetings && id) {
      const unavailableGreeting = Object.keys(vmGreetings).find(
        greeting => greeting === "UnavailableGreeting"
      );
      if (unavailableGreeting) {
        const originalGreetingFilePath = vmGreetings[unavailableGreeting]?.Path;
        this.originalGreetingFilePath = originalGreetingFilePath;
        this.recorderRef.recordingsForm.controls.upload.patchValue(
          new File([], originalGreetingFilePath)
        );
        this.recorderRef.recordingsForm.controls.record.patchValue(
          new File([], originalGreetingFilePath)
        );
        const audioUrl = this.voicemailService.getVoicemailUrl(id, unavailableGreeting);
        if (audioUrl) {
          const sanitizedOriginalFile = this.domSanitize.bypassSecurityTrustResourceUrl(audioUrl);
          this.unsubscriber.add(
            timer(100).subscribe(() => {
              this.recorderRef.recordingUrlsByType$.next({
                ...this.recorderRef.recordingUrlsByType$.value,
                record: sanitizedOriginalFile,
                upload: sanitizedOriginalFile
              });
            })
          );
        }
      }
    }
  }

  private fillEmailControl() {
    const notifyEmailControl = this.formGroup.get("notifyEmail");
    notifyEmailControl &&
      this.unsubscriber.add(
        notifyEmailControl.valueChanges
          .pipe(
            distinctUntilChanged(
              (prev, next) => (prev !== NotifyEmailOption.NO) === (next !== NotifyEmailOption.NO)
            )
          )
          .subscribe(value => {
            if (value !== NotifyEmailOption.NO) {
              this.formGroup.get("email")?.setValidators([Validators.email, Validators.required]);
            } else {
              this.formGroup.get("email")?.setValidators([Validators.email]);
              this.formGroup.get("email")?.setValue("");
            }
          })
      );
  }

  private hasUpdatedVoicemailParams(params: VoicemailSettingInfo): boolean {
    const { mwi, sayDuration, sayDateTime, sayCallerId, email, password, emailOptions } =
      this.voicemailbox;
    const paramNotifyEmail =
      params.notifyEmail === NotifyEmailOption.EMAIL ? NotifyEmailOption.NO : params.notifyEmail;
    return (
      emailOptions.attach !== paramNotifyEmail ||
      email !== params.email ||
      !mwi !== params.mwi ||
      password !== params.password ||
      sayCallerId !== params.announceCallerId ||
      sayDateTime !== params.announceDateAndTime ||
      sayDuration !== params.announceDuration
    );
  }

  private handleSuccess(): void {
    this.snackbar.openSnackBar(
      this.translate.instant("ONSIP_I18N.YOUR_CHANGES_HAVE_BEEN_SAVED"),
      "success"
    );
    this.handleCancel();
  }

  private handleError(error?: string) {
    error =
      error ||
      (this.translate.instant("ONSIP_I18N.THERE_WAS_AN_ERROR_SAVING_YOUR_INFORMATION") as string);
    this.snackbar.openSnackBar(error, "error");
    this.voicemailService.clearErrors();
    this.loadingStateComplete$.next(true);
  }

  private filterPasswordField() {
    this.unsubscriber.add(
      this.formGroup.controls.password.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe(value => {
          const filterOnlyNumbers = value.replace(/\D/g, "");
          this.formGroup.controls.password.patchValue(filterOnlyNumbers);
        })
    );
  }
}
