import { VolumeEvent, VolumeEventFactory } from "./volume-event";

/**
 * Emitted when voicemail volume is changed
 */
export class VoicemailVolumeEvent {
  static make(volume: number): VolumeEvent {
    return VolumeEventFactory.make(VoicemailVolumeEvent.id, volume, "");
  }
  static get id(): string {
    return "[Volume] voicemail";
  }
}
