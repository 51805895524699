export interface Presentity {
  aor: string;
  userAgentAor: string;
  event: EventPackage;
  eventData: DialogInfo | ExtendedDialogInfo | RegInfo | QueueInfo;
  uuidSubscription: string | undefined;
}

/** Presentity State */
export interface PresentityState {
  /** Presentities. */
  presentity: Array<Presentity>;
}

export type EventPackage = "dialog" | "reg" | "onsip-queue";

export interface DialogInfo {
  state: DialogInfoState;
  priority: number;
  confirmedTime: number; // TODO sometimes this is undefined ??
}

export function instanceOfDialogInfo(object: any): object is DialogInfo {
  return object && "state" in object && "priority" in object;
}

export interface ExtendedDialogInfo {
  dialogs: Array<PersonalDialog>;
}

export function instanceOfExtendedDialogInfo(object: any): object is ExtendedDialogInfo {
  return (
    object &&
    "dialogs" in object &&
    Array.isArray(object.dialogs) &&
    object.dialogs.map(instanceOfPersonalDialog).every((result: any) => result === true)
  );
}

export interface PersonalDialog {
  state: DialogInfoState;
  priority: number;
  confirmedTime: number; // TODO sometimes this is undefined ??
  localAor: string | undefined;
  localDisplayName: string | undefined;
  remoteAor: string | undefined;
  remoteDisplayName: string | undefined;
  direction: string;
  callId: string;
  fromTag: string;
  toTag: string; // TODO sometimes this is undefined ??
  coreProxyIp: string;
}

export function instanceOfPersonalDialog(object: any): object is PersonalDialog {
  return (
    "state" in object &&
    "priority" in object &&
    "direction" in object &&
    "callId" in object &&
    "fromTag" in object &&
    "coreProxyIp" in object
  );
}

export type DialogInfoState = "" | "terminated" | "early" | "proceeding" | "trying" | "confirmed";

export interface RegInfo {
  aor: string;
  state: RegInfoState;
  isWebRTC: boolean;
  priority: number;
}

export type RegInfoState = "" | "init" | "terminated" | "active";

export interface QueueInfo {
  agents: any;
  overview: any;
}

// export type QueueInfoState = "" | "init" | "terminated" | "active";
