export interface ApiQueueCallerEvent {
  AgentAddress: string;
  AgentAnswerTime: string;
  AppId: string;
  CallId: string;
  CallerAddress: string;
  CallerName: string;
  ConfirmedDuration: string;
  Created: string;
  Disposition: string;
  EndTime: string;
  EnqueueTime: string;
  HoldDuration: string;
  QueueCallerEventId: string;
  QueueOrganizationId: string;
  WaitDuration: string;
}

export interface QueueCallerEvent {
  agentAddress: string;
  agentAnswerTime: string;
  appId: string;
  callId: string;
  callerAddress: string;
  callerName: string;
  confirmedDuration: string;
  created: string;
  disposition: string;
  endTime: string;
  enqueueTime: string;
  holdDuration: string;
  queueCallerEventId: string;
  queueOrganizationId: string;
  waitDuration: string;
}

export function apiQueueCallerEventToQueueCallerEvent(
  apiQueueCallerEvent: ApiQueueCallerEvent
): QueueCallerEvent {
  return {
    agentAddress: apiQueueCallerEvent.AgentAddress,
    agentAnswerTime: apiQueueCallerEvent.AgentAnswerTime,
    appId: apiQueueCallerEvent.AppId,
    callId: apiQueueCallerEvent.CallId,
    callerAddress: apiQueueCallerEvent.CallerAddress,
    callerName: apiQueueCallerEvent.CallerName,
    confirmedDuration: apiQueueCallerEvent.ConfirmedDuration,
    created: apiQueueCallerEvent.Created,
    disposition: apiQueueCallerEvent.Disposition,
    endTime: apiQueueCallerEvent.EndTime,
    enqueueTime: apiQueueCallerEvent.EnqueueTime,
    holdDuration: apiQueueCallerEvent.HoldDuration,
    queueCallerEventId: apiQueueCallerEvent.QueueCallerEventId,
    queueOrganizationId: apiQueueCallerEvent.QueueOrganizationId,
    waitDuration: apiQueueCallerEvent.WaitDuration
  };
}
