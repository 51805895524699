export interface AuthServiceObject {
  tokenId: string;
  serviceId: string;
  scope: string;
  onsipUserId: string;
  serviceUserId: string;
  refreshToken: string;
  accessToken?: string;
}

export interface UserOauth2AccessTokenProxyParams {
  Service: string;
  Method: "GET" | "POST" | "PUT" | "DELETE";
  Path: string;
  HeaderFields: Array<string>;
  Body?: string;
}

export interface ApiUserOauth2AccessToken {
  UserOAuth2AccessTokenId: string;
  Service: string;
  Scope: string;
  UserId: string;
  ServiceUserId: string;
  Token: string;
  OrganizationId: string;
  Extra?: {
    AccessToken?: string;
    AccessTokenExpiresIn?: string;
    ServiceUserEmail?: string;
  };
}

export interface UserOauth2AccessToken {
  userOAuth2AccessTokenId: string;
  service: string;
  scope: string;
  userId: string;
  serviceUserId: string;
  token: string;
  organizationId: string;
  extra?: {
    accessToken?: string;
    accessTokenExpiresIn?: string;
    serviceUserEmail?: string;
  };
}

export interface ApiUserOauth2AccessTokenResponse {
  UserOAuth2AccessTokens: {
    UserOAuth2AccessToken: ApiUserOauth2AccessToken;
  };
}

export interface UserOauth2AccessTokenResponse {
  userOAuth2AccessTokenId?: string;
  userOAuth2AccessTokens: {
    userOAuth2AccessToken: UserOauth2AccessToken;
  };
}

export interface UserOauth2AccessTokenAddParams {
  AuthorizationCode: string;
  Service: string;
  RedirectUri: string;
  Scope: string;
}

export function oAuth2SAccessTokenApiConverter(
  data: ApiUserOauth2AccessToken
): UserOauth2AccessTokenResponse {
  return {
    userOAuth2AccessTokenId: data?.UserOAuth2AccessTokenId,
    userOAuth2AccessTokens: {
      userOAuth2AccessToken: {
        userOAuth2AccessTokenId: data?.UserOAuth2AccessTokenId,
        service: data?.Service,
        scope: data?.Scope,
        userId: data?.UserId,
        serviceUserId: data?.ServiceUserId,
        token: data?.Token,
        organizationId: data?.OrganizationId,
        extra: {
          accessToken: data?.Extra?.AccessToken,
          accessTokenExpiresIn: data?.Extra?.AccessTokenExpiresIn,
          serviceUserEmail: data?.Extra?.ServiceUserEmail
        }
      }
    }
  };
}
