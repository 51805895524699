import { PrephoneModule } from "../../features/shared/components/prephone/prephone.module";
import { PhoneModule } from "../../features/phone/phone.module";
import { PhoneAppRoutingModule } from "../../app/phone/phoneAppRouting.module";

import { Config } from "../../../common/config";
import { CoreModule } from "../../../common/modules/core/core.module";
import { ConsoleService } from "../../../common/services/console/console.service";
import { LogTarget } from "../../../common/services/logging/log.target";
import { cons, consoleLogTarget } from "../app.config";
import { APP_NAME } from "../app-name";

import { OnsipAppComponent } from "./onsip-app.component";

import { ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { CustomReuseStrategy } from "./customReuseStrategy";
import { RouterModule, RouteReuseStrategy } from "@angular/router";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";

import { WebApiSessionService } from "../../features/shared/services/webApiSession/web-api-session.service";
import { SentryErrorHandler } from "../../../common/services/sentry-error.handler";

import { sentryInit } from "../../platform-dependent/sentry";
import { Dedupe, ExtraErrorData } from "@sentry/integrations";

import { FirebaseBootstrapService } from "../../../common/services/sayso/firebase-bootstrap.service";
import { FirestoreAuthUserService } from "../../../common/services/sayso/firestore-auth-user.service";
import { UserAgentsCallsPresentService } from "../../../common/services/sayso/user-agents-calls-present.service";
import { TopicsPresenceService } from "../../../common/services/sayso/topics-presence.service";
import { HubspotApiService } from "../../features/shared/components/hubspot/services/hubspot-api.service";
import { HubspotInfoService } from "../../features/shared/components/hubspot/services/hubspot-info.service";
import { UserPresenceService } from "../../../common/services/sayso/user-presence.service";
import { FirestoreCallService } from "../../../common/services/sayso/firestore-call.service";
import { LocalizationService } from "../../../common/services/localization.service";
import { ApiSessionService } from "../../../common/services/api/api-session.service";
import { WebNetworkService } from "../../features/shared/services/webNetwork/web-network.service";
import { NetworkService } from "../../../common/services/network.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { WebCallControllerService } from "../../features/shared/services/webCallController/web-call-controller.service";
import { UsersStoreService } from "../../../common/services/sayso/users-store.service";
import { UsersStoreSubscriptionService } from "../../features/shared/services/userStoreSubscription/users-store-subscription.service";
import { PhoneLifeCycleHookApiResourceModule } from "./api/phone-lifecycle-hook-api-resource.module";
import { SubscriptionService } from "../../features/shared/services/subscription/subscription.service";
import { SubscriptionControllerService } from "../../../common/services/subscription-controller.service";

import { log as consoleLog } from "../../../common/libraries/sip/log";

declare let document: any; // there is no document on mobile
if (!/localhost/.test(document.location.host)) {
  sentryInit({
    dsn: "https://9012a64f58224d57b2a4d76ad331e1a9@sentry.io/1286552",
    integrations: [new Dedupe() as any, new ExtraErrorData({ depth: 10 })],
    environment: "production",
    release: Config.VERSION_NUMBER,
    maxBreadcrumbs: 100,
    beforeBreadcrumb: (breadcrumb: any) => {
      breadcrumb.data = breadcrumb.data || {};
      delete breadcrumb.data.logger;
      delete breadcrumb.data.extra;
      return breadcrumb;
    }
  });
}

// i18n mobile translations imports
import { CustomTranslateLoader } from "../../i18n/translate-loader";

// for AoT compilation
export function LoaderFactory() {
  return new CustomTranslateLoader();
}

@NgModule({
  bootstrap: [OnsipAppComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule.forRoot([
      { provide: ConsoleService, useFactory: cons },
      { provide: LogTarget, useFactory: consoleLogTarget, deps: [ConsoleService], multi: true }
    ]),
    PhoneLifeCycleHookApiResourceModule,
    PrephoneModule,
    PhoneModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: LoaderFactory,
        deps: [HttpClient]
      }
    }),
    PhoneAppRoutingModule // must be last
  ],
  declarations: [OnsipAppComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "en-US" }, // needed so datepicker formats are US-based, as we don't parse day/month (only month/day)
    { provide: APP_NAME, useValue: "phone" },
    { provide: NetworkService, useClass: WebNetworkService },
    { provide: ApiSessionService, useClass: WebApiSessionService },
    { provide: CallControllerService, useClass: WebCallControllerService },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    { provide: UsersStoreService, useClass: UsersStoreSubscriptionService },
    { provide: SubscriptionControllerService, useClass: SubscriptionService },
    {
      provide: FirebaseBootstrapService,
      useFactory: (
        ...deps: [
          UserAgentsCallsPresentService,
          FirestoreAuthUserService,
          TopicsPresenceService
        ] /* this type is kinda a lie */
      ) => new FirebaseBootstrapService(...deps), // shove in any deps defined below and instantiate them
      deps: [
        UserAgentsCallsPresentService,
        FirestoreAuthUserService,
        TopicsPresenceService,
        HubspotApiService,
        HubspotInfoService,
        UserPresenceService,
        FirestoreCallService
      ]
    },
    {
      provide: LOCALE_ID,
      deps: [LocalizationService],
      useFactory: (localizationService: LocalizationService) => localizationService.getLocale()
    },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class PhoneApp {
  constructor() {
    consoleLog.enableConsoleLogging();
  }
}
