@if (!hasScreenShare) {
<onsip-call-video
  [callUuid]="callUuid"
  [statusString]="statusString"
  [isQueueVideo]="isQueueVideo"
  [remoteStream]="remoteStream"
  [localStream]="localStream"
  [queueVideoStream]="queueVideoStream"
  [isCallListItem]="isCallListItem"
  [confCalls]="(confCalls | async)!"
>
</onsip-call-video>
} @if (hasScreenShare && !onHold) {
<onsip-screen-share
  [callUuid]="callUuid"
  [mutedVideo]="mutedVideo"
  [localStream]="localStream"
  [remoteStream]="remoteStream"
  [isCallListItem]="isCallListItem"
>
</onsip-screen-share>
}
