import { OperatorFunction } from "rxjs";
import { filter, map, distinctUntilChanged } from "rxjs/operators";

import { ApiStateStoreState } from "../api-state-store.service";
import { ApiSessionState } from "../api-session.service";

export function sessionId(): OperatorFunction<ApiStateStoreState, string> {
  return storeState =>
    storeState.pipe(
      map(store => (store.Session?.state as ApiSessionState).sessionId),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}
