import { Injectable } from "@angular/core";
import {
  ApiAddAction,
  ApiBrowseAction,
  ApiDeleteAction,
  ApiEditAction,
  ApiReadAction
} from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { domain } from "../../apiParams/domain";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { ParameterValue } from "../../util/api-action-description";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { AdvQueue, ApiAdvQueue, apiAdvQueueToAdvQueue as clean } from "./adv-queue";
import { lastValueFrom, Observable } from "rxjs";
import { distinctUntilChanged, map, take, filter } from "rxjs/operators";
import { isAdminRole, Role } from "@onsip/common/services/api/role";
import { CurrentOrganizationService } from "../../apiParams/current-organization.service";

export interface AdvQueueObject extends AdvQueue {
  displayName: string;
  address: string;
}

const debug = false;

@Injectable({ providedIn: "root" })
export class AdvQueueService extends ApiResourceService<AdvQueue> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "AdvQueue", "advQueueId");
    debug && this.state.subscribe(state => console.warn("AdvQueueService", state));

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(session => {
        if (session.role && isAdminRole([session.role as Role])) this.advQueueBrowse();
      });
  }

  async getSmartQueues(): Promise<Array<AdvQueueObject>> {
    return await this.advQueueBrowse().then(() => {
      return this.getSmartQueuesProm().then(queues => {
        const smartQueues: Array<AdvQueueObject> = [];
        queues.forEach(queue => {
          const queueObj = queue as AdvQueueObject;
          queueObj.displayName = this.humanizedQueueName(queue);
          queueObj.address = queue.appAddress;
          smartQueues.push(queueObj);
        });
        return smartQueues;
      });
    });
  }

  async getSmartQueuesWithoutOrgId(): Promise<Array<AdvQueueObject>> {
    return await this.advQueueBrowseWithoutOrgId().then(() => {
      return this.getSmartQueuesProm().then(queues => {
        const smartQueues: Array<AdvQueueObject> = [];
        queues.forEach(queue => {
          const queueObj = queue as AdvQueueObject;
          queueObj.displayName = this.humanizedQueueName(queue);
          queueObj.address = queue.appAddress;
          smartQueues.push(queueObj);
        });
        return smartQueues;
      });
    });
  }

  getSmartQueueObs(): Observable<Array<AdvQueueObject>> {
    return this.state.pipe(
      filter(state => !state.loading),
      map(state =>
        Object.values(state.state).filter(
          queues => queues.organizationId === CurrentOrganizationService.currentOrgId.getValue()
        )
      ),
      map(queues => {
        const smartQueues = queues.filter(queue => queue.smartQueue === true);
        const result: Array<AdvQueueObject> = [];
        smartQueues.forEach(queue => {
          const queueObj = queue as AdvQueueObject;
          queueObj.displayName = this.humanizedQueueName(queue);
          queueObj.address = queue.appAddress;
          result.push(queueObj);
        });
        return result;
      })
    );
  }

  getSmartQueuesProm(): Promise<Array<AdvQueue>> {
    return lastValueFrom(
      this.orgState.pipe(
        map(queues => queues.filter(queue => queue.smartQueue === true)),
        take(1)
      )
    );
  }

  private humanizedQueueName(advQueue: any): string {
    const suffix = new RegExp("\\." + advQueue.OrganizationId + "$");
    return advQueue.queueName.replace(suffix, "");
  }

  advQueueBrowse(extraParams: Record<string, ParameterValue> = {}): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AdvQueueBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 500, // should fetch all
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AdvQueueBrowse);
  }

  // for non admin users to get queue info
  advQueueBrowseWithoutOrgId(
    extraParams: Record<string, ParameterValue> = {}
  ): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AdvQueueBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 500, // should fetch all
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AdvQueueBrowse);
  }

  advQueueRead(AdvQueueId: string): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.AdvQueueRead,
        SessionId: this.store.state.pipe(sessionId()),
        AdvQueueId
      }
    });
    return this.promiseState.toPromise(ApiReadAction.AdvQueueRead);
  }

  advQueueEdit(params: {
    AdvQueueId: string;
    QueueName: string;
    MaxCaller: string;
    MaxAgent: string;
    CallerTimeout: string;
    AgentTimeout: string;
    RingStrategy: string;
    WrapupTime: string;
    MemberDelay: string;
    AnnounceFrequency: string;
    Username: string;
    Password: string;
    SmartQueue?: boolean;
    AnnounceHoldTime?: boolean;
    ReportHoldTime?: boolean;
    EscapeAddress?: string;
    EscapeAddressId?: string;
    FailoverAddress?: string;
    FailoverAddressId?: string;
    RingOnly?: boolean;
    MusicSourceId?: string;
    MusicSourceName?: string;
    MusicSourceUrl?: string;
    StorageServiceId?: string;
    StorageServiceName?: string;
    StorageServiceService?: string;
    AnnounceRecording?: string;
    RecordingTone?: boolean;
  }): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AdvQueueEdit,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AdvQueueEdit);
  }

  advQueueAdd(params: {
    QueueName: string;
    MaxCaller: string;
    MaxAgent: number;
    CallerTimeout: string;
    AgentTimeout: string;
    RingStrategy: string;
    WrapupTime: string;
    MemberDelay: string;
    AnnounceFrequency: string;
    Username: string;
    Password: string;
    SmartQueue?: boolean;
    AnnounceHoldTime?: boolean;
    ReportHoldTime?: boolean;
    EscapeAddress?: string;
    EscapeAddressId?: string;
    FailoverAddress?: string;
    RingOnly?: boolean;
    MusicSourceId?: string;
    MusicSourceName?: string;
    MusicSourceUrl?: string;
    StorageServiceId?: string;
    StorageServiceName?: string;
    StorageServiceService?: string;
    AnnounceRecording?: string;
    RecordingTone?: boolean;
  }): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.AdvQueueAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.AdvQueueAdd);
  }

  advQueueDelete(AdvQueueId: string): ApiPromiseState<AdvQueue> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.AdvQueueDelete,
        SessionId: this.store.state.pipe(sessionId()),
        AdvQueueId
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.AdvQueueDelete);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.AdvQueueBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiAdvQueue>>(response, action, "AdvQueue", "AdvQueues").map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiReadAction.AdvQueueRead:
      case ApiEditAction.AdvQueueEdit:
      case ApiAddAction.AdvQueueAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiAdvQueue>(response, action, "AdvQueue")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.AdvQueueDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedQueue = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "AdvQueueId")?.Value;
        deletedQueue &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedQueue]: undefined }, action);
        break;
    }
  }
}
