<mat-card class="volume-container" [class.vertical]="vertical">
  <mat-icon>volume_mute</mat-icon>
  <onsip-volume-slider
    #slider
    (changeEmitter)="volume = $event"
    [vertical]="vertical"
    [call]="call"
    [voicemail]="voicemail"
  >
  </onsip-volume-slider>
  <mat-icon>volume_up</mat-icon>
</mat-card>
