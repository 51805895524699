import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";

import { EventStateEmitter } from "../../../../../common/libraries/emitter/event-state-emitter";
import { Injectable } from "@angular/core";

import { VolumeEvent } from "./volume-event";
import { CallVolumeEvent, VoicemailVolumeEvent } from "./volume-event";
export interface VolumeState {
  defaultVolume: number;
}

@Injectable({ providedIn: "root" })
export class VolumeService extends EventStateEmitter<VolumeEvent, VolumeState> {
  private defaultVolume = 80;

  private static initialState(): VolumeState {
    return {
      defaultVolume: 80
    };
  }

  constructor(private localStorageService: LocalStorageService) {
    super(VolumeService.initialState());

    this.localStorageService.getVolume().then(volume => {
      this.stateStore.defaultVolume = volume || this.defaultVolume;
      this.publishState();

      this.state.subscribe(state =>
        this.localStorageService.setGeneralSettings({ volume: state.defaultVolume })
      );
    });
  }

  setDefaultVolume(newVolume: number): void {
    // changing this overrides all previous changes, so anything with a custom value
    // must adjust
    this.stateStore.defaultVolume = newVolume;
    this.publishState();
  }

  setCallVolume(uuid: string, newVolume: number): void {
    this.publishEvent(CallVolumeEvent.make(newVolume, uuid));
  }

  setVoicemailVolume(newVolume: number): void {
    this.publishEvent(VoicemailVolumeEvent.make(newVolume));
  }
}
