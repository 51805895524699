import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { map, distinctUntilChanged, tap, filter, take } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import {
  ApiWebCallTopic,
  WebCallTopic,
  apiWebCallTopicToWebCallTopic as clean,
  WebCallTopicSansId
} from "./web-call-topic";
import { WebCallTopicRep } from "./web-call-topic-rep";
import { ApiSessionService } from "../../api-session.service";
import { ApiAction } from "../../api-actions";
import {
  ButtonConfigurationMetadata,
  CONFIG_SCHEMA_VERSION,
  Configuration,
  ButtonConfigurationFilters
} from "../../../../interfaces/organization-configuration";
import { UUID } from "../../../../libraries/sip";
import { ParameterValue } from "../../util/api-action-description";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { sessionId } from "../../apiParams/session-id";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { organizationId } from "../../apiParams/organization-id";

import { ConfigurationFileService } from "../../../configuration-file.service";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseStateService, ApiPromiseState } from "../../api-promise-state.service";
export { WebCallTopic };

const debug = false;

type ConfigurationPatch = Pick<Configuration, "targetDisplay" | "video">;

interface WebCallTopicParams {
  WebCallTopicId?: number;
  Name: string;
  DisplayName: string;
  Audio: boolean;
  Video: boolean;
  Filters: string;
  AgentStrategy: "random" | "priority";
  SchemaVersion: number;
  DocumentVersion?: number;
}

@Injectable({ providedIn: "root" })
export class WebCallTopicService extends ApiResourceService<WebCallTopic> {
  private _state = new BehaviorSubject<Record<string, WebCallTopic>>({});

  constructor(
    session: ApiSessionService,
    protected store: ApiStateStoreService,
    protected configFileService: ConfigurationFileService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "WebCallTopic", "webCallTopicId");
    this.state
      .pipe(
        tap(state => {
          debug && console.warn("WebCallTopicService", state);
        }),
        map(substate => substate.state)
      )
      .subscribe(this._state);
  }

  /**
   * HACK - too much legacy code revolved around StateEmitter.stateValue
   * VERY BAD so we have to maintain the functionality and tackle this later
   */
  get stateValue(): Record<string, WebCallTopic> {
    return this._state.value;
  }

  findTopic(target: Configuration["target"]): WebCallTopic | undefined {
    return Object.values(this.stateValue).find(topic => topic.name === target);
  }

  findInConfiguration<T extends keyof Configuration>(
    prop: T,
    value: Configuration[T]
  ): WebCallTopic | undefined {
    if (!this.stateValue.topics) return undefined;
    return Object.values(this.stateValue.topics).find(
      topic => (topic.configuration && topic.configuration[prop]) === value
    );
  }

  getTopicWebCallTopicReps(
    target: Configuration["target"]
  ): WebCallTopic["webCallTopicReps"] | undefined {
    return this.findTopic(target)?.webCallTopicReps;
  }

  /** Observable of userIds array of sayso topic reps (org users in at least one topic) */
  getAllRepUserIds(): Observable<Array<string>> {
    return this.state.pipe(
      map(substate => {
        if (substate.state) {
          return Object.values(substate.state)
            .map(topic => topic.webCallTopicReps.map(rep => rep.repId.toString()))
            .reduce((acc, curr) => acc.concat(curr), []) // flattened list of rep ids
            .filter((v, i, a) => a.indexOf(v) === i); // unique list of rep ids
        } else {
          return [];
        }
      }),
      distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => b[i] === v))
    );
  }

  webCallTopicBrowse(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<WebCallTopic> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.WebCallTopicBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 50,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.WebCallBrowse);
  }

  webCallTopicAdd(
    patch: ConfigurationPatch,
    filters: ButtonConfigurationFilters = {},
    reps: Array<WebCallTopicRep["repId"]> = [],
    agentStrategy: WebCallTopic["agentStrategy"] = "random",
    documentVersionToBe?: number
  ): ApiPromiseState<WebCallTopic> {
    const configuration = this.getNewConfiguration(patch, filters);
    const webCallTopic = this.getNewWebCallTopic(configuration, reps, agentStrategy);
    const extraParameters = this.formatWebCallTopicParams(
      webCallTopic,
      configuration,
      documentVersionToBe
    );
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.WebCallTopicAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.WebCallTopicAdd);
  }

  webCallTopicEdit(
    target: Configuration["target"],
    configuration: ButtonConfigurationMetadata,
    repsIds: Array<WebCallTopicRep["repId"]>,
    documentVersionToBe: number,
    agentStrategy: WebCallTopic["agentStrategy"] = "random"
  ): ApiPromiseState<WebCallTopic> {
    const foundWebCallTopic = this.findTopic(target);
    if (!foundWebCallTopic) throw new Error("Topic not found in WebCallTopicService");
    if (
      configuration.filters.countryBlacklist !== undefined &&
      configuration.filters.countryWhitelist !== undefined
    ) {
      throw new Error("Topic not written: blacklist & whitelist both written to form");
    }
    const webCallTopic = Object.assign(foundWebCallTopic, {
      agentStrategy,
      webCallTopicReps: repsIds.map((repId, position) => ({ repId, position, repType: "user" }))
    });
    const extraParameters = this.formatWebCallTopicParams(
      webCallTopic,
      configuration,
      documentVersionToBe
    );
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.WebCallTopicEdit,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.WebCallTopicEdit);
  }

  webCallTopicDelete(target: Configuration["target"]): ApiPromiseState<WebCallTopic> {
    const deleteTopic = this.findTopic(target);
    if (!deleteTopic) throw new Error("Topic not found in WebCallTopicService");
    const extraParameters = { WebCallTopicId: deleteTopic.webCallTopicId };
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.WebCallTopicDelete,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.WebCallTopicDelete);
  }

  /** helper function to get a configuration object using given targetDisplay */
  private getNewConfiguration(
    patch: ConfigurationPatch,
    filters: ButtonConfigurationFilters
  ): ButtonConfigurationMetadata {
    const newConfiguration: ButtonConfigurationMetadata = {
      target: this.uniqueTargetFromTargetDisplay(patch.targetDisplay),
      audio: true,
      video: true,
      targetType: "topic",
      targetDisplay: patch.targetDisplay,
      uuid: UUID.randomUUID(),
      filters
    };
    return Object.assign(newConfiguration, patch);
  }

  /** helper function to make sure the new topic's target is always unique */
  private uniqueTargetFromTargetDisplay(
    targetDisplay: Configuration["targetDisplay"]
  ): Configuration["target"] {
    const addDigitsUntilUnique = (target: Configuration["target"]): string => {
      function addDigits(str: string, counter: number): string {
        let val = 0;
        // eslint-disable-next-line no-null/no-null
        if (str.charAt(str.length - 1).match(/[0-9]/) !== null) {
          val = parseInt(str.charAt(str.length - 1));
          val++;
        } else {
          val = counter;
        }
        return str + val;
      }

      let count = 1,
        newTarget = target;
      while (this.stateValue.topics && this.findTopic(newTarget)) {
        newTarget = addDigits(newTarget, count);
        count++;
      }
      return newTarget;
    };

    return addDigitsUntilUnique(
      targetDisplay
        .toLowerCase()
        .split(/[ `~!@#$%^&*()_+=,./<>?;':"|\\]/)
        .join("-")
    );
  }

  // helper function to create new WebCallTopic for topic add

  private getNewWebCallTopic(
    configuration: ButtonConfigurationMetadata,
    reps: Array<WebCallTopicRep["repId"]>,
    agentStrategy: WebCallTopic["agentStrategy"]
  ): WebCallTopicSansId {
    const webCallTopicReps: Array<WebCallTopicRep> = reps.map((repId, position) => ({
      repId,
      position,
      repType: "user"
    }));
    return { name: configuration.target, webCallTopicReps, agentStrategy };
  }

  // helper function to format incoming webcalltopics into params objects needed for add/edit api calls

  private formatWebCallTopicParams(
    webCallTopic: WebCallTopicSansId & Partial<Pick<WebCallTopic, "webCallTopicId">>,
    configuration: ButtonConfigurationMetadata,
    documentVersionToBe?: number
  ): WebCallTopicParams {
    if (configuration.target !== webCallTopic.name) {
      console.error(
        `configuration.target:[${configuration.target}] -- webCallTopic.name:[${webCallTopic.name}]`
      );
      throw new Error(
        "Tried to call WebCallTopicAdd/Edit but configuration.target and webCallTopic.name mismatched"
      );
    }
    const params: WebCallTopicParams = {
      Name: configuration.target,
      DisplayName: configuration.targetDisplay,
      Audio: configuration.audio,
      Video: configuration.video,
      Filters: JSON.stringify(configuration.filters),
      AgentStrategy: webCallTopic.agentStrategy,
      SchemaVersion: CONFIG_SCHEMA_VERSION
    };

    // The following two fields are necessary for WebCallTopicEdit but is not necessary for WebCallTopicAdd
    if (webCallTopic.webCallTopicId) params.WebCallTopicId = webCallTopic.webCallTopicId;
    if (documentVersionToBe) params.DocumentVersion = documentVersionToBe;

    // Unfortunately, the way the api reads rep is by creating a Reps[key][rep_type] and Reps[key][rep_id]
    // These are not typable because the number of new objects will be depended on the numbers of rep being added/modified
    // so the object will be typed with any
    const paramsWithReps: any = params;

    if (webCallTopic.webCallTopicReps.length) {
      webCallTopic.webCallTopicReps
        .sort((repA, repB) => repA.position - repB.position)
        .forEach((rep, i) => {
          paramsWithReps[`Reps[${i}][rep_type]`] = "user";
          paramsWithReps[`Reps[${i}][rep_id]`] = rep.repId;
        });
    } else {
      paramsWithReps.Reps = "";
    }
    return paramsWithReps;
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.WebCallTopicBrowse:
        // eslint-disable-next-line no-case-declarations
        const topics = extractData<Array<ApiWebCallTopic>>(
          response,
          action,
          "WebCallTopic",
          "WebCallTopics"
        ).map(clean);
        this.configFileService
          .getOrgConfig()
          .pipe(
            filter(orgConfig => orgConfig.organizationId.toString() === topics[0]?.organizationId),
            take(1)
          )
          .subscribe(orgConfig => {
            const configs = orgConfig.configurations;
            const topicsWithConfigs = Object.values(topics).map(topic => {
              topic.configuration = configs.find(config => config.target === topic.name);
              return topic;
            });

            this.store.mergeStateUpdate(
              this.resourceName,
              arrayToRecord(topicsWithConfigs, this.indexKeyName),
              action
            );
          });
        break;

      case ApiAction.WebCallTopicRead:
      case ApiAction.WebCallTopicEdit:
      case ApiAction.WebCallTopicAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiWebCallTopic>(response, action, "WebCallTopic")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.WebCallTopicDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deleted = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
          param => param.Name === "WebCallTopicId"
        )?.Value;
        deleted && this.store.mergeStateUpdate(this.resourceName, { [deleted]: undefined }, action);
        break;
    }
  }
}
