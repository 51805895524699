<mat-form-field class="org-selector" appearance="outline">
  <mat-select [formControl]="currentOrganization">
    @for (org of organizationList; track org) {
    <mat-option [value]="org.organizationId">
      {{ org.domain }}
    </mat-option>
    }
  </mat-select>
</mat-form-field>
<div class="shell-navigation-container">
  <onsip-shell-navigations [shellNavigations]="shellNavigations"> </onsip-shell-navigations>
</div>
