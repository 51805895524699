export interface ApiSubAgentAccount {
  AccountContactName: string;
  AccountContactOrganization: string;
  AccountId: string;
  AgentId: string;
  Created: string;
  Modified: string;
  SubAccountBalance: string;
  SubAccountContactName: string;
  SubAccountContactOrganization: string;
  SubAccountCreditLimit: string;
  SubAccountId: string;
  SubAccountReceivable: string;
}

export interface SubAgentAccount {
  accountContactName: string;
  accountContactOrganization: string;
  accountId: string;
  agentId: string;
  created: string;
  modified: string;
  subAccountBalance: string;
  subAccountContactName: string;
  subAccountContactOrganization: string;
  subAccountCreditLimit: string;
  subAccountId: string;
  subAccountReceivable: string;
}

export interface SubAgentAccountBrowseParams {
  AgentId?: string;
  AccountId?: string;
}

export function apiSubAgentAccountToSubAgentAccount(
  apiSubAgentAccount: ApiSubAgentAccount
): SubAgentAccount {
  return {
    accountContactName: apiSubAgentAccount.AccountContactName,
    accountContactOrganization: apiSubAgentAccount.AccountContactOrganization,
    accountId: apiSubAgentAccount.AccountId,
    agentId: apiSubAgentAccount.AgentId,
    created: apiSubAgentAccount.Created,
    modified: apiSubAgentAccount.Modified,
    subAccountBalance: apiSubAgentAccount.SubAccountBalance,
    subAccountContactName: apiSubAgentAccount.SubAccountContactName,
    subAccountContactOrganization: apiSubAgentAccount.SubAccountContactOrganization,
    subAccountCreditLimit: apiSubAgentAccount.SubAccountCreditLimit,
    subAccountId: apiSubAgentAccount.SubAccountId,
    subAccountReceivable: apiSubAgentAccount.SubAccountReceivable
  };
}
