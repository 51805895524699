import { CallEvent } from "./call-event";
import { CallState } from "./call-state";
import { CallGroup } from "./call-group";
import { CallGroupState } from "./call-group-state";
import { EventStateEmitterArray } from "../emitter/event-state-emitter-array";

/** CallGroups - An observable CallGroup array. */
export class CallGroups<CE extends CallEvent, CS extends CallState> extends EventStateEmitterArray<
  CallGroup<CE, CS>,
  CE | CallEvent,
  CallGroupState<CS>
> {
  /**
   * Constructor
   * @param completeOnEmpty Complete when a call group is removed and zero call groups remain. Defaults to false.
   */
  constructor(completeOnEmpty: boolean = false) {
    super(completeOnEmpty);
  }
  /**
   * Dispose (will complete).
   */
  dispose(): void {
    this.publishComplete();
  }
  /**
   * Adds a CallGroup.
   * @param callGroup The CallGroup to add.
   */
  add(callGroup: CallGroup<CE, CS>): this {
    return super.add(callGroup);
  }
  /**
   * Gets a CallGroup.
   * @param uuid The uuid of the CallGroup.
   */
  get(uuid: string): CallGroup<CE, CS> | undefined {
    return this.array.find(group => group.uuid === uuid);
  }
  /**
   * Removes a CallGroup.
   * @param callGroup The CallGroup to remove.
   */
  remove(callGroup: CallGroup<CE, CS>): boolean {
    return super.remove(callGroup);
  }
}
