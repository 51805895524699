import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { map } from "rxjs/operators";

import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { isAtLeastAccountAdminRole } from "@onsip/common/services/api/role";
import { views } from "@onsip/web/app/phone/views";

export const AccountAdminGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);

  return userService.selfUser.pipe(
    map(user => {
      const hasAdminRole = isAtLeastAccountAdminRole(user.roles);
      if (hasAdminRole) {
        return true;
      } else {
        router.navigate([views.HOME]);
        return false;
      }
    })
  );
};
