import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { firebase } from "../cloud/firebase/platform-firebase-types";
import { PresencePublisher } from "./presence-publisher";
import { AgentInstance } from "./agent-instance";

const debug = false;

export class AgentPresencePublisher extends PresencePublisher {
  private static makePublishRef(oid: number): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/agents`).push();
  }

  /**
   * Constructor
   * @param oid The organization id of the agent.
   * @param cid The call id of the call the agent is offering to handle.
   * @param uid The user id of the agent's user.
   */
  constructor(oid: number, private cid: string, private uid: number) {
    super(AgentPresencePublisher.makePublishRef(oid));
  }

  get userId(): number {
    return this.uid;
  }

  setUserId(uid: number): Promise<void> {
    if (this.uid === uid) {
      return Promise.resolve();
    }
    const temp = this.uid;
    this.uid = uid;
    const child: keyof AgentInstance = "u";
    return this.setChild(child, uid).then(committed => {
      if (!committed) {
        this.uid = temp;
      }
    });
  }

  protected get id(): string {
    return `AgentPresencePublisher[${this.publishRef.key}]`;
  }

  protected get value(): AgentInstance {
    return {
      c: this.cid,
      u: this.uid
    };
  }

  private setChild(key: keyof AgentInstance, value: any): Promise<boolean> {
    return this.publishRef
      .child(key)
      .transaction(currentValue => {
        // abort transaction if no data at current location (return undefined)
        // we don't want to set data if the parent node does not exist which
        // should/would be the case if we disconnected and it was removed.
        // eslint-disable-next-line no-null/no-null
        if (currentValue === null) return undefined;
        // set the new value
        return value;
      })
      .then((result: { committed: boolean; snapshot: firebase.database.DataSnapshot }) => {
        debug &&
          console.log(`AgentPresencePublisher.setChild[${key}] committed ${result.committed}`);
        debug &&
          console.log(
            `AgentPresencePublisher.setChild[${key}] data exists ${result.snapshot.exists()}`
          );
        debug &&
          console.log(
            `AgentPresencePublisher.setChild[${key}] data value ${result.snapshot.val()}`
          );
        return result.committed;
      })
      .catch((error: Error) => {
        debug && console.error(`AgentPresencePublisher.setChild[${key}] error`);
        debug && console.error(error);
        throw error;
      });
  }
}
