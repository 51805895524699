<!-- CALL STATUS INFO -->
<mat-card>
  <mat-card-content>
    @if (currentCall) {
    <div class="inc-call-main">
      <div class="inc-call-info">
        <span class="inc-call-title">
          {{
            currentCall.videoExpected
              ? ("ONSIP_I18N.INCOMING_VIDEO_CALL" | translate)
              : ("ONSIP_I18N.INCOMING_PHONE_CALL" | translate)
          }}
          @if ((saysoDisplayTimer | async) && !saysoFailoverLabel) { ({{
            "ONSIP_I18N.REDIRECTS_IN" | translate
          }}
          {{ saysoDisplayTimer | async | duration }}) } @if ((saysoDisplayTimer | async) &&
          saysoFailoverLabel) { ({{ "ONSIP_I18N.ATTEMPT" | translate }} #{{ saysoFailoverLabel }}
          {{ "ONSIP_I18N.REDIRECTS_IN" | translate }} {{ saysoDisplayTimer | async | duration }}) }
        </span>
        <h1 class="mat-headline-4 inc-call-subtitle">
          {{ callFromDisplayName || currentCall.aor }}
        </h1>
        <!-- MOBILE VIEW AVATAR -->
        @if (contact) {
        <div class="avatar-mobile">
          <onsip-avatar-frame>
            <onsip-avatar [avatarUrl]="contact.avatarUrl" [name]="contact.name"></onsip-avatar>
          </onsip-avatar-frame>
        </div>
        } @else {
        <onsip-animation class="avatar-frame avatar-mobile" filename="horn"></onsip-animation>
        } @if ((saysoCallDetails | async) !== false && saysoCallFromWhere) { @if
        (!saysoTargetDisplay) {
        <h4 class="call-status-from">
          {{ "ONSIP_I18N.FROM" | translate }}: "{{ saysoCallFromWhere }}"
        </h4>
        } @if (saysoTargetDisplay) {
        <h4 class="call-status-from">
          {{ "ONSIP_I18N.FROM" | translate }}: "{{ saysoTargetDisplay }}" on
          <a target="_blank" [href]="saysoCallFromLink" tabindex="-1">{{ saysoCallFromWhere }}</a>
        </h4>
        } } @else {
        <h4 class="call-status-from">{{ "ONSIP_I18N.FROM" | translate }}: {{ callFromUri }}</h4>
        }
        <h4 class="call-status-to">{{ "ONSIP_I18N.TO" | translate }}: {{ callToUri }}</h4>
      </div>
      @if (contact) {
      <div class="avatar-desktop">
        <onsip-avatar-frame>
          <onsip-avatar [avatarUrl]="contact.avatarUrl" [name]="contact.name"></onsip-avatar>
        </onsip-avatar-frame>
      </div>
      } @else {
      <onsip-animation class="avatar-frame avatar-desktop" filename="horn"></onsip-animation>
      }
      <div class="button-container">
        <!-- ANSWER CALL VIDEO -->
        @if (currentCall.videoExpected) {
        <button
          mat-fab
          id="onsip--incoming-call--answer-video"
          aria-label="answer as video call"
          autofocus
          class="inc-call-button-video onsip-call-begin-background mat-elevation-z0"
          [matTooltip]="'ONSIP_I18N.ANSWER_WITH_VIDEO' | translate"
          matTooltipClass="onsip-tooltip-background"
          (click)="accept(true)"
        >
          <mat-icon onsip-icon-outlined>videocam</mat-icon>
        </button>
        }
        <!-- ANSWER CALL AUDIO -->
        <button
          mat-fab
          id="onsip--incoming-call--answer-audio"
          aria-label="answer audio call"
          autofocus
          class="inc-call-button-answer onsip-call-begin-background mat-elevation-z0"
          [matTooltip]="'ONSIP_I18N.ANSWER_WITH_AUDIO' | translate"
          matTooltipClass="onsip-tooltip-background"
          (click)="accept()"
        >
          <mat-icon>phone</mat-icon>
        </button>
        <!-- DECLINE CALL -->
        <button
          mat-fab
          id="onsip--incoming-call--end-call"
          aria-label="decline call"
          class="inc-call-button-decline onsip-call-end-background mat-elevation-z0"
          [matTooltip]="'ONSIP_I18N.DECLINE_CALL' | translate"
          matTooltipClass="onsip-tooltip-background"
          (click)="endCall()"
        >
          <mat-icon>call_end</mat-icon>
        </button>
      </div>
    </div>
    }
    <!-- the call page divider is part of the call page, but the incoming call divider is part of the conversations component it's dumb as shit i know -->
    <!-- HUBSPOT INTEGRATION -->
    @if (currentCall) {
    <onsip-hubspot-embed [callState]="currentCall" class="incoming"></onsip-hubspot-embed>
    }
  </mat-card-content>
</mat-card>
