import { URI } from "sip.js";
import { Subscription } from "rxjs";

import { CallConfiguration } from "./call-configuration";
import { CallContact } from "./call-contact";
import {
  CallEvent,
  NewCallEvent,
  ConnectedCallEvent,
  ConnectingCallEvent,
  EndCallEvent,
  EndCallEventReason,
  LastCallEvent,
  ProgressCallEvent,
  VideoCallEvent,
  TransferFailedEvent
} from "./call-event";
import { CallState, CallStateInitializer } from "./call-state";
import { EventStateEmitter } from "../emitter/event-state-emitter";
import { Session } from "./session";
import {
  NewSessionEvent,
  ConnectedSessionEvent,
  ConnectingSessionEvent,
  EndSessionEvent,
  LastSessionEvent,
  ReferredSessionEvent,
  ReplacedSessionEvent,
  TransferFailedSessionEvent,
  ProgressSessionEvent
} from "./session-event";
import { UUID } from "./uuid";
import { log } from "./log";
import { CallConstructor } from "./call-constructor";
import { UserAgent } from "./user-agent";
import { SessionSIP } from "./session-sip";

/** CallStage. Only valid transitions are "initial" -> "active" -> "ended" */
export type CallStage = "init" | "active" | "ended";

/**
 * Creates a new Call associated with this UserAgent.
 * @param callConstructor The constructor function for the call class.
 * @param callContact The contact info of the Session peer.
 * @param callConfiguration the configuration of the call.
 */
export function makeCall<E extends CallEvent, S extends CallState>(
  userAgent: UserAgent,
  constructor: CallConstructor<E, S>,
  contact: CallContact,
  configuration: CallConfiguration
): Call<E, S> {
  const localUri = userAgent.targetToURI(userAgent.stateValue.aor || "");
  let localUriString: string | undefined;
  if (localUri) localUriString = localUri.toString();
  const session = new SessionSIP(
    userAgent.displayName,
    localUriString,
    contact.getDisplayName(),
    contact.getURI(userAgent),
    userAgent,
    configuration.sessionConfiguration
  );
  session.setAudio(configuration.audio);
  session.setVideo(configuration.video);
  session.setAudioRequested(configuration.audio);
  session.setVideoRequested(configuration.video);
  const call = new constructor(session, contact, configuration);
  return call;
}

/** Call implementation class. */
export class Call<
  CE extends CallEvent = CallEvent,
  CS extends CallState = CallState
> extends EventStateEmitter<CE | CallEvent, CS> {
  private _connectedAt: Date | undefined;
  private _connectingAt: Date | undefined;
  private _endedAt: Date | undefined;
  private _lastAt: Date | undefined;
  private _newAt: Date | undefined;
  private _progressAt: Date | undefined;
  private _uuid: string;
  private _videoAt: Date | undefined;

  // not using session.incoming as the session can get swapped out
  // and want this to be constant for entire call
  private _incoming: boolean;

  // the only valid transitions are init -> active -> ended
  private _stage: CallStage;

  private _sessionEventSubscription: Subscription | undefined;
  private _sessionStateSubscription: Subscription | undefined;

  static initializer(uuid: string, session: Session): CallState {
    return {
      active: false,
      aor: session.userAgent.aor,
      audio: false,
      audioAvailable: false,
      audioExpected: false,
      audioRequested: false,
      blindTransferInProgress: false,
      connected: false,
      connectedAt: undefined,
      connecting: false,
      connectingAt: undefined,
      ended: false,
      endedAt: undefined,
      hold: false,
      holdInProgress: false,
      incoming: session.incoming,
      init: true,
      lastAt: undefined,
      localDisplayName: session.localDisplayName,
      localUri: session.localUri,
      maxCallTime: session.maxCallTime,
      mute: false,
      new: false,
      newAt: undefined,
      progressAt: undefined,
      remoteDisplayName: session.remoteDisplayName,
      ringing: false,
      stage: "init",
      remoteUri: session.remoteUri.toString(),
      uuid,
      video: false,
      videoAvailable: false,
      videoExpected: false,
      videoRequested: false,
      xData: undefined
    };
  }

  /**
   * Constructor
   * @param _session Session encapsulated by the Call.
   * @param contact On an outoing call, the assoicated contact.
   * @param configuration On an outgoing call, the asooicated configuration.
   */
  constructor(
    private _session: Session,
    contact?: CallContact | undefined,
    configuration?: CallConfiguration | undefined,
    initializer: CallStateInitializer<CS> = Call.initializer as CallStateInitializer<CS>
  ) {
    super(initializer(UUID.randomUUID(), _session));
    this._uuid = this.stateStore.uuid;
    this._stage = this.stateStore.stage;
    this._incoming = this.stateStore.incoming;
    this._session.ouid = this._uuid; // use the call uuid as the session ouid
    this.initializeSessionSubscriptions();
  }

  /**
   * Answers an incoming call.
   */
  accept(): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.accept();
  }

  /**
   * Attended transfers the call.
   * @param transfereeCall Call to replace the current call with
   */
  attendedTransfer<E extends CallEvent, S extends CallState>(
    transfereeCall: Call<E, S>
  ): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.referWithReplaces(transfereeCall._session);
  }

  /**
   * Begins an outgoin call.
   */
  begin(): Promise<void> {
    return this.session.userAgent.invite(this.session);
  }

  /**
   * Blind transfers the call.
   * @param uri URI to REFER UAS to.
   * @param target The target to transfer the call to.
   */
  blindTransfer(uri: URI): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.refer(uri);
  }

  /**
   * Rejects an incoming call.
   */
  decline(): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this.terminate();
  }

  /**
   * Ends the call.
   */
  end(): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this.terminate();
  }

  /**
   * Groups the call with another call.
   * @param call The call to group with.
   */
  groupWith<E extends CallEvent, S extends CallState>(call: Call<E, S>): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.groupWith(call.session);
  }

  /**
   * Sends DTMF.
   * @param tone The tone to send.
   */
  playDTMF(tone: string): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.playDTMF(tone);
  }

  /**
   * Sends a PROGRESS response 183 for call tracking purposes
   */
  callProgressTracking(): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.callProgressTracking();
  }

  /**
   * Enables or disables audio.
   * @param audio Audio enabled if true, disabled if false.
   */
  setAudio(audio: boolean): void {
    if (!this.stageAssert("active")) return;
    this._session.setAudio(audio);
  }

  /**
   * Places the call on hold.
   * @param hold Hold on if true, off if false.
   */
  setHold(hold: boolean): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.setHold(hold);
  }

  /**
   * Sets a maximum time for call.
   * @param maxCallTime Maximum call time in milliseconds, terminate this amt of time after connectedAt
   */
  setMaxCallTime(maxCallTime: number): void {
    return this._session.setMaxCallTime(maxCallTime);
  }

  /**
   * Mutes or unmutes the call.
   * @param mute Muted if true, unmuted if false.
   */
  setMute(mute: boolean): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();
    return this._session.setMute(mute);
  }

  /**
   * Enables or disables video.
   * @param video Video enabled if true, disabled if false.
   */
  setVideo(video: boolean): void {
    if (!this.stageAssert("active")) return;
    this._session.setVideo(video);
    // we notify when video starts
    if (video) {
      this.onVideo();
    }
  }

  setBlindTransferInProgress(transfer: boolean): void {
    if (!this.stageAssert("active")) return;
    return this._session.setBlindTransferInProgress(transfer);
  }

  /**
   * True when call is active.
   * Implies: new || connecting || connected.
   */
  get active(): boolean {
    return this._stage === "active";
  }

  /**
   * The AOR of the user agent.
   */
  get aor(): string {
    return this._session.userAgent.aor;
  }

  /**
   * True if audio is enabled.
   */
  get audio(): boolean {
    return this._session.audio;
  }

  /**
   * True if audio is available to be enabled.
   */
  get audioAvailable(): boolean {
    return this._session.audioAvailable;
  }

  /**
   * True if audio is expected to be available to be enabled.
   */
  get audioExpected(): boolean {
    return this._session.audioExpected;
  }

  /**
   * True if audio was requested to be available.
   */
  get audioRequested(): boolean {
    return this._session.audioRequested;
  }

  get blindTransferInProgress(): boolean {
    return this._session.blindTransferInProgress;
  }

  /**
   * True if call is connected.
   */
  get connected(): boolean {
    return this.active && this.session.connectedAt !== undefined;
  }

  /**
   * When the call connected otherwise undefined.
   */
  get connectedAt(): Date | undefined {
    return this._connectedAt;
  }

  /**
   * True if call is connecting (outbound call progress).
   */
  get connecting(): boolean {
    return (
      this.active &&
      this.session.connectingAt !== undefined &&
      this.session.connectedAt === undefined
    );
  }

  /**
   * When the call received first indication of progress otherwise undefined.
   */
  get connectingAt(): Date | undefined {
    return this._connectingAt;
  }

  /**
   * True if call has ended.
   */
  get ended(): boolean {
    return this._stage === "ended";
  }

  /**
   * When the call ended otherwise undefined.
   */
  get endedAt(): Date | undefined {
    return this._endedAt;
  }

  /**
   * True if the call is on hold.
   */
  get hold(): boolean {
    return this._session.hold;
  }

  /**
   * True if the call is in the middle of a hold/unhold request
   */
  get holdInProgress(): boolean {
    return this._session.holdInProgress;
  }

  /**
   * True if the call is incoming.
   */
  get incoming(): boolean {
    return this._incoming;
  }

  /**
   * True if call is in initial stage.
   */
  get init(): boolean {
    return this._stage === "init";
  }

  /**
   * When call deactivated.
   */
  get lastAt(): Date | undefined {
    return this._lastAt;
  }

  /**
   * The display name of the local peer.
   */
  get localDisplayName(): string | undefined {
    return this._session.localDisplayName;
  }

  /**
   * The URI of the local peer.
   */
  get localUri(): string | undefined {
    return this._session.localUri;
  }

  /**
   * Maximum time for session in milliseconds.
   */
  get maxCallTime(): number | undefined {
    return this._session.maxCallTime;
  }

  /**
   * True if the call is muted.
   */
  get mute(): boolean {
    return this._session.mute;
  }

  /**
   * The display name of the remote peer.
   */
  get remoteDisplayName(): string {
    return this._session.remoteDisplayName;
  }

  /**
   * True if call is new.
   * Implies: active && !connecting && !connected.
   */
  get new(): boolean {
    return this.active && !this.connecting && !this.connected;
  }

  /**
   * When the call was activated otherwise undefined.
   */
  get newAt(): Date | undefined {
    return this._newAt;
  }

  /**
   * When the call first received a provisional response other than 100 Trying - epoch time in milliseconds.
   */
  get progressAt(): Date | undefined {
    return this._progressAt;
  }

  /**
   * True if an incoming call is ringing.
   */
  get ringing(): boolean {
    return this.active && this.incoming && !this.connectedAt;
  }

  /**
   * The Session assoicated with the call.
   */
  get session(): Session {
    return this._session;
  }

  /**
   * The stage of the call.
   */
  get stage(): CallStage {
    return this._stage;
  }

  /**
   * The URI of the remote peer.
   */
  get remoteUri(): URI {
    return this._session.remoteUri;
  }

  /**
   * The UUID of the call.
   */
  get uuid(): string {
    return this._uuid;
  }

  /**
   * True if video is enabled.
   */
  get video(): boolean {
    return this._session.video;
  }

  /**
   * True if video is available to be enabled.
   */
  get videoAvailable(): boolean {
    return this._session.videoAvailable;
  }

  /**
   * True if video is expected to be available to be enabled.
   */
  get videoExpected(): boolean {
    return this._session.videoExpected;
  }

  /**
   * True if video was requested to be available.
   */
  get videoRequested(): boolean {
    return this._session.videoRequested;
  }

  /**
   * Custom data if available. Undefined otherwise.
   */
  get xData(): string | undefined {
    return this._session.xData;
  }

  protected didConnected(): boolean {
    if (!this.stageAssert("active")) return false;
    if (this._connectedAt) return false;
    this._connectedAt = new Date();
    this.publishState();
    this.publishEvent(new ConnectedCallEvent(this.uuid));
    return true;
  }

  protected didConnecting(): boolean {
    if (!this.stageAssert("active")) return false;
    if (this._connectingAt) return false;
    this._connectingAt = new Date();
    this.publishState();
    this.publishEvent(new ConnectingCallEvent(this.uuid));
    return true;
  }

  protected didEnd(reason: EndCallEventReason): boolean {
    if (!this.stageAssert("active")) return false;
    this.stageTransition("ended");
    this._endedAt = new Date();
    this.publishState();
    this.publishEvent(new EndCallEvent(this.uuid, reason));
    return true;
  }

  protected didLast(): boolean {
    if (!this.stageAssert("ended")) return false;
    if (this._lastAt) return false;
    this._lastAt = new Date();
    this.publishState();
    this.publishEvent(new LastCallEvent(this.uuid));
    return true;
  }

  protected didNew(): boolean {
    if (!this.stageAssert("init")) return false;
    this.stageTransition("active");
    this._newAt = new Date();
    this.publishState();
    this.publishEvent(new NewCallEvent(this.uuid));
    return true;
  }

  protected didProgress(): boolean {
    this._progressAt = new Date();
    this.publishState();
    this.publishEvent(new ProgressCallEvent(this.uuid));
    return true;
  }

  /**
   * Called by state emitter just prior publishing state.
   */
  protected flushState() {
    super.flushState();
    this.stateStore.active = this.active;
    this.stateStore.aor = this.aor;
    this.stateStore.audio = this.audio;
    this.stateStore.audioAvailable = this.audioAvailable;
    this.stateStore.audioExpected = this.audioExpected;
    this.stateStore.audioRequested = this.audioRequested;
    this.stateStore.blindTransferInProgress = this.blindTransferInProgress;
    this.stateStore.connected = this.connected;
    this.stateStore.connectedAt = this.connectedAt ? this.connectedAt.getTime() : undefined;
    this.stateStore.connecting = this.connecting;
    this.stateStore.connectingAt = this.connectingAt ? this.connectingAt.getTime() : undefined;
    this.stateStore.ended = this.ended;
    this.stateStore.endedAt = this.endedAt ? this.endedAt.getTime() : undefined;
    this.stateStore.hold = this.hold;
    this.stateStore.holdInProgress = this.holdInProgress;
    this.stateStore.incoming = this.incoming;
    this.stateStore.init = this.init;
    this.stateStore.lastAt = this.lastAt ? this.lastAt.getTime() : undefined;
    this.stateStore.localDisplayName = this.localDisplayName;
    this.stateStore.localUri = this.localUri;
    this.stateStore.maxCallTime = this.maxCallTime;
    this.stateStore.mute = this.mute;
    this.stateStore.remoteDisplayName = this.remoteDisplayName;
    this.stateStore.new = this.new;
    this.stateStore.newAt = this.newAt ? this.newAt.getTime() : undefined;
    this.stateStore.progressAt = this.progressAt ? this.progressAt.getTime() : undefined;
    this.stateStore.ringing = this.ringing;
    this.stateStore.stage = this.stage;
    this.stateStore.remoteUri = this.remoteUri.toString();
    this.stateStore.uuid = this.uuid;
    this.stateStore.video = this.video;
    this.stateStore.videoAvailable = this.videoAvailable;
    this.stateStore.videoExpected = this.videoExpected;
    this.stateStore.videoRequested = this.videoRequested;
    this.stateStore.xData = this.xData;
  }

  protected onConnected(): void {
    this.didConnected();
  }

  protected onConnecting(): void {
    this.didConnecting();
  }

  protected onEnd(reason: EndCallEventReason): void {
    if (!this.stageAssert("active")) return;
    // if the current session has a replacement, continue on the replacement session if it is still active
    if (this._session.replacement && !this._session.replacement.endedAt) {
      this._session.replacement.ouid = this.uuid;
      this._session = this._session.replacement;
      this.initializeSessionSubscriptions();
      this.publishState();
      return;
    }
    // if the current session was the result of a refer,
    // continue on the session which was referred if it is still active
    if (this._session.referred && !this._session.referred.endedAt) {
      this._session = this._session.referred;
      this.initializeSessionSubscriptions();
      this.publishState();
      return;
    }
    this.didEnd(reason);
  }

  protected onLast(): void {
    this.didLast();
  }

  protected onNew(): void {
    this.didNew();
  }

  protected onProgress(): void {
    this.didProgress();
  }

  protected onReferred(): void {
    if (!this._session.referral) {
      throw new Error(`Call[${this._uuid}] Referral session does not exist`);
    }
    this._session.referral.ouid = this.uuid;
    this._session = this._session.referral;
    this.initializeSessionSubscriptions();
    this.publishState();
  }

  protected onReplaced(): void {
    if (!this._session.replacement) {
      throw new Error(`Call[${this._uuid}] Replacement session does not exist`);
    }
    this._session.replacement.ouid = this.uuid;
    this._session = this._session.replacement;
    this.initializeSessionSubscriptions();
    this.publishState();
  }

  protected onTransferFailed(): void {
    this.publishEvent(new TransferFailedEvent(this.uuid));
  }

  protected onVideo(): void {
    if (this._videoAt) return;
    this._videoAt = new Date();
    this.publishEvent(new VideoCallEvent(this.uuid));
  }

  /**
   * True if stage is current stage.
   * False if stage is earlier than current stage.
   * Throws if stage is later than current stage.
   * @param stage Stage to assert.
   */
  protected stageAssert(stage: CallStage): boolean {
    if (stage !== "init" && stage !== "active" && stage !== "ended") {
      throw new Error(`Call[${this._uuid}].stageAssert ${stage} invalid stage.`);
    }
    if (stage === "active" && this._stage === "init") {
      throw new Error(`Call[${this._uuid}].stageAssert ${stage}.`);
    }
    if (stage === "ended" && (this._stage === "init" || this._stage === "active")) {
      throw new Error(`Call[${this._uuid}].stageAssert ${stage}.`);
    }
    return this._stage === stage;
  }

  /**
   * Transitions the current stage.
   * Throws stage transition is invalid.
   * @param stage Stage to transition to.
   */
  protected stageTransition(stage: CallStage): void {
    if (stage !== "init" && stage !== "active" && stage !== "ended") {
      throw new Error(`Call[${this._uuid}].stageTransition ${stage} invalid stage.`);
    }
    if (
      stage === "init" ||
      (stage === "active" && this._stage !== "init") ||
      (stage === "ended" && this._stage !== "active")
    ) {
      throw new Error(`Call[${this._uuid}].stageTransition ${this._stage} -> ${stage}.`);
    }
    this._stage = stage;
  }

  /**
   * Terminates the call.
   */
  protected terminate(): Promise<void> {
    if (!this.stageAssert("active")) return Promise.resolve();

    // end the call
    let reason: EndCallEventReason;
    if (this.connectedAt) {
      reason = EndCallEventReason.Terminated;
    } else {
      reason = this.incoming ? EndCallEventReason.Unanswered : EndCallEventReason.Cancelled;
    }
    if (this.ended) {
      return Promise.resolve();
    }
    this.didEnd(reason);

    // complete the call
    this.didLast();
    this.publishEventComplete();
    this.publishStateComplete();

    return Promise.all(
      [this._session, this._session.replacement, this._session.referred].map(session =>
        session && !session.endedAt ? session.terminate() : Promise.resolve()
      )
    ).then(() => Promise.resolve());
  }

  private initializeSessionSubscriptions() {
    // our subscription to session events
    if (this._sessionEventSubscription) {
      this._sessionEventSubscription.unsubscribe();
    }
    this._sessionEventSubscription = this._session.event.subscribe({
      next: x => {
        log.debug(`Call[${this.uuid}].event ${x.id}`);
        switch (x.id) {
          case NewSessionEvent.id:
            this.onNew();
            break;
          case ConnectingSessionEvent.id:
            this.onConnecting();
            break;
          case ConnectedSessionEvent.id:
            this.onConnected();
            break;
          case EndSessionEvent.id:
            switch (x.reason) {
              case "cancelled":
                this.onEnd(EndCallEventReason.Cancelled);
                break;
              case "failed":
                this.onEnd(EndCallEventReason.Failed);
                break;
              case "unanswered":
                this.onEnd(EndCallEventReason.Unanswered);
                break;
              case "terminated":
                this.onEnd(EndCallEventReason.Terminated);
                break;
              default:
                throw new Error(`Unrecognized EndSessionEvent reason '${x.reason}'`);
            }
            break;
          case LastSessionEvent.id:
            this.onLast();
            break;
          case ProgressSessionEvent.id:
            this.onProgress();
            break;
          case ReferredSessionEvent.id:
            this.onReferred();
            break;
          case ReplacedSessionEvent.id:
            this.onReplaced();
            break;
          case TransferFailedSessionEvent.id:
            this.onTransferFailed();
            break;
          default:
            break;
        }
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        this._sessionEventSubscription = undefined;
        this.publishEventComplete();
      }
    });

    // session state observer and associated subscription
    if (this._sessionStateSubscription) {
      this._sessionStateSubscription.unsubscribe();
    }
    this._sessionStateSubscription = this.session.state.subscribe({
      next: () => {
        this.publishState();
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        this._sessionStateSubscription = undefined;
        this.publishStateComplete();
      }
    });
  }
}
