import { Injectable } from "@angular/core";
import { distinctUntilChanged, filter, map, take } from "rxjs/operators";
import { Config } from "../../../../config";
import {
  ApiAction,
  ApiAddAction,
  ApiBrowseAction,
  ApiDeleteAction,
  ApiEditAction,
  ApiReadAction
} from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { ParameterValue } from "../../util/api-action-description";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { Recording, ApiRecording, apiRecordingToRecording as clean } from "./recording";
// @ts-ignore: this dep is untyped, so ts yells at us because it can't find any declarations
import { fetch as polyfillFetch } from "whatwg-fetch";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { lastValueFrom } from "rxjs";
declare const polyfillFetch: any; // this dep is untyped- tell typescript it's an any

const debug = false;

@Injectable({ providedIn: "root" })
export class RecordingService extends ApiResourceService<Recording> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Recording", "recordingId");
    debug && this.state.subscribe(state => console.warn("RecordingService", state));
  }

  recordingBrowse(extraParams: Record<string, ParameterValue> = {}): ApiPromiseState<Recording> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.RecordingBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 2500, // should fetch all
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.RecordingBrowse);
  }

  recordingRead(RecordingId: string): ApiPromiseState<Recording> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.RecordingRead,
        SessionId: this.store.state.pipe(sessionId()),
        RecordingId
      }
    });
    return this.promiseState.toPromise(ApiReadAction.RecordingRead);
  }

  // Number is a required parameter but we shouldn't let the user modified it
  recordingEdit(params: {
    RecordingId: string;
    Name: string;
    Number: string;
  }): ApiPromiseState<Recording> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.RecordingEdit,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.RecordingEdit);
  }

  // this should only be called after a recordingBrowse or the increment number part will be off
  recordingAdd(Name: string, OrganizationId?: string): ApiPromiseState<Recording> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.RecordingAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: OrganizationId || this.store.state.pipe(organizationId()),
        Name,
        Number: this.state.pipe(state =>
          state.pipe(
            map(state => {
              const lastNumber = Math.max(
                ...Object.values(state.state).map(recording => parseInt(recording.number))
              );
              return lastNumber > 0 ? lastNumber + 1 : 1;
            }),
            filter(<T>(val: T): val is NonNullable<T> => !!val),
            distinctUntilChanged()
          )
        )
      }
    });
    return this.promiseState.toPromise(ApiAddAction.RecordingAdd);
  }

  recordingDelete(RecordingId: string): ApiPromiseState<ApiDeleteAction.RecordingDelete> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.RecordingDelete,
        SessionId: this.store.state.pipe(sessionId()),
        RecordingId
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.RecordingDelete);
  }

  async recordingUpload(
    RecordingId: string,
    file: File | Blob | undefined
  ): ApiPromiseState<Recording> {
    const fetchResult = await lastValueFrom(this.store.state.pipe(sessionId(), take(1))).then(
      sessionId => {
        const actionUrl = Config.ADMIN_API_URL + "?Action=" + ApiAction.RecordingUpload;
        const formData = new FormData();
        let blob: Blob | "";
        if (!file) {
          blob = "";
        } else if (file instanceof Blob) {
          blob = file;
        } else {
          blob = new Blob([file], { type: "audio/wav" });
        }
        formData.append("File", blob || "");
        formData.append("SessionId", sessionId || "");
        formData.append("RecordingId", RecordingId);
        formData.append("Type", "audio/wav");
        formData.append("Output", "json");

        return (fetch || polyfillFetch)(actionUrl, { method: "POST", body: formData });
      }
    );
    const res = await fetchResult.json();
    // we want to return any error that is coming out of the recording upload api so check for the errors object
    const errors = res.Response.Context.Request.Errors;
    if (errors) {
      const data = {
        code: errors.Error.Code,
        message: errors.Error.Message,
        parameter: errors.Error.Parameter,
        action: ApiAction.RecordingUpload,
        Response: res.Response as OnsipApiResponse
      };
      return {
        status: "error",
        action: ApiAction.RecordingUpload,
        data
      };
    } else {
      // if there are no errors, we can update the service state with a recording read
      return this.recordingRead(RecordingId);
    }
  }

  getRecordingUrl(RecordingId: string): string {
    let SessionId;
    this.store.state.pipe(sessionId(), take(1)).subscribe(sessionId => {
      SessionId = sessionId;
    });
    const params = {
      RecordingId,
      SessionId,
      Action: "RecordingFileRead"
    };
    let urlString: string = Config.ADMIN_API_URL + "?";
    Object.keys(params).forEach((key, idx) => {
      urlString += (idx > 0 ? "&" : "") + key + "=" + (params as any)[key];
    });
    return urlString;
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.RecordingBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiRecording>>(response, action, "Recording", "Recordings").map(
              clean
            ),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiReadAction.RecordingRead:
      case ApiEditAction.RecordingEdit:
      case ApiAddAction.RecordingAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiRecording>(response, action, "Recording")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.RecordingDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedRecording = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "RecordingId")?.Value;
        deletedRecording &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedRecording]: undefined }, action);
        break;
    }
  }
}
