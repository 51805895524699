import { Injectable, inject } from "@angular/core";
import { VoicemailService } from "@onsip/common/services/api/resources/voicemail/voicemail.service";
import { SnackbarService } from "../../../../../shared/components/snackbar/snackbar.service";
@Injectable({
  providedIn: "root"
})
export class VoicemailEditService {
  voicemailService = inject(VoicemailService);
  snackbarService = inject(SnackbarService);

  addGreetingRecord(
    isNewRecording: boolean,
    recording: File,
    fullCurrentVoicemailboxId: string,
    advVoicemailboxId?: string
  ): Promise<void> {
    if (!isNewRecording) {
      return Promise.resolve();
    }
    return this.voicemailService
      .advVoicemailboxGreetingUpload(
        "UnavailableGreeting",
        advVoicemailboxId ?? fullCurrentVoicemailboxId,
        recording
      )
      .then(response => {
        if (response.status === "error") {
          this.snackbarService.openSnackBar(
            "To save, make sure file is wav format and less than 1 min long",
            "error"
          );
          this.voicemailService.clearErrors();
          throw Error("To save, make sure file is wav format and less than 1 min long");
        }
      });
  }
}
