import { Injectable } from "@angular/core";
import { ApiResourceService } from "../api-resource.service";
import { ApiPackage, Package, apiPackageToPackage as clean } from "./package";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiBrowseAction, ApiEditAction, ApiGenericAction } from "../../api-actions";
import { accountId } from "../../apiParams/account-id";
import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";

import { Observable, distinctUntilChanged } from "rxjs";
import { filter, map, take } from "rxjs/operators";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { Package };

const debug = false;

@Injectable({ providedIn: "root" })
export class PackageService extends ApiResourceService<Package> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Package", "packageId");
    debug && this.state.subscribe(state => console.warn("Package", state));

    // clear store and reset all if there is changed in users
    this.store.state
      .pipe(userId())
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.dispose();
      });
  }

  /** return observable of primary packages assigned to account
   * check package-status.ts on what is considered a primary package
   */
  getAssignedPrimaryPackages(): Observable<Array<Package>> {
    return this.state.pipe(
      filter(state => !state.loading),
      map(state => {
        const packages = Object.values(state.state);
        return packages.filter(
          plan =>
            plan.assigned &&
            (plan.status === "available" ||
              plan.status === "discontinued" ||
              plan.status === "user")
        );
      })
    );
  }

  /** packageBrowseAssigned fetchs the packages assigned to given account */
  packageBrowseAssigned(Limit?: number): ApiPromiseState<Package> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.PackageBrowseAssigned,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Limit
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.PackageBrowseAssigned);
  }

  /** packageBrowseComplementary fetches all the conference suite and minutes bundles */
  packageBrowseComplementary(Limit?: number): ApiPromiseState<Package> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.PackageBrowseComplementary,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Limit: Limit || 50
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.PackageBrowseComplementary);
  }

  // packageAssign fetched package does not have an assigned parameter
  // and will need to call packageBrowseAssigned to get newly assigned package
  packageAssign(PackageId: string): ApiPromiseState<Package> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.PackageAssign,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        PackageId
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.PackageAssign);
  }

  packageDeassign(PackageId: string): ApiPromiseState<Package> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.PackageDeassign,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        PackageId
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.PackageDeassign);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.PackageBrowseAssigned:
      case ApiBrowseAction.PackageBrowseComplementary:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiPackage>>(response, action, "Package", "Packages").map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiGenericAction.PackageAssign:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiPackage>(response, action, "Package"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiGenericAction.PackageDeassign: {
        // api response assigned should be undefined after deassignment
        const cleanResponse = clean(extractData<ApiPackage>(response, action, "Package"));
        cleanResponse.assigned = undefined;
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord([cleanResponse], this.indexKeyName),
          action
        );
        break;
      }
      case ApiEditAction.AccountEditUnlimitedUserPricing: {
        // so assigning the unlimited package is done through the account service api and does not return a package response
        // To get the unlimited package we will have to call packageBrowseAssigned
        // in the subscription below: we are finding the unlimited package if it existed in the store
        // then if we are deassigning or disabling the package, we set the assigned parameter to undefined
        this.state
          .pipe(
            map(state => Object.values(state.state).filter(plan => plan.assigned)),
            take(1)
          )
          .subscribe(packages => {
            const unlimitedPackage = packages.find(
              pack => pack.displayName === "Per Seat Unlimited Plan"
            );
            if (unlimitedPackage && response.Context.Request.Parameters) {
              const enabledUnlimitedPlan = onsipApiArrayToArray(
                response.Context.Request.Parameters,
                "Parameter"
              ).find(param => param.Name === "Enabled")?.Value;
              if (enabledUnlimitedPlan === "false") {
                unlimitedPackage.assigned = undefined;
                this.store.mergeStateUpdate(
                  this.resourceName,
                  arrayToRecord([unlimitedPackage], this.indexKeyName),
                  action
                );
              }
            }
          });
        break;
      }
    }
  }
}
