import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { views } from "../../../../app/phone/views";

import { Subscription } from "rxjs";
import { filter, map, take, takeWhile, distinctUntilChanged, skipWhile } from "rxjs/operators";

import { FreeTrialService } from "./free-trial.service";
import {
  FreeTrialExpiringModalComponent,
  FreeTrialModalAction
} from "./free-trial-expiring-modal.component";
import { FreeTrialWelcomeModalComponent } from "./free-trial-welcome-modal.component";

// no template just a container to trigger dialogs
@Component({
  selector: "onsip-free-trial-container",
  template: "",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeTrialContainerComponent implements OnInit, OnDestroy {
  /** Flag for Welcome Modal being opened, only maximum of once per login */
  private seenWelcomeModal = false;
  /** Flag for Expiring Modal being opened, only open once in the 'expiring' state once */
  private seenExpiringModal = false;
  /** Flag for Expiring Modal being dismissed, only reopen if dismissed before timeout */
  private dismissedExpiringModal = false;
  private unsubscriber = new Subscription();

  constructor(
    private dialog: MatDialog,
    private freeTrialService: FreeTrialService,
    private router: Router
  ) {}

  ngOnInit() {
    this.unsubscriber.add(
      this.freeTrialService.state
        .pipe(
          filter(state => state.isFreeTrialAccount),
          takeWhile(state => !state.trialExpired)
        )
        .subscribe(state => {
          // Free Trial Account and First Login, open maximum of one time
          if (state.isFreeTrialAccount && state.showWelcomeModal && !this.seenWelcomeModal) {
            this.seenWelcomeModal = true;
            this.triggerWelcomeDialog(); // Open welcome dialog once only on first login
          }

          // 30 seconds left in trial, open maximum of one time
          if (
            state.timeRemaining !== undefined &&
            state.timeRemaining <= 30000 &&
            !this.seenExpiringModal
          ) {
            this.seenExpiringModal = true;
            this.triggerExpiringDialog();
          }
        })
    );

    // freeTrialService goes from counting down (with pstn calls) to no calls
    this.unsubscriber.add(
      this.freeTrialService.state
        .pipe(
          takeWhile(state => !state.trialExpired), // if trial is already expired
          map(state => state.timeRemaining === undefined),
          skipWhile(allCallsEnded => !!allCallsEnded), // skip initial
          distinctUntilChanged(), // only fire on changed from false to true = allCallsEnded "event"
          filter(allCallsEnded => !!allCallsEnded),
          take(3) // extra precaution since this calls api, shouldn't be possible more than 3 times
        )
        .subscribe(allCallsEnded => {
          if (allCallsEnded) this.freeTrialService.checkEarlyEnd();
        })
    );

    // Fire once, only when trial transitions to expired
    this.unsubscriber.add(
      this.freeTrialService.state
        .pipe(
          map(
            state =>
              state.trialExpired && state.trialExpired !== undefined && state.timeRemaining === 0
          ),
          skipWhile(expired => !expired), // skip initial state until we see not expired
          distinctUntilChanged(),
          filter(expired => !!expired),
          take(1)
        )
        .subscribe(() => {
          // Don't open a second modal if one is already open
          if (!this.seenExpiringModal || this.dismissedExpiringModal) this.triggerExpiredDialog();
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  /** Open Expiring Dialog, set dismissed flag and do action when closed */
  private triggerExpiringDialog() {
    const modal = this.dialog.open(FreeTrialExpiringModalComponent, {
      data: {
        expiring: true
      },
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      width: "480px"
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe((action: FreeTrialModalAction | undefined) => {
          this.dismissedExpiringModal = true;
          if (action === "contact-sales") {
            this.router.navigate([views.CONTACT_SALES]);
          } else if (action === "end-calls") {
            this.freeTrialService.endFreeTrialCalls();
          }
        })
    );
  }

  /** Open Expired Dialog, do action when closed */
  private triggerExpiredDialog() {
    const modal = this.dialog.open(FreeTrialExpiringModalComponent, {
      data: {
        expiring: false
      },
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      width: "480px"
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(action => {
          if (action === "contact-sales") {
            this.router.navigate([views.CONTACT_SALES]);
          }
        })
    );
  }

  /** Open Welcome Dialog */
  private triggerWelcomeDialog() {
    const modal = this.dialog.open(FreeTrialWelcomeModalComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"], // global material styles on dialog
      width: "480px" // const width 80% of small size
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          this.freeTrialService.setFreeTrialWelcomeModal(false);
          this.freeTrialService.setFreeTrialWarningBar(true);
        })
    );
  }
}
