import { DialpadService } from "./dialpad.service";

import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[onsipDtmfEnabled]"
})
export class DialpadInputDirective {
  @Input() dtmfEnabled = false;
  @Input() isDialpadComponent = false;
  @Input() target: "newCall" | "call" | "instacall" = "call";

  private validInputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

  // NOTE: dialpadService checks are for IE11, where it isn't defined for some reason (not worth exploring)
  constructor(private dialpadService: DialpadService) {}

  @HostListener("buttonPress", ["$event"]) onDialpadButton(key: string): void {
    if (
      this.dtmfEnabled &&
      this.dialpadService &&
      this.validInputs.some(innerKey => innerKey === key)
    ) {
      this.dialpadService.startKey(key, "button", this.target);
    }
  }

  @HostListener("buttonUp", ["$event"]) onButtonUp(key: string): void {
    if (
      this.dtmfEnabled &&
      this.dialpadService &&
      this.validInputs.some(innerKey => innerKey === key)
    ) {
      this.dialpadService.stopKey(key, "button", this.target);
    }
  }

  @HostListener("keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    if (
      this.dtmfEnabled &&
      this.dialpadService &&
      !this.isDialpadComponent &&
      this.validInputs.some(key => key === event.key)
    ) {
      this.dialpadService.startKey(event.key, "keyboard", this.target);
    }
  }

  @HostListener("keyup", ["$event"]) onKeyUp(event: KeyboardEvent): void {
    if (!this.dialpadService) {
      return;
    } else if (
      this.dtmfEnabled &&
      !this.isDialpadComponent &&
      this.validInputs.some(key => key === event.key)
    ) {
      this.dialpadService.stopKey(event.key, "keyboard", this.target);
    } else if (this.dtmfEnabled && !this.isDialpadComponent && event.key === "Shift") {
      this.dialpadService.stopKey("*", "keyboard", this.target);
      this.dialpadService.stopKey("#", "keyboard", this.target);
    }
  }
}
