import { Injectable } from "@angular/core";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import {
  AdvQueueWarning,
  ApiEditAdvQueueWarningParams,
  ApiUnsubscribeAdvQueueWarningParams
} from "./adv-queue-warning";

const debug = false;

@Injectable({ providedIn: "root" })
export class AdvQueueWarningService extends ApiResourceService<AdvQueueWarning> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "AdvQueueWarning", "AdvQueueWarningId");
    debug && this.state.subscribe(state => console.warn("AdvQueueWarning", state));
  }

  advQueueWarningBrowse(params: { Limit?: number }): ApiPromiseState<AdvQueueWarning> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.AdvQueueWarningBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Limit: params.Limit || 250
      }
    });

    return this.promiseState.toPromise(ApiAction.AdvQueueWarningBrowse);
  }

  advQueueWarningAdd(parameters: ApiEditAdvQueueWarningParams): ApiPromiseState<AdvQueueWarning> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.AdvQueueWarningAdd,
        UserId: this.store.state.pipe(userId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...parameters
      }
    });

    return this.promiseState.toPromise(ApiAction.AdvQueueWarningAdd);
  }

  advQueueWarningEdit(parameters: ApiEditAdvQueueWarningParams): ApiPromiseState<AdvQueueWarning> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.AdvQueueWarningEdit,
        UserId: this.store.state.pipe(userId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...parameters
      }
    });

    return this.promiseState.toPromise(ApiAction.AdvQueueWarningEdit);
  }

  advQueueWarningDelete(advQueueWarningId: string): ApiPromiseState<AdvQueueWarning> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.AdvQueueWarningDelete,
        UserId: this.store.state.pipe(userId()),
        SessionId: this.store.state.pipe(sessionId()),
        AdvQueueWarningId: advQueueWarningId
      }
    });

    return this.promiseState.toPromise(ApiAction.AdvQueueWarningDelete);
  }

  advQueueWarningUnsubscribe(
    params: ApiUnsubscribeAdvQueueWarningParams
  ): ApiPromiseState<AdvQueueWarning> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.AdvQueueWarningUnsubscribe,
        ...params
      }
    });

    return this.promiseState.toPromise(ApiAction.AdvQueueWarningUnsubscribe);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.AdvQueueWarningBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<AdvQueueWarning>>(
              response,
              action,
              "AdvQueueWarning",
              "AdvQueueWarnings"
            ),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.AdvQueueWarningAdd:
      case ApiAction.AdvQueueWarningEdit:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<AdvQueueWarning>(response, action, "AdvQueueWarning")],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.AdvQueueWarningDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedQueueWarning = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "AdvQueueWarningId")?.Value;
        deletedQueueWarning &&
          this.store.mergeStateUpdate(
            this.resourceName,
            { [deletedQueueWarning]: undefined },
            action
          );
        break;
      case ApiAction.AdvQueueWarningUnsubscribe:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
