import { CallState, SessionState } from "../../../../common/libraries/firebase/store/call-state";

export interface CallItem {
  id: string;
  /** Who the conversation is with. Empty string if unknown. */
  who: string;
  /** What the target (topic/user/aor) is. Empty string if unknown. */
  what: string;
  /** Where the conversation came from (web page title). Empty string if unknown. */
  where: string;
  /** Where the conversation came from (url). Empty string if unknown. */
  link: string;
  /** When the conversation started (ms epoch time). */
  when: number;
  /** True if conversation was missed. */
  missed: boolean;
  /** True if audio was part of converstation. */
  audio: boolean;
  /** True if video was part of converstation. */
  video: boolean;
  /** True if HubSpot vistor token availble. */
  hubspot: boolean;
  /** What the display name of the target is, if any -> needs config, so logic is in component */
  whatDisplay: string;
}

export function makeCallItem(state: CallState, aor: string | undefined): CallItem {
  const session = pickPrimarySession(state, aor);
  const id = state.id;
  const who = whoFrom(state, aor);
  const what = whatFrom(state, aor);
  const where = whereFrom(state);
  const link = linkFrom(state);
  const when = whenFrom(state, aor, session);
  const missed = missedFrom(state, aor, session);
  const audio = audioFrom(state, aor, session);
  const video = videoFrom(state, aor, session);
  const hubspot = hubspotFrom(state);
  const whatDisplay = whatDisplayFrom(state, aor);
  return { id, who, what, where, link, when, missed, audio, video, hubspot, whatDisplay };
}

function whoFrom(state: CallState, aor: string | undefined): string {
  let who = "";
  if (aor) {
    Object.keys(state.sessions).forEach(key => {
      const session = state.sessions[key];
      if (session.remoteUri.includes(aor)) {
        who = session.userAgent.displayName;
      }
    });
  }
  return who;
}

function whatFrom(state: CallState, aor: string | undefined): string {
  const user = aor ? aor.split("@", 2)[0] : undefined;
  let what = "";
  switch (state.targetType) {
    case "aor":
      what = state.target;
      break;
    case "user":
      what = user ? `@${user}` : `@${state.target}`;
      break;
    case "topic":
      what = `#${state.target}`;
      break;
    default:
      what = "";
      break;
  }
  return what;
}

function whereFrom(state: CallState): string {
  if (state.context.browser && state.context.browser.title) return state.context.browser.title;
  if (state.context.browser && state.context.browser.href) return state.context.browser.href;
  return "";
}

function linkFrom(state: CallState): string {
  if (state.context.browser && state.context.browser.href) return state.context.browser.href;
  return "";
}

function whenFrom(
  state: CallState,
  aor: string | undefined,
  session: SessionState | undefined
): number {
  return session && session.activatedAt ? session.activatedAt : state.created.seconds * 1000;
}

function missedFrom(
  state: CallState,
  aor: string | undefined,
  session: SessionState | undefined
): boolean {
  return session ? (session.connectedAt ? false : true) : false;
}

function audioFrom(
  state: CallState,
  aor: string | undefined,
  session: SessionState | undefined
): boolean {
  return session ? true : false;
}

function videoFrom(
  state: CallState,
  aor: string | undefined,
  session: SessionState | undefined
): boolean {
  return session && session.video ? true : false;
}

function hubspotFrom(state: CallState): boolean {
  const hubspot = state.context.cookie && state.context.cookie.hubspotutk;
  return hubspot ? true : false;
}

function whatDisplayFrom(state: CallState, aor: string | undefined): string {
  if (state.targetDisplay) return state.targetDisplay;
  const user = aor ? aor.split("@", 2)[0] : undefined;
  let what = "";
  switch (state.targetType) {
    case "aor":
      what = state.target;
      break;
    case "user":
      what = user ? `@${user}` : `@${state.target}`;
      break;
    case "topic":
      what = state.target;
      break;
    default:
      what = "";
      break;
  }
  return what;
}

function pickPrimarySession(state: CallState, aor: string | undefined): SessionState | undefined {
  if (!aor) {
    return undefined;
  }
  const sessions: Array<SessionState> = [];
  Object.keys(state.sessions).forEach(key => {
    sessions.push(state.sessions[key]);
  });
  const prioritized = sessions
    .filter(session => session.remoteUri.includes(aor))
    .sort((a, b) => {
      // sessions that connected have priority over sessions that did not connect
      // sessions that occured earlier have priority over sessions that occured later
      if (a.connectedAt && b.connectedAt) return a.connectedAt - b.connectedAt;
      if (a.connectedAt && !b.connectedAt) return -1;
      if (!a.connectedAt && b.connectedAt) return 1;
      if (a.activatedAt && b.activatedAt) return a.activatedAt - b.activatedAt;
      if (a.activatedAt && !b.activatedAt) return -1;
      if (!a.activatedAt && b.activatedAt) return 1;
      return 0;
    });
  if (prioritized.length) {
    return prioritized[0];
  }
  return undefined;
}
