import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject } from "rxjs";
import { AdminTableAbstractComponent } from "../../abstractClass/admin-table-abstract.component";

interface TableData {
  date: string;
  time: string;
  name: string;
  status: string;
  id: string;
}

@Component({
  selector: "onsip-developer-table",
  templateUrl: "./developer-table.component.html",
  styleUrls: ["./developer-table.component.scss"]
})
export class DeveloperTableComponent
  extends AdminTableAbstractComponent<TableData>
  implements OnInit
{
  protected displayedColumnsRecord = {
    XSmall: ["name", "actions"],
    Small: ["date", "name", "actions"],
    Medium: ["date", "name", "status", "actions"],
    Large: ["date", "time", "name", "status", "actions"],
    XLarge: ["date", "time", "name", "status", "actions"]
  };
  // examples on what kind of table input you can use
  recentCalls: Array<TableData> = [
    { date: "4/1/2020", time: "3:45:00 PM", name: "Alice", status: "Answered", id: "1" },
    { date: "3/31/2020", time: "1:00:34 PM", name: "Bob", status: "Missed", id: "2" },
    { date: "3/31/2020", time: "10:40:12 AM", name: "Carol", status: "Cancelled", id: "3" },
    { date: "3/30/2020", time: "12:40:09 AM", name: "Karen", status: "Answered", id: "4" }
  ];

  recentCallsBS = new BehaviorSubject<Array<TableData>>(this.recentCalls);
  dataSource = new MatTableDataSource(this.recentCalls);

  protected getItemName({ name }: TableData): string {
    return name;
  }

  protected getItemValueByColumn(item: TableData, property: string): string | number {
    switch (property) {
      case "name":
        return item.name.toLowerCase();
      case "date":
        return item.date?.toLowerCase();
      case "time":
        return item.time?.toLowerCase();
      case "status":
        return item.status?.toLowerCase();
      default:
        return item.name.toLowerCase();
    }
  }

  protected initDataSource(): void {}

  protected performDeleteRequest(item: TableData): void {
    this.recentCalls = this.dataSource.data;
    this.dataSource = new MatTableDataSource(this.recentCalls.filter(el => el.id !== item.id));
  }
}
