import { CallEvent, EventId } from "./call-event";

/** Emitted once when Call video is enabled. */
export class VideoCallEvent extends CallEvent {
  id = EventId.VideoCallEventId;

  /**
   * Factory for VideoCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isVideoCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for VideoCallEvent.
 * @param event Event to check.
 */
export function isVideoCallEvent(event: any): event is VideoCallEvent {
  return event instanceof VideoCallEvent;
}
