import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ChatLineService {
  /****
   *  a chat event that represents text being added to a chat.
   *
   *   ts: the canonical recorded timestamp of the message.  all messages within
   *     a single channel are guaranteed to have a unique timestamp which is ASCII sortable.
   *     given the precision of the timestamp, clients should treat these timestamps as strings,
   *     not floats/doubles gauranteed to be unique
   *
   *     pending events do not have a ts until they are acked by the rtm server. For sorting,
   *     give them a temporary ts and replace it with the confirmed ts later.  There is a possibility
   *     of ts collision, but for now not going to worry about it.
   */
  constructor() {}

  createChatLine(
    chatBodyId: any,
    text: any,
    isPending: any,
    provider: string,
    timestamp: any,
    data: any
  ): any {
    const chatLine: any = {
      data: data || {},
      id: chatBodyId,
      text: text || "",
      timestamp: timestamp || new Date().getTime() / 1000
    };

    return chatLine;
  }
}
