import { Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { AgentPresencePublisher } from "../../../libraries/firebase/realtime/agent-presence-publisher";
import { Call } from "../../../libraries/firebase/store/call";
import {
  CallerPresenceListener,
  CallerPresenceState
} from "../../../libraries/firebase/realtime/caller-presence-listener";
import { CallState } from "../../../libraries/firebase/store/call-state";
import { StateEmitter } from "../../../libraries/emitter/state-emitter";
import { compareTimestamp } from "../../../libraries/firebase/store/timestamp";

import { AuthService, AuthState } from "../auth.service";

export { CallState };

export class UserAgentCallsPresent extends StateEmitter<Array<CallState>> {
  private authState: AuthState | undefined;
  private callerPresenceListener: CallerPresenceListener | undefined;
  private callMap = new Map<string, Call>();
  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(private _target: string, protected auth: AuthService) {
    super([]);
    this.init();
  }

  dispose(): void {
    this.publishStateComplete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stopCallerPresence();
  }

  get target(): string {
    return this._target;
  }

  private get oid(): number {
    if (!this.authState || !this.authState.oid) {
      throw new Error("oid is undefined.");
    }
    return this.authState.oid;
  }

  private get uid(): number {
    if (!this.authState || !this.authState.uid) {
      throw new Error("uid is undefined.");
    }
    return this.authState.uid;
  }

  private init(): void {
    Promise.resolve().then(() => {
      this.subscribeAuth();
    });
  }

  private signedIn(): void {
    this.startCallerPresence();
  }

  private signedOut(): void {
    this.stopCallerPresence();
  }

  private subscribeAuth(): void {
    this.auth.state.pipe(takeUntil(this.unsubscribe)).subscribe(state => {
      const didChange = AuthService.didAuthStatusChange(this.authState, state);
      this.authState = state;
      switch (didChange) {
        case "signedIn":
          return this.signedIn();
        case "signedOut":
          return this.signedOut();
        default:
          return;
      }
    });
  }

  private callHandlingStrategy(callState: CallState): AgentPresencePublisher | undefined {
    const cid = callState.id;
    const agentPresencePublisher = new AgentPresencePublisher(this.oid, cid, this.uid);
    return agentPresencePublisher;
  }

  private subscribeCall(caller: CallerPresenceState): void {
    const cid = caller.cid;
    const call = new Call();
    // If we decide to handle this call as an agent, we are going to publish as an agent for it
    let agentPresencePublisher: AgentPresencePublisher | undefined;
    call.state.pipe(filter(state => state.id !== "")).subscribe({
      next: callState => {
        const idx = this.stateStore.findIndex(innerCall => innerCall.id === cid);
        if (idx === -1) {
          this.stateStore.push(callState);
          agentPresencePublisher = this.callHandlingStrategy(callState);
          if (agentPresencePublisher) {
            agentPresencePublisher.publish();
          }
        } else {
          this.stateStore[idx] = callState;
        }
        this.stateStore = this.stateStore
          .sort((a, b) => compareTimestamp(a.created, b.created))
          .reverse();
        this.publishState();
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        if (agentPresencePublisher) {
          agentPresencePublisher.dispose();
        }
        this.callMap.delete(cid);
        const idx = this.stateStore.findIndex(innerCall => innerCall.id === cid);
        if (idx !== -1) {
          this.stateStore.splice(idx, 1);
          this.publishState();
        }
      }
    });
    call.start(this.oid, cid);
    this.callMap.set(cid, call);
  }

  private startCallerPresence(): void {
    this.callerPresenceListener = new CallerPresenceListener(this.oid, this.target);
    this.callerPresenceListener.state.subscribe({
      next: callers => {
        // dispose of calls that are no longer present
        this.callMap.forEach((call, cid) => {
          if (!callers.some(caller => caller.cid === cid)) {
            call.dispose();
          }
        });
        // subscribe to newly present calls
        callers
          .filter(caller => !this.callMap.has(caller.cid))
          .forEach(caller => this.subscribeCall(caller));
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        this.callMap.forEach(call => {
          call.dispose();
        });
      }
    });
    this.callerPresenceListener.start();
  }

  private stopCallerPresence(): void {
    if (this.callerPresenceListener) {
      this.callerPresenceListener.dispose();
      this.callerPresenceListener = undefined;
    }
  }
}
