import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";
import { PipesModule } from "../../pipes/pipes.module";

import { AudioRecorderComponent } from "./audio-recorder.component";
import { SoundWaveDirective } from "./sound-wave.component";

@NgModule({
  imports: [CommonModule, MaterialModule, PipesModule],
  declarations: [AudioRecorderComponent, SoundWaveDirective],
  exports: [AudioRecorderComponent, SoundWaveDirective]
})
export class AudioRecorderModule {}
