import { SupportService } from "../../../../../common/services/support/support.service";
import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";

import { Injectable } from "@angular/core";
import { StateEmitter } from "../../../../../common/libraries/emitter";

export interface AppCallingState {
  enabled: boolean;
}

// desktopMode being enabled is app calling disabled
@Injectable({ providedIn: "root" })
export class AppCallingService extends StateEmitter<AppCallingState> {
  constructor(
    private supportService: SupportService,
    private localStorageService: LocalStorageService
  ) {
    super({
      enabled: true
    });

    if (!this.supportService.isWebrtcSupported()) {
      this.stateStore.enabled = false;
      this.publishState();
      return;
    }

    this.localStorageService.getDesktopMode().then(desktopModeEnabled => {
      if (desktopModeEnabled) {
        this.stateStore.enabled = false;
        this.publishState();
      }
    });
  }

  enable(): void {
    this.stateStore.enabled = true;
    this.publishState();

    this.localStorageService.setGeneralSettings({ desktopMode: false });
  }

  disable(): void {
    this.stateStore.enabled = false;
    this.publishState();

    this.localStorageService.setGeneralSettings({ desktopMode: true });
  }
}
