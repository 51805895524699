import { EventStateEmitterArray } from "../emitter/event-state-emitter-array";
import { UserAgent, UserAgentState } from "./user-agent";
import { UserAgentEvent } from "./user-agent-event";

/**
 * An observable array of Session objects which never completes.
 */
export class UserAgents extends EventStateEmitterArray<UserAgent, UserAgentEvent, UserAgentState> {
  constructor() {
    super(false); // infinate emitter
  }
  /**
   * Dispose - remove all UserAgents and complete.
   */
  dispose(): void {
    this.publishComplete();
  }
  /**
   * Adds a UserAgent.
   * @param userAgent The UserAgent to add.
   */
  add(userAgent: UserAgent): this {
    return super.add(userAgent);
  }
  /**
   * Gets a UserAgent.
   * @param aor The AOR of the UserAgent.
   */
  get(aor: string): UserAgent | undefined {
    return this.array.find(userAgent => userAgent.aor === aor);
  }
  /**
   * Removes a UserAgent.
   * @param userAgent The UserAgent to remove.
   */
  remove(userAgent: UserAgent): boolean {
    return super.remove(userAgent);
  }
}
