@if (avatarUrl) {
<img [src]="avatarUrl" alt="Avatar" />
} @else {
<svg viewBox="0 0 36 36">
  <circle cx="50%" cy="50%" r="50%" [style.fill]="background" />
  <text
    x="50%"
    y="24"
    text-anchor="middle"
    [style.fill]="background | textColorForCustomBackground"
    font-size="16"
    font-weight="300"
    font-family="Roboto, sans-serif"
  >
    {{ name | initials }}
  </text>
</svg>
<ng-template> </ng-template>
}
