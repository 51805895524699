import { Injectable, OnDestroy } from "@angular/core";
import {
  OrganizationConfiguration,
  ButtonConfigurationMetadata
} from "../interfaces/organization-configuration";
import { Http400Error, ParseConfigurationError } from "./configuration-error";

import { Subscription, Observable, BehaviorSubject } from "rxjs";
import { filter } from "rxjs/operators";

import { UserService } from "./api/resources/user/user.service";

// Configuration File Service is the where s3 configuration is fetched and stored.
// Any services or components that need the configurations should use the getOrgConfig method
@Injectable({ providedIn: "root" })
export class ConfigurationFileService implements OnDestroy {
  private orgConfig = new BehaviorSubject<OrganizationConfiguration>(
    {} as OrganizationConfiguration
  );
  private unsubscriber = new Subscription();

  constructor(private userService: UserService) {
    this.init();
  }

  /** public method to get s3 config. this should be the only one way to retreive config */
  getOrgConfig(): Observable<OrganizationConfiguration> {
    return this.orgConfig.pipe(filter(orgConfig => Object.keys(orgConfig).length > 0));
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  private init() {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(userState => {
        const orgId = userState.organizationId;
        fetchConfig(parseInt(orgId))
          .then(orgConfig => {
            this.orgConfig.next(orgConfig);
          })
          .catch(e => {
            if (e instanceof Http400Error) {
              // means there's no file in s3- initialize the service with a blank configuration and any api actions will upsert the file automagically
              this.orgConfig.next({
                organizationId: parseInt(orgId),
                configurations: [] as Array<ButtonConfigurationMetadata>
              } as OrganizationConfiguration);
            } else {
              throw e;
            }
          });
      })
    );
  }
}

async function fetchConfig(organizationId: number): Promise<OrganizationConfiguration> {
  const url = `https://onsip-cdn-components.s3.amazonaws.com/org-config/${organizationId}.json`;
  const result = await fetch(url).then(response => {
    if (response.status / 400 >= 1) {
      throw new Http400Error(response.statusText);
    } else {
      return response;
    }
  });
  return result.json().catch(e => {
    throw new ParseConfigurationError(e);
  });
}
