import { Injectable } from "@angular/core";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { QueueSummaryParams } from "../queueSummary/queue-summary";
import {
  ApiQueueAgentEvent,
  QueueAgentEvent,
  apiQueueAgentEventToQueueAgentEvent as clean
} from "./queue-agent-event";

const debug = false;

@Injectable({ providedIn: "root" })
export class QueueAgentEventService extends ApiResourceService<QueueAgentEvent> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "QueueAgentEvent", "queueAgentEventId");
    debug && this.state.subscribe(state => console.warn("QueueAgentEventSevice", state));
  }

  queueAgentEventBrowse(params?: QueueSummaryParams): ApiPromiseState<QueueAgentEvent> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.QueueAgentEventBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 25000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.QueueAgentEventBrowse);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.QueueAgentEventBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiQueueAgentEvent>>(
              response,
              action,
              "QueueAgentEvent",
              "QueueAgentEvents"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
