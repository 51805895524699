export interface UserGoogleFirebaseTokenApi {
  GoogleFirebaseToken: string;
  GoogleFirebaseProjectId: string;
}

export interface UserGoogleFirebaseToken {
  googleFirebaseToken: string;
  googleFirebaseProjectId: string;
}

export function convertUserGoogleFirebaseTokenApi(
  apiDate: UserGoogleFirebaseTokenApi
): UserGoogleFirebaseToken {
  return {
    googleFirebaseToken: apiDate.GoogleFirebaseToken,
    googleFirebaseProjectId: apiDate.GoogleFirebaseProjectId
  };
}
