import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { URI, Subscriber } from "sip.js";

import { Subscription, SubscriptionState } from "./subscription";
import { Subscriptions } from "./subscriptions";
import { ConnectedSubscriptionEvent, EndSubscriptionEvent } from "./subscription-event";
import {
  AbortSubscriptionError,
  DuplicateSubscriptionError,
  InvalidTargetSubscriptionError,
  NotFoundSubscriptionError,
  UserAgentNotFoundSubscriptionError
} from "./subscription-error";
import { PromiseCompletion } from "./promise-completion";
import { StateEmitter } from "../emitter/state-emitter";
import { UserAgent, UserAgentState } from "./user-agent";
import { UserAgents } from "./user-agents";
import { log } from "./log";
import { SubscriptionSIP } from "./subscription-sip";
import { UserAgentSIP } from "./user-agent-sip";

/** Subscription Controller State */
export interface SubscriptionControllerState {
  /** Subscriptions. */
  subscriptions: Array<SubscriptionState>;
  /** User agents available to create subscriptions. */
  userAgents: Array<UserAgentState>;
}

/**
 * SubscriptionController
 * - Manages creating and ending subscriptions.
 * - Prevents duplicate subscriptions to same presentity.
 * - Serializes initial subscription requests providing backoff.
 * - Rate limits requests to avoid presence server from blocking/throttling.
 */
export class SubscriptionController extends StateEmitter<SubscriptionControllerState> {
  /** Subscriptions. */
  subscriptions: Subscriptions = new Subscriptions();
  /** User agents available to create subscriptions. */
  userAgents: UserAgents = new UserAgents();

  // Queues
  private activateQueue: Array<{ subscription: Subscription; completion: PromiseCompletion }> = [];
  private activateQueueLastAt = 0; // epoch time of last activation
  private activateQueueMinimumInterval = 100;
  private endQueue: Array<{ subscription: Subscription; end: () => void }> = [];
  private endQueueMinimumInterval = 100;

  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor() {
    super({
      subscriptions: [],
      userAgents: []
    });
    this.init();
    log.debug("SubscriptionController Constructed");
  }

  /**
   * Destructor
   */
  dispose(): Promise<void> {
    log.debug("SubscriptionController: Disposing...");
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.disposeSubscriptions();
    this.subscriptions.dispose();
    const disposePromise = this.disposeUserAgents();
    this.userAgents.dispose();
    log.debug("SubscriptionController: Disposed");
    return disposePromise;
  }

  /**
   * Dispose of all Subscriptions (but not the observable)
   */
  disposeSubscriptions(): void {
    log.debug("SubscriptionController.disposeSubscriptions");
    // nothing to do
    if (!this.subscriptions.array.length) {
      return;
    }

    // clear subscriptions queued for activation (rejecting them all)
    this.activateQueue.forEach(next =>
      next.completion.reject(
        new AbortSubscriptionError(`Subscription[${next.subscription.uuid}]: Aborted`)
      )
    );
    this.activateQueue = [];

    // discard any subscriptions which never activated (they would fail to close)
    this.subscriptions.array
      .filter(subscription => !subscription.activatedAt)
      .forEach(subscription => {
        this.subscriptions.remove(subscription);
        subscription.dispose();
      });

    // close all activated subscriptions
    this.subscriptions.array.forEach(subscription => this.endSubscription(subscription));
  }

  /**
   * Dispose of all UserAgents (but not the observable)
   */
  disposeUserAgents(): Promise<void> {
    log.debug("SubscriptionController.disposeUserAgents");
    // time remaining to process the end queue plus 2 seconds (worst case for all user agents)
    const delay = this.endQueue.length * this.endQueueMinimumInterval + 2000;
    return Promise.all(
      this.userAgents.array.map(userAgent =>
        userAgent.dispose(delay).then(() => {
          this.userAgents.remove(userAgent);
        })
      )
    ).then();
  }

  /**
   * The minimum interval in milliseconds between initial subscription requests
   */
  get activateMinimumInterval(): number {
    return this.activateQueueMinimumInterval;
  }

  /**
   * The minimum interval in milliseconds between initial subscription requests
   */
  set activateMinimumInterval(milliseconds: number) {
    this.activateQueueMinimumInterval = milliseconds;
  }

  /**
   * The minimum interval in milliseconds between final subscription requests
   */
  get endMinimumInterval(): number {
    return this.activateQueueMinimumInterval;
  }

  /**
   * The minimum interval in milliseconds between final subscription requests
   */
  set endMinimumInterval(milliseconds: number) {
    this.endQueueMinimumInterval = milliseconds;
  }

  /**
   * Add a UserAgent to use for making subscriptions.
   * @param userAgent UserAgent to add.
   */
  addUserAgent(userAgent: UserAgent): void {
    this.userAgents.add(userAgent);
  }

  /**
   * Creates and activate a new Subscription. Duplicate Subscriptions are not allowed.
   * @param aor The AOR of the UserAgent to use to establish the subscription.
   * @param target The presentity uri to subscribe to.
   * @param eventPackage The event package to subscribe to.
   * @reject {AbortSubscriptionError} Subscription aborted.
   * Occurs when a subscription is disposed prior to being activated.
   * @reject {DuplicateSubscriptionError} Subscription duplicate.
   * Occurs when a matching subscription already exists.
   */
  create(aor: string, target: string, eventPackage: string): Promise<Subscription> {
    log.debug(
      "SubscriptionController.create: aor=" +
        aor +
        " target=" +
        target +
        " eventPackage=" +
        eventPackage
    );
    // find the user agent to use
    const userAgent = this.findUserAgent(aor);
    if (!userAgent) {
      return Promise.reject(
        new UserAgentNotFoundSubscriptionError("Failed to find user agent for " + aor)
      );
    }
    // convert target to a uri
    const uri = userAgent.targetToURI(target);
    if (!uri) {
      return Promise.reject(
        new InvalidTargetSubscriptionError("Failed to create URI from " + target)
      );
    }
    // guard against a user agent creating multiple subscriptions to the same presentity and event package
    // (O(n) time, but important to avoid coding errors)
    const duplicate = this.subscriptions.array.find(
      subscription =>
        subscription.userAgent.aor === userAgent.aor &&
        subscription.uri !== undefined &&
        subscription.uri.aor === uri.aor &&
        subscription.eventPackage === eventPackage &&
        !subscription.endedAt
    );
    if (duplicate) {
      return Promise.reject(
        new DuplicateSubscriptionError(duplicate.uuid, "Subscription: Duplicate")
      );
    }
    // create a new subscription and activate it
    const sub = this.createSubscription(userAgent, uri, eventPackage);
    return this.activateSubscription(sub);
  }

  /**
   * Ends a Subscription.
   * @param uuid The UUID of the Subscription.
   * @reject {NotFoundSubscriptionError} Subscription not found.
   */
  end(uuid: string): Promise<void> {
    log.debug(`SubscriptionController[${uuid}].end`);
    return this.find(uuid).then(subscription => this.endSubscription(subscription));
  }

  /**
   * Returns a Subscription given a UUID.
   * Returns undefined if not found.
   * @param uuid The UUID of the Subscription.
   */
  findSubscription(uuid: string): Subscription | undefined {
    return this.subscriptions.array.find(subscription => subscription.uuid === uuid);
  }

  /**
   * Returns a UserAgent give an AOR.
   * Returns undefined if not found.
   * @param aor The AOR of the UserAgent.
   */
  findUserAgent(aor: string): UserAgent | undefined {
    return this.userAgents.array.find(userAgent => userAgent.aor === aor);
  }

  /**
   * Activates a Subscription.
   * Promise resolution/rejection occurs in activateNextSubscription().
   * @param subscription Subscription to activate.
   */
  protected activateSubscription(subscription: Subscription): Promise<Subscription> {
    log.debug(`SubscriptionController[${subscription.uuid}].activateSubscription`);
    return new Promise<Subscription>((resolve, reject) => {
      const completion = new PromiseCompletion(resolve, reject);
      if (this.activateQueue.push({ subscription, completion }) === 1) {
        // if the subscription we just pushed is only thing in queue, activate it immediately
        this.activateNextSubscription();
      }
    });
  }

  /**
   * Manages the activation queue.
   * Removes the last subscription in the activation queue. If present, it will be activated.
   * Activates the next subscription in the activation queue. If present, it will not be activated.
   * The minimum duration between initial subscription requests is controlled by the value
   * of 'activateQueueMinimumInterval'. The next subscription will not be activated until the previous
   * one has been accepted or rejected (final response received).
   * If activation fails, this rejects and immediately move on to the next pending subscription.
   * If activation succeeds, this resolves and waits until called again.
   * @param delay Time in milliseconds to delay the next subscription.
   */
  protected activateNextSubscription(): void {
    if (!this.activateQueue.length) {
      return; // nothing to do
    }

    // if the head of the queue has been activated shift it,
    // keep track of when it activated and move on to next subscription
    const activatedAt = this.activateQueue[0].subscription.activatedAt;
    if (activatedAt) {
      this.activateQueueLastAt = activatedAt.getTime();
      this.activateQueue.shift();
      this.activateNextSubscription();
      return;
    }

    // the next subscription to be activated
    const next = this.activateQueue[0];

    // the duration since the last activation
    const duration = Date.now() - this.activateQueueLastAt;
    if (duration < 0) {
      const error = new Error(
        "SubscriptionController.activateNextSubscription duration between subscription activations is negative"
      );
      log.error(error.toString());
      throw error;
    }

    // delay the activation of the next subscription if the duration since the
    // previous subscription activation is less than the activation interval (slow down)
    const delay =
      duration < this.activateQueueMinimumInterval
        ? this.activateQueueMinimumInterval - duration
        : 0;

    // activate the next subscription
    new Promise<void>(resolve => setTimeout(resolve, delay))
      .then(() => this.subscribe(next.subscription))
      .then(() => next.completion.resolve(next.subscription))
      .catch(error => {
        // remove the subscription, reject and move onto the next one if something went wrong
        this.subscriptions.remove(next.subscription);
        next.subscription.dispose();
        next.completion.reject(error);
        this.activateNextSubscription();
      });
  }

  /**
   * Creates a new Subscription (but does not activate the Subscription).
   * @param userAgent The UserAgent to associate the Subscription with.
   * @param uri The presentity URI to subscribe to.
   * @param eventPackage The event package to subscribe to.
   */
  protected createSubscription(userAgent: UserAgent, uri: URI, eventPackage: string): Subscription {
    const subscription = new SubscriptionSIP(userAgent, uri, eventPackage);
    this.subscriptions.add(subscription);
    return subscription;
  }

  /**
   * Ends a Subscription.
   * The minimum duration between final subscription requests is controlled by the value of 'endQueueMinimumInterval'.
   * @param subscription Subscription to end.
   */
  protected endSubscription(subscription: Subscription): void {
    // This doesn't prevent a subscription from being ended more than once (which is a noop), but
    // it does avoid unnecessarily queuing up duplicates which would otherwise needlessly slow processing.
    if (this.endQueue.findIndex(next => next.subscription.uuid === subscription.uuid) !== -1) {
      return; // already in queue
    }

    // recursively process queue (after processing the head of the queue,
    // delay before dequeue and moving on to the next one)
    if (
      this.endQueue.push({
        subscription,
        end: () => {
          subscription
            .unsubscribe()
            .then(
              () => new Promise<void>(resolve => setTimeout(resolve, this.endQueueMinimumInterval))
            )
            .then(() => {
              this.endQueue.shift();
              if (this.endQueue.length) {
                this.endQueue[0].end();
              }
              return;
            });
        }
      }) === 1
    ) {
      this.endQueue[0].end(); // if the subscription we just pushed is only thing in queue, end it immediately
    }
  }

  /**
   * Returns the Subscription identified by the UUID.
   * @param uuid The UUID of the Subscription.
   * @reject {NotFoundSubscriptionError} Subscription not found.
   */
  protected find(uuid: string): Promise<Subscription> {
    const subscription = this.subscriptions.array.find(sub => sub.uuid === uuid);
    if (!subscription) {
      return Promise.reject(
        new NotFoundSubscriptionError(
          "SubscriptionController[${x.uuid}] unable to find subscription."
        )
      );
    }
    return Promise.resolve(subscription);
  }

  /**
   * Sends a SUBSCRIBE.
   * @param subscription The Subscription on which to send the SUBSCRIBE.
   */
  private subscribe(subscription: Subscription): Promise<void> {
    log.debug(`SubscriptionController[${subscription.userAgent.aor}].subscribe`);
    if (!subscription.userAgent.connected) {
      return Promise.reject(new Error("Not connected."));
    }
    const event = subscription.eventPackage;
    if (!event) {
      throw new Error("Event undefined.");
    }

    if (subscription instanceof SubscriptionSIP && subscription.userAgent instanceof UserAgentSIP) {
      const subscriber = new Subscriber(subscription.userAgent.SIP, subscription.uri, event, {
        expires: 300
      });
      subscription.SIP = subscriber;
      return subscriber.subscribe();
    }
    return Promise.reject("should never hit this");
  }

  private init(): void {
    // subscription to subscription events
    this.subscriptions.event.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: x => {
        log.debug(`SubscriptionController[${x.uuid}].event ${x.id}`);
        // If the event is associated with the pending activated subscription,
        // move on to the next subscription in the queue.
        // In effect, we are serializing subscription requests
        // - a pending one has to accepted/rejected before we move onto the next one.
        if (
          (x.id === ConnectedSubscriptionEvent.id || x.id === EndSubscriptionEvent.id) &&
          this.activateQueue.length &&
          this.activateQueue[0].subscription.uuid === x.uuid
        ) {
          this.activateNextSubscription();
        }
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.subscriptions.isEventStopped) {
          const error = new Error(
            "SubscriptionController subscription event emitter completed unexpected"
          );
          log.error(error.toString());
          throw error;
        }
      }
    });

    // state observers and associated subscriptions
    this.subscriptions.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.subscriptions = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.subscriptions.isStateStopped) {
          const error = new Error(
            "SubscriptionController subscription state emitter completed unexpectedly"
          );
          log.error(error.toString());
          throw error;
        }
      }
    });

    this.userAgents.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.userAgents = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.userAgents.isStateStopped) {
          const error = new Error(
            "SubscriptionController userAgent state emitter completed unexpectedly"
          );
          log.error(error.toString());
          throw error;
        }
      }
    });
  }
}
