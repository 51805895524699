import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  ChangeDetectorRef,
  OnDestroy
} from "@angular/core";
import { Subscription } from "rxjs";

export interface MultiLineListInfo {
  title: string;
  value: string;
  accentColorClass?: string;
}

@Component({
  selector: "onsip-multiline-item-list",
  templateUrl: "./onsip-multiline-item-list.component.html",
  styleUrls: ["./onsip-multiline-item-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnSIPMultilineItemListComponent implements OnChanges, OnDestroy {
  @Input() listItems: Array<Array<MultiLineListInfo>> = [];
  @Input() itemNameSingular!: string;
  @Input() itemNamePlural!: string;
  @Input() mobileView = false;

  private unsubscriber = new Subscription();

  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }
}
