import { Injectable, NgZone } from "@angular/core";
import { lastValueFrom, Subscription } from "rxjs";
import { distinctUntilChanged, map, filter, take } from "rxjs/operators";

import { StateEmitter } from "../../libraries/emitter/state-emitter";
import { UserPresencePublisher } from "../../libraries/firebase/realtime/user-presence-publisher";
import { UserPresenceListener } from "../../libraries/firebase/realtime/user-presence-listener";

import { NetworkService } from "../network.service";
import { AuthService, AuthState } from "./auth.service";

import { UserCustomizationService } from "../api/resources/userCustomization/user-customization.service";
import { UserCustomization } from "../api/resources/userCustomization/user-customization";

export interface UserPresenceState {
  status: "init" | "connected" | "disconnected";
  audioAvailable: boolean;
  videoAvailable: boolean;
  instanceBusy: boolean;
  instanceDoNotDisturb: boolean;
  instanceVideoDisabled: boolean;
}

@Injectable({ providedIn: "root" })
export class UserPresenceService extends StateEmitter<UserPresenceState> {
  private authState: AuthState | undefined;
  private userPresencePublisher: UserPresencePublisher | undefined;
  private userPresenceListener: UserPresenceListener | undefined;
  private unsubscriber = new Subscription();
  private signedInSubscription: Subscription | undefined = undefined;

  static initialState(): UserPresenceState {
    return {
      status: "init",
      audioAvailable: false,
      videoAvailable: false,
      instanceBusy: false,
      instanceDoNotDisturb: false,
      instanceVideoDisabled: false
    };
  }

  constructor(
    private auth: AuthService,
    private network: NetworkService,
    private zone: NgZone,
    private userCustomization: UserCustomizationService
  ) {
    super(UserPresenceService.initialState());
    this.init();
  }

  dispose(): void {
    this.publishStateComplete();
    this.unsubscriber.unsubscribe();
    this.stopUserPresence();
  }

  /**
   * Updates the user's presence to indicate if they are busy in this instance.
   * The user's RTC availability is based on the busy state of all the instances they have
   * connectected. If they are busy on any instance, they are not "available".
   * @param busy True if busy.
   */
  setInstanceBusy(busy: boolean): void {
    this.stateStore.instanceBusy = busy;
    this.publishState();
    if (this.userPresencePublisher) {
      this.userPresencePublisher.setBusy(busy);
    }
  }

  setInstanceDoNotDisturb(dnd: boolean): void {
    this.stateStore.instanceDoNotDisturb = dnd;
    this.publishState();
    if (this.userPresencePublisher) {
      this.userPresencePublisher.setDoNotDisturb(dnd);
    }
  }

  setInstanceVideoDisabled(disabled: boolean): void {
    this.stateStore.instanceVideoDisabled = disabled;
    this.publishState();
    if (this.userPresencePublisher) {
      this.userPresencePublisher.setVideoDisabled(disabled);
    }
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(() => {
      return fn();
    });
  }

  private init(): void {
    Promise.resolve().then(() => {
      this.subscribeAuth();
    });
  }

  private signedIn(): void {
    const networkSub = this.network.state
      .pipe(
        map(state => state.online),
        distinctUntilChanged()
      )
      .subscribe(online => (online ? this.startUserPresence() : this.stopUserPresence()));

    this.unsubscriber.add(networkSub);
    this.signedInSubscription = networkSub;
  }

  private signedOut(): void {
    if (this.signedInSubscription) {
      this.signedInSubscription.unsubscribe();
      this.signedInSubscription = undefined;
    }
    this.stopUserPresence();
  }

  private subscribeAuth(): void {
    this.unsubscriber.add(
      this.auth.state.subscribe(state => {
        const didChange = AuthService.didAuthStatusChange(this.authState, state);
        this.authState = state;
        switch (didChange) {
          case "signedIn":
            return this.signedIn();
          case "signedOut":
            return this.signedOut();
          default:
            return;
        }
      })
    );

    this.unsubscriber.add(
      this.auth.state
        .pipe(
          map(state => state.aor),
          distinctUntilChanged()
        )
        .subscribe(aor => {
          if (this.userPresencePublisher && aor) {
            this.userPresencePublisher.setAOR(aor);
          }
        })
    );

    this.unsubscriber.add(
      this.auth.state
        .pipe(
          map(state => state.name),
          distinctUntilChanged()
        )
        .subscribe(name => {
          if (this.userPresencePublisher && name) {
            this.userPresencePublisher.setName(name);
          }
        })
    );
  }

  private async startUserPresence(): Promise<void> {
    if (!this.authState) throw new Error("auth state undefined");
    if (!this.authState.oid) throw new Error("auth state oid undefined");
    if (!this.authState.uid) throw new Error("auth state uid undefined");
    if (!this.authState.aor) throw new Error("auth state aor undefined");
    if (!this.authState.name) throw new Error("auth state name undefined");
    const oid = this.authState.oid;
    const uid = this.authState.uid;
    const aor = this.authState.aor;
    const name = this.authState.name;

    // these won't update as the user changes them
    let userCustomization: UserCustomization;
    try {
      userCustomization = await lastValueFrom(this.userCustomization.selfUser.pipe(take(1)));
      const picture: string | undefined = userCustomization.userAvatarUrl;
      const title: string | undefined = userCustomization.userInfo?.personalPageInfo?.title;

      this.userPresencePublisher = new UserPresencePublisher(oid, uid, aor, name, picture, title);
      this.userPresencePublisher.state
        .pipe(
          filter(state => state.status === "connected"),
          take(1)
        )
        .subscribe(() => {
          if (this.userPresencePublisher) {
            this.userPresencePublisher.setBusy(this.stateStore.instanceBusy);
            this.userPresencePublisher.setDoNotDisturb(this.stateStore.instanceDoNotDisturb);
            this.userPresencePublisher.setVideoDisabled(this.stateStore.instanceVideoDisabled);
          }
        });
      this.userPresencePublisher.state.subscribe(state => {
        this.stateStore.status = state.status;
        this.publishState();
      });
      this.userPresencePublisher.publish();

      this.userPresenceListener = new UserPresenceListener(oid, uid);
      this.userPresenceListener.state.subscribe(state => {
        this.stateStore.audioAvailable = state.audioAvailable;
        this.stateStore.videoAvailable = state.videoAvailable;
        this.publishState();
      });
      this.userPresenceListener.start();
    } catch {
      // error means uesr never logged in before
    }
  }

  private stopUserPresence(): void {
    if (this.userPresencePublisher) {
      this.userPresencePublisher.dispose();
      this.userPresencePublisher = undefined;
    }
    if (this.userPresenceListener) {
      this.userPresenceListener.dispose();
      this.userPresenceListener = undefined;
    }
    this.publishState();
  }
}
