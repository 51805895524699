import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Observable, Subscription, combineLatest } from "rxjs";
import { map, filter, pluck } from "rxjs/operators";

import { Config } from "@onsip/common/config";

import { GlobalPresenceService } from "@onsip/common/services/global-presence.service";
import { NoAuthOrganizationSummaryService } from "@onsip/common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";

import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { IdentityService } from "@onsip/common/services/identity.service";

import { views } from "@onsip/web/app/phone/views";
import { isAdminRole, isAtLeastAccountAdminRole, Role } from "@onsip/common/services/api/role";
import { AuthService } from "@onsip/common/services/sayso/auth.service";
import { UserAddress } from "@onsip/common/services/api/resources/userAddress/user-address";
import { UserCustomizationService } from "@onsip/common/services/api/resources/userCustomization/user-customization.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalMaterialComponent } from "../../modal/modal-material.component";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { SuperAdminNavigationService } from "@onsip/common/services/super-admin-navigation.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { E911Service } from "@onsip/common/services/api/resources/e911/e911.service";
import { UserAddressService } from "@onsip/common/services/api/resources/userAddress/user-address.service";
import { E911Component } from "../../e911/e911.component";

@Component({
  selector: "onsip-status-menu",
  templateUrl: "./status-menu.component.html",
  styleUrls: ["./status-menu.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusMenuComponent implements OnInit, OnDestroy {
  defaultIdentity!: Observable<UserAddress | undefined>;
  orgName: string | undefined;
  showAdminTabs = false;
  showAccountAdminInfo = false;
  views = views;
  firebaseInitialized: Observable<boolean> | undefined;
  professionalCallLink: string | undefined;
  myAvatar!: Observable<string>;
  accountId: Observable<string> = this.userService.selfUser.pipe(pluck("accountId"));
  isTesting = false;
  E2eLocators = E2eLocators;
  isDesktop = Config.IS_DESKTOP;
  showSuperAdminButton!: Observable<boolean>;
  superAdminButtonRoute!: Observable<string>;
  contactName = this.userService.selfUser.pipe(pluck("contact", "name"));
  notSpoofingAgents = false;
  e911Location!: Observable<string | undefined>;

  private _globalAvailability = false;

  private unsubscriber = new Subscription();

  constructor(
    private userService: UserService,
    private identityService: IdentityService,
    private noAuthOrgSummary: NoAuthOrganizationSummaryService,
    private authService: AuthService,
    private globalPresenceService: GlobalPresenceService,
    private dialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private userCustomization: UserCustomizationService,
    private translate: TranslateService,
    private superAdminNavService: SuperAdminNavigationService,
    private apiSessionService: ApiSessionService,
    private e911Service: E911Service,
    private userAddressService: UserAddressService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  get availability(): boolean {
    return this._globalAvailability;
  }

  set availability(availability: boolean) {
    if (!this.defaultIdentity) {
      throw new Error(
        "StatusMenuComponent: Attempted to set globalAvailability but defaultUserState was undefined - skipping"
      );
    }
    this.globalPresenceService.enableGlobalPresence(availability);
  }

  onLogoutClick(): void {
    // can skip the dialog step for automated testing
    if (this.isTesting) this.logout();
    if (this.availability) {
      const dialog = this.dialog.open(ModalMaterialComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: {
          title: this.translate.instant("ONSIP_I18N.DONT_FORGET_TO_MARK_YOURSELF_AS_AWAY"),
          message: this.translate.instant(
            "ONSIP_I18N.IF_YOU_DONT_WANT_TO_RECEIVE_CALLS_PLEASE_MAKE_SURE_TO_MARK_YOURSELF_AS_AWAY_BEFORE_YOU_LOG_OUT_THIS_WAY_WE_KNOW_NOT_TO_SEND_CALLS_ANYWHERE_INCLUDING_TO_YOUR_MOBILE_AND_DESK_DEVICES"
          ),
          primaryBtnText: this.translate.instant("ONSIP_I18N.LOG_OUT"),
          primaryBtnFlat: true,
          secondaryBtnText: this.translate.instant("ONSIP_I18N.BACK"),
          noSentenceCaseTitle: true
        }
      });
      this.unsubscriber.add(
        dialog.afterClosed().subscribe(continueLogout => {
          if (continueLogout && continueLogout.doPrimaryAction) this.logout();
        })
      );
    } else {
      this.logout();
    }
  }

  logout(): void {
    document.cookie =
      "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
    document.cookie =
      "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
    this.platformCloseWindow();
  }

  openE911Dialog() {
    this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      data: {
        title: "Set E911 Location",
        component: E911Component, // re-using e911 component
        primaryBtnText: this.translateService.instant("ONSIP_I18N.SAVE"),
        primaryBtnColor: "primary",
        primaryBtnFlat: true,
        secondaryBtnText: this.translateService.instant("ONSIP_I18N.CANCEL"),
        noSentenceCaseTitle: true
      },
      disableClose: true
    });
  }

  private platformCloseWindow(): void {
    if (Config.IS_WEB) {
      window.location.href = ".";
    }
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("log-out");
    }
  }

  private init(): void {
    this.initMyAvatar();
    this.initUserAgent();
    this.initAdminRole();
    this.initOrganization();
    this.initGlobalAvailability();
    this.initAuthService();
    this.initSuperAdminNav();
    this.getSpoofAgentState();
    this.getE911Location();
  }

  private initMyAvatar(): void {
    this.myAvatar = this.userCustomization.selfUser.pipe(
      map(userCustomization => userCustomization.userAvatarUrl)
    );
  }

  private initOrganization(): void {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        if (user && this.showAdminTabs) this.orgName = user.contact.organization;
      })
    );
    this.unsubscriber.add(
      combineLatest({
        teamPageDomain: this.noAuthOrgSummary.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0]?.teamPageDomain)
        ),
        idState: this.identityService.state.pipe(filter(state => state.addresses.length > 0))
      }).subscribe(({ teamPageDomain, idState }) => {
        this.professionalCallLink = teamPageDomain
          ? `https://${teamPageDomain}/${idState.addresses[0].username}`
          : undefined;
      })
    );
  }

  private initAdminRole(): void {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.showAdminTabs = isAdminRole(user.roles);
        this.showAccountAdminInfo = isAtLeastAccountAdminRole(user.roles);
      })
    );
  }

  private initUserAgent(): void {
    this.defaultIdentity = this.identityService.state.pipe(map(state => state.defaultIdentity));
    // check if aor is our testing account aor. If so, we should not show the warning dialog for closing the app
    this.unsubscriber.add(
      this.identityService.state.subscribe(state => {
        this.isTesting = state.defaultIdentity?.aor === "saucelabs@example.onsip.com";
      })
    );
  }

  private initGlobalAvailability(): void {
    this.unsubscriber.add(
      this.globalPresenceService.globalPresenceStream.subscribe(state => {
        this._globalAvailability = state;
        this.changeDetector.markForCheck(); // updates radio button bc ngModel doesn't pick this up
      })
    );
  }

  private initAuthService(): void {
    this.firebaseInitialized = this.authService.state.pipe(
      map(state => state.status === "signedIn")
    );
  }

  private getE911Location(): void {
    this.e911Location = combineLatest({
      e911Locs: this.e911Service.selfE911Locations,
      userAdds: this.userAddressService.selfUser
    }).pipe(
      map(({ e911Locs, userAdds }) => {
        // we only assign E911 Location to the main user address and not its alias
        const userAdd = userAdds.sort(
          (a, b) => parseInt(a.userAddressId) - parseInt(b.userAddressId)
        )[0];
        const currentLocationId = userAdd?.e911LocationId;
        const e911Loc = e911Locs.find(loc => loc.e911LocationId === currentLocationId);
        return e911Loc?.locationName || e911Loc?.fullAddress || undefined;
      })
    );
  }

  private initSuperAdminNav(): void {
    this.showSuperAdminButton = this.superAdminNavService.showSuperAdminButtonObs;
    this.superAdminButtonRoute = this.superAdminNavService.superAdminButtonRouteObs;
  }

  private getSpoofAgentState(): void {
    this.unsubscriber.add(
      this.apiSessionService.state.subscribe(state => {
        this.notSpoofingAgents = !(!!state.parentUserId && state.loggedInRole !== Role.SuperUser);
      })
    );
  }
}
