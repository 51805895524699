import { MonitoredCallEvent, EventId } from "./monitored-call-event";

/** Emitted when call monitoring starts. */
export class StartMonitoredCallEvent extends MonitoredCallEvent {
  id = EventId.StartMonitoredCallEventId;
  /**
   * Factory for StartMonitoredCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isStartMonitoredCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for StartMonitoredCallEvent.
 * @param event Event to check.
 */
export function isStartMonitoredCallEvent(event: any): event is StartMonitoredCallEvent {
  return event instanceof StartMonitoredCallEvent;
}
