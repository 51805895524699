import { Subscription } from "rxjs";

import { EventStateEmitter } from "./event-state-emitter";

/**
 * This coalesces more than one EventStateEmitter of the same type
 * into a single EventStateEmitter. The events of the added emitters
 * are are merged into a single event stream and their state is
 * merged into an array of their state.
 *
 * For example, you can use it to coalesce Component objects which extend
 * EventStateEmitter<ComponentEvent, ComponentState> into a single EventStateEmitter.
 * Then you can subscribe to its single `event` observable property to receive
 * the stream of ComponentEvent emitted by all the Components and its single `state`
 * observable property to receive a stream of state emitted as an Array<ComponentState>.
 *
 * interface MyEvent {
 *  ...
 * }
 *
 * interface MyState {
 *  uuid: string
 *  ...
 * }
 *
 * class MyEmitter extends EventStateEmitter<MyEvent, MyState> {
 *  ...
 * }
 *
 * class MyEmitterArray extends EventStateEmitterArray<MyEmitter, MyEvent, MyState> {
 *   constructor() {
 *     super();
 *   }
 * }
 */
export class EventStateEmitterArray<
  T extends EventStateEmitter<E, S>,
  E,
  S
> extends EventStateEmitter<E, Array<S>> {
  /** The array of EventStateEmitters being reduced to a single EventStateEmitter */
  private _array: Array<T> = [];

  // Subscriptions to each member of the array
  private eventSubscriptions: Array<Subscription> = [];
  private stateSubscriptions: Array<Subscription> = [];

  /**
   * Constructor. Protected - the expectation is that this class will be extended.
   * @param completeOnEmpty If true, complete will be sent when the array of Emitters becomes empty.
   * Otherwise complete will never be sent (making this an "infinite" Emitter). Default is false.
   */
  protected constructor(private completeOnEmpty: boolean = false) {
    super([]);
  }

  /** Shallow copy of the array. */
  get array(): ReadonlyArray<T> {
    return this._array.slice();
  }

  /**
   * Adds an emitter to the end of the array (push it).
   * @param eventStateEmitter Emitter to add.
   * @throws {Error} On duplicate.
   */
  protected add(eventStateEmitter: T): this {
    if (!eventStateEmitter) {
      return this;
    }

    // if we already have this emitter, throw
    if (this._array.findIndex(el => el === eventStateEmitter) !== -1) {
      throw new Error("Already exists in array.");
    }

    // add the eventStateEmitter to our array
    this._array.push(eventStateEmitter);

    // subscribe to the eventStateEmitter's events and push the subscription to our subscription list
    this.eventSubscriptions.push(
      eventStateEmitter.event.subscribe({
        next: next => {
          this.publishEvent(next);
        },
        error: (error: unknown) => {
          this.publishEventError(error);
        },
        complete: () => {
          // the eventStateEmitter is likely going away...
          // to avoid a possible race condition we wait to remove on state complete below
        }
      })
    );

    // subscribe to the eventStateEmitter's state and push the subscription to our subscription list
    // the state store will get updated when subscription occurs
    this.stateSubscriptions.push(
      eventStateEmitter.state.subscribe({
        next: next => {
          // get the index from our array and update the eventStateEmitter state
          const index = this._array.findIndex(el => el === eventStateEmitter);
          this.stateStore[index] = next;
          this.publishState();
        },
        error: (error: unknown) => {
          this.publishStateError(error);
        },
        complete: () => {
          // if get here the eventStateEmitter has completed, so remove it
          this.remove(eventStateEmitter);
        }
      })
    );

    return this;
  }

  /**
   * Removes an emitter from the array (splice it).
   * Returns true if an element existed and has been removed.
   * @param eventStateEmitter Emitter to remove.
   */
  protected remove(eventStateEmitter: T): boolean {
    if (!eventStateEmitter) {
      return false;
    }

    // get the index from our array
    const index = this._array.findIndex(el => el === eventStateEmitter);
    if (index === -1) {
      return false;
    }

    // unsubscribe and remove subscriptions
    this.eventSubscriptions[index].unsubscribe();
    this.eventSubscriptions.splice(index, 1);
    this.stateSubscriptions[index].unsubscribe();
    this.stateSubscriptions.splice(index, 1);

    // remove the eventStateEmitter object from our array
    this._array.splice(index, 1);

    // remove the eventStateEmitter state from the state store
    this.stateStore.splice(index, 1);

    // if we have nothing more to observe notify complete
    if (this.completeOnEmpty && !this._array.length) {
      this.publishEventComplete();
      this.publishStateComplete();
    } else {
      // else publish change notification
      this.publishState();
    }

    return true;
  }

  /**
   * Removes all stored emitters and completes this emitter.
   * Useful to complete an "infinite" emitter.
   */
  protected publishComplete(): void {
    this._array.slice().forEach(element => {
      this.remove(element);
    });
    if (!this.completeOnEmpty) {
      this.publishEventComplete();
      this.publishStateComplete();
    }
  }
}
