<mat-toolbar class="onsip-header">
  @if (debug) {
  <div id="debug"></div>
  }
  <div class="onsip-left-nav">
    <img
      id="onsip-logo"
      [src]="
        (useSmallIcon | async)
          ? 'resources/img/onsip-blue-small.svg'
          : 'resources/img/onsip-blue.svg'
      "
      [routerLink]="views.SUPER_USER_VIEW"
      alt="OnSIP"
    />
    <mat-divider class="divider" vertical></mat-divider>
    <div class="mat-headline-6">Super admin</div>
  </div>
  <onsip-status-menu></onsip-status-menu>
</mat-toolbar>
<onsip-super-user-search></onsip-super-user-search>
