import {
  ApiUserCustomizationContact,
  UserCustomizationContact,
  apiUserCustomizationContactToUserCustomizationContact
} from "./user-customization-contact";
import { ApiUserInfo, UserInfo, apiUserInfoToUserInfo } from "../../apiResponse/user-info";
import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { Contact } from "@onsip/common/interfaces/contact";
import { ParameterValue } from "../../util/api-action-description";

export enum UserCustomizationEditContactTypes {
  ADD = "Add",
  DELETE = "Delete",
  EDIT = "Edit",
  EDIT_FAVOURITES = "EDIT_FAVOURITES"
}

export declare interface ApiUserCustomization {
  AccountId: string;
  Created?: string;
  Modified?: string;
  OrganizationId: string;
  UserAvatar?: string;
  UserAvatarUrl?: string;
  /** hack - should be OnsipApiArray but i didn't want to refactor legacy code */
  UserCustomizationContacts?: {
    UserCustomizationContact: Array<ApiUserCustomizationContact>;
  };
  UserCustomizationId?: string;
  UserId: string;
  UserInfo?: ApiUserInfo;
}

export declare interface UserCustomization {
  accountId: string;
  created?: string;
  modified?: string;
  organizationId: string;
  userAvatar: string;
  userAvatarUrl: string;
  userCustomizationContacts: Array<UserCustomizationContact>;
  userCustomizationId?: string;
  userId: string;
  userInfo?: UserInfo;
}

export function apiUserCustomizationToUserCustomization(
  apiUserCustomization: ApiUserCustomization
): UserCustomization {
  return {
    accountId: apiUserCustomization.AccountId,
    created: apiUserCustomization.Created,
    modified: apiUserCustomization.Modified,
    organizationId: apiUserCustomization.OrganizationId,
    userAvatar: apiUserCustomization.UserAvatar || "",
    userAvatarUrl: apiUserCustomization.UserAvatarUrl || "",
    userCustomizationContacts: onsipApiArrayToArray(
      apiUserCustomization.UserCustomizationContacts as OnsipAPIArray<
        "UserCustomizationContact",
        ApiUserCustomizationContact
      >,
      "UserCustomizationContact"
    ).map(apiUserCustomizationContactToUserCustomizationContact),
    userCustomizationId: apiUserCustomization.UserCustomizationId,
    userId: apiUserCustomization.UserId,
    userInfo: apiUserCustomization.UserInfo
      ? apiUserInfoToUserInfo(apiUserCustomization.UserInfo)
      : undefined
  };
}

export function prepareUserCustomizationEditParams(
  contacts: Array<Contact>
): Record<string, ParameterValue> {
  const params: Record<string, ParameterValue> = {};

  contacts.forEach((contact, paramIndex) => {
    params[`Contacts[${paramIndex}][Action]`] = UserCustomizationEditContactTypes.EDIT;
    params[`Contacts[${paramIndex}][ContactId]`] = contact.contactId;
    params[`Contacts[${paramIndex}][ContactName]`] = contact.name;
    params[`Contacts[${paramIndex}][ContactIsAFavorite]`] = contact.favorite;
    if (contact.aors.length) {
      params[`Contacts[${paramIndex}][ContactSipAddress]`] = contact.aors[0];
      params[`Contacts[${paramIndex}][ContactPhone]`] = ""; // this will delete the phone entry if there is one
    } else if (contact.e164PhoneNumbers?.length) {
      const phoneNumber = contact.e164PhoneNumbers[0].replace(/\+/, "");
      params[`Contacts[${paramIndex}][ContactPhone]`] = phoneNumber;
      params[`Contacts[${paramIndex}][ContactSipAddress]`] = ""; // this will delete the sip addr entry if there is one
    } else {
      throw new TypeError("Invalid Contact");
    }
  });

  return params;
}

export function prepareUserCustomizationAddParams(
  contacts: Array<Contact>
): Record<string, ParameterValue> {
  const params: Record<string, ParameterValue> = {};

  contacts.forEach((contact, paramIndex) => {
    params[`Contacts[${paramIndex}][ContactIsAFavorite]`] = false;
    if (contact.userId) {
      params[`Contacts[${paramIndex}][ContactUserId]`] = contact.userId;
    } else {
      params[`Contacts[${paramIndex}][ContactName]`] = contact.name;
      if (contact.e164PhoneNumbers && contact.e164PhoneNumbers.length > 0) {
        const numString: string = contact.e164PhoneNumbers[0].startsWith("+")
          ? contact.e164PhoneNumbers[0].substring(1)
          : contact.e164PhoneNumbers[0];

        if (numString.startsWith("011")) {
          // international
          params[`Contacts[${paramIndex}][ContactPhone]`] = numString.substring(3);
        } else {
          params[`Contacts[${paramIndex}][ContactPhone]`] = numString;
        }
      } else if (contact.extensions.length > 0) {
        params[`Contacts[${paramIndex}][ContactPhone]`] = contact.extensions[0];
      }
      if (contact.aors.length > 0) {
        params[`Contacts[${paramIndex}][ContactSipAddress]`] = contact.aors[0];
      }
    }

    params[`Contacts[${paramIndex}][Action]`] = UserCustomizationEditContactTypes.ADD;
  });

  return params;
}

export function prepareUserCustomizationDeleteParams(
  contacts: Array<Contact>
): Record<string, ParameterValue> {
  const params: Record<string, ParameterValue> = {};

  contacts.forEach((contact, paramIndex) => {
    params[`Contacts[${paramIndex}][Action]`] = UserCustomizationEditContactTypes.DELETE;
    if (contact.userId) {
      params[`Contacts[${paramIndex}][ContactUserId]`] = contact.userId;
    } else {
      params[`Contacts[${paramIndex}][ContactName]`] = contact.name;
    }
  });

  return params;
}

export function prepareUserCustomizationEditFavouritesParams(
  contacts: Array<Contact>
): Record<string, ParameterValue> {
  const params: Record<string, ParameterValue> = {};
  contacts.forEach((contact, idx) => {
    params["Contacts[" + idx + "][ContactIsAFavorite]"] = contact.favorite;
    // it's not typo, yes to delete or add contact to favourite you should use Add action
    params["Contacts[" + idx + "][Action]"] = UserCustomizationEditContactTypes.ADD;
    if (contact.custom) {
      params["Contacts[" + idx + "][ContactName]"] = contact.name;
      if (contact.aors.length) {
        params["Contacts[" + idx + "][ContactSipAddress]"] = contact.aors[0];
      } else if (contact.e164PhoneNumbers?.length) {
        const phoneNumber = contact.e164PhoneNumbers[0].replace(/\+/, "");
        params["Contacts[" + idx + "][ContactPhone]"] = phoneNumber;
      } else {
        throw new TypeError("Invalid Contact");
      }
    } else {
      params["Contacts[" + idx + "][ContactUserId]"] = contact.userId;
    }
  });

  return params;
}
