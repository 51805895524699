import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";
import {
  ApiWebCallTopicRep,
  WebCallTopicRep,
  apiWebCallTopicRepToWebCallTopicRep
} from "./web-call-topic-rep";
import {
  ButtonConfigurationMetadata,
  ButtonConfigurationFilters
} from "../../../../interfaces/organization-configuration";

export declare interface ApiWebCallTopic {
  AccountId: string;
  AgentStrategy: "random" | "priority";
  Created: string;
  Modified: string;
  Name: string;
  OrganizationId: string;
  WebCallTopicId: string;
  WebCallTopicReps: OnsipAPIArray<"WebCallTopicRep", ApiWebCallTopicRep>;
  Configuration?: string; // JSON parseable
}

export declare interface WebCallTopic extends WebCallTopicSansId {
  /** Primary key in web_call_topic in the jnctn database (MySQL) */
  webCallTopicId: number;
  organizationId: string;
}

export declare interface WebCallTopicSansId {
  /** String identifier to tell sayso how to parse and present to the user the incoming agents from firebase */
  agentStrategy: "random" | "priority";
  /** Same as "target" in configuration file */
  name: string;
  /** Ledger of reps on the topic */
  webCallTopicReps: Array<WebCallTopicRep>;
  /** Optional store of topic's configuration */
  configuration?: ButtonConfigurationMetadata;
}

/** The PublicWebCallTopic interface contains all the relevant information components can interact with */
export declare interface PublicWebCallTopic {
  configuration: ButtonConfigurationMetadata;
  filters: ButtonConfigurationFilters;
  webCallTopicReps: WebCallTopic["webCallTopicReps"];
}

export function apiWebCallTopicToWebCallTopic(apiWebCallTopic: ApiWebCallTopic): WebCallTopic {
  const webCallTopic = {
    agentStrategy: apiWebCallTopic.AgentStrategy,
    name: apiWebCallTopic.Name,
    webCallTopicId: parseInt(apiWebCallTopic.WebCallTopicId),
    webCallTopicReps: onsipApiArrayToArray(apiWebCallTopic.WebCallTopicReps, "WebCallTopicRep").map(
      apiWebCallTopicRepToWebCallTopicRep
    ),
    organizationId: apiWebCallTopic.OrganizationId
  };
  return apiWebCallTopic.Configuration
    ? { ...webCallTopic, configuration: JSON.parse(apiWebCallTopic.Configuration) }
    : webCallTopic;
}
