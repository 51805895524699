import { Injectable } from "@angular/core";

import { UserAddress } from "./api/resources/userAddress/user-address";
import { ApiSessionService } from "./api/api-session.service";
import { combineLatest } from "rxjs";
import { IdentityService } from "../modules/core";
import { UserAddressService } from "./api/resources/userAddress/user-address.service";

@Injectable({ providedIn: "root" })
export class DoNotDisturbService {
  private addresses: Array<UserAddress> = [];
  private togglingIndex = 0;

  constructor(
    private apiSession: ApiSessionService,
    private identity: IdentityService,
    private userAddress: UserAddressService
  ) {
    combineLatest([this.apiSession.state, this.identity.state]).subscribe(
      ([apiSessionState, identityState]) => {
        if (apiSessionState.loggedIn && identityState.addresses.length > 0) {
          this.addresses = identityState.addresses;
        } else if (!apiSessionState.loggedIn) {
          this.addresses = [];
        }
      }
    );

    this.userAddress.state.subscribe(state => {
      const togglingAddress: UserAddress = this.addresses[this.togglingIndex];
      const userAddresses = Object.values(state.state);
      if (togglingAddress) {
        const matchedAddress = userAddresses.find(
          address => address.addressId === togglingAddress.addressId
        );
        if (matchedAddress) {
          const newDnd = matchedAddress.doNotDisturb;
          const didDndUpdate = this.addresses[this.togglingIndex].doNotDisturb !== newDnd;
          if (didDndUpdate) {
            this.addresses[this.togglingIndex].doNotDisturb = newDnd;
            this.identity.updateDndState(togglingAddress);
          }
        }
      }
    });
  }

  setDoNotDisturb(enableDnd: boolean): void {
    const togglingAddress: UserAddress = this.addresses[this.togglingIndex];

    if (togglingAddress) {
      if (togglingAddress.doNotDisturb === enableDnd) {
        return;
      }

      this.userAddress.userAddressEditDoNotDisturb({
        AddressId: togglingAddress.addressId,
        Enabled: enableDnd
      });
    }
  }

  // newState: GA !== available
  updateUserDndState(newState: boolean) {
    const togglingAddress: UserAddress = this.addresses[this.togglingIndex];
    togglingAddress.doNotDisturb = newState;
  }
}
