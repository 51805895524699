import { Injectable } from "@angular/core";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";

import { ApiResourceService } from "../api-resource.service";
import { getApiActionName } from "../../onsip-api-action-new";
import { OnsipApiResponse } from "../../apiResponse/response-body-new";

interface ResetPasswordWithEmailKey {
  EmailKey: string;
  Username: string;
  Password: string;
}

const debug = false;

@Injectable({
  providedIn: "root"
})
export class ResetPasswordUsingEmailKeyService extends ApiResourceService<void> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "ResetPasswordUsingEmailKey", "" as never);
    debug &&
      this.state.subscribe(state => console.warn("ResetPasswordUsingEmailKeyService", state));
  }

  resetPasswordUsingEmailKey(parameters: ResetPasswordWithEmailKey): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.ResetPasswordUsingEmailKey,
        ...parameters
      }
    });
    return this.promiseState.toPromise(ApiAction.ResetPasswordUsingEmailKey);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.ResetPasswordUsingEmailKey:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
