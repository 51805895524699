import { Config } from "../../../../../common/config";
import { LogService } from "../../../../../common/services/logging";

import { OAuth2Client } from "./oauth2-auth.service";

import { Injectable } from "@angular/core";

export type WindowFeatures = string | ElectronWindowFeatures;

export interface ElectronWindowFeatures {
  height: number;
  width: number;
  title?: string;
  webPreferences?: {
    sandbox: boolean;
  };
}

const POPUP_TIMEOUT = "Popup was open for too long, closing";
const REQUEST_CANCELLED = "Request cancelled";

@Injectable({ providedIn: "root" })
export class Oauth2PopupWindowFactory {
  static platformGetWindowFeatures(height: number, width: number): WindowFeatures {
    if (Config.IS_DESKTOP) {
      return { height, width };
    }
    if (Config.IS_WEB) {
      if (height && width) {
        return "height=" + height + ",width=" + width;
      }
    }
    throw new Error("Unimplemented.");
  }

  // @ts-ignore: noUnusedLocals
  constructor(private log: LogService) {}

  createRequest(oauth2Client: OAuth2Client) {
    // object literal return type inferred
    const request = {
      clientId: oauth2Client.clientId,
      authEndpoint: oauth2Client.authEndpoint,
      scope: oauth2Client.scope.toString(),
      redirectUri: oauth2Client.redirectUri,
      state: this.createRandomToken(32),
      url: "",
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      popup: (windowFeatures: WindowFeatures): Promise<string> => Promise.reject("Unimplemented.")
    };

    request.url =
      request.authEndpoint +
      "?client_id=" +
      encodeURIComponent(request.clientId) +
      "&scope=" +
      encodeURIComponent(request.scope) +
      "&state=" +
      encodeURIComponent(request.state) +
      "&redirect_uri=" +
      encodeURIComponent(request.redirectUri) +
      "&response_type=code" +
      "&access_type=offline" +
      "&prompt=consent";
    request.popup = (windowFeatures: WindowFeatures): Promise<string> => {
      return new Promise<string>((resolve, reject) => {
        this.platformSetupWindow(request, windowFeatures, resolve, reject);
      }).catch(message => {
        // convert rejection from string to Error
        if (message === POPUP_TIMEOUT || message === REQUEST_CANCELLED) {
          console.error(message);
          return message;
        }
        throw new Error(message);
      });
    };

    return request;
  }

  private parseUrlParameter(url: string, parameterName: string): string | undefined {
    const paramName: string = parameterName.replace(/[[]/, "\\[").replace(/[\]]/, "\\]"),
      regex: any = new RegExp("[\\?&]" + paramName + "=([^&#]*)"),
      results: any = regex.exec(url);

    return (results && decodeURIComponent(results[1].replace(/\+/g, " "))) || undefined;
  }

  // TODO: There are a mix of error cases some of which can be gracefully handled (like user cancels request).
  // The error cases from which the application may recover gracefully should be typed so the caller has a chance
  // of distinguishing them from other errors and ultimately the user interface has a chance of being helpful.
  private platformSetupWindow(
    request: any,
    windowFeatures: WindowFeatures,
    resolve: (code: string) => void,
    reject: (message: string) => void
  ): void {
    let popupWindow: any,
      popupTimeoutId: any,
      popupCheckIntervalId: any,
      popupListener: (ev: MessageEvent) => void;

    if (Config.IS_WEB) {
      popupWindow = window.open(request.url, "Authorize OnSIP", windowFeatures as any);

      popupListener = (ev: MessageEvent) => {
        if (popupWindow && popupWindow === ev.source) {
          const response: any = this.createResponse(ev.source);

          if (request.state !== response.state) {
            reject("response state does not match request state");
          } else if (request.redirectUri !== response.uri) {
            reject("response uri does not match request redirect uri");
          } else if (response.error) {
            reject(response.error);
          } else {
            resolve(response.code);
          }
          clearInterval(popupCheckIntervalId);
          clearTimeout(popupTimeoutId);
          popupWindow.removeEventListener("message", popupListener);
          popupWindow.close();
        } else {
          this.log.debug("oauth2AuthRequestFactory", "- popup: message is not from popupWindow");
        }
      };

      window.addEventListener("message", popupListener);

      popupCheckIntervalId = setInterval(() => {
        if (popupWindow && popupWindow.closed) {
          clearInterval(popupCheckIntervalId);
          clearTimeout(popupTimeoutId);
          reject(REQUEST_CANCELLED);
        }
      }, 1000);

      popupTimeoutId = setTimeout(() => {
        clearInterval(popupCheckIntervalId);
        if (popupWindow) {
          popupWindow.close();
        }
        reject(POPUP_TIMEOUT);
      }, 60000 * 3);
    }

    if (Config.IS_DESKTOP) {
      if (typeof windowFeatures === "string") {
        throw new Error("Type of window features is invalid for desktop.");
      }
      window.electron.onMessage("oauth-popup-resolve", (event, code) => {
        resolve(code);
      });

      window.electron.onMessage("oauth-popup-reject", (event, errorOrString) => {
        reject(errorOrString);
      });
      // we cannot pass nested objects as args to ipcMain so going to stringify the args so it is inputted as a string
      window.electron.sendMessage("oauth-popup-open", JSON.stringify({ windowFeatures, request }));
    }
  }

  private createRandomToken(size: number, base: number = 32): string {
    let token = "";

    for (let i = 0; i < size; i++) {
      const r: number = (Math.random() * base) | 0;
      token += r.toString(base);
    }

    return token;
  }

  // @ts-ignore: noUnusedLocals
  private createResponse(responseWindow: any): any {
    const response: any = {
      code: this.parseUrlParameter(responseWindow.location.search, "code"),
      state: this.parseUrlParameter(responseWindow.location.search, "state"),
      uri: responseWindow.location.href.split(/[?#]/g, 1)[0],
      error: undefined
    };

    if (!response.code) {
      response.error =
        this.parseUrlParameter(responseWindow.location.search, "error") ||
        responseWindow.document.body ||
        "invalid response";
    }

    return response;
  }
}
