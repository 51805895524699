export interface BHR {
  /** IMPORTANT: 'enabled' on the root level BHR refers to whether we want OPT-IN to apply BHRs at all.
   * true: we want to apply the BHRs enumerated in the rest of the object
   * false: we don't want to apply BHRs at all, so we OPT-IN to receive calls all day
   */
  enabled: boolean;
  /** a parseInt'able string representing the UTC offset for the timezone, implementation default is Eastern Standard: "-5" */
  timezone: string;
  sunday: BHRDay;
  monday: BHRDay;
  tuesday: BHRDay;
  wednesday: BHRDay;
  thursday: BHRDay;
  friday: BHRDay;
  saturday: BHRDay;
  holidays?: Array<BHRHoliday>;
}

export interface BHRDay {
  /** IMPORTANT: 'disabled' on the BHRDay refers to whether we want to OPT-OUT of that day.
   * true: we OPT-OUT of this day, don't want ANY calls on this day
   * false: we want calls on this day with this rule applied
   */
  disabled: boolean;
  start: BHRTime;
  end: BHRTime;
}

export interface BHRTime {
  h: number; // 24 hour time
  m: number;
}

export interface BHRHoliday {
  m: number;
  d: number;
  label: string;
}

export function isBHRDay(arg: any): arg is BHRDay {
  return (
    arg !== undefined &&
    arg.disabled !== undefined &&
    arg.start !== undefined &&
    arg.end !== undefined &&
    typeof arg.disabled === "boolean" &&
    (arg.disabled ||
      (isBHRTime(arg.start) && isBHRTime(arg.end) && timeGreaterThan(arg.end, arg.start)))
  );
}

function isBHRTime(arg: any): arg is BHRTime {
  return (
    arg !== undefined &&
    arg.h !== undefined &&
    arg.m !== undefined &&
    arg.h >= 0 &&
    arg.h < 24 &&
    arg.m >= 0 &&
    arg.m < 60
  );
}

export function timeGreaterThan(a: BHRTime, b: BHRTime): boolean {
  return a.h > b.h || (a.h === b.h && a.m > b.m);
}

export function mapStringToBHRTime(time: string): BHRTime {
  let newTime: BHRTime;
  if (time.length === 5) {
    newTime = { h: Number(time.substring(0, 2)), m: Number(time.substring(3, 8)) };
  } else {
    // incomplete or empty field stored as h: -1
    newTime = { h: -1, m: 0 };
  }
  return newTime;
}

export function mapBHRTimeToString(time: BHRTime): string {
  if (time !== undefined && time.h !== undefined && time.m !== undefined) {
    if (time.h === -1) {
      return "";
    } else {
      return String("0" + time.h).slice(-2) + ":" + String("0" + time.m).slice(-2);
    }
  } else {
    return "";
  }
}
