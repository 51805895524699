import { Injectable } from "@angular/core";
import { ApiAction, ApiReadAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import {
  AccountDetails,
  ApiAccountDetails,
  apiAccountDetailsToAccountDetails as clean
} from "./account-details";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { AccountDetails };

const debug = false;

interface AccountDetailsWithUserId extends AccountDetails {
  userId: string; // NOTE: account details does not have an indexable param so we are adding the userId into the object
}

@Injectable({ providedIn: "root" })
export class AccountDetailsService extends ApiResourceService<AccountDetailsWithUserId> {
  private userId!: string;
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    // NOTE: we are adding the userId to the response in the reducer
    super(session, store, promiseState, "AccountDetails", "userId");
    this.store.state.pipe(userId()).subscribe(userId => (this.userId = userId));
    debug && this.state.subscribe(state => console.warn("AccountDetails", state));
  }

  // We need a separate service for user read account details since it is response type is completely different from other userReads
  UserReadAccountDetails(): ApiPromiseState<AccountDetailsWithUserId> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.UserReadAccountDetails,
        SessionId: this.store.state.pipe(sessionId())
      }
    });
    return this.promiseState.toPromise(ApiReadAction.UserReadAccountDetails);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.UserReadAccountDetails:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              {
                ...clean(extractData<ApiAccountDetails>(response, action, "AccountDetails")),
                userId: this.userId
              }
            ],
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
