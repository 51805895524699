import firebase from "firebase/app";

import { enableLogging as enableRealtimeLogging } from "../../../common/libraries/firebase/realtime/enable-logging";

export type Feature = "auth" | "firestore" | "database";

const firebaseRealtimeDebug = false;

const debug = false;

let app: firebase.app.App | undefined;

const featureKeys: { [key in Feature]: string } = {
  auth: "authDomain",
  database: "databaseURL",
  firestore: "projectId"
};

// @ts-ignore unused const
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const developmentConfig = {
  apiKey: "AIzaSyCFfQozTiIitwuIYEwLhB4BnJM0dyRItkM",
  authDomain: "fir-test-5f263.firebaseapp.com",
  databaseURL: "https://fir-test-5f263.firebaseio.com",
  projectId: "fir-test-5f263"
};

// @ts-ignore unused const
const productionConfig = {
  apiKey: "AIzaSyBMXsl3OGhYpuKGdV8HFnMiXNDe_Q9RKH4",
  authDomain: "onsip-3e3a9.firebaseapp.com",
  databaseURL: "https://onsip-3e3a9.firebaseio.com",
  projectId: "onsip-3e3a9"
};

const config: Record<string, string> = productionConfig;

/**
 * Initialize Firebase app with specified features.
 * This allows the code in our library to be shared without activing
 * all features for every project.
 *
 * For example, Instacall does not need or want the Firebase Auth
 * as it never auths users - however if we initialized auth then
 * it would always run and try to auth people in the background
 * (failing all the time).
 * @param features The Firebase features to initialize
 */
export function initializeApp(features: Feature | Array<Feature>): void {
  debug && console.log("initializeApp features", features);
  if (app !== undefined) {
    console.error("Firebase already initialized."); // TODO refactor firebase app initialization, then we can throw here instead of log
    return;
  }
  const options: Record<string, string> = {
    apiKey: config.apiKey
  };
  if (features instanceof Array) {
    features.forEach(feature => {
      const key = featureKeys[feature];
      options[key] = config[key];
    });
  } else {
    const key = featureKeys[features];
    options[key] = config[key];
  }
  debug && console.log("initializeApp options", options);

  enableRealtimeLogging(firebaseRealtimeDebug);

  app = firebase.initializeApp(options);
}
