import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { views } from "@onsip/web/app/phone/views";

@Injectable({ providedIn: "root" })
export class PhoneGuard {
  private readonly NOT_SUPPORTED_RETURN_URLS = [
    "/call",
    "/video-conference",
    "/contact",
    "/queue-dashboard",
    "/administrators/e911"
  ];

  constructor(
    private apiSession: ApiSessionService,
    private router: Router,
    private location: Location
  ) {}

  canActivate() {
    if (this.apiSession.isLoggedIn()) {
      return true;
    } else {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: this.getReturnUrl() }
      });
      return false;
    }
  }

  private getReturnUrl(): string {
    if (this.doesSupportReturnUrl()) {
      return this.location.path(true);
    }

    return views.HOME;
  }

  private doesSupportReturnUrl(): boolean {
    return !this.NOT_SUPPORTED_RETURN_URLS.some(url => this.location.path(true).startsWith(url));
  }
}
