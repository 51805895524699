import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";
import { HubspotEmbedComponent } from "./hubspot-embed.component";
import { HubspotAuthorizedToggleComponent } from "./hubspot-authorized-toggle.component";
import { HubspotDisableWarningModalComponent } from "./hubspot-disable-warning-modal.component";

import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, TranslateModule],
  declarations: [
    HubspotEmbedComponent,
    HubspotAuthorizedToggleComponent,
    HubspotDisableWarningModalComponent
  ],
  exports: [HubspotEmbedComponent, HubspotAuthorizedToggleComponent]
})
export class HubspotModule {}
