//
// Helper class to deal with completing promises outside their scope
//
export class PromiseCompletion {
  constructor(private _resolve: (result: any) => void, private _reject: (reason: any) => void) {}

  resolve(result: any): void {
    this._resolve(result);
  }

  reject(e: any): void {
    this._reject(e);
  }
}
