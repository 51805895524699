import { Injectable } from "@angular/core";
import { ApiAction } from "@onsip/common/services/api/api-actions";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { genericApiAction } from "@onsip/common/services/api/onsip-api-action";

@Injectable({ providedIn: "root" })
export class AdminSupportSecureCodeService {
  private secureIdCode = "";

  constructor(private apiSessionService: ApiSessionService) {
    this.generateSecureCode();
    this.sendCodeToAudit();
  }

  getSecureIdCode(): string {
    return this.secureIdCode;
  }

  private sendCodeToAudit(): void {
    genericApiAction<any>(ApiAction.NoOpAudit, {
      SuperSecretCode: this.secureIdCode,
      SessionId: this.apiSessionService.stateValue.sessionId
    });
  }

  private generateSecureCode(): void {
    const adjs = [
      "Hot",
      "Cold",
      "Blue",
      "Green",
      "Dark",
      "Leathery",
      "Impossible",
      "Clairvoyant",
      "Untraceable",
      "Contiguous",
      "Cornerless",
      "Attractive",
      "Personable",
      "Effortless",
      "Brilliant",
      "Sparkling",
      "Glowing",
      "Splendid",
      "Magnificent",
      "Outstanding",
      "High",
      "Low",
      "Hopeful",
      "Yellow",
      "Red",
      "Purple",
      "Inquisitive",
      "Ponderous",
      "Glamorous",
      "Indivisible",
      "Light",
      "Ashen",
      "Feline",
      "Strong",
      "Bold",
      "Confident",
      "Graceful",
      "Hearty",
      "Boisterous",
      "Weak",
      "Deafening",
      "Callous",
      "Indifferent",
      "Silly",
      "Serious",
      "Innovative",
      "Preposterous",
      "Iniquitous",
      "Barbaric",
      "Golden",
      "Perfect",
      "Flawless",
      "Foolish",
      "Beneficial",
      "Improbable",
      "Bulky",
      "Weighty",
      "Quick",
      "Fast",
      "Slow",
      "Open",
      "Closed",
      "Smelly",
      "Acidic",
      "Nomadic",
      "Short",
      "Tall",
      "Unvanquished",
      "Hard",
      "Easy",
      "Perplexing",
      "Simple",
      "Facile",
      "Demanding",
      "Culpable",
      "Errant",
      "Metallic",
      "Wooden",
      "Fibery",
      "Costly",
      "Reflexive",
      "Introspective",
      "Quiet",
      "Loud",
      "Difficult",
      "Paperish",
      "Taut",
      "Loose",
      "Real",
      "Unreal",
      "Commanding",
      "Scary",
      "Happy",
      "Feasible",
      "Onerous",
      "Calcareous",
      "Meaty",
      "Solar",
      "Wise",
      "Considerate",
      "Prudent",
      "Exceptional",
      "Broad",
      "Long",
      "Thick",
      "Goatish",
      "Centerless",
      "Invisible",
      "Workable",
      "Possible",
      "Orange",
      "Grey",
      "Dependent",
      "Sagacious",
      "Inveterate",
      "Consistent",
      "Palpable",
      "Touchy",
      "Congruent",
      "Matching",
      "Unknown",
      "Celebrated",
      "Catchable",
      "Strange",
      "Bizarre",
      "Colorless",
      "Profitable",
      "Worthwhile",
      "Easeful",
      "Charming",
      "Geographic",
      "Interstellar",
      "Interplanetary",
      "Earthen",
      "Complete",
      "Final",
      "Entrancing",
      "Convincing",
      "Culpable",
      "Guilty",
      "Masterful",
      "Violet",
      "Snowy",
      "Rainy",
      "Glittering",
      "Unsure",
      "Positive",
      "Lucky",
      "Fortuitous",
      "Ridiculous",
      "Vanilla",
      "Chocolate"
    ];
    const nouns = [
      "Dojo",
      "Hospital",
      "Church",
      "Rectory",
      "Baseball",
      "Football",
      "Star",
      "Moon",
      "Hammer",
      "Screw",
      "Painting",
      "Artist",
      "Knife",
      "Fork",
      "Hat",
      "Sun",
      "Lentil",
      "Cabbage",
      "Jeans",
      "Khakis",
      "Pizza",
      "Coat",
      "Truck",
      "Ambulance",
      "Bottle",
      "Theater",
      "Tent",
      "Headset",
      "Phone",
      "Crypt",
      "Lair",
      "Dragon",
      "Unicorn",
      "Waiter",
      "King",
      "Policeman",
      "Monkey",
      "Snake",
      "President",
      "Councilman",
      "Lifter",
      "Runner",
      "Mars",
      "Pencil",
      "Pen",
      "Magician",
      "Psychic",
      "Driver",
      "Passenger",
      "Visionary",
      "Manager",
      "Layabout",
      "Concierge",
      "Yodeler",
      "Guitarist",
      "Wolf",
      "Dog",
      "Sasquatch",
      "Skyscraper",
      "Taxi",
      "Fanboy",
      "Wrestler",
      "Fireman",
      "Anaconda",
      "Yogurt",
      "Stadium",
      "Football",
      "Chef",
      "Judge",
      "Dancer",
      "Singer",
      "Politician",
      "Mountain",
      "Sea",
      "Tundra",
      "Fish",
      "Desert",
      "Ocean",
      "Lake",
      "Forest",
      "Woods",
      "Operator",
      "Pitcher",
      "Quarterback",
      "Captain",
      "Pilot",
      "Television",
      "Computer",
      "Watch",
      "Browser",
      "Screen",
      "Camera",
      "Doctor",
      "Photographer",
      "Carpenter",
      "Soothsayer",
      "Clairvoyant",
      "Performer",
      "Fisherman",
      "Zookeeper",
      "Ranch",
      "Park",
      "Esplanade",
      "Knoll",
      "Hill",
      "Road",
      "Bicycle",
      "Motorbike",
      "Scooter",
      "Inventor",
      "Pancake",
      "Burger",
      "Salmon",
      "Trout",
      "Machine",
      "Fries",
      "Bacon",
      "Lettuce",
      "Tomato",
      "Ketchup",
      "Bun",
      "Pork",
      "Chips",
      "Blimp",
      "Tire",
      "Compressor",
      "Plywood",
      "Weatherman",
      "Computer",
      "Mouse",
      "Bin",
      "Jupiter",
      "Pluto",
      "Helicopter",
      "Chief",
      "Coach",
      "Stuntman",
      "Mayor",
      "Daredevil",
      "Swimmer",
      "Headphones",
      "Owl",
      "Plug",
      "Trashcan",
      "Astronaut",
      "Scientist",
      "Writer",
      "Lawyer",
      "Professor",
      "Programmer",
      "Genius"
    ];
    this.secureIdCode =
      adjs[Math.floor(Math.random() * adjs.length)] +
      " " +
      nouns[Math.floor(Math.random() * nouns.length)];
  }
}
