import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  HostBinding,
  ChangeDetectionStrategy
} from "@angular/core";
import { combineLatest, Subscription } from "rxjs";

import { CallControllerService } from "../../../../common/services/call-controller.service";

import { ContactService } from "../../../../common/services/contact/contact.service";
import { Contact } from "../../../../common/interfaces/contact";
import { FirestoreCallService } from "../../../../common/services/sayso/firestore-call.service";
import { CallState } from "../../../../common/libraries/sip/call-state";
import { OnSIPURI } from "../../../../common/libraries/onsip-uri";

import { E164PhoneNumber } from "../../../../common/libraries/e164-phone-number";

@Component({
  selector: "onsip-call-status",
  templateUrl: "./call-status.component.html",
  styleUrls: ["./call-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallStatusComponent implements OnInit, OnDestroy, OnChanges {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  @Input() callUuid!: string;
  @Input() nameOverride: string | undefined;
  @Input() hideLocalInfo = false;

  callState: CallState | undefined;
  callFromUri = "";
  callToUri = "";
  callWithName = "";
  contact: Contact | undefined;

  /** see CallItem.where */
  saysoCallFromWhere: string | undefined;

  /** see CallItem.link */
  saysoCallFromLink: string | undefined;

  /** "target" property from sayso call  */
  saysoTarget: string | undefined;

  /** "targetDisplay" property from sayso call */
  saysoTargetDisplay: string | undefined;

  private unsubscriber = new Subscription();

  constructor(
    private callControllerService: CallControllerService,
    private contactService: ContactService,
    private firestoreCallService: FirestoreCallService
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      combineLatest([this.callControllerService.state, this.firestoreCallService.state]).subscribe(
        ([, firestoreCall]) => {
          this.callState = this.callControllerService.getCallStateByUuid(this.callUuid);
          if (this.callState) {
            const saysoCall = firestoreCall.find(
              call => call.id === this.firestoreCallService.getCallId(this.callState as CallState)
            );
            if (saysoCall) {
              this.saysoCallFromWhere =
                (saysoCall.context.browser && saysoCall.context.browser.title) || "Unknown";
              this.saysoCallFromLink =
                (saysoCall.context.browser && saysoCall.context.browser.href) || "";
              this.saysoTarget = saysoCall.target;
              this.saysoTargetDisplay = saysoCall.targetDisplay;
            } else {
              this.saysoCallFromWhere = undefined;
              this.saysoCallFromLink = undefined;
              this.saysoTarget = undefined;
              this.saysoTargetDisplay = undefined;
            }
          }
        }
      )
    );
  }

  getParsedUri(uri: string | undefined, option?: "local" | "remote"): string {
    let parsedUri = "";
    if (uri) {
      const phoneNumber = new E164PhoneNumber(uri.slice(0, uri.indexOf("@")));
      if (phoneNumber.isValid) {
        // if a phone number was dialed
        parsedUri = phoneNumber.e164Uri;
      } else {
        const onsipUri: OnSIPURI | undefined = OnSIPURI.parseString(uri);
        if (onsipUri && onsipUri.isExtension()) {
          // if EXT was dialed
          parsedUri = "Ext. " + onsipUri.user;
        } else if (uri.endsWith("anonymous.invalid")) {
          parsedUri = "Anonymous Caller";
        } else if (onsipUri && onsipUri.aor) {
          // if there is an onsipUri aor, set parsedUri to the uri aor
          parsedUri = onsipUri.aor;
        } else {
          // if SIP Address was dialed
          parsedUri = uri.slice(4);
        }
      }
    }
    // this is here to make transfer remote/local uri more readable when display on the call page
    // if there is a transfer remote uri, use the call remoteDisplayName instead
    // if there is a transfer local uri or the local uri is undefined which happens on a transfer call, display the user's aor instead
    if (
      option &&
      this.callState &&
      (uri === undefined || uri.match(/^sip:[\w\d]*\*[0-9.]*![0-9]*_n@[0-9.]*.*;gr.*$/))
    ) {
      parsedUri = option === "remote" ? this.callState.remoteDisplayName : this.callState.aor;
    }
    // uri from hard phones are slightly different and often lack remoteDisplayName too
    // luckily in these cases, the remoteUri is baked into the uri and we can pull that out with some string matching
    if (
      uri &&
      (uri.match(/^sip:[\w\d.]*@[\w\d.]*\.com.*\?Replaces.*$/) ||
        (this.callState && !this.callState.remoteDisplayName))
    ) {
      const matchUri = uri.match(/[\w\d.]*@[\w\d.]*\.com/);
      parsedUri = matchUri ? matchUri[0] : uri;
    }
    return parsedUri;
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  ngOnChanges(): void {
    if (this.callUuid) {
      this.callState = this.callControllerService.getCallStateByUuid(this.callUuid);
      if (!this.callState) {
        return;
      }

      this.contact = this.contactService.findUsingAOR(this.callState.remoteUri.slice(4));

      // this means the user is making the call so localDisplayName and localUri must go to 'From' header
      if (this.callState.active && !this.callState.incoming) {
        this.callFromUri = this.getParsedUri(this.callState.localUri, "local");
        this.callToUri = this.getParsedUri(this.callState.remoteUri, "remote");
        this.callWithName = this.callState.remoteDisplayName
          ? this.callState.remoteDisplayName
          : this.callToUri;
        // this means the user is receiving the call so localDisplayName and localUri must go to 'To' header
      } else if (this.callState.active && this.callState.incoming) {
        this.callToUri = this.getParsedUri(this.callState.localUri, "local");
        this.callFromUri = this.getParsedUri(this.callState.remoteUri, "remote");
        this.callWithName = this.callState.remoteDisplayName
          ? this.callState.remoteDisplayName
          : this.callFromUri;
      }
    }
  }
}
