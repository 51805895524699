import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "../../../../modules/material/material.module";
import { ContactListComponent } from "./contactList/contact-list.component";
import { StatusMenuComponent } from "./statusMenu/status-menu.component";

import { AvatarModule } from "../avatar/avatar.module";
import { PipesModule } from "../../pipes/pipes.module";
import { ModalModule } from "../modal/modal.module";
import { UsabilityModule } from "../usability/usability.module";
import { TranslateModule } from "@ngx-translate/core";
import { PopupContainerModule } from "../popupContainer/popup-container.module";
import { ContactModule } from "../../../contact/contact.module";
import { TimerModule } from "../timer/timer.module";
import { OrgSideNavModule } from "./orgSideNav/org-side-nav.module";
import { OrgSideNavComponent } from "./orgSideNav/org-side-nav.component";

@NgModule({
  imports: [
    AvatarModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    PipesModule,
    ModalModule,
    UsabilityModule,
    RouterModule,
    TranslateModule,
    PopupContainerModule,
    ContactModule,
    TimerModule,
    OrgSideNavModule
  ],
  declarations: [StatusMenuComponent, ContactListComponent],
  exports: [StatusMenuComponent, ContactListComponent, OrgSideNavComponent]
})
export class SidebarModule {}
