import { timer, Subscription } from "rxjs";
import { take, filter } from "rxjs/operators";

import { OAuth2Service, AccessToken } from "../../../../services/oauth2/oauth2.service";
import { StateEmitter } from "@onsip/common/libraries/emitter";

const debug = true;

export interface OAuthState {
  authorized: boolean;
  accessToken: AccessToken | undefined;
}

interface HubSpotAccessTokenInfo {
  app_id: number;
  expires_in: number;
  hub_domain: string;
  hub_id: number;
  scopes: Array<string>;
  token: string;
  token_type: string;
  user: string;
  user_id: number;
}

export class OAuth extends StateEmitter<OAuthState> {
  private static service = "Organization.HubSpot";
  private static clientId = "90934277-9870-4277-bd61-b6b3314676c9";
  private static endPoint = "https://app.hubspot.com/oauth/authorize";
  private static scope = "crm.objects.contacts.read crm.objects.owners.read timeline";
  private static windowHeight = 700;
  private static windowWidth = 700;

  private refreshComplete = new Subscription();
  private unsubscriber = new Subscription();

  constructor(private oAuth2: OAuth2Service) {
    super({ authorized: false, accessToken: undefined });
    this.init();
  }

  dispose(): void {
    this.refreshComplete.unsubscribe();
    this.unsubscriber.unsubscribe();
  }

  /**
   * Attempt to get authorization from user to access HupSpot API on their behalf.
   */
  authorize(): Promise<void> {
    const windowOptions = { height: OAuth.windowHeight, width: OAuth.windowWidth };
    // HUBSPOT REDIRECTURI: The redirectUri is now static for hubspot. I didn't want to mess with the other oauth redirectUri so hard coding it here
    const redirectUri = "https://app.onsip.com/app/oauth2/";
    return this.oAuth2
      .authorize(OAuth.clientId, OAuth.endPoint, OAuth.scope, windowOptions, redirectUri)
      .then(result =>
        this.oAuth2.add(OAuth.service, result.authorizationCode, OAuth.scope, result.redirectUri)
      )
      .then(accessToken => {
        this.stateStore.authorized = true;
        this.stateStore.accessToken = { ...accessToken };
        this.scheduleRefresh();
        this.publishState();
      });
  }

  /**
   * True if currently authorized.
   */
  isAuthorized(): boolean {
    return this.stateStore.authorized;
  }

  /**
   * Revoke authorization (disabling HubSpot).
   */
  revoke(): Promise<void> {
    if (!this.stateStore.authorized) {
      return Promise.resolve();
    }
    return this.oAuth2.remove(OAuth.service).then(() => {
      this.refreshComplete.unsubscribe();
      this.refreshComplete = new Subscription();
      this.stateStore.authorized = false;
      this.stateStore.accessToken = undefined;
      this.publishState();
    });
  }

  protected fetch(
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: string
  ): Promise<Response> {
    if (!this.stateStore.accessToken) {
      throw new Error("Access token undefined.");
    }
    const headerFields = [`Authorization: Bearer ${this.stateStore.accessToken.token}`];
    return this.oAuth2.proxy(OAuth.service, path, method, headerFields, body);
  }

  private init(): void {
    this.unsubscriber.add(
      this.oAuth2.state
        .pipe(
          filter(state => !!state),
          take(1)
        )
        .subscribe(() => {
          if (this.oAuth2.has(OAuth.service)) {
            this.refresh();
          }
        })
    );
  }

  private refresh(): Promise<void> {
    debug && console.log(`OAuth.refresh`);
    return this.oAuth2
      .refresh(OAuth.service)
      .then(accessToken => {
        this.stateStore.authorized = true;
        this.stateStore.accessToken = { ...accessToken };
        this.scheduleRefresh();
        this.publishState();
      })
      .catch(error => {
        this.stateStore.authorized = false;
        this.stateStore.accessToken = undefined;
        this.publishState();
        throw error;
      });
  }

  private scheduleRefresh(): void {
    if (!this.stateStore.accessToken) {
      throw new Error("Access token undefined");
    }
    // I just made the following approach up, so...
    //  - expires In must be at least twice "minRefresh"
    //  - we refresh "minRefresh" before the token expires
    const minRefresh = 300000; // 5 min as ms
    const refresh = this.stateStore.accessToken.expiresIn - minRefresh; // refresh min refresh before expires
    if (refresh < minRefresh) {
      throw new Error("Refresh time less than 5 minutes.");
    }
    debug && console.log(`OAuth.scheduleRefresh in ${refresh}`);
    this.refreshComplete.add(
      timer(refresh)
        .pipe(take(1))
        .subscribe(() => this.refresh())
    );
  }

  // @ts-ignore: noUnusedLocals
  private getAccessTokenInfo(): Promise<HubSpotAccessTokenInfo> {
    if (!this.stateStore.accessToken) {
      throw new Error("Access token undefined.");
    }
    const request = `/oauth/v1/access-tokens/${this.stateStore.accessToken}`;
    return this.fetch(request, "GET").then(response => {
      return response.json();
    });
  }
}
