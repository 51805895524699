import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

// this should be imported only in app.component.ts

@Injectable({ providedIn: "root" })

/** Register svgs as mat-icons */
export class CustomIconRegistryService {
  constructor(private domSanitizer: DomSanitizer, private matIconRegistry: MatIconRegistry) {
    this.matIconRegistry.addSvgIcon(
      "onsip_presence_away",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/away.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "onsip_presence_busy",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/busy.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "onsip_presence_available",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/check.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "white_pencil_edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/white_pencil_edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "black_pencil_edit",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/black_pencil_edit.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "facebook_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/facebook_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "linkedin_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/linkedin_icon.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "twitter_icon",
      this.domSanitizer.bypassSecurityTrustResourceUrl("resources/img/icons/twitter_icon.svg")
    );
  }
}
