import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";

import { OnsipTableComponent } from "./table2/table.component";
import {
  OnsipHeaderRowDefDirective,
  OnsipFooterRowDefDirective,
  OnsipRowDefDirective,
  OnsipHeaderRowComponent,
  OnsipFooterRowComponent,
  OnsipRowComponent,
  OnsipNoDataRowDirective
} from "./table2/table-row.component";

import {
  OnsipCellDefDirective,
  OnsipHeaderCellDefDirective,
  OnsipFooterCellDefDirective,
  OnsipColumnDefDirective,
  OnsipHeaderCellDirective,
  OnsipFooterCellDirective,
  OnsipCellDirective
} from "./table2/table-cell.component";

import { TableShelfComponent } from "./table2/table-shelf.component";
import { TableShelfCellComponent } from "./table2/table-shelf-cell.component";

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    OnsipTableComponent,
    OnsipHeaderRowDefDirective,
    OnsipFooterRowDefDirective,
    OnsipRowDefDirective,
    OnsipHeaderRowComponent,
    OnsipFooterRowComponent,
    OnsipRowComponent,
    OnsipNoDataRowDirective,
    OnsipCellDefDirective,
    OnsipHeaderCellDefDirective,
    OnsipFooterCellDefDirective,
    OnsipColumnDefDirective,
    OnsipHeaderCellDirective,
    OnsipFooterCellDirective,
    OnsipCellDirective,
    TableShelfComponent,
    TableShelfCellComponent
  ],
  exports: [
    OnsipTableComponent,
    OnsipHeaderRowDefDirective,
    OnsipFooterRowDefDirective,
    OnsipRowDefDirective,
    OnsipHeaderRowComponent,
    OnsipFooterRowComponent,
    OnsipRowComponent,
    OnsipNoDataRowDirective,
    OnsipCellDefDirective,
    OnsipHeaderCellDefDirective,
    OnsipFooterCellDefDirective,
    OnsipColumnDefDirective,
    OnsipHeaderCellDirective,
    OnsipFooterCellDirective,
    OnsipCellDirective,
    TableShelfComponent,
    TableShelfCellComponent
  ]
})
export class TableModule {}
