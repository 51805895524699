<div class="card">
  <span class="button-controls">
    @if ( (currentFileSubject | async) && (isPlaying | async) === false && (isRecording | async) ===
    false ) {
    <button type="button" mat-icon-button color="primary" (click)="play()">
      <mat-icon>play_arrow</mat-icon>
    </button>
    } @if ((currentFileSubject | async) && (isPlaying | async) && (isRecording | async) === false) {
    <button type="button" mat-icon-button color="primary" (click)="pause()">
      <mat-icon>pause</mat-icon>
    </button>
    } @if ((isRecording | async) === false) {
    <button
      type="button"
      class="large-button"
      mat-icon-button
      color="primary"
      (click)="recording()"
    >
      <mat-icon>mic</mat-icon>
    </button>
    } @if (isRecording | async) {
    <button
      type="button"
      class="large-button"
      mat-icon-button
      color="warn"
      (click)="stopRecording()"
    >
      <mat-icon>stop_circle</mat-icon>
    </button>
    } @if ((currentFileSubject | async) && (isRecording | async) === false) {
    <button type="button" mat-icon-button color="primary" (click)="stop()">
      <mat-icon>stop_circle</mat-icon>
    </button>
    }
  </span>
  @if (mediaRecorder) {
  <canvas
    [height]="canvasHeight"
    [onsipSoundWave]="mediaRecorder"
    (soundWaveBlob)="getSoundWaveBlob($event)"
    class="sound-wave"
  >
  </canvas>
  } @if (!mediaRecorder) {
  <div class="playback-audio-player">
    <div class="time-slider">
      <div #scrubber class="scrubber"></div>
    </div>
  </div>
  }
  <span class="playback-current-time">
    {{
      (isRecording | async)
        ? (this.recordedTime | duration : false)
        : (this.currentTime | duration : false) + " / " + (this.audioDuration | duration : false)
    }}
  </span>
</div>
@if ((currentFileSubject | async) && (isRecording | async) === false) {
<span class="action-button-container">
  <button mat-stroked-button type="button" (click)="discardGreeting()" color="primary">
    Discard
  </button>
  <ng-content></ng-content>
</span>
}
