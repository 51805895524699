export type Maybe<T> = T | void;

export function isDefined<T>(x: Maybe<T>): x is T {
  // eslint-disable-next-line no-null/no-null
  return x !== undefined && x !== null;
}

export function isUndefined<T>(x: Maybe<T>): x is void {
  // eslint-disable-next-line no-null/no-null
  return x === undefined || x === null;
}

export function getOrElse<T>(x: Maybe<T>, defaultValue: T): T {
  return isDefined(x) ? x : defaultValue;
}

// Tests...
//
// function test1(x: Maybe<string>) {
//   const x1 = getOrElse(x, "Undefined");         // string
//   const x2 = isDefined(x) ? x : "Undefined";    // string
//   const x3 = isUndefined(x) ? "Undefined" : x;  // string
// }

// function test2(x: Maybe<number>) {
//   const x1 = getOrElse(x, -1);         // number
//   const x2 = isDefined(x) ? x : -1;    // number
//   const x3 = isUndefined(x) ? -1 : x;  // number
// }
