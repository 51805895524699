<mat-slider
  [color]="color"
  min="0"
  max="100"
  step="1"
  style="min-width: 100%"
  [discrete]="true"
  thumbLabel
>
  <input matSliderThumb (input)="emit(volume)" [(ngModel)]="volume" />
</mat-slider>
