import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "@onsip/web/modules/material/material.module";
import { OnSIPItemListComponent } from "./viewList/onsip-item-list.component";
import { EditItemListComponent } from "./editList/edit-item-list.component";
import { CheckItemListComponent } from "./checkList/check-item-list.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ModalModule } from "../modal/modal.module";
import { TranslateModule } from "@ngx-translate/core";
import { OnSIPMultilineItemListComponent } from "./multiLineViewList/onsip-multiline-item-list.component";
import { PipesModule } from "../../pipes/pipes.module";
import { AliasSipModalComponent } from "./alias-sip-modal/alias-sip-modal.component";
import { SipAddressFieldModule } from "../sipAddressField/sip-address-field.module";
import { AliasSipListComponent } from "./alias-sip-list/alias-sip-list.component";

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ModalModule,
    ReactiveFormsModule,
    TranslateModule,
    SipAddressFieldModule,
    PipesModule
  ],
  declarations: [
    OnSIPItemListComponent,
    AliasSipModalComponent,
    EditItemListComponent,
    CheckItemListComponent,
    OnSIPMultilineItemListComponent,
    AliasSipListComponent
  ],
  exports: [
    OnSIPItemListComponent,
    EditItemListComponent,
    CheckItemListComponent,
    OnSIPMultilineItemListComponent,
    AliasSipListComponent
  ]
})
export class EditItemListModule {}
