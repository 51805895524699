import { Injectable, inject } from "@angular/core";
import { UserAddressService } from "@onsip/common/services/api/resources/userAddress/user-address.service";
import { UserSummaryContactWithAddresses } from "@onsip/common/services/api/resources/userSummaryContact/user-summary-contact";
import { UserSummaryContactService } from "@onsip/common/services/api/resources/userSummaryContact/user-summary-contact.service";
import { IdentityService } from "@onsip/common/services/identity.service";
import { combineLatest, filter, map, shareReplay } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class AppsettingsService {
  identityService = inject(IdentityService);
  userSummaryContactService = inject(UserSummaryContactService);
  userAddressService = inject(UserAddressService);

  loadProfile$ = combineLatest({
    identity: this.identityService.state.pipe(filter(state => state.addresses.length > 0)),
    userSummaryContactBrowse: this.userSummaryContactService.state.pipe(
      filter(state => !state.loading),
      map(state => state.state)
    ),
    userAddresses: this.userAddressService.state.pipe(
      filter(state => !state.loading),
      map(state => state.state)
    )
  }).pipe(
    map(({ identity, userSummaryContactBrowse, userAddresses }) => {
      const primaryUserAddress = identity.defaultIdentity;
      if (primaryUserAddress) {
        const userSummaryContact = userSummaryContactBrowse[primaryUserAddress.userId];

        const userAddress = Object.values(userAddresses).find(
          address => address.userAddressId === primaryUserAddress.userAddressId
        );
        if (userSummaryContact && userAddress) {
          const summary: UserSummaryContactWithAddresses = userSummaryContact;
          summary.previousDefaultAddress = userAddress.previousDefaultAddress;
          summary.defaultAddress = userAddress.defaultAddress;
          summary.timeout = userAddress.timeout;
          summary.sipAddress = userAddress.aor;
          summary.domain = userAddress.domain;
          summary.authUsername = userAddress.authUsername;
          summary.authPassword = userAddress.authPassword;

          return summary;
        }
      }

      return;
    }),
    shareReplay({ bufferSize: 1, refCount: false })
  );
}
