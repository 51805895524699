// New API
import { Notification, Subscriber, SubscriptionState, URI } from "sip.js";

import { Subscription } from "./subscription";
import { UserAgent } from "./user-agent";
import { log } from "./log";

/**
 * A SIP Subscription
 */
export class SubscriptionSIP extends Subscription {
  // SIP.js subscription
  private subscription: Subscriber | undefined;

  /**
   * Creates a new Subscription.
   * @param userAgent UserAgent owning the Subscription.
   */
  constructor(userAgent: UserAgent, uri: URI, eventPackage: string) {
    super(userAgent, uri, eventPackage);
  }

  /**
   * Sends a SUBSCRIBE request.
   */
  subscribe(): Promise<void> {
    log.debug(`SubscriptionSIP[${this.uuid}].subscribe: Subscription[${this.uuid}]`);
    if (!this.subscription) {
      return Promise.reject(new Error("SIP.js subscription does not exist."));
    }
    return this.subscription.subscribe().then(() => super.subscribe());
  }

  /**
   * Sends a SUBSCRIBE request with expires header value of 0, terminating the subscription.
   */
  unsubscribe(): Promise<void> {
    log.debug(`SubscriptionSIP[${this.uuid}].unsubscribe`);
    if (!this.subscription) {
      return Promise.reject(new Error("SIP.js subscription does not exist."));
    }
    return this.subscription.unsubscribe().then(() => super.unsubscribe());
  }

  /** The SIP.js subscription associated with the Subscription. */
  get SIP(): Subscriber {
    if (!this.subscription) {
      throw new Error("SIP.js subscription does not exist.");
    }
    return this.subscription;
  }

  /** Sets the SIP.js subscription associated with the Subscription and activates the Subscription. */
  set SIP(subscription: Subscriber) {
    this.subscription = subscription;
    this.subscription.data = this;
    this.initSubscription();
    this.activate();
  }

  private initSubscription(): void {
    if (!this.subscription) {
      throw new Error("SIP.js subscription does not exist.");
    }

    this.subscription.delegate = {
      onNotify: notification => {
        this.handleNotification(notification);
      }
    };

    this.subscription.stateChange.addListener((newState: SubscriptionState) => {
      if (newState === SubscriptionState.Terminated) {
        log.debug(`SubscriptionSIP[${this.uuid}] state changed to ${newState}`);
        this.onTerminated();
      }
    });
  }

  private handleNotification(notification: Notification): void {
    log.debug(`SubscriptionSIP[${this.uuid}].handleNotification`);
    notification.accept().then(() => {
      const request = notification.request;
      const subscriptionState = request.parseHeader("subscription-state");
      if (subscriptionState === undefined) {
        // TODO: assuming this is not an operational error, so throwing... is it something that should be handled?
        throw new Error('Failed to parse "subscription-state" header.');
      }

      if (subscriptionState.state === undefined) {
        // TODO: assuming this is not an operational error, so throwing... is it something that should be handled?
        throw new Error('Parsed "subscription-state" header missing state.');
      }

      if (subscriptionState.state !== "terminated") {
        this.onNotify(request.body);
      }
    });
  }
}
