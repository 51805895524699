import { SubscriptionEvent, SubscriptionEventFactory } from "./subscription-event";

/**
 * Emitted when the subscriber receives a notification.
 */
export class NotifySubscriptionEvent {
  static make(aor: string, uuid: string, body: string): SubscriptionEvent {
    return SubscriptionEventFactory.make(NotifySubscriptionEvent.id, aor, uuid, body);
  }
  static get id(): string {
    return "[Subscription] notify";
  }
}
