import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { PrephoneGuard } from "./prephone.guard";

import { LoginFormComponent } from "./login/login-form.component";
import { PrephoneComponent } from "./prephone.component";
import { PasswordResetRequestFormComponent } from "./passwordResetRequest/password-reset-request-form.component";
import { ForgotSipAddressFormComponent } from "./forgotSipAddress/forgot-sip-address-form.component";
import { ContactSupportComponent } from "../entryPage/contactSupport/contact-support.component";

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: "",
        canActivate: [PrephoneGuard],
        component: PrephoneComponent,
        children: [
          {
            path: "",
            redirectTo: "login",
            pathMatch: "full"
          },
          {
            path: "login",
            component: LoginFormComponent,
            data: { title: "OnSIP - Login" }
          },
          {
            path: "forgot-password",
            component: PasswordResetRequestFormComponent
          },
          {
            path: "forgot-address",
            component: ForgotSipAddressFormComponent
          },
          {
            path: "support",
            component: ContactSupportComponent
          }
        ]
      }
    ])
  ],
  exports: [RouterModule]
})
export class PrephoneRoutingModule {}
