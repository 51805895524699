<mat-card class="main-card">
  <mat-card-content>
    <div class="shell-container onsip-grid-placement">
      <div class="first-column">
        <onsip-shell-navigations [shellNavigations]="shellNavigations"> </onsip-shell-navigations>
      </div>

      <div class="second-column">
        <mat-card class="router-card">
          <router-outlet></router-outlet>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>
