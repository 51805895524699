import { DocumentData } from "../cloud/firebase/firestore/document";
import { TimestampedState, makeTimestampedState } from "./state";
import { getOrElse } from "./maybe";

/** What users does a topic have (map of topic to array of uids). */
export interface Topics {
  [topic: string]: Array<string>;
}

export interface OrgData {
  topics: Topics;
}

export function makeOrgData(data: DocumentData): OrgData {
  return {
    topics: getOrElse<OrgData["topics"]>(data.topics, {})
  };
}

export interface OrgState extends TimestampedState, OrgData {}

export function makeOrgState(id: string, data: DocumentData): OrgState {
  return {
    ...makeTimestampedState(id, data),
    ...makeOrgData(data)
  };
}
