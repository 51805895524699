@if (!isSmallOrXs) {
<div class="header">
  <div class="mat-headline-6 accounts-title">Support</div>
  <div>
    <a mat-button color="primary" href="https://support.onsip.com" target="_blank">Knowledgebase</a>
  </div>
</div>
}
<div class="info-sections-container onsip-grid-placement">
  <div class="dedicated-rep gray-bg-card">
    <div class="rep-desc-container">
      <div class="avatar">
        <img [src]="'resources/img/chuck-avatar.svg'" alt="chuck-avatar" class="avatar-img" />
      </div>
      <div class="desc-container">
        <div class="desc-title-container">
          <div class="mat-headline-6">Your dedicated</div>
          <div class="mat-headline-6">Channel Sales Engineer</div>
        </div>
        <div class="mat-body-2 desc">Chuck Dunne is always available to support you.</div>
      </div>
    </div>
    <div class="contact-container">
      <div>
        Phone:<span
          >&nbsp;<a
            class="primary-500 mat-body-2 call-link"
            onsipNewCall
            remoteAor="12129339369@onsip.com"
            >1(212)933-9369</a
          ></span
        >
      </div>
      <div>
        Email:<span
          >&nbsp;<a class="primary-500 mat-body-2" href="mailto:chuck@onsip.com"
            >chuck&#64;onsip.com</a
          ></span
        >
      </div>
    </div>
  </div>
  <div class="knowledgebase-container gray-bg-card info-card">
    <div>
      <div class="mat-headline-6 info-card-title">OnSIP user guide</div>
      <mat-divider onsip-solid-divider></mat-divider>
      <div class="info-card-desc">
        <div>Knowledgebase is our user guide for all things OnSIP:</div>
        <ul>
          <li>Set up users</li>
          <li>Provision phones</li>
          <li>Voicemail</li>
          <li>More advanced features...</li>
        </ul>
      </div>
    </div>
    <a href="https://support.onsip.com" target="_blank">
      <button mat-flat-button color="primary" class="onsip-support-buttons">
        <div>Go to Knowledgebase &nbsp;</div>
        <mat-icon class="open-new-icon">open_in_new</mat-icon>
      </button>
    </a>
  </div>
  <div class="resources-container gray-bg-card info-card">
    <div>
      <div class="mat-headline-6 info-card-title">Partner resources</div>
      <mat-divider onsip-solid-divider></mat-divider>
      <div class="info-card-desc">
        <div>Get access our marketing and sales support package including:</div>
        <ul>
          <li>Sales collateral</li>
          <li>Support tools</li>
          <li>OnSIP marketing guides</li>
          <li>Partner resources</li>
        </ul>
      </div>
    </div>
    <a href="https://www.onsip.com/partner" target="_blank">
      <button mat-flat-button color="primary" class="onsip-support-buttons">
        <div>Go to Partner resources&nbsp;</div>
        <mat-icon class="open-new-icon">open_in_new</mat-icon>
      </button>
    </a>
  </div>
</div>
<div class="general-support-container">
  <div class="mat-headline-6">General support</div>
  <mat-divider onsip-solid-divider></mat-divider>
  <div class="phone-subsection">
    <div class="mat-subtitle-1">Phone</div>
    <div class="mat-body-2">
      Call the Support Team on
      <a class="primary-500 mat-body-2 call-link" onsipNewCall remoteAor="18008013381@onsip.com"
        >800-801-3381</a
      >
      and select <strong>Option 2</strong>
    </div>
  </div>
  <div>
    <div class="mat-subtitle-1">Email</div>
    <div class="mat-body-2">
      Submit your request here. Please include your email address in the description field so that
      we can follow-up directly with the relevant person in your organization.
    </div>
  </div>
</div>
<onsip-admin-support-submit-ticket
  [showSubject]="false"
  [showHeader]="false"
></onsip-admin-support-submit-ticket>
