import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";
import { Message } from "sip.js";

import { Call, makeCall } from "./call";
import { Calls } from "./calls";
import { CallContact } from "./call-contact";
import { CallConfiguration } from "./call-configuration";
import { CallConstructor } from "./call-constructor";
import { CallEvent, isNewCallEvent } from "./call-event";
import { CallGroup } from "./call-group";
import { CallGroupConstructor } from "./call-group-constructor";
import { CallGroups } from "./call-groups";
import { CallState } from "./call-state";
import { CallControllerConfiguration } from "./call-controller-configuration";
import {
  GroupNotFoundCallError,
  InvalidTargetCallError,
  HoldFailedCallError,
  MaximumCallGroupsReachedCallError,
  MaximumCallsPerGroupReachedCallError,
  NotFoundCallError,
  UserAgentNotConnectedCallError,
  UserAgentNotFoundCallError
} from "./call-controller-error";
import { CallControllerEvent, ResetCallControllerEvent } from "./call-controller-event";
import { CallControllerState } from "./call-controller-state";
import { EventStateEmitter } from "../emitter/event-state-emitter";
import { Session } from "./session";
import { UserAgent } from "./user-agent";
import { UserAgents } from "./user-agents";
import { log } from "./log";

/**
 * CallControllerImplementation.
 * The inteface provided by this class is designed to be overridden
 * to allow for domain specific call handling implementations (i.e. CallKit)
 */
export class CallController<
  CE extends CallEvent = CallEvent,
  CS extends CallState = CallState
> extends EventStateEmitter<CallControllerEvent, CallControllerState<CS>> {
  /** All calls. */
  calls: Calls<CE, CS> = new Calls();
  /** User agents available to make calls. */
  userAgents: UserAgents = new UserAgents();

  /** Incoming calls which have not yet been accepted or declined. */
  protected incoming: Calls<CE, CS> = new Calls();
  /** Outgoing calls and incoming calls which have been accepted organized in call groups. */
  protected groups: CallGroups<CE, CS> = new CallGroups();

  private lockStateStore = false;
  private unsubscribe: Subject<void> = new Subject<void>();

  // Keep track of all invalid userAgents which failed to unregister
  // A common occurence in mobile where we logout but had poor connection
  private invalidUserAgents: Array<UserAgent> = [];

  /**
   * Constructor
   * @param configuration The call controller configuration.
   * @param callConstructor The call class.
   * @param callGroupConstructor The call group class.
   */
  constructor(
    private configuration: CallControllerConfiguration,
    private callConstructor: CallConstructor<CE, CS> = Call,
    private callGroupConstructor: CallGroupConstructor<CE, CS> = CallGroup
  ) {
    super({
      calls: [],
      incoming: [],
      groups: [],
      userAgents: []
    });
    this.init();
    log.debug("CallController: Constructed");
  }

  /**
   * Destructor
   */
  dispose(): void {
    log.debug("CallController: Disposing...");
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.disposeCalls();
    this.calls.dispose();
    this.incoming.dispose();
    this.groups.dispose();
    this.disposeUserAgents();
    this.userAgents.dispose();
    log.debug("CallController: Disposed");
  }

  /**
   * Dispose of all Calls (but not the observable)
   */
  disposeCalls(): void {
    // nothing to do
    if (!this.calls.array.length) {
      return;
    }

    // discard any calls which never activated (they would fail to close)
    this.calls.array
      .filter(call => !call.newAt)
      .forEach(call => {
        this.calls.remove(call);
        call.session.dispose();
      });

    // close all activated calls
    this.calls.array.forEach(call => this.end(call.uuid));
  }

  /**
   * Dispose of all UserAgents (but not the observable)
   */
  disposeUserAgents(): void {
    this.userAgents.array.forEach(userAgent => {
      this.userAgents.remove(userAgent);
      userAgent
        .unregister()
        .then(() => {
          userAgent.dispose();
        })
        .catch(err => {
          console.log("CallController.disposeUserAgents failed with: " + err);
          this.invalidUserAgents.push(userAgent);
        });
    });
  }

  // If we have any userAgents that failed to unregister previously, dispose of them here
  disposeInvalidUserAgents(): void {
    for (let i = this.invalidUserAgents.length - 1; i >= 0; i--) {
      const userAgent: UserAgent = this.invalidUserAgents[i];
      this.invalidUserAgents.splice(i, 1);
      userAgent
        .reconnect()
        .then(() =>
          userAgent.unregister().then(() => {
            userAgent.dispose();
          })
        )
        .catch(() => {
          this.invalidUserAgents.push(userAgent);
        });
    }
  }
  /**
   * Reconnect all UserAgents (but not the observable)
   */
  reconnectUserAgents(): void {
    this.userAgents.array.forEach(userAgent => userAgent.reconnect());
  }

  /**
   * Reconnect and register UserAgents (but not the observable)
   */
  refreshUserAgents(): Promise<void> {
    return Promise.all(
      this.userAgents.array.map(userAgent => userAgent.reconnect().then(() => userAgent.register()))
    ).then(() => Promise.resolve());
  }

  /**
   * Add a UserAgent to use for making calls.
   * @param userAgent UserAgent to add.
   */
  addUserAgent(userAgent: UserAgent): void {
    this.userAgents.add(userAgent);
  }

  /**
   * Answers an incoming Call.
   * @param uuid The UUID of the Call.
   * @reject {NotFoundCallError} Call not found.
   */
  accept(uuid: string, configuration?: CallConfiguration): Promise<void> {
    log.debug(`CallController[${uuid}].accept`);
    return this.getCall(uuid).then(call => {
      if (configuration) {
        call.session.setAudioRequested(configuration.audio);
        call.session.setVideoRequested(configuration.video);
      } else {
        // by default match with what is available from peer or is expected to be available from peer
        call.session.setAudioRequested(call.session.audioAvailable || call.session.audioExpected);
        call.session.setVideoRequested(call.session.videoAvailable || call.session.videoExpected);
      }
      return call.accept().then(() => {
        // lock state store so we don't publish state with call in both incoming and groups
        this.lockStateStore = true;
        // create a new group for the call and add it
        this.groups.add(new this.callGroupConstructor(this, call));
        // remove it from incoming calls
        this.incoming.remove(call);
        // unlock state store
        this.lockStateStore = false;
        // publish state
        this.publishState();
      });
    });
  }

  /**
   * Enables audio on a call.
   * @param uuid The uuid of the call.
   * @param audio If true, audio is enabled.
   * @reject {NotFoundCallError} On call not found.
   */
  audio(uuid: string, audio: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].audio`);
    return this.getCall(uuid).then(call => call.setAudio(audio));
  }

  /**
   * Begins a new outgoing Call.
   * @param aor The AOR of the UserAgent to use for the Call.
   * @param contact The CallContact to begin a Call with.
   * @param configuraton The CallConfiguration to begin a Call with.
   * @reject {InvalidTargetCallError} Invalid target.
   * @reject {MaximumCallGroupsReachedCallError} Maximum number of call groups reached.
   * @reject {UserAgentNotConnectedCallError} User agent is not connected.
   * @reject {UserAgentNotFoundCallError} User agent is not found.
   */
  begin(aor: string, contact: CallContact, configuration: CallConfiguration): Promise<string> {
    log.debug(`CallController[${aor}].begin`);
    return this.getUserAgent(aor)
      .then(userAgent => this.createCall(userAgent, contact, configuration)) // create a new call
      .then(call => this.beginCall(call, contact)) // activate the call
      .then(call => call.uuid); // resolve the call's uuid
  }

  /**
   * Sends a PROGRESS response 183 for call tracking purposes
   * @param uuid The UUID of the call
   */
  callProgressTracking(uuid: string): Promise<void> {
    log.debug(`CallController[${uuid}].progress`);
    return this.getCall(uuid).then(call => call.callProgressTracking());
  }

  /**
   * Ignores an incoming Call.
   * @param uuid The UUID of the Call.
   * @reject {NotFoundCallError} Call not found.
   */
  decline(uuid: string): Promise<void> {
    log.debug(`CallController[${uuid}].decline`);
    return this.getCall(uuid).then(call =>
      call.decline().then(() => {
        // remove it from incoming calls
        this.incoming.remove(call);
      })
    );
  }

  /**
   * Ends a call.
   * @param uuid The uuid of the call.
   * @reject {NotFoundCallError} call not found.
   */
  end(uuid: string): Promise<void> {
    log.debug(`CallController[${uuid}].end`);
    return this.getCall(uuid).then(call => call.end());
  }

  /**
   * Ends a group
   * @param uuid The uuid of the group.
   * @reject {NotFoundCallError} call not found.
   * @reject {NotFoundCallGroupError} group not found.
   */
  endGroup(uuid: string): Promise<void> {
    log.debug(`CallController[${uuid}].endGroup`);
    return this.getGroup(uuid).then(group => group.end());
  }

  /**
   * Groups (merges) a Call with one or more other Calls (i.e. three-way confererence)
   * @param uuid The UUID of the Call.
   * @param uuidToGroupWith The UUID of a Call in the target group. If undefined, ungroup.
   * @reject {MaximumCallsPerGroupReachedCallError} Maximum calls per group reached.
   * @reject {NotFoundCallError} Call not found.
   */
  groupWith(uuid: string, uuidToGroupWith: string | undefined): Promise<void> {
    log.debug(`CallController[${uuid}].groupWith ${uuidToGroupWith}`);

    // FIXME: this is not enough... obviously more cases.
    if (this.configuration.maximumCallsPerCallGroup === 1) {
      return Promise.reject(
        new MaximumCallsPerGroupReachedCallError("Maximum calls per group reached.")
      );
    }

    //
    // TODO: FIXME: Remote parties cannot hear each other. Client side conferencing is a big todo.
    //

    // first find target call in whatever group it is currently in
    const group = this.groups.array.find(groupObj => groupObj.calls.get(uuid) !== undefined);
    if (!group) {
      console.error("call uuid unable to ungroup:", uuid);
      return Promise.reject(new NotFoundCallError(`CallController unable to find call to ungroup`));
    }
    const call = group.calls.get(uuid);
    if (!call) {
      console.error("call uuid unable to ungroup:", uuid);
      return Promise.reject(new NotFoundCallError(`CallController unable to find call to ungroup`));
    }

    if (uuidToGroupWith) {
      // if we are to group it with another call, then put it in the group with that call
      const groupWith = this.groups.array.find(
        innerGroup => innerGroup.calls.get(uuidToGroupWith) !== undefined
      );
      if (!groupWith) {
        console.error("call uuid unable to find group:", uuid, " and group with:", uuidToGroupWith);
        return Promise.reject(
          new NotFoundCallError(`CallController unable to find call to group with`)
        );
      }
      const callToGroupWith = groupWith.calls.get(uuidToGroupWith);
      if (!callToGroupWith) {
        console.error("call uuid unable to find group:", uuid, " and group with:", uuidToGroupWith);
        return Promise.reject(
          new NotFoundCallError(`CallController unable to find call to group with`)
        );
      }
      return call.groupWith(callToGroupWith).then(() => {
        groupWith.add(call);
        group.remove(call);
      });
    } else {
      // otherwise we are ungrouping it so put it in a new group of its own
      const newGroup = new this.callGroupConstructor(this, call);
      this.groups.add(newGroup);
      group.remove(call);
      return Promise.resolve();
    }
  }

  /**
   * Hold/Unhold call.
   * @param uuid The uuid of the call.
   * @param hold If true, call is placed on hold.
   * @reject {NotFoundCallError} On call not found.
   * @reject {HoldFailedCallError} On failure to hold call.
   */
  hold(uuid: string, hold: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].hold`);
    return this.getCall(uuid)
      .then(call => call.setHold(hold))
      .catch(e => {
        // FIXME: This should propagate as a typed error from lower down
        if (e instanceof Error) {
          console.log("uuid on hold fail is " + uuid);
          const error = new HoldFailedCallError(`CallController unable to hold Call.`);
          throw error;
        }
        throw e;
      });
  }

  /**
   * Hold/Unhold group.
   * @param uuid The uuid of the group.
   * @param hold If true, group is placed on hold.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   */
  holdGroup(uuid: string, hold: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].holdGroup`);
    return this.getGroup(uuid).then(group => group.setHold(hold));
  }

  /**
   * Set max call time.
   * @param uuid The uuid of the call.
   * @param maxCallTime Maximum call time in milliseconds, terminate this amt of time after connectedAt
   * @reject {NotFoundCallError} On call not found.
   */
  maxCallTime(uuid: string, maxCallTime: number): Promise<void> {
    log.debug(`CallController[${uuid}].maxCallTime`);
    return this.getCall(uuid).then(call => call.setMaxCallTime(maxCallTime));
  }

  /**
   * Mute/Unmute call.
   * @param uuid The uuid of the call.
   * @param mute If true, call is muted.
   * @reject {NotFoundCallError} On call not found.
   */
  mute(uuid: string, mute: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].mute`);
    return this.getCall(uuid).then(call => call.setMute(mute));
  }

  /**
   * Mute/Unmute a group.
   * @param uuid The uuid of the group.
   * @param mute If true, group is muted.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   */
  muteGroup(uuid: string, mute: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].muteGroup`);
    return this.getGroup(uuid).then(group => group.setMute(mute));
  }

  /**
   * Send DTMF on call.
   * @param uuid The uuid of the call.
   * @param tone The tone to send.
   * @param type The type of tone.
   * @reject {NotFoundCallError} On call not found.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playDTMF(uuid: string, tone: string, type: number = 0): Promise<void> {
    log.debug(`CallController[${uuid}].playDTMF`);
    return this.getCall(uuid).then(call => call.playDTMF(tone));
  }

  /**
   * Send DTMF to group.
   * @param uuid The uuid of the group.
   * @param tone The tone to send.
   * @param type The type of tone.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playDTMFGroup(uuid: string, tone: string, type: number = 0): Promise<void> {
    log.debug(`CallController[${uuid}].playDTMFGroup`);
    return this.getGroup(uuid).then(group => group.playDTMF(tone));
  }

  /**
   * blind Transfers a call.
   * @param uuid The uuid of the call.
   * @param target The target of the transfer.
   * @reject {InvalidTargetCallError} On invalid target.
   * @reject {NotFoundCallError} On call not found.
   */
  blindTransfer(uuid: string, target: string): Promise<void> {
    log.debug(`CallController[${uuid}].blindTransfer ${target}`);
    return this.getCall(uuid).then(call => {
      const uri = call.session.userAgent.targetToURI(target);
      if (!uri) {
        throw new InvalidTargetCallError("Failed to create URI from " + target);
      }
      call.blindTransfer(uri);
    });
  }

  /**
   * attended Transfers a call.
   * @param uuid The uuid of the call.
   * @param target The target of the transfer.
   * @reject {InvalidTargetCallError} On invalid target.
   * @reject {NotFoundCallError} On call not found.
   */
  attendedTransfer(transfererUuid: string, transfereeUuid: string): Promise<void> {
    log.debug(`CallController[${transfererUuid}].AttendedTransfer ${transfereeUuid}`);
    return Promise.all([this.getCall(transfererUuid), this.getCall(transfereeUuid)]).then(vals => {
      const transfererCall: Call<CE, CS> | undefined = vals[0],
        transfereeCall: Call<CE, CS> | undefined = vals[1];

      if (!transfererCall || !transfereeCall) {
        throw new NotFoundCallError("Could not find " + transfererUuid + " or " + transfereeUuid);
      }
      transfererCall.attendedTransfer(transfereeCall);
    });
  }

  /**
   * Blind Transfers a group.
   * @param uuid The uuid of the group.
   * @param target The target of the transfer.
   * @reject {InvalidTargetCallError} On invalid target.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   */
  blindTransferGroup(uuid: string, target: string): Promise<void> {
    log.debug(`CallController[${uuid}].transferGroup ${target}`);
    return this.getGroup(uuid).then(group => group.blindTransfer(target));
  }

  /**
   * Attended Transfers a group.
   * @param uuid The uuid of the group.
   * @param target The target of the transfer.
   * @reject {InvalidTargetCallError} On invalid target.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   * @reject {InvalidTargetCallError} if either group has more than 1 call.
   */
  attendedTransferGroup(transfererUuid: string, transfereeUuid: string): Promise<void> {
    log.debug(`CallController[${transfererUuid}].transferGroup ${transfereeUuid}`);
    return Promise.all([this.getGroup(transfererUuid), this.getGroup(transfereeUuid)]).then(
      vals => {
        const transfererCallGroup: CallGroup<CE, CS> | undefined = vals[0],
          transfereeCallGroup: CallGroup<CE, CS> | undefined = vals[1];

        if (
          !transfererCallGroup ||
          !transfereeCallGroup ||
          transfererCallGroup.calls.array.length === 1 ||
          transfereeCallGroup.calls.array.length === 1
        ) {
          throw new InvalidTargetCallError("Attended Transfer Groups each must have one call");
        }
        return transfererCallGroup.attendedTransfer(transfereeCallGroup);
      }
    );
  }

  /**
   * Enables video on a call.
   * @param uuid The uuid of the call.
   * @param video If true, video is enabled.
   * @reject {NotFoundCallError} On call not found.
   */
  video(uuid: string, video: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].video`);
    return this.getCall(uuid).then(call => call.setVideo(video));
  }

  /**
   * Enables video on a group.
   * @param uuid The uuid of the group.
   * @param video If true, video is enabled.
   * @reject {NotFoundCallError} On call not found.
   * @reject {NotFoundCallGroupError} On group not found.
   */
  videoGroup(uuid: string, video: boolean): Promise<void> {
    log.debug(`CallController[${uuid}].videoGroup`);
    return this.getGroup(uuid).then(group => group.setVideo(video));
  }

  /**
   * UserAgentDelegate Implementation
   * @param message Incoming Message to be handled.
   */
  handleIncomingMessage(message: Message): void {
    log.debug(`CallController.handleIncomingMessage ${message.request.body}`);
    // TODO
    return;
  }

  /**
   * UserAgentDelegate Implementation
   * @param session Incoming Session to be handled.
   */
  handleIncomingSession(session: Session): void {
    log.debug(`CallController.handleIncomingSession ${session.uuid}`);
    // TODO: behavior should be configurable
    // see that audio is enabled and video is disabled initially on incoming calls...
    session.setAudio(true);
    session.setVideo(false);
    const call = new this.callConstructor(session);
    this.calls.add(call);
    return; // session (and thus call) activated immedaitely after this return
  }

  /**
   * Activate a Call.
   * If activation succeeds, add the call to a new call group and resolves.
   * If activation fails, removes the call and rejects.
   * @param call Call to activate.
   * @reject {MaximumCallGroupsReachedCallError} Maximum call groups reached.
   */
  protected activateCall(call: Call<CE, CS>): Promise<Call<CE, CS>> {
    log.debug(`CallController[${call.uuid}].activateCall`);
    if (
      this.configuration.maximumCallGroups &&
      this.groups.array.length === this.configuration.maximumCallGroups
    ) {
      this.calls.remove(call);
      return Promise.reject(new MaximumCallGroupsReachedCallError("Maximum call groups reached."));
    }
    return (
      call.session.userAgent
        .invite(call.session)
        .then(() => {
          // create a new group for the call and add it
          this.groups.add(new this.callGroupConstructor(this, call));
          return call;
        })
        // remove the call if something went wrong
        .catch(error => {
          this.calls.remove(call);
          call.session.dispose();
          throw error;
        })
    );
  }

  /**
   * Begins a Call. This a hook for sub-classes to override so
   * they can grab the call after created but before it is activated.
   * @param call The call to start.
   * @param contact The contact being called.
   * @param configuration The configuration of the call
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected beginCall(call: Call<CE, CS>, contact: CallContact): Promise<Call<CE, CS>> {
    return this.activateCall(call);
  }

  /**
   * Creates a new Call (but does not activate the Call).
   * @param userAgent UserAgent to associate the Call with.
   * @param contact The contact info of the Session peer.
   * @param configuration The configuration of the call.
   * @reject {InvalidTargetCallError} Invalid target.
   * @reject {UserAgentNotConnectedCallError} User agent is not connected.
   */
  protected createCall(
    userAgent: UserAgent,
    contact: CallContact,
    configuration: CallConfiguration
  ): Call<CE, CS> {
    // not gonna work if not connected
    if (!userAgent.connected) {
      throw new UserAgentNotConnectedCallError(
        `CallController[${userAgent.aor}] User agent not connected.`
      );
    }
    const call = makeCall(userAgent, this.callConstructor, contact, configuration);
    this.calls.add(call);
    return call;
  }

  /**
   * Returns the Call identified by the UUID.
   * @param uuid The UUID of the Call.
   * @reject {NotFoundCallError} Call not found.
   */
  protected getCall(uuid: string): Promise<Call<CE, CS>> {
    const call = this.calls.get(uuid);
    if (!call) {
      return Promise.reject(new NotFoundCallError(`CallController[${uuid}] unable to find Call.`));
    }
    return Promise.resolve(call);
  }

  /**
   * Returns the CallGroup identified by the UUID.
   * @param uuid The UUID of the CallGroup.
   * @reject {NotFoundCallGroupError} CallGroup not found.
   */
  protected getGroup(uuid: string): Promise<CallGroup<CE, CS>> {
    const group = this.groups.get(uuid);
    if (!group) {
      return Promise.reject(
        new GroupNotFoundCallError(`CallController[${uuid}] unable to find CallGroup.`)
      );
    }
    return Promise.resolve(group);
  }

  /**
   * Returns the UserAgent identified by the AOR.
   * @param aor The AOR of the UserAgent.
   * @reject {NotFoundUserAgentError} UserAgent not found.
   */
  protected getUserAgent(aor: string): Promise<UserAgent> {
    const ua = this.userAgents.get(aor);
    if (!ua) {
      return Promise.reject(
        new UserAgentNotFoundCallError(`CallController[${aor}] unable to find UserAgent.`)
      );
    }
    return Promise.resolve(ua);
  }

  /**
   * Some issue occured and we need to reset (for example, CallKit resets).
   */
  protected onReset(): void {
    this.publishEvent(new ResetCallControllerEvent());
  }

  /**
   * Overload.
   */
  protected publishState() {
    if (this.lockStateStore) {
      return;
    }
    super.publishState();
  }

  private init(): void {
    this.calls.event.pipe(filter(isNewCallEvent), takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        // add new incoming calls
        const call = this.calls.get(next.uuid);
        if (call && call.incoming) {
          this.incoming.add(call);
        }
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.calls.isEventStopped) {
          const error = new Error("CallController call event emitter completed unexpectedly");
          log.error(error.toString());
          throw error;
        }
      }
    });

    this.calls.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.calls = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.calls.isStateStopped) {
          const error = new Error("CallController call state emitter completed unexpectedly");
          log.error(error.toString());
          throw error;
        }
      }
    });

    this.incoming.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.incoming = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.incoming.isStateStopped) {
          const error = new Error("CallController incoming state emitter completed unexpectedly");
          log.error(error.toString());
          throw error;
        }
      }
    });

    this.groups.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.groups = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.groups.isStateStopped) {
          const error = new Error("CallController group state emitter completed unexpectedly");
          log.error(error.toString());
          throw error;
        }
      }
    });

    this.userAgents.state.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: next => {
        this.stateStore.userAgents = next;
        this.publishState();
      },
      error: (error: unknown) => {
        log.error(error as string);
        throw error;
      },
      complete: () => {
        if (this.userAgents.isStateStopped) {
          const error = new Error("CallController userAgent state emitter completed unexpectedly");
          log.error(error.toString());
          throw error;
        }
      }
    });
  }
}
