import { ModalMaterialComponent } from "./modal-material.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";
import { PipesModule } from "../../pipes/pipes.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, PipesModule, TranslateModule],
  declarations: [ModalMaterialComponent],
  exports: [ModalMaterialComponent]
})
export class ModalModule {}
