import { Injectable } from "@angular/core";

import { StateEmitter } from "../../libraries/emitter/state-emitter";

import { OAuthPresencePublisher } from "../../libraries/firebase/realtime/oauth-presence-publisher";

interface OAuthPresenceState {
  status: "init" | "connected" | "disconnected";
}

@Injectable({ providedIn: "root" })
export class OAuthPresencePublisherService extends StateEmitter<OAuthPresenceState> {
  private presencePublisher: OAuthPresencePublisher | undefined;

  constructor() {
    super({ status: "init" });
  }

  start(id: string, token: string) {
    this.presencePublisher = new OAuthPresencePublisher(id, token);
    this.presencePublisher.state.subscribe(state => {
      this.stateStore.status = state.status;
      this.publishState();
    });
    this.presencePublisher.publish();
  }

  stop() {
    if (this.presencePublisher) {
      this.presencePublisher.dispose();
      this.presencePublisher = undefined;
    }
  }
}
