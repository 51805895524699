<div class="left">
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.APP_VOLUME" | translate }}
    </span>
    <span class="volume-container" id="onsip-user-settings-volume">
      <mat-icon aria-label="volume_mute">volume_mute</mat-icon>
      <onsip-volume-slider class="volume-slider" color="primary"></onsip-volume-slider>
      <mat-icon aria-label="volume_up">volume_up</mat-icon>
    </span>
  </div>
  <div class="card-section">
    <span class="label"> Browser notifications </span>
    <div class="notification-controls-container">
      <div class="checkbox-control-setting">
        <mat-checkbox
          color="primary"
          onsip-checkbox-thin-border
          class="user-settings-toggle"
          [checked]="allNotificationsEnabled$ | async"
          (change)="setNotification('all', $event.checked)"
          disableRipple
          [disabled]="isNotificationsDisabled"
        >
          {{ "ONSIP_I18N.ALL" | translate }}
        </mat-checkbox>
      </div>
      @for (type of notificationTypes$ | async; track type) {
      <div class="checkbox-control-setting">
        <mat-checkbox
          color="primary"
          onsip-checkbox-thin-border
          class="user-settings-toggle"
          [checked]="(notifications$ | async)![type]"
          (change)="setNotification(type, $event.checked)"
          disableRipple
          [disabled]="isNotificationsDisabled"
        >
          @if (type === 'from incoming calls') {
          {{ "ONSIP_I18N.FROM_INCOMING_CALLS" | translate }}
          } @if (type === 'on chat') {
          {{ "ONSIP_I18N.ON_CHAT" | translate }}
          } @if (type === 'on hangup') {
          {{ "ONSIP_I18N.ON_HANGUP" | translate }}
          } @if (type === 'on queue alert') {
          {{ "ONSIP_I18N.ON_QUEUE_ALERT" | translate }}
          } @if (type === 'on transfer') {
          {{ "ONSIP_I18N.ON_TRANSFER" | translate }}
          }
        </mat-checkbox>
      </div>
      }
    </div>
  </div>
  <div class="section-spacing card-section">
    <span class="label">{{ "ONSIP_I18N.VERSION" | translate }}</span>
    <p class="mat-body-2">{{ versionNumber }}</p>
  </div>
</div>
<div class="right">
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.INBOUND_CALLS_RING_FOR" | translate }}
    </span>
    <mat-form-field
      appearance="outline"
      onsip-form-field-flush-outline
      onsip-form-field-outline-white
      onsip-mat-select-small-height
      class="settings-form small-width"
    >
      <mat-select [(ngModel)]="failoverTime" (ngModelChange)="updateFailover(undefined)">
        @for (failoverTime of secondsArr; track failoverTime) {
        <mat-option [value]="failoverTime">
          {{ failoverTime }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  @if (showFailoverAddress) {
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.IF_UNANSWERED_SEND_TO" | translate }}
    </span>
    <mat-form-field
      appearance="outline"
      onsip-form-field-flush-outline
      onsip-form-field-outline-white
      onsip-mat-select-small-height
      class="settings-form large-width"
    >
      <mat-select [(ngModel)]="failoverSelection" (ngModelChange)="updateFailover($event)">
        @for (failoverOption of failoverOptions; track failoverOption) {
        <mat-option
          [value]="failoverOption.Address"
          selected="failoverSelection === failoverOption.Address"
        >
          {{ failoverOption.Name }}
        </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  } @if (showQueueWarnings$ | async) {
  <div class="section-spacing">
    <onsip-queue-warnings-settings class="user-settings-queue-warnings">
    </onsip-queue-warnings-settings>
  </div>
  } @if (thirdPartySignInService) {
  <!-- if google -->
  @if (thirdPartySignInService === 'GoogleLogin') {
  <div class="section-spacing">
    <button
      id="onsip--user-settings--disable-google-sign-in"
      mat-raised-button
      color="primary"
      class="oauth-btn oauth__setting"
      (click)="deleteThirdPartySignIn()"
    >
      {{ "ONSIP_I18N.DISABLE_GOOGLE_SIGN_IN" | translate }}
    </button>
  </div>
  }
  <!-- if slack -->
  @if (thirdPartySignInService === 'SlackLogin') {
  <div class="section-spacing">
    <button
      id="onsip--user-settings--disable-slack-sign-in"
      mat-raised-button
      color="primary"
      class="oauth-btn oauth__setting"
      (click)="deleteThirdPartySignIn()"
    >
      {{ "ONSIP_I18N.DISABLE_SLACK_SIGN_IN" | translate }}
    </button>
  </div>
  } }
  <div class="section-spacing">
    <div class="horizontal-card-section">
      <span class="label">{{ "ONSIP_I18N.HUBSPOT_INTEGRATION" | translate }}</span>
      <onsip-hubspot-authorized-toggle></onsip-hubspot-authorized-toggle>
    </div>
  </div>
  <div class="section-spacing">
    <a
      id="onsip--user-settings--open-user-feedback-modal"
      class="primary-500 mat-body-1 provide-feedback-link"
      (click)="onProvideFeedbackClick()"
    >
      {{ "ONSIP_I18N.PROVIDE_FEEDBACK" | translate }}
    </a>
  </div>
</div>
