import { Observable, Subject } from "rxjs";

import { EventEmitterInterface } from "./event-emitter";
import { StateEmitter } from "./state-emitter";

/**
 * This is a cover on BehaviorSubject which exposes it as an Observable and sets up a storage cache.
 * It is intended to be extended by subclasses which need to emit their state as well as events.
 */
export class EventStateEmitter<E, S> extends StateEmitter<S> implements EventEmitterInterface<E> {
  /** The public Observable that the Observer subscribes to for the event stream. */
  event: Observable<E>;

  /** The implementation a Subject. */
  private _event: Subject<E>;

  /**
   * Constructor is protected - the expectation is that this class will be extended.
   * @param stateStore Initial copy of the state store which can then be updated directly by the subclass.
   */
  protected constructor(protected stateStore: S) {
    super(stateStore);
    this._event = new Subject();
    this.event = this._event.asObservable();
  }

  /**
   * Returns true if the Observable has stopped. Common use is to check if a subscription completed unexpectedly.
   * For example, you can use this to check to see if your subscription completed because the Observable completed.
   */
  get isEventStopped(): boolean {
    return this._event.closed;
  }

  /** Completes the Observable */
  protected publishEventComplete(): void {
    this.publishRun(() => {
      this._event.complete();
    });
  }

  /** Errors the Observable */
  protected publishEventError(error: any): void {
    this.publishRun(() => {
      this._event.error(error);
    });
  }

  /** Publish an event, thus notifying Observers */
  protected publishEvent(event: E): void {
    this.publishRun(() => {
      this._event.next(event);
    });
  }
}
