import { Injectable } from "@angular/core";
import { ApiReadAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { sessionId } from "../../apiParams/session-id";
import { OnsipApiResponse } from "../../apiResponse/response-body-new";
import { TermsAndConditions, apiTacToTac as clean } from "./terms-and-conditions";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { TermsAndConditions };

const debug = false;

@Injectable({ providedIn: "root" })
export class TermsAndConditionsService extends ApiResourceService<TermsAndConditions> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "TermsAndConditions", "name");
    debug && this.state.subscribe(state => console.warn("TermsAndConditionsService", state));
  }

  termsAndConditionsRead(): ApiPromiseState<TermsAndConditions> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.TermsAndConditionsRead,
        SessionId: this.store.state.pipe(sessionId())
      }
    });
    return this.promiseState.toPromise(ApiReadAction.TermsAndConditionsRead);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiReadAction.TermsAndConditionsRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            <Array<TermsAndConditions>>(
              // yeah this one is weirdly nested so we cant use the default extractData here
              (response as any).Result[action]["TermsAndConditions"]["Services"]["Service"].map(
                clean
              )
            ),
            this.indexKeyName
          ),
          action
        );
    }
  }
}
