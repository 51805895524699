import { Injectable } from "@angular/core";

import { StateEmitter } from "../../libraries/emitter/state-emitter";

import { IApiError as ApiError } from "../../services/api/onsip-api-action";
import { ApiActionType } from "./api-actions";
import { ApiPromiseStateService } from "./api-promise-state.service";

const debug = false;

export interface ApiSubstate<T> {
  state: Record<string, T>;
  errors: Array<ApiError>;
  length: number;
  loading: boolean;
  stateVersion: number;
}

export type ApiStateStoreState = Record<string, ApiSubstate<any>>;

@Injectable({ providedIn: "root" })
export class ApiStateStoreService extends StateEmitter<ApiStateStoreState> {
  constructor(private apiPromise: ApiPromiseStateService) {
    super({});
  }

  flushState() {
    Object.keys(this.stateStore).forEach(substateName => {
      this.stateStore[substateName].length = Object.values(
        this.stateStore[substateName].state
      ).filter(value => value !== undefined).length;
    });
  }

  /**
   * Used to propagate a state update for a substate that indicates an API request is in-flight
   * This function does not allow a client to turn `loading` off
   * `mergeStateUpdate` or `mergeErrorUpdate` must be called in order to signify an API request has been fulfilled
   */
  toggleLoading(substateName: string): void {
    if (!(substateName in this.stateValue)) {
      throw new Error(
        `ApiStateStoreService.toggleLoading[${substateName}] not in store - did you initialize?`
      );
    }
    if (this.stateStore[substateName].loading === true) return; // prevent spurious state updates
    debug && console.log(`ApiStateStoreService.toggleLoading - ${substateName}`);
    ++this.stateStore[substateName].stateVersion;
    this.stateStore[substateName].loading = true;
    this.publishState();
  }

  /**
   * * Updates a substate with the cleaned data from a fulfilled API call
   *
   * @param substateName name of substate or service that is being updated
   * @param payload data object coming from the api response
   * @param action the type of api action that was called, your BREAD actions
   * @param replaceOldState (optional) flag that allows state to be cleared and use the new payload. This is set to false by default.
   */
  mergeStateUpdate<T>(
    substateName: string,
    payload: Record<string, T>,
    action: ApiActionType,
    replaceOldState = false
  ): void {
    if (!(substateName in this.stateValue)) {
      throw new Error(
        `ApiStateStoreService.mergeStateUpdate[${substateName}] not in store - did you initialize?`
      );
    }
    this.apiPromise.updateState<T>({
      status: "success",
      action,
      data: payload
    });
    debug && console.log(`ApiStateStoreService.mergeStateUpdate - ${substateName} - ${payload}`);
    if (replaceOldState) {
      this.stateStore[substateName].state = payload;
    } else {
      deepMerge(this.stateStore[substateName].state, payload);
    }
    ++this.stateStore[substateName].stateVersion;
    this.stateStore[substateName].loading = false;
    this.publishState();
  }

  /**
   * Updates a substate with a new error from a fulfilled API call
   */
  mergeErrorUpdate(substateName: string, apiError: ApiError): void {
    if (!(substateName in this.stateValue)) {
      throw new Error(
        `ApiStateStoreService.pushErrorUpdate[${substateName}] not in store - did you initialize?`
      );
    }
    this.apiPromise.updateState({
      status: "error",
      action: apiError.action as ApiActionType,
      data: apiError
    });
    debug && console.log(`ApiStateStoreService.pushErrorUpdate - ${substateName} - ${apiError}`);
    this.stateStore[substateName].errors.push(apiError);
    ++this.stateStore[substateName].stateVersion;
    this.stateStore[substateName].loading = false;
    this.publishState();
  }

  clearErrors(substateName: string): void {
    if (!(substateName in this.stateValue)) {
      throw new Error(
        `ApiStateStoreService.clearErrors[${substateName}] not in store - did you initialize?`
      );
    }
    debug && console.log(`ApiStateStoreService.clearErrors - ${substateName}`);
    ++this.stateStore[substateName].stateVersion;
    this.stateStore[substateName].errors = [];
    this.publishState();
  }

  initSubstate(substateName: string): void {
    debug && console.log(`ApiStateStoreService.initSubstate - ${substateName}`);
    this.stateStore[substateName] = {
      state: {},
      errors: [],
      length: 0,
      loading: true,
      stateVersion: 0
    };
    this.publishState();
  }

  reset(): void {
    debug && console.log("ApiStateStoreService.reset");
    this.stateStore = {};
    this.publishState();
  }
}

// https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge
export const deepMerge = <T extends Record<string, any> = Record<string, any>>(
  target: T,
  ...sources: Array<T>
): T => {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (source === undefined) {
    return target;
  }

  if (isMergebleObject(target) && isMergebleObject(source)) {
    Object.keys(source).forEach((key: string) => {
      if (isMergebleObject(source[key])) {
        if (!target[key]) {
          (target as any)[key] = {};
        }
        deepMerge(target[key], source[key]);
      } else {
        (target as any)[key] = source[key];
      }
    });
  }

  return deepMerge(target, ...sources);
};

const isObject = (item: any): boolean => {
  // eslint-disable-next-line no-null/no-null
  return item !== null && typeof item === "object";
};

const isMergebleObject = (item: any): boolean => {
  return isObject(item) && !Array.isArray(item);
};
