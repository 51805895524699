import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  HostBinding,
  ChangeDetectionStrategy
} from "@angular/core";
import { Subscription } from "rxjs";

import { IdentityService } from "../../../../../common/services/identity.service";
import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";

@Component({
  selector: "onsip-multiple-user-agent",
  templateUrl: "./multiple-user-agent.component.html",
  styleUrls: ["./multiple-user-agent.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultipleUserAgentComponent implements OnInit, OnDestroy {
  @Input() label = "";
  @Input() card = false;

  @HostBinding("class.no-show") noShow = false;

  identities: Array<UserAddress> = [];
  outboundUserAgentAor = "";
  private unsubscriber = new Subscription();

  constructor(public identityService: IdentityService) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.identityService.state.subscribe(identityState => {
        this.identities = identityState.addresses;
        this.outboundUserAgentAor = identityState.outboundIdentity
          ? identityState.outboundIdentity.aor
          : "";
        this.noShow = this.identities.length <= 1;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }
}
