import { Subscription } from "rxjs";

import { StateEmitter } from "./state-emitter";

/**
 * This coalesces more than one StateEmitter of the same type
 * into a single StateEmitter. The states of the added emitters
 * are are merged into a single array of their state.
 *
 * For example, you can use it to coalesce Component objects which extend
 * StateEmitter<ComponentState> into a single StateEmitter.
 * Then you can subscribe to its single `state` observable property to receive
 * a stream of state emitted as an Array<ComponentState>.
 *
 * interface MyState {
 *  uuid: string
 *  ...
 * }
 *
 * class MyEmitter extends StateEmitter<MyState> {
 *  ...
 * }
 *
 * class MyEmitterArray extends StateEmitterArray<MyEmitter, MyState> {
 *   constructor() {
 *     super();
 *   }
 * }
 */
export class StateEmitterArray<T extends StateEmitter<S>, S> extends StateEmitter<Array<S>> {
  /** The array of StateEmitters being reduced to a single StateEmitter */
  private _array: Array<T> = [];

  // Subscriptions to each member of the array
  private stateSubscriptions: Array<Subscription> = [];

  /**
   * Constructor. Protected - the expectation is that this class will be extended.
   * @param completeOnEmpty If true, complete will be sent when the array of Emitters becomes empty.
   * Otherwise complete will never be sent (making this an "infinite" Emitter). Default is false.
   */
  protected constructor(private completeOnEmpty: boolean = false) {
    super([]);
  }

  /** Shallow copy of the array. */
  get array(): ReadonlyArray<T> {
    return this._array.slice();
  }

  /**
   * Adds an emitter to the end of the array (push it).
   * @param stateEmitter Emitter to add.
   * @throws {Error} On duplicate.
   */
  protected add(stateEmitter: T): this {
    if (!stateEmitter) {
      return this;
    }

    // if we already have this emitter, throw
    if (this._array.findIndex(el => el === stateEmitter) !== -1) {
      throw new Error("Already exists in array.");
    }

    // add the stateEmitter to our array
    this._array.push(stateEmitter);

    // subscribe to the stateEmitter's state and push the subscription to our subscription list
    // the state store will get updated when subscription occurs
    this.stateSubscriptions.push(
      stateEmitter.state.subscribe({
        next: next => {
          // get the index from our array and update the stateEmitter state
          const index = this._array.findIndex(el => el === stateEmitter);
          this.stateStore[index] = next;
          this.publishState();
        },
        error: (error: unknown) => {
          this.publishStateError(error);
        },
        complete: () => {
          // if get here the stateEmitter has completed, so remove it
          this.remove(stateEmitter);
        }
      })
    );

    return this;
  }

  /**
   * Removes an emitter from the array (splice it).
   * Returns true if an element existed and has been removed.
   * @param stateEmitter Emitter to remove.
   */
  protected remove(stateEmitter: T): boolean {
    if (!stateEmitter) {
      return false;
    }

    // get the index from our array
    const index = this._array.findIndex(el => el === stateEmitter);
    if (index === -1) {
      return false;
    }

    // unsubscribe and remove subscriptions
    this.stateSubscriptions[index].unsubscribe();
    this.stateSubscriptions.splice(index, 1);

    // remove the stateEmitter object from our array
    this._array.splice(index, 1);

    // remove the stateEmitter state from the state store
    this.stateStore.splice(index, 1);

    // if we have nothing more to observe notify complete
    if (this.completeOnEmpty && !this._array.length) {
      this.publishStateComplete();
    } else {
      // else publish change notification
      this.publishState();
    }

    return true;
  }

  /**
   * Removes all stored emitters and completes this emitter.
   * Useful to complete an "infinite" emitter.
   */
  protected publishComplete(): void {
    this._array.slice().forEach(element => {
      this.remove(element);
    });
    if (!this.completeOnEmpty) {
      this.publishStateComplete();
    }
  }
}
