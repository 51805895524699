import {
  Observable,
  Subject,
  from,
  of,
  partition,
  forkJoin,
  NEVER,
  EMPTY,
  OperatorFunction,
  connectable,
  combineLatest
} from "rxjs";

import {
  pluck,
  map,
  distinctUntilKeyChanged,
  take,
  concatMap,
  catchError,
  retryWhen,
  switchMap,
  mergeMap,
  exhaustMap,
  filter
} from "rxjs/operators";

import { ApiSessionService } from "../api-session.service";
import { ApiStateStoreService, ApiSubstate } from "../api-state-store.service";

import {
  ApiActionDescription,
  ObservableParameters,
  ObservedApiParameters
} from "../util/api-action-description";
import { IApiError as ApiError, onsipApiBrowse, genericApiAction } from "../onsip-api-action-new";
import { OnsipApiResponse } from "../apiResponse/response-body";
import { ApiPromiseStateService } from "../api-promise-state.service";
import { currentOrganization } from "../apiParams/current-organization";
import { accountId } from "../apiParams/account-id";
/**
 * PLEASE READ BEFORE EDITING OR CREATING ANY API RESOURCE FILE
 *
 * A note on how to use the new ApiResourceService:
 *
 * Any client should access a given substate via the publically exposed `state` member on this class
 * Implementing an API call on a child of this class should be done following these few steps:
 * 1. Expose an appropriately named void-returning function which pushes a new value onto the `dispatcher` Subject
 *    pushes onto `this.dispatcher` represent the parameters object for an API call
 * 2. Any store-based parameters can and should be expressed as Observables of the parameter value
 * 3. `Action` is the only parameter that MUST be implemented
 * 4. Repeat this process for every action you wish to implement
 *
 * Example:
 *
 * class FooService extends ApiResourceService<ApiFoo, CleanFoo> {
 *
 *   foo(extraParameters?: any) {
 *    this.dispatcher.next({
 *      Action: ApiActionType.FooAction,
 *      Limit: 50,
 *      SessionId: this.store.state.pipe(sessionId()),
 *      ...extraParams
 *    });
 *   }
 *
 * }
 */

/**
 * @template T Clean response type
 */
export abstract class ApiResourceService<T> {
  protected static readonly response = new Subject<OnsipApiResponse<string, any>>();
  protected readonly dispatcher = new Subject<ApiActionDescription>();

  state!: Observable<ApiSubstate<T>>;
  orgState!: Observable<Array<T>>;
  accountState!: Observable<Array<T>>;

  static arrayToRecord<T, K extends keyof T>(array: Array<T>, indexKeyName: K): Record<string, T> {
    return array.reduce<Record<string, T>>((prev, curr) => {
      prev[curr[indexKeyName] as unknown as string] = curr;
      return prev;
    }, {} as Record<string, T>);
  }

  constructor(
    private session: ApiSessionService,
    protected store: ApiStateStoreService,
    protected promiseState: ApiPromiseStateService,
    readonly resourceName: string,
    readonly indexKeyName: T extends Array<infer U> ? keyof U : keyof T
  ) {
    // Set up api call pipeline

    // initialize shared neverending session recreate
    const _sessionRecreater = new Subject();
    const sessionRecreater = connectable(
      _sessionRecreater.pipe(exhaustMap(() => from(this.session.createNewSessionInternally()))),
      {
        connector: () => new Subject(),
        resetOnDisconnect: false
      }
    );
    sessionRecreater.connect();

    // split action descriptions into blocking and nonblocking
    const [unblocked, blocked] = partition(this.dispatcher, ({ nonblocking }) => !!nonblocking);

    blocked
      .pipe(
        map(({ parameters }) => parameters),
        concatMap(observableParameters =>
          // retryWhen has a quirk: when it retries it retries the WHOLE history
          // get around this by wrapping in `of`
          of(observableParameters).pipe(
            extractParams(),
            concatMap(observedParameters => {
              this.resourceName && this.store.toggleLoading(this.resourceName);
              return /Browse/.test(observedParameters.Action)
                ? // eslint-disable-next-line deprecation/deprecation
                  from(onsipApiBrowse(observedParameters)).pipe(concatMap(from))
                : from(genericApiAction(observedParameters));
            }),
            catchError((_error: unknown) => {
              const error = _error as ApiError;
              if (
                error?.parameter === "SessionId" &&
                (error?.code === "Session.Authentication" || "Session.BadLocation")
              ) {
                throw error;
              } else {
                const { action, message, code, parameter, Response } = error;
                const unwrappedError = { action, message, code, parameter, Response };
                this.resourceName && this.store.mergeErrorUpdate(this.resourceName, unwrappedError);
                return EMPTY;
              }
            }),
            retryWhen(errors =>
              errors.pipe(
                switchMap(err => {
                  _sessionRecreater.next(err);
                  return sessionRecreater;
                }),
                take(5)
              )
            )
          )
        )
      )
      .subscribe(ApiResourceService.response);

    unblocked
      .pipe(
        map(({ parameters }) => parameters),
        mergeMap(observableParameters =>
          // retryWhen has a quirk: when it retries it retries the WHOLE history
          // get around this by wrapping in `of`
          of(observableParameters).pipe(
            extractParams(),
            concatMap(observedParameters => {
              this.resourceName && this.store.toggleLoading(this.resourceName);
              return /Browse/.test(observedParameters.Action)
                ? // eslint-disable-next-line deprecation/deprecation
                  from(onsipApiBrowse(observedParameters)).pipe(concatMap(from))
                : from(genericApiAction(observedParameters));
            }),
            catchError((_error: unknown) => {
              const error = _error as ApiError;
              if (
                error?.parameter === "SessionId" &&
                (error?.code === "Session.Authentication" || "Session.BadLocation")
              ) {
                throw error;
              } else {
                const { action, message, code, parameter, Response } = error;
                const unwrappedError = { action, message, code, parameter, Response };
                this.resourceName && this.store.mergeErrorUpdate(this.resourceName, unwrappedError);
                return EMPTY;
              }
            }),
            retryWhen(errors =>
              errors.pipe(
                switchMap(err => {
                  _sessionRecreater.next(err);
                  return sessionRecreater;
                }),
                take(5)
              )
            )
          )
        )
      )
      .subscribe(ApiResourceService.response);

    // subscribe to static response so every api response goes through every reducer
    ApiResourceService.response.subscribe(this.reducer.bind(this));

    if (this.resourceName) {
      // iniialize the state
      this.state = this.store.state.pipe(
        pluck(this.resourceName),
        distinctUntilKeyChanged("stateVersion"),
        catchError((error: unknown) => {
          console.error(error);
          // sentry?
          return NEVER;
        })
      );

      this.orgState = combineLatest({
        state: this.state,
        org: this.store.state.pipe(currentOrganization())
      }).pipe(
        filter(({ state }) => !state.loading),
        map(({ state, org }) => {
          return Object.values(state.state).filter((el: any) => {
            const sameDomain = el.domain && el.domain === org.domain;
            const sameAddressDomain = el.addressDomain && el.addressDomain === org.domain;
            const sameDomainAddress = el.address?.domain && el.address?.domain === org.domain;
            const sameOrgId = el.organizationId && el.organizationId === org.organizationId;

            return sameDomain || sameOrgId || sameAddressDomain || sameDomainAddress;
          });
        })
      );

      this.accountState = combineLatest({
        state: this.state,
        accountId: this.store.state.pipe(accountId())
      }).pipe(
        filter(({ state }) => !state.loading),
        map(({ state, accountId }) => {
          return Object.values(state.state).filter((el: any) => {
            return el.accountId === accountId;
          });
        })
      );

      this.store.initSubstate(this.resourceName);

      // // debug
      // (window as any).onsip = (window as any).onsip || {};
      // (window as any).onsip[this.resourceName] = this;
    }
  }

  get sessionId(): string | undefined {
    return this.session.stateValue.sessionId;
  }

  /**
   * Blows out and reinitializes this substate from the store
   */
  dispose(): void {
    this.resourceName && this.store.initSubstate(this.resourceName);
  }

  clearErrors(): void {
    this.resourceName && this.store.clearErrors(this.resourceName);
  }

  /**
   * This is by definition not a reducer- but it serves the same purpose as a redux reducer
   * This method will take in responses and convert them to state updates to the store
   * By desgin, EVERY response will go through EVERY reducer
   *   meaning any given child service will be able to see responses from other services if need be
   * It is considered bad practice to send off another API call in the reducer
   *
   * @param response
   */
  protected abstract reducer(response: OnsipApiResponse): void;
}

/**
 * helper function
 *
 * An operator function for evaluating the observable-described
 * query params within an ApiActionDescription
 */
function extractParams(): OperatorFunction<ObservableParameters, ObservedApiParameters> {
  function deleteUndefineds<T extends Record<keyof any, any>>(obj: T): Required<T> {
    return Object.entries(obj)
      .filter(([, value]) => value !== undefined)
      .reduce((acc, [key, value]: [keyof T, any]) => {
        acc[key] = value;
        return acc;
      }, {} as Required<T>);
  }

  return obs =>
    obs.pipe(
      mergeMap(parameters =>
        forkJoin(
          // forkJoin has some quirks:
          // input observables must complete, so we tack on a take(1) here
          // wrap non-observable params in `Rxjs.of`
          Object.entries(deleteUndefineds(parameters)).reduce(
            (prev, [key, val]) =>
              Object.assign(prev, {
                [key]: val instanceof Observable ? val.pipe(take(1)) : of(val)
              }),
            {}
          )
        )
      )
    );
}
