import {
  OnsipAPIArray,
  OnsipAPINotEmptyArray,
  onsipApiArrayToArray
} from "../../apiResponse/xml-json";
import { UserStatus } from "../../apiResponse/user-status";
import { ApiUser, User } from "../user/user";
import { Role } from "../../role";
import {
  apiUserSummaryAddressToAddress,
  UserSummaryAddress,
  ApiUserSummaryAddress
} from "../../apiResponse/address";
import { UserInfo } from "../../apiResponse/user-info";
import { ApiUserCustomization, UserCustomization } from "../userCustomization/user-customization";

export declare interface ApiUserSummary {
  AccountId: string;
  Addresses: OnsipAPIArray<"Address", ApiUserSummaryAddress>;
  Aliases: OnsipAPIArray<"Alias", string>;
  Blurb: string;
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string | undefined;
  E164Aliases: OnsipAPIArray<"E164Alias", string>;
  E911Locations: OnsipAPIArray<"E911Location", string>;
  OrganizationId: string;
  Roles: OnsipAPINotEmptyArray<"Role", { Name: Role }>;
  Status: UserStatus;
  TeamPageVisible: string;
  Title: string;
  UserAvatarUrl?: string;
  UserId: string;
  Voicemailboxes: OnsipAPIArray<"Voicemailbox", string>;
}

export declare interface UserSummary {
  accountId: string;
  addresses: Array<UserSummaryAddress>;
  aliases: Array<string>;
  blurb: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string | undefined;
  e164Aliases: Array<string>;
  e911Locations: Array<string>;
  organizationId: string;
  roles: Array<Role>;
  status: UserStatus;
  teamPageVisible: boolean;
  title: string;
  userAvatarUrl: string;
  userId: string;
  voicemailboxes: Array<string>;
}

export function apiUserSummaryToUserSummary(apiUserSummary: ApiUserSummary): UserSummary {
  return {
    accountId: apiUserSummary.AccountId,
    addresses: onsipApiArrayToArray(apiUserSummary.Addresses, "Address").map(
      apiUserSummaryAddressToAddress
    ),
    aliases: onsipApiArrayToArray(apiUserSummary.Aliases, "Alias"),
    blurb: apiUserSummary.Blurb,
    contactEmail: apiUserSummary.ContactEmail,
    contactName: apiUserSummary.ContactName,
    contactPhone: apiUserSummary.ContactPhone,
    e164Aliases: onsipApiArrayToArray(apiUserSummary.E164Aliases, "E164Alias"),
    e911Locations: onsipApiArrayToArray(apiUserSummary.E911Locations, "E911Location"),
    organizationId: apiUserSummary.OrganizationId,
    roles: onsipApiArrayToArray(apiUserSummary.Roles, "Role").map(roleObject =>
      roleObject.Name ? roleObject.Name : (roleObject as unknown as Role)
    ),
    status: apiUserSummary.Status,
    teamPageVisible: apiUserSummary.TeamPageVisible === "true",
    title: apiUserSummary.Title,
    userAvatarUrl: apiUserSummary.UserAvatarUrl || "",
    userId: apiUserSummary.UserId,
    voicemailboxes: onsipApiArrayToArray(apiUserSummary.Voicemailboxes, "Voicemailbox")
  };
}

export function apiUserSummaryToUserPartial(apiUserSummary: ApiUserSummary): Partial<User> {
  return {
    accountId: apiUserSummary.AccountId,
    organizationId: apiUserSummary.OrganizationId,
    roles: onsipApiArrayToArray(apiUserSummary.Roles, "Role").map(roleObject =>
      roleObject.Name ? roleObject.Name : (roleObject as unknown as Role)
    ),
    status: apiUserSummary.Status,
    teamPageVisible: apiUserSummary.TeamPageVisible === "true",
    userId: apiUserSummary.UserId
  };
}

export function apiUserToUserSummaryPartial(apiUser: ApiUser): Partial<UserSummary> {
  return {
    accountId: apiUser.AccountId,
    contactEmail: apiUser.Contact.Email,
    contactName: apiUser.Contact.Name,
    contactPhone: apiUser.Contact.Phone,
    organizationId: apiUser.OrganizationId,
    roles: onsipApiArrayToArray(apiUser.Roles, "Role").map(roleObject =>
      roleObject.Name ? roleObject.Name : (roleObject as unknown as Role)
    ),
    status: apiUser.Status,
    teamPageVisible: apiUser.TeamPageVisible === "true",
    userId: apiUser.UserId
  };
}

export function apiUserSummaryToUserCustomizationPartial(
  apiUserSummary: ApiUserSummary
): Partial<UserCustomization> {
  return {
    userInfo: {
      personalPageInfo: { title: apiUserSummary.Title, blurb: apiUserSummary.Blurb }
    } as UserInfo,
    userAvatar: apiUserSummary.UserAvatarUrl,
    userAvatarUrl: apiUserSummary.UserAvatarUrl,
    userId: apiUserSummary.UserId
  };
}

export function apiUserCustomizationToUserSummaryPartial(
  apiUserCustomization: ApiUserCustomization
): Partial<UserSummary> {
  return {
    blurb: apiUserCustomization?.UserInfo?.PersonalPageInfo?.Blurb,
    title: apiUserCustomization?.UserInfo?.PersonalPageInfo?.Title,
    userAvatarUrl: apiUserCustomization.UserAvatarUrl ?? "",
    userId: apiUserCustomization.UserId
  };
}
