import { Injectable } from "@angular/core";
import { filter, map, switchMap } from "rxjs/operators";

import { Config } from "../../../common/config";
import { LogService } from "../../../common/services/logging";

import { VolumeService } from "../shared/components/volume/volume.service";
import { CallVolumeEvent } from "../shared/components/volume/volume-event";
import { WebAudioService, WebAudioWrapper } from "../shared/services/webAudio/web-audio.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { VideoConferenceService } from "../videoConference/video-conference.service";
import { CallGroupService } from "../shared/controller/call-group.service";

import { StateEmitter } from "../../../common/libraries/emitter/state-emitter";

import { CallState } from "../../../common/libraries/sip/call-state";
import { CallGroupState } from "../../../common/libraries/sip/call-group-state";

// @ts-ignore
import { fetch as polyfillFetch } from "whatwg-fetch";
import { of, NEVER } from "rxjs";

// this was originally for our safari, which we no longer support.
// it defaulted to true, and so the warning bar only disappeared once the user
// interacted with the browser which allowed audio.
export interface AudioApprovedState {
  // This should really extend warning bar state
  enabled: boolean;
}

export interface AudioInfo {
  volume: number;
  muted: boolean;
  id: string;
}

interface RingTone {
  type: AudioStates;
  intervalTime: number;
  playing: boolean;
  element?: HTMLAudioElement;
  interval?: any;
}

enum AudioStates {
  "RINGING",
  "DIALING",
  "WAITING"
}

@Injectable({ providedIn: "root" })
export class CallAudioService extends StateEmitter<AudioApprovedState> {
  private remoteAudioElement: HTMLAudioElement = new Audio();
  private ringTones: Record<string, RingTone> = {};
  private defaultVolume: number = (50 / 100) * 0.75; // used to be from volumeService, wasn't worth it
  private remoteAudioDestination: any;
  private bufferPromise: Promise<any> = Promise.resolve();

  private audioInfo: Array<AudioInfo> = []; // call.uuid => mute value, webAudio
  private audioElements: any = {}; // call.uuid => audio element
  private callVolume = 0.5;

  private ringerVolume: number;
  private activeUuid: string | undefined;
  private activeGroupAmount = 0;
  private activeCallStreams: any;
  private wasConnected = false;
  private audioExplicitlyApproved = false;
  private audioHasBeenSetup = false;

  private static initialState(): AudioApprovedState {
    return {
      enabled: false // Indicates whether the warning bar is shown or not -> if we start supporting safari, switch to true
    };
  }

  constructor(
    private log: LogService,
    private volumeService: VolumeService,
    private callControllerService: CallControllerService,
    private videoConferenceService: VideoConferenceService,
    private callGroupService: CallGroupService,
    private webAudioService: WebAudioService
  ) {
    super(CallAudioService.initialState());

    if (this.webAudioService.hasCapableAudioContext()) {
      this.remoteAudioDestination = this.webAudioService.createMediaStreamDestination();
    }

    this.remoteAudioElement.volume = this.defaultVolume;
    this.ringerVolume = this.defaultVolume / 0.75;

    const ringing: HTMLAudioElement = new Audio();
    const dialing: HTMLAudioElement = new Audio();

    Promise.all([
      polyfillFetch("resources/wav/ring.wav"),
      polyfillFetch("resources/wav/ringback.wav")
    ])
      .then(([ring, ringback]: Array<any>) => Promise.all([ring.blob(), ringback.blob()]))
      .then(([ring, ringback]: Array<Blob>) => {
        ringing.src = URL.createObjectURL(ring);
        ringing.preload = "auto";
        dialing.src = URL.createObjectURL(ringback);
        dialing.preload = "auto";

        this.stateStore.enabled = false;
        this.publishState();
        this.setupCallAudio(ringing, dialing);
      })
      .catch(err => {
        throw new Error("CallAudio: Couldn't create ring and ringback media." + err);
      });

    const findNewCalls = this.videoConferenceService.state.pipe(
      switchMap(state =>
        of(...state.calls.filter(call => call.connected && !this.audioElements[call.uuid]))
      )
    );

    findNewCalls
      .pipe(
        switchMap(newCall => {
          const callEventObservable = this.videoConferenceService.getCallObservableFilteredByEnd(
            newCall.uuid
          );
          if (callEventObservable) {
            return callEventObservable.pipe(map(() => newCall.uuid));
          }
          return NEVER;
        })
      )
      .subscribe(uuid => {
        this.audioElements[uuid] = undefined;
      });

    findNewCalls
      .pipe(
        switchMap(newCall => {
          this.audioElements[newCall.uuid] = document.createElement("audio");
          this.audioElements[newCall.uuid].volume = this.callVolume;

          const audioAvailObservable =
            this.videoConferenceService.getCallObservableOnAudioAvailChange(newCall.uuid);
          if (audioAvailObservable) {
            return audioAvailObservable.pipe(map(() => newCall));
          }
          return NEVER;
        })
      )
      .subscribe(newCall => {
        if (!newCall.hold) {
          this.renderVidConfAudio(newCall.uuid, this.audioElements[newCall.uuid]);
        }
      });
  }

  setupCallAudio(ringingElement: HTMLAudioElement, dialingElement: HTMLAudioElement): void {
    if (this.audioHasBeenSetup) {
      return;
    }

    this.ringTones[AudioStates.RINGING] = this.createRingTone(
      AudioStates.RINGING,
      ringingElement,
      3000
    );
    this.ringTones[AudioStates.DIALING] = this.createRingTone(
      AudioStates.DIALING,
      dialingElement,
      1000
    );
    this.ringTones[AudioStates.WAITING] = this.createRingTone(AudioStates.WAITING, undefined, 7000);

    this.audioHasBeenSetup = true;

    this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
    this.pauseRingtone(this.ringTones[AudioStates.DIALING]);
    this.pauseRingtone(this.ringTones[AudioStates.WAITING]);

    window.addEventListener("beforeunload", () => {
      this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
      this.pauseRingtone(this.ringTones[AudioStates.DIALING]);
      this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
    });

    this.callControllerService.state.subscribe(state => {
      const callsToConsider: Array<CallState> = state.calls.filter(call => call.stage !== "init"),
        dialingCalls: Array<CallState> = callsToConsider.filter(call => call.connecting),
        ringingCalls: Array<CallState> = callsToConsider.filter(call => call.ringing),
        audioPlayingCalls: Array<CallState> = callsToConsider.filter(
          call => !call.hold && call.connected
        );
      let ringerAllowed = true;

      if (audioPlayingCalls.length > 0) {
        const call: CallState | undefined = audioPlayingCalls[0],
          group: CallGroupState<CallState> | undefined = call
            ? this.callControllerService.getGroupStateFromCall(call.uuid)
            : undefined,
          streams: Array<MediaStream> = call ? this.getStreams(call.uuid) : [];

        ringerAllowed = !group || !streams || streams.length === 0;

        if (
          group &&
          call &&
          streams.length > 0 &&
          (this.activeUuid !== call.uuid ||
            group.calls.length !== this.activeGroupAmount ||
            (!this.wasConnected && call.connected) ||
            streams.length !== this.activeCallStreams.length)
        ) {
          this.renderAudio(call.uuid, streams);
          this.activeGroupAmount = group.calls ? group.calls.length : 0;
          this.activeCallStreams = streams || undefined;
          this.wasConnected = !!call && call.connected;
          this.activeUuid = call.uuid;
        } else {
          this.activeGroupAmount = group && group.calls ? group.calls.length : 0;
          this.wasConnected = !!call && call.connected;
          this.activeCallStreams = streams || undefined;
        }
      } else if (this.activeUuid) {
        this.unrenderAudio(this.activeUuid);
        this.activeGroupAmount = 0;
        this.activeCallStreams = undefined;
        this.activeUuid = undefined;

        if (this.ringTones[AudioStates.WAITING].playing) {
          this.playRingtone(this.ringTones[AudioStates.RINGING]);
          this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
        }
      }

      if (dialingCalls.length > 0 && ringerAllowed) {
        this.playRingtone(this.ringTones[AudioStates.DIALING]);
      } else {
        this.pauseRingtone(this.ringTones[AudioStates.DIALING]);
      }

      if (
        ringingCalls.length > 0 &&
        !(
          ringingCalls.length === 1 &&
          !!audioPlayingCalls.find(call => call.uuid === ringingCalls[0].uuid)
        )
      ) {
        if (!ringerAllowed) {
          this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
          this.playRingtone(this.ringTones[AudioStates.WAITING]);
        } else {
          this.playRingtone(this.ringTones[AudioStates.RINGING]);
          this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
        }
      } else {
        this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
        this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
      }
    });

    this.volumeService.state.subscribe(state => {
      const setVolume: number =
        state.defaultVolume !== undefined && (state.defaultVolume === 0 || state.defaultVolume > -1)
          ? state.defaultVolume
          : this.defaultVolume;

      this.defaultVolume = setVolume;
      this.setRingerVolumes(setVolume);

      this.audioInfo.forEach(info => (info.volume = setVolume));
      this.setCallVolume();
    });

    this.volumeService.event.pipe(filter(e => e.id === CallVolumeEvent.id)).subscribe(event => {
      const info: any | undefined = this.findInfo(event.uuid),
        setVolume: number =
          event.volume !== undefined && (event.volume === 0 || event.volume > -1)
            ? event.volume
            : this.defaultVolume;

      if (info) {
        info.volume = setVolume;
      }

      if (event.uuid === "VIDEOCONFERENCE") {
        if (!this.videoConferenceService.stateValue.connected) {
          return;
        }
        this.callVolume = event.volume / 100;
        this.videoConferenceService.stateValue.calls.forEach(call => {
          this.audioElements[call.uuid].volume = this.callVolume;
        });
      }

      this.setCallVolume();
      this.setRingerVolumes(setVolume);
    });
  }

  approveAudio(): void {
    this.stateStore.enabled = false;
    this.publishState();
    if (this.audioExplicitlyApproved) {
      return;
    }
    this.audioExplicitlyApproved = true;

    // in safari, this function is only called on user input, at which point
    // we need to use the audio tags immediately, and they are ok to be used freely from there
    if (this.audioHasBeenSetup) {
      // if the network requests for the sound files fail, these calls will also fail, so condition on setup
      this.playRingtone(this.ringTones[AudioStates.RINGING]);
      this.playRingtone(this.ringTones[AudioStates.DIALING]);

      this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
      this.pauseRingtone(this.ringTones[AudioStates.DIALING]);
    }
  }

  platformSendRinging(): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("ringing");
    }
  }

  platformPauseRinging(): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("ringing-pause");
    }
  }

  findInfo(uuid: string): AudioInfo {
    let info: AudioInfo | undefined = this.audioInfo.find(audioInfo => audioInfo.id === uuid);

    if (!info) {
      info = {
        id: uuid,
        muted: false,
        volume: this.defaultVolume
      };
      this.audioInfo.push(info);
    }
    return info;
  }

  localMute(uuid: string): void {
    const audioInfo: AudioInfo = this.findInfo(uuid),
      otherCall: CallState | undefined = this.callGroupService.findThreeWayConferencePartner(uuid);

    if (otherCall) {
      const confAudioInfo: any = this.findInfo(otherCall.uuid),
        wrapper: any = this.webAudioService.getWrapper(uuid),
        confWrapper: any = this.webAudioService.getWrapper(otherCall.uuid);

      if (wrapper && confWrapper) {
        this.webAudioService.safeDisconnect(wrapper.localAudioSource);
        this.webAudioService.safeDisconnect(confWrapper.localAudioSource);
      }

      this.callControllerService.setCallAudio(uuid, false);
      this.callControllerService.setCallAudio(otherCall.uuid, false);
      confAudioInfo.muted = true;
    } else {
      this.callControllerService.setCallAudio(uuid, false);
    }

    audioInfo.muted = true;
  }

  localUnmute(uuid: string): void {
    const audioInfo: any = this.findInfo(uuid),
      otherCall: CallState | undefined = this.callGroupService.findThreeWayConferencePartner(uuid);

    if (otherCall) {
      const confAudioInfo: any = this.findInfo(otherCall.uuid),
        wrapper: any = this.webAudioService.getWrapper(uuid),
        confWrapper: any = this.webAudioService.getWrapper(otherCall.uuid);

      if (wrapper && confWrapper) {
        wrapper.localAudioSource.connect(wrapper.localAudioDestination);
        confWrapper.localAudioSource.connect(confWrapper.localAudioDestination);
      }

      this.callControllerService.setCallAudio(uuid, true);
      this.callControllerService.setCallAudio(otherCall.uuid, true);
      confAudioInfo.muted = false;
    } else {
      this.callControllerService.setCallAudio(uuid, true);
    }

    audioInfo.muted = false;
  }

  private renderVidConfAudio(uuid: string, audioElement: HTMLAudioElement): void {
    const audioInfo: any = this.findInfo(uuid),
      wrapper: WebAudioWrapper | undefined = this.webAudioService.getWrapper(uuid);

    if (audioInfo) {
      this.setCallVolume(uuid);
    }

    if (audioInfo && wrapper) {
      this.webAudioService.safeDisconnect(this.remoteAudioDestination);
      this.webAudioService.safeDisconnect(wrapper.localAudioDestination);

      this.bufferAudioElementCalls(
        this.remoteAudioElement,
        "srcObject",
        this.remoteAudioDestination.stream
      );
      this.bufferAudioElementCalls(this.remoteAudioElement, "play");

      this.renderWebAudio(uuid);
    } else {
      const attachAndPlay = (innerStreams: Array<MediaStream>, element: HTMLAudioElement) => {
          innerStreams.forEach(stream => {
            this.bufferAudioElementCalls(element, "srcObject", stream);
            this.bufferAudioElementCalls(element, "play");
          });
        },
        streams: Array<MediaStream> = this.getStreams(uuid),
        call: CallState | undefined = this.callControllerService.getCallStateByUuid(uuid);

      if (
        this.audioHasBeenSetup &&
        call &&
        !call.incoming &&
        call.connecting &&
        streams.length > 0
      ) {
        this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
        this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
      }
      attachAndPlay(streams, audioElement);
    }
  }

  private setCallVolume(uuid: string = this.activeUuid || ""): void {
    const info: any = this.findInfo(uuid),
      wrapper: any = this.webAudioService.getWrapper(uuid),
      volume: number = (info ? info.volume : this.defaultVolume) / 100;

    if (!info) {
      return;
    }

    if (this.remoteAudioElement.volume !== volume) {
      this.log.debug(uuid, "volume changed to " + volume * 100);
      this.remoteAudioElement.volume = volume;
      if (wrapper && wrapper.remoteAudioGain) {
        wrapper.remoteAudioGain.gain.value = volume;

        const otherCall: CallState | undefined =
          this.callGroupService.findThreeWayConferencePartner(uuid);

        if (otherCall) {
          const confWrapper: any = this.webAudioService.getWrapper(otherCall.uuid);
          if (confWrapper) {
            confWrapper.remoteAudioGain.gain.value = volume;
          }
        }
      }
    }
  }

  private renderAudio(uuid: string, streams: Array<MediaStream>): void {
    const audioInfo: any = this.findInfo(uuid),
      wrapper: any = this.webAudioService.getWrapper(uuid);
    this.log.debug(uuid, "render remote audio");

    if (audioInfo) {
      this.setCallVolume(uuid);
    }

    if (audioInfo && wrapper) {
      this.webAudioService.safeDisconnect(this.remoteAudioDestination);
      this.webAudioService.safeDisconnect(wrapper.localAudioDestination);

      this.bufferAudioElementCalls(
        this.remoteAudioElement,
        "srcObject",
        this.remoteAudioDestination.stream
      );
      this.bufferAudioElementCalls(this.remoteAudioElement, "play");

      const callRemote: any = this.renderWebAudio(uuid);

      const otherCall: CallState | undefined =
        this.callGroupService.findThreeWayConferencePartner(uuid);

      if (otherCall) {
        const confWrapper: any = this.webAudioService.getWrapper(otherCall.uuid);

        if (confWrapper) {
          this.webAudioService.safeDisconnect(confWrapper.localAudioDestination);
        }

        const confCallRemote: any = this.renderWebAudio(otherCall.uuid);

        if (callRemote && confCallRemote) {
          callRemote.connect(confWrapper.localAudioDestination);
          confCallRemote.connect(wrapper.localAudioDestination);
        }
      }
    } else {
      const attachAndPlay = (innerStreams: Array<MediaStream>, element: HTMLAudioElement) => {
          innerStreams.forEach(stream => {
            this.bufferAudioElementCalls(element, "srcObject", stream);
            this.bufferAudioElementCalls(element, "play");
          });
        },
        remoteAudio: any = this.remoteAudioElement,
        call: CallState | undefined = this.callControllerService.getCallStateByUuid(uuid);

      if (
        this.audioHasBeenSetup &&
        call &&
        !call.incoming &&
        call.connecting &&
        streams.length > 0
      ) {
        this.pauseRingtone(this.ringTones[AudioStates.RINGING]);
        this.pauseRingtone(this.ringTones[AudioStates.WAITING]);
      }
      attachAndPlay(streams, remoteAudio);
    }
  }

  private unrenderAudio(uuid: string | undefined): void {
    if (uuid) {
      const otherCall: CallState | undefined =
        this.callGroupService.findThreeWayConferencePartner(uuid);

      if (otherCall) {
        this.webAudioService.removeWrapperSource(otherCall.uuid);
      }
      this.webAudioService.removeWrapperSource(uuid);
    } else if (this.activeUuid) {
      this.webAudioService.removeWrapperSource(this.activeUuid);
    }

    this.webAudioService.safeDisconnect(this.remoteAudioDestination);
  }

  private getPeerConnection(uuid: string): RTCPeerConnection | undefined {
    const peerConnection = this.videoConferenceService.getPeerConnection(uuid);
    if (peerConnection) {
      return peerConnection;
    }

    const sdh = this.callControllerService.findSessionDescriptionHandler(uuid);

    return sdh ? (sdh as any).peerConnection : undefined;
  }

  private getStreams(uuid: string): Array<MediaStream> {
    const peerConnection: RTCPeerConnection | undefined = this.getPeerConnection(uuid);
    let streams: Array<MediaStream> = [];

    if (!peerConnection) {
      return [];
    }

    if (peerConnection.getReceivers) {
      const tracks: Array<any> = [];

      peerConnection
        .getReceivers()
        .filter(receiver => receiver.track.kind === "audio")
        .forEach(receiver => tracks.push(receiver.track));
      if (tracks.length > 0) {
        streams = [new MediaStream(tracks)];
      } else {
        streams = [];
      }
    } else {
      streams = peerConnection.getRemoteStreams();
    }

    return streams || [];
  }

  private playRingtone(ringTone: any): void {
    if (ringTone.playing) {
      return;
    }
    ringTone.playing = true;
    this.platformSendRinging();

    if (ringTone.intervalTime > 0) {
      if (ringTone.interval) {
        return;
      }
      if (ringTone.type === AudioStates.WAITING) {
        this.webAudioService.playCallWaitingTone(this.ringerVolume);
        ringTone.interval = setInterval(() => {
          this.webAudioService.playCallWaitingTone(this.ringerVolume);
        }, ringTone.intervalTime);
      } else {
        ringTone.currentTime = 0;
        this.bufferAudioElementCalls(ringTone.element, "play");
        ringTone.interval = setInterval(() => {
          ringTone.currentTime = 0;
          this.bufferAudioElementCalls(ringTone.element, "play");
        }, ringTone.intervalTime);
      }
    } else {
      this.bufferAudioElementCalls(ringTone.element, "play");
    }
  }

  private pauseRingtone(ringTone: RingTone): void {
    if (!ringTone.playing) {
      return;
    }
    ringTone.playing = false;
    this.platformPauseRinging();

    ringTone.element && this.bufferAudioElementCalls(ringTone.element, "pause");
    if (ringTone.interval) {
      clearInterval(ringTone.interval);
      ringTone.interval = undefined;
    }
  }

  private createRingTone(
    name: AudioStates,
    element: HTMLAudioElement | undefined,
    intervalTime: number
  ): RingTone {
    const ringTone: RingTone = {
      type: name,
      intervalTime,
      playing: false
    };
    if (element) {
      ringTone.element = element;
      ringTone.element.volume = this.ringerVolume;
      ringTone.playing = true;
    }

    return ringTone;
  }

  private setRingerVolumes(volume: number) {
    const setVolume: number =
      volume !== undefined && volume > -1 ? volume / 100 : this.ringerVolume;
    this.ringerVolume = setVolume * 0.66;
    const ringingTone = this.ringTones[AudioStates.RINGING],
      dialingTone = this.ringTones[AudioStates.DIALING];
    if (ringingTone.element) ringingTone.element.volume = this.ringerVolume;
    if (dialingTone.element) dialingTone.element.volume = this.ringerVolume;
  }

  private renderWebAudio(uuid: string): any {
    const audioInfo: AudioInfo = this.findInfo(uuid),
      wrapper: WebAudioWrapper | undefined = this.webAudioService.getWrapper(uuid);

    if (!wrapper) {
      return;
    }

    if (!audioInfo.muted) {
      wrapper.localAudioSource.connect(wrapper.localAudioDestination);
    }

    if (!wrapper.remoteAudioSource) {
      let remoteStream: any;
      const peerConnection: RTCPeerConnection | undefined = this.getPeerConnection(uuid);

      if (!peerConnection) {
        return;
      }

      this.log.debug(uuid, "remote audio for remoteSrc");

      if (peerConnection.getReceivers) {
        const receiver: any = peerConnection
          .getReceivers()
          .filter(rcvr => rcvr.track.kind === "audio")[0];
        if (receiver) {
          remoteStream = new MediaStream([receiver.track]);
        }
      } else {
        remoteStream = peerConnection.getRemoteStreams()[0];
      }

      if (!remoteStream) {
        return;
      }

      // this audio element is a hack for chrome to get the remoteSource playing
      let audioElement: any = new Audio();
      audioElement.muted = true;
      audioElement.srcObject = remoteStream;
      audioElement = undefined;

      wrapper.remoteAudioSource = this.webAudioService.createMediaStreamSource(remoteStream);

      this.webAudioService.safeDisconnect(wrapper.remoteAudioGain);

      if (wrapper.remoteAudioSource) wrapper.remoteAudioSource.connect(wrapper.remoteAudioGain);
    }

    wrapper.remoteAudioGain.connect(this.remoteAudioDestination);

    return wrapper.remoteAudioSource;
  }

  private bufferAudioElementCalls(element: any, audioFunction: string, srcObject?: any): void {
    this.bufferPromise = this.bufferPromise
      .then(() => {
        if (audioFunction === "srcObject") {
          element.srcObject = srcObject;
          return;
        }
        return element[audioFunction]();
      })
      .catch(error => {
        console.error("bufferAudioElementCalls error, continuing:", error);
        return;
      });
  }
}
