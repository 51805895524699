import {
  OnsipAPIArray,
  onsipApiArrayToArray,
  OnsipAPIBoolean,
  onsipApiBooleanToBoolean
} from "../../apiResponse/xml-json";
import { ApiUserSummaryAddress, ApiFailoverAddress } from "../../apiResponse/address";
import { UserStatus } from "../../apiResponse/user-status";
import {
  apiUserSummaryAddressToAddress,
  UserSummaryAddress,
  apiFailoverAddressToAddress,
  FailoverAddress
} from "../../apiResponse/address";
import { ApiUser } from "../user/user";
import { apiContactToContact } from "../../apiResponse/contact";
import { APIUserAddress } from "../userAddress/user-address";
import { ApiUserCustomization } from "../userCustomization/user-customization";

export declare interface ApiUserSummaryContact {
  AccountId: string;
  Addresses: OnsipAPIArray<"Address", ApiUserSummaryAddress>;
  Aliases: OnsipAPIArray<"Alias", string>;
  Blurb: string;
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string | undefined;
  E164Aliases: OnsipAPIArray<"E164Alias", string>;
  ExposePii: OnsipAPIBoolean;
  OrganizationId: string;
  Status: UserStatus;
  Timeout: number;
  TeamPageVisible: OnsipAPIBoolean;
  Title: string;
  UserAvatarUrl: string;
  UserId: string;
}

// After the UserSummaryContactBrowseOverride, a call back turns Address into a string array
export declare interface ApiUserSummaryContactClean extends ApiUserSummaryContact {
  Addresses: { Address: Array<string> };
  Aliases: { Alias: Array<string> };
  E164Aliases: { E164Alias: Array<string> };
}

export declare interface ApiUserSummaryContactCleanWithAddresses
  extends ApiUserSummaryContactClean {
  AuthUsername?: string;
  AuthPassword?: string;
  DefaultAddress?: ApiFailoverAddress;
  Domain?: string;
  PreviousDefaultAddress?: ApiFailoverAddress;
  SipAddress?: string;
}

export declare interface ApiUserSummaryContactWithAddresses extends ApiUserSummaryContact {
  AuthUsername?: string;
  AuthPassword?: string;
  DefaultAddress?: ApiFailoverAddress;
  Domain?: string;
  PreviousDefaultAddress?: ApiFailoverAddress;
  SipAddress?: string;
}

export declare interface UserSummaryContact {
  accountId: string;
  addresses: Array<UserSummaryAddress>;
  aliases: Array<string>;
  blurb: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string | undefined;
  e164Aliases: Array<string> | undefined;
  exposePii: boolean;
  organizationId: string;
  status: UserStatus;
  teamPageVisible: boolean;
  timeout: number;
  title: string;
  userAvatarUrl: string;
  userId: string;
}

export declare interface UserSummaryContactWithAddresses extends UserSummaryContact {
  authUsername?: string;
  authPassword?: string;
  defaultAddress?: FailoverAddress;
  domain?: string;
  previousDefaultAddress?: FailoverAddress;
  sipAddress?: string;
}

export function apiUserSummaryContactToUserSummaryContact(
  apiUserSummaryContact: ApiUserSummaryContact
): UserSummaryContact {
  return {
    accountId: apiUserSummaryContact.AccountId,
    addresses: onsipApiArrayToArray(apiUserSummaryContact.Addresses, "Address").map(
      apiUserSummaryAddressToAddress
    ),
    aliases: onsipApiArrayToArray(apiUserSummaryContact.Aliases, "Alias"),
    blurb: apiUserSummaryContact.Blurb,
    contactEmail: apiUserSummaryContact.ContactEmail,
    contactName: apiUserSummaryContact.ContactName,
    contactPhone: apiUserSummaryContact.ContactPhone,
    e164Aliases: onsipApiArrayToArray(apiUserSummaryContact.E164Aliases, "E164Alias"),
    exposePii: apiUserSummaryContact.ExposePii === "true",
    organizationId: apiUserSummaryContact.OrganizationId,
    status: apiUserSummaryContact.Status,
    teamPageVisible: onsipApiBooleanToBoolean(apiUserSummaryContact.TeamPageVisible),
    timeout: apiUserSummaryContact.Timeout,
    title: apiUserSummaryContact.Title,
    userAvatarUrl: apiUserSummaryContact.UserAvatarUrl || "",
    userId: apiUserSummaryContact.UserId
  };
}

export function apiUserSummaryContactWAddressesToUserSummaryContactWAddresses(
  apiUserSummaryContact: ApiUserSummaryContactWithAddresses
): UserSummaryContactWithAddresses {
  return {
    accountId: apiUserSummaryContact.AccountId,
    addresses: onsipApiArrayToArray(apiUserSummaryContact.Addresses, "Address").map(
      apiUserSummaryAddressToAddress
    ),
    aliases: onsipApiArrayToArray(apiUserSummaryContact.Aliases, "Alias"),
    authPassword: apiUserSummaryContact.AuthPassword,
    authUsername: apiUserSummaryContact.AuthUsername,
    blurb: apiUserSummaryContact.Blurb,
    contactEmail: apiUserSummaryContact.ContactEmail,
    contactName: apiUserSummaryContact.ContactName,
    contactPhone: apiUserSummaryContact.ContactPhone,
    defaultAddress: apiUserSummaryContact.DefaultAddress
      ? apiFailoverAddressToAddress(apiUserSummaryContact.DefaultAddress)
      : undefined,
    domain: apiUserSummaryContact.Domain,
    e164Aliases: onsipApiArrayToArray(apiUserSummaryContact.E164Aliases, "E164Alias"),
    exposePii: apiUserSummaryContact.ExposePii === "true",
    organizationId: apiUserSummaryContact.OrganizationId,
    previousDefaultAddress: apiUserSummaryContact.PreviousDefaultAddress
      ? apiFailoverAddressToAddress(apiUserSummaryContact.PreviousDefaultAddress)
      : undefined,
    sipAddress: apiUserSummaryContact.SipAddress,
    status: apiUserSummaryContact.Status,
    teamPageVisible: onsipApiBooleanToBoolean(apiUserSummaryContact.TeamPageVisible),
    timeout: apiUserSummaryContact.Timeout,
    title: apiUserSummaryContact.Title,
    userAvatarUrl: apiUserSummaryContact.UserAvatarUrl || "",
    userId: apiUserSummaryContact.UserId
  };
}

export function apiUserToUserSummaryContactPartial(apiUser: ApiUser): Partial<UserSummaryContact> {
  return {
    accountId: apiUser.AccountId,
    contactName: apiContactToContact(apiUser.Contact).name,
    contactEmail: apiContactToContact(apiUser.Contact).email,
    contactPhone: apiContactToContact(apiUser.Contact).phone,
    exposePii: onsipApiBooleanToBoolean(apiUser.ExposePii),
    organizationId: apiUser.OrganizationId,
    status: apiUser.Status,
    teamPageVisible: onsipApiBooleanToBoolean(apiUser.TeamPageVisible),
    userId: apiUser.UserId
  };
}

export function apiUserAddressToUserSummaryContactPartial(
  apiUserAddress: APIUserAddress
): Partial<UserSummaryContact> {
  return {
    addresses: [
      {
        address: `${apiUserAddress.Address.Username}@${apiUserAddress.Address.Domain}`,
        statuses: ""
      }
    ],
    userId: apiUserAddress.UserId
  };
}

export function apiUserCustomizationToUserSummaryContactPartial(
  apiUserCustomization: ApiUserCustomization
): Partial<UserSummaryContact> {
  return {
    userAvatarUrl: apiUserCustomization.UserAvatarUrl,
    userId: apiUserCustomization.UserId
  };
}
