<div class="caller-table-container">
  <div
    [ngClass]="{ 'active-block': openTable.value, selectable: callerData.length > 0 }"
    id="onsip--realtime-caller-table--{{ displayType }}"
    class="queue-caller-table"
    tabindex="{{ callerData.length > 0 ? '0' : '-1' }}"
    aria-label="toggle queue caller table"
    (click)="onTriggerClick()"
    (mouseenter)="openTableHover()"
    (mouseleave)="closeTableHover()"
  >
    {{ callerData.length }}
  </div>
  @if (((openTable | async) || (onTableHover | async)) && callerData.length > 0) {
  <mat-card
    class="table-content mat-elevation-z4 caller-{{ displayType }}"
    [class.hover-state]="onTableHover.value"
    (click)="$event.stopPropagation()"
  >
    <table mat-table [dataSource]="activeCallerData">
      <ng-container matColumnDef="caller-name">
        <td mat-cell *matCellDef="let caller" class="caller-name">
          {{ caller.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="caller-wait-time">
        <td mat-cell *matCellDef="let caller" class="caller-wait-time">
          @if (displayType === 'waiting') {
          <div>
            <onsip-timer [referencePoint]="caller.eventTime" [hoursZeroes]="false"></onsip-timer>
          </div>
          } @if (displayType === 'on call') {
          <div>
            <onsip-timer [referencePoint]="caller.eventTime" [hoursZeroes]="false"></onsip-timer>
          </div>
          } @if (displayType === 'completed' || displayType === 'abandoned') {
          <div>
            {{ caller.eventTime - caller.enqueueTime | duration : false }}
          </div>
          } @if (displayType === 'failover') {
          <div>{{ callerData.length | length }}</div>
          }
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: ['caller-name', 'caller-wait-time']"></tr>
    </table>
    @if (callerData.length > pageSize) {
    <mat-paginator
      [length]="callerData.length"
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      (page)="onPageChanged($event)"
      onsip-paginator-primary-icon
    ></mat-paginator>
    }
  </mat-card>
  }
</div>
