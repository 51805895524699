import { Config } from "../../common/config";
import { ConsoleService } from "../../common/services/console/console.service";
import { LogLevel } from "../../common/services/logging/log.target";
import { ConsoleTarget } from "../../common/services/logging/console.target";
import { PLATFORM } from "../platform-dependent/platform-vars";
import packageJson from "../../../package.json";

//
// Core configuration file. This file is intended to be imported by the applicaiton root module only.
// While some settings may be overriden elsewhere at runtime, the expectation is the configuration is
// seeded at build time to appropriate values for the environment the application is to be running in.
//

const userAgent = window.navigator.userAgent,
  // NOTE: come back and fix this deprecation
  // eslint-disable-next-line deprecation/deprecation
  platform = window.navigator.platform,
  macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
  windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
  iosPlatforms = ["iPhone", "iPad", "iPod"];
let os;

if (macosPlatforms.indexOf(platform) !== -1) {
  os = "Mac OS";
} else if (iosPlatforms.indexOf(platform) !== -1) {
  os = "ios";
} else if (windowsPlatforms.indexOf(platform) !== -1) {
  os = "Windows";
} else if (/Android/.test(userAgent)) {
  os = "android";
} else if (!os && /Linux/.test(platform)) {
  os = "Linux";
}

// platform build target - defaults to web if not set
switch (PLATFORM) {
  case "desktop":
    Config.PLATFORM_TARGET = Config.PLATFORMS.DESKTOP;
    switch (os) {
      case "Linux":
        Config.OPERATING_SYSTEM_TARGET = Config.OPERATING_SYSTEMS.LINUX;
        break;
      case "Mac OS":
        Config.OPERATING_SYSTEM_TARGET = Config.OPERATING_SYSTEMS.MAC;
        break;
      case "Windows":
        Config.OPERATING_SYSTEM_TARGET = Config.OPERATING_SYSTEMS.WIN;
        break;
      default:
        Config.OPERATING_SYSTEM_TARGET = "";
    }
    break;
  case "mobile": // NOTE: this file is not used in mobile
    Config.PLATFORM_TARGET = Config.PLATFORMS.MOBILE;
    switch (os) {
      case "android":
        Config.OPERATING_SYSTEM_TARGET = Config.OPERATING_SYSTEMS.ANDROID;
        break;
      case "ios":
        Config.OPERATING_SYSTEM_TARGET = Config.OPERATING_SYSTEMS.IOS;
        break;
      default:
        Config.OPERATING_SYSTEM_TARGET = "";
    }
    break;
  case "web":
    Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
    Config.OPERATING_SYSTEM_TARGET = "";
    break;
  default:
    Config.PLATFORM_TARGET = Config.PLATFORMS.WEB;
}

// not setting this is production URL
// use beta api if on beta or dev-buld or localhost or alpha
if (
  /localhost/.test(document.location.host) ||
  window.location.href.includes("app.onsip.com/beta") ||
  window.location.href.includes("app.onsip.com/dev-build") ||
  window.location.href.includes("app.onsip.com/alpha")
) {
  Config.ADMIN_API_URL = Config.API_URLS.BETA;
}

// set to a localhost api
// Config.ADMIN_API_URL = 'http://localhost:8000/api';

// not setting this is production URL;
// Config.WS_SERVER = 'wss://edge-proxy3.new-york-1.realtime.jnctn.net';

// debug levels - defaults to no logging if not set
Config.DEBUG.LEVEL_1 = true; // LogService .error logging enabled
Config.DEBUG.LEVEL_2 = true; // LogService .warn  logging enabled
Config.DEBUG.LEVEL_3 = true; // LogService .info  logging enabled
Config.DEBUG.LEVEL_4 = true; // LogService .debug logging enabled

Config.VERSION = packageJson.version;

// for AoT compilation to work:
declare let console: any;

export function cons() {
  return console;
}

export function consoleLogTarget(consoleService: ConsoleService) {
  return new ConsoleTarget(consoleService, { minLogLevel: LogLevel.Debug });
}
