import { Component, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from "@angular/core";

/** default initials (or initials for nameless contacts) for default avatars */
const DEFAULT_NAME = "- -";

/** colors choices for the default avatar, chosen randomly */
const DEFAULT_COLORS: ReadonlyArray<string> = [
  "#9a4bad", // Dark Purple
  "#f6d0f7", // Light Purple
  "#706f6b", // Dark Gray
  "#c1bfb9", // Light Gray
  "#dc790c", // Dark Orange
  "#f9caa7", // Light Orange
  "#24b5b1", // Dark Teal
  "#c3e5dd" // Light Teal
];

@Component({
  selector: "onsip-avatar",
  templateUrl: "./avatar.component.html",
  styleUrls: ["./avatar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnChanges {
  @Input() avatarUrl?: string;
  @Input() name?: string;
  background!: string;

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.name || changes.name.previousValue === changes.name.currentValue) return;
    function selectByName<T>(str: string, options: ReadonlyArray<T>): T {
      let acc = 0;
      for (let i = 0; i < str.length; i++) {
        acc += str.charCodeAt(i);
      }
      return options[acc % options.length];
    }
    this.background = selectByName(this.name || DEFAULT_NAME, DEFAULT_COLORS);
  }
}
