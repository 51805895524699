import { Component, ChangeDetectionStrategy, OnInit, Input, OnChanges } from "@angular/core";
import * as Highcharts from "highcharts";

const ONSIP_COLORS = {
  active: "#7A9BFF",
  inactive: "#012AA5"
};

export interface DonutGraphData {
  name: string;
  value: number;
  color?: string;
}

@Component({
  selector: "onsip-donut-graph",
  templateUrl: "./donut-graph.component.html",
  styleUrls: ["./donut-graph.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DonutGraphComponent implements OnInit, OnChanges {
  @Input() data!: Array<DonutGraphData>;
  @Input() legendLayout: "horizontal" | "vertical" = "vertical";
  @Input() graphDiameter = 125;
  @Input() feature = "numbers";
  /** Required for highcharts-angular, bound to <highcharts-chart [Highcharts].../> */
  Highcharts = Highcharts;
  /** Highcharts master options object, update this for any changes to chart, e.g. new data
   *  bound to <highcharts-chart [options].../>
   */
  chartOptions!: Highcharts.Options;
  /** Callback Function sets this.chart, bound to <highcharts-chart [callbackFunction].../> */
  chartCallback!: Highcharts.ChartCallbackFunction;
  /** Reference to the Chart Object's "this", make this.chart the same as "this" from within chart's scope */
  chart: any;
  totalValue!: number;

  ngOnInit() {
    // Initialize a callback for the chart reference, this is important for dealing with any chart events
    const self = this;
    this.chartCallback = chart => (self.chart = chart); // saving chart reference
    this.setChartOptions();
  }

  ngOnChanges(): void {
    this.totalValue = this.data.map(d => d.value).reduce((a, b) => a + b, 0);
    this.setChartOptions();
  }

  private addColorToData(): void {
    this.data = this.data.map(d => {
      const color = d.name === "active" ? ONSIP_COLORS["active"] : ONSIP_COLORS["inactive"];
      return { ...d, color };
    });
  }

  // This is not typed with Highcharts type because it is missing the visible parameter
  // We are hacking in invisible data points to simulate the space in between data points
  // Not great and not sure why highcharts don't have a parameter for this
  private generateData() {
    const dataArray: Array<{
      name?: string;
      y: number;
      color?: string;
      sliced?: boolean;
      visible?: boolean;
    }> = [];
    this.data.forEach(data => {
      dataArray.push({
        name: data.name,
        y: data.value,
        color: data.color,
        sliced: true
      });

      dataArray.push({
        name: "spacer",
        visible: false,
        y: this.totalValue * 0.05 // spacer depends total values we are showing. need a bigger space for larger numbers
      });
    });

    return dataArray;
  }

  private setChartOptions() {
    this.addColorToData();
    const seriesAction: Highcharts.SeriesOptionsType = {
      type: "pie",
      name: "Donut graph",
      data: this.generateData()
    };

    this.chartOptions = {
      chart: {
        type: "pie",
        plotShadow: false,
        backgroundColor: "transparent",
        style: {
          fontFamily: "Roboto",
          fontWeight: "400"
        },
        width: this.graphDiameter,
        height: this.graphDiameter
      },
      credits: { enabled: false },
      plotOptions: {
        pie: {
          innerSize: "98%",
          borderWidth: this.graphDiameter / 20,
          borderColor: undefined,
          slicedOffset: 0,
          // add in spacer data, hack to add space in between data points
          ignoreHiddenPoint: false,
          dataLabels: {
            enabled: false
          },
          showInLegend: true,
          center: ["50%", "50%"]
        }
      },
      series: [seriesAction],
      title: {
        verticalAlign: "middle",
        floating: true,
        useHTML: true,
        text: this.titleStyle(),
        style: {
          textAlign: "center"
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      }
    };
  }

  private titleStyle(): string {
    return `
      <div>
        <div style="
          font-weight: 400;
          font-size: 32px
        ">${this.totalValue}</div>
        <div style="
          font-size: 10.5px;
          color: rgba(0,0,0,0.54);
        ">${this.feature.toUpperCase()}</div>
      </div>
    `;
  }
}
