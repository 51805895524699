import { LogService } from "../../../../../common/services/logging";
import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";
import { SubscriptionControllerService } from "../../../../../common/services/subscription-controller.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";
import {
  AdvQueueObject,
  AdvQueueService
} from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { WebCallTopicService } from "../../../../../common/services/api/resources/webCallTopic/web-call-topic.service";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { UserSummaryContactService } from "../../../../../common/services/api/resources/userSummaryContact/user-summary-contact.service";

import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  pluck,
  skip,
  switchMapTo
} from "rxjs/operators";

import { isAdminRole } from "../../../../../common/services/api/role";

import { UUID } from "../../../../../common/libraries/sip/uuid";
import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";
import { Config } from "../../../../../common/config";
import { IdentityService } from "../../../../../common/modules/core";
import { NetworkService } from "../../../../../common/services/network.service";
import { DisconnectedUserAgentEvent } from "../../../../../common/libraries/sip/user-agent-event";
import { ContactService } from "@onsip/common/services/contact/contact.service";
import { SdhFactoryHelperService } from "../../controller/sdh-factory-helper.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class SubscriptionService extends SubscriptionControllerService {
  private resetInProgress = new BehaviorSubject<boolean>(false);
  private queueSubscribe = new Subscription();

  constructor(
    log: LogService,
    private callControllerService: CallControllerService,
    private contactService: ContactService,
    private identity: IdentityService,
    private network: NetworkService,
    private userSummaryContact: UserSummaryContactService,
    private queueService: AdvQueueService,
    private localStorageService: LocalStorageService,
    private webCallTopicService: WebCallTopicService,
    private userService: UserService,
    private sdhFactoryHelperService: SdhFactoryHelperService,
    private apiSessionService: ApiSessionService
  ) {
    super(log);

    let addressLength = 0;
    combineLatest([this.network.state, this.identity.state]).subscribe(
      ([networkState, identityState]) => {
        if (identityState.addresses.length !== addressLength) {
          addressLength = identityState.addresses.length;
          if (addressLength === 0) {
            this.shutdown();
          } else if (networkState.online) {
            // we are online & the number of our addresses changed.
            this.reset(identityState.addresses);
          }
        }
      }
    );

    // handle edge case where the new identity when swapping user have the same number of addresses
    this.identity.state
      .pipe(
        pairwise(),
        filter(
          ([prev, curr]) =>
            prev.addresses.length > 0 &&
            prev.addresses.length === curr.addresses.length &&
            !!prev.defaultIdentity &&
            !!curr.defaultIdentity &&
            prev.defaultIdentity.aor !== curr.defaultIdentity.aor
        ),
        map(([, curr]) => curr)
      )
      .subscribe(currIdentity => {
        this.reset(currIdentity.addresses);
      });

    this.subscribeUserAgentReconnect(); // All automatic transport reconnect handled here
  }

  private subscribeUserAgentReconnect() {
    // RECONNECT CASE 1: Offline -> Online
    this.network.state
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.online === curr.online &&
            prev.suspended === curr.suspended &&
            prev.connectivity === curr.connectivity
        ),
        filter(
          identityState =>
            identityState.online &&
            identityState.connectivity !== "none" &&
            identityState.connectivity !== "unknown"
        ),
        skip(1) // Ignore first time we sign in
      )
      .subscribe(identityState => {
        debug &&
          console.log(identityState.online, identityState.connectivity, identityState.suspended);
        this.reconnectAllUserAgents();
      });

    // RECONNECT CASE 2: Random Websocket Disconnect e.g. 1006
    this.getUserAgentEventObservable()
      .pipe(
        filter(e => e.id === DisconnectedUserAgentEvent.id),
        switchMapTo(this.network.state)
      )
      .subscribe(networkState => {
        // No point in attempting reconnect when offline
        if (networkState.online) {
          this.reconnectAllUserAgents();
        }
      });
  }

  reconnectAllUserAgents(): Promise<Array<void>> {
    if (!this.subscriptionController) {
      return Promise.reject();
    }
    const reconnectPromises: Array<Promise<void>> = [];
    this.subscriptionController.userAgents.array.forEach(userAgent =>
      reconnectPromises.push(userAgent.reconnect())
    );

    return Promise.all(reconnectPromises);
  }

  private reset(addresses: Array<UserAddress>): Promise<void> {
    debug && this.log.debug("Subscription Service Begin Reset...");

    if (this.resetInProgress.getValue()) {
      return Promise.reject("SubscriptionService: reset attempt while reset in progress");
    }
    this.resetInProgress.next(true);

    // make sure all call controller is cleaned up so all calls and ua are shut down before restarting the subscription controller
    this.callControllerService.cleanCallController();

    return this.shutdown()
      .then(() => {
        this.resetController(); // sychronous, creates controller
        // setup the UserAgent for the SubscriptionController
        return Promise.all(this.setupUserAgents(addresses)).then(() => {
          // subscribe to combined list of aors for which presence is needed. i.e. config users, contacts
          this.subscribeUsers();
          // subscribe to our smart queues
          this.setupQueueService();
          // publish state
          this.publishState();
          debug && this.log.debug("Presence Service Reset");
        });
      })
      .finally(() => {
        this.resetInProgress.next(false);
      });
  }

  private shutdown(): Promise<void> {
    debug && this.log.debug("Presence Service Begin Shutdown...");
    return this.shutdownController();
  }

  private setupUserAgents(addresses: Array<UserAddress>): Array<Promise<void>> {
    debug && this.log.debug("PresenceService.setupUserAgent");

    if (!this.subscriptionController) {
      this.log.error("PresenceService.setupUserAgent no subscription controller");
      return [];
    }

    const uaPromises: Array<Promise<void>> = [];

    addresses.forEach(address => {
      uaPromises.push(
        this.localStorageService.getUserAgentValues(address.aor).then(uaValues => {
          let uuid = uaValues.instanceId;
          if (!uuid) {
            uuid = UUID.randomUUID();
            this.localStorageService.setUserAgent(address.aor, { instanceId: uuid });
          }
          // we should add ua to call controller if ua does not exist in call controller user agent's array
          const addToCallController = !this.callControllerService.hasUserAgent(address.aor);

          const ua = this.callControllerService.makeAndAddUserAgent(
            {
              authorizationUsername: address.authUsername,
              authorizationPassword: address.authPassword,
              userAgentString: "OnSIP_App/" + Config.VERSION_NUMBER + "/" + Config.PLATFORM_STRING
            },
            address.aor,
            {
              instanceId: uuid
            },
            undefined,
            this.sdhFactoryHelperService.createFactory(),
            addToCallController
          );
          // do not register the spoofing user
          if (this.apiSessionService.stateValue.parentUserId) {
            ua.shouldBeRegistered = false;
          }
          if (ua && this.subscriptionController) {
            ua.start();
            this.subscriptionController.addUserAgent(ua);
            this.addDialog(ua.aor, true);
          }
        })
      );
    });

    return uaPromises;
  }

  /** Get observable array aors of users that are in at least one topic */
  private getConfigurationAors(): Observable<Array<string>> {
    return combineLatest([
      this.userSummaryContact.state.pipe(pluck("state")),
      this.webCallTopicService.getAllRepUserIds()
    ]).pipe(
      map(
        ([users, repUserIds]) =>
          repUserIds
            .map(id => users[id])
            .filter(<T>(state: T | undefined): state is T => !!state) // filter nulls user not found
            .map(user => user.addresses[0].address) // Always take first aor
      ),
      distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => b[i] === v))
    );
  }

  /** Get observable array aors of contacts */
  private getContactListAors(): Observable<Array<string>> {
    return this.contactService
      .getContactList$()
      .pipe(
        map(state =>
          state
            .filter(contact => !contact.custom && !!contact.aors[0])
            .map(contact => contact.aors[0])
        )
      );
  }

  /** Subscibe to aor lists, create min set of aors we want presence for e.g. contacts, sayso reps, add/remove as necessary */
  private subscribeUsers(): void {
    const configUsersObservable = this.getConfigurationAors();
    const contactUsersObservable = this.getContactListAors();
    let promiseChain: Promise<Array<any>> = Promise.resolve([]);
    this.unsubscriber.add(
      combineLatest([
        this.identity.state,
        configUsersObservable,
        contactUsersObservable,
        this.userService.selfUser,
        this.resetInProgress
      ])
        .pipe(
          filter(([, , , , resetInProgress]) => !!this.subscriptionController && !resetInProgress)
        )
        .subscribe(([identity, configUsers, contactUsers, user]) => {
          const isAdmin = isAdminRole(user.roles);
          let allAors: Array<string> = [];
          allAors = allAors.concat(contactUsers);
          if (isAdmin) allAors = allAors.concat(configUsers); // Check privileges so only admins listen to sayso reps
          allAors = allAors.filter((v, i, a) => a.indexOf(v) === i); // filter duplicate aors from various sources
          const currentAors = this.stateStore.presentity
            .filter(pres => pres.event === "dialog")
            .map(pres => pres.aor);
          const removals = currentAors.filter(
            aor =>
              allAors.indexOf(aor) < 0 && identity.addresses.every(address => address.aor !== aor)
          );
          const additions = allAors.filter(
            aor =>
              currentAors.indexOf(aor) < 0 &&
              identity.addresses.every(address => address.aor !== aor)
          );
          const promises: Array<Promise<any>> = [];
          if (this.subscriptionController) {
            removals.forEach(aor => {
              promises.push(this.remove(aor, "reg").catch(Promise.resolve));
              promises.push(this.remove(aor, "dialog").catch(Promise.resolve));
            });
            additions.forEach(aor => {
              promises.push(this.add(aor, "reg").catch(Promise.resolve));
              promises.push(this.add(aor, "dialog").catch(Promise.resolve));
            });
            promiseChain = promiseChain.then(() => Promise.all(promises));
          } else {
            console.error(
              new Error("SubscriptionService: no subscription controller in subscribe users")
            );
          }
        })
    );
  }

  private setupQueueService(): void {
    this.queueSubscribe.unsubscribe();
    this.queueSubscribe = new Subscription();
    this.queueService.getSmartQueuesWithoutOrgId().then(queues => {
      queues.forEach(queue => {
        this.addQueue(queue.address);
      });
    });

    this.queueSubscribe.add(
      this.queueService
        .getSmartQueueObs()
        .pipe(
          distinctUntilChanged(
            (prev, curr) =>
              prev.length === curr.length && !this.getDiffArray(prev, curr, "appAddress").length
          ),
          pairwise()
        )
        .subscribe(([prev, curr]) => {
          const prevOrgId = prev[0]?.organizationId;
          const currOrgId = curr[0]?.organizationId;

          // non spoofing queues only
          if (prevOrgId === currOrgId) {
            //delete
            if (prev.length > curr.length) {
              const removed = this.getDiffArray(prev, curr);
              this.remove(removed[0].appAddress, "onsip-queue").catch(Promise.resolve);
            }
            //add
            else if (curr.length > prev.length) {
              const added = this.getDiffArray(curr, prev);
              this.addQueue(added[0].address);
            }
            //edit
            else {
              const changed = this.getDiffArray(prev, curr, "appAddress")[0];
              const { advQueueId, appAddress } = changed;
              const itemToAdd = curr.find(item => item.advQueueId === advQueueId);
              this.remove(appAddress, "onsip-queue").catch(Promise.resolve);
              this.addQueue(itemToAdd?.appAddress as string).catch(Promise.resolve);
            }
          }
        })
    );
  }

  private getDiffArray(
    firstArr: Array<AdvQueueObject>,
    secondArr: Array<AdvQueueObject>,
    key: keyof (typeof firstArr)[0] = "advQueueId"
  ) {
    return firstArr.filter(
      firstItem => !secondArr.some(secondItem => firstItem[key] === secondItem[key])
    );
  }
}
