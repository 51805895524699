import {
  Invitation,
  Inviter,
  Referral,
  SessionState,
  InviterInviteOptions,
  SessionInviteOptions,
  InviterOptions,
  Grammar,
  URI,
  Web,
  InvitationProgressOptions
} from "sip.js";
import { SessionReferOptions } from "sip.js/lib/api/session-refer-options";
import { Session } from "./session";
import { SessionDescriptionHandlerSIP } from "./session-description-handler-sip";
import { UserAgent } from "./user-agent";
import { log } from "./log";
import { SessionConfiguration, InviteOptions } from "./session-configuration";

/**
 * A SIP Session
 */
export class SessionSIP extends Session {
  // SIP.js session
  private session: Invitation | Inviter | undefined;
  private dtmfTones: Array<string> = [];
  private musicOnHoldSession: Inviter | undefined;

  /**
   * Make invite options from a session.
   * @param session The session.
   */
  static makeInviteOptions(session: SessionSIP): InviteOptions {
    const options = session?.configuration?.sip?.inviteOptions || {};

    return this.commonInviteOptions(
      options,
      session.audioRequested,
      session.videoRequested,
      session.ouid
    );
  }

  private static commonInviteOptions(
    options: InviteOptions,
    audio: boolean,
    video: boolean,
    ouid: string | undefined
  ): InviteOptions {
    // Add required session description handler options if not provided.
    options.sessionDescriptionHandlerOptions = options.sessionDescriptionHandlerOptions || {};
    options.sessionDescriptionHandlerOptions.constraints = options.sessionDescriptionHandlerOptions
      .constraints || {
      audio,
      video
    };

    // Default to INVITE without SDP
    options.inviteWithoutSdp =
      options.inviteWithoutSdp === undefined ? true : options.inviteWithoutSdp;

    // Add proprietary OnSIP Headers
    options.extraHeaders = options.extraHeaders || [];
    // TODO: Should have equivilant approaches for both audio & video
    if (video) {
      options.extraHeaders.push("X-Vid: 1");
    }
    if (ouid) {
      options.extraHeaders.push(`P-OUID: ${ouid}`);
    }

    return options;
  }

  private static makeHoldOptions(session: SessionSIP): SessionInviteOptions {
    const options: SessionInviteOptions = {
      sessionDescriptionHandlerModifiers: [],
      sessionDescriptionHandlerOptions: {
        constraints: { audio: session.audioInitialized, video: session.videoInitialized }
      }
    };
    return options;
  }

  private static makeInviteOptionsOnRefer(
    session: SessionSIP,
    withReplaces: boolean,
    replacesVideo: boolean
  ): InviteOptions {
    let options = session?.configuration?.sip?.inviteOptionsOnRefer || {};

    // for video, see comment in handleReferral
    options = this.commonInviteOptions(
      options,
      session.audio || session.audioRequested,
      replacesVideo,
      session.ouid
    );

    if (withReplaces) {
      // Default to INVITE with SDP if INVITE with Replaces.
      // This INVITE should never fork, so should be fine (if not desirable) to send with SDP.
      options.inviteWithoutSdp = false;
    }

    return options;
  }

  /**
   * Creates a new Session.
   * @param userAgent UserAgent owning the Session.
   */
  constructor(
    localDisplayName: string,
    localUri: string | undefined,
    remoteDisplayName: string,
    remoteUri: URI,
    userAgent: UserAgent,
    configuration?: SessionConfiguration
  ) {
    super(localDisplayName, localUri, remoteDisplayName, remoteUri, userAgent, configuration);
  }

  /**
   * Sends positive final reponse to an INVITE.
   */
  accept(): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].accept`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.connectedAt) {
      return Promise.resolve();
    }
    const options: Web.SessionDescriptionHandlerOptions =
      this.session.sessionDescriptionHandlerOptions;
    options.constraints = options.constraints || {};
    options.constraints.audio = this.audioRequested && (this.audioAvailable || this.audioExpected);
    options.constraints.video = this.videoRequested && (this.videoAvailable || this.videoExpected);

    if (!(this.session instanceof Invitation)) {
      throw new Error("SIP.js session is not an instance of Invitation");
    }
    return this.session
      .accept({ sessionDescriptionHandlerOptions: options })
      .then(() => super.accept());
  }

  /**
   * Groups the session with another session.
   * @param session Session to group with.
   */
  groupWith(session: SessionSIP): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].groupWith`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (!this.sessionDescriptionHandler) {
      return Promise.reject(
        new Error("SIP.js session description handler does not exist (on this session).")
      );
    }
    if (!session.sessionDescriptionHandler) {
      return Promise.reject(
        new Error("SIP.js session description handler does not exist (on target session).")
      );
    }
    if (this.endedAt || session.endedAt) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      try {
        // TODO: FIXME: Adding remote track to a peer connection "works",
        //              but audio sounds like crap, so making this a no-op till it worksa
        //              Will also need to extend SessionDescriptionHandler interface to
        //              support this without accessing the tracks.
        //              Something like...
        // this.sessionDescriptionHandler.groupWith(session.sessionDescriptionHandler)
        //              ...which will do something like the following internally
        // this.sessionDescriptionHandler.addAudioTrack(
        //   session.sessionDescriptionHandler.remoteAudioTrack).then(() => this.session.sendReinvite()
        // )
        // session.sessionDescriptionHandler.addAudioTrack(
        //   this.sessionDescriptionHandler.remoteAudioTrack).then(() => session.session.sendReinvite()
        // )
        resolve();
      } catch (error) {
        reject(error);
      }
    }).then(() => super.groupWith(session));
  }

  /**
   * Sends initial INVITE.
   */
  invite(): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].invite`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (!(this.session instanceof Inviter)) {
      return Promise.reject(new Error("SIP.js session not instance of Inviter."));
    }
    const options: InviterInviteOptions = {
      requestDelegate: {
        onAccept: () => {},
        onProgress: () => {
          this.onProgress();
        },
        onRedirect: () => {},
        onReject: () => {},
        onTrying: () => {
          this.onTrying();
        }
      }
    };
    return this.session.invite(options).then(() => {
      return;
    });
  }

  /**
   * Sends DTMF.
   * @param tone Tone to send.
   */
  playDTMF(tone: string): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].playDTMF`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt) {
      return Promise.resolve();
    }
    this.sendDTMF(tone);
    return super.playDTMF(tone);
  }

  /**
   * Sends a PROGRESS response 183 for call tracking purposes
   */
  callProgressTracking(): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].progress`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt) {
      return Promise.resolve();
    }
    if (!(this.session instanceof Invitation)) {
      throw new Error("SIP.js session is not an instance of Invitation");
    }

    const body = {
      contentType: "application/json",
      body: JSON.stringify({
        timeRinging: 15,
        id: "CallProgressTracking"
      })
    };

    const options: InvitationProgressOptions = {
      statusCode: 183, // sip code 183 SESSION PROGRESS
      body
    };

    return this.session.progress(options).then(() => super.callProgressTracking());
  }

  /**
   * Sends a REFER request.
   * @param uri URI to REFER UAS to.
   */
  refer(uri: URI): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].refer`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt) {
      return Promise.resolve();
    }
    return this.session.refer(uri, this.makeReferOptions()).then(() => super.refer(uri));
  }

  /**
   * Sends an REFER request with replaces header (attended transfer).
   * @param session Session to replace the current session with.
   */
  referWithReplaces(transfereeSession: SessionSIP): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].refer`);
    if (!this.session || !transfereeSession.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt || transfereeSession.endedAt) {
      return Promise.resolve();
    }
    const options: SessionReferOptions = { requestOptions: { extraHeaders: [] } };
    // HACK: for some reason, all of our video properties are not consistent, worth investigating
    if ((transfereeSession.sessionDescriptionHandler as any).remoteVideoTrack) {
      options.requestOptions &&
        options.requestOptions.extraHeaders &&
        options.requestOptions.extraHeaders.push("X-Vid: 1");
    }
    return this.session
      .refer(transfereeSession.session, options)
      .then(() => super.referWithReplaces(transfereeSession));
  }

  /**
   * Sends a negative final response to an INVITE.
   */
  reject(): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].reject`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt) {
      return Promise.resolve();
    }
    if (!(this.session instanceof Invitation)) {
      throw new Error("SIP.js session is not an instance of Invitation");
    }
    return this.session.reject().then(() => super.reject());
  }

  /**
   * Enables or disables audio.
   * @param audio Audio on if true, off if false.
   */
  setAudio(audio: boolean): void {
    log.debug(`SessionSIP[${this.uuid}].setAudio`);
    if (this.sessionDescriptionHandler) {
      this.sessionDescriptionHandler.enableLocalAudio(audio && !this.hold && !this.mute);
    }
    super.setAudio(audio);
  }

  /**
   * Flags the Session as having initialized audio locally.
   */
  setAudioInitialized(): void {
    log.debug(`SessionSIP[${this.uuid}].setAudioInitialized`);
    this.setAudio(this.audio);
    super.setAudioInitialized();
  }

  /**
   * Puts Session on hold.
   * @param hold Hold on if true, off if false.
   */
  setHold(hold: boolean): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].setHold`);
    if (!this.session) {
      return Promise.reject(new Error("Session does not exist."));
    }
    if (this.session.state !== SessionState.Established) {
      return Promise.reject(new Error("Session not established."));
    }
    /**
     * Function becomes a noop if we are in the same state. This can still result in problems
     * (mainly callkit) because of race conditions where multiple callers can call hold/unhold.
     * TODO: Chain requests
     */
    if (this.hold === hold) {
      return Promise.resolve();
    }
    if (this.holdInProgress) {
      return Promise.reject(new Error("A hold request is already in progress."));
    }
    if (!this.sessionDescriptionHandler) {
      return Promise.reject("A Session Description Handler is required to put a call on hold.");
    }
    this.setHoldInProgress(true);
    const options = SessionSIP.makeHoldOptions(this);
    options.requestDelegate = {
      onAccept: () => {
        this.setHoldInProgress(false);
        if (
          this.session &&
          (this.session.sessionDescriptionHandler as SessionDescriptionHandlerSIP)
            .replaceSenderTrackSupport
        ) {
          this.setMusicOnHold(hold);
        }
        super.setHold(hold);
      },
      onReject: () => {
        log.debug(`SessionSIP[${this.uuid}].setHold re-invite request was rejected`);
        this.setHoldInProgress(false);
      }
    };

    this.sessionDescriptionHandler.enableLocalAudio(this.audio && !hold && !this.mute);
    this.sessionDescriptionHandler.enableLocalVideo(this.video && !hold && !this.mute);
    this.sessionDescriptionHandler.enableRemoteAudio(!hold);
    this.sessionDescriptionHandler.enableRemoteVideo(!hold);

    // Session properties used to pass modifiers to the SessionDescriptionHandler:
    //
    // 1) Session.sessionDescriptionHandlerModifiers
    //    - used in all cases when handling the initial INVITE transaction as either UAC or UAS
    //    - may be set directly at anytime
    //    - may optionally be set via constructor option
    //    - may optionally be set via options passed to Inviter.invite() or Invitation.accept()
    //
    // 2) Session.sessionDescriptionHandlerModifiersReInvite
    //    - used in all cases when handling a re-INVITE transaction as either UAC or UAS
    //    - may be set directly at anytime
    //    - may optionally be set via constructor option
    //    - may optionally be set via options passed to Session.invite()

    // Set the session's SDH re-INVITE modifiers to produce the appropriate SDP offer to place call on hold
    this.session.sessionDescriptionHandlerModifiersReInvite = hold ? [Web.holdModifier] : [];

    return this.session
      .invite(options)
      .then(() => {
        if (!this.session) {
          throw new Error("Session undefined.");
        }
        // Reset the session's SDH re-INVITE modifiers.
        // Note that if the modifiers are not reset, they will be applied
        // to the SDP answer as well (which we do not want in this case).
        this.session.sessionDescriptionHandlerModifiersReInvite = [];
      })
      .catch((error: Error) => {
        this.setHoldInProgress(false);
        log.error(error.message);
        throw error;
      });
  }

  /**
   * Puts Session on mute.
   * @param mute Mute on if true, off if false.
   */
  setMute(mute: boolean): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].setMute`);
    return new Promise<void>((resolve, reject) => {
      try {
        if (this.sessionDescriptionHandler) {
          this.sessionDescriptionHandler.enableLocalAudio(this.audio && !this.hold && !mute);
          this.sessionDescriptionHandler.enableLocalVideo(this.video && !this.hold && !mute);
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    }).then(() => super.setMute(mute));
  }

  /**
   * Enables or disables video.
   * @param video Video on if true, off if false.
   */
  setVideo(video: boolean): void {
    log.debug(`SessionSIP[${this.uuid}].setVideo`);
    if (this.sessionDescriptionHandler) {
      this.sessionDescriptionHandler.enableLocalVideo(video && !this.hold && !this.mute);
    }
    super.setVideo(video);
  }

  /**
   * Flags the Session as having initialized video locally.
   */
  setVideoInitialized(): void {
    log.debug(`SessionSIP[${this.uuid}].setVideoInitialized`);
    this.setVideo(this.video);
    super.setVideoInitialized();
  }

  /**
   * Terminates a Session regardless of current state.
   */
  terminate(): Promise<void> {
    log.debug(`SessionSIP[${this.uuid}].terminate`);
    if (!this.session) {
      return Promise.reject(new Error("SIP.js session does not exist."));
    }
    if (this.endedAt) {
      return Promise.resolve();
    }

    // When an end user declines or otherwise ends a Session, the expectation is
    // the Session should end immediately. However terminating a SIP.js API Session
    // immediately is not a thing. You might think you could just session.cancel()
    // (or whatever) and move on... and you would be right. That said, there are
    // cases where the SIP.js API Session transitions to a "terminating" state for a
    // period of time prior to transitioning to a "terminated" state. Once in the
    // "terminating" state the Session will eventually transition "terminated", but
    // it can take a relatively long time to do so.
    //
    // As an example of what might happen when you try to terminate a SIP.js API Session...
    //  - Outgoing call not yet answered and cancel() is called. A CANCEL is sent, but
    //    if no final response to the INVITE arrives (for whatever reason), and the
    //    SIP.js API just silently waits in "terminating" state for a timeout to occur
    //    before transitioning to a "terminated" state.
    //
    // Anyway, here we end the our Session immediately without waiting on the SIP.js API Session to terminate.

    switch (this.session.state) {
      case SessionState.Initial:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => super.terminate());
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => super.terminate());
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Establishing:
        if (this.session instanceof Inviter) {
          return this.session.cancel().then(() => super.terminate());
        } else if (this.session instanceof Invitation) {
          return this.session.reject().then(() => super.terminate());
        } else {
          throw new Error("Unknown session type.");
        }
      case SessionState.Established:
        return this.session.bye().then(() => super.terminate());
      case SessionState.Terminating:
        return Promise.resolve().then(() => super.terminate());
      case SessionState.Terminated:
        return Promise.resolve().then(() => super.terminate());
      default:
        throw new Error("Unknown session state.");
    }
  }

  /**
   * The SessionDescriptionHandler associated with the Session.
   */
  get sessionDescriptionHandler(): SessionDescriptionHandlerSIP | undefined {
    if (!this.session) {
      return undefined;
    } else if (this.session.sessionDescriptionHandler) {
      return this.session.sessionDescriptionHandler as SessionDescriptionHandlerSIP;
    } else if (
      (this.session as any).earlyMediaSessionDescriptionHandlers &&
      (this.session as any).earlyMediaSessionDescriptionHandlers.size > 0 &&
      (this.session as any).earlyMediaSessionDescriptionHandlers.get(this.session.id)
    ) {
      return undefined;
    } else {
      return undefined;
    }
  }

  /** The SIP.js session associated with the Session. */
  get SIP(): Invitation | Inviter {
    if (!this.session) {
      throw new Error("SIP.js session does not exist.");
    }
    return this.session;
  }

  /** When set, activates the Session. */
  set SIP(session: Invitation | Inviter) {
    this.session = session;
    this.session.data = this;
    this.initSession();
    this.activate();
  }

  private initSession() {
    if (!this.session) {
      throw new Error("SIP.js session does not exist.");
    }

    const delegate = {
      onInvite: () => {
        // HACK: If the remote peer takes session off-hold, currently the
        // peer connection starts sending audio. This is here to make sure
        // that mute is set correctly after the remote end sends a re-invite.
        this.setMute(this.mute);
      },
      onRefer: (referral: Referral) => {
        this.handleReferral(referral);
      },
      onReinviteSuccess: () => {
        // FIXME: delete this is a TBD
        // HACK: If the remote peer takes session off-hold, currently the
        // peer connection starts sending audio. This is here to make sure
        // that mute is set correctly after the remote end sends a re-invite.
        this.setMute(this.mute);
      }
    };
    this.session.delegate = delegate;

    this.session.stateChange.addListener((newState: SessionState) => {
      switch (newState) {
        case SessionState.Establishing:
          log.debug(`SessionSIP[${this.uuid}] state changed to ${newState}`);
          break;
        case SessionState.Established:
          log.debug(`SessionSIP[${this.uuid}] state changed to ${newState}`);
          this.onAccepted();
          break;
        case SessionState.Terminating:
          log.debug(`SessionSIP[${this.uuid}] state changed to ${newState}`);
          if (this.incoming) {
            this.onUnanswered();
          } else {
            this.onCancelled();
          }
          // If one exists, make sure the music on hold session gets terminated
          if (this.musicOnHoldSession) {
            this.terminateMusicOnHold();
          }
          break;
        case SessionState.Terminated:
          log.debug(`SessionSIP[${this.uuid}] state changed to ${newState}`);
          if (this.connectedAt) {
            this.onTerminated();
          } else {
            if (this.incoming) {
              this.onUnanswered();
            } else {
              this.onFailed();
            }
          }
          // If one exists, make sure the music on hold session gets terminated
          if (this.musicOnHoldSession) {
            this.terminateMusicOnHold();
          }
          break;
        default:
          throw new Error("Unexpected state change.");
      }
    });
  }

  private handleReferral(referral: Referral): void {
    log.debug(`SessionSIP[${this.uuid}].handleReferral`);
    referral.accept().then(() => {
      // create a new Session for the referral
      const newSession = new SessionSIP(
        "",
        undefined,
        referral.referTo.displayName,
        referral.referTo.uri,
        this.userAgent,
        this.configuration
      );
      if (!(newSession instanceof SessionSIP)) {
        throw new TypeError("New Session not instance of SessionSIP.");
      }
      const withVideo = referral.request.getHeader("X-Vid") === "1";

      // TODO: If existing Session is on hold, new Session should start on hold too
      // newSession.setHold(this.hold);
      newSession.setMute(this.mute);
      newSession.setAudio(this.audio);
      newSession.setAudioRequested(this.audio || this.audioRequested);

      newSession.setVideo(withVideo);
      newSession.setVideoRequested(withVideo);

      // create a new inviter for the referral
      const options = SessionSIP.makeInviteOptionsOnRefer(this, !!referral.replaces, withVideo);
      const inviter = referral.makeInviter(options);
      newSession.SIP = inviter;

      // send the invite
      return newSession.invite().then(() => this.onReferred(newSession));
    });
  }

  private makeReferOptions(): SessionReferOptions {
    // if we don't get a 1xx in asecond, end point is probably not sending progress
    // grandstream does this, so we can't just say 'broken UA' and let it go longer
    const timerIdx = setTimeout(() => {
      this.terminate();
    }, 1 * 1000);

    return {
      onNotify: notification => {
        notification.accept();
        // if the notifyEvent is emitted, the messageBody is valid
        const messageBody = Grammar.parse(notification.request.body, "sipfrag");
        const statusCode: number = messageBody.status_code;
        if (statusCode > 100) {
          clearTimeout(timerIdx);
          if (statusCode >= 300) {
            // refer failed
            super.onTransferFailed();
            this.setBlindTransferInProgress(false);
            return;
          }
          // refer is working, so we'll hang up
          // we could wait until the far end picks up,
          // but we don't have the UX for that (yet) so this is the compromise
          this.terminate();
          this.setBlindTransferInProgress(false);
        }
        if (messageBody.reason_phrase === "Ringing") {
          this.setBlindTransferInProgress(false);
        }
      }
    };
  }

  /**
   * Send DTMF.
   * @param tones - Tones to send.
   * @param options - Options bucket.
   * @internal
   */
  private sendDTMF(tones: string): void {
    // Check tones
    if (!tones || !tones.toString().match(/^[0-9A-D#*,]+$/i)) {
      throw new TypeError("Invalid tones: " + tones);
    }

    const send: () => void = (): void => {
      const session = this.session;
      if (!session) {
        throw new Error("SIP.js session does not exist.");
      }

      // Stop sending DTMF if session is not established or nothing to send
      if (session.state !== SessionState.Established || this.dtmfTones.length === 0) {
        return;
      }

      const dtmf = this.dtmfTones.shift();
      const duration = 2000;
      const body = {
        contentDisposition: "render",
        contentType: "application/dtmf-relay",
        content: "Signal= " + dtmf + "\r\nDuration= " + duration
      };
      const requestOptions = { body };
      session.info({ requestOptions });

      // Set timeout for the next tone
      const timeout = duration + 500;
      setTimeout(send, timeout);
    };

    if (this.sessionDescriptionHandler) {
      const sent: boolean = this.sessionDescriptionHandler.sendDtmf(tones);
      if (sent) {
        return;
      }
    }

    const tonesArray = tones.split("");
    const tonesQueued = this.dtmfTones.length ? true : false;
    this.dtmfTones = this.dtmfTones.concat(tonesArray);
    if (!tonesQueued) {
      send();
    }
  }

  private setMusicOnHold(hold: boolean): void {
    log.debug(`SessionSIP[${this.uuid}].setMusicOnHold`);

    if (!this.session) {
      throw new Error("Session does not exist.");
    }
    if (this.session.state !== SessionState.Established) {
      log.warn(`SessionSIP[${this.uuid}].setMusicOnHold session not established, so done here`);
      return;
    }

    // If there is an existing music on hold session, terminate it.
    if (this.musicOnHoldSession) {
      this.terminateMusicOnHold();
    }

    // Stop music on hold
    if (!hold) {
      // Since the hold track was removed, we need to ensure
      // that the original sender track is re-enabled.
      if (!this.sessionDescriptionHandler) {
        throw new Error("Session description handler undefined");
      }
      const sdh = this.sessionDescriptionHandler;
      sdh
        .restoreSenderTrack()
        .then(() => {
          sdh.enableLocalAudio(this.audio && !hold && !this.mute);
        })
        .catch(error => {
          log.error(`SessionSIP[${this.uuid}].setMusicOnHold failed to restore sender track`);
          log.error(error);
        });
    }

    // Start music on hold
    if (hold) {
      let mohUri: URI | undefined;
      try {
        mohUri = new URI("sip", "moh", this.session.userAgent.configuration.uri.host);
      } catch (e: any) {
        log.error(`SessionSIP[${this.uuid}].setMusicOnHold could not create MOH URI`);
        log.error(e);
      }
      if (!mohUri) {
        return;
      }
      const mohConfig: InviterOptions = {
        inviteWithoutSdp: false,
        sessionDescriptionHandlerOptions: {
          constraints: {
            audio: true,
            video: false
          }
        }
      };
      const musicOnHoldSession = new Inviter(this.SIP.userAgent, mohUri, mohConfig);
      this.musicOnHoldSession = musicOnHoldSession;
      this.musicOnHoldSession.data = this.musicOnHoldSession.data || {};
      (this.musicOnHoldSession.data as any).moh = true; // Indicate we are doing MOH with this session for the SDH
      this.musicOnHoldSession.invite({
        requestDelegate: {
          onAccept: () => {
            if (!this.session) {
              throw new Error("Session undefined.");
            }
            // MOH session may have terminated while waiting for response to MOH INVITE
            if (!this.musicOnHoldSession) {
              log.warn(
                `SessionSIP[${this.uuid}].setMusicOnHold MOH session undefined, terminating MOH session...`
              );
              musicOnHoldSession.bye();
              return;
            }
            // Our session may have terminated while waiting for response to MOH INVITE
            if (this.session.state !== SessionState.Established) {
              log.warn(
                `SessionSIP[${this.uuid}].setMusicOnHold session not established, terminating MOH session...`
              );
              this.terminateMusicOnHold();
              return;
            }
            const sessionDescriptionHandler = this.musicOnHoldSession
              .sessionDescriptionHandler as SessionDescriptionHandlerSIP;
            if (!sessionDescriptionHandler.replaceSenderTrackSupport) {
              throw new Error("MOH SDH not instance of WebSessionDescriptionHandler.");
            }
            sessionDescriptionHandler.enableRemoteAudio(false);
            const musicTrack = sessionDescriptionHandler.remoteAudioTrack;
            if (!musicTrack) {
              log.error(
                `SessionSIP[${this.uuid}].setMusicOnHold unable to get a received audio track from the MOH session, terminating MOH session...`
              );
              this.terminateMusicOnHold();
              return;
            }
            if (!this.sessionDescriptionHandler) {
              throw new Error("Session description handler undefined");
            }
            const sdh = this.sessionDescriptionHandler;
            sdh
              .replaceSenderTrack(musicTrack)
              .then(() => {
                sdh.enableLocalAudio(this.audio);
              })
              .catch(error => {
                log.error(`SessionSIP[${this.uuid}].setMusicOnHold failed to replace sender track`);
                log.error(error);
                this.terminateMusicOnHold();
              });
          },
          onReject: () => {
            log.warn(
              `SessionSIP[${this.uuid}].setMusicOnHold invite to MOH was rejected, no music will be played`
            );
            this.musicOnHoldSession = undefined;
          }
        }
      });
    }
  }

  private terminateMusicOnHold(): void {
    log.debug(`SessionSIP[${this.uuid}].terminateMusicOnHold`);
    if (this.musicOnHoldSession) {
      if (
        this.musicOnHoldSession.state === SessionState.Initial ||
        this.musicOnHoldSession.state === SessionState.Establishing
      ) {
        this.musicOnHoldSession.cancel();
      } else if (this.musicOnHoldSession.state === SessionState.Established) {
        this.musicOnHoldSession.bye();
      }
    }
    this.musicOnHoldSession = undefined;
  }
}
