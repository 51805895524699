import { Injectable, NgZone } from "@angular/core";

import { ApiStateStoreService } from "./api-state-store.service";
import { APICredentials, APISessionObject, OAuthInfo } from "../api/api-credentials";
import { LoginError, LoginErrorCode } from "../api/login-error";
import { LogService } from "../logging/log.service";
import { StateEmitter } from "../../libraries/emitter";

import { Subject } from "rxjs";
import { NetworkService } from "../network.service";
import { OAuth2SessionCreate } from "./apiResponse/oauth-2-session-create";
import { OnsipApiResponse } from "./apiResponse/response-body";
import { ApiAction } from "./api-actions";
import { Role } from "./role";

const debug = false;

/**
 * The application state.
 */
export interface ApiSessionState {
  /** last time session id changed */
  updatedSessionTime: number;
  /** The User's ID in our system as a string */
  userId: string | undefined;
  /** user id of the super admin used to log into others account */
  parentUserId: string | undefined;
  /** The User's Session ID in our system as a string */
  sessionId: string | undefined;
  /** The role of the user */
  role: Role | undefined;
  /** The User's Organization ID */
  organizationId: string | undefined;
  /** The User's Account ID */
  accountId: string | undefined;
  /** State if user signs in via OAuth */
  thirdPartySignInInfo: OAuthInfo | undefined;
  /** whether or not this is the users first time logging in - mobile specific, but will possibly come into play for web */
  firstLogin: boolean;
  /** The application has logged in using the credentials herein. Application may or may not be online. */
  loggedIn: boolean;
  /** Currently attempting to login using the credentials herein */
  loginInProgress: boolean;
  /** the role of the first user logged in. used for spoofing */
  loggedInRole: Role | undefined;
}

@Injectable({ providedIn: "root" })
export class ApiSessionService extends StateEmitter<ApiSessionState> {
  protected api: APICredentials;
  protected unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    protected log: LogService,
    private ngZone: NgZone,
    protected network: NetworkService,
    private store: ApiStateStoreService
  ) {
    super({
      updatedSessionTime: Date.now(),
      userId: undefined,
      parentUserId: undefined,
      sessionId: undefined,
      role: undefined,
      organizationId: undefined,
      accountId: undefined,
      thirdPartySignInInfo: undefined,
      firstLogin: true,
      loggedIn: false,
      loginInProgress: false,
      loggedInRole: undefined
    });
    debug && this.state.subscribe(state => console.warn("ApiSessionState", state));

    this.api = new APICredentials();
    this.store.initSubstate("Session");
    this.platformInit();
    this.initAdminApiAuth();
  }

  /** Dispose of service. */
  dispose(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.logout();
    this.publishStateComplete();

    debug && this.log.debug("ApiSessionService Disposed");
  }

  /** For debugging. */
  dumpStore(): void {
    debug && this.log.debug(JSON.stringify(this.stateStore));
  }

  /** Platform specific - overridden by children */
  useOldLogin(): void {}

  recoverSession(sessionId: string, userId: string): Promise<APISessionObject | void> {
    debug && this.log.debug("ApiSessionService recoverSession");
    return this.api.SessionRecreate(sessionId, userId);
  }

  // when your api session expires, you can use the same function initially used
  // to make another one
  createNewSessionInternally(): Promise<APISessionObject | void> {
    debug && this.log.debug("ApiSessionService createNewSessionInternally");
    return this.api.useSessionCreationFunction().catch(e => {
      if (e instanceof LoginError && e.code === LoginErrorCode.CookieRecreateFailure) {
        return;
      }
      console.error("ApiSessionService.createNewSessionInternally failed", e);
      throw e;
    });
  }

  /** allow a super user to swap to a different user and essentially spoof as that user */
  sessionSubstituteUser(userId: string): Promise<void> {
    return this.api.SessionSubstituteUser(userId).then(() => {
      this.useOldLogin();
    });
  }

  sessionRestoreUser(): Promise<void> {
    return this.api.SessionRestoreUser().then(() => {
      this.useOldLogin();
    });
  }

  /** agentSessionSubsituteUser will use the substitute user api if there are no parentUserId but for further spoofing, it will only modify the session store */
  agentSessionSubstituteUser(userId: string): Promise<void> {
    if (this.stateStore.parentUserId) {
      return this.api.AgentSessionSubstituteUser(userId);
    } else {
      return this.sessionSubstituteUser(userId);
    }
  }

  getLastAgentSession(): APISessionObject | undefined {
    return this.api.getLastAgentSession();
  }

  /** agentSessionRestoreUser will use the sessionRestoreUser if there are no parentUserId but for further spoofing, it will only modify the session store */
  agentSessionRestoreUser(): Promise<void> {
    if (this.getLastAgentSession()) {
      return this.api.AgentSessionRestoreUser();
    } else {
      return this.sessionRestoreUser();
    }
  }

  /**
   * Logs user in via oauth. May return list of users connected to this service user in our system
   * @param authCode Code from service to confirm login
   * @param serviceName OAuth Service in question
   */
  oAuthLogin(
    authCode: string,
    serviceName: string
  ): Promise<OnsipApiResponse<"OAuth2SessionCreate", OAuth2SessionCreate>> {
    debug && this.log.debug("ApiSessionService.oAuthLogin");
    if (!this.network.stateValue.online) {
      return Promise.reject(new Error("ApiSessionService.login: not online."));
    }

    return this.api
      .OAuth2SessionCreate(authCode, serviceName, this.platformGetRedirectUri())
      .catch(error => {
        this.log.error("ApiSessionService.oAuthLogin: failed - " + error);
        throw this.api.apiErrorToLoginError(error);
      });
  }

  oAuthPostSignupLogin(
    token: string,
    serviceUserId: string,
    service: string,
    userId: string
  ): Promise<any> {
    debug && this.log.debug("ApiSessionService.oAuthSignupLogin");
    if (!this.network.stateValue.online) {
      return Promise.reject(new Error("ApiSessionService.login: not online."));
    }

    return this.api
      .OAuth2KnownSessionCreate({
        Service: service,
        ServiceUserId: serviceUserId,
        UserId: userId,
        Token: token
      })
      .catch(error => {
        this.log.error("ApiSessionService.oAuthLogin: failed - " + error);
        throw this.api.apiErrorToLoginError(error);
      });
  }

  /**
   * If we have the token already, we can use it to make a session
   */
  oAuthRecoverSession(token: any): Promise<void> {
    debug && this.log.debug("ApiSessionService.oAuthRecoverSession");
    if (!this.network.stateValue.online) {
      return Promise.reject(new Error("ApiSessionService.login: not online."));
    }

    return this.api.OAuth2KnownSessionCreate(token);
  }

  /**
   * Logs user in.
   * @param authCode Code from service to confirm login
   * @param serviceName OAuth Service in question
   */
  oAuthSelectUser(userId: string, accessToken: string): Promise<void> {
    debug && this.log.debug("ApiSessionService oAuthSelectUser");
    return this.api.OAuth2SessionReplaceUser(userId, accessToken);
  }

  signupSessionCreate(params: any): Promise<any> {
    debug && this.log.debug("ApiSessionService signupSessionCreate");
    return this.api.SignupAccountConfirm(params).then(response => {
      return response.Result.SignupAccountConfirm;
    });
  }

  /**
   * Logs user in.
   * @param username Login username.
   * @param password Login password.
   */
  login(username: string, password: string): Promise<APISessionObject> {
    debug && this.log.debug("ApiSessionService.login", username, password, this.stateStore);
    if (!this.network.stateValue.online) {
      return Promise.reject(new Error("ApiSessionService.login: not online."));
    }

    return this.api.SessionCreate(username, password).catch(error => {
      this.log.error("ApiSessionService.login: failed - " + error);
      throw this.api.apiErrorToLoginError(error);
    });
  }

  isLoggedIn(): boolean {
    return this.stateStore.loggedIn;
  }

  /**
   * Logs user out.
   */
  logout(): Promise<void> {
    debug && this.log.debug("ApiSessionService.logout");
    if (!this.stateStore.loggedIn) {
      return Promise.resolve();
    }
    return new Promise<void>(resolve => {
      this.api.reset();
      this.stateStore = {
        updatedSessionTime: Date.now(),
        userId: undefined,
        parentUserId: undefined,
        sessionId: undefined,
        role: undefined,
        organizationId: undefined,
        accountId: undefined,
        thirdPartySignInInfo: undefined,
        firstLogin: this.stateStore.firstLogin,
        loggedIn: this.stateStore.loggedIn,
        loginInProgress: this.stateStore.loginInProgress,
        loggedInRole: undefined
      };
      this.stateStore.loggedIn = false;
      this.publishState();
      resolve();
    });
  }

  /*
   * Platform specific redirectUri.
   * This method is overridden by other platform specific implementations of ApiSessionService (i.e. mobile).
   */
  platformGetRedirectUri(): string {
    return "";
  }

  protected flushState() {
    this.store.mergeStateUpdate(
      "Session",
      JSON.parse(JSON.stringify(this.stateStore)),
      ApiAction.SessionCreate
    );
  }

  /**
   * Platform specific initialization.
   * This method is overridden by other platform specific implementations of ApiSessionService (i.e. mobile).
   */
  protected platformInit(): void {}

  protected publishRun(fn: () => any): any {
    return this.ngZone.run(() => {
      return fn();
    });
  }

  private initAdminApiAuth(): void {
    debug && this.log.debug("ApiSessionService.initAdminApiAuth");
    this.api.state.subscribe(state => {
      this.stateStore.loginInProgress = state.loginInProgress;
      let time = this.stateStore.updatedSessionTime;
      if (state.SessionId !== this.stateStore.sessionId) {
        time = Date.now();
      }
      this.stateStore = {
        updatedSessionTime: time,
        userId: state.UserId,
        parentUserId: state.ParentUserId,
        sessionId: state.SessionId,
        role: state.role,
        organizationId: state.OrganizationId,
        accountId: state.AccountId,
        thirdPartySignInInfo: state.thirdPartySignInInfo,
        firstLogin: this.stateStore.firstLogin,
        loggedIn: this.stateStore.loggedIn,
        loginInProgress: this.stateStore.loginInProgress,
        loggedInRole: state.loggedInRole
      };
      if (this.stateStore.loggedIn && !state.loggedIn && !state.loginInProgress) {
        this.stateStore.loggedIn = false;
      } else if (!this.stateStore.loggedIn && state.loggedIn && state.UserId && state.SessionId) {
        this.stateStore.loggedIn = true;
      }
      this.publishState();
    });
  }
}
