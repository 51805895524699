import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { URI } from "sip.js";

import { EventStateEmitter } from "../emitter/event-state-emitter";
import { UserAgent, UserAgentState } from "./user-agent";
import { DisconnectedUserAgentEvent } from "./user-agent-event";
import {
  SubscriptionEvent,
  NewSubscriptionEvent,
  LastSubscriptionEvent,
  ConnectedSubscriptionEvent,
  EndSubscriptionEvent,
  NotifySubscriptionEvent
} from "./subscription-event";
import { UUID } from "./uuid";
import { log } from "./log";

//
// State
//

/**
 * Defines the Subscription state.
 */
export interface SubscriptionState {
  /** State of UserAgent the Subscription belongs to. */
  userAgent: UserAgentState;
  /** UUID of the Subscription. */
  uuid: string;
  /** When the Subscription activated - epoch time in milliseconds. */
  activatedAt: number | undefined;
  /** When the Subscription connected - epoch time in milliseconds. */
  connectedAt: number | undefined;
  /** When the Subscription deactivated - epoch time in milliseconds. */
  deactivatedAt: number | undefined;
  /** When the Subscription ended - epoch time in milliseconds. */
  endedAt: number | undefined;
  /** The event package defining the information to be provided by the notifier. */
  eventPackage: string;
  /** The body of the most recent notification. */
  notifyBody: string | undefined;
  /** URI of resource being subscribed to. */
  uri: string;
}

//
// Object
//

/**
 * A Subscription
 */
export class Subscription extends EventStateEmitter<SubscriptionEvent, SubscriptionState> {
  private _uuid: string;
  private _activatedAt: Date | undefined;
  private _connectedAt: Date | undefined;
  private _deactivatedAt: Date | undefined;
  private _endedAt: Date | undefined;
  private _notifyBody: string | undefined;

  private _unsubscribe: Subject<void> = new Subject<void>();

  private static initialState(
    uuid: string,
    userAgent: UserAgentState,
    uri: string,
    eventPackage: string
  ): SubscriptionState {
    return {
      userAgent,
      uuid,
      activatedAt: undefined,
      connectedAt: undefined,
      deactivatedAt: undefined,
      endedAt: undefined,
      eventPackage,
      notifyBody: undefined,
      uri
    };
  }

  /**
   * Creates a new Subscription.
   * @param userAgent UserAgent owning the Subscription.
   */
  constructor(private _userAgent: UserAgent, private _uri: URI, private _eventPackage: string) {
    super(
      Subscription.initialState(
        UUID.randomUUID(),
        _userAgent.stateValue,
        _uri.toString(),
        _eventPackage
      )
    );

    this._uuid = this.stateStore.uuid;
    this._activatedAt = undefined;
    this._connectedAt = undefined;
    this._deactivatedAt = undefined;
    this._endedAt = undefined;
    this._notifyBody = undefined;

    this.initUserAgentSubscriptions();
  }

  /**
   * Disposes of a Subscription.
   * If a Subscription is never activated, it needs to be disposed of explicitly.
   * Otherwise is will be cleaned up automatically when closed.
   */
  dispose() {
    if (this._activatedAt && !this._deactivatedAt) {
      throw new Error(
        `Subscription[${this._uuid}] activated subscription has not been deactivated - dispose`
      );
    }
    this.publishStateComplete();
    this.publishEventComplete();
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }

  /**
   * Sends a subscribe request.
   */
  subscribe(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends a unsubscribe request.
   * Terminates the subscription.
   */
  unsubscribe(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sets the event package defining the information to be provided by the notifier.
   * @param eventPackage The name of the event package.
   */
  setEventPackage(eventPackage: string): Promise<void> {
    this._eventPackage = eventPackage;
    this.publishState();
    return Promise.resolve();
  }

  /**
   * Sets the URI of the resource being subscribed to.
   * @param uri The URI of the resource being subscribed to.
   */
  setURI(uri: URI): Promise<void> {
    this._uri = uri;
    this.publishState();
    return Promise.resolve();
  }

  /**
   * When Subscription activated otherwise undefined.
   */
  get activatedAt(): Date | undefined {
    return this._activatedAt;
  }

  /**
   * When Subscription connected otherwise undefined.
   */
  get connectedAt(): Date | undefined {
    return this._connectedAt;
  }

  /**
   * When the Subscription deactivated.
   */
  get deactivatedAt(): Date | undefined {
    return this._deactivatedAt;
  }

  /**
   * When the Subscription ended.
   */
  get endedAt(): Date | undefined {
    return this._endedAt;
  }

  /**
   * The event package associated with the subscription.
   */
  get eventPackage(): string | undefined {
    return this._eventPackage;
  }

  /**
   * The body of the most recent notification.
   */
  get notifyBody(): string | undefined {
    return this._notifyBody;
  }

  /**
   * The URI identifying the resource being subscribed to.
   */
  get uri(): URI {
    return this._uri;
  }

  /**
   * The user agent this subscription belongs to.
   */
  get userAgent(): UserAgent {
    return this._userAgent;
  }

  /**
   * UUID of this subscription.
   */
  get uuid(): string {
    return this._uuid;
  }

  /**
   * Activates a Subscription.
   * NewSubscriptionEvent is emitted (initial event).
   */
  protected activate(): void {
    if (this._activatedAt) {
      return;
    }
    this._activatedAt = new Date();
    this.publishState();
    this.publishEvent(NewSubscriptionEvent.make(this.userAgent.aor, this.uuid));
  }

  /**
   * Deactivates a Subscription.
   * LastSubscriptionEvent is emitted (final event).
   * Subscription destroys itself.
   * @param delay Time in ms to delay deactivation.
   */
  protected deactivate(delay: number = 1000): void {
    if (this._deactivatedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Subscription[${this._uuid}] has not been activated - deactivate.`);
    }
    if (delay) {
      const timer = setInterval(() => {
        clearInterval(timer);
        this._deactivatedAt = new Date();
        this.publishState();
        this.publishEvent(LastSubscriptionEvent.make(this.userAgent.aor, this.uuid));
        this.dispose();
      }, delay);
    } else {
      this._deactivatedAt = new Date();
      this.publishState();
      this.publishEvent(LastSubscriptionEvent.make(this.userAgent.aor, this.uuid));
      this.dispose();
    }
  }

  /**
   * Connects a Subscription.
   * ConnectedSubscriptionEvent is emitted.
   */
  protected didConnect(): void {
    if (this._connectedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Subscription[${this._uuid}] has not been activated - didConnect.`);
    }
    this._connectedAt = new Date();
    this.publishState();
    this.publishEvent(ConnectedSubscriptionEvent.make(this.userAgent.aor, this.uuid));
  }

  /**
   * Ends a Subscription.
   * EndSubscriptionEvent is emitted.
   * @param delay Time in ms to delay deactivation.
   */
  protected didEnd(delay: number = 1000): void {
    if (this._endedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Subscription[${this._uuid}] has not been activated - didEnd.`);
    }
    this._endedAt = new Date();
    this.publishState();
    this.publishEvent(EndSubscriptionEvent.make(this.userAgent.aor, this.uuid));
    this.deactivate(delay);
  }

  protected onNotify(body: string): void {
    this.didConnect();
    this._notifyBody = body;
    this.publishState();
    this.publishEvent(NotifySubscriptionEvent.make(this.userAgent.aor, this.uuid, body));
  }

  protected onTerminated(): void {
    this.didEnd();
  }

  protected flushState() {
    super.flushState();
    this.stateStore.uuid = this.uuid;
    this.stateStore.activatedAt = this._activatedAt ? this._activatedAt.getTime() : undefined;
    this.stateStore.connectedAt = this._connectedAt ? this._connectedAt.getTime() : undefined;
    this.stateStore.deactivatedAt = this._deactivatedAt ? this._deactivatedAt.getTime() : undefined;
    this.stateStore.endedAt = this._endedAt ? this._endedAt.getTime() : undefined;
    this.stateStore.eventPackage = this._eventPackage;
    this.stateStore.notifyBody = this._notifyBody;
    this.stateStore.uri = this._uri.toString();
  }

  private initUserAgentSubscriptions() {
    // FIXME: TODO: as of 0.8.0, SIP.js dealing with subscription handling gotchas...
    //
    // Case 1) WebSocket connection drops and doesn’t come back prior to next expire time.
    // - SIP.js will attempt to send re-subscribe just prior to the next expire time, fail
    //   on lack of transport, and silently delete the subscription dialog (no events are emitted).
    //
    // Case 2) WebSocket connection drops and comes back up on different edge proxy (we will have a a new contact).
    // - After reconnecting, SIP.js will attempt to send re-subscribe just prior to the next expire time,
    //   fail on negative response, emit 'failed' and 'rejected', and deletes the subscription dialog.
    //
    // Case 3) WebSocket connection drops and comes back up on same edge proxy (we will have a a new contact).
    // - After reconnecting, SIP.js will attempt to send re-subscribe just prior to the next expire time and succeed.
    // - The presence server matches the subscription dialog on to-tag, from-tag, call-id, updates stored contact,
    //   and responds to new contact.
    //
    // Case 4) WebSocket connection drops and comes back up on same edge proxy (we will have a a new contact).
    //         but a NOTIFY was attempted while SIP.js was not connected.
    // - When presence server attempts to send a NOTIFY it gets a 408 back and deletes the subscription.
    // - After reconnecting, SIP.js will attempt to send re-subscribe just prior to the next expire time
    //   and receive a 481 negative response, and emit ‘failed’ and ‘rejected'.
    // - SIP.js attempts to re-subscription twice, failing twice, and emitting ‘failed’ and ‘rejected’ twice,
    //   and then delete the subscription dialog.
    //
    // In all cases, SIP.js is silent with regard to subscription events at least until it attempts
    // re-subscription at whatever point in the future (and it's completely silent in case 1).
    // So there is apparently no straight forward, reliable, or timely way to determine when a subscription
    // has failed due to websocket connection loss.
    //
    // The approach implemented herein is to monitor UserAgent events, attempt to detect a WebSocket
    // disconnection and close all subscriptions associated with the User Agent.
    // This approach appears to be the the only reasonable way of currently handling a WebSocket disconnection as...
    //  a) we have no way of knowing if a subscription will be silently removed client side
    //     if the connection is not re-established prior to expire time (case 1)
    //  b) we have no way of knowing if we will reconnect to the same edge proxy and even
    //     if we do if the subscription will still be there when we do (cases 2 & 3)
    //  c) we have no way of knowing if a subscription will be removed server side (408) while
    //     we are disconnected (case 4)
    //
    // Regardless of all the above, as we are not receiving NOTIFY messages while we are
    // without a WebSocket connection, the appears to be zero benefit to attempt
    // to maintain subscriptions while disconnected. It's not clear why SIP.js is not implemented
    // such that it tears down all subscriptions on transport failure.
    //
    // Fortunately SIP.js does reliably emit User Agent events upon disconnection, so we work around these issues
    // by looking for User Agent 'disconnected' events while in a connected state and disposing of the subscription.
    // Note that we are skipping the normal disposal delay to assist in avoiding a potential race condition when
    // reestablishing subscriptions upon the user agent reconnecting. For example, when a laptop is shut a common
    // occurrence is the have a user agent emit 'disconnect' immediately followed by 'connect'.
    //
    // It's worth noting that there are also subscription handling gotchas associated with not
    // DOSing the presence server with a SUBSCRIPTION flood when initiating
    // numerous subscriptions simultaneously (SIP.js will fire them off as fast as you can call subscribe),
    // but we are not dealing with those issues here.

    this.userAgent.event.pipe(takeUntil(this._unsubscribe)).subscribe({
      next: x => {
        log.debug(`Subscription[${this.uuid}].event ${x.id}`);
        switch (x.id) {
          // Read above, but this is basically a hack to work around fundamental SIP.js issues.
          // It ideally goes away with the release of some future version of SIP.js.
          case DisconnectedUserAgentEvent.id:
            if (this.connectedAt) {
              // this failure leads to way too many uncaught errors, given it fails skipping it makes no difference
              // this.unsubscribe(); // will fail, but...
              this.didEnd(0); // end with prejudice, zero delay to force immediate disposal
            }
            break;
          default:
            break;
        }
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        // subscription complete and going away, but we cleanup below when the state subscription ends
      }
    });

    this.userAgent.state.pipe(takeUntil(this._unsubscribe)).subscribe({
      next: next => {
        this.stateStore.userAgent = next;
        this.publishState();
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        // user agent has gone away, oh my...
        if (this._activatedAt) {
          this.deactivate();
        } else {
          this.dispose();
        }
      }
    });
  }
}
