import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "onsip-footer",
  templateUrl: "./onsip-footer.component.html",
  styleUrls: ["./onsip-footer.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnsipFooterComponent {
  currentYear: number = new Date().getFullYear();
}
