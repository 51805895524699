import { CallEvent, EventId } from "./call-event";

/** Emitted once when attempt to transfer someone else fails */
export class TransferFailedEvent extends CallEvent {
  id = EventId.TransferFailedEventId;

  /**
   * Factory for TransferFailedEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isTransferFailedEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for TransferFailedEvent.
 * @param event Event to check.
 */
export function isTransferFailedEvent(event: any): event is TransferFailedEvent {
  return event instanceof TransferFailedEvent;
}
