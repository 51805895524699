import { Injectable } from "@angular/core";
import { StateEmitter } from "../../../common/libraries/emitter/state-emitter";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { CallState } from "../../../common/libraries/sip/call-state";
const debug = false;

interface ScreenShareType {
  hasScreenShare: boolean;
  screenShareUuid: string | undefined;
}

@Injectable({ providedIn: "root" })
export class ScreenShareService extends StateEmitter<ScreenShareType> {
  /** kay value is uuid, points to whether or not the call has screenshare active */
  calls: Record<string, boolean> = {};
  /** variable to determine whether to display screenshare or not */
  showScreenShare = false;
  /** store screen share stream from getDisplayMedia */
  private screenShareStream!: MediaStream | undefined;
  /** store the track that we are replacing with screenShareStream */
  private replacedSenderTrack: MediaStreamTrack | undefined;

  constructor(private callControllerService: CallControllerService) {
    super({
      hasScreenShare: false,
      screenShareUuid: undefined
    });
  }

  getScreenShareFromUuid(uuid: string): boolean {
    if (this.calls[uuid]) {
      return this.calls[uuid];
    } else {
      this.calls[uuid] = false;
      return this.calls[uuid];
    }
  }

  toggleScreenShare(uuid: string): void {
    // if screen share is turned on, attempt getDisplayMedia on current browser
    if (!this.stateValue.hasScreenShare && !this.stateValue.screenShareUuid) {
      this.getStartCapture(uuid);
    } else if (this.stateValue.screenShareUuid) {
      // close stream which will trigger the stopCapture event listener
      this.stopCapture();
    }
  }

  toggleShowScreenShare(): void {
    this.showScreenShare = !this.showScreenShare;
  }

  async stopCapture(): Promise<void> {
    this.screenShareStream?.getTracks().forEach(track => track.stop());
    this.screenShareStream = undefined;
    this.showScreenShare = false;
    await this.restoreSenderTrack();
    this.replacedSenderTrack = undefined;
    this.clearScreenShareUuid();
    this.stateStore.hasScreenShare = false;
    this.publishState();
    return Promise.resolve();
  }

  private async getStartCapture(uuid: string): Promise<void> {
    try {
      const peerConnection = this.getPeerConnection(uuid);
      if (!peerConnection) {
        return Promise.reject(new Error("Peer connection undefined."));
      }
      // we want replacementTrack.kind === "video" which will be at index 1
      this.replacedSenderTrack = peerConnection.getSenders()[1].track || undefined;
      // use navigator.mediaDevices.getDisplayMedia to get screen sharing
      // need to type cast navigator.mediaDevices as any since the getDisplayMedia function does not exist in the typescript dom library
      this.screenShareStream = await (navigator.mediaDevices as any).getDisplayMedia({
        video: {
          cursor: "always"
        },
        audio: true
      });
      const videoTrack = this.screenShareStream?.getVideoTracks()[0];
      await this.replaceSenderTrack(uuid, videoTrack);
      this.stateStore.hasScreenShare = true;
      this.stateStore.screenShareUuid = uuid;
      this.calls[uuid] = true;
      this.publishState();
      // add event to listen to native end share screen buttons
      // code taken from https://github.com/webrtc/samples/blob/gh-pages/src/content/getusermedia/getdisplaymedia/js/main.js#L88
      this.screenShareStream?.addEventListener("inactive", () => {
        console.log("Capture stream inactive - stop recording");
        this.stopCapture();
      });
    } catch (err) {
      console.error(err);
    }
  }

  private clearScreenShareUuid(): void {
    if (this.stateValue.screenShareUuid) {
      const currentCall: CallState | undefined = this.callControllerService.getCallStateByUuid(
        this.stateValue.screenShareUuid
      );
      // if ending the screenshare and call is on hold, the call will be unheld because we will restore the sender track
      if (currentCall && currentCall.hold) {
        this.callControllerService.makeUnheldCall(currentCall.uuid);
      }
      delete this.calls[this.stateValue.screenShareUuid];
      this.stateStore.screenShareUuid = undefined;
    }
  }

  // similar to callAudioService
  private getPeerConnection(uuid: string): RTCPeerConnection | undefined {
    const sdh = this.callControllerService.findSessionDescriptionHandler(uuid);
    return sdh ? (sdh as any).peerConnection : undefined;
  }

  private replaceSenderTrack(
    uuid: string,
    replacementTrack: MediaStreamTrack | undefined
  ): Promise<void> {
    if (replacementTrack) {
      const sdh = this.callControllerService.findSessionDescriptionHandler(uuid);
      return sdh
        ? sdh.replaceSenderTrack(replacementTrack)
        : Promise.reject("error in replacing sender track");
    } else {
      debug && console.warn("There was no call uuid or screen share track");
      return Promise.resolve();
    }
  }

  private restoreSenderTrack(): Promise<void> {
    if (this.stateValue.screenShareUuid) {
      const sdh = this.callControllerService.findSessionDescriptionHandler(
        this.stateValue.screenShareUuid
      );
      if (sdh) {
        return sdh.restoreSenderTrack(this.replacedSenderTrack);
      } else {
        debug && console.log("Closing screen share tracks after call ended");
        return Promise.resolve();
      }
    } else {
      debug && console.warn("There was no call uuid or stored replace track");
      return Promise.resolve();
    }
  }
}
