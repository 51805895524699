import {
  hasTooManyCallsWaiting,
  hasCallWaitingTooLong,
  hasTooFewAgents,
  NotifyObject,
  AdvQueueWarning,
  ThresholdValue,
  ReturnObj as TriggerReturnObj
} from "./triggers";
import { DateTime } from "luxon";

const typesToTriggers: Record<
  string,
  (thresholdValue: ThresholdValue, notifyObject: NotifyObject, currDate: Date) => TriggerReturnObj
> = {
  too_few_agents: hasTooFewAgents,
  too_many_callers: hasTooManyCallsWaiting,
  too_long_waiting: hasCallWaitingTooLong
};

function thresholdCheck(
  advQueueWarning: AdvQueueWarning,
  notifyObject: NotifyObject,
  currDate: Date
) {
  return typesToTriggers[advQueueWarning.ThresholdType](
    JSON.parse(advQueueWarning.ThresholdValue),
    notifyObject,
    currDate
  );
}

function convertDeprecatedTimezone(oldTimeZone: string): string {
  switch (oldTimeZone) {
    case "US/Samoa":
      return "Pacific/Samoa";
    case "US/Hawaii":
      return "Pacific/Honolulu";
    case "US/Aleutian":
      return " America/Adak";
    case "US/Alaska":
      return "America/Anchorage";
    case "US/Pacific":
      return "America/Los_Angeles";
    case "Canada/Yukon":
      return "America/Whitehorse";
    case "Canada/Pacific":
      return "America/Vancouver";
    case "US/Mountain":
      return "America/Denver";
    case "US/Arizona":
      return "America/Phoenix";
    case "Canada/Mountain":
      return "America/Edmonton";
    case "US/Indiana-Starke":
      return "America/Indiana/Knox";
    case "US/Central":
      return "America/Chicago";
    case "Canada/Saskatchewan":
      return "America/Regina";
    case "Canada/East-Saskatchewan":
      return "America/Regina";
    case "Canada/Central":
      return "America/Winnipeg";
    case "US/Michigan":
      return " America/Detroit";
    case "US/Eastern":
      return "America/New_York";
    case "US/East-Indiana":
      return "America/Indiana/Indianapolis";
    case "Canada/Eastern":
      return "America/Toronto";
    case "Canada/Atlantic":
      return "America/Halifax";
    case "Canada/Newfoundland":
      return "America/St_Johns";
    default:
      return "America/New_York";
  }
}

export function subtractTimes(timeOne: number, timeTwo: number, currSeconds: number): number {
  const oneMinutes = timeOne % 100,
    twoMinutes = timeTwo % 100,
    oneHours = ((timeOne - oneMinutes) / 100) * 60,
    twoHours = ((timeTwo - twoMinutes) / 100) * 60;

  currSeconds = currSeconds || 0;

  // returns milliseconds
  return (oneHours + oneMinutes - (twoHours + twoMinutes)) * 60000 - currSeconds * 1000;
}

// TODO/NOTE: checks for next day are currently just "get a new NOTIFY
// at the end of today"... this can be improved, if we want, but it would
// just reduce number of NOTIFYs
export function businessHoursCheck(advQueueWarning: AdvQueueWarning, currDate: Date): number {
  // Javascript date uses 0 as Sunday, but we use 0 as Monday, so shift and wrap
  let bhrArray;

  try {
    bhrArray = JSON.parse(advQueueWarning.WarningBhr);
  } catch (err) {
    return Infinity;
  }

  const luxonObj = DateTime.local().setZone(convertDeprecatedTimezone(bhrArray.TimeZone));
  if (!luxonObj) {
    return Infinity;
  }

  const hourOffset = (luxonObj.offset / 60) * 100;
  let currTime = currDate.getUTCHours() * 100 + currDate.getUTCMinutes() + hourOffset,
    currDay = (((currDate.getUTCDay() - 1) % 7) + 7) % 7;

  if (currTime < 0) {
    currTime += 2400;
    currDay = currDay === 0 ? 6 : currDay - 1;
  } else if (currTime >= 2400) {
    currTime -= 2400;
    currDay = currDay === 6 ? 0 : currDay + 1;
  }

  if (!bhrArray[currDay]) {
    return subtractTimes(2400, currTime, currDate.getUTCSeconds());
  }

  const start = parseInt(bhrArray[currDay][0].replace(/:/g, "")),
    end = parseInt(bhrArray[currDay][1].replace(/:/g, ""));

  if (start <= currTime && end >= currTime) {
    return Infinity;
  }

  if (start > currTime) {
    return subtractTimes(start, currTime, currDate.getUTCSeconds());
  }

  return subtractTimes(2400, currTime, currDate.getUTCSeconds());
}

export function cooldownCheck(advQueueWarning: AdvQueueWarning, currDate: Date): number {
  if (!advQueueWarning.cooldownStart) {
    return Infinity;
  }

  const cooldownOver =
      advQueueWarning.cooldownStart + parseInt(advQueueWarning.CooldownSeconds) * 1000,
    currTime = currDate.getTime();

  if (cooldownOver <= currTime) {
    return Infinity;
  }

  return cooldownOver - currTime;
}

export function warningChecker(
  advQueueWarning: AdvQueueWarning,
  notifyObject: NotifyObject,
  stateChangeEmitter: EventTarget,
  currDate: Date
): number {
  const bhr = businessHoursCheck(advQueueWarning, currDate),
    tc = cooldownCheck(advQueueWarning, currDate),
    min = Math.min(bhr, tc),
    shortCircuit = isFinite(min);

  if (!advQueueWarning.hasCleared) {
    advQueueWarning.hasCleared = true;
  }

  if (advQueueWarning.hasCleared && shortCircuit) {
    return min;
  }

  const triggerInfo = thresholdCheck(advQueueWarning, notifyObject, currDate);

  // "has an alert ever cleared" checks
  if (!triggerInfo.triggered) {
    advQueueWarning.hasCleared = true;
    stateChangeEmitter.dispatchEvent(
      new CustomEvent("clear", { detail: { advQueueWarning, notifyObject } })
    );
  } else if (!shortCircuit && advQueueWarning.hasCleared) {
    // trigger checks
    advQueueWarning.hasCleared = false;
    advQueueWarning.cooldownStart = currDate.getTime();
    stateChangeEmitter.dispatchEvent(
      new CustomEvent("trigger", { detail: { advQueueWarning, notifyObject } })
    );
  }

  return Math.min(min, triggerInfo.recheckIn);
}
