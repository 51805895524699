import { Component, Inject, Type, ChangeDetectionStrategy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { E2eLocators } from "@onsip/e2e/configs/locators";

// Use this interface to create custom modals without needing to create brand new component.
export interface ModalMaterialData {
  /** Populates the mat-dialog-title header forced to case */
  title: string;
  /** Populates [innerhtml] of mat-dialog-content can pass html or plain text */
  message?: string;
  /** Pass a component reference to be placed in the mat-dialog-content */
  component?: Type<any>;
  /** If true, display checkbox for "do not show again". */
  showFastPass?: boolean;
  /** Affirmative / Primary button text e.g. ACCEPT, REMOVE, DELETE */
  primaryBtnText?: string;
  /** Affirmative / Primary button color e.g. warn | primary | accent */
  primaryBtnColor?: "warn" | "primary" | "accent";
  /** Affirmative / Primary button to be a mat-raised-button */
  primaryBtnFlat?: boolean;
  /** Negative / Secondary button text e.g. ACCEPT, REMOVE, DELETE */
  secondaryBtnText?: string;
  /** Negative / Secondary button color e.g. warn | primary | accent */
  secondaryBtnColor?: "warn" | "primary" | "accent";
  /** Changes the styling to rounder corners & font, and a bold title */
  roundLook: boolean;
  /** option to only have a primary button */
  showOnlyPrimaryBtn?: boolean;
  /** option to not sentence case the title */
  noSentenceCaseTitle?: boolean;
  /** option to not sentence case the primary button text */
  noSentenceCasePrimaryBtn?: boolean;
  /** option to hide underline for links */
  //noUnderlinedLinks?: boolean;
}

export interface ModalMaterialReturnData {
  doPrimaryAction: boolean;
  fastPass: boolean;
}

@Component({
  templateUrl: "./modal-material.component.html",
  styleUrls: ["./modal-material.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalMaterialComponent {
  response: ModalMaterialReturnData = {
    doPrimaryAction: false,
    fastPass: false
  };
  E2eLocators = E2eLocators;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ModalMaterialData) {}
}
