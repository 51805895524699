import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
  AgentTransaction,
  AgentTransactionType
} from "@onsip/common/services/api/resources/agentTransaction/agent-transaction";
import { currencyFormatter } from "@onsip/web/features/shared/helpers/currency-formatter";
import { debounceTime, fromEvent, Subscription } from "rxjs";
import { saveAs } from "file-saver";

interface AccountInfo {
  accountId: string;
  org: string;
}
interface Revenue extends AccountInfo {
  date: string;
  paymentId: string;
  commissionableOverTotal: string;
}

interface PSTNRevenue extends AccountInfo {
  orgId: string;
  domain: string;
  min: string;
  calls: string;
  commissionable: string;
  ineligibleRevenue: boolean;
}

@Component({
  selector: "onsip-agent-commissions-shelf",
  templateUrl: "./agent-commissions-shelf.component.html",
  styleUrls: ["./agent-commissions-shelf.component.scss"],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    "[style.--table-width]": "tableWidth"
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentAdminCommissionsShelfComponent implements OnInit, OnDestroy {
  @Input() transaction!: AgentTransaction;
  @ViewChild("asrTableSort") set asrTableSort(asrTableSort: MatSort) {
    this.asrDataSource.sort = asrTableSort;
  }
  @ViewChild("pstnTableSort") set pstnTableSort(pstnTableSort: MatSort) {
    this.pstnDataSource.sort = pstnTableSort;
  }

  @ViewChild("wgrTableSort") set wgrTableSort(wgrTableSort: MatSort) {
    this.wgrDataSource.sort = wgrTableSort;
  }

  transactionDetails!: Array<string>;
  // Payment detail variables
  paymentTitle: string | undefined;
  paymentAgent: string | undefined;
  paymentAgreement!: string | undefined;
  paymentBeginningBalance!: string | undefined;
  paymentApplicationServicesRevenue!: string | undefined;
  applicationServicesRevenues: Array<Revenue> = [];
  asrDataSource = new MatTableDataSource<Revenue>([]);
  asrTotal!: string | undefined;
  asrCommissionRate!: string | undefined;
  asrCommission: string | undefined;
  pstnGatewayUtilizationRevenue!: string | undefined;
  pstnRevenues: Array<PSTNRevenue> = [];
  pstnDataSource = new MatTableDataSource<PSTNRevenue>([]);
  pstnTotal!: string | undefined;
  pstnCommissionRate!: string | undefined;
  pstnCommission!: string | undefined;
  totalCommission!: string | undefined;
  endingBalance!: string | undefined;
  whiteGloveRevenue: string | undefined;
  whiteGloveRevenues: Array<Revenue> = [];
  wgrDataSource = new MatTableDataSource<Revenue>([]);
  wgrTotal!: string | undefined;
  wgrCommissionRate!: string | undefined;
  wgrCommission: string | undefined;

  asrDisplayColumns = ["accountId", "org", "date", "paymentId", "commissionableOverTotal"];
  pstnDisplayColumns = ["accountId", "org", "orgId", "domain", "min", "calls", "commissionable"];
  wgrDisplayColumns = ["accountId", "org", "date", "paymentId", "commissionableOverTotal"];

  tableWidth!: string;
  private unsubscriber = new Subscription();

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    const type = this.transaction.type as `${AgentTransactionType}`;
    const parsedTransaction = this.transaction.description.split("\n");
    if (type !== "payment") {
      if (type === "credit" || type === "debit") {
        this.transactionDetails = parsedTransaction;
      } else {
        this.transactionDetails = parsedTransaction.map(line => this.cleanUpComma(line));
      }
    } else {
      this.handlePaymentDetails(parsedTransaction);
    }
    this.handleTableResize();
    this.handleRevenueSorting(this.asrDataSource);
    this.handleRevenueSorting(this.wgrDataSource);
    this.handlePSTNSorting();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private handlePaymentDetails(parsedTransaction: Array<string>): void {
    let parsedLines = parsedTransaction.filter(line => line.length > 0).map(line => line.trim());
    this.paymentTitle = parsedLines.shift();
    const paymentAgent = parsedLines.shift();
    if (paymentAgent) {
      const [agent, name] = paymentAgent.split(":");
      const agentId = agent.split(" ")[1];
      this.paymentAgent = agentId + " - " + name;
    }
    const paymentAgreement = parsedLines.shift();
    if (paymentAgreement) {
      const [, value] = paymentAgreement.split(":");
      this.paymentAgreement = value.trim();
    }
    const paymentBeginningBalance = parsedLines.shift();
    if (paymentBeginningBalance) {
      const [, value] = paymentBeginningBalance.split(":");
      // slice the dollar sign and let formatter do the job
      this.paymentBeginningBalance = currencyFormatter(value.trim().slice(1));
    }
    this.paymentApplicationServicesRevenue = parsedLines.shift();
    // now into the Application Services Revenue table
    parsedLines.shift(); // this will drop the Commission/Total line - we can insert this on our own.
    for (let i = 0; i < parsedLines.length; ) {
      // kill for loop if we reached the end of the table
      if (parsedLines[i].includes("Total") && parsedLines[i + 1].includes("Commission Rate")) {
        parsedLines = parsedLines.slice(i);
        break;
      }

      if (parsedLines[i + 1].match(/\d{4}-\d{2}-\d{2}/gi)) {
        let counter = i + 2;
        const start = i;
        this.parseRevenue(
          parsedLines[start],
          parsedLines[start + 1],
          this.applicationServicesRevenues
        );
        while (parsedLines[counter].match(/\d{4}-\d{2}-\d{2}/gi)) {
          this.parseRevenue(
            parsedLines[start],
            parsedLines[counter],
            this.applicationServicesRevenues
          );
          counter++;
        }
        i = counter;
      } else {
        this.parseRevenue(parsedLines[i], parsedLines[i + 1], this.applicationServicesRevenues);
        i = i + 2;
      }
    }
    this.asrDataSource.data = this.applicationServicesRevenues;
    this.asrTotal = this.getCurrencyValue(parsedLines.shift());
    this.asrCommissionRate = this.getCurrencyValue(parsedLines.shift());
    this.asrCommission = this.getCurrencyValue(parsedLines.shift());
    // White Glove Revenue
    // Note: not all commission payments will have white glove revenue
    if (parsedLines[0] === "White Glove Revenue") {
      this.whiteGloveRevenue = parsedLines.shift();
      parsedLines.shift(); // this will drop the Commission/Total line - we can insert this on our own.
      // loop will be same as application services
      for (let i = 0; i < parsedLines.length; ) {
        // kill for loop if we reached the end of the table
        if (parsedLines[i].includes("Total") && parsedLines[i + 1].includes("Commission Rate")) {
          parsedLines = parsedLines.slice(i);
          break;
        }

        if (parsedLines[i + 1].match(/\d{4}-\d{2}-\d{2}/gi)) {
          let counter = i + 2;
          const start = i;
          this.parseRevenue(parsedLines[start], parsedLines[start + 1], this.whiteGloveRevenues);
          while (parsedLines[counter].match(/\d{4}-\d{2}-\d{2}/gi)) {
            this.parseRevenue(parsedLines[start], parsedLines[counter], this.whiteGloveRevenues);
            counter++;
          }
          i = counter;
        } else {
          this.parseRevenue(parsedLines[i], parsedLines[i + 1], this.whiteGloveRevenues);
          i = i + 2;
        }
      }
      this.wgrDataSource.data = this.whiteGloveRevenues;
      this.wgrTotal = this.getCurrencyValue(parsedLines.shift());
      this.wgrCommissionRate = this.getCurrencyValue(parsedLines.shift());
      this.wgrCommission = this.getCurrencyValue(parsedLines.shift());
    }

    this.pstnGatewayUtilizationRevenue = parsedLines.shift();
    for (let i = 0; parsedLines.length; i++) {
      // kill for loop if we reached the end of the table
      if (parsedLines[i].includes("Total") && parsedLines[i + 1].includes("Commission Rate")) {
        parsedLines = parsedLines.slice(i);
        break;
      }
      if (parsedLines[i].includes("Account") && parsedLines[i + 1].includes("Organization")) {
        this.parsePSTNRevenue(parsedLines, i);
      }
    }
    this.pstnDataSource.data = this.pstnRevenues;
    this.pstnTotal = this.getCurrencyValue(parsedLines.shift());
    this.pstnCommissionRate = this.getCurrencyValue(parsedLines.shift());
    this.pstnCommission = this.getCurrencyValue(parsedLines.shift());
    this.totalCommission = this.getCurrencyValue(parsedLines.shift());
    const endingBalance = parsedLines.shift();
    if (endingBalance) {
      const [, value] = endingBalance.split(":");
      this.endingBalance = currencyFormatter(value.trim().slice(1));
    }
  }

  private parseRevenue(line1: string, line2: string, dataArray: Array<Revenue>): void {
    let revenueObject = {
      accountId: "",
      org: "",
      date: "",
      paymentId: "",
      commissionableOverTotal: ""
    };
    revenueObject = { ...revenueObject, ...this.parseAccountLine(line1) };
    // line2 can be either "-- No Payments --" or "2022-05-16 Payment #2326045 81.95/91.18"
    // if no payments then leave fields in object empty, else fill in with line2 data
    if (!line2.includes("No Payments")) {
      // split and then filter out the empty spaces
      const splitLine2 = line2.split(" ").filter(data => data.length > 0);
      // data should look like this now: ['2022-05-16', 'Payment', '#2326045', '81.95/91.18']
      revenueObject.date = splitLine2[0];
      revenueObject.paymentId = splitLine2[2];
      const commissionableOverTotal = splitLine2[3];
      revenueObject.commissionableOverTotal = commissionableOverTotal
        .split("/")
        .map(value => currencyFormatter(value.trim()))
        .join("/");
    }
    dataArray.push(revenueObject);
  }

  private parsePSTNRevenue(lines: Array<string>, index: number): number {
    const accountInfo = this.parseAccountLine(lines[index]);
    index++;
    for (let j = index; j < lines.length; j = j + 2) {
      // if the next line says the revenue is ineligible, then mark the previous revenue as ineligible and readjust step count
      if (lines[j].includes("Ineligible Revenue Adjustment") && this.pstnRevenues) {
        this.pstnRevenues[this.pstnRevenues.length - 1].ineligibleRevenue = true;
        j = j - 1;
        continue;
      }
      if (
        (lines[j].includes("Account") && lines[j + 1].includes("Organization")) ||
        (lines[j].includes("Total") && lines[j + 1].includes("Commission Rate"))
      ) {
        // end if hit another account or reach end of pstn revenue
        // end loop here. also return index of line we are at for the outer loop to continue past this section
        // minus one so the outer loop can handle the ending line logic
        return j - 1;
      }

      this.parseOrgPSTNRevenue(lines[j], lines[j + 1], accountInfo);
    }
    // shouldn't hit this return
    return index;
  }

  private parseOrgPSTNRevenue(line1: string, line2: string, accountInfo: AccountInfo): void {
    const pstnObject = {
      ...accountInfo,
      ...{ orgId: "", domain: "", min: "", calls: "", commissionable: "", ineligibleRevenue: false }
    };

    const [organization, domain] = line1.split(":");
    pstnObject.orgId = organization.replace(/\D/g, "");
    pstnObject.domain = domain.trim();
    // some org may not be eligible for commission so skip parsing steps for those
    if (line2.includes("Not Eligible for Commission")) {
      pstnObject.ineligibleRevenue = true;
    } else {
      const [min, callsAndCommissionable] = line2.split(",");
      pstnObject.min = min.replace(/\D/g, "");
      const [calls, commissionable] = callsAndCommissionable.split("  ").filter(v => v.length > 0);
      pstnObject.calls = calls.replace(/\D/g, "");
      pstnObject.commissionable = currencyFormatter(commissionable);
    }
    this.pstnRevenues.push(pstnObject);
  }

  private parseAccountLine(line: string): AccountInfo {
    // line looks like this "Account 169910 : Mike Corp"
    // account will be Account 169910 and then strip all non numeric char to get id
    // org is basically the org just need to trim white spaces
    const [account, org] = line.split(":");
    return {
      accountId: account.replace(/\D/g, ""),
      org: org.trim()
    };
  }

  // get currency value for lines where the value is at the end of the line aka totals, commission rates, etc
  private getCurrencyValue(line: string | undefined): string {
    if (!line) return "";
    const splitLine = line.split(" ");
    const value = splitLine[splitLine.length - 1];
    return currencyFormatter(value);
  }

  // comma is in a weird spot for the commission text. Use this helper function to shift the comma to be right after the first word
  private cleanUpComma(line: string): string {
    const [part1, part2] = line.split(":").map(p => p.trim());
    return part1 + ": " + part2;
  }

  private handleTableResize(): void {
    // initial to the current window size. Do not go smaller than 1280 at Large breakpoint
    // table width should be at Large breakpoint even at smaller breakpoints
    // for XLarge breakpoint, the table is fixed so use the fixed width
    this.tableWidth = `${Math.max(window.innerWidth, 1280) - 396}px`;
    if (window.innerWidth >= 1920) this.tableWidth = "936px";
    this.unsubscriber.add(
      fromEvent(window, "resize")
        .pipe(debounceTime(1000))
        .subscribe(e => {
          const windowWidth = (e.target as any)?.innerWidth;
          // get width of table row
          if (windowWidth) {
            this.tableWidth = `${Math.max(windowWidth, 1280) - 396}px`;
            if (windowWidth >= 1920) this.tableWidth = "936px";
          }
          this.cdRef.markForCheck();
        })
    );
  }

  private handleRevenueSorting(dataSource: MatTableDataSource<Revenue>): void {
    dataSource.sortingDataAccessor = (item: Revenue, columnName: string) => {
      switch (columnName) {
        case "accountId":
          return parseInt(item.accountId);
        case "org":
          return item.org;
        case "date":
          return item.date;
        case "paymentId":
          return item.paymentId;
        case "commissionableOverTotal":
          if (!item.commissionableOverTotal.length) return "";
          // split and get total currency value. parse to get number value and use number for sorting comparsion
          return Number(item.commissionableOverTotal.split("/")[1].replace(/[^0-9.-]+/g, ""));
        default:
          return parseInt(item.accountId);
      }
    };
  }

  private handlePSTNSorting(): void {
    this.pstnDataSource.sortingDataAccessor = (item: PSTNRevenue, columnName: string) => {
      switch (columnName) {
        case "accountId":
          return parseInt(item.accountId);
        case "org":
          return item.org;
        case "orgId":
          return parseInt(item.orgId);
        case "domain":
          return item.domain;
        case "min":
          return parseInt(item.min);
        case "calls":
          return parseInt(item.calls);
        case "commissionable":
          return Number(item.commissionable.replace(/[^0-9.-]+/g, ""));
        default:
          return parseInt(item.accountId);
      }
    };
  }

  downloadCSV() {
    let rowsCsv = "";
    if (this.transaction.type !== "payment") {
      this.transactionDetails.forEach(details => {
        rowsCsv += `${this.cleanUpCommaForCsv(details)},\r\n`;
      });
    } else {
      rowsCsv = this.createCsvForPayments(rowsCsv);
    }
    this.saveAsCsv(rowsCsv);
  }

  private createCsvForPayments(rowsCsv: string): string {
    rowsCsv += this.paymentTitle + "\r\n";
    rowsCsv += `Agreement:, ${this.paymentAgreement} \r\n`;
    rowsCsv += `Beginning balance:, ${this.cleanUpCommaForCsv(this.paymentBeginningBalance)} \r\n`;
    rowsCsv += `Total commission:, ${this.cleanUpCommaForCsv(this.totalCommission)} \r\n`;
    rowsCsv += `Ending balance:, ${this.cleanUpCommaForCsv(this.endingBalance)} \r\n \r\n`;

    rowsCsv += this.paymentApplicationServicesRevenue + "\r\n";

    const revenueHeaderRow = "Acct Id,Org,Date,Payment Id,Commissionable / total\r\n";
    rowsCsv += revenueHeaderRow;
    this.applicationServicesRevenues.forEach(revenue => {
      rowsCsv += `${revenue.accountId},${revenue.org},${revenue.date || "-"},${
        revenue.paymentId || "-"
      },${this.cleanUpCommaForCsv(revenue.commissionableOverTotal) || "No payments"}\r\n`;
    });
    rowsCsv += `Total:, ${this.cleanUpCommaForCsv(this.asrTotal)}\r\n`;
    rowsCsv += `Commission rate:, ${this.cleanUpCommaForCsv(this.asrCommissionRate)}\r\n`;
    rowsCsv += `App services commission:, ${this.cleanUpCommaForCsv(this.asrCommission)}\r\n \r\n`;

    if (this.whiteGloveRevenues.length) {
      rowsCsv += this.whiteGloveRevenue + "\r\n";
      rowsCsv += revenueHeaderRow;
      this.whiteGloveRevenues.forEach(revenue => {
        rowsCsv += `${revenue.accountId},${revenue.org},${revenue.date || "-"},${
          revenue.paymentId || "-"
        },${this.cleanUpCommaForCsv(revenue.commissionableOverTotal) || "No payments"}\r\n`;
      });
      rowsCsv += `Total:, ${this.cleanUpCommaForCsv(this.wgrTotal)}\r\n`;
      rowsCsv += `Commission rate:, ${this.cleanUpCommaForCsv(this.wgrCommissionRate)}\r\n`;
      rowsCsv += `White glove commission:, ${this.cleanUpCommaForCsv(this.wgrCommission)}\r\n \r\n`;
    }

    rowsCsv += this.pstnGatewayUtilizationRevenue + "\r\n";
    const pstnHeaderRow = "Account Id,Org,OrgId,Domain,Min,calls,Commissionable\r\n";
    rowsCsv += pstnHeaderRow;
    this.pstnRevenues.forEach(revenue => {
      rowsCsv += `${revenue.accountId},${revenue.org},${revenue.orgId},${revenue.domain},${
        revenue.min
      },${revenue.calls},${this.cleanUpCommaForCsv(revenue.commissionable)}\r\n`;
    });
    rowsCsv += `Total:, ${this.cleanUpCommaForCsv(this.pstnTotal)}\r\n`;
    rowsCsv += `Commission rate:, ${this.cleanUpCommaForCsv(this.pstnCommissionRate)}\r\n`;
    rowsCsv += `PSTN Gateway commission:, ${this.cleanUpCommaForCsv(this.pstnCommission)}\r\n`;
    return rowsCsv;
  }

  private saveAsCsv(csvString: string) {
    const blob: Blob = new Blob([csvString], { type: "text/csv;charset=utf8;" });
    saveAs(blob, "onsip-agent-transaction.csv");
  }

  private cleanUpCommaForCsv(csvString: string | undefined): string | undefined {
    return csvString?.replace(/,/g, "");
  }
}
