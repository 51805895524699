export interface WarningBarData {
  /** name of the warning status */
  name: WarningBarNames;
  /** background color of warning bar if undefined default to warn */
  bgColor?: "primary" | "accent" | "warn";
  /** i18n message is the key to the i18n message */
  i18nMessage: string;
  /** the displayed message */
  message: string;
  /** the displayed icon. use mat-icon names */
  displayIcon: string;
  /** canEnable means there is a service function that can revert the warning bar state */
  canEnable: boolean;
  /** only if canEnable is true, the button i18n text of the revert button */
  buttonText?: string;
  /** the hide button's i18n text, will say hide if not passed */
  hideButtonText?: string;
  /** priority used to order data based on priority */
  priority: number;
}

export type WarningBarNames =
  | "offline"
  | "callSetup"
  | "appCallDisabled"
  | "globalAvailability"
  | "approvedAudio"
  | "canAutoAnswer"
  | "deniedNotification"
  | "doNotDisturb"
  | "noE911AdminDesktop"
  | "noE911Admin"
  | "E911Admin"
  | "noE911User"
  | "E911User"
  | "freeTrial"
  | "experimentalIOS";

export const WarningBarStates: Record<WarningBarNames, WarningBarData> = {
  offline: {
    name: "offline",
    i18nMessage: "ONSIP_I18N.THE_NETWORK_IS_CURRENTLY_OFFLINE_PLEASE_CHECK_YOUR_CONNECTION",
    message: "The network is currently offline. Please check your connection.",
    displayIcon: "",
    canEnable: false,
    priority: 0
  },
  experimentalIOS: {
    name: "experimentalIOS",
    i18nMessage: "For optimal calling and voicemail please use the OnSIP mobile application",
    message: "For optimal calling and voicemail please use the OnSIP mobile application",
    displayIcon: "cancel",
    canEnable: true,
    buttonText: "Download mobile app",
    hideButtonText: "ONSIP_I18N.REMIND_ME_LATER",
    priority: 1
  },
  freeTrial: {
    name: "freeTrial",
    bgColor: "primary",
    i18nMessage: "Your free calling period ends soon. Need more time? Our Sales agents can help.",
    message: "Your free calling period ends soon. Need more time? Our Sales agents can help.",
    displayIcon: "cloud",
    canEnable: true,
    buttonText: "ONSIP_I18N.SPEAK_TO_AN_AGENT",
    hideButtonText: "ONSIP_I18N.HIDE",
    priority: 2
  },
  callSetup: {
    name: "callSetup",
    i18nMessage: "ONSIP_I18N.A_CALL_HAS_BEEN_INITIATED_FROM_THE_APP_PLEASE_ANSWER_YOUR_PHONE",
    message: "A call has been initiated from the app. Please answer your phone.",
    displayIcon: "cancel",
    canEnable: true,
    buttonText: "ONSIP_I18N.TURN_OFF",
    priority: 3
  },
  appCallDisabled: {
    name: "appCallDisabled",
    i18nMessage: "ONSIP_I18N.APP_CALLING_IS_CURRENTLY_DISABLED",
    message: "App calling is currently disabled.",
    displayIcon: "cancel",
    canEnable: true,
    buttonText: "ONSIP_I18N.TURN_ON",
    priority: 4
  },
  globalAvailability: {
    name: "globalAvailability",
    i18nMessage:
      "ONSIP_I18N.AWAY_YOU_WILL_NOT_RECEIVE_CALLS_ANYWHERE_THIS_INCLUDES_YOUR_DESK_PHONE",
    message: "Away: You will not receive calls anywhere. This includes your desk phone.",
    displayIcon: "watch_later",
    canEnable: true,
    buttonText: "ONSIP_I18N.MARK_AVAILABLE",
    priority: 5
  },
  approvedAudio: {
    name: "approvedAudio",
    i18nMessage: "ONSIP_I18N.AUDIO_IS_NOT_APPROVED_FOR_THIS_BROWSER",
    message: "Audio is not approved for this browser.",
    displayIcon: "",
    canEnable: true,
    buttonText: "ONSIP_I18N.ALLOW",
    priority: 6
  },
  canAutoAnswer: {
    name: "canAutoAnswer",
    i18nMessage: "ONSIP_I18N.AUTO_ANSWERING_IS_CURRENTLY_TURNED_ON",
    message: "Auto answering is currently turned on.",
    displayIcon: "phonelink_erase",
    canEnable: true,
    buttonText: "ONSIP_I18N.TURN_OFF",
    priority: 7
  },
  deniedNotification: {
    name: "deniedNotification",
    i18nMessage: "ONSIP_I18N.YOUR_BROWSER_SETTINGS_DO_NOT_PERMIT_NOTIFICATIONS",
    message: "Your browser settings do not permit notifications.",
    displayIcon: "portable_wifi_off",
    canEnable: false,
    priority: 8
  },
  doNotDisturb: {
    name: "doNotDisturb",
    i18nMessage:
      "ONSIP_I18N.DO_NOT_DISTURB_IS_ON_YOU_WILL_NOT_RECEIVE_CALLS_ON_THIS_SPECIFIC_DEVICE",
    message: "Do not disturb mode is enabled",
    displayIcon: "error",
    canEnable: true,
    buttonText: "ONSIP_I18N.TURN_OFF",
    priority: 9
  },
  E911Admin: {
    name: "E911Admin",
    i18nMessage: "ONSIP_I18N_DO_NOT_FORGET_ADD_E911",
    message: "You cannot currently make emergency (e911) calls.",
    displayIcon: "error",
    canEnable: true,
    buttonText: "ONSIP_I18N.ADD.E911",
    hideButtonText: "ONSIP_I18N.CLOSE",
    priority: 10
  },
  E911User: {
    name: "E911User",
    i18nMessage: "ONSIP_I18N_DO_NOT_FORGET_UPDATE_E911",
    message: "You cannot currently make emergency (e911) calls.",
    displayIcon: "error",
    buttonText: "ONSIP_I18N.MY_SETTINGS",
    canEnable: true,
    hideButtonText: "ONSIP_I18N.CLOSE",
    priority: 11
  },
  noE911AdminDesktop: {
    name: "noE911Admin",
    i18nMessage: "ONSIP_I18N.YOU_CANNOT_CURRENTLY_MAKE_EMERGENCY_E911_CALLS",
    message: "You cannot currently make emergency (e911) calls.",
    displayIcon: "cancel",
    canEnable: false,
    buttonText: "ONSIP_I18N.SET_UP_E911",
    hideButtonText: "ONSIP_I18N.CLOSE",
    priority: 12
  },
  noE911Admin: {
    name: "noE911Admin",
    i18nMessage: "ONSIP_I18N.YOU_CANNOT_CURRENTLY_MAKE_EMERGENCY_E911_CALLS",
    message: "You cannot currently make emergency (e911) calls.",
    displayIcon: "cancel",
    canEnable: true,
    buttonText: "ONSIP_I18N.SET_UP_E911",
    hideButtonText: "ONSIP_I18N.REMIND_ME_LATER",
    priority: 13
  },
  noE911User: {
    name: "noE911User",
    i18nMessage:
      "ONSIP_I18N.YOU_CANNOT_CURRENTLY_MAKE_EMERGENCY_E911_CALLS_CONTACT_YOUR_ADMIN_TO_ENABLE_E911_CALLING",
    message: "You cannot currently make emergency (e911) calls.",
    displayIcon: "cancel",
    canEnable: false,
    hideButtonText: "ONSIP_I18N.CLOSE",
    priority: 14
  }
};
