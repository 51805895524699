export interface ApiAgentTransaction {
  AgentId: string;
  AgentTransactionId: string;
  Amount: string;
  Created: string;
  Date: string;
  Description: string;
  Modified: string;
  /**
   * a - account assignment
   * r - account deassignment
   * d - debit
   * c - credit
   * p - payment
   */
  Type: "a" | "r" | "d" | "c" | "p";
  UserId: string;
}

export interface AgentTransaction {
  agentId: string;
  agentTransactionId: string;
  amount: string;
  created: string;
  date: string;
  description: string;
  modified: string;
  type: string;
  userId: string;
}

export enum AgentTransactionType {
  "a" = "account assignment",
  "r" = "account deassignment",
  "c" = "credit",
  "d" = "debit",
  "p" = "payment"
}

export function apiAgentTransactionToAgentTransaction(
  apiAgentTransaction: ApiAgentTransaction
): AgentTransaction {
  return {
    agentId: apiAgentTransaction.AgentId,
    agentTransactionId: apiAgentTransaction.AgentTransactionId,
    amount: apiAgentTransaction.Amount,
    created: apiAgentTransaction.Created,
    date: apiAgentTransaction.Date,
    description: apiAgentTransaction.Description,
    modified: apiAgentTransaction.Modified,
    type: AgentTransactionType[apiAgentTransaction.Type],
    userId: apiAgentTransaction.UserId
  };
}

export interface AgentTransactionParams {
  AgentId: string;
  Limit?: string;
  StartDateTime?: string;
  EndDateTime?: string;
  Type?: ApiAgentTransaction["Type"];
}
