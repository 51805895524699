import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CallControllerService } from "@onsip/common/services/call-controller.service";
import { views } from "@onsip/web/app/phone/views";

import { of } from "rxjs";

@Injectable({ providedIn: "root" })
export class CallResolver {
  constructor(private router: Router, private callController: CallControllerService) {}

  resolve() {
    const currentNavigation = this.router.getCurrentNavigation();
    if (!currentNavigation) throw new Error("StateResolverService: currentNavigation undefined");
    if (
      currentNavigation.trigger === "imperative" &&
      currentNavigation.extras.state &&
      currentNavigation.extras.state.callUuid
    ) {
      return of(currentNavigation.extras.state.callUuid as string);
    } else {
      const activeCall = this.callController.getUnheldCall();
      if (!activeCall) {
        this.router.navigate([views.HOME]);
        return of() as any; // navigate obviates this, but needed for typechecking
      }
      if (!activeCall.ended) return of(activeCall.uuid);
    }
  }
}
