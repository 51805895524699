import { Injectable, OnDestroy, Self } from "@angular/core";
import { StateEmitter } from "../../../common/libraries/emitter";

import { combineLatest, Subscription } from "rxjs";
import { filter, take, map, distinctUntilChanged } from "rxjs/operators";

import { CallHistoryCall, RecentCallDisposition, RecentCallDirection } from "./call-history";
import { IdentityService } from "../identity.service";
import { SubscriptionControllerService } from "../subscription-controller.service";
import { CallControllerService } from "../call-controller.service";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";

import {
  instanceOfExtendedDialogInfo,
  PersonalDialog
} from "../../../common/interfaces/presentity";
import { UserService } from "../api/resources/user/user.service";

interface CallHistoryDialogState {
  callHistoryDialogs: Array<CallHistoryCall>;
  currentDialogs: Array<PersonalDialog>;
}

@Injectable({ providedIn: "root" })
export class CallHistoryDialogService
  extends StateEmitter<CallHistoryDialogState>
  implements OnDestroy
{
  // HACKS To allow dialogs to be shown 3 seconds after terminated
  /** Record of all callIds on calls, do not show dialogs that are / were calls, until termination */
  private callIdLog: Record<string, boolean> = {};
  /** Record of terminated dialog cids, cid: true => fully terminated don't show, cid: false => 3 sec delay in progress */
  private terminatedDialogs: Record<string, boolean> = {};
  private unsubscriber = new Subscription();

  constructor(
    protected identity: IdentityService,
    // self here is needed. Not sure why but we want an instance of subscription controller service for dialog service only
    // otherwise dialog state is not updated correctly
    @Self() protected subscriptionService: SubscriptionControllerService,
    protected callControllerService: CallControllerService,
    protected userService: UserService
  ) {
    super({ callHistoryDialogs: [], currentDialogs: [] } as CallHistoryDialogState);
    this.unsubscriber.add(
      this.userService.selfUser
        .pipe(
          map(user => user.userId),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.stateStore.callHistoryDialogs = [];
          this.stateStore.currentDialogs = [];
          this.publishState();
        })
    );

    this.initDialogSubscription();
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  /** Subscription to maintain dialogList, dialogList contains dialogs matching the following criteria:
   *  1. PersonalDialogs belonging to one of users AORs
   *  2. Not a call-setup, moh, or x-conf dialog
   *  3. Not associated with a call unless call has been ended in last 3 seconds
   *  4. Not terminated unless terminated within the last 3 seconds
   */
  private initDialogSubscription(): void {
    // identity subscription, needed to know which aors to look at
    const uaAorObservable = this.identity.state.pipe(
      filter(state => state.addresses.length > 0),
      map(state => state.addresses.map(address => address.aor)),
      take(1)
    );
    this.unsubscriber.add(
      combineLatest([
        uaAorObservable,
        this.subscriptionService.state,
        this.callControllerService.state // no data is used but need the state updates to decide when to show dialog for ended
      ])
        .pipe(
          map(([aors, subscriptionState]) => {
            return subscriptionState.presentity
              .filter(pres => pres.event === "dialog" && aors.some(aor => pres.aor === aor)) // dialog for one of your aors,
              .map(pres => pres.eventData)
              .filter(instanceOfExtendedDialogInfo) // typeguard
              .map(eventData => eventData.dialogs)
              .reduce((acc, curr) => acc.concat(curr), []) // flatten to 1D list of PersonalDialogs
              .filter(
                dialog =>
                  !(
                    (dialog.remoteAor && dialog.remoteAor.indexOf("call-setup@onsip.com") >= 0) ||
                    !dialog.localAor ||
                    dialog.localAor.indexOf("call-setup@onsip.com") >= 0 ||
                    (dialog.remoteAor && dialog.remoteAor.indexOf("moh@") >= 0) ||
                    (dialog.localDisplayName && dialog.localDisplayName.startsWith("X-Conf"))
                  )
              ); // Not setup, moh, or conf
          })
        )
        .subscribe(dialogs => {
          // HACK to keep dialogs around for 3 seconds after termination
          dialogs.forEach(dialog => {
            if (!this.callIdLog[dialog.callId]) {
              const callState = this.callControllerService.getCallStateByCallId(dialog.callId);
              this.callIdLog[dialog.callId] = !!callState && !!callState.connectedAt;
            }
            if (dialog.priority <= 0 && this.terminatedDialogs[dialog.callId] === undefined) {
              // Skip 3 second delay if we never saw the dialog or call
              this.terminatedDialogs[dialog.callId] =
                !this.stateStore.currentDialogs.find(found => found.callId === dialog.callId) &&
                !this.callIdLog[dialog.callId];
              if (!this.terminatedDialogs[dialog.callId]) {
                if (!this.callIdLog[dialog.callId]) this.addDialogToCallHistory(dialog); // do this once per dialog when terminated
                setTimeout(() => {
                  this.terminatedDialogs[dialog.callId] = true;
                  // remove in case we don't get another state update
                  this.stateStore.currentDialogs = this.stateStore.currentDialogs.filter(
                    shownDialogs => !this.terminatedDialogs[shownDialogs.callId]
                  );
                  this.publishState();
                }, 3000);
              }
            }
          });

          // Filter out dialogs associated with ongoing calls, and those terminated > 3 seconds ago
          this.stateStore.currentDialogs = dialogs.filter(
            dialog =>
              !this.terminatedDialogs[dialog.callId] &&
              dialog.priority > 0 &&
              !this.callControllerService.getCallStateByCallId(dialog.callId)
          );
          this.publishState();
        })
    );
  }

  /** helper function in converting dialogs to CallHistoryCall object. handles the insertion of dialog in CallHistoryCall state */
  addDialogToCallHistory(dialog: PersonalDialog): void {
    const call = this.makeUsingDialog(dialog);
    if (call) {
      if (call.remoteUri.indexOf("moh@") > 0) return; // Do not add calls to moh (moh@<DOMAIN>)
      this.stateStore.callHistoryDialogs.unshift(call);
      this.publishState();
    }
  }

  /** converts dialog to CallHistoryCall object */
  private makeUsingDialog(dialog: PersonalDialog): CallHistoryCall | undefined {
    const disposition: RecentCallDisposition = dialog.confirmedTime
      ? RecentCallDisposition.ANSWERED
      : dialog.direction === "recipient"
      ? RecentCallDisposition.MISSED
      : RecentCallDisposition.CANCELLED;
    const remoteURI: OnSIPURI | undefined = OnSIPURI.parseString("sip:" + dialog.remoteAor),
      localURI: OnSIPURI | undefined = OnSIPURI.parseString("sip:" + dialog.localAor);

    const myUri = dialog.direction === "recipient" ? remoteURI : localURI,
      otherUri = dialog.direction === "recipient" ? localURI : remoteURI,
      otherName =
        dialog.direction === "recipient"
          ? dialog.localDisplayName ||
            (dialog.localAor && dialog.localAor.slice(0, dialog.localAor.indexOf("@"))) ||
            "unknown"
          : dialog.remoteDisplayName ||
            (dialog.remoteAor && dialog.remoteAor.slice(0, dialog.remoteAor.indexOf("@"))) ||
            "unknown";

    if (!myUri) return;

    return {
      callId: dialog.callId,
      callTime:
        (dialog.confirmedTime && new Date(dialog.confirmedTime).toISOString()) ||
        new Date().toISOString(),
      direction:
        dialog.direction === "recipient"
          ? RecentCallDirection.INBOUND
          : RecentCallDirection.OUTBOUND,
      disposition,
      duration: Date.now() - dialog.confirmedTime,
      localUri: myUri.toString(),
      ouid: "", // dialogs do not have ouids
      remoteName: otherName,
      remoteUri: otherUri ? otherUri.toString() : "unknown"
    };
  }
}
