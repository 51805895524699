import { SessionEventFactory } from "./session-event";

/**
 * Emitted once when the Session is deactivated just prior to being destroyed.
 */
export class LastSessionEvent {
  static make(aor: string, uuid: string) {
    return SessionEventFactory.make(LastSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] last";
  }
}
