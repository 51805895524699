import { Injectable } from "@angular/core";
import { ChatBody } from "./chat-body.service";

export interface ChatContact {
  id: string;
  displayName: string;
  email: string;
  presence: string;
  isDeleted: boolean;
  fullName: string;
  isSelf: boolean;
  chatBody?: ChatBody;
}

@Injectable({ providedIn: "root" })
export class ChatContactService {
  createChatContact(
    id: any,
    displayName: string,
    email: any,
    presence: any,
    options: any = {}
  ): ChatContact {
    options = options || {};

    return {
      id,
      displayName,
      email,
      presence,
      isDeleted: options.isDeleted || false,
      isSelf: false,
      fullName: options.fullName
    };
  }
}
