export enum Issuer {
  "None",
  "Visa",
  "MasterCard",
  "American Express",
  "Discover"
}

export enum CreditCardLogo {
  "None" = "",
  "Visa" = "resources/img/credit-card-logo/visa.svg",
  "MasterCard" = "resources/img/credit-card-logo/mastercard.svg",
  "American Express" = "resources/img/credit-card-logo/amex.svg",
  "Discover" = "resources/img/credit-card-logo/discover.svg"
}

export declare interface ApiCreditCard {
  AccountId: string;
  Address: string;
  City: string;
  CountryId: string;
  CreditCardId: string;
  CreditCardType: keyof typeof Issuer; // gets the enum keys as a type
  CreditCardTypeId: Issuer;
  Expiration: string;
  FirstName: string;
  IPAddress: string;
  LastFour: string;
  LastName: string;
  Modified: string;
  Name: string;
  Phone: string;
  State: string;
  Zipcode: string;
}

export declare interface CreditCard {
  accountId: string;
  address: string;
  city: string;
  countryId: string;
  creditCardId: string;
  creditCardType: keyof typeof Issuer;
  creditCardTypeId: Issuer;
  expiration: string;
  firstName: string;
  ipAddress: string;
  lastFour: string;
  lastName: string;
  name: string;
  phone: string;
  state: string;
  zipcode: string;
}

export function apiCreditCardToCreditCard(apiCreditCard: ApiCreditCard): CreditCard {
  return {
    accountId: apiCreditCard.AccountId,
    address: apiCreditCard.Address,
    city: apiCreditCard.City,
    countryId: apiCreditCard.CountryId,
    creditCardId: apiCreditCard.CreditCardId,
    creditCardType: apiCreditCard.CreditCardType,
    creditCardTypeId: apiCreditCard.CreditCardTypeId,
    expiration: formatCardExpiration(apiCreditCard.Expiration),
    firstName: apiCreditCard.FirstName,
    ipAddress: apiCreditCard.IPAddress,
    lastFour: apiCreditCard.LastFour,
    lastName: apiCreditCard.LastName,
    name: apiCreditCard.Name,
    phone: apiCreditCard.Phone,
    state: apiCreditCard.State,
    zipcode: apiCreditCard.Zipcode
  };
}

/** convert api expiration date format MM/YYYY to MM/YY */
function formatCardExpiration(date: string): string {
  const [month, year] = date.split("/");
  return [month, year.slice(2)].join("/");
}

/** parameters for adding or editing a credit card */
export declare interface CreditCardParameters {
  CCFirstName: string;
  CCLastName: string;
  CCName: string;
  CCTypeId: number;
  CCExpirationMonth: number;
  CCExpirationYear: number;
  CCNumber: string;
  CCCode: string;
  CCPhone: string;
  CCAddress: string;
  CCCity: string;
  CCState: string;
  CCZipcode: string;
}
