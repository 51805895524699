import { Injectable } from "@angular/core";
import { ApiBrowseAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { accountId } from "../../apiParams/account-id";
import { sessionId } from "../../apiParams/session-id";
import { OnsipApiResponse } from "../../apiResponse/response-body";
import { extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import {
  AgentTransaction,
  AgentTransactionParams,
  ApiAgentTransaction,
  apiAgentTransactionToAgentTransaction as clean
} from "./agent-transaction";

const debug = false;

@Injectable({ providedIn: "root" })
export class AgentTransactionService extends ApiResourceService<AgentTransaction> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Account", "agentTransactionId");
    debug && this.state.subscribe(state => console.warn("AgentTransactionService", state));
  }

  agentTransactionBrowse(params: AgentTransactionParams): ApiPromiseState<AgentTransaction> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AgentTransactionBrowse,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 25000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AgentTransactionBrowse);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.AgentTransactionBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiAgentTransaction>>(
              response,
              action,
              "AgentTransaction",
              "AgentTransactions"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
