<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="mat-typography"
  cdkConnectedOverlayBackdropClass="transparent-background"
  [cdkConnectedOverlayOpen]="isOpened"
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayPositions]="getPositions()"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayPositionStrategy]="positionStrategy"
  [cdkConnectedOverlayHasBackdrop]="hasBackdrop"
  (detach)="connectedOverlayDetach()"
  (backdropClick)="connectedOverlayDetach()"
>
  <div
    #content
    class="popup-content"
    [ngClass]="{ 'top-down': arrowPosition !== 'above', 'bottom-up': arrowPosition === 'above' }"
  >
    @if (arrowPosition !== 'above') {
    <div
      class="arrow-below-origin"
      [style.margin-right]="!arrowRightMargin ? 'auto' : arrowRightMargin"
    ></div>
    }
    <ng-content></ng-content>
    @if (arrowPosition === 'above') {
    <div class="arrow-above-origin"></div>
    }
  </div>
</ng-template>
