import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";
import { ApiAdvQueueEmailReport } from "./adv-queue-email-report";

export interface EmailReportObj {
  EmailReport: ApiAdvQueueEmailReport | Array<ApiAdvQueueEmailReport>;
}

export interface ApiAdvQueue {
  AdvQueueId: string;
  OrganizationId: string;
  AppId: string;
  AppAddress: string;
  AdvQueueLoginAppId: string;
  QueueName: string;
  MaxCaller: string;
  MaxAgent: string;
  CallerTimeout: string;
  AgentTimeout: string;
  RingOnly: OnsipAPIBoolean;
  InstaHoldUrl: string;
  Virtual: OnsipAPIBoolean;
  RingStrategy: string;
  WrapupTime: string;
  FailoverAddress: string;
  FailoverAddressId: string;
  EscapeAddress: string;
  EscapeAddressId: string;
  MemberDelay: string;
  ReportHoldTime: OnsipAPIBoolean;
  AnnounceHoldTime: OnsipAPIBoolean;
  AnnounceFrequency: string;
  AnnounceRecording: string;
  Username: string;
  Password: string;
  SmartQueue: OnsipAPIBoolean;
  MusicSourceId: string;
  MusicSourceName: string;
  MusicSourceUrl: string;
  StorageServiceId: string;
  StorageServiceName: string;
  StorageServiceService: string;
  RecordingTone: OnsipAPIBoolean;
  EmailReports: EmailReportObj;
}

export interface AdvQueue {
  advQueueId: string;
  organizationId: string;
  appId: string;
  appAddress: string;
  advQueueLoginAppId: string;
  queueName: string;
  maxCaller: string;
  maxAgent: string;
  callerTimeout: string;
  agentTimeout: string;
  ringOnly: boolean;
  instaHoldUrl: string;
  virtual: boolean;
  ringStrategy: string;
  wrapupTime: string;
  failoverAddress: string;
  failoverAddressId: string;
  escapeAddress: string;
  escapeAddressId: string;
  memberDelay: string;
  reportHoldTime: boolean;
  announceHoldTime: boolean;
  announceFrequency: string;
  announceRecording: string;
  username: string;
  password: string;
  smartQueue: boolean;
  musicSourceId: string;
  musicSourceName: string;
  musicSourceUrl: string;
  storageServiceId: string;
  storageServiceName: string;
  storageServiceService: string;
  recordingTone: boolean;
  emailReports: Array<ApiAdvQueueEmailReport>;
}

export function apiAdvQueueToAdvQueue(apiAdvQueue: ApiAdvQueue): AdvQueue {
  return {
    advQueueId: apiAdvQueue.AdvQueueId,
    organizationId: apiAdvQueue.OrganizationId,
    appId: apiAdvQueue.AppId,
    appAddress: apiAdvQueue.AppAddress,
    advQueueLoginAppId: apiAdvQueue.AdvQueueLoginAppId,
    queueName: apiAdvQueue.QueueName,
    maxCaller: apiAdvQueue.MaxCaller,
    maxAgent: apiAdvQueue.MaxAgent,
    callerTimeout: apiAdvQueue.CallerTimeout,
    agentTimeout: apiAdvQueue.AgentTimeout,
    ringOnly: onsipApiBooleanToBoolean(apiAdvQueue.RingOnly),
    instaHoldUrl: apiAdvQueue.InstaHoldUrl,
    virtual: onsipApiBooleanToBoolean(apiAdvQueue.Virtual),
    ringStrategy: apiAdvQueue.RingStrategy,
    wrapupTime: apiAdvQueue.WrapupTime,
    failoverAddress: apiAdvQueue.FailoverAddress,
    failoverAddressId: apiAdvQueue.FailoverAddressId,
    escapeAddress: apiAdvQueue.EscapeAddress,
    escapeAddressId: apiAdvQueue.EscapeAddressId,
    memberDelay: apiAdvQueue.MemberDelay,
    reportHoldTime: onsipApiBooleanToBoolean(apiAdvQueue.ReportHoldTime),
    announceHoldTime: onsipApiBooleanToBoolean(apiAdvQueue.AnnounceHoldTime),
    announceFrequency: apiAdvQueue.AnnounceFrequency,
    announceRecording: apiAdvQueue.AnnounceRecording,
    username: apiAdvQueue.Username,
    password: apiAdvQueue.Password,
    smartQueue: onsipApiBooleanToBoolean(apiAdvQueue.SmartQueue),
    musicSourceId: apiAdvQueue.MusicSourceId,
    musicSourceName: apiAdvQueue.MusicSourceName,
    musicSourceUrl: apiAdvQueue.MusicSourceUrl,
    storageServiceId: apiAdvQueue.StorageServiceId,
    storageServiceName: apiAdvQueue.StorageServiceName,
    storageServiceService: apiAdvQueue.StorageServiceService,
    recordingTone: onsipApiBooleanToBoolean(apiAdvQueue.RecordingTone),
    emailReports: Array.isArray(apiAdvQueue.EmailReports.EmailReport)
      ? apiAdvQueue.EmailReports.EmailReport
      : Object.values(apiAdvQueue.EmailReports)
  };
}
