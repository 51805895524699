import { SessionEventFactory, SessionEvent } from "./session-event";

/**
 * Emitted once when Session receives provisional response to an INVITE.
 */
export class ConnectingSessionEvent {
  static make(aor: string, uuid: string): SessionEvent {
    return SessionEventFactory.make(ConnectingSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] connecting";
  }
}
