import { NgModule } from "@angular/core";

import { SaysoInstallationComponent } from "./sayso-installation.component";
import { RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    component: SaysoInstallationComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)]
})
export class SaysoInstallationRoutingModule {}
