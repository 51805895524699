<mat-toolbar class="onsip-toolbar">
  <mat-toolbar-row class="onsip-header">
    @if (debug) {
    <div id="debug"></div>
    }
    <div class="onsip-left-nav">
      <img
        id="onsip-logo"
        [src]="
          (useSmallIcon | async)
            ? 'resources/img/onsip-blue-small.svg'
            : 'resources/img/onsip-blue.svg'
        "
        [routerLink]="views.AGENT_ACCOUNTS"
        alt="OnSIP"
      />
      <mat-divider class="divider" vertical></mat-divider>
      <div class="mat-headline-6">
        {{ hasParentUserId && parentUserRole === "Super User" ? "Super admin" : agentOrgName }}
      </div>
    </div>
    <onsip-status-menu></onsip-status-menu>
  </mat-toolbar-row>
  @if (isSmallOrXs) {
  <mat-toolbar-row class="account-details-toolbar-row">
    @if (hasParentUserId) {
    <div class="super-user-spoof-view-small">
      <div class="super-user-viewing-detail">
        <span class="mat-body-2">Viewing</span>
        <span class="mat-headline-6">{{ currOrgName }}</span>
      </div>
      <div>
        <button
          mat-stroked-button
          color="primary"
          (click)="backToAdmin()"
          class="back-to-search-button"
        >
          Back to Search
        </button>
      </div>
    </div>
    }
    <div class="details-flex">
      <div class="mat-subtitle-1 details-text">
        {{ hasParentUserId ? "Agent admin portal" : "Account details" }}
      </div>
      <mat-icon (click)="toggleExpandDetails()" color="primary" class="expand-button">{{
        detailsExpanded ? "expand_less" : "expand_more"
      }}</mat-icon>
    </div>
  </mat-toolbar-row>
  } @if (isSmallOrXs && detailsExpanded) {
  <mat-toolbar-row class="expanded-details-row">
    <div class="expanded-details">
      <div class="property">
        <div class="property-header mat-body-1">Account ID</div>
        <div class="mat-body-2">{{ agentAccount ? "#" + agentAccount.accountId : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Domain</div>
        <div class="mat-body-2">{{ domain }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Product code</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.productcode : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Agent ID</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.agentId : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Account admin</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.contact.name : "" }}</div>
      </div>
    </div>
  </mat-toolbar-row>
  } @if (isSmallOrXs) {
  <mat-toolbar-row class="shell-nav-toolbar-row">
    <onsip-shell-navigations [shellNavigations]="shellNavigations" [horizontalNav]="true">
    </onsip-shell-navigations>
  </mat-toolbar-row>
  }
</mat-toolbar>
<mat-sidenav-container cdkDropListGroup>
  @if (!isSmallOrXs) {
  <mat-sidenav
    onsip-sidenav-overflow
    class="side-nav"
    mode="side"
    [opened]="true"
    [fixedInViewport]="true"
    [fixedTopGap]="65"
  >
    @if (hasParentUserId) {
    <div class="super-user-spoof-view">
      <div>
        <button
          mat-stroked-button
          color="primary"
          (click)="backToAdmin()"
          class="back-to-search-button"
        >
          Back to Search
        </button>
      </div>
      <div class="super-user-viewing-detail">
        <span class="mat-body-2">Viewing</span>
        <span class="mat-headline-6">{{ currOrgName }}</span>
      </div>
      <div class="mat-subtitle-1 details-text">
        {{ hasParentUserId ? "Agent admin portal" : "Account details" }}
      </div>
    </div>
    }
    <div class="nav-section" [ngClass]="{ 'has-spoof-view': hasParentUserId }">
      <onsip-shell-navigations [shellNavigations]="shellNavigations"> </onsip-shell-navigations>
    </div>
    <mat-divider class="nav-divider"></mat-divider>
    <div class="info-section">
      <div class="property">
        <div class="property-header mat-body-1">Account ID</div>
        <div class="mat-body-2">{{ agentAccount ? "#" + agentAccount.accountId : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Domain</div>
        <div class="mat-body-2">{{ domain }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Product code</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.productcode : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Agent ID</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.agentId : "" }}</div>
      </div>
      <div class="property">
        <div class="property-header mat-body-1">Account admin</div>
        <div class="mat-body-2">{{ agentAccount ? agentAccount.contact.name : "" }}</div>
      </div>
    </div>
  </mat-sidenav>
  }
  <mat-sidenav-content #mainContent class="main-sidenav-content">
    <router-outlet></router-outlet>
    <onsip-footer [showAdminTabs]="true"></onsip-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
