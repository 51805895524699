import { Injectable } from "@angular/core";

import { take } from "rxjs/operators";

import { Tracker } from "../../../../../common/libraries/analytics/tracker";
import { FormEventData } from "../../../../../common/interfaces/analytics-form-event-data";
import { UserService, User } from "../../../../../common/services/api/resources/user/user.service";
import { Config } from "../../../../../common/config";
import { lastValueFrom } from "rxjs";

@Injectable({ providedIn: "root" })
export class FormsAnalyticsService {
  private analyticsStarted: Promise<void>;
  private user!: User;
  private tracker: Tracker;

  constructor(private userService: UserService) {
    this.tracker = new Tracker("forms", {
      schemas: [
        {
          name: "forms",
          schema: "iglu:com.onsip/forms/jsonschema/1-0-3",
          collector: "snowcat"
        }
      ]
    });

    this.analyticsStarted = lastValueFrom(this.userService.selfUser.pipe(take(1))).then(user => {
      // for use in snowplow analytics calls
      this.user = user;
      // Set User ID etc in snowplow so they are included in events fired this session
      this.tracker.setUserId(parseInt(user.userId));
    });
  }

  sendFormEvent(
    eventData: Omit<FormEventData, "user_id" | "organization_id" | "account_id" | "type"> // these fields are provided by this service, or hardcoded in
  ): void {
    this.analyticsStarted.then(() => {
      this.tracker.trackEvent("forms", {
        version: Config.VERSION_NUMBER,
        user_id: parseInt(this.user.userId),
        organization_id: parseInt(this.user.organizationId),
        account_id: parseInt(this.user.accountId),
        type: "forms",
        ...eventData
      });
    });
  }
}
