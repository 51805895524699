import { SessionEventFactory } from "./session-event";

/**
 * Emitted once when the Session received a REFER request.
 */
export class ReferredSessionEvent {
  static make(aor: string, uuid: string) {
    return SessionEventFactory.make(ReferredSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] referred";
  }
}
