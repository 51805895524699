// This is defined in the module already, but I am unsure how to port that to Angular2
// since we have to require these things at the top of the page.

export const pageSize = 12;
export const numPageLinks = 9; // the SdrBrowse limit will be set to (numPageLinks + 1) * pageSize in order to determine if more unbrowsed records exist
export const disposition = {
  ANSWERED: "Answered",
  CANCELLED: "Cancelled",
  MISSED: "Missed", // not in the set of SDR dispositions as it is inferred from call direction
  REJECTED: "Rejected",
  FAILED: "Failed",
  EXPIRED: "Expired" // "expired" calls were "answered" but never hung up by user agents (core removes them)
};
export const direction = {
  INBOUND: "Inbound",
  OUTBOUND: "Outbound"
};
/** sdr records are not immediately available. This is the time (in seconds) between
 * the end of session and when that session is included in the browse response.
 */
export const latency = 60;

/** sdr data before 2015 is not considered to be accurate */
export const minDate = new Date("2015-01-01T00:00:00+00:00");

/** We keep asking for SDRs month by month. If we get X months in a row that are empty we assume they have no more. */
export const maxEmptyApiCalls = 3;

/** Parameter we send to the API for SdrBrowse */
export const orderBy: "StartTime DESC, SdrId DESC" = "StartTime DESC, SdrId DESC";
