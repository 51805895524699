declare let window: any;
declare let navigator: any;

interface BrowserRule {
  name: string;
  rule: RegExp;
}

interface UpdateFunctionObject {
  audio: boolean;
  video: boolean;
  devices: object;
}

export class WebRTCSupport {
  private cameras = 0;
  private microphones = 0;
  private speakers = 0;
  private latestDevices = {};
  private initialSetup: Promise<void>;
  private updateFunction: ((obj: UpdateFunctionObject) => void) | undefined;

  // A reference version: http://faisalman.github.io/ua-parser-js/

  static parseUserAgent(userAgentString: string): { name: string; version: string } | undefined {
    const browsers = this.getBrowserRules();

    const detected =
      browsers
        .map(browser => {
          const match = browser.rule.exec(userAgentString);
          let version = match && match[1].split(/[._]/).slice(0, 3);

          if (version && version.length < 3) {
            version = version.concat(version.length === 1 ? ["0", "0"] : ["0"]);
          }

          return (
            match && {
              name: browser.name,
              version: version ? version.join(".") : ""
            }
          );
        })
        .filter(Boolean)[0] || undefined;

    return detected;
  }

  private static buildRules(ruleTuples: Array<[string, RegExp]>): Array<BrowserRule> {
    return ruleTuples.map(tuple => {
      return {
        name: tuple[0],
        rule: tuple[1]
      };
    });
  }

  private static getBrowserRules(): Array<BrowserRule> {
    return this.buildRules([
      ["aol", /AOLShield\/([0-9._]+)/],
      ["edge", /Edge\/([0-9._]+)/],
      ["yandexbrowser", /YaBrowser\/([0-9._]+)/],
      ["vivaldi", /Vivaldi\/([0-9.]+)/],
      ["kakaotalk", /KAKAOTALK\s([0-9.]+)/],
      ["samsung", /SamsungBrowser\/([0-9.]+)/],
      ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
      ["phantomjs", /PhantomJS\/([0-9.]+)(:?\s|$)/],
      ["crios", /CriOS\/([0-9.]+)(:?\s|$)/],
      ["firefox", /Firefox\/([0-9.]+)(?:\s|$)/],
      ["fxios", /FxiOS\/([0-9.]+)/],
      ["opera", /Opera\/([0-9.]+)(?:\s|$)/],
      ["opera", /OPR\/([0-9.]+)(:?\s|$)$/],
      ["ie", /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/],
      ["ie", /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
      ["ie", /MSIE\s(7\.0)/],
      ["bb10", /BB10;\sTouch.*Version\/([0-9.]+)/],
      ["android", /Android\s([0-9.]+)/],
      ["ios", /Version\/([0-9._]+).*Mobile.*Safari.*/],
      ["safari", /Version\/([0-9._]+).*Safari/],
      ["facebook", /FBAV\/([0-9.]+)/],
      ["instagram", /Instagram ([0-9.]+)/]
    ]);
  }

  constructor() {
    this.initialSetup = new Promise(resolve => {
      this.updateDeviceList().then(resolve);
    });

    if (navigator.mediaDevices && navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = () => {
        this.updateDeviceList().then(() => {
          this.cameras = 0;
          this.speakers = 0;
          this.microphones = 0;
          this.checkDeviceSupport().then(obj => this.updateFunction && this.updateFunction(obj));
        });
      };
    }
  }

  // later this can take arguments depending on what you want tested
  // for now there is only one set of tests to be done
  checkSupport(): boolean {
    return this.peerConnection() && this.websocket() && this.getUserMedia() && this.browser();
  }

  checkDeviceSupport(): Promise<UpdateFunctionObject> {
    return Promise.all([this.getMicrophones(), this.getSpeakers(), this.getCameras()]).then(
      results => {
        return {
          audio: results[0] && results[1],
          video: results[2],
          devices: this.latestDevices
        };
      }
    );
  }

  addUpdateFunction(update: (obj: UpdateFunctionObject) => void): void {
    this.updateFunction = update;
  }

  private updateDeviceList(): Promise<void> {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      return navigator.mediaDevices.enumerateDevices().then(this.afterEnumerateDevices);
    } else if (navigator.enumerateDevices) {
      return new Promise(resolve => {
        navigator.enumerateDevices((devices: Array<MediaDeviceInfo>) => {
          this.latestDevices = devices;
          this.afterEnumerateDevices(devices);
          resolve();
        });
      });
    }
    return Promise.resolve();
  }

  private afterEnumerateDevices(devices: Array<any>): void {
    for (const device of devices) {
      switch (device.kind) {
        case "videoinput":
          this.cameras++;
          break;
        case "audioinput":
          this.microphones++;
          break;
        case "audiooutput":
          this.speakers++;
          break;
      }
    }
  }

  private browser(): boolean {
    if (typeof navigator === "undefined") {
      return false;
    }

    const browserObj = WebRTCSupport.parseUserAgent(navigator.userAgent);
    if (browserObj === undefined) {
      return false;
    }

    let versionExp: RegExp | undefined;

    if (browserObj.name === "chrome") {
      /* Only Chrome 25 and up */
      versionExp = /^2[5-9]\.|^[3-9][0-9]/;
    } else if (browserObj.name === "firefox") {
      /* Only Firefox 23 and up */
      versionExp = /^2[3-9]\.|^[3-9][0-9]/;
    } else if (browserObj.name === "opera") {
      /* Only Opera 18 and up */
      versionExp = /^1[8-9]\.|^[2-9][0-9]/;
    } else if (browserObj.name === "edge") {
      /* NO versions of Edge */
      versionExp = undefined;
    } else if (browserObj.name === "ie") {
      /* No versions of IE */
      versionExp = undefined;
    } else if (browserObj.name === "safari" || browserObj.name === "ios") {
      versionExp = /^1[1-9]\.|^[0-9][0-9]/;
    }

    if (!versionExp) {
      return false;
    }

    return versionExp.test(browserObj.version);
  }

  private getUserMedia(): boolean {
    return !!(
      (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    );
  }

  private peerConnection(): boolean {
    return !!(
      window.webkitRTCPeerConnection ||
      window.mozRTCPeerConnection ||
      window.RTCPeerConnection
    );
  }

  private websocket(): boolean {
    return !!WebSocket;
  }

  private getCameras(): Promise<boolean> {
    return this.initialSetup.then(() => {
      return this.cameras > 0;
    });
  }

  private getMicrophones(): Promise<boolean> {
    return this.initialSetup.then(() => {
      return this.microphones > 0;
    });
  }

  private getSpeakers(): Promise<boolean> {
    return this.initialSetup.then(() => {
      return this.speakers > 0;
    });
  }
}
