import { Injectable, NgZone } from "@angular/core";

import { StateEmitterArray } from "../../libraries/emitter/state-emitter-array";

import { AuthService } from "./auth.service";
import { TopicPresence, TopicPresenceState } from "./lib/topic-presence";

@Injectable({ providedIn: "root" })
export class TopicsPresenceService extends StateEmitterArray<TopicPresence, TopicPresenceState> {
  constructor(private auth: AuthService, private zone: NgZone) {
    super();
  }

  addTopic(target: string): void {
    if (this.array.map(topic => topic.target).includes(target)) {
      return;
    }
    this.add(new TopicPresence(target, this.auth));
  }

  removeTopic(target: string): void {
    const topic = this.get(target);
    if (topic) {
      topic.dispose(); // will publish complete thus removing itself
    }
  }

  /**
   * Dispose (will complete).
   */
  dispose(): void {
    this.publishComplete();
  }
  /**
   * Adds an TopicPresence.
   * @param topic The TopicPresence to add.
   */
  add(topic: TopicPresence): this {
    return super.add(topic);
  }
  /**
   * Gets a TopicPresence.
   * @param target The target of the TopicPresence.
   */
  get(target: string): TopicPresence | undefined {
    return this.array.find(agent => agent.target === target);
  }
  /**
   * Removes a TopicPresence.
   * @param topic The TopicPresence to remove.
   */
  remove(topic: TopicPresence): boolean {
    return super.remove(topic);
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(fn);
  }
}
