import { ApiFullAddress } from "../../apiResponse/address";

export interface TelephoneNumberAddressParams {
  OrganizationId: string;
  Domain: string;
  Name: string;
  UserId: string;
  Username: string;
}

export interface TelephoneNumberAddressEditParams {
  Name?: string;
  UserId: string;
  /** new phone number */
  Username: string;
  /** old_phone_number@domain.onsip.com */
  Address: string;
}

export interface ApiTelephoneNumberAddress {
  Address: ApiFullAddress;
  UserId: string;
}

export interface TelephoneNumberAddress {
  addressId: string;
  cplText: string;
  created: string;
  domain: string;
  modified: string;
  name: string;
  username: string;
  userId: string;
}

export function ApiTelephoneNumberAddressToTelephoneNumberAddress(
  apiTelephoneNumber: ApiTelephoneNumberAddress
): TelephoneNumberAddress {
  return {
    addressId: apiTelephoneNumber.Address.AddressId,
    cplText: apiTelephoneNumber.Address.CplText,
    created: apiTelephoneNumber.Address.Created,
    domain: apiTelephoneNumber.Address.Domain,
    modified: apiTelephoneNumber.Address.Modified,
    name: apiTelephoneNumber.Address.Name,
    username: apiTelephoneNumber.Address.Username,
    userId: apiTelephoneNumber.UserId
  };
}
