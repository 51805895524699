import { Component } from "@angular/core";

@Component({
  selector: "onsip-e911-admin-modal",
  templateUrl: "./e911-admin-modal.component.html",
  styleUrls: ["./e911-admin-modal.component.scss"]
})
export class E911AdminModalComponent {
  readonly LINK = "https://support.onsip.com/hc/en-us/articles/360043689012-What-is-E911-";
  constructor() {}
}
