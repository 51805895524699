<mat-card>
  <mat-card-content>
    <form class="entry-form">
      <mat-card-title>{{ "ONSIP_I18N.TROUBLE_LOGGING_IN" | translate }}</mat-card-title>
      <mat-card-content>
        {{
          "ONSIP_I18N.FOR_MORE_ASSISTANCE_CLICK_ON_THE_BUTTON_BELOW_TO_CALL_OUR_SUPPORT_TEAM_OR_EMAIL"
            | translate
        }}
        <a class="help-link" href="mailto:support@onsip.com">support&#64;onsip.com </a>
        .
      </mat-card-content>
      <div class="entry-button-container">
        <button
          mat-button
          routerLink="/"
          routerLinkActive="selected"
          [skipLocationChange]="true"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          {{ "ONSIP_I18N.BACK" | translate }}
        </button>
        <button mat-raised-button color="primary" (click)="anonymousCallSupport()">
          {{ "ONSIP_I18N.CALL_SUPPORT" | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
