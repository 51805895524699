// Usage: all children of the element that has this directive become navigable by arrow keys
//        set attribute data-item-class if some children are to be excluded from the navigation

import { Directive, ElementRef, HostListener, OnInit, OnDestroy } from "@angular/core";

@Directive({
  selector: "[onsipArrowKeyNavigation]"
})
export class ArrowKeyNavigationDirective implements OnInit, OnDestroy {
  private domObserver: MutationObserver | undefined;
  private lastFocused: any;
  private el: any;

  constructor(element: ElementRef) {
    this.el = element.nativeElement;
  }

  @HostListener("keydown", ["$event"]) onKeyDown(ev: any): void {
    switch (ev.keyCode) {
      case 37: // arrow left
      case 38: // arrow up
        this.focusSibling(ev.target, "previousSibling");
        ev.preventDefault();
        break;

      case 39: // arrow right
      case 40: // arrow down
        if (ev.target === this.el) {
          this.focusFirstChild();
        } else {
          this.focusSibling(ev.target, "nextSibling");
        }
        ev.preventDefault();
        break;
    }
  }

  ngOnInit(): void {
    this.el.tabIndex = this.el.tabIndex >= 0 ? this.el.tabIndex : 0;

    Array.prototype.forEach.call(this.el.children, child => {
      child.tabIndex = -1;
    });

    this.el.addEventListener("focus", this.onFocus.bind(this));

    // watch for DOM changes
    if (MutationObserver) {
      this.domObserver = new MutationObserver(this.onDomChange);
      this.domObserver.observe(this.el, {
        childList: true,
        // documentation says these must be observed even though we don't need to watch for these changes
        attributes: true,
        characterData: true
      });
    }
  }

  ngOnDestroy(): void {
    if (this.domObserver) {
      this.domObserver.disconnect();
    }
    this.el.removeEventListener("keydown", this.onFocus);
  }

  onFocus(ev: any): void {
    const relatedTarget: any = ev.relatedTarget || ev.explicitOriginalTarget;
    // relatedTarget = the element that was blurred before the focus event. It distinguishes actual tabbing into
    // behavior from anything else that may fire a focus event.
    // FF does not set the related target attribute: https://bugzilla.mozilla.org/show_bug.cgi?id=962251
    // explicitOriginalTarget is a non standard FF implementation and so we can use it here to the same effect (for now)
    if (this.el && this.el.children.length && relatedTarget && this.el !== relatedTarget) {
      if (!this.el.contains(relatedTarget)) {
        if (this.lastFocused && this.isNavigable(this.lastFocused)) {
          this.focus(this.lastFocused);
        } else {
          this.focusFirstChild();
        }
      } else if (ev.explicitOriginalTarget && this.el.contains(ev.explicitOriginalTarget)) {
        this.focus(ev.explicitOriginalTarget);
      }
    }
  }

  isNavigable(child: any): boolean {
    return child && child.nodeType === 1 && this.isVisible(child) && !child.disabled;
  }

  isVisible(el: any): boolean {
    // eslint-disable-next-line no-null/no-null
    return el.offsetParent !== null;
  }

  focus(el: any): void {
    if (el !== this.lastFocused) {
      if (this.lastFocused) {
        el.tabIndex = this.lastFocused.tabIndex;
        this.lastFocused.tabIndex = -1;
      } else {
        el.tabIndex = this.el.tabIndex;
        this.el.tabIndex = -1;
      }
      this.lastFocused = el;
    }
    el.focus();
  }

  private focusFirstChild(): void {
    let firstChild: any = this.el.children.item(0);
    // eslint-disable-next-line no-null/no-null
    while (!this.isNavigable(firstChild) && firstChild !== null) {
      firstChild = firstChild.nextSibling;
    }

    if (firstChild) {
      this.focus(firstChild);
    }
  }

  private focusSibling(child: any, siblingProperty: string): void {
    let sibling: any = child[siblingProperty];
    // eslint-disable-next-line no-null/no-null
    while (!this.isNavigable(sibling) && sibling !== null) {
      sibling = sibling[siblingProperty];
    }

    if (sibling) {
      this.focus(sibling);
    }
  }

  private onDomChange(mutations: Array<MutationRecord>): void {
    mutations.forEach(mutationRecord => {
      if (mutationRecord.type === "childList") {
        Array.prototype.forEach.call(mutationRecord.addedNodes, child => {
          child.tabIndex = -1;
        });
        Array.prototype.forEach.call(mutationRecord.removedNodes, child => {
          if (child === this.lastFocused) {
            this.el.tabIndex = 0;
          }
        });
      }
    });
  }
}
