import { ValidatorFn, AbstractControl } from "@angular/forms";
import { ContactService } from "../../../../common/services/contact/contact.service";

/**
 * Validates uniqueness of custom contact namme
 * @param service Pass instance of ContactService for lookup
 * @param excludeUuid Optionally exclude a uuid validator will not fail against itself
 */
export function customNameValidator(service: ContactService, excludeUuid?: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const customName: string = control.value;
    if (excludeUuid !== undefined) {
      return service.stateValue.contacts
        .filter(c => c.uuid !== excludeUuid)
        .find(c => c.name === customName)
        ? { invalidName: { name: control.value } }
        : // eslint-disable-next-line no-null/no-null
          null;
    } else {
      // eslint-disable-next-line no-null/no-null
      return service.findUsingName(customName) ? { invalidName: { name: control.value } } : null;
    }
  };
}
