import { SubscriptionEventFactory } from "./subscription-event";

/**
 * Emitted once when the Subscription is deactivated just prior to being destroyed.
 */
export class LastSubscriptionEvent {
  static make(aor: string, uuid: string) {
    return SubscriptionEventFactory.make(LastSubscriptionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Subscription] last";
  }
}
