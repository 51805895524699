@for (_portal of portals; track _portal; let i = $index) { @if ((currentFrame | async) === i) {
<div [@fade]>
  <ng-template [cdkPortalOutlet]="portals[i]"></ng-template>
  <!-- ngFor seems to munge the portal object - work with indices instead -->
</div>
} }
<div class="dot-container">
  @for (_portal of portals; track _portal; let i = $index) {
  <div class="dot" [class.primary-500-bg]="(currentFrame | async) === i"></div>
  }
</div>
