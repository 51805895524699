/**
 * Enums of all api actions. Except for in the soon to be deprecated api.service,
 * ApiAction strings should never be called explictly, but rather added to these enums
 * and used from ApiAction
 */

export enum ApiEditAction {
  "AdvConferenceEdit" = "AdvConferenceEdit",
  "AdvDbnDirectoryEdit" = "AdvDbnDirectoryEdit",
  "AccountEditAddCredit" = "AccountEditAddCredit",
  "AccountEditContact" = "AccountEditContact",
  "AccountEditCreditCard" = "AccountEditCreditCard",
  "AccountEditRecharge" = "AccountEditRecharge",
  "AccountEditUnlimitedUserPricing" = "AccountEditUnlimitedUserPricing",
  "AccountResourceEditLimit" = "AccountResourceEditLimit",
  "AdvAnnouncementEdit" = "AdvAnnouncementEdit",
  "AdvQueueEmailReportEdit" = "AdvQueueEmailReportEdit",
  "TimeSwitchAddressEdit" = "TimeSwitchAddressEdit",
  "TimeSwitchAddressEditUsername" = "TimeSwitchAddressEditUsername",
  "AdvQueueEdit" = "AdvQueueEdit",
  "AdvQueueWarningEdit" = "AdvQueueWarningEdit",
  "AdvVoicemailboxEdit" = "AdvVoicemailboxEdit",
  "AdvVoicemailboxInboxEdit" = "AdvVoicemailboxInboxEdit",
  "AdvAttendantMenuEdit" = "AdvAttendantMenuEdit",
  "AdvParkEdit" = "AdvParkEdit",
  "AppAddressEdit" = "AppAddressEdit",
  "ConferenceBridgeAddressEdit" = "ConferenceBridgeAddressEdit",
  "ConferenceBridgeEdit" = "ConferenceBridgeEdit",
  "DidEdit" = "DidEdit",
  "DidEditName" = "DidEditName",
  "DidEditNote" = "DidEditNote",
  "E911LocationEdit" = "E911LocationEdit",
  "ExternalAddressEdit" = "ExternalAddressEdit",
  "GatewayUserEditCallerId" = "GatewayUserEditCallerId",
  "GroupAddressEdit" = "GroupAddressEdit",
  "GroupAddressEditAddMember" = "GroupAddressEditAddMember",
  "GroupAddressEditRemoveMember" = "GroupAddressEditRemoveMember",
  "InboundBridgeAddressEdit" = "InboundBridgeAddressEdit",
  "MohAddressEdit" = "MohAddressEdit",
  "MusicSourceEdit" = "MusicSourceEdit",
  "MusicSourceOrganizationDefault" = "MusicSourceOrganizationDefault",
  "OrganizationCustomizationAvatarEdit" = "OrganizationCustomizationAvatarEdit",
  "OrganizationCustomizationEdit" = "OrganizationCustomizationEdit",
  "OrganizationEditBusyLampField" = "OrganizationEditBusyLampField",
  "OrganizationEditCallerId" = "OrganizationEditCallerId",
  "OrganizationEditContact" = "OrganizationEditContact",
  "OrganizationEditE911Provisioning" = "OrganizationEditE911Provisioning",
  "RecordingEdit" = "RecordingEdit",
  "RecordingRuleEdit" = "RecordingRuleEdit",
  "RecordingManagementAddressEdit" = "RecordingManagementAddressEdit",
  "PhoneEdit" = "PhoneEdit",
  "StorageServiceEdit" = "StorageServiceEdit",
  "TelephoneNumberAddressEdit" = "TelephoneNumberAddressEdit",
  "TelephoneNumberAddressIdentityEdit" = "TelephoneNumberAddressIdentityEdit",
  "UserAddressEdit" = "UserAddressEdit",
  "UserAddressEditDefaultAddress" = "UserAddressEditDefaultAddress",
  "UserAddressEditDoNotDisturb" = "UserAddressEditDoNotDisturb",
  "UserBatchEditTeamPageVisibility" = "UserBatchEditTeamPageVisibility",
  "UserCustomizationAvatarEdit" = "UserCustomizationAvatarEdit",
  "UserCustomizationEdit" = "UserCustomizationEdit",
  "UserCustomizationOnboardingEdit" = "UserCustomizationOnboardingEdit",
  "UserCustomizationPersonalPageInfoEdit" = "UserCustomizationPersonalPageInfoEdit",
  "UserCustomizationSaysoEdit" = "UserCustomizationSaysoEdit",
  "UserEditContact" = "UserEditContact",
  "UserEditE911Provisioning" = "UserEditE911Provisioning",
  "UserEditExposePii" = "UserEditExposePii",
  "UserEditNoOutgoingPstn" = "UserEditNoOutgoingPstn",
  "UserEditPortalPassword" = "UserEditPortalPassword",
  "UserEditPSTNTrunking" = "UserEditPSTNTrunking",
  "UserEditQueueEvent" = "UserEditQueueEvent",
  "UserEditRoleSubmit" = "UserEditRoleSubmit",
  "UserEditStatus" = "UserEditStatus",
  "UserEditTeamPageVisibility" = "UserEditTeamPageVisibility",
  "VoicemailManagementAddressEdit" = "VoicemailManagementAddressEdit",
  "WebCallTopicEdit" = "WebCallTopicEdit",
  "WebCallTopicEditAllFilters" = "WebCallTopicEditAllFilters"
}

export enum ApiAddAction {
  "AdvAnnouncementAdd" = "AdvAnnouncementAdd",
  "AdvConferenceAdd" = "AdvConferenceAdd",
  "AdvDbnDirectoryAdd" = "AdvDbnDirectoryAdd",
  "TimeSwitchAddressAdd" = "TimeSwitchAddressAdd",
  "AdvQueueAdd" = "AdvQueueAdd",
  "AdvQueueEmailReportAdd" = "AdvQueueEmailReportAdd",
  "AdvQueueWarningAdd" = "AdvQueueWarningAdd",
  "AdvVoicemailboxAdd" = "AdvVoicemailboxAdd",
  "AdvAttendantMenuAdd" = "AdvAttendantMenuAdd",
  "AdvParkAdd" = "AdvParkAdd",
  "AgentAddAccount" = "AgentAddAccount",
  "BlockedE164NumberAdd" = "BlockedE164NumberAdd",
  "ConferenceBridgeAddressAdd" = "ConferenceBridgeAddressAdd",
  "ConferenceBridgeAdd" = "ConferenceBridgeAdd",
  "E911LocationAdd" = "E911LocationAdd",
  "E911LocationAddWithoutOrgId" = "E911LocationAddWithoutOrgId",
  "ExternalAddressAdd" = "ExternalAddressAdd",
  "GroupAddressAdd" = "GroupAddressAdd",
  "InboundBridgeAddressAdd" = "InboundBridgeAddressAdd",
  "MusicSourceAdd" = "MusicSourceAdd",
  "PhoneAdd" = "PhoneAdd",
  "OrganizationAdd" = "OrganizationAdd",
  "DidRequest" = "DidRequest",
  "RecordingAdd" = "RecordingAdd",
  "RecordingRuleAdd" = "RecordingRuleAdd",
  "RecordingManagementAddressAdd" = "RecordingManagementAddressAdd",
  "StorageServiceAdd" = "StorageServiceAdd",
  "SubAgentAccountAdd" = "SubAgentAccountAdd",
  "TelephoneNumberAddressAdd" = "TelephoneNumberAddressAdd",
  "TelephoneNumberAddressIdentityAdd" = "TelephoneNumberAddressIdentityAdd",
  "UserAdd" = "UserAdd",
  "UserAddressAdd" = "UserAddressAdd",
  "UserAliasAdd" = "UserAliasAdd",
  "UserOAuth2AccessTokenAdd" = "UserOAuth2AccessTokenAdd",
  "WebCallTopicAdd" = "WebCallTopicAdd"
}

export enum ApiReadAction {
  "AccountRead" = "AccountRead",
  "AccountResourceRead" = "AccountResourceRead",
  "AccountInvoiceRead" = "AccountInvoiceRead",
  "AdvAnnouncementRead" = "AdvAnnouncementRead",
  "AdvConferenceRead" = "AdvConferenceRead",
  "AdvDbnDirectoryRead" = "AdvDbnDirectoryRead",
  "TimeSwitchAddressRead" = "TimeSwitchAddressRead",
  "AdvQueueRead" = "AdvQueueRead",
  "AdvVoicemailboxInboxRead" = "AdvVoicemailboxInboxRead",
  "AdvVoicemailboxRead" = "AdvVoicemailboxRead",
  "AppAddressRead" = "AppAddressRead",
  "AdvAttendantMenuRead" = "AdvAttendantMenuRead",
  "AdvParkRead" = "AdvParkRead",
  "BlockedE164NumberRead" = "BlockedE164NumberRead",
  "ConferenceBridgeAddressRead" = "ConferenceBridgeAddressRead",
  "ConferenceBridgeRead" = "ConferenceBridgeRead",
  "CountryRead" = "CountryRead",
  "E911LocationRead" = "E911LocationRead",
  "EmailReportRead" = "EmailReportRead",
  "GroupAddressRead" = "GroupAddressRead",
  "GatewayUserRead" = "GatewayUserRead",
  "InboundBridgeAddressRead" = "InboundBridgeAddressRead",
  "MohAddressRead" = "MohAddressRead",
  "MusicSourceRead" = "MusicSourceRead",
  "MusicSourceAddressAssignmentRead" = "MusicSourceAddressAssignmentRead",
  "NoAuthUserSummaryRead" = "NoAuthUserSummaryRead",
  "NoAuthOrganizationSummaryRead" = "NoAuthOrganizationSummaryRead",
  "OrganizationCustomizationAvatarRead" = "OrganizationCustomizationAvatarRead",
  "OrganizationCustomizationRead" = "OrganizationCustomizationRead",
  "OrganizationRead" = "OrganizationRead",
  "OrganizationMigrateDomain" = "OrganizationMigrateDomain",
  "RecordingRead" = "RecordingRead",
  "RecordingRuleRead" = "RecordingRuleRead",
  "RecordingManagementAddressRead" = "RecordingManagementAddressRead",
  "StorageServiceRead" = "StorageServiceRead",
  "TelephoneNumberAddressIdentityRead" = "TelephoneNumberAddressIdentityRead",
  "TermsAndConditionsRead" = "TermsAndConditionsRead",
  "UserCustomizationAvatarRead" = "UserCustomizationAvatarRead",
  "UserCustomizationRead" = "UserCustomizationRead",
  "UserGoogleFirebaseTokenRead" = "UserGoogleFirebaseTokenRead",
  "UserRead" = "UserRead",
  "UserReadAccountDetails" = "UserReadAccountDetails",
  "UserSummaryRead" = "UserSummaryRead",
  "WebCallTopicRead" = "WebCallTopicRead"
}

export enum ApiBrowseAction {
  "AccountBrowse" = "AccountBrowse",
  "AccountInvoiceBrowse" = "AccountInvoiceBrowse",
  "AccountResourceBrowse" = "AccountResourceBrowse",
  "AddressSummaryBrowse" = "AddressSummaryBrowse",
  "AdvAnnouncementBrowse" = "AdvAnnouncementBrowse",
  "AdvConferenceBrowse" = "AdvConferenceBrowse",
  "AdvDbnDirectoryBrowse" = "AdvDbnDirectoryBrowse",
  "AgentTransactionBrowse" = "AgentTransactionBrowse",
  "BusinessHoursRuleBrowse" = "TimeSwitchAddressBrowse",
  "AdvQueueBrowse" = "AdvQueueBrowse",
  "AdvQueueEmailReportBrowse" = "EmailReportBrowse",
  "AdvQueueWarningBrowse" = "AdvQueueWarningBrowse",
  "AdvAttendantMenuBrowse" = "AdvAttendantMenuBrowse",
  "AdvVoicemailboxBrowse" = "AdvVoicemailboxBrowse",
  "AdvParkBrowse" = "AdvParkBrowse",
  "AppAddressBrowse" = "AppAddressBrowse",
  "BlockedE164NumberBrowse" = "BlockedE164NumberBrowse",
  "Cdr2Browse" = "Cdr2Browse",
  "CdrSummary" = "CdrSummary",
  "ConferenceBridgeAddressBrowse" = "ConferenceBridgeAddressBrowse",
  "ConferenceBridgeBrowse" = "ConferenceBridgeBrowse",
  "CountryBrowse" = "CountryBrowse",
  "DidBrowse" = "DidBrowse",
  "NpaNxxBrowse" = "NpaNxxBrowse",
  "E164CountryCodeBrowse" = "E164CountryCodeBrowse",
  "E911LocationBrowse" = "E911LocationBrowse",
  "ExternalAddressBrowse" = "ExternalAddressBrowse",
  "ExtensionBrowse" = "ExtensionBrowse",
  "GroupAddressBrowse" = "GroupAddressBrowse",
  "InboundBridgeAddressBrowse" = "InboundBridgeAddressBrowse",
  "InstacallActionHourlyTotalBrowse" = "InstacallActionHourlyTotalBrowse",
  "MohAddressBrowse" = "MohAddressBrowse",
  "MusicSourceBrowse" = "MusicSourceBrowse",
  "MusicStreamSourceBrowse" = "MusicStreamSourceBrowse",
  "NoAuthUserSummaryBrowse" = "NoAuthUserSummaryBrowse",
  "OrganizationBrowse" = "OrganizationBrowse",
  "PackageBrowseAssigned" = "PackageBrowseAssigned",
  "PackageBrowseComplementary" = "PackageBrowseComplementary",
  "PhoneBrowse" = "PhoneBrowse",
  "PrepaidTransactionBrowse" = "PrepaidTransactionBrowse",
  "QueueAgentEventBrowse" = "QueueAgentEventBrowse",
  "QueueAgentSummaryBrowse" = "QueueAgentSummaryBrowse",
  "QueueCallerEventBrowse" = "QueueCallerEventBrowse",
  "QueueSummaryBrowse" = "QueueSummaryBrowse",
  "RecordingBrowse" = "RecordingBrowse",
  "RecordingRuleBrowse" = "RecordingRuleBrowse",
  "RecordingManagementAddressBrowse" = "RecordingManagementAddressBrowse",
  "RegistrationBrowse" = "RegistrationBrowse",
  "SdrBrowse" = "SdrBrowse",
  "StorageServiceBrowse" = "StorageServiceBrowse",
  "SubAgentAccountBrowse" = "SubAgentAccountBrowse",
  "TelephoneNumberAddressBrowse" = "TelephoneNumberAddressBrowse",
  "TelephoneNumberAddressIdentityBrowse" = "AddressIdentityBrowse",
  "UserBrowse" = "UserBrowse",
  "UserAddressBrowse" = "UserAddressBrowse",
  "UserAddressRegistrationBrowse" = "UserAddressRegistrationBrowse",
  "UserAliasBrowse" = "UserAliasBrowse",
  "UserCustomizationBrowse" = "UserCustomizationBrowse",
  "UserOAuth2AccessTokenBrowse" = "UserOAuth2AccessTokenBrowse",
  "UserSummaryBrowse" = "UserSummaryBrowse",
  "UserSummaryContactBrowse" = "UserSummaryContactBrowse",
  "VoicemailManagementAddressBrowse" = "VoicemailManagementAddressBrowse",
  "WebCallBrowse" = "WebCallBrowse",
  "WebCallTopicBrowse" = "WebCallTopicBrowse",
  "WebComponentFileBrowse" = "WebComponentFileBrowse"
}

export enum ApiDeleteAction {
  "AdvAnnouncementDelete" = "AdvAnnouncementDelete",
  "AdvConferenceDelete" = "AdvConferenceDelete",
  "AdvDbnDirectoryDelete" = "AdvDbnDirectoryDelete",
  "AdvQueueDelete" = "AdvQueueDelete",
  "AdvQueueWarningDelete" = "AdvQueueWarningDelete",
  "AdvVoicemailboxDelete" = "AdvVoicemailboxDelete",
  "AdvVoicemailboxInboxDelete" = "AdvVoicemailboxInboxDelete",
  "AdvVoicemailboxInboxDeleteAll" = "AdvVoicemailboxInboxDeleteAll",
  "AdvAttendantMenuDelete" = "AdvAttendantMenuDelete",
  "AdvParkDelete" = "AdvParkDelete",
  "BlockedE164NumberDelete" = "BlockedE164NumberDelete",
  "ConferenceBridgeAddressDelete" = "ConferenceBridgeAddressDelete",
  "ConferenceBridgeDelete" = "ConferenceBridgeDelete",
  "E911LocationDelete" = "E911LocationDelete",
  "EmailReportDelete" = "EmailReportDelete",
  "ExternalAddressDelete" = "ExternalAddressDelete",
  "GroupAddressDelete" = "GroupAddressDelete",
  "InboundBridgeAddressDelete" = "InboundBridgeAddressDelete",
  "MusicSourceDelete" = "MusicSourceDelete",
  "UserDelete" = "UserDelete",
  "DidDelete" = "DidDelete",
  "PhoneDelete" = "PhoneDelete",
  "RecordingDelete" = "RecordingDelete",
  "RecordingRuleDelete" = "RecordingRuleDelete",
  "RecordingManagementAddressDelete" = "RecordingManagementAddressDelete",
  "StorageServiceDelete" = "StorageServiceDelete",
  "SubAgentAccountDelete" = "SubAgentAccountDelete",
  "TimeSwitchAddressDelete" = "TimeSwitchAddressDelete",
  "TelephoneNumberAddressDelete" = "TelephoneNumberAddressDelete",
  "UserAddressDelete" = "UserAddressDelete",
  "UserAliasDelete" = "UserAliasDelete",
  "UserOAuth2AccessTokenDelete" = "UserOAuth2AccessTokenDelete",
  "WebCallTopicDelete" = "WebCallTopicDelete",
  "WebComponentFileDelete" = "WebComponentFileDelete"
}

export enum ApiGenericAction {
  "AccountInvoicePretend" = "AccountInvoicePretend",
  "AdvQueueWarningUnsubscribe" = "AdvQueueWarningUnsubscribe",
  "AdvVoicemailboxUnavailableGreetingUpload" = "AdvVoicemailboxUnavailableGreetingUpload",
  "AdvVoicemailboxNameGreetingUpload" = "AdvVoicemailboxNameGreetingUpload",
  "AdvVoicemailboxTempGreetingUpload" = "AdvVoicemailboxTempGreetingUpload",
  "CallHangup" = "CallHangup",
  "CallSetup" = "CallSetup",
  "CallTransfer" = "CallTransfer",
  "CdrSummaryStatistic" = "CdrSummaryStatistic",
  "DidRequestFillPoolDid" = "DidRequestFillPoolDid",
  "DidRequestWithoutFill" = "DidRequestWithoutFill",
  "E911LocationAssign" = "E911LocationAssign",
  "E911LocationDeassign" = "E911LocationDeassign",
  "HelpAddSupportCase" = "HelpAddSupportCase",
  "MfaConfirm" = "MfaConfirm",
  "MusicSourceAddressAssign" = "MusicSourceAddressAssign",
  "NoOpAudit" = "NoOpAudit",
  "OAuth2SignupAccount" = "OAuth2SignupAccount",
  "OrganizationConfigurationFileSet" = "OrganizationConfigurationFileSet",
  "PackageAssign" = "PackageAssign",
  "PackageDeassign" = "PackageDeassign",
  "PhoneAssignAddress" = "PhoneAssignAddress",
  "PhoneAssignAddressBulk" = "PhoneAssignAddressBulk",
  "PhoneDeassignAddress" = "PhoneDeassignAddress",
  "PhoneRemoveEncryptionKey" = "PhoneRemoveEncryptionKey",
  "ProvideFeedback" = "ProvideFeedback",
  "RecordingUpload" = "RecordingUpload",
  "ResetPasswordUsingEmailKey" = "ResetPasswordUsingEmailKey",
  "SaysoFailoverAlert" = "SaysoFailoverAlert",
  "SaysoVerifySnippet" = "SaysoVerifySnippet",
  "SendEmail" = "SendEmail",
  "SendPasswordResetEmail" = "SendPasswordResetEmail",
  "SessionCreate" = "SessionCreate",
  "SignupCheckVerification" = "SignupCheckVerification",
  "SessionRecreate" = "SessionRecreate",
  "SessionRestoreUser" = "SessionRestoreUser",
  "SessionSubstituteUser" = "SessionSubstituteUser",
  "SignupAccountConfirm" = "SignupAccountConfirm",
  "SignupSendUserEmail" = "SignupSendUserEmail",
  "SignupValidateDomain" = "SignupValidateDomain",
  "StorageServiceSendCredentialsErrorEmail" = "StorageServiceSendCredentialsErrorEmail",
  "TelephoneNumberAddressIdentityDelete" = "TelephoneNumberAddressIdentityDelete",
  "UserAckHostedE911" = "UserAckHostedE911",
  "UserAckHostedTerms" = "UserAckHostedTerms",
  "UserBulkValidate" = "UserBulkValidate",
  "UserOAuth2AccessTokenClaim" = "UserOAuth2AccessTokenClaim",
  "UserOAuth2AccessTokenProxy" = "UserOAuth2AccessTokenProxy",
  "UserOAuth2AccessTokenVerify" = "UserOAuth2AccessTokenVerify",
  "OAuth2SessionCreate" = "OAuth2SessionCreate",
  "OAuth2SessionReplaceUser" = "OAuth2SessionReplaceUser",
  "VerifiedSignupFreemiumAccount" = "VerifiedSignupFreemiumAccount",
  "ZendeskGetTickets" = "ZendeskGetTickets"
}

export enum ApiUpdateAction {
  "Reset" = "Reset"
}

export const ApiAction = {
  ...ApiEditAction,
  ...ApiAddAction,
  ...ApiReadAction,
  ...ApiBrowseAction,
  ...ApiDeleteAction,
  ...ApiGenericAction,
  ...ApiUpdateAction
};

export type ApiActionType =
  | ApiEditAction
  | ApiAddAction
  | ApiReadAction
  | ApiBrowseAction
  | ApiDeleteAction
  | ApiGenericAction
  | ApiUpdateAction;
