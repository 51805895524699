@switch (dept) { @case ('sales') { @if (mediumOrBigger | async) {
<img [src]="'resources/img/contact-onsip-maps/sales.svg'" alt="OnSIP Sales Map" />
}
<h1>{{ "ONSIP_I18N.CONTACT_ONSIP_SALES" | translate }}</h1>
<h4>
  {{
    "ONSIP_I18N.OUR_US_BASED_TEAM_IS_ON_HAND_TO_DISCUSS_FEATURES_PRICING_AND_ANYTHING_ELSE_YOU_NEED_CLICK_TO_CALL_OR"
      | translate
  }}
  <a href="mailto:sales@onsip.com" target="_blank" class="primary-500">{{
    "ONSIP_I18N.SEND_US_AN_EMAIL" | translate
  }}</a>
</h4>
} @case ('support') { @if (mediumOrBigger | async) {
<img [src]="'resources/img/contact-onsip-maps/support.svg'" alt="OnSIP Support Map" />
}
<h1>{{ "ONSIP_I18N.NEED_HELP" | translate }}</h1>
<h4>
  {{
    "ONSIP_I18N.OUR_AWARDWINNING_US_BASED_SUPPORT_TEAM_IS_JUST_A_CLICK_AWAY_AND_AVAILABLE_TO_TALK_RIGHT_NOW_JUST_CLICK_THE_BUTTON_BELOW_TO_START_A_CALL_OR"
      | translate
  }}
  <a href="mailto:support@onsip.com" target="_blank" class="primary-500">{{
    "ONSIP_I18N.SUBMIT_A_TICKET" | translate
  }}</a>
</h4>
} }
<div>
  <!--  wrapping div needed to prevent button from taking up entire grid column span -->
  <button
    mat-flat-button
    color="primary"
    aria-label="start voice call"
    id="onsip--contact-onsip--voice-call"
    onsipNewCall
    remoteAor="{{ dept }}@onsip.com"
    [contact]="contact"
  >
    <mat-icon>phone</mat-icon>
    {{ "ONSIP_I18N.VOICE_CALL" | translate }}
  </button>
  @if (dept === 'support') {
  <button
    mat-flat-button
    color="primary"
    aria-label="start video call"
    id="onsip--contact-onsip-video-call"
    onsipNewCall
    remoteAor="{{ dept }}@onsip.com"
    [contact]="contact"
    [video]="true"
  >
    <mat-icon>videocam</mat-icon>
    {{ "ONSIP_I18N.VIDEO_CALL" | translate }}
  </button>
  }
</div>
@switch (dept) { @case ('sales') {
<p class="mat-body-2">
  {{ "ONSIP_I18N.OPERATING_HOURS_MONDAY__FRIDAY_9_AM__6_PM_EASTERN" | translate }}
</p>
} @case ('support') {
<p class="mat-body-2">
  {{
    "ONSIP_I18N.OPERATING_HOURS_MONDAY__THURSDAY_9_AM__8_PM_EASTERN_FRIDAY_9_AM__6_PM_EASTERN"
      | translate
  }}
</p>
} } @if (dept === 'support') {
<div class="onsip-support-faq">
  <mat-divider onsip-dotted-divider></mat-divider>
  <div class="onsip-support-faq-body">
    <h2>{{ "ONSIP_I18N.FREQUENTLY_ASKED_QUESTIONS" | translate }}</h2>
    <ul class="onsip-support-faq-list">
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/360026198551-SMS-Text-Messaging"
          target="_blank"
        >
          {{ "ONSIP_I18N.SMS_TEXT_MESSAGING" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/360015918652-Unable-to-Access-Phone-s-Web-Interface"
          target="_blank"
        >
          {{ "ONSIP_I18N.UNABLE_TO_ACCESS_PHONES_WEB_INTERFACE" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/115003351352-How-to-Recover-a-SIP-Address"
          target="_blank"
        >
          {{ "ONSIP_I18N.HOW_TO_RECOVER_A_SIP_ADDRESS" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/115002597511-Mimecast-email-blocking"
          target="_blank"
        >
          {{ "ONSIP_I18N.MIMECAST_EMAIL_BLOCKING" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/115001747892-VoIP-Test"
          target="_blank"
        >
          {{ "ONSIP_I18N.VOIP_TEST" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/235429248-Encryption-Policy"
          target="_blank"
        >
          {{ "ONSIP_I18N.ENCRYPTION_POLICY" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/208821458-When-trying-to-create-or-edit-an-App-or-Resource-nothing-happens"
          target="_blank"
        >
          {{
            "ONSIP_I18N.WHEN_TRYING_TO_CREATE_OR_EDIT_AN_APP_OR_RESOURCE_NOTHING_HAPPENS"
              | translate
          }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/203687604-VoIP-Security-"
          target="_blank"
        >
          {{ "ONSIP_I18N.VOIP_SECURITY" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/203687594-Toll-Free-Call-Rejection"
          target="_blank"
        >
          {{ "ONSIP_I18N.TOLL_FREE_CALL_REJECTION" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/204012180-Supported-Codecs-"
          target="_blank"
        >
          {{ "ONSIP_I18N.SUPPORTED_CODECS" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/204012170-Reliability-and-Quality-of-Service"
          target="_blank"
        >
          {{ "ONSIP_I18N.RELIABILITY_AND_QUALITY_OF_SERVICE" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/204012160-How-to-Receive-Network-Alert-Notifications"
          target="_blank"
        >
          {{ "ONSIP_I18N.HOW_TO_RECEIVE_NETWORK_ALERT_NOTIFICATIONS" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/203687584-Getting-Listed-in-the-White-Pages"
          target="_blank"
        >
          {{ "ONSIP_I18N.GETTING_LISTED_IN_THE_WHITE_PAGES" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/204304084-What-to-do-when-an-employee-leaves"
          target="_blank"
        >
          {{ "ONSIP_I18N.WHAT_TO_DO_WHEN_AN_EMPLOYEE_LEAVES" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/203687564-External-Number-Roll-Over"
          target="_blank"
        >
          {{ "ONSIP_I18N.EXTERNAL_NUMBER_ROLL_OVER" | translate }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/204012100-Calls-Go-Straight-To-Voicemail-Even-Though-the-Phone-Is-Connected-"
          target="_blank"
        >
          {{
            "ONSIP_I18N.CALLS_GO_STRAIGHT_TO_VOICEMAIL_EVEN_THOUGH_THE_PHONE_IS_CONNECTED"
              | translate
          }}</a
        >
      </li>
      <li>
        <a
          href="https://support.onsip.com/hc/en-us/articles/203687514-Calling-Canadian-Cell-Phones-and-Getting-a-Busy-Signal-"
          target="_blank"
        >
          {{ "ONSIP_I18N.CALLING_CANADIAN_CELL_PHONES_AND_GETTING_A_BUSY_SIGNAL" | translate }}</a
        >
      </li>
    </ul>
    <span class="mat-body-1">
      {{ "ONSIP_I18N.DONT_SEE_WHAT_YOU_NEED" | translate }}
      <a href="https://support.onsip.com" target="_blank">{{
        "ONSIP_I18N.VISIT_OUR_KNOWLEDGE_BASE" | translate
      }}</a>
    </span>
  </div>
</div>
}
