/**
 * Defines a Session event.
 */
export interface SessionEvent {
  id: string; // event id
  aor: string; // session aor
  uuid: string; // session uuid
  reason?: string | undefined; // optional reason for event
  uri?: string | undefined; // optional uri (for REFER)
  replaces?: string | undefined; // optional replaces header (for REFER)
}

export class SessionEventFactory {
  static make(
    id: string,
    aor: string,
    uuid: string,
    reason?: string,
    uri?: string,
    replaces?: string
  ): SessionEvent {
    const event: SessionEvent = {
      id,
      aor,
      uuid
    };
    if (reason) {
      event.reason = reason;
    }
    if (uri) {
      event.uri = uri;
    }
    if (replaces) {
      event.replaces = replaces;
    }
    return event;
  }
}

/**
 * User-Defined Type Guard for SessionEvent
 * @param event Event to check
 */
export function isSessionEvent(event: any): event is SessionEvent {
  if (event && event.id) {
    const id = event.id;
    if (typeof id === "string") {
      return id.startsWith("[Session]");
    }
  }
  return false;
}
