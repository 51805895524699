import { CommonModule, DatePipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { SidebarModule } from "../shared/components/sidebar/sidebar.module";
import { AgentAdminViewComponent } from "./agent-admin-view.component";
import { ShellNavigationsModule } from "@onsip/web/features/shared/components/shellNavigations/shell-navigations.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MaterialModule } from "@onsip/web/modules/material/material.module";
import { AgentAdminAccountsPageComponent } from "./agentAccountsPage/agent-accounts-page.component";
import { AgentAdminCommissionsPageComponent } from "./agentCommissionsPage/agent-commissions-page.component";
import { AgentAdminSupportPageComponent } from "./agentSupportPage/agent-support-page.component";
import { AgentAdminAccountsShelfComponent } from "./agentAccountsPage/agentAccountsShelf/agent-accounts-shelf.component";
import { AgentAdminAccountsCreateAccountComponent } from "./agentAccountsPage/agentCreateAccount/agent-accounts-create-account.component";
import { AgentAdminRoutingModule } from "./agent-admin-routing.module";
import { SuperAdminToolbarModule } from "../phone/superAdminToolbar/super-admin-toolbar.module";
import { LayoutModule } from "../shared/components/layout/layout.module";
import { TableModule } from "../shared/components/table/onsip-table.module";
import { TableDataLoadContainerModule } from "../shared/components/tableDataLoadContainer/table-data-load-container.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OnsipFormsModule } from "../shared/components/forms/onsip-forms.module";
import { FormEventTrackingModule } from "../shared/components/analytics/form-event-tracking.module";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { AdminSupportModule } from "../adminSupport/admin-support.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { OnsipFooterModule } from "../phone/footer/footer.module";
import { AgentAdminCommissionsShelfComponent } from "./agentCommissionsPage/agentCommissionsShelf/agent-commissions-shelf.component";

@NgModule({
  imports: [
    AgentAdminRoutingModule,
    AdminSupportModule,
    CommonModule,
    BrowserModule,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    SidebarModule,
    ShellNavigationsModule,
    TranslateModule,
    MatCardModule,
    MaterialModule,
    NewCallModule,
    SuperAdminToolbarModule,
    LayoutModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    TableModule,
    TableDataLoadContainerModule,
    ReactiveFormsModule,
    OnsipFormsModule,
    FormsModule,
    FormEventTrackingModule,
    OnsipFooterModule
  ],
  declarations: [
    AgentAdminViewComponent,
    AgentAdminAccountsPageComponent,
    AgentAdminCommissionsPageComponent,
    AgentAdminSupportPageComponent,
    AgentAdminAccountsShelfComponent,
    AgentAdminAccountsCreateAccountComponent,
    AgentAdminCommissionsShelfComponent
  ],
  providers: [DatePipe]
})
export class AgentAdminModule {}
