export declare const enum Role {
  SuperUser = "Super User",
  SystemAdmin = "System Admin",
  AccountAdmin = "Account Admin",
  OrganizationAdmin = "Organization Admin",
  User = "User",
  Anonymous = "Anonymous",
  AgentAdmin = "Agent Admin",
  OrganizationViewer = "Organization Viewer",
  SubAgentAdmin = "Sub-Agent Admin"
}

export const adminRoles: Array<Role> = [
  Role.SuperUser,
  Role.SystemAdmin,
  Role.AgentAdmin,
  Role.AccountAdmin,
  Role.OrganizationAdmin,
  Role.SubAgentAdmin
];

export function isAdminRole(roles: Array<Role>): boolean {
  return roles.some(role => adminRoles.includes(role));
}

export function isAtLeastAccountAdminRole(roles: Array<Role>): boolean {
  const higherAdminRoles = adminRoles.filter(role => role !== Role.OrganizationAdmin);
  return roles.some(role => higherAdminRoles.includes(role));
}

export function isSuperUser(roles: Array<Role>): boolean {
  return hasRole(Role.SuperUser, roles);
}

export function isAgentAdmin(roles: Array<Role>): boolean {
  return hasRole(Role.AgentAdmin, roles);
}

export function isSubAgentAdmin(roles: Array<Role>): boolean {
  return hasRole(Role.SubAgentAdmin, roles);
}

export function isQueueSupervisor(roles: Array<Role>): boolean {
  return hasRole(Role.OrganizationViewer, roles);
}

// helper function
function hasRole(checkRole: Role, roles: Array<Role>): boolean {
  return roles.some(role => role === checkRole);
}

/** we will be referring to Super User, Agent Admin, SubAgentAdmin, and System Admin as Super Admins in this repo
 * Not to be confused with super user which has the highest of priority
 */
export function isSuperAdmin(roles: Array<Role>): boolean {
  const superAdmins = adminRoles.filter(
    role => role !== Role.AccountAdmin && role !== Role.OrganizationAdmin
  );
  return roles.some(role => superAdmins.includes(role));
}

export function getHighestRolePriority(roles: Array<Role>): Role {
  if (isSuperUser(roles)) {
    return Role.SuperUser;
  } else if (isAgentAdmin(roles)) {
    return Role.AgentAdmin;
  } else if (isSubAgentAdmin(roles)) {
    return Role.SubAgentAdmin;
  } else if (isAtLeastAccountAdminRole(roles)) {
    return Role.AccountAdmin;
  } else if (isAdminRole(roles)) {
    return Role.OrganizationAdmin;
  } else if (hasRole(Role.OrganizationViewer, roles)) {
    return Role.OrganizationViewer;
  } else {
    return Role.User;
  }
}
