import { Injectable } from "@angular/core";
import { lastValueFrom, Subject } from "rxjs";
import { filter, take } from "rxjs/operators";
import { ApiActionType } from "./api-actions";
import { IApiError } from "./onsip-api-action";

const debug = false;

export type ApiPromiseSubject<T> =
  | { status: "success"; action: ApiActionType; data: Record<string, T> }
  | { status: "error"; action: ApiActionType; data: IApiError };

export type ApiPromiseState<T> = Promise<ApiPromiseSubject<T>>;

/**
 * The ApiPromiseStateService is created in order to get immediate results after an api call and allows to perform
 * promise chaining so we can accurate call one action after another. With the ability to store the api results, we
 * can use those results as conditionals or as parameters for the next api action. With this promise structure, we can abandoned
 * the unreliable usage of rxjs pipe method to chain results as it was convoluted and not intuitive for readers to pick up

 * The service houses a single subject that listens to every mergeStateUpdate and mergeErrorUpdate
 * from the ApiStateStoreService. From these updates, we get either a data payload, properly formatted by its respective third tier service,
 * or an error object that contains the code and message from the backend.
 * The object stored contains the status of the api action, whether it was successful or not, the api action name, and the data payload/error object.
 * We relay this object back to the third tier service using a toPromise method which uses the builtin toPromise method on observables
 * and return the latest state that matches the given api action
 */
@Injectable({ providedIn: "root" })
export class ApiPromiseStateService {
  private readonly stateSubject = new Subject<ApiPromiseSubject<any>>();

  constructor() {
    debug && this.stateSubject.subscribe(state => console.warn(state));
  }

  /**
   * updates the promise subject with the latest api results
   * @param newState promise object coming from mergeStateUpdate or mergeErrorUpdate
   */
  updateState<T>(newState: ApiPromiseSubject<T>): void {
    this.stateSubject.next(newState);
  }

  /**
   * take the latest state that matches the given api action and return state as a promise
   * @param apiActionName given api action name from the third tier service methods
   * @returns ApiPromise<T>
   */
  toPromise<T>(apiActionName: ApiActionType): ApiPromiseState<T> {
    debug && console.warn(apiActionName);
    return lastValueFrom(
      this.stateSubject.pipe(
        filter(state => state.action === apiActionName),
        take(1)
      )
    );
  }
}
