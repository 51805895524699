import { SubscriptionEvent, SubscriptionEventFactory } from "./subscription-event";

/**
 * Emitted once when Subscription is connected.
 */
export class ConnectedSubscriptionEvent {
  static make(aor: string, uuid: string): SubscriptionEvent {
    return SubscriptionEventFactory.make(ConnectedSubscriptionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Subscription] connected";
  }
}
