import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";
import { ApiContact, Contact, apiContactToContact } from "../../apiResponse/contact";

export declare interface ApiOrganization {
  AccountId: string;
  Authenticated: OnsipAPIBoolean;
  BusyLampField: OnsipAPIBoolean;
  CallerId: string;
  CallerIdName: string;
  CallerIdNumber: string;
  Contact: ApiContact;
  Created: string;
  Domain: string;
  E911Provisioning: OnsipAPIBoolean;
  ExtendedDialing: OnsipAPIBoolean;
  Modified: string;
  OrganizationId: string;
  QueueEvent: OnsipAPIBoolean;
  RecordingRule: OnsipAPIBoolean;
  RegEvent: OnsipAPIBoolean;
  RegistrationLimit: string;
  SaysoBilling: OnsipAPIBoolean;
  Service: string;
  Status: string;
  TeamPage: OnsipAPIBoolean;
  TeamPageDomain: string;
}

export declare interface Organization {
  accountId: string;
  authenticated: boolean;
  busyLampField: boolean;
  callerId: string;
  callerIdName: string;
  callerIdNumber: string;
  contact: Contact;
  created: string;
  domain: string;
  e911Provisioning: boolean;
  extendedDialing: boolean;
  modified: string;
  organizationId: string;
  queueEvent: boolean;
  recordingRule: boolean;
  regEvent: boolean;
  registrationLimit: string;
  saysoBilling: boolean;
  service: string;
  status: string;
  teamPage: boolean;
  teamPageDomain: string;
}

export interface OrganizationAddParams {
  Address: string;
  City: string;
  Company: string;
  CountryId: string;
  Domain: string;
  Email: string;
  Name: string;
  Password: string;
  PbxType: OrganizationPbxTypes;
  Phone: string;
  State: string;
  UserRole?: string;
  Username: string;
  Zipcode: string;
}

export enum OrganizationPbxTypes {
  HOSTED = "hosted",
  PLATFORM = "platform",
  TRUNKING = "trunking"
}

export function ApiOrganizationToOrganization(apiOrg: ApiOrganization): Organization {
  return {
    accountId: apiOrg.AccountId,
    authenticated: onsipApiBooleanToBoolean(apiOrg.Authenticated),
    busyLampField: onsipApiBooleanToBoolean(apiOrg.BusyLampField),
    callerId: apiOrg.CallerId,
    callerIdName: apiOrg.CallerIdName,
    callerIdNumber: apiOrg.CallerIdNumber,
    contact: apiContactToContact(apiOrg.Contact),
    created: apiOrg.Created,
    domain: apiOrg.Domain,
    e911Provisioning: onsipApiBooleanToBoolean(apiOrg.E911Provisioning),
    extendedDialing: onsipApiBooleanToBoolean(apiOrg.ExtendedDialing),
    modified: apiOrg.Modified,
    organizationId: apiOrg.OrganizationId,
    queueEvent: onsipApiBooleanToBoolean(apiOrg.QueueEvent),
    recordingRule: onsipApiBooleanToBoolean(apiOrg.RecordingRule),
    regEvent: onsipApiBooleanToBoolean(apiOrg.RegEvent),
    registrationLimit: apiOrg.RegistrationLimit,
    saysoBilling: onsipApiBooleanToBoolean(apiOrg.SaysoBilling),
    service: apiOrg.Service,
    status: apiOrg.Status,
    teamPage: onsipApiBooleanToBoolean(apiOrg.TeamPage),
    teamPageDomain: apiOrg.TeamPageDomain
  };
}

export interface EditCallerIdParams {
  CallerIdName: string;
  CallerIdNumber: string;
  Cascade: boolean;
}
