import { Injectable } from "@angular/core";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiWebCall, WebCall, apiWebCallToWebCall as clean } from "./web-call";
import { ApiResourceService } from "../api-resource.service";
import { ParameterValue } from "../../util/api-action-description";
import { ApiBrowseAction } from "../../api-actions";
import { sessionId } from "../../apiParams/session-id";

import * as callHistoryConfig from "../../../callHistory/call-history-config";
import { getApiActionName } from "../../onsip-api-action-new";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { userId } from "../../apiParams/user-id";
import { organizationId } from "../../apiParams/organization-id";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { WebCall };

const debug = false;

@Injectable({ providedIn: "root" })
export class WebCallService extends ApiResourceService<WebCall> {
  /** the default number of webcall fetches we do per browse, ie. 120 web calls */
  private defaultLimit = (callHistoryConfig.numPageLinks + 1) * callHistoryConfig.pageSize;
  private defaultHost = "GoogleFirestore";
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "WebCall", "webCallId");
    debug && this.state.subscribe(state => console.warn("WebCallService", state));
  }

  webCallBrowse(extraParameters: Record<string, ParameterValue> = {}): ApiPromiseState<WebCall> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.WebCallBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: this.defaultLimit,
        Host: this.defaultHost,
        Descending: "true",
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.WebCallBrowse);
  }

  webCallBrowseWithOuid(
    Ouid: string,
    extraParameters: Record<string, ParameterValue> = {}
  ): ApiPromiseState<WebCall> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.WebCallBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Ouid,
        Limit: 1,
        Host: this.defaultHost,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.WebCallBrowse);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.WebCallBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiWebCall>>(response, action, "WebCall", "WebCalls").map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
