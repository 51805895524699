import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ApiPromiseSubject } from "@onsip/common/services/api/api-promise-state.service";
import { User, UserService } from "@onsip/common/services/api/resources/user/user.service";
import { isAtLeastAccountAdminRole } from "@onsip/common/services/api/role";
import { BehaviorSubject, Subscription } from "rxjs";
import { LoadingModalComponent } from "../../shared/components/loadingModal/loading-modal.component";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { AdvancedFormGroupType } from "../superUserAdvancedSearch/super-user-advanced-search-options";
import {
  SuperUserTableData,
  SuperUserTableType
} from "../superUserTable/super-user-table.component";

type FilterOption = "Account ID" | "User" | "Domain";
type ResultOption = "user" | "org" | "account";

@Component({
  selector: "onsip-super-user-search",
  templateUrl: "./super-user-search.component.html",
  styleUrls: ["./super-user-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperUserSearchComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  showAdvancedSearch = false;

  filterOptions: Array<FilterOption> = ["Account ID", "User", "Domain"];
  formGroup = this.fb.group({
    filterSelect: [<FilterOption>"Account ID"],
    searchQuery: ["", Validators.required]
  });

  tableData = new BehaviorSubject<Array<SuperUserTableData>>([]);
  tableHasFetched = new BehaviorSubject(false);
  tableType!: SuperUserTableType;
  isLoading = new BehaviorSubject(false);
  savedAdvancedForm!: AdvancedFormGroupType;

  private loadingModal!: MatDialogRef<LoadingModalComponent>;
  private unsubscriber = new Subscription();

  constructor(
    private fb: NonNullableFormBuilder,
    private userService: UserService,
    private snackbar: SnackbarService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.unsubscriber.add(
      this.isLoading.subscribe(loading => {
        if (loading) {
          this.loadingModal = this.dialog.open(LoadingModalComponent, {
            data: { modalTitle: "Preparing your results..." }
          });
        } else {
          if (this.loadingModal) this.loadingModal.close();
        }
      })
    );

    this.unsubscriber.add(
      this.formGroup.controls.filterSelect.valueChanges.subscribe(() => {
        this.formGroup.controls.searchQuery.reset();
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  submit() {
    if (this.formGroup.valid) {
      this.isLoading.next(true);
      const filterOption: FilterOption = this.formGroup.controls.filterSelect.value;
      const searchQuery = this.formGroup.controls.searchQuery.value;
      // All searches via the radio buttons are user browses just with preset parameters, eg. searching with account id means, filter for users with that account id
      switch (filterOption) {
        case "User":
          this.handleUserSearch(searchQuery);
          break;
        case "Account ID":
          this.handleAccountIdSearch(searchQuery);
          break;
        case "Domain":
          this.handleDomainSearch(searchQuery);
          break;
      }
    }
  }

  openAdvancedSearch(): void {
    this.showAdvancedSearch = true;
    this.formGroup.disable();
    this.formGroup.controls.searchQuery.reset();
  }

  handleCloseSearch(
    event?: [Array<SuperUserTableData>, SuperUserTableType, AdvancedFormGroupType]
  ): void {
    if (event) {
      const [tableData, tableType, formValue] = event;
      this.tableData.next(tableData);
      this.tableType = tableType;
      this.tableHasFetched.next(true);
      this.savedAdvancedForm = formValue;
    }
    this.showAdvancedSearch = false;
    this.formGroup.enable();
    this.formGroup.controls.searchQuery.markAsUntouched();
  }

  private handleUserSearch(name: string): void {
    this.userService
      .userBrowse({
        SearchField: "Name, string",
        SearchCompOp: "Contains",
        SearchString: name,
        OrganizationId: undefined,
        Limit: 500
      })
      .then(result => {
        this.handleResult(result, "user");
      });
  }

  private handleAccountIdSearch(AccountId: string): void {
    this.userService
      .userBrowse({
        SearchField: "AccountId, int",
        SearchCompOp: "Equals",
        SearchString: AccountId,
        // organizationId need to be undefined to search for org outside that account
        OrganizationId: undefined,
        Limit: 500
      })
      .then(result => {
        this.handleResult(result, "account");
      });
  }

  private handleDomainSearch(domain: string): void {
    this.userService
      .userBrowse({
        SearchField: "Domain, string",
        SearchCompOp: "Contains",
        SearchString: domain,
        OrganizationId: undefined,
        Limit: 500
      })
      .then(result => {
        this.handleResult(result, "org");
      });
  }

  private handleResult(result: ApiPromiseSubject<User>, type: ResultOption): void {
    if (result.status === "success") {
      const dataArray = Object.values(result.data);
      const filteredArray = this.filterData(dataArray, type);
      this.tableData.next(filteredArray);
      this.tableType = type;
      this.isLoading.next(false);
      this.tableHasFetched.next(true);
      if (dataArray.length >= 500) {
        this.snackbar.openSnackBar(
          "Showing the maximum of 500 results. For better results, try refining your search.",
          "error"
        );
      }
    } else {
      const errMsg = result.data.message;
      this.snackbar.openSnackBar(
        `There was an issue with your search parameter: ${errMsg}`,
        "error"
      );
      this.userService.clearErrors();
    }
  }

  private filterData(users: Array<User>, type: ResultOption): Array<SuperUserTableData> {
    return users.map(user => {
      switch (type) {
        case "user":
          return {
            contactName: user.contact.name,
            userId: user.userId,
            status: user.status,
            accountId: user.accountId,
            contactOrg: user.contact.organization,
            isAdmin: isAtLeastAccountAdminRole(user.roles)
          };
        case "account":
          return {
            accountId: user.accountId,
            contactName: user.contact.name,
            userId: user.userId,
            status: user.status,
            contactOrg: user.contact.organization,
            isAdmin: isAtLeastAccountAdminRole(user.roles)
          };
        case "org":
          return {
            domain: user.domain,
            contactName: user.contact.name,
            userId: user.userId,
            status: user.status,
            accountId: user.accountId,
            contactOrg: user.contact.organization,
            isAdmin: isAtLeastAccountAdminRole(user.roles)
          };
      }
    });
  }
}
