import { CallEvent, EventId } from "./call-event";

/** Emitted once when the call first received a provisional response other than 100 Trying - epoch time in milliseconds. */
export class ProgressCallEvent extends CallEvent {
  id = EventId.ProgressCallEventId;

  /**
   * Factory for ProgressCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isProgressCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for ProgressCallEvent.
 * @param event Event to check.
 */
export function isProgressCallEvent(event: any): event is ProgressCallEvent {
  return event instanceof ProgressCallEvent;
}
