import { log } from "../log";

/** CallController event kind constant. */
const CallControllerEventKind = "[CallController]";

// CallController event id consts constants
export enum EventId {
  ResetCallControllerEventId = "[CallController] reset",
  CallkitTimeoutEventId = "[CallController] callkit timeout",
  CallkitAnswerTimeoutEventId = "[CallController] callkit answer timeout",
  CallkitTransactionFailedEventId = "[CallController] callkit transaction failed"
}

/** CallController event interface. */
export class CallControllerEvent {
  /** The event identifier. Unique per event type. */
  kindCallControllerEvent: typeof CallControllerEventKind = CallControllerEventKind;
  /** The event id. */
  id: any;
  /** The cause for the event */
  cause?: string;
  /**
   * Factory for CallControllerEvent.
   * @param guard The type gaurd for the event.
   * @param id The id of the event.
   */
  constructor(guard: (event: CallControllerEvent) => event is CallControllerEvent) {
    if (!guard(this)) {
      const message = `CallControllerEvent.make validation failure creating ${(this as any).id}`;
      log.error(message);
      throw new Error(message);
    }
  }
}

/**
 * User-Defined Type Guard for CallControllerEvent.
 * @param event Event to check.
 */
export function isCallControllerEvent(event: any): event is CallControllerEvent {
  if (
    !event ||
    event.kindCallControllerEvent !== CallControllerEventKind ||
    typeof event.id !== "string"
  ) {
    return false;
  }
  return event.id.startsWith(CallControllerEventKind);
}
