import { Injectable, OnDestroy } from "@angular/core";
import { StateEmitter } from "../../libraries/emitter";
import { Subscription, Observable, combineLatest } from "rxjs";
import { pluck, distinctUntilChanged, map } from "rxjs/operators";

import { SdrService, Sdr } from "../api/resources/sdr/sdr.service";
import { UserService } from "../api/resources/user/user.service";

import { OnSIPURI } from "../../libraries/onsip-uri";
import * as callHistoryConfig from "./call-history-config";
import { CallHistoryCall, RecentCallDirection, RecentCallDisposition } from "./call-history";

import { CallHistoryCallsService } from "./call-history-calls.service";
import { CallHistoryDialogService } from "./call-history-dialog.service";

const debug = false;

/** the default number of sdr fetches we do per browse, ie 120 sdrs */
const DEFAULT_LIMIT = (callHistoryConfig.numPageLinks + 1) * callHistoryConfig.pageSize;

/*
TODO from 2017 - ignoring these for now since they have never been made as priority things to do
- Calls take 60s after hangup to enter the SDR table, so if you're offline or reload the app the last 60s of calls aren't going to show up
  - We need to account for them in a user-friendly way, simply doing another fetch 60s
  - afer the app loads is no good because users will get slammed with SDRs from nowhere
- Somewhat related: SdrID is generally sorted by time, but if a call starts and ends within another call they will come out of the table out of order
  - We could/should sort on the frontend and pass in 1 fewer sort parameter to the API
  - May affect UI performance
*/

interface CallHistoryState {
  callHistoryCall: Array<CallHistoryCall>;
}

@Injectable({ providedIn: "root" })
export class CallHistoryService extends StateEmitter<CallHistoryState> implements OnDestroy {
  /** counter for number of emptyFetches before stop fetching. determined by callHistoryConfig.maxEmptyApiCalls */
  private userId!: string;
  private domain!: string;
  private unsubscriber = new Subscription();
  constructor(
    private sdrService: SdrService,
    private userService: UserService,
    private callHistoryCallsService: CallHistoryCallsService,
    private callHistoryDialogService: CallHistoryDialogService
  ) {
    super({ callHistoryCall: [] } as CallHistoryState);
    this.initUsers();
    this.initCallHistory();
  }

  get isAllFetched(): boolean {
    return this.sdrService.allFetched;
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  /** public method that wraps sdrBrowse in sdrService.
   * should only be used for refetches. does not fetch if there has been max number of empty fetches already
   */
  loadMore(limit?: number): void {
    this.sdrService.sdrBrowse({ Limit: limit || DEFAULT_LIMIT });
  }

  private initUsers(): void {
    this.unsubscriber.add(
      this.userService.selfUser
        .pipe(distinctUntilChanged((prev, next) => prev.userId === next.userId))
        .subscribe(user => {
          // if there is a change in user, we will want to wipe the callHistoryCall State
          if (this.userId !== user.userId) {
            this.userId = user.userId;
            this.domain = user.domain;
            this.stateStore.callHistoryCall = [];
            this.publishState();
          }
        })
    );
  }

  private initCallHistory(): void {
    this.unsubscriber.add(
      combineLatest([this.getCallsAndDialogsObservable(), this.getSdrsObservable()]).subscribe(
        ([callsDialogs, sdrs]) => {
          debug && console.warn("sdrs", sdrs);
          debug && console.warn("calls and dialogs", callsDialogs);
          const callHistoryCall = callsDialogs.concat(sdrs);
          callHistoryCall.sort((a, b) => b.callTime.localeCompare(a.callTime));
          this.stateStore.callHistoryCall = callHistoryCall;
          debug && console.warn(callHistoryCall);
          this.publishState();
        }
      )
    );
  }

  private getSdrsObservable(): Observable<Array<CallHistoryCall>> {
    return this.sdrService.sortedSdrs.pipe(map(sdrs => sdrs.map(this.sdrToCall.bind(this))));
  }

  private getCallsAndDialogsObservable(): Observable<Array<CallHistoryCall>> {
    return combineLatest([
      this.callHistoryCallsService.state.pipe(pluck("callHistoryCalls")),
      this.callHistoryDialogService.state.pipe(
        pluck("callHistoryDialogs"),
        // do not update if array does not change. This is to handle the spam from dialogs
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      )
    ]).pipe(
      map(([calls, dialogs]) => {
        debug && console.warn("calls", calls);
        debug && console.warn("dialogs", dialogs);
        const result = calls;
        dialogs.forEach(dialog => {
          const duplicatedCallIndex = calls.findIndex(call => call.callId === dialog.callId);
          if (duplicatedCallIndex > -1) {
            result.splice(duplicatedCallIndex, 1, dialog);
          } else {
            result.push(dialog);
          }
        });
        return result;
      })
    );
  }

  /** the actual converting method from sdrs to calls. throws any sdrs with missing informations */
  private sdrToCall(sdr: Sdr): CallHistoryCall {
    let direction: RecentCallDirection,
      disposition: RecentCallDisposition,
      remoteName: string,
      remoteAddress: string,
      localAddress: string;

    if (this.userId && sdr.calleeUserId === this.userId) {
      direction = RecentCallDirection.INBOUND;
      remoteName = sdr.callerDisplay && sdr.callerDisplay.replace(/"/g, "");
      remoteAddress = sdr.callerAddress;
      localAddress = sdr.calleeAddress;
    } else if (this.userId && sdr.callerUserId === this.userId) {
      remoteName = sdr.calleeDisplay && sdr.calleeDisplay.replace(/"/g, "");
      direction = RecentCallDirection.OUTBOUND;
      remoteAddress = sdr.calleeAddress;
      localAddress = sdr.callerAddress;
    } else {
      throw new Error("SDR caller and callee user ids do not match call user id");
    }

    // HACK: if an app picks up your call, we will assume failover and mark as missed instead of answered
    // NOTE: we are no longer making this assumption and will show the duration of an app call
    // if (direction === RecentCallDirection.INBOUND && sdr.calleeContact.endsWith("app.jnctn.net")) {
    //   sdr.disposition = RecentCallDisposition.MISSED;
    // }

    // an inbound call where they called you via gruu will always be considered a conference call for now
    if (
      (direction === RecentCallDirection.OUTBOUND &&
        remoteAddress.indexOf("_ovob") > 0 &&
        remoteAddress.indexOf("_ovid") > 0) ||
      (remoteAddress.indexOf(";ocn=") > 0 && remoteAddress.indexOf(";ocp=") > 0)
    ) {
      const conferenceName: string = remoteAddress.slice(
        remoteAddress.indexOf(";ocn=") + 5,
        remoteAddress.indexOf(";ocp")
      );
      const conferenceParticipant: string =
        sdr.calleeDisplay.slice(1, -1) ||
        decodeURIComponent(remoteAddress.slice(remoteAddress.indexOf(";ocp") + 5));
      const conferenceParticipantUser: string = remoteAddress.slice(
        remoteAddress.indexOf(":") + 1,
        remoteAddress.indexOf("*")
      );

      if (
        remoteAddress.indexOf("anonymous.invalid") < 0 &&
        !conferenceParticipantUser.startsWith("anonymous.")
      ) {
        remoteAddress = "sip:" + conferenceParticipantUser + "@" + this.domain;
      } else {
        remoteAddress = "sip:anonymous@anonymous.invalid";
      }
      remoteName = "Conference " + conferenceName + " with " + conferenceParticipant;
    }

    const remoteUri = OnSIPURI.parseString(remoteAddress);
    if (!remoteUri) {
      console.error(`normalization failed for address: ${remoteAddress}`);
    }

    if (
      (direction === RecentCallDirection.INBOUND &&
        localAddress.indexOf("_ovob") > 0 &&
        localAddress.indexOf("_ovid") > 0) ||
      (localAddress.indexOf(";ocn=") > 0 && localAddress.indexOf(";ocp=") > 0)
    ) {
      const conferenceName: string = localAddress.slice(
        localAddress.indexOf(";ocn=") + 5,
        localAddress.indexOf(";ocp")
      );
      const conferenceParticipant: string =
        sdr.callerDisplay.slice(8, -1) ||
        decodeURIComponent(localAddress.slice(localAddress.indexOf(";ocp=") + 5));
      const conferenceParticipantUser: string = localAddress.slice(
        localAddress.indexOf(":") + 1,
        localAddress.indexOf("*")
      );

      if (
        remoteAddress.indexOf("anonymous.invalid") < 0 &&
        !conferenceParticipantUser.startsWith("anonymous.")
      ) {
        localAddress = "sip:" + conferenceParticipantUser + "@" + this.domain;
      } else {
        localAddress = "sip:anonymous@anonymous.invalid"; // conferenceParticipant;
      }
      remoteName = "Conference " + conferenceName + " with " + conferenceParticipant;
    }

    const localUri = OnSIPURI.parseString(localAddress);
    if (!localUri) {
      console.error(`normalization failed for address" ${localAddress}`);
    }

    disposition = sdr.disposition as RecentCallDisposition;

    if (disposition === RecentCallDisposition.EXPIRED) {
      disposition = RecentCallDisposition.ANSWERED;
      sdr.duration = "1"; // expired means never hung up.  these are garbage collected by CPs after 12 hours. A "1" should display as 0 in the UI.
    }

    if (disposition !== RecentCallDisposition.ANSWERED) {
      disposition =
        direction === RecentCallDirection.INBOUND
          ? RecentCallDisposition.MISSED
          : RecentCallDisposition.CANCELLED;
    }

    if (remoteUri && remoteUri.host === "anonymous.invalid") {
      remoteName = remoteName + " (anonymous)";
    }

    return {
      callId: sdr.callId,
      callTime: new Date(sdr.startTime).toISOString(),
      direction,
      disposition,
      duration: parseInt(sdr.duration) * 1000,
      localUri: localUri ? localUri.toString() : "unknown",
      ouid: sdr.ouid,
      remoteName: remoteName ? remoteName : remoteUri ? remoteUri.aor : "unknown",
      remoteUri: remoteUri ? remoteUri.toString() : "unknown"
    };
  }
}
