import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TranslateModule } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Component({
  selector: "onsip-loading-container",
  templateUrl: "./loading-container.component.html",
  styleUrl: "./loading-container.component.scss",
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule, TranslateModule]
})
export class LoadingContainerComponent {
  @Input() deferCond!: Observable<boolean>;
  @Input() loadingMessage = "ONSIP_I18N.PLEASE_WAIT_SECONDS";
}
