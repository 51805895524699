<div class="image-upload mat-typography">
  @if ((showCropper | async) === false) {
  <div mat-dialog-title class="image-upload-header">
    @if (hasFrame) {
    <div>
      @if (image) {
      <onsip-avatar-frame>
        <onsip-avatar [avatarUrl]="image"></onsip-avatar>
      </onsip-avatar-frame>
      } @else {
      <onsip-avatar-frame>
        <onsip-avatar [avatarUrl]="'resources/img/signup/profile-placeholder.png'"></onsip-avatar>
      </onsip-avatar-frame>
      }
    </div>
    } @if (!hasFrame) {
    <div>
      @if (image) {
      <img [src]="image" class="square-no-frame" alt="Uploaded Image" />
      } @else {
      <onsip-avatar-frame>
        <onsip-avatar [avatarUrl]="'resources/img/signup/profile-placeholder.png'"></onsip-avatar>
      </onsip-avatar-frame>
      }
    </div>
    } @if ((showCropper | async) === false && image) {
    <button
      id="onsip-image-upload-delete-current"
      mat-button
      color="primary"
      (click)="deleteCurrent()"
      cdkFocusInitial
    >
      {{ "ONSIP_I18N.DELETE_CURRENT_PROFILE_PICTURE" | translate }}
    </button>
    } @if ((showCropper | async) === false && !image) {
    <h4 class="no-image">
      {{ "ONSIP_I18N.NO_CURRENT_IMAGE_SELECTED" | translate }}
    </h4>
    }
  </div>
  }
  <div mat-dialog-content>
    @if ((showCropper | async) === false) {
    <div class="image-square">
      @if ((showCropper | async) === false) {
      <ngx-file-drop
        id="onsip-image-upload-trigger-upload"
        (click)="triggerUpload()"
        (onFileDrop)="fileDropped($event)"
        dropZoneClassName="avatar-drop-zone"
        contentClassName="avatar-content-zone"
      >
        <ng-template ngx-file-drop-content-tmp>
          @if (!errorText) {
          <div id="onsip-image-directions">
            <h4 class="upload-text">
              {{
                "ONSIP_I18N.DRAG_AND_DROP_PICTURE_HERE_TO_REPLACE_CURRENT_IMAGE_OR_CLICK_TO_UPLOAD"
                  | translate
              }}
            </h4>
          </div>
          } @if (errorText) {
          <span id="onsip-avatar-error-directions">
            {{ errorText }}
          </span>
          }
          <input id="onsip-image-file-upload" type="file" (change)="fileChosen($event)" />
        </ng-template>
      </ngx-file-drop>
      }
    </div>
    } @if (showCropper | async) {
    <div id="onsip-image-cropping">
      <image-cropper
        #cropper
        [imageBase64]="imageToBeCropped.image"
        [roundCropper]="isRound"
        [resizeToHeight]="preserveSize ? 0 : 290"
        [resizeToWidth]="preserveSize ? 0 : 290"
        [cropperMinWidth]="150"
        [cropperMinHeight]="150"
        [maintainAspectRatio]="maintainAspectRatio"
        (imageCropped)="imageCropped($event)"
        output="base64"
        format="png"
      >
      </image-cropper>
    </div>
    }
  </div>
</div>
