import { Injectable } from "@angular/core";
import {
  GroupAddress,
  ApiGroupAddress,
  apiGroupAddressToGroupAddress,
  ApiGroupAddressWithOrganization
} from "./group-address";
import { ApiResourceService } from "../api-resource.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiAction } from "../../api-actions";
import { sessionId } from "../../apiParams/session-id";
import { organizationId } from "../../apiParams/organization-id";
import { domain } from "../../apiParams/domain";
import { ApiStateStoreService } from "../../api-state-store.service";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { onsipApiArrayToArray, arrayToOnsipApiArrayParameter } from "../../apiResponse/xml-json";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";

export { GroupAddress };

// TODO - In the soon to be deprecated admin portal group's tab, you can add/modify the group's extension number.
// However, this is not handled by the GroupAddress API and should be done using the Extension API.

@Injectable({ providedIn: "root" })
export class GroupAddressService extends ApiResourceService<GroupAddress> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "GroupAddress", "username");
  }

  groupAddressBrowse(): ApiPromiseState<GroupAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 1000
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressBrowse);
  }

  groupAddressAdd(
    parameters: Omit<NewGroupAddressParameters, "Domain">
  ): ApiPromiseState<GroupAddress> | undefined {
    if (parameters.SelectedAddresses.length < 1) {
      console.error("There needs to be at least one member in the group");
      return;
    }

    const selectedAddresses = arrayToOnsipApiArrayParameter(
      parameters.SelectedAddresses,
      "SelectedAddresses"
    );

    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        DefaultAddress: parameters.DefaultAddress,
        Ordering: parameters.Ordering,
        SelectedAddresses: parameters.SelectedAddresses,
        Timeout: parameters.Timeout,
        Username: parameters.Username,
        Name: parameters.Name,
        ...selectedAddresses
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressAdd);
  }

  groupAddressEdit(
    parameters: Omit<EditGroupAddressParameters, "Domain">
  ): ApiPromiseState<GroupAddress> | undefined {
    // Throwing a more useful console error here because the one from the api may not be obvious
    if (parameters.SelectedAddresses.length < 1) {
      console.error("There needs to be at least one member in the group");
      return;
    }

    const selectedAddresses = arrayToOnsipApiArrayParameter(
      parameters.SelectedAddresses,
      "SelectedAddresses"
    );

    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressEdit,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        DefaultAddress: parameters.DefaultAddress,
        Ordering: parameters.Ordering,
        SelectedAddresses: parameters.SelectedAddresses,
        Timeout: parameters.Timeout,
        Username: parameters.Username,
        Address: parameters.Address,
        Name: parameters.Name,
        ...selectedAddresses
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressEdit);
  }

  groupAddressDelete(group: GroupAddress): ApiPromiseState<GroupAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressDelete,
        Address: `${group.address.username}@${group.address.domain}`,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId())
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressDelete);
  }

  /**
   * Adds a member to the group
   * @param Address the group's sip address. This can be reconstructed by combining the group's username and group's domain
   * @param MemberAddress the member's sip address. Like the group SIP address, this can be created by combining the member's username and domain
   */
  groupAddressAddMember(Address: string, MemberAddress: string): ApiPromiseState<GroupAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressEditAddMember,
        Address,
        MemberAddress,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId())
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressEditAddMember);
  }

  /**
   * Removes a member from the group
   * @param Address the group's sip address. This can be reconstructed by combining the group's username and group's domain
   * @param MemberAddress the member's sip address. Like the group SIP address, this can be created by combining the member's username and domain
   */
  groupAddressRemoveMember(Address: string, MemberAddress: string): ApiPromiseState<GroupAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.GroupAddressEditRemoveMember,
        Address,
        MemberAddress,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId())
      }
    });
    return this.promiseState.toPromise(ApiAction.GroupAddressEditRemoveMember);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.GroupAddressBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          ApiResourceService.arrayToRecord(
            extractData<Array<ApiGroupAddress>>(
              response,
              action,
              "GroupAddress",
              "GroupAddresses"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.GroupAddressEdit:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const username = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter")
          .find(param => param.Name === "Address")
          ?.Value?.replace(/@.*$/, "");
        // eslint-disable-next-line no-case-declarations
        const payload: Record<string, GroupAddress | undefined> = {};
        if (username) {
          payload[username] = undefined;
        }
        // if the address has edited the username, the payload will have both keys
        // if the address has not edited the username, this next block will overwrite the above
        Object.assign(
          payload,
          ApiResourceService.arrayToRecord(
            [extractData<ApiGroupAddress>(response, action, "GroupAddress", "GroupAddresses")].map(
              clean
            ),
            this.indexKeyName
          )
        );

        this.store.mergeStateUpdate(this.resourceName, payload, action);
        break;
      case ApiAction.GroupAddressAdd:
      case ApiAction.GroupAddressEditAddMember:
      case ApiAction.GroupAddressEditRemoveMember:
        this.store.mergeStateUpdate(
          this.resourceName,
          ApiResourceService.arrayToRecord(
            [extractData<ApiGroupAddress>(response, action, "GroupAddress", "GroupAddresses")].map(
              clean
            ),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.GroupAddressDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedAddress = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "Address")?.Value;
        deletedAddress &&
          this.store.mergeStateUpdate(
            this.resourceName,
            {
              [deletedAddress.replace(/@.*$/, "")]: undefined
            },
            action
          );
        break;
    }
  }
}

function clean(apiGroupAddress: ApiGroupAddress | ApiGroupAddressWithOrganization): GroupAddress {
  // we just want the groupaddress object if result also contains the organization object
  if ("GroupAddress" in apiGroupAddress) apiGroupAddress = apiGroupAddress.GroupAddress;
  return apiGroupAddressToGroupAddress(apiGroupAddress);
}

/** Interface containing all the params needed to make a new group */
interface NewGroupAddressParameters {
  /** the sip address of the failover address */
  DefaultAddress?: string;
  /** the domain portion of the grou's SIP address */
  Domain: string;
  /** parallel/Simultaneous Ring or sequential/Hunt group */
  Ordering: ApiGroupAddress["Ordering"];
  /** the members within the group. Note: there needs to be at least one member in the group */
  SelectedAddresses: Array<string>;
  /** ring time until call goes to failover address */
  Timeout: number;
  /** the user portion of the group's SIP address */
  Username: string;
  /** the display name of the group */
  Name?: string;
}

/** Interface containing params that can be edited */
interface EditGroupAddressParameters {
  /** the group's sip address */
  Address: string;
  /** the sip address of the failover address */
  DefaultAddress?: string;
  /** the display name of the group */
  Name?: string;
  /** parallel/Simultaneous Ring or sequential/Hunt group */
  Ordering: ApiGroupAddress["Ordering"];
  /** the members' sip addresses within the group. Note: there needs to be at least one member in the group */
  SelectedAddresses: Array<string>;
  /** ring time until call goes to failover address */
  Timeout: number;
  /** the user portion of the group's SIP address - note: this will change the group's sip address */
  Username?: string;
}
