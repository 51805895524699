/**
 * Error thrown is API server returned an non-200 response.
 */
export class ApiFailure extends Error {
  /**
   * Constructor
   * @param message Error message.
   */
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}
