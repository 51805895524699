import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from "@angular/core";
import { NonNullableFormBuilder, Validators } from "@angular/forms";
import {
  Account,
  AccountService
} from "@onsip/common/services/api/resources/account/account.service";
import {
  Organization,
  OrganizationService
} from "@onsip/common/services/api/resources/organization/organization.service";
import { User, UserService } from "@onsip/common/services/api/resources/user/user.service";
import { isAtLeastAccountAdminRole } from "@onsip/common/services/api/role";
import { BehaviorSubject, Subscription } from "rxjs";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import {
  SuperUserTableData,
  SuperUserTableType
} from "../superUserTable/super-user-table.component";
import {
  searchTypeOptions,
  adminPrivilegesOptions,
  searchCompareOptions,
  userSearchParamOptions,
  orgSearchParamOptions,
  accountSearchParamOptions,
  AdvancedFormGroupType
} from "./super-user-advanced-search-options";

@Component({
  selector: "onsip-super-user-advanced-search",
  templateUrl: "./super-user-advanced-search.component.html",
  styleUrls: ["./super-user-advanced-search.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SuperUserAdvancedSearchComponent implements OnInit, OnDestroy {
  /** loading behavior subject from the parent search component, use to trigger loading modal */
  @Input() isLoading!: BehaviorSubject<boolean>;
  @Input() savedFormValue!: AdvancedFormGroupType;
  searchTypeOptions = searchTypeOptions;
  adminPrivilegesOptions = adminPrivilegesOptions;
  searchCompareOptions = searchCompareOptions;
  userSearchParamOptions = userSearchParamOptions;
  orgSearchParamOptions = orgSearchParamOptions;
  accountSearchParamOptions = accountSearchParamOptions;
  formGroup = this.fb.group({
    searchType: ["user"],
    withAdminPrivileges: ["yes"],
    searchCompare: ["Contains"],
    /** weird format for backend query - it is ParameterName,datatype */
    userSearchParam: ["AccountId,int"],
    orgSearchParam: ["AccountId,int"],
    accountSearchParam: ["AccountId,int"],
    searchValue: ["", Validators.required]
  });

  @Output() closeSearch = new EventEmitter<
    [Array<SuperUserTableData>, SuperUserTableType, AdvancedFormGroupType]
  >();

  private unsubscriber = new Subscription();

  constructor(
    private fb: NonNullableFormBuilder,
    private userService: UserService,
    private orgService: OrganizationService,
    private accountService: AccountService,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    if (this.savedFormValue) {
      this.formGroup.setValue(this.savedFormValue);
    }
    this.searchTypeChanges();
    this.searchCompareChanges();
    this.searchParamChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  onCancel(): void {
    this.closeSearch.emit();
  }

  submit(): void {
    if (this.formGroup.valid) {
      this.isLoading.next(true);
      const type = this.formGroup.controls.searchType.value;
      const formValue = this.formGroup.getRawValue() as AdvancedFormGroupType;
      switch (type) {
        case "user":
          this.handleUserSearch(formValue);
          break;
        case "organization":
          this.handleOrgSearch(formValue);
          break;
        case "account":
          this.handleAccountSearch(formValue);
      }
    }
  }

  private searchTypeChanges() {
    this.unsubscriber.add(
      this.formGroup.controls.searchType.valueChanges.subscribe(searchType => {
        // we need to update validators state if we are switch between search types so they are reflected of the current search value
        if (searchType === "user") {
          this.formGroup.controls.userSearchParam.updateValueAndValidity();
        } else if (searchType === "organization") {
          this.formGroup.controls.orgSearchParam.updateValueAndValidity();
        } else {
          this.formGroup.controls.accountSearchParam.updateValueAndValidity();
        }
      })
    );
  }

  private searchCompareChanges() {
    this.unsubscriber.add(
      this.formGroup.controls.searchCompare.valueChanges.subscribe(searchCompare => {
        const selectedOption = searchCompareOptions.find(option => option.value === searchCompare);
        if (selectedOption) {
          // if selected option has a type, then filter param options that have the same type
          if (selectedOption.type) {
            this.userSearchParamOptions = userSearchParamOptions.filter(
              option => option.type === selectedOption.type
            );
            const userSearchParam = this.formGroup.controls.userSearchParam;
            // if the selecteds param is no longer valid, then select the first valid one
            if (
              !this.userSearchParamOptions
                .map(option => option.value)
                .includes(userSearchParam.value)
            ) {
              userSearchParam.patchValue(this.userSearchParamOptions[0].value);
            }

            this.orgSearchParamOptions = orgSearchParamOptions.filter(
              option => option.type === selectedOption.type
            );
            const orgSearchParam = this.formGroup.controls.orgSearchParam;
            // if the selecteds param is no longer valid, then select the first valid one
            if (
              !this.orgSearchParamOptions.map(option => option.value).includes(orgSearchParam.value)
            ) {
              orgSearchParam.patchValue(this.orgSearchParamOptions[0].value);
            }

            this.accountSearchParamOptions = accountSearchParamOptions.filter(
              option => option.type === selectedOption.type
            );
            const accountSearchParam = this.formGroup.controls.accountSearchParam;
            // if the selecteds param is no longer valid, then select the first valid one
            if (
              !this.accountSearchParamOptions
                .map(option => option.value)
                .includes(accountSearchParam.value)
            ) {
              accountSearchParam.patchValue(this.accountSearchParamOptions[0].value);
            }
          } else {
            // else set the param options to contain all options
            this.userSearchParamOptions = userSearchParamOptions;
            this.orgSearchParamOptions = orgSearchParamOptions;
            this.accountSearchParamOptions = accountSearchParamOptions;
          }
        }
      })
    );
  }

  private searchParamChanges(): void {
    this.unsubscriber.add(
      this.formGroup.controls.userSearchParam.valueChanges.subscribe(userSearchValue => {
        const userSearch = userSearchParamOptions.find(option => option.value === userSearchValue);
        const type = userSearch?.type;
        if (type) this.updateSearchValueValidator(type);
      })
    );
    this.unsubscriber.add(
      this.formGroup.controls.orgSearchParam.valueChanges.subscribe(orgSearchValue => {
        const orgSearch = orgSearchParamOptions.find(option => option.value === orgSearchValue);
        const type = orgSearch?.type;
        if (type) this.updateSearchValueValidator(type);
      })
    );
    this.unsubscriber.add(
      this.formGroup.controls.accountSearchParam.valueChanges.subscribe(accountSearchValue => {
        const accountSearch = accountSearchParamOptions.find(
          option => option.value === accountSearchValue
        );
        const type = accountSearch?.type;
        if (type) this.updateSearchValueValidator(type);
      })
    );
  }

  /** update search value validator with a number validator or remove it depending on search param type: int or string */
  private updateSearchValueValidator(type: string): void {
    const searchValue = this.formGroup.controls.searchValue;
    if (type === "int") {
      searchValue.addValidators(Validators.pattern("^[0-9]+$"));
    } else {
      searchValue.clearValidators();
      // eslint-disable-next-line no-null/no-null
      searchValue.setErrors(null);
    }
    // add back the required validator
    searchValue.addValidators(Validators.required);
    searchValue.updateValueAndValidity();
  }

  private handleUserSearch(formValue: AdvancedFormGroupType): void {
    this.userService
      .userBrowse({
        SearchField: formValue.userSearchParam,
        SearchCompOp: formValue.searchCompare,
        SearchString: formValue.searchValue,
        OrganizationId: undefined,
        Limit: 500
      })
      .then(result => {
        if (result.status === "success") {
          let dataArray = Object.values(result.data);
          if (formValue.withAdminPrivileges === "yes") {
            dataArray = dataArray.filter(data => isAtLeastAccountAdminRole(data.roles));
          }
          if (formValue.withAdminPrivileges === "no") {
            dataArray = dataArray.filter(data => !isAtLeastAccountAdminRole(data.roles));
          }
          const filteredArray = dataArray.map(data => {
            const primaryFilterRes = this.getSearchFieldUserFilter(formValue.userSearchParam, data);
            const result = {
              contactName: data.contact.name,
              userId: data.userId,
              status: data.status,
              username: data.username,
              email: data.contact.email,
              contactOrg: data.contact.organization,
              orgId: data.organizationId,
              domain: data.domain,
              accountId: data.accountId,
              isAdmin: isAtLeastAccountAdminRole(data.roles)
            };
            return Object.assign(primaryFilterRes, result);
          });
          this.isLoading.next(false);
          this.closeSearch.emit([filteredArray, "advUser", formValue]);
        } else {
          const errMsg = result.data.message;
          this.snackbar.openSnackBar(
            `There was an issue with your search parameter: ${errMsg}`,
            "error"
          );
          this.userService.clearErrors();
        }
      });
  }

  private handleOrgSearch(formValue: AdvancedFormGroupType): void {
    this.orgService
      .organizationBrowse({
        SearchField: formValue.orgSearchParam,
        SearchCompOp: formValue.searchCompare,
        SearchString: formValue.searchValue,
        // account id needs to be undefined to search other accounts
        AccountId: undefined,
        Limit: 500
      })
      .then(result => {
        if (result.status === "success") {
          const dataArray = Object.values(result.data);
          const filteredArray = dataArray.map(data => {
            const primaryFilterRes = this.getSearchFieldOrgFilter(formValue.orgSearchParam, data);
            const result = {
              contactOrg: data.contact.organization,
              orgId: data.organizationId,
              orgContact: data.contact.name,
              email: data.contact.email,
              status: data.status,
              accountId: data.accountId,
              domain: data.domain
            };
            return Object.assign(primaryFilterRes, result);
          });
          this.isLoading.next(false);
          this.closeSearch.emit([filteredArray, "advOrg", formValue]);
        } else {
          const errMsg = result.data.message;
          this.snackbar.openSnackBar(
            `There was an issue with your search parameter: ${errMsg}`,
            "error"
          );
          this.orgService.clearErrors();
        }
      });
  }

  private handleAccountSearch(formValue: AdvancedFormGroupType): void {
    this.accountService
      .accountBrowse({
        SearchField: formValue.accountSearchParam,
        SearchCompOp: formValue.searchCompare,
        SearchString: formValue.searchValue,
        Limit: 500
      })
      .then(result => {
        if (result.status === "success") {
          const dataArray = Object.values(result.data);
          const filteredArray = dataArray.map(data => {
            const primaryFilterRes = this.getSearchFieldAccountFilter(
              formValue.accountSearchParam,
              data
            );
            const result = {
              accountId: data.accountId,
              businessName: data.contact.organization,
              accountContact: data.contact.name,
              email: data.contact.email
            };
            return Object.assign(primaryFilterRes, result);
          });
          this.isLoading.next(false);
          this.closeSearch.emit([filteredArray, "advAccount", formValue]);
        } else {
          const errMsg = result.data.message;
          this.snackbar.openSnackBar(
            `There was an issue with your search parameter: ${errMsg}`,
            "error"
          );
          this.accountService.clearErrors();
        }
      });
  }

  /** helper function used to get the parameter used to filter the table and return only that result */
  private getSearchFieldUserFilter(value: string, data: User): Record<string, string> {
    const fieldMap: Record<string, Record<string, string>> = {
      "Domain,string": { domain: data.domain },
      "Contact,string": { contactOrg: data.contact.organization },
      "Name,string": { contactName: data.contact.name },
      "Email,string": { email: data.contact.email },
      "AuthUsername,string": { username: data.username },
      "UserId,int": { userId: data.userId },
      "OrganizationId,int": { orgId: data.organizationId },
      "AccountId,int": { accountId: data.accountId }
    };
    return fieldMap[value];
  }

  private getSearchFieldOrgFilter(value: string, data: Organization): Record<string, string> {
    const fieldMap: Record<string, Record<string, string>> = {
      "Domain,string": { domain: data.domain },
      "Name,string": { orgContact: data.contact.name },
      "Email,string": { email: data.contact.email },
      "OrganizationId,int": { orgId: data.organizationId },
      "AccountId,int": { accountId: data.accountId }
    };
    return fieldMap[value];
  }

  private getSearchFieldAccountFilter(value: string, data: Account): Record<string, string> {
    const fieldMap: Record<string, Record<string, string>> = {
      "Name,string": { accountContact: data.contact.name },
      "Email,string": { email: data.contact.email },
      "AccountId,int": { accountId: data.accountId }
    };
    return fieldMap[value];
  }
}
