export const searchTypeOptions = [
  { value: "user", text: "A user" },
  { value: "organization", text: "An organization" },
  { value: "account", text: "An account" }
];

export type SearchType = "user" | "organization" | "account";

export const adminPrivilegesOptions = [
  { value: "yes", text: "with admin privileges" },
  { value: "no", text: "with no admin privileges" },
  { value: "any", text: "with any privileges" }
];

export type AdminPrivilegesType = "yes" | "no" | "any";

export const searchCompareOptions = [
  { value: "Contains", text: "that contains" },
  { value: "Equals", text: "that equals" },
  { value: "Begins With", text: "that begins with", type: "string" },
  { value: "Ends With", text: "that ends with", type: "string" },
  { value: "Greater Than", text: "that is greater than", type: "int" },
  { value: "Less Than", text: "that is less than", type: "int" }
];

export type SearchCompareType =
  | "Contains"
  | "Equals"
  | "Begins With"
  | "Ends With"
  | "Greater Than"
  | "Less Than";

export const userSearchParamOptions = [
  { value: "AccountId,int", text: "and an account id", type: "int" },
  { value: "Domain,string", text: "and a domain", type: "string" },
  { value: "Contact,string", text: "and a contact", type: "string" },
  { value: "Name,string", text: "and a name", type: "string" },
  { value: "Email,string", text: "and an email", type: "string" },
  { value: "AuthUsername,string", text: "and a username", type: "string" },
  { value: "UserId,int", text: "and a user id", type: "int" },
  { value: "OrganizationId,int", text: "and an organization id", type: "int" }
];

export type UserSearchParamType =
  | "Domain,string"
  | "Contact,string"
  | "Name,string"
  | "Email,string"
  | "AuthUsername,string"
  | "UserId,int"
  | "OrganizationId,int"
  | "AccountId,int";

export const orgSearchParamOptions = [
  { value: "AccountId,int", text: "and an account id", type: "int" },
  { value: "Domain,string", text: "and a domain", type: "string" },
  { value: "Name,string", text: "and a name", type: "string" },
  { value: "Email,string", text: "and an email", type: "string" },
  { value: "OrganizationId,int", text: "and an organization id", type: "int" }
];

export type OrgSearchParamType =
  | "Domain,string"
  | "Name,string"
  | "Email,string"
  | "OrganizationId,int"
  | "AccountId,int";

export const accountSearchParamOptions = [
  { value: "AccountId,int", text: "and an account id", type: "int" },
  { value: "Name,string", text: "and a name", type: "string" },
  { value: "Email,string", text: "and an email", type: "string" }
];

export type AccountSearchParamType = "Name,string" | "Email,string" | "AccountId,int";

export interface AdvancedFormGroupType {
  searchType: SearchType;
  withAdminPrivileges: AdminPrivilegesType;
  searchCompare: SearchCompareType;
  userSearchParam: UserSearchParamType;
  orgSearchParam: OrgSearchParamType;
  accountSearchParam: AccountSearchParamType;
  searchValue: string;
}
