import { Subject, interval } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { Call } from "./call";
import { CallConfiguration } from "./call-configuration";
import { CallContact } from "./call-contact";
import { EndCallEventReason } from "./call-event";
import {
  MonitoredCallEvent,
  StartMonitoredCallEvent,
  StopMonitoredCallEvent
} from "./monitored-call-event";
import {
  MonitoredCallState,
  MonitoredCallStateInitializer,
  StatsReport
} from "./monitored-call-state";
import { MonitoredCallStrategy } from "./monitored-call-strategy";
import { RTCStatsReport } from "./session-description-handler-sip";
import { Session } from "./session";
import { SessionSIP } from "./session-sip";
import { log } from "./log";

const debug = false;

/**
 * MonitoredCall implementation.
 */
export class MonitoredCall<
  CE extends MonitoredCallEvent = MonitoredCallEvent,
  CS extends MonitoredCallState = MonitoredCallState
> extends Call<CE | MonitoredCallEvent, CS> {
  private _monitored = false;
  private _latestReports: Array<RTCStatsReport> = [];
  private _pollingInterval = 0; // milliseconds (0 for no monitoring)
  private _strategies: Map<string, MonitoredCallStrategy<CE, CS>> = new Map<
    string,
    MonitoredCallStrategy<CE, CS>
  >();

  private unsubscribe: Subject<void> = new Subject<void>();

  /**
   * createMonitoredCallStateInitializer
   * Returns a MonitoredCallStateInitializer function, which in turn returns the initial MonitoredCallState.
   */
  static initializer(uuid: string, session: Session): MonitoredCallState {
    const initialCallState = Call.initializer(uuid, session);
    const additionalMonitoredCallState = {
      monitored: false,
      monitorLatestReports: [] as Array<StatsReport>,
      monitorPollingInterval: 0,
      monitorStategies: [] as Array<any>
    };
    // FIXME: The next line should work, but oddly it throws at runtime with
    //        "ReferenceError: Can't find variable: __assign"
    // const initialMonitoredCallState: MonitoredCallState = { ...initialCallState, ...additionalMonitoredCallState };
    const initialMonitoredCallState: MonitoredCallState = Object.assign(
      initialCallState,
      additionalMonitoredCallState
    );
    return initialMonitoredCallState;
  }

  /**
   * Creates a new monitored Call
   * @param session Session encapsulated by the Call.
   */
  constructor(
    session: Session,
    contact?: CallContact | undefined,
    configuration?: CallConfiguration | undefined,
    initializer: MonitoredCallStateInitializer<CS> = MonitoredCall.initializer as MonitoredCallStateInitializer<CS>
  ) {
    super(session, contact, configuration, initializer);
  }

  /**
   * The interval which call is polled for statistics (milliseconds).
   */
  get pollingInterval(): number {
    return this._pollingInterval;
  }

  set pollingInterval(milliseconds: number) {
    this._pollingInterval = milliseconds;
    this.publishState();
  }

  addStrategy(strategy: MonitoredCallStrategy<CE, CS>): void {
    this._strategies.set(strategy.name, strategy);
  }

  removeStrategy(name: string): void {
    this._strategies.delete(name);
  }

  protected flushState() {
    super.flushState();
    let monitorLatestReports: Array<StatsReport> = [];
    if (this._monitored && this._latestReports) {
      monitorLatestReports = this._latestReports.map(report => {
        return {
          id: report.id,
          timestamp: report.timestamp,
          type: report.type,
          data: Array.from(report.data)
        };
      });
    }

    this.stateStore.monitored = this._monitored ? true : false;
    this.stateStore.monitorLatestReports = monitorLatestReports;
    this.stateStore.monitorPollingInterval = this._pollingInterval ? this._pollingInterval : 0;
    this.stateStore.monitorStategies = this._strategies
      ? Array.from(this._strategies).map(([name]) => name)
      : [];
  }

  protected didConnected(): boolean {
    if (!super.didConnected()) return false;
    if (this.startMonitoring()) {
      this.publishState();
      this.publishEvent(new StartMonitoredCallEvent(this.uuid));
    }
    return true;
  }

  protected didEnd(reason: EndCallEventReason): boolean {
    if (!super.didEnd(reason)) return false;
    if (this._monitored) {
      this.stopMonitoring();
      this.publishState();
      this.publishEvent(new StopMonitoredCallEvent(this.uuid));
    }
    return true;
  }

  protected didLast(): boolean {
    if (!super.didLast()) return false;
    this._latestReports = [];
    this._pollingInterval = 0;
    this._strategies.clear();
    return true;
  }

  private applyStrategies(reports: Array<RTCStatsReport>): void {
    this._strategies.forEach(strategy => {
      const event = strategy.apply(this, reports);
      if (event) {
        this.publishEvent(event);
      }
    });
  }

  private dumpReport(report: RTCStatsReport): void {
    log.debug(`RTCStatsReport[${report.timestamp}] ${report.type} : ${report.id}`);
    report.data.forEach((value, key) => {
      log.debug(` ${key}=${value}`);
    });
  }

  private dumpReports(reports: Array<RTCStatsReport>): void {
    debug && log.debug("MonitoredCall.dumpReports");
    reports.forEach(report => this.dumpReport(report));
  }

  private startMonitoring(): boolean {
    log.debug(`MonitoredCall[${this.uuid}].startMonitoring`);
    if (!this._pollingInterval) {
      log.debug(`MonitoredCall[${this.uuid}]: Not monitoring - no polling interval specified`);
      return false;
    }
    this._monitored = true;
    interval(this._pollingInterval)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe({
        next: i => {
          debug && log.debug(`MonitoredCall[${this.uuid}]: tick ${i}`);
          const session = this.session;
          if (session instanceof SessionSIP && session.sessionDescriptionHandler) {
            session.sessionDescriptionHandler.getStatsReports().then(reports => {
              debug && this.dumpReports(reports);
              this._latestReports = reports;
              super.publishState();
              this.applyStrategies(reports);
            });
          }
        },
        error: (error: unknown) => {
          log.error(`MonitoredCall[${this.uuid}]: Interval subscription ` + error);
          throw error;
        },
        complete: () => {
          debug && log.debug(`MonitoredCall[${this.uuid}]: Interval subscription complete`);
        }
      });
    return true;
  }

  private stopMonitoring(): void {
    log.debug(`MonitoredCall[${this.uuid}].stopMonitoring`);
    this._monitored = false;
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
