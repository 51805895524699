import { Injectable } from "@angular/core";

import { StateEmitter } from "../libraries/emitter";
import { LogService } from "./logging";

const debug = false;

/**
 * The application state.
 */
export interface NetworkState {
  /** True if the application has network connectivity. */
  online: boolean;
  /** The type of network connectivity. Will be "none" if not online, otherwise connectivity type may change while online is true. */
  connectivity: "none" | "mobile" | "wifi" | "unknown";
  /** True if the application has been suspened. This is a mobile specific state. */
  suspended: boolean;
}

@Injectable({ providedIn: "root" })
export class NetworkService extends StateEmitter<NetworkState> {
  constructor(protected log: LogService) {
    super({
      online: false,
      connectivity: "none",
      suspended: false
    });
    this.platformInit();
  }

  protected platformInit(): void {
    debug && this.log.debug("NetworkService.platformInit");
  }
}
