import { Injectable } from "@angular/core";
import { Observable, Subject, timer } from "rxjs";
import { map, filter, takeUntil, withLatestFrom, shareReplay } from "rxjs/operators";

import { CallControllerService } from "./call-controller.service";
import { FirestoreCallService } from "./sayso/firestore-call.service";

// @ts-ignore
import { fetch as polyfillFetch } from "whatwg-fetch";

/** This service consolidates call timers into a single map, keyed by Call uuid.
 * All logic related to countdown and count-up timers for normal calls and rtc/sayso calls is
 * abstracted away so consuming components can simply subscribe to the timer with a given uuid.
 *
 * ### Normal Call:
 * Begin counting up from 0 as soon as an incoming call is detected increment up at 1 second intervals.
 * If the user answers, then the timer immediately goes to 0 and starts counting up
 *
 * ### Sayso Call (Failover):
 * Begin counting down from CallState.delay as soon as an incoming call is detected.
 * If the user answers, then the timer immediately goes to 0 and starts counting up.
 *
 * ### Sayso Call (Non-failover):
 * Begin counting down from CallState.delay as soon as an incoming call is detected.
 * If user answers, continue to count down to 0 and then begin counting up.
 * Side Effect: After user answers, but before countdown reaches 0 play tick.wav audio,
 * at timer value 0 play sayso-connected.wav audio.
 */

@Injectable({ providedIn: "root" })
export class CallTimerService {
  /** Call timer observables keyed by uuid
   *  For countdown / countup for any call simply: CallTimerService.timers[myUUID].subscribe() gives timer observable in milliseconds
   */
  private timers: Record<string, Observable<number>> = {};
  /** An offset to allow resetting timer when call is answered: Only used for sayso failover calls */
  private callAnswerTime: Record<string, number> = {};
  /** Used to clean up observables when calls end */
  private killTimer: Record<string, Subject<void>> = {};

  // helpers for audio related to sayso incoming call experience
  private bufferPromise: Promise<any> = Promise.resolve();
  private saysoConnectedAudioEl: HTMLAudioElement = new Audio();
  private saysoConnectingAudioEl: HTMLAudioElement = new Audio();

  constructor(
    private callControllerService: CallControllerService,
    private firestoreCallService: FirestoreCallService
  ) {
    this.initAudioFiles();
    this.initTimers();
  }

  /** Public getter for timers */
  getCallTimerFromUuid(uuid: string): Observable<number> {
    if (this.timers[uuid]) {
      return this.timers[uuid];
    } else {
      console.warn(`No timer record for uuid: ${uuid}`);
      throw new Error("CallTimerService: missing call record for provided uuid");
    }
  }

  /** Fetch audio files for sayso incoming call experience */
  private initAudioFiles() {
    polyfillFetch("resources/wav/sayso-connected.wav")
      .then((audio: any) => audio.blob())
      .then((audioBlob: Blob) => {
        this.saysoConnectedAudioEl.src = URL.createObjectURL(audioBlob);
        this.saysoConnectedAudioEl.preload = "auto";
        this.saysoConnectedAudioEl.loop = false;
      })
      .catch((err: string) => {
        throw new Error("CallAudio: Couldn't create audio media." + err);
      });
    polyfillFetch("resources/wav/tick.wav")
      .then((audio: any) => audio.blob())
      .then((audioBlob: Blob) => {
        this.saysoConnectingAudioEl.src = URL.createObjectURL(audioBlob);
        this.saysoConnectingAudioEl.preload = "auto";
        this.saysoConnectingAudioEl.loop = false; // want to sync with ticks so manually replay it
      })
      .catch((err: string) => {
        throw new Error("CallAudio: Couldn't create media: tick.wav." + err);
      });
  }

  private initTimers() {
    this.callControllerService.state.subscribe(state => {
      state.calls.forEach(call => {
        if (!this.timers[call.uuid]) {
          // If we haven't seen this call uuid add new timer
          this.killTimer[call.uuid] = new Subject<void>();
          const cid = call.xData?.slice(4);
          if (cid) {
            // sayso call
            const saysoCall = this.firestoreCallService.getCall(cid);
            // if getCall returns undefined we have to wait for the new call event
            const saysoCallStateObservable = saysoCall?.stateValue.id
              ? saysoCall.state
              : this.firestoreCallService.state.pipe(
                  map(callArray => callArray.find(found => found.id === cid)),
                  filter(
                    <T>(saysoCallAsync: T | undefined): saysoCallAsync is T => !!saysoCallAsync
                  )
                );
            this.timers[call.uuid] = timer(0, 1000).pipe(
              map(val => val * 1000),
              withLatestFrom(saysoCallStateObservable),
              map(([timerVal, saysoCallState]) => {
                const delay = saysoCallState.delay;
                const sessions = Object.keys(saysoCallState.sessions).map(
                  key => saysoCallState.sessions[key]
                );
                const updatedCall = this.callControllerService.getCallStateByUuid(call.uuid);
                if (sessions.length === 1 && delay !== 0) {
                  // first rep, no failover, no zero delay (teampage)
                  if (timerVal - delay < 0 && updatedCall?.connectedAt) {
                    this.callConnectingAlert();
                  } else if (timerVal - delay === 0 && updatedCall?.connectedAt) {
                    this.callConnectedAlert();
                  }
                  return timerVal - delay; // negative when counting down
                } else {
                  if (updatedCall?.connectedAt) {
                    // answered call
                    if (!this.callAnswerTime[call.uuid]) this.callAnswerTime[call.uuid] = timerVal;
                    return timerVal - this.callAnswerTime[call.uuid]; // show a timer value of 0 the second the call is answered then count up
                  } else {
                    return timerVal - delay; // failover not answered yet, counting down
                  }
                }
              }),
              takeUntil(this.killTimer[call.uuid]),
              shareReplay({ bufferSize: 1, refCount: false })
            );
          } else {
            // not a sayso call
            this.timers[call.uuid] = timer(0, 1000).pipe(
              map(val => val * 1000), // output in milliseconds
              map(timerVal => {
                const updatedCall = this.callControllerService.getCallStateByUuid(call.uuid);
                if (updatedCall?.connectedAt) {
                  // answered call
                  if (!this.callAnswerTime[call.uuid]) this.callAnswerTime[call.uuid] = timerVal;
                  return timerVal - this.callAnswerTime[call.uuid]; // show a timer value of 0 the second the call is answered then count up
                } else {
                  return timerVal; // don't have a use case for this currently: unanswered non-sayso call
                }
              }),
              takeUntil(this.killTimer[call.uuid]), // cleanup when call is over
              shareReplay({ bufferSize: 1, refCount: false }) // important for synchronizing ticks on subscribers & not duplicating side effects
            );
          }
        }
      });

      // Clean up calls that have finished / no longer on CallControllerService state
      Object.keys(this.timers).forEach(uuid => {
        if (!this.callControllerService.getCallStateByUuid(uuid)) {
          this.killTimer[uuid].next();
          this.killTimer[uuid].complete(); // important so we do not clean up these timer observables when we lose the call recoord
          delete this.timers[uuid];
          delete this.callAnswerTime[uuid];
          delete this.killTimer[uuid];
        }
      });
    });
  }

  /** play call connected sound */
  private callConnectedAlert(): void {
    this.bufferAudioElementCalls(this.saysoConnectedAudioEl, "play");
  }

  /** play call connecting sound */
  private callConnectingAlert(): void {
    this.bufferAudioElementCalls(this.saysoConnectingAudioEl, "play");
  }

  private bufferAudioElementCalls(element: any, audioFunction: string): Promise<void> {
    return new Promise(resolve => {
      this.bufferPromise = this.bufferPromise
        .then(() => {
          const prom = element[audioFunction]();
          resolve(prom);
          return prom;
        })
        .catch(error => {
          console.error("bufferAudioElementCalls error, continuing:", error);
          return;
        });
    });
  }
}
