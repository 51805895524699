import { Injectable, NgZone } from "@angular/core";

import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { LogService } from "@onsip/common/services/logging";
import { NetworkService } from "@onsip/common/services/network.service";
import { Config } from "@onsip/common/config";
import { APISessionObject } from "@onsip/common/services/api/api-credentials";
import { filter } from "rxjs/operators";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class WebApiSessionService extends ApiSessionService {
  private previousSessionId: string | undefined;

  constructor(
    log: LogService,
    ngZone: NgZone,
    network: NetworkService,
    store: ApiStateStoreService
  ) {
    super(log, ngZone, network, store);

    if (document && document.cookie) {
      this.state.subscribe(state => {
        if (state.sessionId && this.previousSessionId !== state.sessionId) {
          document.cookie = "userId=" + state.userId + ";path=/;domain=.onsip.com;secure;";
          document.cookie = "sessionId=" + state.sessionId + ";path=/;domain=.onsip.com;secure;";
        } else if (!state.sessionId && this.previousSessionId) {
          document.cookie =
            "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
          document.cookie =
            "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure;";
        }
        this.previousSessionId = state.sessionId;
      });
    }
  }

  useOldLogin(): void {
    if (document.cookie) {
      const sessionId: string | undefined = this.readCookie("sessionId"),
        userId: string | undefined = this.readCookie("userId");
      if (sessionId && userId) {
        this.recoverSession(sessionId, userId);
      }
    }
  }

  platformGetRedirectUri() {
    debug && console.warn("WebApiSesssionService.platformGetRedirectUri");

    if (Config.IS_DESKTOP) {
      return "https://app.onsip.com/app/oauth2/";
    } else if (Config.IS_WEB) {
      return window.location.href.split(/[?#]/g, 1)[0] + "oauth2/";
    }

    throw new Error("No redirect uri for this platform.");
  }

  recoverSession(sessionId: string, userId: string): Promise<APISessionObject | void> {
    debug && console.warn("WebApiSesssionService.recoverSession");

    return super
      .recoverSession(sessionId, userId)
      .then(session => {
        if (session) {
          window.addEventListener("online", () => this.createNewSessionInternally());

          // if a cookie user's session expires, there is no recovery, so log them out
          this.state.pipe(filter(state => !!state.sessionId && !state.loggedIn)).subscribe(() => {
            const loginUrl =
              window.location.protocol +
              "//" +
              window.location.host +
              location.pathname.substring(0, location.pathname.lastIndexOf("/"));
            location.replace(loginUrl + "/#/login?error=1");
            location.reload();
          });

          return session;
        }
      })
      .catch(() => {
        if (document && document.cookie) {
          document.cookie =
            "sessionId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure";
          document.cookie =
            "userId=; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/; domain=.onsip.com;secure";
        }
      });
  }

  private readCookie(name: string): string | undefined {
    const nameStr: string = name + "=",
      ca: Array<string> = document.cookie.split(";");

    for (let c of ca) {
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameStr) === 0) {
        return c.substring(nameStr.length, c.length);
      }
    }
    return undefined;
  }
}
