import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { ApiSessionService } from "../../../../../common/services/api/api-session.service";

export const PrephoneGuard = () => {
  const apiSession = inject(ApiSessionService);
  const router = inject(Router);

  if (!apiSession.isLoggedIn()) {
    return true;
  } else {
    router.navigate(["/home"]);
    return false;
  }
};
