<div class="call-list-item-header call-list-ellipsis">
  <p class="call-description call-list-ellipsis">
    <span>{{ displayStatus }}</span>
    @if (dialog.state === 'confirmed' && dialog.confirmedTime) {
    <onsip-timer [referencePoint]="dialog.confirmedTime"></onsip-timer>
    }
  </p>
  <p class="call-name call-list-ellipsis">{{ displayName }}</p>
</div>
@if (dialog.state === 'confirmed') {
<div class="call-list-dialog-footer">
  <button mat-button onsip-rounded-button (click)="endDialogCall()">
    <mat-icon>call_end</mat-icon>&nbsp;{{ "ONSIP_I18N.END" | translate }}
  </button>
</div>
}
