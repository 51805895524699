import { LogService } from "../logging/log.service";

import { StateEmitter } from "../../libraries/emitter/state-emitter";
import { Injectable } from "@angular/core";
import { distinctUntilChanged, filter, map, withLatestFrom } from "rxjs/operators";
import { Observable, Subscription, combineLatest } from "rxjs";

import { Contact } from "../../interfaces/contact";
import { UserSummaryContactWithAddresses } from "../api/resources/userSummaryContact/user-summary-contact";
import { UserCustomizationContact } from "../api/resources/userCustomization/user-customization-contact";
import { UUID } from "../../libraries/sip";
import { E164PhoneNumber } from "../../libraries/e164-phone-number";
import { URI } from "sip.js";
import { ApiSessionService, ApiSessionState } from "../api/api-session.service";
import { UserSummaryContactService } from "../api/resources/userSummaryContact/user-summary-contact.service";
import { UserCustomizationService } from "../api/resources/userCustomization/user-customization.service";
import { IdentityService, IdentityState } from "../identity.service";
import { NetworkService } from "../network.service";
import { UserCustomizationEditContactTypes } from "../api/resources/userCustomization/user-customization";
import { Role } from "../api/role";

const debug = false;

export interface ContactState {
  contacts: Array<Contact>;
}

@Injectable({ providedIn: "root" })
export class ContactService extends StateEmitter<ContactState> {
  private unsubscriber = new Subscription();
  private apiSessionState: ApiSessionState | undefined;
  private identityState: IdentityState | undefined;
  private loaded = false;
  private removeFastPass = false;

  /**
   * Compare contacts return true if all props are the same, false otherwise, exclude uuid
   * @param contact1 contact to compare
   * @param contact2 contact to compare
   */
  static compareContacts(contact1: Contact, contact2: Contact): boolean {
    return (
      contact1.aors.length === contact2.aors.length &&
      contact1.aors.every((v, i) => contact2.aors[i] === v) &&
      contact1.avatarUrl === contact2.avatarUrl &&
      contact1.contactList === contact2.contactList &&
      contact1.custom === contact2.custom &&
      ((!contact1.e164PhoneNumbers && !contact2.e164PhoneNumbers) ||
        (!!contact1.e164PhoneNumbers &&
          !!contact2.e164PhoneNumbers &&
          contact1.e164PhoneNumbers.length === contact2.e164PhoneNumbers.length &&
          contact1.e164PhoneNumbers.every(
            (v, i) => contact2.e164PhoneNumbers && contact2.e164PhoneNumbers[i] === v
          ))) &&
      contact1.email === contact2.email &&
      contact1.extensions.length === contact2.extensions.length &&
      contact1.extensions.every((v, i) => contact2.extensions[i] === v) &&
      contact1.favorite === contact2.favorite &&
      contact1.name === contact2.name &&
      contact1.userId === contact2.userId
    );
  }

  static createCustomPhoneContact(name: string, e164PhoneNumber: E164PhoneNumber): Contact {
    return {
      ...ContactService.createBasicCustomContact(name),
      e164PhoneNumbers: [e164PhoneNumber.e164Uri]
    };
  }

  static createCustomSIPContact(name: string, uri: URI): Contact {
    return {
      ...ContactService.createBasicCustomContact(name),
      aors: [uri.aor]
    };
  }

  static createBasicCustomContact(name: string): Contact {
    return {
      uuid: UUID.randomUUID(),
      custom: true,
      favorite: false,
      contactId: "",
      contactList: true,
      userId: "",
      name,
      email: "",
      aors: [],
      extensions: [],
      e164PhoneNumbers: [],
      avatarUrl: ""
    };
  }

  constructor(
    private log: LogService,
    private apiSession: ApiSessionService,
    private network: NetworkService,
    private identity: IdentityService,
    private userSummaryContact: UserSummaryContactService,
    private userCustomization: UserCustomizationService
  ) {
    super({
      contacts: []
    });
    this.subscribeToServices();
  }

  setRemoveFastPass(val: boolean): void {
    this.removeFastPass = val;
  }

  getRemoveFastPass(): boolean {
    return this.removeFastPass;
  }

  getContactList$(): Observable<Array<Contact>> {
    return this.state.pipe(
      map(({ contacts }) => contacts.filter(({ contactList }) => contactList))
    );
  }

  getFavoriteContacts$(): Observable<Array<Contact>> {
    return this.state.pipe(map(({ contacts }) => contacts.filter(({ favorite }) => favorite)));
  }

  getAllContactUserIds(): Observable<Array<string>> {
    return this.getContactList$().pipe(
      map(contacts => contacts.map(contact => contact.userId).filter(id => !!id)),
      distinctUntilChanged((a, b) => a.length === b.length && a.every((v, i) => b[i] === v))
    );
  }

  findDuplicate(
    contactList: Array<Contact>,
    custom: boolean,
    nameOrId?: string
  ): Contact | undefined {
    return contactList.find(
      existingContact =>
        (custom && nameOrId === existingContact.name) ||
        (!custom && nameOrId === existingContact.userId)
    );
  }

  find(uuid: string): Contact | undefined {
    return this.stateStore.contacts.find(contact => contact.uuid === uuid);
  }

  get selfItem(): Contact | undefined {
    if (!this.identityState) {
      throw new Error("selfItem was attempted to be obtained before identityState was set");
    }
    // In mobile looks like we ocassionally have an empty address array, so just return undefined
    if (!this.identityState.addresses.length) {
      return undefined;
    }
    const self = this.identityState.addresses[0];
    return this.findUsingAOR(`${self.username}@${self.domain}`);
  }

  findUsingExt(givenExt: string | undefined): Contact | undefined {
    return this.stateStore.contacts.find(contact => {
      const matchedExt = contact.extensions.find(ext => {
        return ext === givenExt;
      });
      return matchedExt !== undefined;
    });
  }

  findUsingName(givenName: string): Contact | undefined {
    return this.stateStore.contacts.find(contact => contact.name === givenName);
  }

  findUsingAOR(givenAOR: string): Contact | undefined {
    return this.stateStore.contacts.find(contact => {
      const matchedAOR = contact.aors.find(aor => {
        return aor === givenAOR;
      });
      return matchedAOR !== undefined;
    });
  }

  findUsingE164(givenE164: string): Contact | undefined {
    return this.stateStore.contacts.find(contact => {
      const matchedE164 = (contact.e164PhoneNumbers || []).find(e164 => {
        return e164 === givenE164;
      });
      return matchedE164 !== undefined;
    });
  }

  addToFavorites(contacts: Array<Contact>): Promise<void> {
    if (contacts.length === 0) return Promise.resolve();

    contacts = contacts.map(contact => {
      contact.favorite = true;
      return contact;
    });

    return this.userCustomization
      .editContact(UserCustomizationEditContactTypes.EDIT_FAVOURITES, contacts)
      .then(response => {
        console.log(response.status);
        if (response.status === "success") {
          return;
        }
        this.log.error("FavoritesService.addContacts: failed - " + response.data);
        throw response.data;
      });
  }

  /**
   * Remove contacts from the "Favorites" tab
   * @param uuids
   */
  removeFromFavorites(uuids: Array<string>): Promise<Array<Contact>> {
    const contacts: Array<Contact> = [];
    uuids.forEach(uuid => {
      const contact = this.find(uuid);
      if (contact) {
        contact.favorite = false;
        contacts.push(contact);
      }
    });
    if (contacts.length === 0) return Promise.resolve([]);

    this.userCustomization
      .editContact(UserCustomizationEditContactTypes.EDIT_FAVOURITES, contacts)
      .then(response => {
        console.log(response.status);

        if (response.status === "success") {
          return;
        }
        this.log.error("FavoritesService.removeContacts: failed - " + response.data);
        throw response.data;
      });
    return Promise.resolve(contacts);
  }

  /**
   * Add a custom contact.
   * @param contactName The name of the custom contact.
   * @param contact The contact to add. It must contain an AOR or e164PhoneNumber. The first found is used.
   */
  addCustomContact(contactName: string, contact: Contact): Promise<any> {
    debug && this.log.debug("ContactService.addCustomContact");
    const existingContact = this.stateStore.contacts.find(existContact => {
      return contact.name !== undefined && contact.name === existContact.name;
    });
    if (existingContact) {
      return Promise.reject({
        message: "Duplicate contact"
      });
    }
    return this.userCustomization
      .editContact(UserCustomizationEditContactTypes.ADD, [{ ...contact, name: contactName }])
      .then(add => {
        if (add.status === "success") {
          // find corresponding contact in api response, important if we want to edit this custom contact later
          const foundContact =
            add &&
            add.data &&
            add.data[Object.keys(add.data)[0]].userCustomizationContacts.find(
              contact => contact.contactName === contactName
            );
          if (!foundContact || !foundContact.userCustomizationContactId) {
            throw new Error("ContactService.addCustomContact: ContactId not found in response");
          }

          contact.contactId = foundContact.userCustomizationContactId;
          const storedContact = this.stateStore.contacts.find(
            ({ contactId }) => contactId === contact.contactId
          );
          if (storedContact) {
            storedContact.uuid = contact.uuid;
          } else {
            this.stateStore.contacts.push(contact);
          }
          this.publishState();
        } else {
          this.userCustomization.clearErrors();
          this.log.error("ContactService.addCustomContact: failed - " + add.data.message);
          throw add.data;
        }
      });
  }

  /**
   * Edit a custom contact. The same contact object will be editted in place with editParams
   * @param contact The contact to edit.
   * @param editParams Partial Contact object of desired edits, e.g. {name: "new-name", aors: ["sip:new@new.com"]}.
   */
  editCustomContact(edittedContact: Contact): Promise<void> {
    return this.userCustomization
      .editContact(UserCustomizationEditContactTypes.EDIT, [edittedContact])
      .then(response => {
        if (response.status === "error") {
          this.userCustomization.clearErrors();
          throw response.data;
        }
      });
  }

  addContact(additions: Array<Contact>): Promise<Array<Contact>> {
    return this.updateContactList(UserCustomizationEditContactTypes.ADD, additions);
  }

  removeContact(removals: Array<Contact>): Promise<Array<Contact>> {
    return this.updateContactList(UserCustomizationEditContactTypes.DELETE, removals);
  }

  private updateContactList(
    type: UserCustomizationEditContactTypes,
    contacts: Array<Contact>
  ): Promise<Array<Contact>> {
    if (contacts.length === 0) {
      return Promise.resolve([]);
    }

    return this.userCustomization.editContact(type, contacts).then(response => {
      if (response.status === "success") {
        return contacts;
      } else {
        this.userCustomization.clearErrors();
        throw response.data;
      }
    });
  }

  /**
   * Factory to make a Contact from the ContactInfo return from the API
   * @returns {Contact}
   */
  private makeContactFromContactInfo(
    contactName: string,
    contactInfo: UserCustomizationContact["contactInfo"]
  ): Contact | undefined {
    debug && this.log.debug("ContactService.makeContactFromContactInfo");
    const contact: Contact = ContactService.createBasicCustomContact("");

    if (!contactName) {
      debug && this.log.debug("ContactService.makeContactFromContactInfo: contactName is undef!");
      return undefined;
    }

    if (!contactInfo) {
      debug && this.log.debug("ContactService.makeContactFromContactInfo: ContactInfo is undef!");
      return undefined;
    }

    contact.name = contactName;

    if (contactInfo.contactPhone) {
      contact.e164PhoneNumbers ||= [];
      contact.e164PhoneNumbers.push(new E164PhoneNumber(contactInfo.contactPhone).e164Uri);
    } else if (contactInfo.contactSipAddress) contact.aors.push(contactInfo.contactSipAddress);
    else {
      debug && this.log.debug("ContactService: missing both phone number and sip-address");
      return undefined;
    }
    return contact;
  }

  /**
   * Factory to make a Contact from the UserSummary return from the API
   * @param contactUserId
   * @param userCustomzationContact
   * @param contactInfo include this only to determine if the contact is a favorite
   * @returns {Contact}
   */
  private makeContactFromUserCustomizationContact(
    contactUserId: string,
    userCustomzationContact: UserCustomizationContact
  ): Contact | undefined {
    debug && this.log.debug("ContactService.makeContactFromUserSummary");
    if (!contactUserId) {
      this.log.error("ContactService: contactUserId is undef. Failed to make Contact!");
      return undefined;
    }

    if (!userCustomzationContact) {
      this.log.error("ContactService: UserSummary is undef. Failed to make Contact!");
      return undefined;
    }

    const contact: Contact = {
      ...ContactService.createBasicCustomContact(""),
      custom: false,
      contactId: userCustomzationContact.userCustomizationContactId,
      avatarUrl: userCustomzationContact.userSummary?.userAvatarUrl ?? ""
    };

    contact.userId = contactUserId;
    contact.name =
      userCustomzationContact.userSummary?.contactName ?? userCustomzationContact.contactName ?? "";
    contact.email = userCustomzationContact.userSummary?.contactEmail ?? "";
    if (userCustomzationContact.userSummary?.contactPhone) {
      contact.e164PhoneNumbers ||= [];
      contact.e164PhoneNumbers.push(
        new E164PhoneNumber(userCustomzationContact.userSummary?.contactPhone).e164Uri
      );
    }
    if (userCustomzationContact.userSummary?.aliases) {
      userCustomzationContact.userSummary?.aliases.forEach(item => contact.extensions.push(item));
    }
    if (userCustomzationContact.userSummary?.e164Aliases) {
      userCustomzationContact.userSummary?.e164Aliases.forEach(item => {
        contact.e164PhoneNumbers ||= [];
        contact.e164PhoneNumbers.push(new E164PhoneNumber(item).e164Uri);
      });
    }
    if (userCustomzationContact.userSummary?.addresses) {
      userCustomzationContact.userSummary?.addresses.forEach(item =>
        contact.aors.push(item.address)
      );
    }
    return contact;
  }

  private init(): void {
    debug && this.log.info("ContactService.init");
    debug && this.state.subscribe(state => console.warn("ContactService State", state));

    /*
    Contacts, custom contacts, and favorites are not represented in an intuitive way by
    the API. This is an attempt to demistify how we are using them here:

    The issue is that the user_customization table is doing two things: it points to many entries
    in the user_customization_contact table. An entry in the user_customization_contact table
    is either a pointer to an existing user or itself a definition of a user-defined "custom"
    contact (ie a straight sip address or phone number). In other words, a user's customization
    defines a list of contacts, some of which point to existing users on our system, and others
    which define new, custom ones.

    However, in this app, we want a user's directory to contain all potential contacts that
    can be created from users in the organization, in addition to added custom contacts. To
    provide that appearance, we need to pull contacts from two API calls:
    UserSummaryContactBrowse (done in the OrgUSerService) and UserCustomizationRead. The UserSummaryContactBrowse pulls
    in a list of contacts which are generated from all of the existing users in the main
    user's organization (ie the user who is logged in). It would seem to follow that we
    could then pull the remaining custom contacts using the UserCustomizationRead (it does
    a read on the user_customization table), but that's not going to be sufficient.

    Those extra non-custom contacts from the UserCustomizationRead are (potentially) entries
    identifying that contact as a "favorite". That's information we can only gain from this
    particular read, as opposed to the UserSummaryContactBrowse. For that reason in particular,
    we must build a new Contact object from the second read, replacing the Contact object
    done in the first read. (The favorites service pulls its contacts from the directory
    service on adds, hence why it matters here.)
    */

    this.unsubscriber.add(
      combineLatest([
        this.userSummaryContact.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state))
        ),
        this.userCustomization.selfUser
      ])
        .pipe(
          filter(
            ([userSummaryContact, userCustomization]) =>
              !!userSummaryContact.length && !!userCustomization
          ),
          withLatestFrom(this.apiSession.state),
          filter(([, apiSession]) => {
            if (apiSession.parentUserId && apiSession.loggedInRole !== Role.SuperUser) {
              return false;
            }
            return true;
          })
        )
        .subscribe(([[userSummaryContact, userCustomization]]) => {
          const orgId = userCustomization.organizationId;
          const orgContacts = userSummaryContact.filter(
            contacts => contacts.organizationId === orgId
          );
          this.stateStore = this.updateStateStore(
            orgContacts.filter(({ status }) => !!status && status !== "disabled"),
            userCustomization.userCustomizationContacts
          );
          this.publishState();
        })
    );
  }

  private updateStateStore(
    userSummaryContacts: Array<UserSummaryContactWithAddresses>,
    customizationReadRes: Array<UserCustomizationContact>
  ): ContactState {
    const _directory: Array<Contact> = [];

    userSummaryContacts.forEach(userSummaryContact => {
      const aors = userSummaryContact.addresses?.map(item => item.address) || [];
      const e164PhoneNumbers = userSummaryContact.e164Aliases?.map(
        item => new E164PhoneNumber(item).e164Uri
      );

      // make sure we can create a contact from the user info, but if we've already populated the full directory at least once
      // use the duplicate contact from the full list of users so that  we don't get repeat UUIDs
      const duplicate = this.findDuplicate(
        this.stateStore.contacts,
        false,
        userSummaryContact.userId
      );
      if (!duplicate) {
        const contact: Contact = {
          ...ContactService.createBasicCustomContact(
            userSummaryContact.contactName ? userSummaryContact.contactName : ""
          ),
          contactList: false,
          custom: false,
          userId: userSummaryContact.userId,
          email: userSummaryContact.contactEmail ? userSummaryContact.contactEmail : "",
          aors,
          extensions: userSummaryContact.aliases,
          e164PhoneNumbers,
          avatarUrl: userSummaryContact.userAvatarUrl || ""
        };
        _directory.push(contact);
      } else {
        const contact: Contact = {
          uuid: duplicate.uuid,
          custom: false,
          favorite: false,
          contactId: duplicate.contactId,
          contactList: false,
          userId: userSummaryContact.userId,
          name: userSummaryContact.contactName ? userSummaryContact.contactName : "",
          email: userSummaryContact.contactEmail ? userSummaryContact.contactEmail : "",
          aors,
          extensions: userSummaryContact.aliases,
          e164PhoneNumbers,
          avatarUrl: userSummaryContact.userAvatarUrl || ""
        };
        _directory.push(contact);
      }
    });

    // here we understand which regular contacts are in contact list
    // and add custom contact to contact list
    customizationReadRes.forEach(user => {
      const custom = !!user.contactName && !user.userSummary && !!user.contactInfo;
      const favorite = !!(
        user.contactInfo &&
        user.contactInfo.contactIsAFavorite &&
        user.contactInfo.contactIsAFavorite !== ""
      );

      const createdContact = !user.userSummary
        ? this.makeContactFromContactInfo(user.contactName, user.contactInfo)
        : this.makeContactFromUserCustomizationContact(user.contactUserId, user);

      if (custom && createdContact) {
        createdContact.contactId = user.userCustomizationContactId;
        createdContact.custom = true;
        createdContact.favorite = favorite;
        const existingContact = this.stateValue.contacts.find(
          ({ contactId }) => contactId === createdContact.contactId
        );
        if (existingContact) {
          createdContact.uuid = existingContact.uuid;
        }
        _directory.push(createdContact);
      } else {
        const duplicateContact = this.findDuplicate(
          _directory,
          false,
          user.contactUserId !== "" ? user.contactUserId : user.contactName
        );

        if (duplicateContact) {
          duplicateContact.contactId = user.userCustomizationContactId;
          duplicateContact.contactList = true;
          duplicateContact.custom = custom;
          duplicateContact.favorite = favorite;
        }
      }
    });

    return { contacts: _directory };
  }

  private subscribeToServices(): void {
    this.unsubscriber.add(
      combineLatest([this.apiSession.state, this.identity.state]).subscribe({
        next: ([apiSession, identity]) => {
          // if we don't have any app state set yet, this is our first app state update
          if (!this.apiSessionState) this.apiSessionState = apiSession;
          if (!this.identityState) this.identityState = identity;

          // for mobile: if a user is logged out, clear the state contacts. then if they log out and switch accounts
          // then we won't have stored the old contacts
          if (!this.apiSessionState.loggedIn) this.stateStore.contacts = [];

          // if we logged out, clear favorites
          if (this.apiSessionState.loggedIn && !apiSession.loggedIn) {
            this.reset(apiSession, identity);
            return;
          }

          // update our copy of the state
          this.apiSessionState = apiSession;
          this.identityState = identity;

          // subscribe to loading the contacts
          if (this.apiSessionState.loggedIn && this.network.stateValue.online && !this.loaded) {
            this.log.debug(`ContactService  : ${this.constructor.name} load`);
            this.init();
            this.loaded = true;
          }
        },
        error: (error: unknown) => {
          this.log.error("ContactsService.subscribeApiSessionState " + error);
          throw error;
        },
        complete: () => {
          if (this.apiSession.isStateStopped) {
            const error = new Error(
              "ContactsService.subscribeApiSessionState state emitter completed unexpectedly"
            );
            this.log.error(error);
            throw error;
          }
        }
      })
    );
  }

  private reset(newApiSessionState: ApiSessionState, newIdentityState: IdentityState) {
    this.log.debug(`ContactService: ${this.constructor.name} unload`);
    this.apiSessionState = newApiSessionState;
    this.identityState = newIdentityState;
    this.stateStore = {
      contacts: []
    };
    this.loaded = false;
    this.publishState();
  }
}
