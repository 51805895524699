<onsip-avatar-frame [hasBreakpoints]="true">
  <onsip-avatar [avatarUrl]="contact?.avatarUrl" [name]="contact?.name"></onsip-avatar>
</onsip-avatar-frame>
@if (callState && !hideLocalInfo) {
<h1>{{ "ONSIP_I18N.CALL_WITH" | translate }} {{ nameOverride || callWithName }}</h1>
<div class="call-details">
  @if (saysoTarget && saysoCallFromWhere) { @if (!saysoTargetDisplay) {
  <h4 class="call-status-from">{{ "ONSIP_I18N.FROM" | translate }}: "{{ saysoCallFromWhere }}"</h4>
  } @if (saysoTargetDisplay) {
  <h4 class="call-status-from">
    {{ "ONSIP_I18N.FROM" | translate }}: "{{ saysoTargetDisplay }}" on
    <a target="_blank" [href]="saysoCallFromLink" tabindex="-1">{{ saysoCallFromWhere }}</a>
  </h4>
  } } @else { @if (!!callFromUri.length) {
  <h4 class="call-from-info">{{ "ONSIP_I18N.FROM" | translate }}: {{ callFromUri }}</h4>
  } }
  <h4 class="call-to-info">{{ "ONSIP_I18N.TO" | translate }}: {{ callToUri }}</h4>
</div>
}
