import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";

import { MaterialModule } from "../../modules/material/material.module";
import { phoneRoutingModule as PhoneRoutingModule } from "./phoneRouting.module";
import { PipesModule } from "../shared/pipes/pipes.module";

import { CallHistoryModule } from "../shared/components/callHistory/call-history.module";
import { CallModule } from "../call/call.module";
import { ChatModule } from "../shared/components/chat/chat.module";
import { ContactModule } from "../contact/contact.module";
import { FreeTrialModule } from "../shared/components/freeTrial/free-trial.module";
import { ModalModule } from "../shared/components/modal/modal.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { QueueModule } from "../queue/queue.module";
import { SidebarModule } from "../shared/components/sidebar/sidebar.module";
import { UsabilityModule } from "../shared/components/usability/usability.module";
import { AppSettingsModule } from "../appSettings/app-settings.module";
import { VideoConferenceModule } from "../videoConference/video-conference.module";
import { VoicemailModule } from "../voicemail/voicemail.module";
import { WarningBarModule } from "../shared/components/warningBar/warning-bar.module";
import { PopupContainerModule } from "../shared/components/popupContainer/popup-container.module";
import { AnimationModule } from "../shared/components/animation/animation.module";
import { SnackbarModule } from "../shared/components/snackbar/snackbar.module";
import { DeveloperModule } from "../developer/developer.module";

import { HomeMainComponent } from "./home/home-main.component";
import { PhoneComponent } from "./phone.component";
import { LoadingDialogComponent } from "./loading-dialog.component";
import { AdminSupportModule } from "../adminSupport/admin-support.module";
import { AgentAdminModule } from "../agentAdmin/agent-admin.module";
import { SuperUserModule } from "../superUser/super-user.module";
import { SuperAdminToolbarModule } from "./superAdminToolbar/super-admin-toolbar.module";
import { OnsipFooterModule } from "./footer/footer.module";
import { ManageVoicemailsComponent } from "./manage-voicemails/manage-voicemails.component";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DeveloperModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CallHistoryModule,
    CallModule,
    ChatModule,
    ContactModule,
    FreeTrialModule,
    ModalModule,
    NewCallModule,
    PopupContainerModule,
    QueueModule,
    SidebarModule,
    UsabilityModule,
    VideoConferenceModule,
    VoicemailModule,
    WarningBarModule,
    PhoneRoutingModule,
    VideoConferenceModule,
    TranslateModule,
    PipesModule,
    AnimationModule,
    SnackbarModule,
    AppSettingsModule,
    AdminSupportModule,
    AgentAdminModule,
    SuperUserModule,
    SuperAdminToolbarModule,
    OnsipFooterModule,
    ManageVoicemailsComponent
  ],
  declarations: [HomeMainComponent, PhoneComponent, LoadingDialogComponent],
  exports: [PhoneComponent]
})
export class PhoneModule {}
