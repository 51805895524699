import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { parse as xmlParse } from "fast-xml-parser";

@Component({
  selector: "onsip-system-status",
  templateUrl: "./system-status.component.html",
  styleUrls: ["./system-status.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemStatusComponent implements OnInit {
  systemStatus = new BehaviorSubject<string>("loading");

  ngOnInit(): void {
    this.initStatus();
  }

  initStatus(): void {
    const apiUrl: string = "https://api.onsip.com/api".replace(/\/api$/g, ""),
      statusXHR: XMLHttpRequest = new XMLHttpRequest();

    statusXHR.onreadystatechange = () => {
      if (statusXHR.readyState === 4) {
        if (statusXHR.status !== 200) {
          this.systemStatus.next("bad");
          return;
        }
        try {
          const data = xmlParse(
            new XMLSerializer().serializeToString(statusXHR.responseXML as Node)
          );
          if (!data) {
            throw new Error("could not parse xml");
          }

          let title: string;
          // XML looks like rss -> channel -> item -> title -> <string we care about
          title = data.rss.channel.item.title;
          // now we have "Network Status: <words that matter>"
          title = title.split(": ")[1];
          if (title === "All systems go") {
            this.systemStatus.next("good");
          } else {
            this.systemStatus.next("bad");
          }
        } catch (e) {
          // this generally happens when the server can't be reached
          // as this is not indicative of a service advisory, we'll leave it blank
          this.systemStatus.next("");
        }
      }
    };

    statusXHR.open("GET", apiUrl + "/system_status.php?feed=true");
    statusXHR.send();
  }
}
