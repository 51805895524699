import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { filter, map } from "rxjs/operators";

import { DialpadService } from "./dialpad.service";

const dialpadButtons = [
  { number: "1", letters: "" },
  { number: "2", letters: "ABC" },
  { number: "3", letters: "DEF" },
  { number: "4", letters: "GHI" },
  { number: "5", letters: "JKL" },
  { number: "6", letters: "MNO" },
  { number: "7", letters: "PQRS" },
  { number: "8", letters: "TUV" },
  { number: "9", letters: "WXYZ" },
  { number: "*", letters: "" },
  { number: "0", letters: "OPER" },
  { number: "#", letters: "" }
];

@Component({
  selector: "onsip-dialpad",
  templateUrl: "./dialpad.component.html",
  styleUrls: ["./dialpad.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialpadComponent implements OnInit, OnDestroy {
  @Input() target: "newCall" | "call" = "call";
  @Input() showHistory = false;

  dialpadButtons = dialpadButtons;
  history = new BehaviorSubject<string>("");

  private unsubscriber = new Subscription();

  constructor(private dialpadService: DialpadService) {}

  ngOnInit() {
    this.unsubscriber.add(
      this.dialpadService.event
        .pipe(
          filter(event => event.type === "press"),
          map(event => event.key)
        )
        .subscribe(key => this.history.next(this.history.value + key))
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
