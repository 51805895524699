import { CallEvent, EventId } from "./call-event";

/** Emitted once when the Call is deactivated just prior to being destroyed. */
export class LastCallEvent extends CallEvent {
  id = EventId.LastCallEventId;

  /**
   * Factory for LastCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isLastCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for LastCallEvent.
 * @param event Event to check.
 */
export function isLastCallEvent(event: any): event is LastCallEvent {
  return event instanceof LastCallEvent;
}
