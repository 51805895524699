import { Support } from "./support";

import { LogService } from "../../../common/services/logging";

import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class SupportService extends Support {
  // PLEASE NOTE: until the getters are used everywhere, these were made not-private until this service
  // becomes platform-independent, as many files will change
  constructor(log: LogService) {
    const supportIosSafari = true;

    super(log, supportIosSafari);
  }
}
