import { URI, Grammar } from "sip.js";

export class OnSIPURI extends URI {
  /**
   * Returns an OnSIPURI if successfully parsed, undefined otherwise.
   * @param uri URI to parse.
   */
  static parseString(uri: string): OnSIPURI | undefined {
    const _uri = Grammar.URIParse(uri);
    if (_uri && _uri.scheme && _uri.user && _uri.host) {
      // hack
      const sipuri = new OnSIPURI(_uri.scheme, _uri.user, _uri.host, undefined);
      return sipuri;
    } else {
      return undefined;
    }
  }

  static isPhoneNumberGivenString(givenStr: string): boolean {
    // taken from core proxy config
    const onsipPhoneNumberRegex =
      /^((\+|011)?(1[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4})|((\+|011)[2-9][0-9]{3,14}))$/;
    return onsipPhoneNumberRegex.test(givenStr);
  }

  isAnonymous(): boolean {
    // Note: uri.aor could look like
    // "anonymous.106660*38.104.167.182!42305_n@199.7.173.103;transport=ws;nat=yes;gr;ocn=videoconferenceeeeeee;ocp=charmander"
    return (
      this.host === "anonymous.invalid" || (!!this.user && this.user.slice(0, 9) === "anonymous")
    );
  }

  isEmergency(domain?: string): boolean {
    if (domain && domain !== this.host) {
      return false;
    }
    return this.user === "911";
  }

  isExtension(domain?: string): boolean {
    if (!this.user || (domain && domain !== this.host)) {
      return false;
    }
    return /^\d{2,6}$/.test(this.user);
  }

  isInternationalPhoneNumber(domain?: string): boolean {
    if (this.user && this.isPhoneNumber(domain)) {
      const onsipInternationalPhoneNumberPrefixRegex = /^(\+|011)[2-9]/;
      return onsipInternationalPhoneNumberPrefixRegex.test(this.user);
    }
    return false;
  }

  isPhoneNumber(domain?: string): boolean {
    if (!this.user || (domain && domain !== this.host && this.host !== "jnctn.net")) {
      return false;
    }
    // taken from core proxy config
    const onsipPhoneNumberRegex =
      /^((\+|011)?(1[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4})|((\+|011)[2-9][0-9]{3,14}))$/;
    return onsipPhoneNumberRegex.test(this.user);
  }

  // 711 telephone number is used for the Telecommunications Relay Service
  isRelay(uri: string, domain?: string): boolean {
    if (!this.user || (domain && domain !== this.host)) {
      return false;
    }
    return this.user === "911";
  }
}
