import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AgentAdminGuard } from "../shared/guards/agentAdminGuard.guard";
import { AgentAdminViewComponent } from "./agent-admin-view.component";
import { AgentAdminAccountsPageComponent } from "./agentAccountsPage/agent-accounts-page.component";
import { AgentAdminCommissionsPageComponent } from "./agentCommissionsPage/agent-commissions-page.component";
import { AgentAdminSupportPageComponent } from "./agentSupportPage/agent-support-page.component";
import { AgentAdminAccountsCreateAccountComponent } from "./agentAccountsPage/agentCreateAccount/agent-accounts-create-account.component";

const routes: Routes = [
  {
    path: "agent-view",
    component: AgentAdminViewComponent,
    children: [
      {
        path: "",
        canActivate: [AgentAdminGuard],
        pathMatch: "full",
        component: AgentAdminViewComponent
      },
      {
        path: "accounts",
        canActivate: [AgentAdminGuard],
        pathMatch: "full",
        component: AgentAdminAccountsPageComponent
      },
      {
        path: "commissions",
        canActivate: [AgentAdminGuard],
        pathMatch: "full",
        component: AgentAdminCommissionsPageComponent
      },
      {
        path: "support",
        canActivate: [AgentAdminGuard],
        pathMatch: "full",
        component: AgentAdminSupportPageComponent
      },
      {
        path: "create-account",
        canActivate: [AgentAdminGuard],
        pathMatch: "full",
        component: AgentAdminAccountsCreateAccountComponent
      }
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)]
})
export class AgentAdminRoutingModule {}
