import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserModule } from "@angular/platform-browser";
import { LayoutModule } from "../../shared/components/layout/layout.module";
import { LoadingModalModule } from "../../shared/components/loadingModal/loading-modal.module";
import { SnackbarModule } from "../../shared/components/snackbar/snackbar.module";
import { SuperUserAdvancedSearchModule } from "../superUserAdvancedSearch/super-user-advanced-search.module";
import { SuperUserTableModule } from "../superUserTable/super-user-table.module";
import { SuperUserSearchComponent } from "./super-user-search.component";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    MatButtonModule,
    MatRadioModule,
    MatIconModule,
    ReactiveFormsModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    LayoutModule,
    SnackbarModule,
    SuperUserAdvancedSearchModule,
    SuperUserTableModule,
    LoadingModalModule
  ],
  declarations: [SuperUserSearchComponent],
  exports: [SuperUserSearchComponent]
})
export class SuperUserSearchModule {}
