import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { RouterModule } from "@angular/router";
import { SnackbarModule } from "../../shared/components/snackbar/snackbar.module";
import { TableModule } from "../../shared/components/table/onsip-table.module";
import { SuperUserTableComponent } from "./super-user-table.component";

@NgModule({
  imports: [
    CommonModule,
    TableModule,
    MatSortModule,
    MatPaginatorModule,
    RouterModule,
    MatTableModule,
    MatProgressSpinnerModule,
    SnackbarModule
  ],
  declarations: [SuperUserTableComponent],
  exports: [SuperUserTableComponent]
})
export class SuperUserTableModule {}
