<mat-list>
  @if (listItems.length) { @for (item of listItems; track item) {
  <mat-list-item>
    <p matLine>
      <span class="mat-body-1">{{ item.name }}</span>
    </p>
  </mat-list-item>
  } } @else {
  <mat-list-item>
    <p matLine>
      <span class="mat-body-2">{{
        "ONSIP_I18N.NO_VALUES" | translate : { values: itemNamePlural }
      }}</span>
    </p>
  </mat-list-item>
  }
</mat-list>
