import { DialpadComponent } from "./dialpad.component";
import { DialpadButtonComponent } from "./button/dialpad-button.component";
import { DialpadInputDirective } from "./dialpad-input.directive";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule],
  declarations: [DialpadComponent, DialpadButtonComponent, DialpadInputDirective],
  exports: [DialpadComponent, DialpadInputDirective]
})
export class DialpadModule {}
