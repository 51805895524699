import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "./xml-json";

export declare interface ApiUserInfo {
  OnboardingInfo?: {
    click_contact: string;
    make_call: string;
  };
  PersonalPageInfo?: {
    Blurb: string;
    Linkedin: string;
    Facebook: string;
    Twitter: string;
    Title: string;
  };
  Sayso?: {
    Onboarding: string;
    ShowAnalytics: OnsipAPIBoolean;
  };
}

export declare interface UserInfo {
  onboardingInfo?: {
    click_contact?: string;
    make_call?: string;
  };
  personalPageInfo: {
    blurb: string;
    linkedin: string;
    facebook: string;
    twitter: string;
    title: string;
  };
  sayso?: {
    onboarding?: string;
    showAnalytics?: boolean;
  };
}

export function apiUserInfoToUserInfo(apiUserInfo: ApiUserInfo): UserInfo {
  return {
    onboardingInfo: {
      click_contact: apiUserInfo.OnboardingInfo?.click_contact,
      make_call: apiUserInfo.OnboardingInfo?.make_call
    },
    personalPageInfo: {
      blurb: apiUserInfo.PersonalPageInfo?.Blurb || "",
      linkedin: apiUserInfo.PersonalPageInfo?.Linkedin || "",
      facebook: apiUserInfo.PersonalPageInfo?.Facebook || "",
      twitter: apiUserInfo.PersonalPageInfo?.Twitter || "",
      title: apiUserInfo.PersonalPageInfo?.Title || ""
    },
    sayso: {
      onboarding: apiUserInfo.Sayso?.Onboarding,
      showAnalytics: onsipApiBooleanToBoolean(apiUserInfo.Sayso?.ShowAnalytics || "false")
    }
  };
}
