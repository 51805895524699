export interface ApiVoicemail {
  CallerId: string;
  URI: string;
  State: string;
  CreatedAt: string;
  FileType: string;
  Folder: "NEW" | "OLD" | "DELETED";
  Duration: string;
  Path: string;
  MetadataId: string;
}

export interface Voicemail {
  callerId: string;
  uri: string;
  state: string;
  createdAt: string;
  fileType: string;
  folder: "NEW" | "OLD" | "DELETED";
  duration: string;
  path: string;
  metadataId: string;
}

export function apiVoicemailToVoicemail(apiVoicemail: ApiVoicemail): Voicemail {
  return {
    callerId: apiVoicemail.CallerId,
    uri: apiVoicemail.URI,
    state: apiVoicemail.State,
    createdAt: apiVoicemail.CreatedAt,
    fileType: apiVoicemail.FileType,
    folder: apiVoicemail.Folder,
    duration: apiVoicemail.Duration,
    path: apiVoicemail.Path,
    metadataId: apiVoicemail.MetadataId
  };
}

export function voicemailToApiVoicemail(apiVoicemail: Voicemail): ApiVoicemail {
  return {
    CallerId: apiVoicemail.callerId,
    URI: apiVoicemail.uri,
    State: apiVoicemail.state,
    CreatedAt: apiVoicemail.createdAt,
    FileType: apiVoicemail.fileType,
    Folder: apiVoicemail.folder,
    Duration: apiVoicemail.duration,
    Path: apiVoicemail.path,
    MetadataId: apiVoicemail.metadataId
  };
}
