import { Injectable } from "@angular/core";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import {
  ApiAddAction,
  ApiBrowseAction,
  ApiDeleteAction,
  ApiEditAction,
  ApiReadAction
} from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { ParameterValue } from "../../util/api-action-description";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import {
  AddAdvAttendantMenuParams,
  AdvAttendantMenu,
  ApiAdvAttendantMenu,
  apiAdvAttendantMenuToAdvAttendantMenu as clean,
  EditAdvAttendantMenuParams
} from "./attendant-menu";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { domain } from "../../apiParams/domain";

export { AdvAttendantMenu };

const debug = false;

@Injectable({ providedIn: "root" })
export class AdvAttendantMenuService extends ApiResourceService<AdvAttendantMenu> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "AdvAttendantMenu", "advAttendantMenuId");
    debug && this.state.subscribe(state => console.warn("AdvAttendantMenuService", state));
  }
  advAttendantMenuBrowse(
    extraParams: Record<string, ParameterValue> = {}
  ): ApiPromiseState<AdvAttendantMenu> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AdvAttendantMenuBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 2500, // should fetch all
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AdvAttendantMenuBrowse);
  }

  advAttendantMenuRead(advAttendantMenuId: string) {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.AdvAttendantMenuRead,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        advAttendantMenuId
      }
    });
    return this.promiseState.toPromise(ApiReadAction.AdvAttendantMenuRead);
  }

  advAttendantMenuEdit(params: EditAdvAttendantMenuParams) {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AdvAttendantMenuEdit,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AdvAttendantMenuEdit);
  }

  advAttendantMenuAdd(params: AddAdvAttendantMenuParams) {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.AdvAttendantMenuAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.AdvAttendantMenuAdd);
  }

  advAttendantMenuDelete(AdvAttendantMenuId: string) {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.AdvAttendantMenuDelete,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        AdvAttendantMenuId
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.AdvAttendantMenuDelete);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.AdvAttendantMenuBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiAdvAttendantMenu>>(
              response,
              action,
              "AdvAttendantMenu",
              "AdvAttendantMenus"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiReadAction.AdvAttendantMenuRead:
      case ApiAddAction.AdvAttendantMenuAdd:
      case ApiEditAction.AdvAttendantMenuEdit:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiAdvAttendantMenu>(response, action, "AdvAttendantMenu")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.AdvAttendantMenuDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedAttMenu = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "AdvAttendantMenuId")?.Value;
        deletedAttMenu &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedAttMenu]: undefined }, action);
        break;
    }
  }
}
