export interface QueueSummaryParams {
  AppId: string;
  EndDateTime: string;
  Limit: number;
  OrganizationId: string;
  StartDateTime: string;
}

export interface ApiQueueSummary {
  AppId: string;
  AvgAbandonWaitDuration: string;
  AvgAnsweredCallDuration: string;
  AvgWaitDuration: string;
  BusiestTimeOfDay: string;
  CallWithMaxWaitDuration: string;
  CallsAbandoned: string;
  CallsAnswered: string;
  CallsInQueue: string;
  CallsManuallyFailedOver: string;
  MaxCallersFailoverCalls: string;
  MaxCallersRejectCalls: string;
  MaxWaitCalls: string;
  MaxWaitDuration: string;
  MostAbandonedTimeOfDay: string;
  NoAgentFailoverCalls: string;
  NoAgentRejectCalls: string;
  QueueFailureAbandonedCalls: string;
  QueueFailureAnsweredCalls: string;
}

export interface QueueSummary {
  appId: string;
  avgAbandonWaitDuration: string;
  avgAnsweredCallDuration: string;
  avgWaitDuration: string;
  busiestTimeOfDay: string;
  callWithMaxWaitDuration: string;
  callsAbandoned: string;
  callsAnswered: string;
  callsInQueue: string;
  callsManuallyFailedOver: string;
  maxCallersFailoverCalls: string;
  maxCallersRejectCalls: string;
  maxWaitCalls: string;
  maxWaitDuration: string;
  mostAbandonedTimeOfDay: string;
  noAgentFailoverCalls: string;
  noAgentRejectCalls: string;
  queueFailureAbandonedCalls: string;
  queueFailureAnsweredCalls: string;
}

export function ApiQueueSummaryToQueueSummary(apiQueueSummary: ApiQueueSummary): QueueSummary {
  return {
    appId: apiQueueSummary.AppId,
    avgAbandonWaitDuration: apiQueueSummary.AvgAbandonWaitDuration,
    avgAnsweredCallDuration: apiQueueSummary.AvgAnsweredCallDuration,
    avgWaitDuration: apiQueueSummary.AvgWaitDuration,
    busiestTimeOfDay: apiQueueSummary.BusiestTimeOfDay,
    callWithMaxWaitDuration: apiQueueSummary.CallWithMaxWaitDuration,
    callsAbandoned: apiQueueSummary.CallsAbandoned,
    callsAnswered: apiQueueSummary.CallsAnswered,
    callsInQueue: apiQueueSummary.CallsInQueue,
    callsManuallyFailedOver: apiQueueSummary.CallsManuallyFailedOver,
    maxCallersFailoverCalls: apiQueueSummary.MaxCallersFailoverCalls,
    maxCallersRejectCalls: apiQueueSummary.MaxCallersRejectCalls,
    maxWaitCalls: apiQueueSummary.MaxWaitCalls,
    maxWaitDuration: apiQueueSummary.MaxWaitDuration,
    mostAbandonedTimeOfDay: apiQueueSummary.MostAbandonedTimeOfDay,
    noAgentFailoverCalls: apiQueueSummary.NoAgentFailoverCalls,
    noAgentRejectCalls: apiQueueSummary.NoAgentRejectCalls,
    queueFailureAbandonedCalls: apiQueueSummary.QueueFailureAbandonedCalls,
    queueFailureAnsweredCalls: apiQueueSummary.QueueFailureAnsweredCalls
  };
}
