import { take, filter } from "rxjs/operators";
import {
  name,
  version,
  URI,
  Invitation,
  Inviter,
  Message,
  Messager,
  Notification,
  UserAgent as UserAgentSIPJS,
  RegistererState,
  Registerer,
  UserAgentOptions,
  SIPExtension,
  SessionDescriptionHandlerFactory,
  RequestPendingError,
  TransportState,
  RegistererOptions,
  Web
} from "sip.js";

import { SessionSIP } from "./session-sip";
import { UserAgent, UserAgentDelegate } from "./user-agent";
import { UnregisteredUserAgentEvent } from "./user-agent-event";
import { log } from "./log";
import { exponentialBackoff } from "../exponential-backoff";

const SUB_CONTACT_INSTANCE_PARAM = "instance";

/**
 * A SIP UserAgent
 */
export class UserAgentSIP extends UserAgent {
  registerer!: Registerer;

  private refreshInProgress = false;
  private userAgent!: UserAgentSIPJS;

  /**
   * Creates a new SIP UserAgent.
   * @param configuration The UserAgent's configuration.
   * @param delegate The UserAgent's delegate to handling incoming sessions.
   */
  constructor(
    configuration: UserAgentOptions,
    registerOptions: RegistererOptions,
    delegate: UserAgentDelegate | undefined,
    private sessionDescriptionHandlerFactory: SessionDescriptionHandlerFactory | undefined
  ) {
    super(configuration, registerOptions, delegate);
    this.init();
  }

  /** Stops and disposes of current SIP.js UserAgent object. Rejects already when in progress.
   *  @returns Promise when old object has been cleaned up, new one created and started.
   */
  refresh(): Promise<void> {
    if (this.refreshInProgress) {
      return Promise.reject("UserAgentSIP: refresh called while initialization in progress");
    }
    this.refreshInProgress = true;
    this.stateStore.stopped = true;
    return this.stop()
      .then(() => {
        this.stateStore.stopped = false;
        this.init();
        return this.start();
      })
      .finally(() => {
        this.refreshInProgress = false;
      });
  }

  /**
   * Disposes of the UserAgent.
   * @param delay The number of seconds to wait before stopping the user agent
   * (provides it time to finish handling responses to outstanding requests). Defaults to 2 seconds.
   */
  dispose(delay: number = 2000): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].dispose ${this.parent ? "(child)" : "(parent)"}`);
    return super.dispose(delay);
  }

  /**
   * Sends an INVITE.
   * @param session The Session on which to send the INVITE.
   */
  invite(session: SessionSIP): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].invite ${this.parent ? "(child)" : "(parent)"}`);
    if (!this.connected) {
      return Promise.reject(new Error("Not connected."));
    }
    const options = SessionSIP.makeInviteOptions(session);
    const inviter = new Inviter(this.userAgent, session.remoteUri, options);
    session.SIP = inviter;
    return session.invite();
  }

  /**
   * Sends a message.
   * @param uri The URI identifying the Message peer.
   * @param body The body of the Message.
   * @param contentType The contentType of Message.
   * @param headers headers to add (can be omitted)
   */
  message(uri: URI, body: string, contentType: string, headers: Array<string> = []): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].message`);
    if (!this.connected) {
      return Promise.reject(new Error("Not connected."));
    }
    const messager = new Messager(this.userAgent, uri, body, contentType, {
      extraHeaders: headers
    });
    return messager.message();
  }

  /**
   * Registers the UserAgent.
   */
  register(): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].register ${this.parent ? "(child)" : "(parent)"}`);
    if (!this.connected) {
      return Promise.reject(new Error("Not connected."));
    }
    return this.registerer
      .register()
      .then(() => {
        log.debug("Register: Successfully sent the register request");
        /**
         * **HACK** Another issue where abrupt websocket disconnect leaves OnSIP App state
         * different from the registerers state. We must rectify it after a register
         * Note: doing this on then is not good because it doesn't mean the register succeeded
         * but the hope is that if it fails the registerer will correct the onsip app state
         */
        if (this.registerer.state === RegistererState.Registered && !this.registered) {
          this.onRegistered(this.registerer.contacts.length);
        }
      })
      .catch((error: any) => {
        if (error instanceof RequestPendingError) {
          log.warn("Register: Calling Register when it's already in progress");
        } else {
          Promise.reject(error);
        }
      });
  }

  /**
   * Starts the UserAgent.
   */
  start(): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].start ${this.parent ? "(child)" : "(parent)"}`);
    // TODO: Transport Connection can timeout, but we are not able to catch the error...
    return this.userAgent.start();
  }

  /**
   * Stops the UserAgent.
   */
  stop(): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].stop ${this.parent ? "(child)" : "(parent)"}`);
    return this.userAgent.stop();
  }

  /**
   * Reconnects the UserAgent.
   */
  reconnect(): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].reconnect ${this.parent ? "(child)" : "(parent)"}`);
    return exponentialBackoff(
      () => this.userAgent.reconnect(),
      [],
      "UserAgentSIP.reconnect",
      log,
      true,
      true,
      5
    );
  }

  /**
   * Unregisters the UserAgent.
   */
  unregister(): Promise<void> {
    log.debug(`UserAgentSIP[${this.aor}].unregister ${this.parent ? "(child)" : "(parent)"}`);
    // can send unregister even if not registered (push invite handling depends on this)
    if (!this.connected) {
      return Promise.reject(new Error("Not connected."));
    }

    // as of 0.15.8 (new api), unregister resolves immediately, so this is still necessary)
    return new Promise<void>((resolve, reject) => {
      this.event
        .pipe(
          filter(e => e.id === UnregisteredUserAgentEvent.id),
          take(1)
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: (error: unknown) => {
            if (error instanceof RequestPendingError) {
              log.warn("Register: Calling Register when it's already in progress");
              resolve();
            } else {
              reject(error);
            }
          }
        });
      this.registerer.unregister().catch((error: unknown) => {
        if (error instanceof RequestPendingError) {
          log.warn("Register: Calling Register when it's already in progress");
          resolve();
        } else {
          reject(error);
        }
      });
    });
  }

  // used in push.ts, dont use elsewhere
  hackHasSession(callId: string, fromTag: string): boolean {
    return this.hasSession(callId, fromTag);
  }

  getSDHForPush(): SessionDescriptionHandlerFactory | undefined {
    return this.sessionDescriptionHandlerFactory;
  }

  /** The SIP.js UA associated with the UserAgent */
  get SIP(): UserAgentSIPJS {
    return this.userAgent;
  }

  private init(): void {
    log.debug(`UserAgentSIP[${this.aor}].init ${this.parent ? "(child)" : "(parent)"}`);

    log.debug(
      `UserAgentSIP.init regId=${this.registerOptions.regId} ${
        this.parent ? "(child)" : "(parent)"
      }`
    );

    if (this.registerer) this.registerer.dispose();

    this.registerOptions.regId =
      this.registerOptions.regId !== undefined ? this.registerOptions.regId : 1;

    const fullUserAgentOptions = this.addDefaultUserAgentValues(this.configuration);
    fullUserAgentOptions.delegate = {
      onInvite: (invitation: Invitation) => {
        log.debug(`UserAgentSIP[${this.aor}].onInvite ${this.parent ? "(child)" : "(parent)"}`);
        this.handleOnInvite(invitation);
      },
      onMessage: (message: Message) => {
        log.debug(`UserAgentSIP[${this.aor}].onMessage`);
        this.handleOnMessage(message);
      },
      onNotify: (notification: Notification) => {
        log.debug(`UserAgentSIP[${this.aor}].onNotify`);
        if (notification.request.getHeader("event") !== "message-summary") {
          return;
        }
        this.onVoicemailMessageSummaryNotification(notification.request.body);
      },
      onConnect: () => {
        this.initRegisterer();
        this.onConnect();
      },
      onDisconnect: (error: Error) => {
        if (error) log.error(`UserAgentSIP.onDisconnect: ${error.message}`);
        this.onDisconnect();
      }
    };

    this.userAgent = new UserAgentSIPJS(fullUserAgentOptions);

    /* BEGIN FIXME: Update: this used to be in subscribe, which was moved out, but why is still unanswered
    // Why is this being done?
    // And assuming there is a good reason why it is being done, why is this being done here?
    // This appears to be a terrible place to be setting the uri of the contact based on configuration parameters, no?
    */
    const contact = this.userAgent.contact.uri;
    if (this.registerOptions && this.registerOptions.instanceId) {
      contact.setParam(SUB_CONTACT_INSTANCE_PARAM, this.registerOptions.instanceId);
    }
    this.userAgent.contact.uri = contact;
    // END FIXME

    this.userAgent.transport.stateChange.addListener((newState: TransportState) => {
      if (newState === TransportState.Connecting) {
        this.onConnecting();
      }
    });
  }

  private initRegisterer(): void {
    if (this.registerer) return; // if we are just reconnecting, no need for new registerer
    this.registerer = new Registerer(this.userAgent, this.registerOptions);
    this.registerer.stateChange.addListener((newState: RegistererState) => {
      log.debug(
        `UserAgentSIP[${this.aor}] state changed to ${newState} ${
          this.parent ? "(child)" : "(parent)"
        }`
      );
      switch (newState) {
        case RegistererState.Registered:
          this.onRegistered(this.registerer.contacts.length);
          break;
        case RegistererState.Unregistered:
          this.onUnregistered("unknown");
          break;
        case RegistererState.Terminated:
          break;
        default:
          throw new Error("Unexpected state change.");
      }
    });
  }

  private handleOnInvite(session: Invitation): void {
    log.debug(`UserAgentSIP[${this.aor}].handleOnInvite`);
    if (!this.delegate) {
      session.reject();
      return;
    }

    if (!this.handleOnInviteRaceWinner(session)) {
      return;
    }

    let localUriString: string | undefined;
    if (session.localIdentity.uri) localUriString = session.localIdentity.uri.toString();
    const incomingSession = new SessionSIP(
      session.localIdentity.displayName,
      localUriString,
      session.remoteIdentity.displayName,
      session.remoteIdentity.uri,
      this
    );
    incomingSession.setIncoming(true);
    incomingSession.setAudioAvailable(
      typeof session.body === "string" && session.body.indexOf("m=audio") !== -1
    );
    incomingSession.setAudioExpected(true); // FIXME: this is an invalid assumption... need equivilant for X-Vid
    incomingSession.setVideoAvailable(
      typeof session.body === "string" && session.body.indexOf("m=video") !== -1
    );
    incomingSession.setVideoExpected(
      incomingSession.videoAvailable ||
        (session.request && session.request.getHeader("X-Vid") === "1") // Proprietary OnSIP Header
    );
    if (session.request) {
      // Proprietary OnSIP Headers
      const ouid: string | undefined = session.request.getHeader("P-OUID");
      incomingSession.ouid = ouid;
      const xdata: string | undefined = session.request.getHeader("X-Data");
      if (xdata) {
        incomingSession.setXData(xdata);
      }
    }

    // INVITE with Replaces
    if (session.replacee) {
      // Activate the incoming session.
      // Nothing outside this class will be subscribed the session at this point,
      // so the events will go nowhere, but we are depending on that behavior here.
      // In this scenario as we are replacing the SIP.js session associated with a
      // Session which as already been activated (and not yet ended), so we want the
      // replacement SIP.js session to get swapped in a manner where the events emitted
      // by the existing Session continue as though the SIP.js session was never replaced.
      //
      // TODO: This is a little hacky as we are unilaterally deciding how the INVITE will be handled.
      // We are swapping the existing SIP.js session out and replacing it with the new incoming session
      // without giving the Session consumer any say in the matter. For the current use cases this
      // is ok, but there are use cases where this would not be satisfactory. For instance, the
      // incoming session cannot be immediately declined (a 18x proceeding will always be sent).
      incomingSession.SIP = session;
      const replacee = session.replacee.data;
      if (replacee instanceof SessionSIP) {
        incomingSession.setAudio(replacee.audio);
        incomingSession.setAudioRequested(replacee.audio || replacee.audioRequested);
        incomingSession.setVideo(replacee.video);
        incomingSession.setVideoRequested(replacee.video || replacee.videoRequested);
        replacee.replace(incomingSession).then(() => incomingSession.accept());
      } else {
        session.reject();
        throw new Error("SIP.js session.replacee.data not instance of SessionSIP.");
      }
      return;
    }

    this.delegate.handleIncomingSession(incomingSession);
    incomingSession.SIP = session; // activates the session
  }

  // In the case of a push notification triggering the spinning up of child user agents, a parent user agent
  // and its children can, and do, end up being asked to handle the same invite. Only one of them should.
  // So we pick a winner - it's a race. We pick the last one to the party when everyone knocks at same time. :)
  // POTENTIAL ISSUE: This resolution depends on...
  // - session.reject() synchronously removing the session from the user agents array of sessions
  //  (this assumption may not be true in the future)
  private handleOnInviteRaceWinner(session: Invitation): boolean {
    const otherUserAgents: Array<UserAgentSIP> = [];
    if (this.parent && this.parent instanceof UserAgentSIP) {
      otherUserAgents.push(this.parent);
    }

    (this.parent && this.parent instanceof UserAgentSIP
      ? this.parent.children
      : this.children
    ).array.forEach(child => {
      if (child !== this && child instanceof UserAgentSIP) {
        otherUserAgents.push(child);
      }
    });

    if (
      otherUserAgents.some(userAgent =>
        userAgent.hasSession(session.request.callId, session.request.fromTag)
      )
    ) {
      log.debug(
        `UserAgentSIP[${this.aor}].handleOnInviteRaceWinner: ` +
          "other user agent already handling incoming session, rejecting and disposing of ourselves (if child)"
      );
      session.reject();
      if (this.parent) {
        this.dispose();
      }
      return false;
    }

    log.debug(
      `UserAgentSIP[${this.aor}].handleOnInviteRaceWinner: ` +
        (this.parent ? "[child] parent and other children" : "[parent] children") +
        " not handling incoming session, handling"
    );
    return true;
  }

  private handleOnMessage(message: Message): void {
    log.debug(`UserAgentSIP[${this.aor}].handleOnMessage`);
    if (!this.delegate) {
      return;
    }

    message.accept();
    this.delegate.handleIncomingMessage(message);
  }

  // FIXME: TODO: Minor Hack (abstraction violation)
  private hasSession(callid: string, fromtag: string): boolean {
    log.debug(
      `UserAgentSIP[${this.aor}].hasSession: ${callid} ${fromtag} ${
        this.parent ? "(child)" : "(parent)"
      }`
    );
    const res = Object.prototype.hasOwnProperty.call(this.userAgent._sessions, callid + fromtag);
    log.debug(
      `UserAgentSIP[${this.aor}].hasSession: ${callid} ${fromtag} ${
        this.parent ? "(child)" : "(parent)"
      } ` + res
    );
    return res;
  }

  private addDefaultUserAgentValues(configuration: Partial<UserAgentOptions>): UserAgentOptions {
    const transportOptions = configuration.transportOptions as Web.TransportOptions;
    return {
      // configuration
      uri: configuration.uri,
      authorizationUsername: configuration.authorizationUsername,
      authorizationPassword: configuration.authorizationPassword,
      displayName: configuration.displayName,
      noAnswerTimeout: configuration.noAnswerTimeout,
      userAgentString: name + "/" + version + " " + configuration.userAgentString,
      // object
      sessionDescriptionHandlerFactory: this.sessionDescriptionHandlerFactory,
      // other
      allowLegacyNotifications: true, // out of dialog NOTIFY targeting UA (i.e. 'message-summary' event notifications)
      hackViaTcp: true, // needed for polycom phones, which blow up on ws
      // hackStripTcp: false,
      sipExtension100rel: SIPExtension.Supported,
      sipExtensionReplaces: SIPExtension.Supported,
      transportOptions: {
        server: transportOptions ? transportOptions.server : "wss://edge.sip.onsip.com",
        traceSip: transportOptions ? transportOptions.traceSip : true,
        connectionTimeout: 10
      },
      logBuiltinEnabled: false,
      logConnector,
      logConfiguration: configuration.logConfiguration,
      logLevel: "warn"
    };
  }
}

/**
 * A log "connector" function (see SIP.js user agent configuration for more info).
 * @param targetName The log level
 * @param category The category
 * @param label The label
 * @param content The content
 */
function logConnector(
  targetName: string,
  category: string,
  label: string | undefined,
  content: string
): void {
  // paranoid
  if (typeof content !== "string") {
    return;
  }

  let message: string;
  const time: string = new Date().toISOString();
  if (label && typeof label === "string") {
    message = time + ": " + label + " | " + content;
  } else {
    message = time + ": " + content;
  }

  switch (targetName) {
    case "debug":
      log.debug(message);
      break;
    case "log":
      log.debug(message);
      break;
    case "warn":
      log.warn(message);
      break;
    case "error":
      log.error(message);
      break;
  }
  return;
}
