<!--
Two in one modal since designs call for templates to
be "hot-swapped" at expiration if it is still up
-->
@if (timer | async; as timerVal) { @if (data && data.expiring && timerVal > 0) {
<h2 mat-dialog-title>Your free call ends in:</h2>
<div mat-dialog-content>
  <h2>{{ timerVal }} seconds</h2>
</div>
<div mat-dialog-actions class="modal-actions">
  <button mat-button color="primary" mat-dialog-close="none">
    {{ "ONSIP_I18N.CLOSE" | translate | sentenceCase }}
  </button>
  <button
    mat-flat-button
    class="onsip-call-end-background"
    mat-dialog-close="end-calls"
    cdkFocusInitial
  >
    <mat-icon>call_end</mat-icon>
    {{ "ONSIP_I18N.END_CALL" | translate | sentenceCase }}
  </button>
</div>
} @else {
<h2 mat-dialog-title>Free calling is limited.</h2>
<div mat-dialog-content>
  <p>
    Calls with other OnSIP users are free, but calls with phone numbers require additional credit.
    Contact sales to extend your credit.
  </p>
</div>
<div mat-dialog-actions class="modal-actions">
  <button mat-button color="primary" mat-dialog-close="none">
    {{ "ONSIP_I18N.CLOSE" | translate | sentenceCase }}
  </button>
  <button mat-flat-button color="primary" mat-dialog-close="contact-sales" cdkFocusInitial>
    {{ "ONSIP_I18N.SPEAK_TO_AN_AGENT" | translate | sentenceCase }}
  </button>
</div>
} } @else {
<h2 mat-dialog-title>Free calling is limited.</h2>
<div mat-dialog-content>
  <p>
    Calls with other OnSIP users are free, but calls with phone numbers require additional credit.
    Contact sales to extend your credit.
  </p>
</div>
<div mat-dialog-actions class="modal-actions">
  <button mat-button color="primary" mat-dialog-close="none">
    {{ "ONSIP_I18N.CLOSE" | translate | sentenceCase }}
  </button>
  <button mat-flat-button color="primary" mat-dialog-close="contact-sales" cdkFocusInitial>
    {{ "ONSIP_I18N.SPEAK_TO_AN_AGENT" | translate | sentenceCase }}
  </button>
</div>
}
