<mat-divider onsip-dotted-divider></mat-divider>
<div class="container">
  @if(authorized | async) { @if (safeResourceURL) {
  <iframe [src]="safeResourceURL"></iframe>
  } @else { @if (noContactFound) {
  <div>No HubSpot Contact Info Found</div>
  } @else {
  <div>Loading HubSpot Data</div>
  } } } @else {
  <div>
    <h3 matSubheader>Authorize for HubSpot Information</h3>
    <div>
      <h4 matLine>HubSpot Authorized</h4>
      <onsip-hubspot-authorized-toggle></onsip-hubspot-authorized-toggle>
    </div>
  </div>
  }
</div>
