import { CallEvent, EventId } from "./call-event";

/** Emitted once when Call is connecting. */
export class ConnectingCallEvent extends CallEvent {
  id = EventId.ConnectingCallEventId;

  /**
   * Factory for ConnectingCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isConnectingCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for ConnectingCallEvent.
 * @param event Event to check.
 */
export function isConnectingCallEvent(event: any): event is ConnectingCallEvent {
  return event instanceof ConnectingCallEvent;
}
