import {
  ApiFailoverAddress,
  ApiFullAddress,
  FailoverAddress,
  apiFailoverAddressToAddress
} from "../../apiResponse/address";
import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";

// This represents the UserAddress object returned by a UserAddress api action
export declare interface APIUserAddress {
  /** The user's address information */
  Address: ApiFullAddress;
  /** Authentication password associated with the SIP address. */
  AuthPassword: string;
  /** Authentication username associated with the SIP address. */
  AuthUsername: string;
  /** current failover address */
  DefaultAddress: ApiFailoverAddress;
  /** boolean if the Address is set to DoNotDisturb */
  DoNotDisturb: OnsipAPIBoolean;
  /** e911 location id */
  E911LocationId: string;
  /** previous failover address (for resetting it) */
  PreviousDefaultAddress: ApiFailoverAddress;
  /** The time until this user address will fail over */
  Timeout: number;
  /** primary key of the user_address table in jnctn */
  UserAddressId: string;
  /** id of the user in jnctn */
  UserId: string;
}

// This is used as a type throughout the app for a UserAddress Object
export interface UserAddress {
  /** AddressId of the SIP address */
  addressId: string;
  /** Combination of the above two to make sip address */
  aor: string;
  /** Authentication password associated with the SIP address. */
  authPassword: string;
  /** Authentication username associated with the SIP address. */
  authUsername: string;
  /** current failover address */
  defaultAddress: FailoverAddress;
  /** Domain portion of the SIP address. */
  domain: string;
  /** boolean if the Address is set to DoNotDisturb */
  doNotDisturb: boolean;
  /** e911 location id */
  e911LocationId: string;
  /** Display name associated with the SIP address. */
  name: string;
  /** previous failover address (for resetting it) */
  previousDefaultAddress: FailoverAddress;
  /** The time until this user address will fail over */
  timeout: number;
  /** primary key of the user_address table in jnctn */
  userAddressId: string;
  /** id of the user in jnctn */
  userId: string;
  /** User portion of the SIP address. */
  username: string;
}

export function apiUserAddressToUserAddress(apiUserAddress: APIUserAddress): UserAddress {
  return {
    addressId: apiUserAddress.Address.AddressId,
    aor: apiUserAddress.Address.Username + "@" + apiUserAddress.Address.Domain,
    authPassword: apiUserAddress.AuthPassword,
    authUsername: apiUserAddress.AuthUsername,
    defaultAddress: apiFailoverAddressToAddress(apiUserAddress.DefaultAddress),
    domain: apiUserAddress.Address.Domain,
    doNotDisturb: onsipApiBooleanToBoolean(apiUserAddress.DoNotDisturb),
    e911LocationId: apiUserAddress.E911LocationId,
    name: apiUserAddress.Address.Name,
    previousDefaultAddress: apiFailoverAddressToAddress(apiUserAddress.PreviousDefaultAddress),
    timeout: apiUserAddress.DefaultAddress ? apiUserAddress.Timeout : 120, // 130 is longer than our longest timeout
    userAddressId: apiUserAddress.UserAddressId,
    username: apiUserAddress.Address.Username,
    userId: apiUserAddress.UserId
  };
}
