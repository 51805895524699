import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";

export interface ApiRecording {
  AccountId: number;
  HaveRecording: OnsipAPIBoolean;
  Metadata: ApiRecordingMetadata;
  Modified: string;
  Name: string;
  Number: string;
  OrganizationId: string;
  RecordingId: string;
}

interface ApiRecordingMetadata {
  CreatedAt: string;
  Duration: string;
  FileType: string;
  Path: string;
  State: string;
}

export interface Recording {
  accountId: number;
  haveRecording: boolean;
  metadata: RecordingMetadata;
  modified: string;
  name: string;
  number: string;
  organizationId: string;
  recordingId: string;
}

interface RecordingMetadata {
  createdAt: string;
  duration: string;
  fileType: string;
  path: string;
  state: string;
}

export function apiRecordingMetadataToRecordingMetadata(
  apiRecordingMetadata: ApiRecordingMetadata
): RecordingMetadata {
  return {
    createdAt: apiRecordingMetadata.CreatedAt,
    duration: apiRecordingMetadata.Duration,
    fileType: apiRecordingMetadata.FileType,
    path: apiRecordingMetadata.Path,
    state: apiRecordingMetadata.State
  };
}

export function apiRecordingToRecording(apiRecording: ApiRecording): Recording {
  return {
    accountId: apiRecording.AccountId,
    haveRecording: onsipApiBooleanToBoolean(apiRecording.HaveRecording),
    metadata: apiRecordingMetadataToRecordingMetadata(apiRecording.Metadata),
    modified: apiRecording.Modified,
    name: apiRecording.Name,
    number: apiRecording.Number,
    organizationId: apiRecording.OrganizationId,
    recordingId: apiRecording.RecordingId
  };
}
