<form class="password-form mat-typography" (ngSubmit)="reset()">
  <h3 class="password-form-label">{{ "ONSIP_I18N.YOUR_SIP_ADDRESS" | translate }}:</h3>
  <div class="password-form-sip-address" [innerHTML]="sipAddress">
    {{ "ONSIP_I18N.LOADING" | translate }}...
  </div>
  @if (!emailKey) {
  <mat-form-field onsip-form-field-padding-fix class="password-form-password">
    <mat-label>{{ "ONSIP_I18N.CURRENT_PASSWORD" | translate }}</mat-label>
    <input
      matInput
      [type]="hideCurrentPassword ? 'password' : 'text'"
      [formControl]="currentPassword"
    />
    <mat-icon class="show-hide-icon" matSuffix (click)="hideCurrentPassword = !hideCurrentPassword">
      {{ hideCurrentPassword ? "visibility_off" : "visibility" }}
    </mat-icon>
  </mat-form-field>
  }
  <mat-form-field onsip-form-field-padding-fix class="password-form-choose-password">
    <mat-label>{{ "ONSIP_I18N.CHOOSE_PASSWORD" | translate }}</mat-label>
    <input
      matInput
      name="password"
      #newPassword
      [formControl]="passwordFormGroup.controls['newPassword']"
      [type]="hideNewPassword ? 'password' : 'text'"
      autofocus
    />
    <mat-icon class="show-hide-icon" matSuffix (click)="hideNewPassword = !hideNewPassword">
      {{ hideNewPassword ? "visibility_off" : "visibility" }}
    </mat-icon>
    @if (passwordFormGroup.controls['newPassword'].invalid) {
    <mat-error>
      {{ getErrorMessage() }}
    </mat-error>
    }
  </mat-form-field>
  <mat-form-field onsip-form-field-padding-fix class="password-form-retype-password">
    <mat-label>{{ "ONSIP_I18N.RETYPE_PASSWORD" | translate }}</mat-label>
    <input
      matInput
      #verifyPassword
      name="password"
      [formControl]="passwordFormGroup.controls['verifyPassword']"
      [type]="hideVerifyPassword ? 'password' : 'text'"
    />
    <mat-icon class="show-hide-icon" matSuffix (click)="hideVerifyPassword = !hideVerifyPassword">
      {{ hideVerifyPassword ? "visibility_off" : "visibility" }}
    </mat-icon>
    @if (passwordFormGroup.controls['verifyPassword'].invalid) {
    <mat-error>
      {{ getMatchingErrorMessage() }}
    </mat-error>
    }
  </mat-form-field>
  <mat-error class="password-form-error">{{ error }}</mat-error>
  <div mat-dialog-actions class="password-form-change-button modal-actions">
    <button mat-raised-button color="primary">
      {{ "ONSIP_I18N.CHANGE_PASSWORD" | translate }}
    </button>
  </div>
</form>
