import { Injectable } from "@angular/core";
import { ApiAddAction, ApiBrowseAction, ApiDeleteAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiResourceService } from "../api-resource.service";
import {
  ApiSubAgentAccount,
  SubAgentAccount,
  apiSubAgentAccountToSubAgentAccount as clean
} from "./sub-agent-account";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { SubAgentAccountBrowseParams } from "./sub-agent-account";

const debug = false;

@Injectable({ providedIn: "root" })
export class SubAgentAccountService extends ApiResourceService<SubAgentAccount> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "SubAgent", "subAccountId");
    debug && this.state.subscribe(state => console.warn("SubAgentAccountService", state));
  }

  subAgentAccountBrowse(params?: SubAgentAccountBrowseParams): ApiPromiseState<SubAgentAccount> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.SubAgentAccountBrowse,
        AccountId: undefined,
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 100,
        AgentId: undefined,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.SubAgentAccountBrowse);
  }

  /**
   * @param params
   * AgentId - the id of the agent in charge of both accounts
   *
   * AccountId - the id of the account that will be made into a sub-agent
   *
   * SubAccountId - the id of the account that will now be under the new created sub-agent
   */
  subAgentAccountAdd(params: {
    AgentId: string;
    AccountId: string;
    SubAccountId: string;
  }): ApiPromiseState<SubAgentAccount> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.SubAgentAccountAdd,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.SubAgentAccountAdd);
  }

  // same params as subAgentAccountAdd
  subAgentAccountDelete(params: {
    AgentId: string;
    AccountId: string;
    SubAccountId: string;
  }): ApiPromiseState<SubAgentAccount> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.SubAgentAccountDelete,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.SubAgentAccountDelete);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.SubAgentAccountBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiSubAgentAccount>>(
              response,
              action,
              "SubAgentAccount",
              "SubAgentAccounts"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAddAction.SubAgentAccountAdd:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiSubAgentAccount>(response, action, "SubAgentAccount"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiDeleteAction.SubAgentAccountDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedSubAgent = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "SubAccountId")?.Value;
        deletedSubAgent &&
          this.store.mergeStateUpdate(this.resourceName, { [deletedSubAgent]: undefined }, action);
        break;
    }
  }
}
