import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "onsip-contact-support",
  templateUrl: "./contact-support.component.html",
  styleUrls: ["./contact-support.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent {
  anonymousCallSupport(): void {
    window.open(
      "https://app.onsip.com/app/call?a=acdsupportqueuebhr%40junctionnetworks.com&n=OnSIP+Support",
      "_blank"
    );
  }
}
