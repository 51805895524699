import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import { ApiSdr, Sdr, apiSdrToSdr as clean } from "./sdr";
import { ApiSessionService } from "../../api-session.service";
import { ApiBrowseAction } from "../../api-actions";
import { userId } from "../../apiParams/user-id";
import { sessionId } from "../../apiParams/session-id";
import { lastSeenSdrId } from "../../apiParams/last-seen-sdr-id";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { ParameterValue } from "../../util/api-action-description";

import * as callHistoryConfig from "../../../callHistory/call-history-config";
import { ApiStateStoreService } from "../../api-state-store.service";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export { Sdr };

/** the default number of sdr fetches we do per browse, ie 120 sdrs */
const DEFAULT_LIMIT = (callHistoryConfig.numPageLinks + 1) * callHistoryConfig.pageSize;

const debug = false;

@Injectable({ providedIn: "root" })
export class SdrService extends ApiResourceService<Sdr> {
  private emptyFetchCount = 0;

  private _allFetched = false;
  get allFetched(): boolean {
    return this._allFetched;
  }

  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Sdr", "sdrId");
    debug && this.state.subscribe(state => console.warn("SdrService", state));

    // clear sdr store and reset all fetched flag and fetch counter if there is changed in users
    this.store.state
      .pipe(userId())
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this._allFetched = false;
        this.emptyFetchCount = 0;
        this.dispose();
      });
  }

  /** Convenience state of sdrs ordered in the same way the API returns them to us */
  get sortedSdrs(): Observable<Array<Sdr>> {
    return this.state.pipe(
      map(substate =>
        Object.values(substate.state).sort((a, b) => {
          // the block of code below sorts by StartTime descending, then SdrId descending
          // this corresponds with the OrderBy parameter we provide, which used to be hardcoded in
          // this trivial smoke test will break if someone changes the value
          // if the change was purposeful, then you MUST edit this sort function
          if (callHistoryConfig.orderBy !== "StartTime DESC, SdrId DESC") {
            throw new Error("DEVELOPER: callHistoryConfig.orderBy was changed");
          }
          const timeDiff = new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
          if (timeDiff !== 0) return timeDiff;
          else return parseInt(b.sdrId) - parseInt(a.sdrId);
        })
      )
    );
  }

  sdrBrowse(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<Sdr> | undefined {
    if (this.allFetched) return;
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.SdrBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        OnsipAppFilter: "true",
        OrderBy: callHistoryConfig.orderBy,
        Limit: DEFAULT_LIMIT,
        LastSeen: this.store.state.pipe(lastSeenSdrId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.SdrBrowse);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.SdrBrowse:
        // eslint-disable-next-line no-case-declarations
        const sdrs = extractData<Array<ApiSdr>>(response, action, "Sdr", "Sdrs")
          .map(clean)
          // sdrs older than 2015 are "bad"
          .filter(sdr => {
            if (new Date(sdr.startTime) > callHistoryConfig.minDate) {
              return true;
            } else {
              this._allFetched = true; // set allFetched as a side effect if any sdr is bad (assume all subsequent sdrs are also bad)
              return false;
            }
          });

        // if we get 3 empty fetches in a row we can assume they have no more sdrs
        // FIXME sdr browse used to be based on month pagination, what happened? this is probably broken
        if (sdrs.length === 0) {
          this.emptyFetchCount++;
        } else {
          this.emptyFetchCount = 0;
        }
        if (this.emptyFetchCount === callHistoryConfig.maxEmptyApiCalls) {
          this._allFetched = true;
        }

        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(sdrs, this.indexKeyName),
          action
        );
        break;
    }
  }
}
