<form
  [formGroup]="e911FormGroup"
  (ngSubmit)="saveForm()"
  name="admin-e911-location-add"
  id="admin-e911-location-add"
  onsipFormTracking
>
  <mat-horizontal-stepper linear [selectedIndex]="currentIndex" class="onsip-stepper-no-header">
    <mat-step>
      @if (!addPrivateLocation) {
      <div mat-dialog-title class="add-location-title">
        {{ "ONSIP_I18N.E911_EMERGENCY_RESPONSE_LOCATION" | translate }}
      </div>
      } @if (addPrivateLocation) {
      <div class="private-location-header">
        <div mat-dialog-title>Add private E911 location</div>
        <div class="mat-body-2">
          When you dial 911, emergency personnel will be sent to this location. This address is
          private and won’t be shared with the rest of your organization.
        </div>
      </div>
      } @if (hasApiError) {
      <mat-error class="e911-api-error">
        <mat-icon>error</mat-icon>
        <span class="mat-body-2">
          {{
            "ONSIP_I18N.E911_EMERGENCY_RESPONSE_LOCATION_COULD_NOT_BE_ADDED_THERE_WERE_ISSUES_VERIFYING_FIELDS_HIGHLIGHTED_IN_RED"
              | translate
          }}
          <span class="contact-support" [routerLink]="views.CONTACT_SUPPORT">{{
            "ONSIP_I18N.CONTACT_OUR_SUPPORT_TEAM" | translate
          }}</span>
          {{ "ONSIP_I18N.IF_FURTHER_ASSISTANCE_IS_REQUIRED" | translate }}
        </span>
      </mat-error>
      }
      <div class="add-location-row-1">
        <mat-form-field class="add-location-name" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.LOCATION_NAME" | translate }}</mat-label>
          <input matInput required formControlName="name" autocomplete="off" />
          <mat-error>{{
            getGenericNameError(e911FormGroup.controls.name, "ONSIP_I18N.LOCATION_NAME" | translate)
          }}</mat-error>
        </mat-form-field>
        @if (!addPrivateLocation) {
        <div class="add-location-type" formGroupName="additionalParams">
          <span class="mat-body-1">{{ "ONSIP_I18N.ADDRESS_TYPE" | translate }}</span>
          <mat-radio-group
            class="address-type-options"
            formControlName="locationType"
            required
            color="primary"
          >
            <mat-radio-button value="Shared">{{
              "ONSIP_I18N.SHARED" | translate
            }}</mat-radio-button>
            <mat-radio-button value="Private">{{
              "ONSIP_I18N.PRIVATE" | translate
            }}</mat-radio-button>
          </mat-radio-group>
          @if ( additionalParams.controls.locationType.touched &&
          additionalParams.controls.locationType.hasError('required') ) {
          <mat-error>
            {{ "ONSIP_I18N.ADDRESS_TYPE_IS_REQUIRED" | translate }}
          </mat-error>
          }
        </div>
        }
      </div>
      <div class="add-location-row-2">
        <mat-form-field class="add-location-address" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.ADDRESS" | translate }}</mat-label>
          <input matInput required formControlName="address" />
          <mat-error>{{ getAddressError() }}</mat-error>
        </mat-form-field>
      </div>
      <div class="add-location-row-3">
        <mat-form-field class="add-address-type" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.TYPE" | translate }}</mat-label>
          <mat-select formControlName="secondaryAddressType">
            @for (type of addressTypesArray; track type) {
            <mat-option [value]="type">
              {{ type }}
            </mat-option>
            }
          </mat-select>
          <mat-hint>{{ "ONSIP_I18N.EG_FLOOR_SUITE_BUILDING_ETC" | translate }}</mat-hint>
        </mat-form-field>
        <mat-form-field class="add-address-number" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.UNIT_NUMBER_OR_DESCRIPTION_OPTIONAL" | translate }}</mat-label>
          <input matInput formControlName="secondaryAddressNumber" autocomplete="off" />
          <mat-hint>{{ "ONSIP_I18N.PINPOINT_YOUR_EXACT_LOCATION" | translate }}</mat-hint>
          <mat-error>{{
            getGenericNameError(
              this.e911FormGroup.controls.secondaryAddressNumber,
              "ONSIP_I18N.ADDRESS_NUMBER" | translate
            )
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="add-location-row-4">
        <mat-form-field class="add-address-city" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.CITY" | translate }}</mat-label>
          <input matInput required formControlName="city" />
          <mat-error>{{ getCityError() }}</mat-error>
        </mat-form-field>
        <span class="e911-location-sz">
          <mat-form-field class="add-address-state" appearance="outline">
            <!-- HACK for autocompleting state form field -->
            <input
              class="hide-for-autocomplete"
              type="text"
              name="state"
              [formControl]="autoCompleteState"
              autocomplete="address-level1"
            />
            <mat-label>{{ "ONSIP_I18N.STATE" | translate }}</mat-label>
            <mat-select required formControlName="state">
              @for (state of listOfStates; track state) {
              <mat-option [value]="state[1]">
                {{ state[1] }}
              </mat-option>
              }
            </mat-select>
            <mat-error>{{ getStateError() }}</mat-error>
          </mat-form-field>
          <mat-form-field class="add-address-zipcode" appearance="outline">
            <mat-label>{{ "ONSIP_I18N.POSTAL_CODE" | translate }}</mat-label>
            <input matInput required maxlength="10" formControlName="zipcode" />
            <mat-error>{{ getPostalCodeError() }}</mat-error>
          </mat-form-field>
        </span>
      </div>
      <div class="add-location-row-5">
        <div class="add-address-country">
          <span class="mat-body-1">{{ "ONSIP_I18N.COUNTRY" | translate | sentenceCase }}</span>
          <span class="mat-body-2">{{ "ONSIP_I18N.UNITED_STATES_OF_AMERICA" | translate }}</span>
        </div>
        <mat-form-field class="add-address-email" appearance="outline">
          <mat-label>{{ "ONSIP_I18N.NOTIFICATION_EMAIL_ADDRESS" | translate }}</mat-label>
          <input matInput formControlName="email" autocomplete="off" />
          <mat-hint>{{
            "ONSIP_I18N.DIALING_911_WILL_TRIGGER_A_NOTIFICATION_TO_THIS_ADDRESS" | translate
          }}</mat-hint>
          <mat-error>{{ "ONSIP_I18N.MUST_BE_A_VALID_EMAIL_ADDRESS" | translate }}</mat-error>
        </mat-form-field>
      </div>
      <div mat-dialog-actions class="modal-actions">
        <button mat-button type="button" color="primary" mat-dialog-close>
          {{ "ONSIP_I18N.CANCEL" | translate }}
        </button>
        <button mat-flat-button type="button" (click)="goNext()" color="primary">
          {{ "ONSIP_I18N.SAVE" | translate }}
        </button>
      </div>
    </mat-step>
    <mat-step [stepControl]="additionalParams.controls.assignUsers">
      <div class="assign-users-view">
        @if (additionalParams.controls.locationType.value === 'Shared') {
        <div mat-dialog-title class="assign-user-title">
          {{ "ONSIP_I18N.ASSIGN_USERS_TO_LOCATION" | translate }}
        </div>
        } @if (additionalParams.controls.locationType.value === 'Private') {
        <div mat-dialog-title class="assign-user-title">
          {{ "ONSIP_I18N.ASSIGN_USER_TO_LOCATION" | translate }}
        </div>
        }
        <div class="location-address">
          <div class="mat-body-1 location-name">{{ e911FormGroup.controls.name.value }}</div>
          <div class="mat-body-2">{{ getDisplayedAddress() }}</div>
          <div class="mat-body-2">
            {{
              e911FormGroup.controls.city.value +
                ", " +
                e911FormGroup.controls.state.value +
                " " +
                e911FormGroup.controls.zipcode.value
            }}
          </div>
        </div>
        @if (additionalParams.controls.locationType.value === 'Shared') {
        <mat-form-field appearance="outline" class="assign-shared-users">
          <onsip-selection-list-deluxe
            disableRipple
            color="primary"
            [formControl]="assignUsersFormControl"
          >
            @for (user of usersList; track user) {
            <mat-list-option [value]="user" checkboxPosition="before">
              {{ user.name }}
            </mat-list-option>
            }
          </onsip-selection-list-deluxe>
        </mat-form-field>
        } @if (additionalParams.controls.locationType.value === 'Private') {
        <mat-form-field class="assign-private-user" appearance="outline">
          <mat-select [formControl]="assignUsersFormControl" required>
            @for (user of usersList; track user) {
            <mat-option [value]="[user]">
              {{ user.name }}
            </mat-option>
            }
          </mat-select>
          <mat-error>{{
            "ONSIP_I18N.SELECTING_AN_ASSIGNED_USER_IS_REQUIRED" | translate
          }}</mat-error>
        </mat-form-field>
        }
        <div class="mat-body-1 e911-pricing">
          {{ "ONSIP_I18N.180MONTH_PER_USER" | translate }}
        </div>
        <div mat-dialog-actions class="assign-user-actions">
          @if (isMobile | async) {
          <button
            mat-flat-button
            type="submit"
            color="primary"
            [onsipFormSubmit]="e911FormGroup"
            (click)="setAddUsersFlag(true)"
          >
            {{ "ONSIP_I18N.SAVE" | translate }}
          </button>
          <button mat-stroked-button type="button" (click)="goBack()" color="primary">
            {{ "ONSIP_I18N.BACK" | translate }}
          </button>
          @if (additionalParams.controls.locationType.value === 'Shared') {
          <button
            mat-button
            type="submit"
            class="add-users-later-button"
            color="primary"
            [onsipFormSubmit]="e911FormGroup"
            (click)="setAddUsersFlag(false)"
          >
            {{ "ONSIP_I18N.ADD_USERS_LATER" | translate }}
          </button>
          } } @else {
          <button mat-stroked-button type="button" (click)="goBack()" color="primary">
            {{ "ONSIP_I18N.BACK" | translate }}
          </button>
          <span class="action-buttons">
            @if (additionalParams.controls.locationType.value === 'Shared') {
            <button
              mat-button
              type="submit"
              class="add-users-later-button"
              color="primary"
              [onsipFormSubmit]="e911FormGroup"
              (click)="setAddUsersFlag(false)"
            >
              {{ "ONSIP_I18N.ADD_USERS_LATER" | translate }}
            </button>
            }
            <button
              mat-flat-button
              type="submit"
              color="primary"
              [onsipFormSubmit]="e911FormGroup"
              (click)="setAddUsersFlag(true)"
            >
              {{ "ONSIP_I18N.SAVE" | translate }}
            </button>
          </span>
          }
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</form>
