// if you faced something like this
// can't find element [data-cy]=undefined
// find compiled version of this file, in the same directory as this one locators.js
// you should delete it and rerun npm script

export enum E2eLocators {
  ACCOUNT_SETTINGS_CREDIT_CARD_BUTTON = "ACCOUNT_SETTINGS_CREDIT_CARD_BUTTON",
  ACCOUNT_SETTINGS_CALLING_CREDITS_BUTTON = "ACCOUNT_SETTINGS_CALLING_CREDITS_BUTTON",
  ACCOUNT_SETTINGS_BILLING_CONTACT_INFO_BUTTON = "ACCOUNT_SETTINGS_BILLING_CONTACT_INFO_BUTTON",
  ACCOUNT_SETTINGS_BILLING_PLAN_DETAILS_BUTTON = "ACCOUNT_SETTINGS_BILLING_PLAN_DETAILS_BUTTON",
  ACCOUNT_SETTINGS_PURCHASE_HISTORY_BUTTON = "ACCOUNT_SETTINGS_PURCHASE_HISTORY_BUTTON",
  ACCOUNT_SETTINGS_INTERNATIONAL_DIALING_BUTTON = "ACCOUNT_SETTINGS_INTERNATIONAL_DIALING_BUTTON",
  ACCOUNT_SETTINGS_TRANSFER_NUMBERS_BUTTON = "ACCOUNT_SETTINGS_TRANSFER_NUMBERS_BUTTON",
  ACCOUNT_SETTINGS_TRANSFER_PHONES_BUTTON = "ACCOUNT_SETTINGS_TRANSFER_PHONES_BUTTON",
  ACCOUNT_SETTINGS_CREATE_PBX_BUTTON = "ACCOUNT_SETTINGS_CREATE_PBX_BUTTON",

  ADMIN_USERS_ADD_BTN = "ADMIN_USERS_ADD_BTN",
  ADMIN_USERS_TABLE_ITEM = "ADMIN_USERS_TABLE_ITEM",
  ADMIN_USERS_TABLE_EMPTY = "ADMIN_USERS_TABLE_EMPTY",
  ADMIN_USERS_SEARCH_INPUT = "ADMIN_USERS_SEARCH_INPUT",
  ADMIN_USERS_REMOVE_BTN = "ADMIN_USERS_REMOVE_BTN",
  ADMIN_USERS_ADD_FORM_NAME_INPUT = "ADMIN_USERS_ADD_FORM_NAME_INPUT",
  ADMIN_USERS_ADD_FORM_EMAIL_INPUT = "ADMIN_USERS_ADD_FORM_EMAIL_INPUT",
  ADMIN_USERS_ADD_FORM_SUBMIT = "ADMIN_USERS_ADD_FORM_SUBMIT",

  ADMIN_ATT_MENU_ADD_BTN = "ADMIN_ATT_MENU_ADD_BTN",
  ADMIN_ATT_MENU_ADD_NAME = "ADMIN_ATT_MENU_ADD_NAME",
  ADMIN_ATT_MENU_ADD_NEXT_STEP = "ADMIN_ATT_MENU_ADD_NEXT_STEP",
  ADMIN_ATT_MENU_ADD_GO_REVIEW = "ADMIN_ATT_MENU_ADD_GO_REVIEW",
  ADMIN_ATT_MENU_ADD_REVIEW_NAME = "ADMIN_ATT_MENU_ADD_REVIEW_NAME",
  ADMIN_ATT_MENU_ADD_REVIEW_NAME_INPUT = "ADMIN_ATT_MENU_ADD_REVIEW_NAME_INPUT",
  ADMIN_ATT_MENU_PHONES = "ADMIN_ATT_MENU_PHONES",
  ADMIN_ATT_MENU_EXTENSIONS = "ADMIN_ATT_MENU_EXTENSIONS",
  ADMIN_ATT_MENU_DESTINATION = "ADMIN_ATT_MENU_DESTINATION",
  ADMIN_ATT_MENU_ADD_REVIEW_EDIT_BTN = "ADMIN_ATT_MENU_ADD_REVIEW_EDIT_BTN",
  ADMIN_ATT_MENU_ADD_REVIEW_EDIT_CANCEL = "ADMIN_ATT_MENU_ADD_REVIEW_EDIT_CANCEL",
  ADMIN_ATT_MENU_ADD_REVIEW_FINISH = "ADMIN_ATT_MENU_ADD_REVIEW_FINISH",
  ADMIN_ATT_MENU_SEARCH = "ADMIN_ATT_MENU_SEARCH",
  ADMIN_ATT_MENU_ROW = "ADMIN_ATT_MENU_ROW",
  ADMIN_ATT_MENU_DELETE = "ADMIN_ATT_MENU_DELETE",
  ADMIN_ATT_MENU_VIEW_SHELVE_NAME = "ADMIN_ATT_MENU_VIEW_SHELVE_NAME",
  ADMIN_ATT_MENU_VIEW_SHELVE_EDIT = "ADMIN_ATT_MENU_VIEW_SHELVE_EDIT",
  ADMIN_ATT_MENU_EDIT_SHELVE_NAME = "ADMIN_ATT_MENU_EDIT_SHELVE_NAME",
  ADMIN_ATT_MENU_EDIT_SHELVE_SUBMIT = "ADMIN_ATT_MENU_EDIT_SHELVE_SUBMIT",
  ACCOUNT_BILLING_PLAN_ROUTE_BUTTON = "ACCOUNT_BILLING_PLAN_ROUTE_BUTTON",

  ADMIN_BUSINESS_HOUR_RULES_PAGE_LINK = "ADMIN_BUSINESS_HOUR_RULES_PAGE_LINK",
  ADMIN_BUSINESS_HOUR_RULES_CREATE_BUTTON = "ADMIN_BUSINESS_HOUR_RULES_CREATE_BUTTON",
  ADMIN_BUSINESS_HOUR_RULES_NAME_FIELD = "ADMIN_BUSINESS_HOUR_RULES_NAME_FIELD",
  ADMIN_BUSINESS_HOUR_RULES_OPENED_ADDRESS = "ADMIN_BUSINESS_HOUR_RULES_OPENED_ADDRESS",
  ADMIN_BUSINESS_HOUR_RULES_CLOSED_ADDRESS = "ADMIN_BUSINESS_HOUR_RULES_CLOSED_ADDRESS",
  ADMIN_BUSINESS_HOUR_RULES_SAVE_BUTTON = "ADMIN_BUSINESS_HOUR_RULES_SAVE_BUTTON",
  ADMIN_BUSINESS_HOUR_RULES_CANCEL_BUTTON = "ADMIN_BUSINESS_HOUR_RULES_CANCEL_BUTTON",
  ADMIN_BUSINESS_HOUR_RULES_TABLE_NAME = "ADMIN_BUSINESS_HOUR_RULES_TABLE_NAME",
  ADMIN_BUSINESS_HOUR_RULES_DELETE_TABLE_ITEM = "ADMIN_BUSINESS_HOUR_RULES_DELETE_TABLE_ITEM",
  ADMIN_BUSINESS_HOUR_RULES_EDIT_TABLE_ITEM = "ADMIN_BUSINESS_HOUR_RULES_EDIT_TABLE_ITEM",
  ADMIN_BUSINESS_HOUR_RULES_EDIT_BUTTON = "ADMIN_BUSINESS_HOUR_RULES_EDIT_BUTTON",
  ADMIN_BUSINESS_HOUR_RULES_NAME = "ADMIN_BUSINESS_HOUR_RULES_NAME",
  ADMIN_BUSINESS_HOUR_RULES_SIP_ADDRESS = "ADMIN_BUSINESS_HOUR_RULES_SIP_ADDRESS",
  ADMIN_BUSINESS_HOUR_RULES_EXTENSIONS = "ADMIN_BUSINESS_HOUR_RULES_EXTENSIONS",
  ADMIN_BUSINESS_HOUR_RULES_PHONES = "ADMIN_BUSINESS_HOUR_RULES_PHONES",
  ADMIN_BUSINESS_HOUR_RULES_SEARCH_FIELD = "ADMIN_BUSINESS_HOUR_RULES_SEARCH_FIELD",

  ADMIN_INBOUND_CALL_SETTINGS_TAB = "ADMIN_INBOUND_CALL_SETTINGS_TAB",

  ADMIN_INBOUND_BRIDGE_ADD_BTN = "ADMIN_INBOUND_BRIDGE_ADD_BTN",
  ADMIN_INBOUND_BRIDGE_ADD_NAME = "ADMIN_INBOUND_BRIDGE_ADD_NAME",
  ADMIN_INBOUND_BRIDGE_PHONES = "ADMIN_INBOUND_BRIDGE_PHONES",
  ADMIN_INBOUND_BRIDGE_EXTENSIONS = "ADMIN_INBOUND_BRIDGE_EXTENSIONS",
  ADMIN_INBOUND_BRIDGE_ADD_DESTINATION = "ADMIN_INBOUND_BRIDGE_DESTINATION",
  ADMIN_INBOUND_BRIDGE_ADD_BILLING_USER = "ADMIN_INBOUND_BRIDGE_BILLING_USER",
  ADMIN_INBOUND_BRIDGE_ADD_SUBMIT = "ADMIN_INBOUND_BRIDGE_ADD_SUBMIT",
  ADMIN_INBOUND_BRIDGE_SEARCH = "ADMIN_INBOUND_BRIDGE_SEARCH",
  ADMIN_INBOUND_BRIDGE_ROW = "ADMIN_INBOUND_BRIDGE_ROW",
  ADMIN_INBOUND_BRIDGE_DELETE = "ADMIN_INBOUND_BRIDGE_DELETE",
  ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_NAME = "ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_NAME",
  ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_BILLING = "ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_BILLING",
  ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_DESTINATION = "ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_DESTINATION",
  ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_EDIT = "ADMIN_INBOUND_BRIDGE_VIEW_SHELVE_EDIT",
  ADMIN_INBOUND_BRIDGE_EDIT_SHELVE_NAME = "ADMIN_INBOUND_BRIDGE_EDIT_SHELVE_NAME",
  ADMIN_INBOUND_BRIDGE_EDIT_SHELVE_SUBMIT = "ADMIN_INBOUND_BRIDGE_EDIT_SHELVE_SUBMIT",

  ADMIN_DBN_ADD_BTN = "ADMIN_DBN_ADD_BTN",
  ADMIN_DBN_ADD_NAME = "ADMIN_DBN_ADD_NAME",
  ADMIN_DBN_ADD_SIP_ADDRESS = "ADMIN_DBN_ADD_SIP_ADDRESS",
  ADMIN_DBN_ADD_NEXT_STEP = "ADMIN_DBN_ADD_NEXT_STEP",
  ADMIN_DBN_ADD_GO_REVIEW = "ADMIN_DBN_ADD_GO_REVIEW",
  ADMIN_DBN_ADD_SEARCH_USER = "ADMIN_DBN_ADD_SEARCH_USER",
  ADMIN_DBN_ADD_REVIEW_NAME = "ADMIN_DBN_ADD_REVIEW_NAME",
  ADMIN_DBN_PHONES = "ADMIN_DBN_PHONES",
  ADMIN_DBN_EXTENSIONS = "ADMIN_DBN_EXTENSIONS",
  ADMIN_DBN_ADD_REVIEW_FINISH = "ADMIN_DBN_ADD_REVIEW_FINISH",
  ADMIN_DBN_SEARCH = "ADMIN_DBN_SEARCH",
  ADMIN_DBN_ROW = "ADMIN_DBN_ROW",
  ADMIN_DBN_DELETE = "ADMIN_DBN_DELETE",
  ADMIN_DBN_VIEW_SHELVE_NAME = "ADMIN_DBN_VIEW_SHELVE_NAME",
  ADMIN_DBN_VIEW_SHELVE_EDIT = "ADMIN_DBN_VIEW_SHELVE_EDIT",
  ADMIN_DBN_EDIT_SHELVE_NAME = "ADMIN_DBN_EDIT_SHELVE_NAME",
  ADMIN_DBN_EDIT_SHELVE_SUBMIT = "ADMIN_DBN_EDIT_SHELVE_SUBMIT",

  ADMIN_EXT_PHONE_NUM_ADD_BTN = "ADMIN_EXT_PHONE_NUM_ADD_BTN",
  ADMIN_EXT_PHONE_NUM_ADD_NAME_INPUT = "ADMIN_EXT_PHONE_NUM_ADD_NAME_INPUT",
  ADMIN_EXT_PHONE_NUM_ADD_NUMBER_INPUT = "ADMIN_EXT_PHONE_NUM_ADD_NUMBER_INPUT",
  ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_CUSTOM_BTN = "ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_CUSTOM_BTN",
  ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_NAME_INPUT = "ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_NAME_INPUT",
  ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_NUMBER_INPUT = "ADMIN_EXT_PHONE_NUM_ADD_CALLER_ID_NUMBER_INPUT",
  ADMIN_EXT_PHONE_NUM_ADD_SUBMIT_BTN = "ADMIN_EXT_PHONE_NUM_ADD_SUBMIT_BTN",
  ADMIN_EXT_PHONE_NUM_SEARCH = "ADMIN_EXT_PHONE_NUM_SEARCH",
  ADMIN_EXT_PHONE_NUM_ROW = "ADMIN_EXT_PHONE_NUM_ROW",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_NAME = "ADMIN_EXT_PHONE_NUM_SHELF_NAME",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_NUMBER = "ADMIN_EXT_PHONE_NUM_SHELF_NUMBER",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_CALL_ACTIVITY = "ADMIN_EXT_PHONE_NUM_SHELF_CALL_ACTIVITY",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_CALLER_ID = "ADMIN_EXT_PHONE_NUM_SHELF_CALLER_ID",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_ADDRESS = "ADMIN_EXT_PHONE_NUM_SHELF_ADDRESS",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_EXTENSION = "ADMIN_EXT_PHONE_NUM_VIEW_SHELF_EXTENSION",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_PHONE = "ADMIN_EXT_PHONE_NUM_VIEW_SHELF_PHONE",
  ADMIN_EXT_PHONE_NUM_REMOVE_BTN = "ADMIN_EXT_PHONE_NUM_REMOVE_BTN",
  ADMIN_EXT_PHONE_NUM_VIEW_SHELF_EDIT_BTN = "ADMIN_EXT_PHONE_NUM_VIEW_SHELF_EDIT_BTN",
  ADMIN_EXT_PHONE_NUM_EDIT_NAME_INPUT = "ADMIN_EXT_PHONE_NUM_EDIT_NAME_INPUT",
  ADMIN_EXT_PHONE_NUM_EDIT_NUMBER_INPUT = "ADMIN_EXT_PHONE_NUM_EDIT_NUMBER_INPUT",
  ADMIN_EXT_PHONE_NUM_EDIT_CALLER_ID_INPUT = "ADMIN_EXT_PHONE_NUM_EDIT_CALLER_ID_INPUT",
  ADMIN_EXT_PHONE_NUM_EDIT_CALLER_ID_NUM_INPUT = "ADMIN_EXT_PHONE_NUM_EDIT_CALLER_ID_NUM_INPUT",
  ADMIN_EXT_PHONE_NUM_EDIT_SUBMIT_BTN = "ADMIN_EXT_PHONE_NUM_EDIT_SUBMIT_BTN",

  ADMIN_EXT_SIP_ADD_BTN = "ADMIN_EXT_SIP_ADD_BTN",
  ADMIN_EXT_SIP_ADD_NAME_INPUT = "ADMIN_EXT_SIP_ADD_NAME_INPUT",
  ADMIN_EXT_SIP_ADD_ADDRESS_INPUT = "ADMIN_EXT_SIP_ADD_ADDRESS_INPUT",
  ADMIN_EXT_SIP_ADD_SUBMIT_BTN = "ADMIN_EXT_SIP_ADD_SUBMIT_BTN",
  ADMIN_EXT_SIP_SEARCH = "ADMIN_EXT_SIP_SEARCH",
  ADMIN_EXT_SIP_ROW = "ADMIN_EXT_SIP_ROW",
  ADMIN_EXT_SIP_VIEW_SHELF_NAME = "ADMIN_EXT_SIP_SHELF_NAME",
  ADMIN_EXT_SIP_VIEW_SHELF_ADDRESS = "ADMIN_EXT_SIP_VIEW_SHELF_ADDRESS",
  ADMIN_EXT_SIP_VIEW_SHELF_PHONE = "ADMIN_EXT_SIP_VIEW_PHONE",
  ADMIN_EXT_SIP_VIEW_SHELF_EXTENSION = "ADMIN_EXT_SIP_VIEW_EXTENSION",
  ADMIN_EXT_SIP_REMOVE_BTN = "ADMIN_EXT_SIP_REMOVE_BTN",
  ADMIN_EXT_SIP_VIEW_SHELF_EDIT_BTN = "ADMIN_EXT_SIP_VIEW_SHELF_EDIT_BTN",
  ADMIN_EXT_SIP_EDIT_NAME_INPUT = "ADMIN_EXT_SIP_EDIT_NAME_INPUT",
  ADMIN_EXT_SIP_EDIT_ADDRESS_INPUT = "ADMIN_EXT_SIP_EDIT_ADDRESS_INPUT",
  ADMIN_EXT_SIP_EDIT_SUBMIT_BTN = "ADMIN_EXT_SIP_EDIT_SUBMIT_BTN",

  ADMIN_ANNOUNCEMENT_ADD_BTN = "ADMIN_ANNOUNCEMENT_ADD_BTN",
  ADMIN_ANNOUNCEMENT_ADD_NAME = "ADMIN_ANNOUNCEMENT_ADD_NAME",
  ADMIN_ANNOUNCEMENT_ADD_NEXT_STEP = "ADMIN_ANNOUNCEMENT_ADD_NEXT_STEP",
  ADMIN_ANNOUNCEMENT_ADD_GO_REVIEW = "ADMIN_ANNOUNCEMENT_ADD_GO_REVIEW",
  ADMIN_ANNOUNCEMENT_ADD_REVIEW_NAME = "ADMIN_ANNOUNCEMENT_ADD_REVIEW_NAME",
  ADMIN_ANNOUNCEMENT_ADD_REVIEW_EXTENSIONS = "ADMIN_ANNOUNCEMENT_ADD_REVIEW_EXTENSIONS",
  ADMIN_ANNOUNCEMENT_ADD_REVIEW_PHONES = "ADMIN_ANNOUNCEMENT_ADD_REVIEW_PHONES",
  ADMIN_ANNOUNCEMENT_ADD_SUBMIT = "ADMIN_ANNOUNCEMENT_ADD_SUBMIT",
  ADMIN_ANNOUNCEMENT_SEARCH = "ADMIN_ANNOUNCEMENT_SEARCH",
  ADMIN_ANNOUNCEMENT_ROW = "ADMIN_ANNOUNCEMENT_ROW",
  ADMIN_ANNOUNCEMENT_VIEW_SHELF_NAME = "ADMIN_ANNOUNCEMENT_SHELF_NAME",
  ADMIN_ANNOUNCEMENT_VIEW_SHELF_PHONE = "ADMIN_ANNOUNCEMENT_VIEW_PHONE",
  ADMIN_ANNOUNCEMENT_VIEW_SHELF_EXTENSION = "ADMIN_ANNOUNCEMENT_VIEW_EXTENSION",
  ADMIN_ANNOUNCEMENT_REMOVE_BTN = "ADMIN_ANNOUNCEMENT_REMOVE_BTN",
  ADMIN_ANNOUNCEMENT_VIEW_SHELF_EDIT_BTN = "ADMIN_ANNOUNCEMENT_VIEW_SHELF_EDIT_BTN",
  ADMIN_ANNOUNCEMENT_EDIT_NAME = "ADMIN_ANNOUNCEMENT_EDIT_NAME",
  ADMIN_ANNOUNCEMENT_EDIT_SUBMIT_BTN = "ADMIN_ANNOUNCEMENT_EDIT_SUBMIT_BTN",

  ADMIN_VOICEMAIL_ADD_BTN = "ADMIN_VOICEMAIL_ADD_BTN",
  ADMIN_VOICEMAIL_ADD_MAILBOX_NAME = "ADMIN_VOICEMAIL_ADD_NAME",
  ADMIN_VOICEMAIL_ADD_MAILBOX_NUMBER = "ADMIN_VOICEMAIL_ADD_MAILBOX_NUMBER",
  ADMIN_VOICEMAIL_ADD_GO_TO_GREETING_STEP = "ADMIN_VOICEMAIL_ADD_GO_TO_GREETING_STEP",
  ADMIN_VOICEMAIL_ADD_GO_TO_ADVANCED_SETTINGS_STEP = "ADMIN_VOICEMAIL_ADD_ADVANCED_SETTINGS_STEP",
  ADMIN_VOICEMAIL_ADD_GO_REVIEW = "ADMIN_VOICEMAIL_ADD_GO_REVIEW",
  ADMIN_VOICEMAIL_ADD_REVIEW_MAILBOX_NAME = "ADMIN_VOICEMAIL_ADD_REVIEW_MAILBOX_NAME",
  ADMIN_VOICEMAIL_ADD_REVIEW_MAILBOX_NUMBER = "ADMIN_VOICEMAIL_ADD_REVIEW_MAILBOX_NUMBER",
  ADMIN_VOICEMAIL_ADD_REVIEW_EXTENSIONS = "ADMIN_VOICEMAIL_ADD_REVIEW_EXTENSIONS",
  ADMIN_VOICEMAIL_ADD_REVIEW_FINISH = "ADMIN_VOICEMAIL_ADD_REVIEW_FINISH",
  ADMIN_VOICEMAIL_SEARCH = "ADMIN_VOICEMAIL_SEARCH",
  ADMIN_VOICEMAIL_ROW = "ADMIN_VOICEMAIL_ROW",
  ADMIN_VOICEMAIL_DELETE = "ADMIN_VOICEMAIL_DELETE",
  ADMIN_VOICEMAIL_VIEW_SHELVE_MAILBOX_NAME = "ADMIN_VOICEMAIL_VIEW_SHELVE_MAILBOX_NAME",
  ADMIN_VOICEMAIL_VIEW_SHELVE_MAILBOX_NUMBER = "ADMIN_VOICEMAIL_VIEW_SHELVE_MAILBOX_NUMBER",
  ADMIN_VOICEMAIL_VIEW_SHELVE_EDIT = "ADMIN_VOICEMAIL_VIEW_SHELVE_EDIT",
  ADMIN_VOICEMAIL_VIEW_SHELVE_EXTENSIONS = "ADMIN_VOICEMAIL_VIEW_SHELVE_EXTENSIONS",
  ADMIN_VOICEMAIL_VIEW_SHELVE_ADVANCED_SETTINGS_EDIT = "ADMIN_VOICEMAIL_VIEW_SHELVE_ADVANCED_SETTINGS_EDIT",
  ADMIN_VOICEMAIL_EDIT_SHELVE_MAILBOX_NAME = "ADMIN_VOICEMAIL_EDIT_SHELVE_MAILBOX_NAME",
  ADMIN_VOICEMAIL_EDIT_SHELVE_MAILBOX_NUMBER = "ADMIN_VOICEMAIL_EDIT_SHELVE_MAILBOX_NUMBER",
  ADMIN_VOICEMAIL_EDIT_SHELVE_SUBMIT = "ADMIN_VOICEMAIL_EDIT_SHELVE_SUBMIT",
  ADMIN_VOICEMAIL_EDIT_ADVANCED_SETTINGS_SHELVE_SUBMIT = "ADMIN_VOICEMAIL_EDIT_ADVANCED_SETTINGS_SHELVE_SUBMIT",

  CALL_START_NEW_BTN = "CALL_START_NEW_BTN",
  CALL_START_NEW_VOICE_CALL_BTN = "CALL_START_NEW_VOICE_CALL_BTN",
  CALL_START_NEW_INPUT = "CALL_START_NEW_INPUT",
  CALL_PAGE_END_CALL_BTN = "CALL_PAGE_END_CALL_BTN",

  ADMIN_MOH_ENABLE_TOGGLE = "ADMIN_MOH_ENABLE_TOGGLE",
  ADMIN_MOH_SELECT_CHANNELS = "ADMIN_MOH_SELECT_CHANNELS",
  ADMIN_MOH_SET_IS_ENHANCED = "ADMIN_MOH_SET_IS_ENHANCED",
  ADMIN_MOH_DISABLE_IS_ENHANCED = "ADMIN_MOH_DISABLE_IS_ENHANCED",
  ADMIN_MOH_SELECT_CHANNELS_OPTION = "ADMIN_MOH_SELECT_CHANNELS_OPTION",
  ADMIN_MOH_EDIT_CREATE_SAVE_SETTINGS = "ADMIN_MOH_EDIT_CREATE_SAVE_SETTINGS",
  ADMIN_MOH_EDIT_CREATE_CANCEL_EDIT = "ADMIN_MOH_EDIT_CREATE_CANCEL_EDIT",
  ADMIN_MOH_MUSIC_SOURCE_TABLE = "ADMIN_MOH_MUSIC_SOURCE_TABLE",
  ADMIN_MOH_PAGE_EDIT_CONTENT = "ADMIN_MOH_PAGE_EDIT_CONTENT",
  ADMIN_MOH_LAUNCH_EDIT = "ADMIN_MOH_LAUNCH_EDIT",

  CONTACTS_LIST = "CONTACTS_LIST",
  CONTACTS_ADD_BTN = "CONTACTS_ADD_BTN",
  CONTACTS_SHOW_SEARCH_INPUT = "CONTACTS_SHOW_SEARCH_INPUT",
  CONTACTS_SEARCH_INPUT = "CONTACTS_SEARCH_INPUT",
  CONTACTS_SEARCH_EMPTY = "CONTACTS_SEARCH_EMPTY",
  CONTACTS_LIST_ITEM = "CONTACTS_LIST_ITEM",
  CONTACTS_LIST_ITEM_REMOVE = "CONTACTS_LIST_ITEM_REMOVE",

  CONTACTS_CREATE_SEARCH = "CONTACTS_CREATE_SEARCH",
  CONTACTS_CREATE_ITEM = "CONTACTS_CREATE_ITEM",
  CONTACTS_CREATE_SWITCH_CUSTOM_BTN = "CONTACTS_CREATE_CUSTOM_BTN",
  CONTACTS_CREATE_CUSTOM_NAME_INPUT = "CONTACTS_CREATE_CUSTOM_NAME_INPUT",
  CONTACTS_CREATE_CUSTOM_NAME_ERROR = "CONTACTS_CREATE_CUSTOM_NAME_ERROR",
  CONTACTS_CREATE_CUSTOM_PHONE_INPUT = "CONTACT_CREATE_CUSTOM_PHONE_INPUT",
  CONTACTS_CREATE_CUSTOM_SUBMIT_BTN = "CONTACT_CREATE_CUSTOM_SUBMIT_BTN",

  CONTACTS_PAGE_EDIT_BTN = "CONTACTS_PAGE_EDIT_BTN",
  CONTACTS_PAGE_DELETE_BTN = "CONTACTS_PAGE_DELETE_BTN",
  CONTACTS_PAGE_ADD_AGAIN_BTN = "CONTACTS_PAGE_ADD_AGAIN_BTN",
  CONTACTS_PAGE_SAVE_BTN = "CONTACTS_PAGE_SAVE_BTN",
  CONTACTS_PAGE_NAME_INPUT = "CONTACTS_PAGE_NAME_INPUT",
  CONTACTS_PAGE_PHONE_INPUT = "CONTACTS_PAGE_PHONE_INPUT",
  CONTACTS_PAGE_NAME_TEXT = "CONTACTS_PAGE_NAME_TEXT",
  CONTACTS_PAGE_PHONE_TEXT = "CONTACTS_PAGE_PHONE_TEXT",

  CONTROLS_SIP_ADDRESS = "CONTROLS_SIP_ADDRESS",
  CONTROLS_EXTENSIONS_ADD_BTN = "CONTROLS_EXTENSIONS_ADD_BTN",
  CONTROLS_EXTENSIONS_ADD_SUBMIT_BTN = "CONTROLS_EXTENSIONS_ADD_SUBMIT_BTN",
  CONTROLS_EXTENSIONS_INPUT_BTN = "CONTROLS_EXTENSIONS_INPUT_BTN",
  CONTROLS_EXTENSIONS_REMOVE_BTN = "CONTROLS_EXTENSIONS_REMOVE_BTN",
  CONTROLS_PHONES_LIST_ITEM = "CONTROLS_PHONES_LIST_ITEM",
  CONTROLS_ADDRESS_AUTOCOMPLITE_INPUT = "CONTROLS_ADDRESS_AUTOCOMPLITE_INPUT",
  CONTROLS_ADDRESS_AUTOCOMPLITE_OPTION = "CONTROLS_ADDRESS_AUTOCOMPLITE_INPUT_OPTION",

  LOGIN_USER_NAME_INPUT = "LOGIN_USER_NAME_INPUT",
  LOGIN_PASSWORD_INPUT = "LOGIN_PASSWORD_INPUT",
  LOGIN_SUBMIT_BTN = "LOGIN_SUBMIT_BTN",

  MODAL_CONFIRM_YES = "MODAL_CONFIRM_YES",
  MODAL_CONFIRM_NO = "MODAL_CONFIRM_NO",

  NAV_ADMINS = "NAV_ADMINS",
  NAV_ADMINS_ACCOUNT_SETTINGS = "NAV_ADMINS_ACCOUNT_SETTINGS",
  NAV_ADMINS_ATT_MENU = "NAV_ADMINS_ATT_MENU",
  NAV_ADMINS_USERS_LINK = "NAV_ADMINS_USERS_LINK",
  NAV_ADMINS_INBOUND_CALLS = "NAV_ADMINS_INBOUND_CALLS",
  NAV_ADMINS_OUTBOUND_CALLS = "NAV_ADMINS_OUTBOUND_CALLS",
  NAV_ADMINS_CONFERENCE_SUITE = "NAV_ADMINS_CONFERENCE_SUITE",
  NAV_ADMINS_DBN_DIRECTORY = "NAV_ADMINS_DBN_DIRECTORY",
  NAV_ADMINS_INBOUND_BRIDGE = "NAV_ADMINS_INBOUND_BRIDGE",
  NAV_ADMINS_MUSIC_ON_HOLD = "NAV_ADMINS_MUSIC_ON_HOLD",
  NAV_ADMINS_EXTERNAL_SIP_ADDRESS = "NAV_ADMINS_EXTERNAL_SIP_ADDRESS",
  NAV_ADMINS_EXTERNAL_PHONE_NUMBER = "NAV_ADMINS_EXTERNAL_PHONE_NUMBER",
  NAV_ADMINS_ANNOUNCEMENTS = "NAV_ADMINS_ANNOUNCEMENTS",
  NAV_ADMINS_GROUPS = "NAV_ADMINS_GROUPS",
  NAV_BILLING_LANDING_PAGE = "NAV_BILLING_LANDING_PAGE",
  NAV_ADMINS_VOICEMAILS = "NAV_ADMINS_VOICEMAILS",
  NAV_ADMINS_CALL_RECORDING = "NAV_ADMINS_CALL_RECORDING",
  NAV_HOME = "NAV_HOME",

  BILLING_PLAN_DETAILS_BUTTON = "BILLING_PLAN_DETAILS_BUTTON",
  BILLING_PLAN_CHOOSE_BUTTON = "BILLING_PLAN_CHOOSE_BUTTON",
  BILLING_PLAN_SELECT_BUTTON = "BILLING_PLAN_SELECT_BUTTON",
  BILLING_PLAN_CLOSE_SELECT_MODAL = "BILLING_PLAN_CLOSE_SELECT_MODAL",
  BILLING_PLAN_ROUTE_BUTTON = "BILLING_PLAN_ROUTE_BUTTON",
  BILLING_CONTACT_BUTTON = "BILLING_CONTACT_BUTTON",

  DIALOG_CONTENT = "DIALOG_CONTENT",

  ADMIN_SELECT_USERS_LIST_ITEM = "SELECT_USERS_LIST_ITEM",

  ADMIN_GROUP_ADD_BTN = "ADMIN_GROUP_ADD_BTN",
  ADMIN_GROUP_ADD_NAME = "ADMIN_GROUP_ADD_NAME",
  ADMIN_GROUP_ADD_ORDERING_PARALLEL = "ADMIN_GROUP_ADD_ORDERING_PARALLEL",
  ADMIN_GROUP_ADD_SIP_ADDRESS = "ADMIN_GROUP_ADD_SIP_ADDRESS",
  ADMIN_GROUP_ADD_NEXT_STEP = "ADMIN_GROUP_ADD_NEXT_STEP",
  ADMIN_GROUP_ADD_GO_REVIEW = "ADMIN_GROUP_ADD_GO_REVIEW",
  ADMIN_GROUP_ADD_SEARCH_USER = "ADMIN_GROUP_ADD_SEARCH_USER",
  ADMIN_GROUP_ADD_REVIEW_NAME = "ADMIN_GROUP_ADD_REVIEW_NAME",
  ADMIN_GROUP_ADD_REVIEW_ORDERING = "ADMIN_GROUP_ADD_REVIEW_ORDERING",
  ADMIN_GROUP_ADD_REVIEW_RING_DURATION = "ADMIN_GROUP_ADD_REVIEW_RING_DURATION",
  ADMIN_GROUP_PHONES = "ADMIN_GROUP_PHONES",
  ADMIN_GROUP_EXTENSIONS = "ADMIN_GROUP_EXTENSIONS",
  ADMIN_GROUP_ADD_REVIEW_FINISH = "ADMIN_GROUP_ADD_REVIEW_FINISH",
  ADMIN_GROUP_SEARCH = "ADMIN_GROUP_SEARCH",
  ADMIN_GROUP_ROW = "ADMIN_GROUP_ROW",
  ADMIN_GROUP_DELETE = "ADMIN_GROUP_DELETE",
  ADMIN_GROUP_VIEW_TABLE_BASIC_SETTINGS_TAB = "ADMIN_GROUP_VIEW_TABLE_BASIC_SETTINGS_TAB",
  ADMIN_GROUP_VIEW_TABLE_MEMBERS_TAB = "ADMIN_GROUP_VIEW_TABLE_MEMBERS_TAB",
  ADMIN_GROUP_VIEW_SHELVE_NAME = "ADMIN_GROUP_VIEW_SHELVE_NAME",
  ADMIN_GROUP_VIEW_ORDERING = "ADMIN_GROUP_VIEW_ORDERING",
  ADMIN_GROUP_VIEW_RING_DURATION = "ADMIN_GROUP_VIEW_RING_DURATION",
  ADMIN_GROUP_VIEW_SHELVE_EDIT = "ADMIN_GROUP_VIEW_SHELVE_EDIT",
  ADMIN_GROUP_VIEW_SHELVE_MEMBER = "ADMIN_GROUP_VIEW_SHELVE_MEMBER",
  ADMIN_GROUP_VIEW_SHELVE_MEMBERS_EDIT = "ADMIN_GROUP_VIEW_SHELVE_MEMBERS_EDIT",
  ADMIN_GROUP_EDIT_SHELVE_NAME = "ADMIN_GROUP_EDIT_SHELVE_NAME",
  ADMIN_GROUP_EDIT_SHELVE_SUBMIT = "ADMIN_GROUP_EDIT_SHELVE_SUBMIT",
  ADMIN_GROUP_EDIT_SHELVE_MEMBERS_SUBMIT = "ADMIN_GROUP_EDIT_SHELVE_MEMBERS_SUBMIT",

  ADMIN_GROUP_SELECT_MEMBER = "ADMIN_GROUP_SELECT_MEMBER",

  ADMIN_ACD_QUEUES = "ADMIN_ACD_QUEUES",
  ADMIN_ACD_QUEUE_ADD_BTN = "ADMIN_ACD_QUEUE_ADD_BTN",
  ADMIN_ACD_QUEUE_BASIC_QUEUE_SETUP_BTN = "ADMIN_ACD_QUEUE_BASIC_QUEUE_SETUP_BTN",
  ADMIN_ACD_QUEUE_ENHANCED_QUEUE_SETUP_BTN = "ADMIN_ACD_QUEUE_ENHANCED_QUEUE_SETUP_BTN",
  ADMIN_ACD_QUEUE_ADD_NAME = "ADMIN_ACD_QUEUE_ADD_NAME",
  ADMIN_ACD_QUEUE_ADD_LOGIN_PASS = "ADMIN_ACD_QUEUE_ADD_LOGIN_PASS",
  ADMIN_ACD_QUEUE_ADD_GO_TO_AGENT_SETTINGS_STEP = "ADMIN_ACD_QUEUE_ADD_GO_TO_AGENT_SETTINGS_STEP",
  ADMIN_ACD_QUEUE_ADD_GO_TO_CALLER_SETTINGS_STEP = "ADMIN_ACD_QUEUE_ADD_GO_TO_CALLER_SETTINGS_STEP",
  ADMIN_ACD_QUEUE_ADD_GO_TO_ENHANCED_SETTINGS_STEP = "ADMIN_ACD_QUEUE_ADD_GO_TO_ENHANCED_SETTINGS_STEP",
  ADMIN_ACD_QUEUE_ADD_ENHANCED_EMAIL = "ADMIN_ACD_QUEUE_ADD_ENHANCED_EMAIL",
  ADMIN_ACD_QUEUE_ADD_GO_REVIEW = "ADMIN_VOICEMAIL_ADD_GO_REVIEW",
  ADMIN_ACD_QUEUE_ADD_REVIEW_NAME = "ADMIN_ACD_QUEUE_ADD_REVIEW_NAME",
  ADMIN_ACD_QUEUE_ADD_REVIEW_LOGIN_PASS = "ADMIN_ACD_QUEUE_ADD_REVIEW_LOGIN_PASS",
  ADMIN_ACD_QUEUE_ADD_REVIEW_QUEUE_EXTENSIONS = "ADMIN_VOICEMAIL_ADD_REVIEW_EXTENSIONS",
  ADMIN_ACD_QUEUE_ADD_REVIEW_LOGIN_EXTENSIONS = "ADMIN_VOICEMAIL_ADD_REVIEW_EXTENSIONS",
  ADMIN_ACD_QUEUE_ADD_REVIEW_LOGIN_PHONES = "ADMIN_ACD_QUEUE_ADD_REVIEW_LOGIN_PHONES",
  ADMIN_ACD_QUEUE_ADD_REVIEW_ENHANCED_EMAIL = "ADMIN_ACD_QUEUE_ADD_REVIEW_ENHANCED_EMAIL",
  ADMIN_ACD_QUEUE_ADD_REVIEW_FINISH = "ADMIN_VOICEMAIL_ADD_REVIEW_FINISH",
  ADMIN_ACD_QUEUE_SEARCH = "ADMIN_VOICEMAIL_SEARCH",
  ADMIN_ACD_QUEUE_ROW = "ADMIN_VOICEMAIL_ROW",
  ADMIN_ACD_QUEUE_DELETE = "ADMIN_VOICEMAIL_DELETE",

  ADMIN_ACD_QUEUE_VIEW_SHELVE_NAME = "ADMIN_ACD_QUEUE_VIEW_SHELVE_NAME",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_PASS = "ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_PASS",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_QUEUE_EXTENSIONS = "ADMIN_ACD_QUEUE_VIEW_SHELVE_QUEUE_EXTENSIONS",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_EXTENSIONS = "ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_EXTENSIONS",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_PHONES = "ADMIN_ACD_QUEUE_VIEW_SHELVE_LOGIN_PHONES",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_EDIT_BUTTON = "ADMIN_ACD_QUEUE_VIEW_SHELVE_EDIT_BUTTON",
  ADMIN_ACD_QUEUE_VIEW_SHELVE_ENHANCED_EMAIL = "ADMIN_ACD_QUEUE_VIEW_SHELVE_ENHANCED_EMAIL",
  ADMIN_ACD_QUEUE_EDIT_SHELVE_NAME = "ADMIN_ACD_QUEUE_EDIT_SHELVE_NAME",
  ADMIN_ACD_QUEUE_EDIT_SHELVE_LOGIN_PASS = "ADMIN_ACD_QUEUE_EDIT_SHELVE_LOGIN_PASS",
  ADMIN_ACD_QUEUE_EDIT_SHELVE_EDIT_BUTTON = "ADMIN_ACD_QUEUE_EDIT_SHELVE_EDIT_BUTTON",
  ADMIN_ACD_QUEUE_EDIT_SHELVE_ENHANCED_EMAIL = "ADMIN_ACD_QUEUE_EDIT_SHELVE_ENHANCED_EMAIL",

  ADMIN_CALL_RECORDING_TABLE_ITEM = "ADMIN_CALL_RECORDING_TABLE_ITEM",
  ADMIN_CALL_RECORDING_ADD_BUTTON = "ADMIN_CALL_RECORDING_ADD_BUTTON",
  ADMIN_CALL_RECORDING_NAME = "ADMIN_CALL_RECORDING_NAME",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE = "ADMIN_CALL_RECORDING_STORAGE_SERVICE",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_NAME = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_NAME",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_BUCKET_NAME = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_BUCKET_NAME",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_ACCESS_KEY = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_ACCESS_KEY",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_SECRET_KEY = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_AWS_SECRET_KEY",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_GOOGLE_CLOUD = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_GOOGLE_CLOUD",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_NAME = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_NAME",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_API_KEY = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_API_KEY",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_USERNAME = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_ROCK_SPACE_USERNAME",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_OPTION = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_OPTION",
  ADMIN_CALL_RECORDING_STORAGE_SERVICE_ADD = "ADMIN_CALL_RECORDING_STORAGE_SERVICE_ADD",
  ADMIN_CALL_RECORDING_CALL_DIRECTION_BOTH = "ADMIN_CALL_RECORDING_CALL_DIRECTION_BOTH",
  ADMIN_CALL_RECORDING_CALL_DIRECTION_INBOUND = "ADMIN_CALL_RECORDING_CALL_DIRECTION_INBOUND",
  ADMIN_CALL_RECORDING_CALL_DIRECTION_OUTBOUND = "ADMIN_CALL_RECORDING_CALL_DIRECTION_OUTBOUND",
  ADMIN_CALL_RECORDING_CALL_GO_TO_USERS = "ADMIN_CALL_RECORDING_CALL_GO_TO_USERS",
  ADMIN_CALL_RECORDING_CALL_GO_TO_REVIEW = "ADMIN_CALL_RECORDING_CALL_GO_TO_REVIEW",
  ADMIN_CALL_RECORDING_SEARCH_USER = "ADMIN_CALL_RECORDING_SEARCH_USER",
  ADMIN_CALL_RECORDING_REVIEW_GROUP_NAME = "ADMIN_CALL_RECORDING_REVIEW_GROUP_NAME",
  ADMIN_CALL_RECORDING_REVIEW_STORAGE_SERVICE = "ADMIN_CALL_RECORDING_REVIEW_STORAGE_SERVICE",
  ADMIN_CALL_RECORDING_REVIEW_CALL_DIRECTORY = "ADMIN_CALL_RECORDING_REVIEW_CALL_DIRECTORY",
  ADMIN_CALL_RECORDING_REVIEW_MEMBERS = "ADMIN_CALL_RECORDING_REVIEW_MEMBERS",
  ADMIN_CALL_RECORDING_SUBMIT_BUTTON = "ADMIN_CALL_RECORDING_SUBMIT_BUTTON",
  ADMIN_CALL_RECORDING_TABLE_SEARCH = "ADMIN_CALL_RECORDING_TABLE_SEARCH",

  ADMIN_CALL_RECORDING_TABLE_GROUP_NAME = "ADMIN_CALL_RECORDING_TABLE_GROUP_NAME",
  ADMIN_CALL_RECORDING_TABLE_STORAGE_SERVICE = "ADMIN_CALL_RECORDING_TABLE_STORAGE_SERVICE",
  ADMIN_CALL_RECORDING_TABLE_CALL_DIRECTORY = "ADMIN_CALL_RECORDING_TABLE_CALL_DIRECTORY",
  ADMIN_CALL_RECORDING_TABLE_MEMBERS = "ADMIN_CALL_RECORDING_TABLE_MEMBERS",
  ADMIN_CALL_RECORDING_TABLE_USER_TAB = "ADMIN_CALL_RECORDING_TABLE_USER_TAB",
  ADMIN_CALL_RECORDING_TABLE_BASE_SETTINGS_TAB = "ADMIN_CALL_RECORDING_TABLE_BASE_SETTINGS_TAB",
  ADMIN_CALL_RECORDING_TABLE_EDIT_BUTTON = "ADMIN_CALL_RECORDING_TABLE_EDIT_BUTTON",
  ADMIN_CALL_RECORDING_TABLE_EDIT_USER_TAB_BUTTON = "ADMIN_CALL_RECORDING_TABLE_EDIT_USER_TAB_BUTTON",
  ADMIN_CALL_RECORDING_CALL_SAVE_CHANGES = "ADMIN_CALL_RECORDING_CALL_SAVE_CHANGES",
  ADMIN_CALL_RECORDING_TABLE_ITEM_DELETE_BUTTON = "ADMIN_CALL_RECORDING_TABLE_ITEM_DELETE_BUTTON",
  ACCOUNT_BILLING_PLAN_SELECTED_USERS = "ACCOUNT_BILLING_PLAN_SELECTED_USERS",
  ACCOUNT_BILLING_PLAN_SELECTED_USERS_PRICE = "ACCOUNT_BILLING_PLAN_SELECTED_USERS_PRICE",
  ADMIN_CALL_RECORDING_SELECT_ALL = "ADMIN_CALL_RECORDING_SELECT_ALL",

  ADMIN_PARKING_LOT_BUTTON = "ADMIN_PARKING_LOT_BUTTON",
  ADMIN_PARKING_LOT_SAVE_CHANGES_BUTTON = "ADMIN_PARKING_LOT_SAVE_CHANGES_BUTTON",
  ADMIN_ADD_PARKING_LOT_BUTTON = "ADMIN_ADD_PARKING_LOT_BUTTON",
  ADMIN_PARKING_LOT_NAME = "ADMIN_PARKING_LOT_NAME",
  ADMIN_PARKING_LOT_CHANNEL_LIMIT_SELECT = "ADMIN_PARKING_LOT_CHANNEL_LIMIT_SELECT",
  ADMIN_PARKING_LOT_PIN = "ADMIN_PARKING_LOT_PIN",
  ADMIN_PARKING_LOT_SEARCH = "ADMIN_PARKING_LOT_SEARCH",
  ADMIN_PARKING_LOT_FAILOVER = "ADMIN_PARKING_LOT_FAILOVER",
  ADMIN_PARKING_LOT_GO_TO_CALLER_SETTINGS_BUTTON = "ADMIN_PARKING_LOT_GO_TO_CALLER_SETTINGS_BUTTON",
  ADMIN_PARKING_LOT_GO_TO_REVIEW_BUTTON = "ADMIN_PARKING_LOT_GO_TO_REVIEW_BUTTON",
  ADMIN_PARKING_LOT_GO_TO_SUBMIT_BUTTON = "ADMIN_PARKING_LOT_GO_TO_SUBMIT_BUTTON",
  ADMIN_PARKING_LOT_TABLE_ROW = "ADMIN_PARKING_LOT_TABLE_ROW",
  ADMIN_PARKING_LOT_RETRIEVAL_EXTENSIONS = "ADMIN_PARKING_LOT_RETRIEVAL_EXTENSIONS",
  ADMIN_PARKING_LOT_RETRIEVAL_ADDRESS = "ADMIN_PARKING_LOT_RETRIEVAL_ADDRESS",
  ADMIN_PARKING_LOT_EXTENSIONS = "ADMIN_PARKING_LOT_EXTENSIONS",
  ADMIN_PARKING_LOT_SIP_ADDRESS = "ADMIN_PARKING_LOT_SIP_ADDRESS",
  ADMIN_PARKING_LOT_CHANNELS_LIMIT = "ADMIN_PARKING_LOT_CHANNELS_LIMIT",
  ADMIN_PARKING_LOT_EDIT_PARKING_LOT_SETTINGS_BUTTON = "ADMIN_PARKING_LOT_EDIT_PARKING_LOT_SETTINGS_BUTTON",
  ADMIN_ADD_PARKING_LOT_DELETE_BUTTON = "ADMIN_ADD_PARKING_LOT_DELETE_BUTTON"
}
