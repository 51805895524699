<mat-card class="onsip-profile-card onsip-profile-card-background">
  <div class="user-description">
    <div class="avatar-edit-container">
      <button
        id="onsip--profile--avatar-edit"
        aria-label="edit avatar"
        mat-icon-button
        (click)="openUploadAvatarModal()"
        class="avatar-edit-button"
      >
        <mat-icon class="edit-icon" svgIcon="white_pencil_edit" aria-label="Edit"></mat-icon>
      </button>
      <onsip-avatar
        id="onsip--profile--avatar"
        [avatarUrl]="(myAvatar$ | async) || ''"
        [name]="(name$ | async)!"
      ></onsip-avatar>
    </div>
    <h2 id="onsip-user-settings-user-profile-name" class="user-profile-name">
      {{ name$ | async }}
    </h2>
    <div class="title-container">
      <button
        [id]="(titleEditing | async) ? 'onsip--profile--title-save' : 'onsip--profile--title-edit'"
        [attr.aria-label]="(titleEditing | async) ? 'save title changes' : 'edit title'"
        mat-icon-button
        (click)="toggleTitleInfoEdit()"
        class="title-edit-button"
      >
        @if ((titleEditing | async) === false) {
        <mat-icon class="edit-icon" svgIcon="white_pencil_edit" aria-label="Edit"> </mat-icon>
        } @if (titleEditing | async) {
        <mat-icon aria-label="Save"> check </mat-icon>
        }
      </button>
      @if ((titleEditing | async) === false && titleControl.value?.length) {
      <h3 class="title-form-text">
        {{ titleControl.value }}
      </h3>
      } @if ((titleEditing | async) === false && !titleControl.value?.length) {
      <p class="title-form-text">
        {{ "ONSIP_I18N.WHATS_YOUR_TITLE" | translate }}
      </p>
      } @if (titleEditing | async) {
      <mat-form-field class="title-form-field onsip-form-field-white">
        <mat-label>{{ "ONSIP_I18N.YOUR_TITLE" | translate }}</mat-label>
        <textarea matInput cdkTextareaAutosize [formControl]="titleControl" maxlength="40">
        </textarea>
      </mat-form-field>
      }
    </div>
  </div>
  <div class="user-contact-info">
    <div class="card-section">
      <div class="about-me-header">
        <span class="label">
          {{ "ONSIP_I18N.ABOUT_ME" | translate }}
        </span>
        <button
          [id]="
            (blurbEditing | async) ? 'onsip--profile--blurb-save' : 'onsip--profile--blurb-edit'
          "
          [attr.aria-label]="(blurbEditing | async) ? 'save blurb' : 'edit blurb'"
          mat-icon-button
          (click)="toggleBlurbInfoEdit()"
        >
          @if ((blurbEditing | async) === false) {
          <mat-icon class="edit-icon" svgIcon="white_pencil_edit" aria-label="Edit"></mat-icon>
          } @if (blurbEditing | async) {
          <mat-icon aria-label="Save">check</mat-icon>
          }
        </button>
      </div>
      <div class="about-me-container">
        @if ((blurbEditing | async) === false && blurbControl.value?.length) {
        <p>{{ blurbControl.value }}</p>
        } @if ((blurbEditing | async) === false && !blurbControl.value?.length) {
        <p>
          {{ "ONSIP_I18N.WRITE_SOMETHING_ABOUT_YOURSELF" | translate }}
        </p>
        } @if (blurbEditing | async) {
        <mat-form-field class="bio-form-field onsip-form-field-white">
          <mat-label>{{ "ONSIP_I18N.ABOUT" | translate }} {{ name$ | async }}</mat-label>
          <textarea
            matInput
            cdkTextareaAutosize
            [formControl]="blurbControl"
            [maxlength]="maxBlurbLength"
          ></textarea>
          <mat-hint align="end"
            >{{ blurbControl.value?.length || 0 }}/{{ maxBlurbLength }}</mat-hint
          >
        </mat-form-field>
        }
      </div>
    </div>
    <div class="card-section">
      <span class="label">
        {{ "ONSIP_I18N.PERSONAL_CALL_PAGE" | translate }}
      </span>
      @if(link$ | async; as link) {
      <p>
        <a target="_blank" href="https://{{ link }}">https://{{ link }}</a>
      </p>
      }
    </div>
    <div class="card-section">
      <span class="label">
        {{ "ONSIP_I18N.EMAIL" | translate }}
      </span>
      <p class="mat-body-2">{{ email$ | async }}</p>
    </div>
  </div>
  <div class="user-contact-options">
    <div class="card-section profile-publish-email">
      <span class="label">
        {{ "ONSIP_I18N.SHOW_EMAIL_ON_PERSONAL_CALL_PAGE" | translate }}
      </span>
      <mat-slide-toggle
        color="primary"
        [checked]="publishEmail$ | async"
        (change)="onCheckboxChange($event)"
        labelPosition="before"
      >
      </mat-slide-toggle>
    </div>
    <div class="user-media-links">
      <button
        [id]="
          (mediaEditing | async) ? 'onsip--profile--socials-save' : 'onsip--profile--socials-edit'
        "
        [attr.aria-label]="
          (mediaEditing | async) ? 'save social media link changes' : 'edit social media links'
        "
        mat-icon-button
        (click)="toggleMediaEdit()"
        class="social-links-edit-button"
        [disabled]="!socialMediaFormGroup.valid"
      >
        @if ((mediaEditing | async) === false) {
        <mat-icon class="edit-icon" svgIcon="white_pencil_edit" aria-label="Edit"> </mat-icon>
        } @if (mediaEditing | async) {
        <mat-icon aria-label="Save"> check </mat-icon>
        }
      </button>
      @for (item of items; track item) { @if (item.isSocialMedia) {
      <div class="social-media-container">
        <mat-icon
          class="social-media-icon"
          [svgIcon]="item.field.toLowerCase() + '_icon'"
          aria-label="Edit"
        ></mat-icon>
        @if ((mediaEditing | async) === false && !socialMediaFormGroup.controls[item.field].value) {
        <span class="mat-body-2 social-media-content">
          {{ "ONSIP_I18N.ADD_PROFILE" | translate }}
        </span>
        } @if ((mediaEditing | async) === false && socialMediaFormGroup.controls[item.field].value)
        {
        <span class="mat-body-2 social-media-content">
          {{ item.urlPrefix }}{{ socialMediaFormGroup.controls[item.field].value }}
        </span>
        } @if (mediaEditing | async) {
        <div class="mat-body-2 social-media-form-container">
          <mat-form-field class="social-media-content-form onsip-form-field-white">
            <input
              matInput
              class="text-input"
              type="text"
              autocomplete="off"
              [formControl]="socialMediaFormGroup.controls[item.field]"
              [value]="socialMediaFormGroup.controls[item.field].value"
            />
            <span matPrefix class="mat-body-2">{{ item.urlPrefix }}</span>
            @if (!socialMediaFormGroup.controls[item.field].valid) {
            <mat-error>
              {{
                "ONSIP_I18N.PLEASE_MAKE_SURE_TO_INCLUDE_ONLY_THE_RELEVANT_PART_OF_THE_URL"
                  | translate
              }}
            </mat-error>
            }
          </mat-form-field>
        </div>
        }
      </div>
      } }
    </div>
  </div>
</mat-card>
