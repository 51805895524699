import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { filter, map, shareReplay, pluck } from "rxjs/operators";

import { CallControllerService } from "../../../../common/services/call-controller.service";
import { DragDropCallService } from "../../shared/services/dragDropCall/drag-drop-call.service";
import { EavesdropService } from "../../queue/realtime/eavesdrop.service";
import { CallHistoryDialogService } from "../../../../common/services/callHistory/call-history-dialog.service";
import {
  VideoConferenceService,
  VideoConferenceState
} from "../../videoConference/video-conference.service";

import { CallState } from "../../../../common/libraries/sip/call-state";
import { PersonalDialog } from "../../../../common/interfaces/presentity";
import { views } from "../../../app/phone/views";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { Role } from "@onsip/common/services/api/role";

@Component({
  selector: "onsip-call-list",
  templateUrl: "./call-list.component.html",
  styleUrls: ["./call-list.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CallListComponent implements OnInit, OnDestroy {
  /** uuid of call being displayed on call page */
  activeVisibleCallUuidObservable!: Observable<string | undefined>;
  /** Current call list */
  callListObservable: Observable<Array<CallState>> = this.getCallsObservable();
  /** Current dialog list dialogs for any of your AORs */
  dialogList!: Observable<Array<PersonalDialog>>;
  /** Current eaves drop call (queue dashboard), may only have one at a time */
  eavesdropCallObservable: Observable<CallState | undefined> = this.getEavesdropObservable();
  /** Current video conference, may only have one at a time */
  videoConferenceObservable: Observable<VideoConferenceState> = this.videoConferenceService.state;
  views: any = views;
  /** Most recent activeVisibleCallUuid, allows back button use for navigation */
  private mostRecentActive: string | undefined;
  private unsubscriber = new Subscription();

  constructor(
    private callControllerService: CallControllerService,
    private eavesdropService: EavesdropService,
    private router: Router,
    private videoConferenceService: VideoConferenceService,
    private callHistoryDialogService: CallHistoryDialogService,
    private apiSession: ApiSessionService,
    public dragDropCallService: DragDropCallService
  ) {}

  ngOnInit(): void {
    // Other observables are only subscribed to in template. These require some interaction with component state.
    this.dialogList = this.callHistoryDialogService.state.pipe(pluck("currentDialogs"));
    this.initRouterSubscription();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  /** Call List Item (click) callback */
  navigateToCall(call: CallState): void {
    this.router.navigate([views.CALL], {
      state: {
        callUuid: call.uuid
      }
    });
  }

  /** Dialog List Item (click) callback */
  navigateToHome(): void {
    if (
      this.apiSession.stateValue.parentUserId &&
      this.apiSession.stateValue.loggedInRole !== Role.SuperUser
    ) {
      return;
    }
    this.router.navigate([views.HOME]);
  }

  /** Eavesdrop List Item (click) callback */
  navigateToQueueDashboard(): void {
    if (
      this.apiSession.stateValue.parentUserId &&
      this.apiSession.stateValue.loggedInRole !== Role.SuperUser
    ) {
      return;
    }
    this.router.navigate([views.QUEUE_DASHBOARD]);
  }

  /** Video Conference List Item (click) callback */
  navigateToVideoConference(): void {
    if (
      this.apiSession.stateValue.parentUserId &&
      this.apiSession.stateValue.loggedInRole !== Role.SuperUser
    ) {
      return;
    }
    this.router.navigate([views.VIDEO_CONFERENCE]);
  }

  endEavesdrop(uuid: string): void {
    this.callControllerService.endCall(uuid);
  }

  endVideoConf(): void {
    this.videoConferenceService.endVideoConference();
  }

  holdVideoConf(): void {
    this.videoConferenceService.hold();
  }

  trackByCid(index: number, item: PersonalDialog): string {
    return item.callId;
  }

  trackByUuid(index: number, item: CallState): string {
    return item.uuid;
  }

  /** Return observable to Array of all CallStates except eavesdrop */
  private getCallsObservable(): Observable<Array<CallState>> {
    return this.callControllerService.state.pipe(
      map(state =>
        state.calls.filter(
          call => call.stage !== "init" && !this.eavesdropService.isEavesdropCall(call.uuid)
        )
      )
    );
  }

  /** Return observable of eavesdrop callstate if one exists */
  private getEavesdropObservable(): Observable<CallState | undefined> {
    return this.callControllerService.state.pipe(
      map(state => state.calls.find(call => this.eavesdropService.isEavesdropCall(call.uuid)))
    );
  }

  /** Returns observable of uuid for active visible call, i.e. unheld call with user on call page
   *  This is used to determine which call-list-item, if any, will display video
   */
  private getRouterObservable(): Observable<string | undefined> {
    return this.router.events.pipe(
      filter(e => e instanceof NavigationEnd),
      map(() => {
        if (this.router.url.toString() === views.CALL) {
          const callUuid: string | undefined = history.state?.callUuid;
          if (callUuid) {
            return callUuid;
          } else {
            // back button press
            // if user preses back, history.state is no longer useful, so use the old call, or if its ended then the
            // current active call (if none, just old call, even if ended). This was an arbitrary choice.
            const oldCall: CallState | undefined = this.mostRecentActive
              ? this.callControllerService.getCallStateByUuid(this.mostRecentActive)
              : undefined;

            if (!oldCall || oldCall.ended) {
              const newCall: CallState | undefined = this.callControllerService.getUnheldCall();
              if (newCall) {
                return newCall.uuid;
              }
            } else {
              return this.mostRecentActive;
            }
          }
        } else {
          return undefined;
        }
      }),
      // eslint-disable-next-line rxjs/no-sharereplay
      shareReplay(1)
    );
  }

  /** Maintains a reference to last active visible call uuid in case of back button routing */
  private initRouterSubscription(): void {
    this.activeVisibleCallUuidObservable = this.getRouterObservable();
    this.unsubscriber.add(
      this.activeVisibleCallUuidObservable
        .pipe(filter((uuid): uuid is string => !!uuid))
        .subscribe(uuid => {
          this.mostRecentActive = uuid;
        })
    );
  }
}
