import { AppSettingsComponent } from "./app-settings.component";
import { ProfileComponent } from "./profile/profile.component";
import { SentryReportComponent } from "./sentry-report/sentry-report.component";

import { ModalModule } from "../shared/components/modal/modal.module";
import { AvatarModule } from "../shared/components/avatar/avatar.module";
import { PasswordModule } from "../shared/components/password/password.module";
import { ChatModule } from "../shared/components/chat/chat.module";
import { UsabilityModule } from "../shared/components/usability/usability.module";
import { VolumeModule } from "../shared/components/volume/volume.module";
import { E911Module } from "../shared/components/e911/e911.module";
import { E911AdminModule } from "../shared/components/e911-admin/e911-admin.module";
import { QueueModule } from "../queue/queue.module";
import { ImageUploaderModule } from "../shared/components/imageUploader/image-uploader.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ColorPickerModule } from "ngx-color-picker";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxFileDropModule } from "ngx-file-drop";
import { MaterialModule } from "../../modules/material/material.module";

import { HubspotModule } from "../shared/components/hubspot/hubspot.module";

import { SaysoInstallationModule } from "../sayso/saysoSettings/installation/sayso-installation.module";

// Conversations Tab Refugees
import { SettingsIntegrationsComponent } from "./settingsIntegrations/settings-integrations.component";
import { VideoPresenceToggleComponent } from "./videoPresenceToggle/video-presence-toggle.component";
import { TranslateModule } from "@ngx-translate/core";
// import { TopicsModule } from "../sayso/settings/topicsList/topics.module";
import { PipesModule } from "../shared/pipes/pipes.module";
import { QueueWarningsSettingsComponent } from "../queue/warnings/queue-warnings-settings.component";
import { CallPreferencesComponent } from "./call-preferences/call-preferences.component";
import { AccountDetailsComponent } from "./account-details/account-details.component";
import { AppControlsComponent } from "./app-controls/app-controls.component";
import { LoadingContainerComponent } from "../shared/components/loadingContainer/loading-container.component";

@NgModule({
  declarations: [
    AppSettingsComponent,
    ProfileComponent,
    SentryReportComponent,
    SettingsIntegrationsComponent,
    VideoPresenceToggleComponent,
    CallPreferencesComponent,
    AccountDetailsComponent,
    AppControlsComponent
  ],
  exports: [AppSettingsComponent, SentryReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    AvatarModule,
    PasswordModule,
    ChatModule,
    UsabilityModule,
    VolumeModule,
    E911AdminModule,
    E911Module,
    QueueModule,
    MaterialModule,
    ColorPickerModule,
    ImageCropperModule,
    NgxFileDropModule,
    AvatarModule,
    HubspotModule,
    SaysoInstallationModule,
    TranslateModule,
    ImageUploaderModule,
    PipesModule,
    QueueWarningsSettingsComponent,
    LoadingContainerComponent
  ]
})
export class AppSettingsModule {}
