import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { OnsipFormSubmitDirective } from "./onsip-form-submit.directive";
import { OnsipAddressValidatorDirective } from "./onsip-address-validator.directive";
import { OnsipCityValidatorDirective } from "./onsip-city-validator.directive";
import { OnsipStateValidatorDirective } from "./onsip-state-validator.directive";
import { OnsipZipCodeValidatorDirective } from "./onsip-zipcode-validator.directive";

@NgModule({
  imports: [FormsModule],
  declarations: [
    OnsipFormSubmitDirective,
    OnsipAddressValidatorDirective,
    OnsipCityValidatorDirective,
    OnsipStateValidatorDirective,
    OnsipZipCodeValidatorDirective
  ],
  exports: [
    OnsipFormSubmitDirective,
    OnsipAddressValidatorDirective,
    OnsipCityValidatorDirective,
    OnsipStateValidatorDirective,
    OnsipZipCodeValidatorDirective
  ]
})
export class OnsipFormsModule {}
