import { Injectable } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";
import {
  ApiPromiseState,
  ApiPromiseStateService
} from "@onsip/common/services/api/api-promise-state.service";
import { ApiResourceService } from "@onsip/common/services/api/resources/api-resource.service";
import { OnsipApiResponse } from "@onsip/common/services/api/apiResponse/response-body-new";
import { ApiGenericAction } from "@onsip/common/services/api/api-actions";
import { getApiActionName } from "@onsip/common/services/api/onsip-api-action-new";

const debug = false;

@Injectable({ providedIn: "root" })
export class SignupSendUserEmailService extends ApiResourceService<any> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "SignupSendUserEmail", "");
    debug && this.state.subscribe(state => console.warn("Signup.state", state));
  }

  signupSendUserEmail(params: { Email: string; Destination: string }): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.SignupSendUserEmail,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.SignupSendUserEmail);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiGenericAction.SignupSendUserEmail:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
