import { OperatorFunction } from "rxjs";
import { filter, map, withLatestFrom, distinctUntilChanged, startWith } from "rxjs/operators";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { User } from "../resources/user/user";
import { currentOrganization } from "./current-organization";

import { userId } from "./user-id";

export function domain(): OperatorFunction<ApiStateStoreState, string> {
  return storeState =>
    storeState.pipe(
      withLatestFrom(
        storeState.pipe(userId()),
        // in case current Organization is not initiated, which it won't be for non admin users as they cannot call organizationRead,
        // we need to at least populate the observable with one value so the rest of the pipe works. Hence the startWith(undefined)
        storeState.pipe(currentOrganization(), startWith(undefined))
      ),
      map(
        ([store, selfUserId, currentOrg]) =>
          currentOrg?.domain || (store.User as ApiSubstate<User>)?.state[selfUserId]?.domain
      ),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}
