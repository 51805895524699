import {
  Directive,
  Input,
  OnChanges,
  ElementRef,
  NgZone,
  Output,
  EventEmitter
} from "@angular/core";

/** see getBarHeight, this is the source specified by design, in px */
const BAR_HEIGHTS = [4, 10, 13, 18, 13, 18, 13, 10, 5];
/** space between bars ratio */
const BAR_HEIGHTS_RATIO = 0.0333;
/** width ration */
const BAR_WIDTH_RATIO = 0.1;
/** space between bars ratio */
const BAR_SPACING_RATIO = 0.7;

const debug = false;

@Directive({
  selector: "[onsipSoundWave]"
})
export class SoundWaveDirective implements OnChanges {
  @Input("onsipSoundWave") recorder!: MediaRecorder;
  @Input() width!: number;
  @Input() height!: number;
  @Output() soundWaveBlob = new EventEmitter<Blob>();

  /** total length of recorded data bit */
  private recordLength = 0;
  /** array to store all recorded data bits */
  private recordBuffer: Array<Float32Array> = [];
  /** sample rate - the frequency at which data is recorded - by default - the sampling rate is at 16000 Hz */
  private recordSampleRate = 0;

  constructor(private zone: NgZone, private elementRef: ElementRef<HTMLCanvasElement>) {
    if (this.elementRef.nativeElement.constructor !== HTMLCanvasElement) {
      throw new Error("SoundWaveDiretive: you put this on something that isn't a <canvas>");
    }
  }

  ngOnChanges() {
    if (!this.recorder.stream) {
      return;
    }

    const context = new AudioContext();
    const source = context.createMediaStreamSource(this.recorder.stream);
    // eslint-disable-next-line deprecation/deprecation
    const processor = context.createScriptProcessor(1024, 1, 1);
    this.recordSampleRate = source.context.sampleRate;

    // define the volume up here so draw() closes over it
    let volume = 0;

    source.connect(processor);
    processor.connect(context.destination);

    // adapted from https://github.com/cwilso/volume-meter/blob/master/volume-meter.js
    // eslint-disable-next-line deprecation/deprecation
    processor.onaudioprocess = event => {
      // eslint-disable-next-line deprecation/deprecation
      const buffer = event.inputBuffer.getChannelData(0);
      this.recordLength += buffer.length;
      // buffer needs to be a copy because apparently, it can be modified by reference
      // https://stackoverflow.com/questions/58785295/use-javascript-to-record-audio-as-wav-in-chrome
      // the SO uses a spread but we just need a copy so slicing does the trick too without breaking types
      this.recordBuffer.push(buffer.slice(0));

      // Do a root-mean-square on the samples: sum up the squares...
      const sum = buffer.reduce((acc, curr) => acc + curr ** 2, 0);
      // ... then take the square root of the sum.
      const rms = Math.sqrt(sum / buffer.length);

      // Now smooth this out with the averaging factor applied
      // to the previous sample - take the max here because we
      // want "fast attack, slow release."
      volume = Math.max(rms, volume * 0.9);
    };

    let willContinueAnimating = true;
    this.recorder.addEventListener("stop", () => {
      this.recorder.stream.getAudioTracks()[0].stop();
      source.disconnect();
      processor.disconnect();
      willContinueAnimating = false;

      // when the stream is finished we need to format and encode the recording to a WAV file with PCM encoding
      // otherwise, the backend does not accept
      // Steps are taken from the exportBuffer function in https://github.com/awslabs/aws-lex-browser-audio-capture/blob/5134d32b845c2373f67ec7df4db849dd4f2a973d/lib/worker.js#L87
      // and also https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/raw-pcm.html
      // I cut out the downsample step because we aren't downsampling
      debug && console.warn(this.recordBuffer, this.recordLength, this.recordSampleRate);
      const mergedBuffers = mergeBuffers(this.recordBuffer, this.recordLength);
      debug && console.warn(mergedBuffers);
      const encodedWav = encodeWav(mergedBuffers, this.recordSampleRate);
      debug && console.warn(encodedWav);
      const audioBlob = new Blob([encodedWav], { type: "audio/wav" });
      debug && console.warn(audioBlob);
      this.soundWaveBlob.emit(audioBlob);
    });

    const draw = () => {
      const canvas = this.elementRef.nativeElement;
      if (!canvas) {
        throw new Error("SoundWaveDirectve: no canvas");
      }
      const canvasCtx = canvas.getContext("2d");
      if (!canvasCtx) {
        throw new Error("SoundWaveDirectve: no canvasCtx");
      }
      // set the canvas width to the size of the element so our image doesn't stretch on canvas resize
      canvas.width = this.width || canvas.clientWidth;
      canvas.height = this.height || canvas.clientHeight;
      const width = canvas.clientWidth;
      const height = canvas.clientHeight;
      // clear the new rectangle on every frame
      canvasCtx.clearRect(0, 0, width, height);

      const barWidth = height * BAR_WIDTH_RATIO;
      const barSpacing = barWidth * BAR_SPACING_RATIO;
      const barHeights = BAR_HEIGHTS.map(h => h * height * BAR_HEIGHTS_RATIO);

      canvasCtx.lineWidth = barWidth;
      canvasCtx.lineCap = "round";

      // where we start drawing our lines
      const midline = height / 2;
      // make a bar with a definite width for the whole length of the canvas
      const numBars = Math.floor(width / (barWidth + barSpacing));

      // to make the animation more interesting, we add a period shift to the sin function based on time.
      /** higher means slower- its on a millisecond basis */
      const speed = 2000;
      const ms = new Date().getMilliseconds();
      const sec = new Date().getSeconds();
      // we multiply by pi to ensure the period is always a whole number multiple of pi, so that the animation doesn't jump
      const periodShift = (2 * Math.PI * ((ms + 1000 * sec) % speed)) / speed;
      /** the direction of the animation */
      const forward = true;
      // draw the bars
      // there's 3 contributors to the bar's height:
      //    - the base height, from the array that was provided by design. It's treated as a % of the canvas height
      //    - the volume from the audio processor, we multiply i
      //    - the bonus, which is just a sin wave, we both add and mutliply by the bonus to make the peaks peakier
      // all these numbers i kinda just fiddled with until i got something i liked

      /** lower means a wide sin wave */
      const frequency = 0.25;
      /** how high the sin function goes, we add it on too to make our sin have only positive values */
      const amplitude = 5;
      /** some dumb constant, higher makes the sin wave bigger */
      const bonusTermCoeff = 0.5;
      /** the last constant, higher makes the volume more sensitive */
      const volumeTermCoeff = 0.1;

      function getBaseBarHeight(i: number): number {
        return (
          barHeights[i % barHeights.length] /
          barHeights.reduce((acc, curr) => (acc > curr ? acc : curr), 0) // max of the array
        );
      }

      for (let i = 0; i < numBars; i++) {
        const bonus =
          amplitude * Math.sin(frequency * i + (forward ? -1 : 1) * periodShift) + amplitude;
        const halfBarHeight =
          bonusTermCoeff * bonus + volumeTermCoeff * bonus * volume * getBaseBarHeight(i) * height;
        /** the bars get darker the bigger they are, the 175 and 200 refer to rgb values of our lightest and something that's proportional to our darkest. It's safe to go into the negatives here */
        const gray = 175 - (200 * halfBarHeight) / height;
        canvasCtx.strokeStyle = `rgb(${gray},${gray},${gray})`;
        canvasCtx.beginPath();
        // strokes are done in the middle of the stroke width, so multiply width by 1.5
        const x = i * (1.5 * barWidth + barSpacing);
        // we offset the bars from the midline a bit to make it more interesting
        canvasCtx.moveTo(x, midline + 0.75 * halfBarHeight);
        canvasCtx.lineTo(x, midline - 1.25 * halfBarHeight);
        canvasCtx.stroke();
        // reset the color just in case
        canvasCtx.strokeStyle = "black";
      }
      willContinueAnimating && window.requestAnimationFrame(draw);
    };
    this.zone.runOutsideAngular(draw.bind(this));
  }
}

function mergeBuffers(bufferArray: Array<Float32Array>, recLength: number): Float32Array {
  const result = new Float32Array(recLength);
  let offset = 0;
  for (let i = 0; i < bufferArray.length; i++) {
    result.set(bufferArray[i], offset);
    offset += bufferArray[i].length;
  }
  return result;
}

function encodeWav(samples: Float32Array, sampleRate: number): DataView {
  const buffer = new ArrayBuffer(44 + samples.length * 2);
  const view = new DataView(buffer);

  writeString(view, 0, "RIFF");
  view.setUint32(4, 44 + samples.length * 2, true);
  writeString(view, 8, "WAVE");
  writeString(view, 12, "fmt ");
  view.setUint32(16, 16, true);
  view.setUint16(20, 1, true);
  view.setUint16(22, 1, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, sampleRate * 2, true);
  view.setUint16(32, 2, true);
  view.setUint16(34, 16, true);
  writeString(view, 36, "data");
  view.setUint32(40, samples.length * 2, true);
  floatTo16BitPCM(view, 44, samples);

  return view;
}

function writeString(view: DataView, offset: number, string: string): void {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
}

function floatTo16BitPCM(output: DataView, offset: number, input: Float32Array): void {
  for (let i = 0; i < input.length; i++) {
    output.setInt16(offset, input[i] * 0x7fff, true);
    offset += 2;
  }
}
