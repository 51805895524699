import { CallEvent, EventId } from "./call-event";

/** Emitted once when Call is connected. */
export class ConnectedCallEvent extends CallEvent {
  id = EventId.ConnectedCallEventId;

  /**
   * Factory for ConnectedCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isConnectedCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for ConnectedCallEvent.
 * @param event Event to check.
 */
export function isConnectedCallEvent(event: any): event is ConnectedCallEvent {
  return event instanceof ConnectedCallEvent;
}
