import {
  Document,
  arrayUnion,
  timestampSetData,
  timestampUpdateData
} from "../cloud/firebase/firestore/document";
import { CallData, CallState, makeCallState } from "./call-state";
import { SessionState } from "../../sip/session";
import { CallContext } from "../../../interfaces/store/call-context";

function makeDocumentPath(oid: number, aid: string): string {
  return `orgs/${oid}/calls/${aid}`;
}

/**
 * Call class.
 */
export class Call extends Document<CallState> {
  /**
   * Gets the state of an Call.
   * @param oid Organization Id.
   * @param cid Call Id.
   */
  static get(oid: number, cid: string): Promise<CallState | undefined> {
    return this.getDocument(makeDocumentPath(oid, cid), makeCallState);
  }

  /**
   * Merges a Call in an Org
   * @param oid Organization Id.
   * @param cid Call Id.
   * @param data Call data.
   */
  static merge(oid: number, cid: string, data: Partial<CallData>): Promise<void> {
    return this.mergeDocument(makeDocumentPath(oid, cid), timestampUpdateData(data));
  }

  /**
   * Merges a SessionState into a Call in an Org
   * @param oid Organization Id.
   * @param cid Call Id.
   * @param aor AOR to of the session peer.
   * @param ouid OUID of the call.
   * @param state The session state to merge.
   */
  static mergeSession(
    oid: number,
    cid: string,
    aor: string,
    ouid: string,
    state: SessionState
  ): Promise<void> {
    const data = {
      aors: arrayUnion(aor),
      ouid,
      sessions: {
        [state.uuid]: state
      }
    };
    return this.mergeDocument(makeDocumentPath(oid, cid), timestampUpdateData(data));
  }

  /**
   * Sets a Call in an Org
   * @param oid Organization Id.
   * @param cid Call Id.
   * @param data Call data.
   */
  static set(oid: number, cid: string, data: CallData): Promise<void> {
    return this.setDocument(makeDocumentPath(oid, cid), timestampSetData(data));
  }

  /**
   * Updates a Call in an Org
   * NOTE: for nested objects (currently just context), we use to add/change properties, not blow them out
   * @param oid Organization Id.
   * @param cid Call Id.
   * @param data Call data.
   */
  static update(oid: number, cid: string, data: Partial<CallData>): Promise<void> {
    if (data.context) {
      for (const key of Object.keys(data.context)) {
        (data as any)["context." + key] = data.context[key as keyof CallContext];
      }

      delete data.context;
    }

    return this.updateDocument(makeDocumentPath(oid, cid), timestampUpdateData(data));
  }

  /**
   * Updates a Call in an Org
   * @param oid Organization Id.
   * @param cid Call Id.
   * @param setData Call data.
   * @param updateData Call data.
   */
  static setOrUpdate(
    oid: number,
    cid: string,
    setData: CallData,
    updateData: Partial<CallData>
  ): Promise<void> {
    return this.setOrUpdateDocument(
      makeDocumentPath(oid, cid),
      timestampSetData(setData),
      timestampUpdateData(updateData)
    );
  }

  /**
   * Constructor
   * @param state Initial Call state.
   */
  constructor(state: CallState = makeCallState("", {})) {
    super(state);
  }

  /**
   * Disposes.
   */
  dispose() {
    super.dispose();
  }

  /**
   * Starts listening for updates to Call.
   * @param oid Organization Id.
   * @param cid Call Id.
   */
  start(oid: number, cid: string): void {
    this.startListening(makeDocumentPath(oid, cid), makeCallState);
  }

  /**
   * Stops listening.
   */
  stop(): void {
    this.stopListening();
  }
}
