import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  HostListener,
  ElementRef
} from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSidenavContent } from "@angular/material/sidenav";
import { MatTooltip } from "@angular/material/tooltip";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { Subject, Observable, Subscription } from "rxjs";
import {
  filter,
  map,
  mergeMap,
  take,
  distinctUntilChanged,
  withLatestFrom,
  pluck,
  pairwise
} from "rxjs/operators";

import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { Config } from "../../../common/config";
import { UserService } from "../../../common/services/api/resources/user/user.service";
import { FreeTrialService } from "../shared/components/freeTrial/free-trial.service";
import { UserCustomizationService } from "../../../common/services/api/resources/userCustomization/user-customization.service";

import { views } from "../../app/phone/views";
import { isAdminRole, isAtLeastAccountAdminRole, Role } from "../../../common/services/api/role";
type currentCallType = "newCall" | "conferenceCall" | "none";

import { E2eLocators } from "@onsip/e2e/configs/locators";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { InitPhoneService } from "./init-phone.service";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { VoicemailService } from "@onsip/common/services/api/resources/voicemail/voicemail.service";

@Component({
  selector: "onsip-phone",
  templateUrl: "./phone.component.html",
  styleUrls: ["./phone.scss"]
})
export class PhoneComponent implements OnInit, OnDestroy, AfterViewInit {
  // FIXME: newCallComponent - make this go away
  @ViewChild("overflowMenuTrigger") overflowMenu!: MatMenuTrigger;
  @ViewChild("mainContent") mainContent!: MatSidenavContent;
  @ViewChild("tooltip") freeTrialToolTip!: MatTooltip | undefined;
  E2eLocators = E2eLocators;

  hasParentUserId = false;
  hasAdvancedQueues = false;
  showQueueDashboard = false;
  showOrgAdminTabs = false;
  showAccountAdminTabs = false;
  showSaysoAnalytics = false;
  notSpoofingAgents = false;
  isAppStore = Config.isAppStore;

  views = views;
  menuIcon = "expand_more";
  startingCall = false;
  currentCall: currentCallType = "none";
  msgCount = 0;

  sidenavOpened = true;
  isSmall!: Observable<boolean>;
  newCallClosed = new Subject<void>();
  currentView = views.HOME;
  isOnSaysoNavTab = false;
  isOnAccountNavTab = false;
  isOnAdministratorsNavTab = false;
  isDesktop = Config.IS_DESKTOP;
  debug = /localhost/.test(location.host);

  private unsubscriber = new Subscription();

  constructor(
    public initPhoneService: InitPhoneService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private freeTrialService: FreeTrialService,
    private userCustomization: UserCustomizationService,
    private apiSessionService: ApiSessionService,
    private queueService: AdvQueueService,
    private voicemailService: VoicemailService,
    private cdRef: ChangeDetectorRef,
    private elRef: ElementRef
  ) {}

  // NB keydown happens just before keypress
  @HostListener("window:keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  @HostListener("window:keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  ngOnInit(): void {
    this.initMenu();
    this.initBreakpointObserver();
    this.initTitle();
    this.initNewCallCloseObserver();
    this.getShowSaysoAnalyticsField();
    this.checkActiveView();
    this.getSpoofAgentState();
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.showOrgAdminTabs = isAdminRole(user.roles);
        this.showAccountAdminTabs = isAtLeastAccountAdminRole(user.roles);

        this.showQueueDashboard = user.queueEvent;
      })
    );

    this.unsubscriber.add(
      this.apiSessionService.state.subscribe(state => {
        this.hasParentUserId = !!state.parentUserId;
      })
    );
  }

  ngAfterViewInit(): void {
    this.initFreeTrialTooltip();
    this.initScrolling();
    this.unsubscriber.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe(() => this.checkActiveView())
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  startNewCall(): void {
    this.analyticsService.sendNavigationEvent("Clicked Start New Call", { view: this.currentView });
  }

  handleStartCallComponent(callType: currentCallType): void {
    this.currentCall = callType;
    this.startingCall = !this.startingCall;
    if (this.startingCall && callType === "newCall") {
      this.startNewCall();
    }
  }

  private initBreakpointObserver(): void {
    this.isSmall = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      map(breakpointState => breakpointState.matches),
      distinctUntilChanged()
    );
    this.unsubscriber.add(
      this.isSmall.subscribe(isSmall => {
        if (!isSmall) {
          this.sidenavOpened = true;
          this.overflowMenu && this.overflowMenu.closeMenu(); // menu should never be open when its trigger is hidden
        }
      })
    );
  }

  private initNewCallCloseObserver(): void {
    this.unsubscriber.add(
      this.newCallClosed.pipe(withLatestFrom(this.isSmall)).subscribe(([, isSmall]) => {
        this.startingCall = false;
        if (isSmall) this.sidenavOpened = false;
      })
    );
  }

  private initMenu(): void {
    this.unsubscriber.add(
      this.queueService.getSmartQueueObs().subscribe(queues => {
        this.hasAdvancedQueues = queues.length > 0;
      })
    );

    this.unsubscriber.add(
      this.voicemailService.selfUserVoicemailbox
        .pipe(
          map(voicemailbox => {
            const voicemails = voicemailbox?.voicemail.inbox?.voicemail;
            return voicemails
              ? Object.values(voicemails).filter(msg => msg.folder === "NEW").length
              : 0;
          })
        )
        .subscribe(count => {
          this.msgCount = Math.max(0, Math.min(count, 99)); // badge doesn't support 3 digit numbers
          this.platformUpdateVoicemailCount(this.msgCount);
          this.cdRef.markForCheck();
        })
    );
  }

  /** Scroll to top of #mainContent sidenav-content on route changes */
  private initScrolling(): void {
    this.unsubscriber.add(
      this.router.events
        .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
        .subscribe(() => {
          this.mainContent.scrollTo({ top: 0 });
        })
    );
  }

  private initTitle(): void {
    document.title = "OnSIP - My Dashboard";

    this.unsubscriber.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter(route => route.outlet === "primary"),
          mergeMap(route => route.data),
          withLatestFrom(this.isSmall)
        )
        .subscribe(([event, isSmall]) => {
          const title: string | undefined = event.title;
          if (title && title.length) {
            // some pages set their own titles
            document.title = "OnSIP - " + title;
          }
          if (isSmall) this.sidenavOpened = false;
        })
    );
  }

  private initFreeTrialTooltip(): void {
    this.unsubscriber.add(
      this.freeTrialService.state
        .pipe(
          pluck("showWelcomeModal"),
          distinctUntilChanged(),
          pairwise(),
          filter(([prev, current]) => !!prev && !current), // take first time when modal state change from true to false
          take(1)
        )
        .subscribe(() => {
          if (this.freeTrialToolTip) {
            this.freeTrialToolTip.disabled = false;
            this.freeTrialToolTip.show();
            // hide tooltip after 5 secs
            setTimeout(() => {
              if (this.freeTrialToolTip) {
                this.freeTrialToolTip.hide();
                // we need to disable tooltip after initial popup so it does not popup on button hover
                this.freeTrialToolTip.disabled = true;
              }
            }, 5000);
          }
        })
    );
  }

  private getShowSaysoAnalyticsField(): void {
    this.unsubscriber.add(
      this.userCustomization.selfUser.subscribe(userCustomState => {
        this.showSaysoAnalytics = userCustomState.userInfo?.sayso?.showAnalytics || false;
      })
    );
  }

  private getSpoofAgentState(): void {
    this.unsubscriber.add(
      this.apiSessionService.state.subscribe(state => {
        this.notSpoofingAgents = !(!!state.parentUserId && state.loggedInRole !== Role.SuperUser);
      })
    );
  }

  private platformUpdateVoicemailCount(msgCount: number): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("update-vm-count", msgCount);
    }
  }

  private dontBackspace(e: any): void {
    // prevent backspace from leaving page
    if (8 !== e.which) {
      return;
    }

    if (!this.textFieldFocused()) {
      e.preventDefault();
    }
  }

  private textFieldFocused(): boolean {
    const focused: any = this.elRef.nativeElement.ownerDocument.activeElement;
    return /text|textarea|password|number/i.test(focused.type);
  }

  private windowsHotkeys(event: any): void {
    if (event.ctrlKey === true && Config.IS_DESKTOP) {
      // r key pressed
      if (event.keyCode === 82) {
        window.electron.sendMessage("relaunch-function");
      } else if (event.keyCode === 81) {
        window.electron.sendMessage("app-quit");
      }
    }
  }

  private checkActiveView(): void {
    const oldView = this.currentView;
    this.currentView = this.router.url.replace(/[?].*/, "");
    this.isOnSaysoNavTab =
      this.currentView === views.MANAGER_DASHBOARD ||
      this.currentView === views.ORG_CALL_HISTORY ||
      this.currentView === views.TEAM_PAGE ||
      this.currentView === views.SAYSO_DASHBOARD ||
      this.currentView === views.SAYSO_INSTALLATION ||
      this.currentView === views.SAYSO_SETTINGS ||
      this.currentView === views.SAYSO_ADD ||
      this.currentView === views.SAYSO_VIEW ||
      this.currentView === views.SAYSO_EDIT ||
      this.currentView === views.SAYSO_DUPLICATE;

    this.isOnAccountNavTab = /\/account\//.test(this.currentView); // catchall
    this.isOnAdministratorsNavTab = /\/administrators\//.test(this.currentView); // catchall

    this.analyticsService.sendNavigationEvent(this.currentView, { oldView });
  }
}
