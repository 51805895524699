import { Injectable } from "@angular/core";
import { ApiResourceService } from "../api-resource.service";
import {
  ApiUserSummaryContact,
  UserSummaryContact,
  apiUserSummaryContactToUserSummaryContact as clean,
  apiUserToUserSummaryContactPartial as cleanUser,
  apiUserAddressToUserSummaryContactPartial as cleanUserAddress,
  apiUserCustomizationToUserSummaryContactPartial as cleanUserCustomization
} from "./user-summary-contact";
import { ApiUser } from "../user/user";
import { APIUserAddress } from "../userAddress/user-address";
import { ApiUserCustomization } from "../userCustomization/user-customization";
import { ApiSessionService } from "../../api-session.service";
import { ApiAction, ApiBrowseAction } from "../../api-actions";
import { ApiStateStoreService } from "../../api-state-store.service";
import { sessionId } from "../../apiParams/session-id";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { ParameterValue } from "../../util/api-action-description";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
export type { UserSummaryContact };

const debug = false;

@Injectable({ providedIn: "root" })
export class UserSummaryContactService extends ApiResourceService<UserSummaryContact> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserSummaryContact", "userId");
    debug && this.state.subscribe(state => console.warn("UserSummaryContactService", state));
  }

  userSummaryContactBrowse(
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<UserSummaryContact> {
    debug && console.warn("UserSummaryContactService._userSummaryContactBrowse");
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.UserSummaryContactBrowse,
        Status: "enabled",
        Limit: 25000, // was in the old code - i dont know if this matters, seems weird
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.UserSummaryContactBrowse);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.UserSummaryContactBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiUserSummaryContact>>(
              response,
              action,
              "UserSummary",
              "UserSummaries"
            )
              .map(clean)
              // making an assumption here that the app doesn't need "disabled" users
              .filter(user => user.status !== "disabled"),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.UserAdd:
      case ApiAction.UserEditContact:
      case ApiAction.UserEditRoleSubmit:
      case ApiAction.UserEditTeamPageVisibility:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiUser>(response, action, "User")].map(cleanUser),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.UserDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deleted = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
          param => param.Name === "UserId"
        )?.Value;
        deleted && this.store.mergeStateUpdate(this.resourceName, { [deleted]: undefined }, action);
        break;

      case ApiAction.UserCustomizationRead:
      case ApiAction.UserCustomizationEdit:
      case ApiAction.UserCustomizationAvatarEdit:
      case ApiAction.UserCustomizationOnboardingEdit:
      case ApiAction.UserCustomizationPersonalPageInfoEdit:
      case ApiAction.UserCustomizationSaysoEdit:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiUserCustomization>(response, action, "UserCustomization")].map(
              cleanUserCustomization
            ),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.UserAddressBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<APIUserAddress>>(
              response,
              action,
              "UserAddress",
              "UserAddresses"
            ).map(cleanUserAddress),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
