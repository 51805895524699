import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { MaterialModule } from "../../../../modules/material/material.module";

import { ContactSupportComponent } from "./contactSupport/contact-support.component";
import { EntryPageComponent } from "./entry-page.component";
import { OnsipFooterComponent } from "./footer/onsip-footer.component";
import { SystemStatusComponent } from "./systemStatus/system-status.component";

@NgModule({
  imports: [BrowserModule, CommonModule, RouterModule, MaterialModule, TranslateModule],
  declarations: [
    EntryPageComponent,
    ContactSupportComponent,
    OnsipFooterComponent,
    SystemStatusComponent
  ],
  exports: [EntryPageComponent]
})
export class EntryPageModule {}
