import { ErrorHandler, Injectable } from "@angular/core";

import {
  sentryCaptureException,
  sentryCaptureMessage,
  sentryWithScope,
  sentrySeverity
} from "../../web/platform-dependent/sentry";

@Injectable()
export class SentryErrorHandler extends ErrorHandler {
  /** Send custom / user generated message to Sentry.
   * Note: Sentry.io truncates after 250 UTF-8 chars
   */
  static sendMessage(
    message: string,
    level: "Info" | "Warning" | "Error" = "Info",
    extra: Record<string, unknown> = {}
  ): void {
    if (!message || message.length === 0) {
      return;
    }
    if (message.length > 250) {
      message = message.slice(0, 250);
    }

    sentryWithScope(scope => {
      scope.setExtras(extra);
      sentryCaptureMessage(message, sentrySeverity[level]);
    });
  }

  constructor() {
    super();
  }

  handleError(error: any) {
    sentryCaptureException(error.originalError || error);
    super.handleError(error);
  }
}
