import { UserAgentEvent, UserAgentEventFactory } from "./user-agent-event";

/**
 * Emitted when the UserAgent is registered.
 * Occurs only if the user agent was previously unregistered.
 */
export class RegisteredUserAgentEvent {
  /**
   * Factory function to make event.
   * @param aor AOR of UserAgent.
   */
  static make(aor: string): UserAgentEvent {
    return UserAgentEventFactory.make(RegisteredUserAgentEvent.id, aor);
  }
  static get id(): string {
    return "[UserAgent] registered";
  }
}
