<mat-card>
  <div class="mat-headline-5 search-title">Search for</div>
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <div class="form-fields">
      <mat-form-field class="search-type" appearance="outline">
        <mat-select formControlName="searchType">
          @for (type of searchTypeOptions; track type) {
          <mat-option [value]="type.value">
            {{ type.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      @if (formGroup.controls.searchType.value === 'user') {
      <mat-form-field class="admin-privileges" appearance="outline">
        <mat-select formControlName="withAdminPrivileges">
          @for (option of adminPrivilegesOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      } @if (formGroup.controls.searchType.value === 'user') {
      <mat-form-field class="search-param" appearance="outline">
        <mat-select formControlName="userSearchParam">
          @for (option of userSearchParamOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      } @if (formGroup.controls.searchType.value === 'organization') {
      <mat-form-field class="search-param" appearance="outline">
        <mat-select formControlName="orgSearchParam">
          @for (option of orgSearchParamOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      } @if (formGroup.controls.searchType.value === 'account') {
      <mat-form-field class="search-param" appearance="outline">
        <mat-select formControlName="accountSearchParam">
          @for (option of accountSearchParamOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      }
      <mat-form-field class="search-compare" appearance="outline">
        <mat-select formControlName="searchCompare">
          @for (option of searchCompareOptions; track option) {
          <mat-option [value]="option.value">
            {{ option.text }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="search-value" appearance="outline">
        <input matInput formControlName="searchValue" />
        @if (formGroup.controls.searchValue.hasError('required')) {
        <mat-error>Search field is required.</mat-error>
        } @if (formGroup.controls.searchValue.hasError('pattern')) {
        <mat-error>Please enter only numbers for this search type.</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="action-buttons">
      <button mat-button color="primary" (click)="onCancel()" type="button">Cancel</button>
      <button mat-flat-button color="primary" class="search-button">Search</button>
    </div>
  </form>
</mat-card>
