import { Injectable } from "@angular/core";

import { StateEmitter } from "../../../../common/libraries/emitter/state-emitter";
import { CallState } from "../../../../common/libraries/sip/call-state";

import { CallControllerService } from "../../../../common/services/call-controller.service";

export interface CallMessageState {
  queueVideo: any;
}

// TODO: this can probably just be event based instead of state based
@Injectable({ providedIn: "root" })
export class CallMessageService extends StateEmitter<CallMessageState> {
  private queueVideo: any = {}; // maps id: string => queueVideo: any

  constructor(private callControllerService: CallControllerService) {
    super({
      queueVideo: {}
    });
  }

  findQueueVideo(id: string): any | undefined {
    return this.queueVideo[id];
  }

  onQueueVideo(call: CallState, message: any): void {
    this.queueVideo[call.uuid] = this.queueVideo[call.uuid] || {};

    this.queueVideo[call.uuid].queueVideoStream = message.videoStream;

    if (message.onHold && !this.queueVideo[call.uuid].isQueueVideo) {
      console.debug(call, "activated queue video hold", {
        data: this.queueVideo[call.uuid].queueVideoStream
      });
      this.queueVideo[call.uuid].isQueueVideo = true;

      this.callControllerService.toggleRemoteAudio(call.uuid, false);
    } else if (this.queueVideo[call.uuid].isQueueVideo) {
      console.debug(call, "deactivated queue video hold");
      this.queueVideo[call.uuid].isQueueVideo = false;
      this.callControllerService.toggleRemoteAudio(call.uuid, true);
    }
    this.publishState();
  }
}
