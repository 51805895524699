import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { TranslateModule } from "@ngx-translate/core";
import { AudioRecorderModule } from "../audioRecorder/audio-recorder.module";
import { ModalModule } from "../modal/modal.module";
import { TogglableRecorderComponent } from "./togglable-recorder.component";

@NgModule({
  imports: [
    CommonModule,
    MatRadioModule,
    MatSelectModule,
    MatButtonModule,
    ModalModule,
    MatDialogModule,
    ReactiveFormsModule,
    AudioRecorderModule,
    FormsModule,
    TranslateModule,
    MatButtonToggleModule,
    MatIconModule
  ],
  declarations: [TogglableRecorderComponent],
  exports: [TogglableRecorderComponent]
})
export class TogglableRecorderModule {}
