export declare interface ApiSdr {
  CallId: string;
  CalleeAccountId: string;
  CalleeAddress: string;
  CalleeContact: string;
  CalleeDisplay: string;
  CalleeOrganizationId: string;
  CalleeOrganizationService: string;
  CalleeUserId: string;
  CallerAccountId: string;
  CallerAddress: string;
  CallerContact: string;
  CallerDisplay: string;
  CallerOrganizationId: string;
  CallerOrganizationService: string;
  CallerUserId: string;
  ConfirmedDuration: string;
  ConfirmedTime: string;
  Created: string;
  Disposition: string;
  Duration: string;
  EndTime: string;
  Ouid: string;
  SdrId: string;
  StartTime: string;
}

export declare interface Sdr {
  callId: string;
  calleeAccountId: string;
  calleeAddress: string;
  calleeContact: string;
  calleeDisplay: string;
  calleeOrganizationId: string;
  calleeOrganizationService: string;
  calleeUserId: string;
  callerAccountId: string;
  callerAddress: string;
  callerContact: string;
  callerDisplay: string;
  callerOrganizationId: string;
  callerOrganizationService: string;
  callerUserId: string;
  confirmedDuration: string;
  confirmedTime: string;
  created: string;
  disposition: string;
  duration: string;
  endTime: string;
  ouid: string;
  sdrId: string;
  startTime: string;
}

export function apiSdrToSdr(apiSdr: ApiSdr): Sdr {
  return {
    callId: apiSdr.CallId,
    calleeAccountId: apiSdr.CalleeAccountId,
    calleeAddress: apiSdr.CalleeAddress,
    calleeContact: apiSdr.CalleeContact,
    calleeDisplay: apiSdr.CalleeDisplay,
    calleeOrganizationId: apiSdr.CalleeOrganizationId,
    calleeOrganizationService: apiSdr.CalleeOrganizationService,
    calleeUserId: apiSdr.CalleeUserId,
    callerAccountId: apiSdr.CallerAccountId,
    callerAddress: apiSdr.CallerAddress,
    callerContact: apiSdr.CallerContact,
    callerDisplay: apiSdr.CallerDisplay,
    callerOrganizationId: apiSdr.CallerOrganizationId,
    callerOrganizationService: apiSdr.CallerOrganizationService,
    callerUserId: apiSdr.CallerUserId,
    confirmedDuration: apiSdr.ConfirmedDuration,
    confirmedTime: apiSdr.ConfirmedTime,
    created: apiSdr.Created,
    disposition: apiSdr.Disposition,
    duration: apiSdr.Duration,
    endTime: apiSdr.EndTime,
    ouid: apiSdr.Ouid,
    sdrId: apiSdr.SdrId,
    startTime: apiSdr.StartTime
  };
}
