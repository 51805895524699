import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";

export enum E911AddressType {
  "None" = "",
  "Apartment" = "Apartment",
  "Basement" = "Basement",
  "Building" = "Building",
  "Department" = "Department",
  "Floor" = "Floor",
  "Front" = "Front",
  "Hanger" = "Hanger",
  "Key" = "Key",
  "Lobby" = "Lobby",
  "Lot" = "Lot",
  "Lower" = "Lower",
  "Office" = "Office",
  "Penthouse" = "Penthouse",
  "Pier" = "Pier",
  "Rear" = "Rear",
  "Room" = "Room",
  "Side" = "Side",
  "Slip" = "Slip",
  "Space" = "Space",
  "Stop" = "Stop",
  "Suite" = "Suite",
  "Trailer" = "Trailer",
  "Unit" = "Unit",
  "Upper" = "Upper"
}

export type E911LocationType = "Shared" | "Private";

export declare interface APIE911Location {
  AddressType: E911AddressType;
  AddressTypeNumber: string;
  AssignedAddressCount: string;
  City: string;
  Country: string;
  CszOnly: OnsipAPIBoolean;
  E911LocationId: string;
  LocationName: string;
  LocationType: E911LocationType;
  NotificationEmail?: string;
  OrganizationId: string;
  State: string;
  StreetName: string;
  StreetNumber: string;
  UserId: string;
  Zipcode: string;
}

export declare interface E911Location {
  addressType: E911AddressType;
  /** number associated with address type eg. 123 in Room 123 */
  addressTypeNumber: string;
  /** number of users assigned to the e911 location */
  assignedAddressCount: number;
  city: string;
  country: string;
  /** optional param to only validate city state and zipcode of address.
   * Should not be used and may even be removed in the near future */
  cszOnly: boolean;
  e911LocationId: string;
  fullAddress: string;
  /** display name of e911 location */
  locationName: string;
  /** Shared means anyone in the org can be assigned to that location.
   * Private means only assigned user and admins can see that location
   * and only the assigned user can use that location
   * UserId is required if setting location to private
   */
  locationType: E911LocationType;
  notificationEmail?: string;
  organizationId: string;
  state: string;
  streetName: string;
  streetNumber: string;
  userId: string;
  zipcode: string;
}

export function apiE911LocationToE911Location(apiE911: APIE911Location): E911Location {
  return {
    addressType: apiE911.AddressType,
    addressTypeNumber: apiE911.AddressTypeNumber,
    assignedAddressCount: parseInt(apiE911.AssignedAddressCount),
    city: apiE911.City,
    country: apiE911.Country,
    cszOnly: onsipApiBooleanToBoolean(apiE911.CszOnly),
    e911LocationId: apiE911.E911LocationId,
    fullAddress: getE911FullAddress(apiE911),
    locationName: apiE911.LocationName,
    locationType: apiE911.LocationType,
    notificationEmail: apiE911.NotificationEmail,
    organizationId: apiE911.OrganizationId,
    state: apiE911.State,
    streetName: apiE911.StreetName,
    streetNumber: apiE911.StreetNumber,
    userId: apiE911.UserId,
    zipcode: apiE911.Zipcode
  };
}

function getE911FullAddress(apiE911: APIE911Location): string {
  const addressLine1 = [
    apiE911.StreetNumber + " " + apiE911.StreetName,
    apiE911.AddressType + " " + apiE911.AddressTypeNumber
  ]
    .filter(loc => loc.length > 1) // filter out empty address types
    .join(", ");
  const addressLine2 = apiE911.City + ", " + apiE911.State + " " + apiE911.Zipcode;
  const addressLine3 = apiE911.Country;
  return addressLine1 + ", " + addressLine2 + " " + addressLine3;
}

export interface E911LocationParams {
  AddressType?: E911AddressType;
  /** number associated with address type eg. 123 in Room 123 */
  AddressTypeNumber?: string;
  City: string;
  Country: string;
  /** optional param to only validate city state and zipcode of address.
   * Should not be used and may even be removed in the near future */
  CszOnly?: OnsipAPIBoolean;
  /** display name of e911 location */
  LocationName: string;
  /** Shared means anyone in the org can be assigned to that location.
   * Private means only assigned user and admins can see that location
   * and only the assigned user can use that location
   * UserId is required if setting location to private
   */
  LocationType: E911LocationType;
  NotificationEmail?: string;
  State: string;
  StreetName: string;
  StreetNumber: string;
  UserId?: string;
  Zipcode: string;
  /**
   * NOTE: This is used only for non admin user trying to set a private location
   */
  OrganizationId?: string;
}

/** NOTE: cannot edit Location type of e911 location. We will need to delete and recreate the location with the new location type */
export interface EditE911LocationParams
  extends Omit<E911LocationParams, "LocationType" | "UserId"> {
  E911LocationId: string;
}
