import { Injectable } from "@angular/core";

import { ApiResourceService } from "../api-resource.service";
import { ApiSessionService } from "../../api-session.service";
import {
  ApiNoAuthOrganizationSummary,
  NoAuthOrganizationSummary,
  apiNoAuthOrganizationSummaryToNoAuthOrganizationSummary as clean
} from "./no-auth-org-summary";
import { ApiReadAction } from "../../api-actions";
import { ApiStateStoreService } from "../../api-state-store.service";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { domain } from "../../apiParams/domain";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { userId } from "../../apiParams/user-id";
import { distinctUntilChanged } from "rxjs";

const debug = false;

@Injectable({ providedIn: "root" })
export class NoAuthOrganizationSummaryService extends ApiResourceService<NoAuthOrganizationSummary> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "NoAuthOrganizationSummary", "organizationId");
    debug && this.state.subscribe(state => console.warn("NoAuthOrganizationSummaryService", state));
    // clear store and reset all if there is changed in users
    this.store.state
      .pipe(userId())
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.dispose();
      });
  }

  noAuthOrganizationSummaryRead(givenDomain?: string): ApiPromiseState<NoAuthOrganizationSummary> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.NoAuthOrganizationSummaryRead,
        Domain: givenDomain ? givenDomain : this.store.state.pipe(domain())
      }
    });
    return this.promiseState.toPromise(ApiReadAction.NoAuthOrganizationSummaryRead);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiReadAction.NoAuthOrganizationSummaryRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              clean(
                extractData<ApiNoAuthOrganizationSummary>(
                  response,
                  action,
                  "NoAuthOrganizationSummary"
                )
              )
            ],
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
