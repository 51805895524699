import { VolumeEvent, VolumeEventFactory } from "./volume-event";

/**
 * Emitted when a call's volume is changed
 */
export class CallVolumeEvent {
  static make(volume: number, uuid: string): VolumeEvent {
    return VolumeEventFactory.make(CallVolumeEvent.id, volume, uuid);
  }
  static get id(): string {
    return "[Volume] call";
  }
}
