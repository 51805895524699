import { CallEvent, EventId } from "./call-event";

/** Emitted once when Call activated. */
export class NewCallEvent extends CallEvent {
  id = EventId.NewCallEventId;

  /**
   * Factory for NewCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isNewCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for NewCallEvent.
 * @param event Event to check.
 */
export function isNewCallEvent(event: any): event is NewCallEvent {
  return event instanceof NewCallEvent;
}
