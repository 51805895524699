import { PlatformFirebase } from "../platform-firebase";
import { firebase } from "../platform-firebase-types";

// helper function to defer firestore initialization til first use
// it's the wanting to set the setting one time which is the issue
// otherwise we could use firebase.firestore() directly in our code
class FirestoreSettings {
  static _db: firebase.firestore.Firestore;
}
export function db(): firebase.firestore.Firestore {
  if (FirestoreSettings._db === undefined) {
    FirestoreSettings._db = PlatformFirebase.firebase.firestore();
    FirestoreSettings._db.settings({});
  }
  return FirestoreSettings._db;
}
