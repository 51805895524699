import {
  ApiTeamPageInfo,
  TeamPageInfo,
  apiTeamPageInfoToTeamPageInfo
} from "../../apiResponse/team-page-info";
import { OnsipAPIBoolean, onsipApiBooleanToBoolean } from "../../apiResponse/xml-json";

const OLD_ONSIP_BLUE = "#165b8c";

export declare interface ApiNoAuthOrganizationSummary {
  OrganizationId: string;
  Domain: string;
  TeamPageDomain: string;
  TeamPage: OnsipAPIBoolean;
  ContactOrganization: string;
  OrganizationAvatarUrl: string;
  TeamPageInfo?: ApiTeamPageInfo;
}

export declare interface NoAuthOrganizationSummary {
  organizationId: string;
  domain: string;
  teamPageDomain: string;
  teamPage: boolean;
  contactOrganization: string;
  organizationAvatarUrl: string;
  teamPageInfo: TeamPageInfo;
}

export function apiNoAuthOrganizationSummaryToNoAuthOrganizationSummary(
  apiNoAuthOrganizationSummary: ApiNoAuthOrganizationSummary
): NoAuthOrganizationSummary {
  const cleaned = {
    organizationId: apiNoAuthOrganizationSummary.OrganizationId,
    domain: apiNoAuthOrganizationSummary.Domain,
    teamPageDomain: apiNoAuthOrganizationSummary.TeamPageDomain,
    teamPage: onsipApiBooleanToBoolean(apiNoAuthOrganizationSummary.TeamPage),
    contactOrganization: apiNoAuthOrganizationSummary.ContactOrganization,
    organizationAvatarUrl: apiNoAuthOrganizationSummary.OrganizationAvatarUrl,
    teamPageInfo: apiTeamPageInfoToTeamPageInfo(apiNoAuthOrganizationSummary.TeamPageInfo)
  };
  if (!cleaned.teamPageInfo.accentColor) {
    cleaned.teamPageInfo.accentColor = OLD_ONSIP_BLUE;
  }
  return cleaned;
}
