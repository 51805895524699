import { OnsipAPIBoolean } from "../../apiResponse/xml-json";

type Priority = "urgent" | "high" | "normal" | "low";

type Status = "new" | "open" | "pending" | "hold" | "solved" | "closed";

interface FieldItem {
  id: string;
  value: string;
}

export interface ApiZendeskGetTickets {
  allow_attachments: string;
  allow_channelback?: OnsipAPIBoolean;
  assignee_id: string;
  brand_id: string;
  collaborator_ids: {
    collaborator_ids_item: string;
  };
  created_at: string;
  custom_fields: {
    custom_fields_item: Array<FieldItem>;
  };
  description: string;
  due_at?: string;
  email_cc_ids?: {
    email_cc_ids_item: string;
  };
  external_id?: string;
  fields: {
    fields_item: Array<FieldItem>;
  };
  follower_ids: {
    follower_ids_item: string;
  };
  followup_ids?: {
    followup_ids_item: string;
  };
  forum_topic_id?: string;
  group_id: string;
  has_incidents?: OnsipAPIBoolean;
  id: string;
  is_public: string;
  organization_id: string;
  priority: Priority;
  problem_id?: string;
  raw_subject: string;
  recipient?: string;
  requester_id: string;
  result_type: string;
  satisfaction_rating: {
    score: "unoffered" | "offered";
  };
  sharing_agreement_ids?: {
    sharing_agreement_ids_item: string;
  };
  status: Status;
  subject: string;
  submitter_id: string;
  tags: {
    tags_item: Array<string>;
  };
  ticket_form_id: string;
  type: string;
  updated_at: string;
  url: string;
}

// to make life easier, we will only pick out the important parameters that we may use in the frontend
export interface ZendeskGetTickets {
  createdAt: string;
  description: string;
  id: string;
  priority: Priority;
  isPublic: boolean;
  rawSubject: string;
  status: Status;
  subject: string;
  updatedAt: string;
  url: string;
}

export function apiZendeskGetTicketsToZendeskGetTickets(
  api: ApiZendeskGetTickets
): ZendeskGetTickets {
  return {
    createdAt: api.created_at,
    description: api.description,
    id: api.id,
    priority: api.priority,
    isPublic: api.is_public === "1",
    rawSubject: api.raw_subject,
    status: api.status,
    subject: api.subject,
    updatedAt: api.updated_at,
    url: api.url
  };
}
