import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { filter, map, withLatestFrom } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiUser, User, apiUserToUser as clean } from "./user";
export { User };
import {
  ApiNoAuthUserSummary,
  apiNoAuthUserSummaryToUserPartial as cleanNoAuthUserSummary
} from "../../apiResponse/no-auth-user-summary";
import { ApiSessionService } from "../../api-session.service";
import {
  ApiAddAction,
  ApiReadAction,
  ApiDeleteAction,
  ApiEditAction,
  ApiBrowseAction,
  ApiAction
} from "../../api-actions";

import { userId } from "../../apiParams/user-id";
import { sessionId } from "../../apiParams/session-id";
import { organizationId } from "../../apiParams/organization-id";
import { domain } from "../../apiParams/domain";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { ParameterValue } from "../../util/api-action-description";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class UserService extends ApiResourceService<User> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "User", "userId");
    debug && this.state.subscribe(state => console.warn("UserService", state));
  }

  /** public getter to get a filtered observable with just the self user */
  get selfUser(): Observable<User> {
    return this.state.pipe(
      withLatestFrom(this.store.state.pipe(userId())),
      map(([substate, selfUserId]) => substate.state[selfUserId]),
      filter(<T>(user: T): user is NonNullable<T> => !!user)
    );
  }

  userAdd(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.UserAdd,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAddAction.UserAdd);
  }

  userDelete(
    UserId: string,
    extraParameters: Record<string, ParameterValue> = {}
  ): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiDeleteAction.UserDelete,
        SessionId: this.store.state.pipe(sessionId()),
        UserId,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiDeleteAction.UserDelete);
  }

  userEditContact(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditContact,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditContact);
  }

  userEditPSTNTrunking(
    Enabled: boolean,
    UserId: string,
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditPSTNTrunking,
        Enabled,
        SessionId: this.store.state.pipe(sessionId()),
        UserId,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditPSTNTrunking);
  }

  userEditQueueEvent(
    Enabled: boolean,
    UserId: string,
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditQueueEvent,
        SessionId: this.store.state.pipe(sessionId()),
        UserId,
        Enabled,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditQueueEvent);
  }

  userEditTeamPageVisibility(
    UserId: string,
    Enabled: boolean,
    extraParameters?: Record<string, ParameterValue>
  ): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditTeamPageVisibility,
        Enabled,
        SessionId: this.store.state.pipe(sessionId()),
        UserId,
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditTeamPageVisibility);
  }

  userEditRoleSubmit(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditRoleSubmit,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditRoleSubmit);
  }

  userEditNoOutgoingPstn(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditNoOutgoingPstn,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditNoOutgoingPstn);
  }

  /** flag to enable e911 for user. Org E911 provisioning needs to be enabled first before this flag works */
  userEditE911Provisioning(params: { Enabled: boolean; UserId: string }): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditE911Provisioning,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      },
      nonblocking: true
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditE911Provisioning);
  }

  userEditExposePii(params: { Enabled: boolean; UserId?: string }): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditExposePii,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditExposePii);
  }

  userRead(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.UserRead,
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiReadAction.UserRead);
  }

  // can only be used by admins
  userBrowse(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.UserBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.UserBrowse);
  }

  /** acknowledges terms and conditions of using hosted E911.
   * this api simply turns on the user's ackHostedE911 param by setting it to the time when the api was called
   * api response is empty so we will need to call userRead afterwards
   */
  userAckHostedE911(): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserAckHostedE911,
        SessionId: this.store.state.pipe(sessionId())
      }
    });
    return this.promiseState.toPromise(ApiAction.UserAckHostedE911);
  }

  userAckHostedTerms(): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserAckHostedTerms,
        SessionId: this.store.state.pipe(sessionId())
      }
    });
    return this.promiseState.toPromise(ApiAction.UserAckHostedTerms);
  }

  /** flag for enabling or disabling a user */
  userEditStatus(params: {
    UserId: string;
    Status: "enabled" | "disabled";
  }): ApiPromiseState<User> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditStatus,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditStatus);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.UserBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiUser>>(response, action, "User", "Users").map(clean),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAddAction.UserAdd:
      case ApiEditAction.UserEditContact:
      case ApiEditAction.UserEditPSTNTrunking:
      case ApiEditAction.UserEditQueueEvent:
      case ApiEditAction.UserEditTeamPageVisibility:
      case ApiEditAction.UserEditRoleSubmit:
      case ApiEditAction.UserEditNoOutgoingPstn:
      case ApiEditAction.UserEditE911Provisioning:
      case ApiReadAction.UserRead:
      case ApiEditAction.UserEditStatus:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord([clean(extractData<ApiUser>(response, action, "User"))], this.indexKeyName),
          action
        );
        break;

      case ApiDeleteAction.UserDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deleted = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
          param => param.Name === "UserId"
        )?.Value;
        deleted && this.store.mergeStateUpdate(this.resourceName, { [deleted]: undefined }, action);
        break;
      case ApiAction.NoAuthUserSummaryBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiNoAuthUserSummary>>(
              response,
              action,
              "NoAuthUserSummary",
              "NoAuthUserSummaries"
            ).map(cleanNoAuthUserSummary),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.NoAuthUserSummaryRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiNoAuthUserSummary>(response, action, "NoAuthUserSummary")].map(
              cleanNoAuthUserSummary
            ),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
