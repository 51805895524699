export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

export interface DefaultTimestamp {
  seconds: 0;
  nanoseconds: 0;
}

export const defaultTimestamp: DefaultTimestamp = {
  seconds: 0,
  nanoseconds: 0
};

export function isTimestamp(arg: any): arg is Timestamp {
  return typeof arg.seconds === "number" && typeof arg.nanoseconds === "number";
}

export function compareTimestamp(a: Timestamp, b: Timestamp): number {
  if (a.seconds < b.seconds) return -1;
  if (a.seconds > b.seconds) return 1;
  if (a.nanoseconds < b.nanoseconds) return -1;
  if (a.nanoseconds > b.nanoseconds) return 1;
  return 0;
}
