import { AvatarComponent } from "./avatar.component";
import { AvatarFrameComponent } from "./avatarFrame/avatar-frame.component";

import { ImageCropperModule } from "ngx-image-cropper";
import { NgxFileDropModule } from "ngx-file-drop";

import { ModalModule } from "../modal/modal.module";
import { PipesModule } from "../../pipes/pipes.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ImageCropperModule,
    NgxFileDropModule,
    MaterialModule,
    PipesModule,
    TranslateModule
  ],
  declarations: [AvatarFrameComponent, AvatarComponent],
  exports: [AvatarFrameComponent, AvatarComponent]
})
export class AvatarModule {}
