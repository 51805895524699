<h2 class="mat-dialog-title">Configure Queue Email Alert Rules</h2>
<mat-card>
  <form [formGroup]="warningForm" (ngSubmit)="submit()">
    <mat-card-content>
      <div>
        <span>For queue </span>
        <mat-form-field appearance="outline">
          <mat-select formControlName="queueId">
            @for (queue of queues; track queue) {
            <mat-option [value]="queue.advQueueId">
              {{ queue.displayName }}
            </mat-option>
            }
          </mat-select>
          <mat-error>Please select a queue.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <span>send alert on </span>
        <mat-form-field appearance="outline">
          <mat-select formControlName="thresholdType">
            @for (thresholdType of thresholdTypes; track thresholdType) {
            <mat-option [value]="thresholdType">
              {{ thresholdType | underscoresToSpaces }}
            </mat-option>
            }
          </mat-select>
          <mat-error>Please select an alert threshold.</mat-error>
        </mat-form-field>
        <span> {{ thresholdPrefix[warningForm.controls.thresholdType.value] }} </span>
        <mat-form-field appearance="outline" class="queue-warnings-edit__threshold-value">
          <input matInput type="number" min="1" formControlName="thresholdValue" />
          <mat-error>Value is required.</mat-error>
        </mat-form-field>
        <span> {{ thresholdSuffix[warningForm.controls.thresholdType.value] }}</span>
      </div>
      <div>
        <span>Send via email to </span>
        <mat-form-field appearance="outline">
          <input matInput formControlName="alertDestination" />
          <mat-error>Email is required.</mat-error>
        </mat-form-field>
      </div>
      <span>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="mon" labelPosition="before"
            >Mon</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="monStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="monEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="tue" labelPosition="before"
            >Tue</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="tueStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="tueEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="wed" labelPosition="before"
            >Wed</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="wedStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="wedEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="thu" labelPosition="before"
            >Thu</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="thuStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="thuEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="fri" labelPosition="before"
            >Fri</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="friStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="friEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="sat" labelPosition="before"
            >Sat</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="satStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="satEndTime" type="time" />
          </mat-form-field>
        </div>
        <div class="queue-warnings-edit__days-checkboxes">
          <mat-checkbox color="primary" formControlName="sun" labelPosition="before"
            >Sun</mat-checkbox
          >
          <mat-form-field appearance="outline">
            <input matInput formControlName="sunStartTime" type="time" />
          </mat-form-field>
          <span> to </span>
          <mat-form-field appearance="outline">
            <input matInput formControlName="sunEndTime" type="time" />
          </mat-form-field>
        </div>
      </span>
      <div>
        <span>set all to </span>
        <mat-checkbox color="primary" labelPosition="before" formControlName="allhours">
          24 hours
        </mat-checkbox>
      </div>

      <div>
        <span>all times in </span>
        <mat-form-field appearance="outline">
          <mat-select formControlName="timeZone">
            @for (timeZone of timeZones; track timeZone) {
            <mat-option [value]="timeZone.zoneKey">{{ timeZone.zoneName }}</mat-option>
            }
          </mat-select>
          <mat-error>Please select a timezone.</mat-error>
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button type="button" color="warn" (click)="handleDelete()">Delete</button>
      <button mat-flat-button type="submit" color="primary">{{ submitButtonText }}</button>
    </mat-card-actions>
  </form>
</mat-card>
