import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingModalComponent } from "./loading-modal.component";

@NgModule({
  imports: [CommonModule, MaterialModule, TranslateModule],
  declarations: [LoadingModalComponent],
  exports: [LoadingModalComponent]
})
export class LoadingModalModule {}
