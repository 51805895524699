import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ShellNavigationsComponent } from "@onsip/web/features/shared/components/shellNavigations/shell-navigations.component";
import { MatTabsModule } from "@angular/material/tabs";
import { PipesModule } from "@onsip/web/features/shared/pipes/pipes.module";
import { RouterModule } from "@angular/router";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [ShellNavigationsComponent],
  exports: [ShellNavigationsComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    PipesModule,
    RouterModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatIconModule
  ]
})
export class ShellNavigationsModule {}
