<div class="session-media" [class.fullscreen]="fullscreen">
  <button
    id="onsip--video-conference--toggle-full-screen"
    aria-label="toggle fullscreen"
    class="fullscreen-button"
    [class.fullscreen]="fullscreen"
    mat-icon-button
    (click)="toggleFullScreen()"
  >
    <mat-icon>fullscreen</mat-icon>
  </button>
  @if (!fullscreen) {
  <div class="session-info">
    <div class="remote-identity video-title">
      <div class="outer-circle"><div class="inner-circle"></div></div>
      <h3 [class.fullscreen]="fullscreen">
        {{ conferenceName }} ({{ "ONSIP_I18N.VIDEO_CONFERENCE" | translate }})
      </h3>
    </div>
  </div>
  } @if (pageShowing) {
  <onsip-call-video-container> </onsip-call-video-container>
  }
  <div class="footer" [class.fullscreen]="fullscreen">
    <div class="button-container">
      <!-- VIDEO SHARE LINK -->
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        aria-label="hover for sharable conference link"
        #copy
        [matTooltip]="
          'ONSIP_I18N.PEOPLE_CAN_JOIN_YOUR_CONFERENCE_FOR_FREE_USING_THIS_LINK_SHARE_IT_WITH_PEOPLE_YOU_WANT_TO_INVITE_TO_YOUR_CONFERENCE'
            | translate
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="copyShareLink()"
      >
        <mat-icon color="primary">link</mat-icon>
      </button>
      <!-- HOLD/UNHOLD -->
      <div>
        @if (isHeld) {
        <button
          mat-fab
          class="onsip-call-begin-background mat-elevation-z0"
          id="onsip--video-conference--unhold"
          aria-label="unhold"
          [matTooltip]="'ONSIP_I18N.UNHOLD' | translate"
          matTooltipClass="onsip-tooltip-background"
          (click)="hold()"
        >
          <mat-icon>play_arrow</mat-icon>
        </button>
        } @if (!isHeld) {
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0"
          id="onsip--video-conference--hold"
          aria-label="hold"
          [matTooltip]="'ONSIP_I18N.HOLD' | translate"
          matTooltipClass="onsip-tooltip-background"
          (click)="hold()"
        >
          <mat-icon>pause</mat-icon>
        </button>
        }
      </div>
      <!-- MUTE/UNMUTE -->
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        id="onsip--video-conference--toggle-mute-microphone"
        aria-label="toggle mute microphone"
        [matTooltip]="
          isAudioMuted
            ? ('ONSIP_I18N.UNMUTE_MICROPHONE' | translate)
            : ('ONSIP_I18N.MUTE_MICROPHONE' | translate)
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="muteAudio()"
      >
        @if (isAudioMuted) {
        <mat-icon onsip-icon-outlined color="primary">mic_off</mat-icon>
        } @if (!isAudioMuted) {
        <mat-icon color="primary">mic</mat-icon>
        }
      </button>
      <!-- VIDEO ON/OFF -->
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        id="onsip--video-conference--toggle-video"
        aria-label="toggle video"
        [matTooltip]="
          isVideoMuted
            ? ('ONSIP_I18N.UNMUTE_VIDEO' | translate)
            : ('ONSIP_I18N.MUTE_VIDEO' | translate)
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="muteVideo()"
      >
        <mat-icon color="primary">{{ isVideoMuted ? "videocam_off" : "videocam" }}</mat-icon>
      </button>
      <!-- VOLUME -->
      <div cdkOverlayOrigin #videoConfVolumeButton="cdkOverlayOrigin">
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0"
          id="onsip--video-conference--toggle-mute-volume"
          aria-label="toggle mute volume"
          (click)="emitEventToVolume()"
        >
          <mat-icon color="primary">{{ volumeIcon }}</mat-icon>
        </button>
        <onsip-popup-container [overlayOrigin]="videoConfVolumeButton" [trigger]="'hover'">
          <onsip-volume-popup
            [call]="'VIDEOCONFERENCE'"
            [vertical]="false"
            [voicemail]="false"
            [event]="volumeClick.asObservable()"
          ></onsip-volume-popup>
        </onsip-popup-container>
      </div>
      <button
        mat-fab
        id="onsip--video-conference--end-conference"
        aria-label="end conference"
        class="leave-conf-button onsip-call-end-background mat-elevation-z0"
        (click)="endConference()"
      >
        <mat-icon>call_end</mat-icon>
      </button>
    </div>
  </div>
</div>
