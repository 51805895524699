import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "onsip-prephone",
  template: "<onsip-entry-page></onsip-entry-page>",
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrephoneComponent implements OnInit {
  constructor(private route: ActivatedRoute, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParamMap;
    let message = "";

    if (queryParams.get("reset")) {
      message = "Your password has been reset.";
    } else if (queryParams.get("unsubscribe")) {
      message = "You successfully unsubscribed from the queue.";
    } else if (queryParams.get("error")) {
      message = "You've been logged out. Please log in.";
    }

    if (message.length > 0) {
      this.snackBar.open(message, "", {
        verticalPosition: "top",
        duration: 7000
      });
    }
  }
}
