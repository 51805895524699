export const views = {
  CALL: "/call",
  CONTACT: "/contact",
  HOME: "/home",
  QUEUE_DASHBOARD: "/queue-dashboard",
  APP_SETTINGS: "/app-settings",
  VIDEO_CONFERENCE: "/video-conference",
  CONTACT_SALES: "/contact-sales",
  CONTACT_SUPPORT: "/contact-support",
  SAYSO_DASHBOARD: "/sayso/dashboard",
  SAYSO_INSTALLATION: "/sayso/installation",
  SAYSO_SETTINGS: "/sayso/settings",
  SAYSO_ADD: "/sayso/add",
  SAYSO_VIEW: "/sayso/view",
  SAYSO_EDIT: "/sayso/edit",
  SAYSO_DUPLICATE: "/sayso/duplicate",
  ORG_CALL_HISTORY: "/sayso/org-call-history",
  MANAGER_DASHBOARD: "/sayso/manager-dashboard",
  TEAM_PAGE: "/sayso/team-page",
  DEVELOPER: "/developer",
  DEVELOPER_FIRST_TEST_NAV: "/developer/first-test-nav",
  DEVELOPER_SECOND_TEST_NAV: "/developer/second-test-nav",
  DEVELOPER_THIRD_TEST_NAV: "/developer/third-test-nav",
  USERS: "/administrators/users",
  GROUPS: "/administrators/groups",
  GROUPS_SETUP: "/administrators/groups/setup",
  PHONE_NUMBERS: "/administrators/phone-numbers",
  PHONE_NUMBERS_PORTING: "/administrators/phone-numbers/port",
  PHONES: "/administrators/phones",
  PHONE_SETUP: "/administrators/phones/phone-setup",
  PHONE_NUMBERS_VANITY: "/administrators/phone-numbers/vanity",
  PROTOTYPE_HOME: "/prototype/home",
  PROTOTYPE_CALL_PAGE: "/prototype/call-page",
  PROTOTYPE_DND: "/prototype/dnd",
  PROTOTYPE_MGR_DASHBOARD: "/prototype/manager-dashboard",
  PROTOTYPE_ORG_CALL_HISTORY: "/prototype/org-call-history",
  SAYSO_SETTINGS_2: "/prototype/sayso/settings",
  SAYSO_ADD_2: "/prototype/sayso/add",
  SAYSO_VIEW_2: "/prototype/sayso/view",
  SAYSO_EDIT_2: "/prototype/sayso/edit",
  CALL_MANAGER: "/call-manager",
  E911_TABLE: "/administrators/e911",
  E911_NO_BALANCE: "/administrators/e911/no-balance",
  E911_NO_LOCATION: "/administrators/e911/no-location",
  E911_NO_FREE_TRIAL: "/administrators/e911/no-free-trial",
  E911_INVOICED_PREPAID: "/administrators/e911/invoiced-prepaid",
  ADMIN_VOICEMAIL: "/administrators/voicemail",
  ADMIN_VOICEMAIL_SETUP: "/administrators/voicemail/voicemail-setup",
  ADMIN_RECORDING: "/administrators/recording-library",
  ADMIN_RECORDING_SETUP: "/administrators/recording-library/setup",
  ADMIN_ADV_ANNOUNCEMENTS: "/administrators/announcements",
  ADMIN_ADV_ANNOUNCEMENTS_SETUP: "/administrators/announcements/announcements-setup",
  ADMIN_ATTENDANT_MENU: "/administrators/attendant-menu",
  ADMIN_ADD_ATTENDANT_MENU: "/administrators/attendant-menu/add",
  ADMIN_BHR: "/administrators/business-hour-rules",
  ADMIN_BHR_EDIT: "/administrators/business-hour-rules/edit",
  ADMIN_BHR_CREATE: "/administrators/business-hour-rules/create",
  ADMIN_BHR_DETAILS: "/administrators/business-hour-rules/details",
  ADMIN_MUSIC_ON_HOLD: "/administrators/music-on-hold",
  ADMIN_PARKING_LOT: "/administrators/parking-lot",
  ADMIN_PARKING_LOT_SETUP: "/administrators/parking-lot/add",
  ADMIN_CONFERENCE_SUITE: "/administrators/conference-suite",
  ADMIN_CONFERENCE_SUITE_SETUP: "/administrators/conference-suite/setup",
  ADMIN_DBN_DIRECTORY: "/administrators/dbn-directory",
  ADMIN_DBN_DIRECTORY_SETUP: "/administrators/dbn-directory/setup",
  ADMIN_INBOUND_BRIDGE: "/administrators/inbound-bridge",
  ADMIN_INBOUND_BRIDGE_SETUP: "/administrators/inbound-bridge/setup",
  ADMIN_ACD_QUEUES: "/administrators/acd-queues",
  ADMIN_ACD_QUEUES_SETUP: "//administrators/acd-queues/queue-setup",
  ADMIN_DIRECTORY: "/administrators/directory",
  ADMIN_EXTERNAL_ADDRESS: "/administrators/external-address",
  ADMIN_EXTERNAL_ADDRESS_SETUP: "/administrators/external-address/setup",
  ADMIN_EXTERNAL_PHONE_NUMBER: "/administrators/external-phone-number",
  ADMIN_EXTERNAL_PHONE_NUMBER_SETUP: "/administrators/external-phone-number/setup",
  ADMIN_RECORDING_RULES: "/administrators/recording-rules",
  ADMIN_RECORDING_RULES_SETUP: "/administrators/recording-rules/setup",
  ADMIN_ACCOUNT_SETTINGS: "/account-settings",
  ADMIN_ACCOUNT_SETTINGS_PLAN_OVERVIEW: "/account-settings/plan-overview",
  ADMIN_ACCOUNT_SETTINGS_CALLING_CREDITS: "/account-settings/calling-credits",
  ADMIN_ACCOUNT_SETTINGS_CREDIT_CARD: "/account-settings/credit-card",
  ADMIN_ACCOUNT_SETTINGS_BILLING_CONTACT: "/account-settings/billing-contact",
  ADMIN_ACCOUNT_SETTINGS_INTERNATIONAL_DIALING: "/account-settings/international-dialing",
  ADMIN_ACCOUNT_SETTINGS_PURCHASE_HISTORY: "/account-settings/purchase-history",
  ADMIN_ACCOUNT_SETTINGS_TRANSFER_NUMBERS: "/account-settings/numbers-transfer",
  ADMIN_ACCOUNT_SETTINGS_TRANSFER_PHONES: "/account-settings/transfer-phones",
  ADMIN_PBX_SETTINGS: "/administrators/pbx-settings",
  ADMIN_PBX_SETTINGS_CONTACT: "/administrators/pbx-settings/contact",
  ADMIN_PBX_SETTINGS_BLOCKED_NUMBERS: "/administrators/pbx-settings/blocked-numbers",
  ADMIN_PBX_SETTINGS_DEFAULT_CALLER_ID: "/administrators/pbx-settings/default-caller-id",
  ADMIN_PBX_SETTINGS_ENABLED_SERVICES: "/administrators/pbx-settings/enabled-services",
  ADMIN_PBX_SETTINGS_MIGRATE_SIP_DOMAIN: "/administrators/pbx-settings/migrate-sip-domain",
  ADMIN_PBX_SETTINGS_RECORDING_MANAGER: "/administrators/pbx-settings/recording-manager",
  ADMIN_PBX_SETTINGS_VOICEMAIL_MANAGER: "/administrators/pbx-settings/voicemail-manager",
  ADMIN_REPORTS: "/administrators/reports",
  ADMIN_REPORT_DETAILS: "/administrators/reports/details",
  ADMIN_PBX_OVERVIEW: "/administrators/pbx-overview",
  ADMIN_ACCOUNT_SETTINGS_CREATE_PBX: "/account-settings/create-pbx",
  SUB_AGENT_VIEW: "/sub-agent-view",
  AGENT_VIEW: "/agent-view",
  SUPER_USER_VIEW: "/super-user-view",
  AGENT_ACCOUNTS: "/agent-view/accounts",
  AGENT_COMMISSIONS: "/agent-view/commissions",
  AGENT_SUPPORT: "/agent-view/support",
  AGENT_CREATE_ACCOUNT: "/agent-view/create-account",
  ADMIN_PBX_SETTINGS_STORAGE_SERVICE: "/administrators/pbx-settings/storage-service",
  ADMIN_USER_CREATE: "/administrators/users/create",
  ADMIN_PORTING_NO_CARD: "/administrators/phone-numbers/port/no-card"
};
