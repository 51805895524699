/**
 * a single dialpad button that processes mouse and touch events
 * and listens for dialpad events from other sources so that it
 * can update it's template's state
 *
 * requires:
 *   data-dtmf-key
 *   data-dtmf-letters
 */
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "onsip-dialpad-button",
  templateUrl: "./dialpad-button.component.html",
  styleUrls: ["./dialpad-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialpadButtonComponent {
  @Input() dtmfKey = "1";
  @Input() dtmfLetters = "";
  @Output() buttonPress = new EventEmitter<string>();
  @Output() buttonUp = new EventEmitter<string>();

  isActive = false;

  down(): void {
    this.isActive = true;
    this.buttonPress.emit(this.dtmfKey);
  }

  up(): void {
    this.buttonUp.emit(this.dtmfKey);
    this.isActive = false;
  }
}
