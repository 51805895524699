import {
  Document,
  arrayRemove,
  arrayUnion,
  timestampSetData,
  timestampUpdateData
} from "../cloud/firebase/firestore/document";
import { UserData, UserState, makeUserState } from "./user-state";

export function makeDocumentPath(oid: number, uid: number): string {
  return `orgs/${oid}/users/${uid}`;
}

/**
 * User class.
 */
export class User extends Document<UserState> {
  /**
   * exposes user id instance variable to be used for querying
   */
  // this was needed because non registered users would have empty UserState but we still want to query for them with an user id
  private _uid: number | undefined;

  /**
   * getter for uid
   */

  get uid(): number | undefined {
    return this._uid;
  }

  /**
   * Gets the state of an User.
   * @param oid Organization Id.
   * @param uid User Id.
   */
  static get(oid: number, uid: number): Promise<UserState | undefined> {
    return this.getDocument(makeDocumentPath(oid, uid), makeUserState);
  }

  /**
   * Merges a User in an Org
   * @param oid Organization Id.
   * @param uid user Id.
   * @param data User data.
   */
  static merge(oid: number, uid: number, data: Partial<UserData>): Promise<void> {
    return this.mergeDocument(makeDocumentPath(oid, uid), timestampUpdateData(data));
  }

  /**
   * Sets a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param data User data.
   */
  static set(oid: number, uid: number, data: UserData): Promise<void> {
    return this.setDocument(makeDocumentPath(oid, uid), timestampSetData(data));
  }

  /**
   * Updates a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param data User data.
   */
  static update(oid: number, uid: number, data: Partial<UserData>): Promise<void> {
    return this.updateDocument(makeDocumentPath(oid, uid), timestampUpdateData(data));
  }

  /**
   * Updates a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param setData User data.
   * @param updateData User data.
   */
  static setOrUpdate(
    oid: number,
    uid: number,
    setData: UserData,
    updateData: Partial<UserData>
  ): Promise<void> {
    return this.setOrUpdateDocument(
      makeDocumentPath(oid, uid),
      timestampSetData(setData),
      timestampUpdateData(updateData)
    );
  }

  /**
   * Adds a topic into a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param topic The topic to merge.
   */
  static topicAdd(oid: number, uid: number, topic: string): Promise<void> {
    const data = {
      topics: arrayUnion(topic)
    };
    return this.setOrMergeDocument(
      makeDocumentPath(oid, uid),
      timestampSetData(data),
      timestampUpdateData(data)
    );
  }

  /**
   * Remove a topic from a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param topic The topic to remove.
   */
  static topicRemove(oid: number, uid: number, topic: string): Promise<void> {
    const data = {
      topics: arrayRemove(topic)
    };
    return this.updateDocument(makeDocumentPath(oid, uid), timestampUpdateData(data));
  }

  /**
   * Set the availability for a User in an Org
   * @param oid Organization Id.
   * @param uid User Id.
   * @param status The manually set global availability.
   */
  static setUserAvailability(oid: number, uid: number, status: string): Promise<void> {
    const data = {
      availability: status
    };
    return this.updateDocument(makeDocumentPath(oid, uid), timestampUpdateData(data));
  }

  /**
   * Constructor
   * @param state Initial User state.
   */
  constructor(state: UserState = makeUserState("", {})) {
    super(state);
  }

  /**
   * Disposes.
   */
  dispose() {
    super.dispose();
  }

  /**
   * Starts listening for updates to User.
   * @param oid Organization Id.
   * @param uid User Id.
   */
  start(oid: number, uid: number): void {
    this._uid = uid;
    this.startListening(makeDocumentPath(oid, uid), makeUserState);
  }

  /**
   * Stops listening.
   */
  stop(): void {
    this.stopListening();
  }
}
