import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

import { StateEmitter } from "../../../../common/libraries/emitter/state-emitter";

import { CallState } from "../../../../common/libraries/sip/call-state";
import { CallGroupState } from "../../../../common/libraries/sip/call-group-state";
import { CallEvent, isEndCallEvent } from "../../../../common/libraries/sip/call-event";

import { CallControllerService } from "../../../../common/services/call-controller.service";
import { IdentityService } from "../../../../common/services/identity.service";
import { UserAgentService } from "../components/userAgent/user-agent.service";
import { E164PhoneNumber } from "../../../../common/libraries/e164-phone-number";

/* We currently use call groups for multiple purposes:
 * - tracking calls meant to attended transfer together
 * - calls which are 3 way conferencing
 * This service is for tracking which group is for which purpose
 */
/** GroupManagementState interface. */
export interface GroupManagementState {
  conferenceGroups: Array<CallGroupState<CallState>>;

  transferGroups: Array<CallGroupState<CallState>>;
}

@Injectable({ providedIn: "root" })
export class CallGroupService extends StateEmitter<GroupManagementState> {
  private pendingThreeWayConference: any = {};

  constructor(
    private callControllerService: CallControllerService,
    private userAgentService: UserAgentService,
    private identityService: IdentityService
  ) {
    super({
      conferenceGroups: [],
      transferGroups: []
    });

    this.callControllerService.getCallEventObservable().subscribe(event => {
      if (isEndCallEvent(event)) {
        const pendingThree: string | undefined = this.pendingThreeWayConference[event.uuid];

        if (pendingThree) {
          this.pendingThreeWayConference[pendingThree] = undefined;
          this.pendingThreeWayConference[event.uuid] = undefined;
        }
      }
    });

    this.callControllerService.state.subscribe(state => {
      const newConfGroups: Array<CallGroupState<CallState>> = [],
        newTransferGroups: Array<CallGroupState<CallState>> = [];
      let shouldPublish = true;

      this.stateStore.conferenceGroups.forEach(group => {
        const updatedGroup: CallGroupState<CallState> | undefined = state.groups.find(
          newGroup => newGroup.uuid === group.uuid
        );
        if (!updatedGroup || updatedGroup.calls.length < 2) {
          shouldPublish = false;
          return;
        }
        newConfGroups.push(updatedGroup);
      });
      this.stateStore.transferGroups.forEach(group => {
        const updatedGroup: CallGroupState<CallState> | undefined = state.groups.find(
          newGroup => newGroup.uuid === group.uuid
        );
        if (!updatedGroup || updatedGroup.calls.length < 2) {
          shouldPublish = false;
          return;
        }
        newTransferGroups.push(updatedGroup);
      });

      this.stateStore.conferenceGroups = newConfGroups;
      this.stateStore.transferGroups = newTransferGroups;

      if (shouldPublish) {
        this.publishState();
      }
    });
  }

  findAttendedTransferPartner(uuid: string): CallState | undefined {
    const group: CallGroupState<CallState> | undefined =
      this.callControllerService.getGroupStateFromCall(uuid);

    if (
      group &&
      group.calls.length === 2 &&
      !!this.stateStore.transferGroups.find(transferGroup => transferGroup.uuid === group.uuid)
    ) {
      return group.calls.find(call => call.uuid !== uuid);
    }
  }

  findThreeWayConferencePartner(uuid: string): CallState | undefined {
    const group: CallGroupState<CallState> | undefined =
      this.callControllerService.getGroupStateFromCall(uuid);
    let otherCall: CallState | undefined;

    if (
      group &&
      group.calls.length === 2 &&
      !!this.stateStore.conferenceGroups.find(confGroup => confGroup.uuid === group.uuid)
    ) {
      otherCall = group.calls.find(call => call.uuid !== uuid);
    } else {
      const pendingThree: string | undefined = this.pendingThreeWayConference[uuid];

      if (pendingThree) {
        otherCall = this.callControllerService.getCallStateByUuid(pendingThree);
      }
    }

    if (otherCall && !otherCall.ended) {
      return otherCall;
    }
  }

  createThreeWayConferenceCall(existingCall: CallState, targetString: string): Promise<string> {
    const potentialNumber = new E164PhoneNumber(targetString);
    const target = potentialNumber.isValid ? potentialNumber.e164Uri : targetString;

    this.identityService.setOutboundIdentity(existingCall.aor);
    return this.userAgentService.createOutboundCall(target, {}, true).then(uuid => {
      const callObservable: Observable<CallEvent> | undefined =
        this.callControllerService.getCallObservableFilteredByConnected(uuid);
      this.pendingThreeWayConference[uuid] = existingCall.uuid;
      this.pendingThreeWayConference[existingCall.uuid] = uuid;

      if (!callObservable) {
        return uuid;
      }

      callObservable.subscribe(() => {
        this.callControllerService.groupCalls(uuid, existingCall.uuid).then(() => {
          const group: CallGroupState<CallState> | undefined =
            this.callControllerService.getGroupStateFromCall(existingCall.uuid);

          if (!group) {
            return;
          }
          this.stateStore.conferenceGroups.push(group);
          this.pendingThreeWayConference[uuid] = undefined;
          this.pendingThreeWayConference[existingCall.uuid] = undefined;

          const activeCall: CallState | undefined =
              this.callControllerService.getCallStateByUuid(uuid),
            otherCall: CallState | undefined = this.callControllerService.getCallStateByUuid(
              existingCall.uuid
            );

          if (!activeCall || !otherCall || (otherCall.hold && activeCall.hold)) {
            return;
          }
          if (otherCall.hold) {
            this.callControllerService.unholdCall(otherCall.uuid);
          }
          if (activeCall.hold) {
            this.callControllerService.unholdCall(activeCall.uuid);
          }
        });
      });

      return uuid;
    });
  }

  createTransferCall(existingCall: CallState, target: string): Promise<string> {
    this.identityService.setOutboundIdentity(existingCall.aor);
    // target = this.callControllerService.parsePSTNString(target);
    target = new E164PhoneNumber(target).e164Uri;
    return this.userAgentService
      .createOutboundCall(target, {
        isVideoInvite: existingCall.videoAvailable
      })
      .then(uuid => {
        const group: CallGroupState<CallState> | undefined =
            this.callControllerService.getGroupStateFromCall(existingCall.uuid),
          callObservable: Observable<CallEvent> | undefined =
            this.callControllerService.getCallObservableFilteredByConnected(uuid);

        if (!callObservable) {
          return uuid;
        }

        callObservable.subscribe(() => {
          this.callControllerService.groupCalls(uuid, existingCall.uuid).then(() => {
            if (group) {
              this.stateStore.transferGroups.push(group);
            }
            this.publishState();
          });
        });

        return uuid;
      });
  }
}
