<div class="onsip-queue-historical">
  <div class="queue-historical-content">
    <div class="queue-report-top-half">
      <form (ngSubmit)="historicalQueueFormSubmitted()">
        <span class="report-queue-form-options">
          <mat-form-field class="form-field-1" appearance="fill">
            <mat-label>{{ "ONSIP_I18N.QUEUE" | translate }}</mat-label>
            <mat-select
              [(value)]="queueOptionSelected"
              placeholder="{{ 'ONSIP_I18N.SELECT_A_QUEUE' | translate }}"
              [formControl]="queueControl"
              required
            >
              @for (queue of queueOptions; track queue) {
              <mat-option [value]="queue.value">
                {{ queue.label }}
              </mat-option>
              }
            </mat-select>
            @if (queueControl.invalid) {
            <mat-error>{{ "ONSIP_I18N.PLEASE_SELECT_A_QUEUE" | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field class="form-field-2" appearance="fill">
            <mat-label>Start date</mat-label>
            <input
              matInput
              [matDatepicker]="startDatePicker"
              autocomplete="off"
              [formControl]="startDateControl"
              [max]="maxStartDate"
              placeholder="Choose a start date"
            />
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="form-field-3" appearance="fill">
            <mat-label>End date</mat-label>
            <input
              matInput
              [matDatepicker]="endDatePicker"
              autocomplete="off"
              [formControl]="endDateControl"
              [max]="maxEndDate"
              placeholder="Choose an end date"
            />
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field class="form-field-4" appearance="fill">
            <mat-label>{{ "ONSIP_I18N.TIMEZONE" | translate }}</mat-label>
            <mat-select
              [(value)]="timezoneOptionSelected"
              placeholder="{{ 'ONSIP_I18N.SELECT_A_TIMEZONE' | translate }}"
              [formControl]="timezoneControl"
              required
            >
              @for (timezone of timezoneOptions; track timezone) {
              <mat-option [value]="timezone.value">
                {{ timezone.label }}
              </mat-option>
              }
            </mat-select>
            @if (timezoneControl.invalid) {
            <mat-error>{{ "ONSIP_I18N.PLEASE_SELECT_A_TIMEZONE" | translate }}</mat-error>
            }
          </mat-form-field>
        </span>
        <span class="queue-report-form-submit-button">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            id="onsip--queue-historical--generate-report"
            [disabled]="(reportState | async) === 'loading'"
          >
            {{ "ONSIP_I18N.GENERATE_REPORT" | translate }}
          </button>
        </span>
      </form>
      @if ((reportState | async) === 'queue') {
      <section>
        <div class="report-section-title">
          <img height="44px" [src]="'resources/img/waiting_agent_queue.svg'" alt="Waiting Agent" />
          <h2>
            {{ "ONSIP_I18N.QUEUE_REPORT" | translate }}
            <span>
              {{ historicalQueueReports.displayName }}
            </span>
          </h2>
        </div>
        <div>
          @if (!historicalQueueReports.queue) {
          <aside>
            {{
              "ONSIP_I18N.YOUR_SEARCH_PARAMETERS_HAVE_NOT_RETURNED_ANY_DATA_PLEASE_MODIFY_YOUR_START_AND_END_DATE_AND_TRY_YOUR_SEARCH_AGAIN"
                | translate
            }}
          </aside>
          } @else {
          <table class="historical-report-queue-table">
            @for (queue of queueTableInfo; track queue) {
            <tr
              [class.primary-500]="queue.cellTitle === 'Calls Answered'"
              [class.warn-500]="
                queue.cellTitle === 'Calls Abandoned' || queue.cellTitle === 'Calls Rejected'
              "
              [class.accent-500]="queue.cellTitle === 'Calls to Failover'"
              [style.fontWeight]="
                queue.cellTitle === 'Calls Answered' ||
                queue.cellTitle === 'Calls Abandoned' ||
                queue.cellTitle === 'Calls Rejected' ||
                queue.cellTitle === 'Calls to Failover'
                  ? '700'
                  : '400'
              "
            >
              <td>
                {{ queue.cellTitle }}
              </td>
              <td
                [class.primary-500]="true"
                [style.fontWeight]="
                  queue.cellTitle === 'Calls Answered' ||
                  queue.cellTitle === 'Calls Abandoned' ||
                  queue.cellTitle === 'Calls Rejected' ||
                  queue.cellTitle === 'Calls to Failover'
                    ? '700'
                    : '400'
                "
              >
                {{ queue.cellInfo }}
              </td>
            </tr>
            }
            <tfoot>
              <tr>
                <td class="queue-download-table-row" colspan="2">
                  <div class="queue-download-option-row">
                    <a
                      mat-raised-button
                      id="onsip--queue-historical--report-download-summary"
                      class="queue-report-summary-button"
                      color="primary"
                      (click)="downloadTable(historicalQueueReports.csv.caller.download, 'queue')"
                      download="{{ historicalQueueReports.csv.caller.download }}"
                    >
                      {{ "ONSIP_I18N.DOWNLOAD_SUMMARY" | translate }}
                    </a>
                    <a
                      mat-raised-button
                      id="onsip--queue-historical--report-download-events"
                      class="queue-report-events-button"
                      color="primary"
                      (click)="
                        downloadEventsAsCsv(
                          $event,
                          historicalQueueReports.csv.caller.Action,
                          historicalQueueReports.csv.caller.parameters,
                          historicalQueueReports.csv.caller.downloadEvents,
                          timezoneOptionSelected
                        )
                      "
                      download="{{ historicalQueueReports.csv.caller.downloadEvents }}"
                    >
                      {{ "ONSIP_I18N.DOWNLOAD_EVENTS" | translate }}
                    </a>
                  </div>
                  .
                </td>
              </tr>
            </tfoot>
          </table>
          }
        </div>
      </section>
      }
    </div>
    @if ((reportState | async) === 'queue') {
    <section>
      <div class="report-section-title">
        <img height="44px" [src]="'resources/img/single_agent_queue.svg'" alt="Single Agent" />
        <h2>
          {{ "ONSIP_I18N.AGENT_REPORT" | translate }}
          <span>
            {{ historicalQueueReports.displayName }}
          </span>
        </h2>
      </div>
      <div>
        @if(!historicalQueueReports.agents.length) {
        <aside>
          {{
            "ONSIP_I18N.YOUR_SEARCH_PARAMETERS_HAVE_NOT_RETURNED_ANY_DATA_PLEASE_MODIFY_YOUR_START_AND_END_DATE_AND_TRY_YOUR_SEARCH_AGAIN"
              | translate
          }}
        </aside>
        } @else {
        <table
          mat-table
          id="historicalReportAgentsTable"
          class="historical-report-agents-table"
          [dataSource]="historicalQueueReports.agents"
        >
          <ng-container matColumnDef="agentName">
            <th mat-header-cell *matHeaderCellDef>
              {{ "ONSIP_I18N.AGENT_NAME" | translate }}
            </th>
            <td mat-cell *matCellDef="let agent">
              {{ agent.agentName || agent.agentAddress }}
            </td>
          </ng-container>
          <ng-container matColumnDef="loginDuration">
            <th mat-header-cell *matHeaderCellDef class="agent-table-detail-cell">
              {{ "ONSIP_I18N.ACTIVE_TIME" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let agent"
              [class.primary-500]="true"
              class="agent-table-detail-cell"
            >
              {{ agent.loginDuration }}
            </td>
          </ng-container>
          <ng-container matColumnDef="callsAnswered">
            <th mat-header-cell *matHeaderCellDef class="agent-table-detail-cell">
              {{ "ONSIP_I18N.CALLS_ANSWERED" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let agent"
              [class.primary-500]="true"
              class="agent-table-detail-cell"
            >
              {{ agent.callsAnswered }}
            </td>
          </ng-container>
          <ng-container matColumnDef="avgCallLength">
            <th mat-header-cell *matHeaderCellDef class="agent-table-detail-cell">
              {{ "ONSIP_I18N.AVG_CALL_DURATION" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let agent"
              [class.primary-500]="true"
              class="agent-table-detail-cell"
            >
              {{ agent.avgCallLength }}
            </td>
          </ng-container>
          <ng-container matColumnDef="timeOnCalls">
            <th mat-header-cell *matHeaderCellDef class="agent-table-detail-cell">
              {{ "ONSIP_I18N.TIME_ON_CALL" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let agent"
              [class.primary-500]="true"
              class="agent-table-detail-cell"
            >
              {{ agent.timeOnCalls }}
            </td>
          </ng-container>
          <ng-container matColumnDef="timeOnHold">
            <th mat-header-cell *matHeaderCellDef class="agent-table-detail-cell">
              {{ "ONSIP_I18N.CALLS_ON_HOLD" | translate }}
            </th>
            <td
              mat-cell
              *matCellDef="let agent"
              [class.primary-500]="true"
              class="agent-table-detail-cell"
            >
              {{ agent.timeOnHold }}
            </td>
          </ng-container>
          <ng-container matColumnDef="percentActiveTime">
            <th mat-header-cell *matHeaderCellDef>
              {{ "ONSIP_I18N._ACTIVE_TIME_ON_CALL" | translate }}
            </th>
            <td mat-cell *matCellDef="let agent" [class.primary-500]="true">
              {{ agent.percentActiveTime }}
            </td>
          </ng-container>
          <ng-container matColumnDef="agent-download-options">
            <td mat-footer-cell *matFooterCellDef colspan="7">
              <div class="agent-download-option-row">
                <a
                  mat-raised-button
                  id="onsip--queue-historical--agent-report-download-summary"
                  class="queue-report-summary-button"
                  color="primary"
                  (click)="downloadTable(historicalQueueReports.csv.agent.download, 'agent')"
                  download="{{ historicalQueueReports.csv.agent.download }}"
                >
                  {{ "ONSIP_I18N.DOWNLOAD_SUMMARY" | translate }}
                </a>
                <a
                  mat-raised-button
                  id="onsip--queue-historical--agent-report-download-events"
                  color="primary"
                  (click)="
                    downloadEventsAsCsv(
                      $event,
                      historicalQueueReports.csv.agent.Action,
                      historicalQueueReports.csv.agent.parameters,
                      historicalQueueReports.csv.agent.downloadEvents,
                      timezoneOptionSelected
                    )
                  "
                  download="{{ historicalQueueReports.csv.agent.downloadEvents }}"
                >
                  {{ "ONSIP_I18N.DOWNLOAD_EVENTS" | translate }}
                </a>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="agentReportColumns"></tr>
          <tr mat-row *matRowDef="let agent; columns: agentReportColumns"></tr>
          <tr mat-footer-row *matFooterRowDef="['agent-download-options']"></tr>
        </table>
        <!-- mobile view -->
        <div class="historical-report-agents-table-mobile">
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N.ACTIVE_TIME" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.loginDuration }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N.CALLS_ANSWERED" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.callsAnswered }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N.AVG_CALL_DURATION" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.avgCallLength }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N.TIME_ON_CALL" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.timeOnCalls }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N.CALLS_ON_HOLD" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.timeOnHold }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <mat-accordion>
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ "ONSIP_I18N._ACTIVE_TIME_ON_CALL" | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table class="queue-report-agents-table-mobile">
                @for (agent of historicalQueueReports.agents; track agent) {
                <tr>
                  <td>
                    {{ agent.agentName || agent.agentAddress }}
                  </td>
                  <td class="queue-table-detail" [class.primary-500]="true">
                    {{ agent.percentActiveTime }}
                  </td>
                </tr>
                }
              </table>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="queue-download-option-row">
            <a
              mat-raised-button
              id="onsip--queue-historical--agent-report-download-summary"
              class="queue-report-summary-button"
              color="primary"
              (click)="downloadTable(historicalQueueReports.csv.agent.download, 'agent')"
              download="{{ historicalQueueReports.csv.agent.download }}"
            >
              {{ "ONSIP_I18N.DOWNLOAD_SUMMARY" | translate }}
            </a>
            <a
              mat-raised-button
              id="onsip--queue-historical--agent-report-download-events"
              class="queue-report-events-button-mobile"
              color="primary"
              (click)="
                downloadEventsAsCsv(
                  $event,
                  historicalQueueReports.csv.agent.Action,
                  historicalQueueReports.csv.agent.parameters,
                  historicalQueueReports.csv.agent.downloadEvents,
                  timezoneOptionSelected
                )
              "
              download="{{ historicalQueueReports.csv.agent.downloadEvents }}"
            >
              {{ "ONSIP_I18N.DOWNLOAD_EVENTS" | translate }}
            </a>
          </div>
        </div>
        }
      </div>
    </section>
    }
  </div>
</div>
