import { SessionEventFactory } from "./session-event";

/**
 * Emitted once when the Session has been replaced via receipt of an INVITE w/Replaces.
 */
export class ReplacedSessionEvent {
  static make(aor: string, uuid: string) {
    return SessionEventFactory.make(ReplacedSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] replaced";
  }
}
