import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { PresencePublisher } from "./presence-publisher";
import { UserInstance } from "./user-instance";
import { firebase } from "../cloud/firebase/platform-firebase-types";

const debug = false;

export class UserPresencePublisher extends PresencePublisher {
  private _busy = false; // default to instance is not busy
  private _doNotDisturb = false; // default to instance has not gone on dnd
  private _videoDisabled = false; // default is instance has not disabled video

  private static makePublishRef(oid: number, uid: number): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/users/${uid}`).push();
  }

  /**
   * Constructor
   * @param oid The organization id of the user.
   * @param uid The user id.
   * @param _aor The AOR.
   * @param _name The display name.
   * @param _picture The url of the avatar image.
   * @param _title The display title.
   */
  constructor(
    oid: number,
    uid: number,
    private _aor: string,
    private _name: string,
    private _picture?: string,
    private _title?: string
  ) {
    super(UserPresencePublisher.makePublishRef(oid, uid));
  }

  get aor(): string {
    return this._aor;
  }

  get busy(): boolean {
    return this._busy;
  }

  get doNotDisturb(): boolean {
    return this._doNotDisturb;
  }

  get name(): string {
    return this._name;
  }

  get picture(): string | undefined {
    return this._picture;
  }

  get title(): string | undefined {
    return this._title;
  }

  get videoDisabled(): boolean {
    return this._videoDisabled;
  }

  setAOR(aor: string): Promise<void> {
    if (this._aor === aor) {
      return Promise.resolve();
    }
    const prior = this._aor;
    this._aor = aor;
    const child: keyof UserInstance = "a";
    return this.setChild(child, aor).then(committed => {
      if (!committed) {
        this._aor = prior;
      }
    });
  }

  setBusy(busy: boolean): Promise<void> {
    if (this._busy === busy) {
      return Promise.resolve();
    }
    this._busy = busy;
    const child: keyof UserInstance = "b";
    return this.setChild(child, busy).then(committed => {
      if (!committed) {
        this._busy = !busy;
      }
    });
  }

  setDoNotDisturb(dnd: boolean): Promise<void> {
    if (this._doNotDisturb === dnd) {
      return Promise.resolve();
    }
    this._doNotDisturb = dnd;
    const child: keyof UserInstance = "d";
    return this.setChild(child, dnd).then(committed => {
      if (!committed) {
        this._doNotDisturb = !dnd;
      }
    });
  }

  setName(name: string): Promise<void> {
    if (this._name === name) {
      return Promise.resolve();
    }
    const prior = this._name;
    this._name = name;
    const child: keyof UserInstance = "n";
    return this.setChild(child, name).then(committed => {
      if (!committed) {
        this._name = prior;
      }
    });
  }

  setTitle(title: string): Promise<void> {
    if (this._title === title) {
      return Promise.resolve();
    }
    const prior = this._title;
    this._title = title;
    const child: keyof UserInstance = "t";
    return this.setChild(child, title).then(committed => {
      if (!committed) {
        this._title = prior;
      }
    });
  }

  setPicture(picture: string): Promise<void> {
    if (this._picture === picture) {
      return Promise.resolve();
    }
    const prior = this._picture;
    this._picture = picture;
    const child: keyof UserInstance = "p";
    return this.setChild(child, picture).then(committed => {
      if (!committed) {
        this._picture = prior;
      }
    });
  }

  setVideoDisabled(disabled: boolean): Promise<void> {
    if (this._videoDisabled === disabled) {
      return Promise.resolve();
    }
    this._videoDisabled = disabled;
    const child: keyof UserInstance = "v";
    return this.setChild(child, disabled).then(committed => {
      if (!committed) {
        this._videoDisabled = !disabled;
      }
    });
  }

  protected get id(): string {
    return `UserPresencePublisher[${this.publishRef.key}]`;
  }

  protected get value(): UserInstance {
    const myValue: UserInstance = {
      a: this._aor,
      b: this._busy,
      c: PlatformFirebase.database.ServerValue.TIMESTAMP,
      d: this._doNotDisturb,
      n: this._name,
      v: this._videoDisabled
    };
    if (this._picture) myValue.p = this._picture;
    if (this._title) myValue.t = this._title;
    return myValue;
  }

  private setChild(key: keyof UserInstance, value: any): Promise<boolean> {
    return this.publishRef
      .child(key)
      .transaction(currentValue => {
        // abort transaction if no data at current location (return undefined)
        // we don't want to set data if the parent node does not exist which
        // should/would be the case if we disconnected and it was removed.
        // eslint-disable-next-line no-null/no-null
        if (currentValue === null) return undefined;
        // set the new value
        return value;
      })
      .then((result: { committed: boolean; snapshot: firebase.database.DataSnapshot }) => {
        debug &&
          console.log(`UserPresencePublisher.setChild[${key}] committed ${result.committed}`);
        debug &&
          console.log(
            `UserPresencePublisher.setChild[${key}] data exists ${result.snapshot.exists()}`
          );
        debug &&
          console.log(`UserPresencePublisher.setChild[${key}] data value ${result.snapshot.val()}`);
        return result.committed;
      })
      .catch((error: Error) => {
        debug && console.error(`UserPresencePublisher.setChild[${key}] error`);
        debug && console.error(error);
        throw error;
      });
  }
}
