@if ( (isMobile | async) === false) { @if (!displayInstructions) {
<h1>{{ "ONSIP_I18N.SELECT_CMS" | translate }}</h1>
<mat-grid-list [cols]="gridColumns | async" [rowHeight]="rowHeight" [gutterSize]="gridGutter">
  <mat-grid-tile class="cms generic" (click)="toggleInstructions('generic')">
    <mat-card>
      <mat-card-content>
        <div class="no-logo">{{ "ONSIP_I18N.NOT_LISTED" | translate }}</div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms hubspot" (click)="toggleInstructions('hubspot')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/hubspot.png'" alt="Hubspot" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms squarespace" (click)="toggleInstructions('squarespace')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/squarespace.png'" alt="Squarespace" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms wordpress" (click)="toggleInstructions('wordpress')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/wordpress.png'" alt="Wordpress" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms weebly" (click)="toggleInstructions('weebly')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/weebly.png'" alt="Weebly" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms wix" (click)="toggleInstructions('wix')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/wix.png'" alt="Wix" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms shopify" (click)="toggleInstructions('shopify')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img class="logo" [src]="'resources/img/sayso/logos/shopify.png'" alt="Shopify" />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>

  <mat-grid-tile class="cms google-tag" (click)="toggleInstructions('google tag manager')">
    <mat-card>
      <mat-card-content>
        <div class="img-container">
          <img
            class="logo"
            [src]="'resources/img/sayso/logos/google-tag-manager.png'"
            alt="Google Tag Manager"
          />
        </div>
      </mat-card-content>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list>

<ng-container *ngTemplateOutlet="appBackButton"></ng-container>

} @else {
<onsip-installation-instructions [selectedCMS]="selectedCMS" (closeEvent)="toggleInstructions()">
</onsip-installation-instructions>
} } @else {
<h2>{{ "ONSIP_I18N.ALMOST_THERE" | translate }}</h2>
<h4>
  {{
    "ONSIP_I18N.PLEASE_MOVE_TO_DESKTOP_OR_A_LARGER_SCREEN_TO_FINISH_THE_INSTALLATION" | translate
  }}
</h4>
<ng-container *ngTemplateOutlet="appBackButton"></ng-container>
}

<ng-template #appBackButton>
  <div class="back-button">
    <button
      id="onsip--sayso-installation--close-guide"
      mat-raised-button
      color="primary"
      [routerLink]="views.SAYSO_SETTINGS"
    >
      {{ "ONSIP_I18N.BACK" | translate }}
    </button>
  </div>
</ng-template>
