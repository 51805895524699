import { Injectable, NgZone } from "@angular/core";
import { Observable, Subscription, Subject, combineLatest } from "rxjs";
import {
  map,
  filter,
  take,
  pluck,
  distinctUntilChanged,
  skip,
  switchMapTo,
  skipWhile
} from "rxjs/operators";
import { parsePhoneNumber } from "awesome-phonenumber";
import {
  UserAgentOptions,
  RegistererOptions,
  SessionDescriptionHandlerFactory,
  URI,
  Grammar,
  Web
} from "sip.js";

import { StateEmitter } from "../libraries/emitter/state-emitter";

import { CallController } from "../libraries/sip/call-controller";
import { MonitoredCallController } from "../libraries/sip/monitored-call-controller";
import { CallControllerEvent } from "../libraries/sip/call-controller-event";

import { Call } from "../libraries/sip/call";
import { CallEvent, isConnectedCallEvent } from "../libraries/sip/call-event";
import { CallState } from "../libraries/sip/call-state";
import { CallGroupState } from "../libraries/sip/call-group-state";
import { CallContact } from "../libraries/sip/call-contact";
import { CallConfiguration } from "../libraries/sip/call-configuration";

import { MonitoredCall } from "../libraries/sip/monitored-call";
import { MonitoredCallState } from "../libraries/sip/monitored-call-state";
import { MonitoredCallEvent } from "../libraries/sip/monitored-call-event";
import { MonitoredCallStrategy } from "../libraries/sip/monitored-call-strategy";

import { UserAgent, UserAgentState, UserAgentDelegate } from "../libraries/sip/user-agent";
import { UserAgentEvent, DisconnectedUserAgentEvent } from "../libraries/sip/user-agent-event";
import { Session } from "../libraries/sip/session";
import { SessionSIP } from "../libraries/sip/session-sip";
import { Config } from "../config";

import { LogService } from "./logging/log.service";
import { IdentityService, CallControllerConstructor } from "./identity.service";
import { UserAgentSIP } from "../libraries/sip";
import { SessionDescriptionHandlerSIP } from "../libraries/sip/session-description-handler-sip";
import { NetworkService } from "./network.service";

const debug = false;

export interface CallControllerServiceState {
  /** All calls. */
  calls: Array<CallState>;

  incoming: Array<CallState>;

  groups: Array<CallGroupState<CallState>>;

  userAgents: Array<UserAgentState>;

  defaultIsRegistered: boolean;
}

@Injectable({ providedIn: "root" })
export class CallControllerService extends StateEmitter<CallControllerServiceState> {
  // defaults
  // mobile only
  static readonly MAXIMUM_CALL_GROUPS = 2; // Interface currently has no way of dealing with more than 2 call groups
  static readonly MAXIMUM_CALLS_PER_CALL_GROUP = 1; // TODO: Merge & Split are ready to go short of...

  private callController: CallController | MonitoredCallController;
  private callControllerCtor: CallControllerConstructor | undefined;
  private callControllerStateSubscription: Subscription | undefined;
  private callControllerUserAgentEventSubscription: Subscription | undefined;
  private callControllerCallEventSubscription: Subscription | undefined;
  private callControllerEventSubscription: Subscription | undefined;

  private userAgentSubscriptions: Record<string, Subscription> = {};

  private callEventSubject = new Subject<CallEvent>();
  private callEventObservable: Observable<CallEvent>;

  private callControllerEventSubject = new Subject<CallControllerEvent>();
  private callControllerEventObservable: Observable<CallControllerEvent>;

  private userAgentEventSubject = new Subject<UserAgentEvent>();
  private userAgentEventObservable: Observable<UserAgentEvent>;

  private outboundAor: string | undefined;
  private defaultAor: string | undefined;
  private wasOnline = false;

  constructor(
    private log: LogService,
    protected identity: IdentityService,
    private network: NetworkService,
    private ngZone: NgZone
  ) {
    super({
      calls: Array<CallState>(),
      incoming: Array<CallState>(),
      groups: Array<CallGroupState<CallState>>(),
      userAgents: Array<UserAgentState>(),
      defaultIsRegistered: false
    });
    combineLatest([this.identity.state, this.network.state]).subscribe(
      ([identityState, networkState]) => {
        if (this.stateStore.userAgents.length > 0 && !this.wasOnline && networkState.online) {
          this.callController.reconnectUserAgents();
        }
        this.wasOnline = networkState.online;
        this.outboundAor = identityState.outboundIdentity
          ? identityState.outboundIdentity.aor
          : undefined;
        this.defaultAor = identityState.defaultIdentity
          ? identityState.defaultIdentity.aor
          : undefined;

        const defaultUA = this.defaultAor ? this.getUserAgent(this.defaultAor) : undefined;
        this.stateStore.defaultIsRegistered = defaultUA ? defaultUA.registered : false;

        this.publishState();
      }
    );

    this.callEventObservable = this.callEventSubject.asObservable();
    this.userAgentEventObservable = this.userAgentEventSubject.asObservable();
    this.callControllerEventObservable = this.callControllerEventSubject.asObservable();

    this.callControllerCtor = this.getCallControllerConstructor();
    this.callController = new this.callControllerCtor();
    this.subscribeCallController();

    this.subscribeUserAgentReconnect(); // All automatic transport reconnect handled here
  }

  getCallEventObservable(): Observable<CallEvent> {
    return this.callEventObservable;
  }

  /** Gets observable to dnd state: defined by all userAgents unregistered
   *  @returns Observable where true = dnd on; false = dnd off;
   */
  getDoNotDisturbObservable(): Observable<boolean> {
    return this.state.pipe(
      map(
        state =>
          !state.userAgents.map(ua => ua.shouldBeRegistered).reduce((acc, val) => acc || val, false)
      ),
      distinctUntilChanged() // avoid extra values from whenever other things in call controller change
    );
  }

  getUserAgentEventObservable(): Observable<UserAgentEvent> {
    return this.userAgentEventObservable;
  }

  getCallControllerEventObservable(): Observable<CallControllerEvent> {
    return this.callControllerEventObservable;
  }

  getCallStateByUuid(uuid: string): CallState | undefined {
    const call = this.callController.calls.get(uuid);
    return call ? call.stateValue : undefined;
  }

  getCallStateByCallId(callId: string): CallState | undefined {
    // TYPING NOTICE: typing does not like find using Array<type1> | Array<type2>, so switch to Array<type1 | type2> to not lose info
    const callArray = this.callController.calls.array as ReadonlyArray<
      Call<CallEvent, CallState> | Call<MonitoredCallEvent, MonitoredCallState>
    >;
    const retCall = callArray.find(
      call =>
        !call.init &&
        call.session instanceof SessionSIP &&
        call.session.SIP &&
        call.session.SIP.request &&
        call.session.SIP.request.callId === callId
    );

    return retCall ? retCall.stateValue : undefined;
  }

  getGroupStateFromCall(uuid: string): CallGroupState<CallState> | undefined {
    return this.stateStore.groups.find(group => !!group.calls.find(call => call.uuid === uuid));
  }

  getUnheldCall(): CallState | undefined {
    // see typing notice above
    const callArray = this.callController.calls.array as ReadonlyArray<
      Call<CallEvent, CallState> | Call<MonitoredCallEvent, MonitoredCallState>
    >;
    const retCall = callArray.find(call => call.connected && !call.hold && !call.holdInProgress);

    return retCall ? retCall.stateValue : undefined;
  }

  // this is used in web/desktop, not mobile (for now)
  makeUnheldCall(uuid: string | undefined, conferenceUuid?: string | undefined): void {
    const call: CallState | undefined = this.getCallStateByUuid(uuid || "");

    if (uuid && call) {
      this.holdAllOtherCalls(uuid, conferenceUuid);

      if (call.hold) {
        this.unholdCall(uuid);
      }

      if (conferenceUuid) {
        this.unholdCall(conferenceUuid);
      }
    } else {
      this.holdAllOtherCalls("");
    }
  }

  getCallObservableFilteredByConnected(
    uuid: string
  ): Observable<CallEvent> | Observable<CallEvent | MonitoredCallEvent> | undefined {
    // see typing notice above
    const call = this.callController.calls.get(uuid) as Call<
      CallEvent | MonitoredCallEvent,
      CallState | MonitoredCallState
    >;

    return call ? call.event.pipe(filter(isConnectedCallEvent), take(1)) : undefined;
  }

  getCallObservableOnVideoAvailableChange(uuid: string): Observable<boolean> | undefined {
    const call = this.callController.calls.get(uuid) as Call<
      CallEvent | MonitoredCallEvent,
      CallState | MonitoredCallState
    >;

    return call ? call.state.pipe(pluck("videoAvailable"), distinctUntilChanged()) : undefined;
  }

  getCallId(uuid: string): string | undefined {
    const call = this.callController.calls.get(uuid);

    return call && call.session instanceof SessionSIP ? call.session.SIP.request.callId : undefined;
  }

  hasUserAgent(aor: string): boolean {
    return this.callController ? !!this.callController.userAgents.get(aor) : false;
  }

  getOutboundUserAgent(): UserAgent | undefined {
    return this.outboundAor ? this.callController.userAgents.get(this.outboundAor) : undefined;
  }

  makeAndAddUserAgent(
    options: Partial<UserAgentOptions>,
    aor: string | undefined, // anonymous user agents do not pass an aor
    registerOptions: RegistererOptions,
    delegate: Partial<UserAgentDelegate> | undefined,
    sdhFactory: SessionDescriptionHandlerFactory | undefined,
    addToCallController: boolean = true
  ): UserAgent {
    let uri;
    // no aor passed will be anonymous (done in user-agent.ts)
    if (aor) {
      uri = Grammar.URIParse("sip:" + aor);
      if (!uri) {
        throw new Error("URI undefined.");
      }
    }

    const userAgent = new UserAgentSIP(
      {
        uri,
        authorizationPassword: options.authorizationPassword,
        authorizationUsername: options.authorizationUsername,
        displayName: options.displayName,
        noAnswerTimeout: options.noAnswerTimeout,
        transportOptions: {
          server:
            (options.transportOptions &&
              (options.transportOptions as Web.TransportOptions).server) ||
            Config.WS_SERVER,
          traceSip: true
        },
        userAgentString: options.userAgentString,
        logConfiguration: options.logConfiguration
      },
      registerOptions,
      {
        handleIncomingSession: session => {
          if (delegate && delegate.handleIncomingSession) {
            delegate.handleIncomingSession(session);
          } else {
            this.callController.handleIncomingSession(session);
          }
        },
        handleIncomingMessage: message => {
          if (delegate && delegate.handleIncomingMessage) {
            delegate.handleIncomingMessage(message);
          } else {
            this.callController.handleIncomingMessage(message);
          }
        }
      },
      sdhFactory
    );

    if (addToCallController) {
      this.callController.addUserAgent(userAgent);

      this.subscribeUserAgent(userAgent);

      userAgent
        .start()
        .then(() => {
          // anonymous user agents don't register
          if (userAgent.aor.indexOf("anonymous.invalid") === -1 && userAgent.shouldBeRegistered) {
            userAgent.register();
          }
        })
        .catch(error => {
          console.error("CallControllerService.makeAndAddUserAgent failed to start " + error);
        });
    }

    return userAgent;
  }

  isUserAgentSIPInstance(ua: UserAgent): ua is UserAgentSIP {
    return ua instanceof UserAgentSIP;
  }

  switchUserAgentRegisterState(aor: string, switchTo: boolean): void {
    const ua = this.getUserAgent(aor);
    if (!ua) {
      throw new Error("Attempted to switch registration state of aor with no ua: " + aor);
    }
    ua.shouldBeRegistered = switchTo;
  }

  sendMessage(target: string, body: string, contentType: string): void {
    const ua: UserAgent | undefined = this.outboundAor
        ? this.getUserAgent(this.outboundAor)
        : undefined,
      uri: URI | undefined = ua ? ua.targetToURI(target) : undefined;

    if (ua && uri) {
      ua.message(uri, body, contentType);
    }
  }

  // HACK for desktop notifications on refer, callAudioService and callVideoService to get SessionDescriptionHandler
  // PLEASE do not use elsewhere
  findSession(uuid: string): Session | undefined {
    const call = this.callController.calls.get(uuid);

    return call ? call.session : undefined;
  }

  // HACK for callAudioService and callVideoService to get SessionDescriptionHandler
  // PLEASE do not use elsewhere
  findSessionDescriptionHandler(uuid: string): SessionDescriptionHandlerSIP | undefined {
    const call = this.callController.calls.get(uuid),
      session = call ? call.session : undefined;

    return session && session instanceof SessionSIP ? session.sessionDescriptionHandler : undefined;
  }

  monitoringSetPollingInterval(uuid: string, interval: number): void {
    const call = this.callController.calls.get(uuid);
    if (call instanceof MonitoredCall) {
      call.pollingInterval = interval;
    }
  }

  monitoringSetStrategies(
    uuid: string,
    strategies: Array<MonitoredCallStrategy<MonitoredCallEvent, MonitoredCallState>>
  ): void {
    const call = this.callController.calls.get(uuid);
    if (call instanceof MonitoredCall) {
      strategies.forEach(strategy => call.addStrategy(strategy));
    }
  }

  // used in mobile for audioController
  // PLEASE do not use elsewhere
  getCallController():
    | CallController<CallEvent, CallState>
    | MonitoredCallController<MonitoredCallEvent, MonitoredCallState> {
    return this.callController;
  }

  beginCall(callContact: CallContact, callConfig: CallConfiguration): Promise<string> {
    let aorToUse: string | undefined = this.outboundAor;
    if (!aorToUse) {
      const userAgent = this.callController.userAgents.array.find(ua => ua.connected);
      if (!userAgent) {
        return Promise.reject(new Error("callControllerService: no outbound aor set"));
      }
      aorToUse = userAgent.aor;
    }
    return this.callController.begin(aorToUse, callContact, callConfig).then(uuid => {
      this.identity.restoreOutboundIdentity();
      return uuid;
    });
  }

  // mobile does not pass constraints
  acceptCall(uuid: string, constraints?: CallConfiguration): Promise<void> {
    if (constraints) {
      return Promise.all([
        this.setCallAudio(uuid, true),
        this.setCallVideo(uuid, constraints.video)
      ]).then(() => {
        return this.callController.accept(uuid, constraints);
      });
    } else {
      return this.callController.accept(uuid);
    }
  }

  callProgressTracking(uuid: string): Promise<void> {
    return this.callController.callProgressTracking(uuid);
  }

  declineCall(uuid: string): Promise<void> {
    return this.callController.decline(uuid);
  }

  endCall(uuid: string): Promise<void> {
    return this.callController.end(uuid);
  }

  endGroup(uuid: string): Promise<void> {
    return this.callController.endGroup(uuid);
  }

  holdCall(uuid: string): Promise<void> {
    const call: CallState | undefined = this.getCallStateByUuid(uuid);

    if (call && call.connected && !call.hold) {
      return this.callController.hold(uuid, true);
    } else {
      return Promise.resolve();
    }
  }

  // NOTE: this is used in web/desktop, not mobile
  holdAllOtherCalls(uuid?: string, partnerUuid?: string): void {
    const callArray = this.callController.calls.array as ReadonlyArray<
      Call<CallEvent, CallState> | Call<MonitoredCallEvent, MonitoredCallState>
    >;
    callArray.forEach(call => {
      if (!uuid || (call.uuid !== uuid && call.uuid !== partnerUuid)) {
        this.holdCall(call.uuid);
      }
    });
  }

  holdGroup(uuid: string, toggle: boolean = true): Promise<void> {
    return this.callController.holdGroup(uuid, toggle);
  }

  unholdCall(uuid: string): Promise<void> {
    return this.callController.hold(uuid, false);
  }

  playDTMF(uuid: string, tone: string): Promise<void> {
    return this.callController.playDTMF(uuid, tone);
  }

  playDTMFGroup(uuid: string, tone: string): Promise<void> {
    return this.callController.playDTMFGroup(uuid, tone);
  }

  muteGroup(uuid: string, toggle: boolean): Promise<void> {
    return this.callController.muteGroup(uuid, toggle);
  }

  videoGroup(uuid: string, toggle: boolean): Promise<void> {
    return this.callController.videoGroup(uuid, toggle);
  }

  blindTransfer(uuid: string, target: string): Promise<void> {
    const call = this.callController.calls.get(uuid);
    if (call?.maxCallTime !== undefined) {
      console.warn("FreeTrialCalls Cannot be Transfered");
      return Promise.reject();
    }
    // Mark this call as blind transferring to prevent unwanted error snackbar showing up
    call && call.setBlindTransferInProgress(true);
    // In the web and desktop apps, we always hold the call before blind transfering
    this.holdCall(uuid);
    return this.callController.blindTransfer(uuid, this.parsePSTNString(target));
  }

  blindTransferGroup(uuid: string, target: string): Promise<void> {
    return this.callController.blindTransferGroup(uuid, target);
  }

  attendedTransfer(transferer: string, transferee: string): Promise<void> {
    return this.callController.attendedTransfer(transferer, transferee);
  }

  groupCalls(uuid: string, existingUuid: string): Promise<void> {
    return this.callController.groupWith(uuid, existingUuid);
  }

  setCallAudio(uuid: string, toggle: boolean): void {
    this.callController.audio(uuid, toggle);
  }

  setCallVideo(uuid: string, toggle: boolean): void {
    this.callController.video(uuid, toggle);
  }

  toggleRemoteAudio(uuid: string, toggle: boolean): void {
    const call = this.callController.calls.get(uuid);

    if (call && call.session instanceof SessionSIP) {
      if (!call.session.sessionDescriptionHandler) {
        throw new Error("sessionDescriptionHandler undefined.");
      }
      call.session.sessionDescriptionHandler.enableRemoteAudio(toggle);
    }
  }

  // utility function with no good place
  parsePSTNString(target: string): string {
    target = target.trim();

    if (
      target.match(/[^\d*#+-/(/\s)]/) /* clearly not a phone number */ ||
      target.match(/(^\+?011)|(^\+?00)/) /* international dial-out, don't try to parse */
    ) {
      this.log.debug("target = '" + target + "'");
      return target;
    } else {
      const phoneNumber = parsePhoneNumber(target.replace(/[^\d*#+]/g, ""), { regionCode: "US" });
      this.log.debug("DialComponent.getDialTarget phoneNumber = " + JSON.stringify(phoneNumber));
      if (phoneNumber.valid) {
        return phoneNumber.number.e164;
      } else {
        return target;
      }
    }
  }

  setCallController(callController: CallController | MonitoredCallController) {
    this.callController = callController;
    this.subscribeCallController();

    this.callController.userAgents.array.forEach(userAgent => this.subscribeUserAgent(userAgent));
    this.publishState();
  }

  /** Sets DND: defined as all user agents unregistered
   *  @param dnd true = turn on dnd, unregister all ua's; false = turn off dnd, register all ua's;
   */
  setDoNotDisturb(dnd: boolean): void {
    this.callController.userAgents.array.forEach(ua => {
      ua.shouldBeRegistered = !dnd; // actual unregistering done by this.subscribeUserAgent
    });
  }

  /**
   * Sets a maximum time for call.
   * @param maxCallTime Maximum call time in milliseconds, terminate this amt of time after connectedAt
   */
  maxCallTime(uuid: string, maxCallTime: number): void {
    this.callController.maxCallTime(uuid, maxCallTime);
  }

  reset(): void {
    debug && this.log.debug("CallController Service Resetting...");

    Object.keys(this.userAgentSubscriptions).forEach(aor => {
      const sub = this.userAgentSubscriptions[aor];
      if (sub) {
        sub.unsubscribe();
        delete this.userAgentSubscriptions[aor];
      }
    });

    this.unsubscribeCallController();
    if (this.callController) {
      this.callController.dispose();
    }

    if (this.callControllerCtor) {
      this.callController = new this.callControllerCtor();
    } else {
      throw new Error(
        "attempting to reset callControllerService before init'ing, please call init with constructor/config first."
      );
    }

    this.subscribeCallController();
    debug && this.log.debug("CallController Service Reset");
  }

  disposeCallController(): void {
    if (this.callController) {
      this.callController.dispose();
    }
  }

  cleanCallController(): boolean {
    if (!this.callController) {
      return false;
    }
    this.callController.disposeCalls();
    this.callController.disposeUserAgents();
    Object.keys(this.userAgentSubscriptions).forEach(aor => {
      const sub = this.userAgentSubscriptions[aor];
      if (sub) {
        sub.unsubscribe();
        delete this.userAgentSubscriptions[aor];
      }
    });
    return true;
  }

  // Mobile: If we logout and that fails to unregister then we need to keep track of those
  // useragents to unregister on the subsequent push if possible.
  disposeInvalidUserAgents(): void {
    this.callController.disposeInvalidUserAgents();
  }

  // Mobile: If our websocket disconnects we need to dispose of any outstanding calls
  // if any. This should deal with the endless ringing. We don't want to wipe out the
  // UA because mobile reconnects the transport + registers if needed
  cleanCalls(): void {
    if (!this.callController) {
      return;
    }
    this.callController.disposeCalls();
  }

  reconnectAllUserAgents(): Promise<Array<void>> {
    if (!this.callController) {
      return Promise.reject();
    }
    const reconnectPromises: Array<Promise<void>> = [];
    this.callController.userAgents.array.forEach(userAgent =>
      reconnectPromises.push(this.reconnectUserAgent(userAgent))
    );

    return Promise.all(reconnectPromises);
  }

  /** overridden by child services */
  protected getCallControllerConstructor(): CallControllerConstructor {
    debug && this.log.debug("CallControllerService: getCallControllerConstructor");
    // web
    return CallController.bind(undefined, {
      maximumCallGroups: undefined,
      maximumCallsPerCallGroup: undefined
    });
  }

  protected publishRun(fn: () => any): any {
    return this.ngZone.run(() => {
      return fn();
    });
  }

  private getUserAgent(aor: string): UserAgent | undefined {
    return this.callController ? this.callController.userAgents.get(aor) : undefined;
  }

  /** This is the only place we automattically reconnect the main user agent,  */
  private subscribeUserAgentReconnect() {
    // RECONNECT CASE 1: Offline -> Online
    this.network.state
      .pipe(
        distinctUntilChanged(
          (prev, curr) =>
            prev.online === curr.online &&
            prev.suspended === curr.suspended &&
            prev.connectivity === curr.connectivity
        ),
        filter(
          identityState =>
            identityState.online &&
            identityState.connectivity !== "none" &&
            identityState.connectivity !== "unknown"
        ),
        skip(1) // Ignore first time we sign in
      )
      .subscribe(identityState => {
        debug &&
          console.log(identityState.online, identityState.connectivity, identityState.suspended);
        this.reconnectAllUserAgents();
      });

    // RECONNECT CASE 2: Random Websocket Disconnect e.g. 1006
    this.userAgentEventObservable
      .pipe(
        filter(e => e.id === DisconnectedUserAgentEvent.id),
        switchMapTo(this.network.state)
      )
      .subscribe(networkState => {
        // No point in attempting reconnect when offline
        if (networkState.online) {
          this.reconnectAllUserAgents();
        }
      });
  }

  // We manually reconnect the useragent when the UA is in an invalid state
  private subscribeUserAgent(userAgent: UserAgent) {
    this.userAgentSubscriptions[userAgent.aor] = userAgent.state
      .pipe(
        // if !dnd wait until registered, else wait until connected for subscription to fire, avoid extra register(s)
        skipWhile(state => !state.connected || (state.shouldBeRegistered && !state.registered)),
        distinctUntilChanged((prev, curr) => prev.shouldBeRegistered === curr.shouldBeRegistered),
        filter(userAgentState => userAgentState.registered !== userAgentState.shouldBeRegistered)
      )
      .subscribe(userAgentState => {
        debug && console.log(userAgentState);
        if (userAgentState.stopped) {
          return; // we are dealing with connecting and registering internally
        }
        // DND register / unregister occurs here
        if (userAgentState.shouldBeRegistered && !userAgent.registered) {
          Promise.resolve().then(() => this.reconnectUserAgent(userAgent));
        } else if (!userAgentState.shouldBeRegistered && userAgent.registered) {
          Promise.resolve().then(() => userAgent.unregister());
        }
      });
  }

  private reconnectUserAgent(userAgent: UserAgent): Promise<void> {
    if (userAgent.registered) {
      return Promise.resolve();
    }
    // It is now safe to call connect multiple times as it will just resolve if connected
    // or return if its in the process of connecting/disconnecting etc
    // Should we reject if we get a connectionTimeout Error?
    return userAgent
      .reconnect()
      .then(() => {
        if (userAgent.shouldBeRegistered) {
          userAgent.register();
        }
      })
      .catch(error => {
        this.log.error("CallControllerService.UserAgentReconnect: has error: " + error);
      });
  }

  private unsubscribeCallController(): void {
    if (this.callControllerUserAgentEventSubscription) {
      this.callControllerUserAgentEventSubscription.unsubscribe();
      this.callControllerUserAgentEventSubscription = undefined;
    }
    if (this.callControllerStateSubscription) {
      this.callControllerStateSubscription.unsubscribe();
      this.callControllerStateSubscription = undefined;
    }
    if (this.callControllerCallEventSubscription) {
      this.callControllerCallEventSubscription.unsubscribe();
      this.callControllerCallEventSubscription = undefined;
    }
    if (this.callControllerEventSubscription) {
      this.callControllerEventSubscription.unsubscribe();
      this.callControllerEventSubscription = undefined;
    }
  }

  private subscribeCallController(): void {
    if (!this.callController) {
      this.log.error("CallControllerService: no call controller to subscribe to");
      return;
    }
    this.callControllerStateSubscription = this.callController.state.subscribe(state => {
      this.stateStore.calls = state.calls;
      this.stateStore.incoming = state.incoming;
      this.stateStore.groups = state.groups;
      this.stateStore.userAgents = state.userAgents;
      const defaultUA = this.defaultAor ? this.getUserAgent(this.defaultAor) : undefined;
      this.stateStore.defaultIsRegistered = defaultUA ? defaultUA.registered : false;
      this.publishState();
    });

    this.callControllerEventSubscription = this.callController.event.subscribe(event => {
      this.callControllerEventSubject.next(event);
    });

    this.callControllerCallEventSubscription = this.callController.calls.event.subscribe(event => {
      this.callEventSubject.next(event);
    });

    this.callControllerUserAgentEventSubscription = this.callController.userAgents.event.subscribe(
      event => {
        this.userAgentEventSubject.next(event);
      }
    );
  }
}
