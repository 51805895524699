import { FormatBHRTimePipe } from "./format-bhr-time.pipe";
import { FormatDurationPipe } from "./format-duration.pipe";
import { FormatDateTimePipe } from "./format-date-time.pipe";
import { FormatLengthPipe } from "./format-length.pipe";
import { FormatPSTNPipe } from "./format-pstn.pipe";
import { SentenceCasePipe } from "./sentence-case.pipe";
import { UnderscoresToSpacesPipe } from "./underscores-to-spaces.pipe";
import { FirstNamePipe } from "./first-name.pipe";
import { InitialsPipe } from "./initials.pipe";
import { FormatExtensionsPipe } from "./format-extensions.pipe";
import { FormatE164Pipe } from "./format-e164.pipe";
import { FormatMacAddressPipe } from "./format-mac-address.pipe";
import { FormatSingularAddressTypePipe } from "./format-singular-address-type.pipe";
import { FormatPluralAddressTypePipe } from "./format-plural-address-type.pipe";

import { NgModule } from "@angular/core";
import { InvokeFunctionPipe } from "./invoke-function.pipe";
import { TextColorForCustomBackground } from "./text-color-for-custom-background.pipe";
import { TextColorForWhiteBackground } from "./text-color-for-white-background.pipe";
import { SafeHtmlPipe } from "./safe-html.pipe";
import { FormatStrPipe } from "./format-str.pipe";
import { FormatTimezonePipe } from "./timezone.pipe";

@NgModule({
  declarations: [
    FormatBHRTimePipe,
    FormatDurationPipe,
    FormatDateTimePipe,
    FormatLengthPipe,
    FormatPSTNPipe,
    UnderscoresToSpacesPipe,
    FirstNamePipe,
    SentenceCasePipe,
    InitialsPipe,
    InvokeFunctionPipe,
    FormatExtensionsPipe,
    FormatE164Pipe,
    FormatMacAddressPipe,
    FormatSingularAddressTypePipe,
    FormatPluralAddressTypePipe,
    SafeHtmlPipe,
    TextColorForCustomBackground,
    TextColorForWhiteBackground,
    FormatStrPipe,
    FormatTimezonePipe
  ],
  exports: [
    FormatBHRTimePipe,
    FormatDurationPipe,
    FormatDateTimePipe,
    FormatLengthPipe,
    FormatPSTNPipe,
    UnderscoresToSpacesPipe,
    FirstNamePipe,
    SentenceCasePipe,
    InitialsPipe,
    InvokeFunctionPipe,
    FormatExtensionsPipe,
    FormatE164Pipe,
    FormatMacAddressPipe,
    FormatSingularAddressTypePipe,
    FormatPluralAddressTypePipe,
    SafeHtmlPipe,
    TextColorForCustomBackground,
    TextColorForWhiteBackground,
    FormatStrPipe,
    FormatTimezonePipe
  ]
})
export class PipesModule {}
