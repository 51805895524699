import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  ViewChild
} from "@angular/core";
import { HelpAddSupportCaseService } from "@onsip/common/services/api/resources/help-add-support-case.service";
import { SnackbarService } from "../../shared/components/snackbar/snackbar.service";
import { NgForm, NonNullableFormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "onsip-admin-support-submit-ticket",
  templateUrl: "./admin-support-submit-ticket.component.html",
  styleUrls: ["./admin-support-submit-ticket.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminSupportSubmitTicketComponent {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;
  @ViewChild("form") ngForm!: NgForm;
  @Input() showSubject = true;
  @Input() showHeader = true;

  issueTypes = [
    "Configuration",
    "Technical Support",
    "Feature Request",
    "Billing",
    "Payment Options",
    "Other"
  ];

  submitTicketForm = this.fb.group({
    issueType: ["Configuration"],
    subject: [""],
    details: [""]
  });

  constructor(
    private helpAddSupportCaseService: HelpAddSupportCaseService,
    private snackbar: SnackbarService,
    private fb: NonNullableFormBuilder,
    private cdRef: ChangeDetectorRef
  ) {}

  clearForm(): void {
    this.ngForm.resetForm({
      issueType: "",
      subject: "",
      details: ""
    });
  }

  /** if a user clicks on description text field, clear any details error */
  handleDetailFocus(): void {
    // eslint-disable-next-line no-null/no-null
    this.submitTicketForm.controls.details.setErrors(null);
  }

  submit(): void {
    // the description field is required if the user submits a ticket
    if (!this.submitTicketForm.controls.details.value) {
      this.submitTicketForm.controls.details.setErrors(Validators.required);
      return;
    }

    const params = {
      Description: this.submitTicketForm.controls.details.value,
      Subject: this.showSubject
        ? this.submitTicketForm.controls.subject.value
        : "Admin Portal Ticket Created",
      Reason:
        this.submitTicketForm.controls.issueType.value === "Configuration"
          ? "Initial Setup"
          : this.submitTicketForm.controls.issueType.value,
      Type:
        this.submitTicketForm.controls.issueType.value === "Configuration"
          ? "Technical Support"
          : this.submitTicketForm.controls.issueType.value
    };
    this.helpAddSupportCaseService.HelpAddSupportCase(params).then(result => {
      if (result.status === "success") {
        this.snackbar.openSnackBar("Your ticket has been successfully submitted.", "success");
        this.clearForm();
      } else {
        this.snackbar.openSnackBar(result.data.message, "error");
        this.cdRef.markForCheck();
      }
    });
  }
}
