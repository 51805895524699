export declare interface ApiContact {
  Address: string;
  City: string;
  Country: string;
  CountryId: string;
  Email: string;
  Modified: string;
  Name: string;
  Organization: string;
  Phone: string;
  State: string;
  Zipcode: string;
}

export declare interface Contact {
  address: string;
  city: string;
  country: string;
  countryId: string;
  email: string;
  name: string;
  organization: string;
  phone: string;
  state: string;
  zipcode: string;
}

export function apiContactToContact(apiContact: ApiContact): Contact {
  return {
    address: apiContact.Address,
    city: apiContact.City,
    country: apiContact.Country,
    countryId: apiContact.CountryId,
    email: apiContact.Email,
    name: apiContact.Name,
    organization: apiContact.Organization,
    phone: apiContact.Phone,
    state: apiContact.State,
    zipcode: apiContact.Zipcode
  };
}
