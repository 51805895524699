import { Component, ChangeDetectionStrategy, Optional, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { BehaviorSubject } from "rxjs";

@Component({
  templateUrl: "./loading-modal.component.html",
  styleUrls: ["./loading-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingModalComponent {
  modalTitle = new BehaviorSubject("Saving your changes...");

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: { modalTitle: string }) {
    if (this.data) this.modalTitle.next(this.data.modalTitle);
  }
}
// This is a simple boring component
