import { Injectable } from "@angular/core";

import { StateEmitter } from "../../../../../common/libraries/emitter";

export interface RealtimeAgentTableState {
  otherActiveEavesdropState: boolean;
}

@Injectable({ providedIn: "root" })
export class RealtimeAgentTableService extends StateEmitter<RealtimeAgentTableState> {
  constructor() {
    super({
      otherActiveEavesdropState: false
    });
  }

  getOtherActiveEavesdropState(): RealtimeAgentTableState["otherActiveEavesdropState"] {
    return this.stateStore.otherActiveEavesdropState;
  }

  setActiveEavesdrop(): void {
    this.stateStore.otherActiveEavesdropState = true;
    this.publishState();
  }

  permitEavesdrop(): void {
    this.stateStore.otherActiveEavesdropState = false;
    this.publishState();
  }
}
