import { Injectable } from "@angular/core";

import { filter, take, map } from "rxjs/operators";

import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";
import { Contact } from "../../../../../common/interfaces/contact";
import { CallState } from "../../../../../common/libraries/sip/call-state";
import { Tracker } from "../../../../../common/libraries/analytics/tracker";
import { AppEventData } from "../../../../../common/interfaces/analytics";
import { IdentityService } from "../../../../../common/modules/core";
import { UserService, User } from "../../../../../common/services/api/resources/user/user.service";
import { AccountDetailsService } from "../../../../../common/services/api/resources/accountDetails/account-details.service";
import { Config } from "../../../../../common/config";
import { lastValueFrom } from "rxjs";

declare let window: any;

@Injectable({ providedIn: "root" })
export class AnalyticsService {
  private analyticsStarted: Promise<void>;
  private user!: User;
  private tracker: Tracker;
  private userAddress!: UserAddress;
  private freePlanUser = "false";

  constructor(
    private accountDetails: AccountDetailsService,
    private identity: IdentityService,
    private userService: UserService
  ) {
    // snowplow analytics initialization
    this.tracker = new Tracker("onsipApp", {
      schemas: [
        {
          name: "app",
          schema: "https://app.onsip.com/analytics/AppSchema-analytics-v1.0.6.json",
          collector: "fivetran"
        },
        {
          name: "app",
          schema: "iglu:com.onsip/app/jsonschema/1-0-1",
          collector: "snowcat"
        }
      ]
    });

    this.analyticsStarted = new Promise(resolve => {
      Promise.all([
        lastValueFrom(
          this.identity.state.pipe(
            filter(state => state.addresses.length > 0),
            map(state => state.addresses),
            take(1)
          )
        ),
        lastValueFrom(this.userService.selfUser.pipe(take(1))),
        lastValueFrom(
          this.accountDetails.state.pipe(
            filter(({ loading }) => !loading),
            map(state => Object.values(state.state)[0]),
            take(1)
          )
        )
      ]).then(results => {
        const userAddresses: Array<UserAddress> = results[0] as Array<UserAddress>,
          user = results[1],
          accountDetails = results[2],
          freePlanUser: boolean =
            accountDetails.noPrepaidCredit &&
            !accountDetails.perUserPricing &&
            accountDetails.primaryPackages.length === 0;

        if (userAddresses.length === 0) {
          console.error("User has no user addresses, trunking/older account, returning");
          return;
        }

        // for use in snowplow analytics calls
        // TODO: OAuth, Oauth Service, Platform, Version must be added to schema
        this.user = user;
        // Pulls the primary userAddress off of the userAddresses array.
        this.userAddress = userAddresses[0];
        this.freePlanUser = freePlanUser ? "true" : "false";

        // Set User ID etc in snowplow so they are included in events fired this session
        this.tracker.setUserId(parseInt(user.userId));
        resolve();

        window.addEventListener("unload", () => {
          // Logout events are historically unreliable and may not work on desktop consistently.
          this.trackSnowplowAppEvent("Logout");
        });

        // delaying sending login event till getThirdPartySignInInfo can return
        setTimeout(() => {
          this.sendEvent("Login", {});
        }, 500);
      });
    });
  }

  sendCallEvent(action: string, call?: CallState, props: Record<string, any> = {}): void {
    props.action = props.action || action;
    props.video = props.video || (call && call.videoAvailable) || false;
    props.conference = props.conference || false;
    // We add call to the event to keep all snowplow logic in the same place
    props.call = call;
    this.sendEvent("Call", props);
  }

  sendQueueEvent(
    action: string,
    agentUri: string,
    queueName: string,
    props: Record<string, any> = {}
  ): void {
    props.action = props.action || action;
    props.agentName = props.agentName || agentUri;
    props.queueName = props.queueName || queueName;

    this.sendEvent("Queue", props);
  }

  sendContactEvent(
    action: string,
    contact: Contact | undefined,
    props: Record<string, any> = {}
  ): void {
    props.action = props.action || action;
    props.name = props.name || (contact && contact.name);
    props.type = props.type || (contact && contact.userId ? "Directory" : "Custom");

    this.sendEvent("Contact", props);
  }

  sendOnboardingEvent(action: string, props: Record<string, any> = {}): void {
    props.action = props.action || action;

    this.sendEvent("Onboarding", props);
  }

  sendLoginPageEvent(action: string, props: Record<string, any> = {}): void {
    props.action = props.action || action;

    this.sendEvent("LoginPage", props);
  }

  sendNavigationEvent(action: string, props: Record<string, any> = {}): void {
    props.action = props.action || action;

    this.sendEvent("Navigation", props);
  }

  sendMessageEvent(action: string, props: Record<string, any> = {}): void {
    props.action = props.action || action;
    props.chat = props.chat || "";
    props.provider = props.provider || "";

    this.sendEvent("Message", props);
  }

  sendDesktopEvent(action: string, props: Record<string, any> = {}): void {
    props.action = props.action || action;

    this.sendEvent("Desktop", props);
  }

  private sanitizeEventData(type: string, actionData?: Record<string, any>): AppEventData {
    if (!this.user) throw new Error("AnalyticsService.sanitizeEventData - user not defined");
    const eventData: AppEventData = {
      version: Config.VERSION_NUMBER,
      user_id: parseInt(this.user.userId),
      organization_id: parseInt(this.user.organizationId),
      account_id: parseInt(this.user.accountId),
      primary_username: this.userAddress.username,
      primary_domain: this.userAddress.domain,
      type,
      action: "",
      free_plan_user: typeof this.freePlanUser === "string" ? this.freePlanUser : undefined
    };
    eventData.type = type;
    if (actionData) {
      if (actionData.action) {
        eventData.action = actionData.action;
      }
      if (actionData.displayName) {
        eventData.caller_display_name = actionData.displayName;
      }
      if (actionData.conference !== undefined) {
        eventData.conference = actionData.conference;
      }
      if (actionData.conferenceSize !== undefined) {
        eventData.conference_size = actionData.conferenceSize;
      }
      if (actionData.call) {
        if (actionData.call.videoAvailable !== undefined) {
          eventData.video_available = actionData.call.videoAvailable;
        }
        if (actionData.call.audio !== undefined) {
          eventData.audio_call = actionData.call.audio;
        }
        if (actionData.call.video !== undefined) {
          eventData.video_call = actionData.call.video;
        }
        if (actionData.call.audioAvailable !== undefined) {
          eventData.audio_available = actionData.call.audioAvailable;
        }
        if (actionData.call.uuid !== undefined) {
          eventData.ouid = actionData.call.uuid;
        }
        if (actionData.call.aor !== undefined) {
          eventData.local_address = actionData.call.aor;
        }
        if (actionData.call.remoteUri !== undefined) {
          eventData.remote_address = actionData.call.remoteUri;
        }
      }
    }
    return eventData;
  }

  private trackSnowplowAppEvent(type: string, actionData?: Record<string, any>): void {
    // Please only pass arguments of type AppEventData into the tracker.
    const eventData: AppEventData = this.sanitizeEventData(type, actionData);
    this.tracker.trackEvent("app", eventData);
  }

  private sendEvent(action: string, properties: Record<string, any> = {}): void {
    this.analyticsStarted.then(() => {
      this.trackSnowplowAppEvent(action, properties);
    });
  }
}
