import { SubscriptionEvent, SubscriptionEventFactory } from "./subscription-event";

/**
 * Emitted once when Subscription ends.
 */
export class EndSubscriptionEvent {
  static make(aor: string, uuid: string): SubscriptionEvent {
    return SubscriptionEventFactory.make(EndSubscriptionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Subscription] end";
  }
}
