import { Injectable } from "@angular/core";
import { NotificationsService } from "../../shared/services/notifications/notifications.service";
import { filter, map, Observable, take, tap, combineLatest } from "rxjs";
import {
  CallControllerService,
  CallControllerServiceState
} from "@onsip/common/services/call-controller.service";
import { Checker } from "../warnings/checker/refreshWarnings";
import { UserAgent, UserAgentSIP } from "@onsip/common/libraries/sip";
import { Subscriber } from "sip.js";
import { AdvQueueWarningService } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning.service";
import { AdvQueueWarning } from "@onsip/common/services/api/resources/advQueueWarning/adv-queue-warning";

@Injectable({ providedIn: "root" })
export class QueueWarningsService {
  private thresholdCheckerObs$!: Observable<CallControllerServiceState>;
  private thresholdChecker!: Checker;
  private warningEmitter!: EventTarget;
  private warningSubscriptions: Array<Subscriber> = [];
  private areNotificationsEnabled = false;

  constructor(
    private notificationsService: NotificationsService,
    private callControllerService: CallControllerService,
    private advQueueWarningService: AdvQueueWarningService
  ) {
    combineLatest({
      notifications: this.notificationsService.state,
      warnings: this.advQueueWarningService.state.pipe(
        filter(state => !state.loading),
        map(state => Object.values(state.state))
      )
    }).subscribe(({ notifications, warnings }) => {
      this.areNotificationsEnabled = !!notifications.notifications["on queue alert"];

      if (this.areNotificationsEnabled) {
        this.toggleQueueWarnings(true, warnings);
      } else {
        this.toggleQueueWarnings(false, warnings);
      }
    });

    /**
     * Create the emitter that the adv queue warnings threshold notifications module
     * uses to signal updates to threshold check states.
     * You're going to want to pass this into the threshold module.
     *
     * Events:
     *   'trigger': (advQueueWarning, notifyObject)
     */
    this.warningEmitter = new EventTarget();

    // When a warning is triggered, we create a desktop notification.
    this.warningEmitter.addEventListener("trigger", (event: any) => {
      this.notificationsService.notifyOnQueueWarning(event.detail.advQueueWarning);
    });

    this.thresholdCheckerObs$ = this.callControllerService.state.pipe(
      filter(state => state.defaultIsRegistered),
      take(1),
      tap(() => {
        const ua: UserAgent | undefined = this.callControllerService.getOutboundUserAgent();
        if (ua instanceof UserAgentSIP) {
          this.thresholdChecker = new Checker(ua.SIP, this.warningEmitter);
        }
      })
    );
  }

  addWarning(warning: AdvQueueWarning) {
    if (this.areNotificationsEnabled) {
      this.thresholdChecker.addWarning(warning);
    }
  }

  editWarning(warning: AdvQueueWarning) {
    if (this.areNotificationsEnabled) {
      this.thresholdChecker.editWarning(warning);
    }
  }

  deleteWarning(warning: AdvQueueWarning) {
    if (this.areNotificationsEnabled) {
      this.thresholdChecker.removeWarning(warning);
    }
  }

  /**
   * Helper function to turn on queue warnings.
   * This actually starts monitoring the queues and sends notifications.
   */
  private toggleQueueWarnings(isOn: boolean, warnings: Array<AdvQueueWarning>) {
    if (isOn) {
      this.thresholdCheckerObs$.subscribe(() => {
        if (this.thresholdChecker) {
          this.warningSubscriptions = this.thresholdChecker.refreshWarnings(warnings);
        }
      });
    } else if (this.warningSubscriptions.length > 0) {
      this.warningSubscriptions.forEach(subscription => subscription.unsubscribe());
    }
  }
}
