/**
 * Defines a UserAgentEvent.
 */
export interface UserAgentEvent {
  id: string; // event id
  aor: string; // user agent aor
  cause?: string; // cause
}

export class UserAgentEventFactory {
  static make(id: string, aor: string, cause?: string): UserAgentEvent {
    const event: UserAgentEvent = {
      id,
      aor
    };
    if (cause) {
      event.cause = cause;
    }
    return event;
  }
}

/**
 * User-Defined Type Guard for UserAgentEvent
 * @param event Event to check
 */
export function isUserAgentEvent(event: any): event is UserAgentEvent {
  if (event && event.id) {
    const id = event.id;
    if (typeof id === "string") {
      return id.startsWith("[UserAgent]");
    }
  }
  return false;
}
