import { Directive, Self, HostListener, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormGroupDirective
} from "@angular/forms";

import { fromEvent, merge, Subscription } from "rxjs";
import { takeUntil, filter, debounceTime } from "rxjs/operators";

import { UUID } from "../../../../../common/libraries/sip/uuid";

import {
  FormEventData,
  FormEventElement,
  Status
} from "../../../../../common/interfaces/analytics-form-event-data";
import { FormsAnalyticsService } from "./forms-analytics.service";

const debug = false;

@Directive({
  selector: "[onsipFormTracking]",
  exportAs: "onsipFormTracking"
})
export class FormEventTrackingDirective implements OnInit, OnDestroy {
  private readonly formInstanceId = UUID.randomUUID();
  private get name(): string {
    return (this.vcRef.element.nativeElement as HTMLFormElement).name;
  }
  private get id(): string {
    return (this.vcRef.element.nativeElement as HTMLFormElement).id;
  }
  private get formGroup(): FormGroup {
    return this.directive.control;
  }

  private unsubscriber = new Subscription();

  @HostListener("submit", ["$event"])
  onSubmit(event: Event) {
    debug && console.warn("submit", event);
    this.sendEvent("submit");
  }

  @HostListener("reset", ["$event"])
  onReset(event: Event) {
    debug && console.warn("reset", event);
    // unneeded for now, noop
  }

  constructor(
    private analytics: FormsAnalyticsService,
    @Self() private vcRef: ViewContainerRef,
    @Self() private directive: FormGroupDirective
  ) {}

  ngOnInit() {
    if (!this.name) {
      throw new Error(
        "FormEventTrackingDirective: name attribute not detected on host <form> element, did you forget it?"
      );
    }
    if (!this.id) {
      throw new Error(
        "FormEventTrackingDirective: id attribute not detected on host <form> element, did you forget it?"
      );
    }
    if (!this.directive) {
      throw new Error(
        "FormEventTrackingDirective: FormGroupDirective not detected on host <form> element, did you forget it?"
      );
    }
    if (!this.formGroup) {
      throw new Error(
        "FormEventTrackingDirective: bound formGroup not detected on formGroup directive"
      );
    }

    this.unsubscriber.add(
      merge(this.formGroup.valueChanges, this.formGroup.statusChanges)
        .pipe(
          // value and status changes often emit simultaneously (and synchronously), and sometimes we get double value changes on top of that. Do not remove this debounce, if you do, leave it in as debounceTime(0)
          debounceTime(500),
          // a value change is propagated for every child control on submit, so we cut it off after a valid submission
          takeUntil(
            fromEvent(this.vcRef.element.nativeElement, "submit").pipe(
              filter(() => this.formGroup.valid)
            )
          )
        )
        .subscribe(() => {
          this.sendEvent("change");
          debug && console.warn(this.formGroup);
        })
    );

    this.sendEvent("init");
  }

  ngOnDestroy() {
    this.sendEvent("destroy");
    this.unsubscriber.unsubscribe();
  }

  private sendEvent(action: FormEventData["action"]): void {
    const data: FormEventData = {
      action,
      name: this.name,
      formId: this.id,
      formInstanceId: this.formInstanceId,
      status: this.formGroup.status.toLocaleLowerCase() as Status,
      pristine: this.formGroup.pristine,
      touched: this.formGroup.touched,
      dirty: this.formGroup.dirty,
      elements: arrayToRecord(this.getFormEventElementData(this.name, this.formGroup), "name")
    };
    this.analytics.sendFormEvent(data);
  }

  private getFormEventElementData(name: string, ac: AbstractControl): Array<FormEventElement> {
    if (ac instanceof FormControl) {
      return [
        {
          name,
          status: ac.status.toLocaleLowerCase() as Status,
          pristine: ac.pristine,
          touched: ac.touched,
          dirty: ac.dirty,
          errors: ac.errors ? Object.keys(ac.errors) : undefined,
          value: ac.value
        }
      ];
    } else if (ac instanceof FormGroup) {
      return Object.entries(ac.controls)
        .map(([k, v]) => this.getFormEventElementData(k, v))
        .reduce((acc, curr) => acc.concat(curr), []);
    } else if (ac instanceof FormArray) {
      return ac.controls
        .map((c, i) => this.getFormEventElementData("" + i, c))
        .reduce((acc, curr) => acc.concat(curr), []);
    }
    throw new Error(
      "form has to be a valid abstract control: formControl, formGroup, or formArray"
    );
  }
}

function arrayToRecord<T, K extends keyof T>(array: Array<T>, indexKeyName: K): Record<string, T> {
  return array.reduce<Record<string, T>>((prev, curr) => {
    prev[curr[indexKeyName] as unknown as string] = curr;
    return prev;
  }, {} as Record<string, T>);
}
