import { Injectable } from "@angular/core";
import { ApiAction } from "../api-actions";
import { ApiSessionService } from "../api-session.service";
import { sessionId } from "../apiParams/session-id";
import { OnsipApiResponse } from "../apiResponse/response-body-new";
import { ApiResourceService } from "./api-resource.service";
import { ApiStateStoreService } from "../api-state-store.service";
import { userId } from "../apiParams/user-id";
import { getApiActionName } from "../onsip-api-action-new";
import { ParameterValue } from "../util/api-action-description";
import { ApiPromiseState, ApiPromiseStateService } from "../api-promise-state.service";

const debug = false;

@Injectable({
  providedIn: "root"
})
export class HelpAddSupportCaseService extends ApiResourceService<never> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "HelpAddSupportCase", "" as never);
    debug && this.state.subscribe(state => console.warn("HelpAddSupportCaseService", state));
  }

  HelpAddSupportCase(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<never> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.HelpAddSupportCase,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiAction.HelpAddSupportCase);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.HelpAddSupportCase:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
