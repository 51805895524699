import { Injectable, NgZone } from "@angular/core";

import { AuthService } from "./auth.service";
import { UserAgentCallsPresent, CallState } from "./lib/user-agent-calls-present";
import { StateEmitterArray } from "../../libraries/emitter/state-emitter-array";

export { CallState };

@Injectable({ providedIn: "root" })
export class UserAgentsCallsPresentService extends StateEmitterArray<
  UserAgentCallsPresent,
  Array<CallState>
> {
  constructor(private auth: AuthService, private zone: NgZone) {
    super();
  }

  addAgent(target: string): void {
    if (this.array.map(agent => agent.target).includes(target)) {
      return;
    }
    this.add(new UserAgentCallsPresent(target, this.auth));
  }

  removeAgent(target: string): void {
    const agent = this.get(target);
    if (agent) {
      agent.dispose(); // will publish complete thus removing itself
    }
  }

  /**
   * Adds an UserAgentCallsPresent.
   * @param agent The RTCAgent to add.
   */
  add(agent: UserAgentCallsPresent): this {
    return super.add(agent);
  }

  /**
   * Gets a UserAgentCallsPresent.
   * @param target The target of the RTCAgent.
   */
  get(target: string): UserAgentCallsPresent | undefined {
    return this.array.find(agent => agent.target === target);
  }

  /**
   * Removes a UserAgentCallsPresent.
   * @param call The RTCAgent to remove.
   */
  remove(agent: UserAgentCallsPresent): boolean {
    return super.remove(agent);
  }

  /**
   * Dispose (will complete).
   */
  dispose(): void {
    this.publishComplete();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(fn);
  }
}
