import { Injectable } from "@angular/core";

import { StateEmitter } from "../../libraries/emitter/state-emitter";

import {
  OAuthPresenceListener,
  OAuthPresenceState
} from "../../libraries/firebase/realtime/oauth-presence-listener";

@Injectable({ providedIn: "root" })
export class OAuthPresenceListenerService extends StateEmitter<OAuthPresenceState> {
  private presenceListener: OAuthPresenceListener | undefined;

  constructor() {
    super({ id: "" });
  }

  start(id: string) {
    this.presenceListener = new OAuthPresenceListener(id);
    this.presenceListener.start();
    this.presenceListener.state.subscribe(state => {
      this.stateStore = state;
      this.publishState();
    });
  }

  stop() {
    if (this.presenceListener) {
      this.presenceListener.dispose();
      this.presenceListener = undefined;
    }
  }
}
