export class LoginError extends Error {
  constructor(message: string, public code: LoginErrorCode) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}

export enum LoginErrorCode {
  ApiFailure,
  AuthenticationFailure,
  BetaRestricted,
  NetworkFailure,
  RequestTimeout,
  UnknownError,
  CookieRecreateFailure
}
