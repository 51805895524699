import { firebase } from "./platform-firebase-types";

export class PlatformFirebase {
  static database = {
    ServerValue: {} as {
      TIMESTAMP: object;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    enableLogging(enable: boolean): void {
      throw new Error("did not override enableLogging");
    }
  };

  static auth = {
    Auth: {
      Persistence: {} as {
        LOCAL: firebase.auth.Auth.Persistence;
        NONE: firebase.auth.Auth.Persistence;
        SESSION: firebase.auth.Auth.Persistence;
      }
    }
  };

  static firestore = {
    FieldValue: {} as any // Tricky because I cannot use static funcs if I declare the type to be FieldValue
  };

  private static firebaseApp: firebase.app.App;
  private static isNativescript = false;

  static get firebase(): firebase.app.App {
    if (!this.firebaseApp) throw new Error("Firebase wasn't set and we are trying to use it!");
    return this.firebaseApp;
  }

  static set firebase(fb) {
    if (!this.firebaseApp) {
      this.firebaseApp = fb;
    }
  }

  static get nativescript() {
    return this.isNativescript;
  }

  // Flag to know if we are using nativescript. This is neccesary for document.ts where mobile has it's own transaction code
  static set nativescript(isNativescript: boolean) {
    if (!this.isNativescript) {
      this.isNativescript = isNativescript;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static initializeApp(options?: any, name?: string): any {
    // Mobile returns a Promise<any> wheras web returns firebase.app.app
    throw new Error("did not override initializeApp");
  }
}
