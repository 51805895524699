import { Injectable } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";
import {
  ApiPromiseState,
  ApiPromiseStateService
} from "@onsip/common/services/api/api-promise-state.service";
import { ApiResourceService } from "@onsip/common/services/api/resources/api-resource.service";
import { OnsipApiResponse } from "@onsip/common/services/api/apiResponse/response-body-new";
import { ApiGenericAction } from "@onsip/common/services/api/api-actions";
import { getApiActionName } from "@onsip/common/services/api/onsip-api-action-new";

const debug = false;

@Injectable({ providedIn: "root" })
export class SignupValidateDomainService extends ApiResourceService<any> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "SignupValidateDomain", "");
    debug && this.state.subscribe(state => console.warn("Signup.state", state));
  }

  signupValidateDomain(Domain: string): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.SignupValidateDomain,
        Domain: Domain
      }
    });
    return this.promiseState.toPromise(ApiGenericAction.SignupValidateDomain);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    debug && console.warn("SignupValidateDomain.reducer");
    switch (action) {
      case ApiGenericAction.SignupValidateDomain:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
