import { ValidatorFn, AbstractControl } from "@angular/forms";
import { parsePhoneNumber } from "awesome-phonenumber";

export default function phoneNumberValidator(options = { isInternational: false }): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const country = options.isInternational ? undefined : "US";
    const phoneNumber = country
      ? parsePhoneNumber(control.value, { regionCode: "US" })
      : parsePhoneNumber(control.value);
    const validPhoneNumber = phoneNumber.valid || control.value.length === 0;
    // eslint-disable-next-line no-null/no-null
    return !validPhoneNumber ? { validPhoneNumber: { value: validPhoneNumber } } : null;
  };
}
