import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { E911AdminModalComponent } from "./e911-admin-modal/e911-admin-modal.component";

import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [E911AdminModalComponent],
  imports: [CommonModule, FormsModule, MaterialModule, TranslateModule],
  exports: [E911AdminModalComponent]
})
export class E911AdminModule {}
