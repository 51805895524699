@if (currentCall && !(currentCall.videoAvailable && currentCall.connected)) {
<div class="header">
  @if (currentCall && !(currentCall.videoAvailable && currentCall.connected)) {
  <div class="sayso-call-message">
    <!-- sayso call 'Connecting in' message -->
    @if (!(currentCall && currentCall.videoAvailable) && showSaysoConnectingTimer) {
    <div class="sayso-call-message">
      <onsip-pulsing-dot [connected]="callTimerMs >= 0"></onsip-pulsing-dot>
      <span
        >{{
          callTimerMs >= 0
            ? ("ONSIP_I18N.CALL_CONNECTED__SPEAK_NOW" | translate)
            : ("ONSIP_I18N.CALL_CONNECTING_IN" | translate)
        }}&nbsp; @if (callTimerMs < 0) {
        <ng-container class="sayso-call-timer">
          {{ callTimerMs | duration }}
        </ng-container>
        }
      </span>
    </div>
    }
  </div>
  }
  <onsip-call-status [nameOverride]="callFromDisplayName" [callUuid]="currentCall.uuid">
  </onsip-call-status>
</div>
}
<div
  class="main"
  [class.fullscreen]="isFullscreen"
  [class.video-session]="currentCall && currentCall.videoAvailable && currentCall.connected"
  [class.screen-share]="currentCall && hasScreenShare"
>
  @if (currentCall && currentCall.videoAvailable && currentCall.connected) {
  <button
    id="onsip--call2--toggle-full-screen"
    aria-label="toggle full screen"
    class="fullscreen-button"
    mat-icon-button
    (click)="toggleFullScreen()"
  >
    <mat-icon>fullscreen</mat-icon>
  </button>
  } @if (currentCall && currentCall.videoAvailable && currentCall.connected && !isFullscreen) {
  <div class="video-header">
    <!-- sayso video call 'Connecting in' message -->
    @if (callTimerMs < 0) {
    <div class="sayso-call-timer video-sayso-call-timer">
      <h4>{{ "ONSIP_I18N.CONNECTING_IN" | translate }} {{ callTimerMs | duration }}</h4>
    </div>
    }
    <div class="session-info" [class.has-timer]="callTimerMs &lt; 0">
      <div class="remote-identity video-title">
        <div class="outer-circle"><div class="inner-circle"></div></div>
        @if (callFromDisplayName) {
        <h3 [class.fullscreen]="isFullscreen">
          {{ "ONSIP_I18N.CALL_WITH" | translate }} {{ callFromDisplayName }}
        </h3>
        }
      </div>
    </div>
  </div>
  }
  <!-- On Hold Banner -->
  @if (currentCall && currentCall.videoAvailable && currentCall.connected && currentCall.hold) {
  <div class="on-hold-banner">
    <span>
      <mat-icon>pause</mat-icon>
      <div class="mat-headline-6">{{ "ONSIP_I18N.CALL_ON_HOLD" | translate }}</div>
    </span>
    <button mat-flat-button class="banner-button" (click)="hold()">
      {{ "ONSIP_I18N.RESUME" | translate }}
    </button>
    <button
      mat-flat-button
      class="banner-button"
      (click)="endCall()"
      [attr.data-cy]="E2eLocators.CALL_PAGE_END_CALL_BTN"
    >
      {{ "ONSIP_I18N.END_CALL" | translate }}
    </button>
  </div>
  }
  <!-- Screen Share In-Progress Banner -->
  @if ( hasScreenShare && currentCall && !currentCall.hold && currentCall.videoAvailable &&
  currentCall.connected ) {
  <div class="in-progress-banner" [ngClass]="{ paused: !currentCall.video }">
    <span>
      <mat-icon>{{ !currentCall.video ? "stop_screen_share" : "screen_share" }}</mat-icon>
      <div class="mat-headline-6">
        {{
          !currentCall.video
            ? ("ONSIP_I18N.SCREENSHARE_PAUSED" | translate)
            : ("ONSIP_I18N.SCREENSHARE_IN_PROGRESS" | translate)
        }}
      </div>
    </span>
    <button mat-flat-button class="banner-button" (click)="muteVideo(currentCall.video)">
      {{
        !currentCall.video
          ? ("ONSIP_I18N.RESUME_SHARE" | translate)
          : ("ONSIP_I18N.PAUSE_SHARE" | translate)
      }}
    </button>
    <button mat-flat-button class="banner-button" (click)="screenShareService.stopCapture()">
      {{ "ONSIP_I18N.END_SCREENSHARE" | translate }}
    </button>
  </div>
  } @if (currentCall && currentCall.videoAvailable && currentCall.connected) {
  <onsip-call-video-container [callUuid]="currentCall.uuid" [fullscreen]="isFullscreen">
  </onsip-call-video-container>
  }
  <div
    class="footer"
    [class.video-call-footer]="currentCall && currentCall.videoAvailable && currentCall.connected"
  >
    <!-- END CALL IF USER INITIATED CALL AND CALL HAS NOT BEEN ANSWERED YET -->
    @if (currentCall && currentCall.connecting && !currentCall.connected) {
    <div class="button-container">
      <button
        id="onsip-call2-end-call"
        aria-label="end call"
        class="end-call-button"
        mat-fab
        class="onsip-call-end-background mat-elevation-z0"
        autofocus
        (click)="endCall()"
        [attr.data-cy]="E2eLocators.CALL_PAGE_END_CALL_BTN"
      >
        <mat-icon>call_end</mat-icon>
      </button>
    </div>
    } @if (currentCall && currentCall.connected && !currentCall.ended) {
    <div
      class="button-container"
      [class.phone-app-buttons]="!anonymous"
      [class.sayso-video-buttons]="saysoCall && currentCall && currentCall.videoAvailable"
    >
      <!-- Screen share-->
      @if (currentCall.videoAvailable && saysoCall && showScreenShare) {
      <button
        mat-fab
        onsip-stroked-mat-fab
        [disabled]="currentCall.hold"
        color="primary"
        class="mat-elevation-z0"
        id="onsip-call2-screen-share"
        aria-label="toggle screen share"
        [matTooltip]="
          hasScreenShare
            ? ('ONSIP_I18N.END_SCREENSHARE' | translate)
            : ('ONSIP_I18N.START_SCREENSHARE' | translate)
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="screenShareService.toggleScreenShare(currentCall.uuid)"
      >
        @if (!hasScreenShare) {
        <mat-icon color="primary">screen_share</mat-icon>
        } @if (hasScreenShare) {
        <mat-icon color="primary">stop_screen_share</mat-icon>
        }
      </button>
      }
      <!--VOLUME -->
      <div
        id="onsip-call2-volume-popup-container"
        cdkOverlayOrigin
        #volumeButton="cdkOverlayOrigin"
      >
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0"
          id="onsip-call2-toggle-mute-volume"
          aria-label="toggle mute volume"
          disableRipple
          (click)="emitEventToVolume()"
        >
          <mat-icon color="primary">{{ volumeIcon }}</mat-icon>
        </button>
        <onsip-popup-container [overlayOrigin]="volumeButton" [trigger]="'hover'">
          <onsip-volume-popup
            [call]="currentCall.uuid"
            [vertical]="false"
            [voicemail]="false"
            [event]="volumeClick.asObservable()"
          >
          </onsip-volume-popup>
        </onsip-popup-container>
      </div>
      <!-- MUTE/UNMUTE -->
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        id="onsip-call2-toggle-mute-mic"
        aria-label="toggle mute microphone"
        [matTooltip]="
          currentCall && !currentCall.audio
            ? ('ONSIP_I18N.UNMUTE_MICROPHONE' | translate)
            : ('ONSIP_I18N.MUTE_MICROPHONE' | translate)
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="muteAudio()"
      >
        @if (!currentCall.audio) {
        <mat-icon onsip-icon-outlined color="primary">mic_off</mat-icon>
        } @if (currentCall.audio) {
        <mat-icon color="primary">mic</mat-icon>
        }
      </button>
      <!-- UNHOLD -->
      @if (currentCall.hold) {
      <button
        mat-fab
        class="onsip-call-begin-background mat-elevation-z0"
        id="onsip-call2-hold"
        aria-label="unhold call"
        [matTooltip]="'ONSIP_I18N.UNHOLD' | translate"
        matTooltipClass="onsip-tooltip-background"
        (click)="hold()"
      >
        <mat-icon>play_arrow</mat-icon>
      </button>
      }
      <!-- HOLD -->
      @if (!currentCall.hold) {
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        id="onsip-call2-hold"
        aria-label="unhold call"
        [matTooltip]="'ONSIP_I18N.HOLD' | translate"
        matTooltipClass="onsip-tooltip-background"
        (click)="hold()"
      >
        <mat-icon>pause</mat-icon>
      </button>
      }
      <!-- END CALL WHEN ON CALL -->
      @if (currentCall && currentCall.connected) {
      <button
        mat-fab
        class="onsip-call-end-background mat-elevation-z0"
        id="onsip-call2-end-call"
        aria-label="end call"
        [matTooltip]="'ONSIP_I18N.END_CALL' | translate"
        matTooltipClass="onsip-tooltip-background"
        (click)="endCall()"
        [attr.data-cy]="E2eLocators.CALL_PAGE_END_CALL_BTN"
        autofocus
      >
        <mat-icon>call_end</mat-icon>
      </button>
      }
      <!-- VIDEO ON/OFF -->
      @if (currentCall.videoAvailable) {
      <button
        mat-fab
        onsip-stroked-mat-fab
        color="primary"
        class="mat-elevation-z0"
        [disabled]="hasScreenShare || currentCall.hold"
        id="onsip-call2-toggle-video"
        aria-label="toggle video"
        [matTooltip]="
          !currentCall.video
            ? ('ONSIP_I18N.UNMUTE_VIDEO' | translate)
            : ('ONSIP_I18N.MUTE_VIDEO' | translate)
        "
        matTooltipClass="onsip-tooltip-background"
        (click)="muteVideo(!!currentCall.video)"
      >
        <mat-icon color="primary">{{ currentCall.video ? "videocam" : "videocam_off" }}</mat-icon>
      </button>
      }
      <!-- TRANSFER -->
      @if (!anonymous) {
      <div
        id="onsip-call2-transfer-popup-container"
        cdkDropList
        [cdkDropListData]="{ transfer: currentCall }"
        (cdkDropListDropped)="dragDropCallService.dropEvent($event)"
        cdkOverlayOrigin
        matTooltip="Cannot transfer free trial calls"
        matTooltipClass="onsip-tooltip-background"
        [matTooltipDisabled]="currentCall.maxCallTime === undefined"
        #transferButton="cdkOverlayOrigin"
      >
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0"
          id="onsip-call2-toggle-transfer-popup"
          aria-label="toggle transfer popup"
          tabindex="0"
          (click)="!transferCall && isTransferring ? cancelTransfer() : startTransfer()"
          [matTooltip]="
            !transferCall && isTransferring
              ? ('ONSIP_I18N.CANCEL' | translate)
              : ('ONSIP_I18N.TRANSFER_CALL' | translate)
          "
          matTooltipClass="onsip-tooltip-background"
          [disabled]="currentCall.maxCallTime !== undefined"
          cdkDrag
          [cdkDragData]="{ transfer: currentCall }"
          [class.draggable]="!conferenceCall"
        >
          <mat-icon color="primary">{{
            !transferCall && isTransferring ? "close" : "swap_horiz"
          }}</mat-icon>
          <img *cdkDragPreview [src]="'resources/img/transfer-drag.png'" alt="Drag for Transfer" />
        </button>
        @if (currentCall.maxCallTime === undefined) {
        <onsip-popup-container
          [overlayOrigin]="transferButton"
          [trigger]="
            !!transferCall &&
            currentCall.connected &&
            currentCall.connectedAt &&
            transferCall.connectedAt
              ? 'open'
              : 'click'
          "
          [hasBackdrop]="false"
          (dismissed)="cancelTransfer()"
        >
          @if (currentCall && (isTransferring || !!transferCall)) {
          <div class="transfer-conference-container" (click)="$event.stopPropagation()">
            @if (!transferCall) {
            <div class="transfer-header">{{ "ONSIP_I18N.TRANSFER_CALL" | translate }}</div>
            <div class="transfer-input">
              <onsip-sip-address-input
                class="transfer-input-box"
                #transferTarget
                [whiteText]="true"
                [card]="false"
                [placeholder]="'ONSIP_I18N.PHONE_NUMBER_OR_CONTACT' | translate"
                (value)="transferTargetModel = $event"
                (enter)="blindTransfer()"
              >
              </onsip-sip-address-input>
              <span
                class="transfer-dialpad-button"
                cdkOverlayOrigin
                #transferDialpadButton="cdkOverlayOrigin"
              >
                <mat-icon [style.color]="'#ffffff'">dialpad</mat-icon>
                <onsip-popup-container [overlayOrigin]="transferDialpadButton">
                  <onsip-dialpad
                    [target]="'newCall'"
                    [showHistory]="false"
                    (click)="$event.stopPropagation()"
                  ></onsip-dialpad>
                </onsip-popup-container>
              </span>
            </div>
            <div class="transfer-button-container">
              <button
                id="onsip-call2-attended-transfer"
                mat-raised-button
                color="primary"
                (click)="attendedTransfer()"
              >
                {{ "ONSIP_I18N.ATTENDED" | translate }}
              </button>
              <button
                id="onsip-call2-blind-transfer"
                mat-raised-button
                color="primary"
                (click)="blindTransfer()"
              >
                {{ "ONSIP_I18N.BLIND" | translate }}
              </button>
            </div>
            } @if ( !!transferCall && currentCall.connected && currentCall.connectedAt &&
            transferCall.connectedAt ) {
            <div class="attended-transfer-block">
              <h4>{{ "ONSIP_I18N.COMPLETE_TRANSFER" | translate | titlecase }}</h4>
              <span>
                {{ "ONSIP_I18N.COMPLETE_TRANSFER" | translate }}
                {{
                  currentCall.connectedAt < transferCall.connectedAt
                    ? ("ONSIP_I18N.TO" | translate | lowercase)
                    : ("ONSIP_I18N.FROM" | translate | lowercase)
                }}
                {{ transferCall.remoteUri.slice(4) }}?
              </span>
              <div class="complete-transfer-container">
                <button
                  id="onsip--call2--complete-transfer"
                  mat-raised-button
                  color="primary"
                  (click)="completeAttendedTransfer()"
                >
                  {{ "ONSIP_I18N.COMPLETE" | translate }}
                </button>
              </div>
            </div>
            }
          </div>
          }
        </onsip-popup-container>
        }
      </div>
      }
      <!-- DIALPAD -->
      <div
        id="onsip-call2-dialpad-popup-container"
        cdkOverlayOrigin
        #dialpadButton="cdkOverlayOrigin"
      >
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0"
          disableRipple
        >
          <mat-icon color="primary">dialpad</mat-icon>
        </button>
        <onsip-popup-container [overlayOrigin]="dialpadButton">
          <onsip-dialpad [target]="'call'" [showHistory]="true"></onsip-dialpad>
        </onsip-popup-container>
      </div>
      <!-- THIRD PERSON CONFERENCING -->
      @if ( !anonymous && canConference && !currentCall.videoAvailable && isWebrtcSupported &&
      !conferenceCall ) {
      <div
        id="onsip-call2-conference-popup-container"
        cdkDropList
        [cdkDropListData]="{ conference: currentCall }"
        (cdkDropListDropped)="dragDropCallService.dropEvent($event)"
        cdkOverlayOrigin
        #conferencingButton="cdkOverlayOrigin"
      >
        <button
          mat-fab
          onsip-stroked-mat-fab
          color="primary"
          class="mat-elevation-z0 draggable-button draggable"
          id="onsip--call2--toggle-conference-popup"
          aria-label="toggle 3 way conference popup"
          [matTooltip]="'ONSIP_I18N.CONFERENCE' | translate"
          matTooltipClass="onsip-tooltip-background"
          tabindex="0"
          (click)="isConferencing ? cancelConference() : startConference()"
          [cdkDragData]="{ conference: currentCall }"
          cdkDrag
        >
          <mat-icon color="primary">{{ isConferencing ? "clear" : "add" }}</mat-icon>
          <img
            *cdkDragPreview
            [src]="'resources/img/conference/conference-drag.png'"
            alt="Drag for Conference"
          />
        </button>
        <onsip-popup-container
          [overlayOrigin]="conferencingButton"
          (dismissed)="cancelConference()"
        >
          @if (isConferencing) {
          <div class="transfer-conference-container">
            <div class="transfer-header">
              {{ "ONSIP_I18N.THIRD_PERSON_CONFERENCING" | translate }}
            </div>
            <div class="transfer-input">
              <onsip-sip-address-input
                #conferenceTarget
                class="transfer-input-box"
                [card]="false"
                [placeholder]="'ONSIP_I18N.PHONE_NUMBER_OR_CONTACT' | translate"
                [whiteText]="true"
                (value)="conferenceTargetModel = $event"
                (enter)="conference()"
              >
              </onsip-sip-address-input>
              <span
                class="transfer-dialpad-button"
                cdkOverlayOrigin
                #conferencingDialpadButton="cdkOverlayOrigin"
              >
                <mat-icon [style.color]="'#ffffff'">dialpad</mat-icon>
                <onsip-popup-container [overlayOrigin]="conferencingDialpadButton">
                  <onsip-dialpad
                    [target]="'newCall'"
                    [showHistory]="true"
                    (click)="$event.stopPropagation()"
                  ></onsip-dialpad>
                </onsip-popup-container>
              </span>
            </div>
            <div class="transfer-button-container">
              <button
                id="onsip-call2-add-to-call"
                mat-raised-button
                color="primary"
                (click)="conference()"
              >
                <mat-icon>add</mat-icon>{{ "ONSIP_I18N.ADD_TO_CALL" | translate }}
              </button>
            </div>
          </div>
          }
        </onsip-popup-container>
      </div>
      }
    </div>
    }
  </div>
</div>
