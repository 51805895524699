/**
 * jiffyphone/htdocs/app/queue/timeZones.js
 *
 * Each time zone has 3 mandatory keys and one optional key.
 *
 * Mandatory keys:
 *   zoneKey    : The TZData key (name) for the timezone. This must match
 *                whatever TZData uses for that timezone.
 *   zoneOffset : The offset from UTC, as a string.
 *   zoneName   : The display name of the time zone.
 *
 * Optional keys:
 *  mainZone: If true, this is the preferred time zone for that offset.
 *            If absent or false, then it is not preferred over any other zone.
 */

export const timezoneArray = [
  {
    zoneKey: "US/Samoa",
    zoneOffset: "-11:00",
    dstOffset: "-11:00",
    zoneName: "(UTC -11:00) US/Samoa",
    mainZone: true
  },
  {
    zoneKey: "US/Hawaii",
    zoneOffset: "-10:00",
    dstOffset: "-10:00",
    zoneName: "(UTC -10:00) US/Hawaii",
    mainZone: true
  },
  {
    zoneKey: "US/Aleutian",
    zoneOffset: "-10:00",
    dstOffset: "-09:00",
    zoneName: "(UTC -10:00) US/Aleutian",
    mainZone: false
  },
  {
    zoneKey: "US/Alaska",
    zoneOffset: "-09:00",
    dstOffset: "-08:00",
    zoneName: "(UTC -09:00) US/Alaska",
    mainZone: true
  },
  {
    zoneKey: "US/Pacific",
    zoneOffset: "-08:00",
    dstOffset: "-07:00",
    zoneName: "(UTC -08:00) US/Pacific",
    mainZone: true
  },
  {
    zoneKey: "Canada/Yukon",
    zoneOffset: "-08:00",
    dstOffset: "-07:00",
    zoneName: "(UTC -08:00) Canada/Yukon",
    mainZone: false
  },
  {
    zoneKey: "Canada/Pacific",
    zoneOffset: "-08:00",
    dstOffset: "-07:00",
    zoneName: "(UTC -08:00) Canada/Pacific",
    mainZone: false
  },
  {
    zoneKey: "US/Mountain",
    zoneOffset: "-07:00",
    dstOffset: "-06:00",
    zoneName: "(UTC -07:00) US/Mountain",
    mainZone: true
  },
  {
    zoneKey: "US/Arizona",
    zoneOffset: "-07:00",
    dstOffset: "-07:00",
    zoneName: "(UTC -07:00) US/Arizona",
    mainZone: false
  },
  {
    zoneKey: "Canada/Mountain",
    zoneOffset: "-07:00",
    dstOffset: "-06:00",
    zoneName: "(UTC -07:00) Canada/Mountain",
    mainZone: false
  },
  {
    zoneKey: "US/Indiana-Starke",
    zoneOffset: "-06:00",
    dstOffset: "-05:00",
    zoneName: "(UTC -06:00) US/Indiana-Starke",
    mainZone: false
  },
  {
    zoneKey: "US/Central",
    zoneOffset: "-06:00",
    dstOffset: "-05:00",
    zoneName: "(UTC -06:00) US/Central",
    mainZone: true
  },
  {
    zoneKey: "Canada/Saskatchewan",
    zoneOffset: "-06:00",
    dstOffset: "-06:00",
    zoneName: "(UTC -06:00) Canada/Saskatchewan",
    mainZone: false
  },
  {
    zoneKey: "Canada/East-Saskatchewan",
    zoneOffset: "-06:00",
    dstOffset: "-06:00",
    zoneName: "(UTC -06:00) Canada/East-Saskatchewan",
    mainZone: false
  },
  {
    zoneKey: "Canada/Central",
    zoneOffset: "-06:00",
    dstOffset: "-05:00",
    zoneName: "(UTC -06:00) Canada/Central",
    mainZone: false
  },
  {
    zoneKey: "US/Michigan",
    zoneOffset: "-05:00",
    dstOffset: "-04:00",
    zoneName: "(UTC -05:00) US/Michigan",
    mainZone: false
  },
  {
    zoneKey: "US/Eastern",
    zoneOffset: "-05:00",
    dstOffset: "-04:00",
    zoneName: "(UTC -05:00) US/Eastern",
    mainZone: true
  },
  {
    zoneKey: "US/East-Indiana",
    zoneOffset: "-05:00",
    dstOffset: "-04:00",
    zoneName: "(UTC -05:00) US/East-Indiana",
    mainZone: false
  },
  {
    zoneKey: "Canada/Eastern",
    zoneOffset: "-05:00",
    dstOffset: "-04:00",
    zoneName: "(UTC -05:00) Canada/Eastern",
    mainZone: false
  },
  {
    zoneKey: "Canada/Atlantic",
    zoneOffset: "-04:00",
    dstOffset: "-03:00",
    zoneName: "(UTC -04:00) Canada/Atlantic",
    mainZone: true
  },
  {
    zoneKey: "Canada/Newfoundland",
    zoneOffset: "-03:30",
    dstOffset: "-02:30",
    zoneName: "(UTC -03:30) Canada/Newfoundland",
    mainZone: true
  }
];
