import { Injectable } from "@angular/core";
import { ApiAction } from "../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../api-promise-state.service";
import { ApiSessionService } from "../api-session.service";
import { ApiStateStoreService } from "../api-state-store.service";
import { sessionId } from "../apiParams/session-id";
import { OnsipApiResponse } from "../apiResponse/response-body-new";
import { getApiActionName } from "../onsip-api-action-new";
import { ApiResourceService } from "./api-resource.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class SendEmailService extends ApiResourceService<never> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "SendEmail", "" as never);
    debug && this.state.subscribe(state => console.warn("SendEmailService", state));
  }

  sendEmail(Username: string, Type: "NewUser" | "VoicemailInstructions"): ApiPromiseState<never> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.SendEmail,
        Username,
        Type,
        SessionId: this.store.state.pipe(sessionId())
      }
    });
    return this.promiseState.toPromise(ApiAction.SendEmail);
  }

  sendPasswordResetEmail(
    Username: string,
    Destination: string = "app",
    ByAdmin: boolean = false
  ): ApiPromiseState<never> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.SendPasswordResetEmail,
        Username,
        Destination,
        ByAdmin
      }
    });
    return this.promiseState.toPromise(ApiAction.SendPasswordResetEmail);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.SendEmail:
      case ApiAction.SendPasswordResetEmail:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
