import { RemoteIdentityComponent } from "./remote-identity.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [RemoteIdentityComponent],
  exports: [RemoteIdentityComponent]
})
export class RemoteIdentityModule {}
