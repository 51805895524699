import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { AnimationModule } from "../../shared/components/animation/animation.module";
import { ModalModule } from "../../shared/components/modal/modal.module";
import { SnackbarModule } from "../../shared/components/snackbar/snackbar.module";
import { FooterComponent } from "./footer.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    MatDialogModule,
    SnackbarModule,
    ModalModule,
    MatDividerModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule,
    AnimationModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class OnsipFooterModule {}
