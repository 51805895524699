import { VideoConferenceComponent } from "./video-conference.component";
import { ConferencePageComponent } from "./page/conference-page.component";
import { NewConferenceComponent } from "./new/new-conference.component";

import { PopupContainerModule } from "../shared/components/popupContainer/popup-container.module";
import { VolumeModule } from "../shared/components/volume/volume.module";
import { PipesModule } from "../shared/pipes/pipes.module";
import { DialpadModule } from "../shared/components/dialpad/dialpad.module";
import { CallVideoContainerModule } from "../call/video/call-video-container.module";
import { UserAgentModule } from "../shared/components/userAgent/user-agent.module";
import { MaterialModule } from "../../modules/material/material.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    PopupContainerModule,
    VolumeModule,
    PipesModule,
    DialpadModule,
    CallVideoContainerModule,
    UserAgentModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  declarations: [VideoConferenceComponent, NewConferenceComponent, ConferencePageComponent],
  exports: [ConferencePageComponent, VideoConferenceComponent, NewConferenceComponent]
})
export class VideoConferenceModule {}
