import { Injectable } from "@angular/core";
import { ApiSessionService } from "../../../../common/services/api/api-session.service";
import { ApiStateStoreService } from "../../../../common/services/api/api-state-store.service";
import { distinctUntilChanged } from "rxjs/operators";
import { WebCallTopicService } from "../../../../common/services/api/resources/webCallTopic/web-call-topic.service";
import { ConfigurationFileService } from "../../../../common/services/configuration-file.service";
import { ApiPromiseStateService } from "../../../../common/services/api/api-promise-state.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class PhoneWebCallTopicService extends WebCallTopicService {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    configFileService: ConfigurationFileService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, configFileService, promiseState);

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(state => {
        if (state.loggedIn && !!state.sessionId) this.onLogin();
        else if (!state.loggedIn) this.dispose();
      });
  }

  protected onLogin(): void {
    debug && console.log("WebWebCallTopicService.onLogin");
    this.webCallTopicBrowse();
  }
}
