import { AvatarModule } from "../avatar/avatar.module";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { ChatWindowComponent } from "./window/chat-window.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [CommonModule, MaterialModule, AvatarModule, TranslateModule],
  declarations: [ChatWindowComponent],
  exports: [ChatWindowComponent]
})
export class ChatModule {}
