<h1>
  {{ selectedCMS | titlecase }} {{ "ONSIP_I18N.INSTALLATION" | translate }} ({{
    "ONSIP_I18N.TAKES_LESS_THAN_5_MINUTES" | translate
  }})
</h1>
<!-- TEMPLATES FOR INSTRUCTIONS -->
@if (selectedCMS === 'generic') {
<div>
  <ol class="instructions">
    <li>
      {{ "ONSIP_I18N.OPEN_UP_THE_GLOBAL_HEAD_CODE_OF_YOUR_WEBSITE_IN_AN_EDITOR" | translate }}
    </li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <li>
      {{ "ONSIP_I18N.PASTE_THE_CODE_AT_THE_BOTTOM_JUST_BEFORE_THE_HEAD_TAG_AND_SAVE" | translate }}
    </li>
  </ol>
  <ng-container *ngTemplateOutlet="snippet"></ng-container>
  <br />
  <p>
    {{
      "ONSIP_I18N.IF_YOUR_CMS_DOESNT_ALLOW_ACCESS_TO_THE_GLOBAL_CODE_LIKE_THE_GODADDY_WEBSITE_BUILDER_YOU_MIGHT_NOT_BE_ABLE_TO_INSTALL_SAYSO_NOTE_THIS_IS_ONLY_FOR_WEBSITES_BUILT_WITH_GODADDY_AND_DOES_NOT_AFFECT_USERS_WHO_BOUGHT_THEIR_DOMAIN_NAME_THROUGH_GODADDY"
        | translate
    }}
  </p>
</div>
} @if (selectedCMS === 'hubspot') {
<div>
  <ol class="instructions">
    <li>
      {{ "ONSIP_I18N.CLICK_ON_THE_SETTINGS_GEAR_ICON_IN_THE_TOP_RIGHT_OF_THE_SCREEN" | translate }}
    </li>
    <li>{{ "ONSIP_I18N.CLICK_WEBSITE_IN_THE_LEFT_SIDEBAR" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_PAGES_IN_THE_DROPDOWN" | translate }}</li>
    <li>{{ "ONSIP_I18N.ENSURE_THE_MODIFYING_OPTION_IS_SET_TO_ALL_DOMAINS" | translate }}</li>
    <li>
      {{
        "ONSIP_I18N.COPY_AND_PASTE_THE_FOLLOWING_INSTALLATION_CODE_IN_THE_TEXT_BOX_UNDER_SITE_HEADER_HTML_AND_SAVE"
          | translate
      }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
  </ol>
</div>
} @if (selectedCMS === 'squarespace') {
<div>
  <ol class="instructions">
    <li>{{ "ONSIP_I18N.CLICK_SETTINGS_AT_THE_LEFT_OF_THE_SCREEN" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_ADVANCED_AT_THE_BOTTOM_OF_THE_MENU" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_CODE_INJECTION_NEAR_THE_BOTTOM_OF_THE_MENU" | translate }}</li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>{{ "ONSIP_I18N.PASTE_THE_CODE_IN_THE_TEXT_BOX_LABELED_HEADER_AND_SAVE" | translate }}</li>
  </ol>
  <p>
    {{
      "ONSIP_I18N.THE_CODE_INJECTION_TOOL_MAY_BE_ACCESSIBLE_ONLY_WITH_SQUARESPACE_ACCOUNTS_ON_CERTAIN_PLANS_CHECK_YOUR_PLAN_DETAILS_FOR_MORE_INFORMATION"
        | translate
    }}
  </p>
</div>
} @if (selectedCMS === 'wordpress') {
<div>
  <ol class="instructions">
    <li>
      {{ "ONSIP_I18N.FROM_THE_ADMIN_DASHBOARD_CLICK_APPEARANCE_THEN_THEME_EDITOR" | translate }}
    </li>
    <li>{{ "ONSIP_I18N.UNDER_THEME_FILES_SELECT_HEADERPHP" | translate }}</li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>
      {{
        "ONSIP_I18N.PASTE_THE_CODE_AT_THE_BOTTOM_OF_THE_HEAD_SECTION_OF_THE_FILE_JUST_ABOVE_HEAD"
          | translate
      }}
    </li>
    <li>{{ "ONSIP_I18N.CLICK_UPDATE_FILE_TO_SAVE" | translate }}</li>
  </ol>
</div>
} @if (selectedCMS === 'weebly') {
<div>
  <ol class="instructions">
    <li>{{ "ONSIP_I18N.CLICK_SETTINGS_AT_THE_TOP_OF_THE_SCREEN" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_SEO_IN_THE_LEFT_SIDEBAR" | translate }}</li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>
      {{ "ONSIP_I18N.PASTE_THE_CODE_IN_THE_TEXT_BOX_UNDER_HEADER_CODE_AND_SAVE" | translate }}
    </li>
  </ol>
</div>
} @if (selectedCMS === 'wix') {
<div>
  <ol class="instructions">
    <li>{{ "ONSIP_I18N.CLICK_SETTINGS_AT_THE_TOP_OF_THE_SCREEN" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_TRACKING_TOOLS__ANALYTICS_IN_THE_DROPDOWN" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_NEW_TOOL_THEN_CUSTOM" | translate }}</li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>{{ "ONSIP_I18N.PASTE_THE_CODE_IN_THE_FIRST_TEXT_BOX" | translate }}</li>
    <li>{{ "ONSIP_I18N.NAME_THIS_TOOL_SAYSO" | translate }}</li>
    <li>{{ "ONSIP_I18N.UNDER_ADD_CODE_TO_PAGES_SELECT_ALL_PAGES__LOAD_CODE_ONCE" | translate }}</li>
    <li>{{ "ONSIP_I18N.UNDER_PLACE_CODE_IN_SELECT_HEAD_AND_SAVE" | translate }}</li>
  </ol>
  <p>
    {{
      "ONSIP_I18N.THE_TRACKING_TOOLS__ANALYTICS_TOOL_MAY_ONLY_BE_AVAILABLE_TO_WIX_ACCOUNTS_ON_CERTAIN_PLANS_CHECK_YOUR_PLAN_DETAILS_FOR_MORE_INFORMATION"
        | translate
    }}
  </p>
</div>
} @if (selectedCMS === 'shopify') {
<div>
  <ol class="instructions">
    <li>{{ "ONSIP_I18N.CLICK_THEMES_IN_THE_LEFT_SIDEBAR" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_ACTIONS_ABOVE_YOUR_THEME" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_EDIT_CODE_IN_THE_DROPDOWN" | translate }}</li>
    <li>
      {{
        "ONSIP_I18N.CHOOSE_A_FILE_THAT_IS_LOADED_ON_ALL_PAGES_THIS_MAY_DIFFER_FROM_THEME_TO_THEME_USING_THE_DEFAULT_THEME_ONE_OPTION_IS_HEADERLIQUID"
          | translate
      }}
    </li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>{{ "ONSIP_I18N.PASTE_THE_CODE_AT_THE_BOTTOM_OF_THE_TEXT_EDITOR_AND_SAVE" | translate }}</li>
  </ol>
</div>
} @if (selectedCMS === 'google tag manager') {
<div>
  <ol class="instructions">
    <li>{{ "ONSIP_I18N.CHOOSE_THE_APPROPRIATE_CONTAINER_SITE" | translate }}</li>
    <li>{{ "ONSIP_I18N.CLICK_ADD_A_NEW_TAG" | translate }}</li>
    <li>{{ "ONSIP_I18N.CREATE_A_CUSTOM_HTML_TAG" | translate }}</li>
    <li>
      {{ "ONSIP_I18N.CLICK_THE" | translate }}
      <mat-icon class="small" color="primary">content_copy</mat-icon>
      {{ "ONSIP_I18N.ICON_BELOW_TO_COPY_THE_FOLLOWING_INSTALLATION_CODE" | translate }}
    </li>
    <ng-container *ngTemplateOutlet="snippet"></ng-container>
    <li>{{ "ONSIP_I18N.PASTE_THE_CODE_IN_THE_TEXT_BOX_UNDER_HTML" | translate }}</li>
    <li>{{ "ONSIP_I18N.SET_THE_TRIGGER_TO_ALL_PAGES" | translate }}</li>
    <li>{{ "ONSIP_I18N.NAME_AND_SAVE_THE_TAG_THEN_CLICK_SUBMIT" | translate }}</li>
  </ol>
</div>
}
<!-- END OF TEMPLATES FOR INSTRUCTIONS -->
<div mat-dialog-actions class="buttons">
  <button mat-raised-button color="primary" (click)="close()">
    {{ "ONSIP_I18N.FINISH" | translate }}
  </button>
</div>
<!-- TEMPLATE FOR CODE SNIPPET -->
<ng-template #snippet>
  <div class="code-box primary-50-bg">
    <button
      id="onsip--sayso-installation--copy-to-clipboard"
      aria-label="copy snippet to clipboard"
      mat-icon-button
      [matTooltip]="'ONSIP_I18N.COPY' | translate"
      matTooltipClass="onsip-tooltip-background"
      matTooltipPosition="above"
      (click)="autoCopy($event)"
      color="primary"
    >
      <mat-icon color="primary">content_copy</mat-icon>
    </button>
    <code #code id="code">
      <span class="primary-200"> &lt;!-- OnSIP --></span><br />
      &lt;script><br />
      &nbsp;&nbsp;window.onsip=window.onsip||&#123;&#125;;<br />
      &nbsp;&nbsp;window.onsip.do=window.onsip.do||function()&#123;(onsip.q=onsip.q||[]).push(arguments)&#125;;<br />
      &nbsp;&nbsp;onsip.do('preload','<span>{{ orgId | async }}</span
      >');<br />
      &lt;/script><br />
      &lt;script defer src="https://js.onsip.com/onsip.js">&lt;/script><br />
      <span class="primary-200"> &lt;!-- End OnSIP --></span><br />
    </code>
  </div>
</ng-template>
