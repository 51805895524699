import { OperatorFunction } from "rxjs";
import { map, distinctUntilChanged } from "rxjs/operators";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { Sdr } from "../resources/sdr/sdr";

import { orderBy } from "../../callHistory/call-history-config";

export function lastSeenSdrId(): OperatorFunction<ApiStateStoreState, string> {
  return storeState =>
    storeState.pipe(
      map(
        store =>
          Object.values((store.Sdr as ApiSubstate<Sdr>)?.state).sort((a, b) => {
            // the block of code below sorts by StartTime ASCENDING, then SdrId ASCENDING
            // this corresponds with the OPPOSITE (since we're taking the 0th element of the sorted array) of the OrderBy parameter we provide, which used to be hardcoded in
            // this trivial smoke test will break if someone changes the value
            // if the change was purposeful, then you MUST edit this sort function
            if (orderBy !== "StartTime DESC, SdrId DESC") {
              throw new Error("DEVELOPER: callHistoryConfig.orderBy was changed");
            }
            const timeDiff = new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
            if (timeDiff !== 0) return timeDiff;
            else return parseInt(a.sdrId) - parseInt(b.sdrId);
          })[0]?.sdrId
      ),
      distinctUntilChanged()
    );
}
