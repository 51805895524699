import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  ViewChild,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BehaviorSubject, combineLatest, interval, Subscription, timer } from "rxjs";
import { distinctUntilChanged, filter, take, takeUntil, withLatestFrom } from "rxjs/operators";

import { GumService } from "../../services/gum/gum.service";
import { ModalMaterialComponent } from "../modal/modal-material.component";

@Component({
  selector: "onsip-audio-recorder",
  templateUrl: "./audio-recorder.component.html",
  styleUrls: ["./audio-recorder.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioRecorderComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("scrubber") scrubber!: ElementRef;
  @Input() canvasHeight = 30;
  @Input() inputFile: SafeResourceUrl | undefined = undefined;
  @Input() triggerDiscardGreeting = false;
  @Output() outputBlob = new EventEmitter<Blob | undefined>();
  mediaRecorder?: MediaRecorder;
  audioObj = new Audio();
  timer = timer(0, 100);

  isRecording = new BehaviorSubject<boolean>(false);
  isPlaying = new BehaviorSubject<boolean>(false);
  isCompleted = new BehaviorSubject<boolean>(false);

  recordedTime = 0;
  currentTime = 0;
  audioDuration = 0;

  currentFileSubject = new BehaviorSubject<SafeResourceUrl | undefined>(undefined);
  private unsubscriber = new Subscription();
  constructor(
    private cdRef: ChangeDetectorRef,
    private domSanitizer: DomSanitizer,
    private gum: GumService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.unsubscriber.add(
      this.currentFileSubject.pipe(distinctUntilChanged()).subscribe(file => {
        this.audioObj.pause();
        this.audioObj = new Audio();
        this.audioObj.src = file
          ? this.domSanitizer.sanitize(SecurityContext.RESOURCE_URL, file) || ""
          : "";
        this.audioObj.load();
        this.audioObj.preload = "auto";
        this.audioObj.currentTime = 0;
        this.currentTime = 0;
        this.isCompleted.next(true);
        this.isPlaying.next(false);
        this.cdRef.markForCheck();

        this.audioObj.addEventListener("loadedmetadata", () => {
          // tackling the infinity audio duration bug here
          // so any audio coming from a mediastream has this bug. There is a elaborate hack to get the duration but it only works on chrome.
          // so we will just track the recordedTime ourselves. We need to do this anyway.
          // There will be some discrepancies in duration when the audio does get saved to the backend so if the audio is a second off, this is why
          this.audioDuration =
            this.audioObj.duration === Number.POSITIVE_INFINITY
              ? this.recordedTime
              : // we are going to track duration in milliseconds to take advantage of the duration pipe.
                // audio element is in seconds so multiple by 1000
                this.audioObj.duration * 1000 || 0;
          this.cdRef.markForCheck();
        });

        this.audioObj.addEventListener("ended", () => {
          this.isPlaying.next(false);
          this.isCompleted.next(true);
          // the last tick from the timer can be erratic so we will hard set the final tick here
          this.currentTime = this.audioDuration;
          this.updateScrubber();
          this.cdRef.markForCheck();
        });
      })
    );
    this.currentFileSubject.next(this.inputFile);
  }

  ngOnChanges({ inputFile }: SimpleChanges) {
    // allows parent components to trigger a (modal-less) discard via the input variable triggerDiscardGreeting
    // whenever that input variable is changed and true, the execDiscardGreeting method will execute
    // the purpose of this functionality is to move recording file validation/requirements to the parent component
    // and for the parent component to be able to discard "bad" files automatically
    if (this.triggerDiscardGreeting) {
      this.execDiscardGreeting();
      this.triggerDiscardGreeting = false;
    }

    if (inputFile) {
      this.currentFileSubject.next(this.inputFile);
      if (!this.inputFile) {
        this.audioDuration = 0;
      }
    }
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
    // clean up audio elements when closing the component
    this.audioObj.pause();
    this.audioObj.remove();
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") this.mediaRecorder.stop();
  }

  play(): void {
    this.isPlaying.next(true);
    this.isCompleted.next(false);
    this.audioObj.play().then(() => this.updateTimer());
  }

  pause(): void {
    this.isPlaying.next(false);
    this.audioObj.pause();
  }

  stop(): void {
    this.pause();
    // calling load reset the audio to 0
    // there is a bug with firefox where recorded stream audio's current time cannot be modified
    this.audioObj.load();
    this.currentTime = 0;
    this.updateScrubber();
    this.cdRef.markForCheck();
  }

  startRecording() {
    // pause any ongoing audio if there are any before recording
    this.pause();
    this.gum.getUserMedia({ audio: true, video: false }).then(stream => {
      this.cdRef.markForCheck();

      this.mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });

      this.mediaRecorder.start();
      this.isRecording.next(true);
      this.startRecordTimer();
    });
  }

  recording(): void {
    if (!this.inputFile) {
      this.startRecording();
      return;
    }
    this.replaceGreetingWarningModal();
  }

  stopRecording() {
    if (this.mediaRecorder && this.mediaRecorder.state === "recording") this.mediaRecorder.stop();
    this.isRecording.next(false);
  }

  /** handles the output blob after a stream is stopped/finished */
  getSoundWaveBlob(blob: Blob): void {
    // we need to convert the blob to a URL and sanitize so we can replay the blob as an audio file
    const currentFile = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
    this.currentFileSubject.next(currentFile);
    // output the blob to the parent component that is using the blob
    // keeping the output in blob form because the final api request for audio will require the file to be in a blob anyway
    // I think it will be annoying to convert the blob from file and then back to a blob for the api request so going to leave it as a blob
    this.outputBlob.emit(blob);
    this.mediaRecorder = undefined;
    this.cdRef.markForCheck();
  }

  discardGreeting(): void {
    // stop any ongoing recording or playback before discarding
    this.stopRecording();
    this.pause();
    this.unsubscriber.add(
      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: "Discard recorded greeting",
            message: "Are you sure you want to discard your recorded greeting?",
            primaryBtnText: "Discard",
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(res => {
          if (res && res.doPrimaryAction) {
            this.execDiscardGreeting();
          }
        })
    );
  }

  private replaceGreetingWarningModal(): void {
    this.unsubscriber.add(
      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: "You already have a greeting",
            message: "This will replace your current greeting. Are you sure you want to continue?",
            primaryBtnText: "Replace",
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(res => {
          if (res && res.doPrimaryAction) {
            this.startRecording();
          }
        })
    );
  }

  execDiscardGreeting(): void {
    this.currentFileSubject.next(undefined);
    this.audioDuration = 0;
    this.updateScrubber();
    // also have to remember to output an undefined to the parent component
    this.outputBlob.emit(undefined);
    this.cdRef.markForCheck();
  }

  private startRecordTimer(): void {
    const currentTime = new Date().getTime();
    this.unsubscriber.add(
      interval(1000)
        .pipe(takeUntil(this.isRecording.pipe(filter(isRecording => !isRecording))))
        .subscribe(() => {
          this.recordedTime = new Date().getTime() - currentTime;
          this.cdRef.markForCheck();
        })
    );
  }

  // going to use a timer instead of the timeupdate event because the timeupdate event is super inconsistent and results in a janky progress slider
  // timer allows us to control the update interval to the current time
  private updateTimer(): void {
    this.unsubscriber.add(
      this.timer
        .pipe(
          withLatestFrom(this.isCompleted),
          takeUntil(
            combineLatest([this.isPlaying, this.isCompleted]).pipe(
              filter(([isPlaying, isCompleted]) => !isPlaying || isCompleted)
            )
          )
        )
        .subscribe(() => {
          // multiplying by 1000 to convert currentTime seconds to milliseconds
          this.currentTime = this.audioObj.currentTime * 1000;
          this.updateScrubber();
          this.cdRef.markForCheck();
        })
    );
  }

  private updateScrubber(): void {
    // handle edge case if duration is 0
    const percentage = this.audioDuration === 0 ? 0 : this.currentTime / this.audioDuration;
    // update scrubber width
    this.scrubber.nativeElement.style.width = `${(100 * percentage).toFixed(2)}%`;
  }
}
