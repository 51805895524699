@if (!sidebar) {
<mat-card class="new-conference mat-typography">
  <mat-card-content class="new-conference-content">
    @if (subHeader) {
    <h2 class="new-conference-subheader" [innerHTML]="subHeader"></h2>
    } @if (instructions) {
    <p class="new-conference-instructions" [innerHTML]="instructions"></p>
    } @if (!anonymous) {
    <mat-form-field class="conference-form-field">
      <input
        matInput
        #targetInput
        type="text"
        class="new-conference-target"
        autocomplete="off"
        placeholder="{{ 'ONSIP_I18N.ENTER_A_CONFERENCE_NAME' | translate }}"
        focus="true"
        (change)="updateTarget()"
        onsipDtmfEnabled
        [dtmfEnabled]="true"
        [formControl]="targetControl"
        (keyup)="onKeyUp($event)"
      />
      <mat-error>
        {{ getErrorMessage("target") }}
      </mat-error>
    </mat-form-field>
    } @if (anonymous && !fromDisplay) {
    <mat-form-field class="anon-form-field">
      <input
        matInput
        #anonInput
        type="text"
        class="new-conference-local-display-name"
        autocomplete="off"
        focus="anonymous &amp;&amp; !fromDisplay"
        (change)="updateLocalDisplayName()"
        placeholder="{{ 'ONSIP_I18N.ENTER_YOUR_NAME_HERE' | translate }}"
        [formControl]="localDisplayControl"
        (keyup)="onKeyUp($event)"
      />
      <mat-error>
        {{ getErrorMessage("anon") }}
      </mat-error>
    </mat-form-field>
    }
  </mat-card-content>
  <mat-card-footer class="new-conference-footer primary-200-bg">
    <onsip-multiple-user-agent [card]="true"> </onsip-multiple-user-agent>
    <button
      id="onsip--new-conference--start-conference-anon"
      mat-raised-button
      class="conference-call-button onsip-call-begin-background"
      title="Call the video conference"
      (click)="startConference()"
      [disabled]="gumPending"
    >
      <mat-icon aria-label="videocam">videocam</mat-icon>
      <mat-label>&nbsp; {{ "ONSIP_I18N.VIDEO_CONFERENCE" | translate }}</mat-label>
    </button>
  </mat-card-footer>
</mat-card>
} @if (sidebar) {
<div class="mat-typography sidebar">
  @if (!anonymous) {
  <mat-form-field class="conference-form-field-sidebar">
    <input
      matInput
      #targetInput
      type="text"
      class="new-conference-target-sidebar"
      autocomplete="off"
      placeholder="{{ 'ONSIP_I18N.ENTER_A_NEW_OR_EXISTING_NAME' | translate }}"
      focus="true"
      (change)="updateTarget()"
      onsipDtmfEnabled
      [dtmfEnabled]="true"
      [formControl]="targetControl"
      (keyup)="onKeyUp($event)"
    />
    <mat-error>
      {{ getErrorMessage("target") }}
    </mat-error>
  </mat-form-field>
  }
  <onsip-multiple-user-agent [card]="true"> </onsip-multiple-user-agent>
  <button
    id="onsip--new-conference--start-conference"
    mat-raised-button
    color="primary"
    class="conference-call-button"
    title="Call the video conference"
    (click)="startConference()"
    [disabled]="gumPending"
  >
    <mat-icon aria-label="videocam">videocam</mat-icon>
    <mat-label>&nbsp; {{ "ONSIP_I18N.START_CONFERENCE" | translate }}</mat-label>
  </button>
</div>
}
