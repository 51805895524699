/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from "@angular/core";

import { Window } from "./window";

@Injectable()
export class WindowService implements Window {
  navigator: any = {};
  location: any = {};
  alert(msg: string): void {
    return;
  }
  confirm(msg: string): void {
    return;
  }
}
