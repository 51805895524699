import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnDestroy,
  OnInit
} from "@angular/core";
import { views } from "@onsip/web/app/phone/views";
import { Subscription } from "rxjs";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "onsip-agent-support",
  templateUrl: "./agent-support-page.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./agent-support-page.component.scss"]
})
export class AgentAdminSupportPageComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  views = views;
  isSmallOrXs = false;
  horizontalRepBox = false;

  private unsubscriber = new Subscription();

  constructor(private breakpointObserver: BreakpointObserver, private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setBreakpoints();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private setBreakpoints(): void {
    this.unsubscriber.add(
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        if (result.matches) {
          this.isSmallOrXs = true;
        } else {
          this.isSmallOrXs = false;
        }
        this.cdRef.markForCheck();
      })
    );
  }
}
