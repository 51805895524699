<mat-action-list class="list">
  @if(currentSelections.length) { @for (item of currentSelections; track item) {
  <mat-list-item [disableRipple]="true">
    <div class="mat-list-item-content">
      {{ item | invokeFunction : formatter }}
      <onsip-spacer></onsip-spacer>
      <button
        [attr.data-cy]="E2eLocators.CONTROLS_EXTENSIONS_REMOVE_BTN"
        mat-icon-button
        type="button"
        (click)="delete(item)"
      >
        <mat-icon matListIcon color="primary">delete</mat-icon>
      </button>
    </div>
  </mat-list-item>
  } } @else {
  <mat-list-item [disableRipple]="true">
    {{ "ONSIP_I18N.NO_VALUES_ADDED" | translate : { values: itemNamePlural } }}
  </mat-list-item>
  }
</mat-action-list>
@if (!showAddForm) {
<button
  [attr.data-cy]="E2eLocators.CONTROLS_EXTENSIONS_ADD_BTN"
  class="add-item-btn"
  color="primary"
  mat-stroked-button
  (click)="toggleAddForm()"
>
  <mat-icon>add</mat-icon>
  <span> Add {{ itemType }}</span>
</button>
} @if (showAddForm) {
<mat-form-field appearance="outline" class="add-form">
  <mat-label>Add {{ itemType }}</mat-label>
  <input
    [attr.data-cy]="E2eLocators.CONTROLS_EXTENSIONS_INPUT_BTN"
    matInput
    type="text"
    [formControl]="newItemControl"
  />
  @if (newItemControl.invalid) {
  <mat-error>{{ getErrorMessage(newItemControl.errors) }}</mat-error>
  } @if (hint) {
  <mat-hint>{{ hint }}</mat-hint>
  } @if (suffixText) {
  <span matSuffix class="suffix-text">{{ suffixText }}</span>
  } @if (!prefixButton) {
  <button
    matSuffix
    mat-icon-button
    [attr.data-cy]="E2eLocators.CONTROLS_EXTENSIONS_ADD_SUBMIT_BTN"
    type="submit"
    (click)="saveNewItem($event)"
  >
    <mat-icon color="primary">add</mat-icon>
  </button>
  } @if (prefixButton) {
  <button
    matPrefix
    mat-icon-button
    [attr.data-cy]="E2eLocators.CONTROLS_EXTENSIONS_ADD_SUBMIT_BTN"
    type="submit"
    (click)="saveNewItem($event)"
  >
    <mat-icon color="primary">add</mat-icon>
  </button>
  }
</mat-form-field>
}
