import { Component, Input, OnInit, OnDestroy, HostListener, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { CallAudioService } from "./call-audio.service";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { CallTimerService } from "../../../common/services/call-timer.service";
import { CallGroupService } from "../shared/controller/call-group.service";
import { DialpadService } from "../shared/components/dialpad/dialpad.service";
import { LogService } from "../../../common/services/logging";
import { SupportService } from "../../../common/services/support/support.service";
import { UserAgentService } from "../shared/components/userAgent/user-agent.service";
import { FirestoreCallService } from "../../../common/services/sayso/firestore-call.service";
import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { WebAudioService } from "../shared/services/webAudio/web-audio.service";
import { VolumeService } from "../shared/components/volume/volume.service";
import { TranslateService } from "@ngx-translate/core";

import { BaseCallComponent } from "./base-call.component";
import { AcceptedCallService } from "./accepted-call.service";
import { HubspotInfoService } from "../shared/components/hubspot/services/hubspot-info.service";
import { ScreenShareService } from "./screen-share.service";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { DragDropCallService } from "../shared/services/dragDropCall/drag-drop-call.service";

@Component({
  selector: "onsip-call",
  templateUrl: "./call.component.html",
  styleUrls: ["./call.component.scss"]
})

// eslint-disable-next-line rxjs-angular/prefer-composition
export class CallComponent extends BaseCallComponent implements OnInit, OnDestroy, OnChanges {
  @Input() callUuid!: string;
  @Input() anonymous = false;

  isWebrtcSupported = true;
  isFullscreen = false;
  E2eLocators = E2eLocators;

  /** Boolean whether or not screen share is open or not */
  hasScreenShare = false;

  /** whether or not screen share should be shown. determined by org-config screenshare state */
  showScreenShare = false;

  private mediaSection: any;

  constructor(
    log: LogService,
    router: Router,
    callAudioService: CallAudioService,
    userAgentService: UserAgentService,
    callControllerService: CallControllerService,
    callTimerService: CallTimerService,
    screenShareService: ScreenShareService,
    callGroupService: CallGroupService,
    snackbarService: SnackbarService,
    analyticsService: AnalyticsService,
    webAudioService: WebAudioService,
    volumeService: VolumeService,
    firestoreCallService: FirestoreCallService,
    dialog: MatDialog,
    snackbar: MatSnackBar,
    acceptedCallService: AcceptedCallService,
    hubspotInfoService: HubspotInfoService,
    translate: TranslateService,
    dialpadService: DialpadService,
    private supportService: SupportService,
    public dragDropCallService: DragDropCallService
  ) {
    super(
      log,
      router,
      callAudioService,
      userAgentService,
      callControllerService,
      callTimerService,
      acceptedCallService,
      screenShareService,
      callGroupService,
      snackbarService,
      analyticsService,
      webAudioService,
      volumeService,
      hubspotInfoService,
      firestoreCallService,
      dialog,
      snackbar,
      translate,
      dialpadService
    );
  }

  @HostListener("window:keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    const eventTarget = event.target as HTMLElement;
    // By default, keypress in input fields will not enable dtmf.
    // If input is supposed to trigger dtmf, look to add a DialpadInputDirective.
    // The following flag also prevents multiple firing of the same events from input fields
    if (eventTarget.tagName === "INPUT") return;
    if (
      this.currentCall &&
      this.currentCall.connected &&
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].some(key => key === event.key)
    ) {
      this.dialpadService.startKey(event.key, "keyboard", "call");
    }
  }

  @HostListener("window:keyup", ["$event"]) onKeyUp(event: KeyboardEvent): void {
    if (!this.dialpadService) {
      return;
    } else if (
      this.currentCall &&
      this.currentCall.connected &&
      ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].some(key => key === event.key)
    ) {
      this.dialpadService.stopKey(event.key, "keyboard", "call");
    }
  }

  ngOnInit(): void {
    ["", "webkit", "ms", "moz"].forEach(prefix =>
      document.addEventListener(prefix + "fullscreenchange", this.fullscreenCheck.bind(this), false)
    );

    this.isWebrtcSupported = this.supportService.isWebrtcSupported();

    super.ngOnInit();

    this.unsubscriber.add(
      this.screenShareService.state.pipe().subscribe(() => {
        this.hasScreenShare = this.screenShareService.calls[this.callUuid];
      })
    );

    if (this.saysoCall !== undefined) {
      this.showScreenShare = this.saysoCall.stateValue.allowScreenshare;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(): void {
    this.hasScreenShare = this.screenShareService.calls[this.callUuid];
    this.genericOnChanges();
  }

  fullscreenCheck(): void {
    const doc: any = document;
    if (this.isFullscreen) {
      this.isFullscreen = !!(
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement
      );
    }
  }

  toggleFullScreen(): void {
    const doc: any = document, // HACK to circumvent typing
      fullScreenElement: any =
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement;

    this.mediaSection = this.mediaSection || document.querySelector(".video-session");

    if (this.mediaSection.requestFullScreen) {
      if (fullScreenElement) {
        document.exitFullscreen();
      } else {
        this.mediaSection.requestFullScreen();
      }
    } else if (this.mediaSection.mozRequestFullScreen) {
      if (fullScreenElement) {
        doc.mozCancelFullScreen();
      } else {
        this.mediaSection.mozRequestFullScreen();
      }
    } else if (this.mediaSection.webkitRequestFullScreen) {
      if (fullScreenElement) {
        doc.webkitExitFullscreen();
      } else {
        this.mediaSection.webkitRequestFullScreen();
      }
    }
    this.isFullscreen = !!(
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    );
  }
}
