import { Component } from "@angular/core";
import { ShellNavigation } from "@onsip/web/features/shared/components/shellNavigations/shell-navigations.component";
import { views } from "@onsip/web/app/phone/views";

@Component({
  selector: "onsip-developer-shell-navigation",
  templateUrl: "./developer-shell-navigation.component.html",
  styleUrls: ["./developer-shell-navigation.component.scss"]
})
export class DeveloperShellNavigationComponent {
  views = views;
  shellNavigations: Array<ShellNavigation> = [
    {
      name: "First url",
      url: this.views.DEVELOPER_FIRST_TEST_NAV
    },
    {
      name: "Second url",
      url: this.views.DEVELOPER_SECOND_TEST_NAV
    },
    {
      name: "Third url",
      url: this.views.DEVELOPER_THIRD_TEST_NAV
    }
  ];
}
