import { genericApiAction, ApiError } from "./onsip-api-action";
import { ApiFailure } from "./api-failure";
import { StateEmitter } from "../../libraries/emitter";
import { OAuth2SessionCreate } from "./apiResponse/oauth-2-session-create";
import { EstablishedOnsipAPISession } from "./apiResponse/context";
import { onsipApiArrayToArray, OnsipAPINotEmptyArray } from "./apiResponse/xml-json";
import { getHighestRolePriority, Role } from "./role";
import { OnsipApiResponse } from "./apiResponse/response-body";
import { ApiAction, ApiReadAction } from "./api-actions";
import { LoginError, LoginErrorCode } from "./login-error";
import { ApiUser } from "./resources/user/user";
export interface OAuthInfo {
  Service: string | undefined;
  ServiceUserId: string | undefined;
  Token: string | undefined;
  UserOAuth2AccessTokenId: string | undefined;
}

export interface APISessionObject {
  SessionId: string;
  UserId: string;
  ParentUserId?: string;
  Roles: OnsipAPINotEmptyArray<"Role", { Name: Role }>;
}

export interface APICredentialsState {
  SessionId: string | undefined;
  UserId: string | undefined;
  ParentUserId: string | undefined;
  OrganizationId: string | undefined;
  AccountId: string | undefined;
  thirdPartySignInInfo: OAuthInfo | undefined;
  role: Role | undefined;
  loggedIn: boolean;
  loginInProgress: boolean;
  loggedInRole: Role | undefined;
}

enum ERRORS_CODE {
  "UsernamePasswordAuthentication" = "UsernamePassword.Authentication",
  "BetaRestricted" = "Beta.Restricted",
  "PortalPasswordTooShort" = "PortalPassword.TooShort"
}

const debug = false;

export class APICredentials extends StateEmitter<APICredentialsState> {
  private dummySessionId: string | undefined;
  private unclaimedToken: OAuthInfo | undefined;
  private sessionCreationFunction: (() => Promise<APISessionObject>) | undefined;
  /** stored agent sessions so we can correctly exit multiple session spoofing */
  private storedAgentSessions: Array<APISessionObject> = [];

  private static initializer(): APICredentialsState {
    return {
      SessionId: undefined,
      UserId: undefined,
      ParentUserId: undefined,
      OrganizationId: undefined,
      AccountId: undefined,
      thirdPartySignInInfo: undefined,
      role: undefined,
      loggedIn: false,
      loginInProgress: false,
      loggedInRole: undefined
    };
  }

  constructor() {
    super(APICredentials.initializer());
  }

  /**
   * Resets the API service to initial state.
   */
  reset(): void {
    // reset

    this.dummySessionId = undefined;
    this.sessionCreationFunction = undefined;

    //
    // init
    //

    this.stateStore = APICredentials.initializer();
    this.publishState();
  }

  /**
   * Creates an API session.
   * @param username Username
   * @param password Password
   */
  SessionCreate(username: string, password: string): Promise<APISessionObject> {
    debug && console.log("APICredentials - SessionCreate");

    // no auth username logins
    if (!username || !password || !username.includes("@")) {
      return Promise.reject(
        new LoginError("Invalid username or password.", LoginErrorCode.AuthenticationFailure)
      );
    }

    return this.loginBegin(
      genericApiAction(ApiAction.SessionCreate, {
        Username: username,
        Password: password
      })
    ).then(response => {
      const oauthInfo: OAuthInfo | undefined = this.unclaimedToken;
      const sessionInfo = response.Context.Session as EstablishedOnsipAPISession;
      if (oauthInfo) {
        const params = {
          SessionId: sessionInfo.SessionId,
          Service: oauthInfo.Service,
          ServiceUserId: oauthInfo.ServiceUserId,
          OAuthToken: oauthInfo.Token,
          UserId: sessionInfo.UserId
        };
        genericApiAction<any>(ApiAction.UserOAuth2AccessTokenClaim, params).then(response2 => {
          this.stateStore.thirdPartySignInInfo =
            response2.Result.UserOAuth2AccessTokenClaim.UserOAuth2AccessToken;
          this.publishState();
        });

        this.unclaimedToken = undefined; // ensures we only call claim once
      } else {
        this.stateStore.thirdPartySignInInfo = {
          Service: undefined,
          ServiceUserId: undefined,
          Token: undefined,
          UserOAuth2AccessTokenId: undefined
        };
      }

      return this.loginSuccess(sessionInfo, () => this.SessionCreate(username, password));
    });
  }

  OAuth2SessionCreate(
    authCode: string,
    chatService: string,
    redirectUri: string
  ): Promise<OnsipApiResponse<"OAuth2SessionCreate", OAuth2SessionCreate>> {
    return this.loginBegin<OAuth2SessionCreate>(
      genericApiAction<OAuth2SessionCreate>(ApiAction.OAuth2SessionCreate, {
        Service: chatService,
        AuthorizationCode: authCode,
        RedirectUri: redirectUri
      })
    ).then(response => {
      const userOauth2AccessToken = response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;

      response.Result.OAuth2SessionCreate.UserSummaries = response.Result.OAuth2SessionCreate
        .UserSummaries || { UserSummary: [] };

      let users = response.Result.OAuth2SessionCreate.UserSummaries.UserSummary;
      if (!Array.isArray(users)) {
        if (users && (users as any).UserId) {
          users = [users];
        } else {
          users = [];
        }
      }
      response.Result.OAuth2SessionCreate.UserSummaries.UserSummary = users;

      const summaries: Array<any> = [];
      response.Result.OAuth2SessionCreate.UserSummaries.UserSummary.forEach(summary => {
        let address = summary.Addresses.Address;
        if (!Array.isArray(address)) {
          if (address && (address as any).Address) {
            address = [address];
          } else {
            address = [];
          }
        }
        summary.Addresses.Address = address;
        summaries.push(summary);
      });
      response.Result.OAuth2SessionCreate.UserSummaries.UserSummary = summaries;

      if (users.length === 0 || users.length > 1) {
        if (users.length === 0) {
          // no users associated with this oauth user, so save for later when it can be claimed
          this.unclaimedToken = userOauth2AccessToken;
        } else if (response.Context.Session.IsEstablished) {
          this.dummySessionId = (response.Context.Session as APISessionObject).SessionId;
        }
        this.stateStore.loginInProgress = false;
        return response;
      } else if (response.Context.Session) {
        this.oauthLoginSuccess(response.Context.Session as APISessionObject, userOauth2AccessToken);
      }

      return response;
    });
  }

  OAuth2SessionReplaceUser(userId: any, userOAuth2AccessToken: any): Promise<any> {
    const params = {
      SessionId: this.dummySessionId,
      UserId: userId,
      Service: userOAuth2AccessToken.Service,
      ServiceUserId: userOAuth2AccessToken.ServiceUserId,
      OAuthToken: userOAuth2AccessToken.Token
    };
    return this.loginBegin<any>(genericApiAction(ApiAction.OAuth2SessionReplaceUser, params))
      .then(response => {
        this.oauthLoginSuccess(
          response.Context.Session as APISessionObject,
          response.Result.OAuth2SessionReplaceUser.UserOAuth2AccessToken
        );
      })
      .catch(error => {
        console.error(error);
      });
  }

  SessionRecreate(SessionId: string, UserId: string): Promise<APISessionObject> {
    return this.loginBegin<any>(
      genericApiAction(ApiAction.SessionRecreate, { SessionId, UserId })
    ).then(response => {
      const session = response.Context.Session as APISessionObject;
      this.loginSuccess(session, () => {
        return this.SessionRecreate(session.SessionId, session.UserId).catch(() => {
          console.error("Recreate Session has expired, no chance of recovery, will be logged out");
          throw new LoginError(
            "Login failed - CookieRecreateFailure",
            LoginErrorCode.CookieRecreateFailure
          );
        });
      });
      return session;
    });
  }

  /** allow a super user to swap to a different user and essentially spoof as that user */
  SessionSubstituteUser(UserId: string): Promise<void> {
    this.stateStore.ParentUserId = this.stateValue.UserId;
    const SessionId = this.stateStore.SessionId;
    if (SessionId) {
      return genericApiAction<any>(ApiAction.SessionSubstituteUser, { UserId, SessionId })
        .then(response => {
          debug && console.warn(response);
          const session = response.Context.Session as APISessionObject;
          return this.updateStateStoreAfterLogin(session);
        })
        .catch(error => {
          console.error(error);
          throw new Error("You do not have permission to swap to given user id");
        });
    } else {
      throw new Error("There was no session id to verify user session");
    }
  }

  /** restores session back to the super admin */
  SessionRestoreUser(): Promise<void> {
    this.stateStore.UserId = this.stateValue.ParentUserId;
    this.stateStore.ParentUserId = undefined;
    const SessionId = this.stateStore.SessionId;
    if (SessionId) {
      return genericApiAction<any>(ApiAction.SessionRestoreUser, { SessionId })
        .then(response => {
          const session = response.Context.Session as APISessionObject;
          return this.updateStateStoreAfterLogin(session);
        })
        .catch(error => {
          console.error(error);
          throw new Error("You do not have permission to restore back to previous session");
        });
    } else {
      throw new Error("There was no session id to verify user session");
    }
  }

  /** allow an agent to swap to a different user and essentially spoof as that user */
  AgentSessionSubstituteUser(UserId: string): Promise<void> {
    const SessionId = this.stateStore.SessionId;
    const role = this.stateStore.role;
    const currentUserId = this.stateStore.UserId;
    if (SessionId && role && currentUserId) {
      // store current session in storedAgentSessions
      this.storedAgentSessions.push({
        SessionId,
        UserId: currentUserId,
        ParentUserId: this.stateStore.ParentUserId,
        Roles: { Role: { Name: role } }
      });
      const session: APISessionObject = {
        SessionId,
        UserId,
        ParentUserId: this.stateStore.UserId,
        Roles: { Role: { Name: role } }
      };
      return this.updateStateStoreAfterLogin(session);
    } else {
      throw new Error("There was no session id to verify user session");
    }
  }

  getLastAgentSession(): APISessionObject | undefined {
    return this.storedAgentSessions[this.storedAgentSessions.length - 1];
  }

  /** allow an agent to restore to previous session */
  AgentSessionRestoreUser(): Promise<void> {
    const SessionId = this.stateStore.SessionId;
    const role = this.stateStore.role;
    const UserId = this.stateStore.ParentUserId;
    const ParentUserId = this.getLastAgentSession()?.ParentUserId;
    if (SessionId && role && UserId) {
      const session: APISessionObject = {
        SessionId,
        UserId,
        ParentUserId,
        Roles: { Role: { Name: role } }
      };
      this.storedAgentSessions.pop();
      return this.updateStateStoreAfterLogin(session);
    } else {
      throw new Error("There was no session id to verify user session");
    }
  }

  // only used in onboarding
  OAuth2KnownSessionCreate(tokenObj: any): Promise<void> {
    return this.OAuth2KnownSessionCreateUtil(tokenObj).then(response => {
      const responseToken = response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;
      if (!responseToken) {
        throw new Error("apiService OAuth2RecreateSession: Token undefined");
      }
      this.oauthLoginSuccess(response.Context.Session, responseToken);
    });
  }

  SignupAccountConfirm(params: any): Promise<any> {
    return this.loginBegin<any>(genericApiAction(ApiAction.SignupAccountConfirm, params)).then(
      response => {
        const session = response.Context.Session as APISessionObject;
        this.loginSuccess(session, () => Promise.resolve(session));
        // TODO consider what happens when the session needs to be recreated
        return response;
      }
    );
  }

  useSessionCreationFunction(): Promise<APISessionObject> {
    this.stateStore.loggedIn = false;
    if (this.sessionCreationFunction) {
      return this.sessionCreationFunction();
    }
    throw new Error("No session recreate function stored");
  }

  apiErrorToLoginError(error: any): LoginError | Error {
    if (error instanceof LoginError) {
      // Already a LoginError
      return error;
    } else if (error instanceof ApiError) {
      // API responded with an error
      if (
        error.code === ERRORS_CODE.UsernamePasswordAuthentication ||
        error.code === ERRORS_CODE.PortalPasswordTooShort
      ) {
        return new LoginError(
          "Invalid username or password.",
          LoginErrorCode.AuthenticationFailure
        );
      } else if (error.code === ERRORS_CODE.BetaRestricted) {
        return new LoginError(error.message, LoginErrorCode.BetaRestricted);
      }
      return new LoginError("Login failed - " + error.code, LoginErrorCode.UnknownError);
    } else if (error instanceof ApiFailure) {
      // API returned non-200 reponse or some other issue
      return new LoginError("Login failed - API Failure", LoginErrorCode.ApiFailure);
    } else if (error instanceof TypeError) {
      // a network error occured trying to connect to the API
      return new LoginError("Login failed - Network Failure", LoginErrorCode.NetworkFailure);
    } else if (error instanceof Error) {
      // some unexpected Error was returned
      return error;
    } else {
      // some unexpected thing was returned (not an Error object)
      throw new Error("An unexpected API error occured - " + error);
    }
  }

  private loginBegin<T>(loginAction: Promise<any>): Promise<OnsipApiResponse<string, T>> {
    if (this.stateStore.loginInProgress) {
      return Promise.reject(new Error("IdentityService.login: login in progress."));
    }
    if (this.stateStore.loggedIn) {
      return Promise.reject(new Error("IdentityService.login: already logged in."));
    }

    this.stateStore.loginInProgress = true;
    this.publishState();

    return Promise.race([
      new Promise<any>((resolve, reject) => {
        setTimeout(
          reject,
          10 * 1000,
          new LoginError("Request timed out", LoginErrorCode.RequestTimeout)
        );
      }),
      loginAction
    ])
      .then(result => (result.raw ? result.raw : result)) // check for the full OnsipApiResponse first, not possibly typed data
      .catch(error => {
        this.loginFailure(error);
      });
  }

  private OAuth2RecreateSession(userTokenRecord: any): Promise<APISessionObject> {
    console.log(
      "apiService - OAuth2RecreateSession: replacing expired session",
      this.stateStore.SessionId,
      "using access token"
    );
    return this.OAuth2KnownSessionCreateUtil(userTokenRecord).then(response => {
      const responseToken = response.Result.OAuth2SessionCreate.UserOAuth2AccessToken;
      if (!responseToken) {
        throw new Error("apiService OAuth2RecreateSession: Token undefined");
      }
      console.log(
        "apiService - OAuth2RecreateSession: replaced expired session",
        this.stateStore.SessionId,
        "using access token"
      );
      return this.oauthLoginSuccess(response.Context.Session, responseToken);
    });
  }

  private oauthLoginSuccess(session: APISessionObject, token: OAuthInfo): APISessionObject {
    this.stateStore.thirdPartySignInInfo = token;
    return this.loginSuccess(session, () => this.OAuth2RecreateSession(token));
  }

  private loginSuccess(
    session: APISessionObject,
    createFunc: (() => Promise<APISessionObject>) | undefined
  ): APISessionObject {
    this.updateStateStoreAfterLogin(session, createFunc);

    return session;
  }

  updateStateStoreAfterLogin(
    session: APISessionObject,
    createFunc?: () => Promise<APISessionObject>
  ): Promise<void> {
    return genericApiAction<any>(ApiReadAction.UserRead, {
      UserId: session.UserId,
      SessionId: session.SessionId
    })
      .then(response => {
        const user = response.Result.UserRead.User as ApiUser;
        this.stateStore.loginInProgress = false;
        this.stateStore.loggedIn = true;
        this.stateStore.SessionId = session.SessionId;
        this.stateStore.UserId = session.UserId;
        this.stateStore.ParentUserId = session.ParentUserId;
        this.stateStore.AccountId = user.AccountId;
        this.stateStore.OrganizationId = user.OrganizationId;
        this.stateStore.role = getHighestRolePriority(
          onsipApiArrayToArray(user.Roles, "Role").map(role => role.Name)
        );
        if (!this.stateStore.loggedInRole) {
          this.stateStore.loggedInRole = getHighestRolePriority(
            onsipApiArrayToArray(session.Roles, "Role").map(role => role.Name)
          );
        }
        this.sessionCreationFunction = createFunc;
        this.publishState();
      })
      .catch(error => {
        console.error("User ID does not exist", error);
        throw new Error(error);
      });
  }

  private loginFailure(error: Error): void {
    this.stateStore.loginInProgress = false;
    this.stateStore.loggedIn = false;
    this.publishState();
    throw this.apiErrorToLoginError(error);
  }

  private OAuth2KnownSessionCreateUtil(userTokenRecord: any): Promise<any> {
    return this.loginBegin(
      genericApiAction(ApiAction.OAuth2SessionCreate, {
        Service: userTokenRecord.Service,
        ServiceUserId: userTokenRecord.ServiceUserId,
        UserId: userTokenRecord.UserId,
        OAuthToken: userTokenRecord.Token
      })
    );
  }
}
