@if (recordingTypeControl.value; as recordingType) {
<form [formGroup]="recordingsForm">
  @if (hasSelect) {
  <mat-radio-group class="radio-groups" [formControl]="recordingTypeControl" color="primary">
    <mat-radio-button #selectToggle class="m-b-14" value="select">
      Select from existing
    </mat-radio-button>
    @if (recordingType === RecordingTypes.SELECT) {
    <div>
      <mat-form-field appearance="outline">
        <mat-select formControlName="select" [compareWith]="compareWithOptions">
          @for (recording of recordingsOptions$ | async; track recording) {
          <mat-option [value]="recording">
            {{ recording.name }}
          </mat-option>
          }
        </mat-select>
        <mat-error>{{ "ONSIP_I18N._VALUE__IS_REQUIRED" | translate }}</mat-error>
      </mat-form-field>
      <audio
        controls
        controlsList="nodownload"
        class="m-b-14"
        [src]="(recordingUrlsByType$ | async)?.select"
      ></audio>
      @if (hasSave && recordingControl.value) {
      <button mat-flat-button class="full-width-btn m-b-14" color="primary" (click)="saved.emit()">
        {{ "ONSIP_I18N.SAVE" | translate }}
      </button>
      }
    </div>
    }
    <mat-radio-button #recordToggle class="m-b-14" value="record">
      Create custom {{ entityLabel }}
    </mat-radio-button>
    @if (recordingType === RecordingTypes.RECORD) {
    <div class="audio-recorder-container">
      <onsip-audio-recorder
        [inputFile]="(recordingUrlsByType$ | async)?.record || ''"
        (outputBlob)="handleRecordedBlob($event)"
      >
        @if (hasSave && recordingControl.value) {
        <button mat-flat-button class="full-width-btn" color="primary" (click)="saved.emit()">
          {{ "ONSIP_I18N.SAVE" | translate }}
        </button>
        }
      </onsip-audio-recorder>
    </div>
    }
    <mat-radio-button #uploadToggle class="m-b-14" value="upload">
      Upload a .wav file
    </mat-radio-button>
    @if (recordingType === RecordingTypes.UPLOAD) {
    <div>
      <input
        #fileInput
        type="file"
        accept="audio/wav"
        (change)="onFileChange(fileInput)"
        class="record-upload-input"
        name="UnavailableGreeting"
      />
      @if (recordingControl.value) {
      <span class="upload-record">
        <span class="upload-record-file-replace">
          <div class="upload-record-name">{{ recordingControl.value.name }}</div>
          <button mat-button type="button" color="primary" (click)="fileInput.click()">
            Replace
          </button>
        </span>
        @if (recordingUrlsByType$ | async; as recordingUrlsByType) {
        <div class="record-audio-container">
          <audio
            class="record-audio"
            controls
            controlsList="nodownload"
            [src]="recordingUrlsByType.upload ? recordingUrlsByType.upload : ''"
          ></audio>
        </div>
        }
        <div
          class="action-buttons"
          [class.action-buttons--one-column]="!(hasSave && recordingControl.value)"
        >
          <button mat-stroked-button type="button" color="primary" (click)="removeFileUpload()">
            Discard
          </button>
          @if (hasSave && recordingControl.value) {
          <button mat-flat-button type="submit" color="primary" (click)="saved.emit()">
            {{ "ONSIP_I18N.SAVE" | translate }}
          </button>
          }
        </div>
      </span>
      } @else {
      <button mat-stroked-button type="button" color="primary" (click)="fileInput.click()">
        Choose file
      </button>
      }
    </div>
    }
  </mat-radio-group>
  } @if (recordingControl.invalid) {
  <div class="error">The file {{ this.recordingControl.value?.name }} already exists</div>
  }
</form>
@if (!hasSelect) {
<div class="btn-toggle-template">
  <mat-button-toggle-group
    hideSingleSelectionIndicator
    class="greeting-recorder-button-toggle-group"
    [value]="recordingTypeControl.value"
    (change)="onBtnTypeChange($event)"
  >
    <mat-button-toggle #recordToggle class="toggle" [value]="RecordingTypes.RECORD"
      >Record {{ entityLabel }}</mat-button-toggle
    >
    <mat-button-toggle #uploadToggle class="toggle" [value]="RecordingTypes.UPLOAD"
      >Upload {{ entityLabel }}</mat-button-toggle
    >
  </mat-button-toggle-group>
  @if (recordingType === RecordingTypes.RECORD) {
  <div class="audio-recorder-container">
    <onsip-audio-recorder
      [inputFile]="(recordingUrlsByType$ | async)?.record || ''"
      (outputBlob)="handleRecordedBlob($event)"
    >
      @if (hasSave && recordingControl.value) {
      <button mat-flat-button class="full-width-btn" color="primary" (click)="saved.emit()">
        {{ "ONSIP_I18N.SAVE" | translate }}
      </button>
      }
    </onsip-audio-recorder>
  </div>
  } @if (recordingType === RecordingTypes.UPLOAD) {
  <div>
    <input
      #fileInput
      type="file"
      accept="audio/wav"
      (change)="onFileChange(fileInput)"
      class="greeting-upload-input"
      name="UnavailableGreeting"
    />
    @if((recordingUrlsByType$ | async)?.upload) {
    <span class="upload-greeting">
      <div class="upload-greeting-file-replace">
        <span>{{ recordingControl.value?.name }}</span>
        <button mat-button type="button" color="primary" (click)="fileInput.click()">
          Replace
        </button>
      </div>
      <div class="greetings-audio-container">
        <audio
          controls
          controlsList="nodownload"
          [src]="(recordingUrlsByType$ | async)?.upload"
        ></audio>
        <a [href]="(recordingUrlsByType$ | async)?.upload" download type="audio/wav">
          <mat-icon aria-label="download_greetings">get_app</mat-icon>
        </a>
      </div>
      <div
        class="action-buttons"
        [class.action-buttons--one-column]="!(hasSave && recordingControl.value)"
      >
        <button mat-stroked-button type="button" color="primary" (click)="removeFileUpload()">
          {{ "ONSIP_I18N.DISCARD" | translate }}
        </button>
        @if (hasSave && recordingControl.value) {
        <button mat-flat-button type="submit" color="primary" (click)="saved.emit()">
          {{ "ONSIP_I18N.SAVE" | translate }}
        </button>
        }
      </div>
    </span>
    } @else {
    <button mat-stroked-button type="button" color="primary" (click)="fileInput.click()">
      {{ "ONSIP_I18N.CHOOSE_FILE" | translate }}
    </button>
    }
  </div>
  }
</div>
} }
