import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  Input,
  HostBinding,
  ElementRef
} from "@angular/core";
import { MatCell } from "@angular/material/table";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { CdkColumnDef } from "@angular/cdk/table";

@Component({
  selector: "onsip-table-shelf-cell, td[onsip-table-shelf-cell]",
  template: `
    <div
      class="onsip-table-shelf-cell-wrapper"
      [class.default-shelf]="!override"
      [class.expanded]="expanded"
      [style.width]="'100%'"
      [style.box-sizing]="'border-box'"
      [@expand]="expanded ? 'expanded' : 'collapsed'"
    >
      <ng-content></ng-content>
    </div>
  `,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  exportAs: "onsipTableShelfCell",
  animations: [
    trigger("expand", [
      state("collapsed", style({ height: "0px", minHeight: "0", margin: "0", padding: "0 12px" })),
      state("expanded", style({ height: "*" })),
      transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)"))
    ])
  ]
})
export class TableShelfCellComponent extends MatCell {
  @HostBinding("class.expanded")
  @Input()
  expanded = false;

  @Input() override = false;

  constructor(columnDef: CdkColumnDef, elementRef: ElementRef) {
    super(columnDef, elementRef);
  }
}
