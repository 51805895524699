import { Subscription } from "rxjs";
import { filter, pluck, take } from "rxjs/operators";
import { URI } from "sip.js";

import { EventStateEmitter } from "../emitter/event-state-emitter";
import { UserAgent, UserAgentState } from "./user-agent";
import { DisconnectedUserAgentEvent } from "./user-agent-event";
import { EndCallEventReason } from "./call-event";
import { UUID } from "./uuid";
import { log } from "./log";
import { SessionConfiguration } from "./session-configuration";
import {
  ConnectedSessionEvent,
  ConnectingSessionEvent,
  EndSessionEvent,
  ProgressSessionEvent,
  LastSessionEvent,
  NewSessionEvent,
  ReferredSessionEvent,
  ReplacedSessionEvent,
  SessionEvent,
  TransferFailedSessionEvent
} from "./session-event";

//
// State
//

/**
 * Defines the Session state.
 */
export interface SessionState {
  /** When the Session activated - epoch time in milliseconds. */
  activatedAt: number | undefined;
  /** True if Session audio is enabled. */
  audio: boolean;
  /** True if audio is available from Session peer. (Received SDP with audio.) */
  audioAvailable: boolean;
  /** True if audio is expected to be available from Session peer. (Expecting to receive SDP with audio.) */
  audioExpected: boolean;
  /** True if audio was or will be offered to Session peer. (Sent SDP with audio.) */
  audioRequested: boolean;
  /** True if a blind transfer has been initiated */
  blindTransferInProgress: boolean;
  /** When the Session connected - epoch time in milliseconds. */
  connectedAt: number | undefined;
  /** When the Session first received a provisional response - epoch time in milliseconds. */
  connectingAt: number | undefined;
  /** When the Session deactivated - epoch time in milliseconds. */
  deactivatedAt: number | undefined;
  /** When the Session ended - epoch time in milliseconds. */
  endedAt: number | undefined;
  /** Reason for end of session, undefined if no endedAt */
  endReason: EndCallEventReason | undefined;
  /** True if Session is on hold. */
  hold: boolean;
  /** True if a hold/unhold request is in progress */
  holdInProgress: boolean;
  /** True if Session is incoming. */
  incoming: boolean;
  /** Display name of the local peer. */
  localDisplayName: string | undefined;
  /** URI of the local peer. */
  localUri: string | undefined;
  /** Max call time in milliseconds */
  maxCallTime: number | undefined;
  /** True if Session is muted. */
  mute: boolean;
  /** When the Session first received a provisional response other than 100 Trying - epoch time in milliseconds. */
  progressAt: number | undefined;
  /** Display name of the remote peer. */
  remoteDisplayName: string;
  /** URI of the remote peer. */
  remoteUri: string;
  /** State of UserAgent the Session belongs to. */
  userAgent: UserAgentState;
  /** UUID of the Session. */
  uuid: string;
  /** True if Session video is enabled. */
  video: boolean;
  /** True if video is available from Session peer. (Received SDP with video.) */
  videoAvailable: boolean;
  /** True if video is expected from Session peer. (Expecting to receive SDP with video.) */
  videoExpected: boolean;
  /** True if video was or will be offered to Session peer. (Sent SDP with video.) */
  videoRequested: boolean;
  /** Custom data. */
  xData: string | undefined;
}

//
// Object
//

/**
 * A Session
 */
export class Session extends EventStateEmitter<SessionEvent, SessionState> {
  private _activatedAt: Date | undefined;
  private _audio: boolean;
  private _audioAvailable: boolean;
  private _audioExpected: boolean;
  private _audioInitialized = false;
  private _blindTransferInProgress: boolean;
  private _audioRequested: boolean;
  private _connectedAt: Date | undefined;
  private _connectingAt: Date | undefined;
  private _deactivatedAt: Date | undefined;
  private _endedAt: Date | undefined;
  private _endReason: EndCallEventReason | undefined;
  private _hold: boolean;
  private _holdInProgress: boolean;
  private _incoming: boolean;
  private _maxCallTime: number | undefined;
  private _mute: boolean;
  private _ouid: string | undefined;
  private _progressAt: Date | undefined;
  private _uuid: string;
  private _video: boolean;
  private _videoAvailable: boolean;
  private _videoExpected: boolean;
  private _videoInitialized = false;
  private _videoRequested: boolean;
  private _xData: string | undefined;

  // If this Session received a REFER, the Session which was created as a result. Otherwise undefined.
  private _referral: Session | undefined;
  // If this Session was created as a result of a REFER, the reffered Session. Otherwise undefined.
  private _referred: Session | undefined;
  // If this Session received an INVITE w/Replaces, the Session which replaces it. Otherwise undefined.
  private _replacement: Session | undefined;

  private unsubscriber = new Subscription();

  private static initialState(
    localDisplayName: string | undefined,
    localUri: string | undefined,
    remoteDisplayName: string,
    remoteUri: string,
    userAgent: UserAgentState,
    uuid: string
  ): SessionState {
    return {
      activatedAt: undefined,
      audio: false,
      audioAvailable: false,
      audioExpected: false,
      audioRequested: false,
      blindTransferInProgress: false,
      connectedAt: undefined,
      connectingAt: undefined,
      deactivatedAt: undefined,
      endedAt: undefined,
      endReason: undefined,
      localDisplayName,
      localUri,
      remoteDisplayName,
      hold: false,
      holdInProgress: false,
      incoming: false,
      maxCallTime: undefined,
      mute: false,
      progressAt: undefined,
      remoteUri,
      userAgent,
      uuid,
      video: false,
      videoAvailable: false,
      videoExpected: false,
      videoRequested: false,
      xData: undefined
    };
  }

  /**
   * Creates a new Session.
   * @param userAgent UserAgent owning the Session.
   */
  constructor(
    private _localDisplayName: string | undefined,
    private _localUri: string | undefined,
    private _remoteDisplayName: string,
    private _remoteUri: URI,
    private _userAgent: UserAgent,
    private _configuration?: SessionConfiguration
  ) {
    super(
      Session.initialState(
        _localDisplayName,
        _localUri,
        _remoteDisplayName,
        _remoteUri.toString(),
        _userAgent.stateValue,
        UUID.randomUUID()
      )
    );

    this._activatedAt = undefined;
    this._audio = false;
    this._audioAvailable = false;
    this._audioExpected = false;
    this._audioRequested = false;
    this._blindTransferInProgress = false;
    this._connectedAt = undefined;
    this._connectingAt = undefined;
    this._deactivatedAt = undefined;
    this._endedAt = undefined;
    this._endReason = undefined;
    this._hold = false;
    this._holdInProgress = false;
    this._incoming = false;
    this._maxCallTime = undefined;
    this._mute = false;
    this._progressAt = undefined;
    this._uuid = this.stateStore.uuid;
    this._video = false;
    this._videoAvailable = false;
    this._videoExpected = false;
    this._videoRequested = false;
    this._xData = undefined;

    this.initUserAgentSubscriptions();
  }

  /**
   * Sends positive final reponse to an INVITE.
   */
  accept(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Disposes of a Session.
   * If a Session is never activated, it needs to be disposed of explictly.
   * Otherwise is will be cleaned up automatically when terminated.
   */
  dispose() {
    console.log(`Session[${this._uuid}] dispose`);
    if (this._activatedAt && !this._deactivatedAt) {
      throw new Error(
        `Session[${this._uuid}] activated session has not been deactivated - dispose`
      );
    }
    this.publishStateComplete();
    this.publishEventComplete();
    this.unsubscriber.unsubscribe();
  }

  /**
   * Groups the session with another session.
   * @param session Session to group with.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  groupWith(session: Session): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends DTMF.
   * @param tone Tone to send.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  playDTMF(tone: string): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends a PROGRESS response 183 for call tracking purposes
   */
  callProgressTracking(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends a REFER request.
   * @param uri URI to REFER UAS to.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refer(uri: URI): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends an INVITE request with replaces header (attended transfer).
   * @param session Session to replace the current session with.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  referWithReplaces(transfereeSession: Session): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends a negative final response to an INVITE.
   */
  reject(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Notify observers that this session is being replaced with another one.
   * Used in conjuction with handling an INIVTE w/Replaces.
   * @param replacement The replacement Session.
   */
  replace(replacement: Session): Promise<void> {
    return new Promise(resolve => {
      this.onReplaced(replacement);
      resolve();
    });
  }

  /**
   * Enables or disables audio.
   * @param audio Audio on if true, off if false.
   */
  setAudio(audio: boolean): void {
    this._audio = audio;
    this.publishState();
  }

  /**
   * Flags the Session as having audio available from the peer.
   * @param audioAvailable True if audio is available from peer.
   */
  setAudioAvailable(audioAvailable: boolean): void {
    this._audioAvailable = audioAvailable;
    this.publishState();
  }

  /**
   * Flags the Session as expecting to have audio available from the peer.
   * @param audioExpected True if audio is available from peer.
   */
  setAudioExpected(audioExpected: boolean): void {
    this._audioExpected = audioExpected;
    this.publishState();
  }

  /**
   * Flags the Session as having initialized audio locally.
   */
  setAudioInitialized(): void {
    if (this._audioInitialized === true) {
      return;
    }
    this._audioInitialized = true;
    this.publishState();
  }

  /**
   * Flags the Session as having offered audio to the peer.
   * @param audioRequested True if audio was requested.
   */
  setAudioRequested(audioRequested: boolean): void {
    this._audioRequested = audioRequested;
    this.publishState();
  }

  setBlindTransferInProgress(blindTransferInProgress: boolean): void {
    this._blindTransferInProgress = blindTransferInProgress;
    this.publishState();
  }

  /**
   * Puts Session on hold.
   * @param hold Hold on if true, off if false.
   */
  setHold(hold: boolean): Promise<void> {
    this._hold = hold;
    this.publishState();
    return Promise.resolve();
  }

  /**
   * Puts Session on hold in progress.
   * @param holdInProgress on if hold request is in progress, off if not.
   */
  setHoldInProgress(holdInProgress: boolean): void {
    this._holdInProgress = holdInProgress;
    this.publishState();
  }

  /**
   * Flags the Session as incoming.
   * @param incoming Incoming if true, outgoing if false.
   */
  setIncoming(incoming: boolean): void {
    this._incoming = incoming;
    this.publishState();
  }

  /**
   * Sets a maximum time for session.
   * @param maxCallTime Maximum call time in milliseconds, terminate this amt of time after connectedAt
   */
  setMaxCallTime(maxCallTime: number): void {
    this._maxCallTime = maxCallTime;
    this.unsubscriber.add(
      this.state
        .pipe(
          pluck("connectedAt"),
          filter((connectedAt): connectedAt is number => connectedAt !== undefined),
          take(1)
        )
        .subscribe(connectedAt => {
          const timeoutInterval = maxCallTime - (new Date().getTime() - connectedAt);
          setTimeout(
            () => {
              this.terminate();
            },
            timeoutInterval > 0 ? timeoutInterval : 0
          ); // if timeleft negative terminate immediately
        })
    );
    this.publishState();
  }

  /**
   * Puts Session on mute.
   * @param mute Mute on if true, off if false.
   */
  setMute(mute: boolean): Promise<void> {
    this._mute = mute;
    this.publishState();
    return Promise.resolve();
  }

  /**
   * Sets the display name of the remote peer.
   * @param name The display name of the remote peer.
   */
  setRemoteDisplayName(name: string): void {
    this._remoteDisplayName = name;
    this.publishState();
  }

  /**
   * Sets the URI of the remote peer.
   * @param uri The URI of the remote peer.
   */
  setRemoteUri(uri: URI): void {
    this._remoteUri = uri;
    this.publishState();
  }

  /**
   * Enables or disables video.
   * @param video Video on if true, off if false.
   */
  setVideo(video: boolean): void {
    this._video = video;
    this.publishState();
  }

  /**
   * Flags the Session as having video available from the peer.
   * @param videoAvailable True if video is available from peer.
   */
  setVideoAvailable(videoAvailable: boolean): void {
    this._videoAvailable = videoAvailable;
    this.publishState();
  }

  /**
   * Flags the Session as expecting to have video available from the peer.
   * @param videoExpected True if video is available from peer.
   */
  setVideoExpected(videoExpected: boolean): void {
    this._videoExpected = videoExpected;
    this.publishState();
  }

  /**
   * Flags the Session as having initialized video locally.
   * @param videoInitialized True if video has been initialized locally.
   */
  setVideoInitialized(): void {
    if (this._videoInitialized === true) {
      return;
    }
    this._videoInitialized = true;
    this.publishState();
  }

  /**
   * Flags the Session as having offered video to the peer.
   * @param videoRequested True if video was requested.
   */
  setVideoRequested(videoRequested: boolean): void {
    this._videoRequested = videoRequested;
    this.publishState();
  }

  /**
   * Flags the Session as having offered video to the peer.
   * @param videoRequested True if video was requested.
   */
  setXData(data: string): void {
    this._xData = data;
    this.publishState();
  }

  /**
   * Terminates a Session regardless of current state.
   */
  terminate(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * When Session activated otherwise undefined.
   */
  get activatedAt(): Date | undefined {
    return this._activatedAt;
  }

  /**
   * True if audio is currently enabled.
   */
  get audio(): boolean {
    return this._audio;
  }

  /**
   * True if audio is availble from peer.
   */
  get audioAvailable(): boolean {
    return this._audioAvailable;
  }

  /**
   * True if audio is expected from peer.
   */
  get audioExpected(): boolean {
    return this._audioExpected;
  }

  /**
   * True if local audio has been initialized.
   */
  get audioInitialized(): boolean {
    return this._audioInitialized;
  }

  /**
   * True if audio was or will be offered to peer.
   */
  get audioRequested(): boolean {
    return this._audioRequested;
  }

  get blindTransferInProgress(): boolean {
    return this._blindTransferInProgress;
  }

  set configuration(configuration: SessionConfiguration | undefined) {
    this._configuration = configuration;
  }

  /**
   * configuration options for the session (only for initialization at this point)
   */
  get configuration(): SessionConfiguration | undefined {
    return this._configuration;
  }

  /**
   * When Session connected otherwise undefined.
   */
  get connectedAt(): Date | undefined {
    return this._connectedAt;
  }

  /**
   * When Session received first provisional response otherwise undefined.
   */
  get connectingAt(): Date | undefined {
    return this._connectingAt;
  }

  /**
   * When the Session deactivated.
   */
  get deactivatedAt(): Date | undefined {
    return this._deactivatedAt;
  }

  /**
   * When the Session ended.
   */
  get endedAt(): Date | undefined {
    return this._endedAt;
  }

  /**
   * Reason the Session ended.
   */
  get endReason(): EndCallEventReason | undefined {
    return this._endReason;
  }

  /**
   * True if Session is on hold.
   */
  get hold(): boolean {
    return this._hold;
  }

  /**
   * True if a hold/unhold request is in progress.
   */
  get holdInProgress(): boolean {
    return this._holdInProgress;
  }

  /**
   * True if Session is incoming.
   */
  get incoming(): boolean {
    return this._incoming;
  }

  /**
   * The display name of the local peer.
   */
  get localDisplayName(): string | undefined {
    return this._localDisplayName;
  }

  /**
   * The URI of the local peer.
   */
  get localUri(): string | undefined {
    return this._localUri;
  }

  /**
   * Maximum time for session in milliseconds.
   */
  get maxCallTime(): number | undefined {
    return this._maxCallTime;
  }

  /**
   * True if Session is on mute.
   */
  get mute(): boolean {
    return this._mute;
  }

  /**
   * The OUID associated with the Session.
   * This is a proprietary id used to associate a "stream" of Sessions.
   * There are use cases where it cannot be set at configuration time,
   * so access is provided via a setter/getter.
   * @param ouid The OUID.
   */
  set ouid(ouid: string | undefined) {
    this._ouid = ouid;
  }

  /**
   * The OUID associated with the Session.
   */
  get ouid(): string | undefined {
    return this._ouid;
  }

  /**
   * When Session received first provisional response other than 100 Trying otherwise undefined.
   */
  get progressAt(): Date | undefined {
    return this._progressAt;
  }

  /**
   * The Session that was created via receipt of a REFER.
   */
  get referral(): Session | undefined {
    return this._referral;
  }

  /**
   * The Session that received a REFER resulting in the creation of this Session.
   */
  get referred(): Session | undefined {
    return this._referred;
  }

  /**
   * The display name of the remote peer.
   */
  get remoteDisplayName(): string {
    return this._remoteDisplayName;
  }

  /**
   * The URI of the remote peer.
   */
  get remoteUri(): URI {
    return this._remoteUri;
  }

  /**
   * The Session that replaced this one via receipt of an INVITE w/Replaces.
   */
  get replacement(): Session | undefined {
    return this._replacement;
  }

  /**
   * The UserAgent this session belongs to.
   */
  get userAgent(): UserAgent {
    return this._userAgent;
  }

  /**
   * UUID of this session.
   */
  get uuid(): string {
    return this._uuid;
  }

  /**
   * True if video is currently enabled.
   */
  get video(): boolean {
    return this._video;
  }

  /**
   * True if video is availble from peer.
   */
  get videoAvailable(): boolean {
    return this._videoAvailable;
  }

  /**
   * True if video is expected from peer.
   */
  get videoExpected(): boolean {
    return this._videoExpected;
  }

  /**
   * True if local video has been initialized.
   */
  get videoInitialized(): boolean {
    return this._videoInitialized;
  }

  /**
   * True if video was or will be offered to peer.
   */
  get videoRequested(): boolean {
    return this._videoRequested;
  }

  /**
   * Custom data if availabe. Undefined otherwise.
   */
  get xData(): string | undefined {
    return this._xData;
  }

  /**
   * Activates a Session.
   * NewSessionEvent is emitted (initial event).
   */
  protected activate(): void {
    if (this._activatedAt) {
      return;
    }
    this._activatedAt = new Date();
    this.publishState();
    this.publishEvent(NewSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /**
   * Deactivates a Session.
   * LastSessionEvent is emitted (final event).
   * Session destroys itself.
   * @param delay Time in ms to delay deactivation.
   */
  protected deactivate(delay: number = 1000): void {
    if (this._deactivatedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Session[${this._uuid}] has not been activated - deactivate`);
    }
    const timer = setInterval(() => {
      clearInterval(timer);
      this._deactivatedAt = new Date();
      this.publishState();
      this.publishEvent(LastSessionEvent.make(this.userAgent.aor, this.uuid));
      this.dispose();
    }, delay);
  }

  /**
   * Connects a Session.
   * ConnectedSessionEvent is emitted.
   */
  protected didConnect(): void {
    if (this._connectedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Session[${this._uuid}] has not been activated - didConnect`);
    }
    this._connectedAt = new Date();
    this.publishState();
    this.publishEvent(ConnectedSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /**
   * Connecting a Session.
   * ConnectingSessionEvent is emitted.
   */
  protected didConnecting(): void {
    if (this._connectingAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Session[${this._uuid}] has not been activated - didConnecting`);
    }
    this._connectingAt = new Date();
    this.publishState();
    this.publishEvent(ConnectingSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /**
   * Ends a Session.
   * EndSessionEvent is emitted.
   */
  protected didEnd(reason: EndCallEventReason): void {
    if (this._endedAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Session[${this._uuid}] has not been activated - didEnd`);
    }
    this._endedAt = new Date();
    this._endReason = reason;
    this.publishState();
    this.publishEvent(EndSessionEvent.make(this.userAgent.aor, this.uuid, reason));
    this.deactivate();
  }

  /**
   * Progress a Session.
   * PrgoressSessionEvent is emitted.
   */
  protected didProgress(): void {
    if (this._progressAt) {
      return;
    }
    if (!this._activatedAt) {
      throw new Error(`Session[${this._uuid}] has not been activated - didProgress`);
    }
    this._progressAt = new Date();
    this.publishState();
    this.publishEvent(ProgressSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  protected flushState() {
    super.flushState();
    this.stateStore.activatedAt = this._activatedAt ? this._activatedAt.getTime() : undefined;
    this.stateStore.audio = this._audio;
    this.stateStore.audioAvailable = this._audioAvailable;
    this.stateStore.audioExpected = this._audioExpected;
    this.stateStore.audioRequested = this._audioRequested;
    this.stateStore.blindTransferInProgress = this._blindTransferInProgress;
    this.stateStore.connectedAt = this._connectedAt ? this._connectedAt.getTime() : undefined;
    this.stateStore.connectingAt = this._connectingAt ? this._connectingAt.getTime() : undefined;
    this.stateStore.deactivatedAt = this._deactivatedAt ? this._deactivatedAt.getTime() : undefined;
    this.stateStore.endedAt = this._endedAt ? this._endedAt.getTime() : undefined;
    this.stateStore.endReason = this._endReason;
    this.stateStore.hold = this._hold;
    this.stateStore.holdInProgress = this._holdInProgress;
    this.stateStore.incoming = this._incoming;
    this.stateStore.localDisplayName = this._localDisplayName;
    this.stateStore.localUri = this._localUri;
    this.stateStore.mute = this._mute;
    this.stateStore.progressAt = this._progressAt ? this._progressAt.getTime() : undefined;
    this.stateStore.remoteDisplayName = this._remoteDisplayName;
    this.stateStore.remoteUri = this._remoteUri.toString();
    this.stateStore.uuid = this.uuid;
    this.stateStore.video = this._video;
    this.stateStore.videoAvailable = this._videoAvailable;
    this.stateStore.videoExpected = this._videoExpected;
    this.stateStore.videoRequested = this._videoRequested;
    this.stateStore.xData = this._xData;
  }

  /** incoming/outgoing answered */
  protected onAccepted(): void {
    this.didConnect();
    // let the referred session know the INVITE was accepted so it may housekeep
    if (this._referred) {
      this._referred.onReferralAccepted();
    }
  }

  /** outgoing cancelled (sent a CANCEL) */
  protected onCancelled(): void {
    this.didEnd(EndSessionEvent.reasonCancelled);
  }

  /** outgoing failed/rejected (received negative final response) */
  protected onFailed(): void {
    this.didEnd(EndSessionEvent.reasonFailed);
  }

  /** outgoing non-100 provisional received */
  protected onProgress(): void {
    this.didConnecting(); // didConnecting() on any provisional response
    this.didProgress(); // didProgess() on non-100 provisional response
  }

  /** referral accepted */
  protected onReferralAccepted(): void {
    // the INVITE associated with the REFER was accepted, so terminate and cleanup
    this.terminate();
    if (!this._referral) {
      throw new Error("Referred session's referral session is undefined.");
    }
    this._referral._referred = undefined;
  }

  /** been referred */
  protected onReferred(newSession: Session): void {
    // an INVITE associated with a received REFER has been sent, track the new session
    newSession._referred = this;
    this._referral = newSession;
    this.publishEvent(ReferredSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /** session was replaced (received an INVITE w/Replaces) */
  protected onReplaced(newSession: Session): void {
    this._replacement = newSession;
    this.publishEvent(ReplacedSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /** incoming/outgoing dialog ended ended (sent or received a BYE) */
  protected onTerminated(): void {
    this.didEnd(EndSessionEvent.reasonTerminated);
  }

  protected onTransferFailed(): void {
    this.publishEvent(TransferFailedSessionEvent.make(this.userAgent.aor, this.uuid));
  }

  /** outgoing trying response received */
  protected onTrying(): void {
    this.didConnecting(); // didConnecting() on any provisional response
  }

  /** incoming unanswered/declined (sent negative final response) */
  protected onUnanswered(): void {
    this.didEnd(EndSessionEvent.reasonUnanswered);
  }

  private initUserAgentSubscriptions() {
    this.unsubscriber.add(
      this.userAgent.event.subscribe({
        next: x => {
          log.debug(`Session[${this.uuid}].event ${x.id}`);
          switch (x.id) {
            case DisconnectedUserAgentEvent.id:
              // TODO: So... Hum... Guess we solider on and hope for the best...
              break;
            default:
              break;
          }
        },
        error: (error: unknown) => {
          throw error;
        },
        complete: () => {
          // subscription complete and going away, but we cleanup below when the state subscription ends
        }
      })
    );
    this.unsubscriber.add(
      this.userAgent.state.subscribe({
        next: next => {
          this.stateStore.userAgent = next;
          this.publishState();
        },
        error: (error: unknown) => {
          throw error;
        },
        complete: () => {
          // user agent has gone away, oh my...
          if (this._activatedAt) {
            this.deactivate();
          } else {
            this.dispose();
          }
        }
      })
    );
  }
}
