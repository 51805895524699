/**
 * RFC 4122 v4 UUID
 */
export class UUID {
  static randomUUID(): string {
    return UUID.uuidv4();
  }

  // from: https://stackoverflow.com/questions/7905929/how-to-test-valid-uuid-guid
  static isUUID(str: string, version: 3 | 4 | 5 | "all" = "all"): boolean {
    if (typeof str !== "string") {
      return false;
    }
    const pattern: RegExp = uuid[version];
    return pattern.test(str);
  }

  // from: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
  private static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
      const r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  protected constructor() {}
}

// Note that the only difference between versions is the version number,
// which is explained in 4.1.3. Version chapter of UUID 4122 RFC.
const uuid = {
  3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
};
