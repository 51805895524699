import { Injectable, OnDestroy } from "@angular/core";
import { of, Subscription, switchMap, take, tap } from "rxjs";
import { StateEmitter } from "../libraries/emitter";
import { SaysoCallHistory, WebCall } from "./api/resources/webCall/web-call";
import { WebCallService } from "./api/resources/webCall/web-call.service";

import { CallState, SessionState } from "../libraries/firebase/store/call-state";
import { DateService } from "./date.service";
import { Call } from "./sayso/lib/call";
import { ParameterValue } from "./api/util/api-action-description";
import { ApiPromiseState } from "./api/api-promise-state.service";

const debug = false;

export interface WebCallHistoryState {
  webCalls: Array<SaysoCallHistory>;
  loading: boolean;
}

@Injectable({ providedIn: "root" })
export class WebCallHistoryService extends StateEmitter<WebCallHistoryState> implements OnDestroy {
  /** flag for when all web calls have been fetched */
  private allFetched = false;
  /** tracks the last fetched web call. used as an cursor to fetch new web calls */
  private lastSeenWebCall: WebCall | undefined;
  /** store webCalls already fetched */
  private cachedWebCalls: Record<string, WebCall> = {};

  private unsubscriber = new Subscription();

  constructor(private webCallService: WebCallService, private dateService: DateService) {
    super({ webCalls: [], loading: true } as WebCallHistoryState);
    debug && this.state.subscribe(state => console.warn(state));
    this.initWebCallHistory();
  }

  get isAllFetched(): boolean {
    return this.allFetched;
  }

  /** getter to retrieve call from stateStore based on ouid */
  getCallByOuid(ouid: string): SaysoCallHistory | undefined {
    return this.stateValue.webCalls?.find(call => call.ouid === ouid);
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
    this.lastSeenWebCall = undefined;
    this.cachedWebCalls = {};
  }

  initWebCallHistory(): void {
    this.unsubscriber.add(
      this.webCallService.state
        .pipe(
          tap(({ loading }) => (this.stateStore.loading = loading)),
          switchMap(({ loading }) => {
            if (loading === false) {
              return this.webCallService.orgState.pipe(
                take(1),
                tap(webCalls => {
                  webCalls.sort((a, b) => b.hostCreated.localeCompare(a.hostCreated));
                  // get an array of newly fetch webcalls
                  const newWebCalls = webCalls.filter(
                    webCall => !this.cachedWebCalls[webCall.webCallId]
                  );
                  newWebCalls.forEach(webCall => {
                    this.cachedWebCalls[webCall.webCallId] = webCall;
                  });
                  // if there is only one call, do not update the browse cursor
                  // the one call from the "read" api will always interfere with the browse cursor
                  // there is the edge case where the org only has one web call
                  if (newWebCalls.length > 1) {
                    const currentLastWebCall = newWebCalls[newWebCalls.length - 1];
                    this.checkIfLastWebCall(currentLastWebCall);
                    this.lastSeenWebCall = currentLastWebCall;
                  }
                  this.stateStore.webCalls.push(...this.formatWebCalls(newWebCalls));
                  this.stateStore.webCalls.sort((a, b) => b.date.localeCompare(a.date));
                })
              );
            }

            return of([]);
          })
        )
        .subscribe(() => this.publishState())
    );
  }

  webCallBrowse(params?: Record<string, ParameterValue>): void {
    if (params?.StartDateTime && params.EndDateTime) {
      params = this.diffUnfetchedTime(params);
      if (!params) {
        console.warn("Web calls have already been fetched");
        return;
      }
    }
    if (this.lastSeenWebCall && !params?.EndDateTime) {
      if (!params) params = {};
      params.AfterHostCallId = this.lastSeenWebCall.hostCallId;
      params.StartDateTime = new Date(this.lastSeenWebCall.hostCreated).toISOString();
    }
    this.webCallService.webCallBrowse(params);
  }

  /** wrapper around webCallService's webCallBrowseWithOuid so components do not have to import both services */
  webCallBrowseWithOuid(
    ouid: string,
    params?: Record<string, ParameterValue>
  ): ApiPromiseState<WebCall> {
    return this.webCallService.webCallBrowseWithOuid(ouid, params);
  }

  /** insert a new sayso call to stateStore. these are calls created during the user's session */
  addNewSaysoCall(call: Call): void {
    const callValue = call.stateValue;
    let saysoCall;
    if (Object.values(callValue.sessions).length > 0) saysoCall = this.processSaysoCall(callValue);
    if (saysoCall) {
      if (this.stateStore.webCalls) {
        this.stateStore.webCalls.unshift(saysoCall);
      } else {
        this.stateStore.webCalls = [saysoCall];
      }
    }
    this.publishState();
  }

  private formatWebCalls(webCalls: Array<WebCall>): Array<SaysoCallHistory> {
    const saysoCalls: Array<SaysoCallHistory> = [];
    webCalls
      .filter(
        call => Object.values((JSON.parse(call.callData).fields as CallState).sessions).length > 0
      )
      .forEach(call => {
        const callData: CallState = JSON.parse(call.callData).fields;
        const saysoCall = this.processSaysoCall(callData);
        if (saysoCall) saysoCalls.push(saysoCall);
      });
    return saysoCalls;
  }

  private processSaysoCall(callData: CallState): SaysoCallHistory | undefined {
    // Sorting by connectingAt, now the last call must be the only connectedAt session
    // or none of the calls are connected, meaning the sayso call was never answered
    const allSessions: Array<SessionState> = Object.values(callData.sessions).sort(
      (a: SessionState, b: SessionState) => {
        return a.connectingAt && b.connectingAt ? a.connectingAt - b.connectingAt : -1;
      }
    );
    let session: SessionState | undefined;
    const lastSession = allSessions[allSessions.length - 1];
    // If the last session has no connectedAt value, then the sayso call was never answered.
    // In this case we want to display one missed call attributed to the original agent
    if (!lastSession?.connectedAt) {
      session = allSessions[0];
    } else {
      session = lastSession;
    }
    // return undefined if no connected session is found
    if (!session) return undefined;
    // If a connectedAt value exists, it means the call was answered. connectingAt is the time the answered call started ringing
    // Otherwise, if the call was missed, display the time that the initial call was made
    const callTimeAsDate: Date | undefined =
      session.connectedAt && session.connectingAt
        ? new Date(session.connectingAt)
        : session.userAgent.connectedAt
        ? new Date(session.userAgent.connectedAt)
        : undefined;
    const callDuration =
      session.endedAt && session.connectedAt ? session.endedAt - session.connectedAt : undefined;

    return {
      agent: session.remoteDisplayName,
      audio: session.audio,
      callerDisplayName: "sayso Caller",
      date: callTimeAsDate ? callTimeAsDate.toISOString() : "",
      displayDate: callTimeAsDate ? this.dateService.displayDate(callTimeAsDate) : "Unknown",
      duration: callDuration,
      feedback: callData.survey || 0, // 0-5
      hubspotutk: (callData.context.cookie && callData.context.cookie.hubspotutk) || undefined,
      saysoCallFromLink: (callData.context.browser && callData.context.browser.href) || undefined,
      status: session.connectedAt ? "answered" : "missed",
      targetType: callData.targetType,
      time: callTimeAsDate ? callTimeAsDate.toLocaleTimeString() : "",
      topicName: callData.targetType === "topic" ? callData.targetDisplay : "",
      video: session.video,
      uuid: session.uuid,
      ouid: callData.ouid
    };
  }

  private checkIfLastWebCall(call: WebCall | undefined): void {
    if (this.lastSeenWebCall && call) {
      this.allFetched = this.lastSeenWebCall.webCallId === call.webCallId;
    }
  }

  /** parse out only unfetched dates from incoming fetch params.
   * If incoming end date is more recent than last fetched webcall, return and do not fetch.
   */
  private diffUnfetchedTime(
    params: Record<string, ParameterValue>
  ): Record<string, ParameterValue> | undefined {
    if (this.lastSeenWebCall) {
      const lastFetchedTime = new Date(this.lastSeenWebCall.hostCreated).getTime();
      if (new Date(params.EndDateTime as string).getTime() > lastFetchedTime) return; // do not fetch if end date is newer than last fetched date
      // start fetch at lastFetchTime regardless of StartTime, we do not want any time gaps in the statestore
      params.StartDateTime = new Date(lastFetchedTime).toISOString();
    }
    return params;
  }
}
