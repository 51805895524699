import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { GumService } from "../../services/gum/gum.service";

import { Injectable } from "@angular/core";
import { CallState } from "../../../../../common/libraries/sip/call-state";

import {
  isConnectedCallEvent,
  isEndCallEvent
} from "../../../../../common/libraries/sip/call-event";
import { CallContact } from "../../../../../common/libraries/sip/call-contact";
import { CallConfiguration } from "../../../../../common/libraries/sip/call-configuration";
import { filter, take } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class UserAgentService {
  constructor(
    private callControllerService: CallControllerService,
    private gumService: GumService
  ) {
    this.subscribeCallController();

    this.callControllerService.reset();
  }

  /** BEGIN CALL MANAGEMENT SECTION */

  getDisplayStatus(uuid: string): string {
    const call: CallState | undefined = this.callControllerService.getCallStateByUuid(uuid);

    if (!call) {
      return "unknown";
    } else if (call.ended) {
      return "call ended";
    } else if (call.hold) {
      return "on hold";
    } else if (call.connected) {
      return "active";
    } else if (call.ringing) {
      return "incoming call";
    } else if ((!call.incoming || (!call.connected && call.connectedAt)) && call.connecting) {
      return "connecting...";
    }

    return "error";
  }
  /** END CALL MANAGEMENT SECTION */

  /** BEGIN CALL MANIPULATION SECTION */
  createOutboundCall(target: string, options: any, skipAudio: boolean = false): Promise<string> {
    return this.gumService
      .getUserMedia({
        audio: true,
        video: options.isVideoInvite || false
      })
      .then(stream => {
        const callContact: CallContact = new CallContact(target, options.remoteDisplayName),
          callConfig: CallConfiguration = {
            audio: true,
            video: options.isVideoInvite || false,
            sessionConfiguration: {
              sip: {
                inviteOptions: {
                  sessionDescriptionHandlerOptions: {},
                  extraHeaders: options.extraHeaders
                }
              }
            }
          };

        return this.callControllerService.beginCall(callContact, callConfig).then(uuid => {
          if (!skipAudio) {
            this.callControllerService.makeUnheldCall(uuid);
          }

          this.callControllerService
            .getCallEventObservable()
            .pipe(
              filter(event => isEndCallEvent(event) && event.uuid === uuid),
              take(1)
            )
            .subscribe(() => {
              stream.getTracks().forEach(track => {
                track.stop();
              });
            });
          return uuid;
        });
      });
  }

  /** END CALL MANIPULATION SECTION */

  private subscribeCallController(): void {
    this.callControllerService.getCallEventObservable().subscribe(event => {
      const call: CallState | undefined = this.callControllerService.getCallStateByUuid(event.uuid);
      if (call && call.incoming && isConnectedCallEvent(event)) {
        this.callControllerService.makeUnheldCall(event.uuid);
      }
    });

    window.addEventListener(
      "unload",
      () => {
        this.callControllerService.disposeCallController();
      },
      false
    );
  }
}
