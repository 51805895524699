import { Component, ViewChild, HostBinding, OnInit, OnDestroy } from "@angular/core";
import { FormControl, ValidationErrors, Validators } from "@angular/forms";
import { Subscription, distinctUntilChanged } from "rxjs";
import { TogglableRecorderComponent } from "../shared/components/togglableRecorder/togglable-recorder.component";
import { RecordingTypesEnum } from "../shared/components/togglableRecorder/config/recording-types.enum";

import { MatTabChangeEvent } from "@angular/material/tabs";
import { Router } from "@angular/router";
interface MultiLineListItem {
  title: string;
  value: string;
  accentColorClass?: string;
}
import { views } from "@onsip/web/app/phone/views";
import { BarGraphData } from "../administrators/pbxOverview/charts/barGraph/bar-graph.component";
import {
  CdrDateInterval,
  generateIntervalOptions
} from "../administrators/reports/config/date-interval";
import { LineGraphData } from "../administrators/pbxOverview/charts/lineGraph/line-graph.component";

@Component({
  selector: "onsip-developer",
  templateUrl: "./developer.component.html",
  styleUrls: ["./developer.component.scss"]
  // host: { "class": "onsip-grid-content" }
})
export class DeveloperComponent implements OnInit, OnDestroy {
  constructor(private router: Router) {}

  @HostBinding("class.onsip-admin-host-grid")
  _dontUse = true;
  @ViewChild(TogglableRecorderComponent) recorderRef!: TogglableRecorderComponent;
  editItemListControl = new FormControl(["1233", "3455"], { nonNullable: true });
  editItemListValidators = [Validators.minLength(2), Validators.maxLength(6)];
  emailFormControl = new FormControl("", [Validators.required, Validators.email]);

  typesOfShoes: Array<string> = ["Boots", "Clogs", "Loafers", "Moccasins", "Sandals", "Sneakers"];
  customComponentsTabTitle = "Custom components";
  views = views;
  // examples on what kind of custom lists you can use
  multiLinesItemList: Array<Array<MultiLineListItem>> = [
    [
      { title: "Title 1", value: "Value 1" },
      { title: "Title 2", value: "NOT DETECTED", accentColorClass: "accent-500" },
      { title: "Title 3", value: "Value 3" }
    ],
    [
      { title: "Title 4", value: "VALUE 4" },
      { title: "Title 5", value: "Value 5" },
      { title: "Title 6", value: "Value 6", accentColorClass: "accent-500" }
    ]
  ];
  listItems: Array<{ name: string }> = [
    { name: "Item name 1" },
    { name: "Item name 2" },
    { name: "Item name 3" }
  ];

  displayColumns: Array<string> = ["selected", "date", "time", "name", "status"];

  // recorder with togle
  hasSelect = true;
  hasSave = true;

  private unsubscriber = new Subscription();
  // for chart tab
  intervalOptions = generateIntervalOptions();
  dataInterval = new FormControl(this.intervalOptions[4], { nonNullable: true });

  // for line graph
  lineGraphTitle = "Inbound and outbound calling trends";
  lineGraphData: Array<LineGraphData> = [
    {
      name: "Inbound",
      data: []
    },
    {
      name: "Outbound",
      data: []
    }
  ];

  // for donut graph
  activeDonuts = 15;
  inactiveDonuts = 5;
  donutData = [
    { name: "inactive", value: this.inactiveDonuts },
    { name: "active", value: this.activeDonuts }
  ];

  // for bar graph
  destBarData = this.getBarData();
  userBarData = this.getBarData(false);

  todayDate = new Date();

  getOffsetDate(offset: number, startDate: Date = new Date()): Date {
    const dayOffset = 86400000;
    const result = new Date(startDate.getTime() + dayOffset * offset);
    return result;
  }

  save(): void {}

  undo(): void {}

  onRemove(): void {}

  updateData() {
    this.donutData = [
      { name: "inactive", value: this.inactiveDonuts },
      { name: "active", value: this.activeDonuts }
    ];
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "k";
    }
    return value.toString();
  }

  editItemFormatter(value: string): string {
    return `any symbol ${value}`;
  }

  getEditItemListErrorMessage(errors: ValidationErrors | null): string {
    return errors ? "Some error text" : "";
  }

  onChangeTab(event: MatTabChangeEvent): void {
    if (event.tab.textLabel === this.customComponentsTabTitle) {
      this.router.navigate([this.views.DEVELOPER_FIRST_TEST_NAV]);
    }
  }

  onRecorderHasSelectChange(newHasSelect: boolean): void {
    if (
      !newHasSelect &&
      this.recorderRef.recordingTypeControl.value === RecordingTypesEnum.SELECT
    ) {
      this.recorderRef.recordingTypeControl.patchValue(RecordingTypesEnum.RECORD);
    }
  }

  ngOnInit(): void {
    this.generateLineData(this.intervalOptions[4]);
    this.unsubscriber.add(
      this.dataInterval.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value: CdrDateInterval) => {
          // randomly generate data for graphs
          this.generateLineData(value);
          this.activeDonuts = Math.floor(Math.random() * 100);
          this.inactiveDonuts = Math.floor(Math.random() * 100);
          this.updateData();
          this.destBarData = this.getBarData();
          this.userBarData = this.getBarData(false);
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  private generateLineData(value: CdrDateInterval): void {
    const startDate = new Date(value.dateStart);
    const endDate = new Date(value.dateEnd);
    const millisec = endDate.getTime() - startDate.getTime();
    const days = Math.ceil(millisec / 86400000);
    this.lineGraphData = [
      {
        name: "Inbound",
        data: [...Array(days).keys()].map(v => ({
          y: Math.random() * 100,
          x: this.getOffsetDate(v, startDate)
        }))
      },
      {
        name: "Outbound",
        data: [...Array(days).keys()].map(v => ({
          y: Math.random() * 100,
          x: this.getOffsetDate(v, startDate)
        }))
      }
    ];
  }

  private getBarData(haveTooltip = true): Array<BarGraphData> {
    const size = Math.ceil(Math.random() * 4);
    let dataArray = new Array(size).fill(0);
    dataArray = dataArray.map(() => ({
      name: this.getRandomName(),
      value: Math.floor(Math.random() * 25),
      tooltipInfo: haveTooltip ? this.getRandomPhone() : ""
    }));
    dataArray = dataArray.sort((a, b) => b.value - a.value);
    return dataArray;
  }

  private getRandomName(): string {
    const firstName = ["John", "Jane", "James", "Jean", "Jen"];
    const lastName = ["Smith", "Doe", "Johnson", "Williams", "Jones"];
    return (
      firstName[Math.floor(Math.random() * firstName.length)] +
      " " +
      lastName[Math.floor(Math.random() * lastName.length)]
    );
  }

  private getRandomPhone(): string {
    return `1-${Math.floor(Math.random() * 800 + 200).toString()}-${Math.floor(
      Math.random() * 1000
    ).toString()}-${Math.floor(Math.random() * 10000).toString()}`;
  }
}
