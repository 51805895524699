import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from "@angular/core";

import { FileSystemFileEntry, NgxFileDropEntry } from "ngx-file-drop";

import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "onsip-image-upload",
  templateUrl: "./image-upload.component.html",
  styleUrls: ["./image-upload.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageUploadComponent implements OnInit {
  @Input() hasFrame = false;
  @Input() image: string | undefined;
  @Input() isRound = false;
  @Output() deleteCurrentAvatar = new EventEmitter<boolean>();
  imageToBeCropped: any = {};
  theNewValue: any;
  showCropper = new BehaviorSubject<boolean>(false);
  errorText = "";
  errorFormat = "";
  errorSize = "";
  preserveSize = false;
  maintainAspectRatio = false;
  croppedImage: any;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.isRound) {
      // isRound currently means "is avatar" and these are the changes between avatars and logos
      this.preserveSize = false;
      this.maintainAspectRatio = true;
    } else {
      this.preserveSize = true;
      this.maintainAspectRatio = false;
    }

    this.errorFormat = this.translate.instant("ONSIP_I18N.FILE_MUST_BE_AN_IMAGE");
    this.errorSize = this.translate.instant("ONSIP_I18N.FILE_TOO_LARGEMAX_50MB");
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  fileDropped(files: Array<NgxFileDropEntry>): void {
    const potentialFile: NgxFileDropEntry = files[0];

    if (potentialFile.fileEntry.isFile) {
      const fileEntry = potentialFile.fileEntry as FileSystemFileEntry,
        reader: FileReader = new FileReader();

      fileEntry.file((file: File) => {
        const fileType: string = "|" + file.type.slice(file.type.lastIndexOf("/") + 1) + "|";

        if ("|jpg|png|jpeg|bmp|gif|".indexOf(fileType) === -1) {
          this.errorText = this.errorFormat;
          return;
        }
        if (file.size > 5000000) {
          this.errorText = this.errorSize;
          return;
        }

        reader.onloadend = (evt: any): void => {
          const image = evt.target.result;
          this.imageToBeCropped.image = image;
          this.theNewValue = image;
          this.showCropper.next(true);
        };

        reader.readAsDataURL(file);
      });
    }
  }

  fileChosen(event: any) {
    const file: File | undefined = event.target.files[0];

    if (!file) {
      return;
    }
    const reader: FileReader = new FileReader(),
      fileType: string = "|" + file.type.slice(file.type.lastIndexOf("/") + 1) + "|";

    if ("|jpg|png|jpeg|bmp|gif|".indexOf(fileType) === -1) {
      this.errorText = this.errorFormat;
      return;
    }
    if (file.size > 5000000) {
      this.errorText = this.errorSize;
      return;
    }

    reader.onloadend = (evt: any): void => {
      const image = evt.target.result;
      this.imageToBeCropped.image = image;
      this.theNewValue = image;
      this.showCropper.next(true);
    };

    reader.readAsDataURL(file);
  }

  triggerUpload(): void {
    const element: HTMLElement = document.getElementById("onsip-image-file-upload") as HTMLElement;
    element.click();
  }

  loadError(): void {
    this.errorText = this.errorFormat;
    this.imageToBeCropped = {};
    this.showCropper.next(false);
  }

  deleteCurrent(): void {
    this.deleteCurrentAvatar.emit(true);
  }
}
