import {
  ApiUserSummary,
  UserSummary,
  apiUserSummaryToUserSummary
} from "../userSummary/user-summary";

export declare interface ApiUserCustomizationContact {
  ContactAccountId: string;
  ContactInfo:
    | {
        ContactIsAFavorite: string;
        ContactPhone?: string;
        ContactSipAddress?: string;
      }
    | "";
  ContactName: string;
  ContactOrganizationId: string;
  ContactUserId: string;
  Created: string;
  Modified: string;
  UserCustomizationContactId: string;
  UserCustomizationId: string;
  UserSummary: ApiUserSummary | "";
}

export declare interface UserCustomizationContact {
  contactAccountId: string;
  contactInfo?: {
    contactIsAFavorite: string;
    contactPhone?: string;
    contactSipAddress?: string;
  };
  contactName: string;
  contactOrganizationId: string;
  contactUserId: string;
  created: string;
  modified: string;
  userCustomizationContactId: string;
  userCustomizationId: string;
  userSummary?: UserSummary;
}

export function apiUserCustomizationContactToUserCustomizationContact(
  apiUserCustContact: ApiUserCustomizationContact
): UserCustomizationContact {
  return {
    contactAccountId: apiUserCustContact.ContactAccountId,
    contactInfo:
      apiUserCustContact.ContactInfo !== ""
        ? {
            contactIsAFavorite: apiUserCustContact.ContactInfo.ContactIsAFavorite,
            contactPhone: apiUserCustContact.ContactInfo.ContactPhone,
            contactSipAddress: apiUserCustContact.ContactInfo.ContactSipAddress
          }
        : undefined,
    contactName: apiUserCustContact.ContactName,
    contactOrganizationId: apiUserCustContact.ContactOrganizationId,
    contactUserId: apiUserCustContact.ContactUserId,
    created: apiUserCustContact.Created,
    modified: apiUserCustContact.Modified,
    userCustomizationContactId: apiUserCustContact.UserCustomizationContactId,
    userCustomizationId: apiUserCustContact.UserCustomizationId,
    userSummary:
      apiUserCustContact.UserSummary !== ""
        ? apiUserSummaryToUserSummary(apiUserCustContact.UserSummary)
        : undefined
  };
}
