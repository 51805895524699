import { inject } from "@angular/core";
import { Router } from "@angular/router";

import { map } from "rxjs/operators";

import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { isSuperUser } from "@onsip/common/services/api/role";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { Config } from "@onsip/common/config";

export const SuperUserGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  const apiSession = inject(ApiSessionService);

  if (Config.IS_DESKTOP) return false;
  if (apiSession.isLoggedIn()) {
    return userService.selfUser.pipe(
      map(user => {
        const canAccess = isSuperUser(user.roles);
        if (canAccess) {
          return true;
        } else {
          router.navigate(["/login"]);
          return false;
        }
      })
    );
  } else {
    router.navigate(["/login"]);
    return false;
  }
};
