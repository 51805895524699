import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";

export declare type InvoiceItemType =
  | "Unknown"
  | "Debit"
  | "Credit"
  | "Package"
  | "Resource"
  | "Minutes"
  | "Services"
  | "DIDSetup"
  | "DIDMonthly"
  | "LNP"
  | "Vanity"
  | "Conference"
  | "E911"
  | "Voicemail"
  | "PSTNGateway"
  | "PrepaidServices"
  | "ManualInvoiceItem"
  | "UnlimitedBundle"
  | "MiscUnlimitedBundle";

// While each InvoiceItemType has its own unique interface named <InvoiceItemType>Item, the only items that use this unique interface are
// the Package, Resource, and ManualInvoice items. These have been typed out below.

export declare type ApiInvoiceItem<T> = T extends "Resource"
  ? ApiResourceInvoiceItem
  : T extends "Package"
  ? ApiPackageInvoiceItem
  : T extends "ManualInvoiceItem"
  ? APIManualInvoiceItem
  : ApiGenericInvoiceItem;

export declare interface ApiGenericInvoiceItem {
  Amount: string;
  Description: string;
  Quantity: string;
  Type: InvoiceItemType;
  UnitPrice: string;
}

export declare interface ApiResourceInvoiceItem extends ApiGenericInvoiceItem {
  ResourceItem: ApiResourceItem;
}

export declare interface ApiResourceItem {
  AccountLimit: string;
  AccountMinimum: string;
  AccountUsed: string;
  PackageQuantity: string;
  ResourceDescription: string; // we can probably type this too
  ResourceId: string;
  ResourceMonthlyRecurringCharge: string;
  ResourceName: string;
  ResourceOneTimeCharge: string;
}

export declare interface ApiPackageInvoiceItem extends ApiGenericInvoiceItem {
  PackageItem: ApiPackageItem;
}

export declare interface ApiPackageItem {
  IncludedResources: OnsipAPIArray<"IncludedResource", ApiPackageResourceItem>;
  IncludedStandardCallingMinutes?: ApiStandardCallingMinutes;
  PackageDescription: string;
  PackageId: string;
  PackageMonthlyRecurringCharge: string;
  PackageName: string;
  PackageOneTimeCharge: string;
}

export declare type ApiPackageResourceItem = Omit<
  ApiResourceItem & { Quantity: string },
  "AccountLimit" | "AccountMinimum" | "AccountUsed" | "PackageQuantity"
>;

export declare interface ApiStandardCallingMinutes {
  StandardCallingMinutes: string;
  StandardCallingMinutesMonthlyRecurringCharge: string;
}

export declare interface APIManualInvoiceItem extends ApiGenericInvoiceItem {
  ManualInvoiceItem: {
    ManualInvoiceItemId: string;
    Type: string;
  };
}

export declare interface APIManualChargeItem extends ApiGenericInvoiceItem {
  BundleQuantity: string;
  MiscUnlimitedBundle?: string;
  UnlimitedBundle?: string;
}

export declare type InvoiceItem<T> = T extends "Resource"
  ? ResourceInvoiceItem
  : T extends "Package"
  ? PackageInvoiceItem
  : T extends "ManualInvoiceType"
  ? ManualInvoiceItem
  : GenericInvoiceItem;

export declare interface GenericInvoiceItem {
  amount: string;
  description: string;
  quantity: string;
  type: InvoiceItemType;
  unitPrice: string;
}

export declare interface ResourceInvoiceItem extends GenericInvoiceItem {
  resourceItem: ResourceItem;
}

export declare interface ResourceItem {
  accountLimit: string;
  accountMinimum: string;
  accountUsed: string;
  packageQuantity: string;
  resourceDescription: string;
  resourceId: string;
  resourceMonthlyRecurringCharge: string;
  resourceName: string;
  resourceOneTimeCharge: string;
}

export declare interface PackageInvoiceItem extends GenericInvoiceItem {
  packageItem: PackageItem;
}

export declare interface PackageItem {
  includedResource: Array<PackageResourceItem>;
  includedStandardCallingMinutes?: StandardCallingMinutes;
  packageDescription: string;
  packageId: string;
  packageMonthlyRecurringCharge: string;
  packageName: string;
  packageOneTimeCharge: string;
}

export declare type PackageResourceItem = Omit<
  ResourceItem & { quantity: string },
  "accountLimit" | "accountMinimum" | "accountUsed" | "packageQuantity"
>;

export declare interface StandardCallingMinutes {
  standardCallingMinutes: string;
  standardCallingMinutesMonthlyRecurringCharge: string;
}

export declare interface ManualInvoiceItem extends GenericInvoiceItem {
  manualInvoiceItem: {
    manualInvoiceItemId: string;
    type: string;
  };
}

export declare interface ManualChargeItem extends GenericInvoiceItem {
  bundleQuantity: string;
  unlimitedBundle?: string;
  miscUnlimitedBundle?: string;
}

export function apiInvoiceItemToInvoiceItem(
  apiInvoiceItem: ApiInvoiceItem<InvoiceItemType>
): InvoiceItem<InvoiceItemType> {
  switch (apiInvoiceItem.Type) {
    case "Package":
      return apiInvoiceItemToPackageInvoiceItem(apiInvoiceItem as ApiPackageInvoiceItem);
    case "Resource":
      return apiInvoiceItemToResourceInvoiceItem(apiInvoiceItem as ApiResourceInvoiceItem);
    case "ManualInvoiceItem":
      return apiInvoiceItemToManualInvoiceItem(apiInvoiceItem as APIManualInvoiceItem);
    case "MiscUnlimitedBundle":
    case "UnlimitedBundle":
      return apiInvoiceItemToManualChargeItem(apiInvoiceItem as APIManualChargeItem);
    default:
      return apiInvoiceItemToGenericInvoiceItem(apiInvoiceItem);
  }
}

export function apiInvoiceItemToGenericInvoiceItem(
  apiInvoiceItem: ApiInvoiceItem<InvoiceItemType>
): GenericInvoiceItem {
  return {
    amount: apiInvoiceItem.Amount,
    description: apiInvoiceItem.Description,
    quantity: apiInvoiceItem.Quantity,
    type: apiInvoiceItem.Type,
    unitPrice: apiInvoiceItem.UnitPrice
  };
}

export function apiInvoiceItemToResourceInvoiceItem(
  apiInvoiceItem: ApiResourceInvoiceItem
): ResourceInvoiceItem {
  return {
    ...apiInvoiceItemToGenericInvoiceItem(apiInvoiceItem),
    resourceItem: apiResourceItemToResourceItem(apiInvoiceItem.ResourceItem)
  };
}

export function apiResourceItemToResourceItem(apiResourceItem: ApiResourceItem): ResourceItem {
  return {
    accountLimit: apiResourceItem.AccountLimit,
    accountMinimum: apiResourceItem.AccountMinimum,
    accountUsed: apiResourceItem.AccountUsed,
    packageQuantity: apiResourceItem.PackageQuantity,
    resourceDescription: apiResourceItem.ResourceDescription,
    resourceId: apiResourceItem.ResourceId,
    resourceMonthlyRecurringCharge: apiResourceItem.ResourceMonthlyRecurringCharge,
    resourceName: apiResourceItem.ResourceName,
    resourceOneTimeCharge: apiResourceItem.ResourceOneTimeCharge
  };
}

export function apiInvoiceItemToPackageInvoiceItem(
  apiInvoiceItem: ApiPackageInvoiceItem
): PackageInvoiceItem {
  return {
    ...apiInvoiceItemToGenericInvoiceItem(apiInvoiceItem),
    packageItem: apiPackageItemToPackageItem(apiInvoiceItem.PackageItem)
  };
}

export function apiPackageItemToPackageItem(apiPackageItem: ApiPackageItem): PackageItem {
  const packageItem = {
    includedResource: onsipApiArrayToArray(
      apiPackageItem.IncludedResources,
      "IncludedResource"
    ).map(apiPackageResourceItemToPackageResourceItem),
    packageDescription: apiPackageItem.PackageDescription,
    packageId: apiPackageItem.PackageId,
    packageMonthlyRecurringCharge: apiPackageItem.PackageMonthlyRecurringCharge,
    packageName: apiPackageItem.PackageName,
    packageOneTimeCharge: apiPackageItem.PackageOneTimeCharge
  };
  return apiPackageItem.IncludedStandardCallingMinutes
    ? {
        ...packageItem,
        includedStandardCallingMinutes: apiStandardCallingMinutesToStandardCallingMinutes(
          apiPackageItem.IncludedStandardCallingMinutes
        )
      }
    : packageItem;
}

export function apiPackageResourceItemToPackageResourceItem(
  apiPackageResourceItem: ApiPackageResourceItem
): PackageResourceItem {
  return {
    quantity: apiPackageResourceItem.Quantity,
    resourceDescription: apiPackageResourceItem.ResourceDescription,
    resourceId: apiPackageResourceItem.ResourceId,
    resourceMonthlyRecurringCharge: apiPackageResourceItem.ResourceMonthlyRecurringCharge,
    resourceName: apiPackageResourceItem.ResourceName,
    resourceOneTimeCharge: apiPackageResourceItem.ResourceOneTimeCharge
  };
}

export function apiInvoiceItemToManualInvoiceItem(
  apiInvoiceItem: APIManualInvoiceItem
): ManualInvoiceItem {
  return {
    ...apiInvoiceItemToGenericInvoiceItem(apiInvoiceItem),
    manualInvoiceItem: {
      manualInvoiceItemId: apiInvoiceItem.ManualInvoiceItem.ManualInvoiceItemId,
      type: apiInvoiceItem.ManualInvoiceItem.Type
    }
  };
}

export function apiStandardCallingMinutesToStandardCallingMinutes(
  minutes: ApiStandardCallingMinutes
): StandardCallingMinutes {
  return {
    standardCallingMinutes: minutes.StandardCallingMinutes,
    standardCallingMinutesMonthlyRecurringCharge:
      minutes.StandardCallingMinutesMonthlyRecurringCharge
  };
}

export function apiInvoiceItemToManualChargeItem(
  apiInvoiceItem: APIManualChargeItem
): ManualChargeItem {
  const obj = apiInvoiceItemToGenericInvoiceItem(apiInvoiceItem) as ManualChargeItem;
  obj.bundleQuantity = apiInvoiceItem.BundleQuantity;
  if (apiInvoiceItem.MiscUnlimitedBundle) {
    obj.miscUnlimitedBundle = apiInvoiceItem.MiscUnlimitedBundle;
  }
  if (apiInvoiceItem.UnlimitedBundle) {
    obj.unlimitedBundle = apiInvoiceItem.UnlimitedBundle;
  }
  return obj;
}
