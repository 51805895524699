import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: "root" })
export class AcceptedCallService {
  acceptedCallUUID = new BehaviorSubject<string>("");

  hasConnectingAcceptedCall(callUUID: string): boolean {
    return this.acceptedCallUUID.value === callUUID;
  }

  setAcceptedCall(callUUID: string): void {
    this.acceptedCallUUID.next(callUUID);
  }

  finishAcceptedCallState(): void {
    this.acceptedCallUUID.next("");
  }
}
