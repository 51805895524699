import { combineLatest, OperatorFunction } from "rxjs";
import { filter, map, withLatestFrom, distinctUntilChanged } from "rxjs/operators";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { User } from "../resources/user/user";
import { Role } from "../role";
import { CurrentOrganizationService } from "./current-organization.service";

import { userId } from "./user-id";

export function organizationId(): OperatorFunction<ApiStateStoreState, string> {
  return storeState =>
    combineLatest([storeState, CurrentOrganizationService.currentOrgId]).pipe(
      map(([, currOrgId]) => currOrgId),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}

/** this is a hack for UserAddressBrowse where we want to add OrganizationId but
 * only if the user is a SuperUser, which changes the response to the whole table
 * unless an OrganizationId param is given- otherwise it raises the permission level of the call
 * beyond regular users making the call
 */
export function organizationIdIfSuperUser(): OperatorFunction<
  ApiStateStoreState,
  string | undefined
> {
  return storeState =>
    storeState.pipe(
      withLatestFrom(storeState.pipe(userId())),
      map(([store, selfUserId]) => {
        const selfUser = (store.User as ApiSubstate<User>)?.state[selfUserId];
        return selfUser?.roles.includes(Role.SuperUser) ? selfUser.organizationId : undefined;
      }),
      distinctUntilChanged()
    );
}
