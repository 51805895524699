import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";

export type SnackbarType = "success" | "error";

export interface SnackBarData {
  message: string;
  snackbarType?: SnackbarType;
  action?: string;
}

@Component({
  selector: "onsip-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent {
  snackBarRef!: MatSnackBarRef<SnackbarComponent>;
  isError: boolean;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) {
    this.isError = this.data.snackbarType === "error";
  }

  dimiss(): void {
    this.snackBarRef.dismissWithAction();
  }
}
