<mat-card class="main mat-elevation-z9">
  @if (showHistory) {
  <h4 class="history mat-h4">{{ history | async }}</h4>
  }
  <mat-grid-list cols="3" rowHeight="14:12">
    @for (button of dialpadButtons; track button) {
    <mat-grid-tile>
      <onsip-dialpad-button
        onsipDtmfEnabled
        [dtmfEnabled]="true"
        [target]="target"
        [isDialpadComponent]="true"
        [dtmfKey]="button.number"
        [dtmfLetters]="button.letters"
      >
      </onsip-dialpad-button>
    </mat-grid-tile>
    }
  </mat-grid-list>
</mat-card>
