import { Injectable } from "@angular/core";
import { ApiSessionService } from "../../../../common/services/api/api-session.service";
import { ApiStateStoreService } from "../../../../common/services/api/api-state-store.service";
import { distinctUntilChanged } from "rxjs/operators";
import { PackageService } from "../../../../common/services/api/resources/package/package.service";
import { ApiPromiseStateService } from "../../../../common/services/api/api-promise-state.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class PhonePackageService extends PackageService {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState);

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(state => {
        if (state.loggedIn && !!state.sessionId) this.onLogin();
        else if (!state.loggedIn) this.dispose();
      });
  }

  protected onLogin(): void {
    debug && console.log("PhonePackageService.onLogin");
    this.packageBrowseAssigned();
  }
}
