<div [ngClass]="{ loading: (deferCond | async) === false }">
  <ng-content></ng-content>
</div>

@if ((deferCond | async)=== false) {
<div class="placeholder">
  <div class="mat-headline-5">{{ loadingMessage | translate }}</div>
  <mat-spinner></mat-spinner>
</div>
}
