import { BehaviorSubject, Observable } from "rxjs";

/** StateEmitter interface. */
export interface StateEmitterInterface<T> {
  /** The public Observable that the Observer subscribes to for the event stream. */
  state: Observable<T>;
  /** True if the Observable has stopped. */
  readonly isStateStopped: boolean;
  /** The last state published. */
  readonly stateValue: boolean;
}

/**
 * This is a cover on BehaviorSubject which exposes it as an Observable and sets up a storage cache.
 * It is intended to be extended by subclasses which need to emit their state.
 */
export class StateEmitter<T> {
  /** The public Observable that the Observer subscribes to for the state stream. */
  state: Observable<T>;

  /** The implementation is a BeahviorSubject. */
  private _state: BehaviorSubject<T>;

  /**
   * Constructor is protected - the expectation is that this class will be extended.
   * @param stateStore Initial copy of the state store which can then be updated directly by the subclass.
   */
  protected constructor(protected stateStore: T) {
    this._state = new BehaviorSubject(this.copyStateStore());
    this.state = this._state.asObservable();
  }

  /**
   * Returns true if the Observable has stopped. Common use is to check if a subscription completed unexpectedly.
   * For example, you can use this to check to see if your subscription completed because the Observable
   * completed (stopped).
   */
  get isStateStopped(): boolean {
    return this._state.closed;
  }

  /**
   * The last state published. That is, the current state stored in the BehaviorSubject.
   * The value in stateStore may be different (it may not have been published).
   * For example, this can be helpful to an Observer that needs to initialize stuff prior to subscribing.
   */
  get stateValue(): T {
    return this.copyStateValue();
  }

  /**
   * Override this to provide a way to update the stored state immediately prior to publish.
   * For example, this can be helpful if values are being cached in local properties and
   * they need to flushed prior to subscribers being notified.
   */
  protected flushState(): void {}

  /**
   * Override this to provide an execution context for publish tasks.
   * For example, if you want all publishes to be run in an angular zone, you could do something like...
   *  protected publishRun(fn: () => any): any {
   *    return this.ngZone.run(() => { return fn() })
   *  }
   * @param fn Function to execute
   */
  protected publishRun(fn: () => any): any {
    return fn();
  }

  /** Completes the Observable. */
  protected publishStateComplete(): void {
    this.publishRun(() => {
      this._state.complete();
    });
  }

  /** Errors the Observable. */
  protected publishStateError(error: any): void {
    this.publishRun(() => {
      this._state.error(error);
    });
  }

  /** Publish the state currently it the stateStore, thus notifying Observers. */
  protected publishState(): void {
    this.publishRun(() => {
      this.flushState();
      this._state.next(this.copyStateStore());
    });
  }

  /** This does a deep copy so that the observers get a reference free copy of the stored state. */
  private copyStateStore(): T {
    try {
      return JSON.parse(JSON.stringify(this.stateStore));
    } catch (e) {
      console.error("Error:", e);
      console.error("Current stateStore:", this.stateStore);
      const message =
        "StateEmitter failed to deep copy state. " +
        "In order to enforce an immutable state store, your state object must be JSON compatible " +
        "and must not contain functions or cyclical/recursive references.";
      throw new Error(message);
    }
  }

  /** This does a deep copy so that the observers get a reference free copy of the stored state. */
  private copyStateValue(): T {
    try {
      return JSON.parse(JSON.stringify(this._state.getValue()));
    } catch (e) {
      console.error("Error:", e);
      console.error("Current state value:", this._state.getValue());
      const message =
        "StateEmitter failed to deep copy state. " +
        "In order to enforce an immutable state store, your state object must be JSON compatible " +
        "and must not contain functions or cyclical/recursive references.";
      throw new Error(message);
    }
  }
}
