import { Injectable, NgZone } from "@angular/core";
import { StateEmitterArray } from "../../libraries/emitter/state-emitter-array";
import { User as UserStore } from "../../libraries/firebase/store/user";
import { UserState, UserAvailability } from "../../libraries/firebase/store/user-state";

import { Subscription } from "rxjs";

export { UserState, UserAvailability };

@Injectable({ providedIn: "root" })
export class UsersStoreService extends StateEmitterArray<UserStore, UserState> {
  protected unsubscriber = new Subscription();
  constructor(private zone: NgZone) {
    super();
  }

  /**
   * Adds an user to state emitter array
   * @param oid user's Organization Id
   * @param uid user's id
   */
  addUser(oid: number, uid: number): void {
    UserStore.get(oid, uid).then(state => {
      if (!state) return; // no firestore record, ignore. e.g. user has never logged in
      if (this.stateStore.find(user => user.id === uid.toString())) return; // already added listener
      const userStore = new UserStore(state);
      userStore.start(oid, uid);
      this.add(userStore);
    });
  }

  /**
   * get UserState from user's id
   * returns undefined if UserState does not exist
   * @param userId user's id
   */
  getUserStateByUid(userId: string): UserState | undefined {
    return this.stateValue.find(state => state.id === userId);
  }

  /**
   * get UserAvailability from user's id
   * returns undefined if UserState does not exist
   * @param userId user's id
   */
  getUserAvailabilityByUid(userId: string): UserAvailability | undefined {
    const userState = this.getUserStateByUid(userId);
    return userState ? userState.availability : undefined;
  }

  /**
   * Removes an user from state emitter array
   * @param userId user's id
   */
  removeUser(userId: string): void {
    const userStore = this.getUserStore(userId);
    if (userStore) {
      userStore.stop();
      this.remove(userStore);
    }
  }

  /**
   * Disposes state emitter array. job completed
   */
  dispose(): void {
    this.unsubscriber.unsubscribe();
    this.publishComplete();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(fn);
  }

  /**
   * Gets UserStore from user's id
   * @param userId user's id
   */
  private getUserStore(userId: string): UserStore | undefined {
    return this.array.find(userStore => userStore.uid === parseInt(userId));
  }
}
