import { parsePhoneNumber, ParsedPhoneNumber } from "awesome-phonenumber";

export class E164PhoneNumber {
  private _e164Uri = "";
  private _e164DisplayNumber = "";
  private _phoneNumber!: ParsedPhoneNumber | undefined;

  constructor(phoneNumber: string) {
    let pn: ParsedPhoneNumber | undefined;

    // if phoneNumber is clearly not a number just set uri to the inputted value
    if (phoneNumber.match(/[^\d*#+-/(/\s)]/)) {
      this._e164Uri = phoneNumber;
    } else {
      if (phoneNumber.includes("@")) phoneNumber = phoneNumber.split("@")[0];
      if (phoneNumber.includes("sip:")) phoneNumber = phoneNumber.replace(/sip:/g, "");
      phoneNumber = phoneNumber.replace(/[- )(]/g, "");
      if (phoneNumber.charAt(0).match(/[a-zA-Z]/) || phoneNumber.charAt(1).match(/[a-zA-Z]/)) {
        // if the first or second char is a letter, assume it is not a phone number.
        // if first char can be a plus
        pn = undefined;
      } else if (phoneNumber.charAt(0) === "+") {
        // assume already e164 if starts with a '+'
        pn = parsePhoneNumber(phoneNumber);
      } else if (phoneNumber.length > 3 && phoneNumber.slice(0, 3).match(/(^\+?011)|(^\+?00)/)) {
        // international access code
        pn = parsePhoneNumber("+" + phoneNumber.slice(3));
      } else if (phoneNumber.replace(/\D/g, "").length > 4) {
        // minimum length is 5 for any phone number
        if (phoneNumber.length === 10) {
          // us-centric: assume any 10 digit numbers are north american number missing the leading '1' (not sure good idea)
          pn = parsePhoneNumber("+1" + phoneNumber, { regionCode: "US" });
        }
        // if the us number is not valid or it is not a 10 digit number, then assume it is an international number
        if ((pn && !pn.valid) || !pn) {
          pn = parsePhoneNumber("+" + phoneNumber);
        }
      } else {
        // else it is not a phone number
        pn = undefined;
      }
      this._phoneNumber = pn;
      if (pn && pn.valid) {
        const regionCode = pn.regionCode;
        if (regionCode === "US") {
          this._e164Uri = pn.number.e164;
          this._e164DisplayNumber = pn.number.national;
        } else {
          const internationalNumber = pn.number.international;
          this._e164DisplayNumber = internationalNumber.slice(1);
          // we need to manually construct the e164 number because of the trimming zero from e164format.
          // This was leading to conflicts between the sip value and actual inputted value
          this._e164Uri = internationalNumber.replace(/[- )(]/g, "");
        }
      } else {
        // display name will be whatever the user inputted if E164 is false
        this._e164Uri = phoneNumber;
      }
    }
  }
  /**
   * getter for E164 format phone number for uri
   */
  get e164Uri(): string {
    return this._e164Uri;
  }

  /**
   * prettify e164 number to use national format for the number. International number has the international code appended in front of its national format
   */
  get e164DisplayNumber(): string {
    return this._e164DisplayNumber;
  }

  /** getter for parsed PhoneNumber. this for most part is the same as calling new PhoneNumber except for international numbers */
  get phoneNumber(): ParsedPhoneNumber | undefined {
    return this._phoneNumber;
  }

  /** getter for phone number validity, the app for the most part only cares with the phone number is valid so we can just use this helper getter */
  get isValid(): boolean {
    return !!this._phoneNumber && this._phoneNumber.valid;
  }
}
