import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ColorPickerModule } from "ngx-color-picker";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgxFileDropModule } from "ngx-file-drop";
import { MaterialModule } from "../../modules/material/material.module";
import { CallModule } from "../call/call.module";
import { TableModule } from "../shared/components/table/onsip-table.module";

import { DeveloperComponent } from "./developer.component";
import { DeveloperGridComponent } from "./grid/developer-grid.component";
import { EditItemListModule } from "@onsip/web/features/shared/components/onsipItemList/edit-item-list.module";
import { DeveloperTableComponent } from "./developer-table/developer-table.component";
import { ShellNavigationsModule } from "@onsip/web/features/shared/components/shellNavigations/shell-navigations.module";
import { DeveloperShellNavigationComponent } from "@onsip/web/features/developer/developer-shell-navigation/developer-shell-navigation.component";
import { RouterModule } from "@angular/router";
import { TogglableRecorderModule } from "../shared/components/togglableRecorder/togglable-recorder.module";
import { TestShellNavComponent } from "@onsip/web/features/developer/developer-shell-navigation/test-shell-nav/test-shell-nav.component";
import { ChartingLineGraphModule } from "../administrators/pbxOverview/charts/lineGraph/line-graph.module";
import { ChartingDonutGraphModule } from "../administrators/pbxOverview/charts/donutGraph/donut-graph.module";
import { ChartingBarGraphModule } from "../administrators/pbxOverview/charts/barGraph/bar-graph.module";

@NgModule({
  imports: [
    BrowserModule,
    CallModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ColorPickerModule,
    ImageCropperModule,
    NgxFileDropModule,
    TableModule,
    EditItemListModule,
    ShellNavigationsModule,
    RouterModule,
    EditItemListModule,
    TogglableRecorderModule,
    ChartingLineGraphModule,
    ChartingDonutGraphModule,
    ChartingBarGraphModule
  ],
  declarations: [
    DeveloperComponent,
    DeveloperGridComponent,
    DeveloperTableComponent,
    DeveloperShellNavigationComponent,
    TestShellNavComponent
  ],
  exports: [DeveloperComponent]
})
export class DeveloperModule {}
