<div class="contact-support-container">
  <div class="mat-body-2 description">
    Our award winning, U.S. based support team is just a click away.
  </div>
  <div class="hours-container">
    <h4 class="mat-subtitle-2">Operating hours</h4>
    @if (!isSmallBreakpoint) {
    <div class="mat-body-2">
      <div>Mon - Thurs: 9 AM - 8 PM Eastern</div>
      <div>Fri: 9 AM - 6 PM Eastern</div>
      <div>TEL: 800-801-3381 Option 2.</div>
    </div>
    } @if (isSmallBreakpoint) {
    <div class="mat-body-2">
      <div>Mon - Thurs: 9 AM - 8 PM Eastern | Fri: 9 AM - 6 PM Eastern</div>
      <div>TEL: 800-801-3381 Option 2.</div>
    </div>
    }
  </div>
  <div class="call-button-container">
    <button
      mat-button
      color="primary"
      aria-label="start voice call"
      id="onsip--contact-onsip--voice-call"
      onsipNewCall
      remoteAor="support@onsip.com"
      [contact]="contact"
    >
      <mat-icon>phone</mat-icon>
      {{ "ONSIP_I18N.VOICE_CALL" | translate }}
    </button>
  </div>
</div>
