import { Component, OnInit, Input, OnDestroy, HostBinding } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import { Subscription, combineLatest } from "rxjs";
import { filter, take, map } from "rxjs/operators";

import { Config } from "../../../../common/config";

import { IdentityService } from "../../../../common/modules/core";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";

import { views } from "../../../app/phone/views";
import { ModalMaterialComponent } from "../../shared/components/modal/modal-material.component";

declare let navigator: any;

@Component({
  selector: "onsip-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.scss"]
})
export class FooterComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  @Input() showAdminTabs = false;

  professionalCallLink!: string;
  translatedCallLinkText!: string;
  platform = "web";
  isAppStore = Config.isAppStore;
  views = views;

  private unsubscriber = new Subscription();

  constructor(
    private identity: IdentityService,
    private noAuthOrgSummary: NoAuthOrganizationSummaryService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar
  ) {
    if (Config.IS_DESKTOP) {
      this.platform = "desktop";
    }
  }

  ngOnInit(): void {
    this.translatedCallLinkText = this.translate.instant(
      "ONSIP_I18N.PEOPLE_CAN_CALL_YOU_FOR_FREE_USING_THIS_LINK_SHARE_IT_WITH_PEOPLE_YOU_WANT_TO_INVITE_TO_CALL_YOU"
    );
    this.unsubscriber.add(
      combineLatest({
        teamPageDomain: this.noAuthOrgSummary.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0]?.teamPageDomain)
        ),
        idState: this.identity.state.pipe(filter(state => state.addresses.length > 0))
      }).subscribe(({ teamPageDomain, idState }) => {
        this.professionalCallLink = `https://${teamPageDomain}/${idState.addresses[0].username}`;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  openCallLinkModal(): void {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"], // global material styles on dialog
      maxWidth: "480px",
      data: {
        title: this.translate.instant("ONSIP_I18N.YOUR_PERSONAL_CALL_PAGE"),
        message: `${this.translate.instant("ONSIP_I18N.YOUR_UNIQUE_CALL_LINK_IS")}<br/><b>${
          this.professionalCallLink
        }</b>`,
        secondaryBtnText: this.translate.instant("ONSIP_I18N.CLOSE"),
        primaryBtnText: this.translate.instant("ONSIP_I18N.COPY_LINK"),
        primaryBtnColor: "primary",
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) this.copyCallLink();
        })
    );
  }

  private openSnackBar(): void {
    this.snackbar.open("Personal Call Page Link Copied!", "", {
      duration: 4000
    });
  }

  private copyCallLink(): void {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(this.professionalCallLink);
      this.openSnackBar();
    } else {
      const willCopy: HTMLTextAreaElement = document.createElement("textarea");
      willCopy.value = this.professionalCallLink;
      document.body.appendChild(willCopy);
      willCopy.select();
      // NOTE: revisit this and use the clipboard API
      // eslint-disable-next-line deprecation/deprecation
      document.execCommand("copy");
      willCopy.remove();
      this.openSnackBar();
    }
  }
}
