<ng-container>
  <ul class="call-list-group" onsipArrowKeyNavigation>
    <!-- VIDEO CONFERENCE ITEM -->
    @if (videoConferenceObservable | async; as videoConference) { @if (videoConference.connected) {
    <li
      class="call-list-item"
      [ngClass]="{ 'call-list-item-active': !videoConference.hold }"
      (click)="navigateToVideoConference()"
    >
      <div class="call-list-item-header call-list-ellipsis">
        <p class="call-description call-list-ellipsis">
          <span>{{
            (videoConference.hold ? "ONSIP_I18N.ON_HOLD" : "ONSIP_I18N.CONFERENCING") | translate
          }}</span>
          <onsip-timer [referencePoint]="videoConference.connectedAt"></onsip-timer>
        </p>
        <p class="call-name call-list-ellipsis">
          {{
            videoConference.remoteDisplayName
              ? videoConference.remoteDisplayName
              : videoConference.remoteUri
              ? (videoConference.remoteUri | slice : 4)
              : ""
          }}
        </p>
      </div>
      <div
        class="call-list-item-footer"
        [ngClass]="{ 'call-list-item-footer-active': !videoConference.hold }"
      >
        <button id="onsip--call-list-item--hold" mat-button (click)="holdVideoConf()">
          @if (videoConference.hold) {
          <mat-icon>play_arrow</mat-icon>&nbsp;{{ "ONSIP_I18N.RESUME" | translate }}
          } @else {
          <mat-icon>pause</mat-icon>&nbsp;{{ "ONSIP_I18N.HOLD" | translate }}
          }
        </button>
        <button id="onsip--call-list-item--end-call" mat-button (click)="endVideoConf()">
          <mat-icon>call_end</mat-icon>&nbsp;{{ "ONSIP_I18N.END" | translate }}
        </button>
      </div>
    </li>
    } }
    <!-- EAVES DROP CALL ITEM -->
    @if (eavesdropCallObservable | async; as eavesdropCall) { @if (eavesdropCall.connected) {
    <li class="call-list-item call-list-item-active" (click)="navigateToQueueDashboard()">
      <div class="call-list-item-header call-list-ellipsis">
        <p class="call-description call-list-ellipsis">
          <span>{{ "ONSIP_I18N.LISTENING" | translate }}</span>
          <onsip-timer [referencePoint]="eavesdropCall.connectedAt"></onsip-timer>
        </p>
        <p class="call-name call-list-ellipsis">
          {{
            eavesdropCall.remoteDisplayName
              ? eavesdropCall.remoteDisplayName
              : eavesdropCall.remoteUri
              ? (eavesdropCall.remoteUri | slice : 4)
              : ""
          }}
        </p>
      </div>
      <div class="call-list-item-footer call-list-item-footer-active">
        <button
          id="onsip--call-list-item--end-call"
          mat-button
          (click)="endEavesdrop(eavesdropCall.uuid)"
        >
          <mat-icon>call_end</mat-icon>&nbsp;{{ "ONSIP_I18N.END" | translate }}
        </button>
      </div>
    </li>
    } }
    <!-- CALL LIST ITEMS -->
    @for (item of callListObservable | async; track trackByUuid($index, item)) {
    <li
      class="call-list-item"
      [ngClass]="{
        'call-list-item-active': item.connected && !item.hold,
        'call-list-item-incoming': item.ringing,
        'video-showing':
          item.videoAvailable &&
          !item.hold &&
          item.uuid !== (activeVisibleCallUuidObservable | async)
      }"
      cdkDropList
      [cdkDropListData]="{ call: item }"
      (cdkDropListDropped)="dragDropCallService.dropEvent($event)"
    >
      <onsip-call-list-item
        cdkDrag
        [cdkDragData]="{ call: item }"
        [callUuid]="item.uuid"
        [activeVisibleCallUuid]="(activeVisibleCallUuidObservable | async) || undefined"
      >
        <img *cdkDragPreview [src]="'resources/img/transfer-drag.png'" alt="Drag for Transfer" />
      </onsip-call-list-item>
    </li>
    }
    <!-- DIALOGS LIST ITEMS -->
    @for (item of dialogList | async; track trackByCid($index, item)) {
    <li
      class="call-list-item"
      (click)="navigateToHome()"
      cdkDropList
      [cdkDropListData]="{ dialog: item }"
      (cdkDropListDropped)="dragDropCallService.dropEvent($event)"
    >
      <onsip-dialog-list-item
        class="call-list-item"
        cdkDrag
        [cdkDragData]="{ dialog: item }"
        [dialog]="item"
      >
        <img *cdkDragPreview [src]="'resources/img/transfer-drag.png'" alt="Drag for Transfer" />
      </onsip-dialog-list-item>
    </li>
    }
  </ul>
</ng-container>
