<div class="donut-graph-body">
  <highcharts-chart
    [Highcharts]="Highcharts"
    [options]="chartOptions"
    [callbackFunction]="chartCallback"
  >
  </highcharts-chart>
  <!-- Custom legend for donut graph -->
  <div class="legend" [ngClass]="{ column: legendLayout === 'vertical' }">
    @for (item of data; track item) {
    <div class="legend-item">
      <div class="symbol" [ngStyle]="{ backgroundColor: item.color }"></div>
      <span class="legend-text">{{ item.value }} {{ item.name }}</span>
    </div>
    }
  </div>
</div>
