import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { StateEmitter } from "../../emitter/state-emitter";
import { CallerInstances } from "./caller-instance";
import { firebase } from "../cloud/firebase/platform-firebase-types";
const debug = false;

export type CallerPresenceStatus = "init" | "present" | "absent";

export interface CallerPresenceState {
  /** Organization Id the call belongs to. */
  oid: number;
  /** Call id (presence specific - this is not the sip call id). */
  cid: string;
  /** Target id. */
  tid: string;
}

/* Co-opted this listener for the callers-present service. If the tid is ommitted (undefined)
   then this will listen to the entire callers table. */
export class CallerPresenceListener extends StateEmitter<Array<CallerPresenceState>> {
  private listenCallback: ((a: firebase.database.DataSnapshot | null) => any) | undefined =
    undefined;
  private listenRef: firebase.database.Reference;

  static makeInitialState(oid: number, cid: string): CallerPresenceState {
    return {
      oid,
      cid,
      tid: ""
    };
  }

  static makeListenRef(oid: number): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/callers`);
  }

  /**
   * Constructor
   * @param oid The organization id of the user.
   * @param tid (Optional) The target id. If not provide a listener will be started for the entire callers table.
   */
  constructor(private oid: number, private tid?: string) {
    super([]);
    this.listenRef = CallerPresenceListener.makeListenRef(oid);
  }

  dispose(): void {
    debug && console.log(`CallerPresenceListener[${this.id}].dispose`);
    this.publishStateComplete();
    this.stop();
  }

  start(): void {
    if (this.listenCallback) {
      this.stop();
    }
    this.listenCallback = snap => {
      // eslint-disable-next-line no-null/no-null
      if (snap === null || snap.val() === null) {
        this.updateState(undefined);
      } else if (snap.val()) {
        this.updateState(snap.val());
      } else {
        throw new TypeError("Invalid value.");
      }
    };
    if (this.tid) {
      this.listenRef
        .orderByValue()
        .equalTo(this.tid)
        .on("value", this.listenCallback, (error: any) => {
          debug && console.error(`CallerPresenceListener[${this.id}].listenRef error`);
          debug && console.error(error);
          throw error;
        });
    } else {
      this.listenRef.orderByValue().on("value", this.listenCallback, (error: any) => {
        debug && console.error(`CallerPresenceListener[${this.id}].listenRef error`);
        debug && console.error(error);
        throw error;
      });
    }
  }

  stop(): void {
    if (this.listenCallback) {
      this.listenRef.off("value", this.listenCallback);
      this.listenCallback = undefined;
      this.updateState(undefined);
    }
  }

  private updateState(value: CallerInstances | undefined): void {
    debug && console.log(`CallerPresenceListener[${this.id}].updateState`);

    if (value === undefined) {
      this.stateStore = [];
      this.publishState();
      return;
    }

    const callers = value;
    const callerPresenceStates = new Array<CallerPresenceState>();
    Object.keys(callers).forEach(key => {
      callerPresenceStates.push({
        oid: this.oid,
        cid: key,
        tid: callers[key]
      });
    });
    this.stateStore = callerPresenceStates;
    this.publishState();
  }

  private get id(): string {
    return this.listenRef.toString();
  }
}
