<h2 mat-dialog-title>{{ "ONSIP_I18N.REPORT_A_BUG_OR_FEEDBACK" | translate }}</h2>
<div mat-dialog-content>
  <mat-form-field class="user-report-form-field">
    <textarea
      matInput
      [formControl]="userReportControl"
      maxlength="250"
      placeholder="{{ 'ONSIP_I18N.DESCRIPTION_OF_BUG' | translate }}"
    ></textarea>
    <mat-hint align="end">{{ userReportControl.value?.length }} / 250</mat-hint>
  </mat-form-field>
</div>
<div mat-dialog-actions class="modal-actions">
  <button id="onsip--user-sentry-report--cancel" mat-button color="primary" mat-dialog-close>
    {{ "ONSIP_I18N.CANCEL" | translate }}
  </button>
  <button
    id="onsip--user-sentry-report--report"
    mat-button
    color="primary"
    (click)="reportIssue()"
    cdkFocusInitial
  >
    {{ "ONSIP_I18N.REPORT" | translate }}
  </button>
</div>
