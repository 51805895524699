import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { AdvQueueService } from "@onsip/common/services/api/resources/queue/adv-queue.service";
import { views } from "@onsip/web/app/phone/views";

export const QueueGuard = () => {
  const queueService = inject(AdvQueueService);
  const router = inject(Router);

  return queueService.getSmartQueues().then(queues => {
    const hasAdvancedQueues = queues.length > 0;
    if (hasAdvancedQueues) {
      return true;
    } else {
      router.navigate([views.HOME]);
      return false;
    }
  });
};
