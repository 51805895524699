<svg
  id="radio-wave"
  width="30px"
  height="14px"
  viewBox="0 0 30 14"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="2" transform="translate(-149.000000, -72.000000)" stroke="#000000">
      <g id="Group" transform="translate(44.000000, 19.000000)">
        <g id="Group-2">
          <path
            d="M105.89773,66.4133188 C108.528041,58.7074809 113.721425,54.6044805 121.477881,54.1043176 C122.380085,54.0461404 126.166995,54.7667209 129.019283,56.5602714 C131.328529,58.0123503 132.724569,60.3887832 133.851487,61.8815044"
            id="Path-8"
          ></path>
        </g>
      </g>
    </g>
  </g>
</svg>

<svg
  id="hero-girl"
  width="102px"
  height="129px"
  viewBox="0 0 102 129"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="2" transform="translate(-43.000000, -18.000000)" stroke="#000000">
      <g id="Group" transform="translate(44.000000, 19.000000)">
        <g id="Group-2">
          <g id="Group-3">
            <path
              d="M57.4529308,27.4657788 L56.8090795,30.4910854 C63.1802303,31.2062217 66.8258536,32.0879564 67.7459495,33.1362894 C69.1260934,34.7087888 73.1137313,44.3357737 76.6152867,44.3360963 C80.4676072,44.3364512 84.8952334,41.112792 89.898165,34.6651188"
              id="Path-106"
            ></path>
            <path
              d="M54.2417808,6.85567139 L53.0205572,9.60524133 C52.649877,10.4398231 52.0289199,11.1123128 51.2598916,11.5120223 L50.686537,11.8100287 C51.7424113,15.1232931 52.3295377,17.503837 52.4479162,18.9516603 C52.7060992,22.1093601 52.7205522,24.0346582 52.4479162,24.9871961 C51.2700708,29.1023605 42.9552296,26.3218237 43.0377308,26.1383944 C43.2650709,25.6329376 44.6539235,24.1178407 45.0129124,19.98945 C45.2858036,16.8511868 44.9522683,9.31646135 47.4921122,4.57766998 C49.1884467,1.41268189 53.2990323,0.0665429183 55.553335,0.00315230944 C61.0288714,-0.150818844 64.3732317,5.37887703 64.6737335,7.10408858 C65.6984555,12.9871222 67.3839419,20.1424006 67.3839419,23.3069596 C67.3839419,25.3075874 64.4808691,26.6938605 58.6747236,27.4657788 C63.5746425,15.7394214 63.7336008,10.6806676 59.1515988,12.2895171 C54.5695968,13.8983666 52.9329908,12.0870847 54.2417808,6.85567139 Z"
              id="Path-55"
              fill="#2794B0"
              fill-rule="nonzero"
            ></path>
            <path
              d="M89.5458033,34.6667005 C92.9921613,30.7243272 94.7014184,29.944154 94.6735745,32.3261809 C94.6457307,34.7082078 93.7571947,36.1341033 92.0079666,36.6038674 L89.2159158,35.5385698 L89.5458033,34.6667005 Z"
              id="Path-16"
            ></path>
            <path
              d="M92.6271617,36.6038674 C87.0908013,44.6628627 83.0323821,49.6030934 80.4519039,51.4245594 C77.8714258,53.2460255 73.4057897,51.8066835 67.0549958,47.1065335 C65.3728657,49.2447277 64.2510434,50.6840696 63.6895291,51.4245594 C59.1841325,57.3659932 59.6884857,70.6909973 59.6884857,73.8018077 C59.6884857,76.7051863 51.8248023,76.6747462 39.3766922,73.8018077 C37.5588763,73.3822682 35.3722175,72.712071 31.9069843,70.8639258"
              id="Path-19"
            ></path>
            <line
              x1="47.5987155"
              y1="29.5745684"
              x2="50.1776048"
              y2="26.9650559"
              id="Path-15"
            ></line>
            <line
              x1="41.9933132"
              y1="31.2622584"
              x2="47.7683425"
              y2="29.4703644"
              id="Path-23"
            ></line>
            <path
              d="M60.2012577,23.5537565 C59.2436331,25.687044 58.4077218,26.8868449 57.693524,27.1531594 C56.313291,27.667829 53.5344255,27.664724 53.056709,26.2345737"
              id="Path-29"
            ></path>
            <path
              d="M3.33012735,50.3157426 C3.56419899,54.2231991 4.15192412,56.8989378 5.09330273,58.3429587 C9.0122933,64.3544659 19.3579924,62.7717072 21.7676025,65.1868296 C24.0391718,67.4635955 20.9601178,74.6467573 25.6080219,75.9679417 C36.24143,68.1335479 43.0224611,61.7995177 42.7372582,57.0954284 C42.6717711,56.0152947 42.3663372,54.6314885 41.8209565,52.9440098 C41.3660195,51.5363756 40.7441204,49.9174369 39.9552592,48.0871939 L16.5300691,60.0199635 L12.7207992,56.6757843 C27.8012767,45.0005786 34.8686808,39.5153283 36.5282724,38.1070157 C37.7974792,37.0299796 39.9036539,32.8535866 42.7372582,30.9804282 C43.362914,30.5668375 30.2272037,37.0119423 3.33012735,50.3157426 Z"
              id="Path-106"
            ></path>
            <path
              d="M15.3382737,59.7216195 C11.815394,61.7755273 10.0042102,62.2272866 10.7151973,60.5862861 C11.4261843,58.9452855 11.6467706,57.3498325 12.9901493,57.3657113 L15.3382737,59.2906032 L15.3382737,59.7216195 Z"
              id="Path-16"
            ></path>
            <path
              d="M56.4679549,75.9679417 C51.2675933,100.399176 43.5998439,114.786129 33.4647066,119.128801 C23.3295692,123.471472 18.2620006,124.764778 18.2620006,123.008719 L18.2620006,115.23131 C23.0611806,112.949912 26.292373,111.809213 27.9555775,111.809213 C29.6187821,111.809213 33.5301441,102.611978 39.6896636,84.2175079"
              id="Path-3"
            ></path>
            <polyline
              id="Path-4"
              points="39.4117253 84.4692485 10.9588787 113.223226 5.98151523 105.912555 31.5366685 71.0474324"
            ></polyline>
            <path
              d="M18.2620006,116.05964 C14.2712705,116.14782 12.2759054,116.759354 12.2759054,117.894243 C12.2759054,118.875318 12.5774059,121.87205 13.1804068,126.88444 L13.1804366,126.884436 C13.2238993,127.245716 13.5520078,127.503358 13.9132875,127.459895 C14.1804443,127.427756 14.4012117,127.236389 14.4709501,126.9765 C14.4709501,126.9765 14.4709501,126.9765 14.4709501,126.9765 C14.6928803,126.14945 14.9463796,124.007252 16.0895418,123.064245 C16.4194296,122.792116 17.1435826,122.792116 18.2620006,123.064245"
              id="Path-5"
            ></path>
            <path
              d="M5.15527888,106.947984 L2,116.795988 C3.64347919,117.665546 4.96013248,117.086617 5.94995987,115.059201 C6.93978726,113.031785 7.61802402,111.704105 7.98467015,111.07616 L9.34879313,109.805008"
              id="Path-6"
              transform="translate(5.674397, 112.067742) rotate(23.000000) translate(-5.674397, -112.067742) "
            ></path>
            <path
              d="M95.9830243,37.3412086 L94.3421422,39.2794718 L95.8598126,41.1713912 C96.7899597,42.0738942 97.8511543,41.8945059 99.0433964,40.6332263 C100.235638,39.3719468 99.9053824,38.0317512 98.0526277,36.6126396 L94.6739097,32.7814182"
              id="Path-10"
            ></path>
            <path
              d="M91.0637781,32.7814182 L87.4699953,28.9138889 L85.6750567,30.3050882 L83.459382,28.3523249 C82.4353674,26.9480598 82.6413452,25.8053627 84.0773154,24.9242335 C85.5132856,24.0431043 86.9273891,24.2870142 88.3196257,25.6559632 L93.321682,30.980428"
              id="Path-10"
            ></path>
            <line x1="96.0474568" y1="37.860202" x2="93.8364553" y2="35.3650773" id="Path-9"></line>
            <path
              d="M54.8399864,21.5513131 C55.6734045,22.0465778 56.3400711,22.3513441 56.8399864,22.4656121 C57.3399017,22.5798801 58.0065683,22.5798801 58.8399864,22.4656121"
              id="Path-7"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>

<svg
  id="building"
  width="86px"
  height="74px"
  viewBox="0 0 86 74"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
    <g id="Start" transform="translate(-112.000000, -88.000000)" stroke="#000000">
      <g id="Group" transform="translate(113.000000, 88.000000)">
        <g id="Group-2">
          <g id="Group-3" transform="translate(0.430689, 0.677840)">
            <path
              d="M-2.84217094e-14,72.3655126 L-2.84217094e-14,47.5422178 C-2.84217094e-14,46.6243033 0.624833826,45.8242292 1.51539524,45.6018163 L18.1933116,41.4365971 L31.3834625,43.9874355 L31.3834625,72.3655126"
              id="Path"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <line
              x1="18.7618526"
              y1="42.139527"
              x2="18.7618526"
              y2="72.3655126"
              id="Path"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
            <path
              d="M31.3834625,43.6161852 L31.3834625,34.4311971 C31.3834625,31.001428 33.5697191,27.9532573 36.8184101,26.8535651 L63.6735335,17.7630208 C65.446744,17.1627834 67.3743024,17.2034106 69.120647,17.8778299 L78.1164616,21.3519157 C81.2006304,22.5429882 83.2344006,25.50857 83.2344006,28.8147385 L83.2344006,72.3655126"
              id="Path"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <line
              x1="66.5192955"
              y1="17.5369806"
              x2="66.5192955"
              y2="71.6625827"
              id="Path"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></line>
            <line
              x1="55.6295489"
              y1="14.3235868"
              x2="48.0723272"
              y2="17.9386549"
              id="Line"
              stroke-linecap="square"
            ></line>
            <line
              x1="55.6295489"
              y1="9.40307746"
              x2="48.0723272"
              y2="13.0181456"
              id="Line"
              stroke-linecap="square"
            ></line>
            <line
              x1="51.850938"
              y1="6.29010226"
              x2="51.850938"
              y2="21.75456"
              id="Line-8"
              stroke-linecap="square"
            ></line>
            <path
              d="M39.1722428,11.3651499 C41.4631593,4.25206866 45.9864288,0.464683689 52.7420516,0.00299485947 C53.5278423,-0.0507071905 56.8261186,0.614444059 59.31037,2.27002906 C61.3216483,3.61040956 62.5375544,5.80404006 63.5190637,7.18193656"
              id="Path-8"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
