@if (defaultIdentity | async) {
<div id="status-menu">
  <!-- TOP BAR "STATUS-MENU BUTTON" -->
  <div
    #menuTrigger="matMenuTrigger"
    id="onsip--status-menu--toggle-menu"
    class="avatar-container"
    [matMenuTriggerFor]="menu"
    [attr.data-cy]="E2eLocators.NAV_ADMINS_ACCOUNT_SETTINGS"
  >
    <onsip-avatar
      [avatarUrl]="(myAvatar | async) || ''"
      [name]="(contactName | async) || ''"
    ></onsip-avatar>
    @if ((firebaseInitialized | async) && notSpoofingAgents) {
    <mat-icon
      id="onsip--status-menu--available-{{ availability }}"
      [svgIcon]="availability ? 'onsip_presence_available' : 'onsip_presence_away'"
    >
    </mat-icon>
    }
  </div>
  <!-- MENU -->
  <mat-menu #menu="matMenu" class="mat-typography onsip-menu-status-menu">
    <!-- NAME SECTION -->
    <div class="status-menu-user-overview" (click)="$event.stopPropagation()">
      <h3 class="status-menu-title">{{ contactName | async }}</h3>
      <div class="account-id">{{ "ONSIP_I18N.ACCT_ID" | translate }}: {{ accountId | async }}</div>
      @if (showAdminTabs && orgName) {
      <div class="org-name">{{ orgName }}</div>
      }
    </div>
    <!-- AVAILABILITY SELECTION -->
    @if (notSpoofingAgents) {
    <div
      class="status-menu-availability-container status-menu-section"
      (click)="$event.stopPropagation()"
    >
      <mat-radio-group
        class="status-menu-availability-radio-buttons"
        onsip-status-menu-radio-group
        required="true"
        [(ngModel)]="availability"
        [disabled]="(firebaseInitialized | async) === false"
      >
        <mat-radio-button labelPosition="before" onsipCustomColor="onsip-radio-dark" [value]="true">
          <mat-icon svgIcon="onsip_presence_available"></mat-icon>
          <span class="mat-body-1">{{ "ONSIP_I18N.AVAILABLE" | translate }}</span>
        </mat-radio-button>
        <mat-radio-button
          labelPosition="before"
          onsipCustomColor="onsip-radio-dark"
          [value]="false"
        >
          <mat-icon svgIcon="onsip_presence_away"></mat-icon>
          <span class="mat-body-1">{{ "ONSIP_I18N.AWAY" | translate }}</span>
        </mat-radio-button>
      </mat-radio-group>
    </div>
    }
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      onsip-status-menu-e911-button
      id="onsip-status-menu-edit-e911-location"
      class="status-menu-option"
      (click)="openE911Dialog()"
    >
      @if (e911Location | async) {
      <span class="e911-location-button">
        <span>e911 location: {{ e911Location | async }}</span>
        <mat-icon>edit</mat-icon>
      </span>
      } @else {
      <span class="e911-location-button">
        <span>Set E911 location</span>
        <mat-icon>edit</mat-icon>
      </span>
      }
    </button>
    <mat-divider></mat-divider>
    <!-- MENU OPTIONS -->
    @if (notSpoofingAgents) {
    <button
      mat-menu-item
      id="onsip--status-menu--go-to-app-settings"
      class="status-menu-option"
      [routerLink]="views.APP_SETTINGS"
    >
      {{ "ONSIP_I18N.APP_SETTINGS" | translate }}
    </button>
    }
    <!-- <mat-divider *ngIf="((showAccountAdminInfo && !isDesktop) || professionalCallLink) && notSpoofingAgents"></mat-divider> -->
    @if (showAccountAdminInfo && !isDesktop) {
    <button
      mat-menu-item
      id="onsip--status-menu--account-and-payment"
      class="status-menu-option acc-payment"
      [routerLink]="views.ADMIN_ACCOUNT_SETTINGS_PLAN_OVERVIEW"
      [attr.data-cy]="E2eLocators.ACCOUNT_BILLING_PLAN_ROUTE_BUTTON"
    >
      Account & payment
    </button>
    } @if (professionalCallLink && notSpoofingAgents) {
    <a
      mat-menu-item
      id="onsip--status-menu--go-to-personal-page"
      class="status-menu-option personal-call-page"
      target="_blank"
      [href]="professionalCallLink"
    >
      <div class="personal-call-page-link">
        {{ "ONSIP_I18N.PERSONAL_CALL_PAGE" | translate }}
        <mat-icon>open_in_new</mat-icon>
      </div>
    </a>
    } @if (showAccountAdminInfo) {
    <button
      mat-menu-item
      id="onsip--status-menu--go-to-support"
      class="status-menu-option support-page"
      [routerLink]="views.CONTACT_SUPPORT"
    >
      {{ "ONSIP_I18N.SUPPORT" | translate }}
    </button>
    } @if (showSuperAdminButton | async) {
    <button
      mat-menu-item
      id="onsip--status-menu--super-admin"
      class="status-menu-option super-admin"
      [routerLink]="superAdminButtonRoute | async"
    >
      {{
        (superAdminButtonRoute | async) === views.SUPER_USER_VIEW ? "Super Admin" : "Agent Portal"
      }}
    </button>
    }
    <mat-divider></mat-divider>
    <button
      mat-menu-item
      id="onsip--status-menu--logout"
      class="status-menu-option"
      (click)="onLogoutClick()"
    >
      {{ "ONSIP_I18N.LOG_OUT" | translate }}
    </button>
  </mat-menu>
</div>
}
