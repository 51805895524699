<div class="header">
  <div class="mat-headline-6 commission-title">Commission</div>
  <div>
    <a mat-button color="primary" href="https://support.onsip.com" target="_blank">Knowledgebase</a>
  </div>
</div>
<div class="search-filters onsip-grid-placement">
  <mat-form-field appearance="outline" class="search-field">
    <input matInput [formControl]="search" [placeholder]="'Search commission'" autocomplete="off" />
    <mat-icon matSuffix color="primary">search</mat-icon>
  </mat-form-field>
  <mat-form-field appearance="outline" class="filter-type">
    <mat-label>Type</mat-label>
    <mat-select [formControl]="filterOption">
      @for (option of filterType; track option) {
      <mat-option [value]="option">
        {{ option | titlecase }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div class="table-container">
  <onsip-table-two
    multiTemplateDataRows
    matSort
    [dataSource]="dataSource"
    [trackBy]="trackByTransactionId"
    [useGrid]="true"
    [hoverable]="true"
    class="transaction-table"
  >
    <!-- Transaction Id Column -->
    <ng-container matColumnDef="transactionId" [sticky]="true">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Transaction Id </mat-header-cell>
      <mat-cell *matCellDef="let transaction">
        <span class="ellipsis">{{ transaction.agentTransactionId }}</span>
      </mat-cell>
    </ng-container>
    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
      <mat-cell *matCellDef="let transaction">
        <span class="ellipsis">{{ transaction.date }}</span>
      </mat-cell>
    </ng-container>
    <!-- Type Column -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
      <mat-cell *matCellDef="let transaction">
        <span class="ellipsis">{{ transaction.type | sentenceCase }}</span>
      </mat-cell>
    </ng-container>
    <!-- Amount Column -->
    <ng-container matColumnDef="amount">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Amount </mat-header-cell>
      <mat-cell *matCellDef="let transaction">
        <span class="ellipsis">{{ transaction.amount | currency }}</span>
      </mat-cell>
    </ng-container>
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let transaction" class="action-buttons">
        @if (selectedRow?.agentTransactionId !== transaction.agentTransactionId) {
        <mat-icon color="primary">expand_more</mat-icon>
        } @if (selectedRow?.agentTransactionId === transaction.agentTransactionId) {
        <mat-icon color="primary">expand_less</mat-icon>
        }
      </mat-cell>
    </ng-container>
    <!-- SHELF -->
    <ng-container matColumnDef="expanded">
      <onsip-table-shelf-cell
        *matCellDef="let transaction"
        [attr.colspan]="displayedColumns.length"
        [expanded]="transaction.agentTransactionId === selectedRow?.agentTransactionId"
      >
        @if (transaction.agentTransactionId === selectedRow?.agentTransactionId) {
        <onsip-agent-commissions-shelf [transaction]="transaction"></onsip-agent-commissions-shelf>
        }
      </onsip-table-shelf-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectedRow = selectedRow === row ? null : row"
      [class.selected]="selectedRow === row"
    >
    </mat-row>
    <onsip-table-shelf *matRowDef="let row; columns: ['expanded']"></onsip-table-shelf>
    <div *matNoDataRow class="no-data-row">
      <span> No transactions </span>
    </div>
  </onsip-table-two>
</div>
<mat-paginator
  onsip-paginator-primary-icon
  showFirstLastButtons
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
>
</mat-paginator>
