<div class="container">
  <mat-tab-group selectedIndex="0" (selectedTabChange)="onChangeTab($event)">
    <mat-tab label="Color">
      <mat-card>
        <mat-card-title>OnSIP Custom Colors</mat-card-title>
        <mat-card-content>
          <mat-card>
            <mat-card-subtitle>Calling</mat-card-subtitle>
            <mat-card-content>
              <div class="onsip-call-begin-background">Call Begin</div>
              <div class="onsip-call-end-background">Call End</div>
              <p></p>
              <button mat-fab class="onsip-call-begin-background">
                <mat-icon>call_begin</mat-icon>
              </button>
              <button mat-fab class="onsip-call-end-background">
                <mat-icon>call_end</mat-icon>
              </button>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle>Tooltips</mat-card-subtitle>
            <mat-card-content>
              <div class="onsip-tooltip-background">Tooltip Message</div>
              <p></p>
              <button
                mat-raised-button
                matTooltip="Info about the action"
                matTooltipClass="onsip-tooltip-background"
                aria-label="Button that displays a tooltip when focused or hovered over"
              >
                Action
              </button>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle>Settings</mat-card-subtitle>
            <mat-card-content>
              <div style="display: flex; align-items: center">
                <mat-icon class="onsip-video-enabled-color">check</mat-icon>&nbsp;Video Enabled
              </div>
              <div style="display: flex; align-items: center">
                <mat-icon class="onsip-video-disabled-color">close</mat-icon>&nbsp;Video Disabled
              </div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>OnSIP Color Palettes</mat-card-title>
        <mat-card-content>
          <mat-card>
            <mat-card-subtitle>Primary</mat-card-subtitle>
            <mat-card-content>
              <div class="primary-50-bg">50</div>
              <div class="primary-100-bg">100 - Light</div>
              <div class="primary-200-bg">200</div>
              <div class="primary-300-bg">300</div>
              <div class="primary-400-bg">400</div>
              <div class="primary-500-bg">500 - Default</div>
              <div class="primary-600-bg">600</div>
              <div class="primary-700-bg">700 - Dark</div>
              <div class="primary-800-bg">800</div>
              <div class="primary-900-bg">900</div>
              <div class="primary-A100-bg">A100</div>
              <div class="primary-A200-bg">A200</div>
              <div class="primary-A400-bg">A400</div>
              <div class="primary-A700-bg">A700</div>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle>Accent</mat-card-subtitle>
            <mat-card-content>
              <div class="accent-50-bg">50</div>
              <div class="accent-100-bg">100 - Light</div>
              <div class="accent-200-bg">200</div>
              <div class="accent-300-bg">300</div>
              <div class="accent-400-bg">400</div>
              <div class="accent-500-bg">500 - Default</div>
              <div class="accent-600-bg">600</div>
              <div class="accent-700-bg">700 - Dark</div>
              <div class="accent-800-bg">800</div>
              <div class="accent-900-bg">900</div>
              <div class="accent-A100-bg">A100</div>
              <div class="accent-A200-bg">A200</div>
              <div class="accent-A400-bg">A400</div>
              <div class="accent-A700-bg">A700</div>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle>Warnings & Errors</mat-card-subtitle>
            <mat-card-content>
              <div class="warn-50-bg">50</div>
              <div class="warn-100-bg">100 - Light</div>
              <div class="warn-200-bg">200</div>
              <div class="warn-300-bg">300</div>
              <div class="warn-400-bg">400</div>
              <div class="warn-500-bg">500 - Default</div>
              <div class="warn-600-bg">600</div>
              <div class="warn-700-bg">700 - Dark</div>
              <div class="warn-800-bg">800</div>
              <div class="warn-900-bg">900</div>
              <div class="warn-A100-bg">A100</div>
              <div class="warn-A200-bg">A200</div>
              <div class="warn-A400-bg">A400</div>
              <div class="warn-A700-bg">A700</div>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Typography">
      <mat-card>
        <mat-card-title>OnSIP Custom Typography Levels</mat-card-title>
        <mat-card-content>
          <hr />
          <p>&nbsp;</p>
          <span class="typography onsip-page-header">Page Header - 300 32px/40px</span>
          <span class="typography onsip-table-header-cell"
            >Table Column Header - 400 12px/16px</span
          >
          <span class="typography onsip-contact-list-timer"
            >Contact List Timer - 300 12px/14px</span
          >
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Angular Material Typography Levels being used by OnSIP</mat-card-title>
        <mat-card-content>
          <hr />
          <p>&nbsp;</p>
          <!-- Not using these at this point -->
          <!-- <span class="typography mat-headline-1">Display 4 - 300 112px/112px</span> -->
          <!-- <span class="typography mat-headline-2">Display 3 - 56px/56px</span> -->
          <!-- <span class="typography mat-headline-3">Display 2 - 45px/48px</span> -->
          <!-- <span class="typography mat-headline-4">Display 1 - 300 34px/40px</span> -->
          <span class="typography mat-headline-5">Headline-5 - 300 24px/32px</span>
          <span class="typography mat-headline-6">Headline-6 - 300 20px/32px</span>
          <span class="typography mat-subtitle-1">Subtitle-1 - 300 16px/28px</span>
          <!-- <span class="typography mat-subtitle-2">Subheading 1 - 300 15px/24px</span> -->
          <span class="typography mat-body-1">Body 1 - 400 14px/20px</span>
          <span class="typography mat-body-2">Body 2 - 300 14px/20px</span>
          <span class="typography mat-caption">Caption - 300 12px/20px</span>
          <span class="typography">
            <button mat-button>Button - 400 14px/14px</button>
          </span>
          <span class="typography">
            <mat-form-field appearance="outline">
              <mat-label>Input</mat-label>
              <input matInput placeholder="Placeholder" />
              <mat-hint>Hint</mat-hint>
            </mat-form-field>
          </span>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Angular Material Typography Levels</mat-card-title>
        <mat-card-content>
          <hr />
          <p>&nbsp;</p>
          <!-- Not using these at this point
    <span class="typography mat-headline-1">Display 4 - 300 112px/112px</span>
    <span class="typography mat-headline-2">Display 3 - 56px/56px</span>
    <span class="typography mat-headline-3">Display 2 - 45px/48px</span>
    -->
          <span class="typography mat-headline-4">Headline-4 - 300 34px/40px</span>
          <span class="typography mat-headline-5">Headline-5 - 300 24px/32px</span>
          <span class="typography mat-headline-6">Headline-6 - 300 20px/32px</span>
          <span class="typography mat-subtitle-1">Subtitle-1 - 300 16px/28px</span>
          <span class="typography mat-subtitle-2">Subtitle-2 - 300 15px/24px</span>
          <span class="typography mat-body-1">Body 1 - 400 14px/20px</span>
          <span class="typography mat-body-2">Body 2 - 300 14px/20px</span>
          <span class="typography mat-caption">Caption - 300 12px/20px</span>
          <span class="typography">
            <button mat-button>Button - 400 14px/14px</button>
          </span>
          <span class="typography">
            <mat-form-field appearance="outline">
              <mat-label>Input</mat-label>
              <input matInput placeholder="Placeholder" />
              <mat-hint>Hint</mat-hint>
            </mat-form-field>
          </span>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Iconography">
      <mat-card>
        <mat-card-title>OnSIP Custom Icons</mat-card-title>
        <mat-card-content>
          <mat-card>
            <mat-card-subtitle>Presence</mat-card-subtitle>
            <mat-card-content>
              <p style="display: flex; align-items: center">
                <mat-icon svgIcon="onsip_presence_available"></mat-icon>&nbsp;available
              </p>
              <p style="display: flex; align-items: center">
                <mat-icon svgIcon="onsip_presence_busy"></mat-icon>&nbsp;busy
              </p>
              <p style="display: flex; align-items: center">
                <mat-icon svgIcon="onsip_presence_away"></mat-icon>&nbsp;away
              </p>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle> Incoming Call </mat-card-subtitle>
            <mat-card-content>
              <p style="display: flex; align-items: center">
                <onsip-pulsing-dot [connected]="false"></onsip-pulsing-dot>Connecting Call
              </p>
              <p style="display: flex; align-items: center">
                <onsip-pulsing-dot [connected]="true"></onsip-pulsing-dot>Connected Call
              </p>
            </mat-card-content>
          </mat-card>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Elevations">
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z0">0</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z1">1</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z2">2</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z3">3</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z4">4</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z5">5</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z6">6</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z7">7</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z8">8</mat-card>
      <mat-card style="height: 10px; margin: 10px" class="mat-elevation-z9">9</mat-card>
    </mat-tab>
    <mat-tab label="Components">
      <mat-card>
        <mat-card-title>Selection Controls</mat-card-title>
        <mat-card-content>
          <p>
            <mat-checkbox color="primary" [checked]="true">Text</mat-checkbox>
          </p>
          <p>
            <mat-radio-group class="example-radio-group" color="primary">
              <mat-radio-button class="example-radio-button" value="1">Option 1</mat-radio-button>
              <mat-radio-button value="2">Option 2</mat-radio-button>
            </mat-radio-group>
          </p>
          <p>
            <mat-slide-toggle color="primary">Slide me!</mat-slide-toggle>
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Sliders</mat-card-title>
        <mat-card-content>
          <p>
            <mat-slider color="primary"></mat-slider>
          </p>
          <p>
            <mat-slider
              color="primary"
              thumbLabel
              [displayWith]="formatLabel"
              tickInterval="1000"
              min="1"
              max="100000"
            ></mat-slider>
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Anchor Links</mat-card-title>
        <mat-card-content>
          <p>
            <a href="_blank">Anchor Link</a>
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Buttons</mat-card-title>
        <mat-card-content>
          <h3>Text Buttons</h3>
          <p>
            <button mat-button>Basic</button>
            <button mat-button color="primary">Primary</button>
            <button mat-button color="accent">Accent</button>
            <button mat-button color="warn">Warn</button>
            <button mat-button disabled>Disabled</button>
            <button mat-button color="primary"><mat-icon>add</mat-icon>Icon</button>
            <a mat-button routerLink=".">Link</a>
          </p>
          <h3>Stroked Buttons</h3>
          <p>
            <button mat-stroked-button>Basic</button>
            <button mat-stroked-button color="primary">Primary</button>
            <button mat-stroked-button color="accent">Accent</button>
            <button mat-stroked-button color="warn">Warn</button>
            <button mat-stroked-button disabled>Disabled</button>
            <button mat-stroked-button color="primary"><mat-icon>add</mat-icon> Icon</button>
            <a mat-button routerLink=".">Link</a>
          </p>
          <h3>Flat Buttons</h3>
          <p>
            <button mat-flat-button>Basic</button>
            <button mat-flat-button color="primary">Primary</button>
            <button mat-flat-button color="accent">Accent</button>
            <button mat-flat-button color="warn">Warn</button>
            <button mat-flat-button disabled>Disabled</button>
            <button mat-flat-button color="primary"><mat-icon>add</mat-icon> Icon</button>
            <a mat-button routerLink=".">Link</a>
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Form Fields</mat-card-title>
        <mat-card-content>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Input</mat-label>
              <input matInput placeholder="Placeholder" />
              <mat-icon matSuffix>sentiment_very_satisfied</mat-icon>
              <mat-hint>Hint</mat-hint>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Select</mat-label>
              <mat-select placeholder="Select">
                <mat-option value="option1">Option 1</mat-option>
                <mat-option value="option2">Option 2</mat-option>
              </mat-select>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <textarea matInput placeholder="Textarea"></textarea>
            </mat-form-field>
          </p>
          <h3>Input disabled</h3>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Input</mat-label>
              <input matInput placeholder="Placeholder" disabled="true" />
              <mat-hint>Hint</mat-hint>
            </mat-form-field>
          </p>
          <h3>Input with error messages</h3>
          <form class="example-form">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Email</mat-label>
              <input matInput placeholder="Email" [formControl]="emailFormControl" />
              @if (emailFormControl.hasError('email') && !emailFormControl.hasError('required')) {
              <mat-error> Please enter a valid email address </mat-error>
              } @if (emailFormControl.hasError('required')) {
              <mat-error> Email is <strong>required</strong> </mat-error>
              }
            </mat-form-field>
          </form>
          <h3>Input with hints</h3>
          <form class="example-form">
            <mat-form-field appearance="outline" class="example-full-width">
              <mat-label>Email</mat-label>
              <input matInput #message maxlength="256" placeholder="Message" />
              <mat-hint align="start">Don't disclose personal info</mat-hint>
              <mat-hint align="end">{{ message.value.length }} / 256</mat-hint>
            </mat-form-field>
          </form>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Input Types</mat-card-title>
        <mat-card-content>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Date</mat-label>
              <input matInput type="date" />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Date & Time</mat-label>
              <input matInput type="datetime-local" />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password" />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <mat-label>Time</mat-label>
              <input matInput type="time" />
            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="outline">
              <input matInput [matDatepicker]="picker" placeholder="Choose a date" />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
          </p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Lists</mat-card-title>
        <mat-card-content>
          <span class="mat-body-1">Basic List</span>
          <mat-list class="list" role="list">
            <mat-list-item role="listitem">Item 1</mat-list-item>
            <mat-list-item role="listitem">Item 2</mat-list-item>
            <mat-list-item role="listitem">Item 3</mat-list-item>
          </mat-list>
          <p>&nbsp;</p>
          <span class="mat-body-1">Dense List</span>
          <mat-list class="list" dense role="list">
            <mat-list-item role="listitem">Item 1</mat-list-item>
            <mat-list-item role="listitem">Item 2</mat-list-item>
            <mat-list-item role="listitem">Item 3</mat-list-item>
          </mat-list>
          <p>&nbsp;</p>
          <span class="mat-body-1">Action List</span>
          <mat-action-list class="list">
            <button mat-list-item (click)="save()">Save</button>
            <button mat-list-item (click)="undo()">Undo</button>
          </mat-action-list>
          <p>&nbsp;</p>
          <span class="mat-body-1">Avatar Multiline List with Action</span>
          <mat-list class="list">
            @for (shoe of typesOfShoes; track shoe) {
            <mat-list-item>
              <img matListItemAvatar class="example-header-image" alt="Always have an alt" />
              <p matLine>
                <span class="mat-h3">Heading</span>
              </p>
              <p matLine>
                <span class="mat-body">{{ shoe }}</span>
              </p>
              <button mat-icon-button (click)="onRemove()">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-list-item>
            }
          </mat-list>
          <p>&nbsp;</p>
          <span class="mat-body-1">Icon Selection List with Divider</span>
          <mat-selection-list class="list" #shoes>
            @for (shoe of typesOfShoes; track shoe) {
            <mat-list-option>
              <mat-icon matListIcon>phone</mat-icon>
              {{ shoe }}
              <mat-divider></mat-divider>
            </mat-list-option>
            }
          </mat-selection-list>
          <p>&nbsp;</p>
        </mat-card-content>
      </mat-card>
      <mat-card>
        <mat-card-title>Menu</mat-card-title>
        <mat-card-content>
          <button mat-button [matMenuTriggerFor]="menu">Menu</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item>Item 1</button>
            <button mat-menu-item>Item 2</button>
          </mat-menu>
        </mat-card-content>
      </mat-card>
      <h2>Cards</h2>
      <span class="mat-body-1">Simple Card</span>
      <mat-card class="card">Content</mat-card>
      <p></p>
      <span class="mat-body-1">Basic Card</span>
      <mat-card class="card">
        <mat-card-title>Title</mat-card-title>
        <mat-card-subtitle>Subtitle</mat-card-subtitle>
        <mat-card-content>Content</mat-card-content>
      </mat-card>
      <p></p>
      <span class="mat-body-1">Rich Card</span>
      <mat-card>
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title>Shiba Inu - an advanced example</mat-card-title>
          <mat-card-subtitle>Dog Breed</mat-card-subtitle>
        </mat-card-header>
        <img
          mat-card-image
          src="https://material.angular.io/assets/img/examples/shiba2.jpg"
          alt="Photo of a Shiba Inu"
        />
        <mat-card-content>
          <p>
            The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from
            Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu
            was originally bred for hunting.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button mat-button>LIKE</button>
          <button mat-button>SHARE</button>
        </mat-card-actions>
        <mat-card-content>
          <h2>Table</h2>
          <span class="mat-body-1">ONSIP table</span>
          <onsip-developer-table></onsip-developer-table>
        </mat-card-content>
      </mat-card>
      <p></p>
    </mat-tab>
    <mat-tab label="Custom components">
      <mat-card>
        <mat-card-title>Custom Lists</mat-card-title>
        <mat-card-content>
          <span class="mat-body-1">Shell navigations</span>
          <onsip-developer-shell-navigation></onsip-developer-shell-navigation>
          <p></p>
        </mat-card-content>
        <mat-card-content>
          <span class="mat-body-1">Multiline Item List</span>
          <div class="custom-list-wrapper">
            <onsip-multiline-item-list
              class="custom-list"
              [listItems]="multiLinesItemList"
              itemNamePlural="Some multiline item list"
              itemNameSingular="Some multiline item list"
            ></onsip-multiline-item-list>
          </div>
          <p>&nbsp;</p>
          <span class="mat-body-1">Item List</span>
          <div class="custom-list-wrapper">
            <onsip-item-list
              class="custom-list"
              [listItems]="listItems"
              [itemNamePlural]="'Empty item list'"
            ></onsip-item-list>
          </div>
          <p>&nbsp;</p>
        </mat-card-content>
        <mat-card-content>
          <span class="mat-body-1">Edit Item List</span>
          <onsip-edit-item-list
            class="edit-item-list"
            [formControl]="editItemListControl"
            [getErrorMessage]="getEditItemListErrorMessage"
            [hint]="'some hint text'"
            [itemNamePlural]="'Some edit item list'"
            [itemType]="'item type'"
            [listItems]="editItemListControl.value"
            [validators]="editItemListValidators"
          >
          </onsip-edit-item-list>
          <p></p>
        </mat-card-content>
        <mat-card-content>
          <div class="mat-body-1 m-b-8">Settings for recorder with toggle control</div>
          <div class="m-b-8">
            <mat-checkbox
              color="primary"
              [(ngModel)]="hasSelect"
              (ngModelChange)="onRecorderHasSelectChange($event)"
              >Can select from existing recordings</mat-checkbox
            >
          </div>
          <div class="m-b-8">
            <mat-checkbox color="primary" [(ngModel)]="hasSave">Has save button</mat-checkbox>
          </div>
          <div class="mat-body-1 m-b-8">Recorder with toggle control</div>
          <onsip-togglable-recorder
            class="togglable-recorder"
            [hasSelect]="hasSelect"
            [hasSave]="hasSave"
          ></onsip-togglable-recorder>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Charts">
      <mat-card-content>
        <mat-card>
          <mat-form-field onsip-form-field-infix-auto appearance="outline">
            <mat-select [formControl]="dataInterval">
              @for (option of intervalOptions; track option) {
              <mat-option [value]="option">
                {{ option.label }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-card-title>Line Graph</mat-card-title>
          <mat-card-content>
            <h1 class="mat-headline-6">Inbound and outbound calling trends</h1>
            <onsip-highcharts-line-graph
              [data]="lineGraphData"
              [title]="lineGraphTitle"
            ></onsip-highcharts-line-graph>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-title>Donut Graph</mat-card-title>
          <mat-card-content>
            <div>
              <span>Active</span>
              <mat-slider
                [(ngModel)]="activeDonuts"
                (change)="updateData()"
                thumbLabel
                min="0"
                max="100"
              ></mat-slider
              ><span>{{ activeDonuts }}</span>
            </div>
            <div>
              <span>Inactive</span>
              <mat-slider
                [(ngModel)]="inactiveDonuts"
                (change)="updateData()"
                thumbLabel
                min="0"
                max="100"
              ></mat-slider
              ><span>{{ inactiveDonuts }}</span>
            </div>
            <div class="flex-this">
              <onsip-donut-graph [data]="donutData"> </onsip-donut-graph>
              <onsip-donut-graph [data]="donutData" [feature]="'users'"> </onsip-donut-graph>
              <onsip-donut-graph
                [data]="donutData"
                [feature]="'desk phone'"
                [legendLayout]="'horizontal'"
                [graphDiameter]="175"
              >
              </onsip-donut-graph>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card>
          <mat-card-title>Bar Graph</mat-card-title>
          <mat-card-content>
            <div class="flex-this">
              <onsip-highcharts-bar-graph [data]="destBarData"></onsip-highcharts-bar-graph>
              <onsip-highcharts-bar-graph
                [data]="userBarData"
                [categoryName]="'Username'"
              ></onsip-highcharts-bar-graph>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-card-content>
    </mat-tab>
  </mat-tab-group>
</div>
