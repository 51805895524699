import { Injectable } from "@angular/core";
import { BehaviorSubject, withLatestFrom } from "rxjs";
import { map, filter, distinctUntilChanged } from "rxjs/operators";
import { ApiSessionState } from "../api-session.service";
import { ApiStateStoreService, ApiSubstate } from "../api-state-store.service";
import { User } from "../resources/user/user";
import { userId } from "./user-id";

@Injectable({ providedIn: "root" })
export class CurrentOrganizationService {
  static currentOrgId = new BehaviorSubject<string>("");
  /** org id when first logged in */
  firstOrgId = "";
  /** the org id switched to while in the admin tab */
  adminOrgId = "";

  constructor(store: ApiStateStoreService) {
    // get current org based of userId
    store.state
      .pipe(
        withLatestFrom(store.state.pipe(userId())),
        map(
          ([store, selfUserId]) =>
            (store.Session?.state as ApiSessionState).organizationId ||
            (store.User as ApiSubstate<User>)?.state[selfUserId]?.organizationId
        ),
        distinctUntilChanged(),
        filter(<T>(val: T): val is NonNullable<T> => !!val)
      )
      .subscribe(orgId => {
        this.firstOrgId = orgId;
        CurrentOrganizationService.currentOrgId.next(orgId);
      });
  }

  /** switch stored current org id to requested new org id */
  switchCurrentOrg(orgId: string): void {
    CurrentOrganizationService.currentOrgId.next(orgId);
    // save orgId when we need to come back to the admin page
    this.adminOrgId = CurrentOrganizationService.currentOrgId.value.slice();
  }
}
