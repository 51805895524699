<onsip-table-two
  class="example-table"
  multiTemplateDataRows
  matSort
  [dataSource]="dataSource"
  [useGrid]="true"
  [hoverable]="true"
>
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
    <mat-cell *matCellDef="let source">
      <span>{{ source.date }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
    <mat-cell *matCellDef="let source">
      <span>{{ source.name }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="time">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Time</mat-header-cell>
    <mat-cell *matCellDef="let source">
      <span>{{ source.time }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
    <mat-cell *matCellDef="let source">
      <span>{{ source.status }}</span>
    </mat-cell>
  </ng-container>
  <!-- Action Column -->
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <div class="controls-wrapper">
        @if (selectedRows.includes(row.id)) {
        <mat-icon color="primary">expand_less </mat-icon>
        } @if (!selectedRows.includes(row.id)) {
        <mat-icon color="primary">expand_more </mat-icon>
        }
        <button mat-icon-button (click)="removeItem(row, $event)">
          <mat-icon color="primary">delete</mat-icon>
        </button>
      </div>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="expanded">
    <onsip-table-shelf-cell
      *matCellDef="let item"
      [attr.colspan]="displayedColumns.length"
      [expanded]="selectedRows.includes(item.id)"
    >
      <div onsip-grid-placement-shelf>
        <div class="first-column">
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
        </div>
        <div class="second-column">
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
          <section>
            <div class="mat-body-1">Label</div>
            <div>Some Data</div>
          </section>
        </div>
      </div>
    </onsip-table-shelf-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="
      selectedRows.includes(row.id)
        ? selectedRows.splice(selectedRows.indexOf(row.id), 1)
        : selectedRows.push(row.id)
    "
  >
  </mat-row>
  <onsip-table-shelf *matRowDef="let row; columns: ['expanded']"></onsip-table-shelf>
  <div *matNoDataRow class="no-data-row">No results</div>
</onsip-table-two>
