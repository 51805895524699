<div
  class="session-video"
  [ngClass]="{
    conf1: remoteStream || (confCalls && confCalls.length === 1),
    conf2: confCalls && confCalls.length === 2,
    conf3: confCalls && confCalls.length === 3,
    conf4: confCalls && confCalls.length === 4
  }"
>
  <!-- LOCAL VIDEO -->
  @if ((remoteStream || (confCalls && confCalls[0])) && !isCallListItem) {
  <onsip-video-box
    [objectFit]="confCalls && confCalls.length > 1 ? 'cover' : 'contain'"
    class="video-local-feed"
    [localStream]="localStream"
  >
  </onsip-video-box>
  }
  <!-- REMOTE OR CONF[0]-->
  @if ((remoteStream && !isQueueVideo) || (confCalls && confCalls.length > 0)) {
  <onsip-video-box
    [remoteStream]="remoteStream || (confCalls && confCalls[0].remoteStream)"
    [objectFit]="confCalls && confCalls.length > 1 ? 'cover' : 'contain'"
  >
  </onsip-video-box>
  } @if (confCalls) {
  <!-- CONF[1]-->
  @if (confCalls.length > 1) {
  <onsip-video-box
    [remoteStream]="confCalls[1].remoteStream"
    [objectFit]="'cover'"
  ></onsip-video-box>
  }
  <!-- CONF[2]-->
  @if (confCalls.length > 2) {
  <onsip-video-box
    [remoteStream]="confCalls[2].remoteStream"
    [objectFit]="'cover'"
  ></onsip-video-box>
  }
  <!-- CONF[3]-->
  @if (confCalls.length === 4) {
  <onsip-video-box
    [remoteStream]="confCalls[3].remoteStream"
    [objectFit]="'cover'"
  ></onsip-video-box>
  }
  <!-- BLACK BOX -->
  @if (confCalls.length > 1 && confCalls.length < 3) {
  <onsip-video-box class="video__remote-box video__box-up-right video__box-not-used">
  </onsip-video-box>
  } }
  <!-- QUEUE VIDEO -->
  @if (isQueueVideo && !!queueVideoStream && queueVideoStream !== 'no-link') {
  <onsip-queue-video-box [queueVideoStream]="queueVideoStream"> </onsip-queue-video-box>
  }
  <!-- NO VIDEO -->
  @if (isQueueVideo && queueVideoStream === 'no-link') {
  <div class="no-video">
    <mat-icon>videocam_off</mat-icon>
    <span>{{ "ONSIP_I18N.NO_INCOMING_VIDEO_RECEIVED" | translate }}</span>
  </div>
  } @if (!isQueueVideo && !remoteStream && confCalls && confCalls.length === 0 && !callUuid) {
  <div class="no-video">
    <mat-icon>videocam_off</mat-icon>
    @if (!!statusString) {
    <span [innerHtml]="statusString | titlecase"></span>
    }
    <br />
    <mat-spinner onsip-white-spinner></mat-spinner>
  </div>
  }
</div>
