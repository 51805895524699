<h1 [attr.data-cy]="E2eLocators.CONTACTS_PAGE_NAME_TEXT">
  {{ contact.custom && editing ? ("ONSIP_I18N.EDIT_CUSTOM_CONTACT" | translate) : contact.name }}
</h1>
<div class="actionbar">
  @if (!editing) {
  <button
    id="onsip--contact--edit-contact"
    color="primary"
    mat-stroked-button
    [disabled]="!contact.custom || !isInContactList"
    [attr.data-cy]="E2eLocators.CONTACTS_PAGE_EDIT_BTN"
    (click)="editing = true"
  >
    <mat-icon>edit</mat-icon>
    {{ "ONSIP_I18N.EDIT_CONTACT" | translate }}
  </button>
  } @if (editing) {
  <button
    id="onsip--contact--save-contact"
    color="primary"
    mat-stroked-button
    [disabled]="!!isUpdating"
    [attr.data-cy]="E2eLocators.CONTACTS_PAGE_SAVE_BTN"
    (click)="updateCustomContact()"
  >
    <mat-icon>save</mat-icon>
    {{ "ONSIP_I18N.SAVE_CHANGES" | translate }}
  </button>
  } @if (isInContactList) {
  <button
    id="onsip--contact--remove-contact"
    color="primary"
    mat-stroked-button
    (click)="removeContact()"
    [disabled]="!!isUpdating"
    [attr.data-cy]="E2eLocators.CONTACTS_PAGE_DELETE_BTN"
  >
    <mat-icon>delete</mat-icon>
    {{ "ONSIP_I18N.DELETE_CONTACT" | translate }}
  </button>
  } @if (!isInContactList && !(contact.custom && (contact.aors[0] &&
  contact.aors[0].endsWith('anonymous.invalid')))) {
  <button
    id="onsip--contact--add-contact"
    color="primary"
    mat-stroked-button
    (click)="contact.custom ? addCustomContact() : addContact()"
    [disabled]="!!isUpdating"
    [attr.data-cy]="E2eLocators.CONTACTS_PAGE_ADD_AGAIN_BTN"
  >
    <mat-icon>add</mat-icon>
    {{ "ONSIP_I18N.ADD_NEW_CONTACT" | translate }}
  </button>
  }
</div>
<div class="content">
  <onsip-avatar-frame>
    <onsip-avatar [avatarUrl]="contact.avatarUrl" [name]="contact.name"></onsip-avatar>
  </onsip-avatar-frame>
  <form [formGroup]="customContactForm" class="subcontent">
    <span class="mat-body-1" [class.invisible-height]="!isInContactList || !!contact.custom">
      {{
        !isInContactList || !!contact.custom
          ? ""
          : ((isBusy
              ? "ONSIP_I18N.BUSY"
              : isAvailable
              ? "ONSIP_I18N.AVAILABLE"
              : "ONSIP_I18N.UNAVAILABLE"
            )
            | translate
            | uppercase)
      }}
    </span>
    <!-- EDIT NAME -->
    @if (editing) {
    <mat-form-field appearance="outline">
      <mat-label>{{ "ONSIP_I18N.NAME" | translate }}</mat-label>
      <input
        matInput
        formControlName="name"
        autocomplete="off"
        [attr.data-cy]="E2eLocators.CONTACTS_PAGE_NAME_INPUT"
      />
      <mat-error>
        {{ getErrorMessage("name") }}
      </mat-error>
    </mat-form-field>
    }
    <!-- CALL BUTTONS -->
    <div class="button-container">
      <button
        mat-fab
        id="onsip--contact--voice-call"
        aria-label="start voice call"
        onsipNewCall
        [contact]="contact"
        [remoteAor]="
          contact.aors[0] || (contact.e164PhoneNumbers && contact.e164PhoneNumbers[0]) || ''
        "
        class="onsip-call-begin-background mat-elevation-z0"
      >
        <mat-icon>phone</mat-icon>
      </button>
      <button
        mat-fab
        id="onsip--contact--video-call"
        aria-label="start video call"
        onsipNewCall
        [contact]="contact"
        [remoteAor]="
          contact.aors[0] || (contact.e164PhoneNumbers && contact.e164PhoneNumbers[0]) || ''
        "
        [video]="true"
        class="onsip-call-begin-background mat-elevation-z0"
      >
        <mat-icon>videocam</mat-icon>
      </button>
    </div>
    <mat-divider onsip-dotted-divider></mat-divider>
    <!-- CONTACT INFO -->
    @if (contact.extensions && contact.extensions.length > 0 && !editing) {
    <h3>
      {{
        (contact.extensions.length > 1
          ? "ONSIP_I18N.INTERNAL_EXTENSIONS"
          : "ONSIP_I18N.INTERNAL_EXTENSION"
        ) | translate
      }}
      @if (contact.extensions.length > 1) {
      <br />
      } @for (ext of contact.extensions; track ext; let last = $last) {
      <span>{{ ext }}</span>
      @if (!last) {
      <br />
      } }
    </h3>
    } @if (contact.aors && contact.aors.length > 0 && !editing) {
    <h3>
      {{
        (contact.aors.length > 1 ? "ONSIP_I18N.SIP_ADDRESSES" : "ONSIP_I18N.SIP_ADDRESS")
          | translate
      }}: @if (contact.aors.length > 1) {
      <br />
      } @for (aor of contact.aors; track aor; let last = $last) {
      <span>{{ aor }}</span>
      @if (!last) {
      <br />
      } }
    </h3>
    } @if (contact.e164PhoneNumbers && contact.e164PhoneNumbers.length > 0 && !editing) {
    <h3 [attr.data-cy]="E2eLocators.CONTACTS_PAGE_PHONE_TEXT">
      {{
        (contact.e164PhoneNumbers.length > 1
          ? "ONSIP_I18N.PHONE_NUMBERS"
          : "ONSIP_I18N.PHONE_NUMBER"
        ) | translate
      }}: @if (contact.e164PhoneNumbers.length > 1) {
      <br />
      } @for (phoneNumber of contactPhoneNumbers; track phoneNumber; let last = $last) {
      <span>{{ phoneNumber }}</span>
      @if (!last) {
      <br />
      } }
    </h3>
    }
    <!-- EDIT SIP ADDRESS -->
    @if (editing) {
    <mat-form-field appearance="outline">
      <mat-label>{{ "ONSIP_I18N.SIP_ADDRESS_OR_PHONE_NUMBER" | translate }}</mat-label>
      <input
        matInput
        formControlName="sipAddress"
        autocomplete="off"
        [attr.data-cy]="E2eLocators.CONTACTS_PAGE_PHONE_INPUT"
      />
      @if (this.customContactForm.invalid) {
      <mat-error>
        {{ getErrorMessage("sipAddress") }}
      </mat-error>
      }
    </mat-form-field>
    }
    <mat-divider onsip-dotted-divider></mat-divider>
    <onsip-chat-window [contact]="contact"></onsip-chat-window>
  </form>
</div>
