import { NgZone } from "@angular/core";

import { Call as CallStore } from "../../../libraries/firebase/store/call";

export class Call extends CallStore {
  constructor(private zone: NgZone) {
    super();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(() => {
      return fn();
    });
  }
}
