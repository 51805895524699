import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { LayoutModule } from "../shared/components/layout/layout.module";
import { SnackbarModule } from "../shared/components/snackbar/snackbar.module";
import { AdminSupportRoutingModule } from "./admin-support-routing.module";
import { AdminSupportComponent } from "./admin-support.component";
import { AdminSupportContactSupportComponent } from "./adminSupportContactSupport/admin-support-contact-support.component";
import { AdminSupportSubmitTicketComponent } from "./adminSupportSubmitTicket/admin-support-submit-ticket.component";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SupportTicketsComponent } from "./supportTickets/support-tickets.component";

@NgModule({
  imports: [
    CommonModule,
    AdminSupportRoutingModule,
    RouterModule,
    LayoutModule,
    MatCardModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NewCallModule,
    MatIconModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    SnackbarModule
  ],
  declarations: [
    AdminSupportComponent,
    AdminSupportContactSupportComponent,
    AdminSupportSubmitTicketComponent,
    SupportTicketsComponent
  ],
  exports: [AdminSupportSubmitTicketComponent]
})
export class AdminSupportModule {}
