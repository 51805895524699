@if (!openCustomContactForm) {
<section class="contact-list-edit-section">
  <h4 class="add-contact-title mat-subtitle-2">
    {{ "ONSIP_I18N.ADD_NEW_CONTACT" | translate }}
  </h4>
  <form class="search-contact">
    <mat-form-field class="onsip-form-field-white">
      <mat-icon aria-label="search" matPrefix>search</mat-icon>
      <mat-label>{{ "ONSIP_I18N.TYPE_IN_A_NAME" | translate }}</mat-label>
      <input
        matInput
        class="contact-searchbar"
        type="text"
        [attr.data-cy]="E2eLocators.CONTACTS_CREATE_SEARCH"
        autocomplete="off"
        (keyup)="applyFilter($event.target)"
      />
    </mat-form-field>
  </form>
  <span class="add-custom-contact">
    <button
      mat-button
      class="add-custom-contact-button"
      id="onsip--contact-list-edit--add-custom-contact"
      [attr.data-cy]="E2eLocators.CONTACTS_CREATE_SWITCH_CUSTOM_BTN"
      (click)="toggleCustomContactForm()"
    >
      <mat-icon aria-label="add contact">add</mat-icon>
      <span class="mat-body-1">{{ "ONSIP_I18N.CUSTOM_CONTACT" | translate }}</span>
    </button>
  </span>
  <mat-list class="directory-list">
    @for (contact of displayFilteredDirectory; track contact) {
    <mat-list-item>
      <button
        mat-button
        [attr.data-cy]="E2eLocators.CONTACTS_CREATE_ITEM"
        class="directory-list-item-button"
        id="onsip--contact-list-edit--button"
        (click)="handleContactListClick(contact)"
      >
        <div class="directory-list-item">
          <span class="contact-icons">
            @if (contact) {
            <onsip-avatar
              class="avatar"
              [avatarUrl]="contact?.avatarUrl"
              [name]="contact?.name"
            ></onsip-avatar>
            }
          </span>
          <div class="directory-body">
            <span class="mat-body-1">{{ contact.name }}</span>
            @if (contact.isInContactList) {
            <h5 class="added-text" [innerText]="'Added'"></h5>
            }
          </div>
        </div>
      </button>
    </mat-list-item>
    }
  </mat-list>
</section>
} @if (openCustomContactForm) {
<section class="custom-contact-add-section">
  @if (openCustomContactForm) {
  <div>
    <span class="contact-list-edit-header">
      <h4 class="contact-list-edit-header-title">
        {{ "ONSIP_I18N.CUSTOM_CONTACT" | translate }}
      </h4>
      <button
        mat-button
        id="onsip--contact-list-edit--cancel-custom-contact"
        class="cancel-custom-contact"
        (click)="toggleCustomContactForm()"
      >
        {{ "ONSIP_I18N.CANCEL" | translate }}
      </button>
    </span>
    <form
      [formGroup]="customContactForm"
      #formDirective="ngForm"
      (ngSubmit)="createCustomContact(customContactForm, formDirective)"
    >
      <span class="custom-contact-input">
        <mat-form-field class="onsip-form-field-white">
          <mat-label>{{ "ONSIP_I18N.NAME" | translate }}</mat-label>
          <input
            matInput
            [attr.data-cy]="E2eLocators.CONTACTS_CREATE_CUSTOM_NAME_INPUT"
            autocomplete="off"
            formControlName="name"
            id="onsip--contact-list-edit--add-custom-contact--name-input"
          />
          <mat-error [attr.data-cy]="E2eLocators.CONTACTS_CREATE_CUSTOM_NAME_ERROR">
            {{ getErrorMessage(customContactForm, "name") }}
          </mat-error>
          <span> </span>
        </mat-form-field>
        <mat-form-field class="onsip-form-field-white">
          <mat-label>{{ "ONSIP_I18N.SIP_ADDRESS_OR_PHONE_NUMBER" | translate }}</mat-label>
          <input
            matInput
            [attr.data-cy]="E2eLocators.CONTACTS_CREATE_CUSTOM_PHONE_INPUT"
            autocomplete="off"
            formControlName="sipAddress"
            id="onsip--contact-list-edit--add-custom-contact--sip-address-input"
          />
          <mat-error>
            {{ getErrorMessage(customContactForm, "sipAddress") }}
          </mat-error>
        </mat-form-field>
      </span>
      <button
        mat-raised-button
        [attr.data-cy]="E2eLocators.CONTACTS_CREATE_CUSTOM_SUBMIT_BTN"
        type="submit"
        class="custom-contact-button"
        color="primary"
        id="onsip--contact-list-edit--add-custom-contact--create"
      >
        {{ "ONSIP_I18N.CREATE" | translate }}
      </button>
    </form>
  </div>
  }
</section>
}
