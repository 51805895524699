import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "../shared/components/modal/modal.module";
import { SidebarModule } from "../shared/components/sidebar/sidebar.module";
import { SuperUserViewComponent } from "./super-user-view.component";
import { SuperUserSearchModule } from "./superUserSearch/super-user-search.module";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatDialogModule,
    MatDividerModule,
    TranslateModule,
    SidebarModule,
    ModalModule,
    SuperUserSearchModule
  ],
  declarations: [SuperUserViewComponent]
})
export class SuperUserModule {}
