import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

const validPattern = /^[\x21-\x7E]+([\x20-\x7E]+[\x21-\x7E]+)*$/;
const invalidDoubleSpacePattern = /\s\s/;

export function customContactPatternValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (invalidDoubleSpacePattern.test(control.value) || !validPattern.test(control.value)) {
      return { namePattern: true };
    }

    // eslint-disable-next-line no-null/no-null
    return null;
  };
}
