import { SessionEventFactory, SessionEvent } from "./session-event";
import { EndCallEventReason } from "../call-event";

/**
 * Emitted once when Session ends.
 */
export class EndSessionEvent {
  static make(aor: string, uuid: string, reason: string): SessionEvent {
    return SessionEventFactory.make(EndSessionEvent.id, aor, uuid, reason);
  }
  static get id(): string {
    return "[Session] end";
  }
  static get reasonCancelled(): EndCallEventReason {
    return EndCallEventReason.Cancelled;
  } // outgoing cancelled (sent a CANCEL)
  static get reasonFailed(): EndCallEventReason {
    return EndCallEventReason.Failed;
  } // outgoing failed/rejected (received negative final response)
  static get reasonUnanswered(): EndCallEventReason {
    return EndCallEventReason.Unanswered;
  } // incoming unanswered/declined (sent negative final response)
  static get reasonTerminated(): EndCallEventReason {
    return EndCallEventReason.Terminated;
  } // incoming/outgoing dialog ended (sent or received a BYE or connection failure)
}
