<mat-toolbar class="super-admin-toolbar">
  <button mat-stroked-button (click)="backToAdmin()" class="back-to-search-button">
    {{ goBackText }}
  </button>
  <div class="toolbar-title">
    @if ((smallOrLessBreakpoint | async) === false) {
    <span class="super-admin-name mat-headline-6">
      {{ displayRole | titlecase }}
    </span>
    } @if ((smallOrLessBreakpoint | async) === false) {
    <mat-divider class="divider" vertical></mat-divider>
    }
    <span class="viewing mat-headline-6">Viewing</span>
    <span class="current-org mat-headline-6">{{ orgName | titlecase }}</span>
  </div>
</mat-toolbar>
