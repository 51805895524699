export interface VoicemailSettingInfo {
  notifyEmail: NotifyEmailOption;
  email: string;
  password: string;
  mwi: boolean;
  greetingFile: File | undefined;
  announceDuration: boolean;
  announceDateAndTime: boolean;
  announceCallerId: boolean;
}

export enum NotifyEmailOption {
  NO = "no",
  YES = "yes",
  EMAIL = "email",
  YES_THEN_DELETE = "yes-then-delete"
}

export const notifyEmailOptions: Array<{ value: NotifyEmailOption; label: string }> = [
  { label: "ONSIP_I18N.NO_EMAIL", value: NotifyEmailOption.NO },
  { label: "ONSIP_I18N.EMAIL", value: NotifyEmailOption.EMAIL },
  {
    label: "ONSIP_I18N.EMAIL_WITH_WAV_ATTACHMENT",
    value: NotifyEmailOption.YES
  },
  {
    label: "ONSIP_I18N.EMAIL_WITH_DELETED_WAV_ATTACHMENT",
    value: NotifyEmailOption.YES_THEN_DELETE
  }
];
