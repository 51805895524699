<div class="image-upload">
  <div mat-dialog-content>
    <onsip-image-upload
      #imageUpload
      [hasFrame]="hasFrame"
      [image]="hasDeletedAvatar ? '' : useTempAvatar ? currentAvatar : (myAvatar | async) || ''"
      [isRound]="true"
      (deleteCurrentAvatar)="deleteCurrent()"
    >
    </onsip-image-upload>
  </div>
  @if (hasDialog) {
  <div mat-dialog-actions class="modal-actions">
    <button mat-button color="primary" mat-dialog-close>
      {{ "ONSIP_I18N.CANCEL" | translate }}
    </button>
    <button
      id="onsip--avatar-upload--confirm-upload"
      mat-raised-button
      color="primary"
      (click)="confirmUpload()"
      cdkFocusInitial
    >
      {{ "ONSIP_I18N.SAVE_CHANGES" | translate }}
    </button>
  </div>
  }
</div>
