import { EventId, CallControllerEvent } from "./call-controller-event";

/** Emitted when CallController reset. */
export class ResetCallControllerEvent extends CallControllerEvent {
  id = EventId.ResetCallControllerEventId;

  /**
   * Factory for ResetCallControllerEvent.
   */
  constructor() {
    super(isResetCallControllerEvent);
  }
}

/**
 * User-Defined Type Guard for ResetCallControllerEvent.
 * @param event Event to check.
 */
export function isResetCallControllerEvent(event: any): event is ResetCallControllerEvent {
  return event instanceof ResetCallControllerEvent;
}
