import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { FormEventTrackingDirective } from "./form-event-tracking.directive";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  declarations: [FormEventTrackingDirective],
  exports: [FormEventTrackingDirective]
})
export class FormEventTrackingModule {}
