import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { Subject, Subscription } from "rxjs";
import { filter, take, takeWhile } from "rxjs/operators";

import { VolumeService } from "../shared/components/volume/volume.service";
import { CallVolumeEvent } from "../shared/components/volume/volume-event";
import { CallControllerService } from "../../../common/services/call-controller.service";
import { ModalMaterialComponent } from "../shared/components/modal/modal-material.component";
import { TranslateService } from "@ngx-translate/core";
import { VideoConferenceService } from "./video-conference.service";

declare let navigator: any;

@Component({
  selector: "onsip-video-conference",
  templateUrl: "./video-conference.component.html",
  styleUrls: ["./video-conference.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoConferenceComponent implements OnInit, OnDestroy {
  conferenceName!: string;
  pageShowing = true;
  isHeld = false;
  isAudioMuted = false;
  isVideoMuted = false;
  fullscreen = false;
  volumeIcon: "volume_off" | "volume_up" | "volume_down" = "volume_down";
  volumeClick = new Subject<void>();
  private instantConfLink = "";
  private mediaSection: any;
  private unsubscriber = new Subscription();

  constructor(
    private volumeService: VolumeService,
    private callControllerService: CallControllerService,
    private videoConferenceService: VideoConferenceService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    ["", "webkit", "ms", "moz"].forEach(prefix =>
      document.addEventListener(prefix + "fullscreenchange", this.fullscreenCheck.bind(this), false)
    );

    this.unsubscriber.add(
      this.volumeService.state.subscribe(state => {
        this.getVolumeIcon(state.defaultVolume);
      })
    );

    this.unsubscriber.add(
      this.volumeService.event
        .pipe(
          filter(e => e.id === CallVolumeEvent.id),
          filter(e => e.uuid === "VIDEOCONFERENCE")
        )
        .subscribe(e => {
          this.getVolumeIcon(e.volume);
        })
    );

    this.instantConfLink = this.videoConferenceService.getConferenceLink();

    this.videoConferenceService.makeAndAddUserAgent(false);

    this.callControllerService.holdAllOtherCalls();
    this.videoConferenceService.startVideoConference();
    this.unsubscriber.add(
      this.videoConferenceService.state.subscribe(state => {
        if (state.newVideoConferenceState === "switch") {
          this.endConference();
        }

        this.isHeld = state.hold;
        this.isAudioMuted = state.isAudioMuted;
        this.isVideoMuted = state.isVideoMuted;
      })
    );

    this.conferenceName = this.videoConferenceService.getVideoConferenceName();

    // Decide whether or not to display "copy link" popup, note: operator order is important
    this.unsubscriber.add(
      this.videoConferenceService.state
        .pipe(
          takeWhile(state => state.calls.length === 0), // if there's ever a call you didn't just start it
          filter(state => state.status === "empty-host"), // this state only occurs when there are no calls and one reg can occur multiple times
          take(1) // should only ever happen once per instance of VideoConferenceComponent
        )
        .subscribe(() => {
          this.triggerInviteDialog();
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  emitEventToVolume() {
    this.volumeClick.next();
  }

  fullscreenCheck(): void {
    const doc: any = document;
    if (this.fullscreen) {
      this.fullscreen = !!(
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement
      );
    }
  }

  toggleFullScreen(): void {
    const doc: any = document, // HACK to circumvent typing
      fullScreenElement: any =
        doc.fullscreenElement ||
        doc.mozFullScreenElement ||
        doc.webkitFullscreenElement ||
        doc.msFullscreenElement;

    this.mediaSection = this.mediaSection || document.querySelector(".session-media");

    if (this.mediaSection.requestFullScreen) {
      if (fullScreenElement) {
        document.exitFullscreen();
      } else {
        this.mediaSection.requestFullScreen();
      }
    } else if (this.mediaSection.mozRequestFullScreen) {
      if (fullScreenElement) {
        doc.mozCancelFullScreen();
      } else {
        this.mediaSection.mozRequestFullScreen();
      }
    } else if (this.mediaSection.webkitRequestFullScreen) {
      if (fullScreenElement) {
        doc.webkitExitFullscreen();
      } else {
        this.mediaSection.webkitRequestFullScreen();
      }
    }
    this.fullscreen = !!(
      doc.fullscreenElement ||
      doc.mozFullScreenElement ||
      doc.webkitFullscreenElement ||
      doc.msFullscreenElement
    );
  }

  hold(): void {
    this.videoConferenceService.hold();
  }

  muteAudio(): void {
    this.videoConferenceService.muteAudio();
  }

  muteVideo(): void {
    this.videoConferenceService.muteVideo();
  }

  endConference(): void {
    this.videoConferenceService.endVideoConference();
  }

  copyShareLink(): void {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(this.instantConfLink);
      this.openSnackBar();
    } else {
      const willCopy: HTMLTextAreaElement = document.createElement("textarea");
      willCopy.value = this.instantConfLink;
      document.body.appendChild(willCopy);
      willCopy.select();
      // NOTE: revisit later and replace with clipboard API
      // eslint-disable-next-line deprecation/deprecation
      document.execCommand("copy");
      willCopy.remove();
      this.openSnackBar();
    }
  }

  openSnackBar(): void {
    this.snackbar.open(this.translate.instant("ONSIP_I18N.CONFERENCE_CALL_LINK_COPIED"), "", {
      duration: 4000
    });
  }

  /** Open dialog for copying confernce link */
  private triggerInviteDialog() {
    const modal = this.dialog.open(ModalMaterialComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"], // global material styles on dialog
      maxWidth: "480px",
      data: {
        title: this.translate.instant("ONSIP_I18N.INVITE_OTHERS_TO_THE_MEETING"),
        message: `${this.translate.instant("ONSIP_I18N.YOUR_UNIQUE_CONFERENCE_LINK_IS")}<br/><b>${
          this.instantConfLink
        }</b>`,
        secondaryBtnText: this.translate.instant("ONSIP_I18N.CLOSE"),
        primaryBtnText: this.translate.instant("ONSIP_I18N.COPY_LINK"),
        primaryBtnColor: "primary",
        primaryBtnFlat: true
      }
    });

    this.unsubscriber.add(
      modal
        .afterClosed()
        .pipe(take(1))
        .subscribe(retObj => {
          if (retObj && retObj.doPrimaryAction) this.copyShareLink();
        })
    );
  }

  private getVolumeIcon(currentVolume: number): void {
    this.volumeIcon =
      currentVolume === 0 ? "volume_off" : currentVolume > 50 ? "volume_up" : "volume_down";
  }
}
