<!-- start container section -->
@if (card) { @if (identities && identities.length > 1) {
<mat-card>
  <ng-container *ngTemplateOutlet="select"></ng-container>
</mat-card>
} } @else { @if (identities && identities.length > 1) {
<div>
  <ng-container *ngTemplateOutlet="select"></ng-container>
</div>
} }
<!-- end container section -->
<ng-template #select>
  <strong>{{ label }}</strong>
  <mat-select
    [ngModel]="outboundUserAgentAor"
    (ngModelChange)="identityService.setOutboundIdentity($event)"
  >
    @for (identity of identities; track identity) {
    <mat-option [value]="identity.aor">
      {{ identity.aor }}
    </mat-option>
    }
  </mat-select>
</ng-template>
