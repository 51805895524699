import { Injectable } from "@angular/core";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";

import { ApiResourceService } from "../api-resource.service";
import { getApiActionName } from "../../onsip-api-action-new";
import { OnsipApiResponse } from "../../apiResponse/response-body-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { sessionId } from "../../apiParams/session-id";
import {
  UserGoogleFirebaseToken,
  UserGoogleFirebaseTokenApi,
  convertUserGoogleFirebaseTokenApi
} from "./user-google-firebase-token";
import { distinctUntilChanged } from "rxjs";

const debug = false;

@Injectable({
  providedIn: "root"
})
export class UserGoogleFirebaseTokenService extends ApiResourceService<UserGoogleFirebaseToken> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserGoogleFirebaseToken", "googleFirebaseToken");
    debug && this.state.subscribe(state => console.warn("UserGoogleFirebaseTokenService", state));

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(() => this.dispose());
  }

  userGoogleFirebaseTokenRead(parameters: {
    Environment: string;
  }): ApiPromiseState<UserGoogleFirebaseToken> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserGoogleFirebaseTokenRead,
        SessionId: this.store.state.pipe(sessionId()),
        ...parameters
      }
    });
    return this.promiseState.toPromise(ApiAction.UserGoogleFirebaseTokenRead);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.UserGoogleFirebaseTokenRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord([this.getApiData(action, response)], this.indexKeyName),
          action
        );
        break;
    }
  }

  private getApiData(action: string, response: OnsipApiResponse): UserGoogleFirebaseToken {
    const apiResult = response.Result as any;
    const apiData = apiResult[action || ""] as UserGoogleFirebaseTokenApi;
    return convertUserGoogleFirebaseTokenApi(apiData);
  }
}
