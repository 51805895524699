import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

/* Pass two control names whose value must match e.g. "password" & "verifyPassword"
 *  Note: This must be set on the parent FormGroup not an individual control. */
export function matchingPasswordValidator(controlName1: string, controlName2: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const control1 = control.get(controlName1); // e.g. "password"
    const control2 = control.get(controlName2); // e.g. "verifyPassword"
    return control1 && control2 && control1.value !== control2.value
      ? { notMatchingPassword: true }
      : // eslint-disable-next-line no-null/no-null
        null;
  };
}

/* Validator to check password if it has at least one number */
export function atLeastOneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pword: string = control.value;
    const valid = pword.match(/.*[0-9].*/);
    // eslint-disable-next-line no-null/no-null
    return valid ? null : { atLeastOneNumber: true };
  };
}

/* Validator to check password if it has at least one valid symbol */
export function atLeastOneSymbolValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pword: string = control.value;
    // valid characters are from ascii #32 to #126, below are all non-alphanumeric characters within that range
    // exception: removing quotes and backtick as valid symbol
    // exception: whitespace does not count as a symbol for this validation
    const valid = pword.match(/.*[!#$%&()*+,\-./:;<=>?@[\]^_{|}~].*/);
    // eslint-disable-next-line no-null/no-null
    return valid ? null : { atLeastOneSymbol: true };
  };
}

export function invalidCharacterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const pword: string = control.value;
    // password is invalid if it includes any characters outside the ascii range #32 to #126
    const invalid = pword.match(/[^\x20-\x7E]/);
    // eslint-disable-next-line no-null/no-null
    return invalid ? { invalidCharacter: true } : null;
  };
}
