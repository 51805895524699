import {
  ApiInvoiceItem,
  InvoiceItemType,
  InvoiceItem,
  apiInvoiceItemToInvoiceItem
} from "./account-invoice-item";
import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";

export declare type InvoiceType =
  | "Unknown"
  | "ManualPayment"
  | "Incentive"
  | "Promotion"
  | "InternalCredit"
  | "AdjustmentCredit"
  | "TransferCredit"
  | "AppliedPriorPayment"
  | "ResellerCredit"
  | "AutomatedIncentive"
  | "ManualCharge"
  | "Refund"
  | "Forfeit"
  | "AdjustmentDebit"
  | "TransferDebit"
  | "CreditNote"
  | "DebitNote"
  | "AutomatedBilling"
  | "MonthlyAccountInvoice";

export declare interface ApiAccountInvoice {
  AccountId: string;
  AccountInvoiceId: string;
  Date: string;
  ItemGroups: OnsipAPIArray<"ItemGroup", ApiItemGroup>;
  Notes: OnsipAPIArray<"Note", ApiAccountInvoiceNote>;
  SubTotal: string;
  Tax: string;
  TaxItems: OnsipAPIArray<"TaxItem", ApiAccountInvoiceTaxItem>;
  Total: string;
  Type: InvoiceType;
  /** this can store authorizer id or transaction id depending on the invoice type. */
  TypeSpecificData?: Record<"AuthorizerId" | "TransactionId", string>;
}

export declare interface ApiAccountInvoiceNote {
  // pretty sure this is just a string
  Note: string;
}

export declare interface ApiAccountInvoiceTaxItem {
  TaxAmount: string;
  TaxTypeCode: string;
  TaxTypeDesc: string;
  TaxableRevenue: string;
}

export declare interface ApiItemGroup {
  Items: OnsipAPIArray<"Items", ApiInvoiceItem<InvoiceItemType>>;
  SubTotal: string;
  Type: InvoiceItemType;
}

export declare interface AccountInvoice {
  accountId: string;
  accountInvoiceId: string;
  date: string;
  itemGroups: Array<ItemGroup>;
  notes: Array<AccountInvoiceNote>;
  subTotal: string;
  tax: string;
  taxItems: Array<AccountInvoiceTaxItem>;
  total: string;
  type: InvoiceType;
  typeSpecificData?: Record<"authorizerId" | "transactionId", string>;
}

export declare interface AccountInvoiceNote {
  note: string;
}

export declare interface AccountInvoiceTaxItem {
  taxableRevenue: string;
  taxTypeCode: string;
  taxTypeDesc: string;
  taxAmount: string;
}

export declare interface ItemGroup {
  item: Array<InvoiceItem<InvoiceItemType>>;
  subTotal: string;
  type: InvoiceItemType;
}

export function apiAccountInvoiceToAccountInvoice(
  apiAccountInvoice: ApiAccountInvoice
): AccountInvoice {
  const accountInvoice: AccountInvoice = {
    accountId: apiAccountInvoice.AccountId,
    accountInvoiceId: apiAccountInvoice.AccountInvoiceId,
    date: apiAccountInvoice.Date,
    itemGroups: onsipApiArrayToArray(apiAccountInvoice.ItemGroups, "ItemGroup").map(
      apiItemGroupToItemGroup
    ),
    notes: onsipApiArrayToArray(apiAccountInvoice.Notes, "Note").map(
      apiAccountInvoiceNoteToAccountInvoiceNote
    ),
    subTotal: apiAccountInvoice.SubTotal,
    tax: apiAccountInvoice.Tax,
    taxItems: onsipApiArrayToArray(apiAccountInvoice.TaxItems, "TaxItem").map(
      apiAccountInvoiceTaxItemToAccountInvoiceTaxItem
    ),
    total: apiAccountInvoice.Total,
    type: apiAccountInvoice.Type
  };

  // pulled from the webapi code: MonthAccountInvoice and AutomatedIncentive invoice do not have an authorizerId or transactionId
  // only AutomatedBilling has transactionId and the rest of the invoice types all have authorizerIds.
  // can reference the file AccountInvoice.inc in webapi repo
  return accountInvoice.type === "MonthlyAccountInvoice" ||
    accountInvoice.type === "AutomatedIncentive"
    ? accountInvoice
    : accountInvoice.type === "AutomatedBilling"
    ? {
        ...accountInvoice,
        ...{
          typeSpecificData: {
            transactionId: apiAccountInvoice.TypeSpecificData?.TransactionId || ""
          } as AccountInvoice["typeSpecificData"]
        }
      }
    : {
        ...accountInvoice,
        ...{
          typeSpecificData: {
            authorizerId: apiAccountInvoice.TypeSpecificData?.AuthorizerId || ""
          } as AccountInvoice["typeSpecificData"]
        }
      };
}

export function apiAccountInvoiceNoteToAccountInvoiceNote(
  apiAccountInvoiceNote: ApiAccountInvoiceNote
): AccountInvoiceNote {
  return { note: apiAccountInvoiceNote.Note };
}

export function apiAccountInvoiceTaxItemToAccountInvoiceTaxItem(
  apiAccountInvoiceTaxItem: ApiAccountInvoiceTaxItem
): AccountInvoiceTaxItem {
  return {
    taxAmount: apiAccountInvoiceTaxItem.TaxAmount,
    taxTypeCode: apiAccountInvoiceTaxItem.TaxTypeCode,
    taxTypeDesc: apiAccountInvoiceTaxItem.TaxTypeDesc,
    taxableRevenue: apiAccountInvoiceTaxItem.TaxableRevenue
  };
}

export function apiItemGroupToItemGroup(apiItemGroup: ApiItemGroup): ItemGroup {
  return {
    item: onsipApiArrayToArray(apiItemGroup.Items, "Item").map(apiInvoiceItemToInvoiceItem),
    subTotal: apiItemGroup.SubTotal,
    type: apiItemGroup.Type
  };
}
