<!-- HEADERS FOR CALL LIST ITEMS-->
@if (currentCall && (currentCall.hold || !(currentCall.videoAvailable && currentCall.connected &&
currentCall.uuid !== activeVisibleCallUuid))) {
<div
  id="onsip--call-list-item--call-list-item-header"
  class="call-list-item-header"
  (click)="triggerNavigation(currentCall)"
>
  @if (endCallReason) {
  <div>
    <span translate [translateParams]="{ value: endCallReason }">
      ONSIP_I18N.CALL_ENDED_WITH_REASON_VALUE
    </span>
  </div>
  }
  <div class="call-description">
    <span>
      {{ displayStatus }}
    </span>
    @if (currentCall.connected) {
    <span>
      {{ callTimerMs | duration }}
    </span>
    }
  </div>
  <h2 class="call-name call-list-ellipsis" [innerHtml]="callFromDisplayName"></h2>
</div>
}
<div
  [ngClass]="{'video-showing': currentCall &amp;&amp;
  currentCall.uuid !== activeVisibleCallUuid &amp;&amp;
  currentCall.videoAvailable &amp;&amp;
  currentCall.connected &amp;&amp;
  !currentCall.hold}"
>
  @if (currentCall && currentCall.uuid !== activeVisibleCallUuid && currentCall.videoAvailable &&
  currentCall.connected && !currentCall.hold) {
  <div
    id="onsip--call-list-item--video-header"
    class="video-component"
    (click)="triggerNavigation(currentCall)"
  >
    <onsip-call-video-container [isCallListItem]="true" [callUuid]="currentCall.uuid">
    </onsip-call-video-container>
  </div>
  }
  <div>
    <ng-container>
      <div
        class="call-list-item-footer"
        [ngClass]="{'call-list-item-footer-active': currentCall &amp;&amp;
        currentCall.connected &amp;&amp;
        !currentCall.hold,
        'call-list-item-footer-incoming': currentCall &amp;&amp;
        currentCall.ringing,
        'bottom-shadow': currentCall &amp;&amp;
        currentCall.uuid !== activeVisibleCallUuid &amp;&amp;
        currentCall.videoAvailable &amp;&amp;
        currentCall.connected &amp;&amp;
        !currentCall.hold}"
      >
        <!-- OUTBOUND, CONNECTING CALL -->
        @if (currentCall && currentCall.connecting) {
        <button
          id="onsip--call-list-item--cancel-call"
          class="call-list-item-footer-connecting"
          mat-button
          (click)="endCall()"
        >
          <mat-icon>call_end</mat-icon> &nbsp;
          {{ "ONSIP_I18N.CANCEL" | translate }}
        </button>
        }
        <!-- INBOUND, RINGING CALL -->
        @if (currentCall && currentCall.ringing;) {
        <button
          id="onsip--call-list-item--accept-call"
          class="incoming-call-answer"
          mat-button
          (click)="accept()"
        >
          <mat-icon>call</mat-icon> &nbsp; {{ "ONSIP_I18N.ANSWER" | translate }}
        </button>
        <button
          id="onsip--call-list-item--decline-call"
          class="incoming-call-end"
          mat-button
          (click)="endCall()"
        >
          <mat-icon>call_end</mat-icon> &nbsp; {{ "ONSIP_I18N.END" | translate }}
        </button>
        }
        <!-- CONNECTED CALL -->
        @if (currentCall && currentCall.connected) {
        <!-- HOLD -->
        <button id="onsip--call-list-item--hold" mat-button (click)="hold()">
          @if (currentCall.hold) {
          <mat-icon>play_arrow</mat-icon>&nbsp;{{ "ONSIP_I18N.RESUME" | translate }}
          } @else {
          <mat-icon>pause</mat-icon>&nbsp;{{ "ONSIP_I18N.HOLD" | translate }}
          }
        </button>
        <!-- MUTE -->
        <button id="onsip--call-list-item--mute-audio" mat-button (click)="muteAudio()">
          @if (!currentCall.audio) {
          <mat-icon onsip-icon-outlined>mic_off</mat-icon>&nbsp;{{
            "ONSIP_I18N.UNMUTE" | translate
          }}
          } @else {
          <mat-icon>mic</mat-icon>&nbsp;{{ "ONSIP_I18N.MUTE" | translate }}
          }
        </button>
        <!-- END -->
        <button id="onsip--call-list-item--end-call" mat-button (click)="endCall()">
          <mat-icon>call_end</mat-icon>&nbsp;{{ "ONSIP_I18N.END" | translate }}
        </button>
        <!-- MORE OPTIONS MENU -->
        <button
          mat-icon-button
          [matMenuTriggerFor]="moremenu"
          style="width: 10%"
          class="more-option-menu-button"
        >
          <mat-icon style="margin-bottom: 12px">more_vert</mat-icon>
        </button>
        <mat-menu
          #moremenu="matMenu"
          xPosition="before"
          (closed)="menuClosed()"
          class="call-list-items mat-typography onsip-mat-menu-overflow-visible"
        >
          <div class="menu-box" (click)="$event.stopPropagation()">
            <!-- TRANSFER -->
            <div cdkOverlayOrigin #itemTransferButton="cdkOverlayOrigin">
              <button
                id="onsip--call-list-item--toggle-transfer-popup"
                class="more-item"
                aria-label="toggle transfer popup"
                [disabled]="currentCall.maxCallTime !== undefined"
                mat-menu-item
                (click)="!transferCall && isTransferring ? cancelTransfer() : startTransfer()"
              >
                <mat-icon color="primary">{{
                  !transferCall && isTransferring ? "close" : "swap_horiz"
                }}</mat-icon>
              </button>
              @if (currentCall && (isTransferring || !!transferCall)) {
              <onsip-popup-container
                [overlayOrigin]="itemTransferButton"
                (dismissed)="menuClosed()"
              >
                <div class="transfer-conference-container">
                  @if (!transferCall) {
                  <div class="transfer-header">
                    {{ "ONSIP_I18N.TRANSFER_CALL" | translate }}
                  </div>
                  <div class="transfer-input">
                    <onsip-sip-address-input
                      class="transfer-input-box"
                      #transferTarget
                      [whiteText]="true"
                      [card]="false"
                      [placeholder]="'ONSIP_I18N.PHONE_NUMBER_OR_CONTACT' | translate"
                      (value)="transferTargetModel = $event"
                      (enter)="blindTransfer()"
                    >
                    </onsip-sip-address-input>
                    <span
                      class="transfer-dialpad-button"
                      cdkOverlayOrigin
                      #itemTransferDialpadButton="cdkOverlayOrigin"
                    >
                      <mat-icon [style.color]="'#ffffff'">dialpad</mat-icon>
                      <onsip-popup-container [overlayOrigin]="itemTransferDialpadButton">
                        <onsip-dialpad
                          [target]="'newCall'"
                          [showHistory]="false"
                          (click)="$event.stopPropagation()"
                        ></onsip-dialpad>
                      </onsip-popup-container>
                    </span>
                  </div>
                  <div class="button-container transfer-button-container">
                    <button
                      id="onsip-call-list-item-attended-transfer"
                      mat-raised-button
                      color="primary"
                      (click)="attendedTransfer()"
                    >
                      {{ "ONSIP_I18N.ATTENDED" | translate }}
                    </button>
                    <button
                      id="onsip-call-list-item-blind-transfer"
                      mat-raised-button
                      color="primary"
                      (click)="blindTransfer()"
                    >
                      {{ "ONSIP_I18N.BLIND" | translate }}
                    </button>
                  </div>
                  } @if ( !!transferCall && !!currentCall && currentCall.connectedAt &&
                  transferCall.connectedAt && currentCall.connected ) {
                  <div class="attended-transfer-block">
                    <h4>{{ "ONSIP_I18N.COMPLETE_TRANSFER" | translate }}</h4>
                    <span>
                      {{ "ONSIP_I18N.COMPLETE_TRANSFER" | translate }}
                      {{
                        currentCall.connectedAt < transferCall.connectedAt
                          ? ("ONSIP_I18N.TO" | translate | lowercase)
                          : ("ONSIP_I18N.FROM" | translate | lowercase)
                      }}
                      {{ transferCall.remoteUri.slice(4) }}?
                    </span>
                    <div class="complete-transfer-container">
                      <button
                        id="onsip--call-list-item--complete-transfer"
                        mat-raised-button
                        color="primary"
                        (click)="completeAttendedTransfer()"
                        style="padding: 0 16px"
                      >
                        {{ "ONSIP_I18N.COMPLETE" | translate }}
                      </button>
                    </div>
                  </div>
                  }
                </div>
              </onsip-popup-container>
              }
            </div>
            <!-- VOLUME -->
            <div cdkOverlayOrigin #itemVolumeButton="cdkOverlayOrigin">
              <button
                id="onsip--call-list-item--toggle-mute-volume"
                class="more-item"
                aria-label="toggle mute volume"
                mat-menu-item
                (click)="emitEventToVolume()"
              >
                <mat-icon color="primary">{{ volumeIcon }}</mat-icon>
              </button>
              <onsip-popup-container [overlayOrigin]="itemVolumeButton" [trigger]="'hover'">
                <onsip-volume-popup
                  [vertical]="false"
                  [voicemail]="false"
                  [call]="currentCall.uuid"
                  (click)="$event.stopPropagation()"
                  [event]="volumeClick.asObservable()"
                >
                </onsip-volume-popup>
              </onsip-popup-container>
            </div>
            <!-- DIALPAD -->
            <div cdkOverlayOrigin #itemDialpadButton="cdkOverlayOrigin">
              <button
                id="onsip--call-list-item--toggle-dialpad"
                class="more-item"
                aria-label="toggle dialpad"
                mat-menu-item
              >
                <mat-icon color="primary">dialpad</mat-icon>
              </button>
              <onsip-popup-container [overlayOrigin]="itemDialpadButton">
                <onsip-dialpad
                  [target]="'call'"
                  [showHistory]="true"
                  (click)="$event.stopPropagation()"
                >
                </onsip-dialpad>
              </onsip-popup-container>
            </div>
          </div>
        </mat-menu>
        }
      </div>
    </ng-container>
  </div>
</div>
