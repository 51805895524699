import { Injectable } from "@angular/core";
import { ApiGenericAction } from "../../api-actions";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { accountId } from "../../apiParams/account-id";
import { sessionId } from "../../apiParams/session-id";
import { userId } from "../../apiParams/user-id";
import {
  AccountInvoice,
  ApiAccountInvoice,
  apiAccountInvoiceToAccountInvoice as clean
} from "./account-invoice";
import { DisplayPackageName } from "../package/package";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { distinctUntilChanged } from "rxjs";
export { AccountInvoice };

const debug = false;

/** We can just store the payasyougo/basic plan package id as a const
 * we dont really need to do a whole api fetch just to get this package id
 */
export const BASIC_PLAN_PACKAGE_ID = 1243;

// we are going to add in invoiceType based of package name and use this as the index key
// this is necessary since all pretend invoices have an accountInvoiceId of 0
export interface AccountInvoicePretend extends AccountInvoice {
  invoiceType: DisplayPackageName;
}

interface PretendInvoiceParams {
  PerUserPricing?: "true" | "false" | "unlimited" | "metered";
  PackageIds?: Array<number>;
}

@Injectable({ providedIn: "root" })
export class AccountInvoicePretendService extends ApiResourceService<AccountInvoicePretend> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "AccountInvoicePretend", "invoiceType");
    debug && this.state.subscribe(state => console.warn("AccountInvoicePretend", state));
    // clear store and reset all if there is changed in users
    this.store.state
      .pipe(userId())
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.dispose();
      });
  }

  /** api generates a fake invoice. We use this api to display to user their possible features and payments if they switch to a certain package
   * this is also the way we generate user packages based of account size aka our Per Seat Unlimited/Metered plans.
   */

  getBasicPlanInvoicePretend(
    extraParams?: PretendInvoiceParams
  ): ApiPromiseState<AccountInvoicePretend> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.AccountInvoicePretend,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        PerUserPricing: "false",
        PackageIds: [BASIC_PLAN_PACKAGE_ID],
        ...extraParams
      },
      nonblocking: true
    });
    return this.promiseState.toPromise(ApiGenericAction.AccountInvoicePretend);
  }

  getUnlimitedPlanInvoicePretend(
    extraParams?: PretendInvoiceParams
  ): ApiPromiseState<AccountInvoicePretend> {
    this.dispatcher.next({
      parameters: {
        Action: ApiGenericAction.AccountInvoicePretend,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        PerUserPricing: "unlimited",
        ...extraParams
      },
      nonblocking: true
    });
    return this.promiseState.toPromise(ApiGenericAction.AccountInvoicePretend);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiGenericAction.AccountInvoicePretend: {
        if (!response.Context.Request.Parameters) break;
        const invoiceType =
          onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
            param => param.Name === "PerUserPricing"
          )?.Value === "unlimited"
            ? DisplayPackageName.UNLIMITED_PLAN
            : DisplayPackageName.BASIC_PLAN;
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              {
                ...clean(extractData<ApiAccountInvoice>(response, action, "AccountInvoice")),
                invoiceType
              }
            ],
            this.indexKeyName
          ),
          action
        );
        break;
      }
    }
  }
}
