import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { filter, map, withLatestFrom, take } from "rxjs/operators";

import { ApiResourceService } from "../api-resource.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import {
  ApiUserCustomization,
  UserCustomization,
  apiUserCustomizationToUserCustomization as clean,
  UserCustomizationEditContactTypes,
  prepareUserCustomizationAddParams,
  prepareUserCustomizationDeleteParams,
  prepareUserCustomizationEditParams,
  prepareUserCustomizationEditFavouritesParams
} from "./user-customization";
import {
  ApiNoAuthUserSummary,
  apiNoAuthUserSummaryToUserCustomizationPartial as cleanNoAuthUserSummary
} from "../../apiResponse/no-auth-user-summary";
import { ApiReadAction, ApiEditAction, ApiAction, ApiBrowseAction } from "../../api-actions";
import { ParameterValue } from "../../util/api-action-description";
import { OnsipApiResponse, extractData } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";

import { sessionId } from "../../apiParams/session-id";
import { organizationId } from "../../apiParams/organization-id";
import { userId } from "../../apiParams/user-id";
import {
  blurb as blurbFromStore,
  facebook as facebookFromStore,
  linkedin as linkedinFromStore,
  title as titleFromStore,
  twitter as twitterFromStore
} from "../../apiParams/personal-page-info";
import { arrayToRecord } from "../../util/arrayToRecord";
import { OnsipAPIParameter } from "../../apiResponse/context";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { Contact } from "@onsip/common/interfaces/contact";
export { UserCustomization };

const debug = false;

@Injectable({ providedIn: "root" })
export class UserCustomizationService extends ApiResourceService<UserCustomization> {
  private editContactsConverterMap: Record<
    UserCustomizationEditContactTypes,
    (contacts: Array<Contact>) => Record<string, ParameterValue>
  > = {
    [UserCustomizationEditContactTypes.ADD]: prepareUserCustomizationAddParams,
    [UserCustomizationEditContactTypes.DELETE]: prepareUserCustomizationDeleteParams,
    [UserCustomizationEditContactTypes.EDIT]: prepareUserCustomizationEditParams,
    [UserCustomizationEditContactTypes.EDIT_FAVOURITES]:
      prepareUserCustomizationEditFavouritesParams
  };
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserCustomization", "userId");
    debug && this.state.subscribe(state => console.warn("UserCustomizationService", state));

    // new accounts created from the admin portal will not have a user customization id and throw an error
    // we need to catch this error and initialize a user customization id for that account
    this.state
      .pipe(
        filter(state => state.errors.length > 0),
        map(state => state.errors),
        take(1)
      )
      .subscribe(errors => {
        const uninitUserCustomization = errors.find(
          error => error.message === "No user customization for the given user ID."
        );
        if (uninitUserCustomization) {
          // generalize by providing the UserId from the failed UserCustomizationRead
          const paramUserId = (
            (
              uninitUserCustomization.Response.Context.Request.Parameters
                ?.Parameter as Array<OnsipAPIParameter>
            ).find(paramObj => paramObj.Name === "UserId") as OnsipAPIParameter
          ).Value;
          this.userCustomizationPersonalPageInfoEdit({}, paramUserId);
        }
      });
  }

  /** public getter to get a filtered observable with just the self user */
  get selfUser(): Observable<UserCustomization> {
    return this.state.pipe(
      withLatestFrom(this.store.state.pipe(userId())),
      map(([substate, selfUserId]) => substate.state[selfUserId]),
      filter(<T>(userCustomization: T): userCustomization is NonNullable<T> => !!userCustomization)
    );
  }

  userCustomizationBrowse(
    extraParams?: Record<string, ParameterValue>
  ): ApiPromiseState<UserCustomization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.UserCustomizationBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        organizationId: this.store.state.pipe(organizationId()),
        Limit: 1000,
        OrderBy: "UserId",
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiAction.UserCustomizationBrowse);
  }

  userCustomizationRead(
    extraParams?: Record<string, ParameterValue>
  ): ApiPromiseState<UserCustomization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.UserCustomizationRead,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiAction.UserCustomizationRead);
  }

  // this action does not allow atomic edits - so we must send the whole blob with old values if unchanged
  userCustomizationPersonalPageInfoEdit(
    {
      blurb,
      facebook,
      linkedin,
      title,
      twitter
    }: { blurb?: string; facebook?: string; linkedin?: string; title?: string; twitter?: string },
    givenUserId?: string
  ): ApiPromiseState<UserCustomization> {
    const givenParams: Record<string, ParameterValue> = {};
    if (blurb !== undefined) givenParams["PersonalPageInfo[Blurb]"] = blurb;
    if (facebook !== undefined) givenParams["PersonalPageInfo[Facebook]"] = facebook;
    if (linkedin !== undefined) givenParams["PersonalPageInfo[Linkedin]"] = linkedin;
    if (title !== undefined) givenParams["PersonalPageInfo[Title]"] = title;
    if (twitter !== undefined) givenParams["PersonalPageInfo[Twitter]"] = twitter;
    if (givenUserId !== undefined) givenParams.UserId = givenUserId;

    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserCustomizationPersonalPageInfoEdit,
        "PersonalPageInfo[Blurb]": this.store.state.pipe(blurbFromStore(givenUserId)),
        "PersonalPageInfo[Facebook]": this.store.state.pipe(facebookFromStore(givenUserId)),
        "PersonalPageInfo[Linkedin]": this.store.state.pipe(linkedinFromStore(givenUserId)),
        "PersonalPageInfo[Title]": this.store.state.pipe(titleFromStore(givenUserId)),
        "PersonalPageInfo[Twitter]": this.store.state.pipe(twitterFromStore(givenUserId)),
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        // will overwrite above
        ...givenParams
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserCustomizationPersonalPageInfoEdit);
  }

  userCustomizationSaysoEdit(
    extraParams?: Record<string, ParameterValue>
  ): ApiPromiseState<UserCustomization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserCustomizationSaysoEdit,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserCustomizationSaysoEdit);
  }

  userCustomizationAvatarEdit(
    extraParams?: Record<string, ParameterValue>
  ): ApiPromiseState<UserCustomization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserCustomizationAvatarEdit,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...extraParams
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserCustomizationAvatarEdit);
  }

  editContact(
    type: UserCustomizationEditContactTypes,
    contacts: Array<Contact>
  ): ApiPromiseState<UserCustomization> {
    const params: Record<string, ParameterValue> = this.editContactsConverterMap[type](contacts);

    return this.userCustomizationEdit(params);
  }

  userCustomizationEdit(
    params: Record<string, ParameterValue>
  ): ApiPromiseState<UserCustomization> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserCustomizationEdit,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...params
      }
    });

    return this.promiseState.toPromise(ApiEditAction.UserCustomizationEdit);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.UserCustomizationBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiUserCustomization>>(
              response,
              action,
              "UserCustomization",
              "UserCustomizations"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiEditAction.UserCustomizationEdit:
      case ApiEditAction.UserCustomizationOnboardingEdit:
      case ApiEditAction.UserCustomizationPersonalPageInfoEdit:
      case ApiEditAction.UserCustomizationSaysoEdit:
      case ApiEditAction.UserCustomizationAvatarEdit:
      case ApiReadAction.UserCustomizationRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiUserCustomization>(response, action, "UserCustomization")].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.NoAuthUserSummaryBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiNoAuthUserSummary>>(
              response,
              action,
              "NoAuthUserSummary",
              "NoAuthUserSummaries"
            ).map(cleanNoAuthUserSummary),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.NoAuthUserSummaryRead:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [extractData<ApiNoAuthUserSummary>(response, action, "NoAuthUserSummary")].map(
              cleanNoAuthUserSummary
            ),
            this.indexKeyName
          ),
          action
        );
        break;

      case ApiAction.UserDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deleted = onsipApiArrayToArray(response.Context.Request.Parameters, "Parameter").find(
          param => param.Name === "UserId"
        )?.Value;
        deleted && this.store.mergeStateUpdate(this.resourceName, { [deleted]: undefined }, action);
        break;
    }
  }
}
