import { Injectable } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { Organization } from "@onsip/common/services/api/resources/organization/organization";
import { OrganizationService } from "@onsip/common/services/api/resources/organization/organization.service";
import { Role } from "@onsip/common/services/api/role";
import { BehaviorSubject, combineLatest, filter, map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class AgentOrganizationService {
  private _agentOrg = new BehaviorSubject<Organization | undefined>(undefined);

  constructor(private apiSession: ApiSessionService, private orgService: OrganizationService) {
    combineLatest({
      orgId: this.apiSession.state.pipe(
        filter(
          state =>
            state.role === Role.AgentAdmin ||
            (state.role === Role.SubAgentAdmin && !state.parentUserId)
        ),
        map(state => state.organizationId)
      ),
      orgs: this.orgService.state.pipe(
        filter(state => !state.loading),
        map(state => Object.values(state.state))
      )
    })
      .pipe(map(({ orgId, orgs }) => orgs.find(org => org.organizationId === orgId)))
      .subscribe(org => {
        this._agentOrg.next(org);
      });
  }

  get agentOrg(): Observable<Organization | undefined> {
    return this._agentOrg.asObservable();
  }
}
