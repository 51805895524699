import { Config } from "../../../../../common/config";
import { LogService } from "../../../../../common/services/logging";

import {
  UserSummaryContactService,
  UserSummaryContact
} from "../../../../../common/services/api/resources/userSummaryContact/user-summary-contact.service";
import { AnalyticsService } from "../analytics/analytics.service";
import { OnsipChatService } from "./onsip-chat.service";
import { ChatContactService, ChatContact } from "./factories/chat-contact.service";
import { ChatBodyService } from "./factories/chat-body.service";
import { ChatLineService } from "./factories/chat-line.service";

import { Injectable } from "@angular/core";

import { Subject, Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { Contact } from "../../../../../common/interfaces/contact";

const providerNames = {
  ONSIP: "onsip"
};

interface ConnectedObject {
  chatContacts: Array<ChatContact>;
  chatBodies: Array<ChatProviderBody>;
  chatData: ChatData;
  selfId: string;
}

interface MessageObject {
  isDirect: boolean;
  chatId: string;
  authorId: string;
  text: string;
  timestamp: string;
  data: any;
}

export interface OpenChatChannelObject {
  id: string;
  unreadCount: number;
}

export interface ChatListeners {
  onConnected: (a: ConnectedObject) => void;
  onDisconnected: (a: any) => any;
  onMessage: (a: MessageObject) => any;
  onDisabled: (a: any) => any;
  onNewChannel: (a: any) => any;
  onReadMarkerChange: (a: any) => any;
  onUserTyping: (a: any) => any;
  onUserPresenceChange: (a: any) => any;
}

export interface ChatData {
  [key: string]: any; // we map user/usergroup/channel name to id and vice-versa (and other things)
}

export interface ChatProviderBody {
  chatId: string;
  userId: string;
}

@Injectable({ providedIn: "root" })
export class ChatService {
  // We"re doing a lazy copy implementation of event-state-emitter for now

  event: Observable<{ onsipContact?: UserSummaryContact; chatBody: any }>; // TODO type this out with an interface, OK for now as only one purpose
  private _event = new Subject<{ onsipContact?: UserSummaryContact; chatBody: any }>();

  private chatProviders: any;
  private activeProvider = "";
  private allContacts: Array<UserSummaryContact> = [];
  private onChangeCallbacks: Record<string, () => void> = {};
  private onProviderChangeCallbacks: Record<string, (providerName: string) => void> = {};
  private onProviderConnectedCallbacks: Record<string, () => void> = {};
  private onProviderDisconnectedCallbacks: Record<string, () => void> = {};
  private messageSent = false;
  private _isProviderConnected = false;

  constructor(
    private log: LogService,
    private userSummaryContactService: UserSummaryContactService,
    private analyticsService: AnalyticsService,
    onsipChatService: OnsipChatService,
    private chatContactService: ChatContactService,
    private chatBodyService: ChatBodyService,
    private chatLineService: ChatLineService
  ) {
    this.userSummaryContactService.state.pipe(filter(state => !state.loading)).subscribe(state => {
      this.allContacts = Object.values(state.state);

      if (this.activeProvider === providerNames.ONSIP) {
        this.chatProviders[this.activeProvider].service.disable();
        this.chatProviders[this.activeProvider].service.enable(this.allContacts);
      }
    });

    this.event = this._event.asObservable();

    this.chatProviders = {
      onsip: {
        service: onsipChatService,
        bodies: {},
        contacts: {
          _onsipIdentifier_: "aors.0"
        },
        data: {}
      }
    };

    // make interface that looks like below

    Object.keys(this.chatProviders).forEach(providerName => {
      this.chatProviders[providerName].service.setupListeners(this);
    });

    this.switchChatProvider(providerNames.ONSIP);
  }

  isProviderEnabled(providerName: string): boolean {
    return this.activeProvider === providerName;
  }

  switchChatProvider(serviceName: string): Promise<any> {
    if (this.isProviderEnabled(serviceName)) {
      return Promise.resolve();
    }

    this.activeProvider && this.chatProviders[this.activeProvider].service.disable();
    this.activeProvider = serviceName;

    return this.chatProviders[serviceName].service.enable(this.allContacts).then(() => {
      Object.keys(this.onProviderChangeCallbacks).forEach(key => {
        this.onProviderChangeCallbacks[key](serviceName);
      });

      this.messageSent = false;
    });
  }

  platformSendReadCursorUpdate(chatBody: any): any {
    if (Config.IS_WEB || Config.IS_DESKTOP) {
      if (this.activeProvider && this.chatProviders[this.activeProvider].service.markLatestRead) {
        const markLatestRead = (): void => {
          this.chatProviders[this.activeProvider].service.markLatestRead(chatBody);
        };

        if (document.hasFocus()) {
          markLatestRead();
        } else {
          const onDocumentFocus: EventListener = (): void => {
            document.removeEventListener("focus", onDocumentFocus, true);
            markLatestRead();
          };
          document.addEventListener("focus", onDocumentFocus, true);
        }
      }
    }
  }

  getProviderNames(): any {
    return providerNames;
  }

  isProviderConnected(): boolean {
    return this.activeProvider === providerNames.ONSIP || this._isProviderConnected;
  }

  getChatBody(onsipContact: Contact, skipMark: boolean = false): Promise<any> {
    return new Promise<void>(resolve => {
      const contacts: Record<string, Contact | string> =
          this.chatProviders[this.activeProvider].contacts,
        mainId: Array<string> = (contacts._onsipIdentifier_ as string).split(".");
      let identifier = "";

      if (mainId[0] === "email") {
        identifier = onsipContact.email;
      } else if (mainId[0] === "aors") {
        identifier = onsipContact.aors[0];
      }

      const chatContact = contacts[identifier];
      if (!chatContact) {
        resolve();
        return;
      }

      this.openChatBody(chatContact)
        .then(response => {
          return this.initializeChatBody(response);
        })
        .then(chatBody => {
          if (chatBody !== undefined && !skipMark) {
            this.chatProviders[this.activeProvider].service.markLatestRead &&
              this.chatProviders[this.activeProvider].service.markLatestRead(chatBody);
          }
          resolve(chatBody);
        });
    });
  }

  sendMessage(onsipContact: Contact, text: string): Promise<any> {
    const currentProvider: any = this.chatProviders[this.activeProvider];
    let chatBody: any, newLine: any;

    return this.getChatBody(onsipContact)
      .then(body => {
        if (!body) {
          return;
        }
        chatBody = body;
        newLine = this.chatLineService.createChatLine(
          chatBody.id,
          text,
          true,
          this.activeProvider,
          undefined,
          undefined
        );

        return currentProvider.service.sendMessage(newLine, currentProvider.data);
      })
      .then(() => {
        chatBody.addLine(currentProvider.contacts.SELF, newLine);

        this.notifyChange();
        if (!this.messageSent) {
          this.analyticsService.sendMessageEvent("Sent", {
            chat: chatBody.contact.email,
            provider: this.activeProvider
          });
          this.messageSent = true;
        }
      });
  }

  sendTypingIndicator(chatBody: any): any {
    const currentProvider: any = this.chatProviders[this.activeProvider];

    if (!(chatBody && currentProvider.service.sendTypingIndicator)) {
      return;
    }

    return currentProvider.service.sendTypingIndicator(chatBody);
  }

  onChange(callback: () => void): number {
    const time: number = Date.now();

    this.onChangeCallbacks[time] = callback;
    return time;
  }

  removeOnChange(id: number): void {
    delete this.onChangeCallbacks[id];
  }

  onProviderChange(callback: (providerName: string) => void): number {
    const time: number = Date.now();

    this.onProviderChangeCallbacks[time] = callback;
    return time;
  }

  removeOnProviderChange(id: number): void {
    delete this.onProviderChangeCallbacks[id];
  }

  onProviderConnected(callback: () => void): number {
    const time: number = Date.now();

    this.onProviderConnectedCallbacks[time] = callback;
    return time;
  }

  removeOnProviderConnected(id: number): void {
    delete this.onProviderConnectedCallbacks[id];
  }

  onProviderDisconnected(callback: () => void): number {
    const time: number = Date.now();

    this.onProviderDisconnectedCallbacks[time] = callback;
    return time;
  }

  removeOnProviderDisconnected(id: number): void {
    delete this.onProviderDisconnectedCallbacks[id];
  }

  onConnected(data: ConnectedObject): void {
    const currentProvider: any = this.chatProviders[this.activeProvider];
    let unreadExists = false;

    data.chatContacts.forEach(user => {
      const contact: any = this.chatContactService.createChatContact(
        user.id,
        user.displayName,
        user.email,
        user.presence,
        { isDeleted: user.isDeleted, fullName: user.fullName }
      );
      if (currentProvider.contacts[contact.id]) {
        return;
      }
      currentProvider.contacts[contact.id] = contact;
      if (contact.email) {
        // currently email is only extra, so just add
        currentProvider.contacts[contact.email] = contact;
      }
    });

    if (data.selfId) {
      currentProvider.contacts[data.selfId].isSelf = true;
      currentProvider.contacts.SELF = currentProvider.contacts[data.selfId];
    }

    data.chatBodies.forEach(body => {
      const contact: any = currentProvider.contacts[body.userId];

      if (!contact) {
        this.log.error("onConnected chatBody creation: contact not found for: ", body.userId);
        return;
      }

      const chatBody = this.chatBodyService.createChatBody(
        contact,
        body.chatId,
        this.activeProvider,
        0,
        data.chatData
      );
      contact.chatBody = chatBody;
      currentProvider.bodies[chatBody.id] = chatBody;
      if (chatBody.unreadCount > 0) {
        unreadExists = true;
      }
    });

    if (unreadExists) {
      this.notifyChange();
    }

    currentProvider.data = data.chatData;

    Object.keys(this.onProviderConnectedCallbacks).forEach(key => {
      this.onProviderConnectedCallbacks[key]();
    });
    this._isProviderConnected = true;
  }

  onDisconnected(): void {
    Object.keys(this.onProviderDisconnectedCallbacks).forEach(key => {
      this.onProviderDisconnectedCallbacks[key]();
    });
    this._isProviderConnected = false;
  }

  onMessage(data: MessageObject) {
    const currentProvider: any = this.chatProviders[this.activeProvider];
    if (!data.isDirect) {
      return;
    }

    const chatBody = currentProvider.bodies[data.chatId];
    if (!chatBody) {
      this.log.error(this.activeProvider, "- onMessage: chatBody not found");
      return;
    }

    const author = currentProvider.contacts[data.authorId];
    if (!chatBody) {
      this.log.error(this.activeProvider, "- onMessage: author not found");
      return;
    }

    const chatLine = this.chatLineService.createChatLine(
      data.chatId,
      data.text,
      false,
      this.activeProvider,
      data.timestamp,
      data
    );

    if (chatLine.isDelete) {
      chatBody.deleteLine(chatLine);
    } else {
      chatBody.addLine(author, chatLine);
      chatBody.unreadCount++;
      if (!author || !author.isSelf) {
        chatBody.hideUserTyping &&
          author &&
          chatBody.hideUserTyping(author, () => {
            this.notifyChange();
          });

        const onsipContact = this.allContacts.find(
          contact => contact.addresses[0].address === chatBody.contact.id
        );
        this._event.next({ onsipContact, chatBody });
      }
      this.notifyChange();
    }
  }

  onDisabled(data: any): void {
    const disabledProvider: any = this.chatProviders[data.disabledProvider];

    if (disabledProvider) {
      disabledProvider.service.sendMessage(
        {
          text: data.text,
          id: data.authorId
        },
        { sendDisabled: true }
      );
    }
  }

  onNewChannel(data: any): void {
    const currentProvider: any = this.chatProviders[this.activeProvider],
      contact: any = currentProvider.contacts[data.chatId];

    if (!contact) {
      this.log.error(this.activeProvider, "- onNewChannel: contact not found");
      return;
    }
    const chatBody = this.chatBodyService.createChatBody(contact, data.chatId, this.activeProvider);
    contact.chatBody = chatBody;

    currentProvider[chatBody.id] = chatBody;
  }

  onReadMarkerChange(data: any): void {
    const chatBody: any = this.chatProviders[this.activeProvider].bodies[data.chatId];

    if (!chatBody) {
      this.log.error(this.activeProvider, "- onReadMarkerChange: chatBody not found");
      return;
    }
    chatBody.readMarkerTime = data.timestamp;

    const onsipContact = this.allContacts.find(
      contact => contact.addresses[0].address === chatBody.contact.id
    );
    this._event.next({ onsipContact, chatBody });
    this.notifyChange();
  }

  onUserTyping(data: any): void {
    if (!data.isDirect) {
      return;
    }

    const chatBody = this.chatProviders[this.activeProvider].bodies[data.chatId];
    if (!chatBody) {
      this.log.error(this.activeProvider, "- onUserTyping: chatBody not found");
      return;
    }

    const chatContact = this.chatProviders[this.activeProvider].contacts[data.userId];
    if (!chatContact) {
      this.log.error(this.activeProvider, "- onUserTyping: user not found");
      return;
    }

    // this.log.debug("chatService - onUserTyping: time=", new Date());
    if (chatBody.showUserTyping) {
      chatBody.showUserTyping(chatContact, () => {
        this.notifyChange();
      });
    }
  }

  onUserPresenceChange(data: any): void {
    const chatBody: any = this.chatProviders[this.activeProvider].contacts[data.userId].chatBody,
      chatUsername: any = this.chatProviders[this.activeProvider].contacts[data.userId].fullName;

    if (!chatBody) {
      this.log.debug(this.activeProvider, "- onUserPresenceChange: no chatBody for", chatUsername);
      return;
    }

    // this.log.debug("chatService - onUserTyping: time=", new Date());
    chatBody.toggleUserAway && chatBody.toggleUserAway(data.presence);
    this.notifyChange();
  }

  private notifyChange(): void {
    Object.keys(this.onChangeCallbacks).forEach(key => {
      this.onChangeCallbacks[key]();
    });
  }

  private openChatBody(contact: any): Promise<any> {
    if (contact.chatBody) {
      return Promise.resolve(contact.chatBody);
    }

    return this.chatProviders[this.activeProvider].service
      .openChatChannel(contact)
      .then((chatProperties: OpenChatChannelObject) => {
        if (this.chatProviders[this.activeProvider].bodies[chatProperties.id]) {
          contact.chatBody = this.chatProviders[this.activeProvider].bodies[chatProperties.id];
        } else {
          this.chatProviders[this.activeProvider].bodies[chatProperties.id] =
            this.chatBodyService.createChatBody(
              contact,
              chatProperties.id,
              this.activeProvider,
              chatProperties.unreadCount,
              undefined
            );
          contact.chatBody = this.chatProviders[this.activeProvider].bodies[chatProperties.id];
        }

        return contact.chatBody;
      });
  }

  private initializeChatBody(chatBody: any): Promise<any> {
    if (chatBody.obtainedHistory) {
      return Promise.resolve(chatBody);
    }

    const currentProvider: any = this.chatProviders[this.activeProvider];

    return currentProvider.service.getHistory(chatBody).then((response: any) => {
      const history: any = {};

      response.messages.forEach((line: any) => {
        if (line.bot_id) {
          this.log.debug("bot line found, returning without it");
          return;
        }

        if (!line.user) {
          this.log.debug("line with no user, returning without it", line);
          return;
        }

        const author: any = currentProvider.contacts[line.user],
          messageLine: any = this.chatLineService.createChatLine(
            line.channel,
            line.text,
            false,
            this.activeProvider,
            line.ts,
            line
          );

        messageLine.formatIncoming && messageLine.formatIncoming(currentProvider.data);

        if (!author) {
          this.log.error("chatService - _initializeChatBody: author not found.", line);
        }

        history[line.ts] = {
          author,
          line: messageLine
        };
      });

      chatBody.populateHistory(history);
      return chatBody;
    });
  }
}
