import { DocumentData } from "../cloud/firebase/firestore/document";
import { Timestamp, defaultTimestamp } from "./timestamp";
import { getOrElse } from "./maybe";

export interface State {
  id: string; // record id
}

export function makeState(id: string): State {
  return {
    id
  };
}

export interface TimestampedState extends State {
  created: Timestamp; // record create time
  modified: Timestamp; // record modifed time
}

export function makeTimestampedState(id: string, data: DocumentData): TimestampedState {
  return {
    ...makeState(id),
    ...{
      created: getOrElse<TimestampedState["created"]>(data.created, defaultTimestamp),
      modified: getOrElse<TimestampedState["modified"]>(data.modified, defaultTimestamp)
    }
  };
}
