import { EventStateEmitterArray } from "../emitter/event-state-emitter-array";
import { Subscription, SubscriptionState } from "./subscription";
import { SubscriptionEvent } from "./subscription-event";

/**
 * An observable array of Subscription objects which never completes.
 */
export class Subscriptions extends EventStateEmitterArray<
  Subscription,
  SubscriptionEvent,
  SubscriptionState
> {
  constructor() {
    super(false); // infinite emitter
  }
  /**
   * Dispose - remove all subscriptions and complete.
   */
  dispose(): void {
    this.publishComplete();
  }
  /**
   * Adds a Subscription.
   * @param subscription The Subscription to add.
   */
  add(subscription: Subscription): this {
    return super.add(subscription);
  }
  /**
   * Removes a Subscription.
   * @param subscription The Subscription to remove.
   */
  remove(subscription: Subscription): boolean {
    return super.remove(subscription);
  }
}
