import { SubscriptionEvent, SubscriptionEventFactory } from "./subscription-event";

/**
 * Emitted once when Subscription activated.
 */
export class NewSubscriptionEvent {
  static make(aor: string, uuid: string): SubscriptionEvent {
    return SubscriptionEventFactory.make(NewSubscriptionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Subscription] new";
  }
}
