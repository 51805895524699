<div class="left">
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.ACCOUNT_ID" | translate }}
    </span>
    <p class="mat-body-2">{{ accountId$ | async }}</p>
  </div>
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.EMAIL" | translate }}
    </span>
    <p class="mat-body-2">{{ email$ | async }}</p>
  </div>
  @if (extensions$ | async; as extensions) { @if (extensions!.length > 0) {
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.MY_EXTENSIONS" | translate }}
    </span>
    <div class="user-extensions-list">
      @for (extension of extensions; track extension) {
      <p class="mat-body-2">{{ extension }}</p>
      }
    </div>
  </div>
  } } @if (phoneNumbers$ | async; as phoneNumbers) { @if (phoneNumbers!.length > 0) {
  <div class="card-section">
    <span class="label">
      {{ "ONSIP_I18N.PHONE_NUMBERS" | translate }}
    </span>
    <div class="user-phone-numbers-list">
      @for (number of phoneNumbers; track number) {
      <p class="mat-body-2">{{ number }}</p>
      }
    </div>
  </div>
  } }
  <div class="card-section horizontal-card-section">
    <onsip-password-change [sipAddress]="(sipAddress$ | async)!"></onsip-password-change>
  </div>
</div>
<div class="right">
  @if (e911Enabled$ | async) {
  <onsip-e911 class="e911-section" [showTitle]="true"></onsip-e911>
  }
</div>
