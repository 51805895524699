import { UserAgentEvent, UserAgentEventFactory } from "./user-agent-event";

/**
 * Emitted when the UserAgent unregisters.
 * Occurs only if the user agent was previously registered.
 */
export class UnregisteredUserAgentEvent {
  /**
   * Factory function to make event.
   * @param aor AOR of UserAgent.
   */
  static make(aor: string): UserAgentEvent {
    return UserAgentEventFactory.make(UnregisteredUserAgentEvent.id, aor);
  }
  static get id(): string {
    return "[UserAgent] unregistered";
  }
}
