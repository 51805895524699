import { NgModule } from "@angular/core";

import { AnimationComponent } from "./animation.component";
import { HeroGirlAnimationComponent } from "./heroGirl/hero-girl-animation.component";

@NgModule({
  declarations: [AnimationComponent, HeroGirlAnimationComponent],
  exports: [AnimationComponent, HeroGirlAnimationComponent]
})
export class AnimationModule {}
