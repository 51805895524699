const debug = false;

export interface Contact {
  vid: number;
  portalId: number;
  email: string;
  firstname?: string;
  lastname?: string;
  website?: string;
  company?: string;
  phone?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  lifecyclestage?: string;
  hubspotOwnerId?: string;
  hubspotOwnerName?: string;
}

const contactProperties = [
  "email",
  "firstname",
  "lastname",
  "website",
  "company",
  "phone",
  "address",
  "city",
  "state",
  "zip",
  "lifecyclestage",
  "hubspot_owner_id"
];

export function contactFromJSON(json: any): Contact {
  debug && console.warn(json);
  if (
    !json ||
    !json["is-contact"] ||
    !json["canonical-vid"] ||
    !json["portal-id"] ||
    !json.properties ||
    !json.properties.email
  ) {
    throw new Error("Not valid.");
  }
  const contact: any = {};
  contact.vid = json["canonical-vid"];
  contact.portalId = json["portal-id"];
  contact.hubspotOwnerId = json.properties.hubspot_owner_id
    ? json.properties.hubspot_owner_id.value
    : undefined;
  contactProperties.forEach(property => {
    const prop = json.properties[property];
    if (!prop) {
      debug && console.warn(`Propery "${property}" undefined.`);
    } else if (!prop.value) {
      debug && console.warn(`Value of "${property}" undefined.`);
    } else {
      contact[property] = prop.value;
    }
  });
  return contact;
}

export function contactsFromJSON(json: any): Array<Contact> {
  debug && console.warn(json);
  if (!json || !json.contacts) {
    throw new Error("Not valid.");
  }
  const contacts: Array<Contact> = json.contacts.map(contactFromJSON);
  return contacts;
}

export function contactRequestByEmail(email: string): string {
  const request =
    `/contacts/v1/contact/email/${email}/profile?` +
    "&property=" +
    contactProperties.join("&property=") +
    "&property-mode=value_only&formSubmissionMode=none&showListMemberships=false";
  return request;
}

export function contactRequestByQuery(query: string): string {
  const request =
    `/contacts/v1/search/query?q=${encodeURIComponent(query)}` +
    "&count=1" +
    "&property=" +
    contactProperties.join("&property=");
  return request;
}

export function contactRequestByUserToken(utk: string): string {
  const request =
    `/contacts/v1/contact/utk/${utk}/profile?` +
    "&property=" +
    contactProperties.join("&property=") +
    "&property-mode=value_only&formSubmissionMode=none&showListMemberships=false";
  return request;
}

export function contactRequestByVistorId(vid: string): string {
  const request =
    `/contacts/v1/contact/vid/${vid}/profile?` +
    "&property=" +
    contactProperties.join("&property=") +
    "&property-mode=value_only&formSubmissionMode=none&showListMemberships=false";
  return request;
}
