import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { AnimationModule } from "../animation/animation.module";
import { MaterialModule } from "../../../../modules/material/material.module";
import { PipesModule } from "../../pipes/pipes.module";

import { FreeTrialContainerComponent } from "./free-trial-container.component";
import { FreeTrialExpiringModalComponent } from "./free-trial-expiring-modal.component";
import { FreeTrialWelcomeModalComponent } from "./free-trial-welcome-modal.component";

@NgModule({
  imports: [
    AnimationModule,
    CommonModule,
    MaterialModule,
    PipesModule,
    RouterModule,
    TranslateModule
  ],
  declarations: [
    FreeTrialContainerComponent,
    FreeTrialExpiringModalComponent,
    FreeTrialWelcomeModalComponent
  ],
  exports: [
    FreeTrialContainerComponent,
    FreeTrialExpiringModalComponent,
    FreeTrialWelcomeModalComponent
  ]
})
export class FreeTrialModule {}
