@if (transaction.type !== 'payment') {
<div class="padding-y-16">
  @for (detail of transactionDetails; track detail) {
  <div class="mat-body-1">{{ detail }}</div>
  }
</div>
} @if (transaction.type === 'payment') {
<div class="payment-shelf">
  <div class="mat-body-1 padding-y-16 commission-title">{{ paymentTitle }}</div>
  <div class="onsip-grid-placement-shelf payment-info">
    <span>Agent:</span>
    <span class="mat-body-1">{{ paymentAgent }}</span>
  </div>
  <div class="onsip-grid-placement-shelf payment-info">
    <span>Agreement:</span>
    <span class="mat-body-1">{{ paymentAgreement }}</span>
  </div>
  <div class="onsip-grid-placement-shelf payment-info">
    <span>Beginning balance:</span>
    <span class="mat-body-1">{{ paymentBeginningBalance }}</span>
  </div>
  <div class="onsip-grid-placement-shelf payment-info">
    <span>Total commission:</span>
    <span class="mat-body-1">{{ totalCommission }}</span>
  </div>
  <div class="onsip-grid-placement-shelf payment-info">
    <span>Ending balance:</span>
    <span class="mat-body-1">{{ endingBalance }}</span>
  </div>
  <!-- ASR -->
  <div class="application-services-revenue mat-body-1 padding-y-16">
    {{ paymentApplicationServicesRevenue }}
  </div>
  <!-- Application Services Revenue Table -->
  <div class="revenue-table-container">
    <table
      onsip-shelf-table
      matSort
      [dataSource]="asrDataSource"
      multiTemplateDataRows
      #asrTableSort="matSort"
      class="revenue-table onsip-grid-placement-table commission-table"
    >
      <!-- Account Id -->
      <ng-container matColumnDef="accountId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acct Id</th>
        <td mat-cell *matCellDef="let asRevenue">
          <span class="ellipsis">{{ asRevenue.accountId }}</span>
        </td>
      </ng-container>
      <!-- Org -->
      <ng-container matColumnDef="org">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org</th>
        <td mat-cell *matCellDef="let asRevenue">
          <div class="ellipsis">{{ asRevenue.org }}</div>
        </td>
      </ng-container>
      <!-- Date -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let asRevenue">
          <span class="ellipsis">{{ asRevenue.date || "-" }}</span>
        </td>
      </ng-container>
      <!-- Payment Id -->
      <ng-container matColumnDef="paymentId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Id</th>
        <td mat-cell *matCellDef="let asRevenue">
          <span class="ellipsis">{{ asRevenue.paymentId || "-" }}</span>
        </td>
      </ng-container>
      <!-- Commissionable / total -->
      <ng-container matColumnDef="commissionableOverTotal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Commissionable / total</th>
        <td mat-cell *matCellDef="let asRevenue">
          <span class="ellipsis">{{ asRevenue.commissionableOverTotal || "No payments" }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="asrDisplayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: asrDisplayColumns"></tr>
    </table>
  </div>
  <!-- ASR total and commissions -->
  <div class="total-and-commissions-container">
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Total:</span>
      <span class="mat-body-1">{{ asrTotal }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Commission rate:</span>
      <span class="mat-body-1">{{ asrCommissionRate }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>App services commission:</span>
      <span class="mat-body-1">{{ asrCommission }}</span>
    </div>
  </div>
  @if (wgrDataSource.data.length) {
  <!-- WGR -->
  <div class="application-services-revenue mat-body-1 padding-y-16">
    {{ whiteGloveRevenue }}
  </div>
  <!-- White Glove Revenue Table -->
  <div class="revenue-table-container">
    <table
      onsip-shelf-table
      matSort
      [dataSource]="wgrDataSource"
      multiTemplateDataRows
      #wgrTableSort="matSort"
      class="revenue-table onsip-grid-placement-table commission-table"
    >
      <!-- Account Id -->
      <ng-container matColumnDef="accountId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Acct Id</th>
        <td mat-cell *matCellDef="let wgRevenue">
          <span class="ellipsis">{{ wgRevenue.accountId }}</span>
        </td>
      </ng-container>
      <!-- Org -->
      <ng-container matColumnDef="org">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org</th>
        <td mat-cell *matCellDef="let wgRevenue">
          <div class="ellipsis">{{ wgRevenue.org }}</div>
        </td>
      </ng-container>
      <!-- Date -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
        <td mat-cell *matCellDef="let wgRevenue">
          <span class="ellipsis">{{ wgRevenue.date || "-" }}</span>
        </td>
      </ng-container>
      <!-- Payment Id -->
      <ng-container matColumnDef="paymentId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Id</th>
        <td mat-cell *matCellDef="let wgRevenue">
          <span class="ellipsis">{{ wgRevenue.paymentId || "-" }}</span>
        </td>
      </ng-container>
      <!-- Commissionable / total -->
      <ng-container matColumnDef="commissionableOverTotal">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Commissionable / total</th>
        <td mat-cell *matCellDef="let wgRevenue">
          <span class="ellipsis">{{ wgRevenue.commissionableOverTotal || "No payments" }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="wgrDisplayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: wgrDisplayColumns"></tr>
    </table>
  </div>
  <!-- WGR total and commissions -->
  <div class="total-and-commissions-container">
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Total:</span>
      <span class="mat-body-1">{{ wgrTotal }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Commission rate:</span>
      <span class="mat-body-1">{{ wgrCommissionRate }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>White glove commission:</span>
      <span class="mat-body-1">{{ wgrCommission }}</span>
    </div>
  </div>
  }
  <!-- PSTN Gateway Utilization -->
  <div class="application-services-revenue mat-body-1 padding-y-16">
    {{ pstnGatewayUtilizationRevenue }}
  </div>
  <!-- PSTN Table -->
  <div class="pstn-table-container">
    <table
      onsip-shelf-table
      matSort
      [dataSource]="pstnDataSource"
      multiTemplateDataRows
      #pstnTableSort="matSort"
      class="pstn-table onsip-grid-placement-table commission-table"
    >
      <!-- Account Id -->
      <ng-container matColumnDef="accountId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Id</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <span class="ellipsis">{{ pstnRevenue.accountId }}</span>
        </td>
      </ng-container>
      <!-- Org -->
      <ng-container matColumnDef="org">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <div class="ellipsis">{{ pstnRevenue.org }}</div>
        </td>
      </ng-container>
      <!-- Org Id -->
      <ng-container matColumnDef="orgId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Org Id</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <span class="ellipsis">{{ pstnRevenue.orgId }}</span>
        </td>
      </ng-container>
      <!-- Domain -->
      <ng-container matColumnDef="domain">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Domain</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <div class="ellipsis">{{ pstnRevenue.domain }}</div>
        </td>
      </ng-container>
      <!-- Min -->
      <ng-container matColumnDef="min">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Min</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <span class="ellipsis">{{ pstnRevenue.min }}</span>
        </td>
      </ng-container>
      <!-- Calls -->
      <ng-container matColumnDef="calls">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>calls</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <span class="ellipsis">{{ pstnRevenue.calls }}</span>
        </td>
      </ng-container>
      <!-- Commissionable -->
      <ng-container matColumnDef="commissionable">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Commissionable</th>
        <td mat-cell *matCellDef="let pstnRevenue">
          <span class="ellipsis">{{ pstnRevenue.commissionable }}</span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="pstnDisplayColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: pstnDisplayColumns"
        [ngClass]="{ ineligible: row.ineligibleRevenue === true }"
      ></tr>
    </table>
  </div>
  <!-- PSTN total and commissions -->
  <div class="total-and-commissions-container">
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Total:</span>
      <span class="mat-body-1">{{ pstnTotal }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>Commission rate:</span>
      <span class="mat-body-1">{{ pstnCommissionRate }}</span>
    </div>
    <div class="onsip-grid-placement-shelf total-and-commissions">
      <span>PSTN Gateway commission:</span>
      <span class="mat-body-1">{{ pstnCommission }}</span>
    </div>
  </div>
</div>
}
<div class="print-btn-container">
  <button mat-flat-button color="primary" (click)="downloadCSV()">Download CSV</button>
</div>
