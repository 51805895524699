export interface ApiQueueAgentEvent {
  AgentAddress: string;
  AgentName: string;
  AppId: string;
  Created: string;
  Event: string;
  EventTime: string;
  QueueAgentEventId: string;
  QueueOrganizationId: string;
}

export interface QueueAgentEvent {
  agentAddress: string;
  agentName: string;
  appId: string;
  created: string;
  event: string;
  eventTime: string;
  queueAgentEventId: string;
  queueOrganizationId: string;
}

export function apiQueueAgentEventToQueueAgentEvent(
  apiQAEvent: ApiQueueAgentEvent
): QueueAgentEvent {
  return {
    agentAddress: apiQAEvent.AgentAddress,
    agentName: apiQAEvent.AgentName,
    appId: apiQAEvent.AppId,
    created: apiQAEvent.Created,
    event: apiQAEvent.Event,
    eventTime: apiQAEvent.EventTime,
    queueAgentEventId: apiQAEvent.QueueAgentEventId,
    queueOrganizationId: apiQAEvent.QueueOrganizationId
  };
}
