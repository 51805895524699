import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum LogLevel {
  Debug = "debug",
  Info = "info",
  Warning = "warning",
  Error = "error"
}

export interface LogMessage {
  level: LogLevel;
  message: string;
}

/**
 * A singleton for subscribing to log messages from this library.
 */
export class Log {
  /** Singleton Instance */
  private static instance: Log;

  /** The public Observable that the Observer subscribes to for the log message stream. */
  message: Observable<LogMessage>;

  /** The implementation a Subject. */
  private _message: Subject<LogMessage>;

  /** For internal console logging. */
  private logSubscription: Subscription | undefined;
  private unsubscribe: Subject<void> | undefined;

  /** Singleton, private constructor */
  private constructor() {
    this._message = new Subject();
    this.message = this._message.asObservable();
  }

  /** Gets the single instance. */
  static get Instance(): Log {
    if (this.instance === undefined) {
      this.instance = new Log();
    }
    return this.instance;
  }

  /**
   * Log at debug level.
   * @param message Message to log.
   */
  debug(message: string): void {
    this.log(LogLevel.Debug, message);
  }

  /**
   * Log at info level.
   * @param message Message to log.
   */
  info(message: string): void {
    this.log(LogLevel.Info, message);
  }

  /**
   * Log at warn level.
   * @param message Message to log.
   */
  warn(message: string): void {
    this.log(LogLevel.Warning, message);
  }

  /**
   * Log at error level.
   * @param message Message to log.
   */
  error(message: string): void {
    this.log(LogLevel.Error, message);
  }

  /**
   * Enables logging to the console.
   * A "built in" console logger.
   */
  enableConsoleLogging(): void {
    if (this.logSubscription) {
      return;
    }
    this.unsubscribe = new Subject<void>();
    this.logSubscription = this.message.pipe(takeUntil(this.unsubscribe)).subscribe({
      next: logMessage => {
        switch (logMessage.level) {
          case LogLevel.Debug:
            console.log(logMessage.message);
            break;
          case LogLevel.Info:
            console.info(logMessage.message);
            break;
          case LogLevel.Warning:
            console.warn(logMessage.message);
            break;
          case LogLevel.Error:
            console.error(logMessage.message);
            break;
          default:
            throw new Error("Unexpected log level. Message = " + logMessage.message);
        }
      },
      error: (error: unknown) => {
        console.error("Log subscription error: " + error);
        throw error;
      },
      complete: () => {
        // subscription complete
      }
    });
  }

  /**
   * Disables logging to the console.
   */
  disableConsoleLogging(): void {
    if (!this.unsubscribe || !this.logSubscription) {
      return;
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.unsubscribe = undefined;
    this.logSubscription = undefined;
  }

  /**
   * Logs the message.
   * @param level The log level.
   * @param message The log message.
   */
  private log(level: LogLevel, message: string): void {
    this._message.next({
      level,
      message
    });
  }
}

export const log: Log = Log.Instance;
