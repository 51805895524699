import { Injectable } from "@angular/core";
import { Subscription, NEVER } from "rxjs";
import { filter, switchMap, map, take } from "rxjs/operators";

import { isEndCallEvent, isNewCallEvent } from "../../../../../../common/libraries/sip/call-event";
import { HubspotApiService } from "./hubspot-api.service";
import { AuthService } from "../../../../../../common/services/sayso/auth.service";
import { FirestoreCallService } from "../../../../../../common/services/sayso/firestore-call.service";
import { makeCallItem } from "../../../helpers/call-item.helper";
import { CallControllerService } from "../../../../../../common/services/call-controller.service";
import { UserService } from "../../../../../../common/services/api/resources/user/user.service";
import { OnSIPURI } from "../../../../../../common/libraries/onsip-uri";
import { E164PhoneNumber } from "../../../../../../common/libraries/e164-phone-number";
import { Contact } from "./lib/contact";
import { StateEmitter } from "../../../../../../common/libraries/emitter/state-emitter";

// Issues/limitations with E164 matching
//
// 1) Inbound calls with valid Call-Id Number information available
// 2) Only searches only for Contact matches on standard HubSpot “Phone number” field
//  - doesn’t search for Company matches
//  - doesn’t search other phone number fields like mobile phone number
// 3) Only guaranteed to find match if “Phone number” stored in HubSpot in E164 format
//  - effort is made to find match, but HubSpot currently allows random text formats (doesn’t enforce valid phone number format)
//  - here’s a link from the HubSpot community on the issue:
//    https://community.hubspot.com/t5/HubSpot-Ideas/Automatic-Phone-Number-Formatting/idc-p/207805#M22406
// 4) Always returns “first match” which may change from call to call
//  - multiple Contacts in HubSpot can have the same phone number in which case we
//    cannot guarantee that we will match the same Contact on sequential calls
// 5) No timeline update
//  - for sayso calls we update the Contact’s Timeline with OnSIP Call Info as we can guarantee
//    that we have match the correct contact, but for calls matching on Phone Number we cannot
//    know if we have matched the correct Contact so don’t try to update the Contact
const DISABLE_E164 = false;

interface HubspotInfoState {
  utkContacts: Record<string, Contact>;
  e164Contacts: Record<string, Contact>;
}

@Injectable({ providedIn: "root" })
export class HubspotInfoService extends StateEmitter<HubspotInfoState> {
  private enabled = false;
  private unsubscriber = new Subscription();
  private username: string | undefined;

  constructor(
    private hubspotApi: HubspotApiService,
    private firestoreCall: FirestoreCallService,
    private callController: CallControllerService,
    private userService: UserService,
    private auth: AuthService
  ) {
    super({
      utkContacts: {},
      e164Contacts: {}
    });
    this.init();
  }

  dispose(): void {
    this.unsubscriber.unsubscribe();
  }

  fetchHubspotContact(uuid: string): Contact | undefined {
    return this.stateStore.utkContacts[uuid] || this.stateStore.e164Contacts[uuid];
  }

  private init(): void {
    this.subscribeHubspotApiService();
    this.subscribeFirestoreCall();
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.username = user.contact.name;
      })
    );
  }

  private subscribeHubspotApiService() {
    this.unsubscriber.add(
      this.hubspotApi.state.subscribe(state => {
        this.enabled = state.authorized;
      })
    );
  }

  private subscribeFirestoreCall(): void {
    this.unsubscriber.add(
      this.firestoreCall.event
        .pipe(
          filter(event => this.enabled && isNewCallEvent(event)),
          switchMap(event => {
            const firestoreCall = this.firestoreCall.getCallFromEvent(event),
              sipCall = this.callController.getCallStateByUuid(event.uuid);
            if (sipCall) {
              if (firestoreCall) {
                return firestoreCall.state.pipe(
                  filter(
                    state =>
                      state.context.cookie !== undefined &&
                      state.context.cookie.hubspotutk !== undefined
                  ),
                  take(1),
                  map(state => ({
                    utk:
                      state.context.cookie && state.context.cookie.hubspotutk
                        ? state.context.cookie.hubspotutk
                        : undefined,
                    sipCall
                  }))
                );
              } else if (!DISABLE_E164) {
                const uri = OnSIPURI.parseString(sipCall.remoteUri);
                if (!uri || !uri.user || !uri.isPhoneNumber()) return NEVER; // only calls from apparent phone numbers
                const phoneNumber = new E164PhoneNumber(uri.user);
                if (!phoneNumber.isValid) return NEVER; // only calls from valid e164 phone numbers
                this.hubspotApi
                  .getContactByE164(phoneNumber.e164Uri)
                  .then(contact => {
                    this.stateStore.e164Contacts[sipCall.uuid] = contact;
                    this.publishState();
                    this.hubspotApi.getHubspotOwnerFromContact(contact).then(ownerName => {
                      contact.hubspotOwnerName = ownerName;
                      this.stateStore.e164Contacts[sipCall.uuid] = contact;
                      this.publishState();
                    });
                  })
                  .catch(() => {
                    console.error("no contact found for hubspot");
                  });
              }
            }
            return NEVER;
          })
        )
        .subscribe(utkObj => {
          if (!utkObj.utk) return undefined;
          this.hubspotApi.getContactByUserToken(utkObj.utk).then(contact => {
            this.stateStore.utkContacts[utkObj.sipCall.uuid] = contact;
            this.publishState();
            this.hubspotApi.getHubspotOwnerFromContact(contact).then(ownerName => {
              contact.hubspotOwnerName = ownerName;
              this.stateStore.utkContacts[utkObj.sipCall.uuid] = contact;
              this.publishState();
            });
          });
        })
    );

    this.unsubscriber.add(
      this.firestoreCall.event
        .pipe(filter(event => this.enabled && isEndCallEvent(event)))
        .subscribe(event => {
          const firestoreCall = this.firestoreCall.getCallFromEvent(event),
            sipCall = this.callController.getCallStateByUuid(event.uuid);

          if (
            firestoreCall &&
            firestoreCall.stateValue.context.cookie &&
            firestoreCall.stateValue.context.cookie.hubspotutk
          ) {
            const aor = this.username || (sipCall ? sipCall.aor : this.auth.stateValue.aor),
              hubspotutk = firestoreCall.stateValue.context.cookie.hubspotutk,
              callItem = makeCallItem(firestoreCall.stateValue, aor),
              reason = event.reason && event.reason === "terminated" ? "answered" : "unanswered";

            let numSeconds;

            if (sipCall && sipCall.endedAt && sipCall.connectedAt) {
              numSeconds = Math.floor((sipCall.endedAt - sipCall.connectedAt) / 1000);
            }

            this.hubspotApi.addTimelineEvent(
              hubspotutk,
              aor || "N/A",
              callItem,
              numSeconds,
              reason
            );
          }
        })
    );
  }
}
