import { Injectable } from "@angular/core";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import {
  ApiQueueSummary,
  QueueSummary,
  QueueSummaryParams,
  ApiQueueSummaryToQueueSummary as clean
} from "./queue-summary";

const debug = false;

@Injectable({ providedIn: "root" })
export class QueueSummaryService extends ApiResourceService<QueueSummary> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "QueueSummary", "appId");
    debug && this.state.subscribe(state => console.warn("QueueSummarySevice", state));
  }

  queueSummaryBrowse(params?: QueueSummaryParams): ApiPromiseState<QueueSummary> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.QueueSummaryBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 25000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.QueueSummaryBrowse);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.QueueSummaryBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiQueueSummary>>(
              response,
              action,
              "QueueSummary",
              "QueueSummaries"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
