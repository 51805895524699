import { LogoUploadModalComponent } from "./uploadModal/logo-upload-modal.component";
import { AvatarUploadModalComponent } from "./uploadModal/avatar-upload-modal.component";

import { AvatarUploadComponent } from "./avatarUpload/avatar-upload.component";
import { LogoUploadComponent } from "./logoUpload/logo-upload.component";
import { ImageUploadComponent } from "./image-upload.component";

import { ImageCropperModule } from "ngx-image-cropper";
import { NgxFileDropModule } from "ngx-file-drop";

import { ModalModule } from "../modal/modal.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { AvatarModule } from "../avatar/avatar.module";

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ImageCropperModule,
    NgxFileDropModule,
    MaterialModule,
    TranslateModule,
    AvatarModule
  ],
  declarations: [
    ImageUploadComponent,
    LogoUploadModalComponent,
    AvatarUploadModalComponent,
    AvatarUploadComponent,
    LogoUploadComponent
  ],
  exports: [
    ImageUploadComponent,
    LogoUploadModalComponent,
    AvatarUploadModalComponent,
    AvatarUploadComponent,
    LogoUploadComponent
  ]
})
export class ImageUploaderModule {}
