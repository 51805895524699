import { AccountPackageStatus } from "./package-status";
import {
  ApiIncludedResource,
  apiIncludedResourceToIncludeResource,
  IncludedResource
} from "./account-resource";
import { OnsipAPIArray, onsipApiArrayToArray } from "../../apiResponse/xml-json";

export enum DisplayPackageName {
  FREE_PLAN = "Free Plan",
  BASIC_PLAN = "Basic Plan",
  UNLIMITED_PLAN = "Unlimited Plan",
  CUSTOM_PLAN = "Custom Plan",
  NO_PLAN_A_LA_CARTE = "No Plan - A la Carte"
}

export enum UserPackagePlan {
  FREE = "FREE",
  BASIC = "BASIC",
  UNLIMITED = "UNLIMITED"
}

export declare interface ApiPackage {
  /** time when package was assigned to account. this is empty if package is unassigned */
  Assigned?: string;
  Created: string;
  Description: string;
  IncludedResources: OnsipAPIArray<"IncludedResource", ApiIncludedResource>;
  Modified: string;
  MonthlyRecurringCharge: string;
  Name: string;
  OneTimeCharge: string;
  PackageId: string;
  StandardCallingMinutes: string;
  StandardCallingMinutesMonthlyRecurringCharge: string;
  Status: AccountPackageStatus;
}

export declare interface Package {
  /** time when package was assigned to account. this is empty if package is unassigned */
  assigned?: string;
  description: string;
  /** parsing of package name into a user friendly display name */
  displayName: string;
  includedResources: Array<IncludedResource>;
  monthlyRecurringCharge: string;
  name: string;
  oneTimeCharge: string;
  packageId: string;
  standardCallingMinutes: string;
  standardCallingMinutesMonthlyRecurringCharge: string;
  status: AccountPackageStatus;
}

export function apiPackageToPackage(apiPackage: ApiPackage): Package {
  const plan = {
    description: apiPackage.Description,
    displayName: cleanDisplayPackageName(apiPackage.Name, apiPackage.Description),
    includedResources: onsipApiArrayToArray(apiPackage.IncludedResources, "IncludedResource").map(
      apiIncludedResourceToIncludeResource
    ),
    monthlyRecurringCharge: apiPackage.MonthlyRecurringCharge,
    name: apiPackage.Name,
    oneTimeCharge: apiPackage.OneTimeCharge,
    packageId: apiPackage.PackageId,
    standardCallingMinutes: apiPackage.StandardCallingMinutes,
    standardCallingMinutesMonthlyRecurringCharge:
      apiPackage.StandardCallingMinutesMonthlyRecurringCharge,
    status: apiPackage.Status
  };
  return apiPackage.Assigned ? { ...plan, assigned: apiPackage.Assigned } : plan;
}

// we only need to parse the PSM and PSU packages as they have poor description names
export function cleanDisplayPackageName(name: string, description: string): string {
  if (name.match(/User-1\.3-(\d+)/)) {
    return "Per Seat Metered Plan";
  } else if (name.match(/User-1\.[4567]-(\d+)/)) {
    return "Per Seat Unlimited Plan";
  } else {
    return description;
  }
}
