import { ApiNoAuthUserSummaryAddress, NoAuthUserSummaryAddress } from "./address";
import { UserCustomization } from "../resources/userCustomization/user-customization";
import { UserInfo } from "./user-info";
import { User } from "../resources/user/user";
import { UserSummary } from "../resources/userSummary/user-summary";
import { Contact } from "./contact";
import { OnsipAPIBoolean, onsipApiBooleanToBoolean, OnsipAPINotEmptyArray } from "./xml-json";

export declare interface ApiNoAuthUserSummary {
  Addresses: OnsipAPINotEmptyArray<"Address", ApiNoAuthUserSummaryAddress>;
  Blurb: string;
  ButtonConfiguration: string; // DEPRECATED this is the uuid of a button configuration
  ContactEmail: string;
  ContactName: string;
  ContactPhone: string;
  Domain: string;
  ExposePii: OnsipAPIBoolean;
  Facebook: string;
  Linkedin: string;
  OrganizationId: string;
  TeamPageVisible: OnsipAPIBoolean;
  Title: string;
  Twitter: string;
  UserAvatarUrl: string;
  UserId: string;
}

export declare interface NoAuthUserSummary {
  addresses: Array<NoAuthUserSummaryAddress>;
  blurb: string;
  contactEmail: string;
  contactName: string;
  contactPhone: string;
  domain: string;
  exposePii: boolean;
  facebook: string;
  linkedin: string;
  organizationId: string;
  teamPageVisible: boolean;
  title: string;
  twitter: string;
  userAvatarUrl: string;
  userId: string;
}

export function apiNoAuthUserSummaryToUserPartial(
  apiNoAuthUserSummary: ApiNoAuthUserSummary
): Partial<User> {
  return {
    contact: {
      name: apiNoAuthUserSummary.ContactName,
      email: apiNoAuthUserSummary.ContactEmail,
      phone: apiNoAuthUserSummary.ContactPhone
    } as Contact,
    domain: apiNoAuthUserSummary.Domain,
    exposePii: onsipApiBooleanToBoolean(apiNoAuthUserSummary.ExposePii),
    organizationId: apiNoAuthUserSummary.OrganizationId,
    teamPageVisible: onsipApiBooleanToBoolean(apiNoAuthUserSummary.TeamPageVisible),
    userId: apiNoAuthUserSummary.UserId
  };
}

export function apiNoAuthUserSummaryToUserCustomizationPartial(
  apiNoAuthUserSummary: ApiNoAuthUserSummary
): Partial<UserCustomization> {
  return {
    userInfo: {
      personalPageInfo: {
        blurb: apiNoAuthUserSummary.Blurb,
        facebook: apiNoAuthUserSummary.Facebook,
        linkedin: apiNoAuthUserSummary.Linkedin,
        title: apiNoAuthUserSummary.Title,
        twitter: apiNoAuthUserSummary.Twitter
      }
    } as UserInfo,
    organizationId: apiNoAuthUserSummary.OrganizationId,
    userAvatarUrl: apiNoAuthUserSummary.UserAvatarUrl,
    userId: apiNoAuthUserSummary.UserId
  };
}

export function apiNoAuthUserSummaryToUserSummaryPartial(
  apiNoAuthUserSummary: ApiNoAuthUserSummary
): Partial<UserSummary> {
  return {
    blurb: apiNoAuthUserSummary.Blurb,
    contactName: apiNoAuthUserSummary.ContactName,
    contactEmail: apiNoAuthUserSummary.ContactEmail,
    contactPhone: apiNoAuthUserSummary.ContactPhone,
    organizationId: apiNoAuthUserSummary.OrganizationId,
    userAvatarUrl: apiNoAuthUserSummary.UserAvatarUrl,
    userId: apiNoAuthUserSummary.UserId,
    title: apiNoAuthUserSummary.Title
  };
}
