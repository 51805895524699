<div class="mat-headline-5">Your support tickets</div>
<div class="support-tickets">
  @if (supportTickets && supportTickets.length > 0) { @for (ticket of supportTickets; track ticket;
  let last = $last) {
  <div class="ticket-info onsip-grid-placement">
    <span class="ticket-id">{{ ticket.id }}</span>
    <span class="ticket-date">{{ ticket.createdAt | date : "MM/dd/yy" }}</span>
    <span class="ticket-subject">{{ ticket.subject }}</span>
    <span class="ticket-desc">{{ ticket.description }}</span>
    <span class="ticket-status">
      <mat-icon class="ticket-status-circle" [ngClass]="{ closed: ticket.status === 'closed' }"
        >lens</mat-icon
      >
      <span>{{ ticket.status | titlecase }}</span>
    </span>
  </div>
  @if (!last) {
  <mat-divider class="divider"></mat-divider>
  } } } @else {
  <span class="empty-state">You have no open support tickets</span>
  }
</div>
