import { SupportService } from "../../../../../common/services/support/support.service";
import { GumService } from "../gum/gum.service";
import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";
import { AppCallingService } from "../appCalling/app-calling.service";

import { Injectable } from "@angular/core";
import { StateEmitter } from "../../../../../common/libraries/emitter/state-emitter";
import { distinctUntilChanged, pluck } from "rxjs/operators";

export interface AutoAnswerState {
  enabled: boolean;
  hasVideo: boolean;
}

@Injectable({ providedIn: "root" })
export class AutoAnswerService extends StateEmitter<AutoAnswerState> {
  private canAutoAnswer = this.supportService.getBrowser().name === "chrome";
  private savedValue = false;

  constructor(
    private supportService: SupportService,
    private gumService: GumService,
    private appCallingService: AppCallingService,
    private localStorageService: LocalStorageService
  ) {
    super({ enabled: false, hasVideo: false });

    if (!this.canAutoAnswer) {
      return;
    }

    this.localStorageService.getAutoAnswer().then(isAutoAnswer => {
      if (isAutoAnswer) {
        this.enable();
      }

      this.appCallingService.state
        .pipe(pluck("enabled"), distinctUntilChanged())
        .subscribe(isEnabled => {
          if (!isEnabled) {
            this.stateStore.enabled = false;
            this.publishState();
          } else if (this.savedValue) {
            this.stateStore.enabled = true;
            this.publishState();
          }
        });
    });
  }

  enable(): void {
    if (!this.canAutoAnswer) {
      return;
    }
    this.stateStore.enabled = true;
    this.publishState();
    this.getMediaPermission()
      .then(() => {
        this.savedValue = true;
        this.localStorageService.setGeneralSettings({ autoAnswer: true });
      })
      .catch(() => this.disable());
  }

  disable(): void {
    this.stateStore.enabled = this.savedValue = this.stateStore.hasVideo = false;
    this.localStorageService.setGeneralSettings({ autoAnswer: false });
    this.publishState();
  }

  private getMediaPermission(): Promise<any> {
    return this.gumService
      .getUserMedia({ audio: true, video: true }, { isAutoAnswerRequest: true })
      .then(stream => {
        if (stream.active) {
          const tracks = stream.getTracks();
          tracks.some(track => track.kind === "video");
          tracks.forEach(track => {
            if (track.kind === "video") {
              this.stateStore.hasVideo = true;
            }
            track.stop();
          });
        }
      });
  }
}
