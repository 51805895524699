import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "length"
})
export class FormatLengthPipe implements PipeTransform {
  transform(value: any): any {
    // Formats number into string with numbers of the form x -> 0x where x<10
    const num: number = parseInt(value);
    let formatted: string;
    if (num < 10) {
      formatted = "0" + num;
    } else {
      formatted = value;
    }
    return formatted;
  }
}
