import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { StateEmitter } from "../../emitter/state-emitter";
import { UserInstance, UserInstances } from "./user-instance";
import { firebase } from "../cloud/firebase/platform-firebase-types";

const debug = false;

/**
 * From Callers perspective...
 *  - I need to know if I can have an audio call with the User.
 *  - I need to know if I can have a video call with the User.
 * That is, while the user can control the types of calls they are willing to take, the experience
 * of the caller should be such that if they have an option to do audio call and do so then an audio call
 * happens. Likewise if they have an option to do a video call a video all happens. In particular,
 * I don't want to choose to do a video call and then end up not having a video call.
 *
 * From the Users perspective...
 *  - I need to indicate that I don't want to receive any calls. (Do Not Disturb)
 *  - I need to indicate that I don't want to receive video calls. (Video Disabled)
 *
 * The user may have multiple user agents instances running (say a desktop versiona and a mobile version),
 * so a question arrises as to what their overall state is from the callers perspective if the user has indicated
 * different preferences on different instances.
 *
 * The user's preferences can logically be either instance specific or user specific.
 * The implementation herein is currently is instance specific preferences, so...
 *  - A user is available for audio calls if no instance is busy and some instances is not on dnd
 *  - A user is available for video calls if no instance is busy and some instances is not on dnd and has not disabled video
 * There is an assumption, perhaps incorrect, that if a user is willing to take video calls,
 * they are also willing to take audio calls.
 */

export interface UserPresenceState {
  /** Organization Id the user belongs to. */
  oid: number;
  /** The User Id. */
  uid: number;
  /** The AOR to reach this user. */
  aor: string;
  /** The display name associated with the AOR. */
  name: string;
  /** True if the user is busy. Update after call to start(). */
  busy: boolean;
  /** True if user is available for audio call. Updated after call to start(). */
  audioAvailable: boolean;
  /** True if user is available for video call. Updated after call to start(). */
  videoAvailable: boolean;
  /** The url for the user's avatar. */
  picture?: string;
  /** The displayable title for the user. */
  title?: string;
}

export class UserPresenceListener extends StateEmitter<UserPresenceState> {
  private listenCallback: ((a: firebase.database.DataSnapshot | null) => any) | undefined =
    undefined;
  private listenRef: firebase.database.Reference;

  static makeInitialState(
    oid: number,
    uid: number,
    aor: string,
    name: string,
    busy: boolean,
    audioAvailable: boolean,
    videoAvailable: boolean,
    picture?: string,
    title?: string
  ): UserPresenceState {
    return {
      oid,
      uid,
      aor,
      name,
      busy,
      audioAvailable,
      videoAvailable,
      picture,
      title
    };
  }

  static makelistenRef(oid: number, uid: number): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/users/${uid}`);
  }

  /**
   * Constructor
   * @param oid The organization id of the user.
   * @param uid The user id of the user and the key of the node.
   */
  constructor(
    oid: number,
    uid: number,
    initialBusy: boolean = false,
    initialAudioAvailable: boolean = false,
    initialVideoAvailable: boolean = false
  ) {
    super(
      UserPresenceListener.makeInitialState(
        oid,
        uid,
        "",
        "",
        initialBusy,
        initialAudioAvailable,
        initialVideoAvailable
      )
    );
    this.listenRef = UserPresenceListener.makelistenRef(oid, uid);
  }

  dispose(): void {
    debug && console.log(`UserPresenceListener[${this.id}].dispose`);
    this.publishStateComplete();
    this.stop();
  }

  start(): void {
    if (this.listenCallback) {
      this.stop();
    }
    this.listenCallback = snap => {
      // eslint-disable-next-line no-null/no-null
      if (snap === null || snap.val() === null) {
        this.updateState(undefined);
      } else if (snap.val()) {
        this.updateState(snap.val());
      } else {
        throw new TypeError("Invalid value.");
      }
    };
    this.listenRef.on("value", this.listenCallback, (error: any) => {
      debug && console.error(`UserPresenceListener[${this.id}].listenRef error`);
      debug && console.error(error);
      throw error;
    });
  }

  stop(): void {
    if (this.listenCallback) {
      this.listenRef.off("value", this.listenCallback);
      this.listenCallback = undefined;
      this.updateState(undefined);
    }
  }

  private updateState(value: UserInstances | undefined): void {
    debug && console.log(`UserPresenceListener[${this.id}].updateState`);
    if (value === undefined) {
      this.stateStore.aor = "";
      this.stateStore.audioAvailable = false;
      this.stateStore.videoAvailable = false;
      this.publishState();
      return;
    }
    type UserInstanceSansC = Pick<UserInstance, Exclude<keyof UserInstance, "c">>; // the user instance interface but without the "c" property
    const instances = Array<UserInstanceSansC>();
    const userInstances = value;
    Object.keys(userInstances).forEach(key => {
      const userInstance = userInstances[key];
      if (key !== "i") {
        // DELETEME: TODO: FIXME - guard against old data
        const myInstance: UserInstanceSansC = {
          a: userInstance.a,
          n: userInstance.n,
          b: userInstance.b,
          d: userInstance.d,
          v: userInstance.v
        };
        if (userInstance.p) myInstance.p = userInstance.p;
        if (userInstance.t) myInstance.t = userInstance.t;
        instances.push(myInstance);
      }
    });
    const foundInstanceAOR = instances.find(i => i.a !== "");
    const someInstanceBusy = instances.some(i => i.b);
    const someInstanceAudioAvailable = instances.some(i => !i.d);
    const someInstanceVideoAvailable = instances.some(i => !(i.d || i.v));
    this.stateStore.aor = foundInstanceAOR ? foundInstanceAOR.a : "";
    this.stateStore.name = foundInstanceAOR ? foundInstanceAOR.n : "";
    this.stateStore.busy = someInstanceBusy;
    this.stateStore.audioAvailable = !someInstanceBusy && someInstanceAudioAvailable;
    this.stateStore.videoAvailable = !someInstanceBusy && someInstanceVideoAvailable;
    this.stateStore.picture = foundInstanceAOR ? foundInstanceAOR.p : "";
    this.stateStore.title = foundInstanceAOR ? foundInstanceAOR.t : "";
    this.publishState();
  }

  private get id(): string {
    return `${this.listenRef.key}`;
  }
}
