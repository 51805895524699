import { IdentityService } from "../../../services/identity.service";
import { ConsoleService } from "../../../services/console/console.service";
import { LogService } from "../../../services//logging/log.service";
import { RouterExtensions } from "../../../services/router-extensions.service";
import { WindowService } from "../../../services/window/window.service";
import { ApiSessionService } from "../../../services/api/api-session.service";

export const CORE_PROVIDERS: Array<any> = [
  IdentityService,
  ConsoleService,
  LogService,
  RouterExtensions,
  WindowService,
  ApiSessionService
];

export * from "../../../services/identity.service";
export * from "../../../services/console/console.service";
export * from "../../../services//logging/log.service";
export * from "../../../services/router-extensions.service";
export * from "../../../services/window/window.service";
