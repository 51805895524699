<div class="mat-dialog-container mat-typography">
  <h2 class="mat-dialog-title">Configure Queue Email Alert Rules</h2>
  <div class="mat-dialog-content">
    @for (warning of (queueWarnings$ | async); track warning;) {
    <onsip-queue-warnings-card [warning]="warning"></onsip-queue-warnings-card>
    }
  </div>
  <div class="mat-dialog-actions">
    <button mat-raised-button color="primary" (click)="openNewAlert()">New Time Alert</button>
  </div>
</div>
