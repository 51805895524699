import { onsipApiArrayToArray, OnsipAPIArray } from "./xml-json";
import { AddressType, AppType } from "../apiResponseHelpers";

export declare interface ApiUserSummaryAddress {
  Address: string;
  Locations?: OnsipAPIArray<"Location", string>;
  Statuses: string;
}

// NoAuthUserSummary uses a different address object
export declare interface ApiNoAuthUserSummaryAddress {
  Address: string;
  Domain: string;
  Username: string;
}

// Failover address object
export declare interface ApiFailoverAddress {
  /** Display name associated with the SIP address. */
  Name: string;
  /** User portion of the SIP address. */
  Username: string;
  /** Domain portion of the SIP address. */
  Domain: string;
  /** AddressId of the SIP address */
  AddressId: string;
  Type: AddressType | AppType;
}

export declare interface ApiFullAddress extends ApiFailoverAddress {
  Modified: string;
  Created: string;
  CplText: string;
}

export declare interface UserSummaryAddress {
  address: string;
  locations?: Array<string>;
  statuses: string;
}

// NoAuthUserSummary uses a different address object
export declare interface NoAuthUserSummaryAddress {
  address: string;
  domain: string;
  username: string;
}

// Failover address object
export declare interface FailoverAddress {
  /** Display name associated with the SIP address. */
  name: string;
  /** User portion of the SIP address. */
  username: string;
  /** Domain portion of the SIP address. */
  domain: string;
  /** AddressId of the SIP address */
  addressId: string;
  type: AddressType | AppType;
}

export declare interface FullAddress extends FailoverAddress {
  cplText: string;
}

export function apiFailoverAddressToAddress(
  apiFailoverAddress: ApiFailoverAddress
): FailoverAddress {
  return {
    name: apiFailoverAddress.Name,
    username: apiFailoverAddress.Username,
    domain: apiFailoverAddress.Domain,
    addressId: apiFailoverAddress.AddressId,
    type: apiFailoverAddress.Type
  };
}

export function apiFullAddressToAddress(apiFullAddress: ApiFullAddress): FullAddress {
  return {
    ...apiFailoverAddressToAddress(apiFullAddress),
    cplText: apiFullAddress.CplText
  };
}

export function apiUserSummaryAddressToAddress(
  apiUserSummaryAddress: ApiUserSummaryAddress
): UserSummaryAddress {
  return {
    address: apiUserSummaryAddress.Address,
    locations: apiUserSummaryAddress.Locations
      ? onsipApiArrayToArray(apiUserSummaryAddress.Locations, "Location")
      : undefined,
    statuses: apiUserSummaryAddress.Statuses
  };
}

export function apiNoAuthUserSummaryAddressToAddress(
  apiNoAuthUserSummaryAddress: ApiNoAuthUserSummaryAddress
): NoAuthUserSummaryAddress {
  return {
    address: apiNoAuthUserSummaryAddress.Address,
    domain: apiNoAuthUserSummaryAddress.Domain,
    username: apiNoAuthUserSummaryAddress.Username
  };
}
