<div [class.round-look]="data.roundLook" [attr.data-cy]="E2eLocators.DIALOG_CONTENT">
  @if (!data.noSentenceCaseTitle && (data.message ? data.message.length : 0) <= 60) {
  <h2 mat-dialog-title class="modal-title" style="text-align: center">
    {{ data.title | sentenceCase }}
  </h2>
  } @if (data.noSentenceCaseTitle && (data.message ? data.message.length : 0) <= 60) {
  <h2 mat-dialog-title class="modal-title" style="text-align: center">
    {{ data.title }}
  </h2>
  } @if (!data.noSentenceCaseTitle && (data.message ? data.message.length : 0) > 60) {
  <h2 mat-dialog-title class="modal-title">
    {{ data.title | sentenceCase }}
  </h2>
  } @if (data.noSentenceCaseTitle && (data.message ? data.message.length : 0) > 60) {
  <h2 mat-dialog-title class="modal-title">
    {{ data.title }}
  </h2>
  } @if (data.showFastPass) {
  <div mat-dialog-content style="overflow: hidden">
    @if (data.component) {
    <div>
      <ng-container *ngComponentOutlet="data.component"></ng-container>
    </div>
    } @if (data.message && data.message.length <= 60) {
    <div style="text-align: center">
      <p [innerHtml]="data.message"></p>
    </div>
    } @if (data.message && data.message.length > 60) {
    <div>
      <p [innerHtml]="data.message"></p>
    </div>
    } @if (data.showFastPass) {
    <div class="modal-checkbox-container">
      <mat-checkbox color="primary" [(ngModel)]="response.fastPass"></mat-checkbox>
      <span class="modal-checkbox-label">{{
        "ONSIP_I18N.DONT_SHOW_ME_THIS_MESSAGE_AGAIN" | translate
      }}</span>
    </div>
    }
  </div>
  } @if (!data.showFastPass) {
  <div mat-dialog-content>
    @if (data.component) {
    <div>
      <ng-container *ngComponentOutlet="data.component"></ng-container>
    </div>
    } @if (data.message && data.message.length <= 60) {
    <div style="text-align: center">
      <p [innerHtml]="data.message"></p>
    </div>
    } @if (data.message && data.message.length > 60) {
    <div>
      <p [innerHtml]="data.message"></p>
    </div>
    } @if (data.showFastPass) {
    <div class="modal-checkbox-container">
      <mat-checkbox color="primary" [(ngModel)]="response.fastPass"></mat-checkbox>
      <span class="modal-checkbox-label">{{
        "ONSIP_I18N.DONT_SHOW_ME_THIS_MESSAGE_AGAIN" | translate
      }}</span>
    </div>
    }
  </div>
  }
  <div mat-dialog-actions class="modal-actions">
    @if (!data.showOnlyPrimaryBtn) {
    <button
      id="onsip--modal-material--generic-modal-cancel"
      [attr.data-cy]="E2eLocators.MODAL_CONFIRM_NO"
      mat-button
      [color]="data.secondaryBtnColor || 'primary'"
      (click)="response.doPrimaryAction = false"
      [mat-dialog-close]="response"
    >
      {{ data.secondaryBtnText || ("ONSIP_I18N.CANCEL" | translate) | sentenceCase }}
    </button>
    } @if (data.primaryBtnFlat && !data.noSentenceCasePrimaryBtn) {
    <button
      [attr.data-cy]="E2eLocators.MODAL_CONFIRM_YES"
      id="onsip--modal-material--generic-modal-accept"
      mat-flat-button
      [color]="data.primaryBtnColor || 'primary'"
      (click)="response.doPrimaryAction = true"
      [mat-dialog-close]="response"
      cdkFocusInitial
    >
      {{ data.primaryBtnText || ("ONSIP_I18N.ACCEPT" | translate) | sentenceCase }}
    </button>
    } @if (data.primaryBtnFlat && data.noSentenceCasePrimaryBtn) {
    <button
      [attr.data-cy]="E2eLocators.MODAL_CONFIRM_YES"
      id="onsip--modal-material--generic-modal-accept"
      mat-flat-button
      [color]="data.primaryBtnColor || 'primary'"
      (click)="response.doPrimaryAction = true"
      [mat-dialog-close]="response"
      cdkFocusInitial
    >
      {{ data.primaryBtnText || ("ONSIP_I18N.ACCEPT" | translate) }}
    </button>
    } @if (!data.primaryBtnFlat && !data.noSentenceCasePrimaryBtn) {
    <button
      [attr.data-cy]="E2eLocators.MODAL_CONFIRM_YES"
      id="onsip--modal-material--generic-modal-accept"
      mat-button
      [color]="data.primaryBtnColor || 'primary'"
      (click)="response.doPrimaryAction = true"
      [mat-dialog-close]="response"
      cdkFocusInitial
    >
      {{ data.primaryBtnText || ("ONSIP_I18N.ACCEPT" | translate) | sentenceCase }}
    </button>
    } @if (!data.primaryBtnFlat && data.noSentenceCasePrimaryBtn) {
    <button
      [attr.data-cy]="E2eLocators.MODAL_CONFIRM_YES"
      id="onsip--modal-material--generic-modal-accept"
      mat-button
      [color]="data.primaryBtnColor || 'primary'"
      (click)="response.doPrimaryAction = true"
      [mat-dialog-close]="response"
      cdkFocusInitial
    >
      {{ data.primaryBtnText || ("ONSIP_I18N.ACCEPT" | translate) }}
    </button>
    }
  </div>
</div>
