<div class="header">
  <div class="mat-headline-6 accounts-title">Accounts</div>
  <div>
    <a mat-button color="primary" href="https://support.onsip.com" target="_blank">Knowledgebase</a>
  </div>
</div>
<div class="search-create-btn-container onsip-grid-placement">
  <mat-form-field appearance="outline" class="search-field">
    <input matInput [formControl]="search" autocomplete="off" />
    <mat-icon matSuffix color="primary">search</mat-icon>
  </mat-form-field>
  @if (!isSubAgent) {
  <div class="create-container">
    <button
      class="create-new-acct-btn"
      mat-flat-button
      [routerLink]="views.AGENT_CREATE_ACCOUNT"
      color="primary"
    >
      Create new account
    </button>
  </div>
  }
</div>
<section class="table-container">
  <onsip-table-two
    class="agent-account-table"
    matSort
    multiTemplateDataRows
    [dataSource]="dataSource"
    [trackBy]="trackByAccountId"
    [ngClass]="getSubAgentClass()"
    [useGrid]="true"
    [hoverable]="true"
  >
    <!-- ACCOUNTID -->
    <ng-container matColumnDef="accountId" [sticky]="true">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ACCT ID</mat-header-cell>
      <mat-cell *matCellDef="let account" class="id-cell">
        @if (!isSubAgent) {
        <span
          class="account-cell-content account-id-spoof"
          (click)="spoofAccount(account, $event)"
          >{{ account.accountId }}</span
        >
        } @if (isSubAgent) {
        <span
          class="account-cell-content account-id-spoof"
          (click)="spoofAccount(account, $event)"
          >{{ account.subAccountId }}</span
        >
        }
      </mat-cell>
    </ng-container>
    <!-- SUBAGENT -->
    @if (!isSubAgent) {
    <ng-container matColumnDef="subAgent" class="subagent-column">
      <mat-header-cell *matHeaderCellDef mat-sort-header>SUB-AGENT</mat-header-cell>
      <mat-cell *matCellDef="let account">
        <span class="account-cell-content">{{ account.subAgentName || "n/a" }}</span>
      </mat-cell>
    </ng-container>
    }
    <!-- CONTACT NAME -->
    <ng-container matColumnDef="contactName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>CONTACT NAME</mat-header-cell>
      <mat-cell *matCellDef="let account">
        @if (!isSubAgent) {
        <span class="account-cell-content">{{ account.contactName }}</span>
        } @if (isSubAgent) {
        <span class="account-cell-content">{{ account.subAccountContactName }}</span>
        }
      </mat-cell>
    </ng-container>
    <!-- ACCOUNT NAME-->
    <ng-container matColumnDef="accountName">
      <mat-header-cell *matHeaderCellDef mat-sort-header>ACCOUNT NAME</mat-header-cell>
      <mat-cell *matCellDef="let account">
        @if (!isSubAgent) {
        <span class="account-cell-content">{{ account.contactOrganization }}</span>
        } @if (isSubAgent) {
        <span class="account-cell-content">{{ account.subAccountContactOrganization }}</span>
        }
      </mat-cell>
    </ng-container>
    <!-- CREDIT LIMIT -->
    <ng-container matColumnDef="creditLimit">
      <mat-header-cell *matHeaderCellDef mat-sort-header>CREDIT LIMIT</mat-header-cell>
      <mat-cell *matCellDef="let account">
        @if (!isSubAgent) {
        <span class="account-cell-content">{{ currencyFormatter(account.creditLimit) }}</span>
        } @if (isSubAgent) {
        <span class="account-cell-content">{{
          currencyFormatter(account.subAccountCreditLimit)
        }}</span>
        }
      </mat-cell>
    </ng-container>
    <!-- PREPAID BAL -->
    <ng-container matColumnDef="prepaidBal">
      <mat-header-cell *matHeaderCellDef mat-sort-header>PREPAID BAL</mat-header-cell>
      <mat-cell *matCellDef="let account">
        @if (!isSubAgent) {
        <span class="account-cell-content">{{ currencyFormatter(account.balance) }}</span>
        } @if (isSubAgent) {
        <span class="account-cell-content">{{ currencyFormatter(account.subAccountBalance) }}</span>
        }
      </mat-cell>
    </ng-container>
    <!-- INVOICE BAL -->
    <ng-container matColumnDef="invoiceBal">
      <mat-header-cell *matHeaderCellDef mat-sort-header>INVOICE BAL</mat-header-cell>
      <mat-cell *matCellDef="let account">
        @if (!isSubAgent) {
        <span class="account-cell-content">{{ currencyFormatter(account.receivable) }}</span>
        } @if (isSubAgent) {
        <span class="account-cell-content">{{
          currencyFormatter(account.subAccountReceivable)
        }}</span>
        }
      </mat-cell>
    </ng-container>
    <!-- ACTIONS -->
    @if (!isSubAgent) {
    <ng-container matColumnDef="actions" class="actions-column" [stickyEnd]="true">
      <mat-header-cell *matHeaderCellDef class="actions-cell"></mat-header-cell>
      <mat-cell *matCellDef="let account" class="actions-cell">
        <mat-icon color="primary" class="expand-button">expand_more</mat-icon>
      </mat-cell>
    </ng-container>
    }
    <!-- SHELF -->
    @if (!isSubAgent) {
    <ng-container matColumnDef="expanded">
      <onsip-table-shelf-cell
        *matCellDef="let account"
        [attr.colspan]="displayedColumns.length"
        [expanded]="account.accountId === selectedRow?.accountId"
      >
        @if (account?.accountId === selectedRow?.accountId) {
        <onsip-agent-accounts-shelf
          [account]="account"
          [agentId]="agentId"
          [allAccounts]="allAccounts"
        ></onsip-agent-accounts-shelf>
        }
      </onsip-table-shelf-cell>
    </ng-container>
    }
    <!-- ROWS -->
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      (click)="selectedRow = selectedRow === row ? null : row"
      [class.selected]="selectedRow === row"
    >
    </mat-row>
    @if (!isSubAgent) {
    <div>
      <onsip-table-shelf *matRowDef="let row; columns: ['expanded']"></onsip-table-shelf>
    </div>
    }
    <div *matNoDataRow class="no-data-row">
      <span> No accounts </span>
    </div>
  </onsip-table-two>
</section>
<mat-paginator
  onsip-paginator-primary-icon
  showFirstLastButtons
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
>
</mat-paginator>
