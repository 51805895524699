import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Config } from "@onsip/common/config";
import { views } from "@onsip/web/app/phone/views";
import { Observable, Subscription } from "rxjs";
import { filter, map, mergeMap } from "rxjs/operators";
import { ShellNavigationWithLocator } from "@onsip/web/features/shared/components/shellNavigations/shell-navigations.component";
import { MatSidenavContent } from "@angular/material/sidenav";
import { MatSidenav } from "@angular/material/sidenav";
import { OrganizationService } from "@onsip/common/services/api/resources/organization/organization.service";
import {
  Account,
  AccountService
} from "@onsip/common/services/api/resources/account/account.service";
import { InitPhoneService } from "../phone/init-phone.service";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { SuperAdminNavigationService } from "@onsip/common/services/super-admin-navigation.service";
import { isSubAgentAdmin, Role } from "@onsip/common/services/api/role";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { AgentOrganizationService } from "./agent-organization.service";

@Component({
  selector: "onsip-agent-admin-view",
  templateUrl: "./agent-admin-view.component.html",
  styleUrls: ["./agent-admin-view.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgentAdminViewComponent implements OnInit, OnDestroy {
  @ViewChild("mainContent") mainContent!: MatSidenavContent;
  @ViewChild("sidenav") sidenav!: MatSidenav;
  views = views;
  debug = /localhost/.test(location.host);
  isDesktop = Config.IS_DESKTOP;
  isSmallOrXs = false;
  detailsExpanded = false;
  useSmallIcon!: Observable<boolean>;
  agentOrgName!: string;
  currOrgName!: string;
  agentAccount!: Account;
  isSubAgent = false;
  domain!: string;
  hasParentUserId = false;
  parentUserRole: Role | undefined;

  shellNavigations: Array<ShellNavigationWithLocator> = [];

  private unsubscriber = new Subscription();

  constructor(
    public initPhoneService: InitPhoneService,
    private elRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private orgService: OrganizationService,
    private accountService: AccountService,
    private userService: UserService,
    private cdRef: ChangeDetectorRef,
    private apiSessionService: ApiSessionService,
    private superAdminNavService: SuperAdminNavigationService,
    private agentOrgService: AgentOrganizationService
  ) {}

  // copy from phone component
  // NB keydown happens just before keypress
  @HostListener("window:keypress", ["$event"]) onKeyPress(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  @HostListener("window:keydown", ["$event"]) onKeyDown(event: KeyboardEvent): void {
    this.dontBackspace(event);
    this.windowsHotkeys(event);
  }

  ngOnInit(): void {
    this.initTitle();
    this.checkIfSubAgent();
    this.getAgentInfo();
    this.initBreakpoints();
    this.initRouteToAccounts();

    this.unsubscriber.add(
      this.apiSessionService.state.subscribe(state => {
        this.hasParentUserId = !!state.parentUserId;
      })
    );

    this.unsubscriber.add(
      this.superAdminNavService.getParentUserRole().subscribe(role => {
        this.parentUserRole = role;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  toggleExpandDetails(): void {
    this.detailsExpanded = !this.detailsExpanded;
    this.cdRef.markForCheck();
  }

  backToAdmin(): void {
    this.superAdminNavService.goBackToAdminViewNav();
  }

  private initTitle(): void {
    document.title = "OnSIP - Agent View";
    this.unsubscriber.add(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => this.activatedRoute),
          map(route => {
            while (route.firstChild) route = route.firstChild;
            return route;
          }),
          filter(route => route.outlet === "primary"),
          mergeMap(route => route.data)
        )
        .subscribe(event => {
          const title: string | undefined = event.title;
          if (title && title.length) {
            // some pages set their own titles
            document.title = "OnSIP - " + title;
          }
        })
    );
  }

  private checkIfSubAgent(): void {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.isSubAgent = isSubAgentAdmin(user.roles);
        this.shellNavigations = this.isSubAgent
          ? [
              {
                name: "Accounts",
                url: this.views.AGENT_ACCOUNTS
              },
              {
                name: "Support",
                url: this.views.AGENT_SUPPORT
              }
            ]
          : [
              {
                name: "Accounts",
                url: this.views.AGENT_ACCOUNTS
              },
              {
                name: "Commission",
                url: this.views.AGENT_COMMISSIONS
              },
              {
                name: "Support",
                url: this.views.AGENT_SUPPORT
              }
            ];
        this.cdRef.markForCheck();
      })
    );
  }

  private getAgentInfo(): void {
    this.unsubscriber.add(
      this.orgService.orgState.subscribe(orgs => {
        const currentOrg = orgs[0];
        this.domain = currentOrg.domain;
        this.currOrgName = currentOrg.contact.organization;
      })
    );

    this.unsubscriber.add(
      this.accountService.accountState.subscribe(accounts => {
        this.agentAccount = accounts[0];
      })
    );

    this.unsubscriber.add(
      this.agentOrgService.agentOrg.subscribe(agentOrg => {
        this.agentOrgName = agentOrg?.contact.organization || "";
      })
    );
  }

  private initBreakpoints(): void {
    this.unsubscriber.add(
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).subscribe(result => {
        this.isSmallOrXs = result.matches;
      })
    );
    this.useSmallIcon = this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(map(breakpoints => breakpoints.matches));
  }

  private initRouteToAccounts(): void {
    this.router.navigate([this.views.AGENT_ACCOUNTS]);
  }

  private dontBackspace(e: any): void {
    // prevent backspace from leaving page
    if (8 !== e.which) {
      return;
    }

    if (!this.textFieldFocused()) {
      e.preventDefault();
    }
  }

  private textFieldFocused(): boolean {
    const focused: any = this.elRef.nativeElement.ownerDocument.activeElement;
    return /text|textarea|password|number/i.test(focused.type);
  }

  private windowsHotkeys(event: any): void {
    if (event.ctrlKey === true && Config.IS_DESKTOP) {
      // r key pressed
      if (event.keyCode === 82) {
        window.electron.sendMessage("relaunch-function");
      } else if (event.keyCode === 81) {
        window.electron.sendMessage("app-quit");
      }
    }
  }
}
