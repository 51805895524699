<div [class.mobile]="isMobile" class="audio-container">
  <audio
    #messageAudio
    class="voicemail-message"
    [attr.src]="sanitizedAudioFile ? sanitizedAudioFile : ''"
    id="voicemail__message-{{voicemailMessage &amp;&amp; voicemailMessage.datetime}}"
    [ngClass]="{ 'voicemail-message-mozilla': mozilla !== undefined }"
    [controls]="
      !(supportService.browser.name === 'safari' || supportService.browser.name === 'ios')
    "
    controlsList="nodownload"
  ></audio>
</div>
<div class="voicemail-download-button" [class.mobile]="isMobile">
  <a
    id="voicemail__message-dl-{{ voicemailMessage && voicemailMessage.datetime }}"
    [href]="sanitizedAudioFile ? sanitizedAudioFile : ''"
    download="voicemail-{{
      voicemailMessage && voicemailMessage.datetime + '-' + voicemailMessage.fromName
    }}"
  >
    <mat-icon class="voicemail-download-icon" aria-label="save_alt">save_alt</mat-icon>
  </a>
</div>
