import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HighchartsChartModule } from "highcharts-angular";
import { ChartingBarGraphComponent } from "./bar-graph.component";

@NgModule({
  imports: [CommonModule, HighchartsChartModule],
  declarations: [ChartingBarGraphComponent],
  exports: [ChartingBarGraphComponent]
})
export class ChartingBarGraphModule {}
