import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { PhoneGuard } from "@onsip/web/features/shared/guards/phoneGuard.guard";

import { PrephoneGuard } from "../../features/shared/components/prephone/prephone.guard";

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: "", pathMatch: "full", redirectTo: "login" },
        { path: "**", canActivate: [PhoneGuard, PrephoneGuard], children: [] }
      ],
      {
        useHash: true,
        onSameUrlNavigation: "reload",
        preloadingStrategy: PreloadAllModules
      }
    )
  ],
  exports: [RouterModule]
})
export class PhoneAppRoutingModule {}
