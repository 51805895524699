import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class DateService {
  displayDate(date: Date): string {
    const days = ["Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat."];
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    const midnightYesterday = new Date(
      midnight.getFullYear(),
      midnight.getMonth(),
      midnight.getDate() - 1,
      0,
      0,
      0,
      0
    );
    const midnightLastWeek = new Date(
      midnight.getFullYear(),
      midnight.getMonth(),
      midnight.getDate() - 7,
      0,
      0,
      0,
      0
    );

    if (date >= midnight) {
      return "Today";
    } else if (date >= midnightYesterday) {
      return "Yesterday";
    } else if (date >= midnightLastWeek) {
      return days[date.getDay()];
    } else {
      return date.toLocaleDateString();
    }
  }
}
