@if (warningBarService.state | async; as warningBarState) { @if (warningBarState.length > 0) {
<div
  [ngClass]="{
    'accent-50-bg': warningBarState[0].bgColor === 'accent',
    'primary-50-bg': warningBarState[0].bgColor === 'primary',
    'warn-50-bg': warningBarState[0].bgColor === undefined || warningBarState[0].bgColor === 'warn'
  }"
  class="warning-bar mat-typography"
>
  <!-- if there are warning states, display the first one as it is the highest priority -->
  <div class="warning-message">
    <!-- need ngif because mat-icon will still build out the containers despite the empty string -->
    @if (warningBarState[0].displayIcon) {
    <mat-icon
      [color]="warningBarState[0].bgColor !== undefined ? warningBarState[0].bgColor : 'warn'"
    >
      {{ warningBarState[0].displayIcon }}
    </mat-icon>
    }
    <span>{{ warningBarState[0].i18nMessage | translate }}</span>
  </div>
  <div class="action-buttons">
    <button mat-button id="onsip-warning-bar-hide" color="primary" (click)="hideBanner()">
      {{ warningBarState[0].hideButtonText || "ONSIP_I18N.HIDE" | translate | uppercase }}
    </button>
    @if (warningBarState[0].canEnable) {
    <button mat-button id="onsip-warning-bar-turn-off" color="primary" (click)="revertState()">
      {{ warningBarState[0].buttonText! | translate | uppercase }}
    </button>
    }
  </div>
</div>
} @if (warningBarState.length > 1) {
<div class="multiple-warning-bar mat-typography warn-A100-bg">
  <button mat-button id="onsip--warning-bar--dismiss-all" color="primary" (click)="hideAllOthers()">
    <span>{{ "ONSIP_I18N.HIDE" | translate }} </span>
    <span>({{ warningBarState.length - 1 }}) </span>
    @if (warningBarState.length > 2) {
    <span>{{ "ONSIP_I18N.OTHER_ALERTS" | translate }}</span>
    } @else {
    <span>{{ "ONSIP_I18N.OTHER_ALERT" | translate }}</span>
    }
  </button>
</div>
} }
