import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { filter, map, startWith } from "rxjs";
import { E164PhoneNumber } from "@onsip/common/libraries/e164-phone-number";
import { UserSummaryContactWithAddresses } from "@onsip/common/services/api/resources/userSummaryContact/user-summary-contact";
import { OnSIPURI } from "@onsip/common/libraries/onsip-uri";
import { AppsettingsService } from "../appsettings.service";

@Component({
  selector: "onsip-account-details",
  templateUrl: "./account-details.component.html",
  styleUrl: "./account-details.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountDetailsComponent {
  userService = inject(UserService);
  appsettingsService = inject(AppsettingsService);

  e911Enabled$ = this.userService.selfUser.pipe(map(res => res.e911Provisioning));
  email$ = this.userService.selfUser.pipe(map(res => res.contact.email));
  accountId$ = this.userService.selfUser.pipe(map(res => res.accountId));

  phoneNumbers$ = this.appsettingsService.loadProfile$.pipe(
    filter(res => !!res),
    map(res => this.getPhoneNumbers(res as UserSummaryContactWithAddresses))
  );

  extensions$ = this.appsettingsService.loadProfile$.pipe(
    filter(res => !!res),
    map(res => this.getExtensions(res as UserSummaryContactWithAddresses))
  );

  sipAddress$ = this.appsettingsService.loadProfile$.pipe(
    filter(res => !!res),
    map(res => res?.sipAddress as string),
    startWith("")
  );

  private getExtensions(userSummary: UserSummaryContactWithAddresses) {
    const extensions: Array<string> = [];
    const allAliases = userSummary.aliases || [];
    allAliases.forEach(alias => {
      if (!alias.startsWith("sip:")) {
        alias = "sip:" + alias + "@" + userSummary.domain;
      }

      const uri: OnSIPURI | undefined = OnSIPURI.parseString(alias);
      if (uri && uri.user && uri.isExtension()) {
        extensions.push(uri.user);
      }
    });

    return extensions;
  }

  private getPhoneNumbers(userSummary: UserSummaryContactWithAddresses) {
    const phoneNumbers: Array<string> = [];
    const e164Aliases = userSummary.e164Aliases || [];
    e164Aliases.forEach(e164Alias => {
      const parsedE164Num = new E164PhoneNumber(e164Alias);
      if (parsedE164Num.isValid) {
        const phoneNumber = parsedE164Num.e164DisplayNumber;
        phoneNumbers.push(phoneNumber);
      }
    });

    const contactPhone = userSummary.contactPhone || "";
    const e164Num = new E164PhoneNumber(contactPhone);
    if (e164Num.isValid) {
      const phoneNumber = e164Num.e164DisplayNumber;
      if (!phoneNumbers.some(num => num === phoneNumber)) {
        phoneNumbers.push(phoneNumber);
      }
    }

    return phoneNumbers;
  }
}
