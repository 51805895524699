import { Injectable } from "@angular/core";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { accountId } from "../../apiParams/account-id";
import { sessionId } from "../../apiParams/session-id";
import { OnsipApiResponse } from "../../apiResponse/response-body-new";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import {
  ZendeskGetTickets,
  apiZendeskGetTicketsToZendeskGetTickets as clean
} from "./zendesk-get-tickets";

const debug = false;

@Injectable({ providedIn: "root" })
export class ZendeskGetTicketsService extends ApiResourceService<ZendeskGetTickets> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "ZendeskGetTickets", "id");
    debug && this.state.subscribe(state => console.warn("ZendeskGetTickets", state));
  }

  zendeskGetTickets(): ApiPromiseState<ZendeskGetTickets> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.ZendeskGetTickets,
        SessionId: this.store.state.pipe(sessionId()),
        AccountId: this.store.state.pipe(accountId())
      }
    });
    return this.promiseState.toPromise(ApiAction.ZendeskGetTickets);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.ZendeskGetTickets:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(this.getApiData(action, response), this.indexKeyName),
          action
        );
        break;
    }
  }

  // built in extract data does not handle triple nested objects so need to do this manually
  private getApiData(action: string, response: OnsipApiResponse): Array<ZendeskGetTickets> {
    const apiResult = response.Result as any;
    const apiData = apiResult[action]["ZendeskTickets"];
    const apiArray: any = Object.values(onsipApiArrayToArray(apiData, "ZendeskTicket"));
    return apiArray.map(clean);
  }
}
