import { Pipe, PipeTransform } from "@angular/core";

/** default initials (or initials for nameless contacts) for default avatars */
const DEFAULT_NAME = "- -";

@Pipe({
  name: "initials"
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string | undefined): string {
    if (!fullName) return DEFAULT_NAME;
    // regex pattern selects the first char of the first word and the last char of the last word, in the case of one word selects the first and last char
    // "joe smith" -> "js"
    // "alex bob carol dennis" -> "ad"
    // "joe" -> "je"
    return fullName.replace(/^(\S)\S*.*?\s?(\S)\S*$/g, "$1$2").toLocaleUpperCase();
  }
}
