import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Role } from "@onsip/common/services/api/role";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { views } from "@onsip/web/app/phone/views";
import { Config } from "@onsip/common/config";

/** Guard for agents and subagent while spoofing. This will bounce agents and subagents away from viewing PBX components and only see admin portal related pages */
export const AgentSpoofingGuard = () => {
  const router = inject(Router);
  const apiSession = inject(ApiSessionService);

  if (Config.IS_DESKTOP) return true;
  if (apiSession.isLoggedIn()) {
    return apiSession.state.pipe(
      map(apiSession => {
        const canAccess = !apiSession.parentUserId || apiSession.loggedInRole === Role.SuperUser;
        if (canAccess) {
          return true;
        } else if (apiSession.parentUserId) {
          router.navigate([views.ADMIN_PBX_OVERVIEW]);
          return false;
        } else {
          router.navigate([views.HOME]);
          return false;
        }
      })
    );
  } else {
    router.navigate(["/login"]);
    return false;
  }
};
