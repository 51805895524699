import {
  Document,
  timestampSetData,
  timestampUpdateData,
  propertyRemove
} from "../cloud/firebase/firestore/document";
import { OrgData, OrgState, makeOrgState } from "./org-state";

export function makeDocumentPath(oid: number): string {
  return `orgs/${oid}`;
}

/**
 * Org class.
 */
export class Org extends Document<OrgState> {
  /**
   * Gets the state of an Org.
   * @param oid Organization Id.
   */
  static get(oid: number): Promise<OrgState | undefined> {
    return this.getDocument(makeDocumentPath(oid), makeOrgState);
  }

  /**
   * Merges Org data.
   * @param oid Organization Id.
   * @param data Org data.
   */
  static merge(oid: number, data: Partial<OrgData>): Promise<void> {
    return this.mergeDocument(makeDocumentPath(oid), timestampUpdateData(data));
  }

  /**
   * Sets a Org in an Org
   * @param oid Organization Id.
   * @param data Org data.
   */
  static set(oid: number, data: OrgData): Promise<void> {
    return this.setDocument(makeDocumentPath(oid), timestampSetData(data));
  }

  /**
   * Updates a Org in an Org
   * @param oid Organization Id.
   * @param data Org data.
   */
  static update(oid: number, data: Partial<OrgData>): Promise<void> {
    return this.updateDocument(makeDocumentPath(oid), timestampUpdateData(data));
  }

  /**
   * Updates a Org in an Org
   * @param oid Organization Id.
   * @param setData Org data.
   * @param updateData Org data.
   */
  static setOrUpdate(oid: number, setData: OrgData, updateData: Partial<OrgData>): Promise<void> {
    return this.setOrUpdateDocument(
      makeDocumentPath(oid),
      timestampSetData(setData),
      timestampUpdateData(updateData)
    );
  }

  /**
   * Adds a topic to an Org. Overwites existing.
   * @param oid Organization Id.
   * @param uids User Ids
   * @param topic The topic to merge.
   */
  static topicAdd(oid: number, topic: string, uids: Array<string>): Promise<void> {
    const data = { topics: { [topic]: uids } };
    return this.mergeDocument(makeDocumentPath(oid), timestampUpdateData(data));
  }

  /**
   * Remove a topic from an Org.
   * @param oid Organization Id.
   * @param topic The topic to remove.
   */
  static topicRemove(oid: number, topic: string): Promise<void> {
    const data = { ["topics." + topic]: propertyRemove() };
    return this.updateDocument(makeDocumentPath(oid), timestampUpdateData(data));
  }

  /**
   * Constructor
   * @param state Initial Org state.
   */
  constructor(state: OrgState = makeOrgState("", {})) {
    super(state);
  }

  /**
   * Disposes.
   */
  dispose() {
    super.dispose();
  }

  /**
   * Starts listening for updates to Org.
   * @param oid Organization Id.
   * @param uid Org Id.
   */
  start(oid: number): void {
    this.startListening(makeDocumentPath(oid), makeOrgState);
  }

  /**
   * Stops listening.
   */
  stop(): void {
    this.stopListening();
  }
}
