import { Component, ChangeDetectionStrategy } from "@angular/core";

import { WarningBarService } from "./warning-bar.service";

@Component({
  selector: "onsip-warning-bar",
  templateUrl: "./warning-bar.component.html",
  styleUrls: ["./warning-bar.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WarningBarComponent {
  constructor(public warningBarService: WarningBarService) {}

  hideBanner(): void {
    this.warningBarService.removeCurrentWarningState();
  }

  hideAllOthers(): void {
    this.warningBarService.removeAllOthers();
  }

  revertState(): void {
    this.warningBarService.revertState();
  }
}
