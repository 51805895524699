import { Config } from "../../../common/config";
import { views } from "../../app/phone/views";

import { Component, OnInit, HostBinding, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

import { CustomIconRegistryService } from "../../features/shared/services/customIconRegistry/custom-icon-registry.service";
import { filter, take } from "rxjs/operators";
import { ApiSessionService } from "../../../common/services/api/api-session.service";
import {
  INTERNAL_SERVER_ERROR,
  serverErrorSubject
} from "@onsip/common/services/api/onsip-api-action-new";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { ModalMaterialComponent } from "@onsip/web/features/shared/components/modal/modal-material.component";

@Component({
  selector: "onsip-app",
  templateUrl: "./onsip-app.component.html"
})
export class OnsipAppComponent implements OnInit, OnDestroy {
  @HostBinding("class.mat-typography")
  _dontUse = true;

  private unsubscriber = new Subscription();

  constructor(
    private snackbar: SnackbarService,
    private router: Router,
    private apiSession: ApiSessionService,
    private translate: TranslateService,
    private dialog: MatDialog,
    // @ts-ignore: noUnusedLocals
    private customIconRegistryService: CustomIconRegistryService,
    private route: ActivatedRoute
  ) {}

  platformOnlineListener() {
    if (Config.IS_DESKTOP) {
      window.addEventListener("online", () => {
        window.electron.sendMessage("offline-to-online");
      });
    }
  }

  ngOnInit() {
    this.unsubscriber.add(
      serverErrorSubject.pipe(filter(value => value === INTERNAL_SERVER_ERROR)).subscribe(() => {
        const redirectUrl = location.origin + location.pathname;
        this.dialog
          .open(ModalMaterialComponent, {
            panelClass: ["mat-typography", "onsip-dialog-universal-style"],
            data: {
              title: "An error has occurred",
              message: `OnSIP has been notified of this issue and will be looking into it immediately. Please <a href='${redirectUrl}#/contact-support'>Contact Support</a> for further assistance.`,
              primaryBtnFlat: true,
              primaryBtnText: "OK",
              showOnlyPrimaryBtn: true,
              noSentenceCasePrimaryBtn: true
            },
            maxWidth: "292px"
          })
          .afterClosed()
          .pipe(take(1))
          // it is a take 1 so there will be no side effect
          // eslint-disable-next-line rxjs-angular/prefer-composition
          .subscribe(retObj => {
            if (retObj && retObj.doPrimaryAction) {
              serverErrorSubject.next("");
            }
          });
      })
    );
    this.unsubscriber.add(
      this.apiSession.state
        .pipe(
          filter(state => state.loggedIn),
          take(1)
        )
        .subscribe(() => {
          this.snackbar.dismiss();

          const fromOnboarding: string | null =
            this.route.snapshot.queryParamMap.get("from-onboarding");
          const returnUrl = fromOnboarding
            ? views.HOME
            : this.route.snapshot.queryParamMap.get("returnUrl") || views.HOME;

          this.router.navigateByUrl(returnUrl).then(() => {
            if (fromOnboarding) {
              this.router.navigate([returnUrl], {
                queryParamsHandling: undefined,
                skipLocationChange: true,
                queryParams: {
                  onboardingSuccess: 1
                }
              });
            }
          });
        })
    );

    this.apiSession.useOldLogin();

    this.platformOnlineListener();

    this.translate.setDefaultLang("en");
    this.translate.use(this.translate.getBrowserLang() || "en");
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
