import { StateEmitter } from "../../../../../common/libraries/emitter/state-emitter";
import { Config } from "../../../../../common/config";
import { LogService } from "../../../../../common/services/logging/log.service";

import { SupportService } from "../../../../../common/services/support/support.service";

import { Injectable } from "@angular/core";

export interface GumState {
  warningMessage?: string;
}

interface GumConstraints {
  audio: boolean;
  video: boolean;
}

@Injectable({ providedIn: "root" })
export class GumService extends StateEmitter<GumState> {
  private isPending = false;
  private gumDelayId: any;
  private browserSettings: GumConstraints = {
    audio: false,
    video: false
  };
  private gumPromise: Promise<MediaStream> | undefined;

  private static initialState(): any {
    return {};
  }

  constructor(private supportService: SupportService, private log: LogService) {
    super(GumService.initialState());

    if (this.supportService.getBrowser().name === "chrome") {
      try {
        this.browserSettings.audio = localStorage.getItem("audio") ? true : false;
        this.browserSettings.video = localStorage.getItem("video") ? true : false;
      } catch (e) {
        // ignore
      }
    }
  }

  getUserMedia(constraints: any, options: any = {}): Promise<MediaStream> {
    // If there is a pending getUserMedia call, just queue it up
    if (this.isPending && this.gumPromise) {
      return this.gumPromise.then(() => this.getUserMedia(constraints, options));
    }

    if (!this.supportService.isWebrtcSupported()) {
      return Promise.reject("getUserMedia not supported");
    }

    const showGumWarning = this.needsPermission(constraints);
    this.isPending = true;

    if (showGumWarning) {
      if (Config.IS_WEB) {
        this.gumDelayId = setTimeout(() => {
          this.stateStore.warningMessage =
            "Please click 'Allow' to permit OnSIP to access your microphone.";
          this.publishState();
        }, 200);
      } else {
        this.log.info("Desktop app requested GUM permission");
      }
      this.publishState();
    }

    this.gumPromise = navigator.mediaDevices.getUserMedia
      .call(navigator.mediaDevices, constraints)
      .then(stream => {
        this.isPending = false;
        this.stateStore.warningMessage = undefined;
        if (showGumWarning) {
          clearTimeout(this.gumDelayId);
          this.rememberPermission(constraints);
        }
        if (constraints.video && stream.getVideoTracks().length === 0) {
          this.stateStore.warningMessage =
            "No Video. Please update your browser settings to permit OnSIP to access your camera.";
          setTimeout(() => {
            this.stateStore.warningMessage = undefined;
            this.publishState();
          }, 5000);
        }
        this.publishState();
        return stream;
      })
      .catch(e => {
        this.isPending = false;
        if (options.isAutoAnswerRequest && constraints.video) {
          if (showGumWarning) {
            clearTimeout(this.gumDelayId);
          }
          this.stateStore.warningMessage = undefined;
          this.publishState();
          return this.getUserMedia({ audio: true, video: false }, options);
        }
        const device = constraints.video ? "camera" : "microphone";
        if (e.name === "PermissionDeniedError") {
          if (options.isAutoAnswerRequest) {
            this.stateStore.warningMessage =
              "Please allow microphone permissions to enable Auto-Answer. This can be disabled from the settings page";
          } else {
            this.stateStore.warningMessage =
              "Please update your browser settings to permit OnSIP to access your " + device + ".";
          }
        } else {
          this.stateStore.warningMessage =
            "Your call could not be completed. OnSIP is unable to access your computer's " +
            device +
            ".";
        }
        this.publishState();
        setTimeout(() => {
          this.stateStore.warningMessage = undefined;
          this.publishState();
        }, 5000);
        throw e;
      });
    return this.gumPromise;
  }

  private needsPermission(constraints: GumConstraints): boolean {
    // chrome uses previously granted permissions
    if (this.supportService.getBrowser().name === "chrome") {
      return !(["audio", "video"] as Array<keyof GumConstraints>).every(medium => {
        return !constraints[medium] || this.browserSettings[medium];
      });
    } else {
      return true;
    }
  }

  private rememberPermission(constraints: GumConstraints): void {
    if (this.supportService.getBrowser().name === "chrome") {
      (["audio", "video"] as Array<keyof GumConstraints>).forEach(medium => {
        if (constraints[medium]) {
          this.browserSettings[medium] = true;
          try {
            localStorage.setItem(medium, "true");
          } catch (e) {
            // ignore
          }
        }
      });
    }
  }
}
