import { GumService } from "../services/gum/gum.service";
import { SessionDescriptionHandler } from "./session-description-handler";
import { SessionDescriptionHandler as MohSessionDescriptionHandler } from "./moh-session-description-handler";
import { SessionDescriptionHandler as SessionDescriptionHandlerBase } from "../../../libraries/webrtc/session-description-handler";
import { SessionSIP } from "../../../../common/libraries/sip/session-sip";
import { log } from "../../../../common/libraries/sip/log";
import { SessionDescriptionHandlerFactoryOptions as SessionDesriptionHandlerFactoryOptionsDefinition } from "../../../libraries/webrtc/session-description-handler-factory";
import { WebAudioService } from "../services/webAudio/web-audio.service";
import { SessionDescriptionHandlerFactory, Session, Web } from "sip.js";

export interface SessionDescriptionHandlerFactoryOptions
  extends SessionDesriptionHandlerFactoryOptionsDefinition {
  webAudioService: WebAudioService;

  // These are all unused by the new SessionDescriptionHandlerFactory
  modifiers?: any;
  hackStripTcpCandidates?: any;
  hackStripTelephoneEvent?: any;
  hackCleanJitsiSdpImageattr?: any;
}

const mohAudio = new Audio();
mohAudio.volume = 0;

export function defaultSessionDescriptionHandlerFactory(
  gumService: GumService,
  webAudioService: WebAudioService
): SessionDescriptionHandlerFactory {
  return (
    session: Session,
    options?: Partial<SessionDescriptionHandlerFactoryOptions>
  ): SessionDescriptionHandlerBase => {
    const iceGatheringTimeout =
      options?.iceGatheringTimeout !== undefined ? options?.iceGatheringTimeout : 1000;

    // Here is what I am going to do... Check to see if we are doing MOH. If we are then we are going to return a *SPECIAL* SDH.
    if (!session.data) {
      throw new Error("SIP.js session data is missing.");
    }
    if (!(session.data instanceof SessionSIP) && (session.data as any).moh !== true) {
      throw new Error("SIP.js session data is not a Session and not attempting to do MOH.");
    }
    if ((session.data as any).moh === true) {
      const mohOptions = {
        iceGatheringTimeout,
        peerConnectionConfiguration: {
          ...Web.defaultPeerConnectionConfiguration(),
          ...options?.peerConnectionConfiguration
        }
      };
      const logger = session.userAgent.getLogger("sip.MohSessionDescriptionHandler");
      const mohSdh = new MohSessionDescriptionHandler(logger, mohOptions);
      mohSdh.peerConnectionDelegate = {
        ontrack: () => {
          mohAudio.srcObject = mohSdh.remoteMediaStream;
          mohAudio.volume = 0;
        }
      };
      return mohSdh;
    }

    const mediaStreamFactory = (constraints: MediaStreamConstraints) =>
      gumService.getUserMedia(constraints);
    const requiredOptions = {
      webAudioService
    };
    const mergedOptions: SessionDescriptionHandlerFactoryOptions = {
      ...requiredOptions,
      ...options
    };
    mergedOptions.iceGatheringTimeout = iceGatheringTimeout;
    mergedOptions.peerConnectionConfiguration = {
      ...Web.defaultPeerConnectionConfiguration(),
      ...options?.peerConnectionConfiguration
    };
    const sdh = new SessionDescriptionHandler(session, mediaStreamFactory, mergedOptions);

    sdh.peerConnectionDelegate = {
      ontrack: () => {
        if (!session.data || !(session.data instanceof SessionSIP)) {
          throw new Error("SIP.js session data is not an instance of SessionSIP.");
        }

        // FIXME: Broken implementation
        //
        // "audio available" is intended to indicate that audio is now available from the remote peer, and
        // "video available" is intended to indicate that video is now available from the remote peer, however...
        //
        // This implementation herein setting audio available to true if...
        //  a) the local media stream has audio available, and
        //  b) the remote media stream has ANY media available
        //
        // This implementation herein setting video available to true if...
        //  a) the local media stream has video available, and
        //  b) the remote media stream has ANY media available
        //
        // So it's being used more or less backwards from everywhere else (including mobile) and should be fixed.
        // Unfortunately the web code now depends on video available being defined in this fashion
        // so there's a bit of clean to be done...

        // This broken implementation should be removed...
        if (
          sdh.localMediaStream.getAudioTracks().length > 0 &&
          sdh.remoteMediaStream.getTracks().length > 0
        ) {
          log.warn("SessionDescriptionHandler.ontrack - setting audio available to true (FIXME)");
          session.data.setAudioAvailable(true);
        }
        if (
          sdh.localMediaStream.getVideoTracks().length > 0 &&
          sdh.remoteMediaStream.getTracks().length > 0
        ) {
          log.warn("SessionDescriptionHandler.ontrack - setting video available to true (FIXME)");
          session.data.setVideoAvailable(true);
        }

        // This correct implementation should be added...
        // if (event.track.kind === "audio") {
        //   session.data.setAudioAvailable(true);
        // }
        // if (event.track.kind === "video") {
        //   session.data.setVideoAvailable(true);
        // }
      }
    };

    return sdh;
  };
}
