<section class="table-container">
  <onsip-table-two
    multiTemplateDataRows
    matSort
    [dataSource]="dataSource"
    [ngClass]="getTableClass()"
    class="super-user-search-table"
  >
    <!-- User column -->
    <ng-container matColumnDef="contactName" [sticky]="isSticky('contactName') | async">
      <mat-header-cell class="contact-name-cell" *matHeaderCellDef mat-sort-header>
        <span>User</span>
      </mat-header-cell>
      <mat-cell class="contact-name-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.contactName }}</span>
      </mat-cell>
    </ng-container>
    <!-- User ID column -->
    <ng-container matColumnDef="userId" [sticky]="isSticky('userId') | async">
      <mat-header-cell class="user-id-cell" *matHeaderCellDef mat-sort-header>
        <span>User Id</span>
      </mat-header-cell>
      <mat-cell class="user-id-cell" *matCellDef="let result">
        <span
          class="ellipsis"
          [ngClass]="(getClickableClass(result) | async)!"
          (click)="spoofUser(result)"
          >{{ result.userId }}</span
        >
      </mat-cell>
    </ng-container>
    <!-- Status column -->
    <ng-container matColumnDef="status" [sticky]="isSticky('status') | async">
      <mat-header-cell class="status-cell" *matHeaderCellDef mat-sort-header>
        <span>Status</span>
      </mat-header-cell>
      <mat-cell class="status-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.status }}</span>
      </mat-cell>
    </ng-container>
    <!-- Account ID column -->
    <ng-container matColumnDef="accountId" [sticky]="isSticky('accountId') | async">
      <mat-header-cell class="account-id-cell" *matHeaderCellDef mat-sort-header>
        <span>Acct Id</span>
      </mat-header-cell>
      <mat-cell class="account-id-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.accountId }}</span>
      </mat-cell>
    </ng-container>
    <!-- Org column -->
    <ng-container matColumnDef="contactOrg" [sticky]="isSticky('contactOrg') | async">
      <mat-header-cell class="contact-org-cell" *matHeaderCellDef mat-sort-header>
        <span>Org</span>
      </mat-header-cell>
      <mat-cell class="contact-org-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.contactOrg }}</span>
      </mat-cell>
    </ng-container>
    <!-- Domain column -->
    <ng-container matColumnDef="domain" [sticky]="isSticky('domain') | async">
      <mat-header-cell class="domain-cell" *matHeaderCellDef mat-sort-header>
        <span>Domain</span>
      </mat-header-cell>
      <mat-cell class="domain-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.domain }}</span>
      </mat-cell>
    </ng-container>
    <!-- Email column -->
    <ng-container matColumnDef="email" [sticky]="isSticky('email') | async">
      <mat-header-cell class="email-cell" *matHeaderCellDef mat-sort-header>
        <span>Email</span>
      </mat-header-cell>
      <mat-cell class="email-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.email }}</span>
      </mat-cell>
    </ng-container>
    <!-- Org ID column -->
    <ng-container matColumnDef="orgId" [sticky]="isSticky('orgId') | async">
      <mat-header-cell class="org-id-cell" *matHeaderCellDef mat-sort-header>
        <span>Org Id</span>
      </mat-header-cell>
      <mat-cell class="org-id-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.orgId }}</span>
      </mat-cell>
    </ng-container>
    <!-- Username column -->
    <ng-container matColumnDef="username" [sticky]="isSticky('username') | async">
      <mat-header-cell class="username-cell" *matHeaderCellDef mat-sort-header>
        <span>Auth Username</span>
      </mat-header-cell>
      <mat-cell class="username-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.username }}</span>
      </mat-cell>
    </ng-container>
    <!-- Org Contact Column (Org search only) -->
    <ng-container matColumnDef="orgContact" [sticky]="isSticky('orgContact') | async">
      <mat-header-cell class="org-contact-cell" *matHeaderCellDef mat-sort-header>
        <span>Org Contact</span>
      </mat-header-cell>
      <mat-cell class="org-contact-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.orgContact }}</span>
      </mat-cell>
    </ng-container>
    <!-- Account Contact Column (Account search only) -->
    <ng-container matColumnDef="accountContact" [sticky]="isSticky('accountContact') | async">
      <mat-header-cell class="account-contact-cell" *matHeaderCellDef mat-sort-header>
        <span>Acct Contact</span>
      </mat-header-cell>
      <mat-cell class="account-contact-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.accountContact }}</span>
      </mat-cell>
    </ng-container>
    <!-- Business Name Column (Account search only) -->
    <ng-container matColumnDef="businessName" [sticky]="isSticky('businessName') | async">
      <mat-header-cell class="business-name-cell" *matHeaderCellDef mat-sort-header>
        <span>Business Name</span>
      </mat-header-cell>
      <mat-cell class="business-name-cell" *matCellDef="let result">
        <span class="ellipsis">{{ result.businessName }}</span>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="getRowClasses(row)"
    ></mat-row>
    <div *matNoDataRow class="no-data-row">
      @if (data.length === 0) {
      <span>No Results</span>
      }
    </div>
  </onsip-table-two>
</section>
