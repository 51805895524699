import { Core } from "sip.js";
import { log } from "../../../../common/libraries/sip/log";
import { SessionDescriptionHandler as SessionDescriptionHandlerBase } from "../../../libraries/webrtc/session-description-handler";
import { SessionDescriptionHandlerFactoryOptions } from "../../../libraries/webrtc/session-description-handler-factory";

export class SessionDescriptionHandler extends SessionDescriptionHandlerBase {
  private static mediaStreamFactory(): Promise<MediaStream> {
    log.debug("SessionDescriptionHandler.mediaStreamFactory");
    const ctx = new AudioContext();
    const oscillator = ctx.createOscillator();
    const dst = oscillator.connect(ctx.createMediaStreamDestination());
    oscillator.start();
    const stream = (dst as MediaStreamAudioDestinationNode).stream;
    return Promise.resolve(stream);
  }

  constructor(logger: Core.Logger, options: SessionDescriptionHandlerFactoryOptions) {
    super(logger, SessionDescriptionHandler.mediaStreamFactory, options);
  }
}
