import { PasswordChangeComponent } from "./password-change.component";
import { PasswordCheckerComponent } from "./checker/password-checker.component";
import { PasswordChangeModalComponent } from "./modal/password-change-modal.component";

import { ModalModule } from "../modal/modal.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    TranslateModule,
    MaterialModule
  ],
  declarations: [PasswordCheckerComponent, PasswordChangeComponent, PasswordChangeModalComponent],
  exports: [PasswordCheckerComponent, PasswordChangeComponent]
})
export class PasswordModule {}
