@if (termsAccepted | async) {
<div class="e911-select-location">
  @if (showTitle) {
  <div class="e911-title">Set E911 location</div>
  }
  <span class="e911-block mat-body-2">
    When you dial 911, emergency personnel will be sent to this location. If your location is not
    listed, add a new one.
  </span>
  <mat-form-field
    appearance="outline"
    onsip-form-field-flush-outline
    onsip-form-field-outline-white
    class="e911-location-select"
  >
    <mat-select
      placeholder="{{ 'ONSIP_I18N.CHOOSE_YOUR_LOCATION' | translate }}"
      [(ngModel)]="formData.myE911LocId"
      (selectionChange)="selectE911Loc($event.value)"
    >
      @for (e911Loc of e911Locs; track e911Loc) {
      <mat-option [value]="e911Loc.e911LocationId">{{ e911Loc.fullAddress }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <div>
    <button mat-button color="primary" (click)="openAddLocationModal()">
      <mat-icon>add</mat-icon>
      <span>Add private location</span>
    </button>
  </div>
</div>
} @if ((termsAccepted | async) === false) {
<div class="e911-terms-of-service">
  <span>
    {{ "ONSIP_I18N.E911_SERVICES" | translate }}
  </span>
  <button mat-button id="onsip--e911--view-tos" (click)="viewTerms()">
    {{ "ONSIP_I18N.VIEW_TERMS_OF_SERVICE" | translate }}
  </button>
</div>
}
