import { OperatorFunction } from "rxjs";
import { filter, map } from "rxjs/operators";
import { combineLatest, distinctUntilChanged } from "rxjs";
import { ApiStateStoreState } from "../api-state-store.service";
import { ApiSessionState } from "../api-session.service";
import { UserAddress } from "../resources/userAddress/user-address";
import { userId } from "./user-id";

export function primaryUserAddress(): OperatorFunction<ApiStateStoreState, UserAddress> {
  return storeState =>
    combineLatest({ store: storeState, userId: storeState.pipe(userId()) }).pipe(
      map(({ store, userId }) => {
        const userAdds = Object.values(store.UserAddress?.state as ApiSessionState)
          .sort((a, b) => (parseInt(a.userAddressId) < parseInt(b.userAddressId) ? -1 : 1))
          .find(userAddress => userAddress.userId === userId);
        return userAdds;
      }),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}
