@if (isMobile && useMobileView) {
<mat-form-field appearance="outline">
  <mat-select [formControl]="currentView" (closed)="navigateTo(currentView.value)">
    @for (nav of shellNavigations; track nav) {
    <mat-option [value]="nav.url">
      <span class="mat-body-1"> {{ nav.name }} </span>
    </mat-option>
    }
  </mat-select>
</mat-form-field>
} @else { @if (!horizontalNav) {
<nav
  class="shell-navs"
  onsip-shell-navigations
  mat-tab-nav-bar
  onsip-no-pagination
  onsip-no-chervon
  aria-label="onsip-navigation-bar"
  id="onsip-phone-menu-nav-bar"
  color="primary"
  [tabPanel]="tabPanel"
>
  @for (nav of shellNavigations; track nav) { @if (nav.nestedUrls) {
  <div class="nested-dropdown-title" (click)="toggleRow(nav.name)">
    <a mat-tab-link [attr.data-cy]="nav?.cyLocator">
      <span>{{ nav.name | underscoresToSpaces }}</span>
    </a>
    <mat-icon>{{ this.rowMap.get(nav.name) ? "expand_less" : "expand_more" }}</mat-icon>
  </div>
  @if (this.rowMap.get(nav.name)) {
  <onsip-shell-navigations class="nested-navigation" [shellNavigations]="nav.nestedUrls">
  </onsip-shell-navigations>
  } } @else {
  <a
    id="{{ nav.url }}"
    [attr.data-cy]="nav?.cyLocator"
    mat-tab-link
    [active]="
      (outsideNav | async) === false && currentView.value | invokeFunction : isActiveLink : nav.url
    "
    [routerLink]="[nav.url]"
  >
    <span>
      {{ nav.name | underscoresToSpaces }}
    </span>
  </a>
  }
  <div
    [ngClass]="{ hidden: (outsideNav | async) }"
    [ngStyle]="{ transform: inkBarTop }"
    class="left-ink-bar-container"
  >
    <div class="left-ink-bar"></div>
  </div>
  }
</nav>
} @if (horizontalNav) {
<nav
  class="shell-navs-horizontal"
  onsip-shell-navigations
  mat-tab-nav-bar
  onsip-no-pagination
  onsip-no-chervon
  aria-label="onsip-navigation-bar"
  id="onsip-phone-menu-nav-bar"
  color="primary"
>
  @for (nav of shellNavigations; track nav) {
  <ng-container class="shell-navs-horizontal-flex">
    @if (nav.nestedUrls) {
    <div class="nested-dropdown-title" (click)="toggleRow(nav.name)">
      <a mat-tab-link [attr.data-cy]="nav?.cyLocator">
        <span>{{ nav.name | underscoresToSpaces }}</span>
      </a>
      <mat-icon>{{ this.rowMap.get(nav.name) ? "expand_less" : "expand_more" }}</mat-icon>
    </div>
    @if (this.rowMap.get(nav.name)) {
    <onsip-shell-navigations class="nested-navigation" [shellNavigations]="nav.nestedUrls">
    </onsip-shell-navigations>
    } } @else {
    <a
      id="{{ nav.url }}"
      class="horiz-single-link"
      [attr.data-cy]="nav?.cyLocator"
      mat-tab-link
      [active]="
        (outsideNav | async) === false && currentView.value
          | invokeFunction : isActiveLink : nav.url
      "
      [routerLink]="[nav.url]"
    >
      <span>
        {{ nav.name | underscoresToSpaces }}
      </span>
    </a>
    }
    <div
      [ngClass]="{ hidden: (outsideNav | async) }"
      [ngStyle]="{ transform: inkBarTop }"
      class="left-ink-bar-container"
    >
      <div class="left-ink-bar"></div>
    </div>
  </ng-container>
  }
</nav>
} }
<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
