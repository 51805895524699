import { Component, OnDestroy, OnInit, HostBinding } from "@angular/core";
import { FormControl } from "@angular/forms";

import { Subscription, Observable, BehaviorSubject } from "rxjs";
import { map, startWith } from "rxjs/operators";

import { AnalyticsService } from "../../shared/components/analytics/analytics.service";
import { VoicemailService } from "../../../../common/services/api/resources/voicemail/voicemail.service";
import { Config } from "../../../../common/config";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "onsip-home-main",
  templateUrl: "./home-main.component.html",
  styleUrls: ["./home-main.scss"]
})
export class HomeMainComponent implements OnDestroy, OnInit {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  hasVoicemailbox = this.voicemailService.selfUserVoicemailbox.pipe(startWith(false));

  voicemailBox$ = this.voicemailService.selfUserVoicemailbox;

  msgCount!: Observable<number>;
  isMobile = false;
  callHistorySearch = new FormControl("", { nonNullable: true });
  voicemailSearch = new FormControl("", { nonNullable: true });
  isShowManageVoicemail$ = new BehaviorSubject(false);

  private unsubscriber = new Subscription();

  constructor(
    private analyticsService: AnalyticsService,
    private breakpointObserver: BreakpointObserver,
    private voicemailService: VoicemailService
  ) {}

  platformClickedDownload(): void {
    if (Config.IS_WEB) {
      this.analyticsService.sendDesktopEvent("Download", {});
    } else if (Config.IS_DESKTOP) {
      this.analyticsService.sendDesktopEvent("Update", {});
    }
  }

  handleManageVoicemail() {
    this.isShowManageVoicemail$.next(!this.isShowManageVoicemail$.value);
  }

  ngOnInit() {
    this.voicemailService.advVoicemailboxBrowseWithUserId();
    this.msgCount = this.voicemailService.selfUserVoicemailbox.pipe(
      map(voicemailbox => {
        const voicemails = voicemailbox?.voicemail.inbox?.voicemail;
        return voicemails
          ? Object.values(voicemails).filter(msg => msg.folder === "NEW").length
          : 0;
      }),
      map(
        count => Math.max(0, Math.min(count, 99)) // badge doesn't support 3 digit numbers
      )
    );

    this.unsubscriber.add(
      this.breakpointObserver.observe(Breakpoints.XSmall).subscribe(result => {
        this.isMobile = result.matches;
      })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
