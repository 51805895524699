import { Component } from "@angular/core";

import { entryViews } from "./entry-views";

@Component({
  selector: "onsip-entry-page",
  templateUrl: "./entry-page.component.html",
  styleUrls: ["./entry-page.scss"]
})
export class EntryPageComponent {
  entryViews: any = entryViews;
}
