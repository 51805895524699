import {
  newTracker,
  TrackerConfiguration,
  trackSelfDescribingEvent,
  setUserId
} from "@snowplow/browser-tracker";

import { UUID } from "../sip/uuid";
import { PLATFORM } from "../../../web/platform-dependent/platform-vars";

// IF YOU ARE COPYING ANYTHING FROM THIS FILE YOU DID SOMETHING WRONG!!!

// Looking for an npm? Me too!
// Snowplow doesn't (yet) have a browser/client tracker npm
// https://github.com/snowplow/snowplow-javascript-tracker/issues/541

//
// For Snowplow interface documentation, see: https://github.com/snowplow/snowplow/wiki/javascript-tracker
//

const COLLECTORS = ["fivetran", "snowcat"];

export interface SchemaSettings {
  name: string;
  schema: string;
  collector: "fivetran" | "snowcat";
}

export class Tracker {
  private _schemas: Array<SchemaSettings> = [];
  private sessionLoadId: string;
  private testing: boolean;

  /**
   * Creates a single instance for each app that you are trying to track. If the app already exists then it
   * returns the tracker for that app. The `appSettings` is required if the tracker does not already exist.
   *
   * @param appId - The app id of the app you are using for the tracker
   * @param appSettings - Information about the app you are tracking
   */

  constructor(
    appId: string,
    options?: { collectorOptions?: any; schemas?: Array<SchemaSettings> }
  ) {
    this.testing = /localhost|192\.168/.test(document.location.host);
    const platform = PLATFORM === "desktop" ? "pc" : "web";

    COLLECTORS.forEach(collector => {
      this.newTracker(collector, {
        appId,
        cookieDomain: ".onsip.com",
        platform
      });
    });

    if (options && options.schemas) {
      this.schemas = options.schemas;
    }

    // As a note. This sessionLoadId is unique to the session load and this tracker. If you need to load multiple trackers in
    // a single app you should go do the work to make this sessionLoadId consistent across trackers.
    this.sessionLoadId = UUID.randomUUID();
  }

  /**
   * Track an event with Snowplow.
   * @param event - An object describing the event where each property matches to the schema. Circular references will cause an error to be thrown.
   */
  trackEvent(name: string, _event: Record<string, any>) {
    let event: Record<string, any>;
    try {
      // Ensure that there are no ciurcular refences
      // Make a copy of event to ensure it cannot be changed before our async send is complete.
      event = JSON.parse(JSON.stringify(_event));
    } catch (error) {
      console.error("Circular Reference in analytics event object");
      return;
    }
    event.session_load_id = this.sessionLoadId;
    event.testing = this.testing;

    this.schemas.forEach(schema => {
      // https://github.com/snowplow/snowplow/wiki/2-Specific-event-tracking-with-the-Javascript-tracker#trackSelfDescribingEvent
      trackSelfDescribingEvent(
        {
          event: {
            schema: schema.schema,
            data: event
          }
        },
        ["onsip_" + schema.collector]
      );
    });
  }

  setUserId(userId: number) {
    const collectorStrings = this.schemas.map(schema => "onsip_" + schema.collector);
    // https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker-v2.0#user-id
    setUserId(userId.toString(), collectorStrings);
  }

  get schemas(): Array<SchemaSettings> {
    return this._schemas;
  }

  set schemas(schemas: Array<SchemaSettings>) {
    // Combine the arrays. We can tweak this later if needed.
    this._schemas = this._schemas.concat(schemas);
  }

  private newTracker(collector: string, argmap: TrackerConfiguration): void {
    let collectorUrl = "";
    switch (collector) {
      case "fivetran":
        collectorUrl = "events.fivetran.com/snowplow/dispensed_jaws";
        break;
      case "snowcat":
        collectorUrl = "https://snowplow.onsip.com";
        break;
      default:
        throw new Error("Invalid collector");
    }
    newTracker("onsip_" + collector, collectorUrl, argmap);
  }
}
