import { SessionDescriptionHandlerModifier } from "sip.js";

export function replaceIllegalOptionsModifier(): SessionDescriptionHandlerModifier {
  return sessionDescription => {
    if (!sessionDescription.sdp || !sessionDescription.type) {
      throw new Error("Invalid SDP");
    }
    let sdp = sessionDescription.sdp;
    const type = sessionDescription.type;
    const section: number = sdp.search(/^m=video.*$/gm);

    if (section !== -1) {
      const keep: string = sdp.substring(0, section);
      let modify: string = sdp.substring(section);

      if (!/a=(sendrecv|sendonly|recvonly|inactive)/.test(modify)) {
        modify = modify.replace(/(m=[^\r]*\r\n)/g, "$1a=inactive\r\n");
      }

      sdp = keep + modify;
    }

    return Promise.resolve({ sdp, type });
  };
}

export function useInactiveModifier(): SessionDescriptionHandlerModifier {
  return sessionDescription => {
    if (!sessionDescription.sdp || !sessionDescription.type) {
      throw new Error("Invalid SDP");
    }
    let sdp = sessionDescription.sdp;
    const type = sessionDescription.type;
    const section: number = sdp.search(/^m=video.*$[^]*^a=recvonly$/gm);

    if (section !== -1) {
      const keep: string = sdp.substring(0, section),
        modify: string = sdp.substring(section).replace(/^a=recvonly/gm, "a=inactive");

      sdp = keep + modify;
    }

    return Promise.resolve({ sdp, type });
  };
}
