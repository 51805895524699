// need this to get the old webrtc stream api
/// <reference path="./@types/webrtc.d.ts" />

import { Core, Web, SessionDescriptionHandlerModifier } from "sip.js";
import { log } from "../../../common/libraries/sip/log";
import {
  RTCStatsReport,
  SessionDescriptionHandlerSIP
} from "../../../common/libraries/sip/session-description-handler-sip";
import { SessionDescriptionHandlerFactoryOptions } from "./session-description-handler-factory";

export class SessionDescriptionHandler
  extends Web.SessionDescriptionHandler
  implements SessionDescriptionHandlerSIP
{
  readonly replaceSenderTrackSupport = true;

  private _dtlsDirection: string | undefined;
  private _transceiverDirection: "sendrecv" | "sendonly" | "recvonly" | "inactive" | undefined;
  private replacedSenderTrack: MediaStreamTrack | undefined;

  constructor(
    logger: Core.Logger,
    mediaStreamFactory: Web.MediaStreamFactory,
    protected sessionDescriptionHandlerConfiguration: SessionDescriptionHandlerFactoryOptions
  ) {
    super(logger, mediaStreamFactory, sessionDescriptionHandlerConfiguration);
  }

  get localAudioTrack(): MediaStreamTrack | undefined {
    log.debug("SessionDescriptionHandler.localAudioTrack get");
    return this.localMediaStream.getAudioTracks()[0];
  }

  get localVideoTrack(): MediaStreamTrack | undefined {
    log.debug("SessionDescriptionHandler.localVideoTrack get");
    return this.localMediaStream.getVideoTracks()[0];
  }

  get remoteAudioTrack(): MediaStreamTrack | undefined {
    log.debug("SessionDescriptionHandler.remoteAudioTrack get");
    return this.remoteMediaStream.getAudioTracks()[0];
  }

  get remoteVideoTrack(): MediaStreamTrack | undefined {
    log.debug("SessionDescriptionHandler.remoteVideoTrack get");
    return this.remoteMediaStream.getVideoTracks()[0];
  }

  get transceiverDirection(): RTCRtpTransceiverDirection | undefined {
    log.debug("SessionDescriptionHandler.transceiverDirection get");
    return this.getTransceiverDirection();
  }

  get dtlsDirection(): string | undefined {
    return this._dtlsDirection;
  }

  /*
   * This is a hack to prevent Firefox from attempting to change it's DTLS role on renegotiations.
   * We are storing the DTLS and then overriding the "actpass" in future negotiations to force the role we want.
   */
  set dtlsDirection(direction: string | undefined) {
    if (this._dtlsDirection) {
      // We already have a dtls direction for this call
      return;
    }
    if (direction === "active" || direction === "passive") {
      this._dtlsDirection = direction;
    }
  }

  /**
   * Destructor wrapper
   * A wrapper is needed around the close function in the case that there is no sender
   * track to restore or replacing the sender track fails, we still want to close MediaStreamTracks
   */
  close(): void {
    if (this.replaceSenderTrackSupport) {
      this.restoreSenderTrack()
        .then(() => super.close())
        .catch(() => super.close());
    } else {
      super.close();
    }
  }

  getDescription(
    options?: Web.SessionDescriptionHandlerOptions,
    modifiers?: Array<SessionDescriptionHandlerModifier>
  ): Promise<{ body: string; contentType: string }> {
    const opts = options || {};
    opts.answerOptions = {
      ...{ voiceActivityDetection: true },
      ...options?.answerOptions
    };
    opts.offerOptions = {
      ...{ iceRestart: false, voiceActivityDetection: true },
      ...options?.offerOptions
    };

    return super.getDescription(opts, modifiers).then(description => {
      this.setTransceiverDirection(description.body);
      const dtlsDirection = this.getDtlsDirectionFromSdp(description.body);
      this.dtlsDirection = dtlsDirection;
      return description;
    });
  }

  setDescription(
    sdp: string,
    options?: Web.SessionDescriptionHandlerOptions,
    modifiers?: Array<SessionDescriptionHandlerModifier>
  ): Promise<void> {
    modifiers = modifiers || [];
    if (/Firefox\/(6[3-9]\.[0-9]+)(?:\s|$)/.exec(navigator.userAgent)) {
      modifiers.push(Web.addMidLines);
    }
    return super.setDescription(sdp, options, modifiers);
  }

  //
  // Extended interface methods
  //

  /**
   * Enables or disables local audio track.
   * @param enable Enables if true.
   */
  enableLocalAudio(enable: boolean): void {
    this.enableTracks(enable, "sender", "audio");
  }

  /**
   * Enables or disables local video track.
   * @param enable Enables if true.
   */
  enableLocalVideo(enable: boolean): void {
    this.enableTracks(enable, "sender", "video");
  }

  /**
   * Enables or disables remote audio track.
   * @param enable Enables if true.
   */
  enableRemoteAudio(enable: boolean): void {
    this.enableTracks(enable, "receiver", "audio");
  }

  /**
   * Enables or disables video audio track.
   * @param enable Enables if true.
   */
  enableRemoteVideo(enable: boolean): void {
    this.enableTracks(enable, "receiver", "video");
  }

  /**
   * Get WebRTC internal statistics
   */
  getStatsReports(): Promise<Array<RTCStatsReport>> {
    if (this.peerConnection === undefined) {
      return Promise.reject(new Error("Peer connection undefined."));
    }
    // TOOD
    // return this.peerConnection.getStats();
    return Promise.resolve([]);
  }

  /**
   * This replaces the track on the Sender with a new track. There is no renegotiation when using this.
   * The track retains the state that it was in already. After the track is replaced if it was disabled
   * it may need to be enabled.
   * Returns a promise that resolves when the track is successfully replaced.
   * @param replacementTrack The replacement track
   */
  replaceSenderTrack(replacementTrack: MediaStreamTrack): Promise<void> {
    if (this.peerConnection === undefined) {
      return Promise.reject(new Error("Peer connection undefined."));
    }
    if (replacementTrack.kind === "audio") {
      this.replacedSenderTrack = this.peerConnection.getSenders()[0].track || undefined;
      return this.peerConnection.getSenders()[0].replaceTrack(replacementTrack);
    } else {
      this.replacedSenderTrack = this.peerConnection.getSenders()[1].track || undefined;
      return this.peerConnection.getSenders()[1].replaceTrack(replacementTrack);
    }
  }

  /**
   * This restores the track on the Sender with replacedSenderTrack. SessionDescriptionHandler
   * keeps track of replacedSenderTrack but if needed can pass a MediaStreamTrack parameter.
   * If a replacementTrack is passed and replacedSenderTrack exists, we want to restore that exisiting
   * track first to make sure no streams are left open once the call is ended
   * @param replacementTrack optional replacement track, otherwise replace with replacedSenderTrack stored in class
   */
  restoreSenderTrack(replacementTrack?: MediaStreamTrack): Promise<void> {
    if (replacementTrack) {
      if (this.replacedSenderTrack) {
        this.restoreReplacedTrack();
      }
      this.replacedSenderTrack = replacementTrack;
    }
    return this.restoreReplacedTrack();
  }

  /**
   * Shoule be called exclusively from restoreSenderTrack().
   * Restores track after this.replacedSenderTrack is possibly overwritten
   */
  protected restoreReplacedTrack(): Promise<void> {
    if (this.peerConnection === undefined) {
      return Promise.reject(new Error("Peer connection undefined."));
    }
    if (!this.replacedSenderTrack) {
      log.info("There was no sender track to restore.");
      return Promise.resolve();
    }
    if (this.replacedSenderTrack.kind === "audio") {
      return this.peerConnection
        .getSenders()[0]
        .replaceTrack(this.replacedSenderTrack)
        .then(() => {
          this.replacedSenderTrack = undefined;
        });
    } else {
      return this.peerConnection
        .getSenders()[1]
        .replaceTrack(this.replacedSenderTrack)
        .then(() => {
          this.replacedSenderTrack = undefined;
        });
    }
  }

  protected setRemoteSessionDescription(
    sessionDescription: RTCSessionDescriptionInit
  ): Promise<void> {
    let sdp = sessionDescription.sdp;
    if (!sdp) {
      log.error(
        "SessionDescriptionHandler.setRemoteSessionDescription failed - cannot set null sdp"
      );
      return Promise.reject(new Error("SDP is undefined"));
    }
    const dtlsDirection = this.getDtlsDirectionFromSdp(sdp);
    // If we have this.dtlsDirection... and dtlsDirection is actpass. We need to modify the SDP.
    if (this.dtlsDirection && dtlsDirection === "actpass") {
      sdp = sdp.replace(
        /^a=setup:actpass$/m,
        "a=setup:" + this.getOppositeDtlsDirection(this.dtlsDirection)
      );
    } else if (dtlsDirection && !this.dtlsDirection) {
      this.dtlsDirection = this.getOppositeDtlsDirection(dtlsDirection);
    }
    sessionDescription.sdp = sdp;

    return super.setRemoteSessionDescription(sessionDescription).catch((error: Error) => {
      log.error("SessionDescriptionHandler.setRemoteSessionDescription failed - " + error.message);

      // HACK: Try again after stripping out video section.
      // This is hacked in here because a Polycom phone with (or without) camera
      // will offer a video codec which the WebRTC library pukes on. To allow the
      // call to proceed audio only, strip it out and apply again. Apparently the
      // Polycom is fine receiving an answer without a video section in that case.
      // All that said, this is not a great hack as it impacts a wider set of cases.
      if (
        /m=video/gm.test(error.message) &&
        sessionDescription?.sdp &&
        /^m=video.+$/gm.test(sessionDescription.sdp)
      ) {
        log.warn(
          "SessionDescriptionHandler.setRemoteSessionDescription stripping video and trying again (HACK)"
        );
        return super
          .setRemoteSessionDescription(this.stripVideo(sessionDescription))
          .catch((e: Error) => {
            log.error(
              "SessionDescriptionHandler.setRemoteSessionDescription failed after stripping video - " +
                e.message
            );
            if (this.peerConnection) {
              this.close();
            }
            throw error;
          });
      }

      // HACK: this is the sequel to the above hack. Newer polycoms send media type m=application
      // which webrtc doesn't like either.
      if (
        /m=application/gm.test(error.message) &&
        sessionDescription?.sdp &&
        /^m=application.+$/gm.test(sessionDescription.sdp)
      ) {
        log.warn(
          "SessionDescriptionHandler.setRemoteSessionDescription stripping application and trying again (HACK)"
        );
        return super
          .setRemoteSessionDescription(this.stripApplication(sessionDescription))
          .catch((e: Error) => {
            log.error(
              "SessionDescriptionHandler.setRemoteSessionDescription failed after stripping application - " +
                e.message
            );
            if (this.peerConnection) {
              this.close();
            }
            throw error;
          });
      }

      // If this fails the call will be killed which we need to do anyway since we'll have ended in a have-local-offer state
      // manually call this.close to ensure connection is closed - ran into some cases where it was not and the user was unable to start
      // another call -> same for the catch in HACK above
      if (this.peerConnection) {
        this.close();
      }
      throw error;
    });
  }

  private getDtlsDirectionFromSdp(sdp: string): string | undefined {
    const match = sdp.match(/^a=setup:(.*)$/m);
    if (!match) {
      return undefined;
    }
    return match[1];
  }

  private getOppositeDtlsDirection(direction: string): string | undefined {
    switch (direction) {
      case "active":
        return "passive";
      case "passive":
        return "active";
      default:
        return undefined;
    }
  }

  private getTransceiverDirection(): RTCRtpTransceiverDirection | undefined {
    if ("getTransceivers" in RTCPeerConnection.prototype) {
      if (this.peerConnection) {
        const transceivers = this.peerConnection.getTransceivers();
        if (transceivers.length) {
          return transceivers[0].direction;
        }
      }
    } else {
      return this._transceiverDirection;
    }
  }

  private setTransceiverDirection(sdp: string): void {
    if ("getTransceivers" in RTCPeerConnection.prototype) {
      return;
    }
    const match = sdp.match(/a=(sendrecv|sendonly|recvonly|inactive)/);
    // eslint-disable-next-line no-null/no-null
    if (match === null) {
      this._transceiverDirection = undefined;
      return;
    }
    const direction = match[1];
    switch (direction) {
      case "sendrecv":
      case "sendonly":
      case "recvonly":
      case "inactive":
        this._transceiverDirection = direction;
        break;
      default:
        this._transceiverDirection = undefined;
        break;
    }
  }

  private stripMediaDescription(sdp: string, description: string): string {
    const descriptionRegExp = new RegExp("m=" + description + ".*$", "gm");
    if (descriptionRegExp.test(sdp)) {
      const midRegExp = new RegExp("^a=mid:(.+)$", "gm"),
        removedMids: Array<string> = [];
      sdp = sdp
        .split(/^m=/gm)
        .filter((section: string) => {
          const toBeRemoved = section.substring(0, description.length) === description;
          if (toBeRemoved) {
            const midLines = section.match(midRegExp);
            if (midLines && midLines.length > 0) {
              midLines.forEach(midLine => {
                const midNumberArray = midRegExp.exec(midLine);
                if (midNumberArray && midNumberArray.length > 1) {
                  removedMids.push(midNumberArray[1]);
                }
              });
            }
          }
          return !toBeRemoved;
        })
        .join("m=");

      if (removedMids.length > 0) {
        removedMids.forEach(removedMid => {
          const bundleRegExp = new RegExp("^a=group:BUNDLE.*$", "m");
          const bundleLine = sdp.match(bundleRegExp);
          if (bundleLine && bundleLine.length > 0) {
            const numberIndex = bundleLine[0].indexOf(" " + removedMid);
            if (numberIndex >= 0) {
              const changedBundleLine =
                bundleLine[0].slice(0, numberIndex) + bundleLine[0].slice(numberIndex + 2);
              sdp = sdp.replace(bundleLine[0], changedBundleLine);
            }
          }
        });
      }
    }
    return sdp;
  }

  private stripVideo(description: RTCSessionDescriptionInit): RTCSessionDescriptionInit {
    description.sdp = this.stripMediaDescription(description.sdp as string, "video");
    return description;
  }

  private stripApplication(description: RTCSessionDescriptionInit): RTCSessionDescriptionInit {
    description.sdp = this.stripMediaDescription(description.sdp as string, "application");
    return description;
  }

  private enableTracks(enable: boolean, type: "sender" | "receiver", kind: "audio" | "video") {
    log.debug(`SessionDescriptionHandler.enableTracks enable=${enable} type=${type} kind=${kind}`);
    if (!this.peerConnection) throw new Error("Peer connection undefined.");

    if (
      "getSenders" in RTCPeerConnection.prototype &&
      "getReceivers" in RTCPeerConnection.prototype
    ) {
      switch (type) {
        case "sender":
          this.peerConnection.getSenders().forEach(sender => {
            if (sender.track && sender.track.kind === kind && sender.track.enabled !== enable) {
              sender.track.enabled = enable;
            }
          });
          break;
        case "receiver":
          this.peerConnection.getReceivers().forEach(receiver => {
            if (
              receiver.track &&
              receiver.track.kind === kind &&
              receiver.track.enabled !== enable
            ) {
              receiver.track.enabled = enable;
            }
          });
          break;
        default:
          throw new Error("Unknown track type.");
      }
      return;
    }

    // Deprecated
    if (
      "getLocalStreams" in RTCPeerConnection.prototype &&
      "getRemoteStreams" in RTCPeerConnection.prototype
    ) {
      switch (type) {
        case "sender":
          this.peerConnection.getLocalStreams().forEach(stream => {
            const tracks = stream.getTracks();
            tracks.forEach(track => {
              if (track.enabled !== enable) {
                track.enabled = enable;
              }
            });
          });
          break;
        case "receiver":
          this.peerConnection.getLocalStreams().forEach(stream => {
            const tracks = stream.getTracks();
            tracks.forEach(track => {
              if (track.enabled !== enable) {
                track.enabled = enable;
              }
            });
          });
          break;
        default:
          throw new Error("Unknown track type.");
      }

      return;
    }

    throw new Error("Failed to enable/disable tracks.");
  }
}
