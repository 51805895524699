import { Component, OnDestroy, OnInit, inject } from "@angular/core";
import { AppCallingService } from "../../shared/services/appCalling/app-calling.service";
import {
  BehaviorSubject,
  Subscription,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith
} from "rxjs";
import { SupportService } from "@onsip/common/services/support/support.service";
import { AutoAnswerService } from "../../shared/services/autoAnswer/auto-answer.service";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { CallControllerService } from "@onsip/common/services/call-controller.service";
import { LocalStorageService } from "../../shared/services/localStorage/local-storage.service";
import { IdentityService } from "@onsip/common/services/identity.service";
import { FormControl } from "@angular/forms";
import { MatSelectChange } from "@angular/material/select";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";

@Component({
  selector: "onsip-call-preferences",
  templateUrl: "./call-preferences.component.html"
})
export class CallPreferencesComponent implements OnInit, OnDestroy {
  appCallingService = inject(AppCallingService);
  supportService = inject(SupportService);
  autoAnswerService = inject(AutoAnswerService);
  callControllerService = inject(CallControllerService);
  localStorageService = inject(LocalStorageService);
  identityService = inject(IdentityService);
  userService = inject(UserService);
  private unsubscriber = new Subscription();

  defaultUAFormControl = new FormControl({ value: "", disabled: false });

  appCallingEnabled$ = this.appCallingService.state.pipe(map(state => state.enabled));

  autoAnswerEnabled$ = this.autoAnswerService.state.pipe(map(state => state.enabled));

  dndStream$ = this.callControllerService.getDoNotDisturbObservable();

  userAgentStates$ = this.callControllerService.state.pipe(
    map(state => state.userAgents),
    startWith([])
  );

  authUsername$ = this.userService.selfUser.pipe(map(res => res.username));
  authPassword$ = this.userService.selfUser.pipe(map(res => res.password));

  isWebrtcSupported = this.supportService.isWebrtcSupported();

  isChrome = this.supportService.getBrowser().name === "chrome";

  showAuthPassword = false;

  loadingStateComplete$ = new BehaviorSubject(true);

  ngOnInit(): void {
    this.handleFormControl();
    this.handleDisableStatusSelectSipControl();
    this.handleUserAgentChanges();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  toggleAutoAnswer(shouldEnable: MatSlideToggleChange): void {
    if (shouldEnable.checked) {
      this.autoAnswerService.enable();
    } else {
      this.autoAnswerService.disable();
    }
  }

  toggleDesktopMode(shouldEnable: MatSlideToggleChange): void {
    if (shouldEnable.checked) {
      this.appCallingService.enable();
    } else {
      this.appCallingService.disable();
    }
  }

  /** Do Not Disturb Slider callback */
  toggleDnd(sliderChange: MatSlideToggleChange) {
    this.callControllerService.setDoNotDisturb(sliderChange.checked);
    this.localStorageService.setDoNotDisturb(sliderChange.checked);
  }

  toggleRegistration(aor: string, register: boolean): void {
    this.callControllerService.switchUserAgentRegisterState(aor, register);
    this.localStorageService.setUserAgent(aor, { register });
  }

  changeUA(event: MatSelectChange): void {
    const aor = event.value;
    if (this.callControllerService.hasUserAgent(aor)) {
      this.identityService.setDefaultIdentity(aor);
    }
  }

  handleFormControl() {
    this.unsubscriber.add(
      this.identityService.state
        .pipe(
          filter(state => state.addresses.length > 0),
          map(res => res.defaultIdentity),
          distinctUntilChanged(),
          debounceTime(200)
        )
        .subscribe(res => {
          this.defaultUAFormControl.setValue(res?.aor as string);
          this.defaultUAFormControl.updateValueAndValidity();
        })
    );
  }

  handleDisableStatusSelectSipControl() {
    this.unsubscriber.add(
      this.appCallingEnabled$.subscribe(appCallingEnabled => {
        if (!this.isWebrtcSupported || !appCallingEnabled) {
          this.defaultUAFormControl.disable();
        } else {
          this.defaultUAFormControl.enable();
        }
        this.defaultUAFormControl.updateValueAndValidity();
      })
    );
  }

  private handleUserAgentChanges() {
    this.unsubscriber.add(
      this.callControllerService.state
        .pipe(map(state => state.userAgents))
        .subscribe(userAgents => {
          this.loadingStateComplete$.next(!!userAgents.length);
        })
    );
  }
}
