<section class="connection-animation">
  <onsip-animation filename="error" preserveAspectRatio="xMinYMid slice"></onsip-animation>
</section>
<h3 mat-dialog-title>{{ "ONSIP_I18N.UHOH_YOURE_USING_AN_UNSUPPORTED_BROWSER" | translate }}</h3>
<ng-container>
  <mat-divider onsip-dotted-divider></mat-divider>
  <div class="connection-message mat-body-2" mat-dialog-content>
    <span>{{ "ONSIP_I18N.PLEASE_DOWNLOAD_AND_RUN_THE_LATEST_VERSION_OF" | translate }}</span>
    <ng-container *ngTemplateOutlet="browserLink"></ng-container>
    <span>{{ "ONSIP_I18N.TO_CONTINUE" | translate }}</span>
  </div>
</ng-container>

<ng-template #browserLink>
  <ng-container>
    <a class="download-link" href="https://www.google.com/chrome/">
      <span> Google Chrome </span>
    </a>
  </ng-container>
</ng-template>
