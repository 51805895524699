export const params = {
  AUTH: {
    SERVICE_ID: "GoogleLogin",
    CLIENT_ID: {
      PROD: "507668675033-qg6gglbjm3s7jglhn5duehtp5qust3dg.apps.googleusercontent.com",
      DEV: "507668675033-rh1d60qn731oib4tcf4dh4nuol3mspfl.apps.googleusercontent.com"
    },
    SCOPE: ["openid email"],
    ENDPOINT: "https://accounts.google.com/o/oauth2/v2/auth",
    WINDOW_HEIGHT: "800",
    WINDOW_WIDTH: "800"
  }
};
