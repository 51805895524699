import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { StateEmitterArray } from "../../emitter/state-emitter-array";
import { TopicInstances } from "./topic-instance";
import { UserPresenceListener, UserPresenceState } from "./user-presence-listener";
import { firebase } from "../cloud/firebase/platform-firebase-types";
const debug = false;

export class TopicPresenceListener extends StateEmitterArray<
  UserPresenceListener,
  UserPresenceState
> {
  private listenCallback: ((a: firebase.database.DataSnapshot | null) => any) | undefined =
    undefined;
  private listenRef: firebase.database.Reference;

  static makelistenRef(oid: number, tid: string): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/topics/${tid}`);
  }

  /**
   * Constructor
   * @param oid The organization id of the Topics.
   * @param tid The topic id on which to listen for Users.
   */
  constructor(private oid: number, private tid: string) {
    super();
    this.listenRef = TopicPresenceListener.makelistenRef(this.oid, this.tid);
  }

  dispose(): void {
    debug && console.log(`TopicPresenceListener[${this.id}].dispose`);
    this.publishComplete();
    this.stop();
  }

  start(): void {
    if (this.listenCallback) {
      this.stop();
    }
    this.listenCallback = snap => {
      // eslint-disable-next-line no-null/no-null
      if (snap === null || snap.val() === null) {
        this.updateState(undefined);
      } else if (snap.val()) {
        this.updateState(snap.val());
      } else {
        throw new TypeError("Invalid value.");
      }
    };
    this.listenRef.on("value", this.listenCallback, (error: any) => {
      debug && console.error(`TopicPresenceListener[${this.id}].listenRef error`);
      debug && console.error(error);
      throw error;
    });
  }

  stop(): void {
    if (this.listenCallback) {
      this.listenRef.off("value", this.listenCallback);
      this.listenCallback = undefined;
      this.array.forEach(listener => {
        listener.dispose(); // will publish complete (removing itself)
      });
    }
  }

  private updateState(value: TopicInstances | undefined): void {
    debug && console.log(`TopicPresenceListener[${this.id}].updateState`);

    // special case no Topics
    if (value === undefined) {
      this.array.forEach(listener => {
        listener.dispose(); // will publish complete and remove itself
      });
      this.publishState();
      return;
    }

    // current Topic user ids (no duplicates)
    const topics = value;
    const topicUserIdsSet = new Set<number>();
    Object.keys(topics).forEach(key => {
      topicUserIdsSet.add(topics[key]);
    });
    const topicUserIds = Array.from(topicUserIdsSet.values());

    // current user presence listener user ids
    const listeners = this.array;
    const listenerUserIds = listeners.map(listener => listener.stateValue.uid);

    // dispose of user presence listeners for Topics that have gone away
    listeners
      .filter(listener => !topicUserIds.includes(listener.stateValue.uid))
      .forEach(listener => listener.dispose()); // will publish complete (removing itself)

    // add new user presence listeners for Topics that have shown up
    topicUserIds
      .filter(uid => !listenerUserIds.includes(uid))
      .forEach(uid => {
        const listener = new UserPresenceListener(this.oid, uid);
        listener.start();
        this.add(listener);
      });
  }

  private get id(): string {
    return `${this.tid}`;
  }
}
