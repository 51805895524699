<onsip-header-main>
  <onsip-header-page-title>Search</onsip-header-page-title>
</onsip-header-main>
<div class="search-container">
  <form [formGroup]="formGroup" (ngSubmit)="submit()">
    <mat-radio-group class="search-filters" formControlName="filterSelect">
      @for (option of filterOptions; track option) {
      <mat-radio-button color="primary" [value]="option">
        {{ option }}
      </mat-radio-button>
      }
    </mat-radio-group>
    <div class="search-input">
      <mat-form-field appearance="outline" onsip-form-field-flush-outline>
        <input matInput formControlName="searchQuery" />
        <button mat-icon-button color="primary" matSuffix>
          <mat-icon>search</mat-icon>
        </button>
        <mat-error>Search field is required.</mat-error>
      </mat-form-field>
    </div>
  </form>
  @if (showAdvancedSearch) {
  <onsip-super-user-advanced-search
    [isLoading]="isLoading"
    (closeSearch)="handleCloseSearch($event)"
    [savedFormValue]="savedAdvancedForm"
  >
  </onsip-super-user-advanced-search>
  } @else {
  <div class="advanced-search">
    <button
      mat-button
      color="primary"
      class="mat-body-1 advanced-search-button"
      (click)="openAdvancedSearch()"
    >
      Advanced search
    </button>
  </div>
  }
</div>
@if ((tableHasFetched | async)!) {
<onsip-super-user-table [data]="(tableData | async)!" [tableType]="tableType">
</onsip-super-user-table>
}
