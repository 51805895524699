import { Injectable, NgZone } from "@angular/core";
import { UsersStoreService } from "../../../../../common/services/sayso/users-store.service";
import { WebCallTopicService } from "../../../../../common/services/api/resources/webCallTopic/web-call-topic.service";

import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { isAdminRole } from "../../../../../common/services/api/role";

import { combineLatest, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ContactService } from "@onsip/common/services/contact/contact.service";

/** Extends UsersStore service for web
 *  The init method subscribes to any lists of users we wish to add/remove firestore listeners to.
 *  State contains an array of all users that we need Global Availablity data for in the app. e.g. contacts & sayso reps
 */

@Injectable({ providedIn: "root" })
export class UsersStoreSubscriptionService extends UsersStoreService {
  constructor(
    zone: NgZone,
    private userService: UserService,
    private contactService: ContactService,
    private webCallTopicService: WebCallTopicService
  ) {
    super(zone);
    this.init();
  }

  private init() {
    // we need to get the user first or we will have permission errors when accessing the webcalltopic service
    this.unsubscriber.add(
      this.userService.selfUser
        .pipe(
          switchMap(user => {
            const isAdmin = of(isAdminRole(user.roles));
            const orgIdObservable = of(parseInt(user.organizationId));
            const configUsersObservable = this.webCallTopicService.getAllRepUserIds();
            const contactUsersObservable = this.contactService.getAllContactUserIds();

            // Subscibe to all userid lists, create min set of uids, add/remove as necessary
            return combineLatest({
              orgId: orgIdObservable,
              configUsers: configUsersObservable,
              contactUsers: contactUsersObservable,
              isAdmin
            });
          })
        )
        .subscribe(({ orgId, configUsers, contactUsers, isAdmin }) => {
          let allUserIds: Array<string> = [];
          allUserIds = allUserIds.concat(contactUsers);
          if (isAdmin) allUserIds = allUserIds.concat(configUsers); // Check privileges so only admins listen to sayso reps
          allUserIds = allUserIds.filter((v, i, a) => a.indexOf(v) === i); // filter duplicate userIds from various sources
          const currentUserIds = this.stateStore.map(user => user.id); // currently have a listeners
          const removals = currentUserIds.filter(id => allUserIds.indexOf(id) < 0);
          const additions = allUserIds.filter(id => currentUserIds.indexOf(id) < 0);
          removals.forEach(id => this.removeUser(id));
          additions.forEach(id => this.addUser(orgId, Number(id)));
        })
    );
  }
}
