import { CallEvent, EventId, EndCallEventReason } from "./call-event";

/** Emitted once when Call has ended. */
export class EndCallEvent extends CallEvent {
  id = EventId.EndCallEventId;

  /**
   * Factory for EndCallEvent.
   * @param uuid The uuid of the call.
   * @param reason The reason the call ended.
   */
  constructor(uuid: string, reason: EndCallEventReason) {
    super(isEndCallEvent, uuid, reason);
  }
}

/**
 * User-Defined Type Guard for EndCallEvent.
 * @param event Event to check.
 */
export function isEndCallEvent(event: any): event is EndCallEvent {
  return event instanceof EndCallEvent;
}
