import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { AnimationModule } from "../../animation/animation.module";

import { ConnectionIssueModalComponent } from "./connection-issue.component";

@NgModule({
  imports: [
    AnimationModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    MaterialModule,
    TranslateModule
  ],
  declarations: [ConnectionIssueModalComponent],
  exports: [ConnectionIssueModalComponent]
})
export class ConnectionIssueModule {}
