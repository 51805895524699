import { OperatorFunction } from "rxjs";
import { filter, map, withLatestFrom, distinctUntilChanged } from "rxjs/operators";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { Organization } from "../resources/organization/organization";
import { organizationId } from "./organization-id";

export function currentOrganization(): OperatorFunction<ApiStateStoreState, Organization> {
  return storeState =>
    storeState.pipe(
      withLatestFrom(storeState.pipe(organizationId())),
      map(([store, orgId]) => (store.Organization as ApiSubstate<Organization>)?.state[orgId]),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged((prev, curr) => prev?.organizationId === curr?.organizationId)
    );
}
