import { Log } from "./sip/log";
import { SentryErrorHandler } from "../services/sentry-error.handler";
import { LogService } from "../services/logging";

/**
 * Generic Exponenential Backoff implementation.
 * @param callback Callback to be attempted until completed or retries exausted
 * @param errorCodes Array of error codes on which retry is permitted
 * @param functionName Human readable name of callback (for log)
 * @param logger Reference to logger
 * @param sentryOnRetry Flag if true: send a message to sentry on each retry
 * @param retryAllErrors throws out errorCodes array and retries no matter what (use w. caution)
 * @param retries maximum number of retries
 */
export async function exponentialBackoff<T>(
  callback: () => Promise<any>,
  errorCodes: Array<T>,
  functionName: string,
  logger: Log | LogService,
  sentryOnRetry: boolean = false,
  retryAllErrors: boolean = false,
  retries: number = 5
): Promise<void> {
  for (let i = 0; i <= retries; i++) {
    await callback()
      .then(() => {
        logger.debug(`ExponentialBackoff ${functionName} completed`);
        i = retries + 1; // break out of loop if sign in is successful
      })
      .catch(async err => {
        // we want to retry after exponentially growing amount of delay in between tries
        // using simple exponential growth of (2 ^ i + random milliseconds) where i is the current number of retries
        if (i < retries && (retryAllErrors || (err && errorCodes.includes(err.code)))) {
          logger.debug(`Attempt ${functionName} ${i + 1} times`);
          sentryOnRetry &&
            SentryErrorHandler.sendMessage(`${functionName} failed - trying again`, "Info");
          await new Promise(resolve =>
            setTimeout(resolve, 2 ** i * 1000 + Math.floor(Math.random() * 1000))
          );
        } else {
          logger.error(
            `${functionName} failed even after ${i} tries with error: ${JSON.stringify(err)}`
          );
          i = retries + 1; // essentially breaks the for loop if do not catch the retry error conditions we are looking for
          throw err;
        }
      });
  }
}
