@if ((hasStarted | async) === false && anonymous) {
<onsip-new-conference
  class="active"
  [anonymous]="anonymous"
  [fromDisplay]="fromDisplay"
  [conferenceUri]="conferenceUri"
  [conferenceName]="conferenceName"
  [header]="header"
  [subHeader]="subHeader"
  [instructions]="instructions"
>
</onsip-new-conference>
} @if (hasStarted | async) {
<onsip-video-conference> </onsip-video-conference>
}
