import { OperatorFunction, of } from "rxjs";
import { map, distinctUntilChanged, withLatestFrom } from "rxjs/operators";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { UserCustomization } from "../resources/userCustomization/user-customization";
import { UserInfo } from "../apiResponse/user-info";

import { userId } from "./user-id";

/** gives the personal page info for a given userId, if no userId is supplied it uses the self user */
export function blurb(
  givenUserId?: string
): OperatorFunction<ApiStateStoreState, UserInfo["personalPageInfo"]["blurb"]> {
  return storeState => {
    const userIdToFindObs = givenUserId ? of(givenUserId) : storeState.pipe(userId());
    return storeState.pipe(
      withLatestFrom(userIdToFindObs),
      map(([store, userIdToFind]) => {
        return (
          Object.values((store.UserCustomization as ApiSubstate<UserCustomization>).state).find(
            userCustomization => userCustomization.userId === userIdToFind
          )?.userInfo?.personalPageInfo?.blurb || ""
        );
      }),
      distinctUntilChanged()
    );
  };
}

/** gives the personal page info for a given userId, if no userId is supplied it uses the self user */
export function facebook(
  givenUserId?: string
): OperatorFunction<ApiStateStoreState, UserInfo["personalPageInfo"]["facebook"]> {
  return storeState => {
    const userIdToFindObs = givenUserId ? of(givenUserId) : storeState.pipe(userId());
    return storeState.pipe(
      withLatestFrom(userIdToFindObs),
      map(([store, userIdToFind]) => {
        return (
          Object.values((store.UserCustomization as ApiSubstate<UserCustomization>).state).find(
            userCustomization => userCustomization.userId === userIdToFind
          )?.userInfo?.personalPageInfo?.facebook || ""
        );
      }),
      distinctUntilChanged()
    );
  };
}

/** gives the personal page info for a given userId, if no userId is supplied it uses the self user */
export function linkedin(
  givenUserId?: string
): OperatorFunction<ApiStateStoreState, UserInfo["personalPageInfo"]["linkedin"]> {
  return storeState => {
    const userIdToFindObs = givenUserId ? of(givenUserId) : storeState.pipe(userId());
    return storeState.pipe(
      withLatestFrom(userIdToFindObs),
      map(([store, userIdToFind]) => {
        return (
          Object.values((store.UserCustomization as ApiSubstate<UserCustomization>).state).find(
            userCustomization => userCustomization.userId === userIdToFind
          )?.userInfo?.personalPageInfo?.linkedin || ""
        );
      }),
      distinctUntilChanged()
    );
  };
}

/** gives the personal page info for a given userId, if no userId is supplied it uses the self user */
export function title(
  givenUserId?: string
): OperatorFunction<ApiStateStoreState, UserInfo["personalPageInfo"]["title"]> {
  return storeState => {
    const userIdToFindObs = givenUserId ? of(givenUserId) : storeState.pipe(userId());
    return storeState.pipe(
      withLatestFrom(userIdToFindObs),
      map(([store, userIdToFind]) => {
        return (
          Object.values((store.UserCustomization as ApiSubstate<UserCustomization>).state).find(
            userCustomization => userCustomization.userId === userIdToFind
          )?.userInfo?.personalPageInfo?.title || ""
        );
      }),
      distinctUntilChanged()
    );
  };
}

/** gives the personal page info for a given userId, if no userId is supplied it uses the self user */
export function twitter(
  givenUserId?: string
): OperatorFunction<ApiStateStoreState, UserInfo["personalPageInfo"]["twitter"]> {
  return storeState => {
    const userIdToFindObs = givenUserId ? of(givenUserId) : storeState.pipe(userId());
    return storeState.pipe(
      withLatestFrom(userIdToFindObs),
      map(([store, userIdToFind]) => {
        return (
          Object.values((store.UserCustomization as ApiSubstate<UserCustomization>).state).find(
            userCustomization => userCustomization.userId === userIdToFind
          )?.userInfo?.personalPageInfo?.twitter || ""
        );
      }),
      distinctUntilChanged()
    );
  };
}
