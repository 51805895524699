import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { StateEmitter } from "../../emitter/state-emitter";
import { firebase } from "../cloud/firebase/platform-firebase-types";
const debug = false;

export interface PresencePublisherState {
  status: "init" | "connected" | "disconnected";
}

/**
 * This abstract class serves a the base class for publishing presence.
 *
 * To publish presence, call publish(). When a connection is established get value() will be called
 * to and is expected to return value to be published. It will be called everytime the connection is
 * dropped and re-established (the value will be automatically removed when the connection drops.
 *
 * TODO: On some error cses there are some thrown Errors in response to firebase.database.Reference.on()
 * returning an error which will likely go uncaught. I'm not clear when they could occor as the
 * documentation does enumerate the error cases, so for now it's just throw and see what we find out.
 * Not great.
 */
export abstract class PresencePublisher extends StateEmitter<PresencePublisherState> {
  private connectedRef: firebase.database.Reference = PlatformFirebase.firebase
    .database()
    .ref(".info/connected");
  private connectedRefListenerCallback:
    | ((a: firebase.database.DataSnapshot | null) => any)
    | undefined = undefined;
  private publishRefListenerCallback:
    | ((a: firebase.database.DataSnapshot | null) => any)
    | undefined = undefined;

  /**
   * Constructor
   * @param oid The organization id of the user.
   * @param uid The user id.
   */
  constructor(protected publishRef: firebase.database.Reference) {
    super({ status: "init" });
  }

  dispose(): void {
    this.publishStateComplete();
    this.unpublish();
  }

  /** The key of the publshed value. */
  get key(): string {
    const _key = this.publishRef.key;
    if (!_key) {
      throw new Error("key undefined.");
    }
    return _key;
  }

  publish(): void {
    debug && console.log(`${this.id}.publish`);
    this.listenConnectedRef();
    this.listenPublishRef();
  }

  unpublish(): void {
    debug && console.log(`${this.id}.unpublish`);
    this.unlistenConnectedRef();
    this.unlistenPublishRef();
  }

  protected get id(): string {
    return `PresencePublisher[${this.publishRef.key}]`;
  }

  protected abstract get value(): any;

  private listenConnectedRef(): void {
    if (this.connectedRefListenerCallback) {
      this.connectedRef.off("value", this.connectedRefListenerCallback);
      this.connectedRefListenerCallback = undefined;
    }
    this.connectedRefListenerCallback = snap => {
      // eslint-disable-next-line no-null/no-null
      if (snap === null || snap.val() === false) {
        debug && console.log(`${this.id}.disconnected`);
        this.stateStore.status = "disconnected";
        this.publishState();
      } else if (snap.val() === true) {
        debug && console.log(`${this.id}.connected`);
        // Use the 'onDisconnect()' method to add a remove which will only trigger once this client
        // has disconnected by closing the app, losing internet, or any other means.
        // The promise returned from .onDisconnect().remove() will
        // resolve as soon as the server acknowledges the onDisconnect()
        // request, NOT once we've actually disconnected:
        // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect
        // Then we can safely set ourselves as 'online' knowing that the
        // server will mark us as 'offline' once we lose connection.
        this.publishRef
          .onDisconnect()
          .remove()
          .then(() => {
            // Make sure callback still exists as unlistenConnectedRef() could have
            // been called while we are waiting for this to resolve in which case we
            // would otherwise undermine it by adding back the value it just removed.
            if (this.connectedRefListenerCallback) {
              this.publishRef.set(this.value);
              this.stateStore.status = "connected";
              this.publishState();
            }
          })
          .catch(error => {
            debug && console.error(`${this.id}.connected error`);
            debug && console.error(error);
            throw error;
          });
      } else {
        throw new TypeError("Invalid value.");
      }
    };
    this.connectedRef.on("value", this.connectedRefListenerCallback, (error: any) => {
      debug && console.error(`${this.id}.listenConnectedRef error`);
      debug && console.error(error);
      throw error;
    });
  }

  private listenPublishRef(): void {
    // Note: Emperically the listener for .info/connected stops listening
    // if there is no active action or listener for the database. The
    // ./info/connected is not something that exists database side, it is
    // client side thing keeping track of the SDKs connection state.
    // So you in short you need to insure you are listening to something
    // serer side for this to keep working. For example, the server side can
    // drop the connection and the listner will not fire unless something else
    // is being listened to. So here we setup a listenter on the publishRef
    // to make sure we have something listening. In most applications, sonething
    // else will be listenting on something, so this is going to be a redundant listener,
    // but it's a confusing, unexpected and hard to debug thing when it occurs...
    // For more info see:
    // https://github.com/firebase/firebase-js-sdk/issues/434
    // https://stackoverflow.com/questions/47265074/firebase-listener-does-not-identify-or-resume-connection-after-idle-time
    if (this.publishRefListenerCallback) {
      this.publishRef.off("value", this.publishRefListenerCallback);
      this.publishRefListenerCallback = undefined;
    }
    this.publishRefListenerCallback = () => {
      debug && console.log(`${this.id}.change`);
    };
    this.publishRef.on("value", this.publishRefListenerCallback, (error: any) => {
      debug && console.error(`${this.id}.listenPublishRef error`);
      debug && console.error(error);
      throw error;
    });
  }

  private unlistenConnectedRef(): void {
    if (this.connectedRefListenerCallback) {
      this.connectedRef.off("value", this.connectedRefListenerCallback);
      this.connectedRefListenerCallback = undefined;
      this.publishRef.remove();
      this.stateStore.status = "init";
      this.publishState();
    }
  }

  private unlistenPublishRef(): void {
    if (this.publishRefListenerCallback) {
      this.publishRef.off("value", this.publishRefListenerCallback);
      this.publishRefListenerCallback = undefined;
    }
  }
}
