import { DocumentData } from "../cloud/firebase/firestore/document";
import { TimestampedState, makeTimestampedState } from "./state";
import { getOrElse } from "./maybe";

export type UserAvailability = "undefined" | "unavailable" | "available";

export interface UserData {
  /** User's AOR. */
  aor: string;
  /** User's Global Manual Availability */
  availability: UserAvailability;
  /** User's contact name. */
  name: string;
  /** User's topics. */
  topics: Array<string>;
  /** Url for the user's avatar. */
  picture?: string;
  /** The displayable title for the user. */
  title?: string;
}

export function makeUserData(data: DocumentData): UserData {
  const picture = getOrElse<UserData["picture"]>(data.picture, undefined),
    title = getOrElse<UserData["title"]>(data.title, undefined),
    userData: UserData = {
      aor: getOrElse<UserData["name"]>(data.aor, ""),
      availability: getOrElse<UserData["availability"]>(data.availability, "undefined"),
      name: getOrElse<UserData["name"]>(data.name, ""),
      topics: getOrElse<UserData["topics"]>(data.topics, [])
    };
  if (picture) userData.picture = picture;
  if (title) userData.title = title;
  return userData;
}

export interface UserState extends TimestampedState, UserData {}

export function makeUserState(id: string, data: DocumentData): UserState {
  return {
    ...makeTimestampedState(id, data),
    ...makeUserData(data)
  };
}
