import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { PresencePublisher } from "./presence-publisher";
import { TopicInstance } from "./topic-instance";
import { firebase } from "../cloud/firebase/platform-firebase-types";
export class TopicPresencePublisher extends PresencePublisher {
  private static makePublishRef(oid: number, tid: string): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`orgs/${oid}/topics/${tid}`).push();
  }

  /**
   * Constructor
   * @param oid The organization id of the Topic.
   * @param tid The Topic id.
   * @param uid The User id.
   */
  constructor(oid: number, tid: string, private uid: number) {
    super(TopicPresencePublisher.makePublishRef(oid, tid));
  }

  protected get id(): string {
    return `TopicPresencePublisher[${this.publishRef.key}]`;
  }

  protected get value(): TopicInstance {
    return this.uid;
  }
}
