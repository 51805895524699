import { ValidatorFn, AbstractControl } from "@angular/forms";
import { E164PhoneNumber } from "../../../../common/libraries/e164-phone-number";
import { OnSIPURI } from "../../../../common/libraries/onsip-uri";

export function customAddressValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    let customAddress = control.value;
    let uri: boolean | OnSIPURI | undefined;
    if (customAddress && customAddress.indexOf("@") === -1) {
      uri = new E164PhoneNumber(customAddress).isValid;
    } else {
      if (customAddress && !customAddress.startsWith("sip:")) {
        customAddress = "sip:" + customAddress;
        uri = OnSIPURI.parseString(customAddress);
      }
    }
    // eslint-disable-next-line no-null/no-null
    return uri ? null : { uri, invalidAddress: { name: control.value } };
  };
}
