import { LogTargetOptions } from "./log-target-options";
import { LogTarget, LogEvent } from "./log.target";

export abstract class LogTargetBase implements LogTarget {
  constructor(protected options: LogTargetOptions) {}

  log(event: LogEvent): Promise<any> {
    if (event.level >= this.options.minLogLevel) {
      return this.writeToLog(event);
    }
    return Promise.resolve();
  }

  protected abstract writeToLog(event: LogEvent): Promise<any>;
}
