import { Injectable } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";
import {
  ApiPromiseState,
  ApiPromiseStateService
} from "@onsip/common/services/api/api-promise-state.service";
import { ApiResourceService } from "@onsip/common/services/api/resources/api-resource.service";
import { OnsipApiResponse } from "@onsip/common/services/api/apiResponse/response-body-new";
import { ApiEditAction } from "@onsip/common/services/api/api-actions";
import { getApiActionName } from "@onsip/common/services/api/onsip-api-action-new";
import { userId } from "../../apiParams/user-id";
import { sessionId } from "../../apiParams/session-id";
import { ApiUser } from "../user/user";

const debug = false;

declare interface UserEditPortalPassword {
  UserEditPortalPassword: {
    User: ApiUser;
  };
}

@Injectable({ providedIn: "root" })
export class UserEditPortalPasswordService extends ApiResourceService<UserEditPortalPassword> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "UserEditPortalPassword", "UserEditPortalPassword");
    debug && this.state.subscribe(state => console.warn("UserEditPortalPasswordService", state));
  }

  userEditPortalPassword(params: {
    CurrentPortalPassword: string;
    NewPortalPassword: string;
    NewPortalPasswordConfirm: string;
  }): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.UserEditPortalPassword,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiEditAction.UserEditPortalPassword);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiEditAction.UserEditPortalPassword:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
