import { NgModule } from "@angular/core";

import { FixedWidthCardListComponent } from "./fixedWidthCardList/fixed-width-card-list.component";
import { HeaderModule } from "./header/header.module";
import { ThreeColumnModule } from "./threeColumn/three-column.module";

@NgModule({
  imports: [HeaderModule, ThreeColumnModule],
  declarations: [FixedWidthCardListComponent],
  exports: [FixedWidthCardListComponent, HeaderModule, ThreeColumnModule]
})
export class LayoutModule {}
