import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

import { VideoBoxComponent } from "./video-box.component";

@NgModule({
  imports: [BrowserModule, CommonModule, FormsModule],
  declarations: [VideoBoxComponent],
  exports: [VideoBoxComponent]
})
export class VideoBoxModule {}
