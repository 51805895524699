import {
  Component,
  Input,
  OnInit,
  OnChanges,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy
} from "@angular/core";

import { VolumeService } from "../volume.service";
import { CallVolumeEvent } from "../volume-event";
import { CallAudioService, AudioInfo } from "../../../../call/call-audio.service";

import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

@Component({
  selector: "onsip-volume-slider",
  templateUrl: "./volume-slider.component.html",
  styleUrls: ["volume-slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VolumeSliderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() call: string | undefined;
  @Input() voicemail = false;
  @Input() vertical = false;
  @Input() color: "primary" | "accent" | "warn" = "primary";

  @Output() changeEmitter = new EventEmitter<number>();

  volume!: number;
  private mutedVolume: number | undefined;
  private defaultVolume = 75;
  private unsubscriber = new Subscription();

  constructor(private volumeService: VolumeService, private callAudioService: CallAudioService) {}

  ngOnInit(): void {
    const audioInfo: AudioInfo | undefined = this.call
      ? this.callAudioService.findInfo(this.call)
      : undefined;

    if (audioInfo && audioInfo.volume) {
      this.volume = audioInfo.volume;

      this.unsubscriber.add(
        this.volumeService.event
          .pipe(
            filter(
              event => event.id === CallVolumeEvent.id && !!this.call && event.uuid === this.call
            )
          )
          .subscribe(event => {
            this.volume = event.volume;
          })
      );
    } else {
      this.volume = this.defaultVolume;
    }

    this.unsubscriber.add(
      this.volumeService.state.subscribe(state => {
        this.defaultVolume = state.defaultVolume;
        this.volume = state.defaultVolume;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  ngOnChanges(changesObj: any): void {
    if (changesObj.call && this.call) {
      const audioInfo: AudioInfo = this.callAudioService.findInfo(this.call);

      if (audioInfo) {
        this.volume = audioInfo.volume;
      } else {
        this.volume = this.defaultVolume;
      }
    }
  }

  mute(): void {
    if (this.volume === 0) {
      // unmute
      this.volume = this.mutedVolume || 50;
      this.mutedVolume = undefined;
    } else {
      // mute
      this.mutedVolume = this.volume === 0 ? undefined : this.volume;
      this.volume = 0;
    }
    if (this.call) {
      this.volumeService.setCallVolume(this.call, this.volume);
    }
    this.emit(this.volume);
  }

  emit(value: number | null) {
    this.volume = value as number;
    if (this.call) {
      this.volumeService.setCallVolume(this.call, this.volume);
    } else if (this.voicemail) {
      this.volumeService.setVoicemailVolume(this.volume);
    } else {
      this.volumeService.setDefaultVolume(this.volume);
    }

    if (this.volume > 0) {
      // unmute (volume changed while muted)
      this.mutedVolume = undefined;
    }

    this.changeEmitter.emit(this.volume);
  }
}
