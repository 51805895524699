<onsip-header-main>
  <onsip-header-page-title>{{
    "ONSIP_I18N.APP_SETTINGS" | translate | sentenceCase
  }}</onsip-header-page-title>
</onsip-header-main>
<onsip-profile></onsip-profile>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.ACCOUNT_DETAILS" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="onsip-settings-card-background">
    <onsip-account-details class="onsip-settings-card-container"></onsip-account-details>
  </mat-card-content>
</mat-card>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.APP_CONTROLS" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="onsip-settings-card-background">
    <onsip-app-controls class="onsip-settings-card-container"></onsip-app-controls>
  </mat-card-content>
</mat-card>
<mat-card class="onsip-settings-card">
  <mat-card-header>
    <mat-card-title class="mat-h2">
      {{ "ONSIP_I18N.CALL_PREFERENCES" | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="onsip-settings-card-background">
    <onsip-call-preferences
      class="onsip-settings-card-container call-preferences-container"
    ></onsip-call-preferences>
  </mat-card-content>
</mat-card>
