declare let window: any;
declare let navigator: any;

import { Config } from "../../../common/config";

interface BrowserRule {
  name: string;
  rule: RegExp;
}

export class Support {
  // PLEASE NOTE: until the getters are used everywhere, these were made not-private until this service
  // becomes platform-independent, as many files will change
  browser: any = {};
  webrtcSupport = true;
  private devices: any;

  constructor(private log: any = console, supportIosSafari: boolean = false) {
    this.browser = this.platformBuildBrowser(supportIosSafari);
    this.devices = this.platformDetectDevices();

    this.webrtcSupport = this.platformHasWebrtcObjects() && this.browser.isSupported;
  }

  platformBuildBrowser(supportIosSafari: boolean): any {
    const browserObj: any = this.detect();

    if (Config.IS_WEB || Config.IS_DESKTOP) {
      let versionExp: RegExp | undefined;

      if (browserObj.name === "chrome") {
        /* Only Chrome 80 and up */
        versionExp = /\b([1][0-9][0-9]|[8-9][0-9])\b/;
      } else if (browserObj.name === "firefox") {
        /* Only Firefox 80 and up */
        versionExp = /\b([1][0-9][0-9]|[8-9][0-9])\b/;
      } else if (browserObj.name === "opera") {
        /* NO versions of Opera */
        versionExp = undefined;
      } else if (browserObj.name === "edge") {
        /* NO versions of Edge */
        versionExp = undefined;
      } else if (browserObj.name === "ie") {
        /* No versions of IE */
        versionExp = undefined;
      } else if (browserObj.name === "ios") {
        /* ios is tricky because chrome/firebox/safari all have the same versioning;
         * safari is far lower numbers, so this is a 'safe' guess
         */
        versionExp = supportIosSafari ? /^1[1-4]\.|^[0-9][0-9]/ : undefined;
      } else if (browserObj.name === "crios") {
        /** This is the os version on the iPad. Going to assume versions higher than 80 is safe */
        versionExp = supportIosSafari ? /\b([1][0-9][0-9]|[8-9][0-9])\b/ : undefined;
      } else if (browserObj.name === "safari") {
        /* Due to USB headset audio issue, we will not be supporting desktop safari */
        versionExp = undefined; // /^1[1-9]\.|^[0-9][0-9]/;
      }

      browserObj.isSupported = versionExp ? versionExp.test(browserObj.version) : false;
      browserObj.isOld = versionExp ? !browserObj.isSupported : false;

      // determine platform type and filter help overlays appropriately
      // adapted from http://www.javascripter.net/faq/operatin.htm
      if (navigator.appVersion.indexOf("Win") !== -1) {
        browserObj.platform = "win";
      } else if (navigator.appVersion.indexOf("Mac") !== -1) {
        browserObj.platform = "mac";
      }
    } else {
      browserObj.isSupported = true;
      browserObj.isOld = false;
    }

    return browserObj;
  }

  // mobile browser checks - these are standalone functions
  // https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
  isMobile(): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        navigator.userAgent || navigator.vendor || window.opera
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        (navigator.userAgent || navigator.vendor || window.opera).substr(0, 4)
      )
    ) {
      return true;
    }
    return false;
  }

  isMobileOrTablet(): boolean {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        navigator.userAgent || navigator.vendor || window.opera
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        (navigator.userAgent || navigator.vendor || window.opera).substr(0, 4)
      )
    ) {
      return true;
    }
    return false;
  }

  platformDetectDevices(): any {
    let constraints: any = {
      audioinput: true,
      videoinput: true
    };

    try {
      if (Config.IS_WEB || Config.IS_DESKTOP) {
        constraints = {
          audioinput: false,
          videoinput: false
        };

        navigator.mediaDevices.enumerateDevices().then((sources: Array<MediaDeviceInfo>) => {
          for (let i = 0; i < (sources || []).length; i++) {
            constraints[sources[i].kind] = true;
            this.log.info("found", sources[i].kind, "device");
          }
        });
      }
    } catch (e) {
      this.log.info("mediaDevices.enumerateDevices is either absent or exceptional");
    }

    return constraints;
  }

  getBrowser(): any {
    return this.browser;
  }

  isWebrtcSupported(): boolean {
    return this.webrtcSupport;
  }

  getDevices(): any {
    return this.devices;
  }

  private platformHasWebrtcObjects(): boolean {
    if (!Config.IS_WEB) {
      return true;
    }

    const getUserMedia: any =
        (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) ||
        navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia,
      RTCPeerConnection: any =
        window.webkitRTCPeerConnection || window.mozRTCPeerConnection || window.RTCPeerConnection,
      RTCSessionDescription: any =
        window.webkitRTCSessionDescription ||
        window.mozRTCSessionDescription ||
        window.RTCSessionDescription;

    return getUserMedia && RTCPeerConnection && RTCSessionDescription;
  }

  /* Browser detection modified from: https://github.com/DamonOehlman/detect-browser/blob/master/index.js*/

  private detect(): any {
    if (typeof navigator !== "undefined") {
      return this.parseUserAgent(navigator.userAgent);
    }
    return undefined;
  }

  private parseUserAgent(userAgentString: string): { name: string; version: string } | undefined {
    const browsers = this.getBrowserRules();
    if (!userAgentString) {
      return undefined;
    }

    const detected =
      browsers
        .map(browser => {
          const match = browser.rule.exec(userAgentString);
          let version = match && match[1].split(/[._]/).slice(0, 3);

          if (version && version.length < 3) {
            version = version.concat(version.length === 1 ? ["0", "0"] : ["0"]);
          }

          return (
            match && {
              name: browser.name,
              version: version ? version.join(".") : ""
            }
          );
        })
        .filter(Boolean)[0] || undefined;

    return detected;
  }

  private getBrowserRules(): Array<BrowserRule> {
    return this.buildRules([
      ["aol", /AOLShield\/([0-9._]+)/],
      ["edge", /Edge\/([0-9._]+)/],
      ["yandexbrowser", /YaBrowser\/([0-9._]+)/],
      ["vivaldi", /Vivaldi\/([0-9.]+)/],
      ["kakaotalk", /KAKAOTALK\s([0-9.]+)/],
      ["samsung", /SamsungBrowser\/([0-9.]+)/],
      ["chrome", /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/],
      ["phantomjs", /PhantomJS\/([0-9.]+)(:?\s|$)/],
      ["crios", /CriOS\/([0-9.]+)(:?\s|$)/],
      ["firefox", /Firefox\/([0-9.]+)(?:\s|$)/],
      ["fxios", /FxiOS\/([0-9.]+)/],
      ["opera", /Opera\/([0-9.]+)(?:\s|$)/],
      ["opera", /OPR\/([0-9.]+)(:?\s|$)$/],
      ["ie", /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/],
      ["ie", /MSIE\s([0-9.]+);.*Trident\/[4-7].0/],
      ["ie", /MSIE\s(7\.0)/],
      ["bb10", /BB10;\sTouch.*Version\/([0-9.]+)/],
      ["android", /Android\s([0-9.]+)/],
      ["ios", /Version\/([0-9._]+).*Mobile.*Safari.*/],
      ["safari", /Version\/([0-9._]+).*Safari/],
      ["facebook", /FBAV\/([0-9.]+)/],
      ["instagram", /Instagram ([0-9.]+)/]
    ]);
  }

  private buildRules(ruleTuples: Array<[string, RegExp]>): Array<BrowserRule> {
    return ruleTuples.map(tuple => {
      return {
        name: tuple[0],
        rule: tuple[1]
      };
    });
  }
}
