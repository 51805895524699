import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HighchartsChartModule } from "highcharts-angular";
import { ChartingLineGraphComponent } from "./line-graph.component";

@NgModule({
  imports: [CommonModule, HighchartsChartModule],
  declarations: [ChartingLineGraphComponent],
  exports: [ChartingLineGraphComponent]
})
export class ChartingLineGraphModule {}
