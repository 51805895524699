export declare type WebApiWebCallTopicReps =
  | { WebCallTopicRep: ApiWebCallTopicRep | Array<ApiWebCallTopicRep> }
  | "";

export declare interface ApiWebCallTopicRep {
  Created: string;
  Modified: string;
  /** a string of a number */
  Position: string;
  /** a string of a number */
  RepId: string;
  RepType: "user";
  WebCallTopicId: string;
}

export declare interface WebCallTopicRep {
  position: number;
  /** Foreign key- same as UserId in user in the jnctn database (MySQL) */
  repId: number;
  repType: "user";
}

export function apiWebCallTopicRepToWebCallTopicRep(
  apiWebCallTopicRep: ApiWebCallTopicRep
): WebCallTopicRep {
  return {
    position: parseInt(apiWebCallTopicRep.Position),
    repId: parseInt(apiWebCallTopicRep.RepId),
    repType: apiWebCallTopicRep.RepType
  };
}
