import { DateService } from "../../../../../../common/services/date.service";
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class ChatMessageService {
  constructor(private dateService: DateService) {}

  createChatMessage(chat: any, author: any, lines: any): any {
    const chatMessage: any = {
      chat,
      author,
      lines: [],
      fromName: (author && author.displayName) || "Chat"
    };

    this.addChatMessagePrototype(chatMessage);

    lines = lines.forEach ? lines : [lines];
    lines = lines.sort(chatMessage.sortFn);

    for (const line of lines) {
      chatMessage.lines.push(line);
    }

    chatMessage.update();

    return chatMessage;
  }

  private addChatMessagePrototype(chatMessage: any): void {
    // TODO: search for id when confirming pending lines?

    chatMessage.addLine = (line: any): void => {
      if (chatMessage.searchLines("timestamp", line.timestamp)) {
        // replace if editing
        chatMessage.lines[line.timestamp] = line;
      } else {
        // append
        chatMessage.lines.push(line);
        if (
          chatMessage.lines.length > 1 &&
          line.timestamp < chatMessage.lines[chatMessage.lines.length - 2].timestamp
        ) {
          // sort only if it doesn't belong on the end
          chatMessage.lines.sort(chatMessage.sortFn);
        }
      }
      chatMessage.update();
    };

    chatMessage.deleteLine = (event: any): void => {
      const deletedLine: any = chatMessage.searchLines("timestamp", event.timestamp);

      if (!deletedLine) {
        throw new Error("event not found");
      } else {
        chatMessage.lines.splice(chatMessage.lines.indexOf(deletedLine), 1);
      }
    };

    chatMessage.update = (): void => {
      const text: Array<any> = [];
      for (const line of chatMessage.lines) {
        if (
          line.text === "" &&
          ((line.data && line.data.files && line.data.files.length > 0) ||
            (line.data &&
              line.data.data &&
              line.data.data.files &&
              line.data.data.files.length > 0))
        ) {
          text.push("<i>File uploaded</i>");
        } else {
          text.push(line.text);
        }
      }
      chatMessage.text = text;

      chatMessage.timestamp = (chatMessage.lines && chatMessage.lines[0].timestamp) || 0;
      chatMessage.created = new Date(parseInt(chatMessage.timestamp) * 1000) || new Date();

      chatMessage.displayDate =
        this.dateService.displayDate(chatMessage.created) +
        " | " +
        chatMessage.created.toLocaleTimeString("nu", { hour: "2-digit", minute: "2-digit" });

      if (chatMessage.timestamp) {
        chatMessage.archiveLink =
          chatMessage.chat.archiveLink + "/s" + chatMessage.timestamp.toString().replace(".", "");
      } else {
        chatMessage.archiveLink = chatMessage.chat.archiveLink;
      }
    };

    chatMessage.searchLines = (property: any, value: any): any => {
      let lineIndex: number | undefined;

      chatMessage.lines.some((line: any, index: number) => {
        if (line[property] === value) {
          lineIndex = index;
          return true;
        }
      });

      return (lineIndex && chatMessage.lines[lineIndex]) || undefined;
    };

    chatMessage.sortFn = (a: any, b: any): number => {
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      if (a.timestamp < b.timestamp) {
        return -1;
      }
      return 0;
    };
  }
}
