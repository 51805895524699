import { LogService } from "@onsip/common/services/logging";

import { Config } from "@onsip/common/config";
import { Oauth2PopupWindowFactory } from "./oauth2-popup-window.factory";
import { params as slackParams } from "./slackConstants";
import { params as googleParams } from "./googleConstants";

import { Injectable } from "@angular/core";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { UserOauth2AccessTokenService } from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token.service";
import {
  AuthServiceObject,
  UserOauth2AccessToken,
  UserOauth2AccessTokenResponse
} from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token";

export interface OAuth2Client {
  clientId: string;
  scope: string;
  authEndpoint: string;
  redirectUri: string;
}

@Injectable({ providedIn: "root" })
export class Oauth2AuthService {
  private slackConstants: any = slackParams;
  private googleConstants: any = googleParams;
  private authsPromise: Promise<any> | undefined;
  private auths: Record<string, AuthServiceObject> = {};

  constructor(
    private log: LogService,
    private apiSession: ApiSessionService,
    private oauth2PopupWindowFactory: Oauth2PopupWindowFactory,
    private userOauth2AccessTokenService: UserOauth2AccessTokenService
  ) {}

  get(serviceId: string): Promise<AuthServiceObject | undefined> {
    return this.fetch().then(() => {
      return this.auths[serviceId];
    });
  }

  add(serviceId: string, authCode: string, scope: string, redirectUri: string): Promise<any> {
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenAdd({
        AuthorizationCode: authCode,
        Service: serviceId,
        RedirectUri: redirectUri,
        Scope: scope
      })
      .then(response => {
        if (response.status === "success") {
          const result = Object.values(response.data)[0];
          const oauth2AccessToken = result?.userOAuth2AccessTokens?.userOAuth2AccessToken;
          if (
            !(oauth2AccessToken.extra?.accessToken && oauth2AccessToken.extra?.accessTokenExpiresIn)
          ) {
            throw new Error("UserOAuth2AccessTokenAdd failed.");
          }
          const auth = this.createAuth(oauth2AccessToken);
          if (oauth2AccessToken.extra && oauth2AccessToken.extra.accessToken) {
            auth.accessToken = oauth2AccessToken.extra.accessToken;
          }
          this.auths[auth.serviceId] = auth;
          return auth;
        } else {
          throw Error(response.data.message);
        }
      });
  }

  delete(serviceId: string): Promise<any> {
    if (!this.auths[serviceId]) {
      return Promise.resolve(true);
    }
    this.log.debug("oauth2AuthService", "- deleted: '" + serviceId + "' auth deleted");
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenDelete(this.auths[serviceId].tokenId)
      .then(() => {
        delete this.auths[serviceId];
        return undefined;
      });
  }

  login(serviceName: string): Promise<string> {
    let C: any;

    if (serviceName === "SlackLogin") {
      C = this.slackConstants.AUTH;
    } else if (serviceName === "GoogleLogin") {
      C = this.googleConstants.AUTH;
    } else {
      this.log.debug("Not valid a service for login.");
      return Promise.reject();
    }

    const client: OAuth2Client = {
      clientId:
        Config.ADMIN_API_URL === Config.API_URLS.PRODUCTION ||
        Config.ADMIN_API_URL === Config.API_URLS.BETA
          ? C.CLIENT_ID.PROD
          : C.CLIENT_ID.DEV,
      scope: C.SCOPE,
      authEndpoint: C.ENDPOINT,
      redirectUri: this.apiSession.platformGetRedirectUri()
    };

    return this.requestAuthWithClient(client, C.SERVICE_ID);
  }

  getOrgUsers(token: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const reqListener: EventListener = function () {
        // @ts-ignore: noImplicitThis
        const response: any = JSON.parse(this.responseText);

        if (response.ok) {
          resolve(response);
        } else {
          reject("Slack api call failed.");
        }
      };

      const xhr: XMLHttpRequest = new XMLHttpRequest();
      xhr.addEventListener("load", reqListener);
      xhr.open("GET", "https://slack.com/api/users.list?token=" + token);
      xhr.send();
    });
  }

  createClient(clientId: any, scope: string, authEndpoint: string): OAuth2Client {
    return {
      clientId:
        Config.ADMIN_API_URL === Config.API_URLS.PRODUCTION ||
        Config.ADMIN_API_URL === Config.API_URLS.BETA
          ? clientId.PROD
          : clientId.DEV,
      scope,
      authEndpoint,
      redirectUri: this.apiSession.platformGetRedirectUri()
    };
  }

  requestAuthWithClient(client: OAuth2Client, serviceId: string): Promise<string> {
    // slack and google are the same, so no need to differentiate for now
    const authWindowOptions = {
        authWindowHeight: this.slackConstants.AUTH.WINDOW_HEIGHT,
        authWindowWidth: this.slackConstants.AUTH.WINDOW_WIDTH
      },
      request = this.oauth2PopupWindowFactory.createRequest(client);

    this.log.debug(
      "oauth2AuthService.createClient",
      "- requestAuth: '" + serviceId,
      "' auth requested"
    );
    return request.popup(
      this.platformGetWindowFeatures(
        authWindowOptions.authWindowHeight,
        authWindowOptions.authWindowWidth
      )
    );
  }

  private fetch(): Promise<void> {
    if (this.authsPromise) {
      return this.authsPromise;
    }

    this.authsPromise = new Promise<void>(resolve => {
      this.userOauth2AccessTokenService
        .UserOAuth2AccessTokenBrowse({
          Limit: 666
        })
        .then(response => {
          if (response.status === "error") {
            throw new Error("UserOAuth2AccessTokenBrowse2 failed.");
          }

          const userOauth2AccessTokens:
            | UserOauth2AccessTokenResponse
            | Array<UserOauth2AccessTokenResponse> = Object.values(response.data)[0];

          this.auths = {};
          if (!userOauth2AccessTokens) {
            resolve();
            return;
          } else if (!Array.isArray(userOauth2AccessTokens)) {
            // means there is only one result
            const auth: AuthServiceObject = this.createAuth(
              userOauth2AccessTokens.userOAuth2AccessTokens.userOAuth2AccessToken
            );
            this.auths[auth.serviceId] = auth;
          } else {
            // multiple results
            userOauth2AccessTokens.forEach(userOauth2AccessToken => {
              const auth: AuthServiceObject = this.createAuth(
                userOauth2AccessToken.userOAuth2AccessTokens.userOAuth2AccessToken
              );
              this.auths[auth.serviceId] = auth;
            });
          }

          this.log.debug(
            "oauth2AuthService",
            "- fetch: fetched",
            Object.keys(this.auths).length,
            " service.auths"
          );
          resolve();
        })
        .catch(e => {
          this.log.error("oauth2AuthService", "- fetch: failed.", e);
          this.auths = {};
          resolve();
        });
    });

    return this.authsPromise;
  }

  private platformGetWindowFeatures(height: number, width: number): any {
    if (Config.IS_DESKTOP) {
      return { height, width };
    }

    if (Config.IS_WEB) {
      if (height && width) {
        return "height=" + height + ",width=" + width;
      }
    }
  }

  private createAuth(userOauth2AccessToken: UserOauth2AccessToken): AuthServiceObject {
    return {
      tokenId: userOauth2AccessToken.userOAuth2AccessTokenId,
      serviceId: userOauth2AccessToken.service,
      scope: userOauth2AccessToken.scope,
      onsipUserId: userOauth2AccessToken.userId,
      serviceUserId: userOauth2AccessToken.serviceUserId,
      refreshToken: userOauth2AccessToken.token
    };
  }
}
