import { Injectable } from "@angular/core";

import { StateEmitter } from "../../../../../common/libraries/emitter";

export interface RealtimeCallerTableState {
  callerTableOpenState: "open" | "close";
}

@Injectable({ providedIn: "root" })
export class RealtimeCallerTableService extends StateEmitter<RealtimeCallerTableState> {
  constructor() {
    super({
      callerTableOpenState: "close"
    });
  }

  getCallerTableState(): RealtimeCallerTableState["callerTableOpenState"] {
    return this.stateStore.callerTableOpenState;
  }

  openCallerTable(): void {
    this.stateStore.callerTableOpenState = "open";
    this.publishState();
  }

  closeCallerTable(): void {
    this.stateStore.callerTableOpenState = "close";
    this.publishState();
  }
}
