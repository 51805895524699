export const socialItems = [
  {
    field: "linkedin",
    urlPrefix: "https://www.linkedin.com/in/",
    regex: /linkedin/,
    isSocialMedia: true
  },
  {
    field: "facebook",
    urlPrefix: "https://www.facebook.com/",
    regex: /facebook/,
    isSocialMedia: true
  },
  {
    field: "twitter",
    urlPrefix: "https://www.twitter.com/",
    regex: /twitter/,
    isSocialMedia: true
  }
];
