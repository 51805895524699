import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  Validator,
  ValidatorFn
} from "@angular/forms";
import { MatButtonToggle, MatButtonToggleChange } from "@angular/material/button-toggle";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Recording } from "@onsip/common/services/api/resources/recording/recording";
import { RecordingService } from "@onsip/common/services/api/resources/recording/recording.service";
import { BehaviorSubject, combineLatest, Observable, Subscription } from "rxjs";
import { startWith, tap } from "rxjs/operators";
import { ModalMaterialComponent } from "../modal/modal-material.component";
import { SnackbarService } from "../snackbar/snackbar.service";
import { RecordingTypesEnum } from "./config/recording-types.enum";

const MAX_SIZE = 1024 * 1024 * 30; // 30MB is the max size of a recording

@Component({
  selector: "onsip-togglable-recorder",
  styleUrls: ["./togglable-recorder.component.scss"],
  templateUrl: "./togglable-recorder.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref
      useExisting: forwardRef(() => TogglableRecorderComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TogglableRecorderComponent,
      multi: true
    },
    DatePipe
  ]
})
export class TogglableRecorderComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input() fileNamePrefix = "rec";
  @Input() defaultType: RecordingTypesEnum = RecordingTypesEnum.SELECT;
  @Input() hasSave = false;
  @Input() hasSelect = true;
  @Input() entityLabel = "greeting";

  @Output() urlChange = new EventEmitter<SafeResourceUrl | undefined>();
  @Output() recordingTypeChange = new EventEmitter<RecordingTypesEnum>();
  @Output() saved = new EventEmitter<void>();

  @ViewChild("recordToggle") recordToggle!: MatButtonToggle;
  @ViewChild("uploadToggle") uploadToggle!: MatButtonToggle;
  @ViewChild("fileInput") fileInputRef!: ElementRef<HTMLInputElement>;

  RecordingTypes = RecordingTypesEnum;

  existingRecordings: Array<Recording> = [];
  recordingControl = new FormControl(<Recording | File | undefined>undefined, {
    nonNullable: true
  });
  recordingTypeControl = new FormControl<RecordingTypesEnum>(RecordingTypesEnum.SELECT, {
    nonNullable: true
  });
  recordingsOptions$: Observable<Array<Recording>> = this.recordingService.orgState.pipe(
    tap(recordings => (this.existingRecordings = recordings))
  );

  recordingsForm = this.fb.group({
    [RecordingTypesEnum.SELECT]: [<Recording | File | undefined>undefined],
    [RecordingTypesEnum.RECORD]: [<Recording | File | undefined>undefined],
    [RecordingTypesEnum.UPLOAD]: [<Recording | File | undefined>undefined]
  });

  recordingUrlsByType$ = new BehaviorSubject<Record<string, SafeResourceUrl | undefined>>({});

  private disaplayFile$ = new BehaviorSubject<SafeResourceUrl | undefined>(undefined);
  private hasUserInput = false;
  private unsubscriber = new Subscription();

  constructor(
    private recordingService: RecordingService,
    private datePipe: DatePipe,
    private snackbar: SnackbarService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnInit(): void {
    this.setDefaultRecordingType();
    this.initSelectOptions();
    this.initRecordingTypeChange();
    this.initDisplayFile();
    this.initUrlChange();

    this.initRecordingUrls();
    this.initRecordingControl();
    this.addValidatorIfNeeded();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  async getRecNumber(
    value?: Recording | File,
    type?: RecordingTypesEnum,
    name?: string
  ): Promise<string> {
    if (!value) {
      value = this.recordingControl.value;
    }
    const recordingType: RecordingTypesEnum = type ? type : this.recordingTypeControl.value;
    if (recordingType === RecordingTypesEnum.SELECT) {
      const recording: Recording = value as Recording;
      return recording.number;
    }

    const recording: File = value as File;
    let errorMessage = "";
    const addedRecordingResponse = await this.recordingService.recordingAdd(
      name ? name : recording.name
    );

    if (addedRecordingResponse.status === "success") {
      const recordingId = Object.values(addedRecordingResponse.data)[0].recordingId;
      const uploadedRecordingResponse = await this.recordingService.recordingUpload(
        recordingId,
        recording
      );

      if (uploadedRecordingResponse.status === "success") {
        return Object.values(uploadedRecordingResponse.data)[0].number;
      } else {
        errorMessage = uploadedRecordingResponse.data.message;
        // if recording upload is not successful, delete recording so user can upload another with same name
        this.recordingService.recordingDelete(recordingId);
      }
    } else {
      errorMessage = addedRecordingResponse.data.message;
    }

    this.recordingService.clearErrors();
    if (errorMessage) {
      this.snackbar.openSnackBar(errorMessage, "error");
    }

    throw new Error(errorMessage);
  }

  validate(): { [key: string]: any } | null {
    return this.recordingControl.errors;
  }

  onBtnTypeChange(changeEvent: MatButtonToggleChange): void {
    if (this.hasSelect) {
      return this.recordingTypeControl.patchValue(changeEvent.value);
    }

    const prevType: RecordingTypesEnum = this.recordingTypeControl.value;
    const newType = changeEvent.value;

    this.setToggleState(prevType);

    if (this.hasUserInput && prevType !== newType) {
      const dialog = this.dialog.open(ModalMaterialComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: {
          title: "You already have a greeting",
          message: this.getConfirmTypeChangeMessage(newType),
          primaryBtnText: "Replace",
          primaryBtnFlat: true
        },
        restoreFocus: false,
        autoFocus: false
      });
      this.unsubscriber.add(
        // eslint-disable-next-line rxjs/no-nested-subscribe
        dialog.afterClosed().subscribe(res => {
          if (res && res.doPrimaryAction) {
            this.discardRecording();
            this.recordingTypeControl.patchValue(newType);
            this.recordingTypeChange.emit(newType);
            this.setToggleState(newType);
          }
        })
      );
    } else {
      this.recordingTypeControl.patchValue(newType);
      this.recordingTypeChange.emit(newType);
      this.setToggleState(newType);
    }
  }

  private setToggleState(type: RecordingTypesEnum): void {
    this.recordToggle.checked = type === RecordingTypesEnum.RECORD;
    this.uploadToggle.checked = type === RecordingTypesEnum.UPLOAD;
    this.cdr.markForCheck();
  }

  writeValue(recording: Recording): void {
    this.recordingsForm.controls[this.defaultType].patchValue(recording);
  }

  registerOnChange(fn: Function): void {
    this.unsubscriber.add(
      this.recordingControl.valueChanges.subscribe((value: File | Recording | undefined) =>
        fn(value)
      )
    );
  }

  registerOnTouched(): void {}

  handleRecordedBlob(recordedBlob: Blob | undefined): void {
    if (recordedBlob) {
      if (recordedBlob.size > MAX_SIZE) {
        this.snackbar.openSnackBar("To save, make sure the file is less than 30MB", "error");
        return;
      }
      const recordedFile = new File([recordedBlob], this.generateFileName());
      this.hasUserInput = true;
      this.recordingsForm.controls[RecordingTypesEnum.RECORD].patchValue(recordedFile);
    } else {
      this.discardRecording(RecordingTypesEnum.RECORD);
    }
  }

  onFileChange(fileInput: HTMLInputElement): void {
    const uploadedFile = (fileInput as HTMLInputElement & { files: FileList }).files[0];
    if (uploadedFile) {
      if (uploadedFile.size > MAX_SIZE) {
        this.snackbar.openSnackBar("To save, make sure the file is less than 30MB", "error");
        return;
      }
      this.hasUserInput = true;
      this.recordingsForm.controls[RecordingTypesEnum.UPLOAD].patchValue(uploadedFile);
    } else {
      this.discardRecording(RecordingTypesEnum.UPLOAD);
    }
  }

  removeFileUpload(): void {
    this.discardRecording(RecordingTypesEnum.UPLOAD);
    if (this.fileInputRef.nativeElement?.value) {
      this.fileInputRef.nativeElement.value = "";
    }
  }

  compareWithOptions(o1: Recording, o2: Recording): boolean {
    return o1.recordingId === o2.recordingId;
  }

  private generateFileName(): string {
    const timestamp = new Date();
    const formattedDate = this.datePipe.transform(timestamp, "M-d-yy h-mm-ss a");
    return `${this.fileNamePrefix}-${formattedDate}`;
  }

  private discardRecording(type?: RecordingTypesEnum): void {
    this.hasUserInput = false;
    if (this.hasSelect && type) {
      this.recordingsForm.controls[type].patchValue(undefined);
    } else {
      this.recordingsForm.patchValue({
        [RecordingTypesEnum.UPLOAD]: undefined,
        [RecordingTypesEnum.RECORD]: undefined,
        [RecordingTypesEnum.SELECT]: undefined
      });
    }
  }

  private initSelectOptions(): void {
    if (this.hasSelect) {
      this.recordingService.recordingBrowse().then(response => {
        if (response.status === "success") {
          const options = Object.values(response.data);
          if (options.length) {
            const defaultRecordingOption = options[options.length - 1];
            if (!this.recordingsForm.controls[RecordingTypesEnum.SELECT].value) {
              this.recordingsForm.controls[RecordingTypesEnum.SELECT].patchValue(
                defaultRecordingOption
              );
            }
          }
        }
      });
    }
  }

  private initRecordingTypeChange(): void {
    this.unsubscriber.add(
      this.recordingTypeControl.valueChanges.subscribe(type => this.recordingTypeChange.emit(type))
    );
  }

  private getConfirmTypeChangeMessage(type: RecordingTypesEnum): string {
    if (type === RecordingTypesEnum.UPLOAD) {
      return `Uploading a file will replace your recorded greeting. Are you sure you want to replace your recording?`;
    } else {
      return `Recording a greeting will replace your uploaded file. Are you sure you want to replace ${this.recordingsForm.value?.upload?.name}?`;
    }
  }

  private initDisplayFile(): void {
    this.unsubscriber.add(
      combineLatest({
        type: this.recordingTypeControl.valueChanges.pipe(startWith(this.defaultType)),
        urls: this.recordingUrlsByType$.pipe(startWith({}))
      }).subscribe(
        ({ type, urls }: { type: string; urls: Record<string, SafeResourceUrl | undefined> }) => {
          this.disaplayFile$.next(urls[type]);
        }
      )
    );
  }

  private getAudioFileToExistingRecording(recordingId: string): SafeResourceUrl | undefined {
    const audioUrl = this.recordingService.getRecordingUrl(recordingId);
    if (audioUrl) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(audioUrl);
    }
  }

  private initUrlChange(): void {
    this.unsubscriber.add(this.disaplayFile$.subscribe(url => this.urlChange.emit(url)));
  }

  private setDefaultRecordingType(): void {
    if (this.defaultType) {
      this.recordingTypeControl.patchValue(this.defaultType);
    }
  }

  private initRecordingControl(): void {
    this.unsubscriber.add(
      combineLatest({
        type: this.recordingTypeControl.valueChanges.pipe(startWith(this.defaultType)),
        records: this.recordingsForm.valueChanges
      }).subscribe(({ type, records }) => {
        this.recordingControl.patchValue(records[type]);
      })
    );
  }

  private initRecordingUrls(): void {
    this.unsubscriber.add(
      this.recordingsForm.valueChanges.subscribe(val => {
        this.recordingUrlsByType$.next(
          Object.keys(val)
            .map((type: string) => {
              return {
                [type]: this.getFileUrl(val[type as RecordingTypesEnum], type)
              };
            })
            .reduce(
              (prev, acc) => ({
                ...acc,
                ...prev
              }),
              {}
            )
        );
      })
    );
  }

  private getFileUrl(
    recording: Recording | File | undefined,
    recordingType: string
  ): SafeResourceUrl | undefined {
    if (!recording) {
      return;
    }

    if (recording instanceof File) {
      const audioBlob =
        recordingType === RecordingTypesEnum.UPLOAD
          ? new Blob([recording], { type: "audio/wav" })
          : recording;

      if (!(audioBlob instanceof Blob)) {
        return;
      }

      return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(audioBlob));
    }

    if (recording.recordingId) {
      return this.getAudioFileToExistingRecording(recording.recordingId);
    }
  }

  private addValidatorIfNeeded(): void {
    if (this.hasSelect) {
      this.recordingControl.addValidators(this.repeatNameValidator());
    }
  }

  private repeatNameValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fileName: string = control.value?.name || "";

      if (this.recordingTypeControl.value === RecordingTypesEnum.SELECT) {
        // eslint-disable-next-line no-null/no-null
        return null;
      }

      return this.existingRecordings.some(({ name }) => name === fileName)
        ? { repeatName: true }
        : // eslint-disable-next-line no-null/no-null
          null;
    };
  }
}
