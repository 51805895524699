import { Grammar, UserAgent, Subscriber } from "sip.js";

import { warningChecker } from "./warningChecker";

import { NotifyObject, AdvQueueWarning } from "./triggers";

export class Checker {
  private expires: number;
  private addressesToWarnings: Record<string, Array<AdvQueueWarning>> = {};
  private addressesToSubscriptions: { [key: string]: Subscriber } = {};
  private subscriptionToRefreshTime: { [key: string]: any } = {};
  private stateChangeEmitter: EventTarget;
  private globalUA: UserAgent;

  constructor(globalUA: UserAgent, stateChangeEmitter: EventTarget, expires: number = 3600) {
    this.expires = expires;
    this.stateChangeEmitter = stateChangeEmitter;
    this.globalUA = globalUA;
  }

  /**
   * `advQueueWarning`: the API JSON response object.
   */
  addWarning(advQueueWarning: AdvQueueWarning): Subscriber {
    const addressArray = this.addressesToWarnings[advQueueWarning.AdvQueueAddress];
    let sub: Subscriber;
    if (addressArray && addressArray.length > 0) {
      this.addressesToWarnings[advQueueWarning.AdvQueueAddress] =
        addressArray.concat(advQueueWarning);
      sub = this.addressesToSubscriptions[advQueueWarning.AdvQueueAddress];
      sub.subscribe();
    } else {
      this.addressesToWarnings[advQueueWarning.AdvQueueAddress] = [advQueueWarning];
      sub = this.subscribeToQueue(advQueueWarning.AdvQueueAddress);
    }
    return sub;
  }

  /**
   * `advQueueWarning`: the API JSON response object.
   * At minimum, must have 'AdvQueueAddress' and 'AdvQueueWarningId'.
   */
  removeWarning(advQueueWarning: AdvQueueWarning): Subscriber | undefined {
    const oldWarnings = this.addressesToWarnings[advQueueWarning.AdvQueueAddress] || [];

    for (let i = 0; i < oldWarnings.length; i++) {
      const oldWarning = oldWarnings[i];
      if (advQueueWarning.AdvQueueWarningId === oldWarning.AdvQueueWarningId) {
        return this.safeRemoveWarning(advQueueWarning, i);
      }
    }
    // No warning found to remove
  }

  /**
   * `advQueueWarning`: the API JSON response object.
   */
  editWarning(advQueueWarning: AdvQueueWarning): Subscriber {
    const oldWarnings = this.addressesToWarnings[advQueueWarning.AdvQueueAddress] || [];

    for (let i = 0; i < oldWarnings.length; i++) {
      const oldWarning = oldWarnings[i];
      if (advQueueWarning.AdvQueueWarningId === oldWarning.AdvQueueWarningId) {
        advQueueWarning.hasCleared = true;
        delete advQueueWarning.cooldownStart;
        if (advQueueWarning.AdvQueueAddress === oldWarning.AdvQueueAddress) {
          this.addressesToWarnings[advQueueWarning.AdvQueueAddress][i] = advQueueWarning;
          const sub = this.addressesToSubscriptions[advQueueWarning.AdvQueueAddress];
          sub.subscribe();
          return sub;
        } else {
          // We are moving the warning between queues, so update subscriptions
          // if necessary.
          this.safeRemoveWarning(oldWarning, i);
          return this.addWarning(advQueueWarning);
        }
      }
    }

    return this.addWarning(advQueueWarning);
  }

  /*
   * Potential stateChangeEmitter events:
   * - 'trigger': emits when a warning triggers, and notifications should be sent
   * - 'clear': emits when a trigger has cleared (hasCleared becomes true
   */
  refreshWarnings(advQueueWarnings: Array<AdvQueueWarning>): Array<Subscriber> {
    this.stopSubscriptions();
    return this.subscribeWarnings(advQueueWarnings);
  }

  private stopSubscription(address: string): Subscriber {
    const sub = this.addressesToSubscriptions[address];
    if (sub.dialog) {
      clearTimeout(this.subscriptionToRefreshTime[sub.dialog.id]);
    }
    sub.unsubscribe();
    delete this.addressesToSubscriptions[address];
    return sub;
  }

  private stopSubscriptions(): void {
    Object.keys(this.addressesToSubscriptions).forEach(this.stopSubscription);
  }

  private subscribeWarnings(advQueueWarnings: Array<AdvQueueWarning>): Array<Subscriber> {
    this.addressesToWarnings = advQueueWarnings.reduce(
      (prevMap: Record<string, Array<AdvQueueWarning>>, advQueueWarning) => {
        const oldWarnings = this.addressesToWarnings[advQueueWarning.AdvQueueAddress] || [];

        for (const oldWarning of oldWarnings) {
          /*
           * Current properties that must persist across refreshes:
           * - hasCleared: once triggered, tracks if the triggered state has cleared
           * - cooldownStart: time of last cooldown period beginning
           */
          if (advQueueWarning.AdvQueueWarningId === oldWarning.AdvQueueWarningId) {
            advQueueWarning.hasCleared = oldWarning.hasCleared;
            advQueueWarning.cooldownStart = oldWarning.cooldownStart;
            break;
          }
        }
        prevMap[advQueueWarning.AdvQueueAddress] = (
          prevMap[advQueueWarning.AdvQueueAddress] || []
        ).concat(advQueueWarning);
        return prevMap;
      },
      {}
    );

    return Object.keys(this.addressesToWarnings).map(address => this.subscribeToQueue(address));
  }

  private useDate(
    sub: Subscriber,
    advQueueWarnings: Array<AdvQueueWarning>,
    notifyObject: NotifyObject,
    currDate: Date
  ): void {
    const recheckIn = advQueueWarnings.reduce((checkinTime, advQueueWarning) => {
      return Math.min(
        checkinTime,
        warningChecker(advQueueWarning, notifyObject, this.stateChangeEmitter, currDate)
      );
    }, Infinity);

    if (isFinite(recheckIn) && sub.dialog) {
      this.subscriptionToRefreshTime[sub.dialog.id] = setTimeout(() => sub.subscribe(), recheckIn);
    }
  }

  private subscribeToQueue(address: string): Subscriber {
    const uri = Grammar.URIParse("sip:" + address);
    if (!uri) {
      throw new Error("Queue Warnings: bad address given to subscribeToQueue: " + address);
    }
    const sub = new Subscriber(this.globalUA, uri, "onsip-queue", { expires: this.expires });

    sub.delegate = {
      onNotify: notifyInfo => {
        if (!notifyInfo.request.body) {
          console.error("Empty NOTIFY body received, NOTIFY:", notifyInfo.request);
          return;
        }

        let notifyObject;

        try {
          notifyObject = JSON.parse(notifyInfo.request.body);
        } catch (e) {
          console.error(
            "Failed JSON.parse, returning with no action, on:",
            notifyInfo.request.body,
            "and error:",
            e
          );
          return;
        }

        if (sub.dialog) {
          clearTimeout(this.subscriptionToRefreshTime[sub.dialog.id]);
        }
        this.useDate(sub, this.addressesToWarnings[address], notifyObject, new Date());
      }
    };

    sub.subscribe();

    this.addressesToSubscriptions[address] = sub;
    return sub;
  }

  /**
   * Removes a warning and stops the queue subscription if that warning was the
   * last warning for the given subscription's queue address.
   */
  private safeRemoveWarning(advQueueWarning: AdvQueueWarning, index: number): Subscriber {
    this.addressesToWarnings[advQueueWarning.AdvQueueAddress].splice(index, 1);

    if (this.addressesToWarnings[advQueueWarning.AdvQueueAddress].length < 1) {
      return this.stopSubscription(advQueueWarning.AdvQueueAddress);
    } else {
      return this.addressesToSubscriptions[advQueueWarning.AdvQueueAddress];
    }
  }
}
