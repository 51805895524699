import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { TimerComponent } from "./timer.component";
import { MaterialModule } from "@onsip/web/modules/material/material.module";
import { PipesModule } from "../../pipes/pipes.module";

/** Use this for small, highly reusable components
 *  Rule of thumb: these should not rely on any app data / inject any services
 */

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    PipesModule,
    TranslateModule
  ],
  declarations: [TimerComponent],
  exports: [TimerComponent]
})
export class TimerModule {}
