import { Injectable } from "@angular/core";
import { registerLocaleData } from "@angular/common";

import { TranslateService } from "@ngx-translate/core";

import LOCALE_EN from "@angular/common/locales/en";
import LOCALE_ES from "@angular/common/locales/es";

@Injectable({ providedIn: "root" })
export class LocalizationService {
  private onsipLocales = ["en", "es"]; // whitelisted onsip locales (languages we support)

  constructor(private translate: TranslateService) {
    registerLocaleData(LOCALE_EN, "en");
    registerLocaleData(LOCALE_ES, "es");
  }

  /** Get Locale String from browser
   * @returns users locale string, defaults to "en" if unsupported
   */
  getLocale(): string {
    // If LOCALE_ID is in the "white list" i.e. what we have translation files for use it, otherwise use "en"
    return this.onsipLocales.find(lcl => this.translate.getBrowserLang() === lcl)
      ? this.translate.getBrowserLang() || "en"
      : "en";
  }
}
