import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "../../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { TestInstallationComponent } from "./test-installation.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, TranslateModule],
  declarations: [TestInstallationComponent],
  exports: [TestInstallationComponent]
})
export class TestInstallationModule {}
