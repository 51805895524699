import { OrganizationConfigurationValidator } from "./organization-configuration-validator";
import { Configuration } from "./configuration";
import { Verb } from "./verb";
import { BHR, BHRHoliday } from "./bhr";
export { Configuration, BHR, BHRHoliday, Verb };

/** The schema version number of this app */
export const CONFIG_SCHEMA_VERSION = 2;

export type OrganizationConfiguration = OrganizationInfo &
  OrganizationButtonConfigurations &
  OrganizationTheme &
  ConfigurationFileVersioning &
  OrganizationPremiumInfo;

/** interface that contain editable params by the user. For new editable interfaces, simply add them to this list  */
export type EditableConfiguration = OrganizationTheme &
  Pick<OrganizationButtonConfigurations, "disabled"> &
  OrganizationPremiumInfo;

export interface OrganizationInfo {
  /** Organization ID (jnctn organization id) */
  organizationId: number;
  /** The organization's human-readable name- used as a fallback for image logo */
  organizationDisplayName: string;
}

export interface ConfigurationFileVersioning {
  /** The document-version of the orgConfig, this number should never be 0 */
  documentVersion: number;
  /** The latest schema version according to the API */
  schemaVersion: number;
}

export interface OrganizationTheme {
  /** Optional cosmetic parameter for the css background property of the buttons on sayso, as a css-color-valid string. If not set, defaults to onsip blue */
  accentColor?: string;
  /** Optional cosmetic parameter for the css background property of the sayso FAB button, as a css-color-valid string. If not set, defaults to onsip blue */
  buttonColor?: string;
  /** Optional parameter for gradient on button color */
  gradientOverlay?: boolean;
  /** Optional parameter for the css left/right position property of the sayso button. If not set, defaults to "right" */
  horizontalPositionDirection?: HorizontalPositionDirection;
  /** Optional parameter for the css left/right position property value of the sayso button. If not set, defaults to 32 */
  horizontalPositionValue?: number;
  /** Optional parameter for the css left/right position property unit of the sayso button. If not set, defaults to "px" */
  horizontalPositionUnit?: string;
  /** Optional parameter for the css bottom/top position property of the sayso button. If not set, defaults to "bottom" */
  verticalPositionDirection?: VerticalPositionDirection;
  /** Optional parameter for the css bottom/top position property value of the sayso button. If not set, defaults to 32 */
  verticalPositionValue?: number;
  /** Optional parameter for the css bottom/top position property unit of the sayso button. If not set, defaults to "px" */
  verticalPositionUnit?: string;
}

export interface OrganizationButtonConfigurations {
  /** Whether or not to show any topics regardless of their filters - used in the "disable all topics" feature */
  disabled?: boolean;
  configurations: Array<ButtonConfigurationMetadata>;
}

export type ButtonConfigurationMetadata = Configuration & Filters;

export interface Filters {
  /** An object whose keys are the names of the filters and whose values are an array of call-time arguments
   * Filters are extensible with this generic type- where any[] signifies an arguments array
   */
  filters: ButtonConfigurationFilters;
}

export type CountryWhitelist = Array<string>;
export type CountryBlacklist = Array<string>;
export type Disabled = boolean;
export type UrlSubstringWhitelist = Array<[Verb, string]>;
export type HorizontalPositionDirection = "left" | "right";
export type VerticalPositionDirection = "bottom" | "top";

// Filters args are fed in with rest parameter in order to be generic, so filters are all arrays/tuples
export interface DefaultButtonConfigurationFilters {
  /** If present, enable/display only during business hours */
  bhr?: [BHR];
  /** An array of blocked countries to disable the button for. If empty/undef, allow all countries */
  countryBlacklist?: [CountryBlacklist];
  /** An array of allowed countries to enable the button for. If empty/undef, allow all countries */
  countryWhitelist?: [CountryWhitelist];
  /** The button is straight up disabled */
  disabled?: [Disabled];
  /** An array of allowed url substrings to enable the button for. Default (undefined or []) is to allow everywhere */
  urlSubstringWhitelist?: [UrlSubstringWhitelist];
}

export interface ExtendableButtonConfigurationFilters {
  /** Filters are extendable with custom filters - we will probably never write documentation for it though */
  [key: string]: Array<any>;
}

export type ButtonConfigurationFilters = DefaultButtonConfigurationFilters &
  ExtendableButtonConfigurationFilters;

// Keys for any paying features should be stored here
export interface OrganizationPremiumInfo {
  /** Whether or not we will show the Onsip branding on the sayso app */
  branding?: "onsip" | "removed";
  /** Whether or not sayso calls can be screenshared */
  allowScreenshare?: boolean;
}

export function isOrganizationConfiguration(arg: unknown): arg is OrganizationConfiguration {
  return OrganizationConfigurationValidator.ValidateOrganizationConfiguration(arg);
}
