import {
  CDK_TABLE_TEMPLATE,
  CdkTable,
  CDK_TABLE,
  _CoalescedStyleScheduler,
  _COALESCED_STYLE_SCHEDULER
} from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from "@angular/core";
import { _DisposeViewRepeaterStrategy, _VIEW_REPEATER_STRATEGY } from "@angular/cdk/collections";

/**
 * Wrapper for the CdkTable with Material design styles.
 */
@Component({
  selector: "onsip-table-two, table[onsip-table-two], onsip-shelf-table, table[onsip-shelf-table]",
  exportAs: "onsipTable",
  template: CDK_TABLE_TEMPLATE,
  styleUrls: ["main-table.scss", "shelf-table.scss", "table-core.scss"],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "onsip-table",
    "[class.onsip-table-fixed-layout]": "fixedLayout",
    "[class.hoverable]": "hoverable",
    "[class.onsip-table-grid-layout]": "gridTemplateColumns !== 'none'",
    "[style.--columns-widths]": "gridTemplateColumns",
    "[class.onsip-grid-layout]": "useGrid"
  },
  providers: [
    // TODO(michaeljamesparsons) Abstract the view repeater strategy to a directive API so this code
    //  is only included in the build if used.
    { provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy },
    { provide: CdkTable, useExisting: OnsipTableComponent },
    { provide: CDK_TABLE, useExisting: OnsipTableComponent },
    { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler }
  ],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default
})
export class OnsipTableComponent<T> extends CdkTable<T> {
  // @HostBinding("class.onsip-table") _dontUse = true;
  // @HostBinding("[class.onsip-table-fixed-layout]") fixedLayout: any;
  @Input() hoverable = false;
  /** This is essentially used as a wrapper around the css property grid-template-columns.
   * we can use this input to add custom column widths for our table
   * NOTE: setting this variable makes the table use display grid
   */
  @Input() gridTemplateColumns = "none";
  @Input() useGrid = false;
}
