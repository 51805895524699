import { Injectable } from "@angular/core";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import {
  ApiTelephoneNumberAddress,
  TelephoneNumberAddress,
  TelephoneNumberAddressParams,
  ApiTelephoneNumberAddressToTelephoneNumberAddress as clean,
  TelephoneNumberAddressEditParams
} from "./telephone-number-address";
import { ApiResourceService } from "../api-resource.service";
import { ApiAction } from "../../api-actions";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ParameterValue } from "../../util/api-action-description";
import { userId } from "../../apiParams/user-id";
import { onsipApiArrayToArray } from "../../apiResponse/xml-json";
import { organizationId } from "../../apiParams/organization-id";
import { domain } from "../../apiParams/domain";
import { take } from "rxjs/operators";
export { TelephoneNumberAddress };

const debug = false;

@Injectable({ providedIn: "root" })
export class TelephoneNumberAddressService extends ApiResourceService<TelephoneNumberAddress> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "TelephoneNumberAddress", "addressId");
    debug && this.state.subscribe(state => console.warn("TelephoneNumberAddressService", state));
  }

  telephoneNumberAddressBrowse(
    params: Record<string, ParameterValue> = {}
  ): ApiPromiseState<TelephoneNumberAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.TelephoneNumberAddressBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 1000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.TelephoneNumberAddressBrowse);
  }

  // for non-admin users to fetch their telephone numbers
  telephoneNumberAddressBrowseWithoutOrgId(
    params: Record<string, ParameterValue> = {}
  ): ApiPromiseState<TelephoneNumberAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.TelephoneNumberAddressBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        UserId: this.store.state.pipe(userId()),
        Limit: 1000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.TelephoneNumberAddressBrowse);
  }

  telephoneNumberAddressAdd(
    params: TelephoneNumberAddressParams
  ): ApiPromiseState<TelephoneNumberAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.TelephoneNumberAddressAdd,
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.TelephoneNumberAddressAdd);
  }

  telephoneNumberAddressEdit(
    params: TelephoneNumberAddressEditParams
  ): ApiPromiseState<TelephoneNumberAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.TelephoneNumberAddressEdit,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Domain: this.store.state.pipe(domain()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.TelephoneNumberAddressEdit);
  }

  telephoneNumberAddressDelete(Address: string): ApiPromiseState<TelephoneNumberAddress> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.TelephoneNumberAddressDelete,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Address
      }
    });
    return this.promiseState.toPromise(ApiAction.TelephoneNumberAddressDelete);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.TelephoneNumberAddressAdd:
      case ApiAction.TelephoneNumberAddressEdit:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [
              extractData<ApiTelephoneNumberAddress>(response, action, "TelephoneNumberAddress")
            ].map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiAction.TelephoneNumberAddressDelete:
        if (!response.Context.Request.Parameters) break;
        // eslint-disable-next-line no-case-declarations
        const deletedTelephone = onsipApiArrayToArray(
          response.Context.Request.Parameters,
          "Parameter"
        ).find(param => param.Name === "Address")?.Value;
        if (deletedTelephone) {
          this.state.pipe(take(1)).subscribe(({ state }) => {
            const address = Object.values(state).find(address =>
              deletedTelephone.startsWith(address.username)
            );
            if (address?.addressId) {
              this.store.mergeStateUpdate(
                this.resourceName,
                { [address.addressId]: undefined },
                action
              );
            }
          });
        }
        break;
      case ApiAction.TelephoneNumberAddressBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiTelephoneNumberAddress>>(
              response,
              action,
              "TelephoneNumberAddress",
              "TelephoneNumberAddresses"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
