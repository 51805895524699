import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { map } from "rxjs/operators";

import { FreeTrialService } from "./free-trial.service";

/** Data Inputs (injected) to modal */
export interface FreeTrialModalData {
  /** Flag for showing the expiring modal vs expired */
  expiring: boolean;
}

export type FreeTrialModalAction = "none" | "contact-sales" | "end-calls";

@Component({
  templateUrl: "./free-trial-expiring-modal.component.html",
  styleUrls: ["./free-trial-expiring-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FreeTrialExpiringModalComponent {
  timer = this.freeTrialService.state.pipe(
    map(state => (state.timeRemaining !== undefined ? state.timeRemaining / 1000 : undefined))
  );
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: FreeTrialModalData,
    private freeTrialService: FreeTrialService
  ) {}
}
