import { Injectable } from "@angular/core";
import { ApiResourceService } from "@onsip/common/services/api/resources/api-resource.service";
import { OnsipApiResponse } from "@onsip/common/services/api/apiResponse/response-body";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { ApiStateStoreService } from "@onsip/common/services/api/api-state-store.service";
import {
  ApiPromiseState,
  ApiPromiseStateService
} from "@onsip/common/services/api/api-promise-state.service";
import { ApiAction } from "@onsip/common/services/api/api-actions";
import { sessionId } from "@onsip/common/services/api/apiParams/session-id";
import { organizationId } from "@onsip/common/services/api/apiParams/organization-id";
import { getApiActionName } from "@onsip/common/services/api/onsip-api-action-new";
import { ProvideFeedback } from "./provide-feedback";

const debug = false;

@Injectable({
  providedIn: "root"
})
export class ProvideFeedbackService extends ApiResourceService<void> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "ProvideFeedback", "" as never);
    debug && this.state.subscribe(state => console.warn("ProvideFeedbackService", state));
  }

  ProvideFeedback(parameters: ProvideFeedback): ApiPromiseState<void> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.ProvideFeedback,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        ...parameters
      }
    });
    return this.promiseState.toPromise(ApiAction.ProvideFeedback);
  }

  protected reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.ProvideFeedback:
        this.store.mergeStateUpdate(this.resourceName, {}, action);
        break;
    }
  }
}
