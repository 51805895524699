import { OperatorFunction } from "rxjs";
import { filter, map, withLatestFrom, distinctUntilChanged } from "rxjs/operators";
import { ApiSessionState } from "../api-session.service";

import { ApiStateStoreState, ApiSubstate } from "../api-state-store.service";
import { User } from "../resources/user/user";

import { userId } from "./user-id";

export function accountId(): OperatorFunction<ApiStateStoreState, string> {
  return storeState =>
    storeState.pipe(
      withLatestFrom(storeState.pipe(userId())),
      map(
        ([store, selfUserId]) =>
          (store.Session?.state as ApiSessionState).accountId ||
          (store.User as ApiSubstate<User>)?.state[selfUserId]?.accountId
      ),
      filter(<T>(val: T): val is NonNullable<T> => !!val),
      distinctUntilChanged()
    );
}
