import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { CMSInstructionsModule } from "./instructions/cms-instructions.module";
import { TestInstallationModule } from "./testInstallation/test-installation.module";

import { SaysoInstallationComponent } from "./sayso-installation.component";
import { SaysoInstallationRoutingModule } from "@onsip/web/features/sayso/saysoSettings/installation/sayso-installation-routing.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    CMSInstructionsModule,
    TestInstallationModule,
    SaysoInstallationRoutingModule
  ],
  declarations: [SaysoInstallationComponent],
  exports: [SaysoInstallationComponent]
})
export class SaysoInstallationModule {}
