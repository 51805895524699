import { Injectable } from "@angular/core";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { MatDialog } from "@angular/material/dialog";
import { take } from "rxjs/operators";

import { ContactListItem } from "../../components/sidebar/contactList/contact-list-interface";
import { CallState } from "../../../../../common/libraries/sip/call-state";
import { Contact } from "../../../../../common/interfaces/contact";

import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { ContactService } from "../../../../../common/services/contact/contact.service";
import { CallGroupService } from "../../controller/call-group.service";
import { SnackbarService } from "../../components/snackbar/snackbar.service";
import { ModalMaterialComponent } from "../../components/modal/modal-material.component";
import { PersonalDialog } from "../../../../../common/interfaces/presentity";
import { TranslateService } from "@ngx-translate/core";
import { CallTransferService } from "@onsip/common/services/api/resources/callTransfer/call-transfer.service";

interface TransferType {
  transfer: CallState;
}
interface ConferenceType {
  conference: CallState;
}
interface CallItemType {
  call: CallState;
}
interface DialogItemType {
  dialog: PersonalDialog;
}
interface ContactItemType {
  contact: ContactListItem;
}

type DragDropType = TransferType | ConferenceType | CallItemType | DialogItemType | ContactItemType;

@Injectable({ providedIn: "root" })
export class DragDropCallService {
  private fastPass = false;

  constructor(
    private callControllerService: CallControllerService,
    private contactService: ContactService,
    private callGroupService: CallGroupService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private callTransfer: CallTransferService,
    private dialog: MatDialog
  ) {}

  // eslint-disable-next-line
  dropListEnterPredicate(drag: any, drop: any) {
    return false;
  }

  /** Listener on the cdkDragDrop event which signifies a completed drop event
   * For this call drag drop, we will want to process the drag and drop item to determine what action to take
   * @param drag event propagated by cdk
   * @param dropData provided by us
   */
  dropEvent(event: CdkDragDrop<any>): void {
    this.processDropEvent(event.item.data, event.container.data);
  }

  private processDropEvent(dragData: DragDropType, dropData: DragDropType): void {
    // if drag and drop item is a combination of transfer and contact list item, perform a transfer call
    if (this.instanceOfTransfer(dragData) && this.instanceOfContact(dropData)) {
      this.transferCall(dragData.transfer, dropData.contact);
    } else if (this.instanceOfTransfer(dropData) && this.instanceOfContact(dragData)) {
      this.transferCall(dropData.transfer, dragData.contact);

      // if drag and drop item is a combination of call list item and contact list item, perform a transfer call
    } else if (this.instanceofCall(dragData) && this.instanceOfContact(dropData)) {
      this.transferCall(dragData.call, dropData.contact);
    } else if (this.instanceofCall(dropData) && this.instanceOfContact(dragData)) {
      this.transferCall(dropData.call, dragData.contact);

      // if drag item a dialog and drop item is a contact list item, perform a dialog transfer
    } else if (this.instanceOfDialog(dragData) && this.instanceOfContact(dropData)) {
      this.transferDialog(dragData.dialog, dropData.contact);

      // if drag and drop item is a combination of conference and contact list item, perform a conference call
    } else if (this.instanceOfConference(dragData) && this.instanceOfContact(dropData)) {
      this.confirmConference(dragData.conference, dropData.contact);
    } else if (this.instanceOfConference(dropData) && this.instanceOfContact(dragData)) {
      this.confirmConference(dropData.conference, dragData.contact);
    }
    // if it is not any of these combination, do nothing
  }

  private transferCall(call: CallState, contact: ContactListItem): void {
    if (!contact.aors) return;
    if (contact.aors && contact.aors[0] === call.remoteUri.slice(4)) return;

    if (this.fastPass) {
      this.endAnyConferencedCalls(call);
      this.callControllerService.blindTransfer(call.uuid, contact.aors[0]);
    } else {
      const callContact: Contact | undefined = this.contactService.findUsingAOR(
        call.remoteUri.slice(4)
      );
      const params: Record<string, string> = {
        target: contact.name,
        referee: callContact?.name || call.remoteDisplayName || call.remoteUri.slice(4)
      };
      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: this.translate.instant("ONSIP_I18N.TRANSFER_CALL_1"),
            message: this.translate.instant(
              "ONSIP_I18N.DO_YOU_WANT_TO_BLIND_TRANSFER_B_REFEREE_B_TO_B_TARGET_B",
              params
            ),
            showFastPass: true,
            primaryBtnText: "TRANSFER",
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(response => {
          if (response && response.doPrimaryAction) {
            const target =
              contact.aors && contact.aors.length > 0 ? contact.aors[0] : contact.dragDropTarget;
            if (target) {
              this.endAnyConferencedCalls(call);
              this.callControllerService.blindTransfer(call.uuid, target);
              this.fastPass = response.fastPass;
            }
          }
        });
    }
  }

  // we want to end any conferenced call before starting a call transfer
  private endAnyConferencedCalls(call: CallState): void {
    const conferenceCall = this.callGroupService.findThreeWayConferencePartner(call.uuid);
    console.warn(conferenceCall);
    if (conferenceCall) this.callControllerService.endCall(conferenceCall.uuid);
  }

  private transferDialog(dialog: PersonalDialog, contact: ContactListItem): void {
    if (!contact.aors) return;
    // note: if directiion is "recipient" remote is you
    const refereeAor = dialog.direction === "recipient" ? dialog.localAor : dialog.remoteAor;
    const refereeDisplayName =
      dialog.direction === "recipient" ? dialog.localDisplayName : dialog.remoteDisplayName;
    if (contact.aors && contact.aors[0] === refereeAor) return;
    if (this.fastPass) {
      this.remoteTransfer(dialog, contact.aors[0]);
    } else {
      const params: Record<string, string> = {
        target: contact.name,
        // remoteDisplayName and remoteAor can both be undefined
        // so using callId as failsafe but this should probably be something else
        referee: refereeDisplayName || refereeAor || dialog.callId
      };

      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: this.translate.instant("ONSIP_I18N.TRANSFER_CALL_1"),
            message: this.translate.instant(
              "ONSIP_I18N.DO_YOU_WANT_TO_BLIND_TRANSFER_B_REFEREE_B_TO_B_TARGET_B",
              params
            ),
            showFastPass: true,
            primaryBtnText: "TRANSFER",
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(response => {
          if (response && response.doPrimaryAction && contact.aors) {
            this.remoteTransfer(dialog, contact.aors[0]);
            this.fastPass = response.fastPassRequest;
          }
        });
    }
  }

  private remoteTransfer(dialog: PersonalDialog, target: string): void {
    this.callTransfer.callTransfer({
      CpIpAddress: dialog.coreProxyIp,
      FromTag: dialog.fromTag,
      ToTag: dialog.toTag,
      CallId: dialog.callId,
      Refer: dialog.direction === "recipient" ? "caller" : "callee",
      Target: target
    });
  }

  private confirmConference(call: CallState, contact: ContactListItem): void {
    if (!contact.aors) return;
    if (contact.aors && contact.aors[0] === call.remoteUri.slice(4)) return;

    if (this.fastPass) {
      this.conference(call, contact);
    } else {
      const params: Record<string, string> = {
        target: contact.name
      };
      this.dialog
        .open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: this.translate.instant("ONSIP_I18N.TRANSFER_CALL_1"),
            message: this.translate.instant(
              "ONSIP_I18N.DO_YOU_WANT_TO_ADD_B_TARGET_B_TO_THIS_CONFERENCE",
              params
            ),
            showFastPass: true,
            primaryBtnText: "TRANSFER",
            primaryBtnFlat: true
          }
        })
        .afterClosed()
        .pipe(take(1))
        .subscribe(response => {
          if (response && response.doPrimaryAction) {
            this.conference(call, contact);
            this.fastPass = response.fastPassRequest;
          }
        });
    }
  }

  private conference(call: CallState, contact: ContactListItem): void {
    if (contact.aors) {
      this.callGroupService.createThreeWayConferenceCall(call, contact.aors[0]).catch(error => {
        this.snackbarService.openSnackBar(error.message, "error");
      });
    }
  }

  // helper functions to type check data against respective interfaces
  private instanceOfTransfer(data: DragDropType): data is TransferType {
    return "transfer" in data;
  }

  private instanceOfConference(data: DragDropType): data is ConferenceType {
    return "conference" in data;
  }

  private instanceofCall(data: DragDropType): data is CallItemType {
    return "call" in data;
  }

  private instanceOfDialog(data: DragDropType): data is DialogItemType {
    return "dialog" in data;
  }

  private instanceOfContact(data: DragDropType): data is ContactItemType {
    return "contact" in data;
  }
}
