import { CDK_ROW_TEMPLATE, CdkRow } from "@angular/cdk/table";
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from "@angular/core";

export { OnsipHeaderRowDefDirective } from "./table-row/header-row-def.directive";
export { OnsipFooterRowDefDirective } from "./table-row/footer-row-def.directive";
export { OnsipRowDefDirective } from "./table-row/row-def.directive";
export { OnsipHeaderRowComponent } from "./table-row/header-row.component";
export { OnsipFooterRowComponent } from "./table-row/footer-row.component";
export { OnsipNoDataRowDirective } from "./table-row/no-data-row.directive";

/** Data row template container that contains the cell outlet. Adds the right class and role. */
@Component({
  selector: "onsip-row, tr[onsip-row]",
  template: CDK_ROW_TEMPLATE,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: "onsip-row",
    role: "row"
  },
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  exportAs: "onsipRow",
  providers: [{ provide: CdkRow, useExisting: OnsipRowComponent }]
})
export class OnsipRowComponent extends CdkRow {}
