import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ShellNavigationsModule } from "../../shellNavigations/shell-navigations.module";
import { OrgSideNavComponent } from "./org-side-nav.component";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    MatSelectModule,
    MatFormFieldModule,
    TranslateModule,
    ShellNavigationsModule
  ],
  declarations: [OrgSideNavComponent],
  exports: [OrgSideNavComponent]
})
export class OrgSideNavModule {}
