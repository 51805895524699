import { SupportService } from "../../../../common/services/support/support.service";

import {
  Component,
  ViewChild,
  Input,
  ElementRef,
  OnInit,
  AfterViewInit,
  OnChanges,
  ChangeDetectionStrategy
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "onsip-voicemail-message-audio",
  templateUrl: "./voicemail-message-audio.component.html",
  styleUrls: ["./voicemail-message-audio.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoicemailMessageAudioComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() audioFile: any;
  @Input() messageVolume = 80;
  @Input() voicemailMessage: any;
  @Input() isMobile = false;

  @ViewChild("messageAudio") messageAudio!: ElementRef;

  mozilla = false;
  sanitizedAudioFile: any;

  constructor(private sanitizer: DomSanitizer, public supportService: SupportService) {}

  /**
   * Set the volume on all voicemail audio tags.
   *
   * We need to do this because the only way to interact with an <audio> element's
   * volume is through the Javascript DOM interface. You can't just drop an
   * attribute like <audio volume="x">. For some reason that is unsupported.
   */
  setVoicemailVolume() {
    this.messageAudio.nativeElement.volume = this.messageVolume / 100.0;
  }

  sanitize(url: any): any {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.mozilla = this.supportService.getBrowser().name === "firefox"; // will either be true or undefined
  }

  ngAfterViewInit() {
    if (
      !(this.supportService.browser.name === "safari" || this.supportService.browser.name === "ios")
    ) {
      this.setVoicemailVolume();
    }
  }

  ngOnChanges() {
    if (
      this.messageAudio &&
      !(this.supportService.browser.name === "safari" || this.supportService.browser.name === "ios")
    ) {
      this.setVoicemailVolume();
    }
    if (this.audioFile) {
      this.sanitizedAudioFile = this.sanitize(this.audioFile);
    }
  }
}
