<form #form="ngForm" [formGroup]="submitTicketForm" (ngSubmit)="submit()">
  @if (showHeader) {
  <div class="subsection-header mat-subtitle-1">Submit a ticket</div>
  }
  <div class="submit-ticket-form-grid onsip-grid-placement">
    <div class="first-row">
      <div class="first-col">
        <mat-form-field appearance="outline" onsip-form-field-flush-outline>
          <mat-select formControlName="issueType" placeholder="Issue type">
            @for (type of issueTypes; track type) {
            <mat-option [value]="type">
              {{ type }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="sec-col">
        @if (showSubject) {
        <div>
          <mat-form-field appearance="outline" class="sec-col" onsip-form-field-flush-outline>
            <input matInput type="text" formControlName="subject" placeholder="Subject" />
          </mat-form-field>
        </div>
        }
      </div>
    </div>
    <div class="second-row">
      <mat-form-field appearance="outline" onsip-form-field-flush-outline>
        <textarea
          matInput
          type="text"
          formControlName="details"
          placeholder="Type details here"
          (focus)="handleDetailFocus()"
        ></textarea>
        <mat-error>Description field is required.</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="button-container">
    <button mat-flat-button color="primary" class="submit-btn" type="submit">
      {{ "ONSIP_I18N.SUBMIT" | translate }}
    </button>
  </div>
</form>
