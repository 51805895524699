import { Session, SessionDescriptionHandlerModifier, Web } from "sip.js";

import { log } from "../../../../common/libraries/sip/log";
import {
  replaceIllegalOptionsModifier,
  useInactiveModifier
} from "../../../libraries/webrtc/modifiers";
import { SessionDescriptionHandler as SessionDescriptionHandlerBase } from "../../../libraries/webrtc/session-description-handler";
import { SessionDescriptionHandlerFactoryOptions } from "./session-description-handler-factory";
import { SessionSIP } from "../../../../common/libraries/sip/session-sip";

import { WebAudioService } from "../services/webAudio/web-audio.service";

export class SessionDescriptionHandler extends SessionDescriptionHandlerBase {
  private webAudioService: WebAudioService;
  private session: SessionSIP;

  public get peerConnection(): RTCPeerConnection | undefined {
    return this._peerConnection;
  }
  public set peerConnection(value: RTCPeerConnection | undefined) {
    this._peerConnection = value;
  }

  static toSessionSIP(session: Session): SessionSIP {
    if (!session.data || !(session.data instanceof SessionSIP)) {
      throw new Error("SIP.js session data is not an instance of SessionSIP.");
    }
    return session.data;
  }

  constructor(
    session: Session,
    mediaStreamFactory: Web.MediaStreamFactory,
    options: SessionDescriptionHandlerFactoryOptions
  ) {
    super(session.userAgent.getLogger("sip.SDH"), mediaStreamFactory, options);
    this.session = SessionDescriptionHandler.toSessionSIP(session);
    this.webAudioService = options.webAudioService;
  }

  getDescription(
    options?: Web.SessionDescriptionHandlerOptions,
    modifiers?: Array<SessionDescriptionHandlerModifier>
  ): Promise<{ body: string; contentType: string }> {
    options = options || {};
    options.constraints = {
      audio: this.session.audio || (options.constraints && options.constraints.audio) || true,
      video: this.session.video || (options.constraints && options.constraints.video) || false
    };

    if (!options.constraints.video) {
      modifiers = modifiers || [];
      modifiers.push(useInactiveModifier());
      modifiers.push(replaceIllegalOptionsModifier());
    }

    const constraints = options.constraints;

    return super.getDescription(options, modifiers).then(description => {
      // Hold will properly disable/enable the tracks on its own
      if (!this.session.holdInProgress) {
        this.enableLocalAudio(!this.session.mute && !this.session.hold && !!constraints.audio);
        this.enableLocalVideo(!this.session.mute && !this.session.hold && !!constraints.video);
      }
      return description;
    });
  }

  protected setLocalMediaStream(stream: MediaStream): Promise<void> {
    const webAudio: any = this.webAudioService.createWrapper(
      this.session.SIP.request.callId,
      stream
    );
    stream = webAudio ? webAudio.localAudioDestination.stream : stream;
    return super.setLocalMediaStream(stream).then(() => {
      // FIXME: Broken implementation
      //
      // "audio available" is intended to indicate that audio is now available from the remote peer, and
      // "video available" is intended to indicate that video is now available from the remote peer, however...
      //
      // This implementation herein setting audio available to true if...
      //  a) the local media stream has audio available, and
      //  b) the remote media stream has ANY media available
      //
      // This implementation herein setting video available to true if...
      //  a) the local media stream has video available, and
      //  b) the remote media stream has ANY media available
      //
      // So it's being used more or less backwards from everywhere else (including mobile) and should be fixed.
      // Unfortunately the web code now depends on video available being defined in this fashion
      // so there's a bit of clean to be done...

      // This broken implementation should be removed...
      if (
        this.localMediaStream.getAudioTracks().length > 0 &&
        this.remoteMediaStream.getTracks().length > 0
      ) {
        log.warn(
          "SessionDescriptionHandler.setLocalMediaStream - setting audio available to true (FIXME)"
        );
        this.session.setAudioAvailable(true);
      }

      if (
        this.localMediaStream.getVideoTracks().length > 0 &&
        this.remoteMediaStream.getTracks().length > 0
      ) {
        log.warn(
          "SessionDescriptionHandler.setLocalMediaStream - setting video available to true (FIXME)"
        );
        this.session.setVideoAvailable(true);
      }
    });
  }
}
