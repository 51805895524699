import { combineLatest } from "rxjs";
import { map, distinctUntilChanged, pairwise } from "rxjs/operators";

import { FirestoreAuthUserService } from "./firestore-auth-user.service";
import { UserAgentsCallsPresentService } from "./user-agents-calls-present.service";
import { TopicsPresenceService } from "./topics-presence.service";

/**
 * The point of this service is to bootstrap the other services early
 * FirestoreCall for example needs to be up before the callcomponent uses it
 * otherwise we will not add the call and sayso countdown timers will fail
 */
// NOTE: there's no @Injectable here- that's on purpose: this service is provided by an explicit provider in the appropriate platform-specific ngModule
export class FirebaseBootstrapService {
  constructor(
    private agents: UserAgentsCallsPresentService,
    private firestoreAuthUser: FirestoreAuthUserService,
    private topics: TopicsPresenceService,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ...deps: Array<any> // see appropriate ngModule for what these are
  ) {
    this.initTopics();
  }

  private initTopics(): void {
    // remove agents for topics that have been revoked
    combineLatest([
      this.firestoreAuthUser.state.pipe(map(state => state.topics)),
      this.agents.state
    ])
      .pipe(map(([u]) => u)) // map to first el of combineLatest: User state.topics
      .subscribe(userTopics => {
        const agentTopics = this.agents.array.map(agent => agent.target);
        agentTopics
          .filter(topic => !userTopics.includes(topic))
          .forEach(topic => this.removeTopic(topic));
      });

    // new topics automatically added
    this.firestoreAuthUser.state
      .pipe(
        map(state => state.topics),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([prev, next]) => {
        next.filter(topic => !prev.includes(topic)).forEach(topic => this.addTopic(topic));
      });
  }

  private addTopic(topic: string): void {
    this.agents.addAgent(topic);
    this.topics.addTopic(topic);
  }

  private removeTopic(topic: string): void {
    this.agents.removeAgent(topic);
    this.topics.removeTopic(topic);
  }
}
