import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import * as Highcharts from "highcharts";

const ONSIP_COLORS = {
  inbound: "#7A9BFF",
  outbound: "#012AA5"
};

export interface BarGraphData {
  name: string;
  value: number;
  tooltipInfo?: string;
}

@Component({
  selector: "onsip-highcharts-bar-graph",
  templateUrl: "./bar-graph.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartingBarGraphComponent implements OnInit, OnChanges {
  @Input() data!: Array<BarGraphData>;
  @Input() categoryName = "Destination";
  @Input() graphHeight = 190;
  @Input() graphWidth = 324;
  /** Required for highcharts-angular, bound to <highcharts-chart [Highcharts].../> */
  Highcharts = Highcharts;
  /** Highcharts master options object, update this for any changes to chart, e.g. new data
   *  bound to <highcharts-chart [options].../>
   */
  chartOptions!: Highcharts.Options;
  /** Callback Function sets this.chart, bound to <highcharts-chart [callbackFunction].../> */
  chartCallback!: Highcharts.ChartCallbackFunction;
  /** Reference to the Chart Object's "this", make this.chart the same as "this" from within chart's scope */
  chart: any;

  ngOnInit() {
    // Initialize a callback for the chart reference, this is important for dealing with any chart events
    const self = this;
    this.chartCallback = chart => (self.chart = chart); // saving chart reference
    this.setChartOptions();
  }

  ngOnChanges(): void {
    this.setChartOptions();
  }

  private setChartOptions() {
    const categories = this.data.map(d => d.name);
    const seriesAction: Array<Highcharts.SeriesOptionsType> = [
      {
        name: this.categoryName,
        type: "bar",
        data: this.data.map(d => d.value),
        color:
          this.categoryName === "Destination" ? ONSIP_COLORS["inbound"] : ONSIP_COLORS["outbound"]
      }
    ];
    this.chartOptions = {
      chart: {
        type: "bar",
        backgroundColor: "transparent",
        style: {
          fontFamily: "Roboto",
          fontWeight: "400",
          textAlign: "right"
        },
        marginTop: 32,
        width: this.graphWidth,
        height: this.getGraphHeight()
      },
      credits: { enabled: false },
      title: {
        verticalAlign: "top",
        align: "left",
        useHTML: true,
        text: this.titleStyle(),
        style: {
          fontSize: "12px"
        }
      },
      xAxis: {
        categories,
        title: {
          text: undefined
        },
        lineColor: "transparent",
        labels: {
          style: {
            fontSize: "12px"
          },
          useHTML: true,
          formatter: obj => {
            return this.formatLabel(obj);
          }
        },
        max: Math.min(3, this.data.length - 1) // if data length is greater than 4, set max to 3 to show the scrollbar, max starts count at 0
      },
      yAxis: {
        min: 0,
        title: {
          text: undefined
        },
        gridLineWidth: 0,
        labels: {
          enabled: false
        }
      },
      tooltip: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
            style: {
              fontWeight: "400",
              fontSize: "12px",
              color: "rgba(0,0,0,0.54)"
            }
          },
          pointWidth: 9,
          borderRadius: 5
        }
      },
      series: seriesAction
    };
  }

  private titleStyle(): string {
    return `<div style="display: flex; justify-content: space-between; width: ${
      this.graphWidth - 38
    }px;">
          <span>${this.categoryName.toUpperCase()}</span>
          <span>TOTAL</span>
        </div>`;
  }

  private formatLabel(label: Highcharts.AxisLabelsFormatterContextObject): string {
    const tooltipInfo = this.data[label.pos]?.tooltipInfo || "";

    return `
    <div title="${tooltipInfo}">
      ${label.value}
    </div>
  `;
  }

  private getGraphHeight(): number {
    const graphHeightMap: Record<number, number> = {
      // length of data being shown: graph height
      1: 70,
      2: 110,
      3: 150,
      4: 190
    };
    let dataLength = this.data.length;
    // max number of data being shown is 4
    dataLength = Math.min(dataLength, 4);
    // min number of data is 1. zero data length will show empty state so convert zero to 1 anyway to not repeat map values
    dataLength = Math.max(dataLength, 1);
    return graphHeightMap[dataLength];
  }
}
