import { Injectable } from "@angular/core";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { ApiResourceService } from "../api-resource.service";

import { Account, AddAccountParams, ApiAccount, apiAccountToAccount as clean } from "./account";
import { ApiAddAction, ApiBrowseAction, ApiEditAction, ApiReadAction } from "../../api-actions";
import { sessionId } from "../../apiParams/session-id";
import { accountId } from "../../apiParams/account-id";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { Contact } from "../../apiResponse/contact";
import { CreditCardParameters } from "../../apiResponse/credit-card";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { Observable } from "rxjs";
import { ParameterValue } from "../../util/api-action-description";
export { Account };

const debug = false;

@Injectable({ providedIn: "root" })
export class AccountService extends ApiResourceService<Account> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "Account", "accountId");
    debug && this.state.subscribe(state => console.warn("AccountService", state));
  }

  accountBrowse(params?: Record<string, ParameterValue>): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiBrowseAction.AccountBrowse,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Limit: 500,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiBrowseAction.AccountBrowse);
  }

  /** action specific for agents to add a new account under them. Only allowed if the session user is an agent */
  agentAddAccount(params: AddAccountParams): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAddAction.AgentAddAccount,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAddAction.AgentAddAccount);
  }

  currentAccountId(): Observable<string> {
    return this.store.state.pipe(accountId());
  }

  accountRead(extraParameters?: Record<string, ParameterValue>): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiReadAction.AccountRead,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...extraParameters
      }
    });
    return this.promiseState.toPromise(ApiReadAction.AccountRead);
  }

  accountEditContact(contact: Contact): ApiPromiseState<Account> {
    const apiContact = {
      Address: contact.address,
      City: contact.city,
      CountryId: contact.countryId,
      Email: contact.email,
      Name: contact.name,
      Organization: contact.organization,
      Phone: contact.phone,
      State: contact.state,
      Zipcode: contact.zipcode
    };
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AccountEditContact,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...apiContact
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AccountEditContact);
  }

  accountEditCreditCard(creditCard: CreditCardParameters): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AccountEditCreditCard,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        ...creditCard
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AccountEditCreditCard);
  }

  /**
   * @param Recharge - flag to enable or disable auto refill
   * @param RechargeThreshold - when account balance is less than RechargeThreshold, account will be charged until it reaches RechargeLevel
   * @param RechargeLevel - the amount of credit available after a recharge - RechargeLevel has to greater than RechargeThreshold
   *
   * example - if balance is -$1.00, threshold is $5.00, and rechargelevel is $10.00. Since balance is below threshold, we will charge the account
   * $11.00 so the account balance matches the rechargeLevel.
   */
  accountEditRecharge(
    Recharge: boolean,
    RechargeThreshold: number,
    RechargeLevel: number
  ): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AccountEditRecharge,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Recharge,
        RechargeThreshold,
        RechargeLevel
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AccountEditRecharge);
  }

  accountEditAddCredit(Amount: number): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AccountEditAddCredit,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Amount
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AccountEditAddCredit);
  }

  accountEditUnlimitedUserPricing(Enabled: boolean): ApiPromiseState<Account> {
    this.dispatcher.next({
      parameters: {
        Action: ApiEditAction.AccountEditUnlimitedUserPricing,
        AccountId: this.store.state.pipe(accountId()),
        SessionId: this.store.state.pipe(sessionId()),
        Enabled
      }
    });
    return this.promiseState.toPromise(ApiEditAction.AccountEditUnlimitedUserPricing);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiBrowseAction.AccountBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiAccount>>(response, action, "Account", "Accounts").map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiReadAction.AccountRead:
      case ApiAddAction.AgentAddAccount:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiAccount>(response, action, "Account"))],
            this.indexKeyName
          ),
          action
        );
        break;
      case ApiEditAction.AccountEditContact:
      case ApiEditAction.AccountEditCreditCard:
      case ApiEditAction.AccountEditRecharge:
      case ApiEditAction.AccountEditAddCredit:
      case ApiEditAction.AccountEditUnlimitedUserPricing:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            [clean(extractData<ApiAccount>(response, action, "Account"))],
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
