import { MonitoredCallEvent, EventId } from "./monitored-call-event";

/** Emitted when call monitoring stops. */
export class StopMonitoredCallEvent extends MonitoredCallEvent {
  id = EventId.StopMonitoredCallEventId;

  /**
   * Factory for StopMonitoredCallEvent.
   * @param uuid The uuid of the call.
   */
  constructor(uuid: string) {
    super(isStopMonitoredCallEvent, uuid);
  }
}

/**
 * User-Defined Type Guard for StopMonitoredCallEvent.
 * @param event Event to check.
 */
export function isStopMonitoredCallEvent(event: any): event is StopMonitoredCallEvent {
  return event instanceof StopMonitoredCallEvent;
}
