import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { UserService } from "@onsip/common/services/api/resources/user/user.service";
import { isAgentAdmin, isSuperUser, isSubAgentAdmin } from "@onsip/common/services/api/role";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { views } from "@onsip/web/app/phone/views";
import { Config } from "@onsip/common/config";

export const AgentAdminGuard = () => {
  const userService = inject(UserService);
  const router = inject(Router);
  const apiSession = inject(ApiSessionService);

  if (Config.IS_DESKTOP) return false;
  if (apiSession.isLoggedIn()) {
    return userService.selfUser.pipe(
      map(user => {
        const canAccess =
          isSuperUser(user.roles) || isAgentAdmin(user.roles) || isSubAgentAdmin(user.roles);
        if (canAccess) {
          return true;
        } else {
          router.navigate([views.HOME]);
          return false;
        }
      })
    );
  } else {
    router.navigate(["/login"]);
    return false;
  }
};
