import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { StateEmitter } from "../../emitter/state-emitter";
import { firebase } from "../cloud/firebase/platform-firebase-types";
const debug = true;

export interface OAuthPresenceState {
  id: string;
  token?: string;
}

export class OAuthPresenceListener extends StateEmitter<OAuthPresenceState> {
  private listenCallback: ((a: firebase.database.DataSnapshot | null) => any) | undefined =
    undefined;
  private listenRef: firebase.database.Reference;

  static makelistenRef(id: string): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`oauth/${id}`);
  }

  /**
   * Constructor
   * @param id The oauth key
   */
  constructor(private id: string) {
    super({ id, token: "" });
    this.listenRef = OAuthPresenceListener.makelistenRef(this.id);
  }

  dispose(): void {
    debug && console.log(`OAuthPresenceListener[${this.id}].dispose`);
    this.stop();
  }

  start(): void {
    if (this.listenCallback) {
      this.stop();
    }
    this.listenCallback = snap => {
      // eslint-disable-next-line no-null/no-null
      if (snap === null || snap.val() === null) {
        this.updateState("");
      } else if (snap.val()) {
        this.updateState(snap.val());
      } else {
        throw new TypeError("Invalid value.");
      }
    };
    this.listenRef.on("value", this.listenCallback, (error: any) => {
      debug && console.error(`OAuthPresenceListener[${this.id}].listenRef error`);
      debug && console.error(error);
      throw error;
    });
  }

  stop(): void {
    if (this.listenCallback) {
      this.listenRef.off("value", this.listenCallback);
      this.listenCallback = undefined;
      this.dispose();
    }
  }

  private updateState(value: string): void {
    debug && console.log(`OAuthPresenceListener[${this.id}].updateState`);
    this.stateStore.token = value;
    this.publishState();
  }
}
