import { LogService } from "../../../../../common/services/logging";

import { VolumeService } from "../volume/volume.service";
import { WebAudioService } from "../../services/webAudio/web-audio.service";

import { Subject, Observable } from "rxjs";

import { Injectable } from "@angular/core";

export interface DtmfEvent {
  key: string;
  type: "press" | "up";
  source: "button" | "keyboard";
  target: "newCall" | "call" | "instacall";
  duration?: number;
}

@Injectable({ providedIn: "root" })
export class DialpadService {
  event: Observable<DtmfEvent>;
  private _event = new Subject<DtmfEvent>();
  private audioNodes: Record<string, Array<number>> = {};
  private toneVolume = 80;
  private pressedKeys: { [key: string]: number } = {};
  private toneRows: Array<GainNode>;
  private toneCols: Array<GainNode>;

  constructor(
    private log: LogService,
    private volumeService: VolumeService,
    private webAudioService: WebAudioService
  ) {
    this.event = this._event.asObservable();
    this.event.subscribe(event => this.log.debug("DialpadService event - ", event));

    this.toneRows = this.webAudioService.createTones([697, 770, 852, 941]);
    this.toneCols = this.webAudioService.createTones([1209, 1336, 1477]);

    if (this.toneRows.length === 0 || this.toneCols.length === 0) {
      return;
    }

    const validInputs = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

    validInputs.forEach((dtmfKey, index) => {
      const row: number = Math.floor(index / 3),
        col: number = index % 3;

      this.audioNodes[dtmfKey] = [row, col];
    });

    this.volumeService.state.subscribe(state => {
      this.toneVolume = state.defaultVolume * 0.1;

      this.toneRows
        .concat(this.toneCols)
        .forEach(gainNode => (gainNode.gain.value = this.toneVolume / 100));
    });
  }

  startKey(
    key: string,
    source: "button" | "keyboard",
    target: "newCall" | "call" | "instacall"
  ): void {
    if (this.pressedKeys[key]) return;

    if (this.toneVolume > 0 && this.audioNodes[key]) {
      // ramp to mitigate popping sound (fade in 0.1s)
      if (!this.pressedSharesRow(key)) {
        this.webAudioService.connectToContext(
          this.toneRows[this.audioNodes[key][0]],
          this.toneVolume
        );
      }
      if (!this.pressedSharesColumn(key)) {
        this.webAudioService.connectToContext(
          this.toneCols[this.audioNodes[key][1]],
          this.toneVolume
        );
      }
      this.pressedKeys[key] = Date.now();
      this._event.next({ type: "press", key, source, target });
    }

    // timeout if active for more than 6 seconds
    setTimeout(() => this.stopKey(key, source, target), 6000);
  }

  stopKey(
    key: string,
    source: "button" | "keyboard",
    target: "newCall" | "call" | "instacall"
  ): void {
    if (!this.pressedKeys[key]) return;
    const duration = Date.now() - this.pressedKeys[key];
    delete this.pressedKeys[key];
    this._event.next({ type: "up", key, source, target, duration });
    if (key === "#") {
      this.stopKey("3", source, target);
    } else if (key === "*") {
      this.stopKey("8", source, target);
    }

    if (this.audioNodes[key]) {
      if (!this.pressedSharesRow(key)) {
        this.webAudioService.disconnectFromContext(this.toneRows[this.audioNodes[key][0]]);
      }
      if (!this.pressedSharesColumn(key)) {
        this.webAudioService.disconnectFromContext(this.toneCols[this.audioNodes[key][1]]);
      }
    }
  }

  private pressedSharesRow(key: string): boolean {
    const row = this.audioNodes[key][0];
    return Object.keys(this.pressedKeys).some(pressedKey => {
      const pressedRow = this.audioNodes[pressedKey][0];
      return row === pressedRow;
    });
  }

  private pressedSharesColumn(key: string) {
    const row = this.audioNodes[key][1];
    return Object.keys(this.pressedKeys).some(pressedKey => {
      const pressedRow = this.audioNodes[pressedKey][1];
      return row === pressedRow;
    });
  }
}
