<mat-divider></mat-divider>
<div class="footer">
  <div class="left-links">
    <div class="professional-link-container">
      <button mat-icon-button #copy disableRipple="true" (click)="openCallLinkModal()">
        <mat-icon color="primary">link</mat-icon>
      </button>
      <span
        class="mat-body-2"
        [matTooltip]="translatedCallLinkText"
        matTooltipClass="onsip-tooltip-background"
        [matTooltipPosition]="'above'"
      >
        {{ "ONSIP_I18N.PERSONAL_CALL_PAGE_LINK" | translate }}
      </span>
    </div>
    <a href="https://app.onsip.com" target="_blank"
      >@if (platform === 'desktop') {
      <span class="mat-body-2">{{ "ONSIP_I18N.OPEN_ADMIN_PORTAL" | translate }}</span>
      }</a
    >
    <a href="https://www.onsip.com/app/download" target="_blank"
      >@if (platform !== 'desktop') {
      <span class="mat-body-2">{{ "ONSIP_I18N.DOWNLOAD_DESKTOP_APP" | translate }}</span>
      }</a
    >
  </div>
  <div class="right-links">
    <div class="social-media-links">
      <a
        href="https://www.onsip.com/blog"
        target="_blank"
        [matTooltip]="'ONSIP_I18N.BLOG' | translate"
        matTooltipClass="onsip-tooltip-background"
        matTooltipPosition="below"
      >
        <img [src]="'resources/img/icons/blogfeed_icon.png'" alt="Blog" />
      </a>
      <a
        href="https://www.twitter.com/onsip"
        target="_blank"
        [matTooltip]="'ONSIP_I18N.TWITTER' | translate"
        matTooltipClass="onsip-tooltip-background"
        matTooltipPosition="below"
      >
        <img [src]="'resources/img/icons/twitter_icon.png'" alt="Twitter" />
      </a>
      <a
        href="https://www.facebook.com/onsip"
        target="_blank"
        [matTooltip]="'ONSIP_I18N.FACEBOOK' | translate"
        matTooltipClass="onsip-tooltip-background"
        matTooltipPosition="below"
      >
        <img [src]="'resources/img/icons/facebook_icon.png'" alt="Facebook" />
      </a>
      <a
        href="https://www.linkedin.com/company/junction-networks"
        target="_blank"
        [matTooltip]="'ONSIP_I18N.LINKEDIN' | translate"
        matTooltipClass="onsip-tooltip-background"
        matTooltipPosition="below"
      >
        <img [src]="'resources/img/icons/linkedin_icon.png'" alt="LinkedIn" />
      </a>
    </div>
    @if (showAdminTabs) {
    <a>
      <span class="mat-body-2" [routerLink]="views.CONTACT_SALES">{{
        "ONSIP_I18N.CONTACT_SALES" | translate
      }}</span>
    </a>
    <a>
      <span class="mat-body-2" [routerLink]="views.CONTACT_SUPPORT">{{
        "ONSIP_I18N.CONTACT_SUPPORT" | translate
      }}</span>
    </a>
    <a href="https://info.onsip.com/referral-program" target="_blank">
      <span class="mat-body-2">Customer Referral Program</span>
    </a>
    }
  </div>
</div>
<onsip-animation filename="footer"></onsip-animation>
