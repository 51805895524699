import { SessionEventFactory } from "./session-event";

/**
 * Emitted once when the an attempt to transfer fails.
 */
export class TransferFailedSessionEvent {
  static make(aor: string, uuid: string) {
    return SessionEventFactory.make(TransferFailedSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] transfer failed";
  }
}
