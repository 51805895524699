export const params = {
  AUTH: {
    SERVICE_ID: "Slack",
    CLIENT_ID: {
      PROD: "2398830389.66798899927",
      DEV: "2398830389.75443315664"
    },
    SCOPE: ["client"],
    ENDPOINT: "https://slack.com/oauth/authorize",
    WINDOW_HEIGHT: "800",
    WINDOW_WIDTH: "800"
  },

  CHANNEL_TYPE: {
    CHANNEL: "channel", // pubic team channel
    GROUP: "group", // private team channel
    IM: "im", // direct chat between 2 users
    MPIM: "mpim" // direct chat between more than 2 users
  },

  RTM: {
    CONNECTION_EVENTS: {
      CONNECTED: "rtm_service_connected", // received rtm hello event, provides rtm initial state
      DISCONNECTED: "rtm_service_disconnected",
      STOPPED: "rtm_service_stopped" // rtm disconnected and will not try to reconnect
    },

    ERRORS: {
      OPEN_FAIL: "failed to open rtm ws",
      SEND_FAIL: "failed to send message because connection is not open"
    },

    // message.type
    // https://api.slack.com/events
    EVENTS: {
      ACCOUNTS_CHANGED: "accounts_changed",
      BOT_ADDED: "bot_added",
      BOT_CHANGED: "bot_changed",
      CHANNEL_ARCHIVE: "channel_archive",
      CHANNEL_CREATED: "channel_created",
      CHANNEL_DELETED: "channel_deleted",
      CHANNEL_HISTORY_CHANGED: "channel_history_changed",
      CHANNEL_JOINED: "channel_joined",
      CHANNEL_LEFT: "channel_left",
      CHANNEL_MARKED: "channel_marked",
      CHANNEL_RENAME: "channel_rename",
      CHANNEL_UNARCHIVE: "channel_unarchive",
      COMMANDS_CHANGED: "commands_changed",
      DND_UPDATED: "dnd_updated",
      DND_UPDATED_USER: "dnd_updated_user",
      EMAIL_DOMAIN_CHANGED: "email_domain_changed",
      EMOJI_CHANGED: "emoji_changed",
      FILE_CHANGE: "file_change",
      FILE_COMMENT_ADDED: "file_comment_added",
      FILE_COMMENT_DELETED: "file_comment_deleted",
      FILE_COMMENT_EDITED: "file_comment_edited",
      FILE_CREATED: "file_created",
      FILE_DELETED: "file_deleted",
      FILE_PRIVATE: "file_private",
      FILE_PUBLIC: "file_public",
      FILE_SHARED: "file_shared",
      FILE_UNSHARED: "file_unshared",
      GROUP_ARCHIVE: "group_archive",
      GROUP_CLOSE: "group_close",
      GROUP_HISTORY_CHANGED: "group_history_changed",
      GROUP_JOINED: "group_joined",
      GROUP_LEFT: "group_left",
      GROUP_MARKED: "group_marked",
      GROUP_OPEN: "group_open",
      GROUP_RENAME: "group_rename",
      GROUP_UNARCHIVE: "group_unarchive",
      HELLO: "hello",
      IM_CLOSE: "im_close",
      IM_CREATED: "im_created",
      IM_HISTORY_CHANGED: "im_history_changed",
      IM_MARKED: "im_marked",
      IM_OPEN: "im_open",
      MANUAL_PRESENCE_CHANGE: "manual_presence_change",
      MESSAGE: "message",
      MPIM_CLOSE: "mpim_close",
      MPIM_HISTORY_CHANGED: "mpim_history_changed",
      MPIM_JOINED: "mpim_joined",
      MPIM_OPEN: "mpim_open",
      PIN_ADDED: "pin_added",
      PIN_REMOVED: "pin_removed",
      PING: "ping",
      PONG: "pong",
      PREF_CHANGE: "pref_change",
      PRESENCE_CHANGE: "presence_change",
      REACTION_ADDED: "reaction_added",
      REACTION_REMOVED: "reaction_removed",
      RECONNECT_URL: "reconnect_url", // experimental and undocumented
      STAR_ADDED: "star_added",
      STAR_REMOVED: "star_removed",
      SUBTEAM_CREATED: "subteam_created",
      SUBTEAM_SELF_ADDED: "subteam_self_added",
      SUBTEAM_SELF_REMOVED: "subteam_self_removed",
      SUBTEAM_UPDATED: "subteam_updated",
      TEAM_DOMAIN_CHANGE: "team_domain_change",
      TEAM_JOIN: "team_join",
      TEAM_MIGRATION_STARTED: "team_migration_started",
      TEAM_PLAN_CHANGE: "team_plan_change",
      TEAM_PREF_CHANGE: "team_pref_change",
      TEAM_PROFILE_CHANGE: "team_profile_change",
      TEAM_PROFILE_DELETE: "team_profile_delete",
      TEAM_PROFILE_REORDER: "team_profile_reorder",
      TEAM_RENAME: "team_rename",
      USER_CHANGE: "user_change",
      USER_TYPING: "user_typing"
    },
    // message.subtype (only when message.type = message)
    // * structured so that clients can implement distinct behavior for each different subtype,
    //   or can fallback to a simple mode by just displaying the text of the message
    MESSAGE_EVENTS: {
      BOT_MESSAGE: "bot_message",
      CHANNEL_ARCHIVE: "channel_archive",
      CHANNEL_JOIN: "channel_join",
      CHANNEL_LEAVE: "channel_leave",
      CHANNEL_NAME: "channel_name",
      CHANNEL_PURPOSE: "channel_purpose",
      CHANNEL_TOPIC: "channel_topic",
      CHANNEL_UNARCHIVE: "channel_unarchive",
      FILE_COMMENT: "file_comment",
      FILE_MENTION: "file_mention",
      FILE_SHARE: "file_share",
      GROUP_ARCHIVE: "group_archive",
      GROUP_JOIN: "group_join",
      GROUP_LEAVE: "group_leave",
      GROUP_NAME: "group_name",
      GROUP_PURPOSE: "group_purpose",
      GROUP_TOPIC: "group_topic",
      GROUP_UNARCHIVE: "group_unarchive",
      ME_MESSAGE: "me_message",
      MESSAGE_CHANGED: "message_changed",
      MESSAGE_DELETED: "message_deleted",
      PINNED_ITEM: "pinned_item",
      UNPINNED_ITEM: "unpinned_item"
    },
    RECONNECTION: {
      BACKOFF: 3000,
      MAX_ATTEMPTS: 10,
      REASONS: {
        ACK_TIMEOUT: "timed out waiting for for message confirmation",
        CONNECTED_TIMEOUT: "timed out waiting for rtm server hello (connection ack)",
        USER_CLOSE: "user closed rtm connection",
        WS_CLOSED: "rtm ws closed unexpectedly",
        WS_ERROR: "rtm ws reported an error",
        OFFLINE: "host has lost network connectivity"
      }
    },
    TIMEOUT: {
      ACTIVITY: 10000,
      CONNECTED: 20000,
      ACK: 20000
    }
  },

  WEBAPI: {
    ERRORS: {
      account_inactive:
        "authentication failed because the user does not have an active slack account",
      channel_not_found: "chat conversation does not exist",
      invalid_auth: "authorization is no longer valid",
      invalid_arg_name: "argument invalid",
      invalid_array_arg: "argument array is invalid",
      invalid_charset: "charset is invalid",
      invalid_form_data: "invalid form data",
      invalid_post_type: "invalid post type",
      migration_in_progress: "team is being migrated between servers",
      missing_post_type: "post type undefined",
      not_authed: "unauthorized",
      request_timeout: "request timed out",
      token_revoked: "authorization has been revoked",
      user_disabled: "user is disabled",
      user_not_found: "user does not exist",
      user_not_visible: "not authorized to view user"
    },
    WARNINGS: {
      missing_charset: "missing charset",
      superfluous_charset: "the specified Content-Type is not defined to understand the charset"
    },
    URL: "https://slack.com/api/",
    HISTORY_SIZE: 50
  }
};
