@if (frame === 0) {
<h1 class="mat-headline-4">
  {{ "ONSIP_I18N.MONITOR_AGENTS_IN_REALTIME" | translate }}
</h1>
} @if (frame === 1) {
<h1 class="mat-headline-4">
  {{ "ONSIP_I18N.VIEW_UPTOTHEMINUTE_DATA" | translate }}
</h1>
} @if (frame === 2) {
<h1 class="mat-headline-4">
  {{ "ONSIP_I18N.GET_THE_LATEST_STATS_DELIVERED_TO_YOUR_INBOX" | translate }}
</h1>
}
<onsip-carousel (changeEmitter)="frame = $event">
  <ng-template cdkPortal>
    <mat-card class="mat-elevation-z4">
      <mat-card-content>{{
        "ONSIP_I18N.SEE_WHO_IS_LOGGED_IN_AND_ACTIVE_AND_LISTEN_BARGE_AND_WHISPER_INTO_THEIR_CALLS"
          | translate
      }}</mat-card-content>
      <img
        mat-card-image
        src="resources/img/queueMarketing/queue-marketing-0.png"
        alt="Queue Image 1"
      />
    </mat-card>
  </ng-template>
  <ng-template cdkPortal>
    <mat-card class="mat-elevation-z4">
      <mat-card-content>{{
        "ONSIP_I18N.CHECK_HISTORICAL_AND_CURRENT_WAIT_TIMES_AVERAGE_CALL_TIMES_AND_BUSIEST_TIMES_OF_DAY"
          | translate
      }}</mat-card-content>
      <img
        mat-card-image
        src="resources/img/queueMarketing/queue-marketing-1.png"
        alt="Queue Image 2"
      />
    </mat-card>
  </ng-template>
  <ng-template cdkPortal>
    <mat-card class="mat-elevation-z4">
      <mat-card-content>{{
        "ONSIP_I18N.RECEIVE_AUTOMATED_EMAIL_SNAPSHOTS_OF_YOUR_SALES_AND_SUPPORT_OPERATIONS"
          | translate
      }}</mat-card-content>
      <img
        mat-card-image
        src="resources/img/queueMarketing/queue-marketing-2.png"
        alt="Queue Image 3"
      />
    </mat-card>
  </ng-template>
</onsip-carousel>
<h3
  [innerHtml]="'ONSIP_I18N.UPGRADE_TO_STRONGENHANCED_CALLING_QUEUESSTRONG_TODAY' | translate"
></h3>
<button
  mat-flat-button
  color="primary"
  aria-label="speak to an agent"
  id="onsip--queue-marketing--speak-to-an-agent"
  onsipNewCall
  remoteAor="sales@onsip.com"
  [contact]="contact"
>
  {{ "ONSIP_I18N.SPEAK_TO_AN_AGENT" | translate }}
</button>
