<mat-card>
  <mat-card-content>
    <div class="queue-warning__line">For queue {{ warning.queueName }}</div>
    <div class="queue-warning__line">
      when the queue has
      <b>{{ warning.thresholdType | underscoresToSpaces }}</b> @if(warning.thresholdValue) { ({{
        warning.thresholdValue
      }}) } send email to
    </div>
    <div>
      <b>
        {{ warning.alertDestination }}
      </b>
    </div>
    <div class="queue-warning__line">
      Alert during
      <b>
        {{ formatBhr(warning.bhrData) }}
        ({{ warning.bhrData.TimeZone }})
      </b>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="warn" (click)="toggleEdit()">Edit</button>
  </mat-card-actions>
</mat-card>
