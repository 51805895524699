import { NgModule } from "@angular/core";
import { PhoneUserAddressService } from "./phone-user-address.service";
import { UserAddressService } from "../../../../common/services/api/resources/userAddress/user-address.service";
import { NoAuthOrganizationSummaryService } from "../../../../common/services/api/resources/noAuthOrganizationSummary/no-auth-organization-summary.service";
import { PhoneNoAuthOrganizationSummaryService } from "./phone-noauth-org-summary.service";
import { UserCustomizationService } from "../../../../common/services/api/resources/userCustomization/user-customization.service";
import { PhoneUserCustomizationService } from "./phone-user-customization.service";
import { UserSummaryContactService } from "../../../../common/services/api/resources/userSummaryContact/user-summary-contact.service";
import { PhoneUserSummaryContactService } from "./phone-user-summary-contact.service";
import { UserService } from "../../../../common/services/api/resources/user/user.service";
import { PhoneUserService } from "./phone-user.service";
import { WebCallTopicService } from "../../../../common/services/api/resources/webCallTopic/web-call-topic.service";
import { PhoneWebCallTopicService } from "./phone-web-call-topic.service";
import { OrganizationService } from "../../../../common/services/api/resources/organization/organization.service";
import { PhoneOrganizationService } from "./phone-organization.service";
import { SdrService } from "../../../../common/services/api/resources/sdr/sdr.service";
import { PhoneSdrService } from "./phone-sdr.service";
import { GroupAddressService } from "../../../../common/services/api/resources/groupAddress/group-address.service";
import { PhoneGroupAddressService } from "./phone-group-address.service";
import { E911Service } from "../../../../common/services/api/resources/e911/e911.service";
import { PhoneE911Service } from "./phone-e911.service";
import { AccountService } from "../../../../common/services/api/resources/account/account.service";
import { PhoneAccountService } from "./phone-account.service";
import { AccountDetailsService } from "../../../../common/services/api/resources/accountDetails/account-details.service";
import { PhoneAccountDetailsService } from "./phone-account-details.service";
import { PackageService } from "../../../../common/services/api/resources/package/package.service";
import { PhonePackageService } from "./phone-package.service";
import { AdvAttendantMenuService } from "../../../../common/services/api/resources/attendantMenu/attendant-menu.service";
import { PhoneAdvAttendantMenuService } from "./phone-adv-attendant-menu.service";

@NgModule({
  providers: [
    { provide: AccountService, useClass: PhoneAccountService },
    { provide: AccountDetailsService, useClass: PhoneAccountDetailsService },
    { provide: E911Service, useClass: PhoneE911Service },
    { provide: GroupAddressService, useClass: PhoneGroupAddressService },
    { provide: NoAuthOrganizationSummaryService, useClass: PhoneNoAuthOrganizationSummaryService },
    { provide: OrganizationService, useClass: PhoneOrganizationService },
    { provide: PackageService, useClass: PhonePackageService },
    { provide: SdrService, useClass: PhoneSdrService },
    { provide: UserAddressService, useClass: PhoneUserAddressService },
    { provide: UserCustomizationService, useClass: PhoneUserCustomizationService },
    { provide: UserSummaryContactService, useClass: PhoneUserSummaryContactService },
    { provide: UserService, useClass: PhoneUserService },
    { provide: WebCallTopicService, useClass: PhoneWebCallTopicService },
    { provide: AdvAttendantMenuService, useClass: PhoneAdvAttendantMenuService }
  ]
})
export class PhoneLifeCycleHookApiResourceModule {}
