import { ContactListEditComponent } from "./contact-list-edit.component";
import { ContactComponent } from "./contact.component";
import { ContactPageComponent } from "./contact-page.component";
import { ContactOnSIPComponent } from "../contactOnsip/contact-onsip.component";

import { AddContactModule } from "./add-contact.module";
import { AvatarModule } from "../shared/components/avatar/avatar.module";
import { ModalModule } from "../shared/components/modal/modal.module";
import { ChatModule } from "../shared/components/chat/chat.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { UsabilityModule } from "../shared/components/usability/usability.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatIconModule } from "@angular/material/icon";
import { MatDividerModule } from "@angular/material/divider";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  imports: [
    AddContactModule,
    AvatarModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ModalModule,
    ChatModule,
    NewCallModule,
    UsabilityModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    ReactiveFormsModule,
    MatFormFieldModule
  ],

  declarations: [
    ContactListEditComponent,
    ContactComponent,
    ContactPageComponent,
    ContactOnSIPComponent
  ],

  exports: [ContactListEditComponent, ContactComponent, ContactPageComponent, ContactOnSIPComponent]
})
export class ContactModule {}
