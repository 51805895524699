import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MaterialModule } from "../../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { TestInstallationModule } from "../testInstallation/test-installation.module";

import { CMSInstructionsComponent } from "./cms-instructions.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule,
    TestInstallationModule
  ],
  declarations: [CMSInstructionsComponent],
  exports: [CMSInstructionsComponent]
})
export class CMSInstructionsModule {}
