import { Component, OnInit, ChangeDetectionStrategy } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IdentityService } from "@onsip/common/services/identity.service";
import { Config } from "@onsip/common/config";
import { SentryErrorHandler } from "@onsip/common/services/sentry-error.handler";
import { SupportService } from "@onsip/common/services/support/support.service";
import { ProvideFeedbackService } from "@onsip/common/services/api/resources/provideFeedback/provide-feedback.service";
import { FormControl } from "@angular/forms";

@Component({
  selector: "onsip-sentry-report",
  templateUrl: "sentry-report.component.html",
  styleUrls: ["sentry-report.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SentryReportComponent implements OnInit {
  userReportControl = new FormControl("");
  private sipAddress = "";
  private platform = Config.PLATFORM_STRING;
  private version = Config.VERSION_NUMBER;

  constructor(
    private identityService: IdentityService,
    private supportService: SupportService,
    private dialogRef: MatDialogRef<SentryReportComponent>,
    private provideFeedbackService: ProvideFeedbackService
  ) {}

  ngOnInit(): void {
    const state = this.identityService.stateValue;
    if (state && state.defaultIdentity) {
      this.sipAddress = state.defaultIdentity.aor;
    }
  }

  reportIssue(): void {
    const userReport = this.userReportControl.value as string;
    SentryErrorHandler.sendMessage(userReport);
    const browser = this.supportService.getBrowser();
    this.provideFeedbackService.ProvideFeedback({
      Platform:
        this.platform === "web"
          ? browser.platform + " " + browser.name + " " + browser.version
          : "desktop " + browser.platform,
      SipAddress: this.sipAddress,
      Version: this.version,
      Message: userReport
    });
    this.dialogRef.close();
  }
}
