import { Contact } from "../../../common/interfaces/contact";

export declare interface CallHistoryCall {
  callId: string;
  /** iso date, ie "2017-08-23T21:38:56.000Z" */
  callTime: string;
  direction: RecentCallDirection;
  disposition: RecentCallDisposition;
  /** ms, ie 6000 */
  duration: number;
  /** without 'sip:' - for example, john@junctionnetworks.com */
  localUri: string;
  /** id use to match sayso calls, only get from sdr. non sdr inserts will not have ouid */
  ouid: string;
  /** ie "James Criscuolo" */
  remoteName: string;
  /** without 'sip:' - for example john@junctionetworks.com */
  remoteUri: string;
}

export enum RecentCallDirection {
  INBOUND = "Inbound",
  OUTBOUND = "Outbound"
}

export enum RecentCallDisposition {
  ANSWERED = "Answered",
  CANCELLED = "Cancelled",
  /** not in the set of SDR dispositions as it is inferred from call direction */
  MISSED = "Missed",
  REJECTED = "Rejected",
  FAILED = "Failed",
  /** "expired" calls were "answered" but never hung up by user agents (core removes them) */
  EXPIRED = "Expired",
  /** this should just be for initial state */
  UNKNOWN = "Unknown"
}

export declare interface MobileCallHistoryCall extends CallHistoryCall {
  /** ie "Today" */
  displayDate: string;
  displayName: string;
  /** ie "17:38:56 GMT-0400 (EDT)" */
  displayTime: string;
  icon: string;
  isHeader: boolean;
  missed: boolean;
  text: string;
}

export declare interface PhoneCallHistoryCall extends CallHistoryCall {
  contact: Contact;
  /** ie "Today" */
  displayDate: string;
  displayName: string;
  displayNamePrefix: string;
  /** ie "17:38:56 GMT-0400 (EDT)" */
  displayTime: string;
  isIncoming: boolean;
  shouldBold: boolean;
}
