import { URI } from "sip.js";

import { InvalidTargetCallError } from "./call-controller-error";
import { UserAgent } from "./user-agent";

/** CallContact implementation. */
export class CallContact {
  /**
   * Constructor.
   * @param target The target of the call.
   * @param displayName The display name of the target.
   */
  constructor(private target: string, private displayName: string) {}

  /**
   * Get the display name for the Call.
   */
  getDisplayName(): string {
    return this.displayName;
  }

  /**
   * Gets the URI for the Call.
   * @param userAgent The UserAgent making the Call.
   * @reject {InvalidTargetCallError} Invalid target.
   */
  getURI(userAgent: UserAgent): URI {
    // uri validation
    const uri = userAgent.targetToURI(this.target);
    if (!uri) {
      throw new InvalidTargetCallError(
        `CallController[${userAgent.aor}] Failed to create uri from ${this.target}.`
      );
    }
    return uri;
  }
}
