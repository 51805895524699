import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { StateEmitter } from "../../../libraries/emitter/state-emitter";
import { TopicPresencePublisher } from "../../../libraries/firebase/realtime/topic-presence-publisher";
import { TopicPresenceListener } from "../../../libraries/firebase/realtime/topic-presence-listener";

import { AuthService, AuthState } from "../auth.service";

export interface TopicPresenceState {
  status: "init" | "connected" | "disconnected";
}

export class TopicPresence extends StateEmitter<TopicPresenceState> {
  private authState: AuthState | undefined;
  private topicPresencePublisher: TopicPresencePublisher | undefined;
  private topicPresenceListener: TopicPresenceListener | undefined;
  private unsubscribe: Subject<void> = new Subject<void>();

  static initialState(): TopicPresenceState {
    return {
      status: "init"
    };
  }

  constructor(private tid: string, protected auth: AuthService) {
    super(TopicPresence.initialState());
    this.init();
  }

  dispose(): void {
    this.publishStateComplete();
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.stopTopicPresence();
  }

  get target(): string {
    return this.tid;
  }

  private init(): void {
    Promise.resolve().then(() => {
      this.subscribeAuth();
    });
  }

  private signedIn(): void {
    this.startTopicPresence();
  }

  private signedOut(): void {
    this.stopTopicPresence();
  }

  private startTopicPresence(): void {
    if (!this.authState) throw new Error("auth state undefined");
    if (!this.authState.oid) throw new Error("auth state oid undefined");
    if (!this.authState.uid) throw new Error("auth state uid undefined");
    const oid = this.authState.oid;
    const uid = this.authState.uid;
    this.topicPresencePublisher = new TopicPresencePublisher(oid, this.tid, uid);
    this.topicPresencePublisher.state.subscribe(state => {
      this.stateStore.status = state.status;
      this.publishState();
    });
    this.topicPresencePublisher.publish();

    this.topicPresenceListener = new TopicPresenceListener(oid, this.tid);
    this.topicPresenceListener.start();
  }

  private stopTopicPresence(): void {
    if (this.topicPresencePublisher) {
      this.topicPresencePublisher.dispose();
      this.topicPresencePublisher = undefined;
    }
    if (this.topicPresenceListener) {
      this.topicPresenceListener.dispose();
      this.topicPresenceListener = undefined;
    }
  }

  private subscribeAuth(): void {
    this.auth.state.pipe(takeUntil(this.unsubscribe)).subscribe(state => {
      const didChange = AuthService.didAuthStatusChange(this.authState, state);
      this.authState = state;
      switch (didChange) {
        case "signedIn":
          return this.signedIn();
        case "signedOut":
          return this.signedOut();
        default:
          return;
      }
    });
  }
}
