import { CallComponent } from "./call.component";
import { IncomingCallComponent } from "./incoming-call.component";
import { CallPageComponent } from "./page/call-page.component";
import { CallStatusComponent } from "./status/call-status.component";
import { CallListComponent } from "./list/call-list.component";
import { CallListItemComponent } from "./list/call-list-item.component";
import { DialogListItemComponent } from "./list/dialog-list-item.component";
import { BaseCallComponent } from "./base-call.component";
import { PulsingDotComponent } from "./pulsing-dot.component";

import { PopupContainerModule } from "../shared/components/popupContainer/popup-container.module";
import { VolumeModule } from "../shared/components/volume/volume.module";
import { PipesModule } from "../shared/pipes/pipes.module";
import { DialpadModule } from "../shared/components/dialpad/dialpad.module";
import { ChatModule } from "../shared/components/chat/chat.module";
import { ContactModule } from "../contact/contact.module";
import { AvatarModule } from "../shared/components/avatar/avatar.module";
import { UserAgentModule } from "../shared/components/userAgent/user-agent.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { RemoteIdentityModule } from "../shared/components/remoteIdentity/remote-identity.module";
import { CallVideoContainerModule } from "./video/call-video-container.module";
import { HubspotModule } from "../shared/components/hubspot/hubspot.module";
import { MaterialModule } from "../../modules/material/material.module";
import { AnimationModule } from "../shared/components/animation/animation.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { TimerModule } from "../shared/components/timer/timer.module";

@NgModule({
  imports: [
    PopupContainerModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    VolumeModule,
    PipesModule,
    DialpadModule,
    ChatModule,
    AvatarModule,
    UserAgentModule,
    NewCallModule,
    RemoteIdentityModule,
    ContactModule,
    CallVideoContainerModule,
    HubspotModule,
    RouterModule,
    MaterialModule,
    TranslateModule,
    AnimationModule,
    TimerModule
  ],
  declarations: [
    CallComponent,
    IncomingCallComponent,
    CallPageComponent,
    CallStatusComponent,
    CallListComponent,
    CallListItemComponent,
    DialogListItemComponent,
    BaseCallComponent,
    PulsingDotComponent
  ],
  exports: [
    CallComponent,
    CallPageComponent,
    CallStatusComponent,
    CallListComponent,
    PulsingDotComponent
  ]
})
export class CallModule {}
