import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "../../../../modules/material/material.module";
import { PopupContainerComponent } from "./popup-container.component";

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [PopupContainerComponent],
  exports: [PopupContainerComponent]
})
export class PopupContainerModule {}
