/**
 * Keep currentContact in a container service to keep dependency web clean
 * To keep this as simple as possible, we're just going to expose a BehaviorSubject and call it a day
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Contact } from "../../../common/interfaces/contact";

@Injectable({ providedIn: "root" })
export class CurrentContactService {
  state = new BehaviorSubject<Contact | undefined>(undefined);
}
