import { Injectable } from "@angular/core";

import { Contact } from "../../../../../common/interfaces/contact";
import { Contact as HubspotContact } from "../hubspot/services/lib/contact";
import { HubspotApiService } from "../hubspot/services/hubspot-api.service";
import { ContactService } from "../../../../../common/services/contact/contact.service";
import { E164PhoneNumber } from "../../../../../common/libraries/e164-phone-number";
import { HubspotInfo } from "../../../../../common/interfaces/hubspot-info";
import { ExpandedCallDetail } from "./expanded-call-detail";
import { WebCallHistoryService } from "../../../../../common/services/web-call-history.service";

import { ApiPromiseState } from "@onsip/common/services/api/api-promise-state.service";
import { WebCall } from "@onsip/common/services/api/resources/webCall/web-call";

interface CallDetails {
  remoteUri: string;
  ouid?: string;
}

@Injectable({ providedIn: "root" })
export class CallHistoryDetailsService {
  private cacheCalls: Record<string, ExpandedCallDetail> = {};
  constructor(
    private webCallHistory: WebCallHistoryService,
    private hubspotApiService: HubspotApiService
  ) {}

  async getExpandedDetail(call: CallDetails): Promise<ExpandedCallDetail> {
    let corresSaysoCall;
    if (call.ouid) {
      if (this.cacheCalls[call.ouid]) return this.cacheCalls[call.ouid];
      corresSaysoCall = this.webCallHistory.getCallByOuid(call.ouid);
      if (!corresSaysoCall) {
        await this.fetchWebCall(call.ouid);
        corresSaysoCall = this.webCallHistory.getCallByOuid(call.ouid);
      }
    }
    const potentialPhoneNumber =
        call.remoteUri.indexOf("@") > -1
          ? call.remoteUri.slice(0, call.remoteUri.indexOf("@"))
          : call.remoteUri,
      phoneNumber = new E164PhoneNumber(potentialPhoneNumber);

    let hubspotDetail: HubspotContact | undefined;
    let hubspotInfo: HubspotInfo | undefined;
    let hubspotPageUrl: string | undefined;
    if (this.hubspotApiService.stateValue.authorized) {
      if (corresSaysoCall && corresSaysoCall.hubspotutk) {
        await this.hubspotApiService
          .getContactByUserToken(corresSaysoCall.hubspotutk)
          .then(res => {
            hubspotDetail = res;
          })
          .catch(() => {
            // do not throw error, if we can not find contact by utk, just let fetch detail be undefined
            hubspotDetail = undefined;
          });
      } else if (phoneNumber.isValid) {
        await this.hubspotApiService
          .getContactByE164(phoneNumber.e164Uri)
          .then(res => {
            hubspotDetail = res;
          })
          .catch(() => {
            // same as fetch with utk, if pstn cannot be found, just let hubspotDetail be undefined
            hubspotDetail = undefined;
          });
      }
      let contactToAdd: Contact | undefined, callersName: string | undefined;
      if (hubspotDetail?.firstname) callersName = hubspotDetail?.firstname;
      if (hubspotDetail?.lastname) callersName = callersName + " " + hubspotDetail?.lastname;
      if (hubspotDetail?.phone) {
        const hubspotNumber: E164PhoneNumber = new E164PhoneNumber(hubspotDetail?.phone);
        if (hubspotNumber.isValid) {
          if (!callersName) callersName = hubspotNumber.e164DisplayNumber;
          contactToAdd = ContactService.createCustomPhoneContact(callersName, hubspotNumber);
        }
      }
      hubspotPageUrl =
        hubspotDetail?.portalId && hubspotDetail.vid
          ? `https://app.hubspot.com/contacts/${hubspotDetail.portalId}/contact/${hubspotDetail.vid}/`
          : undefined;
      hubspotInfo = {
        callersName,
        contact: contactToAdd,
        companyName: hubspotDetail?.company,
        email: hubspotDetail?.email,
        hubspotOwner: hubspotDetail?.hubspotOwnerName,
        phoneNumber: hubspotDetail
          ? hubspotDetail.phone
          : phoneNumber.isValid
          ? phoneNumber.e164Uri
          : call.remoteUri,
        remoteAor:
          contactToAdd && contactToAdd.e164PhoneNumbers
            ? contactToAdd.e164PhoneNumbers[0]
            : call.remoteUri
      };
    } else {
      hubspotInfo = {
        callersName: undefined,
        contact: undefined,
        companyName: undefined,
        email: undefined,
        hubspotOwner: undefined,
        phoneNumber: phoneNumber.isValid ? phoneNumber.e164Uri : call.remoteUri,
        remoteAor: undefined
      };
    }
    const expandedDetails = {
      // sayso detail
      topicName: corresSaysoCall?.topicName,
      audio: corresSaysoCall?.audio,
      video: corresSaysoCall?.video,
      callFromLink: corresSaysoCall?.saysoCallFromLink,
      // hubspot detail
      hubspotInfo,
      hubspotPageUrl
    };

    // HACK: companies outside of the states and other companies enter the OnSIP phone number
    // for a valid phone number to use in hubspot. When querying hubspot, hubspot will return a random contact
    // Hack here so the OnSIP phone number returns OnSIP support information instead
    if (expandedDetails.hubspotInfo.phoneNumber === "18008013381") {
      expandedDetails.hubspotInfo.callersName = "OnSIP";
      expandedDetails.hubspotInfo.hubspotOwner = "N/A";
      expandedDetails.hubspotInfo.companyName = "OnSIP";
      expandedDetails.hubspotInfo.phoneNumber = "18008013381";
      expandedDetails.hubspotInfo.email = "support@onsip.com";
    }

    if (call.ouid) this.cacheCalls[call.ouid] = expandedDetails;

    return expandedDetails;
  }

  private fetchWebCall(ouid: string): ApiPromiseState<WebCall> {
    return this.webCallHistory.webCallBrowseWithOuid(ouid);
  }
}
