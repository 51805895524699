import { UserAgentEvent, UserAgentEventFactory } from "./user-agent-event";

/**
 * Emitted when the UserAgent is connecting.
 * Occurs only if the user agent was previously disconnected.
 */
export class ConnectingUserAgentEvent {
  /**
   * Factory function to make event.
   * @param aor AOR of UserAgent.
   */
  static make(aor: string): UserAgentEvent {
    return UserAgentEventFactory.make(ConnectingUserAgentEvent.id, aor);
  }
  static get id(): string {
    return "[UserAgent] connecting";
  }
}
