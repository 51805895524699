import { Injectable } from "@angular/core";
import { ApiAction } from "../../api-actions";
import { ApiPromiseState, ApiPromiseStateService } from "../../api-promise-state.service";
import { ApiSessionService } from "../../api-session.service";
import { ApiStateStoreService } from "../../api-state-store.service";
import { organizationId } from "../../apiParams/organization-id";
import { sessionId } from "../../apiParams/session-id";
import { extractData, OnsipApiResponse } from "../../apiResponse/response-body-new";
import { getApiActionName } from "../../onsip-api-action-new";
import { arrayToRecord } from "../../util/arrayToRecord";
import { ApiResourceService } from "../api-resource.service";
import { QueueSummaryParams } from "../queueSummary/queue-summary";
import {
  ApiQueueCallerEvent,
  QueueCallerEvent,
  apiQueueCallerEventToQueueCallerEvent as clean
} from "./queue-caller-event";

const debug = false;

@Injectable({ providedIn: "root" })
export class QueueCallerEventService extends ApiResourceService<QueueCallerEvent> {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState, "QueueCallerEvent", "queueCallerEventId");
    debug && this.state.subscribe(state => console.warn("QueueCallerEventSevice", state));
  }

  queueCallerEventBrowse(params?: QueueSummaryParams): ApiPromiseState<QueueCallerEvent> {
    this.dispatcher.next({
      parameters: {
        Action: ApiAction.QueueCallerEventBrowse,
        SessionId: this.store.state.pipe(sessionId()),
        OrganizationId: this.store.state.pipe(organizationId()),
        Limit: 25000,
        ...params
      }
    });
    return this.promiseState.toPromise(ApiAction.QueueCallerEventBrowse);
  }

  reducer(response: OnsipApiResponse): void {
    const action = getApiActionName(response);
    switch (action) {
      case ApiAction.QueueCallerEventBrowse:
        this.store.mergeStateUpdate(
          this.resourceName,
          arrayToRecord(
            extractData<Array<ApiQueueCallerEvent>>(
              response,
              action,
              "QueueCallerEvent",
              "QueueCallerEvents"
            ).map(clean),
            this.indexKeyName
          ),
          action
        );
        break;
    }
  }
}
