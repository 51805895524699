<onsip-table-two
  multiTemplateDataRows
  [dataSource]="dataSource"
  [hoverable]="true"
  [gridTemplateColumns]="'1fr 1fr 2fr 1fr'"
>
  <!-- Date Column-->
  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.DATE" | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let call">{{ call.displayDate }}</mat-cell>
  </ng-container>
  <!-- Time Column -->
  <ng-container matColumnDef="time">
    <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.TIME" | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let call">{{ call.displayTime }}</mat-cell>
  </ng-container>
  <!-- Remote-identity Column -->
  <ng-container matColumnDef="remote-identity">
    <mat-header-cell class="remote-identity-header" *matHeaderCellDef>{{
      "ONSIP_I18N.NAME" | translate
    }}</mat-header-cell>
    <mat-cell class="remote-identity-cell" *matCellDef="let call">
      <div class="remote-identity-column">
        @if (!call.shouldBold) {
        <span
          >{{ call.isIncoming ? ("ONSIP_I18N.FROM" | translate) : ("ONSIP_I18N.TO" | translate) }}
          {{ call.displayNamePrefix }} {{ call.displayName }}</span
        >
        } @if (call.shouldBold) {
        <span
          >{{ call.isIncoming ? ("ONSIP_I18N.FROM" | translate) : ("ONSIP_I18N.TO" | translate) }}
          {{ call.displayNamePrefix }} <b>{{ call.displayName }}</b></span
        >
        }
      </div>
    </mat-cell>
  </ng-container>
  <!-- Status Column -->
  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>{{ "ONSIP_I18N.STATUS" | translate }}</mat-header-cell>
    <mat-cell *matCellDef="let call">
      @if (call.disposition === disposition.ANSWERED) {
      <span>{{ call.duration | duration }}</span>
      } @if (call.disposition !== disposition.ANSWERED) {
      <span class="mat-warn">{{ call.disposition }}</span>
      }
    </mat-cell>
  </ng-container>
  <!-- Expanded -->
  <ng-container matColumnDef="expanded">
    <onsip-table-shelf-cell *matCellDef="let call" [expanded]="call === expandedRow">
      @if ((isExpandedDetailLoaded | async) && !!expandedDetail) {
      <div class="call-history-details">
        <div class="left-call-history-details">
          <div class="call-history-detail caller-name">
            <span class="detail-title">{{ "ONSIP_I18N.CALLER" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.hubspotInfo?.callersName || "N/A" }}
            </span>
          </div>
          <div class="call-history-detail hubspot-owner">
            <span class="detail-title">{{ "ONSIP_I18N.HUBSPOT_OWNER" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.hubspotInfo?.hubspotOwner || "N/A" }}
            </span>
          </div>
          <div class="call-history-detail company-name">
            <span class="detail-title">{{ "ONSIP_I18N.COMPANY" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.hubspotInfo?.companyName || " N/A" }}
            </span>
          </div>
          <div class="call-history-detail phone-number">
            <span class="detail-title">{{ "ONSIP_I18N.PHONE" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.hubspotInfo?.phoneNumber || "N/A" }}
            </span>
          </div>
          <div class="call-history-detail email">
            <span class="detail-title">{{ "ONSIP_I18N.EMAIL" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.hubspotInfo?.email || "N/A" }}
            </span>
          </div>
        </div>
        <div class="right-call-history-details">
          <div class="call-history-detail topic-name">
            <span class="detail-title">{{ "ONSIP_I18N.TOPIC" | translate }}</span>
            <span class="detail-info">
              {{ expandedDetail.topicName || "N/A" }}
            </span>
          </div>
          <div class="call-history-detail call-from-link">
            <span class="detail-title">{{ "ONSIP_I18N.CALL_ORIGIN" | translate }}</span>
            <span class="detail-info">
              @if (expandedDetail.callFromLink) {
              <a target="_blank" [href]="expandedDetail.callFromLink">{{
                expandedDetail.callFromLink
              }}</a>
              } @if (!expandedDetail.callFromLink) { N/A }
            </span>
          </div>
          <div class="call-history-detail type-of-call">
            <span class="detail-title">{{ "ONSIP_I18N.CALL_TYPE" | translate }}</span>
            <span class="detail-info">
              @if (expandedDetail.video) {
              {{ "ONSIP_I18N.SAYSO_VIDEO" | translate }}
              } @if (expandedDetail.audio && !expandedDetail.video) {
              {{ "ONSIP_I18N.SAYSO_AUDIO" | translate }}
              } @if (isDetailPhoneNumberCall) {
              {{ "ONSIP_I18N.PHONE_CALL" | translate }}
              } @if (!expandedDetail.audio && !expandedDetail.video && !isDetailPhoneNumberCall) {
              {{ "ONSIP_I18N.WEB_CALL" | translate }}
              }
            </span>
          </div>
          <div class="call-history-detail button-container">
            <div class="action-buttons">
              <!-- action buttons for non hubspot calls -->
              <button
                id="onsip--recent-calls-table--return-voice-call"
                aria-label="start voice call"
                autofocus
                class="recent-call-button"
                onsipNewCall
                [contact]="expandedDetail.hubspotInfo?.contact || call.contact"
                [remoteAor]="expandedDetail.hubspotInfo?.remoteAor || call.remoteUri"
                mat-button
                color="primary"
              >
                <mat-icon outlined>phone</mat-icon>
              </button>
              <button
                id="onsip--recent-calls-table--return-video-call"
                aria-label="start video call"
                class="recent-call-button"
                onsipNewCall
                [contact]="expandedDetail.hubspotInfo?.contact || call.contact"
                [remoteAor]="expandedDetail.hubspotInfo?.remoteAor || call.remoteUri"
                [video]="true"
                mat-button
                color="primary"
              >
                <mat-icon outlined>videocam</mat-icon>
              </button>
              <button
                mat-button
                color="primary"
                class="recent-call-button"
                [onsipAddContact]="expandedDetail.hubspotInfo?.contact || call.contact"
              >
                <mat-icon>add</mat-icon>
              </button>
            </div>
            <div class="hubspot-link-container">
              <a
                class="hubspot-link"
                target="_blank"
                [href]="expandedDetail.hubspotPageUrl"
                tabindex="-1"
                [class.hubspot-link-disabled]="!expandedDetail.hubspotPageUrl"
              >
                {{ "ONSIP_I18N.SEE_MORE_DETAILS" | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
      }
    </onsip-table-shelf-cell>
  </ng-container>
  <!-- Mobile View -->
  <ng-container matColumnDef="mobile">
    <cdk-cell *cdkCellDef="let call">
      <div class="mobile-line heading">
        <div class="call-heading">
          @if (!call.shouldBold) {
          <span
            >{{
              call.isIncoming ? ("ONSIP_I18N.FROM" | translate) : ("ONSIP_I18N.TO" | translate)
            }}
            {{ call.displayNamePrefix }} {{ call.displayName }}</span
          >
          } @if (call.shouldBold) {
          <span
            >{{
              call.isIncoming ? ("ONSIP_I18N.FROM" | translate) : ("ONSIP_I18N.TO" | translate)
            }}
            {{ call.displayNamePrefix }} <b>{{ call.displayName }}</b></span
          >
          }
        </div>
        <button
          mat-icon-button
          color="primary"
          (menuOpened)="sendExpandedDetail(call)"
          [matMenuTriggerFor]="menu"
          [matMenuTriggerData]="{ call: call }"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>
      <mat-divider></mat-divider>
      <div class="mobile-line">
        <span>{{ "ONSIP_I18N.DATE" | translate }}</span>
        <span class="right">{{ call.displayDate }}</span>
      </div>
      <div class="mobile-line">
        <span>{{ "ONSIP_I18N.TIME" | translate }}</span>
        <span class="right">{{ call.displayTime }}</span>
      </div>
      <div class="mobile-line">
        <span>{{ "ONSIP_I18N.STATUS" | translate }}</span>
        @if (call.disposition === disposition.ANSWERED) {
        <span>{{ call.duration | duration }}</span>
        } @if (call.disposition !== disposition.ANSWERED) {
        <span class="mat-warn">{{ call.disposition }}</span>
        }
      </div>
    </cdk-cell>
    <mat-menu #menu="matMenu">
      <ng-template matMenuContent let-call="call">
        <!-- Non hubspot call back buttons -->
        @if (!expandedDetail?.hubspotInfo?.remoteAor) {
        <button
          id="onsip--home-main-mobile--return-voice-call"
          aria-label="start voice call"
          onsipNewCall
          [contact]="call.contact"
          [remoteAor]="call.remoteUri"
          [remoteDisplayName]="call.contact ? call.contact.name : call.fromName"
          mat-menu-item
          class="recent-call-button"
        >
          <mat-icon onsip-icon-outlined>phone</mat-icon
          ><span>{{ "ONSIP_I18N.CALL_BACK" | translate }}</span>
        </button>
        } @if (!expandedDetail?.hubspotInfo?.remoteAor) {
        <button
          id="onsip--home-main-mobile--return-video-call"
          aria-label="start video call"
          onsipNewCall
          [contact]="call.contact"
          [remoteAor]="call.remoteUri"
          [remoteDisplayName]="call.contact ? call.contact.name : call.fromName"
          [video]="true"
          mat-menu-item
          class="recent-call-button"
        >
          <mat-icon onsip-icon-outlined>videocam</mat-icon
          ><span>{{ "ONSIP_I18N.VIDEO_CALL" | translate }}</span>
        </button>
        } @if (!expandedDetail?.hubspotInfo?.remoteAor) {
        <button mat-menu-item [onsipAddContact]="call.contact" class="recent-call-button">
          <mat-icon>add_circle_outline</mat-icon
          ><span>{{
            call.contact && call.contact.custom
              ? ("ONSIP_I18N.ADD_CUSTOM_CONTACT" | translate)
              : ("ONSIP_I18N.ADD_NEW_CONTACT" | translate)
          }}</span>
        </button>
        }
        <!-- Hubspot call back buttons -->
        @if (expandedDetail?.hubspotInfo?.remoteAor) {
        <button
          id="onsip--home-main-mobile--return-voice-call"
          aria-label="start voice call"
          onsipNewCall
          [contact]="expandedDetail?.hubspotInfo?.contact"
          [remoteAor]="expandedDetail?.hubspotInfo?.remoteAor"
          [remoteDisplayName]="
            expandedDetail?.hubspotInfo?.contact
              ? expandedDetail?.hubspotInfo?.contact?.name
              : call.fromName
          "
          mat-menu-item
          class="recent-call-button"
        >
          <mat-icon onsip-icon-outlined>phone</mat-icon
          ><span>{{ "ONSIP_I18N.CALL_BACK" | translate }}</span>
        </button>
        } @if (expandedDetail?.hubspotInfo?.remoteAor) {
        <button
          id="onsip--home-main-mobile--return-video-call"
          aria-label="start video call"
          onsipNewCall
          [contact]="expandedDetail?.hubspotInfo?.contact"
          [remoteAor]="expandedDetail?.hubspotInfo?.remoteAor"
          [remoteDisplayName]="
            expandedDetail?.hubspotInfo?.contact
              ? expandedDetail?.hubspotInfo?.contact?.name
              : call.fromName
          "
          [video]="true"
          mat-menu-item
          class="recent-call-button"
        >
          <mat-icon onsip-icon-outlined>videocam</mat-icon
          ><span>{{ "ONSIP_I18N.VIDEO_CALL" | translate }}</span>
        </button>
        } @if (expandedDetail?.hubspotInfo?.remoteAor) {
        <button mat-menu-item [onsipAddContact]="call.contact" class="recent-call-button">
          <mat-icon>add_circle_outline</mat-icon
          ><span>{{
            expandedDetail?.hubspotInfo?.contact
              ? ("ONSIP_I18N.ADD_CUSTOM_CONTACT" | translate)
              : ("ONSIP_I18N.ADD_NEW_CONTACT" | translate)
          }}</span>
        </button>
        }
        <button
          mat-menu-item
          (click)="goToDetails()"
          [disabled]="!expandedDetail?.hubspotPageUrl"
          class="recent-call-button"
        >
          <mat-icon onsip-icon-outlined>view_list</mat-icon>
          <span>{{ "ONSIP_I18N.VIEW_CALL_DETAILS" | translate }}</span>
        </button>
      </ng-template>
    </mat-menu>
  </ng-container>
  <mat-header-row *matHeaderRowDef="isMobile ? null : displayedColumns"></mat-header-row>
  <mat-row
    *matRowDef="let row; columns: displayedColumns; when: showDesktopRow.bind(this)"
    (click)="expandedRow = expandedRow === row ? null : row; sendExpandedDetail(expandedRow)"
  >
  </mat-row>
  <onsip-table-shelf
    *matRowDef="let row; columns: ['expanded']; when: showDesktopRow.bind(this)"
  ></onsip-table-shelf>
  <onsip-row *matRowDef="let row; columns: ['mobile']; when: showMobileRow.bind(this)"></onsip-row>
</onsip-table-two>
@if (dataSource.data.length || search.length) {
<div class="page-controls-wrapper">
  @if (askToLoadMore && (isLoading | async) === false) {
  <div class="load-more-container">
    <span>{{ "ONSIP_I18N.DISPLAY_RESULTS_PAST" | translate }} {{ lastFetchedDate }}</span>
    <button
      id="onsip--recent-calls--keep-searching"
      aria-label="show more search results"
      mat-icon-button
      color="primary"
      (click)="keepSearching()"
    >
      <mat-icon>fast_forward</mat-icon>
    </button>
  </div>
  } @if (isLoading | async) {
  <div class="pagination-loading">
    <span>{{ "ONSIP_I18N.LOADING" | translate }}...</span>
  </div>
  }
</div>
}
<mat-paginator
  [disabled]="isLoading | async"
  onsip-paginator-primary-icon
  [showFirstLastButtons]="true"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
>
</mat-paginator>
@if (!dataSource.data.length && (isLoading | async) === false) {
<div class="empty-state">
  <onsip-hero-girl></onsip-hero-girl>
  <h4>{{ "ONSIP_I18N.THERES_NO_CALL_HISTORY" | translate }}</h4>
  <h5>{{ "ONSIP_I18N.MAKE_CALLS_TO_POWER_UP_YOUR_APP" | translate }}</h5>
</div>
}
