export declare interface ApiResource {
  Description: string;
  MonthlyRecurringCharge: string;
  Name: string;
  OneTimeCharge: string;
  ResourceId: string;
}

export declare interface Resource {
  description: string;
  monthlyRecurringCharge: string;
  name: string;
  oneTimeCharge: string;
  resourceId: string;
}

export function apiResourceToResource(apiResource: ApiResource): Resource {
  return {
    description: apiResource.Description,
    monthlyRecurringCharge: apiResource.MonthlyRecurringCharge,
    name: apiResource.Name,
    oneTimeCharge: apiResource.OneTimeCharge,
    resourceId: apiResource.ResourceId
  };
}

// Included Resource is used in any Account Package related fetches
// it is used to store all resources that come pre-package with a specific plan
// For example, PayasYouGo plan has 3 group addresses, 2 auto-attendant menu, etc. packaged together as a plan
// Included resources would have an included resource object for each type of resource.
// In the example above, PayasYouGo will have an object for group address, one for auto-attendant menu, etc.
// Quantity then refers to the number of resources so quantity would be 3 for the group address object

export declare interface ApiIncludedResource {
  Quantity: string;
  Resource: ApiResource;
}

export declare interface IncludedResource {
  quantity: number;
  resource: Resource;
}

export function apiIncludedResourceToIncludeResource(
  apiIncludedResource: ApiIncludedResource
): IncludedResource {
  return {
    quantity: parseInt(apiIncludedResource.Quantity),
    resource: apiResourceToResource(apiIncludedResource.Resource)
  };
}

// Account Resource is the object fetched when doing an account wide resource browse or read

export declare interface ApiAccountResource {
  AccountId: string;
  /** maximum possible amount of particular resource */
  Limit: string;
  Minimum: string;
  Resource: ApiResource;
  /** number of used resource, must be between minimum and limit */
  Used: string;
}

export declare interface AccountResource {
  accountId: string;
  /** maximum possible amount of particular resource */
  limit: number;
  minimum: number;
  /** number of used resource, must be between minimum and limit */
  used: number;
  description: string;
  monthlyRecurringCharge: string;
  name: string;
  oneTimeCharge: string;
  resourceId: string;
}

export function ApiAccountResourceToAccountResource(
  apiAccountResource: ApiAccountResource
): AccountResource {
  return {
    accountId: apiAccountResource.AccountId,
    limit: parseInt(apiAccountResource.Limit),
    minimum: parseInt(apiAccountResource.Minimum),
    used: parseInt(apiAccountResource.Used),
    description: apiAccountResource.Resource.Description,
    monthlyRecurringCharge: apiAccountResource.Resource.MonthlyRecurringCharge,
    name: apiAccountResource.Resource.Name,
    oneTimeCharge: apiAccountResource.Resource.OneTimeCharge,
    resourceId: apiAccountResource.Resource.ResourceId
  };
}
