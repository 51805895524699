import { E911Component } from "./e911.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, FormsModule, MaterialModule, TranslateModule],
  declarations: [E911Component],
  exports: [E911Component]
})
export class E911Module {}
