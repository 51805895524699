import { Subscription, NEVER, Subject, Observable } from "rxjs";
import { distinctUntilChanged, skipWhile, take, filter, switchMap, map } from "rxjs/operators";

import { LogService } from "../../common/services/logging/log.service";
import { StateEmitter } from "../libraries/emitter/state-emitter";
import {
  PresentityState,
  DialogInfoState,
  DialogInfo,
  EventPackage,
  RegInfoState,
  RegInfo,
  ExtendedDialogInfo,
  PersonalDialog,
  QueueInfo
} from "../interfaces/presentity";
import { SubscriptionController } from "../libraries/sip/subscription-controller";
import { AbortSubscriptionError } from "../libraries/sip/subscription-error";
import { UserAgentEvent, ConnectedUserAgentEvent } from "../libraries/sip/user-agent-event";
import { EndSubscriptionEvent, NotifySubscriptionEvent } from "../libraries/sip/subscription-event";

import { parse as xmlParse } from "fast-xml-parser";

const debug = false;

export abstract class SubscriptionControllerService extends StateEmitter<PresentityState> {
  protected unsubscriber = new Subscription();
  protected running = false;
  protected subscriptionController: SubscriptionController | undefined;

  private controllerUnsubscriber = new Subscription();
  /* Allows for an observable to UserAgentEvent events even after everything has been reset. */
  private userAgentEventSubject = new Subject<UserAgentEvent>();

  /**
   * Constructor
   */
  constructor(protected log: LogService) {
    super({
      presentity: []
    });
    this.log.debug("SubscriptionControllerService Constructed");
  }

  // next 3 are overridden and used in mobile only
  mobileShutdown(): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mobileWatcherRemove(component: string): void {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mobileWatcherAdd(component: string): void {}

  /** Exposes all UserAgentEvents in a way that works after reset */
  protected getUserAgentEventObservable(): Observable<UserAgentEvent> {
    return this.userAgentEventSubject.asObservable();
  }

  protected dispose(): void {
    this.unsubscriber.unsubscribe();
    this.unsubscribeSubscriptionController();
    if (this.subscriptionController) {
      this.subscriptionController.dispose();
    }
    debug && this.log.debug("Presentity Service Disposed");
  }

  protected shutdownController(): Promise<void> {
    this.running = false;
    let shutdownPromise = Promise.resolve();
    if (this.subscriptionController) {
      this.unsubscribeSubscriptionController();
      shutdownPromise = this.subscriptionController.dispose();
      this.subscriptionController = undefined;
    }
    this.stateStore.presentity = [];
    this.publishState();
    return shutdownPromise;
  }

  protected resetController(): void {
    // create a new SubscriptionController
    this.subscriptionController = new SubscriptionController();

    // subscribe to SubscriptionController
    this.subscribeSubscriptionController();

    // we are running
    this.running = true;
  }

  protected add(aor: string, event: EventPackage): Promise<void> {
    debug &&
      this.log.debug(
        "SubscriptionControllerService.add aor = " + aor + " event package = " + event
      );
    switch (event) {
      case "dialog":
        return this.addDialog(aor);
      case "reg":
        return this.addReg(aor);
      default:
        return Promise.reject(
          new Error("SubscriptionControllerService.add unrecognized event package")
        );
    }
  }

  protected addDialog(aor: string, isExtended: boolean = false): Promise<void> {
    debug && this.log.debug("SubscriptionControllerService.addDialog");
    let presentity = this.stateStore.presentity.find(
      pres => pres.aor === aor && pres.event === "dialog"
    );
    if (presentity !== undefined) {
      return Promise.resolve();
    }
    // confirmedTime isn't used but we need it because of the new interface
    if (isExtended) {
      presentity = {
        aor,
        userAgentAor: "",
        event: "dialog",
        eventData: {
          dialogs: []
        },
        uuidSubscription: undefined
      };
    } else {
      presentity = {
        aor,
        userAgentAor: "",
        event: "dialog",
        eventData: {
          state: "",
          priority: -1,
          confirmedTime: 0
        },
        uuidSubscription: undefined
      };
    }
    this.stateStore.presentity.push(presentity);
    this.publishState();
    return this.refresh(aor, "dialog");
  }

  protected addReg(aor: string): Promise<void> {
    debug && this.log.debug("SubscriptionControllerService.addReg");
    let presentity = this.stateStore.presentity.find(
      pres => pres.aor === aor && pres.event === "reg"
    );
    if (presentity !== undefined) {
      return Promise.resolve();
    }
    presentity = {
      aor,
      userAgentAor: "",
      event: "reg",
      eventData: {
        aor: "",
        state: "",
        isWebRTC: false,
        priority: -1
      },
      uuidSubscription: undefined
    };
    this.stateStore.presentity.push(presentity);
    this.publishState();
    return this.refresh(aor, "reg");
  }

  protected addQueue(aor: string): Promise<void> {
    debug && this.log.debug("SubscriptionControllerService.addQueue");
    let presentity = this.stateStore.presentity.find(
      pres => pres.aor === aor && pres.event === "onsip-queue"
    );
    if (presentity !== undefined) {
      return Promise.resolve();
    }
    presentity = {
      aor,
      userAgentAor: "",
      event: "onsip-queue",
      eventData: {
        overview: {},
        agents: {}
      },
      uuidSubscription: undefined
    };
    this.stateStore.presentity.push(presentity);
    this.publishState();
    return this.refresh(aor, "onsip-queue");
  }

  protected refresh(aor: string, event: EventPackage): Promise<void> {
    if (!this.subscriptionController) {
      const error = new Error("SubscriptionControllerService.refresh no subscription controller.");
      this.log.error(error.message);
      return Promise.reject(error);
    }

    const presentity = this.stateStore.presentity.find(
      pres => pres.aor === aor && pres.event === event
    );
    if (presentity === undefined) {
      const message =
        "SubscriptionControllerService.refresh failed to find presentity aor = " +
        aor +
        " event package = " +
        event;
      this.log.error(message);
      return Promise.reject(new Error(message));
    }

    const userAgent =
      this.subscriptionController.userAgents.array.find(ua => ua.aor === presentity.aor) ||
      this.subscriptionController.userAgents.array[0];

    if (!userAgent) {
      const message = "SubscriptionControllerService.refresh failed to find user agent";
      this.log.error(message);
      return Promise.reject(new Error(message));
    }

    presentity.userAgentAor = userAgent.aor;

    if (!userAgent.connected) {
      return Promise.resolve();
    }

    if (presentity.uuidSubscription !== undefined) {
      return Promise.resolve();
    }

    return this.subscriptionController
      .create(userAgent.aor, presentity.aor, event)
      .then(subscription => {
        presentity.uuidSubscription = subscription.uuid;
      })
      .catch(error => {
        this.log.error(
          "SubscriptionControllerService.refresh error creating subscription aor = " +
            aor +
            " event package = " +
            event +
            " " +
            error
        );
        if (error instanceof AbortSubscriptionError) {
          // when we shutdown, any pending subscriptions will fail so this is expected
          return;
        }
        throw error;
      });
  }

  protected remove(aor: string, event: EventPackage): Promise<void> {
    debug &&
      this.log.debug(
        "SubscriptionControllerService.remove aor = " + aor + " event package = " + event
      );
    if (!this.subscriptionController) {
      const error = new Error("SubscriptionControllerService.remove no subscription controller.");
      this.log.error(error.message);
      return Promise.reject(error);
    }

    const index = this.stateStore.presentity.findIndex(
      pres => pres.aor === aor && pres.event === event
    );
    if (index === -1) {
      return Promise.reject(
        new Error(
          "SubscriptionControllerService.remove failed to find presentity aor = " +
            aor +
            " event package = " +
            event
        )
      );
    }
    const presentity = this.stateStore.presentity.splice(index, 1)[0];
    this.publishState();

    if (!presentity.uuidSubscription) {
      return Promise.resolve();
    }

    return this.subscriptionController.end(presentity.uuidSubscription).catch(error => {
      this.log.error("SubscriptionControllerService.remove error ending subscription " + error);
      throw error;
    });
  }

  private getDialogPriority(state: DialogInfoState): number {
    switch (state) {
      case "terminated":
        return 0;
      case "early":
        return 1;
      case "proceeding":
        return 2;
      case "trying":
        return 3;
      case "confirmed":
        return 4;
      default:
        return -1;
    }
  }

  private parseDialogInfoNotifyBody(body: string): DialogInfo {
    const data = xmlParse(body, { ignoreAttributes: false });
    if (!data) {
      this.log.error(
        "SubscriptionControllerService.parseDialogInfoNotifyBody body not parsed: " + body
      );
      throw new Error("Body not parsed");
    } else if (!Object.prototype.hasOwnProperty.call(data, "dialog-info")) {
      this.log.error(
        "SubscriptionControllerService.parseDialogInfoNotifyBody Error: No 'dialog-info' in notify body"
      );
      throw new Error("No 'dialog-info' in notify body");
    }
    const dialogInfo: DialogInfo = {
      state: "",
      priority: -1,
      confirmedTime: 0
    };
    const infoObject = data["dialog-info"];
    if (!infoObject.dialog) {
      // no dialogs is the most common case
      return dialogInfo;
    }
    infoObject.dialog = [].concat(infoObject.dialog);

    infoObject.dialog.forEach((dialog: any) => {
      const timeObj = dialog["dialog-confirmed-time"];

      if (!dialogInfo.state || this.getDialogPriority(dialog.state) > dialogInfo.priority) {
        dialogInfo.state = dialog.state;
        dialogInfo.priority = this.getDialogPriority(dialogInfo.state);
        dialogInfo.confirmedTime = timeObj && timeObj["#text"] && Date.parse(timeObj["#text"]);
      }
    });
    return dialogInfo;
  }

  private parseExtendedDialogInfoNotifyBody(body: string): ExtendedDialogInfo {
    const data = xmlParse(body, { ignoreAttributes: false });
    if (!data) {
      this.log.error(
        "SubscriptionControllerService.parseDialogInfoNotifyBody body not parsed: ",
        body
      );
      throw new Error("Body not parsed");
    } else if (!Object.prototype.hasOwnProperty.call(data, "dialog-info")) {
      this.log.error(
        "SubscriptionControllerService.parseDialogInfoNotifyBody Error: No 'dialog-info' in notify body"
      );
      throw new Error("No 'dialog-info' in notify body");
    }
    const dialogInfo: ExtendedDialogInfo = {
      dialogs: []
    };

    const infoObject = data["dialog-info"];
    if (!infoObject.dialog) {
      // no dialogs is the most common case
      return dialogInfo;
    }
    infoObject.dialog = [].concat(infoObject.dialog);

    infoObject.dialog.forEach((dialog: any) => {
      const localIdentityObj = dialog.local && dialog.local.identity,
        remoteIdentityObj = dialog.remote && dialog.remote.identity,
        proxyObj = dialog["dialog-proxy"],
        dialogId: string | undefined = dialog["@_id"],
        timeObj = dialog["dialog-confirmed-time"],
        personalDialog: PersonalDialog = {
          state: dialog.state,
          priority: this.getDialogPriority(dialog.state),
          callId: "",
          localAor: localIdentityObj
            ? localIdentityObj["#text"]
              ? localIdentityObj["#text"]
              : localIdentityObj
            : undefined,
          localDisplayName: localIdentityObj && localIdentityObj["@_display"],
          remoteAor: remoteIdentityObj
            ? remoteIdentityObj["#text"]
              ? remoteIdentityObj["#text"]
              : remoteIdentityObj
            : undefined,
          remoteDisplayName: remoteIdentityObj && remoteIdentityObj["@_display"],
          direction: dialog["@_direction"],
          toTag:
            dialog["@_direction"] === "recipient" ? dialog["@_local-tag"] : dialog["@_remote-tag"],
          fromTag:
            dialog["@_direction"] === "recipient" ? dialog["@_remote-tag"] : dialog["@_local-tag"],
          coreProxyIp: proxyObj && proxyObj["#text"],
          confirmedTime: timeObj && timeObj["#text"] && Date.parse(timeObj["#text"])
        };

      if (!dialogId) {
        personalDialog.callId = "";
      } else if (dialogId.indexOf("@") >= 0) {
        // ip address is appended to end legally
        personalDialog.callId = dialogId.split(".").slice(0, 4).join(".");
      } else {
        personalDialog.callId =
          dialogId.indexOf(".") === -1 ? dialogId : dialogId.substring(0, dialogId.indexOf("."));
      }

      if (personalDialog.remoteAor && personalDialog.remoteAor.startsWith("sip:")) {
        personalDialog.remoteAor = personalDialog.remoteAor.slice(4);
      }
      if (personalDialog.localAor && personalDialog.localAor.startsWith("sip:")) {
        personalDialog.localAor = personalDialog.localAor.slice(4);
      }
      if (personalDialog.coreProxyIp) {
        personalDialog.coreProxyIp = personalDialog.coreProxyIp.split(":")[1]; // sip:the.core.proxy.ip:5060
      }

      const branch: PersonalDialog | undefined = dialogInfo.dialogs.find(
        innerDialog => innerDialog.callId === personalDialog.callId
      );
      if (branch) {
        if (
          branch.priority < personalDialog.priority ||
          (!branch.confirmedTime && personalDialog.confirmedTime)
        ) {
          dialogInfo.dialogs.splice(dialogInfo.dialogs.indexOf(branch), 1, personalDialog);
        }
      } else {
        dialogInfo.dialogs.push(personalDialog);
      }
    });
    return dialogInfo;
  }

  private getRegPriority(state: RegInfoState): number {
    switch (state) {
      case "init":
        return 0;
      case "terminated":
        return 1;
      case "active":
        return 2;
      default:
        return -1;
    }
  }

  // scoped and modified from jiffyphone subscription.service.ts
  private parseRegInfoNotifyBody(body: string): RegInfo {
    const data = xmlParse(body, { ignoreAttributes: false });
    if (!data) {
      this.log.error(
        "SubscriptionControllerService.parseRegInfoNotifyBody body not parsed: ",
        body
      );
      throw new Error("Body not parsed");
    } else if (!data.reginfo || !data.reginfo.registration) {
      this.log.error(
        "SubscriptionControllerService.parseRegInfoNotifyBody Error: No 'reginfo' in notify body"
      );
      throw new Error("No 'reg-info' in notify body");
    }
    const firstReg = data.reginfo.registration;
    const regInfo: RegInfo = {
      aor: firstReg["@_aor"],
      state: firstReg["@_state"],
      isWebRTC: false,
      priority: this.getRegPriority(firstReg["@_state"])
    };

    firstReg.contact = firstReg.contact ? [].concat(firstReg.contact) : undefined;

    regInfo.isWebRTC =
      firstReg.contact &&
      firstReg.contact.some((contact: any) => {
        return (
          (contact.uri && contact.uri.indexOf(".invalid") > 0) ||
          contact.uri.indexOf("transport=ws") > 0
        );
      });
    return regInfo;
  }

  private parseQueueInfoNotifyBody(body: string): QueueInfo {
    const lastState: any = body && JSON.parse(body);
    // need to check isArray for possibility of empty queue
    if (!Array.isArray(lastState) && !lastState.agents) {
      this.log.error("received bad Adv. Queue NOTIFY, ignoring", body || "");
      return {
        agents: {},
        overview: {}
      };
    }
    const agents: any = lastState.agents || [],
      callers: any = lastState.callers || [];

    const keyStartsWith = (key: string, prefix: string) => {
      return (object: Record<string, string>) => {
        // http://stackoverflow.com/a/646643
        return object[key].slice(0, prefix.length) === prefix;
      };
    };

    const nameTimeStatus = (caller: any): any => {
      return {
        name: caller.uri, // TODO better name?
        eventTime: Date.parse(caller.event_time),
        enqueueTime: Date.parse(caller.enqueue_time),
        isVideo: caller.is_video,
        status: caller.status
      };
    };

    const terminatedCallers: any = callers.filter(keyStartsWith("status", "terminated")),
      isCompleted: any = keyStartsWith("reason", "finished_call_with_"),
      isAbandoned: any = keyStartsWith("reason", "caller_hung_up"),
      completedCallers: Array<any> = [],
      abandonedCallers: Array<any> = [],
      failedCallers: Array<any> = [];

    terminatedCallers.forEach((caller: any) => {
      if (isCompleted(caller)) {
        completedCallers.push(caller);
      } else if (isAbandoned(caller)) {
        abandonedCallers.push(caller);
      } else {
        failedCallers.push(caller);
      }
    });

    const agentsByUri: any = agents.reduce((dict: Record<string, any>, agent: any) => {
      const agentObj: any = {
        name: agent.agent_displayname || agent.uri,
        uri: agent.uri,
        eventTime: Infinity, // filled in from caller info later
        lastLogin: Date.parse(agent.login_time)
      };
      if (agent.status === "logged_out") {
        agentObj.callStatus = "Offline";
        agentObj.loggedIn = false;
      } else {
        agentObj.callStatus = "Available"; // just a default. might change below
        agentObj.loggedIn = true;
      }

      dict[agent.uri] = agentObj;
      return dict;
    }, {});

    // setting up return objects
    const overview: any = {
      waiting: callers.filter(keyStartsWith("status", "waiting")).map(nameTimeStatus),
      onCall: callers.filter(keyStartsWith("status", "on_call_with_")).map(nameTimeStatus),
      completed: completedCallers.map(nameTimeStatus),
      abandoned: abandonedCallers.map(nameTimeStatus),
      failed: failedCallers.map(nameTimeStatus)
    };

    overview.firstWaitTime = Math.min.apply(
      undefined,
      overview.waiting.map((obj: any) => {
        return obj.eventTime;
      })
    );

    overview.onCall.forEach((caller: any) => {
      const agentUri: string = caller.status.slice("on_call_with_".length),
        agent: any = agentsByUri[agentUri];

      agent.callStatus = "On Call";
      agent.isVideo = caller.isVideo;
      agent.eventTime = caller.eventTime;
    });

    const midStep: any = Object.keys(agentsByUri);

    const returnAgents: any = midStep.reduce((list: Array<any>, key: string) => {
      list.push(agentsByUri[key]);
      return list;
    }, []);

    return {
      overview,
      agents: returnAgents
    };
  }

  private subscribeSubscriptionController(): void {
    if (!this.subscriptionController) {
      const error = new Error("SubscriptionControllerService.remove no subscription controller.");
      this.log.error(error.message);
      throw error;
    }

    // Subscription for UserAgent Events - used to tell when disconnect has occurred
    this.controllerUnsubscriber.add(
      this.subscriptionController.userAgents.event.subscribe(this.userAgentEventSubject)
    );

    // subscription to subscription controller state
    this.controllerUnsubscriber.add(
      this.subscriptionController.state.subscribe({
        next: () => {
          // TODO: Not using this subscription - do nothing for now, but might want to add subscriptionController state to our state a al the PhoneService?
        },
        error: (error: unknown) => {
          this.log.error("SubscriptionControllerService.subscribeSubscriptionController " + error);
          throw error;
        },
        complete: () => {
          if (this.subscriptionController && this.subscriptionController.isStateStopped) {
            const error = new Error(
              "SubscriptionControllerService.subscribeSubscriptionController state emitter completed unexpectedly"
            );
            this.log.error(error);
            throw error;
          }
        }
      })
    );

    // subscription to subscription controller subsubscription events
    this.controllerUnsubscriber.add(
      this.subscriptionController.subscriptions.event
        .pipe(
          filter(event => event.id === EndSubscriptionEvent.id),
          switchMap(event => {
            debug &&
              this.log.debug(
                "SubscriptionControllerService: received subscription event: *** " +
                  event.id +
                  " *** " +
                  event.uuid
              );
            // A subscription may end because we have removed the associated presentity or because the
            // associated dialog has been removed (for example, due to a websocket connection dropping).
            // In the former case, the subscription will clean itself up and we can ignore the end event,
            // however note that the former case is also something that SHOULD be transparently handled by
            // Subscription and we should not be dealing with it at all here but currently cannot due to issues
            // with SIP.js we are currently handling it here (and dependent on a workaround implemented in Subscription
            // which hopefully will go away at some future point in time and we can remove dealing with it here).
            // In the later case, we are updating the presentity accordingly but keeping it around so that a
            // new subscription may be established in the future if/when the user agent establishes a new connection.
            const index = this.stateStore.presentity.findIndex(
              presentity => presentity.uuidSubscription === event.uuid
            );
            if (index !== -1) {
              const presentity = this.stateStore.presentity[index];
              switch (presentity.event) {
                case "dialog":
                  presentity.eventData = {
                    state: "",
                    priority: -1,
                    confirmedTime: 0
                  };
                  presentity.uuidSubscription = undefined;
                  break;
                case "reg":
                  presentity.eventData = {
                    aor: "",
                    state: "",
                    isWebRTC: false,
                    priority: -1
                  };
                  presentity.uuidSubscription = undefined;
                  break;
                case "onsip-queue":
                  presentity.eventData = {
                    overview: {},
                    agents: {}
                  };
                  presentity.uuidSubscription = undefined;
                  break;
                default:
                  this.log.error(
                    "SubscriptionControllerService: invalid event package aor = " +
                      presentity.aor +
                      " event = " +
                      presentity.event
                  );
                  break;
              }
              this.publishState();

              // If the subscription ended for reason other than us removing it and the susbscription
              // was established at one point (we received at least one NOTIFY for it in the past),
              // then attempt to re-establish the subscription. For example, if execution is "paused"
              // and we miss our real world time window to re-subscribe prior to expiration, when we
              // are "unpaused" and attempt to re-subscribe we will recevie a 481 (or whatever) and
              // an end event. Here we attempt to create a new subscription in a case like that.
              if (this.subscriptionController) {
                const aor = presentity.aor;
                const subEvent = presentity.event;
                const subscription = this.subscriptionController.findSubscription(event.uuid);
                // Confirm the subscription exists and that it was established
                // (we received at least one non-terminate NOTIFY for it in the past).
                if (subscription && subscription.connectedAt) {
                  // To avoid a duplicate subscription error, wait until the original
                  // subscription is disposed of before trying to re-establish.
                  return this.subscriptionController.state.pipe(
                    skipWhile(state => state.subscriptions.some(s => s.uuid === subscription.uuid)),
                    take(1),
                    map(() => ({ aor, subEvent }))
                  );
                }
              }
            }
            return NEVER;
          })
        )
        .subscribe(subObject => {
          this.refresh(subObject.aor, subObject.subEvent).catch(() => {
            // Currently nobody to notify if an error occurs.
            // And practically nothing to be done regardless.
          });
        })
    );

    this.controllerUnsubscriber.add(
      this.subscriptionController.subscriptions.event
        .pipe(filter(event => event.id === NotifySubscriptionEvent.id))
        .subscribe(event => {
          if (!this.subscriptionController) {
            const error = new Error(
              "SubscriptionControllerService.subscribeSubscriptionController no subscription controller."
            );
            this.log.error(error.message);
            throw error;
          }
          // Match up incoming notifications with their subscriptions and update the associated presenities.
          const subscription = this.subscriptionController.findSubscription(event.uuid);
          if (subscription === undefined) {
            this.log.error(
              "SubscriptionControllerService.subscribeSubscriptionController failed to find subscription for notification id = " +
                event.id +
                " uuid = " +
                event.uuid
            );
            return;
          }
          const presentity = this.stateStore.presentity.find(
            pres => pres.aor === subscription.uri.aor && pres.event === subscription.eventPackage
          );
          if (!presentity) {
            this.log.error(
              "SubscriptionControllerService.subscribeSubscriptionController failed to find presentity for notification eventPackage = " +
                subscription.eventPackage +
                " aor = " +
                subscription.uri.aor
            );
            return;
          }
          if (event.body === undefined) {
            const error = new Error(
              "SubscriptionControllerService.subscribeSubscriptionController received notify without body"
            );
            this.log.error(error.message);
            throw error;
          }
          try {
            switch (subscription.eventPackage) {
              case "dialog":
                if (
                  this.subscriptionController.userAgents.array.find(
                    ua => ua.aor === subscription.uri.aor
                  )
                ) {
                  const dialogInfo = this.parseExtendedDialogInfoNotifyBody(event.body);
                  presentity.eventData = dialogInfo;
                } else {
                  const dialogInfo = this.parseDialogInfoNotifyBody(event.body);
                  presentity.eventData = dialogInfo;
                }
                this.publishState();
                break;
              case "reg":
                // eslint-disable-next-line no-case-declarations
                const regInfo = this.parseRegInfoNotifyBody(event.body);
                presentity.eventData = regInfo;
                this.publishState();
                break;
              case "onsip-queue":
                // eslint-disable-next-line no-case-declarations
                const queueInfo = this.parseQueueInfoNotifyBody(event.body);
                presentity.eventData = queueInfo;
                this.publishState();
                break;
              default:
                this.log.error(
                  "SubscriptionControllerService.subscribeSubscriptionController unknown notification eventPackage = " +
                    subscription.eventPackage +
                    " aor = " +
                    subscription.uri.aor
                );
            }
          } catch (error) {
            this.log.error(
              "SubscriptionControllerService.subscribeSubscriptionController failed to parse notification eventPackage = " +
                subscription.eventPackage +
                " aor = " +
                subscription.uri.aor +
                " error = " +
                error
            );
            throw error;
          }
        })
    );

    // subscription to subscription controller user agent events
    this.controllerUnsubscriber.add(
      this.subscriptionController.userAgents.event.pipe(distinctUntilChanged()).subscribe({
        next: x => {
          debug &&
            this.log.debug(
              "SubscriptionControllerService: received user agent event: *** " + x.id + " *** "
            );
          if (x.id === ConnectedUserAgentEvent.id) {
            // When the subscription controller's user agent established a connection, attempt to establish subscriptions for all our presentities.
            this.stateStore.presentity
              .filter(
                presentity =>
                  presentity.uuidSubscription === undefined && x.aor === presentity.userAgentAor
              )
              .forEach(presentity => this.refresh(presentity.aor, presentity.event));
          }
        },
        error: (error: unknown) => {
          this.log.error(error as string);
          throw error;
        },
        complete: () => {
          if (
            this.subscriptionController &&
            this.subscriptionController.subscriptions.isEventStopped
          ) {
            const error = new Error(
              "SubscriptionControllerService.subscribeSubscriptionController user agent event emitter completed unexplectedly"
            );
            this.log.error(error);
            throw error;
          }
        }
      })
    );
  }

  private unsubscribeSubscriptionController(): void {
    this.controllerUnsubscriber.unsubscribe();
    this.controllerUnsubscriber = new Subscription();
  }
}
