import { Injectable } from "@angular/core";

import { WebAudioService } from "../services/webAudio/web-audio.service";
import { GumService } from "../services/gum/gum.service";
import { defaultSessionDescriptionHandlerFactory } from "./session-description-handler-factory";
import { SessionDescriptionHandlerFactory } from "sip.js";

/* convenience service to keep services only needed for factory creation together */
@Injectable({ providedIn: "root" })
export class SdhFactoryHelperService {
  constructor(private gumService: GumService, private webAudioService: WebAudioService) {}

  createFactory(): SessionDescriptionHandlerFactory {
    return defaultSessionDescriptionHandlerFactory(this.gumService, this.webAudioService);
  }
}
