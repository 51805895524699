import { StateEmitter } from "../../../../emitter/state-emitter";

import { db } from "./db";

import { PlatformFirebase } from "../platform-firebase";

import { firebase } from "../platform-firebase-types";

const debug = false;

/** The type of data in the cloud store. Beware this type is further contrained by Firestore. */
export interface DocumentData {
  [field: string]: any;
}

/** The type of data in the state store. Beware this type is further contrained by JSON. */
export interface DocumentState {
  id: string;
  [field: string]: any;
}

/** Generic function type which converts data from cloud store to state store. */
export type DocumentDataToDocumentState<T> = (id: string, data: DocumentData) => T;

export function arrayUnion(...elements: Array<any>): firebase.firestore.FieldValue {
  return PlatformFirebase.firestore.FieldValue.arrayUnion(...elements);
}

export function arrayRemove(...elements: Array<any>): firebase.firestore.FieldValue {
  return PlatformFirebase.firestore.FieldValue.arrayRemove(...elements);
}

export function propertyRemove(): firebase.firestore.FieldValue {
  return PlatformFirebase.firestore.FieldValue.delete();
}

export function now(data: DocumentData, field: string): DocumentData {
  return {
    ...data,
    ...{
      [field]: Date.now()
    }
  };
}

/** Timestamps data. */
export function timestamp(data: DocumentData, field: string): DocumentData {
  return {
    ...data,
    ...{
      [field]: PlatformFirebase.firestore.FieldValue.serverTimestamp()
    }
  };
}

/** Timestamps data to be added using a standard convention. */
export function timestampAddData(data: DocumentData): DocumentData {
  return timestamp(timestamp(data, "created"), "modified");
}

/** Timestamps data to be set using a standard convention. */
export function timestampSetData(data: DocumentData): DocumentData {
  return timestamp(timestamp(data, "created"), "modified");
}

/** Timestamps data to be updated using a standard convention. */
export function timestampUpdateData(data: DocumentData): DocumentData {
  return timestamp(data, "modified");
}

/**
 * A generic Document proxy class for Firestore documents.
 */
export class Document<T extends DocumentState> extends StateEmitter<T> {
  private _documentPath: string | undefined;
  private unsubscribe: (() => void) | undefined;

  protected static deleteDocument(documentPath: string): Promise<void> {
    return db()
      .doc(documentPath)
      .delete()
      .then(() => {
        debug && console.log(`Document[${documentPath}].deleteDocument success`);
      })
      .catch(error => {
        debug && console.error(`Document[${documentPath}].deleteDocument error`, error);
        throw error;
      });
  }

  protected static getDocument<T>(
    documentPath: string,
    documentConverter: DocumentDataToDocumentState<T>
  ): Promise<T | undefined> {
    return db()
      .doc(documentPath)
      .get()
      .then(doc => {
        const data = doc.data();
        if (data === undefined) {
          debug && console.log(`Document[${documentPath}].getDocument not found`);
          return undefined;
        } else {
          debug && console.log(`Document[${documentPath}].getDocument success`);
          return documentConverter(doc.id, data);
        }
      })
      .catch(error => {
        debug && console.log(`Document[${documentPath}].getDocument error`, error);
        throw error;
      });
  }

  protected static mergeDocument(documentPath: string, data: DocumentData): Promise<void> {
    return db()
      .doc(documentPath)
      .set(data, { merge: true })
      .then(() => {
        debug && console.log(`Document[${documentPath}].mergeDocument success`);
      })
      .catch(error => {
        debug && console.error(`Document[${documentPath}].mergeDocument error`, error);
        throw error;
      });
  }

  protected static setDocument(documentPath: string, data: DocumentData): Promise<void> {
    return db()
      .doc(documentPath)
      .set(data)
      .then(() => {
        debug && console.log(`Document[${documentPath}].setDocument success`);
      })
      .catch(error => {
        debug && console.error(`Document[${documentPath}].setDocument error`, error);
        throw error;
      });
  }

  protected static updateDocument(documentPath: string, data: DocumentData): Promise<void> {
    return db()
      .doc(documentPath)
      .update(data)
      .then(() => {
        debug && console.log(`Document[${documentPath}].updateDocument success`);
      })
      .catch(error => {
        debug && console.error(`Document[${documentPath}].updateDocument error`, error);
        throw error;
      });
  }

  protected static setOrMergeDocument(
    documentPath: string,
    setData: DocumentData,
    updateData: DocumentData
  ): Promise<void> {
    if (PlatformFirebase.nativescript) {
      return new Promise((resolve, reject) => {
        const ref = db().doc(documentPath);
        const callback = (error: Error) => {
          if (error) {
            debug && console.error(`Document[${documentPath}].setOrMergeDocument error`, error);
            reject(error);
          } else {
            debug && console.log(`Document[${documentPath}].setOrMergeDocument success`);
            resolve();
          }
        };
        (db() as any).setOrMergeDocumentTransaction(ref, setData, updateData, callback);
      });
    } else {
      const ref = db().doc(documentPath);
      return db()
        .runTransaction(transaction => {
          return transaction.get(ref).then(doc => {
            if (!doc.exists) {
              transaction.set(ref, setData);
            } else {
              transaction.set(ref, updateData, { merge: true });
            }
          });
        })
        .then(() => {
          debug && console.log(`Document[${documentPath}].setOrMergeDocument success`);
        })
        .catch(error => {
          debug && console.error(`Document[${documentPath}].setOrMergeDocument error`, error);
          throw error;
        });
    }
  }

  protected static setOrUpdateDocument(
    documentPath: string,
    setData: DocumentData,
    updateData: DocumentData
  ): Promise<void> {
    debug && console.error(`Document[${documentPath}].setOrUpdateDocument start`);
    if (PlatformFirebase.nativescript) {
      return new Promise((resolve, reject) => {
        const ref = db().doc(documentPath);
        const callback = (error: Error) => {
          if (error) {
            debug && console.error(`Document[${documentPath}].setOrUpdateDocument error`, error);
            reject(error);
          } else {
            debug && console.log(`Document[${documentPath}].setOrUpdateDocument success`);
            resolve();
          }
        };
        (db() as any).setOrUpdateDocumentTransaction(ref, setData, updateData, callback);
      });
    } else {
      const ref = db().doc(documentPath);
      return db()
        .runTransaction(transaction => {
          return transaction.get(ref).then(doc => {
            if (!doc.exists) {
              transaction.set(ref, setData);
            } else {
              transaction.update(ref, updateData);
            }
          });
        })
        .then(() => {
          debug && console.log(`Document[${documentPath}].setOrUpdateDocument success`);
        })
        .catch(error => {
          debug && console.error(`Document[${documentPath}].setOrUpdateDocument error`, error);
          throw error;
        });
    }
  }

  protected constructor(state: T) {
    super(state);
  }

  dispose(): void {
    this.publishStateComplete();
    this.stopListening();
  }

  update(newState: T): void {
    this.stateStore = newState;
    this.publishState();
  }

  get documentPath(): string | undefined {
    return this._documentPath;
  }

  protected startListening(
    documentPath: string,
    documentConverter: DocumentDataToDocumentState<T>
  ): void {
    debug && console.log(`Document[${documentPath}].startListening`);
    this.stopListening();
    this._documentPath = documentPath;
    this.unsubscribe = db()
      .doc(documentPath)
      .onSnapshot(
        snapshot => {
          const data = snapshot.data();
          if (data === undefined) {
            debug && console.log(`Document[${documentPath}].listening update (not found)`);
            this.update(documentConverter("", {})); // "empty" state
          } else {
            debug && console.log(`Document[${documentPath}].listening update`);
            this.update(documentConverter(snapshot.id, data));
          }
        },
        error => {
          debug && console.error(`Document[${documentPath}].listening error`, error);
          this.unsubscribe = undefined;
          this.publishStateError(error);
        },
        () => {
          // should never happen
          debug && console.error(`Document[${documentPath}].listening complete`);
          this.unsubscribe = undefined;
          this.publishStateComplete();
        }
      );
  }

  protected stopListening(): void {
    debug && console.log(`Document[${this.documentPath}].stopListening`);
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = undefined;
      this._documentPath = undefined;
    }
  }
}
