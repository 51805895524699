/**
 * Parses a HTTP response.
 * @param httpResponse HTTP response as a string.
 */
export function parseResponse(httpResponse: string): Response {
  let header: string;
  let body: string | null;
  // eslint-disable-next-line prefer-const
  [header, body] = httpResponse.split(/\r\n\r\n/, 2);
  // eslint-disable-next-line no-null/no-null
  body = body || null;

  const lines = header.split(/\r\n/);
  const statusLine = lines.shift();
  if (statusLine === undefined) {
    throw new Error("Failed to shift status line off header.");
  }
  const parsedStatusLine = parseStatusLine(statusLine);
  const headers = parseHeaders(lines);

  const response = new Response(body, {
    status: parsedStatusLine.statusCode,
    statusText: parsedStatusLine.statusMessage,
    headers
  });

  return response;
}

function parseStatusLine(statusLine: string) {
  const parts = statusLine.match(/^(.+) ([0-9]{3}) (.*)$/);
  // eslint-disable-next-line no-null/no-null
  if (parts === null) {
    throw new Error("Failed to parse status line.");
  }
  return {
    protocol: parts[1],
    statusCode: parseInt(parts[2], 10),
    statusMessage: parts[3]
  };
}

function parseHeaders(headerLines: Array<string>): Headers {
  const headers = new Headers();
  headerLines.forEach(line => {
    const [name, value] = line.split(":", 2);
    if (!name) throw new Error("Failed to parse header name.");
    if (!value) throw new Error("Failed to parse header value.");
    headers.set(name, value.trim());
  });
  return headers;
}
