export enum NotificationEnum {
  "on hangup" = "on hangup",
  "from incoming calls" = "from incoming calls",
  "on transfer" = "on transfer",
  "on queue alert" = "on queue alert",
  "on chat" = "on chat"
}

export const NotificationTypes: Array<NotificationEnum> = [
  NotificationEnum["on hangup"],
  NotificationEnum["from incoming calls"],
  NotificationEnum["on transfer"],
  NotificationEnum["on queue alert"],
  NotificationEnum["on chat"]
];
