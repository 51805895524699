import { CallVideoContainerComponent } from "./call-video-container.component";
import { QueueVideoBoxComponent } from "./queueVideoBox/queue-video-box.component";
import { CallVideoComponent } from "./callVideo/call-video.component";
import { ScreenShareComponent } from "./screenShare/screen-share.component";

import { MaterialModule } from "../../../modules/material/material.module";
import { VideoBoxModule } from "./videoBox/video-box.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
    VideoBoxModule
  ],
  declarations: [
    CallVideoContainerComponent,
    QueueVideoBoxComponent,
    CallVideoComponent,
    ScreenShareComponent
  ],
  exports: [CallVideoContainerComponent]
})
export class CallVideoContainerModule {}
