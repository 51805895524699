import { log } from "../log";

/** Call event kind type. */
export const CallEventKind = "[Call]"; // Must be string literal (being used as type).

// Call event id types. Must be string literals (being used as types).
export enum EventId {
  NewCallEventId = "[Call] new",
  ConnectingCallEventId = "[Call] connecting",
  ConnectedCallEventId = "[Call] connected",
  EndCallEventId = "[Call] end",
  LastCallEventId = "[Call] last",
  ProgressCallEventId = "[Call] progress",
  VideoCallEventId = "[Call] video",
  TransferFailedEventId = "[Call] transfer failed"
}

/** Call event reasons */
export enum EndCallEventReason {
  /** outgoing call cancelled (sent a CANCEL) */
  Cancelled = "cancelled",
  /** outgoing call failed/rejected (received negative final response) */
  Failed = "failed",
  /** incoming/outgoing dialog ended ended (sent or received a BYE) */
  Terminated = "terminated",
  /** incoming unanswered/declined (sent negative final response) */
  Unanswered = "unanswered"
}

/** Call event interface. */
export class CallEvent {
  /** The event kind. */
  kindCallEvent: typeof CallEventKind = CallEventKind;
  /** The event id. */
  // HACK: leaving this properly makes the type guards not work as you'd expect
  // see https://stackoverflow.com/questions/47841211/type-guards-typescript-inferring-never-but-it-shouldnt
  id!: string;
  /** The UUID of the call the event is assoicated with. */
  uuid: string;
  /** The reason for the event. */
  reason?: any | undefined;

  /**
   * Factory for CallEvent.
   * @param guard The type guard for the event.
   * @param id The id of the event.
   * @param uuid The uuid of the call.
   * @param reason The reason for the event.
   */
  constructor(
    guard: (event: any) => event is CallEvent,
    uuid: string,
    reason?: EndCallEventReason
  ) {
    this.uuid = uuid;
    this.reason = reason;
    if (!guard(this)) {
      const message = `CallEvent.make validation failure creating ${(this as any).id}[${uuid}]`;
      log.error(message);
      throw new Error(message);
    }
  }
}

/**
 * User-Defined Type Guard for CallEvent.
 * @param event Event to check.
 */
export function isCallEvent(event: any): event is CallEvent {
  return event instanceof CallEvent;
}
