import { Injectable, OnDestroy } from "@angular/core";

import { timer, of, Subscription } from "rxjs";
import { map, tap, switchMap, takeWhile, filter, take } from "rxjs/operators";

import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { StateEmitter } from "../../../../../common/libraries/emitter/state-emitter";
import { E164PhoneNumber } from "../../../../../common/libraries/e164-phone-number";
import { AccountDetailsService } from "../../../../../common/services/api/resources/accountDetails/account-details.service";

export interface FreeTrialState {
  /** Free Trial Account must be admin of a free account */
  isFreeTrialAccount: boolean;
  /** Flag to show warning bar, true if welcome modal has been dismissed and  */
  showWarningBar: boolean;
  /** Flag to show welcome modal, true if no pstn trial calls have been made on account */
  showWelcomeModal: boolean;
  /** Milliseconds remaining in trial */
  timeRemaining: number | undefined;
  /** Flag for expired trial, all trial seconds used */
  trialExpired: boolean;
  /** Flag to determine when initial API calls are done */
  initialized: boolean;
}

@Injectable({ providedIn: "root" })
export class FreeTrialService extends StateEmitter<FreeTrialState> implements OnDestroy {
  /** PSTN Calls with maxCallTime Set */
  private activePstnCalls: Record<string, boolean> = {};
  /** Last result from  UserReadAccountDetailsRefresh.AccountDetails.secondsRemaining */
  private lastKnownSecondsRemaining = 0;
  /** Unix timestamp for the soonest time a call will be terminated via maxCallTime
   *  undefined if there are no pstn calls active
   */
  private masterKillTime: number | undefined;
  private unsubscriber = new Subscription();

  private static initialState(): FreeTrialState {
    return {
      isFreeTrialAccount: false,
      showWarningBar: false,
      showWelcomeModal: false,
      timeRemaining: undefined,
      trialExpired: false,
      initialized: false
    };
  }

  constructor(
    private accountDetails: AccountDetailsService,
    private callControllerService: CallControllerService
  ) {
    super(FreeTrialService.initialState());
    this.init();
  }

  init() {
    // Init isFreeTrialAccount, showWarningbar, and firstLogin flags
    this.unsubscriber.add(
      this.accountDetails.state
        .pipe(
          filter(({ loading }) => !loading),
          map(state => Object.values(state.state)[0]),
          take(1)
        )
        .subscribe(accountDetails => {
          const isFreeTrialAccount =
            accountDetails.noPrepaidCredit && accountDetails.primaryPackages.length === 0;
          const secondsRemaining = Number(accountDetails.secondsBalance);
          if (isNaN(secondsRemaining)) {
            throw Error("FreeTrialService: Could not read seconds balance");
          }
          this.lastKnownSecondsRemaining = secondsRemaining;
          this.stateStore.showWelcomeModal = isFreeTrialAccount && secondsRemaining === 180; // Full Trial
          this.stateStore.isFreeTrialAccount = isFreeTrialAccount;
          this.stateStore.trialExpired = isFreeTrialAccount && secondsRemaining <= 0; // Empty Trial
          this.stateStore.showWarningBar =
            this.stateStore.isFreeTrialAccount &&
            !this.stateStore.trialExpired &&
            !this.stateStore.showWelcomeModal;
          this.stateStore.initialized = true;
          this.publishState();
          // Only start main subscription if free trial account that has not expired
          if (this.stateStore.isFreeTrialAccount && !this.stateStore.trialExpired) {
            this.initFreeTrial();
          }
        })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  /** Early check to see if we've run out of credits, if so update state appropriately
   *  Use Case: Calls end before countdown shown, may have no credits remaining
   */
  checkEarlyEnd(): void {
    if (Object.keys(this.activePstnCalls).length === 0) {
      this.unsubscriber.add(
        this.accountDetails.state
          .pipe(
            filter(({ loading }) => !loading),
            map(state => Object.values(state.state)[0]),
            take(1)
          )
          .subscribe(accountDetails => {
            const secondsRemaining = Number(accountDetails.secondsBalance);
            if (isNaN(secondsRemaining)) {
              throw Error("FreeTrialService: Could not read seconds balance");
            }
            this.lastKnownSecondsRemaining = secondsRemaining;
            if (this.lastKnownSecondsRemaining <= 0) {
              this.stateStore.timeRemaining = 0;
              this.stateStore.trialExpired = true;
              this.stateStore.showWarningBar = false;
              this.publishState();
            }
          })
      );
    }
  }

  /** Early end all active free trial pstn calls */
  endFreeTrialCalls(): void {
    Object.keys(this.activePstnCalls).forEach(uuid => {
      this.callControllerService.endCall(uuid);
    });
  }

  /** set free trial welcome modal flag */
  setFreeTrialWelcomeModal(value: boolean) {
    this.stateStore.showWelcomeModal = value;
    this.publishState();
  }

  /** Set free trial warningbar flag */
  setFreeTrialWarningBar(value: boolean) {
    this.stateStore.showWarningBar = value;
    this.publishState();
  }

  /** Initialize Main Free Trial Countdown Observable */
  private initFreeTrial() {
    const killTimeObservable = this.callControllerService.state.pipe(
      // map to list of outgoing pstn calls
      map(state =>
        state.calls.filter(
          call =>
            !call.incoming &&
            new E164PhoneNumber(call.remoteUri.slice(4).replace(/@.*$/, "")).isValid
        )
      ),
      tap(calls => {
        // setMaxCallTimes
        calls.forEach(call => {
          if (this.activePstnCalls[call.uuid] === undefined) {
            // case: First time seeing this PSTN call
            this.activePstnCalls[call.uuid] = true;
            if (call.maxCallTime === undefined) this.updateMaxCallTime(call.uuid); // this will propogate into a callController state update
          }
        });
        // cleanup calls no longer on call controller state
        Object.keys(this.activePstnCalls).forEach(uuid => {
          if (!calls.find(call => call.uuid === uuid)) delete this.activePstnCalls[uuid];
        });
      }),
      map(calls => {
        // compute minimum time remaining on all free-trial pstn calls
        // no calls or no answered calls, with maxCallTime set, killTime is undefined
        if (
          calls.length === 0 ||
          calls.every(call => call.maxCallTime === undefined || call.connectedAt === undefined)
        ) {
          return undefined;
        }
        return calls
          .map(call => {
            if (call.maxCallTime === undefined || call.connectedAt === undefined) return undefined;
            return call.connectedAt + call.maxCallTime; // unix timestamp when this call will end
          })
          .filter((maxTime): maxTime is number => maxTime !== undefined)
          .reduce(
            (acc, curr) => Math.min(acc, curr),
            Date.now() + this.getLowestKnownTimeRemaining()
          );
      })
    );

    killTimeObservable
      .pipe(
        tap(killTime => (this.masterKillTime = killTime)),
        switchMap(killTime => {
          if (killTime === undefined) return of(undefined);
          return timer(0, 1000).pipe(
            map(() => Math.floor((killTime - Date.now()) / 1000) * 1000), // floor to nearest full ms
            takeWhile(timeRemaining => timeRemaining >= 0)
          ); // milliseconds remaining
        })
      )
      .subscribe(timeRemaining => {
        this.stateStore.timeRemaining = timeRemaining;
        if (timeRemaining === 0) {
          this.stateStore.showWarningBar = false;
          this.stateStore.trialExpired = true;
          this.endFreeTrialCalls();
        }
        this.publishState();
      });
  }

  /** Set a maxCallTime, Check API for an update, set if lowered
   *  This should be invoked exactly once per Call
   */
  private updateMaxCallTime(uuid: string) {
    // Wait for call to be connected to ensure all calls terminate at the same time
    this.callControllerService.state
      .pipe(
        map(state => state.calls.find(call => call.uuid === uuid)),
        filter(call => !!call?.connectedAt),
        take(1)
      )
      .subscribe(() => {
        this.callControllerService.maxCallTime(uuid, this.getLowestKnownTimeRemaining());
      });
    if (this.lastKnownSecondsRemaining > 0) this.fetchPstnCreditsRemaining(); // No need to keep fetching if we've hit 0;
  }

  /** Check API for updates on remaining free pstn credits, decide whether to update all active calls */
  private fetchPstnCreditsRemaining() {
    this.unsubscriber.add(
      this.accountDetails.state
        .pipe(
          filter(({ loading }) => !loading),
          map(state => Object.values(state.state)[0]),
          take(1)
        )
        .subscribe(accountDetails => {
          const secondsRemaining = Number(accountDetails.secondsBalance);
          if (isNaN(secondsRemaining)) {
            throw Error("FreeTrialService: Could not read seconds balance");
          }
          this.lastKnownSecondsRemaining = secondsRemaining;
          // if the calls are set to end later than the most recent api result, update all active calls' maxCallTime
          if (!this.masterKillTime || this.masterKillTime > Date.now() + secondsRemaining * 1000) {
            Object.keys(this.activePstnCalls).forEach(uuid => {
              this.callControllerService.maxCallTime(uuid, this.getLowestKnownTimeRemaining());
            });
          }
        })
    );
  }

  /** Minimum time remaining is either: lowest time on another active call or lastKnownCredits -> milliseconds */
  private getLowestKnownTimeRemaining() {
    return this.masterKillTime
      ? Math.min(this.masterKillTime - Date.now(), this.lastKnownSecondsRemaining * 1000)
      : this.lastKnownSecondsRemaining * 1000;
  }
}
