import { Injectable, OnDestroy } from "@angular/core";
import { StateEmitter } from "../../libraries/emitter";

import { CallControllerService } from "../call-controller.service";
import { FirestoreCallService } from "../sayso/firestore-call.service";

import { CallHistoryCall, RecentCallDirection, RecentCallDisposition } from "./call-history";
import { isEndCallEvent } from "../../libraries/sip/call-event";
import { CallState } from "../../libraries/sip/call-state";
import { OnSIPURI } from "../../libraries/onsip-uri";
import { Subscription } from "rxjs";
import { UserService } from "../api/resources/user/user.service";
import { map, distinctUntilChanged } from "rxjs/operators";

interface CallHistoryCallsState {
  callHistoryCalls: Array<CallHistoryCall>;
}

@Injectable({ providedIn: "root" })
export class CallHistoryCallsService
  extends StateEmitter<CallHistoryCallsState>
  implements OnDestroy
{
  private unsubscriber = new Subscription();
  constructor(
    private callControllerService: CallControllerService,
    private firestoreCallService: FirestoreCallService,
    private userService: UserService
  ) {
    super({ callHistoryCalls: [] } as CallHistoryCallsState);

    this.unsubscriber.add(
      this.userService.selfUser
        .pipe(
          map(user => user.userId),
          distinctUntilChanged()
        )
        .subscribe(() => {
          this.stateStore.callHistoryCalls = [];
          this.publishState();
        })
    );

    this.unsubscriber.add(
      this.callControllerService.getCallEventObservable().subscribe(event => {
        if (isEndCallEvent(event)) {
          const callId = this.callControllerService.getCallId(event.uuid);
          const callState = this.callControllerService.getCallStateByUuid(event.uuid);
          if (callState && callId) {
            this.addCallToStore(callState, callId);
          }
        }
      })
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  /** converts CallState object into a CallHistoryCall object. throws any invalid calls */
  private addCallToStore(call: CallState, callId: string): CallHistoryCall | undefined {
    if (!call.newAt) {
      console.error("failed to get start time of call");
      return;
    }

    if (!call.endedAt) {
      console.error("failed to get end timeof call");
      return;
    }

    const uaAor = call.aor;
    const uaUri = OnSIPURI.parseString("sip:" + uaAor);
    if (!uaUri) {
      console.error("failed to parse user agent uri");
      return;
    }

    const remoteUri: OnSIPURI | undefined = OnSIPURI.parseString(call.remoteUri);
    if (!remoteUri || !remoteUri.user) return;

    remoteUri.user = remoteUri.user.replace("+", "");
    const direction = call.incoming ? RecentCallDirection.INBOUND : RecentCallDirection.OUTBOUND;
    const disposition =
      call.incoming && !call.connectedAt
        ? RecentCallDisposition.MISSED
        : !call.incoming && !call.connectedAt
        ? RecentCallDisposition.CANCELLED
        : RecentCallDisposition.ANSWERED;
    let remoteName;
    if (remoteUri.host === "anonymous.invalid" || remoteUri.user.slice(0, 9) === "anonymous") {
      remoteName = (call.remoteDisplayName || "Unknown") + "(anonymous)";
    }

    let ouid = "";
    if (this.firestoreCallService.getCallFromSIPCallState(call)) {
      const saysoCall = this.firestoreCallService.getCallFromSIPCallState(call);
      ouid = saysoCall ? saysoCall.stateValue.ouid : "";
    }

    const newCall = {
      callId,
      callTime: new Date(call.newAt).toISOString(),
      direction,
      disposition,
      duration: new Date(call.endedAt).getTime() - new Date(call.newAt).getTime(),
      localUri: uaUri.toString(),
      ouid,
      remoteName: remoteName || remoteUri.aor,
      remoteUri: remoteUri.toString()
    };

    this.stateStore.callHistoryCalls.unshift(newCall);
    this.publishState();
  }
}
