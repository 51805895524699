import {
  OnsipAPIBoolean,
  onsipApiBooleanToBoolean,
  OnsipAPIArray,
  onsipApiArrayToArray
} from "../../apiResponse/xml-json";
import { ApiContact, Contact, apiContactToContact } from "../../apiResponse/contact";
import { UserStatus } from "../../apiResponse/user-status";
import { Role } from "../../role";

export declare interface ApiUser {
  AccountId: string;
  Ack911: string;
  AckHostedE911: string;
  AckHostedTerms: string;
  AckTrunkingE911: string;
  AckTrunkingTerms: string;
  AuthUsername: string;
  BusyLampField: string;
  Contact: ApiContact;
  Created: string;
  Domain: string;
  E911Provisioning: OnsipAPIBoolean;
  ExposePii: OnsipAPIBoolean;
  ExtendedDialing: OnsipAPIBoolean;
  Flags: string;
  FreePSTNTrunking: OnsipAPIBoolean;
  LoginAlertEmail: OnsipAPIBoolean;
  MfaEmail: OnsipAPIBoolean;
  Modified: string;
  NoOutgoingPstn: OnsipAPIBoolean;
  OrganizationId: string;
  PSTNForwarding: OnsipAPIBoolean;
  PSTNTrunking: OnsipAPIBoolean;
  Password: string;
  QueueEvent: OnsipAPIBoolean;
  RegEvent: OnsipAPIBoolean;
  Roles: OnsipAPIArray<"Role", { Name: Role }>;
  Status: UserStatus;
  SurveyCompleted: string;
  TeamPageVisible: OnsipAPIBoolean;
  UserId: string;
  Username: string;
}

export declare interface User {
  accountId: string;
  ack911: string;
  ackHostedE911: string;
  ackHostedTerms: string;
  ackTrunkingE911: string;
  ackTrunkingTerms: string;
  authUsername: string;
  busyLampField: string;
  created: string;
  contact: Contact;
  domain: string;
  e911Provisioning: boolean;
  exposePii: boolean;
  extendedDialing: boolean;
  flags: string;
  freePSTNTrunking: boolean;
  loginAlertEmail: boolean;
  mfaEmail: boolean;
  noOutgoingPstn: boolean;
  organizationId: string;
  pSTNForwarding: boolean;
  pSTNTrunking: boolean;
  password: string;
  queueEvent: boolean;
  regEvent: boolean;
  roles: Array<Role>;
  status: UserStatus;
  surveyCompleted: string;
  teamPageVisible: boolean;
  userId: string;
  username: string;
}

export function apiUserToUser(apiUser: ApiUser): User {
  return {
    accountId: apiUser.AccountId,
    ack911: apiUser.Ack911,
    ackHostedE911: apiUser.AckHostedE911,
    ackHostedTerms: apiUser.AckHostedTerms,
    ackTrunkingE911: apiUser.AckTrunkingE911,
    ackTrunkingTerms: apiUser.AckTrunkingTerms,
    authUsername: apiUser.AuthUsername,
    busyLampField: apiUser.BusyLampField,
    created: apiUser.Created,
    contact: apiContactToContact(apiUser.Contact),
    domain: apiUser.Domain,
    e911Provisioning: onsipApiBooleanToBoolean(apiUser.E911Provisioning),
    exposePii: onsipApiBooleanToBoolean(apiUser.ExposePii),
    extendedDialing: onsipApiBooleanToBoolean(apiUser.ExtendedDialing),
    flags: apiUser.Flags,
    freePSTNTrunking: onsipApiBooleanToBoolean(apiUser.FreePSTNTrunking),
    loginAlertEmail: onsipApiBooleanToBoolean(apiUser.LoginAlertEmail),
    mfaEmail: onsipApiBooleanToBoolean(apiUser.MfaEmail),
    noOutgoingPstn: onsipApiBooleanToBoolean(apiUser.NoOutgoingPstn),
    organizationId: apiUser.OrganizationId,
    pSTNForwarding: onsipApiBooleanToBoolean(apiUser.PSTNForwarding),
    pSTNTrunking: onsipApiBooleanToBoolean(apiUser.PSTNTrunking),
    password: apiUser.Password,
    queueEvent: onsipApiBooleanToBoolean(apiUser.QueueEvent),
    regEvent: onsipApiBooleanToBoolean(apiUser.RegEvent),
    // Role is an exception to the Array rule and is wrapped in an object keyed to "Name"
    roles: onsipApiArrayToArray(apiUser.Roles, "Role").map(roleObject => roleObject.Name),
    status: apiUser.Status,
    surveyCompleted: apiUser.SurveyCompleted,
    teamPageVisible: onsipApiBooleanToBoolean(apiUser.TeamPageVisible),
    userId: apiUser.UserId,
    username: apiUser.Username
  };
}
