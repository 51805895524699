import { ChatMessageService } from "./chat-message.service";
import { ChatContact } from "./chat-contact.service";

import { Injectable } from "@angular/core";

const providerNames = {
  ONSIP: "onsip"
};

const MESSAGE_SPACING = 180; // 3 minutes

interface MessageLine {
  timestamp: number;
}

export interface ChatBody {
  id: string;
  messages: Array<{
    timestamp: number;
    lines: Array<string>;
    fromName: string;
    created?: Date;
    displayDate: string;
    archiveLink: string;
    text: string;
    author: ChatContact;
    addLine: (a: MessageLine) => void;
  }>;
  contact: {
    id: string;
    displayName: string;
    email: string;
    presence: string;
  };
  unreadCount: number;
  latestMessageTimestamp: number;
  userTypingTimerIds: Record<string, any>;
  userTypingChatContacts: Array<ChatContact>;
  isUserTyping: boolean;
  isUserAway: boolean;
  userAwayMessage: string;
  obtainedHistory: boolean;
  domain?: string;
  name?: string;
  isArchived?: boolean;
  isMember?: boolean;
  members?: Array<string>;
  userTypingFromName?: string;
  userTypingMessage?: string;
  populateHistory: (history: Array<any>) => void;
  addLine: (author: string, messageLine: { timestamp: number }) => void;
  showUserTyping: (contact: ChatContact, func: () => void) => void;
  hideUserTyping: (contact: ChatContact, func: () => void) => void;
  updateUserTypingMessage: () => void;
}

@Injectable({ providedIn: "root" })
export class ChatBodyService {
  private domain: string | undefined;

  constructor(private chatMessageService: ChatMessageService) {}

  addChatBodyPrototype(chatBody: ChatBody): void {
    /***
     * history is lazy loaded, so there could be existing
     * messages when initializing. Existing events may be included in history.
     * deconstruct message and reprocess all events together.

     * Note: useless for OnSIP, but the hope is everything here is generic enough for all
     */
    chatBody.populateHistory = (history: Array<any>): void => {
      const groupedHistory: Array<any> = [];

      if (chatBody.obtainedHistory) {
        return;
      }

      // dedupe
      chatBody.messages.forEach(message => {
        message.lines.forEach((messageLine, timestamp) => {
          history[timestamp] = { author: message.author, line: messageLine };
        });
      });

      // sort
      history = Object.keys(history)
        .sort()
        .map(ts => {
          return history[ts as any];
        });

      // group
      history.forEach((message, idx) => {
        const oldMessage = history[idx - 1],
          author: any = message.author,
          oldAuthor: any = idx === 0 ? "" : oldMessage.author,
          timespan: any = message.line.timestamp - (idx === 0 ? 0 : oldMessage.line.timestamp);

        if (groupedHistory.length === 0 || author !== oldAuthor || timespan > MESSAGE_SPACING) {
          groupedHistory.push(
            this.chatMessageService.createChatMessage(chatBody, message.author, message.line)
          );
        } else {
          groupedHistory[groupedHistory.length - 1].addLine(message.line);
        }
      });

      chatBody.messages = groupedHistory;
      chatBody.latestMessageTimestamp = this.findLatestMessageTimestamp(chatBody.messages);

      chatBody.obtainedHistory = true;
    };

    // append to existing message or create a new one .. do not recreate
    // all messages for each new event after history is loaded.
    chatBody.addLine = (author: string, messageLine: MessageLine): void => {
      let idxToUse = -1;
      const lastMessage: any =
          chatBody.messages.length > 0
            ? chatBody.messages[chatBody.messages.length - 1]
            : undefined,
        foundIdx: boolean = chatBody.messages.some((message, idx) => {
          if (message.timestamp > messageLine.timestamp) {
            idxToUse = idx - 1;
            return true;
          }
        });

      if (!foundIdx) {
        // check against end of messages
        const timespan: any = messageLine.timestamp - (lastMessage ? lastMessage.timestamp : 0);

        if (!lastMessage || author !== lastMessage.author || timespan > MESSAGE_SPACING) {
          chatBody.messages.push(
            this.chatMessageService.createChatMessage(chatBody, author, messageLine)
          );
        } else {
          lastMessage.addLine(messageLine);
        }
      } else if (idxToUse < 0) {
        // create new message at beginning of messages
        chatBody.messages.unshift(
          this.chatMessageService.createChatMessage(chatBody, author, messageLine)
        );
      } else {
        // insert into the one we found
        chatBody.messages[idxToUse].addLine(messageLine);
      }

      chatBody.latestMessageTimestamp = this.findLatestMessageTimestamp(chatBody.messages);
    };
    chatBody.showUserTyping = (chatContact: ChatContact, changeFunc: () => void): void => {
      const wasUserTyping = !!chatBody.userTypingTimerIds[chatContact.id];

      if (wasUserTyping) {
        clearTimeout(chatBody.userTypingTimerIds[chatContact.id]);
        chatBody.userTypingChatContacts.splice(
          chatBody.userTypingChatContacts.indexOf(chatContact),
          1
        );
      }

      chatBody.userTypingTimerIds[chatContact.id] = setTimeout(() => {
        chatBody.hideUserTyping(chatContact, changeFunc);
      }, 5000);

      chatBody.userTypingChatContacts.push(chatContact);
      chatBody.updateUserTypingMessage();

      if (!wasUserTyping) {
        changeFunc();
      }
    };

    chatBody.hideUserTyping = (chatContact: ChatContact, changeFunc: () => void): void => {
      if (chatBody.userTypingTimerIds[chatContact.id]) {
        clearTimeout(chatBody.userTypingTimerIds[chatContact.id]);
        chatBody.userTypingTimerIds[chatContact.id] = undefined;
        chatBody.userTypingChatContacts.splice(
          chatBody.userTypingChatContacts.indexOf(chatContact),
          1
        );
        chatBody.updateUserTypingMessage();
        changeFunc && changeFunc();
      }
    };

    chatBody.updateUserTypingMessage = (): void => {
      let fromNames = "";

      if (chatBody.userTypingChatContacts.length === 0) {
        chatBody.isUserTyping = false;
      } else if (
        chatBody.userTypingChatContacts.length > 0 &&
        chatBody.userTypingChatContacts.length < 5
      ) {
        // show names for 1 - 4 users typing simultaneously
        chatBody.isUserTyping = true;
        chatBody.userTypingChatContacts.forEach((chatContact, chatContactIndex) => {
          if (chatContactIndex === 0) {
            fromNames = chatContact.displayName;
          } else if (
            chatContactIndex > 0 &&
            chatContactIndex < chatBody.userTypingChatContacts.length - 1
          ) {
            fromNames = fromNames + ", " + chatContact.displayName;
          } else {
            fromNames = fromNames + " and " + chatContact.displayName;
          }
        });
        chatBody.userTypingFromName = fromNames;
        chatBody.userTypingMessage =
          chatBody.userTypingChatContacts.length > 1
            ? fromNames + " are typing ..."
            : fromNames + " is typing ...";
      } else {
        // show generic user typing message when 5+ users typing simultaneoulsy
        chatBody.isUserTyping = true;
        chatBody.userTypingFromName = "Several People";
        chatBody.userTypingMessage = "Several people are typing ...";
      }
    };
  }

  // all message events occuring in a message channel, sorted and organized
  // into messages (groups of related events). events are not gauranteed to be delivered in order.
  createChatBody(
    contact: ChatContact,
    id: string,
    provider: string,
    unreadCount?: number,
    data?: any
  ): ChatBody {
    const chatBody: any = {
      id,
      messages: [],
      contact: {
        id: contact.id,
        displayName: contact.displayName,
        email: contact.email,
        presence: contact.presence
      },
      unreadCount: unreadCount || 0,
      latestMessageTimestamp: 0,
      userTypingTimerIds: {},
      userTypingChatContacts: [],
      isUserTyping: false,
      obtainedHistory: provider === providerNames.ONSIP // onsip has no history
      // userTypingFromName: contact.displayName,
      // userTypingMessage: contact.displayName + ' is typing ...',
    };

    this.addChatBodyPrototype(chatBody);

    if (!chatBody.obtainedHistory) {
      // means this body is capable of obtaining history
      if (!this.domain) {
        this.domain = data && data.team && data.team.domain;
      }
      chatBody.domain = this.domain;
    }

    return chatBody as ChatBody;
  }

  private findLatestMessageTimestamp(_messages: Array<any>): number {
    const messages: Array<any> = Object.create(_messages);
    let timestamp = 0;

    messages.reverse();
    messages.some(message => {
      const lines: Array<any> = Object.create(message.lines);
      lines.reverse();
      return lines.some(line => {
        if (!line.isPending) {
          timestamp = line.timestamp;
          return true;
        }
        return false;
      });
    });
    return timestamp;
  }
}
