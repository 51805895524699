import { Injectable, NgZone } from "@angular/core";

import { CallControllerConstructor, LogService, IdentityService } from "@onsip/common/modules/core";
import { CallControllerService } from "@onsip/common/services/call-controller.service";
import { NetworkService } from "@onsip/common/services/network.service";
import { CallController } from "@onsip/common/libraries/sip/call-controller";

const debug = false;

@Injectable({ providedIn: "root" })
export class WebCallControllerService extends CallControllerService {
  constructor(log: LogService, identity: IdentityService, network: NetworkService, ngZone: NgZone) {
    super(log, identity, network, ngZone);
  }

  getCallControllerConstructor(): CallControllerConstructor {
    debug && console.warn("WebCallControllerService.getCallControllerConstructor");

    return CallController.bind(undefined, {
      maximumCallGroups: undefined,
      maximumCallsPerCallGroup: undefined
    });
  }
}
