import { Config } from "@onsip/common/config";
import { LogService } from "@onsip/common/services/logging";
import { NotificationEnum } from "@onsip/common/interfaces/notification-types";
import { Injectable } from "@angular/core";
import { filter, take } from "rxjs/operators";
import { IdentityService } from "@onsip/common/modules/core";
import { mergeDeep } from "../../helpers/merge-deep.helper";

interface GeneralSettings {
  autoAnswer?: boolean;
  desktopMode?: boolean;
  defaultUA?: string;
  volume?: number;
}

interface UserAgentInterface {
  register?: boolean;
  instanceId?: string;
}

interface ModalWarningStorage {
  isHiddenRecordWholeOrgModal?: boolean;
  isHiddenGroupExtNumModal?: boolean;
  isHiddenDBNWholeOrgModal?: boolean;
  isShowedE911AdminModal?: boolean;
}

interface MiscStore {
  bhrViewFlag: "table" | "grid";
}

interface LocalStorageObject {
  general: GeneralSettings;
  userAgents: Record<string, UserAgentInterface>;
  notifications: {
    types: Partial<Record<NotificationEnum, boolean>>;
  };
  modalsWarnings: ModalWarningStorage;
  misc: MiscStore;
}

@Injectable({ providedIn: "root" })
export class LocalStorageService {
  private localStorage!: LocalStorageObject;
  private localKey!: string;
  private initializePromise: Promise<any>;

  constructor(private log: LogService, private identity: IdentityService) {
    this.initializePromise = new Promise<void>(resolve => {
      this.identity.state
        .pipe(
          filter(state => state.addresses.length > 0),
          take(1)
        )
        .subscribe(state => {
          this.localKey = state.addresses[0].domain + "-jiffyphone";

          return this.platformInitReadFromStorage()
            .then(localStorage => {
              this.localStorage =
                typeof localStorage === "string" && localStorage.length > 0
                  ? JSON.parse(localStorage)
                  : {};

              this.localStorage.general = this.localStorage.general || {};

              this.localStorage.userAgents = this.localStorage.userAgents || {};
              state.addresses.forEach(
                address =>
                  (this.localStorage.userAgents[address.aor] =
                    this.localStorage.userAgents[address.aor] || {})
              );

              this.localStorage.notifications = this.localStorage.notifications || {
                types: {}
              };
              this.localStorage.notifications.types = this.localStorage.notifications.types || {};
              this.localStorage.modalsWarnings = this.localStorage.modalsWarnings || {};
              this.localStorage.misc = { bhrViewFlag: "table" };

              resolve();
            })
            .catch(e => {
              this.log.error("localStorageService", "- local storage read:", e);
              this.localStorage = {
                general: {
                  autoAnswer: false,
                  desktopMode: false,
                  defaultUA: undefined,
                  volume: 80
                },
                userAgents: {},
                notifications: {
                  types: {}
                },
                modalsWarnings: {},
                misc: {
                  bhrViewFlag: "table"
                }
              };
              resolve();
            });
        });
    });
  }

  getUserAgentValues(aor: string): Promise<{ register: boolean; instanceId: string | undefined }> {
    return this.initializePromise.then(() => {
      const readRegValue = this.localStorage.userAgents[aor]?.register;
      return {
        register: readRegValue === undefined ? true : readRegValue,
        instanceId: this.localStorage.userAgents[aor]?.instanceId
      };
    });
  }

  setStorage(settings: Partial<LocalStorageObject>): void {
    this.localStorage = mergeDeep(this.localStorage, settings);
    this.platformWriteToStorage();
  }

  setGeneralSettings(general: GeneralSettings): void {
    this.localStorage = mergeDeep(this.localStorage, { general });
    this.platformWriteToStorage();
  }

  getAutoAnswer(): Promise<boolean | undefined> {
    return this.initializePromise.then(() => this.localStorage.general.autoAnswer);
  }

  getDesktopMode(): Promise<boolean | undefined> {
    return this.initializePromise.then(() => this.localStorage.general.desktopMode);
  }

  getDefaultUA(): Promise<string | undefined> {
    return this.initializePromise.then(() => this.localStorage.general.defaultUA);
  }

  getVolume(): Promise<number | undefined> {
    return this.initializePromise.then(() => this.localStorage.general.volume);
  }

  getModalWarnings(): Promise<ModalWarningStorage> {
    return this.initializePromise.then(() => this.localStorage.modalsWarnings);
  }

  setUserAgent(aor: string, userAgents: UserAgentInterface): void {
    this.localStorage = mergeDeep(this.localStorage, { userAgents: { [aor]: userAgents } });
    this.platformWriteToStorage();
  }

  setModalsWarningsStorage(modalsWarnings: ModalWarningStorage): void {
    this.localStorage = mergeDeep(this.localStorage, { modalsWarnings });
    this.platformWriteToStorage();
  }

  /** Shortcut for setting UserAgentRegister on all uas */
  setDoNotDisturb(dnd: boolean) {
    Object.keys(this.localStorage.userAgents).forEach(aor => {
      this.setUserAgent(aor, { register: !dnd });
    });
  }

  getNotifications(): Promise<Record<string, boolean | undefined>> {
    return this.initializePromise.then(() => this.localStorage.notifications.types);
  }

  setNotification(type: string, shouldNotify: boolean): void {
    // TODO these notification types need work, the enum/array thing does not work well
    (this.localStorage.notifications.types as any)[type] = shouldNotify;
    this.platformWriteToStorage();
  }

  getBhrDisplayType(): Promise<MiscStore["bhrViewFlag"]> {
    return this.initializePromise.then(() => this.localStorage.misc.bhrViewFlag);
  }

  setBhrDisplayType(type: MiscStore["bhrViewFlag"]): void {
    this.localStorage.misc.bhrViewFlag = type;
    this.platformWriteToStorage();
  }

  private platformInitReadFromStorage(): Promise<string | null> {
    return new Promise(resolve => {
      if (Config.IS_WEB) {
        resolve(window.localStorage.getItem(this.localKey));
      }
      if (Config.IS_DESKTOP) {
        window.electron.onMessage("data-path-retrieve", (event, dataPath) => {
          window.electron.desktopStorage.setDataPath(dataPath);
          window.electron.desktopStorage.get(this.localKey, (err: string, localStorage: any) =>
            resolve(localStorage)
          );
        });
        window.electron.sendMessage("get-data-path");
      }
    });
  }

  private platformWriteToStorage(): void {
    try {
      const stringify = JSON.stringify(this.localStorage);

      if (Config.IS_WEB) {
        window.localStorage.setItem(this.localKey, stringify);
      }

      if (Config.IS_DESKTOP) {
        window.electron.desktopStorage.set(this.localKey, stringify, (e: string) => {
          if (e) throw e;
        });
      }
    } catch (e) {
      console.error("local storage write failed with:", e);
    }
  }
}
