export interface NotifyObject {
  callers: Array<any>;
  agents: Array<any>;
}

export interface AdvQueueWarning {
  AdvQueueAddress: string;
  AdvQueueWarningId: string;
  ThresholdType: string;
  ThresholdValue: string;
  WarningBhr: string;
  cooldownStart?: number;
  hasCleared?: boolean;
  CooldownSeconds: string;
}

export declare interface ReturnObj {
  triggered: boolean;
  recheckIn: number;
}

export declare interface ThresholdValue {
  Value: any;
}

export function hasTooManyCallsWaiting(
  thresholdValue: ThresholdValue,
  notifyObject: NotifyObject
): ReturnObj {
  return {
    triggered:
      notifyObject.callers.filter(caller => caller.status.startsWith("waiting")).length >
      thresholdValue.Value,
    recheckIn: Infinity
  };
}

export function hasCallWaitingTooLong(
  thresholdValue: ThresholdValue,
  notifyObject: NotifyObject,
  currDate: Date
): ReturnObj {
  const waitingTimes: Array<number> = notifyObject.callers
    .filter(caller => caller.status.startsWith("waiting"))
    .map(caller => Date.parse(caller.enqueue_time))
    .map(milliseconds => (currDate.getTime() - milliseconds) / 1000);

  const underThreshold: Array<number> = waitingTimes.filter(item => item < thresholdValue.Value);

  const closest = Math.max(...underThreshold);

  return {
    triggered: waitingTimes.filter(time => time > thresholdValue.Value).length > 0,
    recheckIn: (thresholdValue.Value - closest) * 1000
  };
}

export function hasTooFewAgents(
  thresholdValue: ThresholdValue,
  notifyObject: NotifyObject
): ReturnObj {
  return {
    triggered:
      notifyObject.agents.filter(agent => !agent.status.startsWith("logged_out")).length <
      thresholdValue.Value,
    recheckIn: Infinity
  };
}
