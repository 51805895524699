import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { views } from "../../../../app/phone/views";

import { of, combineLatest, Subscription } from "rxjs";
import { map, distinctUntilChanged, pluck, filter } from "rxjs/operators";
import { StateEmitter } from "../../../../../common/libraries/emitter";

import { AppCallingService } from "../../services/appCalling/app-calling.service";
import { CallAudioService } from "../../../call/call-audio.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { FreeTrialService } from "../freeTrial/free-trial.service";
import { AutoAnswerService } from "../../services/autoAnswer/auto-answer.service";
import { SupportService } from "../../../../../common/services/support/support.service";
import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";
import { WarningBarStates, WarningBarNames, WarningBarData } from "./warnings";
import { GlobalPresenceService } from "../../../../../common/services/global-presence.service";
import { SubscriptionControllerService } from "../../../../../common/services/subscription-controller.service";
import {
  Presentity,
  ExtendedDialogInfo,
  PersonalDialog
} from "../../../../../common/interfaces/presentity";
import { IdentityService } from "../../../../../common/modules/core";
import { NetworkService } from "../../../../../common/services/network.service";
import { UserAddressService } from "../../../../../common/services/api/resources/userAddress/user-address.service";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { isAtLeastAccountAdminRole } from "../../../../../common/services/api/role";
import { AccountDetailsService } from "../../../../../common/services/api/resources/accountDetails/account-details.service";
import { Config } from "../../../../../common/config";

/* Add new warnings in the warnings.ts if you are using this warning-bar.service.
 * Warnings here should be only for states you want persistent throughout the app, otherwise use a snackbar.
 * You will need to add your message to the i18n en.json file as well for the message to show up on the banner.
 * After you add the warning to the json file, add to the name to the WarningBarNames and import service holding the state for the warning.
 * Create an initial subscription to the service and hook it up like the other services.
 * If there is a undo/revert/turn off button, add the service function for it in the revertState() function.
 */

@Injectable({ providedIn: "root" })
export class WarningBarService extends StateEmitter<Array<WarningBarData>> {
  private static initialState: Array<WarningBarData> = [];
  private hasDeniedNotifications =
    "Notification" in window ? Notification.permission === "denied" : false;
  private isChrome: boolean = this.supportService.getBrowser().name === "chrome";
  private isDesktop: boolean = Config.IS_DESKTOP;
  private unsubscriber = new Subscription();

  constructor(
    private network: NetworkService,
    private identity: IdentityService,
    private router: Router,
    private appCallingService: AppCallingService,
    private callAudioService: CallAudioService,
    private callControllerService: CallControllerService,
    private freeTrialService: FreeTrialService,
    private globalPresenceService: GlobalPresenceService,
    private autoAnswerService: AutoAnswerService,
    private supportService: SupportService,
    private subscriptionControllerService: SubscriptionControllerService,
    private localStorageService: LocalStorageService,
    private userAddress: UserAddressService,
    private userService: UserService,
    private accountDetails: AccountDetailsService
  ) {
    super(WarningBarService.initialState);

    this.subscribeNetworkState();
    this.subscribeE911();
    this.subscribeE911AdminEnabled();
    this.subscribeE911UserEnabled();
    this.subscribeCallSetupActive();
    this.subscribeGlobalAvailability();
    this.subscribeCallAudio();
    this.subscribeAutoAnswer();
    this.subscribeNotification();
    this.subscribeSubscription();
    this.subscribeDoNotDisturb();
    this.subscribeFreeTrial();
    this.subscribeExperimentalIOS();
  }

  /** call to dispose service */
  dispose(): void {
    this.unsubscriber.unsubscribe();
  }

  /** subscribe to app state and look at the online param for offline state */
  subscribeNetworkState(): void {
    this.unsubscriber.add(
      this.network.state
        .pipe(
          map(state => !state.online),
          distinctUntilChanged()
        )
        .subscribe(offline => {
          this.handleSubscription(WarningBarStates.offline, offline);
        })
    );
  }

  subscribeE911(): void {
    this.unsubscriber.add(
      combineLatest([
        this.selfUserAddress$,
        this.userService.selfUser,
        this.accountDetails.state.pipe(
          filter(state => !state.loading),
          map(state => Object.values(state.state)[0])
        )
      ]).subscribe(([e911LocId, user, accountDetails]) => {
        const isAdmin = isAtLeastAccountAdminRole(user.roles);
        const isTacUnnecessary =
          !user.e911Provisioning &&
          parseInt(accountDetails.prepaidBalance) <= 0 &&
          parseInt(accountDetails.secondsBalance) <= 0;

        const warningBarState = isAdmin
          ? this.isDesktop
            ? WarningBarStates.noE911AdminDesktop
            : WarningBarStates.noE911Admin
          : WarningBarStates.noE911User;

        if (isTacUnnecessary || !user.e911Provisioning) {
          this.handleSubscription(warningBarState, true);
        } else if (!e911LocId && !isAdmin) {
          this.handleSubscription(warningBarState, true);
        } else {
          this.handleSubscription(warningBarState, false);
        }
      })
    );
  }

  subscribeE911AdminEnabled() {
    this.unsubscriber.add(
      combineLatest([this.userService.selfUser, this.selfUserAddress$]).subscribe(
        ([user, e911LocationId]) => {
          const isAdmin = isAtLeastAccountAdminRole(user.roles);
          if (isAdmin && !e911LocationId && user.e911Provisioning) {
            this.handleSubscription(WarningBarStates.E911Admin, true);
          } else {
            this.handleSubscription(WarningBarStates.E911Admin, false);
          }
        }
      )
    );
  }

  subscribeE911UserEnabled() {
    this.unsubscriber.add(
      combineLatest([this.userService.selfUser, this.selfUserAddress$]).subscribe(
        ([user, e911LocationId]) => {
          const isAdmin = isAtLeastAccountAdminRole(user.roles);
          if (!isAdmin && !e911LocationId && user.e911Provisioning) {
            this.handleSubscription(WarningBarStates.E911User, true);
          } else {
            this.handleSubscription(WarningBarStates.E911User, false);
          }
        }
      )
    );
  }

  /** subscribe to call setup active to know disabled state of app call */
  subscribeCallSetupActive(): void {
    WarningBarStates.appCallDisabled.canEnable = this.supportService.isWebrtcSupported();
    this.unsubscriber.add(
      this.appCallingService.state
        .pipe(pluck("enabled"), distinctUntilChanged())
        .subscribe(appCallingEnabled => {
          this.handleSubscription(WarningBarStates.appCallDisabled, !appCallingEnabled);
        })
    );
  }
  /** subscribe to user availability to determine user's global availiability state */
  subscribeGlobalAvailability(): void {
    this.unsubscriber.add(
      this.globalPresenceService.globalPresenceStream
        .pipe(distinctUntilChanged())
        .subscribe(isAvailable => {
          this.handleSubscription(WarningBarStates.globalAvailability, !isAvailable);
        })
    );
  }
  /** subscribe to call audio to check for enabled audio state */
  subscribeCallAudio(): void {
    this.unsubscriber.add(
      this.callAudioService.state
        .pipe(
          map(state => state.enabled),
          distinctUntilChanged()
        )
        .subscribe(enabledAudio => {
          this.handleSubscription(WarningBarStates.approvedAudio, enabledAudio);
        })
    );
  }
  /** subscribe to auto answer to know if user has auto answer activate */
  subscribeAutoAnswer(): void {
    this.unsubscriber.add(
      this.autoAnswerService.state.subscribe(state => {
        if (this.isChrome) this.handleSubscription(WarningBarStates.canAutoAnswer, state.enabled);
      })
    );
  }
  /** subscribe to subscription for app calling progress clearing */
  subscribeSubscription(): void {
    this.unsubscriber.add(
      combineLatest([
        this.identity.state.pipe(
          filter(state => state.addresses.length > 0),
          map(state => state.addresses),
          distinctUntilChanged()
        ),
        this.subscriptionControllerService.state
      ]).subscribe(([addresses, subscriptionState]) => {
        const dialogPresentities = subscriptionState.presentity.filter(
          pres => pres.event === "dialog"
        );

        addresses.forEach(address => {
          const uaDialogPresentities: Array<Presentity> = dialogPresentities.filter(
            pres => pres.aor === address.aor
          );

          if (uaDialogPresentities.length > 1) {
            console.error("More than 1 subscription for ua dialog info found for", address.aor);
          } else if (uaDialogPresentities.length === 0) {
            return;
          }

          const uaDialogInfo: ExtendedDialogInfo = uaDialogPresentities[0]
            .eventData as ExtendedDialogInfo;
          const callSetupDialogs: Array<PersonalDialog> = (uaDialogInfo.dialogs || []).filter(
            dialog => {
              return (
                (dialog.remoteAor && dialog.remoteAor.indexOf("call-setup@onsip.com") >= 0) ||
                (dialog.localAor && dialog.localAor.indexOf("call-setup@onsip.com") >= 0)
              );
            }
          );

          if (callSetupDialogs.some(dialog => dialog.state === "early")) {
            this.handleSubscription(WarningBarStates.callSetup, true);
          } else if (callSetupDialogs.some(dialog => dialog.state === "confirmed")) {
            this.handleSubscription(WarningBarStates.callSetup, false);
          } else if (callSetupDialogs.every(dialog => dialog.state === "terminated")) {
            this.handleSubscription(WarningBarStates.callSetup, false);
          }
        });
      })
    );
  }
  // this is a pseudo subscription, notification does not have state and is only done once by looking at the window.Notification.permission
  // this should be fixed and be subscribed to an actual notification state when the usersettings service gets refactor
  subscribeNotification(): void {
    this.unsubscriber.add(
      of(this.hasDeniedNotifications)
        .pipe(distinctUntilChanged())
        .subscribe(notify => {
          this.handleSubscription(WarningBarStates.deniedNotification, notify);
        })
    );
  }

  /** Do not disturb on if app calling is enabled and all shouldBeRegistered false */
  subscribeDoNotDisturb(): void {
    // wait for local storage values to be read so warning bar doesn't flash on login
    this.localStorageService.getDefaultUA().then(() => {
      this.unsubscriber.add(
        combineLatest([
          this.appCallingService.state,
          this.callControllerService.getDoNotDisturbObservable()
        ])
          .pipe(
            map(([appCallState, dnd]) => appCallState.enabled && dnd),
            distinctUntilChanged()
          )
          .subscribe(dnd => {
            this.handleSubscription(WarningBarStates.doNotDisturb, dnd);
          })
      );
    });
  }

  subscribeFreeTrial(): void {
    this.freeTrialService.state
      .pipe(pluck("showWarningBar"), distinctUntilChanged())
      .subscribe(freeTrial => {
        this.handleSubscription(WarningBarStates.freeTrial, freeTrial);
      });
  }

  subscribeExperimentalIOS(): void {
    const browserName = this.supportService.getBrowser().name;
    const experimentalIOS = browserName === "ios" || browserName === "crios";
    this.handleSubscription(WarningBarStates.experimentalIOS, experimentalIOS);
  }

  /** undo or revert whatever the warning state is */
  revertState() {
    const currentWarningName: WarningBarNames = this.stateValue[0].name;
    this.removeCurrentWarningState(); // saves a search to remove and just remove it before an update removal
    if (currentWarningName === "canAutoAnswer") {
      this.autoAnswerService.disable();
    } else if (currentWarningName === "approvedAudio") {
      this.callAudioService.approveAudio();
    } else if (currentWarningName === "globalAvailability") {
      this.globalPresenceService.enableGlobalPresence(true);
    } else if (currentWarningName === "appCallDisabled") {
      this.appCallingService.enable();
    } else if (currentWarningName === "callSetup") {
      this.handleSubscription(WarningBarStates.callSetup, false);
    } else if (currentWarningName === "doNotDisturb") {
      this.callControllerService.setDoNotDisturb(false);
      this.localStorageService.setDoNotDisturb(false);
    } else if (currentWarningName === "noE911Admin" || currentWarningName === "E911Admin") {
      this.router.navigate([views.E911_TABLE]);
    } else if (currentWarningName === "freeTrial") {
      this.router.navigate([views.CONTACT_SALES]);
    } else if (currentWarningName === "experimentalIOS") {
      window.open("macappstore://itunes.apple.com/app/id1253050184", "_blank");
    } else if (currentWarningName === "E911User") {
      this.router.navigate([views.APP_SETTINGS]);
    }
  }

  /** simply remove the first item in the array */
  removeCurrentWarningState(): void {
    this.stateStore.shift();
    this.publishState();
  }

  /** the opposite of removeCurrentWarningState, just keep the current state */
  removeAllOthers(): void {
    const currentState = this.stateStore.shift();
    if (currentState) this.stateStore = [currentState];
    this.publishState();
  }

  /** clears the array by resetting it to an empty array */
  clearWarningBarData(): void {
    this.stateStore = [];
    this.publishState();
  }

  /** helper function to check if warning state is already in the array */
  private doesExistInState(warningData: WarningBarData): boolean {
    return this.stateValue.some(state => state.name === warningData.name);
  }

  /** removes warning state from array regardless of position */
  private removeWarningState(warningData: WarningBarData): void {
    const index = this.stateStore.findIndex(state => state.name === warningData.name);
    this.stateStore.splice(index, 1);
    this.publishState();
  }

  /** adds the warning state to the array and then sort by priority with the higher priority/lower number being first in the array */
  private addNewWarningState(warningData: WarningBarData): void {
    this.stateStore.push(warningData);
    this.stateStore = this.stateStore.sort((a, b) => a.priority - b.priority);
    this.publishState();
  }

  /** if there is a warning message to display and it is not stored in the state, store it
   * and vice versa, if the situation has been clear and it is still stored, remove from state
   */
  private handleSubscription(warningData: WarningBarData, hasWarning: boolean): void {
    const existInArray = this.doesExistInState(warningData);
    if (hasWarning && !existInArray) this.addNewWarningState(warningData);
    if (!hasWarning && existInArray) this.removeWarningState(warningData);
  }

  private get selfUserAddress$() {
    return this.userAddress.selfUser.pipe(
      map(
        // we only assign E911 Location to the main user address and not its alias
        state =>
          state.length > 0
            ? state.sort((a, b) => parseInt(a?.userAddressId) - parseInt(b?.userAddressId))[0]
                .e911LocationId
            : ""
      )
    );
  }
}
