export type OnsipAPIBoolean = "true" | "false";
export function onsipApiBooleanToBoolean(onsipApiBoolean: OnsipAPIBoolean): boolean {
  return onsipApiBoolean === "true";
}
export function booleanToOnsipApiBoolean(boolean: boolean): OnsipAPIBoolean {
  return boolean ? "true" : "false";
}

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export type OnsipAPIEmptyArray = {};
export type OnsipAPINotEmptyArray<K extends string, T> = Pick<Record<string, T | Array<T>>, K>;
export type OnsipAPIArray<K extends string, T> = OnsipAPINotEmptyArray<K, T> | OnsipAPIEmptyArray;

/** You can use this in an intersection type (A & B) with OnsipApiArray when applicable */
export interface OnsipAPIBrowseAttributes {
  "@attributes": {
    /** string of a number */
    Limit: string;
    /** string of a number */
    Offset: string;
    /** string of a number */
    Found: string;
  };
}

export function arrayToOnsipApiArrayParameter<T>(
  arr: Array<T>,
  arrayParameterName: string
): Record<string, T> {
  return arr.reduce((acc: Record<string, T>, curr, i) => {
    acc[`${arrayParameterName}[${i}]`] = curr;
    return acc;
  }, {});
}

export function onsipApiArrayToArray<K extends string, T>(
  apiArray: OnsipAPIArray<K, T>,
  keyName: K
): Array<T> {
  if (isOnsipApiArrayNotEmpty(apiArray, keyName)) {
    if (Array.isArray(apiArray[keyName])) return apiArray[keyName] as Array<T>;
    else return [apiArray[keyName]] as Array<T>;
  } else {
    return [];
  }
}

function isOnsipApiArrayNotEmpty<K extends string, T>(
  apiArray: OnsipAPIArray<K, T>,
  keyName: K
): apiArray is OnsipAPINotEmptyArray<K, T> {
  return !(
    !(apiArray as OnsipAPINotEmptyArray<K, T>)[keyName] ||
    (apiArray as OnsipAPIBrowseAttributes)["@attributes"]?.Found === "0"
  );
}
