import { UserAgentEvent, UserAgentEventFactory } from "./user-agent-event";

/**
 * Emitted when the UserAgent recieves a voicemail message-summary notifcation.
 */
export class VoicemailMessageSummaryNotificationUserAgentEvent {
  /**
   * Factory function to make event.
   * @param aor AOR of UserAgent.
   */
  static make(aor: string): UserAgentEvent {
    return UserAgentEventFactory.make(VoicemailMessageSummaryNotificationUserAgentEvent.id, aor);
  }
  static get id(): string {
    return "[UserAgent] voicemailMessageSummaryNotification";
  }
}
