import {
  OnsipAPIBoolean,
  OnsipAPIArray,
  onsipApiArrayToArray,
  onsipApiBooleanToBoolean,
  booleanToOnsipApiBoolean
} from "../../apiResponse/xml-json";
import {
  ApiVoicemail,
  Voicemail,
  apiVoicemailToVoicemail,
  voicemailToApiVoicemail
} from "./voicemail";
import { arrayToRecord } from "../../util/arrayToRecord";
import {
  ApiFailoverAddress,
  FailoverAddress,
  apiFailoverAddressToAddress
} from "../../apiResponse/address";

export declare interface ApiAdvVoicemailbox {
  AccountId: string;
  AdvVoicemailboxId: string;
  AppId: string;
  AppAddress: {
    AppAddressId: string;
    AddressId: string;
  };
  Created: string;
  Domain: string;
  Email: string;
  EmailOptions?: {
    attach: "yes" | "yes-then-delete" | "no";
  };
  Mailbox: string;
  Modified: string;
  Mwi: string;
  Name: string;
  OrganizationId: string;
  Pager: string;
  Password: string;
  SayCallerId: OnsipAPIBoolean;
  SayDateTime: OnsipAPIBoolean;
  SayDuration: OnsipAPIBoolean;
  Timezone: string;
  TransferAddress: "" | ApiFailoverAddress;
  Voicemail: {
    Greetings?: Record<string, any>;
    Inbox: OnsipAPIArray<"Voicemail", ApiVoicemail>;
  };
}

export interface AdvVoicemailbox {
  accountId: string;
  addressId: string;
  appAddressId: string;
  advVoicemailboxId: string;
  appId: string;
  created: string;
  domain: string;
  email: string;
  emailOptions: {
    attach: "yes" | "yes-then-delete" | "no";
  };
  mailbox: string;
  modified: string;
  mwi: string;
  name: string;
  organizationId: string;
  pager: string;
  password: string;
  sayCallerId: boolean;
  sayDateTime: boolean;
  sayDuration: boolean;
  timezone: string;
  transferAddress?: FailoverAddress;
  voicemail: {
    greetings?: Record<string, any>;
    inbox: {
      voicemail?: Record<string, Voicemail>;
    };
  };
}

export function apiAdvVoicemailboxToAdvVoicemailbox(
  apiAdvVoicemailbox: ApiAdvVoicemailbox
): AdvVoicemailbox {
  return {
    accountId: apiAdvVoicemailbox.AccountId,
    addressId: apiAdvVoicemailbox.AppAddress.AddressId,
    appAddressId: apiAdvVoicemailbox.AppAddress.AppAddressId,
    advVoicemailboxId: apiAdvVoicemailbox.AdvVoicemailboxId,
    appId: apiAdvVoicemailbox.AppId,
    created: apiAdvVoicemailbox.Created,
    domain: apiAdvVoicemailbox.Domain,
    email: apiAdvVoicemailbox.Email,
    emailOptions: {
      attach: apiAdvVoicemailbox.EmailOptions?.attach || "no"
    },
    mailbox: apiAdvVoicemailbox.Mailbox,
    modified: apiAdvVoicemailbox.Modified,
    mwi: apiAdvVoicemailbox.Mwi,
    name: apiAdvVoicemailbox.Name,
    organizationId: apiAdvVoicemailbox.OrganizationId,
    pager: apiAdvVoicemailbox.Pager,
    password: apiAdvVoicemailbox.Password,
    sayCallerId: onsipApiBooleanToBoolean(apiAdvVoicemailbox.SayCallerId),
    sayDateTime: onsipApiBooleanToBoolean(apiAdvVoicemailbox.SayDateTime),
    sayDuration: onsipApiBooleanToBoolean(apiAdvVoicemailbox.SayDuration),
    timezone: apiAdvVoicemailbox.Timezone,
    transferAddress:
      apiAdvVoicemailbox.TransferAddress !== ""
        ? apiFailoverAddressToAddress(apiAdvVoicemailbox.TransferAddress)
        : undefined,
    voicemail: {
      greetings: apiAdvVoicemailbox.Voicemail.Greetings,
      inbox: {
        voicemail: arrayToRecord(
          onsipApiArrayToArray(apiAdvVoicemailbox.Voicemail.Inbox, "Voicemail").map(
            apiVoicemailToVoicemail
          ),
          "metadataId"
        )
      }
    }
  };
}

export function advVoicemailboxToApiAdvVoicemailbox(
  advVoicemailbox: AdvVoicemailbox
): ApiAdvVoicemailbox {
  return {
    AccountId: advVoicemailbox.accountId,
    AppAddress: {
      AppAddressId: advVoicemailbox.appAddressId,
      AddressId: advVoicemailbox.addressId
    },
    AdvVoicemailboxId: advVoicemailbox.advVoicemailboxId,
    AppId: advVoicemailbox.appId,
    Created: advVoicemailbox.created,
    Domain: advVoicemailbox.domain,
    Email: advVoicemailbox.email,
    EmailOptions: {
      attach: advVoicemailbox.emailOptions?.attach
    },
    Mailbox: advVoicemailbox.mailbox,
    Modified: advVoicemailbox.modified,
    Mwi: advVoicemailbox.mwi,
    Name: advVoicemailbox.name,
    OrganizationId: advVoicemailbox.organizationId,
    Pager: advVoicemailbox.pager,
    Password: advVoicemailbox.password,
    SayCallerId: booleanToOnsipApiBoolean(advVoicemailbox.sayCallerId),
    SayDateTime: booleanToOnsipApiBoolean(advVoicemailbox.sayDateTime),
    SayDuration: booleanToOnsipApiBoolean(advVoicemailbox.sayDuration),
    Timezone: advVoicemailbox.timezone,
    TransferAddress: advVoicemailbox.transferAddress
      ? {
          AddressId: advVoicemailbox.transferAddress.addressId,
          Domain: advVoicemailbox.transferAddress.domain,
          Name: advVoicemailbox.transferAddress.name,
          Type: advVoicemailbox.transferAddress.type,
          Username: advVoicemailbox.transferAddress.username
        }
      : "",
    Voicemail: {
      Greetings: advVoicemailbox.voicemail?.greetings,
      Inbox: {
        Voicemail: Object.keys(advVoicemailbox.voicemail?.inbox.voicemail || {}).map(key =>
          voicemailToApiVoicemail(
            (advVoicemailbox.voicemail?.inbox.voicemail as Record<string, Voicemail>)[key]
          )
        )
      }
    }
  };
}
