import { Injectable, NgZone } from "@angular/core";
import { Subscription } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { UserService } from "../api/resources/user/user.service";
import { UserCustomizationService } from "../api/resources/userCustomization/user-customization.service";
import { Auth as AuthFirebase } from "../../libraries/firebase/cloud/firebase/auth/auth";
import { LogService } from "../../services/logging";
import { Org } from "../../libraries/firebase/store/org";
import { StateEmitter } from "../../libraries/emitter/state-emitter";
import { User } from "../../libraries/firebase/store/user";

import { ApiSessionService } from "../api/api-session.service";
import { IdentityService } from "../identity.service";
import { Config } from "../../../common/config";
import { exponentialBackoff } from "../../libraries/exponential-backoff";
import { UserGoogleFirebaseTokenService } from "../api/resources/userGoogleFirebaseToken/user-google-firebase-token.service";

const debug = false;

export interface AuthState {
  oid: number | undefined;
  uid: number | undefined;
  aor: string | undefined;
  name: string | undefined;
  picture: string | undefined;
  roles: string | undefined;
  status: "signedIn" | "signedOut";
  title: string | undefined;
}

@Injectable({ providedIn: "root" })
export class AuthService extends StateEmitter<AuthState> {
  private auth: AuthFirebase | undefined;
  private unsubscriber = new Subscription();

  static initialState(): AuthState {
    return {
      status: "signedOut",
      oid: undefined,
      uid: undefined,
      aor: undefined,
      name: undefined,
      picture: undefined,
      roles: undefined,
      title: undefined
    };
  }

  static didAuthStatusChange(
    priorAuthState: AuthState | undefined,
    currentAuthState: AuthState
  ): "noChange" | "signedIn" | "signedOut" {
    const priorStatus = priorAuthState ? priorAuthState.status : "init";
    if (currentAuthState.status === priorStatus) {
      return "noChange";
    }
    return currentAuthState.status;
  }

  constructor(
    private log: LogService,
    private identity: IdentityService,
    private userService: UserService,
    private apiSession: ApiSessionService,
    private userCustomization: UserCustomizationService,
    private zone: NgZone,
    private userGoogleFirebaseTokenService: UserGoogleFirebaseTokenService
  ) {
    super(AuthService.initialState());
    this.init();
  }

  dispose(): void {
    this.publishStateComplete();
    this.unsubscriber.unsubscribe();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(fn);
  }

  private init() {
    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.stateStore.name = user.contact.name;
      })
    );
    this.unsubscriber.add(
      this.userCustomization.selfUser.subscribe(userCustom => {
        const picture = userCustom.userAvatarUrl;
        const title = userCustom.userInfo?.personalPageInfo.title;
        if (picture) this.stateStore.picture = picture;
        if (title) this.stateStore.title = title;
      })
    );

    this.subscribeUserAgent();
  }

  private getToken(): Promise<string> {
    return this.userGoogleFirebaseTokenService
      .userGoogleFirebaseTokenRead({ Environment: Config.FIREBASE_ENVIRONMENT })
      .then(response => {
        if (response.status === "success") {
          const token: string = Object.values(response.data)[0].googleFirebaseToken;
          debug && console.log("Token = " + token);
          return token;
        } else {
          throw new Error("Failed to get token.");
        }
      });
  }

  private signedIn(): Promise<void> {
    this.log.debug("Auth.signedIn");
    return this.updateOrgAndUserStore();
  }

  private signedOut(): void {
    this.log.debug("Auth.signedOut");
    // get a new token and attempt to authenticate
    this.getToken()
      .then(token => {
        const signIn = () => {
          if (!this.auth) return Promise.reject("AuthService.SignedOut: Auth is undefined");
          return this.auth.signIn(token);
        };
        return exponentialBackoff(
          signIn,
          ["unavailable"],
          "AuthService.SignIn",
          this.log,
          false,
          Config.IS_MOBILE,
          5
        ).then(() => this.publishState());
      })
      .catch(error => {
        // Error will be object with code + message according to docs
        this.log.error("AuthService.SignedOut: failed with - " + JSON.stringify(error));
        throw error;
      });
  }

  private subscribeAuth(): void {
    this.auth = new AuthFirebase(); // moving this to the constructor breaks the app; invstigation needed
    this.unsubscriber.add(
      this.auth.state.subscribe({
        next: state => {
          switch (state.status) {
            case "init":
              break;
            case "signedIn":
              // wait for extra claims
              if (state.oid && state.uid) {
                this.stateStore.status = state.status;
                this.stateStore.oid = toId(state.oid);
                this.stateStore.uid = toId(state.uid);
                this.stateStore.roles = state.roles;
                exponentialBackoff(
                  this.signedIn.bind(this),
                  ["unavailable"],
                  "AuthService.SignedIn",
                  this.log,
                  false,
                  Config.IS_MOBILE,
                  5
                ).then(() => this.publishState());
              }
              break;
            case "signedOut":
              this.stateStore.status = state.status;
              this.stateStore.oid = toId(state.oid);
              this.stateStore.uid = toId(state.uid);
              this.stateStore.roles = state.roles;
              this.signedOut();
              this.publishState();
              break;
            default:
              throw new Error("Unknown auth state status.");
          }
        },
        error: (error: unknown) => {
          this.log.error("AuthService.SubscribeAuth: " + error);
          throw error;
        },
        complete: () => {
          this.publishStateComplete();
        }
      })
    );
  }

  private subscribeUserAgent(): void {
    let defaultAor: string | undefined;
    let firstPostLogin = true;
    // aor and name always set to addresses[0]
    this.unsubscriber.add(
      this.identity.state
        .pipe(distinctUntilChanged((a, b) => a.defaultIdentity?.aor === b.defaultIdentity?.aor))
        .subscribe(state => {
          if (!state.addresses[0]) {
            this.stateStore.aor = undefined;
            this.stateStore.name = undefined;
            this.publishState();
          } else if (defaultAor !== state.addresses[0].aor) {
            this.stateStore.aor = state.addresses[0].aor;
            this.stateStore.name = state.addresses[0].name;
            if (firstPostLogin) {
              firstPostLogin = false;
              this.subscribeAuth();
            } else {
              this.auth?.signOut();
            }
            this.publishState();
          }
          defaultAor = state.defaultIdentity ? state.defaultIdentity.aor : undefined;
        })
    );

    // Mobile specific. Web/Desktop is always "logged in" (since they bypass the app service to login)
    // We need to sign out of Firebase if we are no longer logged in (Hit log out button)
    this.unsubscriber.add(
      this.apiSession.state
        .pipe(
          map(state => state.loggedIn),
          distinctUntilChanged()
        )
        .subscribe(loggedIn => {
          if (!loggedIn) {
            this.auth && this.auth.signOut();
            this.auth && this.auth.dispose();
            firstPostLogin = true;
          }
        })
    );
  }

  private updateOrgAndUserStore(): Promise<void> {
    if (!this.stateStore.oid) throw new Error("oid undefined.");
    if (!this.stateStore.uid) throw new Error("uid undefined.");
    if (!this.stateStore.aor) throw new Error("aor undefined.");
    if (!this.stateStore.name) throw new Error("name undefined.");
    const oid = this.stateStore.oid;
    const uid = this.stateStore.uid;
    const aor = this.stateStore.aor;
    const name = this.stateStore.name;

    // So we cannot write undefined to firestore. Have to use null instead
    // eslint-disable-next-line no-null/no-null
    const picture: any = this.stateStore.picture ? this.stateStore.picture : null;
    // eslint-disable-next-line no-null/no-null
    const title: any = this.stateStore.title ? this.stateStore.title : null;
    // chain org setOrUpdate with user setOrUpdate so org always executes first
    // create org document if it doesn't exist (it needs to exist for calls to be created)
    return (
      Org.get(oid)
        .then(data => {
          if (data === undefined) {
            return Org.setOrUpdate(oid, { topics: {} }, {});
          }
        })
        // create user document if it doesn't exist, otherwise update it
        .then(() =>
          User.setOrUpdate(
            oid,
            uid,
            { aor, availability: "undefined", name, topics: [], picture, title },
            { aor, name, picture, title }
          )
        )
    );
  }
}

function toId(val: string | undefined): number | undefined {
  if (val === undefined) return undefined;
  const i = toInt(val);
  return i === undefined || i < 1 ? undefined : i;
}

function toInt(val: string): number | undefined {
  const parsed = parseInt(val, 10);
  if (isNaN(parsed)) {
    return undefined;
  }
  return parsed;
}
