declare let window: any;

import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { LogService } from "../../../../../common/services/logging";
import { CallControllerService } from "../../../../../common/services/call-controller.service";
import { SupportService } from "../../../../../common/services/support/support.service";
import { isEndCallEvent } from "../../../../../common/libraries/sip/call-event";
import { CallState } from "../../../../../common/libraries/sip/call-state";

export interface WebAudioWrapper {
  localAudioDestination: MediaStreamAudioDestinationNode;
  localAudioSource: MediaStreamAudioSourceNode;
  remoteAudioSource?: MediaStreamAudioSourceNode;
  remoteAudioGain: GainNode;
  gumStream: MediaStream;
}
@Injectable({ providedIn: "root" })
export class WebAudioService {
  private contextUsers = 0;
  private audioContext: AudioContext | undefined;
  private wrappers: Record<string, WebAudioWrapper | undefined> = {}; // uuid => wrapper

  constructor(
    private log: LogService,
    private callControllerService: CallControllerService,
    private supportService: SupportService
  ) {
    if (!(window.AudioContext || window.webkitAudioContext)) {
      this.log.warn("WebAudio API is not supported, some functionality may not work.");
    } else {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.audioContext && this.audioContext.suspend && this.audioContext.suspend();
    }

    this.callControllerService
      .getCallEventObservable()
      .pipe(filter(isEndCallEvent))
      .subscribe(event => {
        this.destroyWrapper(event.uuid);
      });
  }

  createMediaStreamDestination(): MediaStreamAudioDestinationNode | undefined {
    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }

    this.useAudioContext();
    const ret = this.audioContext.createMediaStreamDestination();
    this.releaseAudioContext();

    return ret;
  }

  createMediaStreamSource(stream: MediaStream): MediaStreamAudioSourceNode | undefined {
    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }

    this.useAudioContext();
    const ret = this.audioContext.createMediaStreamSource(stream);
    this.releaseAudioContext();

    return ret;
  }

  safeDisconnect(
    audioNode: MediaStreamAudioDestinationNode | MediaStreamAudioSourceNode | GainNode
  ): void {
    if (audioNode && audioNode.numberOfOutputs > 0) {
      audioNode.disconnect();
    }
  }

  hasCapableAudioContext(): boolean {
    // disabled in Chrome until https://bugs.chromium.org/p/chromium/issues/detail?id=605576 is resolved
    return (
      this.supportService.getBrowser().name === "firefox" &&
      !!this.audioContext &&
      !!this.audioContext.createMediaStreamDestination &&
      !!this.audioContext.createMediaStreamSource
    );
  }

  createWrapper(callId: string, stream: MediaStream): WebAudioWrapper | undefined {
    const call: CallState | undefined = this.callControllerService.getCallStateByCallId(callId);

    // since video tracks need to be appended later, FF >= 47 spits out a 'not supported' error
    if (!this.hasCapableAudioContext() || !call || stream.getVideoTracks().length > 0) {
      return;
    }

    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }
    this.useAudioContext();

    const webAudioWrapper: WebAudioWrapper = {
      localAudioDestination: this.audioContext.createMediaStreamDestination(),
      localAudioSource: this.audioContext.createMediaStreamSource(stream),
      remoteAudioGain: this.audioContext.createGain(),
      gumStream: stream
    };

    // track manipulation was attempted here instead of localAudioSource,
    // but firefox didn't work with it; the video line below does work in FF though

    if (stream.getVideoTracks().length > 0) {
      webAudioWrapper.localAudioDestination.stream.addTrack(stream.getVideoTracks()[0]);
    }

    webAudioWrapper.localAudioSource.connect(webAudioWrapper.localAudioDestination);

    this.wrappers[call.uuid] = webAudioWrapper;

    return webAudioWrapper;
  }

  getWrapper(uuid: string): WebAudioWrapper | undefined {
    return this.wrappers[uuid];
  }

  removeWrapperSource(uuid: string): void {
    const wrapper: WebAudioWrapper | undefined = this.getWrapper(uuid);

    if (!wrapper) {
      return;
    }
    wrapper.remoteAudioSource = undefined;
    this.wrappers[uuid] = wrapper;
  }

  createTones(freqArray: Array<number>): Array<GainNode> {
    if (!this.audioContext || !this.audioContext.createOscillator) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return [];
    }

    return (freqArray || []).map(freq => this.makeTone(freq));
  }

  connectToContext(tone: GainNode, toneVolume: number): void {
    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }

    this.useAudioContext();

    tone.gain.linearRampToValueAtTime(toneVolume / 100, this.audioContext.currentTime + 0.1);
    tone.connect(this.audioContext.destination);
  }

  disconnectFromContext(tone: GainNode): void {
    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }

    tone.disconnect(this.audioContext.destination);
    this.releaseAudioContext();
  }

  playCallWaitingTone(toneVolume: number): void {
    if (!this.audioContext) {
      this.log.error("WebAudio API not supported. Call waiting tone cannot be played");
      return;
    }

    this.useAudioContext();

    const gain = this.makeTone(440);

    gain.connect(this.audioContext.destination);
    gain.gain.value = toneVolume;
    gain.gain.setValueAtTime(0, this.audioContext.currentTime + 0.3); // off after .3 seconds

    setTimeout(() => {
      gain.disconnect();
      this.releaseAudioContext();
    }, 300);
  }

  private destroyWrapper(uuid: string): void {
    const wrapper: WebAudioWrapper | undefined = this.wrappers[uuid];

    if (!wrapper) {
      return;
    }

    this.safeDisconnect(wrapper.localAudioSource);
    this.safeDisconnect(wrapper.localAudioDestination);
    this.safeDisconnect(wrapper.remoteAudioGain);
    if (wrapper.remoteAudioSource) {
      this.safeDisconnect(wrapper.remoteAudioSource);
    }

    this.releaseAudioContext();
    wrapper.gumStream.getTracks().forEach(track => track.stop());
    this.wrappers[uuid] = undefined;
  }

  private useAudioContext(): void {
    if (this.contextUsers === 0) {
      this.log.debug("resuming audioContext");
      this.audioContext && this.audioContext.resume && this.audioContext.resume();
    }
    this.contextUsers++;
    this.log.debug("use audioContext called, count is", this.contextUsers);
  }

  private releaseAudioContext(): void {
    this.contextUsers--;
    this.log.debug("release audioContext called, count is", this.contextUsers);

    if (this.contextUsers <= 0) {
      this.contextUsers = 0;
      this.log.debug("suspending audioContext");
      this.audioContext && this.audioContext.suspend && this.audioContext.suspend();
    }
  }

  private makeTone(frequency: number): GainNode {
    if (!this.audioContext) {
      // Everything that runs this has already checked, this will never be hit. It's for typing.
      throw new Error("No AudioContext");
    }
    const tone: OscillatorNode = this.audioContext.createOscillator(),
      gain: GainNode = this.audioContext.createGain();

    tone.frequency.value = frequency;
    tone.start(0);
    tone.connect(gain);

    return gain;
  }
}
