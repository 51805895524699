import { Subject } from "rxjs";
import { RegistererOptions, UserAgentOptions, Message, Grammar, URI, Web } from "sip.js";

import { EventStateEmitter } from "../emitter/event-state-emitter";

import { Session } from "./session";
import { log } from "./log";
import { UUID } from "./uuid";
import {
  ConnectedUserAgentEvent,
  ConnectingUserAgentEvent,
  DisconnectedUserAgentEvent,
  RegisteredUserAgentEvent,
  UnregisteredUserAgentEvent,
  UserAgentEvent,
  VoicemailMessageSummaryNotificationUserAgentEvent
} from "./user-agent-event";
import { UserAgents } from "./user-agents";

//
// Delegate
//

/** Defines the UserAgentDelegate. */
export interface UserAgentDelegate {
  /**
   * Handle an incoming Message.
   * @param message Incoming Message
   */
  handleIncomingMessage(message: Message): void;
  /**
   * Handle an incoming Session.
   * This is called before the incoming Session is activated (before NewSessionEvent is emmitted).
   * Subscribe to the Session and wait for activation before attempting to control the Session.
   * For example, do not call accept() or reject() on the Session prior to activation as that will fail.
   * Furthmore, upon this method returning the Session will be activated so any subscriptions which wish
   * to receive activation related notifications (NewSessionEvent for example) must be done herein.
   * @param session Incoming Session (not activated)
   */
  handleIncomingSession(session: Session): void;
}

//
// State
//
// TODO: add children to state?

/**
 * Defines the UserAgent state.
 */
export interface UserAgentState {
  /** The AOR of the UserAgent. */
  aor: string;
  /** The display name fo the UserAgent. */
  displayName: string;
  /** True if the UserAgent is connected. */
  connected: boolean;
  /** When the UserAgent last connected - epoch time in milliseconds. */
  connectedAt: number | undefined;
  /** The SIP server the UserAgent is connected to. */
  connectedTo: string | undefined;
  /** True if the UserAgent is connecting. */
  connecting: boolean;
  /** True if the UserAgent is registered. */
  registered: boolean;
  /** True if we want the UserAgent to be registered. */
  shouldBeRegistered: boolean;
  /** When the UserAgent last registered - epoch time in milliseconds. */
  registeredAt: number | undefined;
  /** When the UserAgent's registration expires - epoch time in milliseconds. */
  registerExpiresAt: number | undefined;
  /** true if purposefully stopped temporarily */
  stopped: boolean;
}

//
// Object
//

export function cloneRegisterOptions(
  registerOptions: Partial<RegistererOptions>
): Partial<RegistererOptions> {
  const options = {
    expires: registerOptions.expires,
    extraContactHeaderParams: registerOptions.extraContactHeaderParams
      ? [...registerOptions.extraContactHeaderParams]
      : undefined,
    extraHeaders: registerOptions.extraHeaders ? [...registerOptions.extraHeaders] : undefined,
    instanceId: registerOptions.instanceId,
    logConfiguration: registerOptions.logConfiguration,
    regId: registerOptions.regId,
    registrar: registerOptions.registrar
  };

  /**
   * Strip properties with undefined values from options.
   * This is a work around while waiting for missing vs undefined to be addressed (or not)...
   * https://github.com/Microsoft/TypeScript/issues/13195
   * @param options - Options to reduce
   */
  return Object.keys(options).reduce((object, key) => {
    if ((options as any)[key] !== undefined) {
      (object as any)[key] = (options as any)[key];
    }
    return object;
  }, {});
}

export function cloneUserAgentOptions(
  userAgentOptions: Partial<UserAgentOptions>
): Partial<UserAgentOptions> {
  const options = {
    allowLegacyNotifications: userAgentOptions.allowLegacyNotifications,
    authorizationPassword: userAgentOptions.authorizationPassword,
    authorizationUsername: userAgentOptions.authorizationUsername,
    autoStop: userAgentOptions.autoStop,
    delegate: userAgentOptions.delegate, // TODO: deep clone
    displayName: userAgentOptions.displayName,
    forceRport: userAgentOptions.forceRport,
    // eslint-disable-next-line deprecation/deprecation
    hackViaTcp: userAgentOptions.hackViaTcp, // needed for polycom phones, which blow up on ws
    logBuiltinEnabled: userAgentOptions.logBuiltinEnabled,
    logConfiguration: userAgentOptions.logConfiguration,
    logConnector: userAgentOptions.logConnector,
    logLevel: userAgentOptions.logLevel,
    noAnswerTimeout: userAgentOptions.noAnswerTimeout,
    preloadedRouteSet: userAgentOptions.preloadedRouteSet
      ? [...userAgentOptions.preloadedRouteSet]
      : undefined,
    sessionDescriptionHandlerFactory: userAgentOptions.sessionDescriptionHandlerFactory,
    sessionDescriptionHandlerFactoryOptions:
      userAgentOptions.sessionDescriptionHandlerFactoryOptions, // TODO: deep clone
    sipExtension100rel: userAgentOptions.sipExtension100rel,
    sipExtensionReplaces: userAgentOptions.sipExtensionReplaces,
    sipExtensionExtraSupported: userAgentOptions.sipExtensionExtraSupported
      ? [...userAgentOptions.sipExtensionExtraSupported]
      : undefined,
    sipjsId: userAgentOptions.sipjsId,
    transportConstructor: userAgentOptions.transportConstructor,
    transportOptions: userAgentOptions.transportOptions, // TODO: deep clone
    uri: userAgentOptions.uri ? userAgentOptions.uri.clone() : undefined,
    userAgentString: userAgentOptions.userAgentString,
    viaHost: userAgentOptions.viaHost
  };

  /**
   * Strip properties with undefined values from options.
   * This is a work around while waiting for missing vs undefined to be addressed (or not)...
   * https://github.com/Microsoft/TypeScript/issues/13195
   * @param options - Options to reduce
   */
  return Object.keys(options).reduce((object, key) => {
    if ((options as any)[key] !== undefined) {
      (object as any)[key] = (options as any)[key];
    }
    return object;
  }, {});
}

/**
 * A SIP User Agent
 */
export class UserAgent extends EventStateEmitter<UserAgentEvent, UserAgentState> {
  uuid: UUID = UUID.randomUUID();

  protected configuration: UserAgentOptions;
  protected registerOptions: RegistererOptions;
  protected children: UserAgents = new UserAgents();
  protected parent: UserAgent | undefined;
  protected unsubscribe: Subject<void> = new Subject<void>();

  private _aor: string;
  private _displayName: string;
  private _connected = false;
  private _connectedAt: Date | undefined;
  private _connectedTo: string | undefined;
  private _connecting = true;
  private _registered = false;
  private _shouldBeRegistered = true;
  private _registeredAt: Date | undefined;
  private _numRegistrations = 0;

  /**
   * Sets initial UserAgent state. Disconnected. Unregistered.
   * @param aor AOR of UserAgent
   * @param displayName Display name of UserAgent
   */
  private static initialState(uri?: URI, displayName?: string): UserAgentState {
    if (!uri) {
      uri = Grammar.URIParse(
        "sip:anonymous." + Math.round(Math.random() * 1000000 - 0.5) + "@anonymous.invalid"
      );
      if (!uri) {
        throw new Error("anonymous URI creation failed.");
      }
    }
    if (!displayName) {
      displayName = "";
    }
    return {
      aor: uri.aor,
      displayName,
      connected: false,
      connectedAt: undefined,
      connectedTo: undefined,
      connecting: false,
      registered: false,
      shouldBeRegistered: true,
      registeredAt: undefined,
      registerExpiresAt: undefined,
      stopped: false
    };
  }

  /**
   * Creates a new UserAgent.
   * @param configuration The UserAgent's configuration.
   * @param delegate The UserAgent's delegate to handling incoming sessions.
   */
  constructor(
    configuration: UserAgentOptions,
    registerOptions: RegistererOptions,
    protected delegate: UserAgentDelegate | undefined
  ) {
    super(UserAgent.initialState(configuration.uri, configuration.displayName));
    log.debug(`UserAgent[${this.stateStore.aor}].constructor`);
    this.configuration = cloneUserAgentOptions(configuration); // copy to avoid it getting mucked with
    this.registerOptions = cloneRegisterOptions(registerOptions);
    this._aor = this.stateStore.aor;
    this._displayName = this.stateStore.displayName;
  }

  /**
   * Disposes of the UserAgent.
   * @param delay The number of seconds to wait before stopping the user agent
   * (provides it time to finish handling repsonses to outstanding requests). Defaults to 2 seconds.
   */
  dispose(delay: number = 2000): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      log.debug(`UserAgent[${this.aor}].dispose ${this.parent ? "(child)" : "(parent)"}`);
      log.debug(
        "UserAgent.dispose NOTE: stop will be called at the end of this, that log will say (parent), true value is ^"
      );
      this.unsubscribe.next();
      this.unsubscribe.complete();
      this.parent && this.parent.childRemove(this);
      this.children.array.forEach(userAgent => userAgent.dispose());
      const timer = setInterval(() => {
        this.stop().then(resolve).catch(reject);
        clearInterval(timer);
      }, delay);
    });
  }

  onDispose(disposeFunction: () => void): void {
    this.unsubscribe.subscribe(disposeFunction);
  }

  refresh(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends an invitation to join a new session.
   * @param session The session to join.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  invite(session: Session): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Sends a message.
   * @param uri The URI identifying the Message peer.
   * @param body The body of the Message.
   * @param contentType The contentType of Message.
   * @param headers headers to add (can be omitted)
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  message(uri: URI, body: string, contentType: string, headers: Array<string> = []): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Registers the UserAgent.
   */
  register(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Starts the UserAgent.
   */
  start(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Reconnects the UserAgent.
   */
  reconnect(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Stops the UserAgent.
   */
  stop(): Promise<void> {
    return Promise.resolve();
  }

  /**
   * Returns SIPURI for target. Returns undefind if target does not parse.
   * @param target The target.
   */
  targetToURI(target: string): URI | undefined {
    const uauri = Grammar.URIParse("sip:" + this.aor);
    if (!uauri) {
      throw Error("Unable to parse user agent aor");
    }
    const domain = uauri.host;
    let uri: URI | undefined;
    if (target.indexOf("@") !== -1) {
      uri = Grammar.URIParse(target) || Grammar.URIParse("sip:" + target);
    } else {
      uri = Grammar.URIParse("sip:" + target + "@" + domain);
    }
    return uri;
  }

  /**
   * Unregisters the UserAgent.
   */
  unregister(): Promise<void> {
    return Promise.resolve();
  }

  hasParent(): boolean {
    return !!this.parent;
  }

  getClonedConfiguration(): UserAgentOptions {
    return cloneUserAgentOptions(this.configuration);
  }

  getClonedRegisterOptions(): RegistererOptions {
    return cloneRegisterOptions(this.registerOptions);
  }

  getUnsubscribeSubject(): Subject<void> {
    return this.unsubscribe;
  }

  getDelegateForPush(): UserAgentDelegate | undefined {
    return this.delegate;
  }

  childAdd(child: UserAgent): void {
    child.parent = this;
    this.children.add(child);
  }

  /** The AOR of the UserAgent. */
  get aor(): string {
    return this._aor;
  }

  /** True if the UserAgent is connected. */
  get connected(): boolean {
    return this._connected;
  }

  /** The number of registrations tied to the sip address. */
  get numRegistrations(): number {
    return this._numRegistrations;
  }

  /** The SIP server the UserAgent is connected to. */
  get connectedTo(): string | undefined {
    return this._connectedTo;
  }

  /** The display name of the UserAgent. */
  get displayName(): string {
    return this._displayName;
  }

  /** True if the UserAgent is supposed to be registered. */
  get shouldBeRegistered(): boolean {
    return this._shouldBeRegistered;
  }

  set shouldBeRegistered(value: boolean) {
    this._shouldBeRegistered = value;
    this.publishState();
  }

  /** True if the UserAgent is registered. */
  get registered(): boolean {
    return this._registered;
  }

  protected childRemove(child: UserAgent): void {
    child.parent = undefined;
    this.children.remove(child);
  }

  /**
   * Connecting -> Connected
   */
  protected onConnect(): void {
    // if already connected, do nothing
    if (this._connected && !this._connecting) {
      return;
    }

    this._connected = true;
    this._connecting = false;
    this._connectedTo = this.configuration.transportOptions
      ? (this.configuration.transportOptions as Web.TransportOptions).server
      : undefined;
    this._connectedAt = new Date();
    this.publishState();
    this.publishEvent(ConnectedUserAgentEvent.make(this.aor));
  }

  /**
   * Disconnected -> Connecting
   */
  protected onConnecting(): void {
    // if already connecting, do nothing
    if (!this._connected && this._connecting) {
      return;
    }
    // if not disconnected, fake it
    if (this._connected) {
      this.onDisconnect();
    }
    this._connected = false;
    this._connecting = true;
    this._connectedAt = undefined;
    this._connectedTo = undefined;
    this.publishState();
    this.publishEvent(ConnectingUserAgentEvent.make(this.aor));
  }

  /**
   * Connected -> Disconnected
   * Connecting -> Disconnected
   */
  protected onDisconnect(): void {
    // if already disconnected, do nothing
    if (!this._connected && !this._connecting) {
      return;
    }
    // if not unregistered, fake it
    if (this._registered) {
      this.onUnregistered("Disconnected");
    }
    this._connected = false;
    this._connecting = false;
    this._connectedAt = undefined;
    this._connectedTo = undefined;
    this.publishState();
    this.publishEvent(DisconnectedUserAgentEvent.make(this.aor));
  }

  /**
   * Unregistered -> Registered
   * Registered -> Registered
   */
  protected onRegistered(numRegistrations: number): void {
    this._registered = true;
    this._registeredAt = new Date();
    this._numRegistrations = numRegistrations;
    this.publishState();
    this.publishEvent(RegisteredUserAgentEvent.make(this.aor));
  }

  /**
   * Registered -> Unregistered
   * Unregistered -> Unregistered
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onUnregistered(cause: string): void {
    this._registered = false;
    this._registeredAt = undefined;
    this.publishState();
    this.publishEvent(UnregisteredUserAgentEvent.make(this.aor));
  }

  /**
   * On a voicemail notification.
   * @param body The body of the NOTIFY
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected onVoicemailMessageSummaryNotification(body: string): void {
    // this does not change the user agent state (purely an event)
    this.publishEvent(VoicemailMessageSummaryNotificationUserAgentEvent.make(this.aor));
  }

  protected flushState() {
    super.flushState();
    this.stateStore.aor = this._aor;
    this.stateStore.displayName = this._displayName;
    this.stateStore.connected = this._connected;
    this.stateStore.connectedAt = this._connectedAt ? this._connectedAt.getTime() : undefined;
    this.stateStore.connectedTo = this._connectedTo;
    this.stateStore.connecting = this._connecting;
    this.stateStore.shouldBeRegistered = this._shouldBeRegistered;
    this.stateStore.registered = this._registered;
    this.stateStore.registeredAt = this._registeredAt ? this._registeredAt.getTime() : undefined;
    const registerExpires: number =
      this.registerOptions && this.registerOptions.expires ? this.registerOptions.expires : 600;
    this.stateStore.registerExpiresAt = this.stateStore.registeredAt
      ? this.stateStore.registeredAt + registerExpires
      : undefined;
  }
}
