import { Injectable, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { StateEmitter } from "../../../../common/libraries/emitter/state-emitter";
import { ConfigurationFileService } from "../../../../common/services/configuration-file.service";
import { OrganizationConfigurationFileSetService } from "@onsip/common/services/api/resources/organizationConfigurationFileSet/organization-configuration-file-set.service";
import {
  OrganizationConfiguration,
  ButtonConfigurationMetadata,
  Configuration,
  ButtonConfigurationFilters,
  CONFIG_SCHEMA_VERSION,
  EditableConfiguration
} from "../../../../common/interfaces/organization-configuration";
export {
  OrganizationConfiguration,
  Configuration,
  ButtonConfigurationMetadata,
  ButtonConfigurationFilters
};

export const DEFAULT_COLORS: Array<string> = [
  "#01c8a1",
  "#3248d0",
  "#6b80ff",
  "#c472d5",
  "#9013fe",
  "#04c9df"
];
export const DEFAULT_ACCENT_COLOR: string = DEFAULT_COLORS[2];
export const DEFAULT_BUTTON_COLOR: string = DEFAULT_COLORS[0];

const debug = false;

export type ConfigurationPatch = Pick<Configuration, "targetDisplay" | "video">;

export interface ConfigurationServiceState {
  orgConfig: OrganizationConfiguration;
}

@Injectable({ providedIn: "root" })
export class ConfigurationService
  extends StateEmitter<ConfigurationServiceState>
  implements OnDestroy
{
  private unsubscriber = new Subscription();

  /** Marks documentVersion for incrementing in flushState. This is to ensure the documentVersion is never incremented twice in one state update. */
  private willIncrementDocumentVersion = false;

  private static initialState(): ConfigurationServiceState {
    return {
      orgConfig: {} as OrganizationConfiguration
    };
  }

  constructor(
    private configFileService: ConfigurationFileService,
    private orgConfigFileSetService: OrganizationConfigurationFileSetService
  ) {
    super(ConfigurationService.initialState());
    this.init();
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }

  //////////////
  // Read API //
  //////////////

  /** Return document version of current configurations */
  get documentVersion(): number {
    return this.stateValue.orgConfig.documentVersion;
  }

  ///////////////
  // Write API //
  ///////////////

  /** Edit a config property allowed by the interface EditableConfiguration which is a subset of OrganizationConfiguration
   * This function does the api write and then saves the key value pair to the stateStore
   */
  editConfigProperty<T extends keyof EditableConfiguration>(
    property: T,
    value: OrganizationConfiguration[T]
  ): Promise<void> {
    if (!this.stateValue.orgConfig.organizationId) {
      throw new Error(
        "ConfigurationService.editConfigProperty - cannot find organizationId from config"
      );
    }
    return this.orgConfigFileSetService
      .organizationConfigurationFileSet({ [property]: value })
      .then(() => {
        debug &&
          console.log(`ConfigurationService.editConfigProperty: successfully modified org config`);
        this.stateStore.orgConfig[property] = value;
        this.publishState();
      });
  }

  // we need a way to update the document version for any topic add or edit
  updateDocumentVersion(): void {
    this.willIncrementDocumentVersion = true;
    this.publishState();
  }

  //////////////////
  // Internal API //
  //////////////////

  protected flushState(): void {
    if (this.willIncrementDocumentVersion) {
      this.willIncrementDocumentVersion = false;
      this.stateStore.orgConfig.documentVersion++;
    }
  }

  private init(): void {
    this.unsubscriber.add(
      this.configFileService.getOrgConfig().subscribe(orgConfig => {
        debug && console.log(`ConfigurationService.init: has orgConfig: [${!!orgConfig}]`);
        if (!orgConfig) {
          // this occurs when the user is not an admin, we should not complete initialization
          return;
        }

        this.stateStore.orgConfig = orgConfig;

        // Handle default cases here:
        this.stateStore.orgConfig.documentVersion = orgConfig.documentVersion || 1;
        this.stateStore.orgConfig.schemaVersion = orgConfig.schemaVersion || CONFIG_SCHEMA_VERSION;
        this.stateStore.orgConfig.accentColor = orgConfig.accentColor || DEFAULT_ACCENT_COLOR;
        this.stateStore.orgConfig.buttonColor = orgConfig.buttonColor || DEFAULT_BUTTON_COLOR;

        this.publishState();
      })
    );
  }
}
