declare let window: any;

import { Config } from "../../../../../common/config";
import { LocalStorageService } from "../../../shared/services/localStorage/local-storage.service";
import { IdentityService } from "../../../../../common/services/identity.service";
import { CallControllerService } from "../../../../../common/services/call-controller.service";

import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { Session } from "../../../../../common/libraries/sip/session";
import {
  ReferredSessionEvent,
  ReplacedSessionEvent
} from "../../../../../common/libraries/sip/session-event";
import {
  isConnectedCallEvent,
  isEndCallEvent,
  isNewCallEvent,
  EndCallEvent
} from "../../../../../common/libraries/sip/call-event";
import { CallState } from "../../../../../common/libraries/sip/call-state";
import { UserAddress } from "../../../../../common/services/api/resources/userAddress/user-address";
import { StateEmitter } from "../../../../../common/libraries/emitter";
import { NotificationTypes } from "../../../../../common/interfaces/notification-types";

interface NotificationState {
  notifications: Record<string, boolean>;
}

@Injectable({ providedIn: "root" })
export class NotificationsService extends StateEmitter<NotificationState> {
  private notificationTimeout = 5000;
  private removeFuncs: Record<string, (() => void) | undefined> = {}; // uuid => removeIncomingNotify

  constructor(
    private localStorageService: LocalStorageService,
    private identityService: IdentityService,
    private callControllerService: CallControllerService
  ) {
    super({
      notifications: {}
    });

    this.platformInitNotifications();

    this.callControllerService.getCallEventObservable().subscribe(event => {
      if (isNewCallEvent(event)) {
        const call: CallState | undefined = this.callControllerService.getCallStateByUuid(
          event.uuid
        );
        if (call && call.incoming) {
          this.removeFuncs[event.uuid] = this.notifyOnIncoming(call);
        }

        const session: Session | undefined = this.callControllerService.findSession(event.uuid);

        if (session) {
          this.setSessionReferSubscription(event.uuid, session);
        }
      } else if (isEndCallEvent(event) || isConnectedCallEvent(event)) {
        const remove: (() => void) | undefined = this.removeFuncs[event.uuid];

        if (isEndCallEvent(event)) {
          this.notifyOnHangup(event);
        }
        if (remove) {
          remove();
          this.removeFuncs[event.uuid] = undefined;
        }
      }
    });
  }

  notifyOnRefer(displayName: string): void {
    if (this.shouldNotifyOnRefer() && !document.hasFocus()) {
      this.makeNotification(
        "OnSIP Call",
        {
          body: "You have been transferred to " + displayName,
          icon: "resources/img/orange-phone.png"
        },
        this.notificationTimeout
      );
    }
  }

  notifyOnChat(name: string): void {
    if (this.shouldNotifyOnChat() && !document.hasFocus()) {
      this.makeNotification(
        "OnSIP Chat",
        {
          body: "You have a new message from " + name + ".",
          icon: "resources/img/favicon.png"
        },
        this.notificationTimeout
      );
    }
  }

  notifyOnQueueWarning(advQueueWarning: any): void {
    if (!this.shouldNotifyOnQueueWarning()) {
      return;
    }

    const addr: string = advQueueWarning.AdvQueueAddress,
      threshVal: number = JSON.parse(advQueueWarning.ThresholdValue).Value;
    let msgBody: string;

    switch (advQueueWarning.ThresholdType) {
      case "too_few_agents":
        msgBody = "There are less than " + threshVal + " agents logged into " + addr;
        break;
      case "too_many_callers":
        msgBody = "There are more than " + threshVal + " callers waiting in " + addr;
        break;
      case "too_long_waiting":
        msgBody = "A caller has been waiting longer than " + threshVal / 60 + " minutes in " + addr;
        break;
      default:
        msgBody = "A Queue Alert has been triggered for " + addr;
    }

    this.makeNotification(
      "Queue Alert",
      {
        body: msgBody,
        icon: "resources/img/blue-phone.png"
      },
      this.notificationTimeout
    );
  }

  setNotification(typeStr: string, turnOn: boolean): void {
    if (window.Notification.permission === "denied") {
      return;
    }

    if (typeStr === "all") {
      Object.keys(this.stateStore.notifications).forEach(type => {
        this.localStorageService.setNotification(type, turnOn);
        this.stateStore.notifications[type] = turnOn;
      });
    } else {
      this.localStorageService.setNotification(typeStr, turnOn);
      this.stateStore.notifications[typeStr] = turnOn;
    }
    if (turnOn) {
      this.requestPermission();
    }
    this.publishState();
  }

  private notifyOnHangup(event: EndCallEvent): void {
    if (!this.shouldNotifyOnHangup() || document.hasFocus()) {
      return;
    }
    const call: CallState | undefined = this.callControllerService.getCallStateByUuid(event.uuid);

    if (!call || !call.ended) {
      return;
    }

    this.makeNotification(
      "OnSIP Call",
      {
        body:
          "Your call with " +
          call.remoteDisplayName +
          " has ended" +
          (event.reason && event.reason.length > 0 ? " with cause " + event.reason + "." : "."),
        icon: "resources/img/orange-phone.png"
      },
      this.notificationTimeout
    );
  }

  private notifyOnIncoming(call: CallState): (() => void) | undefined {
    if (!this.shouldNotifyOnIncoming() || document.hasFocus()) {
      return;
    }
    const identity: UserAddress | undefined = this.identityService.getDefaultIdentity();

    if (!call || !identity) {
      return;
    }

    const notifyReturn = this.makeNotification(
      "OnSIP Call",
      {
        body: call.remoteDisplayName + " is calling " + call.aor + ".",
        icon: "resources/img/blue-phone.png"
      },
      identity.timeout * 1000
    );

    return notifyReturn.removeNotify;
  }

  /**
   * Create a desktop notification with remove and click listeners.
   * `timeoutTime` is a number if we want the notification to timeout.
   */
  private makeNotification(
    notifyName: string,
    notifyProps: any,
    timeoutTime: number
  ): { notification: Notification; removeNotify: () => void } {
    notifyProps.dir = notifyProps.dir || "auto";
    notifyProps.lang = notifyProps.lang || "";
    notifyProps.requireInteraction = true;
    timeoutTime = timeoutTime || this.notificationTimeout;

    let timeoutTracker: any;
    const notification: Notification = new Notification(notifyName, notifyProps),
      removeNotify = () => {
        clearTimeout(timeoutTracker);
        notification.close();
        window.removeEventListener("focus", removeNotify, false);
      };

    notification.onclick = () => {
      window.focus();
      notification.close();
      this.platformNotifyClick();
    };

    notification.onshow = () => {
      timeoutTracker = setTimeout(removeNotify, timeoutTime);
    };

    // notification.ondisplay = notification.onshow;

    window.addEventListener("focus", removeNotify, false);

    return {
      notification,
      removeNotify
    };
  }

  private platformInitNotifications(): void {
    this.localStorageService.getNotifications().then(storedNotifications => {
      NotificationTypes.forEach(typeString => {
        const savedBool = storedNotifications[typeString],
          boolToUse = savedBool === undefined ? Config.IS_DESKTOP : savedBool;
        this.stateStore.notifications[typeString] = !!window.Notification && boolToUse;
      });

      if (Object.values(this.stateStore.notifications).some(val => !!val)) {
        this.requestPermission();
      }

      this.publishState();
    });
  }

  private shouldNotifyOnHangup(): boolean {
    return this.stateStore.notifications["on hangup"];
  }

  private shouldNotifyOnIncoming(): boolean {
    return this.stateStore.notifications["from incoming calls"];
  }

  private shouldNotifyOnRefer(): boolean {
    return this.stateStore.notifications["on transfer"];
  }

  private shouldNotifyOnChat(): boolean {
    return this.stateStore.notifications["on chat"];
  }

  private shouldNotifyOnQueueWarning(): boolean {
    return this.stateStore.notifications["on queue alert"];
  }

  private requestPermission(): Promise<boolean> {
    if (!window.Notification) {
      return Promise.resolve(false);
    }

    if (window.Notification.permission === "granted") {
      return Promise.resolve(true);
    }

    return window.Notification.requestPermission().then(() => {
      return window.Notification.permission === "granted";
    });
  }

  private platformNotifyClick(): void {
    if (Config.IS_DESKTOP) {
      window.electron.sendMessage("notification-click");
    }
  }

  private setSessionReferSubscription(uuid: string, session: Session): void {
    session.event
      .pipe(filter(e => e.id === ReferredSessionEvent.id || e.id === ReplacedSessionEvent.id))
      .subscribe(e => {
        if (e.id === ReferredSessionEvent.id) {
          this.notifyOnRefer(
            session.referral
              ? session.referral.remoteDisplayName || session.referral.remoteUri.aor
              : ""
          );
        } else {
          this.notifyOnRefer(session.replacement ? session.replacement.remoteDisplayName : "");
        }
        const newSession: Session | undefined = session.referral || session.replacement;
        if (newSession) {
          this.setSessionReferSubscription(uuid, newSession);
        }
      });
  }
}
