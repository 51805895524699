import { SessionEventFactory, SessionEvent } from "./session-event";

/**
 * Emitted once when Session receives provisional response to an INVITE other than 100 Trying.
 */
export class ProgressSessionEvent {
  static make(aor: string, uuid: string): SessionEvent {
    return SessionEventFactory.make(ProgressSessionEvent.id, aor, uuid);
  }
  static get id(): string {
    return "[Session] progress";
  }
}
