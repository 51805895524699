import { PlatformFirebase } from "../platform-firebase";
import { firebase } from "../platform-firebase-types";

import { StateEmitter } from "../../../../emitter/state-emitter";

//
// Note: You must initialize firebase before instantiation of any of the class herein.
//

// BIG NOTE:
//           The JWT token we are handling client side is not a secure thing!
//           You cannot use the information in it to assert anything about the user.
//           It is not an encrypted thing. It's just encoded JSON objects which could be
//           changed client side. It is only secure when verified by the intended receiptient (in this case Google).
//
//           While client side parsing of ID tokens is convenient for UI changes depending on payload content,
//           it is not sufficient or secure. Server side ID token verification is always required when enforcing
//           access to restricted resources.
//
// Nice blog post to get educated: https://blog.angular-university.io/angular-jwt/

const debug = false;

export interface AuthState {
  /** User's status. */
  status: "init" | "signedIn" | "signedOut";
  /** User's display name according to Google. */
  displayName: string | undefined;
  /** User's email according to Google. */
  email: string | undefined;
  /** True if user has verified by email. */
  emailVerified: boolean | undefined;
  /** True if an anonymous user. */
  isAnonymous: boolean | undefined;
  /** User's phone number according to Google. */
  phoneNumber: string | undefined;
  /** User's Google photo URL. */
  photoURL: string | undefined;
  /** User's User Id (UID). Should be same as our UID for the user. */
  uid: string | undefined;
  /** User's Org Id (OID). Should be available shortly after user is signed in. */
  oid: string | undefined;
  /** User's Org Id (OID). Should be available shortly after user is signed in. */
  aid: string | undefined;
  /** User's Roles. Comma delimited list no whitespace. Should be available shortly after user is signed in. */
  roles: string | undefined;
}

// Current roles...
//
// Super User
// System Admin
// Account Admin
// Organization Admin
// User
// Anonymous
// Agent Admin
// Organization Viewer

export class Auth extends StateEmitter<AuthState> {
  private unsubscribe: firebase.Unsubscribe | undefined = undefined;
  private user: firebase.User | undefined = undefined; // If defined, then signed in.

  constructor() {
    super({
      status: "init",
      displayName: undefined,
      email: undefined,
      emailVerified: undefined,
      isAnonymous: undefined,
      phoneNumber: undefined,
      photoURL: undefined,
      uid: undefined,
      oid: undefined,
      aid: undefined,
      roles: undefined
    });
    this.setPersistence().then(() => {
      this.subscribe();
    });
    debug && console.log("Auth constructed");
  }

  dispose(): void {
    if (this.unsubscribe !== undefined) {
      this.unsubscribe();
    }
    debug && console.log("Auth disposed");
  }

  /**
   * Sign user into the cloud store.
   * A JSON Web Token (JWT), provided by our API to authenticated users,
   * is used for credentials. The JWT includes the user's user id (uid),
   * org id (oid), and account id (aid).
   * @param token JSON Web Token.
   */
  signIn(token: string): Promise<void> {
    return PlatformFirebase.firebase
      .auth()
      .signInWithCustomToken(token)
      .then(() => {
        debug && console.log("Auth sign in with custom token");
      })
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        debug && console.error("Auth sign in with custom token error ", errorCode, errorMessage);
        throw error;
      });
  }

  /**
   * Sign user out of the cloud store.
   * We need to publish the signedOut state so all listeners
   * will dispose themselves and then we can signOut to avoid
   * the permission denied error.
   */
  signOut(): Promise<void> {
    this.stateStore.status = "signedOut";
    this.publishState();
    return PlatformFirebase.firebase
      .auth()
      .signOut()
      .then(() => {
        debug && console.log("Auth sign out");
      })
      .catch(error => {
        debug && console.error("Auth sign out error ", error);
        throw error;
      });
  }

  /**
   * Gets additional claims provided by the current user's token.
   */
  private getClaims(): Promise<void> {
    debug && console.log("Auth getting claims");
    if (this.user === undefined) {
      // don't continue if the user is not signed in
      debug && console.log("Auth user claims - no current user");
      return Promise.resolve();
    }
    return this.user
      .getIdTokenResult()
      .then(idTokenResult => {
        debug && console.log("Auth user claims result");
        if (this.user === undefined) {
          // don't continue if the user is not signed in
          debug && console.log("Auth user claims result - no current user");
          return;
        }
        this.stateStore.aid = idTokenResult.claims.aid;
        this.stateStore.oid = idTokenResult.claims.oid;
        this.stateStore.roles = idTokenResult.claims.roles;
        this.publishState();
      })
      .catch(error => {
        debug && console.log("Auth user claims error ", error);
        throw error;
      });
  }

  private setPersistence(): Promise<void> {
    // In memory persistence will be applied to the signed in Google user.
    // Indicates that the state will only be stored in memory and will be
    // cleared when the window or activity is refreshed. Default is "local".
    return PlatformFirebase.firebase
      .auth()
      .setPersistence(PlatformFirebase.auth.Auth.Persistence.NONE)
      .catch(error => {
        const errorCode = error.code;
        const errorMessage = error.message;
        debug && console.error("Auth sign in set persistence error ", errorCode, errorMessage);
        throw error;
      });
  }

  private subscribe(): void {
    debug && console.log("Auth subscribe");
    this.unsubscribe = PlatformFirebase.firebase.auth().onAuthStateChanged(
      user => {
        if (user) {
          debug && console.log("Auth user signed in");
          // User is signed in. Info to be had...
          this.user = user;
          this.stateStore.status = "signedIn";
          // eslint-disable-next-line no-null/no-null
          this.stateStore.displayName = user.displayName === null ? undefined : user.displayName;
          // eslint-disable-next-line no-null/no-null
          this.stateStore.email = user.email === null ? undefined : user.email;
          this.stateStore.emailVerified = user.emailVerified;
          this.stateStore.isAnonymous = user.isAnonymous;
          // eslint-disable-next-line no-null/no-null
          this.stateStore.phoneNumber = user.phoneNumber === null ? undefined : user.phoneNumber;
          // eslint-disable-next-line no-null/no-null
          this.stateStore.photoURL = user.photoURL === null ? undefined : user.photoURL;
          // TODO: Think this is they way to get our data if non-custom auth method is used?
          // this.stateStore.providerData = user.providerData;a
          this.stateStore.uid = user.uid;
          this.publishState();
          this.getClaims().catch(() => {
            console.error("Auth user signed in, but failed to get additional claims.");
          });
        } else {
          // User is signed out.
          debug && console.log("Auth user signed out");
          this.user = undefined;
          this.stateStore.status = "signedOut";
          this.stateStore.displayName = undefined;
          this.stateStore.email = undefined;
          this.stateStore.emailVerified = undefined;
          this.stateStore.isAnonymous = undefined;
          this.stateStore.phoneNumber = undefined;
          this.stateStore.photoURL = undefined;
          this.stateStore.uid = undefined;
          this.stateStore.oid = undefined;
          this.stateStore.aid = undefined;
          this.publishState();
        }
      },
      error => {
        console.error("Auth subscription error", error);
        this.publishStateError(error);
      },
      () => {
        console.error("Auth subscription unexpectedly completed");
        this.publishStateComplete();
      }
    );
  }

  // Client side payload parsing (untested)
  // private parsePayload(token): object | undefined {
  //   const base64Url = token.split(".")[1];
  //   const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  //   let payload = undefined;
  //   try {
  //     payload = JSON.parse(atob(base64));
  //   } catch (error) {
  //     debug && console.error("Failed to parse JWT payload ", error);
  //   }
  //   return payload;
  // }
}
