export enum LogLevel {
  Debug = 0,
  Info = 1,
  Warning = 2,
  Error = 4
}

export type LogType = string | Object;

export interface LogEvent {
  level: LogLevel;
  message: Array<LogType>;
}

export abstract class LogTarget {
  abstract log(event: LogEvent): Promise<any>;
}
