import { Component, ChangeDetectionStrategy, ViewEncapsulation, HostBinding } from "@angular/core";
import { CdkRow, CDK_ROW_TEMPLATE } from "@angular/cdk/table";
// export declare const CDK_ROW_TEMPLATE = "<ng-container cdkCellOutlet></ng-container>";

@Component({
  selector: "onsip-table-shelf, tr[onsip-table-shelf]",
  template: CDK_ROW_TEMPLATE,
  // See note on CdkTable for explanation on why this uses the default change detection strategy.
  changeDetection: ChangeDetectionStrategy.Default,
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  exportAs: "onsipRow",
  providers: [{ provide: CdkRow, useExisting: TableShelfComponent }]
})
export class TableShelfComponent extends CdkRow {
  @HostBinding("class.mat-row")
  @HostBinding("class.onsip-table-shelf")
  _dontUse = true;

  @HostBinding("attr.role")
  _roleAttr = "row";
}
