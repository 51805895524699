<div id="system-status">
  <span data-*ngIf="(systemStatus | async) === 'loading'"
    >{{ "ONSIP_I18N.LOADING" | translate }}...</span
  >
  <span data-*ngIf="(systemStatus | async) === 'good'">
    <img
      class="system-status-img"
      [src]="'resources/img/system-status/secure.png'"
      alt="System Status: Secure"
    />
    {{ "ONSIP_I18N.SYSTEM_STATUS_ALL_SYSTEMS_GO" | translate }}
  </span>
  <span data-*ngIf="(systemStatus | async) === 'bad'">
    <img
      class="system-status-img"
      [src]="'resources/img/system-status/advisory.png'"
      alt="Service Advisory"
    />
    <a href="https://www.onsip.com/network-status" target="_blank">{{
      "ONSIP_I18N.SERVICE_ADVISORY" | translate
    }}</a>
  </span>
</div>
