import { Call } from "./call";
import { Calls } from "./calls";
import { CallEvent } from "./call-event";
import { CallState } from "./call-state";
import { CallController } from "./call-controller";
import { CallGroupState, CallGroupStateInitializer } from "./call-group-state";
import { EventStateEmitter } from "../emitter/event-state-emitter";
import { UUID } from "./uuid";
import { log } from "./log";

/** CallGroup implementation. */
export class CallGroup<CE extends CallEvent, CS extends CallState> extends EventStateEmitter<
  CE | CallEvent,
  CallGroupState<CS>
> {
  /** The calls in the group. */
  calls: Calls<CE, CS>;

  static initializer(uuid: string): CallGroupState<CallState> {
    return {
      uuid,
      calls: [],
      active: false,
      connecting: false,
      connected: false,
      incoming: false,
      hold: false,
      holdInProgress: false,
      mute: false,
      video: false,
      videoAvailable: false,
      videoExpected: false,
      videoRequested: false
    };
  }

  /**
   * Constructor
   * @param initializer Initializer function.
   * @param initialCall Initial call in the group when created.
   * @param completeOnEmpty Completes when a call is removed and zero calls remain. Defaults to true.
   */
  constructor(
    private callController: CallController<CE, CS>,
    initialCall: Call<CE, CS>,
    initializer: CallGroupStateInitializer<
      CallGroupState<CS>,
      CS
    > = CallGroup.initializer as CallGroupStateInitializer<CallGroupState<CS>, CS>
  ) {
    super(initializer(UUID.randomUUID()));
    // At this point the stateStore is good to go with the exception of the calls (as we just passed in an empty array).
    // Add the initial call and then subscribe which will capture the initial call state and publish the entire state.
    this.calls = new Calls(true);
    this.calls.add(initialCall);
    this.calls.event.subscribe({
      next: next => this.publishEvent(next),
      error: (error: unknown) => {
        throw error;
      },
      complete: () => this.publishEventComplete()
    });
    this.calls.state.subscribe({
      next: next => {
        this.stateStore.calls = next;
        this.publishState();
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => this.publishStateComplete()
    });
  }

  /** The UUID of the group. */
  get uuid(): string {
    return this.stateStore.uuid;
  }

  /**
   * Dispose (will complete).
   */
  dispose(): void {
    this.calls.dispose();
  }

  /**
   * Adds a Call.
   * @param call The Call to add.
   */
  add(call: Call<CE, CS>): void {
    this.calls.add(call);
  }

  /**
   * Removes a Call.
   * @param call The Call to remove.
   */
  remove(call: Call<CE, CS>): void {
    this.calls.remove(call);
  }

  /**
   * End calls in the group.
   * @param hold If true, CallGroup is placed on hold.
   * @reject {NotFoundCallError} CallGroup not found.
   */
  end(): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].end`);
    return Promise.all(this.stateStore.calls.map(call => this.callController.end(call.uuid))).then(
      () => Promise.resolve()
    );
  }

  /**
   * Plays a DTMF tone to the group.
   * @param tone The tone to play.
   */
  playDTMF(tone: string): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].playDTMF`);
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.playDTMF(call.uuid, tone))
    ).then(() => Promise.resolve());
  }

  /**
   * Enables or disables audio.
   * @param audio Audio enabled if true, disabled if false.
   */
  setAudio(audio: boolean): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].setVideo`);
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.audio(call.uuid, audio))
    ).then(() => Promise.resolve());
  }

  /**
   * Places the group on hold.
   * @param hold Hold on if true, off if false.
   * @reject {NotFoundCallError} CallGroup not found.
   * @reject {HoldFailedCallError} On failure to hold call.
   */
  setHold(hold: boolean): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].setHold`);
    // FIXME: This doesn't work right if there are more than one call in the group and hold
    //        fails for one of them but succeeds for others. We're left in a bad state.
    //        Should perhaps attempt to undo the operation for those that succeeded, but
    //        then what happens if the undo operation fails? It's a problem. Punting for now.
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.hold(call.uuid, hold))
    ).then(() => Promise.resolve());
  }

  /**
   * Mutes or unmutes the group.
   * @param mute Muted if true, unmuted if false.
   * @reject {NotFoundCallError} CallGroup not found.
   */
  setMute(mute: boolean): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].setMute`);
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.mute(call.uuid, mute))
    ).then(() => Promise.resolve());
  }

  /**
   * Enables or disables video.
   * @param video Video enabled if true, disabled if false.
   */
  setVideo(video: boolean): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].setVideo`);
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.video(call.uuid, video))
    ).then(() => Promise.resolve());
  }

  /**
   * Blind Transfers the group.
   * @param target Target of the transfer.
   */
  blindTransfer(target: string): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].transfer`);
    return Promise.all(
      this.stateStore.calls.map(call => this.callController.blindTransfer(call.uuid, target))
    ).then(() => Promise.resolve());
  }

  /**
   * Attended transfers the group.
   * @param target Target of the transfer.
   */
  attendedTransfer(transfereeGroup: CallGroup<CE, CS>): Promise<void> {
    log.debug(`CallGroup[${this.uuid}].transfer`);

    return this.callController
      .attendedTransfer(this.calls.array[0].uuid, transfereeGroup.calls.array[0].uuid)
      .then(() => Promise.resolve());
  }

  protected flushState(): void {
    super.flushState();

    // see CallGroupState for definitions
    const activeCalls = this.stateStore.calls.filter(call => call.active);
    const active = activeCalls.length > 0;
    const connecting = active && activeCalls.some(call => call.connecting);
    const connected = active && activeCalls.some(call => call.connected);
    const incoming = active && activeCalls.some(call => call.incoming);
    const hold = active && activeCalls.every(call => call.hold);
    const holdInProgress = active && activeCalls.some(call => call.holdInProgress);
    const mute = active && activeCalls.every(call => call.mute);
    const video = active && activeCalls.some(call => call.video && !call.hold);
    const videoAvailable = active && activeCalls.some(call => call.videoAvailable && !call.hold);
    const videoExpected = active && activeCalls.some(call => call.videoExpected);
    const videoRequested = active && activeCalls.some(call => call.videoRequested);

    this.stateStore.active = active;
    this.stateStore.connecting = connecting;
    this.stateStore.connected = connected;
    this.stateStore.incoming = incoming;
    this.stateStore.hold = hold;
    this.stateStore.holdInProgress = holdInProgress;
    this.stateStore.mute = mute;
    this.stateStore.video = video;
    this.stateStore.videoAvailable = videoAvailable;
    this.stateStore.videoExpected = videoExpected;
    this.stateStore.videoRequested = videoRequested;
  }
}
