<onsip-loading-container
  [deferCond]="loadingStateComplete$"
  [loadingMessage]="'ONSIP_I18N.SAVING_CHANGES'"
>
  <form class="voicemail-settings mat-typography" [formGroup]="formGroup" (submit)="saveBox()">
    <div class="onsip-grid-placement">
      <div class="first-column">
        <div class="voicemail-settings voicemail-number">
          <h4>{{ "ONSIP_I18N.MAILBOX_NUMBER" | translate }}:</h4>
          <span>{{ mailboxNumber }}</span>
        </div>
        <div class="voicemail-settings voicemail-notify-email">
          <h4>{{ "ONSIP_I18N.MESSAGE_NOTIFICATION" | translate }}:</h4>
          <mat-form-field appearance="outline">
            <mat-select formControlName="notifyEmail">
              @for (notifyEmailOption of notifyEmailOptions; track notifyEmailOption) {
              <mat-option [value]="notifyEmailOption.value">
                {{ notifyEmailOption.label }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (formGroup.controls.notifyEmail.value !== 'no') {
        <div class="voicemail-settings voicemail-notification-email">
          <h4>{{ "ONSIP_I18N.NOTIFICATION_EMAIL" | translate }}:</h4>
          <mat-form-field appearance="outline">
            <input matInput type="text" name="email" autocomplete="off" formControlName="email" />
            <mat-error>{{ "ONSIP_I18N.EMAIL_ADDRESS_IS_NOT_VALID" | translate }}</mat-error>
          </mat-form-field>
        </div>
        }
        <div class="voicemail-settings voicemail-password">
          <h4>{{ "ONSIP_I18N.MAILBOX_PASSWORD_PIN" | translate }}:</h4>
          <mat-form-field appearance="outline">
            <input
              matInput
              type="text"
              name="password"
              autocomplete="off"
              formControlName="password"
            />
            <mat-error>{{
              "ONSIP_I18N.PASSWORD_CANNOT_BE_MORE_THAN_SIX_DIGITS_LONG" | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="second-column">
        <div class="mat-body-1">{{ "ONSIP_I18N.VOICEMAIL_SETTINGS" | translate }}</div>
        <div class="voicemail-settings__slide-wrap voicemail-notify-phone">
          <mat-slide-toggle #mwiToggle color="primary" formControlName="mwi">
            {{ "ONSIP_I18N.NOTIFY_PHONES" | translate }}
          </mat-slide-toggle>
        </div>
        <div class="voicemail-settings__slide-wrap voicemail-announce-duration">
          <mat-slide-toggle
            #announceDurationToggle
            color="primary"
            formControlName="announceDuration"
          >
            {{ "ONSIP_I18N.ANNOUNCE_DURATION" | translate }}
          </mat-slide-toggle>
        </div>
        <div class="voicemail-settings__slide-wrap voicemail-date-time">
          <mat-slide-toggle
            #announceDateAndTimeToggle
            color="primary"
            formControlName="announceDateAndTime"
          >
            {{ "ONSIP_I18N.ANNOUNCE_DATE_AND_TIME" | translate }}
          </mat-slide-toggle>
        </div>
        <div class="voicemail-settings__slide-wrap voicemail-caller-id">
          <mat-slide-toggle
            #announceCallerIdToggle
            color="primary"
            formControlName="announceCallerId"
          >
            {{ "ONSIP_I18N.ANNOUNCE_CALLER_ID" | translate }}
          </mat-slide-toggle>
        </div>

        <div class="voicemail-settings__slide-wrap voicemail-custom-greeting">
          <mat-slide-toggle
            color="primary"
            [checked]="hasMessages"
            (change)="toggleUnavailableMessage($event.checked)"
          >
            {{ "ONSIP_I18N.CUSTOM_GREETING" | translate }}
          </mat-slide-toggle>
          @if (hasMessages) {

          <div class="voicemail-settings__recorder-wrapper">
            <onsip-togglable-recorder
              formControlName="greetingFile"
              [hasSelect]="false"
              [defaultType]="greetingType"
              (recordingTypeChange)="this.greetingType = $event"
            >
            </onsip-togglable-recorder>
          </div>
          }
        </div>
      </div>
    </div>
    <div mat-dialog-actions class="voicemail-settings__actions">
      <button mat-button color="primary" type="button" (click)="handleCancel()">
        {{ "ONSIP_I18N.CANCEL" | translate }}
      </button>
      <button mat-flat-button [disabled]="!formGroup.valid" color="primary" type="submit">
        {{ "ONSIP_I18N.SAVE" | translate }}
      </button>
    </div>
  </form>
</onsip-loading-container>
