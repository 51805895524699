import { PrephoneComponent } from "./prephone.component";
import { LoginFormComponent } from "./login/login-form.component";
import { PasswordResetRequestFormComponent } from "./passwordResetRequest/password-reset-request-form.component";
import { ForgotSipAddressFormComponent } from "./forgotSipAddress/forgot-sip-address-form.component";

import { UsabilityModule } from "../usability/usability.module";
import { PrephoneRoutingModule } from "./prephone-routing.module";
import { EntryPageModule } from "../entryPage/entry-page.module";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { ConnectionIssueModule } from "./modal/connection-issue.module";

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UsabilityModule,
    MaterialModule,
    EntryPageModule,
    PrephoneRoutingModule,
    ConnectionIssueModule,
    TranslateModule
  ],
  declarations: [
    PasswordResetRequestFormComponent,
    ForgotSipAddressFormComponent,
    LoginFormComponent,
    PrephoneComponent
  ],
  exports: [
    PasswordResetRequestFormComponent,
    ForgotSipAddressFormComponent,
    LoginFormComponent,
    PrephoneComponent
  ]
})
export class PrephoneModule {}
