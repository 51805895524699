import {
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  ElementRef,
  AfterViewInit,
  OnChanges,
  OnDestroy
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

import { views } from "../../../../app/phone/views";

@Component({
  selector: "onsip-video-box",
  templateUrl: "./video-box.component.html",
  styleUrls: ["./video-box.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoBoxComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild("videoEl") videoElement!: ElementRef;

  @Input() localStream: any;
  @Input() remoteStream: any;
  @Input() objectFit: "contain" | "cover" = "contain";

  private isPlaying = false;
  private bufferPromise: Promise<any> = Promise.resolve();
  private unsubscriber = new Subscription();

  constructor(private route: ActivatedRoute) {}

  ngOnChanges(): void {
    if (this.objectFit !== "contain" && this.objectFit !== "cover") {
      throw new Error("VideoBoxComponent: Unexpected Input value for objectFit");
    }

    if (!this.isPlaying || !this.videoElement) {
      return;
    }

    this.bufferVideoElementCalls(this.videoElement.nativeElement, "pause");
    this.startStream();
  }

  ngAfterViewInit(): void {
    this.unsubscriber.add(
      this.route.url.subscribe(url => {
        const currentView = "/" + url.toString();
        if (currentView === views.VIDEO_CONFERENCE || currentView === views.CALL) {
          this.startStream();
        }
      })
    );

    this.videoElement.nativeElement.muted = true;

    this.startStream();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
    this.bufferVideoElementCalls(this.videoElement.nativeElement, "pause");
  }

  private startStream(): void {
    if (!this.localStream && !this.remoteStream) {
      return;
    }

    this.bufferVideoElementCalls(
      this.videoElement.nativeElement,
      "srcObject",
      this.localStream || this.remoteStream
    );
    this.bufferVideoElementCalls(this.videoElement.nativeElement, "play");

    this.isPlaying = true;
  }

  private bufferVideoElementCalls(
    element: HTMLVideoElement,
    videoFunction: "play" | "srcObject" | "pause",
    srcObject?: any
  ): void {
    this.bufferPromise = this.bufferPromise
      .then(() => {
        if (videoFunction === "play") {
          return element[videoFunction]().catch(error => {
            console.warn("Video auto-play was prevented", error);
            return;
          });
        }
        if (videoFunction === "srcObject") {
          element.srcObject = srcObject;
          return;
        }
        return element[videoFunction]();
      })
      .catch(error => {
        console.error("bufferVideoElementCalls error, continuing:", error);
        return;
      });
  }
}
