/**
 * adapted from https://github.com/bgrins/TinyColor/blob/master/tinycolor.js
 * @param background background color as 6 digit hex
 * @returns The legible text color for the given background color, either "#ffffff" or "#000000" (white or black respectively)
 */

// brightness threshold chosen to be lighter than all sayso preset colors
export function isBrightColor(background: string, threshold: number = 150): boolean {
  // http://www.w3.org/TR/AERT#color-contrast
  const rgb = /^#?([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/.exec(background);
  if (rgb && rgb[1] && rgb[2] && rgb[3]) {
    return (
      (parseInt(/*red*/ rgb[1], 16) * 299 +
        parseInt(/*green*/ rgb[2], 16) * 587 +
        parseInt(/*blue*/ rgb[3], 16) * 114) /
        1000 >
      threshold
    );
  } else {
    throw new RegexParseError(
      `isBrightColor[${background}]: Regex parsing of input did not succeed - did you pass in a valid 6 digit hex input?`
    );
  }
}

export class RegexParseError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
  }
}
