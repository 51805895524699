// angular
import { NgModule, ModuleWithProviders, Optional, SkipSelf } from "@angular/core";

// module
import { CORE_DIRECTIVES } from "./directives/index";
import { CORE_PROVIDERS } from "./services/index";

/**
 * Do not specify providers for modules that might be imported by a lazy loaded module.
 */

@NgModule({
  imports: [],
  declarations: [...CORE_DIRECTIVES],
  exports: [...CORE_DIRECTIVES],
  providers: [...CORE_PROVIDERS]
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and ConsoleService per platform
  static forRoot(configuredProviders: Array<any>): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: configuredProviders
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule already loaded; Import in root module only.");
    }
  }
}
