import { Injectable, NgZone } from "@angular/core";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";

import { User } from "../../libraries/firebase/store/user";
import { AuthService } from "./auth.service";

import { StateEmitter } from "../../../common/libraries/emitter/state-emitter";
import { FirestoreAuthUserService, UserAvailability } from "./firestore-auth-user.service";

export interface UserAvailabilityState {
  availability: UserAvailability;
}

const debug = false;

/**
 * UPDATE: This service is just used for listening to your own GA state now
 *
 * UserAvailability Service is to be used only with apps that support Firebase Auth
 * as it requires access to the user document in order to write to it.
 *
 * The sole purpose on this service is to SET (and listen to its own) Global Availability
 * state which is used to see if the user is willing to accept Sayso calls.
 */
@Injectable({ providedIn: "root" })
export class UserAvailabilityService extends StateEmitter<UserAvailabilityState> {
  private unsubscriber = new Subscription();

  private static initialState(): UserAvailabilityState {
    return {
      availability: "undefined"
    };
  }

  constructor(
    private auth: AuthService,
    private firestoreAuthUser: FirestoreAuthUserService,
    private zone: NgZone
  ) {
    super(UserAvailabilityService.initialState());
    this.init();
  }

  dispose() {
    this.unsubscriber.unsubscribe();
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(() => {
      return fn();
    });
  }

  /**
   * UPDATE: NO ONE SHOULD BE USING THIS NOW AS we use the API
   * to write to Firestore. Leaving here incase
   *
   * Food for thought... Whether this is actually a problem or not
   * Setting the users availability takes time to resolve, but we
   * publish the state setting our availability before it is succesfully
   * written to firestore. We actually do this for all state emitters
   * using firestore + firebase
   *
   * Chaining state update to promise resolution could give a bad UX
   *
   * @param availability Manual Global Availability : undefined | unavailable | available
   */
  private setUserAvailability(availability: UserAvailability): void {
    if (!this.auth.stateValue.oid) throw new Error("Auth oid is undefined!");
    if (!this.auth.stateValue.uid) throw new Error("Auth uid is undefined!");
    if (availability !== this.stateStore.availability) {
      this.stateStore.availability = availability;
      User.setUserAvailability(
        this.auth.stateValue.oid,
        this.auth.stateValue.uid,
        this.stateStore.availability
      );
      this.publishState();
    }
  }

  /**
   * When we have a succesfully logged in user (hence the filter and it could be done
   * listening the AuthService, but since FirestoreAuthUserService does that internally to get its
   * id I just leverage and filter by that) If global availability was never set maybe
   * because they were an existing user then we set them to available since they are
   * signed in.
   */
  private init(): void {
    this.unsubscriber.add(
      this.firestoreAuthUser.state.pipe(filter(state => state.id !== "")).subscribe(state => {
        if (state.availability === "undefined") {
          debug &&
            console.log(
              "UserAvailability.init: User availability is undefined. Setting to Available"
            );
          this.setUserAvailability("available");
        } else {
          if (this.stateStore.availability !== state.availability) {
            this.stateStore.availability = state.availability;
            this.publishState();
          }
        }
        debug && console.log("UserAvailability.init: User state = " + JSON.stringify(state));
      })
    );
  }
}
