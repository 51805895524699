import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sentenceCase"
})
export class SentenceCasePipe implements PipeTransform {
  transform(str: string): string {
    const regex = /([A-Z]|[a-z])/g;
    const pos = str.search(regex); // position of the first letter (alpha)
    if (pos === -1) {
      // no letters
      return str;
    } else {
      // at least one letter capitalize first rest lc
      return (
        str.substring(0, pos).toLowerCase() +
        str[pos].toUpperCase() +
        str.substring(pos + 1).toLowerCase()
      );
    }
  }
}
