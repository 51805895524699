import {
  Component,
  ChangeDetectionStrategy,
  ContentChildren,
  QueryList,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnDestroy
} from "@angular/core";
import { CdkPortal } from "@angular/cdk/portal";
import { trigger, transition, style, animate } from "@angular/animations";

import { Observable, Subscription, interval } from "rxjs";
import { startWith, map, tap, shareReplay } from "rxjs/operators";

@Component({
  selector: "onsip-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("fade", [
      transition(":enter", [style({ opacity: 0 }), animate("1s ease-out", style({ opacity: 1 }))]),
      transition(":leave", [style({ opacity: 1 }), animate("1s ease-in", style({ opacity: 0 }))])
    ])
  ]
})
export class CarouselComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(CdkPortal) set _portals(portals: QueryList<CdkPortal>) {
    this.portals = portals.toArray();
  }
  /** The interval, in milliseconds, at which the image in the carousel changes */
  @Input() interval = 6000;
  /** Emits the current index when the carousel frame changes */
  @Output() changeEmitter = new EventEmitter<number>();

  /** The frames that the carousel will rotate between */
  portals!: Array<CdkPortal>;
  /** the index of the current image from the array of images provided as an input */
  currentFrame!: Observable<number>;

  private unsubscriber = new Subscription();

  ngAfterContentInit() {
    this.currentFrame = interval(this.interval).pipe(
      startWith(-1), // interval's first emission is 0 that occurs AFTER the first period, so start at -1 and add 1 to start at 0 at t=0
      map(index => (index + 1) % this.portals.length),
      tap(index => this.changeEmitter.emit(index)),
      // eslint-disable-next-line rxjs/no-sharereplay
      shareReplay(1)
    );
  }

  ngOnDestroy() {
    this.unsubscriber.unsubscribe();
  }
}
