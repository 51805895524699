import { WarningBarComponent } from "./warning-bar.component";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { MaterialModule } from "../../../../modules/material/material.module";

@NgModule({
  imports: [CommonModule, TranslateModule, MaterialModule],
  declarations: [WarningBarComponent],
  exports: [WarningBarComponent]
})
export class WarningBarModule {}
