// eslint-disable-next-line @typescript-eslint/no-var-requires
const en = require("./translations/en/sheet1.json");
// eslint-disable-next-line @typescript-eslint/no-var-requires
const es = require("./translations/es/sheet1.json");

export class FileReader {
  static languages: Record<string, JSON> = {
    en,
    es
  };

  static readJSON(lang: string) {
    const jsonData = this.languages[lang];
    if (jsonData) {
      return jsonData;
    } else {
      throw Error("File does not exist:" + lang);
    }
  }
}
