import { AbstractControl, ValidatorFn } from "@angular/forms";

export function checkSocialMediaLinks(itemName: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const regex = new RegExp(itemName.toLowerCase());
    const error: boolean = /http|www|\.com/.test(control.value) || regex.test(control.value);
    // eslint-disable-next-line no-null/no-null
    return error ? { badLink: { value: control.value } } : null;
  };
}
