import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MaterialModule } from "../../../modules/material/material.module";

import { VoicemailMessageAudioComponent } from "./voicemail-message-audio.component";

import { VolumeModule } from "../../shared/components/volume/volume.module";

@NgModule({
  imports: [BrowserModule, BrowserAnimationsModule, CommonModule, MaterialModule, VolumeModule],
  declarations: [VoicemailMessageAudioComponent],
  exports: [VoicemailMessageAudioComponent]
})
export class VoicemailMessageAudioModule {}
