<mat-toolbar onsip-header>
  <mat-toolbar-row onsip-header-breadcrumb-container class="mat-caption">
    <ng-content select="onsip-header-breadcrumb-container"></ng-content>
  </mat-toolbar-row>
  <mat-toolbar-row onsip-header-page-title>
    <ng-content select="onsip-header-page-title"></ng-content>
  </mat-toolbar-row>
  @if (showActionBar) {
  <mat-toolbar-row onsip-header-action-bar>
    <ng-content select="onsip-header-action-bar"></ng-content>
  </mat-toolbar-row>
  }
  <mat-toolbar-row onsip-header-page-sub-title>
    <ng-content select="onsip-header-page-sub-title"></ng-content>
  </mat-toolbar-row>
</mat-toolbar>
