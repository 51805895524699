import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from "rxjs";

import { FileReader } from "./file-reader";

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return FileReader.languages[lang]
      ? of(FileReader.readJSON(lang))
      : of(FileReader.readJSON("en"));
  }
}
