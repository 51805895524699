<div class="contact-list-header">
  <span class="header-section">
    <form class="search-contacts" [style.width]="displaySearchBar ? 'min-content' : '16px'">
      @if (displaySearchBar) {
      <mat-icon
        id="onsip--contact-list--close-search"
        aria-label="close search"
        class="search-bar-icon primary-500"
        (click)="toggleSearch()"
      >
        close
      </mat-icon>
      }
      <input
        class="contact-searchbar"
        type="text"
        [placeholder]="searchString"
        [class.exited]="displaySearchBar"
        [attr.data-cy]="E2eLocators.CONTACTS_SEARCH_INPUT"
        (keyup)="applyFilter($event.target)"
        #searchContacts
      />
    </form>
    <div class="search-contacts display-contacts">
      @if (!displaySearchBar) {
      <mat-icon
        id="onsip--contact-list--open-search"
        aria-label="open search"
        class="search-bar-icon primary-500"
        (click)="toggleSearch()"
        [attr.data-cy]="E2eLocators.CONTACTS_SHOW_SEARCH_INPUT"
      >
        search
      </mat-icon>
      }
      <h2 [class.slide]="displaySearchBar" class="contact-list-title">
        {{ "ONSIP_I18N.CONTACTS" | translate }}
      </h2>
    </div>
  </span>
</div>
<!-- EMPTY STATE -->
@if ((filteredItems | async)?.length === 0) {
<div class="empty-state">
  <h4 [attr.data-cy]="E2eLocators.CONTACTS_SEARCH_EMPTY">
    {{ "ONSIP_I18N.NO_CONTACTS" | translate }}
  </h4>
</div>
}
<mat-list
  class="contact-list-container"
  [attr.data-cy]="E2eLocators.CONTACTS_LIST"
  onsipArrowKeyNavigation
>
  @for (item of filteredItems | async; track item) {
  <mat-list-item
    id="onsip--contact-list--go-to-contact"
    class="contact-list-item draggable"
    (click)="navigateToItem(item)"
    (keydown.Enter)="navigateToItem(item)"
    (keydown.Space)="navigateToItem(item)"
    [attr.data-cy]="E2eLocators.CONTACTS_LIST_ITEM"
    cdkDropList
    [cdkDropListDisabled]="(userBusy | async) === false || (userBusy | async) === null"
    [cdkDropListData]="{ contact: item }"
    (cdkDropListDropped)="dragDropCallService.dropEvent($event)"
  >
    <div class="contact-list-item-content" cdkDrag [cdkDragData]="{ contact: item }">
      <img *cdkDragPreview [src]="'resources/img/transfer-drag.png'" alt="Drag for Transfer" />
      <span class="contact-icons">
        <onsip-avatar [avatarUrl]="item?.avatarUrl" [name]="item?.name"></onsip-avatar>
      </span>
      @if ((contactsUnread | async)![item.uuid] > 0) {
      <mat-icon class="unread-messages" aria-label="unread messages" color="primary">
        lens
      </mat-icon>
      }
      <span class="contact-list-item-details">
        <span class="contact-name-and-timer">
          <span class="mat-body-1 contact-list-ellipsis contact-list-name">
            {{ item.name }}
          </span>
          @if ( ((contactsBestDialogInfo | async)![item.uuid]) && (contactsBestDialogInfo |
          async)![item.uuid].state === 'confirmed') {
          <span class="onsip-contact-list-timer">
            <onsip-timer
              [referencePoint]="(contactsBestDialogInfo | async)![item.uuid].confirmedTime"
            ></onsip-timer>
            @if ( !!item.aors && item.aors[0] !== (contactsBestPresentity | async)![item.uuid].aor )
            {
            <span class="contact-list-ellipsis">
              - {{ (contactsBestPresentity | async)![item.uuid].aor }}
            </span>
            }
          </span>
          }
        </span>
      </span>
      <span class="contact-list-item-icons">
        <mat-icon
          id="onsip--contact-list--remove-contact"
          class="quick-remove"
          aria-label="close"
          (click)="quickRemove(item, $event)"
          [attr.data-cy]="E2eLocators.CONTACTS_LIST_ITEM_REMOVE"
        >
          close
        </mat-icon>
        @if ((contactsBusy | async)![item.uuid]) {
        <mat-icon aria-label="on call" svgIcon="onsip_presence_busy"> </mat-icon>
        } @if ( (contactsBusy | async)![item.uuid] === false && (contactsAvailability |
        async)![item.uuid] === 'available' ) {
        <mat-icon aria-label="available" svgIcon="onsip_presence_available"> </mat-icon>
        } @if ( (contactsBusy | async)![item.uuid] === false && (contactsAvailability |
        async)![item.uuid] === 'unavailable' ) {
        <mat-icon aria-label="unavailable" svgIcon="onsip_presence_away"> </mat-icon>
        }
      </span>
    </div>
  </mat-list-item>
  }
</mat-list>
