import { Injectable } from "@angular/core";
import { ApiSessionService } from "../../../../common/services/api/api-session.service";
import { distinctUntilChanged } from "rxjs/operators";
import { OrganizationService } from "../../../../common/services/api/resources/organization/organization.service";
import { ApiStateStoreService } from "../../../../common/services/api/api-state-store.service";
import { ApiPromiseStateService } from "../../../../common/services/api/api-promise-state.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class PhoneOrganizationService extends OrganizationService {
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState);

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(state => {
        if (state.loggedIn && !!state.sessionId) this.onLogin(state.organizationId);
        else if (!state.loggedIn) this.dispose();
      });
  }

  protected onLogin(OrganizationId?: string): void {
    debug && console.log("PhoneOrganizationService.onLogin");
    this.organizationRead({ OrganizationId });
  }
}
