import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { AddContactModule } from "../../../contact/add-contact.module";
import { AvatarModule } from "../avatar/avatar.module";
import { PopupContainerModule } from "../popupContainer/popup-container.module";
import { UserAgentModule } from "../userAgent/user-agent.module";
import { DialpadModule } from "../dialpad/dialpad.module";
import { RemoteIdentityModule } from "../remoteIdentity/remote-identity.module";
import { MaterialModule } from "../../../../modules/material/material.module";
import { FreeTrialModule } from "../freeTrial/free-trial.module";

import { NewCallComponent } from "./new-call.component";
import { NewCallDirective } from "./new-call.directive";
import { SipAddressInputComponent } from "./input/sip-address-input.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    PopupContainerModule,
    ReactiveFormsModule,
    AvatarModule,
    UserAgentModule,
    DialpadModule,
    FreeTrialModule,
    RemoteIdentityModule,
    MaterialModule,
    AddContactModule,
    TranslateModule
  ],
  declarations: [NewCallComponent, NewCallDirective, SipAddressInputComponent],
  exports: [NewCallComponent, NewCallDirective, SipAddressInputComponent]
})
export class NewCallModule {}
