import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnChanges,
  OnInit
} from "@angular/core";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { AccountWithSubAgent } from "../agent-accounts-page.component";
import { FormControl } from "@angular/forms";
import { SubAgentAccountService } from "@onsip/common/services/api/resources/subAgent/sub-agent-account.service";
import { AccountService } from "@onsip/common/services/api/resources/account/account.service";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { DatePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ModalMaterialComponent } from "@onsip/web/features/shared/components/modal/modal-material.component";

@Component({
  selector: "onsip-agent-accounts-shelf",
  templateUrl: "./agent-accounts-shelf.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./agent-accounts-shelf.component.scss"]
})
export class AgentAdminAccountsShelfComponent implements OnInit, OnDestroy, OnChanges {
  @Input() account!: AccountWithSubAgent;
  @Input() agentId!: string;
  @Input() allAccounts!: Array<AccountWithSubAgent>;
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;

  private unsubscriber = new Subscription();
  assignSubAgent = new FormControl<AccountWithSubAgent | undefined>(undefined, {
    nonNullable: true
  });
  hasSubAgent = false;

  constructor(
    private subAgentService: SubAgentAccountService,
    private accountService: AccountService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this.initFields();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  ngOnChanges(): void {
    this.initSelector();
  }

  onSubmit(): void {
    if (!this.hasSubAgent) {
      const assignModal = this.dialog.open(ModalMaterialComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: {
          title: `Are you sure you want to assign Account ${this.account.accountId} to ${this.assignSubAgent.value?.contactName}?`,
          primaryBtnText: "Yes",
          secondaryBtnText: "No",
          primaryBtnFlat: true,
          noSentenceCaseTitle: true
        }
      });

      this.unsubscriber.add(
        assignModal
          .afterClosed()
          .pipe(take(1))
          .subscribe(retObj => {
            if (retObj && retObj.doPrimaryAction) {
              this.execAssignSubAgent(this.assignSubAgent.value);
            }
          })
      );
    } else {
      const unassignModal = this.dialog.open(ModalMaterialComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: {
          title: `Are you sure you want to unassign Account ${this.account.accountId}?`,
          message: `This account will no longer be assigned to ${this.account.subAgentName}.`,
          primaryBtnText: "Yes",
          secondaryBtnText: "No",
          primaryBtnFlat: true,
          noSentenceCaseTitle: true
        }
      });

      this.unsubscriber.add(
        unassignModal
          .afterClosed()
          .pipe(take(1))
          .subscribe(retObj => {
            if (retObj && retObj.doPrimaryAction) {
              this.execUnassignSubAgent();
            }
          })
      );
    }
  }

  formatDate(dateString: string | undefined): string {
    if (!dateString) {
      return "";
    }
    const date = new Date(dateString);
    const dateTrans = this.datePipe.transform(date, "MM.dd.yy HH:mm:ss", "ET") + " ET";
    return dateTrans;
  }

  private initFields(): void {
    if (this.account.subAgentAccountId) {
      this.hasSubAgent = true;
    } else {
      this.hasSubAgent = false;
      this.initSelector();
    }
    this.cdRef.markForCheck();
  }

  private initSelector(): void {
    if (this.allAccounts) {
      // initialize to subAgent that isn't the current account unless it's the only account
      let i = 0;
      while (i < this.allAccounts.length && this.allAccounts[i] === this.account) {
        i += 1;
      }
      if (i === this.allAccounts.length) {
        this.assignSubAgent.setValue(this.allAccounts[this.allAccounts.length - 1]);
      } else {
        this.assignSubAgent.setValue(this.allAccounts[i]);
      }
      this.cdRef.markForCheck();
    }
  }

  private showSuccessSnackbar(subAgentAccountId: string, actionType: "assign" | "unassign"): void {
    this.snackbar.openSnackBar(
      actionType === "assign"
        ? `Account ${subAgentAccountId} has been successfully assigned.`
        : `Account ${subAgentAccountId} has been successfully unassigned.`,
      "success"
    );
  }

  private showErrorSnackbar(message: string): void {
    this.snackbar.openSnackBar(message, "error");
  }

  private execAssignSubAgent(subAgentAccount: AccountWithSubAgent | undefined) {
    if (subAgentAccount && subAgentAccount.agentId) {
      const addParams = {
        AgentId: subAgentAccount.agentId,
        AccountId: subAgentAccount.accountId,
        SubAccountId: this.account.accountId
      };
      this.subAgentService.subAgentAccountAdd(addParams).then(res => {
        if (res.status === "success") {
          this.showSuccessSnackbar(subAgentAccount.accountId, "assign");
          this.accountAndSubAgentAccountBrowse();
          this.hasSubAgent = true;
          this.cdRef.markForCheck();
        } else {
          this.showErrorSnackbar(res.data.message);
          this.subAgentService.clearErrors();
        }
      });
    } else {
      console.error("Does not have an agent id");
      this.showErrorSnackbar("Does not have an agent id");
    }
  }

  private execUnassignSubAgent(): void {
    if (this.account.agentId && this.account.subAgentAccountId) {
      const delParams = {
        AgentId: this.account.agentId,
        AccountId: this.account.subAgentAccountId,
        SubAccountId: this.account.accountId
      };
      this.subAgentService.subAgentAccountDelete(delParams).then(res => {
        if (res.status === "success") {
          this.showSuccessSnackbar(delParams.AccountId, "unassign");
          this.accountAndSubAgentAccountBrowse();
          this.hasSubAgent = false;
          this.cdRef.markForCheck();
        } else {
          this.showErrorSnackbar(res.data.message);
          this.subAgentService.clearErrors();
        }
      });
    }
  }

  private accountAndSubAgentAccountBrowse(): void {
    this.subAgentService.subAgentAccountBrowse({ AgentId: this.account.agentId });
    this.accountService.accountBrowse();
  }
}
