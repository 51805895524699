import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { MaterialModule } from "../../modules/material/material.module";

import { VoicemailComponent } from "./voicemail.component";
import { VoicemailUploadFormComponent } from "./uploadForm/voicemail-upload-form.component";
import { VoicemailMessageAudioModule } from "./messageAudio/voicemail-message-audio.module";

import { AvatarModule } from "../shared/components/avatar/avatar.module";
import { ModalModule } from "../shared/components/modal/modal.module";
import { NewCallModule } from "../shared/components/newCall/new-call.module";
import { PipesModule } from "../shared/pipes/pipes.module";
import { RemoteIdentityModule } from "../shared/components/remoteIdentity/remote-identity.module";
import { UsabilityModule } from "../shared/components/usability/usability.module";
import { UserAgentModule } from "../shared/components/userAgent/user-agent.module";
import { VolumeModule } from "../shared/components/volume/volume.module";

import { TranslateModule } from "@ngx-translate/core";
import { TableModule } from "../shared/components/table/onsip-table.module";
import { AddContactModule } from "../contact/add-contact.module";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    AvatarModule,
    ModalModule,
    NewCallModule,
    PipesModule,
    RemoteIdentityModule,
    TranslateModule,
    UsabilityModule,
    UserAgentModule,
    VolumeModule,
    VoicemailMessageAudioModule,
    TableModule,
    AddContactModule
  ],
  declarations: [VoicemailComponent, VoicemailUploadFormComponent],
  exports: [VoicemailComponent]
})
export class VoicemailModule {}
