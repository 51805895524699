<mat-card>
  <mat-card-content>
    @if (loading | async) {
    <span class="entry-overlay"><mat-spinner></mat-spinner></span>
    }
    <form class="entry-form">
      @if (requestComplete | async) {
      <mat-card-title>{{ "ONSIP_I18N.THANKS" | translate }}</mat-card-title>
      <mat-card-subtitle>{{
        "ONSIP_I18N.PLEASE_CHECK_YOUR_EMAIL_FOR_FURTHER_INSTRUCTIONS" | translate
      }}</mat-card-subtitle>
      } @else {
      <mat-form-field onsip-form-field-padding-fix>
        <mat-label>{{ "ONSIP_I18N.ENTER_YOUR_EMAIL_ADDRESS" | translate }}</mat-label>
        <input
          #email
          matInput
          type="text"
          autocomplete
          placeholder="username@example.com"
          autofocus
          [formControl]="emailControl"
          [errorStateMatcher]="emailErrorStateMatcher"
          (keydown.Enter)="submit($event)"
          (keydown)="resetErrors()"
          (click)="resetErrors()"
        />
      </mat-form-field>
      }
      <div class="entry-button-container">
        <button
          mat-button
          routerLink="{{ prephoneViews.LOGIN }}"
          routerLinkActive="selected"
          [routerLinkActiveOptions]="{ exact: true }"
          [disabled]="loading | async"
        >
          {{ "ONSIP_I18N.GO_BACK" | translate | uppercase }}
        </button>
        @if ((requestComplete | async) === false) {
        <button
          mat-raised-button
          color="primary"
          [disabled]="loading | async"
          (keydown.Enter)="submit($event)"
          (click)="submit($event)"
        >
          {{ "ONSIP_I18N.SEND_EMAIL" | translate | uppercase }}
        </button>
        }
      </div>
    </form>
  </mat-card-content>
</mat-card>
