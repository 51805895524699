import { NgZone, Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { filter, take } from "rxjs/operators";

import { CallEvent, isNewCallEvent, isEndCallEvent } from "../../libraries/sip/call-event";
import { CallState } from "../../libraries/firebase/store/call-state";
import { EventStateEmitter } from "../../libraries/emitter/event-state-emitter";
import { CallControllerService } from "../../services/call-controller.service";
import { CallState as SIPCallState } from "../../libraries/sip/call-state";
import { compareTimestamp } from "../../libraries/firebase/store/timestamp";

import { AuthService } from "./auth.service";
import { Call } from "./lib/call";

import { WebCallHistoryService } from "../web-call-history.service";

export { CallState };

@Injectable({ providedIn: "root" })
export class FirestoreCallService extends EventStateEmitter<CallEvent, Array<CallState>> {
  // Calls we have added specifically that we are subscribed to.
  // Typically these are calls we are currently actively on.
  protected callMap = new Map<string, Call>();

  private unsubscriber = new Subscription();
  constructor(
    private auth: AuthService,
    private callController: CallControllerService,
    private webCallHistoryService: WebCallHistoryService,
    private zone: NgZone
  ) {
    super([]);
    this.init();
  }

  dispose(): void {
    this.publishStateComplete();
    this.unsubscriber.unsubscribe();
    this.callMap.forEach(call => {
      call.dispose();
    });
  }

  /**
   * Gets an RTC Call given a CallState if available, otherwise returns undefined.
   * @param cid The RTC Call ID of the call to listen to.
   */
  getCall(cid: string): Call | undefined {
    return this.callMap.get(cid);
  }

  getCallFromSIPCallState(state: SIPCallState): Call | undefined {
    if (state.xData === undefined) {
      return undefined;
    }
    const data = state.xData.split("=", 2);
    if (data.length < 2 || data[0] !== "CID") {
      return undefined;
    }
    const cid = data[1];

    return cid ? this.callMap.get(cid) : undefined;
  }

  getCallFromEvent(event: CallEvent): Call | undefined {
    const state = this.callController.getCallStateByUuid(event.uuid);
    if (state === undefined) {
      return undefined;
    }
    const rtcCallId = this.getCallId(state);
    if (rtcCallId === undefined) {
      return undefined;
    }
    return this.getCall(rtcCallId);
  }

  /**
   * Returns a RTC Call ID given a SIP Call State.
   * If the SIP Call State does not contain a RTC Call ID, returns undefined.
   * @param state The SIP Call State
   */
  getCallId(state: SIPCallState): string | undefined {
    if (state.xData === undefined) {
      return undefined;
    }
    const data = state.xData.split("=", 2);
    if (data.length < 2 || data[0] !== "CID") {
      return undefined;
    }
    const cid = data[1];
    return cid;
  }

  protected publishRun(fn: () => any): any {
    return this.zone.run(() => {
      return fn();
    });
  }

  /**
   * For a given RTC Call ID adds an RTC Call set of calls being listened to.
   * @param cid The RTC Call ID  of the call to listen to.
   */
  private addCall(cid: string): void {
    // already listening
    if (this.callMap.has(cid)) {
      return;
    }

    // make new one and add it
    const call = new Call(this.zone);
    this.callMap.set(cid, call);

    call.state.pipe(filter(state => state.id !== "")).subscribe({
      next: callState => {
        const idx = this.stateStore.findIndex(innerCall => innerCall.id === cid);
        if (idx === -1) {
          this.stateStore.push(callState);
        } else {
          this.stateStore[idx] = callState;
        }
        this.stateStore = this.stateStore.sort((a, b) => compareTimestamp(b.created, a.created));
        this.publishState();
      },
      error: (error: unknown) => {
        throw error;
      },
      complete: () => {
        const idx = this.stateStore.findIndex(innerCall => innerCall.id === cid);
        if (idx !== -1) {
          this.stateStore.splice(idx, 1);
          this.publishState();
        }
      }
    });

    // make sure we are signed in before starting
    this.unsubscriber.add(
      this.auth.state
        .pipe(
          filter(state => state.status === "signedIn"),
          take(1)
        )
        .subscribe(state => {
          const oid = state.oid;
          if (!oid) {
            throw new Error("oid is undefined.");
          }
          call.start(oid, cid);
        })
    );
  }

  private init(): void {
    Promise.resolve().then(() => {
      this.subscribeUserAgent();
    });
  }

  private subscribeUserAgent(): void {
    this.unsubscriber.add(
      this.callController.getCallEventObservable().subscribe(event => {
        const state = this.callController.getCallStateByUuid(event.uuid),
          rtcCallId = state ? this.getCallId(state) : undefined;
        if (!rtcCallId) {
          if (isNewCallEvent(event)) {
            this.publishEvent(event);
          }
          return;
        }
        if (isNewCallEvent(event)) {
          // Start tracking RTC Call information; outgoing calls dont need to go into firestore
          const newCallState = this.callController.getCallStateByUuid(event.uuid);
          if (newCallState && newCallState.incoming) {
            this.addCall(rtcCallId);
          }
          this.publishEvent(event);
        } else if (isEndCallEvent(event)) {
          // Stop tracking RTC Call information
          const call = this.callMap.get(rtcCallId);
          if (call) {
            this.webCallHistoryService.addNewSaysoCall(call);
            call.dispose();
          }
          this.publishEvent(event);
        }
      })
    );
  }
}
