import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { take, map } from "rxjs/operators";
import { Observable, Subscription } from "rxjs";

import { ModalMaterialComponent } from "../modal/modal-material.component";
import { TermsAndConditionsService } from "../../../../../common/services/api/resources/termsAndConditions/terms-and-conditions.service";
import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import {
  E911Service,
  E911Location
} from "../../../../../common/services/api/resources/e911/e911.service";
import { UserAddressService } from "../../../../../common/services/api/resources/userAddress/user-address.service";
import { SnackbarService } from "../snackbar/snackbar.service";
import { AdminE911AddLocationModalComponent } from "@onsip/web/features/administrators/e911/addLocationModal/add-location-modal.component";

@Component({
  selector: "onsip-e911",
  templateUrl: "./e911.component.html",
  styleUrls: ["./e911.scss"],
  providers: [TitleCasePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class E911Component implements OnInit, OnDestroy {
  @Input() showTitle = false;
  termsAccepted!: Observable<boolean>;
  e911Locs: Array<E911Location> = [];
  formData: { myE911LocId: string | undefined } = {
    myE911LocId: undefined
  };

  private userAor!: string;
  private userId!: string;
  private orgId!: string;
  private currentE911LocId!: string;
  protected unsubscriber = new Subscription();

  constructor(
    private dialog: MatDialog,
    private e911Service: E911Service,
    private tacService: TermsAndConditionsService,
    private userService: UserService,
    private userAddress: UserAddressService,
    private snackbar: SnackbarService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.termsAccepted = this.userService.selfUser.pipe(
      map(user => new Date(user.ackHostedE911) > new Date(user.created))
    );

    this.unsubscriber.add(
      this.e911Service.selfE911Locations.subscribe(locs => {
        this.e911Locs = locs;
      })
    );

    this.unsubscriber.add(
      this.userAddress.selfUser.subscribe(userAdds => {
        // we only assign E911 Location to the main user address and not its alias
        const userAdd = userAdds.sort(
          (a, b) => parseInt(a.userAddressId) - parseInt(b.userAddressId)
        )[0];
        this.formData.myE911LocId = userAdd.e911LocationId;
        this.currentE911LocId = userAdd.e911LocationId;
        this.userAor = userAdd.aor;
        this.userId = userAdd.userId;
        this.cdRef.markForCheck();
      })
    );

    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.orgId = user.organizationId;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  selectE911Loc(E911LocationId: string): void {
    this.e911Service
      .e911LocationAssign({
        Address: this.userAor,
        E911LocationId
      })
      .then(result => {
        if (result.status === "success") {
          // update old e911 Location if any
          if (this.currentE911LocId) this.e911Service.e911LocationRead(this.currentE911LocId);
          // update new e911Location if any
          if (E911LocationId) this.e911Service.e911LocationRead(E911LocationId);
          // call to update current user
          this.userAddress.userAddressBrowse({ UserId: this.userId });
        } else {
          this.snackbar.openSnackBar("Could not assign selected E911 location.", "error");
          this.e911Service.clearErrors();
        }
      });
  }

  viewTerms(): void {
    // Open terms & conditions dialog
    // fetch terms and conditions
    this.tacService.termsAndConditionsRead().then(result => {
      if (result.status === "success") {
        const tac = Object.values(result.data)[0];
        const modal = this.dialog.open(ModalMaterialComponent, {
          panelClass: ["mat-typography", "onsip-dialog-universal-style"],
          data: {
            title: "E-911 Terms and Conditions",
            message: tac.text,
            primaryBtnText: "ACCEPT",
            primaryBtnFlat: true
          }
        });
        this.unsubscriber.add(
          modal
            .afterClosed()
            .pipe(take(1))
            .subscribe(response => {
              if (response && response.doPrimaryAction) {
                // Accepted Terms and conditions
                this.userService.userAckHostedE911();
                // need to call userRead to update service state
                this.userService.userRead();
              }
            })
        );
      }
    });
  }

  openAddLocationModal() {
    this.dialog.open(AdminE911AddLocationModalComponent, {
      panelClass: ["mat-typography", "onsip-dialog-universal-style"],
      disableClose: true,
      data: { addPrivateLocation: true, userId: this.userId, orgId: this.orgId }
    });
  }
}
