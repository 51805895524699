import { Component, OnInit, OnDestroy, HostBinding } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { UserService } from "../../../../../common/services/api/resources/user/user.service";
import { HubspotApiService } from "./services/hubspot-api.service";
import { TranslateService } from "@ngx-translate/core";

import { isAdminRole } from "../../../../../common/services/api/role";
import { ModalMaterialComponent, ModalMaterialReturnData } from "../modal/modal-material.component";

@Component({
  selector: "onsip-hubspot-authorized-toggle",
  templateUrl: "hubspot-authorized-toggle.component.html"
})
export class HubspotAuthorizedToggleComponent implements OnInit, OnDestroy {
  @HostBinding("class.onsip-hubspot-authorized-toggle")
  _dontUse = true;

  isAdmin = false;
  _authorized = false;

  private unsubscriber = new Subscription();

  constructor(
    private userService: UserService,
    private hubspotApi: HubspotApiService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  setAuthorized(enabled: boolean) {
    if (enabled) {
      this._authorized = true;
      this.hubspotApi.authorize().catch(error => {
        this._authorized = false;
        throw error;
      });
    } else {
      const dialog = this.dialog.open(ModalMaterialComponent, {
        panelClass: ["mat-typography", "onsip-dialog-universal-style"],
        data: {
          title: "Are you sure you want to disable Hubspot?",
          message:
            "Disabling Hubspot integration will disable Hubspot for your entire organization. You will need to be a Hubspot admin to re-enable it. Are you sure you want to proceed?",
          primaryBtnText: "Disable Hubspot",
          primaryBtnFlat: true,
          secondaryBtnText: "Back",
          noSentenceCaseTitle: true,
          noSentenceCasePrimaryBtn: true
        }
      });
      this.unsubscriber.add(
        dialog.afterClosed().subscribe((response: ModalMaterialReturnData) => {
          if (response && response.doPrimaryAction === true) {
            this._authorized = false;
            this.hubspotApi.revoke().catch(error => {
              this._authorized = false;
              throw error;
            });
          } else {
            // hack for change detection
            this._authorized = false;
            setTimeout(() => (this._authorized = true));
          }
        })
      );
    }
  }

  get tooltipText(): string {
    if (!this._authorized) {
      return this.translate.instant("ONSIP_I18N.ENABLE_HUBSPOT");
    } else if (this.isAdmin) {
      return this.translate.instant("ONSIP_I18N.DISABLE_HUBSPOT");
    } else {
      return this.translate.instant("ONSIP_I18N.CONTACT_YOUR_ADMIN_TO_DISABLE_HUBSPOT");
    }
  }

  private init(): void {
    this.unsubscriber.add(
      this.hubspotApi.state
        .pipe(map(state => state.authorized))
        .subscribe(authorized => (this._authorized = authorized))
    );

    this.unsubscriber.add(
      this.userService.selfUser.subscribe(user => {
        this.isAdmin = isAdminRole(user.roles);
      })
    );
  }
}
