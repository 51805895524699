import { Injectable } from "@angular/core";
import { ApiSessionService } from "../../../../common/services/api/api-session.service";
import { distinctUntilChanged } from "rxjs/operators";
import { ApiStateStoreService } from "../../../../common/services/api/api-state-store.service";
import { isAtLeastAccountAdminRole, Role } from "../../../../common/services/api/role";
import { AccountService } from "../../../../common/services/api/resources/account/account.service";
import { ApiPromiseStateService } from "../../../../common/services/api/api-promise-state.service";

const debug = false;

@Injectable({ providedIn: "root" })
export class PhoneAccountService extends AccountService {
  private isAdmin = false;
  constructor(
    session: ApiSessionService,
    store: ApiStateStoreService,
    promiseState: ApiPromiseStateService
  ) {
    super(session, store, promiseState);

    session.state
      .pipe(
        distinctUntilChanged(
          (a, b) => a.sessionId === b.sessionId && a.parentUserId === b.parentUserId
        )
      )
      .subscribe(state => {
        if (state.loggedIn && !!state.sessionId) {
          if (isAtLeastAccountAdminRole([state.role as Role])) this.isAdmin = true;
          this.onLogin(state.accountId);
        } else if (!state.loggedIn) this.dispose();
      });
  }

  protected onLogin(AccountId?: string): void {
    debug && console.log("PhoneAccountService.onLogin");
    // only do an account read if user is an admin
    if (this.isAdmin) this.accountRead({ AccountId });
  }
}
