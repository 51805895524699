import { PlatformFirebase } from "../cloud/firebase/database/firebase-database";
import { PresencePublisher } from "./presence-publisher";
import { firebase } from "../cloud/firebase/platform-firebase-types";

export class OAuthPresencePublisher extends PresencePublisher {
  private static makePublishRef(id: string): firebase.database.Reference {
    return PlatformFirebase.firebase.database().ref(`oauth/${id}`);
  }

  /**
   * Constructor
   * @param id The unique oauth session id
   */
  constructor(id: string, private val: string) {
    super(OAuthPresencePublisher.makePublishRef(id));
  }

  protected get id(): string {
    return `OAuthPresencePublisher[${this.publishRef.key}]`;
  }

  protected get value(): string {
    return this.val;
  }
}
