<onsip-loading-container [deferCond]="loadingStateComplete$">
  <div class="sip-addresses-controls">
    <div class="left">
      <div class="card-section">
        <span
          class="label"
          [matTooltip]="'ONSIP_I18N.APP_CALLING_ALLOWS_CALLS_TO_AND_FROM_THE_APP' | translate"
          matTooltipClass="onsip-tooltip-background"
          matTooltipPosition="right"
        >
          {{ "ONSIP_I18N.APP_CALLING" | translate }}
        </span>
        <mat-slide-toggle
          color="primary"
          class="user-settings-toggle"
          [checked]="appCallingEnabled$ | async"
          (change)="toggleDesktopMode($event)"
          disableRipple
          [disabled]="!isWebrtcSupported"
        >
        </mat-slide-toggle>
      </div>
      <div class="card-section">
        <span
          class="label"
          [matTooltip]="'ONSIP_I18N.DO_NOT_DISTURB_DISABLES_CALLS_IN_THE_APP' | translate"
          matTooltipClass="onsip-tooltip-background"
          matTooltipPosition="right"
        >
          {{ "ONSIP_I18N.DO_NOT_DISTURB" | translate }}
        </span>
        <mat-slide-toggle
          class="user-settings-toggle user-settings-sip-toggle"
          (change)="toggleDnd($event)"
          [checked]="dndStream$ | async"
          color="primary"
          [disabled]="!isWebrtcSupported || (appCallingEnabled$ | async) === false"
        >
        </mat-slide-toggle>
      </div>
      @if ((userAgentStates$ | async)!.length > 1) {
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.SIP_ADDRESSES" | translate }}
        </span>
        <div class="sip-addresses-container">
          @for (userAgentState of userAgentStates$ | async; track userAgentState) {
          <div class="checkbox-control-setting">
            <mat-checkbox
              color="primary"
              (change)="toggleRegistration(userAgentState.aor, $event.checked)"
              [checked]="userAgentState.shouldBeRegistered"
              [disabled]="!isWebrtcSupported || (appCallingEnabled$ | async) === false"
              onsip-checkbox-thin-border
            >
              {{ userAgentState.aor }}
            </mat-checkbox>
          </div>
          }
        </div>
      </div>
      }
    </div>
    <div class="right">
      <div class="card-section">
        <span class="label">
          {{ "ONSIP_I18N.PRIMARY_SIP_ADDRESS" | translate }}
        </span>
        <mat-form-field
          appearance="outline"
          onsip-form-field-flush-outline
          onsip-form-field-outline-white
          onsip-mat-select-small-height
          class="settings-form large-width"
        >
          <mat-select [formControl]="defaultUAFormControl" (selectionChange)="changeUA($event)">
            @for (userAgentState of userAgentStates$ | async; track userAgentState) {
            <mat-option [value]="userAgentState.aor">
              {{ userAgentState.aor }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="card-section horizontal-card-section">
        <span class="label">
          {{ "ONSIP_I18N.AUTOMATICALLY_ANSWER_CALLS" | translate }}
        </span>
        <mat-slide-toggle
          color="primary"
          class="user-settings-toggle"
          [checked]="autoAnswerEnabled$ | async"
          disableRipple
          [disabled]="!isChrome || !isWebrtcSupported || (appCallingEnabled$ | async) === false"
          matTooltipClass="onsip-tooltip-background"
          (change)="toggleAutoAnswer($event)"
          [matTooltip]="
            !isChrome || !isWebrtcSupported || (appCallingEnabled$ | async) === false
              ? ('ONSIP_I18N.YOUR_BROWSER_DOES_NOT_SUPPORT_THIS_FUNCTION' | translate)
              : ''
          "
        >
        </mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="sip-username-password-details">
    <div class="left card-section">
      <span class="label">
        {{ "ONSIP_I18N.AUTH_USERNAME" | translate }}
      </span>
      <p class="mat-body-2">{{ authUsername$ | async }}</p>
    </div>
    <div class="right card-section">
      <span class="label">
        {{ "ONSIP_I18N.SIP_PASSWORD" | translate }}
      </span>
      <div
        (mouseenter)="showAuthPassword = true"
        (mouseleave)="showAuthPassword = false"
        class="user-settings-hover-password"
      >
        @if (!showAuthPassword) {
        <span id="onsip-user-settings-sip-password">
          {{ "ONSIP_I18N.HOVER_TO_REVEAL" | translate }}
        </span>
        } @if (showAuthPassword) {
        <span id="onsip-user-settings-sip-password">
          {{ authPassword$ | async }}
        </span>
        }
      </div>
    </div>
  </div>
</onsip-loading-container>
