import {
  OnsipAPIArray,
  onsipApiArrayToArray,
  OnsipAPIBoolean,
  onsipApiBooleanToBoolean
} from "../../apiResponse/xml-json";

export declare interface ApiAccountDetails {
  ComplementaryPackages: OnsipAPIArray<"Package", ApiAccountDetailsPackage>;
  NoPrepaidCredit: OnsipAPIBoolean;
  PerUserPricing: OnsipAPIBoolean;
  PrepaidBalance: string;
  PrimaryPackages: OnsipAPIArray<"Package", ApiAccountDetailsPackage>;
  SecondsBalance: string;
}

export declare interface AccountDetails {
  complementaryPackages: Array<AccountDetailsPackage>;
  noPrepaidCredit: boolean;
  perUserPricing: boolean;
  prepaidBalance: string;
  primaryPackages: Array<AccountDetailsPackage>;
  secondsBalance: string;
}

export declare interface ApiAccountDetailsPackage {
  Description: string;
  Name: string;
}

export declare interface AccountDetailsPackage {
  description: string;
  name: string;
}

export function apiAccountDetailsToAccountDetails(
  accountDetails: ApiAccountDetails
): AccountDetails {
  return {
    complementaryPackages: onsipApiArrayToArray(
      accountDetails.ComplementaryPackages,
      "Package"
    ).map(apiAccountDetailsPackageToAccountDetailsPackage),
    noPrepaidCredit: onsipApiBooleanToBoolean(accountDetails.NoPrepaidCredit),
    perUserPricing: onsipApiBooleanToBoolean(accountDetails.PerUserPricing),
    prepaidBalance: accountDetails.PrepaidBalance,
    primaryPackages: onsipApiArrayToArray(accountDetails.PrimaryPackages, "Package").map(
      apiAccountDetailsPackageToAccountDetailsPackage
    ),
    secondsBalance: accountDetails.SecondsBalance
  };
}

export function apiAccountDetailsPackageToAccountDetailsPackage(
  pack: ApiAccountDetailsPackage
): AccountDetailsPackage {
  return {
    description: pack.Description,
    name: pack.Name
  };
}
