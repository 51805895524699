import { RecentCallsComponent } from "./recent-calls.component";

import { AddContactModule } from "../../../contact/add-contact.module";
import { NewCallModule } from "../newCall/new-call.module";
import { PipesModule } from "../../pipes/pipes.module";
import { UsabilityModule } from "../usability/usability.module";

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { MaterialModule } from "../../../../modules/material/material.module";
import { TranslateModule } from "@ngx-translate/core";
import { TableModule } from "../table/onsip-table.module";
import { AnimationModule } from "../animation/animation.module";
// Charting

@NgModule({
  imports: [
    AddContactModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    NewCallModule,
    PipesModule,
    UsabilityModule,
    MaterialModule,
    TranslateModule,
    TableModule,
    AnimationModule
  ],
  declarations: [RecentCallsComponent],
  exports: [RecentCallsComponent]
})
export class CallHistoryModule {}
