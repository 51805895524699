import {
  OnsipAPIArray,
  onsipApiArrayToArray,
  OnsipAPIBoolean,
  onsipApiBooleanToBoolean
} from "../../apiResponse/xml-json";

export interface ApiAdvAttendantMenu {
  AccountId: string;
  AdvAttendantMenuId: string;
  AdvAttendantMenuOptions: OnsipAPIArray<"AdvAttendantMenuOption", ApiAdvAttendantMenuOption>;
  AppId: string;
  Created: string;
  DigitTimeout: string;
  IncludeExtensions: OnsipAPIBoolean;
  Modified: string;
  Name: string;
  OrganizationId: string;
  RecordingId: string;
  RecordingName: string;
  RecordingNumber: string;
  ResponseTimeout: string;
}

export interface ApiAdvAttendantMenuOption {
  AddressDomain: string;
  AddressType: string;
  AddressUsername: string;
  Option: string;
  SoundFileOption: string;
}

export interface AdvAttendantMenu {
  accountId: string;
  advAttendantMenuId: string;
  advAttendantMenuOptions: Array<AdvAttendantMenuOption>;
  appId: string;
  created: string;
  digitTimeout: string;
  includeExtensions: boolean;
  modified: string;
  name: string;
  organizationId: string;
  recordingId: string;
  recordingName: string;
  recordingNumber: string;
  responseTimeout: string;
  //addressId need for mapping data
  addressId?: string;
}

export interface AdvAttendantMenuOption {
  addressDomain: string;
  addressType: string;
  addressUsername: string;
  option: string;
  soundFileOption: string;
}

export function apiAdvAttendantMenuToAdvAttendantMenu(
  apiAdvAttendantMenu: ApiAdvAttendantMenu
): AdvAttendantMenu {
  return {
    accountId: apiAdvAttendantMenu.AccountId,
    advAttendantMenuId: apiAdvAttendantMenu.AdvAttendantMenuId,
    advAttendantMenuOptions: onsipApiArrayToArray(
      apiAdvAttendantMenu.AdvAttendantMenuOptions,
      "AdvAttendantMenuOption"
    ).map(apiAdvAttendantMenuOptionToAdvAttendantMenuOption),
    appId: apiAdvAttendantMenu.AppId,
    created: apiAdvAttendantMenu.Created,
    digitTimeout: apiAdvAttendantMenu.DigitTimeout,
    includeExtensions: onsipApiBooleanToBoolean(apiAdvAttendantMenu.IncludeExtensions),
    modified: apiAdvAttendantMenu.Modified,
    name: apiAdvAttendantMenu.Name,
    organizationId: apiAdvAttendantMenu.OrganizationId,
    recordingId: apiAdvAttendantMenu.RecordingId,
    recordingName: apiAdvAttendantMenu.RecordingName,
    recordingNumber: apiAdvAttendantMenu.RecordingNumber,
    responseTimeout: apiAdvAttendantMenu.ResponseTimeout
  };
}

export function apiAdvAttendantMenuOptionToAdvAttendantMenuOption(
  apiAdvAttendantMenuOption: ApiAdvAttendantMenuOption
): AdvAttendantMenuOption {
  return {
    addressDomain: apiAdvAttendantMenuOption.AddressDomain,
    addressType: apiAdvAttendantMenuOption.AddressType,
    addressUsername: apiAdvAttendantMenuOption.AddressUsername,
    option: apiAdvAttendantMenuOption.Option,
    soundFileOption: apiAdvAttendantMenuOption.SoundFileOption
  };
}

export interface AdvAttendantMenuParams {
  Name: string;
  RecordingNumber: string;
  ResponseTimeout: number;
  IncludeExtensions: boolean;
  OptionTimeout: string;
  OptionNumberSign?: string;
  OptionAsterisk?: string;
  Option0?: string;
  Option1?: string;
  Option2?: string;
  Option3?: string;
  Option4?: string;
  Option5?: string;
  Option6?: string;
  Option7?: string;
  Option8?: string;
  Option9?: string;
  SoundFileOptionNumberSign?: string;
  SoundFileOptionAsterisk?: string;
  SoundFileOption0?: string;
  SoundFileOption1?: string;
  SoundFileOption2?: string;
  SoundFileOption3?: string;
  SoundFileOption4?: string;
  SoundFileOption5?: string;
  SoundFileOption6?: string;
  SoundFileOption7?: string;
  SoundFileOption8?: string;
  SoundFileOption9?: string;
}

export interface AddAdvAttendantMenuParams extends AdvAttendantMenuParams {
  Username: string;
}

export interface EditAdvAttendantMenuParams extends AdvAttendantMenuParams {
  AdvAttendantMenuId: string;
}
