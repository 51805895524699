import { Injectable } from "@angular/core";
import { Router, RouterEvent } from "@angular/router";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, withLatestFrom, map, take } from "rxjs/operators";
import { User, UserService } from "./api/resources/user/user.service";
import { getHighestRolePriority, isSubAgentAdmin, isSuperAdmin, Role } from "./api/role";
import { views } from "@onsip/web/app/phone/views";
import { ApiSessionService } from "./api/api-session.service";
import { SnackbarService } from "@onsip/web/features/shared/components/snackbar/snackbar.service";
import { Config } from "../config";

@Injectable({ providedIn: "root" })
export class SuperAdminNavigationService {
  showSuperAdminButton = new BehaviorSubject(false);
  superAdminButtonRoute = new BehaviorSubject("");
  parentUser!: Observable<User | undefined>;

  constructor(
    private router: Router,
    private userService: UserService,
    private apiSessionService: ApiSessionService,
    private snackbarService: SnackbarService
  ) {
    this.parentUser = combineLatest({
      apiSession: this.apiSessionService.state,
      users: this.userService.state.pipe(
        filter(state => !state.loading),
        map(state => Object.values(state.state))
      )
    }).pipe(
      map(({ apiSession, users }) => {
        const parentUserId = apiSession.parentUserId;
        if (parentUserId) {
          const parentUser = users.find(user => user.userId === parentUserId);
          return parentUser;
        }
        return undefined;
      })
    );

    this.router.events
      .pipe(
        filter(event => event instanceof RouterEvent),
        withLatestFrom(this.userService.selfUser, this.parentUser)
      )
      .subscribe(([event, user, parentUser]) => {
        // do not show admin portal link if in desktop
        if (Config.IS_DESKTOP) return;
        // do not show admin portal link for user if spoofing from a subagent role.
        if (parentUser && isSubAgentAdmin(parentUser.roles)) return;
        const hasSuperAdminPriv = isSuperAdmin(user.roles);
        if (hasSuperAdminPriv && event instanceof RouterEvent) {
          const currentUrl = event.url;
          const superAdminRoute = this.getSuperAdminRoute(user.roles);
          // agent views have children route so only use the parent route to compare
          const parentAdminRoute = superAdminRoute.split("/")[1];
          const showSuperAdminButton = !currentUrl.includes(parentAdminRoute);
          this.showSuperAdminButton.next(showSuperAdminButton);
          if (showSuperAdminButton) this.superAdminButtonRoute.next(superAdminRoute);
        }
      });
  }

  get showSuperAdminButtonObs(): Observable<boolean> {
    return this.showSuperAdminButton.asObservable();
  }

  get superAdminButtonRouteObs(): Observable<string> {
    return this.superAdminButtonRoute.asObservable();
  }

  /** help determine which session restore user action to use and then perform navigate */
  goBackToAdminViewNav(): void {
    this.getParentUserRole()
      .pipe(take(1))
      .subscribe(role => {
        if (!role) {
          this.snackbarService.openSnackBar("Cannot find previous session's user.", "error");
          return;
        }
        if (role === Role.SuperUser) {
          this.apiSessionService
            .sessionRestoreUser()
            .then(() => {
              this.router.navigate([views.SUPER_USER_VIEW]);
            })
            .catch(err => this.snackbarService.openSnackBar(err, "error"));
        } else {
          // has to be an agent if not a super user so navigate to the agent view
          this.apiSessionService
            .agentSessionRestoreUser()
            .then(() => {
              this.router.navigate([views.AGENT_ACCOUNTS]);
            })
            .catch(err => this.snackbarService.openSnackBar(err, "error"));
        }
      });
  }

  getParentUserRole(): Observable<Role | undefined> {
    return combineLatest({
      apiSession: this.apiSessionService.state,
      users: this.userService.state.pipe(
        filter(state => !state.loading),
        map(state => Object.values(state.state))
      )
    }).pipe(
      map(({ apiSession, users }) => {
        const parentUserId = apiSession.parentUserId;
        const parentUser = users.find(user => user.userId === parentUserId);
        if (parentUser) {
          return getHighestRolePriority(parentUser.roles);
        }
        return undefined;
      })
    );
  }

  private getSuperAdminRoute(roles: Array<Role>): string {
    const role = getHighestRolePriority(roles);
    const routeMap = new Map();
    routeMap.set(Role.SuperUser, views.SUPER_USER_VIEW);
    routeMap.set(Role.AgentAdmin, views.AGENT_ACCOUNTS);
    routeMap.set(Role.SubAgentAdmin, views.AGENT_ACCOUNTS);
    return routeMap.get(role);
  }
}
