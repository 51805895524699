import { Component, ChangeDetectionStrategy } from "@angular/core";

@Component({
  templateUrl: "./connection-issue.component.html",
  styleUrls: ["connection-issue.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})

/** Modal will appear with unsupported browser message */
export class ConnectionIssueModalComponent {}
