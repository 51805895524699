import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HighchartsChartModule } from "highcharts-angular";
import { DonutGraphComponent } from "./donut-graph.component";

@NgModule({
  imports: [CommonModule, HighchartsChartModule],
  declarations: [DonutGraphComponent],
  exports: [DonutGraphComponent]
})
export class ChartingDonutGraphModule {}
