export interface CdrDateInterval {
  label: string;
  dateStart: string;
  dateEnd: string;
}

const monthes = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const dayOffset = 86400000;

export function generateIntervalOptions(isFullMode = false): Array<CdrDateInterval> {
  return [
    {
      label: "Today",
      dateStart: getISOString(getStartOfDay()),
      dateEnd: new Date().toISOString()
    },
    {
      label: "Yesterday",
      dateStart: getISOString(getStartOfDay(dayOffset)),
      dateEnd: getISOString(new Date(getStartOfDay().getTime() - 1))
    },
    {
      label: "This week",
      dateStart: getISOString(getFirstDayOfWeek()),
      dateEnd: new Date().toISOString()
    },
    {
      label: "Last week",
      dateStart: getISOString(getFirstDayOfWeek(dayOffset * 7)),
      dateEnd: getISOString(
        new Date(getFirstDayOfWeek(dayOffset * 7).getTime() + dayOffset * 7 - 1)
      )
    },
    {
      label: "This month",
      dateStart: getISOString(getFirstDayOfMonth(0)),
      dateEnd: new Date().toISOString()
    },
    ...getMonths(isFullMode)
  ];
}

function getStartOfDay(offset = 0): Date {
  const date = new Date(new Date().getTime() - offset);

  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  date.setHours(0);

  return new Date(date.getTime());
}

function getFirstDayOfWeek(offset = 0): Date {
  const now = getStartOfDay(offset);
  return new Date(now.getTime() - (now.getDay() - 1) * dayOffset);
}

export function clarifyTimezoneOffset(date: Date): Date {
  return new Date(date.getTime() - date.getTimezoneOffset() * 1000 * 60);
}

function getISOString(date: Date): string {
  return clarifyTimezoneOffset(date).toISOString();
}

function getFirstDayOfMonth(offsetMonth: number): Date {
  const firstDayOfMonth = getStartOfDay();
  firstDayOfMonth.setDate(1);
  firstDayOfMonth.setMonth(firstDayOfMonth.getMonth() - offsetMonth);
  return firstDayOfMonth;
}

function getMonths(isFullMode: boolean) {
  const fullYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const halfYear = [1, 2, 3, 4, 5, 6];
  return (isFullMode ? fullYear : halfYear).map(monthOffset => {
    const firstDayOfMonth = getFirstDayOfMonth(monthOffset);
    const lastDayOfMonth = new Date(getFirstDayOfMonth(monthOffset - 1).getTime() - 1);

    return {
      label: `${monthes[firstDayOfMonth.getMonth()]} ${firstDayOfMonth.getFullYear()}`,
      dateStart: getISOString(firstDayOfMonth),
      dateEnd: getISOString(lastDayOfMonth)
    };
  });
}
