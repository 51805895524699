@if (!isCallListItem) {
<div class="screen-share-in-progress">
  <div class="screen-share-in-progress-container">
    <div class="screen-share-box-container">
      <h3>{{ callFromDisplayName }}</h3>
      @if (remoteStream) {
      <onsip-video-box
        class="screen-share-in-progress-box"
        [objectFit]="'contain'"
        [remoteStream]="remoteStream"
      >
      </onsip-video-box>
      }
    </div>
    <div class="screen-share-box-container">
      <h3>{{ callToDisplayName }} (You)</h3>
      @if (!screenShareService.showScreenShare || !mutedVideo) {
      <div
        class="screen-share-in-progress-box"
        (click)="screenShareService.toggleShowScreenShare()"
      >
        <mat-icon>{{ !mutedVideo ? "stop_screen_share" : "screen_share" }}</mat-icon>
        <div class="mat-subtitle-1">
          {{
            !mutedVideo
              ? ("ONSIP_I18N.SCREENSHARE_PAUSED" | translate)
              : ("ONSIP_I18N.SCREENSHARE_IN_PROGRESS" | translate)
          }}
        </div>
      </div>
      } @if (screenShareService.showScreenShare && mutedVideo) {
      <div
        class="screen-share-in-progress-box"
        (click)="screenShareService.toggleShowScreenShare()"
      >
        <onsip-video-box [localStream]="localStream"> </onsip-video-box>
      </div>
      }
    </div>
  </div>
</div>
}
<!-- just show remote stream on call list item when doing screenshare-->
@if (remoteStream && isCallListItem) {
<div class="session-video">
  <onsip-video-box [objectFit]="'contain'" [remoteStream]="remoteStream"> </onsip-video-box>
</div>
}
