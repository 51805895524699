import { Component, Input, OnChanges, OnInit, OnDestroy, HostBinding } from "@angular/core";
import { Validators, NonNullableFormBuilder } from "@angular/forms";
import { customNameValidator } from "./validators/custom-contact-name.validator";
import { customAddressValidator } from "./validators/custom-contact-address.validator";

import { Subscription } from "rxjs";
import { map } from "rxjs/operators";

import { AnalyticsService } from "../shared/components/analytics/analytics.service";
import { ContactService } from "../../../common/services/contact/contact.service";
import { CurrentContactService } from "./current-contact.service";
import { SubscriptionControllerService } from "../../../common/services/subscription-controller.service";
import { SnackbarService } from "../shared/components/snackbar/snackbar.service";
import { UsersStoreSubscriptionService } from "../shared/services/userStoreSubscription/users-store-subscription.service";

import { Contact } from "../../../common/interfaces/contact";
import { Presentity, DialogInfo } from "../../../common/interfaces/presentity";
import { E164PhoneNumber } from "../../../common/libraries/e164-phone-number";
import { OnSIPURI } from "../../../common/libraries/onsip-uri";

import { TranslateService } from "@ngx-translate/core";
import { E2eLocators } from "@onsip/e2e/configs/locators";
import { customContactPatternValidator } from "./validators/custom-contact-name-pattern.validator";

@Component({
  selector: "onsip-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.scss"]
})
export class ContactComponent implements OnChanges, OnInit, OnDestroy {
  @HostBinding("class.onsip-grid-content")
  _dontUse = true;
  @Input() contact!: Contact;
  E2eLocators = E2eLocators;
  /** If true show "rem contact" btn else show "add contact" btn */
  isInContactList = false;
  /** Flag true while wating for api call, prevents user from spamming add/rem */
  isUpdating = false;
  /** Flag to indicate "busy" status */
  isBusy = false;
  /** Flag to indicate user's global available state */
  isAvailable = false;
  /** Array of prettified phone numbers */
  contactPhoneNumbers: Array<string> = [];
  /** Flag if user is in edit mode */
  editing = false;
  /** Custom Contact Edit Form Controls */
  customContactForm = this.fb.group({
    name: [
      "",
      [
        Validators.required,
        customContactPatternValidator(),
        Validators.maxLength(60),
        customNameValidator(this.contactService)
      ]
    ],
    sipAddress: ["", [Validators.required, customAddressValidator()]]
  });

  private dialogSubscriptions: Array<Presentity> = [];
  private unsubscriber = new Subscription();

  constructor(
    private contactService: ContactService,
    private currentContactService: CurrentContactService,
    private analyticsService: AnalyticsService,
    private subscriptionControllerService: SubscriptionControllerService,
    private usersStoreSubscriptionService: UsersStoreSubscriptionService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnChanges(): void {
    if (this.contact) {
      this.isInContactList = this.contact.contactList;
      this.checkDialogStatus();
      this.checkAvailableStatus();
      this.contactPhoneNumbers = [];
      if (this.contact.e164PhoneNumbers && this.contact.e164PhoneNumbers.length > 0) {
        this.contact.e164PhoneNumbers.forEach(num => {
          const phoneNumber: E164PhoneNumber = new E164PhoneNumber(num);

          this.contactPhoneNumbers.push(phoneNumber.e164DisplayNumber);
        });
      }
    }

    this.editing = false; // don't want to keep form open when switching between contacts
    this.customContactFormReset(); // reset form and fill values
    this.isUpdating = false;
  }

  ngOnInit(): void {
    // Look for contact name in contact list on contactListService updates
    this.unsubscriber.add(
      this.contactService.getContactList$().subscribe(contacts => {
        this.isInContactList =
          this.contact && !!contacts.find(({ uuid }) => this.contact.uuid === uuid);
      })
    );
    // Keep track of reg and dialog subscriptions to determine contacts availability
    this.unsubscriber.add(
      this.subscriptionControllerService.state.subscribe(state => {
        this.dialogSubscriptions = state.presentity.filter(pres => pres.event === "dialog");
        this.checkDialogStatus();
      })
    );
    this.unsubscriber.add(
      this.usersStoreSubscriptionService.state
        .pipe(map(state => state.filter(user => user.id === this.contact.userId)))
        .subscribe(() => {
          this.checkAvailableStatus();
        })
    );
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }

  /** Add contact to ContactList on user btn click */
  addContact(): void {
    this.isUpdating = true;
    this.contactService
      .addContact([this.contact])
      .then(() => {
        this.snackbarService.openSnackBar(
          this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
          "success"
        );
        this.analyticsService.sendContactEvent("Contact Page Add", this.contact, undefined);
        this.isUpdating = false;
      })
      .catch(error => {
        this.snackbarService.openSnackBar(error.message, "error");
        this.isUpdating = false;
      });
  }

  /** Add custom contact */
  addCustomContact(): void {
    this.isUpdating = true;
    this.contactService
      .addCustomContact(this.contact.name, this.contact)
      .then(() => {
        this.snackbarService.openSnackBar(
          this.translate.instant("ONSIP_I18N.ADDED_TO_YOUR_CONTACTS"),
          "success"
        );
        this.analyticsService.sendContactEvent("Contact Page Add", this.contact, undefined);
        this.isUpdating = false;
      })
      .catch(error => {
        this.snackbarService.openSnackBar(error.message, "error");
        this.isUpdating = false;
      });
  }

  /** i18n error messages for form fields */
  getErrorMessage(controlName: "name" | "sipAddress") {
    const control = this.customContactForm.controls[controlName];
    if (controlName === "name") {
      if (control.hasError("required")) {
        return this.translate.instant("ONSIP_I18N.NAME_IS_REQUIRED");
      }

      if (control.hasError("invalidName")) {
        return `${control.value} ${this.translate.instant("ONSIP_I18N.IS_ALREADY_A_CONTACT")}`;
      }

      if (control.hasError("namePattern")) {
        return this.translate.instant("ONSIP_I18N.CONTACT_NAME_IS_INVALID");
      }

      if (control.hasError("maxlength")) {
        return this.translate.instant("VALIDATION_ERRORS.GENERIC_MAXLENGTH");
      }

      return "";
    } else if (controlName === "sipAddress") {
      return control.hasError("required")
        ? this.translate.instant("ONSIP_I18N.A_SIP_ADDRESS_OR_PHONE_NUMBER_IS_REQUIRED")
        : control.hasError("invalidAddress")
        ? this.translate.instant("ONSIP_I18N.ENTER_A_VALID_SIP_ADDRESS_OR_PHONE_NUMBER")
        : "";
    }
  }

  /** Remove contact from ContactList on user btn click */
  removeContact(): void {
    this.isUpdating = true;
    const contactListContact: Contact | undefined = this.contactService.find(this.contact.uuid);
    if (contactListContact) {
      this.contactService
        .removeContact([contactListContact])
        .then(() => {
          this.analyticsService.sendContactEvent("Contact Page Remove", this.contact, undefined);
        })
        .catch(errors => {
          this.snackbarService.openSnackBar(errors.message, "error");
        })
        .finally(() => {
          this.isUpdating = false;
          this.editing = false; // close edit form if deleted
        });
    }
  }

  /** Saves custom contact if form valid, callback for save changes button */
  updateCustomContact() {
    if (this.customContactForm.valid) {
      const name = this.customContactForm.controls.name.value;
      const uri = OnSIPURI.parseString(`sip:${this.customContactForm.controls.sipAddress.value}`);
      const phoneNumber = new E164PhoneNumber(this.customContactForm.controls.sipAddress.value);
      const edittedContact = uri
        ? ContactService.createCustomSIPContact(name, uri)
        : phoneNumber
        ? ContactService.createCustomPhoneContact(name, phoneNumber)
        : undefined;
      if (!edittedContact) return;
      edittedContact.contactId = this.contact.contactId;
      if (!ContactService.compareContacts(this.contact, edittedContact)) {
        // if no edits skip api call
        this.contactService
          .editCustomContact(edittedContact)
          .then(() => {
            this.contact = edittedContact; // need to update here since generally get contact changes in ngChanges, i.e. no subscription
            this.currentContactService.state.next(edittedContact); // trigger onChanges etc.
            this.isInContactList = true; // HACK: we get contactlist state update before we set this, without this Add Contact btn will be shown
          })
          .catch(err => this.snackbarService.openSnackBar(err.message, "error"))
          .finally(() => {
            this.editing = false;
            this.customContactFormReset();
          });
      } else {
        this.editing = false;
        this.customContactFormReset();
      }
    }
  }

  /** Reset form (avoid dirty form on mutliple edits) and fill values from contact */
  private customContactFormReset() {
    this.customContactForm.reset();
    this.customContactForm.controls.name.setValidators([
      Validators.required, // name uniqueness validator needs to exclude current contact
      customContactPatternValidator(),
      Validators.maxLength(60),
      customNameValidator(this.contactService, this.contact.uuid)
    ]);
    if (this.contact.custom) {
      this.customContactForm.controls.name.setValue(this.contact.name);
      this.customContactForm.controls.sipAddress.setValue(
        this.contact.aors && this.contact.aors.length
          ? this.contact.aors[0]
          : this.contact.e164PhoneNumbers && this.contact.e164PhoneNumbers.length
          ? this.contact.e164PhoneNumbers[0]
          : ""
      );
    }
  }

  /** Checks if this.contact has any active dialogs, if so sets this.isBusy to true */
  private checkDialogStatus(): void {
    let isBusy = false;
    if (this.contact) {
      this.contact.aors.forEach(aor => {
        const contactSub: Presentity | undefined = this.dialogSubscriptions.find(
          pres => pres.aor === aor
        );
        // this means not terminated, but active
        isBusy = isBusy || (!!contactSub && (contactSub.eventData as DialogInfo).priority > 0);
      });
    }
    this.isBusy = isBusy;
  }

  /** Checks if this.contact is globally available, if so set this.isGloballyAvailable to true */
  private checkAvailableStatus(): void {
    let isAvailable = false;
    const userAvailability = this.usersStoreSubscriptionService.getUserAvailabilityByUid(
      this.contact.userId
    );
    isAvailable = userAvailability === "available";
    this.isAvailable = isAvailable;
  }
}
