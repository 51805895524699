import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatCardModule } from "@angular/material/card";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRadioModule } from "@angular/material/radio";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { PortalModule } from "@angular/cdk/portal";

import { SpacerModule } from "../../spacer/spacer.module";

import { ThreeColumnComponent, ColumnItemDirective } from "./three-column.component";

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatCardModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatInputModule,
    PortalModule,
    SpacerModule
  ],
  declarations: [ThreeColumnComponent, ColumnItemDirective],
  exports: [ThreeColumnComponent, ColumnItemDirective]
})
export class ThreeColumnModule {}
