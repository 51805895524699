<div data-*ngIf="isChatEnabled &amp;&amp; !!chatBody">
  <mat-card #chatConversation class="chat-window-conversation">
    <div
      class="chat-window-message"
      data-*ngFor="let message of (!!chatBody ? chatBody.messages : []) | slice:0:messageLimit;
                     let isLast = last;
                     trackBy: trackByTimestamp"
    >
      <onsip-avatar
        [avatarUrl]="(message.author &amp;&amp; !message.author.isSelf) ? contact?.avatarUrl : myAvatar"
        [name]="(message.author &amp;&amp; !message.author.isSelf) ? contact?.name : message.fromName"
      >
      </onsip-avatar>
      <div class="chat-window-message-content">
        <div class="chat-window-message-info">
          <span
            class="chat-window-message-from"
            [class.primary-700]="message.author &amp;&amp; message.author.isSelf"
            [class.primary-500]="message.author &amp;&amp; !message.author.isSelf"
            [innerHtml]=" message.author &amp;&amp; message.author.isSelf ? selfName : message.fromName"
          >
          </span>
        </div>
        <span
          class="chat-window-message-text"
          data-*ngFor="let line of message.text"
          [innerHtml]="line"
        >
        </span>
      </div>
    </div>
    <div class="chat-window__message" data-*ngIf="isContactTyping">
      <onsip-avatar [avatarUrl]="contact?.avatarUrl" [name]="contact?.name"></onsip-avatar>
      <div class="chat-window-message-content">
        <div class="chat-window-message-info">
          <span
            class="chat-window__message-from chat-window__message-from--incoming"
            [innerHtml]="chatBody.userTypingFromName"
          >
          </span>
        </div>
        <span
          class="chat-window__message-text chat-window__message-text--typing"
          [innerHtml]="chatBody.userTypingMessage"
        >
        </span>
      </div>
    </div>
    <div class="chat-window__message" data-*ngIf="isContactAway">
      <onsip-avatar [avatarUrl]="contact?.avatarUrl" [name]="contact?.name"></onsip-avatar>
      <div class="chat-window-message-content">
        <div class="chat-window-message-info">
          <span
            class="chat-window__message-from chat-window__message-from--incoming"
            [innerHtml]="!!chatBody &amp;&amp; chatBody.contact &amp;&amp; chatBody.contact.displayName"
          >
          </span>
        </div>
        <span color="warn" [innerHtml]="!!chatBody &amp;&amp; chatBody.userAwayMessage"> </span>
      </div>
    </div>
  </mat-card>
  <mat-card-footer>
    <div class="chat-window-new-message-container">
      <input
        #newMessage
        matInput
        autocomplete="off"
        class="chat-window-new-message"
        type="text"
        placeholder="{{ 'ONSIP_I18N.TYPE_MESSAGE_HERE' | translate }}..."
        (keydown)="onKeyDown($event)"
      />
    </div>
    <button
      id="onsip--chat-window--send-message"
      mat-button
      class="chat-window-send"
      color="primary"
      (click)="sendNewMessage()"
      [disabled]="!isProviderConnected"
    >
      <span class="gt-xsmall">{{ "ONSIP_I18N.SEND_MESSAGE" | translate }}</span>
      <span class="xsmall">{{ "ONSIP_I18N.SEND" | translate }}</span>
    </button>
  </mat-card-footer>
</div>
