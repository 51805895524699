export declare interface ApiTermsAndConditions {
  Modified: string;
  Name: string;
  Text: string;
}

export declare interface TermsAndConditions {
  modified: string;
  name: string;
  text: string;
}

export function apiTacToTac(apiTac: ApiTermsAndConditions): TermsAndConditions {
  return {
    modified: apiTac.Modified,
    name: apiTac.Name,
    // add line breaks to text
    text: apiTac.Text.replace(/\n/g, "<br/>\n")
  };
}
