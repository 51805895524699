import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { MaterialModule } from "../../../../../modules/material/material.module";

import { SelectionListDeluxeComponent } from "./selection-list-deluxe.component";

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule, TranslateModule],
  declarations: [SelectionListDeluxeComponent],
  exports: [SelectionListDeluxeComponent]
})
export class SelectionListDeluxeModule {}
