import { Injectable } from "@angular/core";

import { Oauth2PopupWindowFactory } from "./oauth2-popup-window.factory";
import { OAuth2Client } from "./oauth2-auth.service";
import { StateEmitter } from "@onsip/common/libraries/emitter";
import { parseResponse } from "./http";
import { ApiSessionService } from "@onsip/common/services/api/api-session.service";
import { UserOauth2AccessTokenService } from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token.service";
import { ApiPromiseState } from "@onsip/common/services/api/api-promise-state.service";
import { UserOauth2AccessToken } from "@onsip/common/services/api/resources/userOAuth2AccessToken/user-oauth2-access-token";
export interface AccessToken {
  /** The service the token is associated with. */
  service: string;
  /** The access token. */
  token: string;
  /** When the token expires relative to last update (ms). */
  expiresIn: number;
  /** Last time the token was refreshed (epoch time ms). */
  refreshedAt: number;
}

/**
 * Basic service usage.
 * - Subscribe to this service state and wait for it to be up (state === true) and then
 *   call has() to check if the service you are interested in has been authorized or not.
 * - If the service has already been authorized, call refresh() to obtain a new access token.
 * - If the service has not been authroized, call authroize() to obtain and authorization code,
 *   then call add() to add the service to the set of authroized services and obtain an access token.
 * - The access token includes the expiration time and you are responsible for calling refresh again
 *   to obtain a new access token before (or after) expiration.
 */

@Injectable({ providedIn: "root" })
export class OAuth2Service extends StateEmitter<boolean> {
  private serviceToTokenIdMap = new Map<string, string>();

  constructor(
    private apiSession: ApiSessionService,
    private oauth2PopupWindowFactory: Oauth2PopupWindowFactory,
    private userOauth2AccessTokenService: UserOauth2AccessTokenService
  ) {
    super(false);
    this.init();
  }

  /**
   * Adds a service to our API.
   * The API will get and maintain a refresh token for the service.
   * @param service Service to add.
   * @param authorizationCode Authorization code returned by authorize().
   * @param scope Scope passed to authorize().
   * @param redirectUri Redirect URI passed to authorize().
   */
  add(
    service: string,
    authorizationCode: string,
    scope: string,
    redirectUri: string
  ): Promise<AccessToken> {
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenAdd({
        AuthorizationCode: authorizationCode,
        Service: service,
        RedirectUri: redirectUri,
        Scope: scope
      })
      .then(response => {
        if (response.status === "success") {
          const result = Object.values(response.data)[0];
          const userOAuthAccessToken = result.userOAuth2AccessTokens?.userOAuth2AccessToken;
          if (
            !(
              userOAuthAccessToken.extra?.accessToken &&
              userOAuthAccessToken.extra.accessTokenExpiresIn
            )
          ) {
            throw new Error("UserOAuth2AccessTokenAdd failed.");
          }
          const tokenId = result.userOAuth2AccessTokenId;
          if (!tokenId) throw new Error("Token Id undefined.");
          this.serviceToTokenIdMap.set(service, tokenId);
          const accessToken = this.makeAccessToken(userOAuthAccessToken);
          return accessToken;
        } else {
          return Promise.reject();
        }
      });
  }

  /**
   * Request authorization. Returns an authorization code.
   * @param clientId Client Id
   * @param authEndpoint URL to use for authorization.
   * @param scope Space separated list of scopes.
   * @param windowOptions Options for the popup window.
   */
  authorize(
    clientId: string,
    authEndpoint: string,
    scope: string, // space separated scopes
    windowOptions: { height: number; width: number },
    redirectURI?: string
  ): Promise<{ authorizationCode: string; redirectUri: string }> {
    const redirectUri = redirectURI || this.apiSession.platformGetRedirectUri();
    const oAuth2Client: OAuth2Client = { clientId, authEndpoint, scope, redirectUri };
    const request = this.oauth2PopupWindowFactory.createRequest(oAuth2Client);
    const windowFeatures = Oauth2PopupWindowFactory.platformGetWindowFeatures(
      windowOptions.height,
      windowOptions.width
    );
    // FIXME:
    // There are operational errors popup() rejects with that should be handled gracefully and other
    // programming/unrecoverable errors that cannot be handled, however these currently cannot
    // be distingusished (ie user cancels vs some configuration issue).
    return request.popup(windowFeatures).then(authorizationCode => {
      return { authorizationCode, redirectUri };
    });
  }

  /**
   * Returns true if the service has been athorized.
   * @param service Service to check for.
   */
  has(service: string): boolean {
    return this.serviceToTokenIdMap.has(service);
  }

  /**
   * Proxies an HTTP request to a service and returns the Response.
   * This is helpful to access provider APIs which do not support CORs or otherwise
   * require use of credentials that are not available client side or not secure to use client side.
   * @param service Id of the service to proxy the request to.
   * @param path The path of the request.
   * @param method The HTTP method to use.
   * @param headerFields Header fields to provide.
   */
  proxy(
    service: string,
    path: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    headerFields: Array<string>,
    body?: string
  ): Promise<Response> {
    const tokenId = this.serviceToTokenIdMap.get(service);
    if (!tokenId) return Promise.reject("Service not found.");
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenProxy({
        Service: service,
        Method: method,
        Path: path,
        HeaderFields: headerFields,
        Body: body
      })
      .then(apiResponse => {
        if (
          !(
            apiResponse &&
            apiResponse.Result &&
            apiResponse.Result.UserOAuth2AccessTokenProxy &&
            apiResponse.Result.UserOAuth2AccessTokenProxy.Output &&
            (apiResponse.Result.UserOAuth2AccessTokenProxy.Output.Response ||
              apiResponse.Result.UserOAuth2AccessTokenProxy.Output.Error)
          )
        ) {
          throw new Error("UserOAuth2AccessTokenProxy failed.");
        }
        // This API call puts the response in "Error" if the response status >= 400, so here we undo that...
        const response = apiResponse.Result.UserOAuth2AccessTokenProxy.Output.Response
          ? apiResponse.Result.UserOAuth2AccessTokenProxy.Output.Response
          : apiResponse.Result.UserOAuth2AccessTokenProxy.Output.Error;
        return parseResponse(response);
      });
  }

  /**
   * Refreshes the access token of a service.
   * @param service Service to refresh.
   */
  refresh(service: string): Promise<AccessToken> {
    const tokenId = this.serviceToTokenIdMap.get(service);
    if (!tokenId) return Promise.reject("Service not found.");
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenVerify(tokenId)
      .then(apiResponse => {
        if (apiResponse.status === "success") {
          const UserOAuth2AccessToken = Object.values(apiResponse.data)[0].userOAuth2AccessTokens
            ?.userOAuth2AccessToken;
          if (
            !(
              UserOAuth2AccessToken?.extra?.accessToken &&
              UserOAuth2AccessToken?.extra?.accessTokenExpiresIn
            )
          ) {
            throw new Error("UserOAuth2AccessTokenVerify failed.");
          }
          const accessToken = this.makeAccessToken(UserOAuth2AccessToken);
          return accessToken;
        } else {
          throw Error(apiResponse.data.message);
        }
      });
  }

  /**
   * Removes a service from our API.
   * The API will revoke the refresh token assoicated with the service.
   * @param service Service to remove.
   */
  remove(service: string): ApiPromiseState<void> {
    const tokenId = this.serviceToTokenIdMap.get(service);
    if (!tokenId) return Promise.reject("Service not found.");
    return this.userOauth2AccessTokenService.UserOAuth2AccessTokenDelete(tokenId);
  }

  private init(): Promise<void> {
    // Get the service which have already been authorized then set the service state to true.
    return this.userOauth2AccessTokenService
      .UserOAuth2AccessTokenBrowse({
        Limit: 100
      })
      .then(apiResponse => {
        if (apiResponse.status === "error") {
          throw new Error("UserOAuth2AccessTokenBrowse2 failed.");
        }

        const tokens: Array<UserOauth2AccessToken> = Object.values(apiResponse.data).map(el => {
          return el.userOAuth2AccessTokens.userOAuth2AccessToken;
        });

        if (tokens.length) {
          tokens.forEach(userOAuth2AccessToken => {
            const service = userOAuth2AccessToken.service;
            const tokenId = userOAuth2AccessToken.userOAuth2AccessTokenId;
            if (!service) throw new Error("Service undefined.");
            if (!tokenId) throw new Error("Token Id undefined.");
            this.serviceToTokenIdMap.set(service, tokenId);
          });
        }
        this.stateStore = true;
        this.publishState();
      });
  }

  private makeAccessToken(userOAuth2AccessToken: UserOauth2AccessToken): AccessToken {
    if (
      !(
        userOAuth2AccessToken &&
        userOAuth2AccessToken.service &&
        userOAuth2AccessToken.extra &&
        userOAuth2AccessToken.extra.accessToken &&
        userOAuth2AccessToken.extra.accessTokenExpiresIn
      )
    ) {
      throw new Error("OAuth2Service.update invalid api data.");
    }
    return {
      service: userOAuth2AccessToken.service,
      token: userOAuth2AccessToken.extra.accessToken,
      expiresIn: parseInt(userOAuth2AccessToken.extra.accessTokenExpiresIn, 10) * 1000,
      refreshedAt: Date.now()
    };
  }
}
