import { Injectable } from "@angular/core";
import { filter } from "rxjs/operators";

import { UserAgentService } from "../../shared/components/userAgent/user-agent.service";
import { CallControllerService } from "../../../../common/services/call-controller.service";
import { IdentityService } from "../../../../common/services/identity.service";
import { CallState } from "../../../../common/libraries/sip/call-state";
import { EavesdropInfo } from "./queue-realtime-types.component";
import { isEndCallEvent } from "@onsip/common/libraries/sip/call-event";

@Injectable({ providedIn: "root" })
export class EavesdropService {
  private eavesdropUUID: string | undefined;
  private eavesdropInfo: EavesdropInfo = {};

  constructor(
    private userAgentService: UserAgentService,
    private identityService: IdentityService,
    private callControllerService: CallControllerService
  ) {
    this.callControllerService.state.pipe(filter(() => !!this.eavesdropUUID)).subscribe(() => {
      if (!this.eavesdropUUID) {
        return;
      }
      const eavesdropCall: CallState | undefined = this.callControllerService.getCallStateByUuid(
        this.eavesdropUUID
      );
      if (!eavesdropCall) {
        this.eavesdropUUID = undefined;
      } else if (eavesdropCall.hold) {
        // eavesdropped calls are ended when put on hold
        this.callControllerService.endCall(this.eavesdropUUID);
        this.eavesdropUUID = undefined;
      }
    });

    this.callControllerService
      .getCallEventObservable()
      .pipe(
        filter(isEndCallEvent),
        filter(event => !!this.eavesdropUUID && this.eavesdropUUID === event.uuid)
      )
      .subscribe(() => {
        this.eavesdropUUID = undefined;
        this.eavesdropInfo = {};
      });
  }

  getEavesdropUuid(): string | undefined {
    return this.eavesdropUUID;
  }

  getEavesdropInfo(): EavesdropInfo {
    return this.eavesdropInfo;
  }

  setEavesdropInfo(eavesdropInfo: EavesdropInfo): void {
    this.eavesdropInfo = eavesdropInfo;
  }

  isEavesdropCall(uuid: string): boolean {
    return this.eavesdropUUID === uuid;
  }

  createEavesdropCall(target: string, options: any = {}): Promise<string> {
    this.identityService.restoreOutboundIdentity();
    return this.userAgentService.createOutboundCall(target, options).then(uuid => {
      if (this.eavesdropUUID && this.eavesdropUUID !== uuid) {
        this.callControllerService.endCall(this.eavesdropUUID);
      }
      this.eavesdropUUID = uuid;

      return uuid;
    });
  }
}
