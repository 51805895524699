import { Injectable, Inject } from "@angular/core";

import { Config } from "../../config";
import { LogTarget, LogLevel, LogType } from "./log.target";

@Injectable({ providedIn: "root" })
export class LogService {
  constructor(@Inject(LogTarget) private targets: Array<LogTarget>) {}

  // debug (standard output)
  debug(...msg: Array<LogType>): Promise<void> {
    if (Config.DEBUG.ALL || Config.DEBUG.LEVEL_4) {
      // console.debug does not work on {N} apps... use `log`
      Promise.all(this.targets.map(logger => this.logEvent(logger, msg, LogLevel.Debug)));
    }
    return Promise.resolve();
  }

  // info
  info(...err: Array<LogType>): Promise<void> {
    if (Config.DEBUG.ALL || Config.DEBUG.LEVEL_3) {
      Promise.all(this.targets.map(logger => this.logEvent(logger, err, LogLevel.Info)));
    }
    return Promise.resolve();
  }

  // warn
  warn(...err: Array<LogType>): Promise<void> {
    if (Config.DEBUG.ALL || Config.DEBUG.LEVEL_2) {
      Promise.all(this.targets.map(logger => this.logEvent(logger, err, LogLevel.Warning)));
    }
    return Promise.resolve();
  }

  // error
  error(...err: Array<LogType>): Promise<void> {
    if (Config.DEBUG.ALL || Config.DEBUG.LEVEL_1) {
      Promise.all(this.targets.map(logger => this.logEvent(logger, err, LogLevel.Error)));
    }
    return Promise.resolve();
  }

  private logEvent(target: LogTarget, message: Array<LogType>, level: LogLevel) {
    return target.log({ level, message });
  }
}
